import { Pagination, Space, Table } from "antd";
import React, { useEffect, useMemo } from "react";
import {
  useGetAnnouncementColumns,
  useGetTablePagination,
} from "../../../hooks";
import style from "../index.module.less";
import { useFormatMessage } from "@/util/formatMessage";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import basicMapper from "../../../../mapper";
import { prop } from "lodash/fp";
import {
  fetchGetAnnouncementInfo,
  fetchGetAnnouncementType,
} from "@/store/fundDetailSlice";
import dayjs from "dayjs";
import { fastNth } from "@/util/opt";

export default React.memo(
  ({ fundId, code }: { fundId: string; code: string }): JSX.Element => {
    const formatMessage = useFormatMessage();
    const { fundBasic } = useAppSelector((state) => basicMapper(state, fundId));
    const dispatch = useAppDispatch();

    const announcementTypeList = useAppSelector(
      prop("fundDetail.announcementType")
    );
    const { pageInfo, changePages } = useGetTablePagination(
      prop("AnnouncementInfo.totalSize")(fundBasic)
    );
    const { AnnouncementType, columns, publishTime, keyword } =
      useGetAnnouncementColumns(
        formatMessage,
        changePages,
        announcementTypeList
      );
    const { pageNum, pageSize } = useMemo(() => {
      return {
        pageNum: pageInfo?.current || 1,
        pageSize: pageInfo?.pageSize || 15,
      };
    }, [pageInfo]);

    useEffect(() => {
      if (code) {
        dispatch(
          fetchGetAnnouncementInfo({
            label: AnnouncementType,
            fundCode: code,
            pageNum: pageNum,
            fundId: fundId,
            pageSize: pageSize as number,
            from: publishTime
              ? dayjs(fastNth(0)(publishTime)).valueOf()
              : undefined,
            to: publishTime
              ? dayjs(fastNth(1)(publishTime)).valueOf()
              : undefined,
            keyWord: keyword,
          })
        );
      }
    }, [
      dispatch,
      code,
      pageNum,
      pageSize,
      AnnouncementType,
      fundId,
      publishTime,
      keyword,
    ]);
    useEffect(() => {
      dispatch(fetchGetAnnouncementType());
    }, [dispatch]);

    const tableData = useMemo(() => {
      return prop("AnnouncementInfo.result")(fundBasic);
    }, [fundBasic]);

    return (
      <Space direction="vertical" className={style.fullWidth}>
        <Table
          bordered={false}
          columns={columns as any}
          dataSource={tableData}
          className={style.NewTable}
          scroll={{
            y: 300,
            x: "auto",
          }}
          pagination={false}
        />
        <Pagination
          className={style.TablePagination}
          {...pageInfo}
          showSizeChanger
          showQuickJumper
          onChange={changePages}
        />
      </Space>
    );
  }
);
