export const openUrl = (url: string) => {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  // 包含pc的safari浏览器
  const isSafari =
    /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  if (isiOS || isSafari) {
    window.location.href = url;
  } else {
    window.open(url);
  }
};
