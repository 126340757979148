import { Space } from "antd";
import React from "react";
import CustomIcon from "./customIcon";

type Props = {
  errMessage: string;
  className?: string;
};
export default React.memo<Props>((props) => (
  <Space align="center" className={props.className}>
    <CustomIcon type="errorIcon" />
    <span>{props.errMessage}</span>
  </Space>
));
