import { RootState } from "@/store";
import { fastProp } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";

export const baseFundInfoSelector = createSelector(
  (state: RootState) => state.fundDetail,
  (_: RootState, fundId: string) => fundId,
  (fundDetail, fundId) => fastProp(fundId)(fundDetail)
);

export const fundDailyNetValueDatesSelector = createSelector(
  (state: RootState) => state.fundDetail,
  (_: RootState, fundId: string) => fundId,
  (fundDetail, fundId) =>
    fastProp(fundId)(fundDetail)?.fundDailyNetValue?.dates || []
);
