import React, { ReactNode } from "react";
import { Tooltip } from "antd";
import { useCreation } from "ahooks";

import {
  useGetAumOrganization,
  getOrganizationName,
  useGetAumPortfolioIndexInfo,
} from "@/hooks/aum";
import { useGetAumPortfolioFactor } from "@/hooks/aum";
import { fastProp, mapIndexed } from "@/util/opt";
import { formatPercentage } from "@/util/numberFormatter";
import { useFormatMessage } from "@/util/formatMessage";
import { factorsFormatter, formatter } from "@/constant/factorFormatter";
import ColorNumber from "@/components/colorNumber";
import { useAppSelector } from "@/hooks/redux";
import { benchmarkMapSelector } from "@/selectors/benchmarks";

import style from "./index.module.less";
import { CommonProps } from "../../type";
import { isString } from "lodash/fp";

type IndexCardData = {
  title: string;
  key: string;
  value?: number | string;
  render?: ReactNode;
};
export default React.memo<CommonProps>(
  ({ id, assetPortfolioId, managerName }) => {
    const organizations = useGetAumOrganization("SELECTOR");
    const formatMessage = useFormatMessage();
    const factorOverview = useGetAumPortfolioFactor(assetPortfolioId);
    const organizationName =
      getOrganizationName(organizations, id) || managerName;
    const indexInfo = useGetAumPortfolioIndexInfo(assetPortfolioId);
    const benchmarkMap = useAppSelector(benchmarkMapSelector);

    const benchmark = useCreation(
      () => benchmarkMap[fastProp("benchmark")(indexInfo)],
      [benchmarkMap, indexInfo]
    );

    const indexCardOptions: IndexCardData[] = useCreation(
      () => [
        {
          title: formatMessage("dailyYieldReturn"),
          key: "dailyYieldReturn",
          value: factorOverview?.lastDailyYield,
          render: (
            <ColorNumber
              formatValue={formatPercentage}
              value={factorOverview?.lastDailyYield}
            />
          ),
        },
        {
          title: formatMessage("yieldInRecentYear"),
          key: "yieldInRecentYear",
          value: factorOverview?.recentYearYield,
          render: (
            <ColorNumber
              formatValue={formatPercentage}
              value={factorOverview?.recentYearYield}
            />
          ),
        },
        {
          title: formatMessage("oneYearMaxDrawdown"),
          key: "oneYearMaxDrawdown",
          value: factorsFormatter.maxDrawdown(
            factorOverview?.recentYearDrawdown
          ),
        },
        {
          title: formatMessage("lastYearVolatility"),
          key: "lastYearVolatility",
          value: factorsFormatter.volatility(
            factorOverview?.recentYearVolatile
          ),
        },
        {
          title: formatMessage("oneYearSharpeRatio"),
          key: "oneYearSharpeRatio",
          value: factorOverview?.recentYearSharpeRatio
            ? factorsFormatter.sharpeRatio(
                factorOverview?.recentYearSharpeRatio
              )
            : "--",
        },
        {
          title: formatMessage("timePointAUM"),
          key: "timePointAUM",
          value: formatter.float2(factorOverview?.timePointAUM),
        },
        {
          title: formatMessage("timePointCustomersNumber"),
          key: "timePointCustomersNumber",
          value: factorOverview?.timePointClient,
        },
        ...(!isString(managerName)
          ? [
              {
                title: formatMessage("averageHoldingTime"),
                key: "averageHoldingTime",
                value: factorOverview?.avgHoldingDay,
                render: formatMessage("days", {
                  number: factorOverview?.avgHoldingDay || "--",
                }),
              },
            ]
          : []),
      ],
      [factorOverview, formatMessage]
    );

    const tooltipOptions = useCreation(
      () => [
        formatMessage("statisticalInterval", {
          historyStart: fastProp("historyStart")(indexInfo),
          historyEnd: fastProp("historyEnd")(indexInfo),
        }),
        formatMessage("performanceBenchmarkWithContent", {
          content: benchmark?.name,
        }),
      ],
      [benchmark?.name, formatMessage, indexInfo]
    );
    return (
      <div className={style.keyIndicatorsWrapper}>
        <div className={style.title}>{formatMessage("keyIndicator")}</div>
        <div className={style.keyIndicatorsContent}>
          <div className={style.orgNameWrapper}>
            <div className={style.orgNameLbl}>
              <div>{organizationName}</div>
            </div>
            <div className={style.triangle}></div>
          </div>
          <div className={style.indexCardsWrapper}>
            {mapIndexed((v: IndexCardData) => (
              <div className={style.indexCard} key={fastProp("key")(v)}>
                <div>{fastProp("title")(v)}</div>
                <div className={style.numLbl}>
                  {v?.render || fastProp("value")(v)}
                </div>
              </div>
            ))(indexCardOptions)}
          </div>
        </div>
        <div className={style.intervalWrapper}>
          {mapIndexed((v: string) => (
            <div>
              <Tooltip title={v}>{v}</Tooltip>
            </div>
          ))(tooltipOptions)}
        </div>
      </div>
    );
  }
);
