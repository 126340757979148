import ColorNumber from "@/components/colorNumber";
import { useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import { Space } from "antd";
import style from "../../../index.module.less";
import { formatPercentage, toFixedNumber } from "@/util/numberFormatter";
import mapper from "../../mapper";
import { useMemo } from "react";

const NavUnitSection = ({ fundId }: { fundId: string }): JSX.Element => {
  const formatMessage = useFormatMessage();
  const { latestNetValue } = useAppSelector((state) => mapper(state, fundId));
  const isCurrencyFund = useMemo(
    () => fastProp("isCurrencyFund")(latestNetValue),
    [latestNetValue]
  );
  return (
    <Space className={style.NavUnitSection} size="large">
      <Space direction="vertical">
        {!isCurrencyFund ? (
          <span>
            {formatMessage("NetUnitValue")}
            {`(${fastProp("tradingDate")(latestNetValue) || "--"})`}
          </span>
        ) : (
          <div>
            <p>{formatMessage("RevenueIncome")}</p>
            <p>{`(${fastProp("tradingDate")(latestNetValue) || "--"})`}</p>
          </div>
        )}
        <Space align="baseline">
          <ColorNumber
            className={style.NetValue}
            value={fastProp("navUnit")(latestNetValue)}
            formatValue={toFixedNumber(3)}
          />
          {!isCurrencyFund && (
            <ColorNumber
              value={fastProp("netValueIncrease")(latestNetValue)}
              formatter="percentage"
            />
          )}
        </Space>
      </Space>
      <Space direction="vertical">
        {!isCurrencyFund ? (
          <span>{formatMessage("NetCumValue")}</span>
        ) : (
          <div>
            <p>{formatMessage("SevenDayAnnualization")}</p>
            <p>{`(${fastProp("tradingDate")(latestNetValue) || "--"})`}</p>
          </div>
        )}
        <ColorNumber
          className={style.NetValue}
          value={fastProp("navCum")(latestNetValue)}
          formatValue={
            isCurrencyFund
              ? (value) => formatPercentage(value, { digit: 3 })
              : toFixedNumber(3)
          }
        />
      </Space>
    </Space>
  );
};

export default NavUnitSection;
