import { flow, map, slice, last, curry, uniqBy, size, sumBy } from "lodash/fp";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import getCumulativeAttributionsByParams from "@/util/quant/cumulativeAttributions";
import moment from "moment";
import {
  BY_DAY,
  BY_MONTH,
  BY_QUARTER,
  BY_YEAR,
  BY_ALL_DATE,
} from "@/constant/attributionPeriod";
import { subAttributionsType, periodType, dateType } from "@/model/fundDetail";
import getMessage from "@/util/getMessage";

export const getDetailTableData = curry(
  (
    dailyPortfolioReturns: number[],
    dailyBenchmarkReturns: number[],
    dailyActiveReturns: number[],

    dailyAllocationAttributions: number[],
    dailyAShareAttributions: number[],
    dailyNonAShareAttributions: number[],
    dailyNonEquityAttributions: number[],
    dailyThirdFloorOtherAttributionReturns: number[],

    dailyAShareAllocationAttributions: number[],
    dailyAShareSelectionAttributions: number[],
    dailyAShareTimingAttributions: number[],
    dailyAShareOtherReturns: number[],

    subAttributions: Array<subAttributionsType>,
    period: string
  ) => {
    if (period === BY_DAY) {
      return mapIndexed((date: string, index: number) => {
        const childAttribution = map(
          ({
            id,
            name,
            subDailyAShareAllocationAttributions,
            subDailyAShareSelectionAttributions,
            subDailyTimingAttributions,
          }) => ({
            id: `${date}${id}`,
            key: `${date}${id}`,
            subId: id,
            title: name || "--",
            allocationAttribution: fastNth(index)(
              subDailyAShareAllocationAttributions
            ),
            selectionAttribution: fastNth(index)(
              subDailyAShareSelectionAttributions
            ),
            timingOrInteractionAttribution: fastNth(index)(
              subDailyTimingAttributions
            ),
          })
        )(subAttributions);
        return {
          id: date,
          key: date,
          title: date,
          activeAttribution: fastNth(index)(dailyActiveReturns),
          fundAttribution: fastNth(index)(dailyPortfolioReturns),
          benchmarkAttribution: fastNth(index)(dailyBenchmarkReturns),

          assetAllocationPerformance: fastNth(index)(
            dailyAllocationAttributions
          ),
          aShareAttribution: fastNth(index)(dailyAShareAttributions),
          nonAShareAttribution: fastNth(index)(dailyNonAShareAttributions),
          nonEquityAttribution: fastNth(index)(dailyNonEquityAttributions),
          threeFloorOtherAttributions: fastNth(index)(
            dailyThirdFloorOtherAttributionReturns
          ),
          allocationAttribution: fastNth(index)(
            dailyAShareAllocationAttributions
          ),
          selectionAttribution: fastNth(index)(
            dailyAShareSelectionAttributions
          ),
          timingOrInteractionAttribution: fastNth(index)(
            dailyAShareTimingAttributions
          ),
          forthFloorOtherAttributions: fastNth(index)(dailyAShareOtherReturns),

          children: childAttribution,
        };
      });
    }
    return flow(
      processDates(period),
      map(({ date, periodStartIndex, periodEndIndex }: periodType) => {
        const sliced = slice(periodStartIndex, periodEndIndex + 1);

        const slicedDailyPortfolioReturns = sliced(dailyPortfolioReturns);
        const slicedCumulativePortfolioReturns = getCumulativeReturns(
          slicedDailyPortfolioReturns
        );

        const slicedDailyBenchmarkReturns = sliced(dailyBenchmarkReturns);
        const slicedCumulativeBenchmarkPositionReturns = getCumulativeReturns(
          slicedDailyBenchmarkReturns
        );

        // 净值 链接算法
        const getActualCumulativeAttributions: any =
          getCumulativeAttributionsByParams(
            slicedDailyPortfolioReturns,
            slicedDailyBenchmarkReturns,
            slicedCumulativePortfolioReturns,
            slicedCumulativeBenchmarkPositionReturns
          );

        const childAttribution = map(
          ({
            id,
            name,
            subDailyAShareAllocationAttributions,
            subDailyAShareSelectionAttributions,
            subDailyTimingAttributions,
          }) => {
            const slicedSubDailyAShareAllocationAttributions = sliced(
              subDailyAShareAllocationAttributions
            );
            const slicedSubCumulativePortfolioPositionAttribution: number[] =
              getActualCumulativeAttributions(
                slicedSubDailyAShareAllocationAttributions
              );
            const slicedSubDailyAShareSelectionAttributions: number[] = sliced(
              subDailyAShareSelectionAttributions
            );
            const slicedSubCumulativeAShareSelectionAttributions: number[] =
              getActualCumulativeAttributions(
                slicedSubDailyAShareSelectionAttributions
              );
            const slicedSubDailyTimingAttributions: number[] = sliced(
              subDailyTimingAttributions
            );
            const slicedSubCumulativeTimingAttributions: number[] =
              getActualCumulativeAttributions(slicedSubDailyTimingAttributions);
            return {
              id: `${date}${id}`,
              key: `${date}${id}`,
              subId: id,
              title: name || "--",
              allocationAttribution: last(
                slicedSubCumulativePortfolioPositionAttribution
              ),
              selectionAttribution: last(
                slicedSubCumulativeAShareSelectionAttributions
              ),
              timingOrInteractionAttribution: last(
                slicedSubCumulativeTimingAttributions
              ),
            };
          }
        )(subAttributions);
        // 资产配置
        const slicedDailyAllocationAttributions = sliced(
          dailyAllocationAttributions
        );
        const slicedCumulativeAllocationAttributions =
          getActualCumulativeAttributions(slicedDailyAllocationAttributions);
        const slicedDailyAShareAttributions = sliced(dailyAShareAttributions);
        const slicedCumulativeAShareAttributions =
          getActualCumulativeAttributions(slicedDailyAShareAttributions);
        const slicedDailyNonAShareAttributions = sliced(
          dailyNonAShareAttributions
        );
        const slicedCumulativeNonAShareAttributions =
          getActualCumulativeAttributions(slicedDailyNonAShareAttributions);
        const slicedDailyNonEquityAttributions = sliced(
          dailyNonEquityAttributions
        );
        const slicedCumulativeNonEquityAttributions =
          getActualCumulativeAttributions(slicedDailyNonEquityAttributions);
        const slicedDailyThirdFloorOtherAttributionReturns = sliced(
          dailyThirdFloorOtherAttributionReturns
        );
        const slicedCumulativeThirdFloorOtherAttributionReturns =
          getActualCumulativeAttributions(
            slicedDailyThirdFloorOtherAttributionReturns
          );

        // 配置业绩
        const slicedDailyAShareAllocationAttributions = sliced(
          dailyAShareAllocationAttributions
        );
        const slicedCumulativeAShareAllocationAttributions =
          getActualCumulativeAttributions(
            slicedDailyAShareAllocationAttributions
          );
        // 选股业绩
        const slicedDailyAShareSelectionAttributions = sliced(
          dailyAShareSelectionAttributions
        );
        const slicedCumulativeAShareSelectionAttributions =
          getActualCumulativeAttributions(
            slicedDailyAShareSelectionAttributions
          );
        // 择时业绩
        const sliceddailyAShareTimingAttributions = sliced(
          dailyAShareTimingAttributions
        );
        const slicedcumulativeAShareTimingAttributions =
          getActualCumulativeAttributions(sliceddailyAShareTimingAttributions);
        // 第四层其他业绩
        const slicedDailyAShareOtherReturns = sliced(dailyAShareOtherReturns);
        const slicedCumulativeDailyAShareOtherReturns =
          getActualCumulativeAttributions(slicedDailyAShareOtherReturns);

        // 超额业绩
        const slicedDailyActiveAttributions = sliced(dailyActiveReturns);
        const slicedCumulativeActiveAttributions =
          getActualCumulativeAttributions(slicedDailyActiveAttributions);

        return {
          id: date,
          key: date,
          title: date,

          fundAttribution: last(slicedCumulativePortfolioReturns),
          benchmarkAttribution: last(slicedCumulativeBenchmarkPositionReturns),
          // 超额业绩
          activeAttribution: last(slicedCumulativeActiveAttributions),

          // 资产配置业绩
          assetAllocationPerformance: last(
            slicedCumulativeAllocationAttributions
          ),
          // 选A股业绩
          aShareAttribution: last(slicedCumulativeAShareAttributions),
          //  选非A股业绩
          nonAShareAttribution: last(slicedCumulativeNonAShareAttributions),
          // 选非权益类业绩
          nonEquityAttribution: last(slicedCumulativeNonEquityAttributions),
          // 其他业绩（三层）
          threeFloorOtherAttributions: last(
            slicedCumulativeThirdFloorOtherAttributionReturns
          ),

          // 配置业绩
          allocationAttribution: last(
            slicedCumulativeAShareAllocationAttributions
          ),
          // 选股业绩
          selectionAttribution: last(
            slicedCumulativeAShareSelectionAttributions
          ),
          // 择时业绩
          timingOrInteractionAttribution: last(
            slicedcumulativeAShareTimingAttributions
          ),
          // 其他业绩（四层）
          forthFloorOtherAttributions: last(
            slicedCumulativeDailyAShareOtherReturns
          ),
          children: childAttribution,
        };
      })
    );
  }
);

export const getETFDetailTableData = curry(
  (
    dailyPortfolioActualReturns: number[],
    dailyPortfolioReturns: number[],
    dailyPortfolioBenchmarkReturns:number[],
    dailyPortfolioBenchmarkPositionReturns:number[],
    dailyActiveAttributions:number[],
    dailyPositionActiveReturns:number[],
    dailyAllocationAttributions : number[],
    dailySelectionAttributions:number[],
    subAttributions:Record<string,any>[],
    period:string,
  ) => {
    if (period === BY_DAY) {
      return mapIndexed(
        (date:string, index:number) => {
          const holderAttribution = map(({
            id,
            name,
            // 是否有杠杆
            hasLeverage,
            dailyAllocationAttributions: subDailyAllocationAttributions,
            dailySelectionAttributions: subDailySelectionAttributions,
            dailyActiveReturns: subDailyActiveReturns,
            dailyTotalReturns: subDailyTotalReturns,
            dailyBenchmarkReturns: subDailyBenchmarkReturns,
          }) => ({
            hasLeverage,
            id: `${date}${id}`,
            key: `${date}${id}`,
            subId: id,
            title: name || "--",
            ...getAttributionsObject(
              fastNth(index)(subDailyActiveReturns),
              fastNth(index)(subDailyAllocationAttributions),
              fastNth(index)(subDailySelectionAttributions),
              fastNth(index)(subDailyTotalReturns),
              fastNth(index)(subDailyBenchmarkReturns),
            ),
          }))(subAttributions);
          return {
            id: date,
            key: date,
            title: date,
            ...getAttributionsObject(
              fastNth(index)(dailyActiveAttributions),
              fastNth(index)(dailyAllocationAttributions),
              fastNth(index)(dailySelectionAttributions),
              fastNth(index)(dailyPortfolioReturns),
              fastNth(index)(dailyPortfolioBenchmarkReturns),
            ),
            timingOrInteractionAttribution: fastNth(index)(dailyPortfolioReturns) -
              fastNth(index)(dailyPortfolioBenchmarkPositionReturns) -
              fastNth(index)(dailyAllocationAttributions) -
              fastNth(index)(dailySelectionAttributions),
            otherAttribution: fastNth(index)(dailyActiveAttributions) - fastNth(index)(dailyPositionActiveReturns),
            fundAttribution: sumBy("fundAttribution")([
              ...holderAttribution,
            ]),
            children: holderAttribution,
          };
        },
      );
    }

    const nullBenchmarkRetures = map(() => 0)(dailyPortfolioBenchmarkReturns);

    return flow(
      processDates(period),
      map(({ date, periodStartIndex, periodEndIndex }: periodType) => {
        const sliced = slice(periodStartIndex, periodEndIndex + 1);
        const slicedDailyPortfolioReturns = sliced(dailyPortfolioReturns);
        const slicedDailyPortfolioBenchmarkReturns = sliced(dailyPortfolioBenchmarkReturns);
        const slicedDailyPortfolioBenchmarkPositionReturns = sliced(dailyPortfolioBenchmarkPositionReturns);
        const slicedCumulativePortfolioReturns = getCumulativeReturns(slicedDailyPortfolioReturns);
        const slicedCumulativePortfolioBenchmarkReturns = getCumulativeReturns(slicedDailyPortfolioBenchmarkReturns);
        const slicedCumulativePortfolioBenchmarkPositionReturns = getCumulativeReturns(slicedDailyPortfolioBenchmarkPositionReturns);
        const getLastCumulativePositionAttribution = flow(
          sliced,
          getCumulativeAttributionsByParams(
            slicedDailyPortfolioReturns,
            slicedDailyPortfolioBenchmarkPositionReturns,
            slicedCumulativePortfolioReturns,
            slicedCumulativePortfolioBenchmarkPositionReturns,
          ),
          last,
        );
        const slicedDailyPortfolioActualReturns = sliced(dailyPortfolioActualReturns);
        const slicedCumulativePortfolioActualReturns = getCumulativeReturns(slicedDailyPortfolioActualReturns);
        const getLastCumulativeActualAttribution = flow(
          sliced,
          getCumulativeAttributionsByParams(
            dailyPortfolioActualReturns,
            dailyPortfolioBenchmarkReturns,
            slicedCumulativePortfolioActualReturns,
            slicedCumulativePortfolioBenchmarkReturns,
          ),
          last,
        );
        const slicedNullBenchmarks = sliced(nullBenchmarkRetures);
        const getAsboluteLastCumulativeAttribution = flow(
          sliced,
          getCumulativeAttributionsByParams(
            slicedDailyPortfolioReturns,
            slicedNullBenchmarks,
            slicedCumulativePortfolioReturns,
            slicedNullBenchmarks,
          ),
          last,
        );

        const getLastCumulativeReturns = flow(
          sliced,
          getCumulativeReturns,
          last,
        );

        const getAsboluteLastBenchmarkCumulativeAttribution = flow(
          sliced,
          getCumulativeAttributionsByParams(
            slicedDailyPortfolioBenchmarkPositionReturns,
            slicedNullBenchmarks,
            slicedCumulativePortfolioBenchmarkPositionReturns,
            slicedNullBenchmarks,
          ),
          last,
        );

        const holderAttribution = map(({
          id,
          name,
          hasLeverage,
          dailyAllocationAttributions: subDailyAllocationAttributions,
          dailySelectionAttributions: subDailySelectionAttributions,
          dailyTotalReturns: subDailyTotalReturns,
          dailyBenchmarkReturns: subDailyBenchmarkReturns,
        }) => {
          const subCumulativePortfolioPositionAttribution = getAsboluteLastCumulativeAttribution(subDailyTotalReturns);
          const subCumulativeBenchmarkPositionAttribution = getAsboluteLastBenchmarkCumulativeAttribution(subDailyBenchmarkReturns);
          return {
            hasLeverage,
            id: `${date}${id}`,
            key: `${date}${id}`,
            subId: id,
            title: name || "--",
            ...getAttributionsObject(
              subCumulativePortfolioPositionAttribution - subCumulativeBenchmarkPositionAttribution,
              getLastCumulativePositionAttribution(subDailyAllocationAttributions),
              getLastCumulativePositionAttribution(subDailySelectionAttributions),
              subCumulativePortfolioPositionAttribution,
              subCumulativeBenchmarkPositionAttribution,
            ),

          };
        })(subAttributions);
        const lastCumulativePortfolioBenchmarkReturn = getLastCumulativeReturns(dailyPortfolioBenchmarkReturns);
        const lastCumulativePortfolioReturn = getLastCumulativeReturns(dailyPortfolioActualReturns);
        const lastCumulativeAllocationAttribution = getLastCumulativePositionAttribution(dailyAllocationAttributions);
        const lastCumulativeSelectionAttribution = getLastCumulativePositionAttribution(dailySelectionAttributions);
        const lastCumulativeActiveAttribution = getLastCumulativeActualAttribution(dailyActiveAttributions);
        const lastCumulativeActivePositionAttribution = getLastCumulativePositionAttribution(dailyPositionActiveReturns);
        // const lastCumulativePortfolioBenchmarkPositionReturn = getLastCumulativeReturns(dailyPortfolioBenchmarkPositionReturns);
        return {
          id: date,
          key: date,
          title: date,
          ...getAttributionsObject(
            lastCumulativeActiveAttribution,
            lastCumulativeAllocationAttribution,
            lastCumulativeSelectionAttribution,
            lastCumulativePortfolioReturn,
            lastCumulativePortfolioBenchmarkReturn,
          ),
          otherAttribution: lastCumulativeActiveAttribution - lastCumulativeActivePositionAttribution,
          timingOrInteractionAttribution: lastCumulativeActivePositionAttribution - lastCumulativeAllocationAttribution - lastCumulativeSelectionAttribution,
          fundAttribution:last(slicedCumulativePortfolioActualReturns),
          children: holderAttribution,
        };
      }),
    );
  },
);

const getAttributionsObject = (
  activeAttribution:number,
  allocationAttribution:number,
  selectionAttribution:number,
  fundAttribution:number,
  benchmarkAttribution:number,
) => ({
  activeAttribution,
  allocationAttribution,
  selectionAttribution,
  fundAttribution,
  benchmarkAttribution,
  timingOrInteractionAttribution: (
    activeAttribution - allocationAttribution - selectionAttribution
  ),
});

const formats = {
  [BY_MONTH]: (date: string) => moment(date).format("YYYY-MM"),
  [BY_QUARTER]: (date: string) => {
    const dateMoment = moment(date);
    return `${dateMoment.format("YYYY")}Q${dateMoment.quarter()}`;
  },
  [BY_YEAR]: (date: string) => moment(date).format("YYYY"),
  [BY_ALL_DATE]: () => getMessage("allDate"),
};

export const processDates = (period: string) => (dates: string[]) =>
  flow(
    mapIndexed((date: string, index: number) => ({
      date: fastProp(period)(formats)(date),
      periodStartIndex: index,
    })),
    uniqBy(({ date }) => date),
    mapIndexed(
      (
        { date, periodStartIndex }: dateType,
        index: number,
        array: { [x: string]: { periodStartIndex: number } }
      ) => ({
        date,
        periodStartIndex,
        periodEndIndex: array[index + 1]
          ? array[index + 1].periodStartIndex - 1
          : size(dates) - 1,
      })
    )
  )(dates);

export const getSelectablePeriods = (from: string, to: string) => {
  const startDate = moment(from);
  const endDate = moment(to);
  const selectablePeriod = [BY_DAY];
  if (!startDate.isSame(endDate, "month")) {
    selectablePeriod.push(BY_MONTH);
  }
  if (
    startDate.quarter() !== endDate.quarter() ||
    !startDate.isSame(endDate, "year")
  ) {
    selectablePeriod.push(BY_QUARTER);
  }
  if (!startDate.isSame(endDate, "year")) {
    selectablePeriod.push(BY_YEAR);
  }
  selectablePeriod.push(BY_ALL_DATE);
  return selectablePeriod;
};
