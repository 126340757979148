import {
  ETFfFundFilter,
  ETFFundFilterParam,
  ETFTagFactorsProp,
} from "@/model/etfFilter";
import { PromiseType } from "@/model/promise";
import { get, post } from "@/util/axios";

// ETF标签大类对应标签和特色因子
export const getETFTagsAndFactor = (): PromiseType<ETFTagFactorsProp> =>
  get("/etf-fund/filter/tagFactors");

// ETF基金筛选
export const getETFFundFilterList = (
  params: ETFFundFilterParam
): PromiseType<ETFfFundFilter[]> =>
  post("/etf-fund/filter/filter", params, {
    config: {
      showLoading: true,
    },
  });
