import { useState } from "react";

export default () => {
  const [visible, changeVisible] = useState(false);
  return {
    visible,
    openModal: () => changeVisible(true),
    closeModal: () => changeVisible(false),
  };
};
