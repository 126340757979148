import React, { useMemo } from "react";
import cn from "classnames";
import { Table } from "antd";

import { fastProp, mapIndexed } from "@/util/opt";
import { EvaluationReportInfoBody } from "@/model/fundDetail";
import { useFormatMessage } from "@/util/formatMessage";
import { useFundReportContext } from "../../../../context";

import style from "../index.module.less";
import {
  useGetBasicInfoTableColumns,
  useGetBasicInfoTableDataSource,
  useGetFundTags,
} from "../hooks";
import { replace } from "lodash/fp";

export enum FUND_STYLE_TYPE_ENUM {
  MyTag_rating23_gh,
  MyTag_rating23_mh,
  MyTag_rating23_vh,
  MyTag_rating23_gm,
  MyTag_rating23_mm,
  MyTag_rating23_vm,
  MyTag_rating23_gl,
  MyTag_rating23_ml,
  MyTag_rating23_vl,
}

export default React.memo(
  ({
    fundId,
    evaluationReportInfo,
  }: {
    fundId: string;
    evaluationReportInfo: EvaluationReportInfoBody | undefined;
  }) => {
    const formatMessage = useFormatMessage();

    const contextValue = useFundReportContext();
    const fundMap = contextValue?.fundMap;
    const fundInfo = useMemo(
      () => fastProp(fundId)(fundMap),
      [fundId, fundMap]
    );

    const columns = useGetBasicInfoTableColumns();
    const dataSource = useGetBasicInfoTableDataSource(evaluationReportInfo);

    const fundTypeActiveIdx = useMemo<number>(
      () =>
        FUND_STYLE_TYPE_ENUM[
          fastProp("fundType")(evaluationReportInfo)
        ] as unknown as number,
      [evaluationReportInfo]
    );

    const { excellentFundTags, poorFundTags } = useGetFundTags(fundId);

    return (
      <>
        <h1>
          {fastProp("name")(fundInfo)} {replace("_OF")("")(fundId)}
        </h1>
        <div className={style.BaseInfoRow}>
          <div className={style.Left}>
            <div className={style.TagWrap}>
              <div>
                {mapIndexed((item: string, idx: number) => (
                  <div className={style.Excellent} key={idx}>
                    {item}
                  </div>
                ))(excellentFundTags)}
              </div>
              <div>
                {mapIndexed((item: string, idx: number) => (
                  <div className={style.Poor} key={idx}>
                    {item}
                  </div>
                ))(poorFundTags)}
              </div>
            </div>
            <div className={style.TableWrap}>
              <Table
                dataSource={dataSource}
                showHeader={false}
                pagination={false}
                columns={columns}
                bordered
                className={style.Table}
                rowClassName={(_, index: number) =>
                  cn(index % 2 === 0 && style.ColoredRow, style.Row)
                }
              />
            </div>
          </div>
          <div className={style.Right}>
            <div className={style.Row}>
              <div className={style.RectChart}>
                {Array(9)
                  .fill(0)
                  .map((_: any, idx: number) => (
                    <div
                      className={cn(
                        style.RectChartItem,
                        idx === fundTypeActiveIdx && style.ActiveItem
                      )}
                      key={idx}
                    />
                  ))}
              </div>
              <div className={style.YAxis}>
                <div className={style.YAxisItem}>
                  {formatMessage("highRisk")}
                </div>
                <div className={style.YAxisItem}>
                  {formatMessage("middleRisk")}
                </div>
                <div className={style.YAxisItem}>
                  {formatMessage("lowRisk")}
                </div>
              </div>
            </div>
            <div className={style.XAxis}>
              <div className={style.XAxisItem}>{formatMessage("growth")}</div>
              <div className={style.XAxisItem}>{formatMessage("balance")}</div>
              <div className={style.XAxisItem}>{formatMessage("value")}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
);
