import { flow, isNumber, map, reduce, size, update } from "lodash/fp";
import { fastProp, getOperateFunc } from "./opt";
import { roundTo, floorTo } from "./numberFormatter";
import { bigNumber } from "./math";
import Big from "big.js";

export type WeightFieldType =
  | string
  | null
  | undefined
  | ((...arg: any[]) => any);
export type TargetArrayType<T> = number[] | Record<string, T>[];

const { convert, plus, minus, toNumber } = bigNumber;
const bigSumBy =
  <T>(weightField: WeightFieldType) =>
  (array: TargetArrayType<T>) => {
    const operateFunc = getOperateFunc(weightField);
    return flow(
      reduce(
        (target: Big, current) => plus(operateFunc(current))(target),
        convert(0) as Big
      ),
      toNumber
    )(array);
  };
export const weightRoundTo =
  <T>(sumWeight?: number, weightField?: string, precision = 4) =>
  (arr: number[] | Record<string, T>[]) => {
    if (!arr || !arr.length || arr.length <= 1) {
      return map((v: any) =>
        weightField
          ? { ...v, [weightField]: roundTo(precision)(v?.[weightField]) }
          : roundTo(precision)(v)
      )(arr);
    }

    const total =
      isNumber(sumWeight) && !isNaN(sumWeight)
        ? sumWeight
        : bigSumBy(weightField)(arr);

    let roundSum = convert(0) as Big;
    const res = map((v: any) => {
      if (weightField) {
        const weight = floorTo(precision)(v?.[weightField]);
        roundSum = plus(weight)(roundSum);
        return { ...v, [weightField]: weight };
      }
      const weight = floorTo(precision)(v);
      roundSum = plus(weight)(roundSum);
      return weight;
    })(arr);

    const remainWeight = flow(
      convert,
      minus(toNumber(roundSum)),
      toNumber
    )(total);
    return update(size(res) - 1, (item) =>
      weightField
        ? {
            ...item,
            [weightField]: roundTo(precision)(
              fastProp(weightField)(item) + remainWeight
            ),
          }
        : roundTo(precision)(item + remainWeight)
    )(res);
  };

type EqualWeightType = {
  weight: number;
  [propName: string]: any;
};
export const equalWeight = <T extends EqualWeightType>(data: T[]): T[] => {
  const dataSize = size(data);
  if (dataSize) {
    const avgWeight = floorTo(4)(1 / dataSize);
    return flow(
      map<T, T>((v) => ({
        ...v,
        weight: avgWeight,
      })),
      weightRoundTo(1, "weight")
    )(data);
  }
  return data;
};
