import React, { useMemo, useState } from "react";
import { Card, Radio, Space, Table } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
// import CumulativeChart from "@/components/cumulativeChart";
import YieldStatisticRangePicker from "@/components/yieldStatisticRangePicker";
import { useAppSelector } from "@/hooks/redux";
import { getYieldIndicatorsColumns } from "@/views/compareManage/fundCompare/constant";
import style from "./index.module.less";
import { AssetDetailComponent } from "../../constant";
import mapper from "./mapper";
import {
  // useGetChartData,
  useGetYieldIndicatorsData,
  useGetRangeOfBenefitsColumns,
  useGetRangeOfBenefitsDataSource,
  useRequestBenchmarkDailyReturnAndFactors,
} from "../../hooks";
import { statisticOptions } from "../../constant";
import BenchmarkSelect from "@/components/benchmarkSelect";
import { DatePeriodName } from "@/util/business-core/datePeriod";
import { getSlicedDateReturnsAndRangeDates } from "@/util/business-core/dailyReturn";
import { YieldTrendChart } from "@/base-components/charts";
import { mapIndexed } from "@/util/opt";
import { ValueType } from "@/base-components/charts/yieldTrendChart/type";

export default React.memo<AssetDetailComponent>(({ updateHandler, fundId }) => {
  const formatMessage = useFormatMessage();
  const {
    benchmarkId,
    statisticRange,
    factorRankValue,
    sameTypeFactors,
    benchmarkFactors,
    benchmarkInfo,
    fundInfo,
    sameTypeInfo,
    dates,
    // benchmarkDates,
    tradingDateList,
  } = useAppSelector((state) => mapper(state, fundId));
  // const chartDataInfo = useGetChartData({
  //   range: statisticRange,
  //   fundInfo,
  //   sameTypeInfo,
  //   benchmarkInfo,
  //   dates,
  //   benchmarkDates,
  // });
  const { datesWithPreviousOneDay, dateReturnsWithPreviousOneDay } = useMemo(
    () =>
      getSlicedDateReturnsAndRangeDates({
        name: statisticRange as DatePeriodName,
        targetDates: tradingDateList,
        targetDateReturns: [
          { dates: fundInfo?.dates, dailyReturns: fundInfo?.dailyReturns },
        ],
        otherDateReturns: [
          {
            dates: sameTypeInfo?.dates,
            dailyReturns: sameTypeInfo?.dailyReturns,
          },
          {
            dates: benchmarkInfo?.dates,
            dailyReturns: benchmarkInfo?.dailyReturns,
          },
        ],
      }),
    [fundInfo, sameTypeInfo, benchmarkInfo, statisticRange, tradingDateList]
  );
  const series = [
    {
      name: fundInfo?.name,
      color: fundInfo?.color,
      ...(dateReturnsWithPreviousOneDay?.[0] || {}),
    },
    {
      name: sameTypeInfo?.name,
      color: sameTypeInfo?.color,
      ...(dateReturnsWithPreviousOneDay?.[1] || {}),
    },
    {
      name: benchmarkInfo?.name,
      color: benchmarkInfo?.color,
      ...(dateReturnsWithPreviousOneDay?.[2] || {}),
    },
  ];

  const { dates: netValDates, dateReturns } = useMemo(
    () =>
      getSlicedDateReturnsAndRangeDates({
        name: statisticRange as DatePeriodName,
        targetDates: tradingDateList,
        targetDateReturns: [
          {
            dates: fundInfo?.fundDailyNetValue?.dates,
            dailyReturns: fundInfo?.fundDailyNetValue?.dailyReturns,
          },
        ],
      }),
    [
      fundInfo?.fundDailyNetValue?.dates,
      fundInfo?.fundDailyNetValue?.dailyReturns,
      statisticRange,
      tradingDateList,
    ]
  );

  const netTrendDataSeries = useMemo(
    () => [
      {
        name: fundInfo?.name,
        color: fundInfo?.color,
        dates: dateReturns?.[0]?.dates,
        returns: dateReturns?.[0]?.dailyReturns,
      },
    ],
    [dateReturns, fundInfo?.color, fundInfo?.name]
  );

  useRequestBenchmarkDailyReturnAndFactors(benchmarkId);
  const yieldIndicatorsTableData = useGetYieldIndicatorsData({
    range: statisticRange,
    dates,
    fundInfo,
    sameTypeInfo,
    benchmarkInfo,
    calculatedDates: dates,
  });
  const columns = useMemo(
    () => getYieldIndicatorsColumns(formatMessage),
    [formatMessage]
  );
  const rangeOfBenefitsColumns = useGetRangeOfBenefitsColumns();
  const rangeOfBenefitsDataSource =
    useGetRangeOfBenefitsDataSource({
      factorRankValue,
      sameTypeFactors,
      benchmarkFactors,
      fundName: fundInfo.name,
      sameTypeName: sameTypeInfo.name,
      benchmarkName: benchmarkInfo.name,
    }) || {};

  const trendTypeOptions: Array<{ title: string; value: ValueType }> = [
    {
      title: formatMessage("cumulativeIncomeTrend"),
      value: "yield",
    },
    {
      title: formatMessage("cumulativeNetWorthTrend"),
      value: "netValue",
    },
  ];
  const [trendType, setTrendType] = useState<ValueType>(
    trendTypeOptions[0].value
  );
  return (
    <Space direction="vertical" className={style.fullWidth} size={20}>
      <Card bordered={false} className={style.Card}>
        <Space direction="vertical" className={style.fullWidth}>
          <div className={style.Flex}>
            <h3>{formatMessage("incomeTrend")}</h3>
            <Space size={12}>
              <BenchmarkSelect
                className={style.CardHeader}
                value={benchmarkId}
                onChange={updateHandler("assetIncomeBenchmarkId")}
              />
              <Space>
                <span>{formatMessage("periodOfTime")}</span>
                <YieldStatisticRangePicker
                  value={statisticRange}
                  onChange={updateHandler("assetIncomeStatisticRange")}
                  options={statisticOptions}
                />
              </Space>
            </Space>
          </div>
          <div className={style.radioWrapper}>
            <Radio.Group
              value={trendType}
              onChange={(e) => {
                setTrendType(e.target.value);
              }}
              buttonStyle="solid"
            >
              {mapIndexed((v: { title: string; value: ValueType }) => (
                <Radio.Button value={v.value}>{v.title}</Radio.Button>
              ))(trendTypeOptions)}
            </Radio.Group>
          </div>
          <YieldTrendChart
            height={400}
            dates={trendType === "yield" ? datesWithPreviousOneDay : netValDates}
            series={trendType === "yield" ? series : netTrendDataSeries}
            type={trendType}
          />
          {/* <CumulativeChart
            dates={chartDataInfo.dates}
            dataSource={chartDataInfo.chartData}
            type="cumulative"
          /> */}
        </Space>
      </Card>
      <Card bordered={false} className={style.Card}>
        <Space direction="vertical" className={style.fullWidth}>
          <h3>{formatMessage("rangeOfBenefits")}</h3>
          <Table
            columns={rangeOfBenefitsColumns}
            dataSource={rangeOfBenefitsDataSource}
            pagination={false}
            className={style.Table}
          />
        </Space>
      </Card>
      <Card bordered={false} className={style.Card}>
        <Space direction="vertical" className={style.fullWidth}>
          <h3>{formatMessage("incomeIndex")}</h3>
          <Table
            columns={columns as any}
            dataSource={yieldIndicatorsTableData}
            pagination={false}
            className={style.Table}
          />
        </Space>
      </Card>
    </Space>
  );
});
