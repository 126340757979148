import { PromiseType } from "@/model/promise";
import { get, post } from "@/util/axios";
import {
  FundsInterface,
  ManagerNameInterface,
  CompanyNameInterface,
} from "@/model/entities";
import {
  AssetPortfolio,
  Optional,
  MainIndex,
  IndustryIndexResponseType,
} from "@/model/dataBoard";

export const getAllFund = (): PromiseType<FundsInterface[]> =>
  get("/openfunds/allFund", undefined, {
    config: {
      showLoading: true,
    },
  });

export const getAllSimpleFund = (): PromiseType<FundsInterface[]> =>
  get("/mobile/front/page/all/evaluation/funds", undefined, {
    config: {
      showLoading: true,
    },
  });

export const getAllFundManagerName = (): PromiseType<ManagerNameInterface[]> =>
  get("/openfunds/allFundManagerName", undefined, {
    config: {
      showLoading: true,
    },
  });

export const getAllFundCompanyName = (): PromiseType<CompanyNameInterface[]> =>
  get("/openfunds/allFundCompanyName", undefined, {
    config: {
      showLoading: true,
    },
  });

// 获取用户的模拟组合
export const getUserAssetPortfolio = ({
  period,
}: {
  period: string;
}): PromiseType<AssetPortfolio[]> => get(`/asset-portfolios/info/${period}`);

// 用户的自选
export const getUserOptional = (data: {
  userId: string;
  section: string;
}): PromiseType<Record<string, Optional[]>> =>
  get("/openfunds/optional", { ...data }, { mocker: true });

// 主要指数
export const getMainIndex = (period: string): PromiseType<MainIndex[]> =>
  get(`/openfunds/mainIndex/${period}`);

// 行业指数
export const getIndustryIndexCard = (
  period: string
): PromiseType<IndustryIndexResponseType[]> =>
  get(`/openfunds/industryIndex/${period}`);
export const getIndustryIndexGraph = ({
  section,
  benchmarkId,
}: {
  section: string;
  benchmarkId: string;
}): PromiseType<IndustryIndexResponseType[]> =>
  get(`/openfunds/industryIndexTrend/${section}/${benchmarkId}`);

// 自选分类
export const getCategories = (name: string): PromiseType<string[]> =>
  get("/optional/categories", { name }, { mocker: true });

// 资讯查询
export const getSearch = (data: {
  keyWord: string;
  section: string;
}): PromiseType => get("/openfunds/information", data);

// 优选基金-推送日期
export const getOptimizationFundDate = () => get("/preferred/fund/date");

// 优选基金
export const getOptimizationFund = (
  date: string | any
): PromiseType<string[]> => post("/preferred/fund/filter", { date });
