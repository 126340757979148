import { useFormatMessage } from "@/util/formatMessage";
import { Tabs } from "antd";
import React from "react";
import WarehouseRecord from "./wareHouseRecord/index";
import style from "./index.module.less";
import AssetInformation from "./assetInformation";

const { TabPane } = Tabs;

export default React.memo(
  ({
    customerId,
    totalAccountId,
    customerName,
  }: {
    customerId: string;
    totalAccountId: string;
    customerName: string;
  }) => {
    const formatMessage = useFormatMessage();

    return (
      <div className={style.WareHouseRecord}>
        <Tabs defaultActiveKey="assetInformation" className={style.Tabs}>
          <TabPane
            tab={formatMessage("assetInformation")}
            key="assetInformation"
            className={style.TabPane}
          >
            <AssetInformation
              customerId={customerId}
              totalAccountId={totalAccountId}
              customerName={customerName}
            />
          </TabPane>
          <TabPane
            tab={formatMessage("warehouseRecord")}
            key="positionStructure"
            className={style.TabPane}
          >
            <WarehouseRecord
              customerId={customerId}
              totalAccountId={totalAccountId}
              customerName={customerName}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
);
