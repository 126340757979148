import { DatePicker, DatePickerProps } from "antd";
import React, { useCallback, useMemo } from "react";
// import moment from "moment";
import dayjs from "dayjs";
import { dateProcess } from "@/util/processedDates";
import { prop } from "lodash/fp";
import { useAppSelector } from "@/hooks/redux";
import { DATEFORMAT } from "@/util/dateFormat";
import { dataSourceTimeSelector } from "@/selectors/dataSource";

export default React.memo<
  Omit<DatePickerProps, "value" | "disabledDate" | "onChange"> & {
    value?: string | null | undefined;
    disabledDate?: (date: string) => boolean;
    onChange?: (date: string) => any;
  }
>((props) => {
  const processedTradingDates = useAppSelector(
    prop("tradingDates.processedTradingDates")
  );
  const disabledDate = useMemo(() => props.disabledDate, [props.disabledDate]);
  const onChange = useMemo(() => props.onChange, [props.onChange]);
  const dataSourceTime = useAppSelector(dataSourceTimeSelector);
  const setDisabledDate = useCallback(
    (currentDate: DatePickerProps["value"]) => {
      if (!currentDate) return false;
      const formatDate = currentDate.format(DATEFORMAT);
      const isTradingDate = dateProcess(formatDate, processedTradingDates);
      return (
        (disabledDate && disabledDate(formatDate)) ||
        !isTradingDate ||
        formatDate > dataSourceTime
      );
    },
    [dataSourceTime, disabledDate, processedTradingDates]
  );
  const handleOnChange = useCallback(
    (value: DatePickerProps["value"], dateString: string) =>
      onChange && onChange(dateString),
    [onChange]
  );
  const formatValue = useMemo(
    () => (props.value ? dayjs(props.value) : null),
    [props.value]
  );
  return (
    <DatePicker
      {...props}
      value={formatValue}
      onChange={handleOnChange}
      disabledDate={setDisabledDate}
    />
  );
});
