import ColorNumber from "@/components/colorNumber";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { dateFormat } from "@/util/dateFormat";
import { FormatMessageFunc } from "@/util/formatMessage";
import {
  fixedNumber,
  fixedHundredMillion,
  formatPercentage,
  addZeroIndex,
  toFixedNumber,
  formatNil,
} from "@/util/numberFormatter";
import { fastProp } from "@/util/opt";
import { getYearByStartAndEnd } from "@/util/business-core/tradingDate";
import { TableProps as AntdTableProps, Tooltip } from "antd";
import { prop } from "lodash/fp";

export type companyRanksItem = {
  id: string;
  messageKey: string;
  scale: string;
  rank: string;
  format?: (v: number) => string;
  unitKey?: string;
};

export const companyRanks: companyRanksItem[] = [
  {
    id: "companyScale",
    messageKey: "totalScale",
    scale: "companyScale",
    rank: "scaleRank",
    format: fixedNumber,
  },
  {
    id: "unCurrentScale",
    messageKey: "unCurrentScale",
    scale: "unCurrentScale",
    rank: "unCurrentRank",
    format: fixedNumber,
  },
  {
    id: "manageFundCount",
    messageKey: "numberOfFundManagers",
    scale: "manageFundCount",
    rank: "manageFundCountRank",
    unitKey: "zhi",
  },
  {
    id: "managerCount",
    messageKey: "numberOfFundManagerCount",
    scale: "managerCount",
    rank: "managerCountRank",
    unitKey: "ge",
  },
];

type TableProps = AntdTableProps<any>;
export const getScaleTrendColumns = (
  formatMessage: FormatMessageFunc
): TableProps["columns"] => [
  {
    key: "date",
    dataIndex: "date",
    title: formatMessage("DATE"),
    width: 80,
    render: (value: string) => {
      return (
        <Tooltip
          title={`${dateFormat(value, "YYYY")}${formatMessage("year")}`}
        >{`${dateFormat(value, "YYYY")}${formatMessage("year")}`}</Tooltip>
      );
    },
    ellipsis: true,
  },
  {
    key: "scale",
    dataIndex: "scale",
    title: `${formatMessage("totalScale")}(${formatMessage("million")})`,
    align: "right",
    render: fixedHundredMillion,
    ellipsis: true,
  },
  {
    key: "scaleChange",
    dataIndex: "scaleChange",
    title: `${formatMessage("TrendChange")}(${formatMessage("million")})`,
    align: "right",
    render: fixedHundredMillion,
    ellipsis: true,
  },
  {
    key: "rank",
    dataIndex: "rank",
    title: formatMessage("scaleRank"),
    align: "right",
    ellipsis: true,
  },
  {
    key: "manageFundCount",
    dataIndex: "manageFundCount",
    title: formatMessage("fundNumberTag"),
    align: "right",
    ellipsis: true,
  },
];

export const getRepresentativeFundColumns = (
  formatMessage: FormatMessageFunc
): TableProps["columns"] => [
  {
    key: "fundId",
    dataIndex: "fundId",
    title: formatMessage("fundName"),
    render: (value, record) => (
      <ToFundDetailPage name={fastProp("name")(record)} id={value} />
    ),
    ellipsis: true,
  },
  {
    key: "fundType",
    dataIndex: "fundType",
    title: formatMessage("fundType"),
    ellipsis: true,
  },
  {
    key: "latestReturn",
    dataIndex: "latestReturn",
    title: formatMessage("dailyReturn"),
    render: (value) => <ColorNumber value={value} formatter="percentage" />,
    ellipsis: true,
  },
  {
    key: "latestNetValue",
    dataIndex: "latestNetValue",
    title: formatMessage("LatestNetValue"),
    render: (value, record) =>
      `${toFixedNumber(3)(value)}(${fastProp("endDate")(record)})`,
    ellipsis: true,
  },
  {
    key: "historyReturn",
    dataIndex: "historyReturn",
    title: `${formatMessage("Yield")}(${formatMessage("FROM_CREATION")})`,
    render: (value) => <ColorNumber value={value} formatter="percentage" />,
    align: "right",
    ellipsis: true,
  },
  {
    key: "foundDate",
    dataIndex: "foundDate",
    title: formatMessage("foundTime"),
    render: (value, record) =>
      `${getYearByStartAndEnd(value, record?.endDate)}${formatMessage("year")}`,
    align: "right",
    ellipsis: true,
  },
];

export const STOCK_FUND = "STOCK_FUND";
export const BOND_FUND = "BOND_FUND";
export const HYBRID_FUND = "HYBRID_FUND";

export type fundTypeItem = {
  id: string;
  messageKey: string;
};
export const fundTypeOptions: fundTypeItem[] = [
  {
    id: STOCK_FUND,
    messageKey: "STOCK_FUND",
  },
  {
    id: BOND_FUND,
    messageKey: "BOND_FUND",
  },
  {
    id: HYBRID_FUND,
    messageKey: "HYBRID_FUND",
  },
];

export const getManageFundColumns = (
  formatMessage: FormatMessageFunc,
  section: string
): TableProps["columns"] => [
  {
    key: "index",
    dataIndex: "index",
    title: formatMessage("serialNumber"),
    render: (_, record, index) => addZeroIndex(index + 1),
    align: "center",
    width: 60,
    ellipsis: true,
  },
  {
    key: "code",
    dataIndex: "code",
    title: formatMessage("fundCode"),
    render: (value, record) => (
      <ToFundDetailPage name={value} id={record.fundId} />
    ),
    ellipsis: true,
  },
  {
    key: "name",
    dataIndex: "name",
    title: formatMessage("fundAbbreviation"),
    render: (value, record) => (
      <ToFundDetailPage name={value} id={record.fundId} />
    ),
    ellipsis: true,
  },
  {
    key: "fundType",
    dataIndex: "fundType",
    title: formatMessage("fundType"),
    ellipsis: true,
  },
  {
    key: section,
    dataIndex: section,
    title: formatMessage(section),
    render: (_, record) =>
      formatPercentage(prop(["factorRankView", "value"])(record), { digit: 2 }),
    align: "center",
    ellipsis: true,
  },
  {
    key: "scaleRank",
    dataIndex: "scaleRank",
    title: formatMessage("similarRankings"),
    render: (_, record) => {
      const { rank, size } = fastProp("factorRankView")(record) || {};
      return `${formatNil(rank)} / ${formatNil(size)}`;
    },
    align: "center",
    ellipsis: true,
  },
];
