import { RootState } from "@/store";
import { fastProp, normalize } from "@/util/opt";
import { treeToMap } from "@/util/opt/tree";
import { industryId } from "@/views/preferredFund/constant";
import { createSelector } from "@reduxjs/toolkit";
import { filter, first, flow, map, find } from "lodash/fp";

export const sectorCategoriesSelector = createSelector(
  (state: RootState) => state.entities.sectorCategories,
  (sectorCategories) => sectorCategories
);
export const sectorCategoriesMapSelector = createSelector(
  sectorCategoriesSelector,
  (sectorCategories) =>
    treeToMap({
      needPath: true,
      childrenKey: "sectors",
    })(sectorCategories)
);

//申万行业2021版分类
export const industryDataSelector = createSelector(
  sectorCategoriesSelector,
  (categories) => {
    return flow(
      filter((item: Record<string, any>) => item.id === industryId),
      first,
      fastProp("sectors"),
      map((v: Record<string, any>) => ({
        id: v.id,
        name: v.name,
        code: v.code,
      }))
    )(categories);
  }
);

export const industryDataCodeAsKeyMapSelector = createSelector(
  industryDataSelector,

  (data) => normalize("code")(data) as Record<string, any>
);

export const industryDataIdAsKeyMapSelector = createSelector(
  industryDataSelector,

  (data) => normalize("id")(data) as Record<string, any>
);

export const defaultSectorCategorySelector = createSelector(
  sectorCategoriesSelector,
  find((item: Record<string, any>) => item.id === industryId)
);
