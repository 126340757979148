import { keys, size } from "lodash/fp";
import React, { useEffect, useRef, useState } from "react";
import { useCreation, useSize } from "ahooks";
import { Table, Timeline } from "antd";
import cn from "classnames";
import style from "./index.module.less";
import { fastHas, fastProp, mapIndexed } from "@/util/opt";
import { useFormatMessage } from "@/util/formatMessage";
import { isEmpty } from "lodash/fp";
import EmptyContent from "@/components/emptyContent";
import { getTurnRecordColumns } from "../../addCustomer/constant";
import { CustomerTurnoverRecordItem } from "@/model/customer";
import { useAppSelector } from "@/hooks/redux";
import { fundCodeMapSelector } from "@/selectors/fund";
import { allStocksMapSelector } from "@/selectors/stocks";

export default React.memo<{
  data: Record<string, CustomerTurnoverRecordItem[]>;
  activeDate: string;
  setDate: (value: string) => void;
}>(({ data, activeDate, setDate }) => {
  const [activeAsset, setActiveAsset] = useState<CustomerTurnoverRecordItem>();

  const formatMessage = useFormatMessage();
  const dates = useCreation<string[]>(() => keys(data) as string[], [data]);
  const tableRef = useRef(null);
  const tableSize = useSize(tableRef);
  const fundMap = useAppSelector(fundCodeMapSelector);
  const stockMap = useAppSelector(allStocksMapSelector);
  const columns = getTurnRecordColumns(formatMessage, fundMap, stockMap);
  const timeLineStyle = useCreation(
    () => ({
      height: (tableSize?.height || 45) - 45,
    }),
    [tableSize?.height]
  );

  useEffect(() => {
    const currentData = fastProp(activeDate)(data);
    const dateSize = size(dates);
    const currentDatasSize = size(currentData);
    const maxSize = dateSize > currentDatasSize ? dateSize : currentDatasSize;

    if (currentData) {
      setActiveAsset(
        currentDatasSize > 6
          ? currentData
          : currentData?.concat(
              mapIndexed((_: any, index: number) => ({ id: index }))(
                new Array(
                  maxSize - currentDatasSize > 4
                    ? 4
                    : maxSize - currentDatasSize
                )
              )
            )
      );
    }
  }, [activeDate, data, dates]);

  return isEmpty(data) ? (
    <EmptyContent message="暂无数据" />
  ) : (
    <div className={style.DisplayContainer}>
      <div className={style.DisplayTime}>
        <h3 className={style.DisplayTimeTitle}>
          {formatMessage("openPosition")} / {formatMessage("theWarehouse")}
        </h3>
        <Timeline className={style.DisplayTimeLine} style={timeLineStyle}>
          {mapIndexed((date: string, index: number) => (
            <Timeline.Item
              key={date}
              className={cn({
                [style.FirstAsset]: index === 0,
                [style.ActiveAsset]: date === activeDate,
                [style.NotActiveAsset]: activeDate !== date && index !== 0,
              })}
            >
              <span onClick={() => setDate(date)}>{date}</span>
            </Timeline.Item>
          ))(dates)}
        </Timeline>
      </div>
      <div className={style.DisplayTable}>
        {!isEmpty(activeAsset) && (
          <Table
            className={cn(style.FundWeightTable)}
            rowKey="fundId"
            ref={tableRef}
            columns={columns as any}
            dataSource={activeAsset as any}
            pagination={false}
            scroll={size(activeAsset) > 5 ? { y: 200 } : undefined}
            rowClassName={(record: any) =>
              !fastHas("accountName")(record) ? style.NoDataRow : ""
            }
          ></Table>
        )}
      </div>
    </div>
  );
});
