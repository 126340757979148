import { Tag, Tooltip } from "antd";
import { take, size } from "lodash/fp";
import React, { useMemo } from "react";
import { mapIndexed } from "@/util/opt";

const ShowTagList = ({ tagList }: { tagList: string[] }): JSX.Element => {
  const showTagList = useMemo(() => {
    return take(3)(tagList);
  }, [tagList]);
  return (
    <>
      <TagList list={showTagList} />
      {size(tagList) > 3 && (
        <Tooltip title={<TagList list={tagList} />}>
          <span>...</span>
        </Tooltip>
      )}
    </>
  );
};

export default React.memo(ShowTagList);

const TagList = ({ list }: { list: string[] }): JSX.Element => {
  return (
    <>
      {mapIndexed((v: string, index: number) => {
        return (
          <Tag color="processing" key={index}>
            {v}
          </Tag>
        );
      })(list)}
    </>
  );
};
