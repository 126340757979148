import { useFormatMessage } from "@/util/formatMessage";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import style from "./index.module.less";
import { manageRangeOptions } from "../constant";
import YieldStatisticRangePicker from "@/components/yieldStatisticRangePicker";
import BarChart from "@/echarts/barChart";
import { useAppDispatch } from "@/hooks/redux";
import { fetchGetCompanyScaleTrend } from "@/store/fundCompanyDetail";
import { useScaleTrendChart } from "../hook";
import LoadingComponent from "@/components/LoadingComponent";
import { COMMON_TIME } from "@/constant/statisticRange";

type manageFundScaleProps = {
  className?: string;
  companyIds: string[];
};

export default React.memo<manageFundScaleProps>(({ className, companyIds }) => {
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();
  const [activeRange, setActiveRange] = useState(COMMON_TIME);

  useEffect(() => {
    companyIds.map((v: string) => dispatch(fetchGetCompanyScaleTrend(v)));
  }, [companyIds, dispatch]);
  const chartDataSource = useScaleTrendChart(companyIds, activeRange);
  return (
    <LoadingComponent actions={fetchGetCompanyScaleTrend}>
      <Card
        bordered={false}
        title={formatMessage("manageFundScaleChange")}
        className={cn(className, style.CardHeadBorder)}
      >
        <div className={style.IncomeRange}>
          <YieldStatisticRangePicker
            value={activeRange}
            options={manageRangeOptions}
            onChange={(e: any) => setActiveRange(e)}
          />
        </div>
        <div className={style.BarChart}>
          <span className={style.BarChartUnitTip}>{`（${formatMessage(
            "million"
          )}）`}</span>
          <BarChart
            showDataZoom
            height={360}
            options={chartDataSource as any}
          />
        </div>
      </Card>
    </LoadingComponent>
  );
});
