import {
  compact,
  first,
  flatten,
  flow,
  groupBy,
  isEmpty,
  map,
  mapValues,
  omit,
  pick,
  prop,
  reject,
  sumBy,
  uniq,
  values,
} from "lodash/fp";
import {
  fastNth,
  fastProp,
  floatEqual,
  mapIndexed,
  normalize,
} from "@/util/opt";
import { FormatMessageFunc } from "@/util/formatMessage";

export const MAX_TOP_FUNDS = 6;
export const getFundWeightsMap = (
  dates: string[],
  fundWeights: any[],
  topFunds: string[],
  {
    id,
    weights,
  }: {
    id: string;
    weights: string;
  }
) =>
  flow(
    mapIndexed((values: any, index: number) => {
      const fundIds = fastProp(id)(values);
      const weight = fastProp(weights)(values);
      return mapIndexed((v: string, idx: number) => ({
        name: v,
        weight: fastNth(idx)(weight),
        date: fastNth(index)(dates),
      }))(fundIds);
    }),
    flatten,
    groupBy("date"),
    mapValues((fundWeights) => {
      const weightsMap = flow(
        normalize("name"),
        mapValues((v: any) => v.weight)
      )(fundWeights);
      const data = pick(topFunds)(weightsMap);
      const other = omit(topFunds)(weightsMap);
      return {
        ...data,
        other: isEmpty(other) ? undefined : other,
      };
    })
  )(fundWeights);

export const chartColors = [
  "#CF5E3F",
  "#E28709",
  "#CF3F3F",
  "#0052BF",
  "#0D77FD",
  "#00A9BF",
  "#633EB4",
];

export const judgeOtherDataEmpty = (data: Record<string, number>): boolean =>
  flow(values, reject(floatEqual(0)), compact, isEmpty)(data);

export const CASH = "cash";

export const getCategoryType = (
  id: string,
  categoryTreeMap: Record<string, any>,
  formatMessage: FormatMessageFunc
) => {
  return id === CASH
    ? formatMessage(id)
    : prop(`${first(prop(`${id}.path`)(categoryTreeMap))}.name`)(
        categoryTreeMap
      );
};

export type Level = {
  parentType: string;
  type: string;
  [propsName: string]: any;
};
export const getTableData = (
  level: Level[],
  formatMessage: FormatMessageFunc
) => {
  const firstLevel = uniq(map(fastProp("parentType"))(level));
  const levelGroup = groupBy("parentType")(level);
  return map((v: string) => {
    const children = fastProp(v)(levelGroup);
    return {
      children: v === formatMessage(CASH) ? null : children,
      weight: sumBy("weight")(children),
      key: v,
      type: v,
      currentRisk: sumBy("currentRisk")(children),
      stressScenariosRisk: sumBy("stressScenariosRisk")(children),
    };
  })(firstLevel);
};
