import { useContext } from "react";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { RequiredJumpProps } from "./interface";
import cn from "classnames";
import style from "./index.module.less";
import { useMemoizedFn } from "ahooks";
import { identity } from "lodash/fp";
import BaseNavigate from "./baseNavigate";

const ToFundDetailPage = ({
  name,
  id,
  className,
  beforeJump = identity,
}: RequiredJumpProps): JSX.Element => {
  const { goToFundDetailPage } = useContext(PlatformNavigationContext);
  const navigateTo = useMemoizedFn(() => {
    beforeJump();
    if (id) goToFundDetailPage({ id });
  });
  return id ? (
    <BaseNavigate name={name} navigateTo={navigateTo} className={className} />
  ) : (
    <p className={cn(className, style.InlineBlockText)}>{name}</p>
  );
};

export default ToFundDetailPage;
