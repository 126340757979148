import dayjs, { Dayjs } from "dayjs";

export const DATEFORMAT = "YYYY-MM-DD";
export const TIME_FORMAT_DATE = "YYYY-MM-DD HH:mm:ss";

export const dateFormat = (time?: string | Dayjs, formatter = DATEFORMAT) =>
  dayjs(time).format(formatter);

export const timeFormat = (time = dayjs(), formatter = TIME_FORMAT_DATE) =>
  dayjs(time).format(formatter);

export const dateTaskTime = (time = dayjs(), formatter = TIME_FORMAT_DATE) =>
  (time && dayjs(time).format(formatter)) || "--";

export const getTime = (date: string) => new Date(date).getTime();

export const getYearAndDay = (time?: string | Dayjs) => {
  const years = dayjs().diff(time, "years");
  if (years < 1) {
    return `${dayjs().diff(time, "days")}天`;
  }
  const startYear = dayjs().startOf("year");
  const days = dayjs().diff(startYear, "days");
  return `${years}年${days}天`;
};
