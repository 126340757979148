import React, { useContext, useEffect } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import IncomeStatistics from "./components/IncomeStatistics";
import EquityBacktest from "./components/equityBacktest";
import RangeReturn from "./components/rangeReturn";
import IncomeDistribution from "./components/incomeDistribution";
import { useSelector } from "react-redux";
import { fetchPortfolioSummary } from "@/store/portfolioAnalysis";
import { updatePortfolioPreviewInfo } from "@/store/portfolioAnalysis";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import { portfolioAnalysisSelector } from "../selectors";
import { RootState } from "@/store";
import { createSelector } from "@reduxjs/toolkit";
import { useMemoizedFn } from "ahooks";
import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import performancePreview from "@/assets/portfolioAnalysisIcons/performancePreview.png";
import { CSI300_ID } from "@/views/compareManage/constant";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import LoadingComponent from "@/components/LoadingComponent";

const loadingActions = [
  fetchPortfolioSummary,
  fetchFundAndBenchmarkDailyReturns,
];

const needTime = 2;
const baseSelector = createSelector(
  portfolioAnalysisSelector,
  (portfolioAnalysis) => {
    const { selectedIncomeType, activeRange, legendIds, netRange, frequency } =
      portfolioAnalysis?.portfolioPreview || {};
    return {
      selectedIncomeType: selectedIncomeType || "cumulativeIncome",
      activeRange: activeRange || "FROM_CREATION",
      legendIds: legendIds || CSI300_ID,
      netRange: netRange || "FROM_CREATION",
      frequency: frequency || "RECENT_MONTH",
    };
  }
);

export default React.memo<{ id: string }>(({ id }) => {
  const formatMessage = useFormatMessage();
  const { dispatch } = useContext(PortfolioAnalysisContext);
  const updateHandler = useMemoizedFn(
    (key: string) => (value: any) =>
      dispatch(
        needTime,
        updatePortfolioPreviewInfo({
          id,
          key,
          value,
        })
      )
  );

  const { activeRange, legendIds, netRange, frequency } = useSelector(
    (state: RootState) => baseSelector(state, id)
  );

  useEffect(() => {
    if (legendIds) {
      dispatch(needTime, fetchFundAndBenchmarkDailyReturns(legendIds));
    }
  }, [legendIds, dispatch]);

  const tip: CardLayoutTipType = [
    [formatMessage("incomeStatistics"), formatMessage("incomeStatisticsTip")],
    [formatMessage("netWorthRetreat"), formatMessage("netWorthRetreatTip")],
    [formatMessage("rangeReturns"), formatMessage("rangeReturnsTip")],
    [
      formatMessage("incomeDistribution"),
      formatMessage("incomeDistributionTip"),
    ],
    [
      formatMessage("statisticalnterval"),
      formatMessage("statisticalntervalTip"),
    ],
  ];

  return (
    <LoadingComponent actions={loadingActions}>
      <CardLayout
        title={formatMessage("performancePreview")}
        icon={performancePreview}
        tip={tip}
      >
        <IncomeStatistics
          id={id}
          activeRange={activeRange}
          legendIds={legendIds}
          onChange={updateHandler}
        />
        <EquityBacktest id={id} netRange={netRange} onChange={updateHandler} />
        <RangeReturn id={id} frequency={frequency} onChange={updateHandler} />
        <IncomeDistribution id={id} />
      </CardLayout>
    </LoadingComponent>
  );
});
