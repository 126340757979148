import { FROM_CREATION, statisticRange } from "@/constant/statisticRange";
import { AssetAllocateParams } from "@/model/portfolioAnalysis";
import { dateFormat } from "@/util/dateFormat";
import getMessage from "@/util/getMessage";
import { fastProp } from "@/util/opt";
import { dateProcess, getTradingDate } from "@/util/processedDates";
import dayjs from "dayjs";
import {
  filter,
  flow,
  invert,
  map,
  maxBy,
  minBy,
  slice,
  some,
} from "lodash/fp";
import {
  FundConfigurationAsset,
  ErrorField,
  FundConfigurationForm,
  ModelType,
} from "./interface";

export const fundConfigurationSteps = {
  fundAllocation: 0,
  backTestingAllocation: 1,
  savePortfolio: 2,
  steps: [
    {
      message: getMessage("allocationFund"),
    },
    {
      message: getMessage("allocationbackTest"),
    },
    {
      message: getMessage("savePortfolio"),
    },
  ],
};

export const modelsConfig = {
  equalWeight: "equalWeight",
  marketWeight: "marketWeight",
  maximizationSharpe: "maximizationSharpe",
  minimizationRisk: "minimizationRisk",
  maximizationOfExpected: "maximizationOfExpected",
  // 优化权重
  optimizeWeight: "optimizeWeight",
  getModels() {
    return [
      {
        id: this.equalWeight,
        message: "等权重",
      },
      {
        id: this.marketWeight,
        message: "市值权重",
      },
      {
        id: this.maximizationSharpe,
        message: "夏普比最大化",
      },
      {
        id: this.minimizationRisk,
        message: "风险最小化",
      },
      {
        id: this.maximizationOfExpected,
        message: "预期收益最大化",
      },
    ];
  },
};

export const goalConfig = {
  [modelsConfig.maximizationSharpe]: "MAX_SHARP_RATIO",
  [modelsConfig.minimizationRisk]: "MIN_RISK",
  [modelsConfig.maximizationOfExpected]: "MAX_RETURN",
};
export const invertGoalConfig = invert(goalConfig);
export const riskFreeRateConfig = {
  specificRiskFree: "specificRiskFree",
  defaultRiskFree: "defaultRiskFree",
  defaultRiskFreeConfig() {
    return {
      type: this.defaultRiskFree,
      riskFreeRate: undefined,
    };
  },
};
export const rebalanceConfig = {
  onlyOnce: "onlyOnce",
  fixInterval: "fixInterval",
  fixDates: "fixDates",
  getRebalanceGroup() {
    return [
      {
        id: this.onlyOnce,
        message: "买入持有",
      },
      {
        id: this.fixInterval,
        message: "定期再平衡",
      },
      {
        id: this.fixDates,
        message: "指定日期",
      },
    ];
  },
  getRebalanceGroupByNatureDateCount(natureDateCount: number | null) {
    const rebalanceGroups = this.getRebalanceGroup();
    if (natureDateCount && natureDateCount < 30) {
      return filter(
        ({ id }) =>
          id === rebalanceConfig.onlyOnce || id === rebalanceConfig.fixDates
      )(rebalanceGroups);
    }
    return rebalanceGroups;
  },
};

export const periodFrequencyConfig = {
  MONTH: "MONTH",
  QUARTER: "QUARTER",
  HALF_YEAR: "HALF_YEAR",
  YEAR: "YEAR",
  getFrequencyGroup() {
    return [
      {
        id: this.MONTH,
        message: "每月",
      },
      {
        id: this.QUARTER,
        message: "每季度",
      },
      {
        id: this.HALF_YEAR,
        message: "每半年",
      },
      {
        id: this.YEAR,
        message: "每年",
      },
    ];
  },
  getFrequencyGroupByNatureDateCount(natureDateCount: number | null) {
    const frequencyGroups = this.getFrequencyGroup();
    if (!natureDateCount) return frequencyGroups;
    if (natureDateCount < 30) return [];
    if (natureDateCount < 90) {
      return slice(0, 1)(frequencyGroups);
    }
    if (natureDateCount < 180) {
      return slice(0, 2)(frequencyGroups);
    }
    if (natureDateCount < 365) {
      return slice(0, 3)(frequencyGroups);
    }
    return frequencyGroups;
  },
};

export const balanceRuleConfig = {
  INIT_WEIGHT: "INIT_WEIGHT",
  INVEST_OBJ: "INVEST_OBJ",
  getBalanceRule() {
    return [
      {
        id: this.INIT_WEIGHT,
        message: "回归初始权重",
      },
      {
        id: this.INVEST_OBJ,
        message: "回归投资目标",
      },
    ];
  },
  getBalanceRuleByModelType(modelType: string | undefined) {
    const balanceRules = this.getBalanceRule();
    if (modelType === modelsConfig.equalWeight) {
      return filter(({ id }) => id === this.INIT_WEIGHT)(balanceRules);
    }
    return balanceRules;
  },
};

export const getDefaultErrorField = (): ErrorField => ({
  fundMinWeightError: false,
  fundMaxWeightError: false,
  fundLimitGreaterLowerErr: false,
  fundEmptyError: true,
  fundLess3MothError: null,
  latestNetUnitValueZeroError: false,
  fundIntersectionDateError: false,
  netValueStartDateBiggerThanEndDate: false,
  netValueStartDateZeroError: false,
  riskValueError: false,
  modelTypeError: true,
  rebalanceTypeError: false,
  foundDateEmptyError: true,
});

export const checkSomeFundRunTimeLess3Month = (
  assets: FundConfigurationAsset[]
): string | null => {
  let errorFund = null;
  some<FundConfigurationAsset>(({ tradingDay, netValueStartDate, fundId }) => {
    if (
      (!netValueStartDate && !tradingDay) ||
      dayjs(netValueStartDate).add(90, "day").isSameOrAfter(tradingDay)
    ) {
      errorFund = fundId;
      return true;
    }
    return false;
  })(assets);
  return errorFund;
};

export const getFundConfigurationFormData = (): FundConfigurationForm => ({
  modelType: undefined,
  riskFreeConfig:
    riskFreeRateConfig.defaultRiskFreeConfig() as FundConfigurationForm["riskFreeConfig"],
  annualizedMinReturn: null,
  riskValue: null,
  dataRange: FROM_CREATION,
  foundDate: "",
  rebalanceType: "",
  periodicFrequency: periodFrequencyConfig.MONTH,
  specifiedDates: [],
  turnoverRule: "",
  netValueStart: "",
});

export const getAssetInterSectionDate = (
  assets: FundConfigurationAsset[]
): [string, string] => {
  const startDate = flow(
    maxBy<FundConfigurationAsset>("netValueStartDate"),
    fastProp("netValueStartDate")
  )(assets);
  const endDate = flow(
    minBy<FundConfigurationAsset>("tradingDay"),
    fastProp("tradingDay")
  )(assets);
  return [startDate || "", endDate || ""];
};

/**
 * 基金配置和自上而下对于选择了模型时长的净值开始日期的计算
 * 特殊处理了一下成立至今的选项，逻辑和近三月一样需要加上90个自然日来判断
 */
export const getAddRangeDate =
  (tradingDateList: string[], processedTradingDate: Record<string, any>) =>
  (dateRange: string, netValueStartDate: string) => {
    if (dateRange === FROM_CREATION) {
      // 成立日往后90个自然日如果是交易日就是那天不是就往后取一个交易日
      const threeMonthLaterDate = dateFormat(
        dayjs(netValueStartDate).add(3, "month")
      );
      return getTradingDate(
        tradingDateList,
        processedTradingDate,
        threeMonthLaterDate
      );
    }
    const { period, count } = statisticRange[dateRange];
    const startDateAddRange = dateFormat(
      dayjs(netValueStartDate).add(
        period as number,
        count as dayjs.ManipulateType
      )
    );
    return getTradingDate(
      tradingDateList,
      processedTradingDate,
      startDateAddRange
    );
  };

export const validateErrorField = (errorFiled: ErrorField) =>
  some((error) => !!error)(errorFiled);

export const serializeAllocateDataHelper = {
  getFrequency(formData: FundConfigurationForm) {
    switch (formData.rebalanceType) {
      case rebalanceConfig.fixDates:
        return {
          type: formData.rebalanceType,
          dates: formData.specifiedDates,
        };
      case rebalanceConfig.fixInterval:
        return {
          type: formData.rebalanceType,
          interval: formData.periodicFrequency,
        };
      default:
        return {
          type: formData.rebalanceType,
        };
    }
  },
  getConstrains(assets: FundConfigurationAsset[]) {
    return map(({ fundId, maxWeight, minWeight }: FundConfigurationAsset) => ({
      id: fundId,
      maxWeight,
      minWeight,
    }))(assets);
  },
  getAllocateConfigByModelType(
    assets: FundConfigurationAsset[],
    formData: FundConfigurationForm
  ) {
    const allocateConfig = {
      configType: formData.modelType as string,
      constrains: this.getConstrains(assets),
    };
    switch (formData.modelType) {
      case modelsConfig.maximizationSharpe:
        return {
          ...allocateConfig,
          configType: modelsConfig.optimizeWeight,
          goal: goalConfig[formData.modelType],
          dataRange: formData.dataRange,
          goalConfig: {
            goalType: "maxShareRatio",
            riskFreeConfig: formData.riskFreeConfig,
          },
        };
      case modelsConfig.minimizationRisk:
        return {
          ...allocateConfig,
          configType: modelsConfig.optimizeWeight,
          goal: goalConfig[formData.modelType],
          dataRange: formData.dataRange,
          goalConfig: {
            goalType: "minRisk",
            annualizedMinReturn: formData.annualizedMinReturn,
          },
        };
      case modelsConfig.maximizationOfExpected:
        return {
          ...allocateConfig,
          configType: modelsConfig.optimizeWeight,
          goal: goalConfig[formData.modelType],
          dataRange: formData.dataRange,
          goalConfig: {
            goalType: "maxReturn",
            annualizedMaxRisk: formData.riskValue as number,
          },
        };
      default:
        return allocateConfig;
    }
  },
  serializeData(
    assets: FundConfigurationAsset[],
    formData: FundConfigurationForm
  ): AssetAllocateParams {
    return {
      turnoverConfig: {
        foundDate: formData.foundDate,
        turnoverRule: formData.turnoverRule,
        frequency: this.getFrequency(formData),
      },
      allocateConfig: this.getAllocateConfigByModelType(assets, formData),
      type: "asset",
    };
  },
};

export const getNetValueStartDate = ({
  startDate,
  dataRange,
  modelType,
  tradingDateList,
  processedTradingDates,
}: {
  startDate: string;
  dataRange: string;
  modelType?: ModelType;
  tradingDateList: string[];
  processedTradingDates: Record<string, any>;
}) => {
  if (!modelType) return null;
  let navStart = getTradingDate(
    tradingDateList,
    processedTradingDates,
    startDate
  );
  if (modelType !== "equalWeight") {
    navStart = getAddRangeDate(tradingDateList, processedTradingDates)(
      dataRange,
      navStart
    );
  }
  return navStart;
};

export const getAvailableDateRange = ({
  startDate,
  endDate,
  tradingDateList,
  processedTradingDates,
}: {
  startDate?: string;
  endDate?: string;
  tradingDateList: string[];
  processedTradingDates: Record<string, any>;
}) => {
  if (!startDate || !endDate) return [];
  const startIndex = dateProcess(startDate, processedTradingDates);
  const endIndex = dateProcess(endDate, processedTradingDates);
  return slice(startIndex as number, (endIndex as number) + 1)(tradingDateList);
};

export const getModels = (
  startDate: string,
  endDate: string,
  fundLess3MothError: boolean,
  processedTradingDates: Record<string, any>
) => {
  const startIndex = dateProcess(startDate, processedTradingDates);
  const endIndex = dateProcess(endDate, processedTradingDates);
  const models = modelsConfig.getModels();
  if (
    (startIndex && endIndex && endIndex - startIndex < 10) ||
    fundLess3MothError
  ) {
    return filter(
      ({ id }) =>
        id === modelsConfig.equalWeight || id === modelsConfig.marketWeight
    )(models);
  }
  return models;
};
