import React from "react";
import { Modal, Switch, Descriptions } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Competition, presentRangeText } from "@/model/competition";
import { setCompetitionValidate } from "@/store/competition";
import { useAppDispatch } from "@/hooks/redux";
import useModal from "@/hooks/useModal";

import style from "./index.module.less";

type ValidateSwitchProps = {
  data: Competition; // field
  disabled: boolean;
};

const ModalContent = React.memo<{
  data: Competition;
  closeModal: () => void;
  visible: boolean;
}>(({ data, visible, closeModal }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  return (
    <Modal
      title={formatMessage(data?.validate ? "sureDisEnable" : "sureEnable")}
      visible={visible}
      onCancel={closeModal}
      width={350}
      okText={formatMessage(
        data?.validate ? "competitionDisEnableShort" : "competitionEnableShort"
      )}
      onOk={() => {
        dispatch(
          setCompetitionValidate({
            competitionId: data?.competitionId,
            validate: !data?.validate,
          })
        ).then(() => {
          closeModal();
        });
      }}
    >
      <>
        <p className={style.SureTip}>
          <CheckCircleOutlined className={style.SureTipIcon} />
          {formatMessage(
            data?.validate ? "SureDisEnableConfirmTip" : "SureEnableConfirmTip"
          )}
        </p>
        <Descriptions column={1} size={"small"}>
          <Descriptions.Item label={formatMessage("competitionName")}>
            {data?.name}
          </Descriptions.Item>
          <Descriptions.Item label={formatMessage("competitionDisplay")}>
            {formatMessage(presentRangeText?.[data?.presentRange])}
          </Descriptions.Item>
          <Descriptions.Item label={formatMessage("startDate")}>
            {data?.startDate}
          </Descriptions.Item>
          <Descriptions.Item label={formatMessage("endDate")}>
            {data?.endDate}
          </Descriptions.Item>
        </Descriptions>
      </>
    </Modal>
  );
});

const ValidateSwitch = ({ data, disabled = false }: ValidateSwitchProps) => {
  const { visible, openModal, closeModal } = useModal();
  return (
    <>
      <Switch
        checked={data?.validate}
        onClick={openModal}
        disabled={disabled}
      />
      {visible && (
        <ModalContent data={data} visible={visible} closeModal={closeModal} />
      )}
    </>
  );
};

export default React.memo(ValidateSwitch);
