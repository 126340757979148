import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchGetRepresentativeFund } from "@/store/fundCompanyDetail";
import { useFormatMessage } from "@/util/formatMessage";
import { Card, Table } from "antd";
import React, { useEffect } from "react";
import { getRepresentativeFundColumns } from "../constant";
import style from "./index.module.less";
import { representativeFundSelector } from "../selector";
import { getTableScroll } from "@/util/tableFormat";
import { useCreation } from "ahooks";
import LoadingComponent from "@/components/LoadingComponent";

type representativeFundProps = {
  id: string;
};

export default React.memo<representativeFundProps>(({ id }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchGetRepresentativeFund(id));
  }, [dispatch, id]);
  const columns = getRepresentativeFundColumns(formatMessage);
  const representativeFund = useAppSelector((state) =>
    representativeFundSelector(state, id)
  );

  const scroll = useCreation(
    () =>
      getTableScroll({
        dataSource: representativeFund,
        lineHeight: 44,
        maxYScroll: 354,
      }),
    [representativeFund]
  );
  return (
    <LoadingComponent actions={fetchGetRepresentativeFund}>
      <Card
        size="small"
        bordered={false}
        title={formatMessage("companyRepresentativeFund")}
        className={style.CompanyCommonCard}
      >
        <Table
          className={style.TableStyle}
          columns={columns}
          dataSource={representativeFund}
          pagination={false}
          scroll={scroll}
        />
      </Card>
    </LoadingComponent>
  );
});
