import {
  ClassicFilterList,
  FundTypeTags,
  SeniorFilterList,
} from "@/model/factorFund";
import { PromiseType } from "@/model/promise";
import { get } from "@/util/axios";
import { socketHttp } from "@/util/socket";

// 查询基金类型
export const getFundTypeTags = (): PromiseType<FundTypeTags[]> =>
  get("/funds/fund_type");

// 指标选基筛选
export const getFactorFilterList = (
  filterList: ClassicFilterList | SeniorFilterList
) =>
  socketHttp("START_FUND_DETAIL_TAG_FILTER", {
    meta: filterList,
  });

// 指标选基行业分类，行业偏好
export const getIndustryPreference = (
  id: string
): PromiseType<{ [key: string]: string }> =>
  get("funds/sector_industry", { id: id });
