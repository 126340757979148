// import { LineChartOpts } from "@/echarts/lineChart";
import { toAverage, toStandardDeviation, sqrt } from "@/util/math";
import {
  map,
  keys,
  size,
  indexOf,
  filter,
  max,
  min,
  ceil,
  isNumber,
  isNaN,
  forEach,
  set,
  last,
  fromPairs,
  flow,
  values,
  findIndex,
} from "lodash/fp";
import { normalize, fastProp, mapIndexed, forEachIndexed } from "@/util/opt";
import getMessage from "@/util/getMessage";
import { formatter } from "@/constant/factorFormatter";
import {
  BYWEEK,
  BYDAY,
} from "@/views/portfolioManage/portfolioAnalysis/constant";
import moment from "moment";
import { FormatMessageFunc } from "@/util/formatMessage";
import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";
import { formatNilToZero } from "@/util/numberFormatter";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import { getTooltipItem } from "@/util/chart";

export const freQuencyOptions = {
  [portfolioAnalysisStatisticRange.RECENT_MONTH.id]: {
    ...portfolioAnalysisStatisticRange.RECENT_MONTH,
    message: "monthFrequency",
  },
  [portfolioAnalysisStatisticRange.RECENT_THREE_MONTH.id]: {
    ...portfolioAnalysisStatisticRange.RECENT_THREE_MONTH,
    message: "quarterFrequency",
  },
  [portfolioAnalysisStatisticRange.RECENT_YEAR.id]: {
    ...portfolioAnalysisStatisticRange.RECENT_YEAR,
    message: "annualFrequency",
  },
};

export type ProcessDataFunc = (values: number) => string | number;

export const getGridTopOptions = (processData: ProcessDataFunc) => ({
  grid: {
    top: 150,
  },
  tooltip: {
    formatter: (param: Record<string, any>) => {
      let showHtml = "";
      forEachIndexed((item: Record<string, any>, index: number) => {
        showHtml +=
          index === 0
            ? `<h5>
          ${item.name}
          </h5>`
            : "";
        return (showHtml += getTooltipItem(
          item.color,
          item.seriesName,
          processData(last(item.value) as number),
          10,
          5
        ));
      })(param);
      return showHtml;
    },
  },
});

// 概率密度函数
export const curveY = (
  standardDeviation: number,
  average: number,
  curvex: number
) => {
  // 除数
  const bottom = standardDeviation * sqrt(2 * Math.PI);
  const high = Math.pow(curvex - average, 2);
  const light = 2 * Math.pow(standardDeviation, 2);
  // 指数的次方
  const index = -(high / light);
  const curveY = Math.pow(Math.E, index) / bottom;
  return curveY;
};

export const getChartData = (
  dates: string[],
  data: Record<string, any>,
  groupDistance: number
) => {
  const { sumAllByPortfolio, sumAllByBenchmark } = data || {};
  const maxDailyReturn = max(sumAllByPortfolio) as number;
  const minDailyReturn = min(sumAllByPortfolio) as number;

  // 平均值
  const portfolioAverage = toAverage(sumAllByPortfolio);
  const benchmarkAverage = toAverage(sumAllByBenchmark);
  // 标准差
  const portfolioStandardDeviation = toStandardDeviation(sumAllByPortfolio);
  const benchmarkStandardDeviation = toStandardDeviation(sumAllByBenchmark);
  //组数
  const distance = ceil((maxDailyReturn - minDailyReturn) / groupDistance + 2);

  const histogramX: Record<string, any>[] = [];
  if (!isNaN(distance)) {
    mapIndexed((_: any, index: number) => {
      let distanceList: Record<string, any> = {};
      if (index === 0) {
        distanceList = {
          first: "-Infinity",
          key: "-Infinity",
          value: minDailyReturn,
          second: minDailyReturn,
        };
      }
      if (index === distance - 1) {
        distanceList = {
          first: minDailyReturn + (index - 1) * groupDistance,
          key: minDailyReturn + (index - 1) * groupDistance,
          value: "Infinity",
          second: "Infinity",
        };
      }
      if (index > 0 && index < distance - 1) {
        distanceList = {
          first: minDailyReturn + (index - 1) * groupDistance,
          key: minDailyReturn + (index - 1) * groupDistance,
          value: minDailyReturn + index * groupDistance,
          second: minDailyReturn + index * groupDistance,
        };
      }
      histogramX.push(distanceList);
    })(new Array(distance));
  }

  const histogramXMap = normalize("key")(histogramX);
  const groupKeys = keys(histogramXMap);

  // 判断sumAllByPortfolio数组里面每个值在哪个区间
  const groupSum: number[] = new Array(size(groupKeys)).fill(0);
  const groupSumByBenchmark: number[] = new Array(size(groupKeys)).fill(0);

  if (size(sumAllByPortfolio)) {
    flow(
      filter((v: number) => isNumber(v)),
      mapIndexed((sum: number) => {
        const num = ceil((sum - minDailyReturn) / groupDistance);
        groupSum[num]++;
        return groupSum;
      })
    )(sumAllByPortfolio);
  }
  if (size(sumAllByBenchmark)) {
    flow(
      filter((v: number) => isNumber(v)),
      mapIndexed((sum: number) => {
        const num = ceil((sum - minDailyReturn) / groupDistance);

        if (sum <= minDailyReturn) {
          groupSumByBenchmark[0]++;
        } else if (sum > fastProp("key")(last(histogramX))) {
          groupSumByBenchmark[size(groupKeys) - 1]++;
        } else {
          groupSumByBenchmark[num]++;
        }
        return groupSumByBenchmark;
      })
    )(sumAllByBenchmark);
  }

  const groupSumPositiveSize = size(filter((v: number) => v > 0)(groupSum));
  const groupSumByBenchmarkPositiveSize = size(
    filter((v: number) => v > 0)(groupSumByBenchmark)
  );
  const curvexList = [];
  const curveYListSize = distance * 5;

  for (let index = 0; index < curveYListSize; index++) {
    const value = minDailyReturn + (index * groupDistance) / 5;
    curvexList.push(value);
  }

  // 计算曲线 标准差，平均值，收益
  // 至少有5个有值的区间
  const curveyPortfolio =
    groupSumPositiveSize >= 5
      ? map((v: number) =>
          curveY(portfolioStandardDeviation, portfolioAverage, v)
        )(curvexList)
      : [];

  const curveyBenchmark =
    groupSumByBenchmarkPositiveSize >= 5
      ? map((v: number) =>
          curveY(benchmarkStandardDeviation, benchmarkAverage, v)
        )(curvexList)
      : [];

  return {
    groupSum,
    groupSumByBenchmark,
    histogramXMap,
    histogramX,
    curveyPortfolio,
    curveyBenchmark,
  };
};

const formaterValue = (value: any) => {
  if (value === "-Infinity") return "-∞";
  if (value === "Infinity") return "+∞";
  return formatter.percent2(value);
};
export const getSummaryText = (
  dates: string[],
  data: Record<string, any>,
  groupDistance: number,
  incomeData: string,
  formatMessage: FormatMessageFunc
) => {
  const { curveyPortfolio, curveyBenchmark, groupSum, histogramX } =
    getChartData(dates, data, groupDistance);
  const maxCurveXPortfolio = max(curveyPortfolio) as number;
  const maxCurveXBenchmark = max(curveyBenchmark) as number;
  const portfolioIndex = findIndex(
    (item: number) => item === maxCurveXPortfolio
  )(curveyPortfolio);
  const benchmarkIndex = findIndex(
    (item: number) => item === maxCurveXBenchmark
  )(curveyBenchmark);

  const distributionPeak =
    portfolioIndex > benchmarkIndex ? "rightSide" : "leftSide";

  const benefitCompare =
    distributionPeak === "rightSide"
      ? getMessage("greaterThan")
      : getMessage("lessThan");

  const profitProbability =
    distributionPeak === "rightSide"
      ? getMessage("higher")
      : getMessage("lower");

  const profitablePeriod = fastProp("profitNumberByPortfolio")(data);
  const loss = fastProp("lossNumberByPortfolio")(data);
  const ability = profitablePeriod / (loss + profitablePeriod);

  const Profitability =
    ability > 0.7
      ? getMessage("higher")
      : ability < 0.3
      ? getMessage("lower")
      : getMessage("moderate");
  const maxWeekIncomeSize = indexOf(max(groupSum))(groupSum);
  const maxWeek = histogramX[maxWeekIncomeSize];
  const maxWeekEarnings = `${formaterValue(
    fastProp("first")(maxWeek)
  )} ~ ${formaterValue(fastProp("second")(maxWeek))}`;

  const summaryText =
    incomeData === BYWEEK
      ? formatMessage("portfolioAnalysisIncomeDistributionWeekSummary", {
          maxWeekEarnings: maxWeekEarnings,
          futureWeekEarnings: maxWeekEarnings,
          profitablePeriod: profitablePeriod,
          Profitability: Profitability,
        })
      : formatMessage("portfolioAnalysisIncomeDistributionDaySummary", {
          maxDayIncome: maxWeekEarnings,
          futureDayIncome: maxWeekEarnings,
        });
  const curveText =
    size(histogramX) >= 5
      ? formatMessage("curveText", {
          distributionPeak: formatMessage(distributionPeak),
          benefitCompare: benefitCompare,
          profitProbability: profitProbability,
        })
      : "";

  return `${summaryText}${curveText}`;
};

export const getSumData = (
  incomeData: string,
  dates: string[],
  dailyReturnMap: Record<string, any>,
  benchmarkReturnMap: Record<string, any>
) => {
  const weekDates = map((date: string) => moment(date).week())(dates);

  const allByPortfolio: number[][] = [];
  const allByBenchmark: number[][] = [];
  let portfolioArr: number[] = [];
  let benchmarkArr: number[] = [];
  if (size(dates) > 0) {
    if (incomeData === BYWEEK) {
      mapIndexed((date: string, index: number) => {
        // 将日期对应周数相同的存入一个数组,如果日期没有和他相同的周数，直接存入
        if (weekDates[index] === weekDates[index + 1]) {
          portfolioArr.push(dailyReturnMap[date]);
          benchmarkArr.push(benchmarkReturnMap[date]);
        } else {
          portfolioArr.push(dailyReturnMap[date]);
          benchmarkArr.push(benchmarkReturnMap[date]);
          allByPortfolio.push(portfolioArr);
          allByBenchmark.push(benchmarkArr);
          portfolioArr = [];
          benchmarkArr = [];
        }
      })(dates);
    }
  }

  const sumAllByWeekPortfolio = map((arr: number[]) => {
    return last(getCumulativeReturns(arr));
  })(allByPortfolio);
  const sumAllByPortfolio: number[] =
    incomeData === BYWEEK
      ? sumAllByWeekPortfolio
      : map((date: string) => dailyReturnMap[date])(dates);
  const sumAllByBenchmark: number[] =
    incomeData === BYWEEK
      ? map((arr: number[]) => {
          return last(getCumulativeReturns(arr));
        })(allByBenchmark)
      : flow(
          map((date: string) => benchmarkReturnMap[date]),
          filter((v) => isNumber(v))
        )(dates);

  let profitNumberByPortfolio = 0;
  let lossNumberByPortfolio = 0;
  let profitNumberByBenchmark = 0;
  let lossNumberByBenchmark = 0;
  if (incomeData === BYWEEK) {
    // 统计sumAllByPortfolio
    flow(
      filter((v) => v !== 0),
      map((v: number) => {
        v > 0 ? profitNumberByPortfolio++ : lossNumberByPortfolio++;
      })
    )(sumAllByPortfolio);
    flow(
      filter((v) => v !== 0),
      map((v: number) => {
        v > 0 ? profitNumberByBenchmark++ : lossNumberByBenchmark++;
      })
    )(sumAllByBenchmark);
  } else if (incomeData === BYDAY) {
    map((date: string) => {
      if (dailyReturnMap[date] > 0) {
        profitNumberByPortfolio++;
      } else if (dailyReturnMap[date] < 0) {
        lossNumberByPortfolio++;
      }
    })(dates);
    map((date: string) => {
      if (benchmarkReturnMap[date] > 0) {
        profitNumberByBenchmark++;
      } else if (benchmarkReturnMap[date] < 0) {
        lossNumberByBenchmark++;
      }
    })(dates);
  }
  return {
    sumAllByPortfolio,
    sumAllByBenchmark,
    profitNumberByPortfolio,
    lossNumberByPortfolio,
    profitNumberByBenchmark,
    lossNumberByBenchmark,
  };
};

const getYeildData = (
  dailyReturnMap: Record<string, any>,
  BenchmarkReturnMap: Record<string, any>,
  calculatedDates: string[],
  tradingDate: string[]
) => {
  const comReturns: number[] = [];
  const benchmarkReturns: number[] = [];
  forEach((date: string) => {
    comReturns.push(formatNilToZero(fastProp(date)(dailyReturnMap)));
    benchmarkReturns.push(formatNilToZero(fastProp(date)(BenchmarkReturnMap)));
  })(calculatedDates);
  return {
    comReturns,
    benchmarkReturns:
      size(tradingDate) === size(calculatedDates)
        ? set(0, 0)(benchmarkReturns)
        : benchmarkReturns,
  };
};

export const getRangeReturnChartAndTable = (
  dailyReturnMap: Record<string, any>,
  BenchmarkReturnMap: Record<string, any>,
  RangeList: Record<string, any>,
  formatMessage: FormatMessageFunc,
  tradingDate: string[],
  portfolioName: string
) => {
  const benchmarkData: Record<string, any> = {};
  const comData: Record<string, any> = {};
  forEach((item: any) => {
    const calculatedDates = fastProp(item)(RangeList);
    const { comReturns, benchmarkReturns } = getYeildData(
      dailyReturnMap,
      BenchmarkReturnMap,
      calculatedDates,
      tradingDate
    );
    benchmarkData[item] = flow(getCumulativeReturns, last)(benchmarkReturns);
    comData[item] = flow(getCumulativeReturns, last)(comReturns);
  })(keys(RangeList));
  const chartDatas = fromPairs([
    [portfolioName, values(comData)],
    [formatMessage("performanceBenchmark"), values(benchmarkData)],
  ]);

  const tableDatas = [
    { ...comData, returnName: portfolioName },
    { ...benchmarkData, returnName: formatMessage("performanceBenchmark") },
  ];
  return { chartDatas, tableDatas };
};
