import React, { useEffect, useMemo, useState } from "react";
import { map, last, sortBy, reverse, flow, isEmpty } from "lodash/fp";
import { Spin } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import {
  getAumIndustryDistributions,
  AumIndustryDistributionsParam,
  getAumPortfolioDates,
} from "@/api/aum";
import { IndustryDistribution, CodeWeightChange } from "@/model/aum";
import { useAppSelector } from "@/hooks/redux";
import { useRequest } from "@/hooks/request";
import {
  industryDataIdAsKeyMapSelector,
  defaultSectorCategorySelector,
} from "@/selectors/sectorCategories";
import EmptyContent from "@/components/emptyContent";
import { DistributionChart } from "@/base-components/charts";
import DisclosureDateCascader from "@/base-components/disclosureDateCascader";
import SubTitle from "../../components/subTitle";
import { CommonProps } from "../../type";
import WeightTable, { DataItem } from "./table";

import style from "../../index.module.less";

const chartHeight = 400;

const useDates = (id: string) => {
  const { data, request } = useRequest<string, string[]>(getAumPortfolioDates);
  useEffect(() => {
    id && request(id);
  }, [id, request]);
  return data;
};

const useIndustryDistributions = (
  date: string,
  id: string,
  sectorCategoryId: string
) => {
  const [result, setResult] = useState<
    Record<string, IndustryDistribution["dailyIndustryInfoList"]>
  >({});
  const { loading, request } = useRequest<
    AumIndustryDistributionsParam,
    IndustryDistribution
  >(getAumIndustryDistributions);
  const resultKey = `${id}_${date}`;
  const currentData = result?.[resultKey];
  useEffect(() => {
    if (id && sectorCategoryId && date && !currentData) {
      request({ id, sectorCategoryId, tradingDate: date }).then(
        (distribution: IndustryDistribution) => {
          setResult((data) => ({
            ...data,
            [resultKey]: distribution?.dailyIndustryInfoList,
          }));
        }
      );
    }
  }, [id, sectorCategoryId, date, setResult, request, currentData, resultKey]);

  return { distributionData: currentData, loading };
};

export default React.memo<CommonProps>(({ assetPortfolioId }) => {
  const formatMessage = useFormatMessage();
  const industryMap = useAppSelector(industryDataIdAsKeyMapSelector);
  const defaultSectorCategory = useAppSelector(defaultSectorCategorySelector);
  const [date, setDate] = useState<string>("");
  const dates = useDates(assetPortfolioId);
  const { distributionData, loading } = useIndustryDistributions(
    date,
    assetPortfolioId,
    defaultSectorCategory?.id
  );

  useEffect(() => {
    const latestDate = last(dates);
    latestDate && setDate(latestDate);
  }, [dates, setDate]);

  const currentIndustrys = useMemo(
    () =>
      map((item: CodeWeightChange) => ({
        ...item,
        name: industryMap?.[item?.code]?.name as string,
        value: item?.weight,
        changeWeight: item?.change,
      }))(distributionData),
    [industryMap, distributionData]
  );

  const tableData = useMemo(
    () => flow(sortBy<DataItem>("weight"), reverse)(currentIndustrys),
    [currentIndustrys]
  );

  return (
    <div>
      <SubTitle
        title={`${formatMessage("industryDistribution")}(${
          defaultSectorCategory?.name || ""
        })`}
        extraContent={
          <DisclosureDateCascader
            dates={dates || []}
            value={date}
            onChange={setDate}
          />
        }
      />
      <Spin spinning={loading}>
        <div className={style.ChartContainer} style={{ height: chartHeight }}>
          <div className={style.Chart}>
            {isEmpty(currentIndustrys) ? (
              <EmptyContent>
                <strong>{formatMessage("noData")}</strong>
              </EmptyContent>
            ) : (
              <DistributionChart
                datas={currentIndustrys}
                height={chartHeight}
                type="rectTree"
              />
            )}
          </div>
          <div className={style.Content}>
            <WeightTable height={chartHeight} dataSource={tableData} />
          </div>
        </div>
      </Spin>
    </div>
  );
});
