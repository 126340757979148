import tradingDateCount from "@/constant/tradingDateCount";
import { zipWithMinus } from "../opt";
import { toStandardDeviation } from "../math";
import { flow, isEmpty, toNumber } from "lodash/fp";
import { formatQuantArrayNilToZero } from "../numberFormatter";

/*
    年化主动风险
*/
export default (dailyReturns: number[], benchmarkDailyReturns: number[]) => {
  if (isEmpty(dailyReturns) || isEmpty(benchmarkDailyReturns)) return 0;
  const [formatDailyReturns, formatBenchmarkDailyReturns] =
    formatQuantArrayNilToZero(dailyReturns, benchmarkDailyReturns);
  const dailySubReturns = zipWithMinus(
    formatDailyReturns,
    formatBenchmarkDailyReturns
  );
  return flow(
    toStandardDeviation,
    toNumber,
    (res) => res * Math.sqrt(tradingDateCount)
  )(dailySubReturns);
};
