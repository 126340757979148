import { div, minus, toAverage, toStandardDeviation, pow } from "../math";
import { flow, isEmpty, size, sumBy } from "lodash/fp";
import { equalZero, formatArrayNilToZero } from "../numberFormatter";

/*
    峰度
*/
export default (dailyReturns: number[]) => {
  if (isEmpty(dailyReturns)) return 0;
  const formatDailyReturns = formatArrayNilToZero(dailyReturns);
  const average = toAverage(formatDailyReturns);
  const standardDeviation = toStandardDeviation(formatDailyReturns);
  if (equalZero(standardDeviation)) return NaN;
  return flow(
    sumBy(flow(minus(average), div(standardDeviation), pow(4))),
    div(size(dailyReturns))
  )(formatDailyReturns);
};
