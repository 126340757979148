import { div, minus, toAverage, toStandardDeviation, pow } from "../math";
import { filter, flow, isEmpty, isNumber, size, sumBy } from "lodash/fp";
import { equalZero, formatArrayNilToZero } from "../numberFormatter";

/*
    偏度
*/
export default (dailyReturns: number[]) => {
  if (isEmpty(dailyReturns)) return 0;
  const formatDailyReturns = formatArrayNilToZero(dailyReturns);
  const average = toAverage(formatDailyReturns);
  const standardDeviation = toStandardDeviation(formatDailyReturns);
  if (equalZero(standardDeviation)) return NaN;
  return flow(
    filter(isNumber),
    sumBy(flow(minus(average), div(standardDeviation), pow(3))),
    div(size(formatDailyReturns))
  )(formatDailyReturns);
};
