import { PromiseType } from "@/model/promise";
import { FundFilter } from "@/model/targetFund";
import { get } from "@/util/axios";
import { socketHttp } from "@/util/socket";

// 获取行业偏好列表
export const getIndustryList = (): PromiseType<Record<string, string>> =>
  get("/funds/industry_preference");

// 获取基金主题
export const getIndustryThemeList = (): PromiseType<Record<string, string>> =>
  get("/funds/industry_theme");

// 目标选基
export const getMainFilterList = (fundRiskLevelList: FundFilter) =>
  socketHttp("START_FUND_TARGET_FILTER_CONDITION", {
    meta: fundRiskLevelList,
  });

//
export const getFundsTags = (): PromiseType<string[]> => get("/funds/tags");
