import { createSelector } from "@reduxjs/toolkit";
import { portfolioAnalysisSelector } from "@/views/portfolioManage/portfolioAnalysis/selectors";
import {
  PositionStrategyTrend,
  PositionTrendResponse,
  PositionTrendStrategyResponse,
} from "@/model/portfolioAnalysis";
import {
  categoryIdMapSelector,
  categoryTreeMapSelector,
} from "@/selectors/category";
import { flow, last } from "lodash/fp";
import { fastProp } from "@/util/opt";

export const AssetPositionSelector = createSelector(
  portfolioAnalysisSelector,
  (portfolioAnalysis) => {
    const assetPositionDetail =
      portfolioAnalysis?.assetPortfoliosPositionDetail;
    return {
      assetPositionDetail,
    };
  }
);
export const PositionTrendSelector = createSelector(
  portfolioAnalysisSelector,
  (portfolioAnalysis): PositionTrendResponse => portfolioAnalysis?.positionTrend
);
export const PositionTrendStrategySelector = createSelector(
  portfolioAnalysisSelector,
  (portfolioAnalysis): PositionTrendStrategyResponse =>
    portfolioAnalysis?.positionTrendStrategy
);
export const CurrentPositionStrategySelector = createSelector(
  PositionTrendStrategySelector,
  (positionTrendStrategy): PositionStrategyTrend =>
    flow(fastProp("positionStrategyTrend"), last)(positionTrendStrategy)
);
export default createSelector(
  AssetPositionSelector,
  PositionTrendSelector,
  PositionTrendStrategySelector,
  categoryTreeMapSelector,
  categoryIdMapSelector,
  CurrentPositionStrategySelector,
  (
    assetPosition,
    positionTrend,
    positionTrendStrategy,
    categoryTreeMap,
    categoryIdMap,
    currentPositionStrategy
  ) => ({
    assetPosition,
    positionTrend,
    positionTrendStrategy,
    categoryTreeMap,
    categoryIdMap,
    currentPositionStrategy,
  })
);
