import {
  Asset,
  ManualCreatePortfolio,
} from "@/views/portfolioManage/manualCreatePortfolio/constant";
import { Updater } from "@/views/portfolioManage/fundConfiguration/backTestingAllocation/interface";
import { Table, Timeline } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp, fastNth, mapIndexed } from "@/util/opt";
import { useCreation, useSize } from "ahooks";
import { map, size, sumBy, isEmpty, flow } from "lodash/fp";
import { prop } from "lodash/fp";
import { useGetFundWeightTableColumns } from "../hooks";
import style from "./index.module.less";
import EmptyContent from "@/components/emptyContent";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import cn from "classnames";
import { mergeRowsFunc } from "../../fundAllocation/hooks";
import { validateWeightMaxError } from "@/components/portfolioCompoents/fundListTable";
import { getTableSummaryNode } from "@/components/portfolioCompoents/constant";
import { formatPercentage } from "@/util/numberFormatter";

const SummaryNode = React.memo<{
  size: number;
  positionWeight: number;
}>(({ size, positionWeight }) => {
  const formatMessage = useFormatMessage();
  return useCreation(
    () =>
      getTableSummaryNode([
        {
          align: "left",
          index: 0,
          colSpan: 8,
          render: formatMessage("fundTotalNum", { num: size }),
        },
        {
          align: "left",
          index: 1,
          render: (
            <div
              className={cn(
                style.SummaryPosition,
                validateWeightMaxError(positionWeight)
                  ? style.PositionError
                  : ""
              )}
            >
              <p>{formatMessage("RemainingAvailablePositions")}</p>
              <p className={style.Position}>
                {formatPercentage(1 - positionWeight)}
              </p>
            </div>
          ),
        },
      ]),
    [formatMessage, positionWeight, size]
  );
});

export default React.memo<{
  originData: ManualCreatePortfolio[];
  data: ManualCreatePortfolio[];
  onUpdate: (index: number) => (updater: Updater) => any;
  errorField?: Record<string, any>;
}>(({ originData, data, onUpdate, errorField }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const dataSource = useMemo(
    () =>
      flow(fastNth(activeIndex), fastProp("weights"), (data) =>
        mergeRowsFunc(data, "category")
      )(data),
    [activeIndex, data]
  );

  const originDataSource = useMemo<Asset[]>(
    () => fastProp("weights")(fastNth(activeIndex)(originData)),
    [activeIndex, originData]
  );
  const formatMessage = useFormatMessage();
  const dates = useCreation<string[]>(() => map("turnoverDate")(data), [data]);
  const tableRef = useRef(null);
  const tableSize = useSize(tableRef);
  const indexUpdater = useCreation(
    () => onUpdate(activeIndex),
    [onUpdate, activeIndex]
  );
  const columns = useGetFundWeightTableColumns(indexUpdater, originDataSource);
  const timeLineStyle = useCreation(
    () => ({
      height: (tableSize?.height || 45) - 45,
    }),
    [tableSize?.height]
  );

  const scroll = useCreation(
    () =>
      size(dataSource) > 10
        ? { y: 407, x: "max-content" }
        : { x: "max-content" },
    [dataSource]
  );
  const positionWeight = useCreation(
    () => sumBy("weight")(dataSource),
    [dataSource]
  );

  return isEmpty(data) ? (
    <EmptyContent message={formatMessage("noData")} />
  ) : (
    <div className={style.DisplayContainer}>
      <div className={style.DisplayTime}>
        <h3 className={style.DisplayTimeTitle}>
          {formatMessage("openPosition")} / {formatMessage("theWarehouse")}
        </h3>
        <Timeline className={style.DisplayTimeLine} style={timeLineStyle}>
          {mapIndexed((date: string, index: number) => (
            <Timeline.Item
              key={date}
              className={cn({
                [style.FirstAsset]: index === 0,
                [style.ActiveAsset]: activeIndex === index,
                [style.NotActiveAsset]: activeIndex !== index && index !== 0,
              })}
            >
              <span onClick={() => setActiveIndex(index)}>
                {date} &nbsp;&nbsp;{" "}
                {prop(index)(errorField) && (
                  <ExclamationCircleOutlined
                    className={style.ExclamationCircleOutlined}
                  />
                )}
              </span>
            </Timeline.Item>
          ))(dates)}
        </Timeline>
      </div>
      <div className={style.DisplayTable}>
        {!isEmpty(dataSource) && (
          <Table
            bordered
            className={style.FundWeightTable}
            rowKey="fundId"
            ref={tableRef}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={scroll}
            summary={() =>
              !isEmpty(dataSource) && (
                <Table.Summary fixed>
                  <SummaryNode
                    positionWeight={positionWeight}
                    size={size(dataSource)}
                  />
                </Table.Summary>
              )
            }
          />
        )}
      </div>
    </div>
  );
});
