// import { useFormatMessage } from "@/util/formatMessage";
import { Space } from "antd";
import React, { ReactElement } from "react";
import arrow from "@/assets/fundEvaluationReport/arrow.svg";
import style from "./oneLevelName.module.less";
import Rate from "./rate";

type SecondLevelProps = {
  name: string;
  rateNum?: number | string;
  hideColon?: boolean;
  children?: ReactElement;
};

export default React.memo(
  ({ name, rateNum = 0, hideColon = false, children }: SecondLevelProps) => {
    return (
      <h2 className={style.SecondLevelContainer}>
        <img src={arrow} />
        <Space size={12}>
          <span className={style.SecondLevelName}>
            {name}
            {!hideColon && ":"}
          </span>
          {children || (
            <Rate value={rateNum as number} className={style.FundTate} />
          )}
        </Space>
      </h2>
    );
  }
);
