import { concat, update, map } from "lodash/fp";

type factorInfoType = string | number[];

export type BaseTableProps = {
  maxAssetContribution: Record<string, any>;
  minAssetContribution: Record<string, any>;
  portfolioReturn: Record<string, any>;
  benchmarkAttribution: Record<string, any>;
};

export const getChartFactorInfo = (
  factorInfo: Record<string, factorInfoType>
) => update("cumulativeReturns", (v: number[]) => concat(0)(v))(factorInfo);

export const lineChartTableFactor = [
  "maxDrawdown",
  "sharpe",
  "infoRatio",
  "trackDeviation",
];

export const formatYAxisEllipsis = (data: string[]): any[] => {
  return map((v: string) => ({
    value: v,
    // textStyle: {
    //   width: 100,
    //   overflow: "truncate",
    // },
  }))(data);
};

export const formatYAxisText = (val: string): any => {
  let valueTxt = "";
  if (val?.length > 8) {
    valueTxt = `${val.substring(0, 9)}...`;
  } else {
    valueTxt = val;
  }
  return valueTxt;
};
