import { RootState } from "@/store";
import { fastProp, normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { optionalFundGroupList } from "./interface";

export const optionalFundGroupListSelector = createSelector(
  (state: RootState) => state.workbench.optionalFundGroupList,
  (groupList): optionalFundGroupList[] => groupList
);

export const optionalFundGroupMapSelector = createSelector(
  optionalFundGroupListSelector,
  (groupList): Record<string, optionalFundGroupList> =>
    normalize<optionalFundGroupList>("groupName")(groupList)
);

export const groupSelectFundListSelector = createSelector(
  (state: RootState) => state.workbench.groupFundList,
  (_: RootState, name: string) => name,
  (groupFundList, name) => fastProp(name)(groupFundList)
);
