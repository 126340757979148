import { map, size, sumBy } from "lodash/fp";
import { fastProp } from "./opt";
import { TableProps as AntdTableProps } from "antd";

const formatDataSourceWithKey = <T>(
  dataSource: T[],
  key: string
): Array<
  T & {
    key: unknown;
  }
> => {
  return map((v: T) => {
    return {
      ...v,
      key: fastProp(key)(v),
    };
  })(dataSource);
};

export default formatDataSourceWithKey;

type TableProps = AntdTableProps<any>;
export const getTableScroll = ({
  dataSource,
  columns,
  lineHeight = 35,
  maxYScroll,
}: {
  dataSource?: TableProps["dataSource"];
  columns?: TableProps["columns"];
  lineHeight?: number;
  maxYScroll?: number;
}) => {
  const x = columns ? sumBy("width")(columns) : undefined;
  const y =
    dataSource && maxYScroll && size(dataSource) * lineHeight > maxYScroll
      ? maxYScroll
      : undefined;
  return { x, y };
};
