import { useState, useEffect, useCallback } from "react";
import { map, size, slice } from "lodash/fp";
import { LineSeriesOption } from "echarts";

export type DataSource = {
  name: string;
  dailyReturns: [string, number][];
} & LineSeriesOption;

export type SeriesDataSource = {
  name: string;
  cumulativeReturns: DataSource["dailyReturns"];
} & LineSeriesOption;

export const useManageDataZoom = (
  dataSource: DataSource[]
): [DataSource[], ({ start, end }: any) => void] => {
  const [slicedDataSource, setSlicedDataSource] = useState(dataSource);
  useEffect(() => {
    setSlicedDataSource(dataSource);
  }, [dataSource]);

  const sliceFunc = useCallback((start: number, end: number) => {
    if (start !== 0) return slice(start + 1, end);
    return slice(start, end);
  }, []);

  const onDataZoom = useCallback(
    ({ start, end }) => {
      const slicedDataSource = map<DataSource, DataSource>(
        ({ name, dailyReturns, ...rest }) => {
          const singleSlice = size(dailyReturns) / 100;
          const startIndex = Math.floor(start * singleSlice);
          const endIndex = Math.floor(end * singleSlice);
          return {
            name,
            ...rest,
            dailyReturns: sliceFunc(startIndex, endIndex)(dailyReturns),
          };
        }
      )(dataSource);
      setSlicedDataSource(slicedDataSource);
    },
    [dataSource, sliceFunc]
  );
  return [slicedDataSource, onDataZoom];
};
