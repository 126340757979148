import {
  toFixedNumber,
  formatPercentage,
  formatNil,
} from "@/util/numberFormatter";
import { includeStr } from "@/util/opt";
import { toInteger, toLower } from "lodash/fp";
import type { Factor } from "@/model/factors";

export const formatter = {
  integer: toInteger,
  float2: toFixedNumber(2),
  float4: toFixedNumber(4),
  percent2: (value: number | undefined) => formatPercentage(value),
  percent4: (value: number | undefined) =>
    formatPercentage(value, { digit: 4 }),
  scale: toFixedNumber(2),
  scaleWithHundredMillion: (value: number) =>
    toFixedNumber(2)(value / 100000000),
  scaleWithTenThousand: (value: number) => toFixedNumber(2)(value / 10000),
};

export const factorsFormatter = {
  // 收益，所有的收益指标都格式化成百分数
  yield: formatter.percent2,
  // alpha
  alpha: formatter.float2,
  // 波动率
  volatility: formatter.percent2,
  // 最大回撤
  maxDrawdown: formatter.percent2,
  // 偏度
  skewness: formatter.float2,
  // 峰度
  kurtosis: formatter.float2,
  // 夏普比率
  sharpeRatio: formatter.float2,
  // mar比率
  marRatio: formatter.float2,
  // 克莱玛比率
  carmerRatio: formatter.float2,
  // 特雷诺比率
  treynorRatio: formatter.float2,
  // 净值
  netValue: formatter.float4,
  // 信息比率
  informationRatio: formatter.float2,
  // 跟踪误差
  trackDeviation: formatter.percent2,
  // 万
  scaleWithTenThousand: formatter.scaleWithTenThousand,
  // 亿
  scaleWithHundredMillion: formatter.scaleWithHundredMillion,
};

export const getFactorsFormatterName = (
  nameOrKey: string | undefined
): Required<Factor>["format"] => {
  if (!nameOrKey) return "percent2";
  const lowerCase = toLower(nameOrKey);
  if (includeStr("克莱玛")(lowerCase) || includeStr("carmer")(lowerCase))
    return "float2";
  if (includeStr("特雷诺")(lowerCase) || includeStr("treynor")(lowerCase))
    return "float2";
  if (includeStr("夏普比")(lowerCase) || includeStr("sharpe")(lowerCase))
    return "float2";
  if (includeStr("mar")(lowerCase)) return "float2";
  if (includeStr("波动率")(lowerCase) || includeStr("volatility")(lowerCase))
    return "percent2";
  if (includeStr("回撤")(lowerCase) || includeStr("maxdrawdown")(lowerCase))
    return "percent2";
  if (includeStr("偏度")(lowerCase) || includeStr("skewness")(lowerCase))
    return "float2";
  if (includeStr("峰度")(lowerCase) || includeStr("kurtosis")(lowerCase))
    return "float2";
  if (
    includeStr("收益")(lowerCase) ||
    includeStr("yield")(lowerCase) ||
    includeStr("cumulative")(lowerCase)
  )
    return "percent2";
  if (includeStr("阿尔法")(lowerCase) || includeStr("alpha")(lowerCase))
    return "float2";
  if (includeStr("净值")(lowerCase) || includeStr("netvalue")(lowerCase))
    return "float4";
  if (includeStr("信息")(lowerCase) || includeStr("info")(lowerCase))
    return "float2";
  if (includeStr("索提诺")(lowerCase) || includeStr("sortino")(lowerCase))
    return "float2";
  if (includeStr("卡玛")(lowerCase) || includeStr("calmar")(lowerCase))
    return "float2";
  if (includeStr("盈利期")(lowerCase) || includeStr("profit")(lowerCase))
    return "integer";
  return "percent2";
};

// 获取指标格式化的方法
export const getFactorsFormatter = (nameOrKey: string) => {
  const key = getFactorsFormatterName(nameOrKey);
  const formatterFunc = formatter[key];
  return formatterFunc || formatNil;
};

export function formatFactors(
  value: string | number | null | "NaN",
  format?: Factor["format"]
) {
  if (value === "NaN") return "--";
  if (!format || typeof value === "string") {
    return formatNil(value);
  }
  if (value === null || isNaN(value)) return "--";
  switch (format) {
    case "integer":
      return formatter.integer(value);
    case "float2":
      return formatter.float2(value);
    case "float4":
      return formatter.float4(value);
    case "percent2":
      return formatter.percent2(value);
    case "percent4":
      return formatter.percent4(value);
    case "scale":
      return formatter.scale(value);
    case "scaleWithTenThousand":
      return formatter.scaleWithTenThousand(value);
    case "scaleWithHundredMillion":
      return formatter.scaleWithHundredMillion(value);
  }
}
