/**
 * 夏普比率
 */
import { flow, zipWith } from "lodash/fp";
import { fastSize } from "../opt";
import tradingDateCount from "@/constant/tradingDateCount";
import { div, times, toAverage, toStandardDeviation, minus } from "../math";
import { equalZero, formatQuantArrayNilToZero } from "../numberFormatter";

export default (dailyReturns: number[], riskFreeRates: number[]) => {
  if (!dailyReturns || fastSize(dailyReturns) === 0) {
    return 0;
  }
  const [formatDailyReturns, formatRiskFreeRates] = formatQuantArrayNilToZero(
    dailyReturns,
    riskFreeRates
  );
  const standardDeviation = toStandardDeviation(formatDailyReturns);
  if (equalZero(standardDeviation)) return NaN;
  return flow(
    zipWith((riskFreeRate: number, dailyTotalReturn: number) =>
      minus(riskFreeRate || 0)(dailyTotalReturn)
    )(formatRiskFreeRates),
    toAverage,
    div(standardDeviation),
    times(Math.sqrt(tradingDateCount))
  )(formatDailyReturns);
};
