/* eslint-disable @typescript-eslint/no-explicit-any */
import { flatMap, prop, map, identity, isEmpty, flow, size } from "lodash/fp";
import { fastProp, overrideBy } from "./index";

const TRUE = () => true;
const defaultIdKey = "id";
const defaultChildKey = "children";
type TreeNode<T> = T & { path?: string[]; [propsName: string]: any };
type AddIdField = <T>(node: TreeNode<T>) => TreeNode<T>;

const haveChildren = <T>(node: TreeNode<T>) =>
  !!size(fastProp("children")(node));
// const haveNoChildren = <T>(node: TreeNode<T>) => !haveChildren(node);
const addIdField: AddIdField = (node) => ({
  ...node,
  key: fastProp("id")(node),
});

export function flattenAllLeaf<T extends Record<string, any>>(
  tree: T[],
  childKey = defaultChildKey
): T[] {
  return flatMap((node: T) => {
    const child = prop(childKey)(node);
    if (child && child?.length) {
      return [...flattenAllLeaf<T>(child)];
    }
    return node;
  })(tree);
}

export function mapTree<T extends Record<string, any>, R = T>(
  childKey = "children",
  func: (node: T, parent?: T) => R = identity,
  parent?: T
) {
  return (tree: T[]): R[] => {
    return map((node: T) => {
      const child = prop(childKey)(node);
      if (child && child?.length) {
        return {
          ...func(node, parent),
          [childKey]: mapTree<T, R>(childKey, func, node)(child),
        };
      }
      return func(node, parent);
    })(tree);
  };
}

type TreeToMapConfig<T> = {
  idKey?: string;
  childrenKey?: string;
  needPath?: boolean;
  filter?: (node?: T) => boolean;
  mapItem?: (node?: T) => any;
};
export const treeToMap = <T = any>(config: TreeToMapConfig<T>) => {
  const {
    idKey = defaultIdKey,
    childrenKey = defaultChildKey,
    needPath,
    filter = TRUE,
    mapItem = identity,
  } = config || {};

  const iterator =
    (result: Record<string, any> = {}) =>
    (node: T[], path: string[] | undefined) => {
      if (isEmpty(node)) {
        return result;
      }

      // eslint-disable-next-line
      for (let key in node) {
        const item = node[key];
        const id = fastProp(idKey)(item);
        const breadCrumb = needPath ? [...(path || []), id] : undefined;
        if (filter(item)) {
          // eslint-disable-next-line
          result[id] = flow(
            breadCrumb ? overrideBy({ path: breadCrumb }) : identity,
            mapItem
          )(item);
        }
        iterator(result)(fastProp(childrenKey)(item), breadCrumb);
      }
      return result;
    };

  return (tree: T[]) => iterator()(tree, []);
};

export const forEachTree =
  <T extends Record<string, any>>(
    func: (item: T, dep: number) => any,
    childKey: string | ((arg: T) => string) = "children",
    filter?: (item: T) => boolean,
    dep = 0
  ) =>
  (tree: T[]) => {
    if (isEmpty(tree)) {
      return;
    }
    // eslint-disable-next-line
    for (let key in tree) {
      const item = tree[key];
      func(item, dep);
      let child = null;
      if (typeof childKey === "function") {
        child = childKey(item);
      } else {
        child = fastProp(childKey)(item);
      }
      if (!isEmpty(child)) {
        if (filter && filter(item)) {
          forEachTree(func, childKey, filter, dep + 1)(child);
        } else {
          forEachTree(func, childKey, filter, dep + 1)(child);
        }
      }
    }
  };

export function listToTree(
  items: any[],
  parentKey = "parentId",
  childKey = "id"
) {
  const result: any[] = [];
  const itemMap: any = {};
  for (const item of items) {
    const id = item?.[childKey];
    const pid = item?.[parentKey];

    if (!itemMap[id]) {
      itemMap[id] = {
        children: [],
      };
    }

    itemMap[id] = {
      ...item,
      children: itemMap[id]["children"],
    };
    const treeItem = itemMap[id];

    if (pid === "-1" || !pid) {
      result.push(treeItem);
    } else {
      if (!itemMap[pid]) {
        itemMap[pid] = {
          children: [],
        };
      }
      itemMap[pid].children.push(treeItem);
    }
  }
  return result;
}

/**
 * 对树形节点进行过滤操作和修改操作
 * @param {*} filterFunc 过滤函数， 与lodash.filter传入的函数作用相同，返回为true则保留此节点，返回false则删除该节点
 * @param {*} mapFunc 转化函数, 与lodash.map传入的函数作用相同，返回值为新的节点
 */
type FilterAndMapTreeOption<T> = {
  addPath: boolean;
  paths: string[];
  nodeKey: string;
  parent: TreeNode<T> | null;
};

type FilterAndMapTree = <T>(
  filterFunc: (node: TreeNode<T>) => boolean,
  mapFunc?: (node: TreeNode<T>) => TreeNode<T>,
  sortFunc?: (node: TreeNode<T>[]) => TreeNode<T>[],
  option?: FilterAndMapTreeOption<T>
) => (tree: TreeNode<T>[]) => TreeNode<T>[];

export const filterAndMapTree: FilterAndMapTree =
  (
    filterFunc = () => true,
    mapFunc = addIdField,
    sortFunc = identity,
    { addPath, paths, nodeKey } = {
      addPath: false,
      paths: [],
      nodeKey: "id",
      parent: null,
    }
  ) =>
  (tree) => {
    const result = [];
    // eslint-disable-next-line
    for (let key in tree) {
      const node = tree[key];
      // eslint-disable-next-line
      if (!filterFunc(node)) continue;
      const nodeId = fastProp(nodeKey)(node);
      const innerPath = [...paths, nodeId];
      const newNode = haveChildren(node)
        ? {
            ...node,
            children: flow(
              fastProp("children"),
              filterAndMapTree(filterFunc, mapFunc, sortFunc, {
                addPath,
                paths: innerPath,
                nodeKey,
                parent: node,
              })
              // (children) => sortFunc(children, item),
            )(node),
          }
        : node;
      // eslint-disable-next-line
      if (!filterFunc(newNode)) continue;
      if (addPath) {
        newNode.path = innerPath;
      }
      result.push(mapFunc ? mapFunc(newNode) : newNode);
    }
    return sortFunc(result);
  };
