import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { BYWEEK } from "@/views/portfolioManage/portfolioAnalysis/constant";
import dayjs from "dayjs";
import { calculationFactorRatio } from "@/store/portfolioAnalysis";
import {
  filter,
  isEmpty,
  prop,
  first,
  last,
  keys,
  includes,
  toArray,
  pull,
  concat,
  isInteger,
  size,
  max,
  map,
} from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { analysisBasicInfoSelector, factorRatioSelector } from "./selectors";
import { RangeInterface } from "@/constant/statisticRange";
import { useCreation, useMemoizedFn } from "ahooks";
import { fastProp, mapIndexed } from "@/util/opt";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { FormatMessageFunc } from "@/util/formatMessage";
import { TableProps } from "antd";
import {
  getRectangleLegendConfig,
  getSquareLegendConfig,
  getTooltipItem,
} from "@/util/chart";
import { formatPercentage } from "@/util/numberFormatter";
import { formatter } from "@/constant/factorFormatter";

type TablePropsType = TableProps<any>;

export const useGetFactors = (id: string, range: string, factors: string[]) => {
  const portfolioSummary = useAppSelector((state: RootState) =>
    analysisBasicInfoSelector(state, id)
  );
  const benchmarkId = fastProp("benchmark")(portfolioSummary);
  const dailyReturns = useAppSelector((state) => state.dailyReturns);
  const benchmarkDailyReturns = useCreation(
    () => fastProp(benchmarkId)(dailyReturns),
    [benchmarkId, dailyReturns]
  );
  const riskFreeRate = useAppSelector((state) => state.entities.riskFreeRate);

  const factorValues = useAppSelector((state: RootState) =>
    factorRatioSelector(state, id, {
      range,
      factors,
    })
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (
      id &&
      !isEmpty(portfolioSummary?.dailyReturn) &&
      !isEmpty(benchmarkDailyReturns?.dailyReturns) &&
      !isEmpty(riskFreeRate)
    ) {
      dispatch(
        calculationFactorRatio({
          id,
          range,
          factor: factors,
        })
      );
    }
  }, [
    benchmarkDailyReturns?.dailyReturns,
    dispatch,
    factors,
    id,
    portfolioSummary?.dailyReturn,
    range,
    riskFreeRate,
  ]);
  return factorValues;
};

export const useGetStatisticRange = (tradingDateSize = 2) => {
  const processedTradingDate = useAppSelector(
    (state: RootState) => state.tradingDates.processedTradingDates
  );
  return useMemoizedFn(
    (
      startDate: string,
      endDate: string,
      staticRange: Record<string, RangeInterface>
    ) => {
      if (!startDate || !endDate) return toArray(staticRange);
      const endDay = dayjs(endDate);
      return filter(({ checkIsValid, period, count }: RangeInterface) => {
        if (checkIsValid) {
          return checkIsValid(
            startDate,
            endDate,
            processedTradingDate,
            tradingDateSize
          );
        }
        return !endDay
          .subtract(period as number, count as dayjs.ManipulateType)
          .isBefore(startDate);
      })(staticRange);
    }
  );
};

export const useBenchmarkReturns = (benchmarkId: string) => {
  const dailyReturns = useAppSelector(prop("dailyReturns"));
  const benchmarkReturn = fastProp(benchmarkId)(dailyReturns);
  const benchmarkReturnMap = normalizeDailyReturnsMap(
    fastProp("dates")(benchmarkReturn),
    fastProp("dailyReturns")(benchmarkReturn)
  );
  return {
    benchmarkReturnMap,
    benchmarkReturn,
  };
};

export const useStatisticRangeMap = (
  tradingDate: string[],
  statisticRangeList: Record<string, RangeInterface>
) => {
  const startDate = first(tradingDate);
  const endDate = last(tradingDate);

  const calcStatisticRangeList = useGetStatisticRange(2);

  const statisticRange = useCreation(
    () =>
      calcStatisticRangeList(
        startDate as string,
        endDate as string,
        statisticRangeList as Record<string, RangeInterface>
      ),
    [startDate, endDate, statisticRangeList]
  );
  return statisticRange;
};

export const useTableData = (
  errorBoundary: string | undefined,
  formatMessage: FormatMessageFunc,
  statisticRangeList: Record<string, RangeInterface>,
  rangeList: any,
  rangeDatesMap: Record<string, any>
) => {
  // const tableData = [];
  const list = keys(statisticRangeList);

  const tableData: Record<string, any>[] = [];
  mapIndexed((_: any, index: number) => {
    if (includes(list[index])(rangeList) && !errorBoundary) {
      tableData.push({
        key: index,
        time: formatMessage(
          fastProp("message")(statisticRangeList[list[index]])
        ),
        portfolioProfit: rangeDatesMap[list[index]].profitNumberByPortfolio,
        benchmarkProfit: rangeDatesMap[list[index]].profitNumberByBenchmark,
        portfolioLoss: rangeDatesMap[list[index]].lossNumberByPortfolio,
        benchmarkLoss: rangeDatesMap[list[index]].lossNumberByBenchmark,
      });
    } else {
      tableData.push({
        key: index,
        time: formatMessage(
          fastProp("message")(statisticRangeList[list[index]])
        ),
        portfolioProfit: "--",
        benchmarkProfit: "--",
        portfolioLoss: "--",
        benchmarkLoss: "--",
      });
    }
  })(list);

  return tableData;
};

export const getColumns = (formatMessage: FormatMessageFunc) => [
  {
    dataIndex: "time",
    key: "name",
    width: 80,
  },
  {
    title: formatMessage("profitNumber"),
    children: [
      {
        title: formatMessage("production"),
        dataIndex: "portfolioProfit",
        key: "portfolioProfit",
        width: 80,
      },
      {
        title: formatMessage("benchmark"),
        dataIndex: "benchmarkProfit",
        key: "benchmarkProfit",
        width: 80,
      },
    ],
  },
  {
    title: formatMessage("lossNumber"),
    children: [
      {
        title: formatMessage("production"),
        dataIndex: "portfolioLoss",
        key: "portfolioLoss",
        width: 80,
      },
      {
        title: formatMessage("benchmark"),
        dataIndex: "benchmarkLoss",
        key: "benchmarkLoss",
        width: 80,
      },
    ],
  },
];

export const useGetExpandedRowKeys = (allExpandedRowKeys: string[]) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  useEffect(() => {
    setExpandedRowKeys(allExpandedRowKeys);
  }, [allExpandedRowKeys]);

  const onExpandAllRows = useCallback(() => {
    setExpandedRowKeys(isEmpty(expandedRowKeys) ? allExpandedRowKeys : []);
  }, [allExpandedRowKeys, expandedRowKeys]);

  const onExpand = useCallback(
    (key: string) => {
      if (includes(key)(expandedRowKeys)) {
        setExpandedRowKeys(pull(key));
      } else {
        setExpandedRowKeys(concat(key));
      }
    },
    [expandedRowKeys]
  );
  const expandable = useMemo<TablePropsType["expandable"]>(
    () => ({
      expandedRowKeys,
      onExpand: (_, record) => onExpand(record.key),
    }),
    [expandedRowKeys, onExpand]
  );
  return { onExpandAllRows, expandable, expandedRowKeys };
};

const useSeries = (
  portfolioName: string,
  formatMessage: FormatMessageFunc,
  groupSum: number[],
  groupSumByBenchmark: number[],
  curveyPortfolio: number[],
  curveyBenchmark: number[]
) => {
  const series = useMemo(() => {
    return [
      {
        name: portfolioName || formatMessage("backtestingPortfolio"),
        type: "bar",
        data: groupSum,
        color: "#3D77CE",
        yAxisIndex: 0,
      },
      {
        name: formatMessage("performanceBenchmark"),
        type: "bar",
        data: groupSumByBenchmark,
        color: "#FFA62A",
        yAxisIndex: 0,
      },
      {
        name: `${
          portfolioName || formatMessage("backtestingPortfolio")
        }${formatMessage("curveProbability")}`,
        type: "line",
        lineStyle: {
          width: 1.5,
        },
        data: curveyPortfolio,
        color: "#3D77CE",
        symbol: "none",
        smooth: true,
        xAxisIndex: 1,
        yAxisIndex: 1,
      },
      {
        name: `${formatMessage("performanceBenchmark")}${formatMessage(
          "curveProbability"
        )}`,
        type: "line",
        lineStyle: {
          width: 1.5,
        },
        data: curveyBenchmark,
        color: "#FFA62A",
        symbol: "none",
        smooth: true,
        xAxisIndex: 1,
        yAxisIndex: 1,
      },
    ];
  }, [
    portfolioName,
    formatMessage,
    curveyPortfolio,
    curveyBenchmark,
    groupSum,
    groupSumByBenchmark,
  ]);
  return series;
};

const useLegendData = (
  curveyPortfolio: number[],
  curveyBenchmark: number[],
  portfolioName: string,
  formatMessage: FormatMessageFunc
) => {
  const curveLegend = useMemo(() => {
    return [
      !isEmpty(curveyPortfolio)
        ? {
            name: `${
              portfolioName || formatMessage("backtestingPortfolio")
            }${formatMessage("curveProbability")}`,
            icon: getRectangleLegendConfig().icon,
          }
        : null,
      !isEmpty(curveyBenchmark)
        ? {
            name: `${formatMessage("performanceBenchmark")}${formatMessage(
              "curveProbability"
            )}`,
            icon: getRectangleLegendConfig().icon,
          }
        : null,
    ];
  }, [curveyPortfolio, portfolioName, formatMessage, curveyBenchmark]);
  const legend = useMemo(() => {
    return [
      {
        name: portfolioName || formatMessage("backtestingPortfolio"),
        icon: getSquareLegendConfig().icon,
      },
      {
        name: formatMessage("performanceBenchmark"),
        icon: getSquareLegendConfig().icon,
      },
      ...curveLegend,
    ];
  }, [portfolioName, formatMessage, curveLegend]);
  return legend;
};

export const useGetChartData = (
  histogramX: Record<string, any>[],
  groupSum: number[],
  groupSumByBenchmark: number[],
  portfolioName: string,
  incomeData: string,
  curveyPortfolio: number[],
  curveyBenchmark: number[],
  formatMessage: FormatMessageFunc
) => {
  const maxIndex = max([...groupSum, ...groupSumByBenchmark]) as number;
  return {
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
      formatter: (params: any) => {
        let res = `<div>${params[0].axisValue}</div>`;
        mapIndexed((_: any, index: number) => {
          const value = isInteger(params[index].value)
            ? params[index].value
            : formatter.float2(params[index].value);
          if (params[index].seriesType === "bar") {
            res += getTooltipItem(
              params[index].color,
              params[index].seriesName,
              value,
              10,
              10
            );
          }
          if (params[index].seriesType === "line") {
            res += getTooltipItem(
              params[index].color,
              params[index].seriesName,
              value,
              10,
              5
            );
          }
        })(params);
        return res;
      },
    },
    legend: {
      data: useLegendData(
        curveyPortfolio,
        curveyBenchmark,
        portfolioName,
        formatMessage
      ),
      left: 0,
      top: 10,
    },
    xAxis: [
      {
        type: "category",
        data: map(
          (v: Record<string, any>) =>
            `${formatPercentage(v.first, {
              defaultDisplay: "-∞",
            })}~${formatPercentage(v.second, { defaultDisplay: "+∞" })}`
        )(histogramX),
        axisLabel: {
          interval: Math.floor(size(histogramX) / 20),
          rotate: 45,
        },
        axisTick: {
          show: false,
        },
      },
      {
        type: "category",
        show: false,
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: (value: number) =>
            incomeData === BYWEEK
              ? `${value}${formatMessage("week")}`
              : `${value}${formatMessage("date")}`,
        },
        min: 0,
        max: maxIndex + 1,
      },
      {
        type: "value",
        axisLabel: {
          show: false,
        },
        // 刻度
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: useSeries(
      portfolioName,
      formatMessage,
      groupSum,
      groupSumByBenchmark,
      curveyPortfolio,
      curveyBenchmark
    ),
  };
};
