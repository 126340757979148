import CheckBoxGroup from "@/components/checkBoxGroup";
import FormItemLabelMessage from "@/components/FormItemLabelMessage";
import ManagerOrCompanySearch from "@/components/managerOrCompanySearch";
import RadioBoxGroup from "@/components/radioBoxGroup";
import {
  educationTag,
  getAchievementTag,
  getFundNums,
  manageScales,
  unlimitedAll,
  workTimeTag,
} from "@/constant/fundFilter";
import { CheckBoxItem } from "@/model/checkBoxGroup";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import { Form, Select } from "antd";
import { first, prop } from "lodash/fp";
import { useMemo } from "react";
import style from "./index.module.less";
import { ClassicFundFilter } from "./seniorFilter";

const FormItem = Form.Item;
const useCheckBoxGroupList = () => {
  const formatMessage = useFormatMessage();
  const workTimeTagList: CheckBoxItem[] = useMemo(
    () => [unlimitedAll(formatMessage), ...workTimeTag(formatMessage)],
    [formatMessage]
  );
  const educationTagList: CheckBoxItem[] = useMemo(
    () => [unlimitedAll(formatMessage), ...educationTag(formatMessage)],
    [formatMessage]
  );
  return {
    workTimeTagList,
    educationTagList,
  };
};
const SelectFilterTag = ({
  value,
  options,
  onSelectFilterTag,
}: {
  value?: "NOW" | "HISTORY";
  options: { label: string; value: string }[];
  onSelectFilterTag: (value: string) => void;
}): JSX.Element => {
  const defaultValue = useMemo(
    () => fastProp("value")(first(options)),
    [options]
  );
  return (
    <Select
      size="small"
      value={value}
      defaultValue={defaultValue}
      onSelect={onSelectFilterTag}
      options={options}
    />
  );
};
export const getSelectFilterTagOptions = (
  formatMessage: (arg0: string) => string,
  messageType: "Number" | "Scale"
) => [
  {
    label: formatMessage("NOWFUND") + formatMessage(messageType),
    value: "NOW",
  },
  {
    label: formatMessage("HISTORYFUND") + formatMessage(messageType),
    value: "HISTORY",
  },
];

export const getSelectFilterTagRangeOptions = (
  formatMessage: (arg0: string) => string
) => [
  {
    label: formatMessage("RATE_RETURN"),
    value: "RATE_RETURN",
  },
  {
    label: formatMessage("EXCESS_RETURN"),
    value: "EXCESS_RETURN",
  },
];

const FundManagersFilter = ({
  seniorFilterList,
  update,
  updateObject,
}: ClassicFundFilter): JSX.Element => {
  const formatMessage = useFormatMessage();
  const { workTimeTagList, educationTagList } = useCheckBoxGroupList();
  const managerManageScaleTagList: CheckBoxItem[] = useMemo(
    () => [
      unlimitedAll(formatMessage),
      {
        id: "SelectFilterTag",
        name: "SelectFilterTag",
        render: () => (
          <SelectFilterTag
            options={getSelectFilterTagOptions(formatMessage, "Scale")}
            onSelectFilterTag={updateObject(
              "managerManageScaleTag",
              "filterTag"
            )}
            value={prop("managerManageScaleTag.filterTag")(seniorFilterList)}
          />
        ),
      },
      ...manageScales(formatMessage),
    ],
    [formatMessage, seniorFilterList, updateObject]
  );
  const manageFundNumTagList: CheckBoxItem[] = useMemo(
    () => [
      unlimitedAll(formatMessage),
      {
        id: "SelectFilterTag",
        name: "SelectFilterTag",
        render: () => (
          <SelectFilterTag
            options={getSelectFilterTagOptions(formatMessage, "Number")}
            onSelectFilterTag={updateObject("manageFundNumTag", "filterTag")}
            value={prop("manageFundNumTag.filterTag")(seniorFilterList)}
          />
        ),
      },
      ...getFundNums(formatMessage),
    ],
    [formatMessage, seniorFilterList, updateObject]
  );
  const achievementTagList: CheckBoxItem[] = useMemo(
    () => [
      unlimitedAll(formatMessage),
      {
        id: "SelectFilterTag",
        name: "SelectFilterTag",
        render: () => (
          <SelectFilterTag
            options={getSelectFilterTagRangeOptions(formatMessage)}
            onSelectFilterTag={updateObject("achievementTag", "filterTag")}
            value={prop("achievementTag.filterTag")(seniorFilterList)}
          />
        ),
      },
      ...getAchievementTag(formatMessage),
    ],
    [formatMessage, seniorFilterList, updateObject]
  );
  return (
    <Form className={style.Form}>
      <FormItem
        label={<FormItemLabelMessage message={formatMessage("fundManagers")} />}
        name="fundManagerIdTag"
        colon={false}
        labelAlign="left"
      >
        <ManagerOrCompanySearch
          className={style.ManagerOrCompanySearch}
          selector="managerName"
          selectValue={fastProp("fundManagerIdTag")(seniorFilterList)}
          placeholder={formatMessage("fundManagersPlaceholderMessage")}
          onSelect={update("fundManagerIdTag")}
        />
      </FormItem>
      <FormItem
        label={<FormItemLabelMessage message={formatMessage("workTimeTag")} />}
        name="workTimeTag"
        colon={false}
        labelAlign="left"
      >
        <div>
          <CheckBoxGroup
            list={workTimeTagList}
            onChange={updateObject("workTimeTag", "foundDate")}
            value={prop("workTimeTag.foundDate")(seniorFilterList)}
          />
        </div>
      </FormItem>
      <FormItem
        label={<FormItemLabelMessage message={formatMessage("educationTag")} />}
        name="educationTag"
        colon={false}
        labelAlign="left"
      >
        <div>
          <CheckBoxGroup
            list={educationTagList}
            onChange={update("educationTag")}
            value={fastProp("educationTag")(seniorFilterList)}
          />
        </div>
      </FormItem>
      <FormItem
        label={
          <FormItemLabelMessage message={formatMessage("achievementTag")} />
        }
        name="achievementTag"
        colon={false}
        labelAlign="left"
      >
        <div>
          <RadioBoxGroup
            list={achievementTagList}
            value={prop("achievementTag.range")(seniorFilterList)}
            onChange={updateObject("achievementTag", "range")}
          />
        </div>
      </FormItem>
      <FormItem
        label={
          <FormItemLabelMessage
            message={formatMessage("managerManageScaleTag")}
          />
        }
        name="managerManageScaleTag"
        colon={false}
        labelAlign="left"
      >
        <div>
          <CheckBoxGroup
            list={managerManageScaleTagList}
            onChange={updateObject("managerManageScaleTag", "manageScales")}
            value={prop("managerManageScaleTag.manageScales")(seniorFilterList)}
          />
        </div>
      </FormItem>
      <FormItem
        label={
          <FormItemLabelMessage message={formatMessage("manageFundNumTag")} />
        }
        name="manageFundNumTag"
        colon={false}
        labelAlign="left"
      >
        <div>
          <CheckBoxGroup
            list={manageFundNumTagList}
            onChange={updateObject("manageFundNumTag", "fundNums")}
            value={prop("manageFundNumTag.fundNums")(seniorFilterList)}
          />
        </div>
      </FormItem>
    </Form>
  );
};

export default FundManagersFilter;
