import { useAppSelector } from "@/hooks/redux";
import { profOrDefList, subGrpList } from "@/model/aum";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { formatPercentage } from "@/util/numberFormatter";
import { fastProp, mapIndexed } from "@/util/opt";
import { TableProps, Tooltip } from "antd";
import { flow, prop } from "lodash/fp";
import { useMemo } from "react";
import cn from "classnames";
import {
  dealScaleData,
  getMoldSubColumns,
  getScaleChartStyle,
  getSubScaleChartStyle,
} from "../concentrationMonitor/constant";
import {
  getSubColumns,
  TooltipContent,
  useGetBasicData,
  useGetFundBasicColumns,
} from "../concentrationMonitor/hook";
import cnStyle from "../concentrationMonitor/index.module.less";
import { RECENT_WEEK } from "@/constant/statisticRange";
import { allStocksMapSelector } from "@/selectors/stocks";
import ColorNumber from "@/components/colorNumber";

const getStockBasicColumns = (formatMessage: FormatMessageFunc) => {
  return [
    {
      dataIndex: "code",
      width: 120,
      title: formatMessage("securitiesCode"),
    },
    {
      dataIndex: "cumulativeReturn",
      width: 120,
      title: formatMessage("recentWeekYieldRatio"),
      render: (text: number) => {
        return <ColorNumber value={text} formatter="percentage" />;
      },
    },
  ];
};

export const useGetColumns = (
  showType: string,
  type: string,
  section: string,
  dataSource: profOrDefList[],
  key: string,
  isShowSubOrganizations: boolean,
  firstColor?: string
) => {
  const formatMessage = useFormatMessage();
  const getFundBasicColumns = useGetFundBasicColumns();
  const { maxWeight, subNum, maxsubTotleScale } = useGetBasicData(
    dataSource,
    "cumulativeReturn",
    "subGrpList",
    "scale"
  );
  const stockMap = useAppSelector(allStocksMapSelector);
  const basicColumns = useMemo(() => {
    if (type === "FUND") {
      return showType === "table"
        ? flow((res) =>
            res.concat([
              {
                dataIndex: "cumulativeReturn",
                width: 120,
                title: (
                  <span>
                    {formatMessage(section)}
                    {formatMessage("yield")}
                  </span>
                ),
                render: (text: number) => {
                  return <ColorNumber value={text} formatter="percentage" />;
                },
              },
              {
                dataIndex: "scale",
                width: 100,
                title: formatMessage("retentionScale"),
                render: (text: number) => {
                  return <span>{dealScaleData(text)}</span>;
                },
              },
              {
                dataIndex: "customers",
                width: 100,
                title: formatMessage("positionCustomersNumber"),
                render: (text: number) => {
                  return text || "--";
                },
              },
              ...getSubColumns(
                subNum,
                "subGrpList",
                "name",
                "scale",
                formatMessage
              ),
            ])
          )(getFundBasicColumns(showType))
        : getFundBasicColumns(showType);
    } else {
      return showType === "table"
        ? [
            {
              dataIndex: "code",
              width: 150,
              fixed: "left",
              title:
                type === "STOCK"
                  ? formatMessage("stockName")
                  : formatMessage("bondName"),
              render: (text: string) => {
                return prop(`${text}.name`)(stockMap);
              },
            },
            ...getStockBasicColumns(formatMessage),
            {
              dataIndex: "weight",
              width: 120,
              title: (
                <span>
                  {formatMessage("concentration")}
                  {formatMessage("estimate")}
                </span>
              ),
              render: (text: number) => {
                return formatPercentage(text);
              },
            },
            {
              dataIndex: "scale",
              width: 120,
              title: (
                <span>
                  {formatMessage("Scale")}
                  {formatMessage("estimate")}
                </span>
              ),
              render: (text: number) => {
                return <span>{dealScaleData(text)}</span>;
              },
            },
            ...getMoldSubColumns(
              subNum,
              "subGrpList",
              "name",
              "scale",
              formatMessage
            ),
          ]
        : [
            {
              dataIndex: "code",
              fixed: "left",
              width: 200,
              title:
                type === "STOCK" ? (
                  <span className={cnStyle.NameTitle}>
                    {formatMessage("stockName")}
                  </span>
                ) : (
                  <span className={cnStyle.NameTitle}>
                    {formatMessage("bondName")}
                  </span>
                ),
              render: (text: string) => {
                return prop(`${text}.name`)(stockMap);
              },
            },
          ];
    }
  }, [
    formatMessage,
    getFundBasicColumns,
    showType,
    section,
    type,
    subNum,
    stockMap,
  ]);
  return useMemo<TableProps<any>["columns"]>(
    () =>
      showType === "table"
        ? (basicColumns as any)
        : [
            ...basicColumns,
            ...getChartColumns(
              maxWeight || 0,
              maxsubTotleScale,
              formatMessage,
              section,
              key,
              isShowSubOrganizations,
              type,
              firstColor
            ),
          ],
    [
      basicColumns,
      showType,
      formatMessage,
      maxWeight,
      maxsubTotleScale,
      firstColor,
      section,
      key,
      isShowSubOrganizations,
      type,
    ]
  );
};

export const getChartColumns = (
  maxWeight: number,
  maxsubTotleScale: number,
  formatMessage: FormatMessageFunc,
  section: string,
  key: string,
  isShowSubOrganizations: boolean,
  type: string,
  firstColor?: string
) => {
  return [
    {
      dataIndex: "scale",
      width: 300,
      title: formatMessage("changeTop10ScaleRatio", {
        section:
          type === "FUND" ? formatMessage(section) : formatMessage(RECENT_WEEK),
        type: formatMessage(key),
        typeName:
          type === "FUND"
            ? formatMessage("fundProducts")
            : formatMessage("stock"),
      }),
      render: (_: any, row: Record<string, any>, index: number) => {
        return (
          <>
            <Tooltip
              title={
                <TooltipContent
                  name={row.name}
                  scale={row.scale}
                  customers={row.customers}
                  showCustomers={false}
                />
              }
              color="#ffff"
            >
              <div
                className={cnStyle.ProgressDiv}
                style={getScaleChartStyle(
                  fastProp("cumulativeReturn")(row),
                  index,
                  maxWeight,
                  firstColor
                )}
              />
            </Tooltip>
            <span className={cnStyle.Weight}>
              {formatPercentage(row?.cumulativeReturn)}
            </span>
          </>
        );
      },
    },
    {
      dataIndex: "subGrpList",
      title: formatMessage("subTop10PositionDistribution"),
      className: !isShowSubOrganizations && cnStyle.NoShow,
      render: (data: subGrpList[], _: any, index: number) => {
        return (
          <div className={cnStyle.SubOrgs}>
            {mapIndexed((item: subGrpList, i: number) => {
              return (
                <Tooltip
                  title={
                    <TooltipContent
                      name={item?.name}
                      scale={item?.scale}
                      customers={item?.customers}
                      showCustomers={false}
                    />
                  }
                  color="#ffff"
                  key={i}
                >
                  <div
                    className={cn(
                      cnStyle.SubProgress,
                      i !== 0 && cnStyle.BorderLeft
                    )}
                    style={getSubScaleChartStyle(
                      fastProp("scale")(item),
                      index,
                      maxsubTotleScale,
                      i
                    )}
                  />
                </Tooltip>
              );
            })(data)}
          </div>
        );
      },
    },
  ];
};

export const useGetDataSource = (
  aumGrpId: string,
  type: string,
  fundType: string,
  dataKey: string,
  section?: string
) => {
  const dataSource = useAppSelector(
    prop(
      type === "FUND"
        ? `aum.${aumGrpId}.positionStructure.changeMonitor.${fundType}.${section}.${dataKey}`
        : `aum.${aumGrpId}.positionStructure.changeMonitor.${type}.${dataKey}`
    )
  );
  return dataSource;
};
