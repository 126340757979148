import StatisticRange from "@/components/statisticRange";
import { useCreation } from "ahooks";
import React from "react";
import { useGetStatisticRange } from "../hooks";
import { RangeInterface } from "@/constant/statisticRange";
import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";

type CustomStaticRangeProps = {
  startDate: string;
  endDate: string;
  value: string;
  onChange: (v: string) => void;
  tradingDateSize?: number;
  className?: string;
  staticRange?: Record<string, RangeInterface>;
};
const CustomStaticRange = ({
  startDate,
  endDate,
  value,
  onChange,
  className,
  tradingDateSize = 2,
  staticRange = portfolioAnalysisStatisticRange,
}: CustomStaticRangeProps): JSX.Element => {
  const calcStatisticRangeList = useGetStatisticRange(tradingDateSize);
  const statisticRangeList = useCreation(
    () => calcStatisticRangeList(startDate, endDate, staticRange),
    [startDate, endDate, staticRange]
  );
  return (
    <StatisticRange
      className={className}
      value={value}
      statisticRangeList={statisticRangeList as any}
      onChange={onChange}
    />
  );
};
export default React.memo(CustomStaticRange);
