import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import React, { useCallback, useMemo, useState } from "react";
import performanceAttributionIcon from "@/assets/portfolioAnalysisIcons/performanceAttribution.png";
import { useFormatMessage } from "@/util/formatMessage";
import ButtonGroup from "@/components/buttonGroup";
import { buttonGroupList } from "./constant";
import { first } from "lodash/fp";
import { fastProp } from "@/util/opt";
import style from "./index.module.less";
import { Tabs } from "antd";
import PerformanceDismantling from "./performanceDismantling";
import NetValueAttribution from "./netValueAttribution";
import Brinson from "./brinsonAttribution";
import { PortfolioAnalysisProps } from "..";
import LoadingComponent from "@/components/LoadingComponent";
import { fetchPortfolioSummary } from "@/store/portfolioAnalysis";

const { TabPane } = Tabs;
const useButtonGroup = () => {
  const [buttonGroupValue, setButtonGroupValue] = useState(
    fastProp("value")(first(buttonGroupList))
  );
  const onChangeButtonGroupValue = useCallback((v: string) => {
    setButtonGroupValue(v);
  }, []);
  return useMemo(
    () => ({
      buttonGroupValue,
      onChangeButtonGroupValue,
    }),
    [buttonGroupValue, onChangeButtonGroupValue]
  );
};

const PerformanceAttributionTitle = (
  props: PortfolioAnalysisProps
): JSX.Element => {
  const formatMessage = useFormatMessage();

  const { buttonGroupValue, onChangeButtonGroupValue } = useButtonGroup();

  const tip: CardLayoutTipType = [
    [
      formatMessage("performanceAttribution"),
      formatMessage("performanceAttributionTip"),
    ],
    [
      formatMessage("PerformanceDismantling"),
      formatMessage("PerformanceDismantlingTip"),
    ],
    [
      formatMessage("NetValueAttribution"),
      formatMessage("NetValueAttributionTip"),
    ],
    [formatMessage("BRINSON"), formatMessage("BRINSONTip")],
  ];

  return (
    <LoadingComponent actions={fetchPortfolioSummary}>
      <CardLayout
        icon={performanceAttributionIcon}
        title={formatMessage("performanceAttribution")}
        tip={tip}
      >
        <div className={style.PerformanceAttribution}>
          <h4 className={style.PerformanceAttributionTitle}>
            {formatMessage("performanceAttribution")}
          </h4>
          <div className={style.ButtonGroup}>
            <ButtonGroup
              groups={buttonGroupList}
              onChange={onChangeButtonGroupValue}
              value={buttonGroupValue}
            />
          </div>
        </div>
        <Tabs className={style.Tabs} activeKey={buttonGroupValue}>
          <TabPane forceRender key="PerformanceDismantling">
            <PerformanceDismantling {...props} />
          </TabPane>
          <TabPane forceRender key="NetValueAttribution">
            <NetValueAttribution {...props} />
          </TabPane>
          <TabPane forceRender key="BRINSON">
            <Brinson {...props} />
          </TabPane>
        </Tabs>
      </CardLayout>
    </LoadingComponent>
  );
};

export default React.memo(PerformanceAttributionTitle);
