import {
  AssetTypeDistributionParams,
  BrinsonAttributionBody,
  BrinsonAttributionSummaryDataParams,
  BrinsonAttributionSummaryDataResponse,
  DrawAndIndexFundParams,
  DrawAndIndexFundResponse,
  DrawAndIndexSummaryParams,
  DrawAndIndexSummaryResponse,
  FactorEvaluationParams,
  FactorEvaluationResponse,
} from "@/model/aum";
import {
  AccountInfoBody,
  AssetChangeParam,
  AssetListBody,
  AssetTypeDistributionBody,
  AssetTypeDistributionSummaryBody,
  CumulativeIncomeParam,
  InvestmentDetailBody,
  OrganizationTypeBody,
  purchaseRedeemBody,
  RangeIncomeBody,
  BrinsonAttributionResponse,
  RiskDissectResponse,
  CustomerTurnoverRecordItem,
  PositionTrendResponse,
  PositionAssetsListBody,
  SaveOrUpdateInvestPlanParam,
  PutInvestPlanStatusParam,
  FixedInvestmentDetailParam,
  FixedInvestmentDetailBody,
  PositionAssetTransactionParam,
  FundsDailyReturnParam,
  FundsDailyReturnBody,
} from "@/model/customer";
import { assetPortfoliosPositionDetail } from "@/model/portfolioAnalysis";
import { PromiseType } from "@/model/promise";
import { get, post, put } from "@/util/axios";

export const getAccountInfoApi = (
  customerId: string
): PromiseType<AccountInfoBody> =>
  get(`/customer/account/analyze/total/account/${customerId}`);

export const getRangeIncomeInfoApi = (
  customerId: string
): PromiseType<RangeIncomeBody> =>
  get(`/customer/account/analyze/section/profit/${customerId}`);

export const getAssetChangeInfoApi = (
  body: AssetChangeParam
): PromiseType<RangeIncomeBody> =>
  get(
    `/customer/account/analyze/asset/trend/${body?.customerId}/${body?.section}`
  );

export const getAssetListInfoApi = (
  customerId: string
): PromiseType<AssetListBody[]> =>
  get(`/customer/account/analyze/assets/all/${customerId}`);

export const getPurchaseRedeemInfo = (
  body: AssetChangeParam
): PromiseType<purchaseRedeemBody[]> =>
  get(
    `/customer/account/analyze/turnover-record/${body?.customerId}/${body?.section}`
  );

export const getAssetTypeDistributionInfo = ({
  customerId,
  customerBenchmarkType,
  groupType,
}: AssetTypeDistributionParams): PromiseType<AssetTypeDistributionBody> =>
  get(`/customer/analyze/position-type-trend`, {
    accountId: customerId,
    customerBenchmarkType,
    groupType,
  });

export const getFundCumulativeIncomeApi = (
  body: CumulativeIncomeParam
): PromiseType<RangeIncomeBody> =>
  get(
    `/customer/account/analyze/position/scale_trend/${body?.customerId}/${body?.section}`,
    { type: body?.type }
  );

export const getOrganizationTypeApi = (): PromiseType<OrganizationTypeBody[]> =>
  get("/aum/portfolio_type");

export const getInvestmentDetailInfo = (
  customerId: string
): PromiseType<InvestmentDetailBody> =>
  get(`/customer/account/analyze/fund/position`, { customerId: customerId });

export const getAssetTypeDistributionSummary = (
  customerId: string
): PromiseType<AssetTypeDistributionSummaryBody> =>
  get(`customer/account/analyze/summary/${customerId}`);

export const getBrinsonAttributionData = ({
  id,
  section,
}: BrinsonAttributionBody): PromiseType<BrinsonAttributionResponse> =>
  get(`/customer/analyze/brinson/${id}/${section}`);

export const getRiskDissectData = ({
  id,
  section,
}: BrinsonAttributionBody): PromiseType<RiskDissectResponse> =>
  get(`/asset/asset-portfolios/risk/dissect/${section}`, { portfolioId: id });

// 获取行业偏好列表
export const getIndustryList = (): PromiseType<Record<string, string>> =>
  get("/funds/industry_preference");

export const getDrawAndIndexFundData = ({
  section,
  customerId,
}: DrawAndIndexFundParams): PromiseType<DrawAndIndexFundResponse> =>
  get(`/customer/analyze/draw-down/trend/${customerId}/${section}`);

export const getDrawAndIndexSummary = ({
  section,
  customerId,
  customerBenchmarkType,
}: DrawAndIndexSummaryParams): PromiseType<DrawAndIndexSummaryResponse> =>
  get(
    `/customer/account/analyze/draw-down/summary/index/${customerId}/${section}`,
    {
      customerBenchmarkType,
    }
  );

export const getBrinsonAttributionSummaryData = ({
  section,
  customerId,
}: BrinsonAttributionSummaryDataParams): PromiseType<BrinsonAttributionSummaryDataResponse> =>
  get(
    `/customer/account/analyze/brinson/summary/index/${customerId}/${section}`
  );

export const getFactorEvaluation = ({
  customerId,
}: FactorEvaluationParams): PromiseType<FactorEvaluationResponse> =>
  get(`/customer/account/analyze/factor/evaluation`, { accountId: customerId });

// 申购赎回记录
export const getCustomerTurnoverRecord = ({
  accountId,
  section,
}: {
  accountId: string;
  section: string;
}): PromiseType<CustomerTurnoverRecordItem[]> =>
  get(`/customer/account/analyze/turnover-record/${accountId}/${section}`);

// 持仓分布走势
export const getCustomerPositionTrend = ({
  accountId,
  section,
}: {
  accountId: string;
  section: string;
}): PromiseType<Record<string, PositionTrendResponse>> =>
  get(
    `/customer/account/analyze/asset-type/scale/trend/${accountId}/${section}`
  );

/**
 * 持仓资产列表
 */
export const getPositionAssetsList = (
  accountId: string
): PromiseType<PositionAssetsListBody> =>
  get(`/customer/position/assets/${accountId}`);

/**
 * 定投管理
 */
export const saveOrUpdateInvestPlanApi = (
  params: SaveOrUpdateInvestPlanParam
): PromiseType<void> =>
  post(`/customer/account/invest_plan/saveOrUpdate`, params);

/**
 * 投资管理-终止定投计划
 */
export const putInvestPlanStatusApi = ({
  accountId,
  assetCode,
  date,
}: PutInvestPlanStatusParam): PromiseType<void> =>
  put(`/customer/account/invest_plan/${accountId}/${assetCode}/${date}`, {});

/**
 * 定投计划详情获取
 */
export const getFixedInvestmentDetail = ({
  accountId,
  assetCode,
}: FixedInvestmentDetailParam): PromiseType<FixedInvestmentDetailBody> =>
  get(`/customer/position/fixed-investment/detail/${accountId}/${assetCode}/`);

/**
 * 资产交易
 */
export const positionAssetTransactionApi = (
  params: PositionAssetTransactionParam
): PromiseType<void> => post(`/customer/position/asset/transaction`, params);

/**
 * 获取基金收益
 */
export const getFundsDailyReturn = ({
  fundCodes,
  section,
}: FundsDailyReturnParam): PromiseType<FundsDailyReturnBody> =>
  post(`/openfunds/daily_return?section=${section}`, fundCodes);

export const getCustomerAssetPositionDetail = ({
  id,
  date,
}: {
  id: string;
  date: string;
}): PromiseType<assetPortfoliosPositionDetail> =>
  get(`customer/position/detail/${id}`, { date });
