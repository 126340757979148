import getMessage from "@/util/getMessage";
import { fastProp, normalize } from "@/util/opt";
import { flow, map, orderBy, take } from "lodash/fp";
import { ReturnContributionProp } from "@/model/aum";
export const performanceDisassembleOptions = [
  {
    label: getMessage("assetCategory"),
    value: "ASSET_CATEGORY",
  },
];

export const getContributionInfo = (contribution: ReturnContributionProp[]) => {
  const newContribution = orderBy(
    (item: Record<string, string | number>) =>
      Math.abs(fastProp("contribution")(item)),
    "asc"
  )(contribution);
  return {
    contributionNames: map(fastProp("name"))(newContribution),
    contributionMap: normalize("name")(newContribution),
  };
};

export const get10ContributionInfo = (
  contribution: ReturnContributionProp[]
) => {
  const newContribution = flow(
    take(10),
    orderBy(
      (item: Record<string, string | number>) =>
        Math.abs(fastProp("contribution")(item)),
      "asc"
    )
  )(contribution);
  return {
    contributionNames: map(fastProp("name"))(newContribution),
    contributionMap: normalize("name")(newContribution),
  };
};
