import { useGetAumPortfolioIndexInfo } from "@/hooks/aum";
import { useFormatMessage } from "@/util/formatMessage";
import DeepPerformance from "@/views/aum/deepPerformance";
import PositionStructure from "@/views/aum/positionStructure";
// import RankList from "@/views/aum/rankList";
import { Tabs } from "antd";
import React, { useState } from "react";

import style from "./index.module.less";

const { TabPane } = Tabs;

export default React.memo(({ customerId }: { customerId: string }) => {
  const [tabKey, setTabKey] = useState("positionStructure");
  const formatMessage = useFormatMessage();
  const indexInfo = useGetAumPortfolioIndexInfo(customerId);
  const name = indexInfo?.name || "";
  return (
    <>
      <Tabs
        defaultActiveKey="positionStructure"
        activeKey={tabKey}
        onChange={setTabKey}
        className={style.Tabs}
      >
        <TabPane
          tab={formatMessage("positionStructure")}
          key="positionStructure"
        >
          <PositionStructure
            id={customerId}
            assetPortfolioId={customerId}
            managerName={name}
          />
        </TabPane>
        <TabPane tab={formatMessage("deepPerformance")} key="deepPerformance">
          <DeepPerformance
            id={customerId}
            assetPortfolioId={customerId}
            managerName={name}
          />
        </TabPane>
      </Tabs>
    </>
  );
});
