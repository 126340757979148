import { useAppSelector } from "@/hooks/redux";
import { CompanyNameInterface, ManagerNameInterface } from "@/model/entities";
import { includeStr, normalize } from "@/util/opt";
import { Select } from "antd";
import { map, prop } from "lodash/fp";
import { useCallback, useMemo, useState } from "react";
import HighLight from "./highLight";

const { Option } = Select;
type nameValueType = ManagerNameInterface & CompanyNameInterface;
type ManagerOrCompanySearchProps = {
  selector: "managerName" | "companyName";
  className?: string;
  placeholder?: string;
  selectValue: string;
  onSelect?: (v: string) => void;
};
const ManagerOrCompanySearch = ({
  selectValue,
  selector,
  onSelect,
  className,
  placeholder,
}: ManagerOrCompanySearchProps): JSX.Element => {
  const data = useAppSelector(prop(`entities.${selector}`));
  const [searchValue, changeSearchValue] = useState<string>();
  const dataMap = useMemo(() => normalize("id")(data), [data]);
  const filterOption = useCallback(
    (input, { value }) => {
      const optionName = prop(`${value}.name`)(dataMap);
      const optionPy = prop(`${value}.py`)(dataMap);
      return (
        includeStr(input)(optionPy) ||
        (includeStr(input)(optionName) as boolean)
      );
    },
    [dataMap]
  );
  return (
    <Select
      className={className}
      showSearch
      placeholder={placeholder}
      value={selectValue}
      onSearch={changeSearchValue}
      filterOption={filterOption}
      onSelect={onSelect}
      size="small"
    >
      {map((v: nameValueType) => (
        <Option key={v.id} value={v.id}>
          <HighLight value={v.name} keyValue={searchValue as string} />
        </Option>
      ))(data)}
    </Select>
  );
};

export default ManagerOrCompanySearch;
