import React, { ReactElement } from "react";
import { Space } from "antd";
import { REQUEST_FAILED } from "@/constant/socket";
import cn from "classnames";
import failedIcon from "@/assets/failedIcon.png";
import { useFormatMessage } from "@/util/formatMessage";
import Progress from "@/components/progress";
import {
  SocketErrorData,
  SocketFinishData,
  SocketProgressData,
} from "@/util/socket";
import { prop } from "lodash/fp";
import style from "./index.module.less";

function TaskResult(props: TaskResultProps): ReactElement {
  const formatMessage = useFormatMessage();
  const progress = prop("progress")(props.task);
  if (progress === REQUEST_FAILED) {
    return (
      <div className={cn(style.BrinsonStatus, props.className)}>
        <Space size={12} direction="vertical" className={style.BrinsonFailed}>
          <img src={failedIcon} />
          <p className={style.FailedTitle}>
            {formatMessage("BrinsonTaskFailed")}
          </p>
          <span className={style.FailedReason}>
            {prop("fieldError.message")(props.task)}
          </span>
        </Space>
      </div>
    );
  }
  return (
    <div className={cn(style.BrinsonStatus, props.className)}>
      <Progress percent={progress} className={style.BrinsonProgress} />
    </div>
  );
}

export default React.memo(TaskResult);

interface TaskResultProps {
  task: SocketErrorData | SocketProgressData | SocketFinishData;
  className?: string;
}
