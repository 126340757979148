import messages from "./zh-messages.json";
// import zhCN from "antd/lib/locale/zh_CN";
import "dayjs/locale/zh-cn";
import zhCN from "antd/locale/zh_CN";

const locales = () => {
  return {
    locale: "zh",
    messages,
    antd: zhCN,
  };
};
export default locales();
