import { SOCKET_AUTHORIZATION } from "@/constant/socket";
import { PromiseType } from "@/model/promise";
import { post, getWithCache, get } from "@/util/axios";
import type {
  LoginEntity,
  LoginResponse,
  RegisterEntity,
  DataSource,
  UserConfig,
  User,
} from "@/model/login";
import { socketHttp } from "@/util/socket";
import { update } from "lodash/fp";
import { fastProp } from "@/util/opt";

export const login = (loginEntity: LoginEntity): PromiseType<LoginResponse> =>
  post("/sessions", loginEntity, {
    withoutAuth: true,
    config: {
      showError: false,
      showLoading: true,
    },
    zip: true,
  });

export const getDataSource = (): Promise<DataSource[]> =>
  getWithCache("/datasource", undefined, {
    withoutAuth: true,
    config: {
      showError: false,
    },
  });

export const register = (registerEntity: RegisterEntity): PromiseType =>
  post("/auth/register", registerEntity);

export const getUserConfig = (moduleId: string): Promise<UserConfig> =>
  get<UserConfig>(`/users/config/${moduleId}`).then((res) => {
    if (!res) return res;
    try {
      return update("config", JSON.parse)(res);
    } catch (error) {
      return undefined;
    }
  });

export const updateUserConfig = ({
  moduleId,
  config,
}: {
  moduleId: string;
  config: any;
}): Promise<void> =>
  post("/users/config/", { moduleId, config: JSON.stringify(config) });

export const socketAuthorization = (
  authorization: string,
  dataSourceId: string
) =>
  socketHttp(SOCKET_AUTHORIZATION, {
    authorization,
    task: {
      dataSourceId,
    },
  });

export const getUserProfile = () =>
  get("/profile", undefined, {
    config: {
      showError: false,
    },
  }).then((res: any) => {
    sessionStorage.setItem("userId", fastProp("id")(res));
    return res;
  });

export const getUsers = (): Promise<User[]> => getWithCache("/users/allUser");
