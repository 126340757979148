import React, { useEffect, useMemo, useState, useContext } from "react";
import { Input, Button } from "antd";
import { filter, includes } from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import {
  usePlatformContentSize,
  PlatformNavigationContext,
} from "@/providers/platformNavigationProvider";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchAllCompetitions } from "@/store/competition";
import { Competition } from "@/model/competition";
import Table from "./table";

import style from "./index.module.less";

const Manager = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();
  const { goToCreateCompetitionPage } = useContext(PlatformNavigationContext);
  const [searchValue, setSearchValue] = useState("");
  const { contentHeight } = usePlatformContentSize();
  const competitions = useAppSelector(
    (state) => state?.competition?.allCompetitions
  );
  const result = useMemo(
    () =>
      filter<Competition>((competition) =>
        includes(searchValue)(competition?.name)
      )(competitions),
    [competitions, searchValue]
  );

  useEffect(() => {
    dispatch(fetchAllCompetitions());
  }, [dispatch]);

  return (
    <div className={style.Container} style={{ height: contentHeight }}>
      <div className={style.Bar}>
        <Input.Search
          className={style.Search}
          onSearch={setSearchValue}
          placeholder={formatMessage("competitionNamePlaceholder")}
          enterButton={formatMessage("inquire")}
        />
        <Button type="primary" onClick={() => goToCreateCompetitionPage({})}>
          {formatMessage("createNewCompetitiion")}
        </Button>
      </div>

      <Table competitions={result} />
    </div>
  );
};

export default React.memo(Manager);
