import socketIO from "socket.io-client";
import { identity } from "lodash/fp";
import { FAILED, REQUEST_FAILED } from "@/constant/socket";
import {
  EmitSocketPayload,
  EmitSocketResult,
  EmitSocketAck,
  FunctionType,
} from "./interface";

const socketStaticOptions = {
  reconnection: false,
  autoConnect: false,
  rejectUnauthorized: false,
  path: "/socket",
  // transports: ["websocket"],
};

export class Socket {
  socket;

  connectionMonitor = false;

  reConnectionTimer: NodeJS.Timeout | null;

  static getSocketNameSpace() {
    return "/socket";
  }

  constructor() {
    this.socket = socketIO(Socket.getSocketNameSpace(), socketStaticOptions);
    // TODO，在最外层捕获socket错误
    this.socket.on("error", identity);
    this.reConnectionTimer = null;
  }

  connected() {
    return this.socket.connected;
  }

  on(eventName: string, callback: FunctionType) {
    this.socket.on(eventName, callback);
    return Promise.resolve();
  }

  off(eventName: string) {
    this.socket.off(eventName);
  }

  connect() {
    if (this.socket.disconnected) {
      this.socket.connect();
    }
    if (!this.connectionMonitor) {
      this.connectionMonitor = true;
      this.reConnectionTimer = global.setInterval(() => {
        if (this.socket.disconnected) {
          this.socket.connect();
        }
      }, 10000);
    }
  }

  close() {
    if (this.reConnectionTimer) {
      global.clearInterval(this.reConnectionTimer);
      this.reConnectionTimer = null;
    }
    this.connectionMonitor = false;
    if (this.socket.connected) {
      this.socket.disconnect();
      this.socket.close();
    }
  }

  once(eventName: string, callback: FunctionType) {
    this.socket.once(eventName, callback);
  }

  emit(
    eventName: string,
    payload: EmitSocketPayload,
    ack: EmitSocketAck = identity
  ) {
    const { socket } = this;
    return new Promise<EmitSocketResult>((resolve, reject) => {
      if (socket && socket.connected) {
        socket.emit(eventName, payload, (data: EmitSocketResult) =>
          resolve(ack(data))
        );
      } else {
        reject({
          status: FAILED,
          progress: REQUEST_FAILED,
          fieldError: {
            message: "Socket 连接已经断开",
          },
        });
      }
    });
  }
}

const socket = new Socket();

export default socket;
