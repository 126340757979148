import React, { useEffect, useState } from "react";
import { useMemoizedFn } from "ahooks";
import { Upload, Button, Space } from "antd";
import type { RcFile } from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";
import { useFormatMessage } from "@/util/formatMessage";
import { getBase64, getSizeLabel, getError } from "./helper";

import style from "./index.module.less";

type ImgInfo = { img: string; imgName: string };
type UploadImgProps = {
  limitSize: number;
  width: number;
  height: number;
  text: string;
  className?: string;
  value?: ImgInfo;
  children?: React.ReactNode;
  onChange?: (value: ImgInfo) => void;
};

const initImgInfo = { img: "", imgName: "" };

const UploadImg = React.memo<UploadImgProps>(
  ({
    limitSize,
    width,
    height,
    text,
    className,
    value,
    children,
    onChange,
  }): JSX.Element => {
    const [error, setError] = useState("");
    const [imgInfo, setImgInfo] = useState<ImgInfo>(value || initImgInfo);
    const formatMessage = useFormatMessage();

    useEffect(() => {
      if (value?.img) {
        setImgInfo({ img: value?.img, imgName: value?.imgName });
      }
    }, [value]);

    const setImgInfoAfterInteractive = useMemoizedFn((_imgInfo) => {
      setImgInfo(_imgInfo);
      onChange?.(_imgInfo);
    });

    const handleBeforeUpload = useMemoizedFn(async (file) => {
      const error = await getError({
        file,
        limitSize,
        width,
        height,
      });
      setError(error);
      if (error) {
        setImgInfoAfterInteractive(initImgInfo);
        return false;
      }
      const preview = await getBase64(file as RcFile);
      setImgInfoAfterInteractive({
        img: file?.url || (preview as string),
        imgName:
          file?.name ||
          (file?.url?.substring(file?.url?.lastIndexOf("/") + 1) as string),
      });
      return false;
    });

    return (
      <Upload
        beforeUpload={handleBeforeUpload}
        maxCount={1}
        isImageUrl={() => true}
        fileList={[]}
        className={className}
        itemRender={() => null}
      >
        <>
          <Space>
            <Button icon={<UploadOutlined />} type="primary">
              {text}
            </Button>
            <div>
              {formatMessage("uploadCompetitionImgLimit", {
                width,
                height,
                size: getSizeLabel(limitSize),
              })}
            </div>
            {/* <span>最大支持{getSizeLabel(limitSize)}</span> */}
          </Space>
          {error && <div className={style.Error}>{error}</div>}
          {imgInfo?.img && (
            <div className={style.ImgPanel}>
              <div className={style.ImgContent}>
                <span className={style.PreviewTitle}>{imgInfo?.imgName}</span>
                {children ? children : null}
              </div>
              <img alt="example" src={imgInfo?.img} />
            </div>
          )}
        </>
      </Upload>
    );
  }
);

export default UploadImg;
