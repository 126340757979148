import React, { useMemo } from "react";
import { useFormatMessage } from "@/util/formatMessage";

import style from "../index.module.less";
import { first, flow, isEqual, join, prop, split } from "lodash/fp";
import { useAppSelector } from "@/hooks/redux";
import { EvaluationReportRiskSummaryBody } from "@/model/fundDetail";
import { fastProp, mapIndexed } from "@/util/opt";
import {
  getProsperity,
  getMatchDegree,
  CHART_COLOR,
  riskLevel,
} from "../constants";
import SecondLevelName from "../../../secondLevelName";

export default React.memo(({ fundId }: { fundId: string }) => {
  const formatMessage = useFormatMessage();

  // const current = 55;
  // const history = 62;
  const total = 1;

  const marketCapabilityDetail = useAppSelector(
    prop(`fundDetail.${fundId}.riskDetail`)
  );

  const evaluationReportRiskSummary: EvaluationReportRiskSummaryBody =
    useAppSelector(prop(`fundDetail.${fundId}.evaluationReportRiskSummary`));

  const { styleRisk } = evaluationReportRiskSummary || {};

  const fundStyle = useMemo(() => {
    const fundStyleMap = {
      g: formatMessage("fundStyle_G"),
      v: formatMessage("fundStyle_V"),
      m: formatMessage("fundStyle_M"),
    };
    return flow(
      fastProp("fundStyle"),
      split("MyTag_rating23_"),
      join(""),
      first,
      (val) => fastProp(val)(fundStyleMap)
    )(styleRisk);
  }, [formatMessage, styleRisk]);

  const { styleRiskDetail } = marketCapabilityDetail || {};

  const { curFundStyleValue, historyFundStyleValue } = styleRiskDetail || {};

  const compareHistory = useMemo(() => {
    if (isEqual(curFundStyleValue)(historyFundStyleValue)) {
      return formatMessage("fit");
    }
    if (curFundStyleValue <= 0.3 && historyFundStyleValue <= 0.3) {
      return formatMessage("fit");
    }
    if (
      curFundStyleValue > 0.3 &&
      curFundStyleValue < 0.7 &&
      historyFundStyleValue > 0.3 &&
      historyFundStyleValue < 0.7
    ) {
      return formatMessage("fit");
    }
    if (
      curFundStyleValue >= 0.7 &&
      curFundStyleValue <= 1 &&
      historyFundStyleValue >= 0.7 &&
      historyFundStyleValue <= 1
    ) {
      return formatMessage("fit");
    }
    return formatMessage("atypism");
  }, [formatMessage, curFundStyleValue, historyFundStyleValue]);

  const getLeftVal = (value: number) => `${(value / total) * 100}%`;

  return (
    <div className={style.StyleDynamicAlertWrap}>
      <SecondLevelName name={formatMessage("styleDynamicAlert")}>
        <span className={style.RiskStatus1}>
          {styleRisk?.riskLevel && (
            <>
              {formatMessage("risk")}
              {getProsperity(styleRisk?.riskLevel)}
            </>
          )}
        </span>
      </SecondLevelName>
      <div className={style.Text}>
        {formatMessage("styleDynamicAlertSummary", {
          fundStyle: fundStyle,
          compareHistory: compareHistory,
          matchDegree: getMatchDegree(styleRisk?.riskLevel),
        })}
      </div>
      <div className={style.ChartWrap}>
        <div className={style.Chart}>
          {mapIndexed((key: string, idx: number) => (
            <div key={idx} style={{ backgroundColor: CHART_COLOR[idx] }}>
              {formatMessage(key)}
            </div>
          ))(riskLevel)}
        </div>
        <div className={style.Arrows1}>
          <div style={{ left: getLeftVal(styleRiskDetail?.curFundStyleValue) }}>
            {formatMessage("current")}
          </div>
          <div
            style={{ left: getLeftVal(styleRiskDetail?.historyFundStyleValue) }}
          >
            {formatMessage("History")}
          </div>
        </div>
      </div>
    </div>
  );
});
