import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { prop } from "lodash/fp";
import {
  getDailyReturns,
  DailyReturns,
  getFundSameTypeReturns,
  FundSameTypeBody,
} from "@/api/dailyReturns";
import { getFundsDailyReturn } from "@/api/replaceFund";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { normalize } from "@/util/opt";

export const fetchFundAndBenchmarkDailyReturns = avoidMultiRequestActionThunk(
  (id) => `dailyReturns.${id}`,
  createAsyncThunk("dailyReturns/getDailyReturns", async (id: string) => {
    const response = await getDailyReturns(id);
    return response;
  })
);

export const fetchFundsDailyReturns = createAsyncThunk(
  "dailyReturns/fundsDailyReturns",
  async (fundIds: string[]) => {
    const response = await getFundsDailyReturn(fundIds);
    return response;
  }
);

export const fetchFundSameTypeDailyReturns = createAsyncThunk(
  "dailyReturns/fundSameTypeReturns",
  async (params: FundSameTypeBody) => {
    const response = await getFundSameTypeReturns(params);
    return response;
  }
);

type SectorCategoryStore = Record<string, DailyReturns>;

const initialState: SectorCategoryStore = {};

const dailyReturnsSlice = createSlice({
  name: "dailyReturns",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFundAndBenchmarkDailyReturns.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = action.payload;
    },
    [fetchFundsDailyReturns.fulfilled.type]: (state, action) => {
      Object.assign(
        state,
        normalize("id", prop("dailyReturn"))(action.payload)
      );
    },
    [fetchFundSameTypeDailyReturns.fulfilled.type]: (state, action) => {
      state[action.meta?.arg?.fundType] = action.payload;
    },
  },
});

export default dailyReturnsSlice.reducer;
