import { useAppSelector } from "@/hooks/redux";
import { CheckBoxItem } from "@/model/checkBoxGroup";
import { FundSecondTypes, FundTypeTags } from "@/model/factorFund";
import { industryThemeListMapSelector } from "@/selectors/fundTheme";
import { fastProp, normalize } from "@/util/opt";
import {
  getSelectFilterTagRangeOptions,
  getSelectFilterTagOptions,
} from "@/views/factorFund/fundManagersFilter";
import { flatten, isEmpty } from "lodash";
import {
  assign,
  flow,
  keys,
  map,
  pick,
  reduce,
  toArray,
  values,
} from "lodash/fp";
import { useMemo } from "react";
import { statisticRange } from "./statisticRange";

export const UNLIMITED = "ALL";

export const unlimitedAll = (
  formatMessage: (arg0: string) => string
): CheckBoxItem => ({
  id: UNLIMITED,
  name: formatMessage("unlimited"),
});

export const fundFiskLevel = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "R1",
    name: formatMessage("R1"),
  },
  {
    id: "R2",
    name: formatMessage("R2"),
  },
  {
    id: "R3",
    name: formatMessage("R3"),
  },
  {
    id: "R4",
    name: formatMessage("R4"),
  },
  {
    id: "R5",
    name: formatMessage("R5"),
  },
];

export const fundStyleStability = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "EXTREMELY_STABLE",
    name: formatMessage("HIGHEST"),
  },
  {
    id: "RELATIVELY_STABLE",
    name: formatMessage("HIGH"),
  },
  {
    id: "MODERATE_STABLE",
    name: formatMessage("MEDIUM"),
  },
  {
    id: "UNSTABLE",
    name: formatMessage("LOW"),
  },
  {
    id: "EXTREMELY_UNSTABLE",
    name: formatMessage("LOWEST"),
  },
];

// 大于 >
export const GREATER_THAN = "GREATER_THAN";
// 大于等于 >=
export const GREATER_THAN_OR_EQUALS = "GREATER_THAN_OR_EQUALS";
// 交集
export const INTERSECTION = "INTERSECTION";
// 并集
export const UNION = "UNION";

export const ratingTags = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "1",
    name: formatMessage("oneStar"),
  },
  {
    id: "2",
    name: formatMessage("twoStar"),
  },
  {
    id: "3",
    name: formatMessage("threeStar"),
  },
  {
    id: "4",
    name: formatMessage("fourStar"),
  },
  {
    id: "5",
    name: formatMessage("fiveStar"),
  },
];
export const fundStyleTags = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "MyTag_rating23_gh",
    name: formatMessage("MyTag_rating23_gh"),
  },
  {
    id: "MyTag_rating23_mh",
    name: formatMessage("MyTag_rating23_mh"),
  },
  {
    id: "MyTag_rating23_vh",
    name: formatMessage("MyTag_rating23_vh"),
  },
  {
    id: "MyTag_rating23_gm",
    name: formatMessage("MyTag_rating23_gm"),
  },
  {
    id: "MyTag_rating23_mm",
    name: formatMessage("MyTag_rating23_mm"),
  },
  {
    id: "MyTag_rating23_vm",
    name: formatMessage("MyTag_rating23_vm"),
  },
  {
    id: "MyTag_rating23_gl",
    name: formatMessage("MyTag_rating23_gl"),
  },
  {
    id: "MyTag_rating23_ml",
    name: formatMessage("MyTag_rating23_ml"),
  },
  {
    id: "MyTag_rating23_vl",
    name: formatMessage("MyTag_rating23_vl"),
  },
];

// 按排名
export const getListByRank = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "0.1",
    name: formatMessage("TopPercent10"),
  },
  {
    id: "0.25",
    name: formatMessage("TopPercent25"),
  },
  {
    id: "0.5",
    name: formatMessage("TopPercent50"),
  },
  {
    id: "0.75",
    name: formatMessage("TopPercent75"),
  },
];

// 收益按取值
export const yieldListByValue = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "0.05",
    name: formatMessage("GreaterThanTopPercent5"),
  },
  {
    id: "0.1",
    name: formatMessage("GreaterThanTopPercen10"),
  },
  {
    id: "0.2",
    name: formatMessage(" GreaterThanTopPercen20"),
  },
  {
    id: "0.5",
    name: formatMessage("GreaterThanTopPercen50"),
  },
];

export const riskListByValue = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "0.05",
    name: formatMessage("LessThanTopPercent5"),
  },
  {
    id: "0.1",
    name: formatMessage("LessThanTopPercent10"),
  },
  {
    id: "0.2",
    name: formatMessage("LessThanTopPercent20"),
  },
  {
    id: "0.5",
    name: formatMessage("LessThanTopPercent50"),
  },
];

// 成立时间
export const getFoundDate = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "UNDER_ONE_YEAR",
    name: formatMessage("underOneYear"),
  },
  {
    id: "YEAR_TO_THREE_YEAR",
    name: formatMessage("yearToThreeYear"),
  },
  {
    id: "THREE_YEAR_TO_FIVE_YEAR",
    name: formatMessage("threeYearToFiveYear"),
  },
  {
    id: "MORE_THAN_FIVE_YEAR",
    name: formatMessage("moreThanFiveYear"),
  },
];

// 基金规模
export const getFundScale = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "UNDER_ONE",
    name: formatMessage("UNDER_ONE"),
  },
  {
    id: "ONE_TO_FIVE",
    name: formatMessage("ONE_TO_FIVE"),
  },
  {
    id: "FIVE_TO_TEN",
    name: formatMessage("FIVE_TO_TEN"),
  },
  {
    id: "MORE_THAN_TEN",
    name: formatMessage("MORE_THAN_TEN"),
  },
];

// 运作方式
export const getOperateModes = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "OPEN",
    name: formatMessage("OPEN"),
  },
  {
    id: "CLOSED",
    name: formatMessage("CLOSED"),
  },
];

// 基金经理人数
export const fundManagerNums = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "ONE",
    name: formatMessage("ONE"),
  },
  {
    id: "TWO",
    name: formatMessage("TWO"),
  },
  {
    id: "THREE",
    name: formatMessage("THREE"),
  },
];

// 申购状态
export const purchaseTag = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "PURCHASE_T",
    name: formatMessage("PURCHASE_T"),
  },
  {
    id: "PURCHASE_F",
    name: formatMessage("PURCHASE_F"),
  },
  {
    id: "LIMIT_LARGE_PURCHASE",
    name: formatMessage("LIMIT_LARGE_PURCHASE"),
  },
  {
    id: "CLOSED",
    name: formatMessage("CLOSEDTIME"),
  },
  {
    id: "FLOOR_TRADING",
    name: formatMessage("FLOOR_TRADING"),
  },
  {
    id: "SUSPEND_TRADING",
    name: formatMessage("SUSPEND_TRADING"),
  },
  {
    id: "FUND_STOP",
    name: formatMessage("FUND_STOP"),
  },
];
// 赎回状态
export const redemptionTag = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "REDEMPTION_T",
    name: formatMessage("REDEMPTION_T"),
  },
  {
    id: "REDEMPTION_F",
    name: formatMessage("REDEMPTION_F"),
  },
  {
    id: "CLOSED",
    name: formatMessage("CLOSEDTIME"),
  },
  {
    id: "FLOOR_TRADING",
    name: formatMessage("FLOOR_TRADING"),
  },
  {
    id: "SUSPEND_TRADING",
    name: formatMessage("SUSPEND_TRADING"),
  },
  {
    id: "FUND_STOP",
    name: formatMessage("FUND_STOP"),
  },
];
// 大类资产
export const assetTypes = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "EQUITY",
    name: formatMessage("EQUITY"),
  },
  {
    id: "FIXED",
    name: formatMessage("FIXED"),
  },
  {
    id: "MONEY",
    name: formatMessage("MONEY"),
  },
  {
    id: "COMMODITY",
    name: formatMessage("COMMODITY"),
  },
];

// 任职时长
export const workTimeTag = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "UNDER_ONE_YEAR",
    name: formatMessage("underOneYear"),
  },
  {
    id: "YEAR_TO_THREE_YEAR",
    name: formatMessage("yearToThreeYear"),
  },
  {
    id: "THREE_YEAR_TO_FIVE_YEAR",
    name: formatMessage("threeYearToFiveYear"),
  },
  {
    id: "MORE_THAN_FIVE_YEAR",
    name: formatMessage("moreThanFiveYear"),
  },
];

// 学历要求
export const educationTag = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "GRADUATE",
    name: formatMessage("GRADUATE"),
  },
  {
    id: "MASTER",
    name: formatMessage("MASTER"),
  },
  {
    id: "DOCTOR",
    name: formatMessage("DOCTOR"),
  },
];

// 管理规模
export const manageScales = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "UNDER_ONE",
    name: formatMessage("UNDER_ONE"),
  },
  {
    id: "ONE_TO_FIVE",
    name: formatMessage("ONE_TO_FIVE"),
  },
  {
    id: "FIVE_TO_TEN",
    name: formatMessage("FIVE_TO_TEN"),
  },
  {
    id: "MORE_THAN_TEN",
    name: formatMessage("MORE_THAN_TEN"),
  },
];

// 管理基金
export const getFundNums = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "ONE",
    name: formatMessage("oneNum"),
  },
  {
    id: "TWO",
    name: formatMessage("twoNum"),
  },
  {
    id: "MORE_THAN_THREE",
    name: formatMessage("moreThanThreeNum"),
  },
];

// 业绩要求
export const getAchievementTag = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "0.1",
    name: formatMessage("TopPercent10"),
  },
  {
    id: "0.25",
    name: formatMessage("TopPercent25"),
  },
  {
    id: "0.5",
    name: formatMessage("TopPercent50"),
  },
  {
    id: "0.75",
    name: formatMessage("TopPercent75"),
  },
];

// 基金数量
export const getFundNumberTag = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "UNDER_TWO_HUNDRED",
    name: formatMessage("UNDER_TWO_HUNDRED"),
  },
  {
    id: "TWO_HUNDRED_TO_THREE_HUNDRED",
    name: formatMessage("TWO_HUNDRED_TO_THREE_HUNDRED"),
  },
  {
    id: "MORE_THAN_THREE_HUNDRED",
    name: formatMessage("MORE_THAN_THREE_HUNDRED"),
  },
];

// 经理数量
export const getCompanyManagerNumTag = (
  formatMessage: (arg0: string) => string
): CheckBoxItem[] => [
  {
    id: "UNDER_TWENTY",
    name: formatMessage("UNDER_TWENTY"),
  },
  {
    id: "TWENTY_TO_FORTY",
    name: formatMessage("TWENTY_TO_FORTY"),
  },
  {
    id: "FORTY_TO_SIXTY",
    name: formatMessage("FORTY_TO_SIXTY"),
  },
  {
    id: "MORE_THAN_SIXTY",
    name: formatMessage("MORE_THAN_SIXTY"),
  },
];
export type FilterSelected = {
  tags: any;
  messageKey: string;
  tagKey?: string;
  anotherTags?: any;
  options?: any;
  type?: string;
};
export const useClassicFilterSelected = (
  formatMessage: (arg0: string) => string
): Record<string, FilterSelected> => {
  const fundTypeTagArr = useAppSelector(
    (state) => state.factorFund.fundTypeTags
  );
  const fundTypeTagsMap = flow(
    map(({ fundFirstType, fundSecondTypes, name }: FundTypeTags) => {
      if (isEmpty(fundSecondTypes))
        return {
          name,
          id: fundFirstType,
        };
      return map(({ fundSecondType, name }: FundSecondTypes) => ({
        name,
        id: fundSecondType,
      }))(fundSecondTypes);
    }),
    flatten,
    normalize("id")
  )(fundTypeTagArr);
  const industryPreference = useAppSelector(
    (state) => state.factorFund.industryPreference
  );
  const industryAllData = flow(
    values,
    reduce((result, item) => assign(result, item), {})
  )(industryPreference);
  const swIndustryMap = flow(
    keys,
    map((v: string) => ({
      id: v,
      name: fastProp(v)(industryAllData),
    })),
    normalize("id")
  )(industryAllData);
  const industryThemeListMap = useAppSelector(industryThemeListMapSelector);
  const industryThemeList = useMemo(
    () => flow(pick(hotThemeIds), toArray)(industryThemeListMap),
    [industryThemeListMap]
  );
  return useMemo(
    () => ({
      fundTypeTag: {
        tags: fundTypeTagsMap,
        messageKey: "fundTypeTag",
      },
      foundDateTag: {
        tags: normalize("id")(getFoundDate(formatMessage)),
        messageKey: "foundDateTag",
        tagKey: "foundDate",
        type: "tagKey",
      },
      fundScaleTag: {
        tags: normalize("id")(getFundScale(formatMessage)),
        messageKey: "fundScaleTag",
        tagKey: "fundScale",
        type: "tagKey",
      },
      ratingTag: {
        tags: normalize("id")(ratingTags(formatMessage)),
        messageKey: "ratingTag",
      },
      operateModeTag: {
        tags: normalize("id")(getOperateModes(formatMessage)),
        messageKey: "operateModeTag",
      },
      purchaseTag: {
        tags: normalize("id")(purchaseTag(formatMessage)),
        messageKey: "purchaseStatus",
      },
      redemptionTag: {
        tags: normalize("id")(redemptionTag(formatMessage)),
        messageKey: "redemption",
      },
      fundManagerNumTag: {
        tags: normalize("id")(fundManagerNums(formatMessage)),
        messageKey: "fundManagerNumTag",
      },
      fundStyleTag: {
        tags: normalize("id")(fundStyleTags(formatMessage)),
        messageKey: "stockStyle",
      },
      fundIndustryTag: {
        tags: swIndustryMap,
        messageKey: "fundIndustryTag",
        tagKey: "industryIds",
        type: "tagKey",
      },
      assetTypeTag: {
        tags: normalize("id")(assetTypes(formatMessage)),
        messageKey: "assetTypeTag",
      },
      fundYieldTag: {
        tags: normalize("id")(yieldListByValue(formatMessage)),
        messageKey: "fundYieldTag",
        anotherTags: normalize("id")(getListByRank(formatMessage)),
        options: statisticRange,
        tagKey: "id",
        type: "anotherTags",
      },
      fundRiskTag: {
        tags: normalize("id")(riskListByValue(formatMessage)),
        messageKey: "fundRiskTag",
        anotherTags: normalize("id")(getListByRank(formatMessage)),
        options: statisticRange,
        tagKey: "id",
        type: "anotherTags",
      },
      fundManagerIdTag: {
        tags: {},
        messageKey: "fundManagers",
        type: "fundManagerIdTag",
      },
      workTimeTag: {
        tags: normalize("id")(workTimeTag(formatMessage)),
        messageKey: "workTimeTag",
        tagKey: "foundDate",
        type: "tagKey",
      },
      educationTag: {
        tags: normalize("id")(educationTag(formatMessage)),
        messageKey: "educationTag",
      },
      achievementTag: {
        tags: normalize("id")(getAchievementTag(formatMessage)),
        messageKey: "achievementTag",
        tagKey: "range",
        type: "tagKey",
        options: normalize("value")(
          getSelectFilterTagRangeOptions(formatMessage)
        ),
      },
      managerManageScaleTag: {
        tags: normalize("id")(manageScales(formatMessage)),
        messageKey: "managerManageScaleTag",
        tagKey: "manageScales",
        options: normalize("value")(
          getSelectFilterTagOptions(formatMessage, "Scale")
        ),
        type: "tagKey",
      },
      manageFundNumTag: {
        tags: normalize("id")(getFundNums(formatMessage)),
        messageKey: "manageFundNumTag",
        tagKey: "fundNums",
        options: normalize("value")(
          getSelectFilterTagOptions(formatMessage, "Number")
        ),
        type: "tagKey",
      },
      fundCompanyIdTag: {
        tags: {},
        messageKey: "fundCompanys",
        type: "fundCompanyIdTag",
      },
      companyFoundDateTag: {
        tags: normalize("id")(getFoundDate(formatMessage)),
        messageKey: "companyFoundDate",
        tagKey: "foundDate",
        type: "tagKey",
      },
      companyManageScaleTag: {
        tags: normalize("id")(getFundScale(formatMessage)),
        messageKey: "managerManageScaleTag",
        tagKey: "fundScale",
        type: "tagKey",
      },
      fundNumberTag: {
        tags: normalize("id")(getFundNumberTag(formatMessage)),
        messageKey: "fundNumberTag",
      },
      companyManagerNumTag: {
        tags: normalize("id")(getCompanyManagerNumTag(formatMessage)),
        messageKey: "managerNums",
      },
      fundStyleGrowthRatingTag: {
        tags: normalize("id")(ratingTags(formatMessage)),
        messageKey: "fundStyleGrowthRatingTag",
      },
      fundStyleValueRatingTag: {
        tags: normalize("id")(ratingTags(formatMessage)),
        messageKey: "fundStyleValueRatingTag",
      },
      fundStyleMassRatingTag: {
        tags: normalize("id")(ratingTags(formatMessage)),
        messageKey: "fundStyleMassRatingTag",
      },
      fundStyleCmpstRatingTag: {
        tags: normalize("id")(ratingTags(formatMessage)),
        messageKey: "fundStyleCmpstRatingTag",
      },
      fundRiskLevelList: {
        tags: normalize("id")(fundFiskLevel(formatMessage)),
        messageKey: "fofriskLevel",
      },
      fundStabilityList: {
        tags: normalize("id")(fundStyleStability(formatMessage)),
        messageKey: "styleStability",
      },
      fundThemeList: {
        tags: normalize("id")(industryThemeList),
        messageKey: "fundTheme",
      },
    }),
    [formatMessage, fundTypeTagsMap, swIndustryMap, industryThemeList]
  );
};

export const hotThemeIds: string[] = [
  "1000027509000000",
  "1000043345000000",
  "1000041219000000",
  "1000022533000000",
  "1000022514000000",
  "1000022504000000",
  "1000022523000000",
  "1000022502000000",
  "1000022640000000",
  "1000022498000000",
  "1000022575000000",
  "1000022620000000",
  "1000022617000000",
  "1000022625000000",
  "1000022627000000",
  "1000027463000000",
  "1000022579000000",
  "1000022550000000",
];
