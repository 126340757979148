import React, { useEffect, useMemo, useRef } from "react";
import type { LineSeriesOption, ScatterSeriesOption } from "echarts";
import { defaultColors } from "@/util/colors";
import EchartsWrapper, {
  WrapperProps,
} from "../../../../echarts/echartsWrapper";
import { fixedHundred } from "@/util/numberFormatter";
import { mapIndexed } from "@/util/opt";
import { FUND } from "@/views/myOptional/constant";
import { getScatterTooltipItem, getTooltipItem } from "@/util/chart";
import { dataZoomConfig } from "@/base-components/charts/helper";
import {
  dealScatterTipData,
  dealTipData,
} from "../../heldAssetsManage/wareHouseRecord/hook";
import { STOCK } from "../../addCustomer/components/addAssetPortfolio/hooks";
import getMessage from "@/util/getMessage";

export interface LineOrScatterChartOpts extends WrapperProps {
  dates: string[];
  series?: (LineSeriesOption | ScatterSeriesOption)[];
  color?: string[];
  showLegend?: boolean;
}

export default React.memo<LineOrScatterChartOpts>(
  ({
    series,
    events = {},
    width,
    height,
    dates,
    showDataZoom = true,
    color = defaultColors,
    showLegend = false,
  }) => {
    const handleOptions = useMemo<LineOrScatterChartOpts["options"]>(
      () => ({
        grid: {
          top: 60,
          right: 40,
        },
        xAxis: {
          data: dates,
          nameGap: 40,
          axisPointer: { type: "line" },
          splitLine: {
            lineStyle: {
              width: 1,
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: "单位：万元",
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            formatter: (value: number) => {
              return fixedHundred(value);
            },
          },
        },
        legend: {
          top: 10,
          left: 10,
          show: showLegend,
        },
        dataZoom: dataZoomConfig,
        tooltip: {
          trigger: "axis",
          enterable: true,
          position: (point) => point,
          formatter: (params: any) => {
            let res = `<div style="position:sticky;top:0;left:0;background:white;">${params[0].axisValue}</div>`;
            mapIndexed((_: any, index: number) => {
              if (params[index].seriesType === "scatter") {
                const assetName = params[index].value[3];
                const accountName = params[index].value[4];
                const assetType = params[index].value[5];
                const value = dealScatterTipData(
                  params[index].value[2],
                  assetType
                );
                let assetTypeName = "";
                if (assetType === STOCK) {
                  assetTypeName = getMessage("STOCK");
                }
                if (assetType === FUND) {
                  assetTypeName = getMessage("OPEN_FUND");
                }
                const seriesName = `${assetTypeName}${params[index].seriesName}`;
                // res = `<div>${assetName}:</div>` + res;
                res += getScatterTooltipItem(
                  params[index].color,
                  assetName,
                  seriesName,
                  value,
                  accountName
                );
              } else if (params[index].seriesType === "line") {
                const value = dealTipData(params[index].value[1]);
                res += getTooltipItem(
                  params[index].color,
                  params[index].seriesName,
                  value,
                  10,
                  5
                );
              }
            })(params);
            return `<div style="max-height:300px;overflow:auto;padding-bottom:4px;">${res}</div>`;
          },
        },
        color: color,
        series: series,
      }),
      [color, series, dates, showLegend]
    );
    const chartRef = useRef<any>(null);
    useEffect(() => {
      if (chartRef.current) {
        chartRef.current?.resize();
      }
    }, []);
    return (
      <EchartsWrapper
        width={width}
        height={height}
        options={handleOptions}
        showDataZoom={showDataZoom}
        events={events}
      />
    );
  }
);
