import { fastHas, fastNth } from "@/util/opt";
import { filter, flow, isArray, isEmpty, map, reject } from "lodash/fp";

export const MAX_COMPARE_SELECT_SIZE = 4;

export const CSI300_ID = "idx_000300sh";

export const uniqManagerIds = (managerIds: string[][]) => {
  const uniqManagerIdsMap: Record<string, boolean> = {};
  return flow(
    map(
      filter((id: string) => {
        if (fastHas(id)(uniqManagerIdsMap)) return false;
        uniqManagerIdsMap[id] = true;
        return true;
      })
    ),
    reject(isEmpty)
  )(managerIds);
};

export const validateIsFundRelatedManager = (
  managerIds?: string[] | string[][]
) => {
  if (!managerIds) return false;
  return isArray(fastNth(0)(managerIds));
};
