import { Select } from "antd";
import type { SelectProps } from "antd/es/select";
import React, { useMemo } from "react";
import { map } from "lodash/fp";
import cn from "classnames";
import { useAppSelector } from "@/hooks/redux";
import type { category } from "@/model/sectorCategory";
import { LabeledValue } from "antd/lib/select";
import style from "./sectorCategoriesSelect.module.less";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { fastProp } from "@/util/opt";
import { sectorIdList } from "../constant/sectors";

export default React.memo<SelectProps<string>>(({ className, ...extra }) => {
  const sectorCategoriesMap = useAppSelector(sectorCategoriesMapSelector);
  const options = useMemo(() => {
    const defaultSectorCategories = map((id: string) =>
      fastProp(id)(sectorCategoriesMap)
    )(sectorIdList);
    // fastProp(defaultSectorId)(sectorCategoriesMap);
    if (defaultSectorCategories) {
      return map<category, LabeledValue>(({ id, name }) => ({
        label: name,
        value: id,
      }))(defaultSectorCategories);
    }
    return [];
  }, [sectorCategoriesMap]);
  return (
    <Select
      className={cn(style.sectorCategorySelect, className)}
      {...extra}
      options={options}
    />
  );
});
