import { useFormatMessage } from "@/util/formatMessage";
import { Form, Radio, RadioChangeEvent, Space } from "antd";
import React from "react";
import { selectConfigurationTargets } from "../constant";
import style from "../index.module.less";
import InfoIcon from "@/assets/infoIcon.svg";
import NumberInput from "@/components/numberInput";
import RiskFreeRate from "@/views/portfolioManage/fundConfiguration/fundAllocation/components/riskFreeRate";
import { constrainsItem, modelComponentProps } from "../../interface";
import { fastProp } from "@/util/opt";
import { map, prop } from "lodash/fp";
import CategoryWeightInput from "./categoryWeightInput";
import { useMeanVarianceModel } from "../hooks";
import { categoryTypeMap } from "../../constant";
import ErrorField from "../../../manualCreatePortfolio/components/errorField";
import cn from "classnames";
import { useGetIndexInfo, useGetModelDurationOptions } from "./hooks";

const FormItem = Form.Item;
export default React.memo<modelComponentProps>(
  ({
    modelAllocationData,
    updateModelAllocation,
    categoryModelErrorField,
    buttonDisabled,
  }) => {
    const formatMessage = useFormatMessage();
    const {
      goal,
      dataRange,
      goalConfig,
      constrains,
      categorySource,
      onChangeGoalConfig,
      updateAllocateConfig,
      updateConstrains,
    } = useMeanVarianceModel({
      modelAllocationData,
      updateModelAllocation,
      buttonDisabled,
    });
    const { factorMap } = useGetIndexInfo();
    const { modelOptions } = useGetModelDurationOptions();
    return (
      <div>
        <FormItem
          labelCol={{ span: 3 }}
          labelAlign="left"
          label={formatMessage("SelectConfigurationTarget")}
        >
          <Radio.Group
            className={style.RadioGroupItem}
            options={selectConfigurationTargets}
            value={goal}
            onChange={(e: RadioChangeEvent) =>
              updateAllocateConfig("goal")(e.target.value)
            }
          />
        </FormItem>
        {goal === "MIN_RISK" && (
          <FormItem
            labelCol={{ span: 3 }}
            labelAlign="left"
            label={formatMessage("yearExpectedReturnDescription")}
          >
            <NumberInput
              type="PERCENTAGE"
              min={0}
              max={1}
              precision={2}
              allowClear
              size="small"
              placeholder="0~100"
              addonBefore=">="
              addonAfter="%"
              className={style.RiskInput}
              value={fastProp("annualizedMinReturn")(goalConfig)}
              onChange={onChangeGoalConfig}
            />
          </FormItem>
        )}
        {goal === "MAX_RETURN" && (
          <FormItem
            labelCol={{ span: 3 }}
            labelAlign="left"
            label={formatMessage("annualRisk")}
          >
            <NumberInput
              type="PERCENTAGE"
              min={0}
              max={1}
              precision={2}
              allowClear
              size="small"
              placeholder="0~100"
              addonBefore="<="
              addonAfter="%"
              className={cn({
                [style.RiskInput]: true,
                [style.InputError]: categoryModelErrorField.riskValueError,
              })}
              value={fastProp("annualizedMaxRisk")(goalConfig)}
              onChange={onChangeGoalConfig}
            />
            {categoryModelErrorField.riskValueError && (
              <ErrorField
                message={formatMessage("pleaseInputRiskMaxValue")}
                className={style.MarginTop8}
              />
            )}
          </FormItem>
        )}
        {goal === "MAX_SHARP_RATIO" && (
          <FormItem
            labelCol={{ span: 3 }}
            labelAlign="left"
            label={formatMessage("riskFreeRate")}
          >
            <RiskFreeRate
              value={fastProp("riskFreeConfig")(goalConfig)}
              onChange={onChangeGoalConfig}
            />
          </FormItem>
        )}
        <FormItem
          labelCol={{ span: 3 }}
          labelAlign="left"
          label={formatMessage("ModelDuration")}
        >
          <Radio.Group
            className={style.ModelDuration}
            optionType="button"
            options={modelOptions}
            value={dataRange}
            onChange={(e: RadioChangeEvent) =>
              updateAllocateConfig("dataRange")(e.target.value)
            }
          />
        </FormItem>
        {/* <div className={cn(style.DescriptionTip, style.RiskDescription)}>
          <img src={InfoIcon} className={style.AlertIcon} />
          <div>
            <p>{formatMessage("modelAllocationDescriptionOne")}</p>
            <p>{formatMessage("modelAllocationDescriptionTwo")}</p>
          </div>
        </div> */}
        <FormItem
          label={formatMessage("CategoryWeightConstraints")}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
        >
          <Space wrap>
            {map((v: constrainsItem) => (
              <CategoryWeightInput
                label={
                  categorySource === "ASSET_CATEGORY"
                    ? prop([v.id, "label"])(categoryTypeMap)
                    : prop([v.id, "name"])(factorMap)
                }
                maxValue={v.maxWeight}
                minValue={v.minWeight}
                onChangeMaxValue={updateConstrains("maxWeight", v.id)}
                onChangeMinValue={updateConstrains("minWeight", v.id)}
                key={v.id}
              />
            ))(constrains)}
          </Space>
          {buttonDisabled && (
            <p className={style.MaxTips}>* {formatMessage("maxWeightTips")}</p>
          )}
        </FormItem>
        <div className={style.DescriptionTip}>
          <img src={InfoIcon} className={style.AlertIcon} />
          <p>{formatMessage("modelAllocationRiskDescription")}</p>
        </div>
      </div>
    );
  }
);
