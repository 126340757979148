import {
  CreateScenarioParams,
  getScenarioListApi,
  createScenarioApi,
  deleteScenarioApi,
} from "@/api/portfolioAnalysis";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ScenarioListResponse } from "@/model/portfolioAnalysis";

export const fetchPortfolioScenarioList = avoidMultiRequestActionThunk<void>(
  "scenarioList.scenarioList",
  createAsyncThunk("scenario/scenarioList", async () => {
    const response = await getScenarioListApi();
    return response;
  })
);

export const createScenario = createAsyncThunk(
  "scenario/createScenario",
  async (params: CreateScenarioParams) => {
    const response = await createScenarioApi(params);
    return response;
  }
);

export const deleteScenario = createAsyncThunk(
  "scenario/deleteScenario",
  async (id: string) => {
    const response = await deleteScenarioApi(id);
    return response;
  }
);
interface initScenario {
  scenarioList: ScenarioListResponse[];
}

const initialState: initScenario = {
  scenarioList: [],
};

const scenarioSlice = createSlice({
  name: "scenario",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPortfolioScenarioList.fulfilled.type]: (state, action) => {
      state.scenarioList = action.payload;
    },
    [createScenario.fulfilled.type]: (state, action) => {
      state.scenarioList = state.scenarioList.concat(action.payload);
    },
    [deleteScenario.fulfilled.type]: (state, action) => {
      const removeId = action?.meta?.arg;
      if (removeId) {
        state.scenarioList = state.scenarioList.filter(
          ({ id }) => id !== removeId
        );
      }
    },
  },
});
export default scenarioSlice.reducer;
