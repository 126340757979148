import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { Card, Row, Col, Space, Table, TableColumnProps } from "antd";
import {
  concat,
  flow,
  fromPairs,
  keys,
  map,
  prop,
  forEach,
  orderBy,
  sumBy,
} from "lodash/fp";
import React, { useEffect, useMemo, useState } from "react";
import style from "../index.module.less";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fastNth, fastProp, mapIndexed, normalize } from "@/util/opt";
import { addZeroIndex, fixedNumber } from "@/util/numberFormatter";
import inStyle from "../../fundCompare/components/index.module.less";
import ReportDate from "./reportDate";
import {
  fundManagerMapSelector,
  selectedReportDateSelector,
} from "../selectors";
import { fetchFundManagerCompareTop10PositionStocks } from "@/store/compareManage";
import { allStocksMapSelector } from "@/selectors/stocks";
import { size } from "lodash";
import LoadingComponent from "@/components/LoadingComponent";

const getColumns = (
  formatMessage: FormatMessageFunc,
  fundIds: string[],
  date: string
) =>
  flow(
    map<string, TableColumnProps<any>>((id) => ({
      title: date,
      dataIndex: id,
      align: "center",
      width: 250,
      render: (value, record, index) => {
        if (index >= 1 && index <= 10) {
          return (
            <div className={inStyle.Top10StocksColumn}>
              <span>{fastProp("stockName")(value)}</span>
              <span>{fixedNumber(fastProp("weight")(value))}</span>
            </div>
          );
        }
        if (index >= 11 && index <= 12) {
          return fixedNumber(value);
        }
        return value;
      },
    })),
    concat({
      title: formatMessage("endOfDate"),
      dataIndex: "index",
      align: "center",
      width: 100,
    })
  )(fundIds);

const useGetTableDataSource = (selectedDate: string, managerIds: string[]) => {
  const formatMessage = useFormatMessage();

  const baseData = useAppSelector(
    prop("compareManage.fundManager.top10PositionStocks")
  );
  const allStocksMap = useAppSelector(allStocksMapSelector);
  const fundManagerMap = useAppSelector(fundManagerMapSelector);

  const top10PositionStocks = useMemo(() => {
    const stock = flow(fastProp(selectedDate))(baseData);
    const stockData = flow(
      map((item: Record<string, any>) => ({
        ...item,
        stockMarketValue: flow(
          map((key: string) => ({
            nameId: key,
            weight: fastProp(key)(item?.stockMarketValue),
          })),
          orderBy("weight", "desc")
        )(keys(item?.stockMarketValue)),
      }))
    )(stock);
    return size(stock) ? normalize("fundManagerId")(stockData) : [];
  }, [baseData, selectedDate]);

  const { fundNameRow, totalRow } = useMemo(() => {
    const fundNameRow: any = {};
    const totalRow: any = {};
    forEach((id: string) => {
      fundNameRow[id] = prop(`${id}.name`)(fundManagerMap);
      const sumValue = flow(
        prop(`${id}.stockMarketValue`),
        sumBy("weight")
      )(top10PositionStocks);
      totalRow[id] = sumValue;
    })(managerIds);

    return {
      fundNameRow: { index: formatMessage("serialNumber"), ...fundNameRow },
      totalRow: { index: formatMessage("total"), ...totalRow },
    };
  }, [fundManagerMap, top10PositionStocks, formatMessage, managerIds]);

  const top10StockData = useMemo(
    () =>
      mapIndexed((_: undefined, index: number) => {
        const topStocks = flow(
          map((id: string) => {
            const stock = fastProp(id)(top10PositionStocks);
            const item = fastNth(index)(stock?.stockMarketValue) || "";
            return [
              stock?.fundManagerId,
              {
                stockName: prop(`${item.nameId}.name`)(allStocksMap),
                weight: fastProp("weight")(item),
              },
            ];
          }),
          fromPairs
        )(managerIds);
        return {
          index: addZeroIndex(index + 1),
          ...topStocks,
        };
      })(new Array(10)),
    [allStocksMap, top10PositionStocks, managerIds]
  );
  return [fundNameRow, ...top10StockData, totalRow];
};

export default React.memo<{
  managerIds: string[];
}>(({ managerIds }): JSX.Element => {
  const reportDate = useAppSelector(selectedReportDateSelector);
  const [selectedDate, setSelectedDate] = useState(reportDate as string);
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const dataSource = useGetTableDataSource(selectedDate, managerIds);
  const columns = useMemo(
    () => getColumns(formatMessage, managerIds, selectedDate),
    [selectedDate, formatMessage, managerIds]
  );
  useEffect(() => {
    setSelectedDate(reportDate);
  }, [reportDate]);

  useEffect(() => {
    if (selectedDate) {
      dispatch(
        fetchFundManagerCompareTop10PositionStocks({
          ids: managerIds,
          reportDate: selectedDate,
        })
      );
    }
  }, [selectedDate, managerIds, dispatch]);

  return (
    <Card>
      <Space direction="vertical" className={style.fullWidth}>
        <Row justify="space-between" align="middle">
          <Col>
            <h3 className={style.margin16}>
              {formatMessage("top10PositionMarketValue")}
            </h3>
          </Col>
          <Col>
            <ReportDate
              value={selectedDate}
              onChange={setSelectedDate}
              type="stocksReportDate"
            />
          </Col>
        </Row>
        <LoadingComponent actions={fetchFundManagerCompareTop10PositionStocks}>
          <Table
            columns={columns}
            bordered
            dataSource={dataSource}
            pagination={false}
            className={style.Table}
          />
        </LoadingComponent>
      </Space>
    </Card>
  );
});
