import axios, { AxiosInstance } from "axios";
import { message } from "antd";
import store from "@/store";
import { addGlobalLoading, subGlobalLoading } from "@/store/global";
import { reduce } from "lodash/fp";
import { ApiErrorResponse, errorField } from "@/model/response";
import parseError from "@/util/errorParser";
import getMessage from "../getMessage";

type responseType = Record<string, string>;
const formatApiResponseError = (
  error: ApiErrorResponse | undefined
): Record<string, string> => {
  if (!error) return {};
  return reduce<errorField, responseType>(
    (object, { field, message, messageKey }) => ({
      ...object,
      [field]: getMessage(messageKey) || message,
    }),
    {}
  )(error?.errors);
};

export const createAxios = (): AxiosInstance => {
  const axiosInstance = axios.create({
    timeout: 120 * 1000,
    headers: {
      "accept-language": "zh",
      "content-type": "application/json",
    },
  });

  // 请求拦截
  axiosInstance.interceptors.request.use(
    (request) => {
      if (request.config?.showLoading) {
        store.dispatch(addGlobalLoading());
      }
      return request;
    },
    (error) => {
      // eslint-disable-next-line no-console
      console.log("请求拦截失败");
      return Promise.reject(error);
    }
  );

  // 响应拦截
  axiosInstance.interceptors.response.use(
    (request) => {
      if (request.config?.config?.showLoading) {
        store.dispatch(subGlobalLoading());
      }
      return request.data;
    },
    (error) => {
      if (error?.config?.config?.showLoading) {
        store.dispatch(subGlobalLoading());
      }
      const showError = error.config?.config?.showError;
      const errorStatusReg = /^(4|5)0\d$/;
      if (error.response.status === 401) {
        return location.replace("/login");
      }
      if (
        error.response &&
        errorStatusReg.test(error.response.status.toString())
      ) {
        const formattedError = formatApiResponseError(error.response.data);
        error.message = formattedError;
        if (showError) {
          const errorMessage = parseError(
            formattedError,
            error.response.status
          );
          message.error(errorMessage);
        }
      } else {
        error.message = getMessage("systemError");
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
