import { Select, SelectProps } from "antd";
import { compact, map, toArray } from "lodash/fp";
import { statisticRange, RangeInterface } from "@/constant/statisticRange";
import { useFormatMessage } from "@/util/formatMessage";
import { useCreation } from "ahooks";

const { Option } = Select;
export interface StatisticRangeProps extends SelectProps<string> {
  onChange: (value: string) => void;
  className?: string;
  statisticRangeList?:
  | Partial<Record<string, RangeInterface>>
  | Record<string, RangeInterface>
  | RangeInterface[];
  value?: string;
  disabled?: boolean;
}
const StatisticRange = ({
  onChange,
  size,
  className,
  statisticRangeList,
  value,
  disabled = false,
  ...restProps
}: StatisticRangeProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const rangeList = useCreation(
    () => compact(toArray(statisticRangeList || statisticRange)),
    [statisticRangeList]
  );
  return (
    <Select
      size={size || "middle"}
      className={className}
      value={value}
      onChange={onChange}
      disabled={disabled}
      {...restProps}
    >
      {map<RangeInterface, JSX.Element>((option) => {
        return (
          <Option key={option.id} value={option.id}>
            {formatMessage(option.message)}
          </Option>
        );
      })(rangeList)}
    </Select>
  );
};

export default StatisticRange;
