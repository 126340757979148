import { createContext } from "react";

export interface WindowSizeContext {
  containerWidth: number;
  containerHeight: number;
}

export default createContext<WindowSizeContext>({
  containerWidth: 0,
  containerHeight: 0,
});
