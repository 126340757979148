import { debounce } from "lodash/fp";
import { useRafState } from "ahooks";
import React, { ReactNode, useContext, useEffect } from "react";
import LocationContext, { WindowSizeContext } from "./context";

const TOP_HEIGHT = 56;

export default React.memo<{
  children: ReactNode;
}>(({ children }) => {
  const [windowSize, setWindowSize] = useRafState<WindowSizeContext>({
    containerWidth: 0,
    containerHeight: 0,
  });
  useEffect(() => {
    const resizeHandler = debounce(500)(() => {
      setWindowSize({
        containerWidth: document.documentElement.clientWidth,
        containerHeight: document.documentElement.clientHeight - TOP_HEIGHT,
      });
    });
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, [setWindowSize]);
  return (
    <LocationContext.Provider value={windowSize}>
      {children}
    </LocationContext.Provider>
  );
});

export function useWindowSizeContext(): WindowSizeContext {
  return useContext(LocationContext);
}
