// import { useFormatMessage } from "@/util/formatMessage";
import LoadingComponent from "@/components/LoadingComponent";
import { Card, Radio } from "antd";
import React, { useMemo, useState } from "react";
import { AssetDetailComponent, newType } from "../../constant";
import { useFormatMessage } from "@/util/formatMessage";
import NewInfo from "./components/newInfo";
import Announcement from "./components/announcement";
import {
  fetchGetAnnouncementInfo,
  fetchGetAnnouncementType,
  fetchGetNewsInfo,
} from "@/store/fundDetailSlice";
import { useAppSelector } from "@/hooks/redux";
import { fastProp } from "@/util/opt";
import basicMapper from "../../../mapper";

const requestList = [
  fetchGetNewsInfo,
  fetchGetAnnouncementInfo,
  fetchGetAnnouncementType,
];

export default React.memo<AssetDetailComponent>(({ fundId }) => {
  const formatMessage = useFormatMessage();
  const [type, setType] = useState<string>(newType.NEW_INFORMATION);
  const { fundBasicTitle } = useAppSelector((state) =>
    basicMapper(state, fundId)
  );

  const code = useMemo(
    () => fastProp("code")(fundBasicTitle),
    [fundBasicTitle]
  );

  return (
    <LoadingComponent actions={requestList}>
      <Card>
        <Radio.Group
          onChange={(v) => setType(v.target.value)}
          value={type}
          optionType="button"
          buttonStyle="solid"
        >
          <Radio.Button value={newType.NEW_INFORMATION}>
            {formatMessage("NewInformation")}
          </Radio.Button>
          <Radio.Button value={newType.ASSET_ANNOUNCEMENT}>
            {formatMessage("assetAnnouncement")}
          </Radio.Button>
        </Radio.Group>
        {newType.isNews(type) ? (
          <NewInfo fundId={fundId} code={code} />
        ) : (
          <Announcement fundId={fundId} code={code} />
        )}
      </Card>
    </LoadingComponent>
  );
});
