import React, { memo } from "react";
import { Radio, RadioGroupProps } from "antd";
import { map } from "lodash/fp";

import { useFormatMessage } from "@/util/formatMessage";

export type PeriodTypeValue = "SECTION" | "QUARTER" | "YEAR";

export type PeriodTypeOption = {
  label: string;
  value: PeriodTypeValue;
};

const PeriodTypePicker = memo<
  Omit<RadioGroupProps, "onChange" | "value"> & {
    value: PeriodTypeValue;
    onChange: (e: PeriodTypeValue) => void;
  }
>(({ value, onChange, ...restProps }) => {
  const formatMessage = useFormatMessage();
  const _options: PeriodTypeOption[] = [
    {
      label: formatMessage("sectionIncrease"),
      value: "SECTION",
    },
    {
      label: formatMessage("quarterIncrease"),
      value: "QUARTER",
    },
    {
      label: formatMessage("yearIncrease"),
      value: "YEAR",
    },
  ];

  return (
    <Radio.Group value={value} {...restProps}>
      {map(({ label, value }) => {
        return (
          <Radio.Button
            key={value}
            value={value}
            onChange={(e) => onChange?.(e.target.value as PeriodTypeValue)}
          >
            {label}
          </Radio.Button>
        );
      })(_options)}
    </Radio.Group>
  );
});

export default PeriodTypePicker;
