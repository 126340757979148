import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Card, Table, TableProps, Timeline } from "antd";
import cn from "classnames";
import { compact, mapValues, size } from "lodash/fp";
import { useCreation, useMemoizedFn, useUpdateEffect } from "ahooks";
import { createSimplePortfolio } from "@/store/portfolioList";
import FundReplaceRule, {
  validateFundReplaceError,
} from "@/components/fundReplaceRule";
import PortfolioBasicInfo, {
  validateFormError,
} from "@/components/portfolioCompoents/portfolioBasicInfo";
import { useForm } from "antd/lib/form/Form";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { useFundListTable } from "@/components/portfolioCompoents/fundListTable";
import { InitSimpleData } from "@/views/portfolioManage/createSimplePortfolio";
import { formatPortfolioValue } from "@/components/portfolioCompoents/constant";
import { ManualCreatePortfolio } from "../constant";
import style from "./index.module.less";
import SaveTable from "./saveTable";
import { useFormatMessage } from "@/util/formatMessage";
import { formatPercentage } from "@/util/numberFormatter";
import { InitSimpleDataType } from "@/components/portfolioCompoents/hook";
import { useAppDispatch } from "@/hooks/redux";
import { fastProp, mapIndexed } from "@/util/opt";
import { BackTestingForm } from "../backTestingAllocation/backTestingForm";
import { assetsType } from "@/model/portfolioList";
import { useGetPositionDate, useGetSaveTableData } from "../hooks";
import { defalueCashHold } from "@/components/fundReplaceRule/constant";
import { ReplaceRuleType } from "@/components/fundReplaceRule/hooks";

const WareHouseTable = ({
  dataSource,
}: {
  dataSource: ManualCreatePortfolio["weights"];
}) => {
  const formatMessage = useFormatMessage();
  const fundDataSource = useFundListTable(dataSource as any);
  const scroll = useCreation(
    () => (size(dataSource) > 10 ? { y: 407, x: "max-content" } : undefined),
    [dataSource]
  );
  const columns = useCreation<TableProps<any>["columns"]>(() => {
    return [
      {
        key: "name",
        dataIndex: "name",
        title: formatMessage("fundName"),
        width: 150,
        ellipsis: true,
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
      },
      {
        key: "code",
        dataIndex: "code",
        title: formatMessage("fundCode"),
        width: 100,
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
      },
      {
        key: "investType",
        dataIndex: "investType",
        width: 200,
        title: formatMessage("policyType"),
      },
      {
        key: "weight",
        dataIndex: "weight",
        width: 100,
        align: "right",
        title: formatMessage("positionWeight"),
        render: (value) => formatPercentage(value),
      },
      {
        title: "",
      },
    ];
  }, [formatMessage]);
  return (
    <Table
      className={style.WareHouseTable}
      columns={columns}
      pagination={false}
      rowKey="fundId"
      scroll={scroll}
      dataSource={fundDataSource}
    />
  );
};

export type SavePortfolioRef = {
  onSavePortfolio: () => Promise<any> | any;
};
export default forwardRef<
  SavePortfolioRef,
  {
    data: ManualCreatePortfolio[];
    onError: React.Dispatch<React.SetStateAction<boolean>>;
    formData: BackTestingForm;
  }
>(({ data, onError, formData }, ref) => {
  const [replaceRule, setReplaceRule] =
    useState<ReplaceRuleType>(defalueCashHold);
  const tableData = useGetSaveTableData(data);
  const formatMessage = useFormatMessage();
  const initSimpleData = useCreation(
    () => ({
      ...InitSimpleData,
      ...formData,
      benchmark: {
        benchmarkId: fastProp("benchmarkId")(formData),
        benchmarkType: fastProp("benchmarkType")(formData),
      },
    }),
    [formData]
  );

  const [form] = useForm();
  const saveTableRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  useImperativeHandle(ref, () => ({
    onSavePortfolio: () =>
      form.validateFields().then((tableData: InitSimpleDataType) => {
        return saveTableRef.current
          .getData()
          .then((assetData: assetsType[]) => {
            onError(true);
            return dispatch(
              createSimplePortfolio({
                ...formatPortfolioValue(tableData),
                assets: assetData,
                replaceRule: {
                  ...replaceRule,
                  replaceFund: mapValues((ids: string[]) => compact(ids))(
                    fastProp("replaceFund")(replaceRule)
                  ),
                },
              })
            )
              .unwrap()
              .finally(() => {
                onError(false);
              });
          })
          .catch(() => {
            return Promise.reject();
          });
      }),
  }));
  useEffect(() => {
    onError(validateFormError(initSimpleData));
    form.setFieldsValue(initSimpleData);
  }, [form, initSimpleData, onError]);
  const onValuesChange = useMemoizedFn(
    (changeValue, allValue: InitSimpleDataType) => {
      onError(
        validateFormError(allValue) || validateFundReplaceError(replaceRule)
      );
      form.setFieldsValue(allValue);
    }
  );
  useUpdateEffect(() => {
    onError(
      validateFormError(form.getFieldsValue(true)) ||
        validateFundReplaceError(replaceRule)
    );
  }, [onError, replaceRule]);

  const positionDate = useGetPositionDate(initSimpleData);

  const [fundList, setFundList] = useState([]);
  return (
    <>
      <Card className={cn(style.Card)}>
        <PortfolioBasicInfo
          onValuesChange={onValuesChange}
          form={form}
          initialValues={initSimpleData}
        />
      </Card>
      <Card className={cn(style.Card, style.PositionCard)}>
        <h3 className={style.MarginBottom12}>请选择建仓初始配比</h3>
        <SaveTable
          dataSource={tableData}
          ref={saveTableRef}
          positionDate={positionDate}
          onChange={setFundList as any}
        />
        <FundReplaceRule
          fundList={fundList}
          value={replaceRule}
          onChange={setReplaceRule}
        />
      </Card>
      <Card className={cn(style.Card, style.PositionCard)}>
        <h3 className={style.MarginBottom12}>所有调仓历史</h3>
        <Timeline>
          {mapIndexed((item: ManualCreatePortfolio, index: number) => (
            <Timeline.Item key={item.turnoverDate as string}>
              <p className={style.TimeLineTitle}>
                {index === 0
                  ? formatMessage("positionDate")
                  : formatMessage("warehouseDate")}
                ：{item.turnoverDate}
              </p>
              <WareHouseTable dataSource={item.weights} />
            </Timeline.Item>
          ))(tableData)}
        </Timeline>
      </Card>
    </>
  );
});
