// import { useFormatMessage } from "@/util/formatMessage";
import { Space } from "antd";
import React, { ReactNode } from "react";
import style from "./oneLevelName.module.less";

type OneLevelProps = {
  name: string;
  children?: ReactNode;
};

export default React.memo(({ name, children }: OneLevelProps) => {
  // const formatMessage = useFormatMessage();
  return (
    <h1 className={style.OneLevelContainer}>
      <Space size={16}>
        <span className={style.OneLevelName}>{name}</span>
        {children}
      </Space>
    </h1>
  );
});
