import React, { useMemo } from "react";
// import { TableColumnType } from "antd";
import { BaseTableProps } from "react-base-table";
import { useFormatMessage } from "@/util/formatMessage";
import { User } from "@/model/login";
import { useGetUsers } from "@/hooks/users";
import SelectTreeNodeModal from "@/base-components/selectTreeNodeModal";

type Props = {
  onSelect: (userIds: string[]) => void;
  value?: string[];
};

const useGetListColumns = () => {
  const formatMessage = useFormatMessage();
  return useMemo(
    () =>
      [
        {
          title: formatMessage("staffUsername"),
          key: "username",
          dataKey: "username",
          align: "left",
          resizable: true,
          width: 300,
        },
      ] as BaseTableProps<User>["columns"],
    [formatMessage]
  );
};

const onFilter = (user: User, searchValue: string) => {
  if (!searchValue) return true;
  return (
    user?.username?.toLowerCase()?.indexOf(searchValue?.toLowerCase()) > -1
  );
};

export default React.memo<Props>(({ onSelect, value }) => {
  const formatMessage = useFormatMessage();
  const { users } = useGetUsers();
  const treeCategory = [
    {
      key: "all",
      title: formatMessage("all"),
    },
  ];
  const categoryData = {
    all: users,
  };
  const columns = useGetListColumns();
  return (
    <SelectTreeNodeModal<User>
      title={formatMessage("addStaff")}
      searchPlaceholder={formatMessage("searchStaffPlaceholder")}
      btnText={formatMessage("addStaff")}
      defaultTreeSelectedKey="all"
      treeData={treeCategory}
      categoryData={categoryData}
      listColumns={columns}
      rowKey="id"
      value={value}
      onFilter={onFilter}
      onSelect={onSelect}
      batchAddKey="username"
      errorTip="notExistUserNameTip"
      resultItemTitleKey="username"
    />
  );
});
