import React from "react";
import { Card, Form } from "antd";
import style from "./index.module.less";
import type {
  ErrorField,
  FundConfigurationForm,
  FundConfigurationFormUpdater,
} from "../interface";
import ModelForm from "./components/modelForm";
import FoundDatePicker from "./components/foundDatePicker";
import RebalanceForm from "./components/rebalanceForm";
import ButtonRadioGroup from "../components/buttonRadioGroup";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import { getModels } from "../constant";
import { useAppSelector } from "@/hooks/redux";
import { useCreation } from "ahooks";

export default React.memo<{
  startDate: string;
  endDate: string;
  availableDateRange: string[];
  formData: FundConfigurationForm;
  updateFormData: FundConfigurationFormUpdater;
  errorField: ErrorField;
  foundDatePickerErrorMessage?: string;
}>(
  ({
    formData,
    updateFormData,
    startDate,
    endDate,
    availableDateRange,
    errorField,
    foundDatePickerErrorMessage,
  }) => {
    const formatMessage = useFormatMessage();
    const { processedTradingDates } = useAppSelector(
      (state) => state.tradingDates
    );
    const models = useCreation(
      () =>
        getModels(
          startDate,
          endDate,
          fastProp("fundLess3MothError")(errorField),
          processedTradingDates
        ),
      [endDate, errorField, processedTradingDates, startDate]
    );
    return (
      <Form>
        <Card className={style.Card}>
          <div className={style.Header}>
            <h3>{formatMessage("ModelSettings")}</h3>
          </div>
          <ButtonRadioGroup
            options={models as any}
            value={formData.modelType}
            onChange={updateFormData("modelType")}
          />
          <ModelForm
            startDate={startDate}
            endDate={endDate}
            formData={formData}
            updateFormData={updateFormData}
            riskValueError={errorField.riskValueError}
          />
        </Card>
        <Card className={style.Card}>
          <div className={style.Header}>
            <h3>{formatMessage("WarehouseAdjustmentSetting")}</h3>
          </div>
          <FoundDatePicker
            dateRange={availableDateRange}
            value={formData.foundDate}
            onChange={updateFormData("foundDate")}
            errorMessage={foundDatePickerErrorMessage}
          />
          <RebalanceForm
            formData={formData}
            endDate={endDate}
            updateFormData={updateFormData}
          />
        </Card>
      </Form>
    );
  }
);
