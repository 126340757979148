import React, { ReactNode } from "react";
import style from "../index.module.less";

const portfolioAnalysisSubtitle = ({
  name,
}: {
  name: string | ReactNode;
}): JSX.Element => {
  return (
    <div className={style.portfolioAnalysisSubtitle}>
      <span>{name}</span>
    </div>
  );
};

export default portfolioAnalysisSubtitle;
