import moment from "moment";
import { trim } from "lodash/fp";
import { DATEFORMAT } from "@/util/dateFormat";
import { Competition } from "@/model/competition";
import type { FormatMessageFunc } from "@/util/formatMessage";
import dayjs from "dayjs";

export const nameReg = /^[^*%；]*$/;

export const competitionInitValues = {
  presentRange: "PUBLIC",
};

export const basicValidateOfName = (
  name: string,
  formatMessage: FormatMessageFunc
) => {
  const _name = trim(name);
  if (!_name) return formatMessage("validateNoEmpty");
  if (_name.length > 30)
    return formatMessage("validateCharacterLenghtLimit", { count: 30 });
  if (!nameReg.test(_name))
    return formatMessage("competitionNameSpecialCharLimit");
};

export const transformFormValuesToServerValues = (formValues: any) => {
  const {
    name,
    banner,
    competeUsers,
    dateRange,
    enterEndDate,
    entrance,
    presentRange,
    regulation,
    summary,
  } = formValues;

  return {
    name,
    presentRange,
    regulation,
    summary,
    bannerImage: banner?.img,
    bannerImageName: banner?.imgName,
    entranceImage: entrance?.img,
    entranceImageName: entrance?.imgName,
    startDate: dayjs(dateRange?.[0]).format(DATEFORMAT),
    endDate: dayjs(dateRange?.[1]).format(DATEFORMAT),
    enterEndDate: dayjs(enterEndDate).format(DATEFORMAT),
    competeUsers,
  } as Competition;
};

export const transformServerValuesToFormValues = (competition: Competition) => {
  return {
    name: competition?.name,
    presentRange: competition?.presentRange,
    regulation: competition?.regulation,
    summary: competition?.summary,
    banner: {
      img: competition?.bannerImage,
      imgName: competition?.bannerImageName,
    },
    entrance: {
      img: competition?.entranceImage,
      imgName: competition?.entranceImageName,
    },
    dateRange: [moment(competition?.startDate), moment(competition?.endDate)],
    enterEndDate: moment(competition?.enterEndDate),
    competeUsers: competition?.competeUsers,
  };
};
