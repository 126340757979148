import React, { ReactNode, useMemo } from "react";
import LocationContext, {
  AnalysisType,
  portfolioAnalysisConfig,
} from "./context";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { getNatureDateAndTradingDate } from "@/util/processedDates";
import { fastProp } from "@/util/opt";
import { assetPortfolioMapSelector } from "@/selectors/portfolioList";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { useCreation, useMemoizedFn } from "ahooks";
import { isNaN } from "lodash/fp";

export const checkNeedTime = (needTime: number, runTime: number) => {
  if (isNaN(needTime)) return true;
  return needTime > runTime;
};
export default React.memo<{
  id?: string;
  children: ReactNode;
  start?: string;
  end?: string;
  analysisType?: AnalysisType;
}>(
  ({
    children,
    id = "",
    start,
    end,
    analysisType = portfolioAnalysisConfig.SIMULATION,
  }) => {
    const dataMaps = useAppSelector(assetPortfolioMapSelector);
    const processedTradingDates = useAppSelector(
      (state) => state.tradingDates.processedTradingDates
    );
    const { historyStart, historyEnd } = useMemo(() => {
      if (start || end) {
        return {
          historyStart: start,
          historyEnd: end,
        };
      }
      const { historyStart, historyEnd, startDate, endDate } =
        fastProp(id)(dataMaps) || {};
      return {
        historyStart: historyStart || startDate,
        historyEnd: historyEnd || endDate,
      };
    }, [dataMaps, end, id, start]);
    const [, tradingDateCount] = useMemo(
      () =>
        getNatureDateAndTradingDate(
          historyStart,
          historyEnd
        )(processedTradingDates),
      [historyEnd, historyStart, processedTradingDates]
    );
    const dispatch = useAppDispatch();
    const handleDispatch = useMemoizedFn(
      (needTime: number, event: AsyncThunkAction<any, any, any>) => {
        if (checkNeedTime(needTime, tradingDateCount) || !historyEnd)
          return Promise.reject();
        return dispatch(event);
      }
    );
    const value = useCreation(
      () => ({
        runningTime: tradingDateCount,
        dispatch: handleDispatch,
        analysisType,
      }),
      [tradingDateCount, handleDispatch, analysisType]
    );
    return (
      <LocationContext.Provider value={value}>
        {children}
      </LocationContext.Provider>
    );
  }
);
