import { fastNth, fastProp, getProp, mapIndexed, zipWithMinus, zipWithPlus } from "@/util/opt";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import getCumulativeAttributionsByParams from "@/util/quant/cumulativeAttributions";
import { flow, last, map, sum } from "lodash/fp";
import getInformationRatio from "@/util/quant/informationRatio";
import getTrackDeviation from "@/util/quant/trackDeviation";


export default function(result:Record<string,any>, sectors:Record<string,any>) {
    const dailyPortfolioReturns = fastProp("dailyPortfolioReturns")(result);
    const dailyPortfolioActualReturns = fastProp("dailyPortfolioActualReturns")(result);
    const dailyFundAttributions = fastProp("dailyFundAttributions")(result);
    const dailyTradeAttributions = fastProp("dailyTradeAttributions")(result);
    const dailyBenchmarkReturns = fastProp("dailyBenchmarkReturns")(result);
    const dailyBenchmarkPositionReturns = fastProp("dailyBenchmarkPositionReturns")(result);
    const dailyAllocationAttributions = fastProp("dailyAllocationAttributions")(result);
    const dailySelectionAttributions = fastProp("dailySelectionAttributions")(result);
    const dailyTimingAttributions = fastProp("dailyTimingAttributions")(result);
    // 日净值超额收益
    const dailyActiveReturns = zipWithMinus(
      dailyPortfolioActualReturns,
      dailyBenchmarkReturns,
    );
    // 日持仓超额收益
    const dailyPositionActiveReturns = zipWithMinus(
      dailyPortfolioReturns,
      dailyBenchmarkPositionReturns,
    );
    // 基金业绩（持仓计算）和基准业绩（持仓计算）的超额部分减去 选股业绩（持仓计算）、配置业绩（持仓计算）得到交叉项/择时业绩
    const dailyTimingOrInteractionAttributions = zipWithMinus(
      dailyPortfolioReturns,
      dailyBenchmarkPositionReturns,
      dailyAllocationAttributions,
      dailySelectionAttributions,
    );
    const cumulativePortfolioReturns = getCumulativeReturns(dailyPortfolioReturns);
    const cumulativePortfolioActualReturns = getCumulativeReturns(dailyPortfolioActualReturns);
    const cumulativeFundAttributions = dailyFundAttributions ? getCumulativeReturns(dailyFundAttributions) : cumulativePortfolioActualReturns;
    const cumulativeBenchmarkReturns = getCumulativeReturns(dailyBenchmarkReturns);
    const cumulativeTimingAttributions = getCumulativeReturns(dailyTimingAttributions);
    const cumulativeBenchmarkPositionReturns = getCumulativeReturns(dailyBenchmarkPositionReturns);
    // const dailyOtherAttributions = zipWithMinus(
    // zipWithMinus(cumulativePortfolioActualReturns, cumulativePortfolioReturns),
    // zipWithMinus(cumulativeBenchmarkReturns, cumulativeBenchmarkPositionReturns),
    //   dailyActiveReturns,
    //   dailyPositionActiveReturns,
    // );
    // 持仓 连接算法
    const getPositionCumulativeAttributions = getCumulativeAttributionsByParams(
      dailyPortfolioReturns,
      dailyBenchmarkPositionReturns,
      cumulativePortfolioReturns,
      cumulativeBenchmarkPositionReturns,
    );
    // 净值 连接算法
    const getActualCumulativeAttributions = getCumulativeAttributionsByParams(
      dailyPortfolioActualReturns,
      dailyBenchmarkReturns,
      cumulativePortfolioActualReturns,
      cumulativeBenchmarkReturns,
    );
    const cumulativeActiveAttributions = getActualCumulativeAttributions(dailyActiveReturns);
    const cumulativePositionActiveAttributions = getPositionCumulativeAttributions(dailyPositionActiveReturns);
    const cumulativeAllocationAttributions = getPositionCumulativeAttributions(dailyAllocationAttributions);
    const cumulativeSelectionAttributions = getPositionCumulativeAttributions(dailySelectionAttributions);
    const cumulativeTimingOrInteractionAttributions = getPositionCumulativeAttributions(dailyTimingOrInteractionAttributions);
    const otherAttribution = last(cumulativeActiveAttributions) as number - (last(cumulativePositionActiveAttributions) as number);
    const cumulativeTimingOrInteractionAttribution = last(cumulativePositionActiveAttributions) as number - (last(cumulativeAllocationAttributions) as number)-(last(cumulativeSelectionAttributions) as number);

    const nullBenchmarkRetures = map(() => 0)(dailyBenchmarkReturns);
    const getAsboluteLastBenchmarkCumulativeAttribution = flow(
      getCumulativeAttributionsByParams(
        dailyBenchmarkPositionReturns,
        nullBenchmarkRetures,
        cumulativeBenchmarkPositionReturns,
        nullBenchmarkRetures,
      ),
      last,
    );
    const getAsboluteLastCumulativeAttribution = flow(
      getCumulativeAttributionsByParams(
        dailyPortfolioReturns,
        nullBenchmarkRetures,
        cumulativePortfolioReturns,
        nullBenchmarkRetures,
      ),
      last,
    );
    const subAttributions = flow(
      fastProp("subAttributions"),
      mapIndexed((subAttribution:Record<string,any>) => {
        const id = fastProp("id")(subAttribution);
        const subDailyPortfolioReturns = fastProp("dailyTotalReturns")(subAttribution);
        const subDailyBenchmarkReturns = fastProp("dailyBenchmarkReturns")(subAttribution);
        const subDailyActiveReturns = zipWithMinus(
          subDailyPortfolioReturns,
          subDailyBenchmarkReturns,
        );

        const subDailyAllocationAttributions = fastProp("dailyAllocationAttributions")(subAttribution);
        const subDailySelectionAttributions = fastProp("dailySelectionAttributions")(subAttribution);
        const subCumulativeActiveAttributions = getActualCumulativeAttributions(subDailyActiveReturns);
        const subCumulativeSelectionAttributions =
        getPositionCumulativeAttributions(subDailySelectionAttributions);
        const subCumulativeAllocationAttributions =
        getPositionCumulativeAttributions(subDailyAllocationAttributions);
        // const subCumulativeActiveAttribution = last(subCumulativeActiveAttributions);
        const subCumulativeSelectionAttribution = last(subCumulativeSelectionAttributions);
        const subCumulativeAllocationAttribution = last(subCumulativeAllocationAttributions);
        const subCumulativeBenchmarkAttribution = getAsboluteLastBenchmarkCumulativeAttribution(subDailyBenchmarkReturns);
        const subCumulativePortfolioAttribution = getAsboluteLastCumulativeAttribution(subDailyPortfolioReturns);
        const subCumulativeActiveAttribution = (subCumulativePortfolioAttribution as number) - (subCumulativeBenchmarkAttribution as number);
        const subDailyInteractionAttributions = mapIndexed(
          (subDailyActiveReturn:number, index:number) => (
            subDailyActiveReturn
          - fastNth(index)(subDailyAllocationAttributions)
          - fastNth(index)(subDailySelectionAttributions)),
        )(subDailyActiveReturns);

        return {
          ...subAttribution,
          dailySelectionAttributions: subDailySelectionAttributions,
          dailyActiveReturns: subDailyActiveReturns,
          dailyTotalReturns: subDailyPortfolioReturns,
          name: getProp(`${id}.name`)(sectors),
          shortName: getProp(`${id}.name`)(sectors),
          informationRatio: getInformationRatio(subDailyActiveReturns),
          allocationInformationRatio: getInformationRatio(subDailyAllocationAttributions),
          selectionInformationRatio: getInformationRatio(subDailySelectionAttributions),
          interactionInformationRatio: getInformationRatio(subDailyInteractionAttributions),
          cumulativeActiveAttributions: subCumulativeActiveAttributions,
          cumulativeActiveAttribution: subCumulativeActiveAttribution,
          cumulativeSelectionAttributions: subCumulativeSelectionAttributions,
          cumulativeSelectionAttribution: subCumulativeSelectionAttribution,
          cumulativeAllocationAttributions: subCumulativeAllocationAttributions,
          cumulativeAllocationAttribution: subCumulativeAllocationAttribution,
          cumulativeTimingOrInteractionAttribution: (subCumulativeActiveAttribution as number)-(subCumulativeSelectionAttribution as number)-(subCumulativeAllocationAttribution as number),
        };
      }),
    )(result);
    const dailyActiveReturnsPlusFundAttr = dailyTradeAttributions
      ? zipWithPlus([dailyActiveReturns, dailyTradeAttributions])
      : dailyActiveReturns;
    return {
      ...result,
      dailyFundAttributions: dailyFundAttributions || dailyPortfolioReturns,
      cumulativeFundAttributions,
      dailySelectionAttributions,
      informationRatio: getInformationRatio(dailyActiveReturnsPlusFundAttr),
      cumulativeBenchmarkReturn: last(cumulativeBenchmarkReturns),
      cumulativePortfolioReturn: last(cumulativePortfolioActualReturns),
      dailyActiveReturns,
      cumulativePortfolioReturns: cumulativePortfolioActualReturns,
      cumulativeBenchmarkReturns,
      cumulativeActiveAttributions,
      cumulativeAllocationAttributions,
      cumulativeSelectionAttributions,
      cumulativeTimingAttributions,
      cumulativeTimingAttribution: last(cumulativeTimingAttributions),
      cumulativeSelectionAttribution: last(cumulativeSelectionAttributions),
      cumulativeAllocationAttribution: last(cumulativeAllocationAttributions),
      cumulativeActiveAttribution: last(cumulativeActiveAttributions),
      subAttributions,
      cumulativeTimingOrInteractionAttributions,
      cumulativeTimingOrInteractionAttribution,
      dailyTimingOrInteractionAttributions,
      trackDeviation: getTrackDeviation(dailyFundAttributions || dailyPortfolioActualReturns, dailyBenchmarkReturns),
      cumulativeTradeAttribution: sum(
        [fastProp("cumulativeBuyAttributions")(result),
        fastProp("cumulativeSellAttributions")(result),
        fastProp("cumulativeTaxAttributions")(result),]
      ),
      otherAttribution,
      dailyPositionActiveReturns,
    };
}
