import React from "react";
import FundInfo from "./components/fundInfo";
import FundScaleTrend from "./components/fundScaleTrend";
import FundSummary from "./components/fundSummary";
import FundRating from "./components/fundRating";
import FundBusinessRule from "./components/fundBusinessRule";
import { AssetDetailComponent } from "../../constant";

export default React.memo<AssetDetailComponent>(({ fundId }) => {
  return (
    <>
      <FundSummary fundId={fundId} />
      <br />
      <FundInfo fundId={fundId} />
      <br />
      <FundScaleTrend fundId={fundId} />
      <br />
      <FundRating fundId={fundId} />
      <br />
      <FundBusinessRule fundId={fundId} />
    </>
  );
});
