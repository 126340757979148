import React, { useContext } from "react";
import cn from "classnames";
import type { FundCompanyBasicInfo } from "@/model/compareManage";
import style from "./index.module.less";
import { fixedNumber } from "@/util/numberFormatter";
import { useFormatMessage } from "@/util/formatMessage";
import Rating from "@/components/rating";
import { Tooltip } from "antd";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useMemoizedFn } from "ahooks";
import { getYearByStartAndEnd } from "@/util/business-core/tradingDate";

export default React.memo<
  {
    className?: string;
  } & FundCompanyBasicInfo
>(
  ({
    className,
    averageOfficeTerm,
    companyName,
    fundCount,
    managerCount,
    scale,
    fundCompanyScore,
    scaleRank,
    foundDate,
    scaleCount,
    companyId,
  }) => {
    const formatMessage = useFormatMessage();
    const { goToFundCompanyDetailPage } = useContext(PlatformNavigationContext);
    const toFundCompanyDetail = useMemoizedFn(() =>
      goToFundCompanyDetailPage({ id: companyId })
    );
    return (
      <div className={cn(className, style.ShieldInfo)}>
        <div className={style.Shield}>
          <h2 className={style.ScaleNum}>{fixedNumber(scale)}</h2>
          <p className={style.ScaleDesc}>{formatMessage("creditScale")}</p>

          <div className={style.Ranking}>
            <p>
              {scaleRank}/{scaleCount}
            </p>
            <p>{formatMessage("creditScaleRanking")}</p>
          </div>
          <p className={style.CompanyName} onClick={toFundCompanyDetail}>
            <Tooltip title={companyName}>{companyName}</Tooltip>
          </p>
        </div>
        <div className={style.CompanyInfo}>
          <div className={style.CompanyInfoItem}>
            {formatMessage("ratingTag")}:{" "}
            <Rating rating={fundCompanyScore || 0} />
          </div>
          <p className={style.CompanyInfoItem}>
            {formatMessage("establishOfYear")}:{" "}
            {getYearByStartAndEnd(foundDate)}
            {formatMessage("year")}
          </p>
          <p className={style.CompanyInfoItem}>
            {formatMessage("fundManagerNumTag")}: {managerCount}
          </p>
          <p className={style.CompanyInfoItem}>
            {formatMessage("numberOfFundManagers")}: {fundCount}
          </p>
          <p className={style.CompanyInfoItem}>
            {formatMessage("averageLengthOfFundManager")}: {averageOfficeTerm}
          </p>
        </div>
      </div>
    );
  }
);
