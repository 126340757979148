import { useFormatMessage } from "@/util/formatMessage";
import { useHandleEffectiveDate } from "@/views/portfolioManage/portfolioList/hooks";
import { Button, Card, Form, FormProps, Input } from "antd";
import DatePicker from "@/components/tradingDatePicker";
import cn from "classnames";
import React, { useEffect } from "react";
import style from "./index.module.less";
import ShareRangeSelect from "./shareRangeSelect";
import { InitSimpleDataType } from "./hook";
import NumberInput from "../numberInput";
import { isEmpty, isNil } from "lodash/fp";
import {
  portfolioNameReg,
  nameUniqValidator,
  benchmarkValidator,
} from "@/util/formRules";
import { SettingOutlined } from "@ant-design/icons";
import CustomBenchmarkDialog from "../customBenchmarkDialog";
import BenchmarkSelect from "../benchmarkSelect";

const FormItem = Form.Item;
const { TextArea } = Input;
const FormItemLabel = ({
  messageKey,
  required = true,
}: {
  messageKey: string;
  required?: boolean;
}) => {
  const formatMessage = useFormatMessage();
  return (
    <span className={cn(style.FormItemLabel, !required && style.NotRequired)}>
      {formatMessage(messageKey)}&nbsp;:
    </span>
  );
};

export const validateFormError = (data: InitSimpleDataType) => {
  return (
    isEmpty(data.benchmark.benchmarkId) ||
    isEmpty(data.name) ||
    isNil(data.initScale)
  );
};

type PortfolioBasicInfoProps = FormProps<InitSimpleDataType> & {
  disabledInitScale?: boolean;
};
const PortfolioBasicInfo = ({
  initialValues,
  disabledInitScale = false,
  ...res
}: PortfolioBasicInfoProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const { effectiveDate, onGetEffectiveDate } = useHandleEffectiveDate();
  useEffect(() => {
    if (initialValues && !isEmpty(initialValues) && !initialValues.name) {
      onGetEffectiveDate();
    }
  }, [initialValues, onGetEffectiveDate]);
  return (
    <Card size="small" bordered={false}>
      <h4>{formatMessage("BasicInfo")}</h4>
      <Form
        colon={false}
        initialValues={initialValues}
        {...res}
        className={style.BasicInfoContent}
        validateTrigger="onBlur"
      >
        <div className={style.BasicInfoLeft}>
          <FormItem
            name="name"
            label={<FormItemLabel messageKey="FofName" />}
            rules={[
              {
                required: true,
                message: formatMessage("PortfolioBasicInfoNameIsEmpty"),
              },
              {
                pattern: portfolioNameReg,
                message: formatMessage("PortfolioBasicInfoNameRule"),
              },
              { max: 30, message: formatMessage("PortfolioBasicInfoNameMax") },
              nameUniqValidator(initialValues ? initialValues.name : undefined),
            ]}
          >
            <Input placeholder={formatMessage("pleaseEnter")} />
          </FormItem>
          <FormItem
            name="initScale"
            label={<FormItemLabel messageKey="portfolioInitScale" />}
            rules={[
              {
                required: true,
                message: formatMessage("portfolioInitScaleIsNull"),
              },
            ]}
          >
            <NumberInput
              placeholder={formatMessage("pleaseEnter")}
              disabled={disabledInitScale}
              min={10000}
              max={10000000000}
              precision={2}
              type="THOUSANDTH"
              addonAfter={formatMessage("YUAN")}
            />
          </FormItem>
          <div className={style.BenchmarkStyle}>
            <FormItem
              name="benchmark"
              label={<FormItemLabel messageKey="performanceBenchmark" />}
              rules={[
                {
                  required: true,
                },
                benchmarkValidator,
              ]}
            >
              <BenchmarkSelect returnDataType="object" />
            </FormItem>

            <div className={style.CustomBenchmark}>
              <CustomBenchmarkDialog>
                <Button
                  icon={<SettingOutlined />}
                  type="primary"
                  ghost
                  size="small"
                >
                  {formatMessage("manageBenchmark")}
                </Button>
              </CustomBenchmarkDialog>
            </div>
          </div>

          <FormItem
            name="shareRange"
            label={<FormItemLabel messageKey="VisibleRange" />}
            rules={[
              { required: true, message: formatMessage("VisibleRangeIsNull") },
            ]}
          >
            <ShareRangeSelect />
          </FormItem>
          <FormItem
            label={<FormItemLabel required={false} messageKey="foundDate" />}
          >
            <DatePicker
              value={(initialValues && initialValues.date) || effectiveDate}
              size="small"
              disabled
            />
          </FormItem>
        </div>
        <div className={style.BasicInfoRight}>
          <FormItem
            name="strategy"
            label={
              <FormItemLabel
                required={false}
                messageKey="combinationStrategy"
              />
            }
          >
            <TextArea className={style.TextArea} showCount maxLength={300} />
          </FormItem>
          <FormItem
            name="description"
            label={
              <FormItemLabel
                required={false}
                messageKey="combinationDescription"
              />
            }
          >
            <TextArea className={style.TextArea} showCount maxLength={300} />
          </FormItem>
        </div>
      </Form>
    </Card>
  );
};

export default React.memo(PortfolioBasicInfo);
