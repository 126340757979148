import { RootState } from "@/store";
import { createSelector } from "@reduxjs/toolkit";
import { prop } from "lodash/fp";
import { fundBasicInfoSelector } from "../../../mapper";

export const currentFundManagerReturnSelector = createSelector(
  fundBasicInfoSelector,
  (_: RootState, fundId: string, managerId: string | undefined) => managerId,
  (fundBasic, managerId: string | undefined) =>
    prop(`currentFundManagerReturn.${managerId}`)(fundBasic) || {}
);
