import { useGetConfirm } from "@/hooks/modal";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchMoveOptionalFund } from "@/store/workbench";
import { useFormatMessage } from "@/util/formatMessage";
import { arrayToMap, fastProp } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import { Popover, Tooltip, Button } from "antd";
import { filter, flow, isEmpty, join, map, reject, find } from "lodash/fp";
import React, { useState } from "react";
import style from "../index.module.less";
import { GroupFundList, optionalFundGroupList } from "../interface";
import { optionalFundGroupListSelector } from "../selector";

type movePopoverProps = {
  className?: string;
  groupName: string;
  fundIds: string[];
  btnType?: string;
  type: string;
};
export default React.memo<movePopoverProps>(
  ({ groupName, fundIds, type, btnType = "text" }) => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();
    const allGroupList = useAppSelector(optionalFundGroupListSelector);
    const groupList = reject(
      (v: optionalFundGroupList) => v.groupName === groupName
    )(allGroupList);
    const confirm = useGetConfirm();
    const onMoveOptionalFund = useMemoizedFn(
      (newGroupName: string) => (fundIds: string[]) => {
        dispatch(
          fetchMoveOptionalFund({
            newGroupName,
            oldGroupName: groupName,
            fundIds,
            type,
          })
        );
      }
    );
    const [visible, setVisible] = useState<boolean>(false);
    const onMoveFunds = useMemoizedFn((newGroupName: string) => {
      const newGroup = find(
        (v: optionalFundGroupList) => v?.groupName === newGroupName
      )(allGroupList);
      const fundList = newGroup?.fundViews;
      const fundIdsMap = arrayToMap(fundIds);
      const filterFunds = filter((v: GroupFundList) =>
        fastProp(v.fundId)(fundIdsMap)
      )(fundList);
      if (isEmpty(filterFunds)) {
        setVisible(false);
        onMoveOptionalFund(newGroupName)(fundIds);
      } else {
        const message = flow(map(fastProp("name")), join("、"))(filterFunds);
        confirm({
          content: formatMessage("optionalMovePopverMessage", {
            message,
            newGroupName:
              newGroupName === ""
                ? formatMessage("unnamedGroup")
                : newGroupName,
          }),
          onOk: () => onMoveOptionalFund(newGroupName)(fundIds),
        });
      }
      // });
    });
    const handleVisible = useMemoizedFn(() => setVisible(!visible));
    const handleVisibleChange = useMemoizedFn((newVisible) => {
      setVisible(newVisible);
    });
    return (
      <Popover
        placement="bottom"
        trigger="click"
        content={
          <ul className={style.MovePopover}>
            {map((v: optionalFundGroupList) => (
              <Tooltip
                title={
                  v.groupName === ""
                    ? formatMessage("unnamedGroup")
                    : v.groupName
                }
                placement="left"
              >
                <li
                  key={v.groupName}
                  className={style.MovePopoverItem}
                  onClick={() => onMoveFunds(v.groupName)}
                >
                  <div className={style.Item}>
                    {v.groupName === ""
                      ? formatMessage("unnamedGroup")
                      : v.groupName}
                  </div>
                </li>
              </Tooltip>
            ))(groupList)}
          </ul>
        }
        visible={visible}
        onOpenChange={handleVisibleChange}
      >
        <Button
          className={style.MoveToButton}
          type={btnType === "default" ? "default" : "text"}
          disabled={btnType === "default" && isEmpty(fundIds)}
          onClick={handleVisible}
        >
          {formatMessage("moveTo")}
        </Button>
      </Popover>
    );
  }
);
