import { Card, Row, Col, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { fetchStockIndustryDistribution } from "@/store/compareManage";
import SectorCategoriesSelect from "@/components/sectorCategoriesSelect";
import { isEmpty, prop, map, flow, orderBy } from "lodash/fp";
import { eightPercentColors } from "@/util/colors";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fastProp, mapIndexed, mapValuesIndexed } from "@/util/opt";
import PieCard from "../../components/pieCard";
import style from "../../index.module.less";
import { getIndustyPieChartOptions, isOther } from "../constant";
import { selectedReportDateSelector } from "../selectors";
import { fundIdMapSelector } from "@/selectors/fund";
import ReportDateSelector from "./reportDateSelector";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { defaultSectorId } from "@/constant/sectors";
import { useCreation } from "ahooks";

const useGetPieCardData = (
  fundIds: string[],
  selectedReportDate: string,
  selectedSectorId: string
) => {
  const baseData = useAppSelector(
    prop("compareManage.fundCompare.stockIndustryDistribution")
  );

  const stockIndustryDistribution = useMemo(
    () => prop(`${selectedSectorId}.${selectedReportDate}`)(baseData),
    [baseData, selectedReportDate, selectedSectorId]
  );
  const fundsMap = useAppSelector(fundIdMapSelector);
  const sectorCategoryMap = useAppSelector(sectorCategoriesMapSelector);
  const formatMessage = useFormatMessage();
  return useMemo(
    () =>
      map((id: string) => {
        const stocksInfo = flow(
          mapValuesIndexed((value: number, key: string) => ({
            industryId: key,
            value,
          })),
          orderBy("value", "desc")
        )(fastProp(id)(stockIndustryDistribution));
        const pieData = mapIndexed(
          (industry: { industryId: string; value: number }, index: number) => ({
            name: isOther(fastProp("industryId")(industry))
              ? formatMessage(fastProp("industryId")(industry))
              : formatMessage(
                  prop(`${fastProp("industryId")(industry)}.name`)(
                    sectorCategoryMap
                  )
                ),
            value: fastProp("value")(industry),
            color: eightPercentColors[index],
          })
        )(stocksInfo);
        return {
          name: prop(`${id}.name`)(fundsMap),
          pieData,
        };
      })(fundIds),
    [
      formatMessage,
      fundIds,
      fundsMap,
      sectorCategoryMap,
      stockIndustryDistribution,
    ]
  );
};

export default React.memo<{
  className?: string;
  fundIds: string[];
}>(({ className, fundIds }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const reportDate = useAppSelector(selectedReportDateSelector);
  const [selectedSectorId, setSelectedSectorId] = useState(defaultSectorId);
  const [selectedReportDate, setSelectedReportDate] = useState(
    reportDate as string
  );
  useEffect(() => {
    if (selectedSectorId && selectedReportDate && !isEmpty(fundIds)) {
      dispatch(
        fetchStockIndustryDistribution({
          sectorId: selectedSectorId,
          fundIds,
          reportDate: selectedReportDate,
        })
      );
    }
  }, [dispatch, fundIds, selectedSectorId, selectedReportDate]);

  const pieOptions = useCreation(
    () => getIndustyPieChartOptions(formatMessage),
    []
  );

  const pieCardData = useGetPieCardData(
    fundIds,
    selectedReportDate,
    selectedSectorId
  );
  return (
    <Card bordered={false} className={className}>
      <Row justify="space-between" align="middle" className={style.RowTitle}>
        <Col>
          <h3>{formatMessage("stockIndustryDistribution")}</h3>
        </Col>
        <Col>
          <Space size={12}>
            <Col>
              <Space>
                <span>{formatMessage("industryDistribution")}</span>
                <SectorCategoriesSelect
                  value={selectedSectorId}
                  onChange={setSelectedSectorId}
                />
              </Space>
            </Col>
            <Col>
              <Space>
                <span>{formatMessage("endOfDate")}</span>
                <ReportDateSelector
                  value={selectedReportDate}
                  onChange={setSelectedReportDate}
                />
              </Space>
            </Col>
          </Space>
        </Col>
      </Row>
      <Row gutter={16}>
        {map(({ name, pieData }) => (
          <Col span={12} key={name} className={style.marginBottom12}>
            <PieCard
              title={name}
              dataSource={pieData}
              pieOptions={pieOptions}
            />
          </Col>
        ))(pieCardData)}
      </Row>
    </Card>
  );
});
