import React, { useMemo } from "react";
import { EChartsOption, BarSeriesOption } from "echarts";
import { map, prop } from "lodash/fp";

import { formatPercentage } from "@/util/numberFormatter";
import { StockSelectionAbilityBody } from "@/model/fundDetail";
import {
  formatNilMessage,
  getCompareValueMsgKey,
  LEVEL_MESSAGE_KEY_MAP_1,
  LEVEL_MESSAGE_KEY_MAP_2,
  LEVEL_MESSAGE_KEY_MAP_3,
} from "@/components/fundInfoExport/constants";
import { useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";

import style from "../index.module.less";
import SimilarRanking from "../../../similarRanking";
import BaseEcharts from "../../../echarts/baseEcharts";
import SecondLevelName from "../../../secondLevelName";

export default React.memo(({ fundId }: { fundId: string }) => {
  const formatMessage = useFormatMessage();
  const stockSelectionAbility: StockSelectionAbilityBody =
    useAppSelector(prop(`fundDetail.${fundId}.stockSelectionAbility`)) || {};
  const {
    abilityRating,
    rank,
    sameTypeCount,
    top10StockWeightAvg,
    positionStockDisperseGrade,
    topStockRetainRatio,
    topStockAdjustGrade,
    selectionAbilitySummary,
    brinsonSelection,
    multiFactorSelection,
  } = stockSelectionAbility;

  const SerieOptions = useMemo(
    () => [
      {
        name: formatMessage("theFund"),
        data: [brinsonSelection?.fundValue, multiFactorSelection?.fundValue],
      },
      {
        name: formatMessage("sameTypeFundAvgValue"),
        data: [
          brinsonSelection?.sameTypeAvg,
          multiFactorSelection?.sameTypeAvg,
        ],
      },
    ],
    [
      brinsonSelection?.fundValue,
      brinsonSelection?.sameTypeAvg,
      formatMessage,
      multiFactorSelection?.fundValue,
      multiFactorSelection?.sameTypeAvg,
    ]
  );
  const options = useMemo<EChartsOption>(
    () => ({
      legend: { itemGap: 100 },
      xAxis: {
        type: "category",
        axisTick: { show: false },
        boundaryGap: true,
        data: [
          formatMessage("BRINSON"),
          formatMessage("multifactorAttribution"),
        ],
      },
      yAxis: { show: false },
      series: map((SerieOption: { name: string; data: number[] }) => ({
        ...SerieOption,
        type: "bar",
        barWidth: 68,
        barGap: "6%",
        label: {
          show: true,
          position: "top",
          fontFamily: "DINAlternate",
          fontSize: 18,
          fontWeight: "bold",
        },
      }))(SerieOptions) as BarSeriesOption[],
    }),
    [SerieOptions, formatMessage]
  );

  return (
    <div className={style.IndividualStockSelectionAbilityWrap}>
      <SecondLevelName
        name={formatMessage("individualStockSelectionAbility")}
        rateNum={abilityRating}
      />
      <SimilarRanking
        rank={rank}
        count={sameTypeCount}
        className={style.SimilarRank}
      />
      <div className={style.Text}>
        {formatMessage("stockSelectionSummary1", {
          top10StockWeightAvg: formatPercentage(top10StockWeightAvg),
          positionStockDisperseGrade: formatNilMessage(formatMessage)(
            LEVEL_MESSAGE_KEY_MAP_1,
            positionStockDisperseGrade
          ),
          topStockRetainRatio: formatPercentage(topStockRetainRatio),
          topStockAdjustGrade: formatNilMessage(formatMessage)(
            LEVEL_MESSAGE_KEY_MAP_2,
            topStockAdjustGrade
          ),
        })}
      </div>
      <div className={style.Text}>
        {formatMessage("stockSelectionSummary2", {
          selectionAbilitySummary: formatNilMessage(formatMessage)(
            LEVEL_MESSAGE_KEY_MAP_3,
            selectionAbilitySummary
          ),
          brinsonSelection: formatPercentage(
            fastProp("fundValue")(brinsonSelection)
          ),
          brinsonSelectionCmp: formatNilMessage(formatMessage)(
            LEVEL_MESSAGE_KEY_MAP_3,
            getCompareValueMsgKey(brinsonSelection)
          ),
          multiFactorSelection: formatPercentage(
            fastProp("fundValue")(multiFactorSelection)
          ),
          multiFactorSelectionCmp: formatNilMessage(formatMessage)(
            LEVEL_MESSAGE_KEY_MAP_3,
            getCompareValueMsgKey(multiFactorSelection)
          ),
        })}
      </div>
      <div className={style.ChartWrap}>
        <BaseEcharts options={options} height={200} />
      </div>
    </div>
  );
});
