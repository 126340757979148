import { useFormatMessage } from "@/util/formatMessage";
import { Empty, Tabs } from "antd";
import {
  concat,
  eq,
  map,
  flow,
  isEmpty,
  compact,
  size,
  orderBy,
} from "lodash/fp";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import style from "./index.module.less";
import AssetCard from "./assetCard";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { customerAnalysisSelector } from "../selectors";
import { RootState } from "@/store";
import { AssetListBody, fundAsset, stockAsset } from "@/model/customer";
import { fastProp } from "@/util/opt";
import { toFixedNumber } from "@/util/numberFormatter";
import { fetchFundsDailyReturn } from "@/store/customerAccountAnalysis";
import { RECENT_MONTH } from "@/constant/statisticRange";

type PositionType = "left";
const assetType = [
  {
    id: "all",
    message: "all",
  },
  {
    id: "fund",
    message: "fundNavWeight",
  },
  {
    id: "stock",
    message: "stockNavWeight",
  },
  {
    id: "bonds",
    message: "bonds",
  },
];

const useGetAssetData = (assetList: AssetListBody) => {
  const fundInfos = fastProp("fundInfos")(assetList);
  const stockInfos = fastProp("stockInfos")(assetList);
  const fundData = useMemo(
    () =>
      flow(
        map((item: fundAsset) => ({ ...item, type: "fund" })),
        orderBy("totalProfit", "desc")
      )(fundInfos || []),
    [fundInfos]
  );
  const stockData = useMemo(
    () =>
      flow(
        map((item: stockAsset) => ({ ...item, type: "stock" })),
        orderBy("totalProfit", "desc")
      )(stockInfos || []),
    [stockInfos]
  );
  const assetData = useMemo(() => {
    return {
      all: flow(concat(fundData))(stockData),
      fund: fundData,
      stock: stockData,
    };
  }, [fundData, stockData]);
  return assetData;
};
export default React.memo(({ customerId }: { customerId: string }) => {
  const formatMessage = useFormatMessage();
  const [type, setType] = useState("all");
  // const columns = getAssetsColumns(formatMessage);
  const handleOnChange = useCallback(
    (v: string) => {
      setType(eq(v)(type) ? "all" : v);
    },
    [type]
  );

  const customerAnalysis = useAppSelector((state: RootState) =>
    customerAnalysisSelector(state, customerId)
  );
  const { assetList } = customerAnalysis;
  const assetDataMap = useGetAssetData(assetList);
  const OperationsSlot: Record<PositionType, React.ReactNode> = {
    left: (
      <div className={style.AssetTitle}>
        <h4>{formatMessage("assetList")}</h4>（{formatMessage("cash")}：
        {toFixedNumber(2)(fastProp("cash")(assetList))}
        {formatMessage("YUAN")}）
      </div>
    ),
  };

  const assetData = useMemo(
    () => fastProp(type)(assetDataMap),
    [type, assetDataMap]
  );

  const fundCodes = useMemo(
    () => flow(map(fastProp("fundCode")), compact)(assetData),
    [assetData]
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (size(fundCodes)) {
      dispatch(fetchFundsDailyReturn({ fundCodes, section: RECENT_MONTH }));
    }
  }, [dispatch, fundCodes]);

  return (
    <Tabs
      tabBarExtraContent={OperationsSlot}
      activeKey={type}
      onTabClick={handleOnChange}
      className={style.Tabs}
    >
      {map((item: Record<string, string>) => (
        <Tabs.TabPane tab={formatMessage(item?.message)} key={item?.id}>
          {isEmpty(assetData) ? <Empty /> : <AssetCard assetList={assetData} />}
        </Tabs.TabPane>
      ))(assetType)}
    </Tabs>
  );
});
