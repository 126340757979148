import dayjs from "dayjs";
import { FundRatingTrendType } from "@/model/fundDetail";
import { fastProp } from "@/util/opt";
import { operatingCosts } from "@/views/fundDetail/constant";
import { createSelector } from "@reduxjs/toolkit";
import { flow, orderBy, first, prop, map, pick, sumBy } from "lodash/fp";
import { fundBasicSelector } from "../../../mapper";

export const FundInfoSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => ({
    ...fastProp("basic")(fundBasic),
    fundRating: flow(
      fastProp("fundRatingTrend"),
      orderBy("reportDate", "desc"),
      first,
      fastProp("bfRating")
    )(fundBasic),
    fundStyle: fastProp("fundStyle")(fundBasic),
  })
);

export const FundScaleTrendSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => ({
    fundScale: flow(
      fastProp("fundScaleTrend"),
      orderBy("reportDate", "asc")
    )(fundBasic),
    name: prop("basic.name")(fundBasic),
  })
);

export const FundRatingTrendSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => ({
    fundRating: flow(
      fastProp("fundRatingTrend"),
      orderBy("reportDate", "asc"),
      map((v: FundRatingTrendType) => ({
        ...v,
        bfRating: v.bfRating || 0,
      }))
    )(fundBasic),
    name: prop("basic.name")(fundBasic),
  })
);

export const FundOperatingCostsSelector = createSelector(
  fundBasicSelector,
  (fundBasic) =>
    flow(
      fastProp("basic"),
      pick(map(fastProp("id"))(operatingCosts))
    )(fundBasic)
);

export const FundSummaryResultSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => {
    const { fundSummaryProgress, fundSummaryTask } = fundBasic || {};
    const {
      fundReturnRiskSummary,
      fundPositionStyleSummary: fundPositionStyle,
      fundManagerSummary,
      fundCompanySummary: fundCompany,
    } = fundSummaryTask || {};
    const fundPositionStyleSummary = {
      ...fundPositionStyle,
      firstInvestType: fundBasic?.basic?.firstInvestType,
      fundStyle: fundBasic?.fundStyle,
      stabilityOfStyle: fundBasic?.stabilityOfStyle,
      top3IndustryNames: map(fastProp("industryName"))(
        fundPositionStyle?.top3IndustryWeight
      ),
      top3IndustryWeight: sumBy("weight")(
        fundPositionStyle?.top3IndustryWeight
      ),
    };
    const fundCompanySummary = {
      ...fundCompany,
      foundDate: fundCompany?.foundDate,
      foundYear: fundCompany?.foundDate
        ? dayjs(fundCompany?.foundDate).format("YYYY")
        : "",
    };
    return {
      fundSummaryProgress,
      fundReturnRiskSummary,
      fundPositionStyleSummary,
      fundManagerSummary,
      fundCompanySummary,
    };
  }
);

export default createSelector(
  FundInfoSelector,
  FundScaleTrendSelector,
  FundRatingTrendSelector,
  FundOperatingCostsSelector,
  FundSummaryResultSelector,
  (
    fundInfo,
    fundScaleTrend,
    fundRatingTrend,
    fundOperatingCosts,
    fundSummaryResult
  ) => ({
    fundInfo,
    ...fundScaleTrend,
    fundRatingTrend,
    fundOperatingCosts,
    ...fundSummaryResult,
  })
);
