import React from "react";
import { Button, Card, Popconfirm } from "antd";
import cn from "classnames";
import EmptyContent from "@/components/emptyContent";
import { PlusOutlined } from "@ant-design/icons";
import { size } from "lodash/fp";
import trashActive from "@/assets/trashActive.svg";
import { ManualCreatePortfolio } from "../constant";
import { fastProp, mapIndexed, fastNth } from "@/util/opt";
import ChangePositionItem from "../components/changePositionItem";
import { Updater } from "../hooks";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";

export default React.memo<{
  className?: string;
  changingPositionData: ManualCreatePortfolio[];
  onUpdate: (index: number) => (key: string) => (updater: Updater) => any;
  onAdd: (index?: number | undefined) => any;
  onRemove: (index: number) => any;
  onClear: () => any;
  positionDate: string | null;
}>(
  ({
    className,
    changingPositionData,
    onUpdate,
    onAdd,
    positionDate,
    onRemove,
    onClear,
  }) => {
    const formatMessage = useFormatMessage();
    return (
      <Card
        title="调仓"
        extra={
          changingPositionData.length ? (
            <Popconfirm
              onConfirm={onClear}
              title={formatMessage("clearChangingPositionConfirm")}
            >
              <p className={style.ClearButton}>
                <img src={trashActive} className={style.ClearIcon} />
                {formatMessage("clear")}
              </p>
            </Popconfirm>
          ) : null
        }
        className={className}
      >
        {changingPositionData.length ? (
          <div>
            {mapIndexed((data: ManualCreatePortfolio, index: number) => (
              <ChangePositionItem
                onUpdate={onUpdate(index)}
                onRemove={() => onRemove(index)}
                changingPositionData={data}
                index={index + 1}
                prevTurnoverDate={
                  index === 0
                    ? positionDate
                    : fastProp("turnoverDate")(
                        fastNth(index - 1)(changingPositionData)
                      )
                }
                nextTurnoverDate={fastProp("turnoverDate")(
                  fastNth(index + 1)(changingPositionData)
                )}
                key={index + (data.turnoverDate || "")}
              />
            ))(changingPositionData)}
            <Button
              className={cn(style.AddChangingPositionButton, style.MarginTop12)}
              icon={<PlusOutlined />}
              onClick={() => onAdd(size(changingPositionData) - 1)}
            >
              添加调仓
            </Button>
          </div>
        ) : (
          <EmptyContent message="暂无调仓数据">
            <Button
              className={style.AddChangingPositionButton}
              icon={<PlusOutlined />}
              onClick={() => onAdd()}
            >
              添加调仓
            </Button>
          </EmptyContent>
        )}
      </Card>
    );
  }
);
