import { createSelector } from "@reduxjs/toolkit";
import {
  forEachIndexed,
  fastNth,
} from "@/util/opt";
import { map, sumBy } from "lodash/fp";
import { subAttributionsType } from "@/model/fundDetail";
import { fundAttributionBrinsonResultSelector, fundDetailIsETFSelector } from "../common-selector";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import getBrinsonResultCal from "./brinson-result-cal";
import getETFBrinsonResultCal from "./eft-brinson-result-cal";

export const getAShareAllocationAndSelectionAttributions = (
  subAttributions: Array<subAttributionsType>,
  arraySize: number
) => {
  const dailyAShareAllocationAttributions: number[] = [];
  const dailyAShareSelectionAttributions: number[] = [];
  const dailyAShareTimingAttributions: number[] = [];
  forEachIndexed((_: any, index: number) => {
    const sumData = map(
      ({
        dailyAllocationAttributions,
        dailySelectionAttributions,
        dailyTimingAttributions,
      }: subAttributionsType) => ({
        dailyAllocationAttribution: fastNth(index)(dailyAllocationAttributions),
        dailySelectionAttribution: fastNth(index)(dailySelectionAttributions),
        dailyTimingAttribution: fastNth(index)(dailyTimingAttributions),
      })
    )(subAttributions);
    dailyAShareAllocationAttributions.push(
      sumBy("dailyAllocationAttribution")(sumData)
    );
    dailyAShareSelectionAttributions.push(
      sumBy("dailySelectionAttribution")(sumData)
    );
    dailyAShareTimingAttributions.push(
      sumBy("dailyTimingAttribution")(sumData)
    );
  })(new Array(arraySize));
  return {
    dailyAShareAllocationAttributions,
    dailyAShareSelectionAttributions,
    dailyAShareTimingAttributions,
  };
};
export const getInitalResultSelector = createSelector(
  fundAttributionBrinsonResultSelector,
  sectorCategoriesMapSelector,
  fundDetailIsETFSelector,
  (result, sectors,isETF) => {
    return isETF? getETFBrinsonResultCal(result,sectors) : getBrinsonResultCal(result,sectors);
  }
);
