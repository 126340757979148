import React, { useReducer, useMemo } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { Spin } from "antd";
import { useAppSelector } from "@/hooks/redux";
import { prop } from "lodash/fp";
import { WindowSizeProvider } from "./providers/windowSizeProvider";
import store from "./store/index";
import localeConfigs from "./locales/index";
import {
  GlobalReducer,
  globalDataInit,
  ChoiceLang,
} from "./contexts/ChoiceLang";
import { useFormatMessage } from "./util/formatMessage";
import router from "./router";

const RouterComponent = React.memo(() => useRoutes(router));

function ReduxRoot() {
  const loadingCount = useAppSelector<number>(prop("global.loadingCount"));
  const formatMessage = useFormatMessage();
  return (
    <Spin
      spinning={loadingCount > 0}
      tip={formatMessage("loading")}
      wrapperClassName="globalSpin"
    >
      <BrowserRouter>
        <WindowSizeProvider>
          <RouterComponent />
        </WindowSizeProvider>
      </BrowserRouter>
    </Spin>
  );
}

export default function App(): JSX.Element {
  const [globalState, setGlobalState] = useReducer(
    GlobalReducer,
    globalDataInit
  );
  const antdLocale = useMemo(
    () => prop(`${globalState.lang}.antd`)(localeConfigs),
    [globalState]
  );
  const intlLocale = useMemo(
    () => prop(`${globalState.lang}.locale`)(localeConfigs),
    [globalState]
  );
  const intlMessages = useMemo(
    () => prop(`${globalState.lang}.messages`)(localeConfigs),
    [globalState]
  );

  return (
    <div>
      <Provider store={store}>
        <ConfigProvider locale={antdLocale}>
          <IntlProvider
            key={intlLocale}
            locale={intlLocale}
            messages={intlMessages}
            defaultLocale="zh"
          >
            <ChoiceLang.Provider value={{ globalState, setGlobalState }}>
              <ReduxRoot />
            </ChoiceLang.Provider>
          </IntlProvider>
        </ConfigProvider>
      </Provider>
    </div>
  );
}
