import React, { useState, useMemo, useCallback, useContext } from "react";
import { useCreation } from "ahooks";
import { Radio, Row, Col, Space } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { RANGERETURN, HISTORYRETURN, getColumns } from "../../constant";
import PortfolioAnalysisSubtitle from "../../components/portfolioAnalysisSubtitle";
import BarChart, { BarChartOpts } from "@/echarts/barChart";
import { formatPercentage } from "@/util/numberFormatter";
import { fastNth, fastProp, forEachIndexed, mapIndexed } from "@/util/opt";
import HorizontalTable from "@/components/horizontalTable";
import {
  map,
  prop,
  forEach,
  last,
  flow,
  keys,
  pick,
  first,
  size,
  filter,
  values,
  fromPairs,
} from "lodash/fp";
import style from "../../index.module.less";
import SummaryCard from "../../components/summaryCard";
import FrequencySelect from "../../components/frequencySelect";
import { useAppSelector } from "@/hooks/redux";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { RANGE_COLOR } from "@/util/colors";
import { ColumnType } from "antd/lib/table";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);
import { dateFormat } from "@/util/dateFormat";
import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";
import { freQuencyOptions, getRangeReturnChartAndTable } from "../constant";
import { analysisBasicInfoSelector } from "../../selectors";
import { RootState } from "@/store";
import { tradingDatesSelector } from "@/selectors/tradingDates";
import { useGetStatisticRange } from "@/views/portfolioManage/portfolioAnalysis/hooks";
import { RangeInterface } from "@/constant/statisticRange";
import { assign } from "lodash/fp";
import ErrorBoundary from "@/components/errorBoundary";
import { checkNeedTime } from "@/providers/portfolioAnalysisProvider/provider";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import { errorValidator } from "@/components/errorBoundary/constant";
import { getSquareLegendConfig, getTooltipItem } from "@/util/chart";
import { getCumulativeCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";

const needTime = 2;
const staticRange = assign(
  {
    RECENT_WEEK: {
      id: "RECENT_WEEK",
      message: "RECENT_WEEK",
      period: 7,
      count: "day",
    },
  },
  portfolioAnalysisStatisticRange
);
const percentage = (value: number) => formatPercentage(value);

const getCalculateFrequencyDate = (
  tradingDate: string[],
  frequency: string
) => {
  const dates: Record<string, string[]> = {};
  forEach((date: string) => {
    let dataIndex: any = "";
    if (frequency === "RECENT_MONTH") {
      dataIndex = dayjs(
        `${dayjs(date).get("year")}-${dayjs(date).get("month") + 1}`
      ).format("YYYY-MM");
    } else if (frequency === "RECENT_THREE_MONTH") {
      dataIndex = `${dayjs(date).get("year")}-${dayjs(date).quarter()}`;
    } else {
      dataIndex = dayjs(date).get("year").toString();
    }

    if (fastProp(dataIndex)(dates)) {
      dates[dataIndex] = dates[dataIndex].concat([date]);
    } else {
      dates[dataIndex] = [date];
    }
  })(tradingDate);
  return dates;
};

const getlastIncomeData = (
  analysisType: string,
  period: string,
  incomeDatas: Record<string, any>
) => {
  const data = flow(
    filter((item: Record<string, any>) => item.returnName === analysisType)
  )(incomeDatas);
  return flow(first, fastProp(period))(data);
};

const useGetBarChartDataSource = (
  id: string,
  returnType: string,
  frequency: string,
  runningTime: number
) => {
  const portfolioSummary = useAppSelector((state: RootState) =>
    analysisBasicInfoSelector(state, id)
  );
  const dailyReturns = useAppSelector(prop("dailyReturns"));

  const { tradingDate, dailyReturn, benchmark, portfolioName } =
    portfolioSummary;
  const formatMessage = useFormatMessage();

  const isRangeReturn = returnType === "rangeReturns";

  //业绩基准
  const performanceBenchmarkReturn = useMemo(
    () => fastProp(benchmark)(dailyReturns),
    [benchmark, dailyReturns]
  );
  const performanceBenchmarkReturnMap = useMemo(
    () =>
      normalizeDailyReturnsMap(
        fastProp("dates")(performanceBenchmarkReturn),
        fastProp("dailyReturns")(performanceBenchmarkReturn)
      ),
    [performanceBenchmarkReturn]
  );

  const dailyReturnMap = useMemo(
    () => normalizeDailyReturnsMap(tradingDate, dailyReturn),
    [dailyReturn, tradingDate]
  );

  const { tradingDateList, processedTradingDates } =
    useAppSelector(tradingDatesSelector);

  const startDate = useMemo(() => first(tradingDate), [tradingDate]);
  const endData = useMemo(() => last(tradingDate), [tradingDate]);

  const calcStatisticRangeList = useGetStatisticRange(2);
  //计算区间
  const statisticRangeList = useCreation(
    () =>
      calcStatisticRangeList(
        startDate as string,
        endData as string,
        staticRange as Record<string, RangeInterface>
      ),
    [startDate, endData, staticRange]
  );
  const statisticRangeLists = flow(
    map((item: Record<string, any>) => [item.id, item]),
    fromPairs
  )(values(statisticRangeList));
  const rangeList = useMemo(() => {
    const listData: Record<string, any> = {};
    forEach(({ id }) => {
      listData[id] = getCumulativeCalculateRangeDate({
        dates: tradingDate,
        range: id,
        tradingDateList,
        processedTradingDates,
        thisYearDate: last(tradingDate),
      });
    })(statisticRangeLists);
    return listData;
  }, [
    processedTradingDates,
    statisticRangeLists,
    tradingDate,
    tradingDateList,
  ]);
  const { chartDatas, tableDatas } = useMemo(() => {
    if (checkNeedTime(needTime, runningTime)) {
      const nanData = flow(
        mapIndexed((_: any, key: string) => [key, NaN]),
        fromPairs
      )(rangeList);
      return {
        chartDatas: [],
        tableDatas: [
          { ...nanData, returnName: portfolioName },
          { ...nanData, returnName: formatMessage("performanceBenchmark") },
        ],
      };
    }
    return getRangeReturnChartAndTable(
      dailyReturnMap,
      performanceBenchmarkReturnMap,
      rangeList,
      formatMessage,
      tradingDate,
      portfolioName
    );
  }, [
    dailyReturnMap,
    formatMessage,
    performanceBenchmarkReturnMap,
    rangeList,
    runningTime,
    tradingDate,
    portfolioName,
  ]);

  const calculatedFrequencyDates = useMemo(
    () => getCalculateFrequencyDate(tradingDate, frequency),
    [frequency, tradingDate]
  );
  const historyReturnData = useMemo(() => {
    const { chartDatas, tableDatas } = getRangeReturnChartAndTable(
      dailyReturnMap,
      performanceBenchmarkReturnMap,
      calculatedFrequencyDates,
      formatMessage,
      tradingDate,
      portfolioName
    );
    return { historyChartDatas: chartDatas, historyTableDatas: tableDatas };
  }, [
    formatMessage,
    calculatedFrequencyDates,
    dailyReturnMap,
    performanceBenchmarkReturnMap,
    tradingDate,
    portfolioName,
  ]);

  const categories = useMemo(() => {
    if (isRangeReturn) {
      return mapIndexed((item: Record<string, any>) =>
        formatMessage(fastProp("message")(item))
      )(statisticRangeLists);
    } else {
      if (frequency === "RECENT_MONTH") {
        const currentMonth = `${dayjs().get("year")}-${
          dayjs().get("month") + 1
        }`;
        return flow(filter((item: string) => item <= currentMonth))(
          keys(calculatedFrequencyDates)
        );
      } else if (frequency === "RECENT_THREE_MONTH") {
        const currentQuarter = `${dayjs().get("year")}-${dayjs().quarter()}`;
        return flow(filter((item: string) => item <= currentQuarter))(
          keys(calculatedFrequencyDates)
        );
      } else {
        return keys(calculatedFrequencyDates);
      }
    }
  }, [
    isRangeReturn,
    statisticRangeLists,
    calculatedFrequencyDates,
    formatMessage,
    frequency,
  ]);

  const { historyChartDatas, historyTableDatas } = historyReturnData;

  const chartData = useMemo<BarChartOpts["options"]>(() => {
    return {
      categories: categories,
      grid: {
        top: 50,
        bottom: 50,
        right: 20,
      },
      yAxis: {
        axisLabel: {
          formatter(val: number) {
            return percentage(val);
          },
        },
      },
      legend: getSquareLegendConfig(),
      tooltip: {
        valueFormatter: (value) => {
          return percentage(value as number);
        },
        formatter: (param: Record<string, any>) => {
          let showHtml = "";
          forEachIndexed((item: Record<string, any>, index: number) => {
            showHtml +=
              index === 0
                ? `<h5>
                ${item.name}
                </h5>`
                : "";
            return (showHtml += getTooltipItem(
              item.color,
              item.seriesName,
              percentage(item.value[index + 1] as number),
              10,
              5
            ));
          })(param);
          return showHtml;
        },
      },
      xAxis: {
        nameGap: 80,
        data: categories,
        interval: 0,
        axisLabel: {
          formatter(value: string) {
            return value;
          },
          align: "right",
        },
      },
      data: isRangeReturn ? chartDatas : historyChartDatas,
      series: mapIndexed((name: any, index: number) => ({
        color: fastNth(index)(RANGE_COLOR),
        barMaxWidth: "30%",
        name,
      }))(isRangeReturn ? keys(chartDatas) : keys(historyChartDatas)),
    };
  }, [categories, isRangeReturn, chartDatas, historyChartDatas]);
  const tableData = useMemo(() => {
    if (!isRangeReturn && frequency) {
      return map((item) => ({
        ...pick([...keys(calculatedFrequencyDates)].concat(["returnName"]))(
          item
        ),
        align: "center",
        render: (value: number) => {
          return percentage(value);
        },
      }))(historyTableDatas);
    } else {
      return map((item) => ({
        ...pick([...keys(staticRange)].concat(["returnName"]))(item),
        align: "center",
        render: (value: number) => {
          return percentage(value);
        },
      }))(tableDatas);
    }
  }, [
    isRangeReturn,
    frequency,
    historyTableDatas,
    calculatedFrequencyDates,
    tableDatas,
  ]);

  const columns = useMemo(() => {
    if (isRangeReturn) {
      return getColumns(formatMessage);
    } else {
      return map((item: string) => ({
        columnName: item,
        dataIndex: item,
      }))(keys(calculatedFrequencyDates));
    }
  }, [isRangeReturn, calculatedFrequencyDates, formatMessage]);

  const summaryText = useMemo(() => {
    const startDate = first(tradingDate);
    const preYearDate: string = dateFormat(
      dayjs(last(tradingDate)).subtract(1, "year")
    );
    const preMonthDate: string = dateFormat(
      dayjs(last(tradingDate)).subtract(1, "month")
    );
    const lastYearIncome = getlastIncomeData(
      portfolioName,
      "RECENT_YEAR",
      tableDatas
    );
    const lastMonthIncome = getlastIncomeData(
      portfolioName,
      "RECENT_MONTH",
      tableDatas
    );
    const lastBenchmarklastYearIncome = getlastIncomeData(
      formatMessage("performanceBenchmark"),
      "RECENT_YEAR",
      tableDatas
    );
    const lastBenchmarklastMonthIncome = getlastIncomeData(
      formatMessage("performanceBenchmark"),
      "RECENT_MONTH",
      tableDatas
    );
    const dataNumber: number = size(values(historyChartDatas)[0]);
    const benmarkValue = values(historyChartDatas)[1];
    const yeildValue = values(historyChartDatas)[0];
    const yeildHightNumber = flow(
      mapIndexed(
        (_: any, index: number) => yeildValue[index] > benmarkValue[index]
      ),
      filter((item: boolean) => item === true),
      size
    )(yeildValue);
    const incomeRate = percentage(yeildHightNumber / dataNumber);
    const timeRange =
      frequency === "RECENT_MONTH"
        ? formatMessage("month")
        : frequency === "RECENT_YEAR"
        ? formatMessage("particularYear")
        : formatMessage("quarter");
    if (isRangeReturn) {
      if (preMonthDate < (startDate as string)) {
        // 不足一月
        return;
      } else if (preYearDate < (startDate as string)) {
        // 不足一年
        return formatMessage("portfolioAnalysisRangeReturnLessYearSummary", {
          lastMonthIncome: percentage(lastMonthIncome),
          incomeCompare:
            lastMonthIncome > lastBenchmarklastMonthIncome
              ? formatMessage("betterThan")
              : formatMessage("InferiorTo"),
          shortPerformance:
            lastMonthIncome > lastBenchmarklastMonthIncome
              ? formatMessage("preferably")
              : formatMessage("commonly"),
        });
      } else {
        return formatMessage("portfolioAnalysisRangeReturnWholeSummary", {
          lastYearIncome: percentage(lastYearIncome),
          lastMonthIncome: percentage(lastMonthIncome),
          lastYearCompare:
            lastYearIncome > lastBenchmarklastYearIncome
              ? formatMessage("betterThan")
              : formatMessage("InferiorTo"),
          lastMonthCompare:
            lastMonthIncome > lastBenchmarklastMonthIncome
              ? formatMessage("betterThan")
              : formatMessage("InferiorTo"),
          longPerformance:
            lastYearIncome > lastBenchmarklastYearIncome
              ? formatMessage("preferably")
              : formatMessage("commonly"),
          shortPerformance:
            lastMonthIncome > lastBenchmarklastMonthIncome
              ? formatMessage("preferably")
              : formatMessage("commonly"),
        });
      }
    } else {
      if (flow(keys, size)(calculatedFrequencyDates) > 3) {
        return formatMessage("portfolioAnalysisHistoryReturnSummary", {
          frequency: formatMessage(
            flow(fastProp(frequency), fastProp("message"))(freQuencyOptions)
          ),
          timeRange: `${yeildHightNumber}个${timeRange}`,
          sampleRatio: incomeRate,
          winRate:
            yeildHightNumber / dataNumber >= 0.7
              ? formatMessage("higher")
              : yeildHightNumber / dataNumber <= 0.3
              ? formatMessage("lower")
              : formatMessage("moderate"),
        });
      } else {
        return;
      }
    }
  }, [
    tradingDate,
    formatMessage,
    calculatedFrequencyDates,
    isRangeReturn,
    tableDatas,
    historyChartDatas,
    frequency,
    portfolioName,
  ]);

  return {
    chartData,
    tableData,
    isRangeReturn,
    columns,
    summaryText,
  };
};

export default React.memo<{
  id: string;
  frequency: string;
  onChange: (key: string) => (value: any) => any;
}>(({ onChange, id, frequency }) => {
  const formatMessage = useFormatMessage();
  const [returnType, setReturnType] = useState<string>("rangeReturns");
  const updateHandler = useCallback((value) => onChange(value), [onChange]);

  const headingColumn = useMemo<ColumnType<any>>(
    () => ({
      title: "",
      dataIndex: "columnName",
      align: "center",
    }),
    []
  );

  const { runningTime } = useContext(PortfolioAnalysisContext);
  const { chartData, tableData, isRangeReturn, columns, summaryText } =
    useGetBarChartDataSource(id, returnType, frequency, runningTime);
  return (
    <>
      <PortfolioAnalysisSubtitle name={formatMessage("rangeReturns")} />

      <Space direction="vertical" className={style.fullWidth}>
        <Radio.Group
          onChange={(v) => setReturnType(v.target.value)}
          value={returnType}
          optionType="button"
          buttonStyle="solid"
        >
          <Radio.Button value={RANGERETURN}>
            {formatMessage("rangeReturns")}
          </Radio.Button>
          <Radio.Button value={HISTORYRETURN}>
            {formatMessage("historyReturns")}
          </Radio.Button>
        </Radio.Group>
        <ErrorBoundary
          errKey={errorValidator.lessTwoTradingDate(needTime, runningTime)}
        >
          <Space direction="vertical" className={style.fullWidth}>
            <Row gutter={24}>
              <Col span={18}>
                <BarChart
                  options={chartData}
                  height={440}
                  showDataZoom={isRangeReturn ? false : true}
                />
              </Col>
              <Col span={6}>
                <Space direction="vertical" className={style.fullWidth}>
                  {!isRangeReturn && (
                    <Space direction="vertical" className={style.fullWidth}>
                      <div className={style.frequencySelectPart}>
                        <Space>
                          <span>{formatMessage("frequency")}</span>
                          <FrequencySelect
                            className={style.FrequencySelect}
                            value={frequency}
                            onChange={updateHandler("frequency")}
                          />
                        </Space>
                      </div>
                    </Space>
                  )}
                  <HorizontalTable
                    id="returnName"
                    titleKey="returnName"
                    key="dataIndex"
                    headingColumn={headingColumn}
                    columns={columns}
                    dataSource={tableData}
                    className={style.Table}
                    pagination={false}
                    bordered
                    scroll={{ y: 350 }}
                  />
                </Space>
              </Col>
            </Row>
            {summaryText && <SummaryCard summaryText={summaryText} />}
          </Space>
        </ErrorBoundary>
      </Space>
    </>
  );
});
