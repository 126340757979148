import { useMemo, useState, useEffect } from "react";
import { Space, TableProps, Tooltip } from "antd";
import { prop, flow, find } from "lodash/fp";
import { useMemoizedFn } from "ahooks";
// import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { formatFactors } from "@/constant/factorFormatter";
import ToFundManagerDetailPage from "@/components/navigateToPage/toFundManagerDetailPage";
import ToFundCompanyDetailPage from "@/components/navigateToPage/toFundCompanyDetailPage";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { useFormatMessage } from "@/util/formatMessage";
import { fundManagerMapSelector } from "@/views/compareManage/fundManagerCompare/selectors";
import { fundIdMapSelector } from "@/selectors/fund";
import Rating from "@/components/rating";
import { fastProp, normalize } from "@/util/opt";
import {
  fetchDeleteOptionalFund,
  // fetchAddGroupFundList,
  fetchAddGroupFundManagerList,
} from "@/store/workbench";
import { toFixedNumber, formatPercentage } from "@/util/numberFormatter";
import AddOptional from "@/components/addOptional";
import {
  FundManager,
  optionalFundManagerGroupList,
  FundManagerFund,
} from "./interface";
import MovePopover from "./components/movePopover";
import DeletePopover from "../components/deletePopover";
import { optionalFundManagerGroupListSelector } from "./selector";
import style from "./index.module.less";
import { FUND_MANAGER } from "../constant";

export const useGetFundManagerColumns = (groupId: string) => {
  const formatMessage = useFormatMessage();
  const fundManagerMap = useAppSelector(fundManagerMapSelector);
  const allFundIdMap = useAppSelector(fundIdMapSelector);
  return useMemo<FundManagerAntdTableProps["columns"]>(
    () => [
      {
        title: formatMessage("fundManagers"),
        dataIndex: "fundManagerId",
        width: 100,
        align: "left",
        render: (value) => (
          <ToFundManagerDetailPage
            id={value}
            name={prop(`${value}.name`)(fundManagerMap)}
            className={style.MaxWidth}
          />
        ),
      },
      {
        title: formatMessage("fundManagerRating"),
        dataIndex: "fundManagerRating",
        width: 150,
        align: "left",
        render: (value) => <Rating rating={value || 0} />,
      },
      {
        title: formatMessage("belongCompang"),
        dataIndex: "fundCompanyId",
        width: 150,
        align: "left",
        render: (value, { fundCompany }) => (
          <ToFundCompanyDetailPage
            name={fundCompany as string}
            id={value}
            className={style.MaxWidth}
          />
        ),
      },
      {
        title: formatMessage("workTimeTag"),
        dataIndex: "workYear",
        width: 110,
        align: "right",
        sorter: true,
        render: (value) => `${value}${formatMessage("year")}`,
      },
      {
        title: formatMessage("representativeWorks"),
        dataIndex: "majorFund",
        width: 150,
        align: "left",
        render: (value) => (
          <ToFundDetailPage
            name={prop(`${value}.name`)(allFundIdMap)}
            id={value}
            className={style.MaxWidth}
          />
        ),
      },
      {
        title: formatMessage("investType"),
        dataIndex: "investType",
        width: 100,
        align: "left",
        ellipsis: {
          showTitle: false,
        },
        render: (value) => <Tooltip title={value}>{value}</Tooltip>,
      },
      {
        title: formatMessage("manageFundNumber"),
        dataIndex: "manageFundNum",
        width: 140,
        align: "right",
        sorter: true,
      },
      {
        title: `${formatMessage("manageSize")}(${formatMessage("Millions")})`,
        dataIndex: "manageScale",
        width: 130,
        align: "right",
        sorter: true,
        render: (value) => formatFactors(value, "scaleWithHundredMillion"),
      },
      {
        title: formatMessage("operator"),
        dataIndex: "fundManagerId",
        fixed: "right",
        align: "center",
        render: (id) => (
          <Space className={style.Operator}>
            <MovePopover
              fundManagerIds={[id]}
              groupId={groupId}
              type={FUND_MANAGER}
            />
            <DeletePopover ids={[id]} selected={groupId} type={FUND_MANAGER} />
          </Space>
        ),
        width: 130,
      },
    ],
    [formatMessage, fundManagerMap, allFundIdMap, groupId]
  );
};

export type FundManagerAntdTableProps = TableProps<FundManager>;

export type FundManagerFundAntdTableProps = TableProps<FundManagerFund>;

export const useExpandedColumns = () => {
  const formatMessage = useFormatMessage();
  return useMemo<FundManagerFundAntdTableProps["columns"]>(
    () => [
      {
        title: formatMessage("fundName"),
        dataIndex: "name",
        align: "left",
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (text: string, { fundId }) => (
          <ToFundDetailPage
            name={text}
            id={fundId}
            className={style.MaxWidth}
          />
        ),
      },
      {
        title: formatMessage("fundCode"),
        dataIndex: "code",
        align: "left",
        width: 100,
        ellipsis: {
          showTitle: false,
        },
        render: (text: string, { fundId }) => (
          <ToFundDetailPage name={text} id={fundId} />
        ),
      },
      {
        title: formatMessage("fundType"),
        dataIndex: "fundType",
        key: "fundType",
        align: "left",
        width: 200,
      },
      {
        title: formatMessage("LatestNetValue"),
        dataIndex: "latestNetValue",
        width: 100,
        align: "right",
        sorter: true,
        render: toFixedNumber(2),
      },
      {
        title: formatMessage("DailyYield"),
        dataIndex: "latestDailyReturn",
        width: 100,
        align: "right",
        sorter: true,
        render: (value) => formatPercentage(value, { digit: 2 }),
      },
      {
        title: formatMessage("purchaseStatus"),
        dataIndex: "purchStatus",
        width: 100,
        align: "left",
      },
      {
        title: formatMessage("redemption"),
        dataIndex: "redemStatus",
        // width: 100,
        align: "left",
      },
      // {
      //   title: formatMessage("yieldAfterFavorite"),
      //   dataIndex: "createDateCumulativeReturn",
      //   width: 160,
      //   align: "left",
      //   render: (value, record: FundManagerFund) =>
      //     `${formatPercentage(value)} (${
      //       isNumber(fastProp("createDay")(record))
      //         ? fastProp("createDay")(record)
      //         : ""
      //     }天)`,
      // },
      {
        title: formatMessage("operator"),
        dataIndex: "fundId",
        fixed: "right",
        align: "center",
        render: (id) => (
          <AddOptional id={id} shape="icon" className={style.AddOperator} />
        ),
        width: 130,
      },
    ],
    [formatMessage]
  );
};

export const useFundManagerOptionalService = () => {
  // todo update 获取自选列表数据最好再这里，但要解决同时调用此处导致的重复请求问题.
  const optionalGruopName = null;
  const dispatch = useAppDispatch();
  const groups = useAppSelector(optionalFundManagerGroupListSelector);
  const groupMap = useMemo(
    () => normalize<optionalFundManagerGroupList>("id")(groups),
    [groups]
  );
  const optionalMap = useMemo(
    () =>
      flow(
        find(
          (item: optionalFundManagerGroupList) =>
            item?.groupName === optionalGruopName
        ),
        (v: optionalFundManagerGroupList) => v?.fundManagerViews,
        normalize("fundManagerId")
      )(groups),
    [groups]
  );

  const isOptionaled = useMemoizedFn((id: string) => !!optionalMap?.[id]);

  const addOptional = useMemoizedFn((id: string) => {
    return dispatch(fetchAddGroupFundManagerList(id));
  });
  const defaultGroupId = useMemo(
    () =>
      flow(
        find(
          (item: optionalFundManagerGroupList) =>
            item?.groupName === optionalGruopName
        ),
        fastProp("id")
      )(groups),
    [groups]
  );
  const cancelOptional = useMemoizedFn((id: string) => {
    return dispatch(
      fetchDeleteOptionalFund({
        groupId: defaultGroupId,
        fundManagerIds: [id],
        type: FUND_MANAGER,
      })
    );
  });

  // const addToGroup = useMemoizedFn((ids: string[], groupName: string) => {
  //   return dispatch(
  //     fetchAddGroupFundList({
  //       groupName,
  //       fundIds: ids,
  //     })
  //   );
  // });

  return {
    optionalMap,
    isOptionaled,
    groups,
    groupMap,
    add: addOptional,
    cancel: cancelOptional,
    // addToGroup,
  };
};

export const useGetRowSelection = (
  fundManagersMap: Record<string, FundManager>
): [string[], FundManager[], FundManagerAntdTableProps["rowSelection"]] => {
  const [selectedFundCodes, changeSelectedFundCodes] = useState<string[]>([]);
  useEffect(() => {
    changeSelectedFundCodes([]);
  }, [fundManagersMap]);
  return [
    selectedFundCodes,
    useMemo(
      () =>
        selectedFundCodes?.map(
          (fundManagerId) => fundManagersMap[fundManagerId]
        ),
      [selectedFundCodes, fundManagersMap]
    ),
    useMemo(
      () => ({
        fixed: true,
        // hideSelectAll: true,
        hideOnSinglePage: false,
        onChange(selectedRowKeys: React.Key[]) {
          changeSelectedFundCodes(selectedRowKeys as string[]);
        },
      }),
      [changeSelectedFundCodes]
    ),
  ];
};

export const useGetPagination = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const changePages = useMemoizedFn((page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  });

  return {
    page,
    pageSize,
    changePages,
  };
};
