export interface FundBasicIndicatorsType {
  id: string;
  messageKey: string;
}
export interface FundBasicDataSource extends FundBasicIndicatorsType {
  value: string | any[] | { [key: string]: string };
  message: string;
}
export const fundBasicIndicators: FundBasicIndicatorsType[] = [
  {
    id: "fundCompany",
    messageKey: "fundCompanies",
  },
  {
    id: "foundDate",
    messageKey: "foundDate",
  },
  {
    id: "fundManager",
    messageKey: "fundManagers",
  },
  {
    id: "fundScale",
    messageKey: "fundScale",
  },
  {
    id: "fundType",
    messageKey: "operateModeTag",
  },
  {
    id: "purchaseStatus",
    messageKey: "purchaseStatus",
  },
  {
    id: "fundStyle",
    messageKey: "evaluationFundType",
  },
  {
    id: "redemptionStatus",
    messageKey: "redemptionStatus",
  },
  {
    id: "stabilityOfStyle",
    messageKey: "stabilityOfStyle",
  },
  {
    id: "firstInvestType",
    messageKey: "fundType",
  },
];

export const EARNINGS_FACTORS = [
  "71c964ab-779a-497b-becf-8abe658739c3",
  "2d6aada8-319f-4e5b-b9e8-88509b14a7c6",
  "b5b3fe13-339e-4e37-b493-00abd661fc0e",
  "21eac6b5-80ed-46b7-a8aa-561f8ca8c456",
  "125dce85-1809-4336-a745-ae53c384d3d8",
];

export const MOCK_EARNINGS_FACTORS = [
  {
    id: "71c964ab-779a-497b-becf-8abe658739c3",
    message: "近1月",
    section: "RECENT_MONTH",
  },
  {
    id: "2d6aada8-319f-4e5b-b9e8-88509b14a7c6",
    message: "近3月",
    section: "RECENT_THREE_MONTH",
  },
  {
    id: "b5b3fe13-339e-4e37-b493-00abd661fc0e",
    message: "近6月",
    section: "RECENT_HALF_YEAR",
  },
  {
    id: "21eac6b5-80ed-46b7-a8aa-561f8ca8c456",
    message: "近1年",
    section: "RECENT_YEAR",
  },
  {
    id: "125dce85-1809-4336-a745-ae53c384d3d8",
    message: "近3年",
    section: "RECENT_THREE_YEAR",
  },
  {
    id: "foundNet",
    message: "成立以来",
    section: "FROM_CREATION",
  },
];

export const fundInfoDataIndicators: FundBasicIndicatorsType[] = [
  {
    id: "name",
    messageKey: "fundName",
  },
  {
    id: "fundRating",
    messageKey: "fundRating",
  },
  {
    id: "code",
    messageKey: "fundCode",
  },
  {
    id: "fundType",
    messageKey: "operateModeTag",
  },
  {
    id: "firstInvestType",
    messageKey: "fundType",
  },
  {
    id: "fundManager",
    messageKey: "fundManagers",
  },
  {
    id: "purchaseStatus",
    messageKey: "purchaseStatus",
  },
  {
    id: "fundCompany",
    messageKey: "fundCompanies",
  },
  {
    id: "redemptionStatus",
    messageKey: "redemptionStatus",
  },
  {
    id: "foundDate",
    messageKey: "foundDate",
  },
  {
    id: "benchmark",
    messageKey: "performanceBenchmark",
  },
  {
    id: "fundScale",
    messageKey: "fundScale",
  },
  {
    id: "fundStyle",
    messageKey: "fundStyle",
  },
];

export const operatingCosts: FundBasicIndicatorsType[] = [
  {
    id: "manageFeeRatio",
    messageKey: "manageFeeRatio",
  },
  {
    id: "custodianFeeRatio",
    messageKey: "custodianFeeRatio",
  },
];

export const sectorTrend: FundBasicIndicatorsType[] = [
  {
    id: "stocktonav",
    messageKey: "stock",
  },
  {
    id: "bondtonav",
    messageKey: "fixedIncome",
  },
  {
    id: "cashtonav",
    messageKey: "bankDeposits",
  },
  {
    id: "fundtonav",
    messageKey: "funds",
  },
  {
    id: "othertonav",
    messageKey: "other",
  },
];
