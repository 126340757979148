import { Button, Input, Menu, message, Modal, Popover, Space, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import style from "./addTag.module.less";
import { useFormatMessage } from "@/util/formatMessage";
import {
  PlusOutlined,
  DashOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { filter, find, first, includes, isEmpty, map } from "lodash/fp";
import { useMemoizedFn } from "ahooks";
import {
  useAppDispatch,
  useAppSelector,
  // useAppSelector
} from "@/hooks/redux";
import {
  createCustomerTag,
  deleteCustomerTag,
  editCustomerTag,
  fetchAllcustomerTag,
} from "@/store/createCustomer";
import { tagItem } from "@/model/customer";
import nullIcon from "@/assets/customerAnalysisIcons/nullIcon.png";
import { fastProp } from "@/util/opt";
import { RootState } from "@/store";

type tagProp = {
  selectedTag: string[];
  onChangeTag: any;
  disabled: boolean;
};

export default React.memo(({ selectedTag, onChangeTag, disabled }: tagProp) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const options = useAppSelector(
    (state: RootState) => state.createCustomer.allCustomerTag
  );

  const [tagOptions, setTagOptions] = useState(options);
  useEffect(() => {
    setTagOptions(options);
  }, [options]);

  const optionsName = useMemo(
    () => map((item: tagItem) => item.name)(options),
    [options]
  );

  useEffect(() => {
    dispatch(fetchAllcustomerTag());
  }, [dispatch]);

  const searchAndCreate = useMemoizedFn((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e?.target?.value;
    setValue(value);
    if (value) {
      const filterTag = filter((item: tagItem) => item.name.includes(value))(
        options
      );
      setTagOptions(filterTag);
    } else {
      setTagOptions(options);
    }
  });

  const warning = useMemoizedFn((id: string) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: formatMessage("delectTagTip"),
      okText: "确认",
      cancelText: "取消",
      onOk() {
        return dispatch(deleteCustomerTag(id))
          .unwrap()
          .then(() => {
            message.success(formatMessage("deleteSuccess"));
            dispatch(fetchAllcustomerTag());
          })
          .catch((err: any) => {
            message.success(err);
          });
      },
    });
  });

  const updateTag = useMemoizedFn((id: string, type: boolean) => {
    const newData = map((item: Record<string, any>) => {
      if (item.id === id) {
        return {
          ...item,
          isEdit: type,
        };
      }
      return item;
    })(options);
    setTagOptions(newData as any);
  });

  const editTag = useMemoizedFn(
    (params: { id: string; frequency: number; name: string }) => {
      if (find((item: tagItem) => item.name == params?.name)(options)) {
        updateTag(params.id, false);
        message.warning(formatMessage("tagNameNotRepeat"));
      } else {
        updateTag(params.id, false);
        dispatch(editCustomerTag(params))
          .unwrap()
          .then(() => {
            message.success(formatMessage("editSucceeded"));
            dispatch(fetchAllcustomerTag());
          })
          .catch((err: any) => {
            message.error(err);
          });
      }
    }
  );

  const createTag = useMemoizedFn((name: string) => {
    if (!name || loading) return false;
    setLoading(true);
    dispatch(createCustomerTag(name))
      .unwrap()
      .then(() => {
        message.success(formatMessage("createSuccess"));
        dispatch(fetchAllcustomerTag());
        setLoading(false);
        setValue("");
      })
      .catch(({ errors }) => {
        message.error(errors[0]?.message);
        setLoading(false);
      });
  });

  const selectTag = useMemoizedFn((id: string) => {
    const newTag = (selectedTag || []).includes(id)
      ? selectedTag || []
      : (selectedTag || []).concat([id]);
    onChangeTag(newTag);
  });

  const searchPressEnter = useMemoizedFn(() => {
    if (!isEmpty(tagOptions)) {
      const firstTag = first(tagOptions);
      selectTag(fastProp("id")(firstTag));
    } else {
      createTag(value);
    }
  });

  const content = (id: string) => (
    <Menu>
      <Menu.Item key="update" onClick={() => updateTag(id, true)}>
        <Space>
          <EditOutlined />
          {formatMessage("edit")}
        </Space>
      </Menu.Item>
      <Menu.Item key="delect" onClick={() => warning(id)}>
        <Space>
          <DeleteOutlined />
          {formatMessage("delete")}
        </Space>
      </Menu.Item>
    </Menu>
  );

  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const handleClick = useMemoizedFn(() => {
    if (!disabled) setIsOpenPopover(!isOpenPopover);
  });

  return (
    <Popover
      trigger="click"
      open={isOpenPopover}
      onOpenChange={handleClick}
      content={
        <div className={style.AddTagContent}>
          <Input
            placeholder={formatMessage("findOrCreateLabels")}
            value={value}
            onChange={searchAndCreate}
            allowClear
            onPressEnter={searchPressEnter}
          />
          <div className={style.TagPart}>
            {!isEmpty(tagOptions) ? (
              map((option: tagItem) => (
                <div key={option.id} className={style.TagItem}>
                  {option?.isEdit ? (
                    <Input
                      defaultValue={option.name}
                      className={style.EditInput}
                      size="small"
                      onBlur={(e) =>
                        editTag({
                          id: option.id,
                          frequency: option.frequency,
                          name: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <Tag
                      color="#EDF1F7"
                      key={option.id}
                      onClick={() => selectTag(option.id)}
                    >
                      <span
                        style={{
                          color: (selectedTag || []).includes(option.id)
                            ? "#0171FF"
                            : "#647286",
                        }}
                      >
                        {option.name}
                      </span>
                    </Tag>
                  )}
                  {!option?.isEdit && (
                    <Popover
                      content={content(option.id)}
                      //   trigger="click"
                      placement="right"
                    >
                      <Button
                        size="small"
                        icon={<DashOutlined />}
                        className={style.OpreateButton}
                      ></Button>
                    </Popover>
                  )}
                </div>
              ))(tagOptions)
            ) : (
              <div className={style.EmptyTag}>
                <img src={nullIcon} />
                <div className={style.EmptyTagInfo}>
                  {formatMessage("nullTagTip")}
                </div>
              </div>
            )}
          </div>
          {value && !includes(value)(optionsName) && (
            <div
              className={style.CreateTag}
              onClick={() => {
                createTag(value);
              }}
            >
              <Space>
                <span>{formatMessage("create")}</span>
                <span>{value}</span>
              </Space>
            </div>
          )}
        </div>
      }
    >
      <Button
        type="primary"
        icon={<PlusOutlined />}
        disabled={disabled}
        onClick={handleClick}
      >
        {formatMessage("addTag")}
      </Button>
    </Popover>
  );
});
