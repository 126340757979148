import React from "react";
import { Popconfirm, Space } from "antd";
// import { BellFilled } from "@ant-design/icons";
// import peopleSetting from "@/assets/peopleSetting.svg";
import { removeLocalStorage } from "@/util/localStorage";
import { AUTHORIZATION } from "@/const";
import { useNavigate } from "react-router-dom";
import { useMemoizedFn } from "ahooks";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";

export default React.memo(() => {
  const navigate = useNavigate();
  const formatMessage = useFormatMessage();
  const signOut = useMemoizedFn(() => {
    removeLocalStorage(AUTHORIZATION);
    sessionStorage.removeItem("userId");
    navigate("/login");
    location.reload();
  });

  return (
    <Space>
      {/* <img src={peopleSetting} /> */}
      {/* <BellFilled /> */}
      <Popconfirm title={formatMessage("signoutConfig")} onConfirm={signOut}>
        <div className={style.signOut}></div>
      </Popconfirm>
    </Space>
  );
});
