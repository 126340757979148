import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPortfolioList,
  deletePortfolio as deletePortfolioAction,
  getPortfolioDailyEarnings,
  createSimplePortfolio as createSimplePortfolioAction,
  editPortfolio as editPortfolioAction,
  EditPortfolioParam,
  saveBackTestingScheme as saveBackTestingSchemeApi,
  getBackTestingSchemeList as getBackTestingSchemeListApi,
  deleteBackTestingScheme as deleteBackTestingSchemeApi,
  editBackTestingScheme as editBackTestingSchemeApi,
  SaveBackTestingSchemeParams,
  EditBackTestingSchemeParam,
} from "@/api/portfolioList";
import type {
  assetPortfolios,
  BackTestingScheme,
  dailyEarningsType,
  PortfoliosSimple,
} from "@/model/portfolioList";
import { flow, fromPairs, map, prop, reject } from "lodash/fp";
import { isEmpty, orderBy } from "lodash/fp";
import { fastHas } from "@/util/opt";
import createSocketThunk from "@/util/createSocketThunk";
import { TaskData } from "@/util/socket";

type dailyEarnings = Record<string, dailyEarningsType>;
export type PortfolioList = {
  portfolioList: assetPortfolios[];
  yieldSelectedBenchmarkId?: string;
  yieldSelectedRange?: string;
  dailyEarnings: dailyEarnings; // 每日收益echarts数据
  // 组合动态回撤相关
  drowDownBenchmarkId?: string;
  drowDownRange?: string;
  backTestingScheme: BackTestingScheme[];
  saveBackTestingSchemeProgress?: TaskData | undefined;
};

const initialState: PortfolioList = {
  portfolioList: [],
  dailyEarnings: {},
  backTestingScheme: [],
};

export const fetchPortfolioList = createAsyncThunk(
  "portfolioList/getPortfolioList",
  async () => {
    const portfolioList = await getPortfolioList();
    return portfolioList;
  }
);
export const deletePortfolio = createAsyncThunk(
  "portfolioList/deletePortfolio",
  async (portfolioId: string) => {
    const response = await deletePortfolioAction(portfolioId);
    return response;
  }
);
export const dailyEarnings = createAsyncThunk(
  "portfolioList/dailyEarnings",
  async (portfolioIds: string[], { getState }: any) => {
    const returnFactors = prop("portfolioList.dailyEarnings")(getState());
    const requestIds = reject<string>((id) => fastHas(id)(returnFactors))(
      portfolioIds
    );
    if (isEmpty(requestIds)) return Promise.resolve([]);
    const response = await getPortfolioDailyEarnings({
      portfolioIds: requestIds,
    });
    return response;
  }
);
export const createSimplePortfolio = createAsyncThunk(
  "portfolioList/createSimplePortfolio",
  async (data: PortfoliosSimple) => {
    const response = await createSimplePortfolioAction(data);
    return response;
  }
);
export const editPortfolio = createAsyncThunk(
  "portfolioList/editPortfolio",
  async (data: EditPortfolioParam) => {
    const response = await editPortfolioAction(data);
    return response;
  }
);

export const saveBackTestingScheme = createSocketThunk(
  "portfolioList/saveBackTestingScheme",
  "FINISH_BACK_TESTING_PLAN",
  (params: SaveBackTestingSchemeParams) => {
    return saveBackTestingSchemeApi(params);
  }
);
export const getBackTestingSchemeList = createAsyncThunk(
  "portfolioList/getBackTestingSchemeList",
  async () => {
    const response = await getBackTestingSchemeListApi();
    return response;
  }
);
export const deleteBackTestingScheme = createAsyncThunk(
  "portfolioList/deleteBackTestingScheme",
  async (id: string) => {
    const response = await deleteBackTestingSchemeApi(id);
    return response;
  }
);
export const editBackTestingScheme = createAsyncThunk(
  "portfolioList/editBackTestingScheme",
  async (param: EditBackTestingSchemeParam) => {
    const response = await editBackTestingSchemeApi(param);
    return response;
  }
);
const saveBackTestingSchemeFinish: string = saveBackTestingScheme.finish.type;
const saveBackTestingSchemeError: string = saveBackTestingScheme.error.type;
const saveBackTestingSchemeProgress: string =
  saveBackTestingScheme.progress.type;
const portfolioListSlice = createSlice({
  name: "portfolioList",
  initialState,
  reducers: {
    updatePortfolioInfo: (state: PortfolioList, action) => {
      const { key, value } = action.payload;
      state[key as keyof PortfolioList] = value;
    },
  },
  extraReducers: {
    [fetchPortfolioList.fulfilled.type]: (state, action) => {
      state.portfolioList = orderBy<assetPortfolios>(
        "createTime",
        "desc"
      )(action.payload);
    },
    [deletePortfolio.fulfilled.type]: (state, action) => {
      if (action.meta?.arg) {
        state.portfolioList = state.portfolioList.filter(
          ({ id }) => id !== action.meta?.arg
        );
      }
    },
    [dailyEarnings.fulfilled.type]: (state, action) => {
      if (action.payload?.length > 0) {
        state.dailyEarnings = {
          ...state.dailyEarnings,
          ...flow(
            map((item: dailyEarningsType) => [item.portfolioId, item]),
            fromPairs
          )(action.payload),
        };
      }
    },
    [createSimplePortfolio.fulfilled.type]: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.portfolioList.unshift(action.payload);
      }
    },
    [editPortfolio.fulfilled.type]: (state, action) => {
      state.portfolioList = state.portfolioList.map((portfolio) => {
        if (portfolio.id === action.meta?.arg?.portfolioId) {
          return {
            ...portfolio,
            ...(action?.payload || {}),
          };
        }
        return portfolio;
      });
    },
    [getBackTestingSchemeList.fulfilled.type]: (state, action) => {
      state.backTestingScheme = orderBy<BackTestingScheme>(
        "createTime",
        "desc"
      )(action.payload);
    },
    [saveBackTestingSchemeFinish]: (state, action) => {
      if (!isEmpty(action.payload.view)) {
        if (
          state.backTestingScheme.some((v) => v?.id === action.meta?.arg?.id)
        ) {
          state.backTestingScheme = state.backTestingScheme.map(
            (backTestingScheme) => {
              if (backTestingScheme.id === action.meta?.arg?.id) {
                return {
                  ...backTestingScheme,
                  ...(action?.payload.view || {}),
                };
              }
              return backTestingScheme;
            }
          );
        } else {
          state.backTestingScheme.unshift(action.payload.view);
        }
      }
      state.saveBackTestingSchemeProgress = action.meta;
    },
    [saveBackTestingSchemeError]: (state, action) => {
      state.saveBackTestingSchemeProgress = action.error;
    },
    [saveBackTestingSchemeProgress]: (state, action) => {
      state.saveBackTestingSchemeProgress = action.payload;
    },
    [deleteBackTestingScheme.fulfilled.type]: (state, action) => {
      if (action.meta?.arg) {
        state.backTestingScheme = state.backTestingScheme.filter(
          ({ id }) => id !== action.meta?.arg
        );
      }
    },
    [editBackTestingScheme.fulfilled.type]: (state, action) => {
      state.backTestingScheme = state.backTestingScheme.map(
        (backTestingScheme) => {
          if (backTestingScheme.id === action.meta?.arg?.id) {
            return {
              ...backTestingScheme,
              ...(action?.payload || {}),
            };
          }
          return backTestingScheme;
        }
      );
    },
  },
});

export const { updatePortfolioInfo } = portfolioListSlice.actions;
export default portfolioListSlice.reducer;
