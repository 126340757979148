import dayjs from "dayjs";
import { forEach, first, last, max, min, filter, size } from "lodash/fp";
import { TradingDates, getNextTradingDate, DATEFORMAT } from "../tradingDate";
import {
  DatesArray,
  PeriodDate,
  NormalDatePeriodName,
  DatePeriodName,
} from "./type";

function getStartDateOfThisYear(endDate: string, tradingDates?: TradingDates) {
  if (!tradingDates) return "";
  const year = dayjs(endDate).year();
  return getNextTradingDate(`${year}-01-01`, tradingDates, true);
}

/**
 * 共同时间段计算时间区间
 * @param datesArr
 * @returns
 */
export function getPeriodDateByCommonTime(datesArr: DatesArray): PeriodDate {
  const firstDates: TradingDates = [],
    endDates: TradingDates = [];
  let isDateNil = false;
  forEach<TradingDates>((dates) => {
    const firstDate = first(dates);
    const lastDate = last(dates);
    if (!firstDate || !lastDate) {
      isDateNil = true;
      return;
    }
    firstDates.push(firstDate);
    endDates.push(lastDate);
  })(datesArr);
  if (isDateNil) return ["", ""];
  const maxFirstDate = max(firstDates);
  const minLastDate = min(endDates);
  if (!maxFirstDate || !minLastDate) return ["", ""];
  if (maxFirstDate > minLastDate) return ["", ""];
  return [maxFirstDate, minLastDate];
}

/**
 * 时间段名称计算时间区间
 * @param name
 * @param tradingDates
 * @param currentDate
 * @returns
 */
export function getPeriodDate(
  name: NormalDatePeriodName,
  tradingDates: TradingDates,
  currentDate?: string
): PeriodDate {
  const endDate: string = dayjs(
    currentDate || last(tradingDates) || undefined
  ).format(DATEFORMAT);
  if (name === "FROM_THIS_YEAR") {
    return [getStartDateOfThisYear(endDate, tradingDates), endDate];
  }
  if (name === "FROM_CREATION") {
    return [tradingDates[0], endDate];
  }
  let startDate = "";
  switch (name) {
    case "RECENT_WEEK":
      startDate = dayjs(endDate).subtract(1, "week").format(DATEFORMAT);
      break;
    case "RECENT_MONTH":
      startDate = dayjs(endDate).subtract(1, "month").format(DATEFORMAT);
      break;
    case "RECENT_THREE_MONTH":
      startDate = dayjs(endDate).subtract(3, "month").format(DATEFORMAT);
      break;
    case "RECENT_HALF_YEAR":
      startDate = dayjs(endDate).subtract(6, "month").format(DATEFORMAT);
      break;
    case "RECENT_YEAR":
      startDate = dayjs(endDate).subtract(1, "year").format(DATEFORMAT);
      break;
    case "RECENT_TWO_YEAR":
      startDate = dayjs(endDate).subtract(2, "year").format(DATEFORMAT);
      break;
    case "RECENT_THREE_YEAR":
      startDate = dayjs(endDate).subtract(3, "year").format(DATEFORMAT);
      break;
    case "RECENT_FIVE_YEAR":
      startDate = dayjs(endDate).subtract(5, "year").format(DATEFORMAT);
      break;
    default:
      break;
  }
  if (startDate) {
    startDate = getNextTradingDate(startDate, tradingDates);
  }
  return [startDate, endDate];
}

/**
 *
 * @param name 时间段周期
 * @param targetDates 日期数组
 * @param currentDate 时间段周期依赖的结束时间
 * @returns
 */
export function getDatesByDatePeriod(
  name: DatePeriodName,
  targetDates: TradingDates,
  currentDate?: string
): TradingDates {
  if (!targetDates || !name) {
    return [];
  }
  if (name === "COMMON_TIME") {
    return targetDates;
  }
  const [start, end] = getPeriodDate(name, targetDates, currentDate);
  return filter<string>((date) => date >= start && date <= end)(targetDates);
}

function gtAndEqualDate(sourceDate: string, compareDate: string) {
  if (!sourceDate) return false;
  if (!compareDate) return true;
  return sourceDate >= compareDate;
}

function ltAndEqualDate(sourceDate: string, compareDate: string) {
  if (!sourceDate) return false;
  if (!compareDate) return true;
  return sourceDate <= compareDate;
}

export function getDatesByPeriodDate(
  periodDate: PeriodDate,
  tradingDates: TradingDates
) {
  if (!periodDate || !size(periodDate)) {
    return tradingDates;
  }
  const [start, end] = periodDate;
  if (start && end && start > end) {
    return tradingDates;
  }
  return filter<string>(
    (date) => gtAndEqualDate(date, start) && ltAndEqualDate(date, end)
  )(tradingDates);
}
