import { isNull } from "lodash";
import py from "pinyin";

export const STYLE_NORMAL = py.STYLE_NORMAL;
export const STYLE_FIRST_LETTER = py.STYLE_FIRST_LETTER;

const pyFormat = (v: string, style?: number): string => {
  if (isNull(v)) return "";
  return py(v, {
    style: style || STYLE_NORMAL,
  })
    .flat()
    .join("");
};

export default pyFormat;
