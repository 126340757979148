import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTradingDates as getTradingDatesAction } from "@/api/tradingDates";
import processedDates from "@/util/processedDates";
import type { TradingDates } from "@/model/tradingDates";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";

export const fetchTradingDates = avoidMultiRequestActionThunk<void>(
  "tradingDates.tradingDateList",
  createAsyncThunk("tradingDates/tradingDateList", async () => {
    const response = await getTradingDatesAction();
    return response;
  })
);

const initialState: TradingDates = {
  tradingDateList: [],
  processedTradingDates: {},
};
const tradingDatesSlice = createSlice({
  name: "tradingDates",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTradingDates.fulfilled.type]: (state, action) => {
      state.tradingDateList = action.payload;
      state.processedTradingDates = processedDates(action.payload);
    },
  },
});
export default tradingDatesSlice.reducer;
