import React, { useState, useMemo, useEffect } from "react";
import { CommonProps } from "@/views/aum/type";
import StatisticRange from "@/components/statisticRange";
import { useCreation } from "ahooks";
import { useGetStatisticRange } from "@/views/portfolioManage/portfolioAnalysis/hooks";
import { isInProgress } from "@/constant/socket";
import { useGetAumPortfolioIndexInfo } from "@/hooks/aum";
import { fastProp } from "@/util/opt";
import { first, some, map, isEmpty, last } from "lodash/fp";
import { Space, Select } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { fetchGetAumPerformanceDisassemble } from "@/store/aum";
import { performanceDisassembleOptions } from "./constant";
import style from "./index.module.less";
import { useDispatch } from "react-redux";
import DismantlingLineChart from "./components/dismantingLineChart";
import DismantlingBarChart from "./components/dismantingBarChart";
import DismantlingTreeTable from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/performanceDismantling/compoents/dismantlingTreeTable";
import { useAppSelector } from "@/hooks/redux";
import mapper from "./mapper";
import SummaryCard from "@/views/portfolioManage/portfolioAnalysis/components/summaryCard";
import TaskResult from "@/components/taskResult";
import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";

const { Option } = Select;

export default React.memo<Omit<CommonProps, "id">>(({ assetPortfolioId }) => {
  const formatMessage = useFormatMessage();

  const [dissectType, setDissectType] = useState<string>(
    fastProp("value")(first(performanceDisassembleOptions))
  );

  const indexInfo = useGetAumPortfolioIndexInfo(assetPortfolioId);

  const startDate = useMemo(
    () => fastProp("historyStart")(indexInfo),
    [indexInfo]
  );
  const endDate = useMemo(() => fastProp("historyEnd")(indexInfo), [indexInfo]);

  const dispatch = useDispatch();

  const calcStatisticRangeList = useGetStatisticRange(2);
  const statisticRangeList = useCreation(
    () =>
      calcStatisticRangeList(
        startDate,
        endDate,
        portfolioAnalysisStatisticRange
      ),
    [startDate, endDate, portfolioAnalysisStatisticRange]
  );

  const defaultRange = useMemo(() => {
    if (some((v: any) => v?.id === "RECENT_MONTH")(statisticRangeList))
      return "RECENT_MONTH";
    return "FROM_CREATION";
  }, [statisticRangeList]);

  const [statisticRange, setStatisticRange] = useState(defaultRange);

  useEffect(() => {
    if (assetPortfolioId && statisticRange && dissectType) {
      dispatch(
        fetchGetAumPerformanceDisassemble({
          dissectType,
          section: statisticRange,
          portfolioId: assetPortfolioId,
        })
      );
    }
  }, [assetPortfolioId, dissectType, dispatch, statisticRange]);

  const {
    dismantlingTextSelector: dismantlingText,
    dismantlingDatesSelector: dismantingDates,
    fundDisassembleTableSelector: fundDisassembleTable,
    performanceDismantlingProgressSelector: performanceDismantlingProgress,
  } = useAppSelector((state) => mapper(state, assetPortfolioId));

  const summaryText = useMemo(
    () =>
      isEmpty(dismantlingText)
        ? ""
        : formatMessage("PerformanceDismantlingSummaryText", {
          from: dismantlingText.from,
          to: dismantlingText.to,
          fundName: dismantlingText.fundName,
          attributionRatio: dismantlingText.attributionRatio,
        }),
    [formatMessage, dismantlingText]
  );

  return (
    <Space direction="vertical" className={style.FullWidth}>
      <Space size={"large"}>
        <Space>
          <span> {formatMessage("statisticTime")}</span>
          <StatisticRange
            disabled={isInProgress(performanceDismantlingProgress)}
            value={statisticRange}
            statisticRangeList={statisticRangeList as any}
            onChange={setStatisticRange}
          />
          {isEmpty(dismantingDates) || isInProgress(performanceDismantlingProgress) ? null : (
            <span>
              ({first(dismantingDates)}~{last(dismantingDates)})
            </span>
          )}
        </Space>
        <Space>
          <span>{formatMessage("disassemblyCategory")}</span>
          <Select
            disabled={isInProgress(performanceDismantlingProgress)}
            className={style.Select}
            value={dissectType}
            onChange={(value) => setDissectType(value)}
          >
            {map(({ label, value }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))(performanceDisassembleOptions)}
          </Select>
        </Space>
      </Space>
      <TaskResult task={performanceDismantlingProgress}>
        {isEmpty(dismantingDates) ? (
          <div className={style.Empty}></div>
        ) : (
          <Space direction="vertical" className={style.FullWidth}>
            <DismantlingLineChart id={assetPortfolioId} />
            <DismantlingBarChart id={assetPortfolioId} />
            <DismantlingTreeTable fundDisassembleTable={fundDisassembleTable} />
            {summaryText ? <SummaryCard summaryText={summaryText} /> : null}
          </Space>
        )}
      </TaskResult>
    </Space>
  );
});
