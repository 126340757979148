import { flatten, flow, map } from "lodash/fp";

export const GreenColor = "#00b867";

export const YellowColor = "#ffa62a";

export const BlueColor = "#0171ff";

export const RedColor = "#ff0a00";

export const SCALE_COLORS = ["#0973C3", "#009BDD", "#11CDE0", "#72D4AF"];

export const PORTFOLIO_COLOR = "#1A7FFF";

export const BENCHMARK_COLOR = "#ACBFDD";

export const RANGE_COLOR = ["#1A7FFF", "#FFA62A"];

export const colors = [
  BlueColor,
  RedColor,
  YellowColor,
  GreenColor,
  "#0973C3",
  "#009BDD",
  "#11CDE0",
  "#72D4AF",
  "#0052BF",
  "#62D9AB",
  "#FFA62A",
  "#FF6863",
  "#6482E2",
  "#FFE0ED",
  "#FF99C3",
  // "#FF6863", repeat
  "#F7BD42",
  "#945FB9",
  // "#62D9AB",
  "#59A2FF",
  // 定义的颜色不够用，增加一些
  "#EA917C",
  "#C45542",
  "#F26060",
  "#EAA95A",
  "#8ED374",
  "#55AB8A",
  "#3D49BB",
  "#3E578C",
  "#8696B3",
  "#A05C76",
  "#7B5D9A",
];

/**
 * 对十六进制的颜色设置透明度，对于十六进制的颜色，前六位可以表示rgb三个颜色，还有后两位用来表示0-255的透明度
 * 用透明度百分比 * 255得到十进制的透明度，再通过toString转成16进制
 * 最后添加到原本的颜色后面，即可为十六进制颜色设置透明度
 * @param {Number} percent
 * @param {String} color
 * @returns {String}
 * */
export const getHexOpacityColor = (percent: number) => (color: string) => {
  const opacity = parseInt(`${255 * percent}`, 10).toString(16);
  return `${color}${opacity}`;
};

export const eightPercentColors = flow(
  map<string, string[]>((color) => [getHexOpacityColor(0.7)(color), color]),
  flatten
)(colors);

// 28个颜色 主要用于堆叠图
export const paletteColors = [
  "#004BAE",
  "#0080BF",
  "#00B9BF",
  "#E26D51",
  "#B11E05",
  "#EC0B0B",
  "#E28D24",
  "#69C447",
  "#1E8F64",
  "#0011B8",
  "#002166",
  "#5E749A",
  "#A73C66",
  "#5D3C80",
  "#3E77C2",
  "#3E9FCF",
  "#3ECBCF",
  "#EA917C",
  "#C45542",
  "#F26060",
  "#EAA95A",
  "#8ED374",
  "#55AB8A",
  "#3D49BB",
  "#3E578C",
  "#8696B3",
  "#A05C76",
  "#7B5D9A",
];

// lineChart 系统默认颜色
export const defaultColors = [
  "#5470c6",
  "#91cc75",
  "#fac858",
  "#ee6666",
  "#73c0de",
  "#3ba272",
  "#fc8452",
  "#9a60b4",
  "#ea7ccc",
];
