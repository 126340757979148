import React from "react";
import { useFormatMessage } from "@/util/formatMessage";
import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import positionThroughIcon from "@/assets/portfolioAnalysisIcons/positionThrough.png";
import IndustryDistribution from "../industryDistribution";
import SectorDistribution from "../sectorDistribution";
import HoldStockStyle from "../holdStockStyle";
import { CommonProps } from "../../type";
import style from "../../index.module.less";
import FundCategoryDistribution from "../fundCategoryDistribution";
import AssetAllocationDistribution from "../assetAllocationDistribution";
// import HeavyBondDistribution from "../heavyBondDistribution";

export default React.memo<CommonProps>(({ id, assetPortfolioId }) => {
  const formatMessage = useFormatMessage();
  const tip: CardLayoutTipType = [
    [formatMessage("positionThrough"), formatMessage("positionThroughTip")],
  ];
  return (
    <CardLayout
      title={formatMessage("positionThrough")}
      icon={positionThroughIcon}
      tip={tip}
    >
      <div className={style.CardContent}>
        <IndustryDistribution id={id} assetPortfolioId={assetPortfolioId} />
        <div className={style.Divide} />
        <SectorDistribution id={id} assetPortfolioId={assetPortfolioId} />
        <div className={style.Divide} />
        <HoldStockStyle id={id} assetPortfolioId={assetPortfolioId} />
        <div className={style.Divide} />
        <FundCategoryDistribution id={id} assetPortfolioId={assetPortfolioId} />
        <div className={style.Divide} />
        <AssetAllocationDistribution
          id={id}
          assetPortfolioId={assetPortfolioId}
        />
        {/* <div className={style.Divide} />
        <HeavyBondDistribution id={id} assetPortfolioId={assetPortfolioId} /> */}
      </div>
    </CardLayout>
  );
});
