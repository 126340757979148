/*
   赫斯特指数算法
*/
import { mapIndexed } from "../opt";
import { minus, toAverage, toStandardDeviation } from "../math";
import {
  flow,
  flatten,
  map,
  max,
  min,
  size,
  first,
  last,
  slice,
} from "lodash/fp";
import MLR from "ml-regression-multivariate-linear";
import { formatArrayNilToZero } from "../numberFormatter";

const collectionList = (returns: number[], collectSize: number) => {
  const collectList = [];
  const avgCount = size(returns) / collectSize;
  for (let i = 0; i < collectSize; i++) {
    collectList.push(slice(i * avgCount, (i + 1) * avgCount)(returns));
  }
  return collectList;
};
export default (dailyReturns: number[]) => {
  if (size(dailyReturns) < 80) return NaN;
  const returnSize = size(dailyReturns);
  const subDailyReturns = slice(
    returnSize % 16,
    returnSize
  )(formatArrayNilToZero(dailyReturns));
  const groups: number[] = mapIndexed((_: number, index: number) =>
    Math.pow(2, index)
  )(new Array(5));
  // 将交易日序列切片成5类
  // a.单个片段大小是整个序列，分成1组；
  // b.单个片段大小是序列的1/2，分成2组；
  // c.单个片段大小是序列的1/4，分成4组；
  // d.单个片段大小是序列的1/8，分成8组；
  // e.单个片段大小是序列的1/16，分成16组；
  const sequenceData = flow(
    map<number, number[][]>((group) => collectionList(subDailyReturns, group)),
    flatten
  )(groups);

  const R2S = map((group: number[]) => {
    const sequenceAverage = toAverage(group);
    const dev = map<number, number>(minus(sequenceAverage))(group);
    const dis = (max(dev) as number) - (min(dev) as number);
    return dis / toStandardDeviation(group);
  })(sequenceData);

  const avgR2S = map<number, number>((i) =>
    toAverage(slice(i - 1, 2 * i - 1)(R2S))
  )(groups);

  const lnR2S = map<number, number[]>((item) => [Math.log10(item)])(avgR2S);

  const lnN = map<number, number[]>((item) => [Math.log10(item)])(groups);

  const mlr = new MLR(lnN, lnR2S);
  const [beta] = [first(first(mlr.weights)), first(last(mlr.weights))];
  return beta;
};
