import React, { useRef, NamedExoticComponent, useEffect } from "react";
import { useInViewport } from "ahooks";
import { DebouncedFunc } from "lodash";
import { Spin } from "antd";
import style from "./index.module.less";

const threshold = new Array(100).fill(100).map((v, idx) => idx / v);

export default React.memo<{
  id: string;
  Component: NamedExoticComponent<any>;
  isRender: boolean;
  navigateTo: DebouncedFunc<(hash: string) => void>;
  componentProps: Record<string, any>;
}>(({ id, Component, isRender, navigateTo, componentProps }) => {
  const ref = useRef(null);
  const [inViewport] = useInViewport(ref, { threshold });
  useEffect(() => {
    if (inViewport) navigateTo(id);
  }, [id, inViewport, navigateTo]);
  return (
    <div id={id} ref={ref}>
      {isRender ? (
        <Component {...componentProps} />
      ) : (
        <Spin spinning>
          <div className={style.FullHeight}></div>
        </Spin>
      )}
    </div>
  );
});
