import TradingDatePicker from "@/components/tradingDatePicker";
import { arrayToMap, fastHas } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import cn from "classnames";
import { Form } from "antd";
import style from "./index.module.less";
import React, { useMemo } from "react";

export default React.memo<{
  value: string | undefined;
  onChange: (value: string) => any;
  dateRange: string[];
  errorMessage?: string;
}>(({ value, onChange, dateRange, errorMessage }) => {
  const turnoverDatesMap = useMemo(() => arrayToMap(dateRange), [dateRange]);
  const disabledDate = useMemoizedFn(
    (date: string) => !fastHas(date)(turnoverDatesMap)
  );
  return (
    <Form.Item
      label="建仓日期"
      className={cn(style.FormItem, style.FormLabelWidth110)}
      validateStatus={errorMessage ? "error" : undefined}
      help={errorMessage}
    >
      <TradingDatePicker
        disabledDate={disabledDate}
        allowClear={false}
        value={value}
        className={style.DatePickerWidth}
        onChange={onChange}
      />
    </Form.Item>
  );
});
