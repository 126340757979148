import React, { useMemo } from "react";
import { DatePicker } from "antd";
import { fastNth } from "@/util/opt";
// import moment from "moment";
import dayjs from "dayjs";
import { useAppSelector } from "@/hooks/redux";
import { getDisabledDate } from "@/util/processedDates";
import { useMemoizedFn } from "ahooks";

export default React.memo<{
  value: string[];
  minDate: string;
  maxDate: string;
  onChange: (value: string[]) => void;
  startDateTitle?: string;
  endDateTitle?: string;
  rangeSeparator?: string;
  className?: string;
}>(
  ({
    value,
    onChange,
    minDate,
    maxDate,
    startDateTitle,
    endDateTitle,
    rangeSeparator = "~",
    className,
  }) => {
    const from = useMemo<any>(
      () => (!fastNth(0)(value) ? "" : dayjs(fastNth(0)(value))),
      [value]
    );
    const to = useMemo<any>(
      () => (!fastNth(1)(value) ? "" : dayjs(fastNth(1)(value))),
      [value]
    );

    const changeFromDate = useMemoizedFn((start: string) => {
      if (start) {
        onChange([start, fastNth(1)(value)]);
      }
    });
    const changeToDate = useMemoizedFn((end: string) => {
      if (end) {
        onChange([fastNth(0)(value), end]);
      }
    });
    const { processedTradingDates } = useAppSelector(
      (store) => store.tradingDates
    );

    const startDisabledDate = useMemoizedFn((value: any) => {
      return getDisabledDate({
        time: value,
        start: minDate,
        end: to || maxDate,
        tradingDate: processedTradingDates,
      });
    });
    const endDisabledDate = useMemoizedFn((value: any) => {
      return getDisabledDate({
        time: value,
        start: from || minDate,
        end: maxDate,
        tradingDate: processedTradingDates,
      });
    });
    const stopPropagation = useMemoizedFn(
      (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
      }
    );
    return (
      <span onClick={stopPropagation} className={className}>
        {startDateTitle}&nbsp;
        <DatePicker
          allowClear={false}
          disabledDate={startDisabledDate}
          value={from}
          getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
          onChange={(date, dateString) => {
            changeFromDate(dateString);
          }}
        />
        &nbsp;{rangeSeparator}&nbsp;
        {endDateTitle}&nbsp;
        <DatePicker
          allowClear={false}
          disabledDate={endDisabledDate}
          value={to}
          getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
          onChange={(date, dateString: string) => {
            changeToDate(dateString);
          }}
        />
      </span>
    );
  }
);
