import { getProp } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { portfolioAnalysisSelector } from "@/views/portfolioManage/portfolioAnalysis/selectors";

export const brinsonAttributionSelector = createSelector(
  portfolioAnalysisSelector,
  (portfolioAnalysis) => {
    const section = getProp("brinsonAttribution.section")(portfolioAnalysis);
    return getProp(`brinsonAttribution.${section}.brinsonReturn`)(
      portfolioAnalysis
    );
  }
);
