import React, { useContext, useEffect, useMemo, useState } from "react";
import PortfolioAnalysisSubtitle from "../../components/portfolioAnalysisSubtitle";
import { useFormatMessage } from "@/util/formatMessage";
import { PortfolioAnalysisProps } from "../..";
import { Col, Radio, Row, Space, Table, TableProps, Tooltip } from "antd";
import { FUND_TREND, STRATEGY_TYPE_TREND } from "../../constant";
import { useCreation, useMemoizedFn } from "ahooks";
import style from "../index.module.less";
import TradingDatePicker from "@/components/tradingDatePicker";
import {
  fetchGetPositionTrend,
  fetchGetPositionTrendStrategy,
} from "@/store/portfolioAnalysis";
import {
  compact,
  concat,
  first,
  flow,
  isEmpty,
  join,
  keys,
  last,
  map,
  prop,
  reject,
  size,
  sum,
  sumBy,
  take,
  values,
} from "lodash/fp";
import dayjs from "dayjs";
import LineChart, { LineChartOpts } from "@/echarts/lineChart";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import ErrorBoundary from "@/components/errorBoundary";
import { errorValidator } from "@/components/errorBoundary/constant";
import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";
import mapper from "../mapper";
import { categoryIdMapSelector } from "@/selectors/category";
import { fastNth, fastProp, floatEqual, mapIndexed } from "@/util/opt";
import { formatPercentage } from "@/util/numberFormatter";
import { colors } from "@/util/colors";
import { LineSeriesOption } from "echarts";
import { getLastDateOfYear } from "@/util/chart";
import {
  getFundWeightsMap,
  judgeOtherDataEmpty,
  MAX_TOP_FUNDS,
} from "../constant";

type FundAntdTableProps = TableProps<any>;
const needTime = 1;
const CASH = "cash";
type PositionTrendTableNameProps = {
  value: string;
  color?: string;
};
const PositionTrendTableName = ({
  value,
  color,
}: PositionTrendTableNameProps): JSX.Element => {
  return (
    <Tooltip title={value}>
      <div className={style.PositionTrendTableName}>
        {color ? (
          <p className={style.TableName} style={{ background: color }} />
        ) : null}
        <p className={style.PositionTrendTableNameValue}>{value}</p>
      </div>
    </Tooltip>
  );
};
const tooltipFormatter = (...args: any[]): string => {
  const data = args?.[0];
  const name = args?.[0]?.[0].name;
  const content = flow(
    map(({ seriesName, value, marker }: any) =>
      floatEqual(0)(fastNth(1)(value))
        ? ""
        : `<p style="margin: 8px 0;">${marker}${seriesName}: ${formatPercentage(
            fastNth(1)(value)
          )}</p>`
    ),
    join("")
  )(data);
  return `<div>
<p>${name}</p>
${content}</div>`;
};
const useManageDatePicker = (dates: string[]) => {
  const [datePicker, setDatePicker] = useState<string>("");
  useEffect(() => {
    setDatePicker(last(dates) as string);
  }, [dates]);
  const onChangeDatePicker = useMemoizedFn((v: string) => {
    setDatePicker(v);
  });
  const endDisabledDate = useMemoizedFn((date: string) => {
    const day = dayjs(date);
    return day.isBefore(first(dates)) || day.isAfter(last(dates));
  });
  return {
    datePicker,
    onChangeDatePicker,
    endDisabledDate,
  };
};

const useManageType = (id: string) => {
  const { dispatch } = useContext(PortfolioAnalysisContext);
  const [radioType, setRadioType] = useState<string>(FUND_TREND);
  const changeRadioType = useMemoizedFn((e) => {
    setRadioType(e.target.value);
  });
  useEffect(() => {
    if (id) {
      switch (radioType) {
        case FUND_TREND:
          dispatch(needTime, fetchGetPositionTrend(id));
          return;
        case STRATEGY_TYPE_TREND:
          dispatch(needTime, fetchGetPositionTrendStrategy(id));
          return;
      }
    }
  }, [dispatch, id, radioType]);
  return {
    radioType,
    changeRadioType,
  };
};
const useGetDeconstruction = (id: string, type: string) => {
  const fundIdMap = useAppSelector(fundIdMapSelector);
  const { positionTrend, positionTrendStrategy } = useAppSelector((state) =>
    mapper(state, id)
  );
  const categoryIdMap = useAppSelector(categoryIdMapSelector);
  return useMemo(() => {
    if (type === FUND_TREND)
      return {
        dates: fastProp("date")(positionTrend),
        fundWeights: fastProp("fundWeights")(positionTrend),
        topFunds: flow(
          fastProp("topFunds"),
          take(MAX_TOP_FUNDS)
        )(positionTrend),
        nameIdMap: fundIdMap,
        fundWeightKey: {
          id: "fundIds",
          weights: "weights",
        },
        columnsName: "fundName",
      };
    return {
      dates: fastProp("dates")(positionTrendStrategy),
      fundWeights: fastProp("positionStrategyTrend")(positionTrendStrategy),
      topFunds: flow(
        fastProp("topStrategies"),
        take(MAX_TOP_FUNDS)
      )(positionTrendStrategy),
      nameIdMap: categoryIdMap,
      fundWeightKey: {
        id: "strategyId",
        weights: "weights",
      },
      columnsName: "policyType",
    };
  }, [categoryIdMap, fundIdMap, positionTrend, positionTrendStrategy, type]);
};
const useGetLineCharDataSource = (id: string, type: string) => {
  const formatMessage = useFormatMessage();
  const { dates, fundWeights, topFunds, nameIdMap, fundWeightKey } =
    useGetDeconstruction(id, type);
  const fundWeightsMap = getFundWeightsMap(
    dates,
    fundWeights,
    topFunds,
    fundWeightKey
  );
  const lineChartData = useMemo(
    () =>
      mapIndexed((id: string, index: number) => ({
        type: "line",
        lineStyle: {
          width: 1.5,
        },
        name: prop(`${id}.name`)(nameIdMap),
        showSymbol: false,
        smooth: false,
        areaStyle: {
          opacity: 0.3,
        },
        stack: "Total",
        color: fastNth(index)(colors),
        data: map((v: string) => {
          const value = prop(`${v}.${id}`)(fundWeightsMap) || 0;
          return [v, value];
        })(dates),
      }))(topFunds),
    [dates, fundWeightsMap, nameIdMap, topFunds]
  );
  const lineCharOtherData = useMemo(() => {
    const others = flow(
      values,
      map(fastProp("other")),
      compact,
      reject(judgeOtherDataEmpty)
    )(fundWeightsMap);
    if (isEmpty(others)) return {};
    const data = flow(
      map((v: string) => {
        const otherData =
          flow(prop(`${v}.other`), values, sum)(fundWeightsMap) || 0;
        return [v, otherData];
      }),
      compact
    )(dates);
    return {
      type: "line",
      lineStyle: {
        width: 1.5,
      },
      name: formatMessage("Other"),
      showSymbol: false,
      smooth: false,
      areaStyle: {
        opacity: 0.3,
      },
      stack: "Total",
      color: fastNth(size(topFunds))(colors),
      data,
    };
  }, [dates, formatMessage, fundWeightsMap, topFunds]);
  const series = useMemo(
    () => concat(lineChartData, lineCharOtherData) as LineSeriesOption[],
    [lineCharOtherData, lineChartData]
  );
  const yearOfDates = getLastDateOfYear(dates);
  const legend = useMemo(() => map(fastProp("name"))(series), [series]);
  const options = useMemo<LineChartOpts["options"]>(
    () => ({
      grid: {
        top: 60,
        right: 40,
      },
      xAxis: {
        data: dates,
        nameGap: 40,
        axisLabel: {
          interval: (index: number, value: string) => {
            const year = fastProp(value)(yearOfDates);
            return year ? true : false;
          },
          formatter(value: string) {
            return `${fastProp(value)(yearOfDates)}${formatMessage("year")}`;
          },
        },
      },
      yAxis: {
        min: 0,
        max: 1,
        axisLabel: {
          formatter(value: number) {
            return formatPercentage(value);
          },
        },
      },
      legend: {
        left: 0,
        top: 15,
        icon: "rect",
        data: legend,
        textStyle: {
          width: 100,
          overflow: "truncate",
        },
        itemHeight: 16,
        itemWidth: 16,
      },
      tooltip: {
        formatter: tooltipFormatter,
      },
    }),
    [dates, formatMessage, legend, yearOfDates]
  );
  return useMemo(
    () => ({
      options,
      series,
    }),
    [options, series]
  );
};

const useGetTableDataSource = (
  id: string,
  type: string,
  datePicker: string
) => {
  const formatMessage = useFormatMessage();
  const {
    dates,
    fundWeights,
    topFunds,
    nameIdMap,
    fundWeightKey,
    columnsName,
  } = useGetDeconstruction(id, type);
  const fundWeightsMap = getFundWeightsMap(
    dates,
    fundWeights,
    topFunds,
    fundWeightKey
  );
  const tableDataSource = useMemo(() => {
    const tableData = fastProp(datePicker)(fundWeightsMap);
    const other = fastProp("other")(tableData);
    const otherFundsDataSource = judgeOtherDataEmpty(other)
      ? []
      : flow(
          keys,
          map((v: string) => ({
            key: v,
            fundName: prop(`${v}.name`)(nameIdMap) || v,
            positionWeights: fastProp(v)(other),
          })),
          (children) => ({
            key: "other",
            fundName: formatMessage("Other"),
            positionWeights: sumBy("positionWeights")(children),
            children,
            color: fastNth(size(topFunds))(colors),
          })
        )(other);
    const topFundsData = flow(
      mapIndexed((fund: string, index: number) => {
        const positionWeights = fastProp(fund)(tableData);
        if (!positionWeights) return;
        return {
          key: fund,
          positionWeights: fastProp(fund)(tableData),
          fundName: prop(`${fund}.name`)(nameIdMap),
          color: fastNth(index)(colors),
        };
      }),
      compact
    )(topFunds);
    return concat(topFundsData, otherFundsDataSource);
  }, [datePicker, formatMessage, fundWeightsMap, nameIdMap, topFunds]);
  const columns = useCreation<FundAntdTableProps["columns"]>(
    () => [
      {
        key: "fundName",
        dataIndex: "fundName",
        title: formatMessage(columnsName),
        align: "left",
        render: (value, record) => {
          if (value === CASH) return formatMessage(value);
          const color = fastProp("color")(record);
          return <PositionTrendTableName value={value} color={color} />;
        },
      },
      {
        key: "positionWeights",
        dataIndex: "positionWeights",
        title: formatMessage("positionWeights"),
        align: "right",
        render: (v) => formatPercentage(v),
        width: 100,
      },
    ],
    [formatMessage]
  );
  return useMemo(
    () => ({
      tableDataSource,
      columns,
    }),
    [columns, tableDataSource]
  );
};

export default React.memo<PortfolioAnalysisProps>((props) => {
  const { id } = props;
  const { runningTime } = useContext(PortfolioAnalysisContext);
  const formatMessage = useFormatMessage();
  const { radioType, changeRadioType } = useManageType(id);
  const { dates } = useGetDeconstruction(id, radioType);
  const { datePicker, onChangeDatePicker, endDisabledDate } =
    useManageDatePicker(dates);
  const { options, series } = useGetLineCharDataSource(id, radioType);
  const { tableDataSource, columns } = useGetTableDataSource(
    id,
    radioType,
    datePicker
  );
  const scroll = useMemo(() => (size(series) > 6 ? { y: 260 } : {}), [series]);
  return (
    <>
      <PortfolioAnalysisSubtitle name={formatMessage("positionTrend")} />
      <Radio.Group
        value={radioType}
        onChange={changeRadioType}
        optionType="button"
        buttonStyle="solid"
      >
        <Radio.Button value={FUND_TREND}>
          {formatMessage("fundTrend")}
        </Radio.Button>
        <Radio.Button value={STRATEGY_TYPE_TREND}>
          {formatMessage("strategyTypeTrend")}
        </Radio.Button>
      </Radio.Group>
      <ErrorBoundary errKey={errorValidator.lessOneDay(needTime, runningTime)}>
        <Row className={style.PositionTrendContent}>
          <Col span={18}>
            <LineChart
              options={options}
              series={series}
              height={400}
              showDataZoom
              onChartClick={onChangeDatePicker}
            />
          </Col>
          <Col span={6}>
            <Space className={style.DatePicker}>
              <span>{formatMessage("dateOfData")}</span>
              <TradingDatePicker
                value={datePicker}
                onChange={onChangeDatePicker}
                disabledDate={endDisabledDate as any}
                allowClear={false}
              />
            </Space>
            <Table
              className={style.PositionTable}
              bordered
              columns={columns}
              dataSource={tableDataSource}
              pagination={false}
              scroll={scroll}
              expandable={{
                defaultExpandAllRows: true,
                defaultExpandedRowKeys: ["other"],
              }}
            />
          </Col>
        </Row>
      </ErrorBoundary>
    </>
  );
});
