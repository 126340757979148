import { fetchNetValueAttribution } from "@/store/portfolioAnalysis";
import { useFormatMessage } from "@/util/formatMessage";
import React, { useMemo } from "react";
import cn from "classnames";
import SummaryCard from "../../components/summaryCard";
import { PortfolioAnalysisProps } from "../..";
import brinsonStyle from "../brinsonAttribution/index.module.less";
import CustomStaticRange from "../../components/customStaticRange";
import { Button, Space, Table } from "antd";
import { customOptions, getDateRangeText } from "./constant";
import { useCreation } from "ahooks";
import { fastProp } from "@/util/opt";
import ErrorBoundary from "@/components/errorBoundary";
import { getFactorTableColumns, getStatisticsText } from "./constant";

import { isEmpty, pick } from "lodash/fp";
import LineChart from "@/echarts/lineChart";
import LoadingComponent from "@/components/LoadingComponent";
import EchartsWrapper from "@/echarts/echartsWrapper";
import {
  useManageHeaderState,
  useGetNetValueAttributionData,
  useGetLineChartDataAndOptions,
  useGetStatisticsTableColumnsAndData,
  useGetTreeChartOptions,
} from "./hooks";
import { portfolioAnalysisSelector } from "../../selectors";

const factors = ["maxDrawdown", "annualSharpeRatio"];

export default React.memo<PortfolioAnalysisProps>(({ id }) => {
  const {
    range,
    runningTime,
    onChangeRange,
    buttonDisabled,
    onStartCalculate,
  } = useManageHeaderState(id);

  const { endDate, startDate, errorBoundary, netValueAttribution } =
    useGetNetValueAttributionData(id, range, runningTime, portfolioAnalysisSelector);
  const formatMessage = useFormatMessage();

  const { dataSource, columns } = useMemo<{
    dataSource: any;
    columns: any;
  }>(
    () => ({
      dataSource: [
        {
          ...pick(factors)(netValueAttribution),
          ...fastProp("allPerformace")(netValueAttribution),
        },
      ],
      columns: getFactorTableColumns(formatMessage),
    }),
    [netValueAttribution, formatMessage]
  );

  const { options, series } =
    useGetLineChartDataAndOptions(netValueAttribution);
  const { columns: statisticColumns, dataSource: statisticTableData } =
    useGetStatisticsTableColumnsAndData(netValueAttribution);

  const treeChartOptions = useGetTreeChartOptions(netValueAttribution);

  const dateRangeText = useCreation(
    () => getDateRangeText(netValueAttribution, formatMessage),
    [netValueAttribution, formatMessage]
  );

  const statisticsText = useCreation(
    () => getStatisticsText(netValueAttribution, formatMessage),
    [netValueAttribution, formatMessage]
  );
  return (
    <LoadingComponent actions={fetchNetValueAttribution}>
      <Space className={brinsonStyle.FullWidth} direction="vertical">
        <Space>
          <span>{formatMessage("attributionTime")}</span>
          <CustomStaticRange
            value={range}
            onChange={onChangeRange}
            startDate={startDate}
            endDate={endDate}
            staticRange={customOptions}
          />
          {dateRangeText && <span>( {dateRangeText} )</span>}
          <Button
            type="primary"
            onClick={onStartCalculate}
            disabled={buttonDisabled || !!errorBoundary}
          >
            {formatMessage("startCalculate")}
          </Button>
        </Space>
        <ErrorBoundary errKey={errorBoundary}>
          {isEmpty(netValueAttribution?.dailyPerformace?.dates) ? (
            <div className={brinsonStyle.Empty} />
          ) : (
            <>
              <Table
                columns={columns}
                bordered
                dataSource={dataSource}
                pagination={false}
                className={cn(brinsonStyle.Table, brinsonStyle.BrinsonTable)}
              />
              <LineChart height={400} series={series} options={options} />
              <div className={brinsonStyle.Flex}>
                <div className={brinsonStyle.Chart}>
                  <EchartsWrapper options={treeChartOptions} />
                </div>
                <div className={brinsonStyle.detailTable}>
                  <Table
                    columns={statisticColumns}
                    bordered
                    dataSource={statisticTableData}
                    pagination={false}
                    defaultExpandAllRows
                    scroll={{ x: "100%" }}
                    className={cn(brinsonStyle.BrinsonTable)}
                  />
                </div>
              </div>
              {statisticsText ? (
                <SummaryCard summaryText={statisticsText} />
              ) : null}
            </>
          )}
        </ErrorBoundary>
      </Space>
    </LoadingComponent>
  );
});
