import React, { useMemo } from "react";

import { useFormatMessage } from "@/util/formatMessage";
import { fastProp, mapIndexed } from "@/util/opt";

import style from "../index.module.less";
import { compact, flow, join, map, orderBy } from "lodash/fp";
import BaseEcharts from "../../../echarts/baseEcharts";
import { EChartsOption } from "echarts";
import { ManagerEvaluationBody } from "@/model/fundDetail";

type ManagerExchangeDataType = { date: string; from: string[]; to: string }[];

export default React.memo(
  ({
    managerEvaluation,
  }: {
    managerEvaluation?: ManagerEvaluationBody;
    fundId: string;
  }) => {
    const formatMessage = useFormatMessage();

    const { fundScoreTrend, fundManagerRecords } = managerEvaluation || {};
    const managerExchangeData: ManagerExchangeDataType = useMemo(() => {
      return flow(
        mapIndexed(
          (
            managers: ManagerEvaluationBody["fundManagerRecords"][string],
            key: string
          ) => {
            return { date: key, managers };
          }
        ),
        orderBy((item: Record<string, any>) => item?.date)("asc"),
        mapIndexed(
          (
            v: Record<string, any>,
            idx: number,
            currentArr: Record<string, any>
          ) => {
            if (idx > 0) {
              return {
                date: v.date,
                from: currentArr[idx - 1]?.managers,
                to: v.managers,
              };
            }
            return null;
          }
        ),
        compact
      )(fundManagerRecords);
    }, [fundManagerRecords]);

    const lineChartOptions = useMemo<EChartsOption>(
      () => ({
        grid: { left: 32, top: 32, bottom: 44, right: 20 },
        xAxis: {
          axisTick: { show: true, alignWithLabel: true },
        },
        yAxis: {
          max: 100,
          type: "value",
          splitLine: { show: false },
          axisLabel: {
            formatter: (val: any) => `${val}%`,
          },
        },
        series: {
          type: "line",
          data: flow(
            map((item: Record<string, any>) => [
              fastProp("date")(item),
              fastProp("score")(item),
            ])
          )(fundScoreTrend),
          markLine: {
            symbol: "none",
            label: { show: false },
            lineStyle: {
              color: "rgba(207,180,127,0.3)",
              width: 16,
              type: "solid",
            },
            data: map((item: Record<string, any>) => ({
              xAxis: fastProp("date")(item),
            }))(managerExchangeData),
          },
        },
      }),
      [fundScoreTrend, managerExchangeData]
    );
    return (
      <>
        <div className={style.FundHistoryInfoWrap}>
          <div className={style.Title}>
            {formatMessage("fundManagerChangeHistory")}
          </div>
          <div className={style.ChartWrap}>
            <BaseEcharts options={lineChartOptions} height={280} />
          </div>
          <div className={style.HistoryInfo}>
            {map((item: ManagerExchangeDataType[number]) => {
              return (
                <div>
                  {formatMessage("fundManagerChangeInfo", {
                    date: fastProp("date")(item),
                    from: (
                      <span>{flow(fastProp("from"), join("、"))(item)}</span>
                    ),
                    to: <span>{flow(fastProp("to"), join("、"))(item)}</span>,
                  })}
                </div>
              );
            })(managerExchangeData)}
          </div>
        </div>
      </>
    );
  }
);
