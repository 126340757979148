import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Popover, Popconfirm, message, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchPortfolioList } from "@/store/portfolioList";
import { filter, flow, map, prop, size, uniqBy } from "lodash/fp";
import { useGetIndexInfo } from "@/components/fundResultTable/hooks";
import { searchData } from "@/util/search";
import { usePaginationData } from "@/hooks/pagination";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { addZeroIndex } from "@/util/numberFormatter";
import { useGetFactorColumns } from "@/hooks/factors";
import { getFundManagersByDate } from "@/store/fundManagersSlice";
import { getUserId } from "@/util/userInfo";
import { useGetFactorsDataSource, useGetFactorValue } from "@/hooks/factors";
import { portfolioListMapSelector } from "@/selectors/portfolioList";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { deletePortfolio } from "@/store/portfolioList";
import {
  getEffectiveDate,
  getPortfolioPositionRecord,
  getTurnoverRecord,
} from "@/api/portfolioList";
import type { assetsType, TurnoverRecordHistory } from "@/model/portfolioList";
import style from "./index.module.less";
import { ColumnType } from "antd/lib/table";
import { fastProp } from "@/util/opt";
import { assetPortfolios } from "@/model/portfolioList";
import { useCreation, useMemoizedFn } from "ahooks";
import { RootState } from "@/store";
import { managerNameMapSelector } from "@/selectors/manager";

export const useManagePortfolioList = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchPortfolioList());
  }, [dispatch]);
  const portfolioList = useAppSelector(prop("portfolioList.portfolioList"));
  return portfolioList;
};

const PopoverContent = React.memo<{
  id: string;
  formatMessage: FormatMessageFunc;
  onDeletePortfolio: (id: string) => any;
  goToPortfolioChangingPositionHistory: (id: string) => any;
}>(
  ({
    id,
    formatMessage,
    onDeletePortfolio,
    goToPortfolioChangingPositionHistory,
  }) => (
    <div>
      <p
        className={style.PopoverItem}
        onClick={() => goToPortfolioChangingPositionHistory(id)}
      >
        {formatMessage("warehouseRecord")}
      </p>
      <Popconfirm
        title={formatMessage("deletePortfolioConfirm")}
        onConfirm={() => onDeletePortfolio(id)}
      >
        <p className={style.PopoverItem}>{formatMessage("deletePortfolio")}</p>
      </Popconfirm>
    </div>
  )
);

const OperateRenderer = React.memo<{
  id: string;
  hasPermission: boolean;
  goToPortfolioChangingPosition: () => any;
  goToPortfolioEdit: () => any;
  onGoToPortfolioChangingPositionHistory: (
    id: string,
    closePopover: () => any
  ) => void;
  onDeletePortfolio: (id: string, closePopover: () => any) => void;
  goToPortfolioAnalysis: () => any;
}>(
  ({
    id,
    hasPermission,
    goToPortfolioChangingPosition,
    goToPortfolioEdit,
    onGoToPortfolioChangingPositionHistory,
    onDeletePortfolio,
    goToPortfolioAnalysis,
  }) => {
    const formatMessage = useFormatMessage();
    const ref = useRef<{ close: () => any }>(null);
    const closePopover = useCallback(() => {
      ref.current?.close();
    }, []);
    return (
      <div className={style.ListTableOperate}>
        {hasPermission && (
          <Button type="link" onClick={goToPortfolioChangingPosition}>
            {formatMessage("theWarehouse")}
          </Button>
        )}
        <Button type="link" onClick={goToPortfolioAnalysis}>
          {formatMessage("analysis")}
        </Button>
        {hasPermission && (
          <Button type="link" onClick={goToPortfolioEdit}>
            {formatMessage("edit")}
          </Button>
        )}
        {hasPermission ? (
          <Popover
            placement="bottomRight"
            ref={ref}
            content={
              <PopoverContent
                id={id}
                formatMessage={formatMessage}
                goToPortfolioChangingPositionHistory={() =>
                  onGoToPortfolioChangingPositionHistory(id, closePopover)
                }
                onDeletePortfolio={() => onDeletePortfolio(id, closePopover)}
              />
            }
            trigger="click"
          >
            <Button type="link">{formatMessage("more")}</Button>
          </Popover>
        ) : (
          <Button
            type="link"
            onClick={() =>
              onGoToPortfolioChangingPositionHistory(id, closePopover)
            }
          >
            {formatMessage("warehouseRecord")}
          </Button>
        )}
      </div>
    );
  }
);

export const useGetPortfolioListColumns = (factorIds: string[]) => {
  const factorColumns = useGetFactorColumns("portfolio", factorIds);
  const formatMessage = useFormatMessage();
  const {
    goToPortfolioEdit,
    goToPortfolioAnalysis,
    goToPortfolioChangingPosition,
    goToPortfolioChangingPositionHistory,
  } = useContext(PlatformNavigationContext);
  const onGoToPortfolioChangingPositionHistory = useCallback(
    (id: string, closePopover: () => any) => {
      goToPortfolioChangingPositionHistory({ portfolioId: id });
      closePopover();
    },
    [goToPortfolioChangingPositionHistory]
  );
  const dispatch = useAppDispatch();
  const onDeletePortfolio = useCallback(
    (portfolioId: string, closePopover: () => any) => {
      dispatch(deletePortfolio(portfolioId))
        .unwrap()
        .then(() => {
          message.success(formatMessage("deleteSuccess"));
          closePopover();
        });
    },
    [dispatch, formatMessage]
  );
  const userId = useMemo(() => getUserId(), []);
  return useMemo<ColumnType<any>[]>(
    () => [
      {
        title: formatMessage("serialNumber"),
        fixed: "left",
        width: 80,
        render: (_: any, record: any, index: number) => addZeroIndex(index + 1),
      },
      {
        title: formatMessage("assetName"),
        fixed: "left",
        width: 200,
        dataIndex: "name",
        ellipsis: true,
        render: (name: string, record) => (
          <Tooltip title={name}>
            <span
              className={style.Link}
              onClick={() =>
                goToPortfolioAnalysis({ id: fastProp("id")(record) })
              }
            >
              {name}
            </span>
          </Tooltip>
        ),
      },
      ...(factorColumns || []),
      {
        title: formatMessage("operator"),
        width: 185,
        dataIndex: "id",
        fixed: "right",
        render: (id: string, record) => (
          <OperateRenderer
            id={id}
            hasPermission={fastProp("ownerId")(record) === userId}
            goToPortfolioChangingPosition={() =>
              goToPortfolioChangingPosition({ portfolioId: id })
            }
            goToPortfolioEdit={() => goToPortfolioEdit({ portfolioId: id })}
            goToPortfolioAnalysis={() => goToPortfolioAnalysis({ id: id })}
            onGoToPortfolioChangingPositionHistory={
              onGoToPortfolioChangingPositionHistory
            }
            onDeletePortfolio={onDeletePortfolio}
          />
        ),
      },
    ],
    [
      factorColumns,
      formatMessage,
      goToPortfolioAnalysis,
      goToPortfolioChangingPosition,
      goToPortfolioEdit,
      onDeletePortfolio,
      onGoToPortfolioChangingPositionHistory,
      userId,
    ]
  );
};

export const useGetCurrentPortfolio = (portfolioId: string) => {
  const portfolioListMap = useAppSelector(portfolioListMapSelector);
  return useMemo<assetPortfolios>(
    () => fastProp(portfolioId)(portfolioListMap),
    [portfolioId, portfolioListMap]
  );
};

export const useManageTableState = (
  factorIds: string[],
  portfolioType: "myPortfolio" | "allPortfolio"
) => {
  const [searchValue, setSearchValue] = useState("");
  const { factorMap } = useGetIndexInfo("portfolio");
  const portfolioList = useManagePortfolioList();
  const filterPortfolioTypeList = useMemo(() => {
    if (portfolioType === "allPortfolio") return portfolioList;
    const userId = getUserId();
    return filter<assetPortfolios>(
      (item) => fastProp("ownerId")(item) === userId
    )(portfolioList);
  }, [portfolioList, portfolioType]);

  useGetFactorValue(
    filterPortfolioTypeList,
    "id",
    "portfolio",
    factorIds,
    factorMap
  );
  const [portfolioListWithFactorValues, onSort] =
    useGetFactorsDataSource<assetPortfolios>(
      filterPortfolioTypeList,
      "id",
      factorIds,
      factorMap
    );
  const filterDataSource = useMemo(() => {
    if (!searchValue) return portfolioListWithFactorValues;
    return searchData(portfolioListWithFactorValues, searchValue, ["name"]);
  }, [portfolioListWithFactorValues, searchValue]);
  const { pageInfo, paginationData, changePages } =
    usePaginationData(filterDataSource);
  return {
    setSearchValue,
    pageInfo,
    onSort,
    changePages,
    dataSource: paginationData,
  };
};

export const useHandleEffectiveDate = () => {
  const [effectiveDate, setEffectiveDate] = useState("");
  const onGetEffectiveDate = useMemoizedFn(() => {
    getEffectiveDate().then((date: string) => setEffectiveDate(date));
  });
  return {
    effectiveDate,
    onGetEffectiveDate,
  };
};
export const useGetEffectiveDate = () => {
  const { effectiveDate, onGetEffectiveDate } = useHandleEffectiveDate();
  useEffect(() => {
    onGetEffectiveDate();
  }, [onGetEffectiveDate]);
  return effectiveDate;
};

export const useGetTurnoverRecordHistory = (portfolioId: string) => {
  const [turnoverRecordHistory, setTurnoverRecordHistory] = useState<
    TurnoverRecordHistory[]
  >([]);
  useEffect(() => {
    getTurnoverRecord(portfolioId).then((data) =>
      setTurnoverRecordHistory(data)
    );
  }, [portfolioId]);
  return turnoverRecordHistory;
};

export const useGetRunningPortfolios = () => {
  const allPortfolio = useAppSelector(prop("portfolioList.portfolioList"));
  return useCreation(
    () =>
      filter<assetPortfolios>((portfolio) => !!portfolio.historyEnd)(
        allPortfolio
      ),
    [allPortfolio]
  );
};

export const useGetFundManagersByDate = () => {
  const dispatch = useAppDispatch();
  const managerNamesMap = useAppSelector(managerNameMapSelector);
  const fundManagers = useAppSelector((state: RootState) => state.fundManagers);
  const onRequestFundManagers = useCallback(
    (date: string, fundIds: string[]) =>
      dispatch(getFundManagersByDate({ date, fundIds })).unwrap(),
    [dispatch]
  );
  const getFundManagersByDateAndId = useCallback(
    (date: string, fundId: string) => {
      const currentDateManagers = fastProp(date)(fundManagers);
      return flow(
        fastProp(fundId),
        map((id: string) => fastProp(id)(managerNamesMap))
      )(currentDateManagers);
    },
    [fundManagers, managerNamesMap]
  );
  return {
    onRequestFundManagers,
    getFundManagersByDateAndId,
  };
};

export const useGetReplaceFundIds = (currentPortfolio: Record<string, any>) => {
  const oldData = useMemo(
    () => fastProp("assets")(currentPortfolio),
    [currentPortfolio]
  );
  const [fundList, setFundList] = useState<assetsType[]>(oldData);

  useEffect(() => {
    if (fastProp("historyEnd")(currentPortfolio)) {
      Promise.resolve(
        getPortfolioPositionRecord(
          fastProp("id")(currentPortfolio),
          fastProp("historyEnd")(currentPortfolio)
        )
      ).then((res) => {
        const newData = fastProp("fundWeights")(res) || [];
        if (size(newData)) {
          newData.concat(oldData);
        }
        setFundList(flow(uniqBy("fundId"))(newData));
      });
    }
  }, [currentPortfolio, oldData]);

  return fundList;
};
