import {
  RECENT_MONTH,
  RECENT_THREE_MONTH,
  RECENT_HALF_YEAR,
  RECENT_YEAR,
  RECENT_THREE_YEAR,
  FROM_CREATION,
  RECENT_WEEK,
  RECENT_FIVE_YEAR,
  RECENT_TWO_YEAR,
  COMMON_TIME,
  yieldStatisticRange,
} from "@/constant/statisticRange";
import { flow, pick, toArray } from "lodash/fp";

export const incomeRangeOptions = flow(
  pick([
    COMMON_TIME,
    RECENT_WEEK,
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    RECENT_THREE_YEAR,
    RECENT_FIVE_YEAR,
    FROM_CREATION,
  ]),
  toArray
)(yieldStatisticRange);

export const manageRangeOptions = flow(
  pick([
    COMMON_TIME,
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    RECENT_THREE_YEAR,
    RECENT_FIVE_YEAR,
    FROM_CREATION,
  ]),
  toArray
)(yieldStatisticRange);

export const incomeDistributionOptions = flow(
  pick([
    RECENT_WEEK,
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    RECENT_THREE_YEAR,
  ]),
  toArray
)(yieldStatisticRange);

export const riskDistributionOptions = flow(
  pick([
    RECENT_WEEK,
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    RECENT_THREE_YEAR,
  ]),
  toArray
)(yieldStatisticRange);
