import React, { useMemo } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import style from "../index.module.less";
import { Space } from "antd";
import { RangeInterface, statisticRange } from "@/constant/statisticRange";
import { omit, set } from "lodash/fp";
import BenchmarkSelect from "@/components/benchmarkSelect";
import CustomStaticRange from "@/views/portfolioManage/portfolioAnalysis/components/customStaticRange";

const statisticOptions = omit(["RECENT_TRADING_DAY", "FROM_THIS_YEAR"])(
  statisticRange
);

export default React.memo<{
  activeRange: string;
  benchmarkId: string;
  className?: string;
  onRangeChange: (value: any) => any;
  onBenchmarkChange: (value: any) => any;
  staticRange?: Record<string, RangeInterface>;
  startDate?: string;
  endDate?: string;
}>(
  ({
    activeRange,
    benchmarkId,
    onRangeChange,
    onBenchmarkChange,
    className,
    staticRange,
    startDate = "",
    endDate = "",
  }) => {
    const formatMessage = useFormatMessage();
    const rangeList = useMemo(
      () =>
        set(
          "FROM_CREATION.message",
          formatMessage("sinceTheIndustry")
        )(staticRange ? staticRange : statisticOptions),
      [formatMessage, staticRange]
    );
    return (
      <div className={className}>
        <div className={style.managerSelectPart}>
          <Space>
            <div>
              <Space>
                <span>{formatMessage("benchmark")}</span>
                <BenchmarkSelect
                  className={style.CardHeader}
                  value={benchmarkId}
                  onChange={onBenchmarkChange}
                />
              </Space>
            </div>
            <span>{formatMessage("periodOfTime")}</span>
            <CustomStaticRange
              className={style.StatisticRange}
              value={activeRange}
              onChange={onRangeChange}
              startDate={startDate}
              endDate={endDate}
              staticRange={rangeList as Record<string, RangeInterface>}
            />
          </Space>
        </div>
      </div>
    );
  }
);
