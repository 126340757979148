import { useFormatMessage } from "@/util/formatMessage";
import { useCreation, useMemoizedFn } from "ahooks";
import { Button, Checkbox, Input, Modal, Space } from "antd";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import style from "../index.module.less";
import cn from "classnames";
import { useGetIndexInfo, useSelected, useShowFactor } from "./hooks";
import { RightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { identity, prop, size } from "lodash/fp";

const maxSize = 10;
type MarketIndexDialog = {
  children?: React.Component;
  selectedIds: string[];
  onChange?: (ids: string[]) => void;
};
export default React.memo<MarketIndexDialog>(
  ({ selectedIds, onChange = identity, children }) => {
    const formatMessage = useFormatMessage();
    const { marketIndexListMap, marketIndexList, factorMap } =
      useGetIndexInfo();
    const { showFactors, changeSelectCategory, selectCategory, onSearch } =
      useShowFactor(marketIndexListMap);
    const {
      selectIds,
      selectIdsMap,
      changeSelectedIds,
      onChecked,
      changeSelectedIdsByItem,
      clearSelected,
    } = useSelected();
    const [visible, setVisible] = useState<boolean>(false);
    const onChangeVisible = useMemoizedFn(() => {
      setVisible(!visible);
      if (!visible) {
        changeSelectedIds(selectedIds);
      }
    });
    const onCancel = useMemoizedFn(() => {
      setVisible(false);
    });
    const onOk = useMemoizedFn(() => {
      onChange(selectIds);
      setVisible(false);
    });
    const sizeSelectIds = useCreation(() => size(selectIds), [selectIds]);
    return (
      <>
        {children ? (
          <span onClick={onChangeVisible}>{children}</span>
        ) : (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={onChangeVisible}
          >
            {formatMessage("addMarketIndex")}
          </Button>
        )}
        <Modal
          visible={visible}
          width={752}
          onCancel={onCancel}
          title={
            <Space>
              {formatMessage("addMarketIndex")}
              <span>{`(${sizeSelectIds}/10)`}</span>
            </Space>
          }
          className={style.IndexModal}
          footer={
            <>
              <Button onClick={onCancel}>{formatMessage("cancel")}</Button>
              <Button onClick={onOk} type="primary">
                {formatMessage("ok")}
              </Button>
            </>
          }
        >
          <div className={style.IndexBody}>
            <div className={style.Left}>
              <ul className={style.Category}>
                {marketIndexList.map((v) => (
                  <li
                    key={v.id}
                    className={cn(
                      style.CategoryItem,
                      v.id === selectCategory && style.CategorySelected
                    )}
                    onClick={() => changeSelectCategory(v.id)}
                  >
                    {v.name}
                    &nbsp;&nbsp;
                    <RightOutlined />
                  </li>
                ))}
              </ul>
              <div className={style.FactorsBox}>
                <div className={style.SearchBox}>
                  <Input.Search
                    onSearch={onSearch}
                    placeholder={formatMessage("pleaseEnterIndexNameSearch")}
                    className={style.Search}
                  />
                </div>
                <ul className={style.Factors}>
                  {showFactors.map((v) => (
                    <li
                      key={v.id}
                      factor-id={v.id}
                      className={cn(
                        style.FactorItem,
                        sizeSelectIds === maxSize &&
                          !selectIdsMap[v.id] &&
                          style.Disabled
                      )}
                      onClick={
                        sizeSelectIds === maxSize && !selectIdsMap[v.id]
                          ? undefined
                          : changeSelectedIdsByItem
                      }
                    >
                      <Checkbox
                        disabled={
                          sizeSelectIds === maxSize && !selectIdsMap[v.id]
                        }
                        factor-id={v.id}
                        onChange={onChecked}
                        checked={selectIdsMap[v.id]}
                      />
                      &nbsp;&nbsp;
                      {v.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={style.Right}>
              <div className={style.Head}>
                <Space>
                  {formatMessage("selectedCategoryIndex")}
                  <span>{`(${sizeSelectIds}/10)`}</span>
                </Space>
              </div>
              <ul className={style.FactorSelectIds}>
                {selectIds.map((v) => (
                  <li
                    key={v}
                    className={cn(style.FactorItem, style.FactorItemRes)}
                  >
                    {prop([v, "name"])(factorMap)}
                    <CloseCircleOutlined onClick={() => clearSelected(v)} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Modal>
      </>
    );
  }
);
