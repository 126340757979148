import React, { useState } from "react";
import { max, pick, flow } from "lodash/fp";
import { useAppSelector } from "@/hooks/redux";
import RangePicker from "@/components/rangePicker";
import style from "./timeRange.module.less";
import { getStartDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { statisticRange } from "@/constant/statisticRange";
import StatisticRange from "@/components/statisticRange";
import { FROM_CREATION, RECENT_THREE_MONTH } from "@/constant/statisticRange";
import { getStatisticsRangeStartDate } from "@/constant/statisticRangeCalculator/helper";
import { fastProp, mapIndexed } from "@/util/opt";

const messages = {
  RECENT_THREE_MONTH: "lastQuarter",
  RECENT_HALF_YEAR: "lastHalfYear",
  RECENT_YEAR: "lastYear",
  FROM_CREATION: "allPeriod",
};

const statisticRangeList = flow(
  pick([
    "RECENT_THREE_MONTH",
    "RECENT_HALF_YEAR",
    "RECENT_YEAR",
    "FROM_CREATION",
  ]),
  mapIndexed(({ ...restProp }: Record<string, any>, index: string) => {
    return {
      ...restProp,
      message: fastProp(index)(messages),
    };
  })
)(statisticRange);

const TimeRange = (props: {
  value: string[];
  onChange: (value: string[]) => void;
  minDate: string;
  maxDate: string;
}): JSX.Element => {
  const { value, minDate, maxDate, onChange } = props || {};
  const { processedTradingDates, tradingDateList } = useAppSelector(
    (store) => store.tradingDates
  );
  const changeDate = (value: string[]) => {
    onChange(value);
  };
  const onShortcutClick = (range: string) => {
    if (range === FROM_CREATION) {
      changeDate([minDate, maxDate]);
      return;
    }
    const statisticStartDate = getStatisticsRangeStartDate(
      range,
      maxDate as string
    );
    if (statisticStartDate) {
      const startDate = getStartDate(
        tradingDateList,
        processedTradingDates
      )(statisticStartDate);
      changeDate([max([startDate, minDate]), maxDate]);
      return;
    }
    changeDate([minDate, maxDate]);
  };
  const [selectValue, setSelectValue] = useState(RECENT_THREE_MONTH);
  return (
    <span>
      <StatisticRange
        className={style.Width}
        value={selectValue}
        statisticRangeList={statisticRangeList as any}
        onChange={(value: string) => {
          setSelectValue(value);
          onShortcutClick(value);
        }}
      />
      <RangePicker
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onChange={changeDate}
      />
    </span>
  );
};

export default TimeRange;
