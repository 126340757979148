import { prop, isEmpty, set } from "lodash/fp";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BrinsonAttributionBody,
  BrinsonAttributionResponse,
  ChangeMonitorBody,
  ConcentrationDetectionBody,
  factorValueBody,
  factorValueResponse,
  Organization,
  AumPerformanceDisassembleParams,
  RankBody,
  RankResponse,
} from "@/model/aum";
import {
  getAumFactorInfo,
  getAumFactorValue,
  getAumOrganizations,
  getAumPortfolioFactorOverview,
  getAumPortfolioIndexInfo,
  getAumPortfolios,
  getAumPortfolioSummary,
  getBrinsonAttribution,
  getChangeMonitor,
  getConcentrationDetection,
  getPortfolioPerformanceTrend,
  getAumPerformanceDisassemble,
  getRankList,
} from "@/api/aum";
import { getValueAtRisk } from "@/api/portfolioAnalysis";
import { OrganizationType, AumPortfolio } from "@/model/aum";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { valueAtRiskParam } from "@/api/portfolioAnalysis";
import { getSection } from "@/views/portfolioManage/portfolioAnalysis/riskAnalysis/constant";
import { DatePeriodValue } from "@/base-components/datePeriodSelect";
import createSocketThunk from "@/util/createSocketThunk";
import { FactorCategory } from "@/model/factors";
import { mapTree } from "@/util/opt/tree";
import { getFactorsFormatterName } from "@/constant/factorFormatter";
import { RootState } from "./index";

export const fetchOrganizations = createAsyncThunk(
  "aum/organizations",
  async (organizationType: OrganizationType) => {
    const response = await getAumOrganizations(organizationType);
    return response;
  }
);

export const fetchAumAssetPortfolios = createAsyncThunk(
  "aum/assetPortfolios",
  async (organizationId: string) => {
    const response = await getAumPortfolios(organizationId);
    return response;
  }
);

//集中检测基金相关内容
export const fetchConcentrationFund =
  avoidMultiRequestActionThunk<ConcentrationDetectionBody>(
    (data) =>
      `aumData.${data.grpId}.positionStructure.concentrationData.concentrationFund.${data.type}`,
    createAsyncThunk(
      "aumData.positionStructure.concentrationData.concentrationFund",
      async (data) => {
        const response = await getConcentrationDetection(data);
        return response;
      }
    )
  );

//集中检测类型分类相关内容
export const fetchConcentrationType =
  avoidMultiRequestActionThunk<ConcentrationDetectionBody>(
    (data) =>
      `aumData.${data.grpId}.positionStructure.concentrationData.typeRelatedData.${data.type}`,
    createAsyncThunk(
      "aumData.positionStructure.concentrationData.typeRelatedData",
      async (data) => {
        const response = await getConcentrationDetection(data);
        return response;
      }
    )
  );

//异动检测
export const fetchChangeMonitor =
  avoidMultiRequestActionThunk<ChangeMonitorBody>(
    (data) =>
      data?.section
        ? `aumData.${data.aumGrpId}.positionStructure.changeMonitor.${data.type}.${data?.section}`
        : `aumData.${data.aumGrpId}.positionStructure.changeMonitor.${data.type}`,
    createAsyncThunk(
      "aumData.positionStructure.changeMonitor",
      async (data) => {
        const response = await getChangeMonitor(data);
        return response;
      }
    )
  );

export const fetchFactors = createAsyncThunk("aum/aumFactors", async () => {
  const response = await getAumFactorInfo();
  return response;
});

export const fetchAumSubRankList = createAsyncThunk(
  "aum/aumSubRankList",
  async (body: RankBody) => {
    const response = await getRankList(body);
    return response;
  }
);

export const fetchAumSameLevelRankList = createAsyncThunk(
  "aum/aumSameLevelRankList",
  async (body: RankBody) => {
    const response = await getRankList(body);
    return response;
  }
);

type AumDataState = {
  positionStructure?: Record<string, any>;
};

export const fetchAumAssetPortfolioFactor = createAsyncThunk(
  "aum/assetPortfolio/factor",
  async (id: string) => {
    const response = await getAumPortfolioFactorOverview(id);
    return response;
  }
);

export const fetchAumAssetPortfolioSummary = createAsyncThunk(
  "aum/assetPortfolio/summary",
  async (id: string) => {
    const response = await getAumPortfolioSummary(id);
    return response;
  }
);

export const fetchPredictMaxLoss = createAsyncThunk(
  "aum/predictMaxLoss",
  async (param: valueAtRiskParam, { getState }) => {
    const { id, computationType, confidenceType, future } = param;
    const sectionId = getSection(computationType, confidenceType, future);
    const data = prop(`aum.${id}.predictMaxLoss.${sectionId}`)(
      getState() as RootState
    );
    if (!isEmpty(data)) return Promise.resolve();
    const res = await getValueAtRisk(param);
    return res;
  }
);
export const fetchAumAssetPortfolioIndexInfo =
  avoidMultiRequestActionThunk<string>(
    (id) => `aum.portfolioIndexInfo.${id}`,
    createAsyncThunk("aum/assetPortfolio/indexInfo", async (id) => {
      const response = await getAumPortfolioIndexInfo(id);
      return response;
    })
  );
export const fetchPortfolioPerformanceTrend = createAsyncThunk(
  "aum/portfolio/performance/trend",
  async ({
    portfolioId,
    section,
  }: {
    portfolioId: string;
    section: DatePeriodValue;
  }) => {
    const response = await getPortfolioPerformanceTrend(portfolioId, section);
    return response;
  }
);

export const fetchGetAumPerformanceDisassemble = createSocketThunk(
  "aum/FINISH_AUM_PERFORMANCE_DISSECT",
  "FINISH_AUM_PERFORMANCE_DISSECT",
  async (params: AumPerformanceDisassembleParams) => {
    const response = await getAumPerformanceDisassemble(params);
    return response;
  }
);

//brinson归因
export const fetchBrinsonAttribution = createAsyncThunk(
  "aum/brinsonAttribution",
  async (data: BrinsonAttributionBody) => {
    const response = await getBrinsonAttribution(data);
    return response;
  }
);

export const fetchAumRankListFactorValue = createAsyncThunk(
  "aum/rankList/factorValue",
  async (body: factorValueBody) => {
    const response = await getAumFactorValue(body);
    return response;
  }
);

type AumState = {
  organizations: Record<string, Organization[]>;
  assetPortfolios: Record<string, AumPortfolio[]>;
  [key: string]: AumDataState | any;
  portfolioFactor: Record<string, Record<string, number>>;
  brinsonAttribution: BrinsonAttributionResponse;
  aumFactor: FactorCategory[];
  aumSubRankList: RankResponse;
  sameLevelRankList: RankResponse;
  aumFactorValue: Record<string, factorValueResponse[]>;
};

const initialState: AumState = {
  organizations: {},
  assetPortfolios: {},
  portfolioFactor: {},
  portfolioSummary: {},
  brinsonAttribution: {
    annualSharpeRatio: 0,
    dailyAllocationAttributions: [],
    dailyBenchmarkActualReturns: [],
    dailyBenchmarkReturns: [],
    dailyPortfolioActualReturns: [],
    dailyPortfolioReturns: [],
    dailySelectionAttributions: [],
    dates: [],
    informationRatio: 0,
    maxDrawdown: 0,
    trackingError: 0,
  },
  aumFactor: [],
  aumSubRankList: {
    numOfPortfolios: 0,
    portfolioList: [],
  },
  sameLevelRankList: {
    numOfPortfolios: 0,
    portfolioList: [],
  },
  aumFactorValue: {},
};

const fetchGetAumPerformanceDisassembleFinish: string =
  fetchGetAumPerformanceDisassemble.finish.type;
const fetchGetAumPerformanceDisassembleError: string =
  fetchGetAumPerformanceDisassemble.error.type;
const fetchGetAumPerformanceDisassembleProgress: string =
  fetchGetAumPerformanceDisassemble.progress.type;

const aumDataSlice = createSlice({
  name: "aum",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOrganizations.fulfilled.type]: (state, action) => {
      state.organizations[action?.meta?.arg] = action.payload;
    },
    [fetchAumAssetPortfolios.fulfilled.type]: (state, action) => {
      state.assetPortfolios[action?.meta?.arg] = action.payload?.data;
    },
    [fetchConcentrationFund.fulfilled.type]: (state, action) => {
      const { grpId, type } = action.meta?.arg;
      if (action.payload) {
        state[grpId] = set(
          `positionStructure.concentrationData.concentrationFund.${type}`,
          action.payload
        )(state[grpId]);
      }
    },
    [fetchConcentrationType.fulfilled.type]: (state, action) => {
      const { grpId, type } = action.meta?.arg;
      if (action.payload) {
        state[grpId] = set(
          `positionStructure.concentrationData.typeRelatedData.${type}`,
          action.payload
        )(state[grpId]);
      }
    },
    [fetchChangeMonitor.fulfilled.type]: (state, action) => {
      const { aumGrpId, type, section } = action.meta?.arg;
      if (action.payload) {
        section
          ? (state[aumGrpId] = set(
              `positionStructure.changeMonitor.${type}.${section}`,
              action.payload
            )(state[aumGrpId]))
          : (state[aumGrpId] = set(
              `positionStructure.changeMonitor.${type}`,
              action.payload
            )(state[aumGrpId]));
      }
    },
    [fetchPortfolioPerformanceTrend.fulfilled.type]: (state, action) => {
      const { section } = action.meta?.arg;
      if (action.payload && section) {
        state.portfolioPerformanceTrend = set(
          section,
          action.payload
        )(state.portfolioPerformanceTrend);
      }
    },
    [fetchAumAssetPortfolioFactor.fulfilled.type]: (state, action) => {
      state.portfolioFactor[action?.meta?.arg] = action.payload;
    },
    [fetchAumAssetPortfolioSummary.fulfilled.type]: (state, action) => {
      if (action.payload && action?.meta?.arg) {
        state.portfolioSummary[action?.meta?.arg] = action.payload;
      }
    },
    [fetchPredictMaxLoss.fulfilled.type]: (state, action) => {
      const { id, computationType, confidenceType, future } = action.meta?.arg;
      const section = getSection(computationType, confidenceType, future);
      if (id && section && action.payload) {
        state[id] = set(`predictMaxLoss.${section}`, action.payload)(state[id]);
      }
    },
    [fetchAumAssetPortfolioIndexInfo.fulfilled.type]: (state, action) => {
      if (action.payload && action?.meta?.arg) {
        state.portfolioIndexInfo = set(
          action?.meta?.arg,
          action.payload
        )(state[action?.meta?.arg]);
      }
    },
    [fetchGetAumPerformanceDisassembleFinish]: (state, action) => {
      state[action.meta?.arg.portfolioId] = {
        ...state[action.meta?.arg.portfolioId],
        performanceDisassembleResult: action?.payload?.view,
        performanceDisassembleProgress: action.meta,
      };
    },

    [fetchGetAumPerformanceDisassembleError]: (state, action) => {
      state[action.meta?.arg.portfolioId] = {
        ...state[action.meta?.arg.portfolioId],
        performanceDisassembleProgress: action.error,
      };
    },
    [fetchGetAumPerformanceDisassembleProgress]: (state, action) => {
      state[action.meta?.arg.portfolioId] = {
        ...state[action.meta?.arg.portfolioId],
        performanceDisassembleProgress: action?.payload,
      };
    },
    [fetchBrinsonAttribution.fulfilled.type]: (state, action) => {
      state.brinsonAttribution = action.payload;
    },
    [fetchFactors.fulfilled.type]: (state, action) => {
      state.aumFactor = [
        {
          id: "portfolioBasic",
          nameKey: "portfolioInfo",
          factorViews: [
            {
              id: "historyEnd",
              nameKey: "dataUpdateDate",
              isBasicInfo: true,
              sorter: true,
            },
            {
              id: "foundDate",
              nameKey: "foundDate",
              isBasicInfo: true,
              sorter: true,
            },
            {
              id: "netValue",
              nameKey: "latestNetValue",
              isBasicInfo: true,
              sorter: true,
              align: "right",
              format: "float4",
            },
            {
              id: "dailyReturn",
              nameKey: "dailyYieldReturn",
              isBasicInfo: true,
              sorter: true,
              align: "right",
              format: "percent2",
            },
            {
              id: "dailyRevenue",
              nameKey: "dailyYield",
              isBasicInfo: true,
              sorter: true,
              format: "float2",
              align: "right",
            },
          ],
        },
        ...mapTree<FactorCategory>("factorViews", (item) => ({
          ...item,
          format: item.factorViews
            ? undefined
            : getFactorsFormatterName(item.name),
          align: "right",
        }))(action.payload),
      ];
    },
    [fetchAumSubRankList.fulfilled.type]: (state, action) => {
      state.aumSubRankList = action.payload;
    },
    [fetchAumSameLevelRankList.fulfilled.type]: (state, action) => {
      state.sameLevelRankList = action.payload;
    },
    [fetchAumRankListFactorValue.fulfilled.type]: (state, action) => {
      state.aumFactorValue[action.meta?.arg?.key] = action.payload;
    },
  },
});

export default aumDataSlice.reducer;
