import React, { useEffect } from "react";
import { AssetDetailComponent } from "../../constant";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import FundAllocation from "./components/fundAllocation";
import FundIndustry from "./components/fundIndustry";
import FundTopPositions from "./components/fundTopPositions";
import {
  fetchGetFundDetailSectorTrend,
  fetchGetFundDetailStockPositionTrend,
} from "@/store/fundDetailSlice";
import mapper from "./mapper";

export default React.memo<AssetDetailComponent>(({ fundId, updateHandler }) => {
  const dispatch = useAppDispatch();
  const { industryId } = useAppSelector((state) => mapper(state, fundId));
  useEffect(() => {
    dispatch(fetchGetFundDetailStockPositionTrend({ fundId, industryId }));
    dispatch(fetchGetFundDetailSectorTrend(fundId));
  }, [dispatch, fundId, industryId]);
  return (
    <>
      <FundAllocation fundId={fundId} />
      <br />
      <FundIndustry fundId={fundId} updateHandler={updateHandler} />
      <br />
      <FundTopPositions fundId={fundId} updateHandler={updateHandler} />
    </>
  );
});
