// 比赛状态
export enum CompetitionStatus {
  HAS_END = -1, // 已结束
  PENDING = 0, // 正在进行
  ENROLL_DEADLINE = 1, // 报名截止
  ENROLLING = 2, // 报名中
}

// 参赛状态
export enum UserJoinCompetitionStatus {
  NO_PERMISSION = 0, // 没有比赛资格
  NO_JOIN = 1, // 有资格未参赛
  HAS_JOIN = 2, // 有资格已参赛
  // COMPETITION_END = 3, // 比赛已结束 todo 目前认为应该不存在这个
}

export type CompetitionValidate = true | false; //  1 启动 0 不启用
export type PresentRange = "PRIVATE" | "PUBLIC";
export type CompeteUsers = string[];

export type Competition = {
  competitionId: string;
  name: string;
  summary: string;
  regulation: string;
  startDate: string;
  endDate: string;
  presentRange: PresentRange; // 比赛展示范围
  enterEndDate: string; // 报名截止时间
  bannerImage: string;
  bannerImageName: string;
  entranceImage: string;
  entranceImageName: string;
  createUserId: string;
  validate: CompetitionValidate; //  1 启动 0 不启用
  status: CompetitionStatus;
  competeUsers: CompeteUsers;
};

export type UserCompetition = {
  hasPermission: boolean; // 是否有参赛资格
  inCompetition: boolean; // 是否在参赛中
  portfolioTotalNum: boolean;
} & Competition;

export const presentRangeText = {
  PUBLIC: "presentRangePublic",
  PRIVATE: "presentRangePrivate",
};

export const competitionStatusText = {
  [CompetitionStatus.HAS_END]: "competitionStatusHasEnd",
  [CompetitionStatus.PENDING]: "competitionStatusPending",
  [CompetitionStatus.ENROLL_DEADLINE]: "competitionStatusEnrollDeadline",
  [CompetitionStatus.ENROLLING]: "competitionStatusEnrolling",
};

export function getCompetitionStatusText(status: CompetitionStatus): string {
  if (
    status === CompetitionStatus.ENROLLING ||
    status === CompetitionStatus.ENROLL_DEADLINE
  ) {
    return "notStarted";
  }
  return competitionStatusText?.[status];
}

export function getUserJoinCompetitionStatus(
  userCompetition: UserCompetition
): UserJoinCompetitionStatus {
  if (!userCompetition?.hasPermission) {
    return UserJoinCompetitionStatus.NO_PERMISSION;
  }
  if (userCompetition?.hasPermission && userCompetition?.inCompetition) {
    return UserJoinCompetitionStatus.HAS_JOIN;
  }
  return UserJoinCompetitionStatus.NO_JOIN;
}

export type CompetitionInterface = {
  [key: string]: any;
};

export type CompetitionPortFactors = {
  returnFactors: Record<string, any>[];
  ratioFactors: Record<string, any>[];
  riskFactors: Record<string, any>[];
};

export interface CompetitionDetail extends Competition {
  inCompetition: boolean;
  hasPermission: boolean;
  portfolioTotalNum: string;
  updateDate: string;
  enterEndDate: string;
}

export interface RankBasicParam {
  factorId: string;
  competitionId: string;
  type: string;
}

export type PortfolioRank = {
  portfolioId: string;
  ownerName: string;
  portfolioName: string;
  rankNum: string;
  historyEnd: string;
  snapshotUpdateDate: string;
};

export interface CompetitionRankBasic {
  myPortfolioRank: PortfolioRank;
  portfolioRanks: PortfolioRank[];
}

export interface CompetitionFactorsParam {
  competitionId: string;
  factorIds: string[];
  portfolioIds: string[];
  type: string;
}

export interface CompetitionFactors {
  portfolioId: string;
  factors: Record<string, number>;
}

export interface WantCompetitionParam {
  competitionId: string;
  portfolioId: string;
}
