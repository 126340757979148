import { Space, Table } from "antd";
import cn from "classnames";
import { LineSeriesOption } from "echarts";
import { map, prop } from "lodash/fp";
import React, { useMemo } from "react";
import LineChart, { LineChartOpts } from "@/echarts/lineChart";
import { useFormatMessage } from "@/util/formatMessage";
import { getBrinsonAndNetValueLineChartOptions } from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/constant";
import { getChartColumns, names } from "../constant";
import { fastProp } from "@/util/opt";
import { useAppSelector } from "@/hooks/redux";
import { brinsonLineChartSelector } from "../mapper";
import style from "../index.module.less";

export default React.memo(() => {
  const formatMessage = useFormatMessage();
  const brinsonLineChart = useAppSelector(brinsonLineChartSelector);
  const listTableData = useMemo(
    () => [fastProp("listData")(brinsonLineChart)],
    [brinsonLineChart]
  );
  // const listTable = useGetChartTableDataSource(listTableData);

  const chartData = useMemo(
    () => fastProp("chartData")(brinsonLineChart),
    [brinsonLineChart]
  );
  const { dataSource, dates } = chartData;
  const options = useMemo<LineChartOpts["options"]>(
    () => getBrinsonAndNetValueLineChartOptions(dates),
    [dates]
  );
  const series = useMemo<LineChartOpts["series"]>(
    () =>
      map<string, LineSeriesOption>((v) => ({
        type: "line",
        name: formatMessage(v),
        showSymbol: false,
        smooth: false,
        lineStyle: {
          width: 1.5,
        },
        data: map((date: string) => prop(`${date}.${v}`)(dataSource) || 0)(
          dates
        ),
      }))(names),
    [formatMessage, dataSource, dates]
  );
  const columns: any = useMemo(
    () => getChartColumns(formatMessage),
    [formatMessage]
  );
  return (
    <Space className={style.FullWidth} direction="vertical">
      <Table
        bordered
        columns={columns}
        dataSource={listTableData}
        pagination={false}
        className={cn(style.Table, style.BrinsonTable)}
      />
      <LineChart options={options} series={series} height={400} />
    </Space>
  );
});
