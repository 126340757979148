import { PromiseType } from "@/model/promise";
import { get, post, put, del } from "@/util/axios";
import {
  GroupFundList,
  optionalFundGroupList,
} from "@/views/myOptional/fundOptional/interface";
import { optionalFundManagerGroupList } from "@/views/myOptional/fundManagerOptional/interface";

// 自选基金-分组列表
export const getGroupList = (): PromiseType<optionalFundGroupList[]> =>
  get("/optional_fund/group_list");

// 自选基金-添加/修改分组
export type addOptionalGroupParm = {
  groupId?: string | undefined;
  oldGroupName?: string | undefined;
  newGroupName?: string | undefined;
  groupName?: string | undefined;
  groupDetail: string | undefined;
  type: string;
};
export const addOptionalGroup = (data: addOptionalGroupParm) =>
  post("/optional/create_group", data, {
    config: {
      showError: false,
    },
  });

export const updateOptionalGroup = (data: addOptionalGroupParm) =>
  put("/optional/update_group", data, {
    config: {
      showError: false,
    },
  });
export type repeatNameParm = {
  name: string | undefined;
  type: string;
};
// 自选基金经理-分组重名校验
export const groupNameRepeat = (data: repeatNameParm) =>
  get("/optional/dup_name", data, {
    config: {
      showError: false,
    },
  });

// 自选基金-删除分组
export type delGroupParm = {
  groupId: string | undefined;
  type: string;
};
export const deleteOptionalGroup = (data: delGroupParm) =>
  del(
    `/optional/delete_group?groupId=${data?.groupId}&type=${data?.type}`,
    undefined
  );

// 自选基金-添加自选基金
export type AddGroupFundListParm = {
  groupId?: string;
  fundManagerIds?: string[];
  groupName?: string | undefined;
  fundIds?: string[] | undefined[];
  type?: string;
};
export const getAddGroupFundList = (data: AddGroupFundListParm) =>
  post("/optional/add_to_group", data);

// 自选基金-全量修改
export const updateGroupFundList = (data: AddGroupFundListParm) =>
  post("optional_fund/update_list", data);

// 自选基金-删除基金
export const deleteOptionalFund = (data: AddGroupFundListParm) =>
  put("/optional/delete_optional", data);

// 自选基金-移动
export type MoveOptionalFundParm = {
  fundIds?: string[];
  oldGroupName?: string;
  newGroupName?: string;
  fundManagerIds?: string[];
  fromGroupId?: string;
  toGroupId?: string;
  type: string;
};
export const moveOptionalFund = (data: MoveOptionalFundParm) =>
  put("/optional/move", data);

// 自选基金经理-分组列表
export const getManagerGroupList = (): PromiseType<
  optionalFundManagerGroupList[]
> => get("/optional_manager/group_list");

// 自选基金经理-旗下基金列表
export const getManagerGroupFundList = (
  fundManagerId: string
): PromiseType<GroupFundList[]> =>
  get("/fund_manager/manage_fund", { fundManagerId });

// 自选基金经理-添加至默认分组
export const addFundManagerDefaultGroup = (fundManagerId: string) =>
  post("/optional_manager/add_to_default_group", { fundManagerId });
