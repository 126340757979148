import React, { memo } from "react";
import { Radio, RadioGroupProps } from "antd";
import { map } from "lodash/fp";
// import { useFormatMessage } from "@/base/hooks";
import { DatePeriodName } from "@/util/business-core/datePeriod";
import { useOptions } from "./select";
import { DatePeriodProps } from "./type";

const DatePeriodPicker = memo<
  Omit<RadioGroupProps, "value" | "onChange" | "value"> & DatePeriodProps
>(({ value, options, onChange, ...restProps }) => {
  const _options = useOptions(options);

  return (
    <Radio.Group value={value} {...restProps}>
      {map(({ label, value }) => {
        return (
          <Radio.Button
            key={value}
            value={value}
            onChange={(e) => onChange?.(e.target.value as DatePeriodName)}
          >
            {label}
          </Radio.Button>
        );
      })(_options)}
    </Radio.Group>
  );
});

export default DatePeriodPicker;
