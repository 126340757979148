import { useFormatMessage } from "@/util/formatMessage";
import { Button, Card, Space, Steps } from "antd";
import { map } from "lodash/fp";
import React from "react";
import BackTestingAllocation from "./backTestingAllocation";
import { fromAboveToBelowSteps } from "./constant";
import FundAllocation from "./fundAllocation";
import { useManageStepOperator } from "./hooks";
import style from "./index.module.less";
import ModelAllocation from "./modelAllocation";
import SavePortfolio from "../manualCreatePortfolio/savePortfolio";
import { useAppSelector } from "@/hooks/redux";
import { useCloseModal } from "../manualCreatePortfolio/hooks";

const { Step } = Steps;
export default React.memo(() => {
  const formatMessage = useFormatMessage();
  const {
    steps,
    onNext,
    goBack,
    buttonDisabled,
    setButtonDisabled,
    savePortfolioRef,
  } = useManageStepOperator();
  const { backTestingFormData, modelPortfolioAllocateData } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation
  );
  const cancel = useCloseModal("modelAllocation");
  return (
    <div className={style.LayoutContainer}>
      <div className={style.LayoutScrollYContent}>
        <Card className={style.MarginBottom16}>
          <Steps current={steps} className={style.StepContainer}>
            {map(({ message }) => <Step key={message} title={message} />)(
              fromAboveToBelowSteps.steps
            )}
          </Steps>
        </Card>
        {steps === fromAboveToBelowSteps.allocationModel && (
          <ModelAllocation
            onError={setButtonDisabled}
            buttonDisabled={buttonDisabled}
          />
        )}
        {steps === fromAboveToBelowSteps.fundAllocation && (
          <FundAllocation onError={setButtonDisabled} />
        )}
        {steps === fromAboveToBelowSteps.backTestingAllocation && (
          <BackTestingAllocation onError={setButtonDisabled} />
        )}
        {steps === fromAboveToBelowSteps.savePortfolio && (
          <SavePortfolio
            ref={savePortfolioRef}
            formData={backTestingFormData}
            data={modelPortfolioAllocateData}
            onError={setButtonDisabled}
          />
        )}
      </div>
      <div className={style.LayoutFooter}>
        <Space>
          <Button className={style.OperateButton} onClick={cancel}>
            {formatMessage("cancel")}
          </Button>
          {steps !== fromAboveToBelowSteps.allocationModel && (
            <Button
              className={style.OperateButton}
              type="primary"
              onClick={goBack}
            >
              {formatMessage("thePreviousStep")}
            </Button>
          )}
          <Button
            className={style.OperateButton}
            type="primary"
            onClick={onNext}
            disabled={buttonDisabled}
          >
            {steps === fromAboveToBelowSteps.savePortfolio
              ? formatMessage("savePortfolio")
              : formatMessage("theNextStep")}
          </Button>
        </Space>
      </div>
    </div>
  );
});
