import {
  CompetitionIntroduceIcon,
  CompetitionTitleIcon,
  DateIcon,
} from "@/components/icons";
import { Competition } from "@/model/competition";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import { Modal, Space } from "antd";
import dayjs from "dayjs";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import style from "./index.module.less";

const IconStyle = {
  width: 15,
  height: 15,
  marginRight: 8,
};

export type CompetitionModalRef = {
  openModal: (competitionInfo: Competition) => any;
};

export default forwardRef<CompetitionModalRef>((_, ref) => {
  const formatMessage = useFormatMessage();
  const [visible, setVisible] = useState(false);
  const [competitionInfo, setCompetitionInfo] = useState({});
  const openModal = useMemoizedFn((competitionInfo: Competition) => {
    setVisible(true);
    setCompetitionInfo(competitionInfo);
  });
  useImperativeHandle(ref, () => ({
    openModal: openModal,
  }));
  return (
    <>
      <Modal
        width={543}
        visible={visible}
        footer={null}
        closable={false}
        className={style.Modal}
        onCancel={(e) => {
          e.stopPropagation();
          setVisible(false);
        }}
        getContainer={false}
      >
        <img src={fastProp("entranceImage")(competitionInfo)} />
        <div className={style.CompetitionInfo}>
          <Space direction="vertical" className={style.FullWidth}>
            <div className={style.CompetitionName}>
              <Space>
                <CompetitionTitleIcon style={IconStyle} />
                <h3>{fastProp("name")(competitionInfo)}</h3>
                <div>
                  ({formatMessage("competitionStatusEnrollDeadline")}:
                  {fastProp("enterEndDate")(competitionInfo)})
                </div>
              </Space>
            </div>
            <div className={style.CompetitionIntroduce}>
              <CompetitionIntroduceIcon style={IconStyle} />
              <span className={style.Summary}>
                {fastProp("summary")(competitionInfo)}
              </span>
            </div>
            <div>
              <DateIcon style={IconStyle} />
              {dayjs(fastProp("startDate")(competitionInfo)).format(
                "YYYY年MM月DD日"
              )}{" "}
              ——{" "}
              {dayjs(fastProp("endDate")(competitionInfo)).format(
                "YYYY年MM月DD日"
              )}
            </div>
          </Space>
        </div>
      </Modal>
    </>
  );
});
