import { useFormatMessage } from "@/util/formatMessage";
import { Space } from "antd";
import { prop } from "lodash/fp";
import React, { ReactNode } from "react";
import { useCreation } from "ahooks";
import EmptyContent from "../emptyContent";
import errorMessageMap from "./constant";

type ErrorBoundaryProps = {
  errKey: string | undefined | null;
  className?: string;
  children?: ReactNode;
  height?: number;
};

const ErrorBoundary = ({
  errKey,
  height = 400,
  className,
  children,
}: ErrorBoundaryProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const containerStyle = useCreation(
    () => ({
      height: `${height}px`,
    }),
    [height]
  );
  return errKey ? (
    <div style={containerStyle} className={className}>
      <EmptyContent>
        <Space direction="vertical">
          <strong>{formatMessage("noData")}</strong>
          <strong>
            {formatMessage(prop(`${errKey}.message`)(errorMessageMap))}
          </strong>
        </Space>
      </EmptyContent>
    </div>
  ) : (
    <>{children}</>
  );
};

export default React.memo(ErrorBoundary);
