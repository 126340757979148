import {
  concat,
  flow,
  fromPairs,
  includes,
  isEmpty,
  keys,
  map,
  toNumber,
  values,
  forEach,
  isNil,
  isNaN,
  prop,
  isNumber,
} from "lodash/fp";
import cn from "classnames";
import { FormatMessageFunc } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import { Space, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import style from "./index.module.less";
import moment from "moment";
import { toFixedNumber } from "@/util/numberFormatter";
import { SearchOptionInterface } from "@/components/headerSearch/constant";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { size } from "lodash";
import { subAccountNameProp } from "@/model/customer";
import { CASH } from "./components/addAssetPortfolio/hooks";
import dayjs from "dayjs";

export const transactionTypeMap: Record<string, any> = {
  买入: "PURCHASE",
  卖出: "REDEMPTION",
  转入: "APPEND_INVEST",
  转出: "REDUCE_INVEST",
};

export const assetCategoryMap: Record<string, any> = {
  股票: "STOCK",
  基金: "OPEN_FUND",
  债劵: "BOND",
  现金: "CASH",
};
export const dividendMethodMap: Record<string, any> = {
  分红再投资: "REINVESTMENT",
  分红转现金: "TURNTOCASH",
};

// const intRegex = /^[-+]?(\d+[Ee]\+?)?\d+$/;
// eslint-disable-next-line
// const floatReg = /^[+-]?(\d+.)?(\d+[Ee][-\+])?\d+%?$/;

const transactionTypes: string[] = concat(keys(transactionTypeMap))(
  values(transactionTypeMap)
);
const assetTypes = concat(keys(assetCategoryMap))(values(assetCategoryMap));

const dividendMethod = concat(keys(dividendMethodMap))(
  values(dividendMethodMap)
);

const pRege = /[a-z]/i;

export const getColumns = (formatMessage: FormatMessageFunc) => [
  {
    title: formatMessage("Account"),
    dataIndex: "subAccountName",
    align: "left",
  },
  {
    title: formatMessage("transactionType"),
    dataIndex: "transactionType",
    align: "left",
    render: (text: string) => {
      if (!checkType(text)(transactionTypes))
        return (
          <Space>
            <span>{text}</span>
            <Tooltip title={formatMessage("pleaseEnterCorrectData")}>
              <InfoCircleFilled className={style.ErrorIcon} />
            </Tooltip>
          </Space>
        );
      if (pRege.test(text)) {
        return formatMessage(text);
      }
      return text;
    },
  },
  {
    title: formatMessage("assetCategory"),
    dataIndex: "assetType",
    align: "left",
    render: (text: string) => {
      if (!checkType(text)(assetTypes))
        return (
          <Space>
            <span>{text}</span>
            <Tooltip title={formatMessage("pleaseEnterCorrectData")}>
              <InfoCircleFilled className={style.ErrorIcon} />
            </Tooltip>
          </Space>
        );
      if (pRege.test(text)) {
        return text === "CASH" ? formatMessage("cash") : formatMessage(text);
      }
      return text;
    },
  },
  {
    title: formatMessage("Date"),
    dataIndex: "investDate",
    align: "left",
    render: (date: string) => {
      return (
        <>
          <span>{date}</span>
          {checkInvalidDate(date) && (
            <Tooltip title={formatMessage("pleaseEnterCorrectData")}>
              <InfoCircleFilled className={style.ErrorIcon} />
            </Tooltip>
          )}
        </>
      );
    },
  },
  {
    title: <span>{formatMessage("positionAssetCode")}</span>,
    dataIndex: "assetCode",
    align: "left",
  },
  {
    title: formatMessage("assetNumber"),
    dataIndex: "share",
    align: "left",
    render: (data: string) => {
      return (
        <>
          {checkInvalidFloat(data) && data ? (
            <>
              <span>{data}</span>
              <Tooltip title={formatMessage("pleaseEnterCorrectData")}>
                <InfoCircleFilled className={style.ErrorIcon} />
              </Tooltip>
            </>
          ) : (
            <span>{data ? Number(data) : ""}</span>
          )}
        </>
      );
    },
  },
  {
    title: formatMessage("transactionPrice"),
    dataIndex: "transactionPrice",
    align: "left",
    render: (data: string) => {
      return !isNil(data) && !isNaN(data)
        ? checkError(data, formatMessage)
        : "";
    },
  },
  {
    title: (
      <span>
        {formatMessage("amountOfMoney")}（{formatMessage("YUAN")}）
      </span>
    ),
    dataIndex: "investScale",
    align: "left",
    render: (text: string) => {
      return (
        <>
          {checkInvalidFloat(text) && text ? (
            <>
              <span>{text}</span>
              <Tooltip title={formatMessage("pleaseEnterCorrectData")}>
                <InfoCircleFilled className={style.ErrorIcon} />
              </Tooltip>
            </>
          ) : (
            <span>
              {!isNil(text) && !isNaN(text)
                ? toFixedNumber(2)(Number(text))
                : ""}
            </span>
          )}
        </>
      );
    },
  },
  {
    title: <span>{formatMessage("dividendMethod")}</span>,
    dataIndex: "dividendPolicy",
    align: "left",
    render: (text: string) => {
      if (text && !checkType(text)(dividendMethod))
        return (
          <Space>
            <span>{text}</span>
            <Tooltip title={formatMessage("pleaseEnterCorrectData")}>
              <InfoCircleFilled className={style.ErrorIcon} />
            </Tooltip>
          </Space>
        );
      if (pRege.test(text)) {
        return formatMessage(text);
      }
      return text;
    },
  },
  {
    title: <span>{formatMessage("serviceCharge")}</span>,
    dataIndex: "handlingFee",
    align: "left",
    render: (text: string) => {
      return (
        <>
          {checkInvalidFloat(text) ? (
            <>
              {text}
              <Tooltip title={formatMessage("pleaseEnterCorrectData")}>
                <InfoCircleFilled className={style.ErrorIcon} />
              </Tooltip>
            </>
          ) : (
            <span>{!isNil(text) && !isNaN(text) ? text : ""}</span>
          )}
        </>
      );
    },
  },
];

const checkError = (data: string, formatMessage: FormatMessageFunc) => {
  return (
    <>
      {checkInvalidFloat(data) && data ? (
        <>
          <span>{data}</span>
          <Tooltip title={formatMessage("pleaseEnterCorrectData")}>
            <InfoCircleFilled className={style.ErrorIcon} />
          </Tooltip>
        </>
      ) : (
        <span>{toFixedNumber(2)(Number(data))}</span>
      )}
    </>
  );
};

const uploadFileCloumnToName = {
  A: "subAccountName",
  B: "transactionType",
  C: "assetType",
  D: "investDate",
  E: "assetCode",
  F: "share",
  G: "transactionPrice",
  H: "investScale",
  I: "dividendPolicy",
  J: "handlingFee",
};

export const transformUploadFile = (data: Record<string, any>[]) => {
  if (isEmpty(data)) {
    return [];
  }
  const newData = flow(
    map((item: Record<string, any>) => {
      const itemKeys = keys(item);
      const res = flow(
        map((key: string) => [
          fastProp(key)(uploadFileCloumnToName),
          fastProp(key)(item),
        ]),
        fromPairs
      )(itemKeys);
      return res;
    })
  )(data);
  return newData;
};

export const exportDataColumn = [
  {
    prop: "subAccountName",
    label: "所在账户",
    wrapText: true,
  },
  {
    prop: "transactionType",
    label: "交易类型",
    wrapText: true,
  },
  {
    prop: "assetType",
    label: "资产类型",
    wrapText: true,
    //   format: FORMAT_TYPES.TWO_FLOAT,
  },
  {
    prop: "investDate",
    label: "日期",
    wrapText: true,
  },
  {
    prop: "assetCode",
    label: "持仓资产code",
    wrapText: true,
  },
  {
    prop: "share",
    label: "资产份额",
    wrapText: true,
  },
  {
    prop: "investScale",
    label: "金额（元）",
    wrapText: true,
  },
];

export const validateAssetInfo = (assetInfo: Record<string, any>[]) => {
  let invalid = true;
  forEach((item: Record<string, any>) => {
    if (!checkType(fastProp("transactionType")(item))(transactionTypes)) {
      invalid = false;
    }
    if (!checkType(fastProp("assetType")(item))(assetTypes)) {
      invalid = false;
    }
    if (checkInvalidDate(fastProp("investDate")(item))) {
      invalid = false;
    }
    if (fastProp("share")(item) && checkInvalidFloat(fastProp("share")(item))) {
      invalid = false;
    }
    if (
      fastProp("investScale")(item) &&
      checkInvalidFloat(fastProp("investScale")(item))
    ) {
      invalid = false;
    }
    if (
      fastProp("dividendPolicy")(item) &&
      !checkType(fastProp("dividendPolicy")(item))(dividendMethod)
    ) {
      invalid = false;
    }
    if (
      fastProp("handlingFee")(item) &&
      checkInvalidFloat(fastProp("handlingFee")(item))
    ) {
      invalid = false;
    }
    if (
      fastProp("transactionPrice")(item) &&
      checkInvalidFloat(fastProp("transactionPrice")(item))
    ) {
      invalid = false;
    }
  })(assetInfo);
  return invalid;
};

const checkInvalidFloat = (num: string | number) =>
  num !== "0" && num !== 0 && !isNil(num) && !Number(num);
// const checkInvalidInt = (num: string) => !intRegex.test(num);
const checkType = (value: string) => (types: string[]) =>
  includes(value)(types);
const checkInvalidDate = (date: string) =>
  !(isNaN(toNumber(date)) && moment(date).isValid());

export const getTurnRecordColumns = (
  formatMessage: FormatMessageFunc,
  fundMap: Record<string, any>,
  stockMap: Record<string, any>
) => [
  {
    title: formatMessage("Account"),
    dataIndex: "accountName",
    align: "left",
  },
  {
    title: formatMessage("transactionType"),
    dataIndex: "type",
    align: "left",
    render: (text: string, row: Record<string, any>) => {
      if (pRege.test(text)) {
        return (
          <span>
            {formatMessage(text)}
            {row?.turnoverSource === "INVEST_PLAN" &&
              `(${formatMessage("fixedInvestment")})`}
          </span>
        );
      }
      return (
        <span>
          {text}
          {row?.turnoverSource === "INVEST_PLAN" &&
            `(${formatMessage("fixedInvestment")})`}
        </span>
      );
    },
  },
  {
    title: formatMessage("assetCategory"),
    dataIndex: "assetType",
    align: "left",
    render: (text: string) => {
      if (pRege.test(text)) {
        return text === "CASH" ? formatMessage("cash") : formatMessage(text);
      }
      return text;
    },
  },
  {
    title: formatMessage("Date"),
    dataIndex: "createDate",
    align: "left",
  },
  {
    title: <span>{formatMessage("assetsName")}</span>,
    dataIndex: "assetCode",
    align: "left",
    width: 250,
    render: (code: string) => {
      const id = prop(`${code}.fundId`)(fundMap);
      const name = prop(`${code}.name`)(fundMap);
      const stockName = prop(`${code}.name`)(stockMap);
      if (!code) return "";
      if (id) {
        return (
          <div>
            <ToFundDetailPage
              name={name}
              id={id}
              className={cn(size(name) > 8 && style.FundName)}
            />
            <ToFundDetailPage name={`(${code})`} />
          </div>
        );
      }
      return stockName ? (
        <div>
          {stockName}({code})
        </div>
      ) : (
        formatMessage("cash")
      );
    },
  },
  {
    title: formatMessage("assetsShare"),
    dataIndex: "share",
    align: "left",
    render: (text: number) => (text ? toFixedNumber(2)(text) : ""),
  },
  {
    title: (
      <span>
        {formatMessage("amountOfMoney")}（{formatMessage("YUAN")}）
      </span>
    ),
    dataIndex: "scale",
    align: "left",
    render: (text: number) => (isNumber(text) ? toFixedNumber(2)(text) : ""),
  },
  {
    title: formatMessage("price"),
    dataIndex: "transactionPrice",
    align: "left",
    render: (text: number) => (isNumber(text) ? toFixedNumber(2)(text) : ""),
  },
  {
    title: <span>{formatMessage("dividendMethod")}</span>,
    dataIndex: "dividendPolicy",
    align: "left",
    render: (text: string) => formatMessage(text),
  },
  {
    title: <span>{formatMessage("serviceCharge")}</span>,
    dataIndex: "handlingFee",
    align: "left",
  },
  // {
  //   title: <span>{formatMessage("feeRatio")}</span>,
  //   dataIndex: "feeRatio",
  //   align: "left",
  //   render: (text: number, row: Record<string, any>) =>
  //     isNumber(text) ? (
  //       <span>
  //         {row?.assetType === "STOCK"
  //           ? formatPercentage(text, { symbol: "‰", scale: 1000 })
  //           : formatPercentage(text)}
  //       </span>
  //     ) : (
  //       ""
  //     ),
  // },
];

export const headerSearchOptionObj: Record<string, SearchOptionInterface> = {
  funds: {
    id: "funds",
    message: "funds",
    placeholderMessage: "fundsPlaceholderMessage",
    selector: "funds",
  },
  stocks: {
    id: "stocks",
    message: "stocks",
    placeholderMessage: "stocksPlaceholderMessage",
    selector: "allStocks",
  },
  fundManagers: {
    id: "fundManagers",
    message: "fundManagers",
    placeholderMessage: "fundManagersPlaceholderMessage",
    selector: "managerName",
  },
  fundCompanies: {
    id: "fundCompanies",
    message: "fundCompanies",
    placeholderMessage: "fundCompaniesPlaceholderMessage",
    selector: "companyName",
  },
};

export const validateEntryAssetInfo = (assetInfo: Record<string, any>[]) => {
  let invalid = true;
  forEach((item: Record<string, any>) => {
    const {
      transactionType,
      assetType,
      investDate,
      investScale,
      assetCode,
      share,
      subAccountName,
      transactionPrice,
      handlingFee,
    } = item || {};

    if (!subAccountName) {
      invalid = false;
    }

    if (!checkType(assetType)(assetTypes)) {
      invalid = false;
    }

    if (!checkType(transactionType)(transactionTypes)) {
      invalid = false;
    }

    if (assetType === "CASH") {
      if (!investDate || checkInvalidDate(investDate)) {
        invalid = false;
      }
      if (!investScale || checkInvalidFloat(investScale)) {
        invalid = false;
      }
    }

    if (assetType === "OPEN_FUND") {
      if (!investDate || checkInvalidDate(investDate)) {
        invalid = false;
      }
      if (!assetCode) {
        invalid = false;
      }
      if (
        handlingFee &&
        (!isNumber(handlingFee) || checkInvalidFloat(handlingFee))
      ) {
        invalid = false;
      }
      if (!share || checkInvalidFloat(share)) {
        invalid = false;
      }
    }

    if (assetType === "STOCK") {
      if (!investDate || checkInvalidDate(investDate)) {
        invalid = false;
      }
      if (!assetCode) {
        invalid = false;
      }
      if (!share || checkInvalidFloat(share)) {
        invalid = false;
      }
      if (!transactionPrice || checkInvalidFloat(transactionPrice)) {
        invalid = false;
      }
    }
  })(assetInfo);
  return invalid;
};

export const checkAssetInfo = (
  type: boolean,
  assetInfo: Record<string, any>[]
) => {
  return type
    ? validateAssetInfo(assetInfo)
    : validateEntryAssetInfo(assetInfo);
};

export const formatData = (assetInfo: subAccountNameProp[]) => {
  const data = map((item: subAccountNameProp) => {
    return {
      ...item,
      assetCode: item?.assetCode?.replace(/\s*/g, ""),
      transactionType:
        transactionTypeMap[item?.transactionType] ?? item?.transactionType,
      assetType: assetCategoryMap[item?.assetType] ?? item?.assetType,
      dividendPolicy:
        dividendMethodMap[item?.dividendPolicy || ""] ?? item?.dividendPolicy,
      handlingFee:
        item?.assetType === CASH || assetCategoryMap[item?.assetType] === CASH
          ? undefined
          : item?.handlingFee,
      investDate: dayjs(item?.investDate).format("YYYY-MM-DD"),
    };
  })(assetInfo);
  return data;
};
