import {
  companyDailyReturnResponse,
  companyInfoResponse,
  companyManageFundResponse,
  companyScaleTrendResponse,
  representativeFundResponse,
  scaleRankTrendItem,
} from "@/model/fundCompanyDetail";
import { RootState } from "@/store";
import { fastProp } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { prop } from "lodash/fp";

export const baseFundCompanyInfoSelector = createSelector(
  (state: RootState) => state.fundCompanyDetail,
  (_: RootState, id: string) => id,
  (fundCompanyDetail, id) => fastProp(id)(fundCompanyDetail)
);

export const companyInfoSelector = createSelector(
  baseFundCompanyInfoSelector,
  (fundCompanyDetail): companyInfoResponse =>
    fastProp("companyInfo")(fundCompanyDetail)
);

export const companyDailyReturnSelector = createSelector(
  baseFundCompanyInfoSelector,
  (_: RootState, id: string, fundType: string) => fundType,
  (fundCompanyDetail, fundType): companyDailyReturnResponse =>
    prop(["companyDailyReturn", fundType])(fundCompanyDetail)
);

export const companyScaleTrendSelector = createSelector(
  baseFundCompanyInfoSelector,
  (fundCompanyDetail): companyScaleTrendResponse =>
    fastProp("companyScaleTrend")(fundCompanyDetail)
);

export const companyScaleRankSelector = createSelector(
  baseFundCompanyInfoSelector,
  (fundCompanyDetail): scaleRankTrendItem[] =>
    fastProp("companyScaleRank")(fundCompanyDetail)
);

export const companyManageFundSelector = createSelector(
  baseFundCompanyInfoSelector,
  (_: RootState, id: string, fundType: string) => fundType,
  (_: RootState, id: string, fundType: string, section: string) => section,
  (fundCompanyDetail, fundType, section): companyManageFundResponse[] =>
    prop(["companyManageFund", fundType, section])(fundCompanyDetail)
);

export const representativeFundSelector = createSelector(
  baseFundCompanyInfoSelector,
  (fundCompanyDetail): representativeFundResponse[] =>
    fastProp("representativeFund")(fundCompanyDetail)
);
