import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import React, { useEffect } from "react";
import riskAnalysisIcon from "@/assets/portfolioAnalysisIcons/riskAnalysis.png";
import { useFormatMessage } from "@/util/formatMessage";
import CorrelationNalysis from "./components/correlationNalysis";
import ValueAtRisk from "./components/valueAtRisk";
import { PortfolioAnalysisProps } from "..";
import RiskDismantle from "./components/riskDismantle";
import { fetchPortfolioScenarioList } from "@/store/scenarioList";
import { useAppDispatch } from "@/hooks/redux";

const RiskAnalysis = (props: PortfolioAnalysisProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchPortfolioScenarioList());
  }, [dispatch]);

  const tip: CardLayoutTipType = [
    [
      formatMessage("correlationNalysis"),
      formatMessage("correlationNalysisTip"),
    ],
    [formatMessage("valueAtRisk"), formatMessage("valueAtRiskTip")],
    [formatMessage("riskDismantle"), formatMessage("riskDismantleTip")],
  ];
  return (
    <>
      <CardLayout
        icon={riskAnalysisIcon}
        title={formatMessage("riskAnalysis")}
        tip={tip}
      >
        <CorrelationNalysis {...props} />
        <ValueAtRisk {...props} />
        <RiskDismantle {...props} />
      </CardLayout>
    </>
  );
};

export default React.memo(RiskAnalysis);
