import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { compact, find, flow, forEach, last, toArray } from "lodash/fp";
import { useCreation } from "ahooks";

import { useFormatMessage } from "@/util/formatMessage";
import { DistributionChangeChart } from "@/base-components/charts";
import {
  AssetAllocationDistributionResponse,
  FundTypeDissect,
} from "@/model/aum";
import { useRequest } from "@/hooks/request";
import { getPortfolioFundDissectList } from "@/api/aum";
import DisclosureDateCascader from "@/base-components/disclosureDateCascader";
import { SeriesOption } from "@/base-components/charts/distributionChangeChart/type";
import { mapIndexed } from "@/util/opt";
import AssertTypePieChart from "@/base-components/charts/assertTypePieChart";
import { PieChartData } from "@/base-components/charts/assertTypePieChart/type";

import style from "./index.module.less";
import SubTitle from "../../components/subTitle";
import { colors } from "@/base-components/charts/helper/colors";

export default React.memo<{ id: string; assetPortfolioId: string }>(
  ({ assetPortfolioId }) => {
    const formatMessage = useFormatMessage();
    const {
      loading,
      data: response,
      request,
    } = useRequest<string, AssetAllocationDistributionResponse>(
      getPortfolioFundDissectList
    );
    const [date, setDate] = useState<string>("");

    const data = useCreation(
      () => response?.fundTypeDissects,
      [response?.fundTypeDissects]
    );

    useEffect(() => {
      if (assetPortfolioId) request(assetPortfolioId);
    }, [assetPortfolioId, request]);

    const { series, dates } = useCreation(() => {
      const tmpObj: Record<string, any> = {};
      const dates: string[] = [];
      let colorIndex = 0;
      forEach((item: FundTypeDissect) => {
        dates.push(item.date);
        mapIndexed((val: number, key: string) => {
          if (!(key === "date" || key === "portfolioId")) {
            if (!tmpObj[key])
              tmpObj[key] = {
                name: formatMessage(key),
                data: [[item.date, val]],
                color: colors[colorIndex++],
              };
            else tmpObj[key].data.push([item.date, val]);
          }
        })(item);
      })(data);
      const series: SeriesOption[] = toArray(tmpObj);
      return { series, dates };
    }, [data, formatMessage]);

    useEffect(() => setDate(last(dates) || ""), [dates]);

    const pieChartData: PieChartData = useCreation(() => {
      const targetData = find((v: FundTypeDissect) => v.date === date)(data);
      let colorIndex = 0;
      return flow(
        mapIndexed((value: number, key: string) => {
          return (
            !(key === "date" || key === "portfolioId") && {
              name: formatMessage(key),
              value,
              color: colors[colorIndex++],
            }
          );
        }),
        compact
      )(targetData);
    }, [data, date, formatMessage]);

    const handleChartClick = (event: any) => {
      if (event?.data) setDate(event.data?.[0]);
    };

    return (
      <div>
        <SubTitle title={formatMessage("AssetAllocationDistribution")} />
        <Spin spinning={loading}>
          <div className={style.ChartContainer}>
            <div className={style.Chart}>
              <DistributionChangeChart
                type="bar"
                series={series}
                dates={dates || []}
                height={400}
                showDataZoom={false}
                selectedDate={date}
                onEvents={{ click: handleChartClick }}
              />
            </div>
            <div className={style.Content}>
              <div className={style.DisclosureDateWrap}>
                <DisclosureDateCascader
                  dates={dates || []}
                  value={date}
                  onChange={setDate}
                />
              </div>
              <div className={style.PieChartWrapper}>
                <AssertTypePieChart
                  pieChartData={pieChartData}
                  title={formatMessage("AssetAllocationDistribution")}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
);
