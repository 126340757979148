import { Button, Card, Space } from "antd";
import React, { useContext, useEffect, useMemo } from "react";
import defaultCompetitipnImg from "@/assets/defaultCompetition.png";
import { useFormatMessage } from "@/util/formatMessage";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchCompetitionDetail } from "@/store/competition";
import { fastProp } from "@/util/opt";
import { competitionDetailSelector } from "../selectors";
import style from "../index.module.less";
import StatusTag from "../../components/competitionStatusTag";
import { CompetitionStatus } from "@/model/competition";

const CompetitionBasicInfo = ({
  competitionId,
  type,
}: {
  competitionId: string;
  type: string;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const { goToWantCompetePage } = useContext(PlatformNavigationContext);
  const competitionDetail = useAppSelector(competitionDetailSelector);
  useEffect(() => {
    dispatch(fetchCompetitionDetail(competitionId));
  }, [dispatch, competitionId]);
  const disabled = useMemo(() => {
    return (
      !fastProp("validate")(competitionDetail) ||
      fastProp("status")(competitionDetail) !== CompetitionStatus.ENROLLING ||
      fastProp("inCompetition")(competitionDetail)
    );
  }, [competitionDetail]);
  return (
    <Card size="small">
      <div className={style.BasicInfo}>
        <div className={style.BasicInfoLeft}>
          {/* <div className={style.ImgPart}> */}
          <img
            src={
              fastProp("bannerImage")(competitionDetail) ||
              defaultCompetitipnImg
            }
            className={style.CompetitipnImg}
          />
          {/* </div> */}
        </div>
        <div className={style.BasicInfoRight}>
          <div className={style.CardRight}>
            <Space direction="vertical" className={style.FullWidth}>
              <Space>
                <h2>{fastProp("name")(competitionDetail)}</h2>
                <StatusTag status={fastProp("status")(competitionDetail)} />
              </Space>
              <div className={style.Incontent}>
                <span className={style.IntroductionName}>
                  {formatMessage("competitionIntroduction")}:
                </span>
                <span className={style.Introduction}>
                  <p>{fastProp("summary")(competitionDetail)}</p>
                </span>
              </div>
              <Space>
                <span>{formatMessage("competitionDate")}:</span>
                <span>
                  {fastProp("startDate")(competitionDetail)} ———{" "}
                  {fastProp("endDate")(competitionDetail)}
                </span>
              </Space>
              <div className={style.Incontent}>
                <span className={style.IntroductionName}>
                  {formatMessage("competitionRules")}:
                </span>
                <span className={style.Introduction}>
                  <p>{fastProp("regulation")(competitionDetail)}</p>
                </span>
              </div>
              {fastProp("hasPermission")(competitionDetail) && (
                <Button
                  type="primary"
                  onClick={() =>
                    goToWantCompetePage({
                      competitionId: competitionId,
                      type: type,
                    })
                  }
                  disabled={disabled}
                >
                  {formatMessage("wantCompete")}
                </Button>
              )}
            </Space>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default React.memo(CompetitionBasicInfo);
