/**
 * 下行风险/下行波动率
 */

import { filter, flow, isNumber, size, sumBy } from "lodash/fp";
import tradingDateCount from "@/constant/tradingDateCount";
import { div, minus, pow, sqrt, toAverage } from "../math";
import { formatArrayNilToZero } from "../numberFormatter";

// 非年化的下行风险
export const getDownwardVolatiltyWithoutAnnual = (dailyReturns: number[]) => {
  if (size(dailyReturns) === 0) return 0;
  const minusReturns = filter<number>((returns) => returns < 0)(
    formatArrayNilToZero(dailyReturns)
  );
  const average = toAverage(minusReturns);
  const sd = flow(
    filter(isNumber),
    sumBy(flow(minus(average), pow(2))),
    div(size(dailyReturns) - 1),
    sqrt
  )(minusReturns);
  return Math.abs(sd) < Number.EPSILON ? 0 : sd;
};

// 年化下行风险
export default (dailyReturns: number[]) => {
  const standardDeviation = getDownwardVolatiltyWithoutAnnual(dailyReturns);
  return standardDeviation * Math.sqrt(tradingDateCount);
};
