import React, { useEffect, useMemo } from "react";
import { Card, Space } from "antd";
import fundStyle from "../../../fundDetail/index.module.less";
import ManagerInfo from "./components/managerInfo";
import ManagerAbilityRadar from "./components/managerAbilityRadar";
import ManagerDescription from "./components/managerDescription";
import AddCompareIcon from "./components/addCompareIcon";
import style from "./index.module.less";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { currentManagerInfoSelector } from "../selectors";
import { RootState } from "@/store";
import { BaseFundManagerDetailInfoProps } from "../interface";
import LoadingComponent from "@/components/LoadingComponent";
import {
  getFundManagerAvatar,
  getFundManagerDetailBasicInfo,
} from "@/store/fundManagerDetailSlice";
import { useMemoizedFn } from "ahooks";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { fastProp } from "@/util/opt";
import { map, prop } from "lodash/fp";
import { AddFundManagerOptional } from "@/components/addOptional";

export default React.memo<
  BaseFundManagerDetailInfoProps & {
    addSelectIds: (id: string) => any;
    validateIsChecked: (id: string) => boolean;
  }
>(({ id, addSelectIds, validateIsChecked }) => {
  const managerInfo = useAppSelector((state: RootState) =>
    currentManagerInfoSelector(state, id)
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (managerInfo?.headPortraitUrl) {
      dispatch(
        getFundManagerAvatar({
          id,
          url: managerInfo?.headPortraitUrl,
        })
      );
    }
  }, [dispatch, id, managerInfo?.headPortraitUrl]);
  const onClick = useMemoizedFn(() => addSelectIds(id));
  const sectorCategoryMap = useAppSelector(sectorCategoriesMapSelector);
  const tags = useMemo(
    () =>
      map<string, string>((industry) =>
        prop(`${industry}.name`)(sectorCategoryMap)
      )(fastProp("industryTop3")(managerInfo)),
    [managerInfo, sectorCategoryMap]
  );

  return (
    <LoadingComponent actions={getFundManagerDetailBasicInfo}>
      <Card
        className={fundStyle.FundBasicInfoCard}
        size="small"
        bordered={false}
      >
        <div className={style.HeaderContainer}>
          <ManagerInfo
            name={managerInfo?.name}
            avatar={managerInfo?.managerAvatar}
            rating={managerInfo?.score}
            tags={tags}
            category={managerInfo?.windFirstCategory}
          />
          <ManagerAbilityRadar id={id} />
          <ManagerDescription
            className={style.Description}
            company={managerInfo?.company}
            majorFund={managerInfo?.majorFund}
            manageScale={managerInfo?.manageScale}
            annualizedReturn={managerInfo?.annualizedReturn}
            historyManageScale={managerInfo?.historyManageScale}
            workAge={managerInfo?.workAge}
            manageAmount={managerInfo?.manageAmount}
            historyManageAmount={managerInfo?.historyManageAmount}
            qualification={managerInfo?.qualification}
            majorFundName={managerInfo?.majorFundName}
            companyId={managerInfo?.companyId}
          />
          <Space align="start" direction="vertical">
            <AddCompareIcon checked={validateIsChecked(id)} onClick={onClick} />
            <AddFundManagerOptional id={id} shape="icon" size="middle" />
          </Space>
        </div>
      </Card>
    </LoadingComponent>
  );
});
