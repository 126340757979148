import { map } from "lodash/fp";
import { formatPercentage } from "@/util/numberFormatter";
import { DistributionChartProps, DistributionChartData } from "./type";

export const getOption = ({
  showLegend,
  saveImgEnable,
  datas,
  title,
}: Omit<DistributionChartProps, "height">) => ({
  toolbox: {
    feature: {
      saveAsImage: saveImgEnable ? {} : null,
    },
    right: 20,
  },
  tooltip: {
    trigger: "item",
    valueFormatter: (value: number) => formatPercentage(value),
    backgroundColor: "rgba(255,  255, 255, 0.7)",
  },
  legend: {
    top: saveImgEnable ? 30 : 0,
    show: showLegend,
    type: "scroll",
    orient: "vertical",
    right: 10,
    bottom: 10,
  },
  series: [
    {
      name: title || "",
      type: "pie",
      // colorBy: "series",
      radius: ["70%", "95%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: false,
          fontSize: "40",
          fontWeight: "bold",
        },
      },
      select: {
        disabled: true,
      },
      labelLine: {
        show: false,
      },
      data: map(({ name, value, color }: DistributionChartData) => ({
        name,
        value,
        itemStyle: { ...(color ? { color } : {}) },
      }))(datas),
    },
  ],
});

function limitMinValue(value: number): number {
  if (value <= 0.02) {
    return 0.02 - (0.02 - value) * 0.01;
  }
  return value;
}

export const getRectTreeOption = ({
  datas,
}: Omit<DistributionChartProps, "height">) => {
  const resultData = map((data: DistributionChartData) => ({
    ...data,
    realValue: data?.value,
    value: limitMinValue(data?.value),
  }))(datas);
  return {
    legend: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: (params: any) =>
        `${params?.name} ${formatPercentage(params?.data?.realValue)}`,
      backgroundColor: "rgba(255,  255, 255, 0.7)",
    },
    animationDuration: 0,
    series: {
      type: "treemap",
      roam: false,
      nodeClick: false,
      label: {
        formatter: (params: any) =>
          `${params?.name}\n${formatPercentage(params?.data?.realValue)}`,
        lineHeight: 18,
        color: "#000",
      },
      animation: false,
      breadcrumb: { show: false },
      width: "100%",
      height: "100%",
      emphasis: {
        disabled: false,
        focus: "series",
      },
      data: [{ name: "all", children: resultData }],
      // visualMax: 0.3,
      itemStyle: {
        borderWidth: 0.5,
      },
      levels: [
        {
          color: ["#0171ff"],
        },
        {
          colorAlpha: [0.3, 1],
        },
      ],
    },
  };
};
