import BaseTable, { BaseTableProps, ColumnShape } from "react-base-table";
import { formatPercentage, toFixedNumber } from "@/util/numberFormatter";
import "react-base-table/styles.css";
import { forwardRef, useImperativeHandle, useRef } from "react";

interface VirtualTableProps<T> extends BaseTableProps<T> {
  test?: string;
}

type NumberType = "float2" | "float4" | "percent2" | "percent4";
function isCellType(type: string): type is NumberType {
  return (
    type === "float2" ||
    type === "float4" ||
    type === "percent2" ||
    type === "percent4"
  );
}
type NumberTypeFormat = (value: number) => string | JSX.Element;
const renderTypesMap: Record<NumberType, NumberTypeFormat> = {
  float2: toFixedNumber(2),
  float4: toFixedNumber(4),
  percent2: formatPercentage,
  percent4: (v: number) => formatPercentage(v, { digit: 4 }),
};

function TableCell<T>(props: {
  className: string;
  isScrolling?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellData: any;
  columns: ColumnShape<T>[];
  column: ColumnShape<T>;
  columnIndex: number;
  rowData: T;
  rowIndex: number;
  container: BaseTable<T>;
}) {
  if (props.column?.cellRenderer) {
    if (typeof props.column?.cellRenderer === "function") {
      return props.column?.cellRenderer(props);
    }
    return props.column?.cellRenderer;
  }
  if (props.column?.renderType && isCellType(props.column?.renderType)) {
    return renderTypesMap[props?.column?.renderType as NumberType](
      props.cellData
    );
  }
  if (props.column?.render) {
    return props.column?.render(props.cellData, props.rowData, props.rowIndex);
  }
  return props.cellData ?? "";
}

const VirtualTable = function <T>() {
  return forwardRef<
    {
      getBaseTable: () => BaseTable<T>;
    },
    VirtualTableProps<T>
  >((props, ref) => {
    const baseTable = useRef<any>(null);
    useImperativeHandle(ref, () => baseTable.current);
    return (
      <BaseTable
        {...props}
        ref={baseTable}
        components={{
          TableCell,
        }}
      />
    );
  });
};
export default VirtualTable;
