import { useFormatMessage } from "@/util/formatMessage";
import { Tabs } from "antd";
import React, { useEffect, useRef } from "react";
import HeaderInfo from "./headerInfo";
import BasicInfo from "./basicInfo";
import style from "./index.module.less";
import { useAppDispatch } from "@/hooks/redux";
import {
  getFundManagerDetailBasicInfo,
  getFundManagerDetailDailyReturn,
} from "@/store/fundManagerDetailSlice";
import { BaseFundManagerDetailInfoProps } from "./interface";
import ManageFundInfo from "./manageFundInfo";
import AlreadySelectAnchor, {
  AlreadyRef,
} from "@/components/alreadySelectAnchor";
import { FUND_MANAGER } from "@/components/alreadySelectAnchor/constant";
import { useAddCompareIds } from "@/components/alreadySelectAnchor/hooks";
import PositionInfo from "./positionInfo";

const { TabPane } = Tabs;
export default React.memo<BaseFundManagerDetailInfoProps>(({ id }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getFundManagerDetailBasicInfo(id));
    dispatch(getFundManagerDetailDailyReturn(id));
  }, [dispatch, id]);
  const alreadyRef = useRef<AlreadyRef>(null);
  const { comparedSelectIds, onChangeIds, addSelectIds, validateIsChecked } =
    useAddCompareIds(FUND_MANAGER);
  return (
    <div
      onClick={alreadyRef?.current?.onCloseModal}
      onScroll={alreadyRef?.current?.onCloseModal}
    >
      <HeaderInfo
        id={id}
        addSelectIds={addSelectIds}
        validateIsChecked={validateIsChecked}
      />
      <Tabs className={style.Tabs} defaultActiveKey="basicInfo">
        <TabPane tab={formatMessage("basicInfo")} key="basicInfo">
          <BasicInfo id={id} />
        </TabPane>
        <TabPane tab={formatMessage("manageFundNumTag")} key="manageFundInfo">
          <ManageFundInfo id={id} />
        </TabPane>
        <TabPane tab={formatMessage("positionInfo")} key="positionInfo">
          <PositionInfo id={id} />
        </TabPane>
      </Tabs>
      <AlreadySelectAnchor
        ref={alreadyRef}
        type={FUND_MANAGER}
        selectIds={comparedSelectIds}
        onChangeIds={onChangeIds}
      />
    </div>
  );
});
