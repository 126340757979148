import { last } from "lodash/fp";
import { fastNth, forEachIndexed } from "@/util/opt";
/**
 * 链接算法
 */
const getCumulativeAttributionByPrevious = (
  previousCumulativeAttribution: number,
  dailyReturn: number,
  previousQ: number,
  deltaQ: number,
  q: number
) => (previousCumulativeAttribution * previousQ + dailyReturn * deltaQ) / q;

const getQ = (totalReturn: number, benchmarkReturn: number) => {
  if (totalReturn - benchmarkReturn === 0) {
    return 1;
  }
  return (
    (Math.log(1 + totalReturn) - Math.log(1 + benchmarkReturn)) /
    (totalReturn - benchmarkReturn)
  );
};

export default (
    dailyPortfolioReturns: number[],
    dailyPortfolioBenchmarkReturns: number[],
    cumulativePortfolioReturns: number[],
    cumulativePortfolioBenchmarkReturns: number[]
  ) =>
  (dailyActiveReturns: number[]) => {
    let previousQ = 0;
    let cumulativeAttribution = 0;
    const cumulativeAttributions: number[] = [];
    const q = getQ(
      last(cumulativePortfolioReturns) || 0,
      last(cumulativePortfolioBenchmarkReturns) || 0
    );

    forEachIndexed((dailyReturn: number, index: number) => {
      const deltaQ = getQ(
        fastNth(index)(dailyPortfolioReturns),
        dailyPortfolioBenchmarkReturns
          ? fastNth(index)(dailyPortfolioBenchmarkReturns)
          : 0
      );

      cumulativeAttribution = getCumulativeAttributionByPrevious(
        cumulativeAttribution,
        dailyReturn,
        previousQ,
        deltaQ,
        q
      );
      cumulativeAttributions.push(cumulativeAttribution);
      previousQ = q;
    })(dailyActiveReturns);
    return cumulativeAttributions;
  };
