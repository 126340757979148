import { useFormatMessage } from "@/util/formatMessage";
import {
  formatPercentage,
  roundTo,
  toFixedNumber,
} from "@/util/numberFormatter";
import { fastProp, size } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import {
  useFundListTable,
  SliderAndInput,
  SummaryNode,
  FundListTableProps,
  validateWeightMaxError,
} from "@/components/portfolioCompoents/fundListTable";
import { Button, Table, TableProps } from "antd";
import { identity, isEmpty, sumBy } from "lodash/fp";
import NumberInput from "@/components/numberInput";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import React, { useEffect, useMemo } from "react";
import style from "@/components/portfolioCompoents/index.module.less";
import listStyle from "./index.module.less";

type TablePropsType = TableProps<any>;

const FundListTable = ({
  fundList,
  onChange = identity,
  onError = identity,
  setEqualWeight = identity,
  scale,
}: FundListTableProps & {
  scale: number;
  setEqualWeight: () => any;
}): JSX.Element => {
  const positionWeight = useMemo(
    () => roundTo(4)(sumBy("weight")(fundList)),
    [fundList]
  );
  useEffect(() => {
    onError(validateWeightMaxError(positionWeight) || positionWeight === 0);
  }, [positionWeight, onError]);
  const dataSource = useFundListTable(fundList);

  const scroll = useMemo(
    () => (size(dataSource) > 8 ? { y: 360, x: "max-content" } : undefined),
    [dataSource]
  );
  const deleteFund = useMemoizedFn((v: string) => onChange(v)(null));
  const formatMessage = useFormatMessage();
  const columns = useMemo<TablePropsType["columns"]>(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: formatMessage("fundName"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
      },
      {
        key: "policyType",
        dataIndex: "firstInvestType",
        title: formatMessage("policyType"),
      },
      {
        key: "netValue",
        dataIndex: "netValue",
        title: formatMessage("latestNetUnitValue"),
        align: "right",
        render: (netValue: number, record: any) => {
          return (
            <div>
              {toFixedNumber(4)(netValue)}{" "}
              <span className={listStyle.NetValueDateTip}>
                （&nbsp;{fastProp("tradingDay")(record)}&nbsp;）
              </span>
            </div>
          );
        },
      },
      {
        key: "positionScale",
        dataIndex: "positionScale",
        title: formatMessage("positionScale"),
        align: "right",
        render: (_, record) =>
          toFixedNumber(2)((fastProp("currentWeight")(record) || 0) * scale),
      },
      {
        key: "currentWeight",
        dataIndex: "currentWeight",
        title: formatMessage("currentWeight"),
        align: "right",
        render: (value: number) => formatPercentage(value || 0),
      },
      {
        key: "weight",
        dataIndex: "weight",
        title: (
          <div>
            {formatMessage("positionWeight")}
            <Button
              size="small"
              onClick={setEqualWeight}
              className={listStyle.EqualWeightButton}
            >
              {formatMessage("equalWeight")}
            </Button>
          </div>
        ),
        render: (_, record) => (
          <SliderAndInput
            disabled={!fastProp("editable")(record)}
            value={record.weight}
            onChange={onChange(record.fundId, "weight")}
          />
        ),
      },
      {
        key: "forecastScale",
        dataIndex: "forecastScale",
        title: formatMessage("forecastScale"),
        align: "right",
        render: (_, record) =>
          toFixedNumber(2)(scale * fastProp("weight")(record)),
      },
      {
        key: "purchFeeRatio",
        dataIndex: "purchFeeRatio",
        title: formatMessage("purchFeeRatio"),
        render: (_, record) => (
          <NumberInput
            onChange={onChange(record.fundId, "purchFeeRatio")}
            min={0}
            max={1}
            precision={2}
            className={style.RatioNumberInput}
            size="small"
            disabled={!fastProp("editable")(record)}
            value={record.purchFeeRatio}
            type={"PERCENTAGE"}
          />
        ),
      },
      {
        key: "redeemFeeRatio",
        dataIndex: "redeemFeeRatio",
        title: formatMessage("redeemFeeRatio"),
        render: (_, record) => (
          <NumberInput
            className={style.RatioNumberInput}
            precision={2}
            size="small"
            min={0}
            max={1}
            disabled={!fastProp("editable")(record)}
            onChange={onChange(record.fundId, "redeemFeeRatio")}
            value={record.redeemFeeRatio}
            type={"PERCENTAGE"}
          />
        ),
      },
      {
        key: "operator",
        dataIndex: "operator",
        title: formatMessage("operator"),
        render: (_, record) =>
          fastProp("removable")(record) && (
            <span
              onClick={() => deleteFund(record.fundId)}
              className={style.Operator}
            >
              {formatMessage("delete")}
            </span>
          ),
      },
    ],
    [formatMessage, setEqualWeight, scale, onChange, deleteFund]
  );
  return (
    <Table
      dataSource={dataSource}
      pagination={false}
      columns={columns as any[]}
      scroll={scroll}
      rowKey="fundId"
      summary={() =>
        !isEmpty(dataSource) && (
          <SummaryNode
            positionWeight={positionWeight}
            fundListSize={size(dataSource)}
          />
        )
      }
    />
  );
};

export default React.memo(FundListTable);
