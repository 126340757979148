import React, { useCallback, useMemo } from "react";
import { Radio, RadioGroupProps } from "antd";
import { flow, isEmpty, map, pick, toArray } from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import {
  FROM_CREATION,
  FROM_THIS_YEAR,
  RECENT_HALF_YEAR,
  RECENT_MONTH,
  RECENT_THREE_MONTH,
  RECENT_THREE_YEAR,
  RECENT_YEAR,
  COMMON_TIME,
  yieldStatisticRange,
} from "@/constant/statisticRange";

const defaultOptions = flow(
  pick([
    COMMON_TIME,
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    FROM_THIS_YEAR,
    RECENT_YEAR,
    RECENT_THREE_YEAR,
    FROM_CREATION,
  ]),
  toArray
)(yieldStatisticRange);

export default React.memo<
  Omit<RadioGroupProps, "onChange"> & {
    onChange: (value: string) => any;
  }
>(({ onChange, value, options, ...rest }) => {
  const formatMessage = useFormatMessage();
  const updateHandler = useCallback(
    (e) => onChange && onChange(e.target.value),
    [onChange]
  );
  const handleOptions = useMemo(
    () => (isEmpty(options) ? defaultOptions : options),
    [options]
  );
  return (
    <Radio.Group value={value} onChange={updateHandler} {...rest}>
      {map(({ id, message }) => (
        <Radio.Button key={id} value={id}>
          {formatMessage(message)}
        </Radio.Button>
      ))(handleOptions)}
    </Radio.Group>
  );
});
