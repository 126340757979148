import React, { useMemo } from "react";
import { concat, flow, map, omit, prop, zip } from "lodash/fp";
import { BarSeriesOption, EChartsOption, LineSeriesOption } from "echarts";

import { useFormatMessage } from "@/util/formatMessage";
import { TurnoverImprovementAbilityBody } from "@/model/fundDetail";
import { useAppSelector } from "@/hooks/redux";

import style from "../index.module.less";
import SimilarRanking from "../../../similarRanking";
import BaseEcharts from "../../../echarts/baseEcharts";
import SecondLevelName from "../../../secondLevelName";
import { formatPercentage } from "@/util/numberFormatter";
import { toAverage } from "@/util/math";
import {
  formatNilMessage,
  LEVEL_MESSAGE_KEY_MAP_4,
  LEVEL_MESSAGE_KEY_MAP_5,
  LEVEL_MESSAGE_KEY_MAP_6,
} from "@/components/fundInfoExport/constants";
import { fastProp } from "@/util/opt";

type SerieDataType = {
  name: string;
  data: [string, number][];
};
export default React.memo(({ fundId }: { fundId: string }) => {
  const formatMessage = useFormatMessage();

  const stockSelectionAbility: TurnoverImprovementAbilityBody =
    useAppSelector(prop(`fundDetail.${fundId}.turnoverImprovementAbility`)) ||
    {};

  const {
    rank,
    sameTypeCount,
    abilityRating,
    tradeAbility,
    extendNewStocksAbility,
  } = stockSelectionAbility;

  const { turnoverAbility, dates, extraReturn, extraReturnSameAvg } =
    tradeAbility || {};

  const tradeAbilitySerieData = useMemo<SerieDataType[]>(
    () => [
      {
        name: formatMessage("theFund"),
        data: concat(zip(dates)(extraReturn))([
          [formatMessage("threeYearsAvg"), toAverage(extraReturn)],
        ]) as [string, number][],
      },
      {
        name: formatMessage("sameTypeFundAvgValue"),
        data: concat(zip(dates)(extraReturnSameAvg))([
          [formatMessage("threeYearsAvg"), toAverage(extraReturnSameAvg)],
        ]) as [string, number][],
      },
    ],
    [dates, extraReturn, extraReturnSameAvg, formatMessage]
  );

  const {
    desireGrade,
    newStockQuality,
    newStockRatio,
    newStockReturnOfMarket,
    newStockTrend,
  } = extendNewStocksAbility || {};

  const {
    dates: newStockTrendDates,
    newStockReturn,
    newStockReturnAvg,
    newStockRatio: newStockRatioTrend,
    newStockRatioAvg: newStockRatioTrendAvg,
  } = newStockTrend || {};

  const extendNewStocksAbilityBarSerieData = useMemo<SerieDataType[]>(
    () => [
      {
        name: `${formatMessage("theFund")}${formatMessage(
          "proceedsFromNewShares"
        )}`,
        data: concat(zip(newStockTrendDates)(newStockReturn))([
          [formatMessage("threeYearsAvg"), toAverage(newStockReturn)],
        ]) as [string, number][],
      },
      {
        name: `${formatMessage("sameTypeFundAvgValue")}${formatMessage(
          "proceedsFromNewShares"
        )}`,
        data: concat(zip(newStockTrendDates)(newStockReturnAvg))([
          [formatMessage("threeYearsAvg"), toAverage(newStockReturnAvg)],
        ]) as [string, number][],
      },
    ],
    [formatMessage, newStockReturn, newStockReturnAvg, newStockTrendDates]
  );

  const extendNewStocksAbilityLineSerieData = useMemo<SerieDataType[]>(
    () => [
      {
        name: `${formatMessage("theFund")}${formatMessage("expansionRatio")}`,
        data: concat(zip(newStockTrendDates)(newStockRatioTrend))([
          [formatMessage("threeYearsAvg"), toAverage(newStockRatioTrend)],
        ]) as [string, number][],
      },
      {
        name: `${formatMessage("sameTypeFundAvgValue")}${formatMessage(
          "expansionRatio"
        )}`,
        data: concat(zip(newStockTrendDates)(newStockRatioTrendAvg))([
          [formatMessage("threeYearsAvg"), toAverage(newStockRatioTrendAvg)],
        ]) as [string, number][],
      },
    ],
    [
      formatMessage,
      newStockRatioTrend,
      newStockRatioTrendAvg,
      newStockTrendDates,
    ]
  );

  const tradeAbilityOptions = useMemo<EChartsOption>(
    () => ({
      grid: { bottom: 108 },
      legend: { itemGap: 100 },
      xAxis: {
        axisTick: { show: false },
        boundaryGap: true,
        axisLabel: {
          margin: 52,
          hideOverlap: false,
          interval: 0,
        },
      },
      yAxis: { show: false },
      series: map((item: SerieDataType) => ({
        name: item.name,
        type: "bar",
        barWidth: 35,
        barGap: "6%",
        data: flow(
          omit("name"),
          map((v: [string, number]) => ({
            value: v,
            label: {
              position: v[1] >= 0 ? "top" : "bottom",
            },
          }))
        )(item.data),
      }))(tradeAbilitySerieData) as BarSeriesOption[],
    }),
    [tradeAbilitySerieData]
  );

  const extendNewStocksAbilityOptions = useMemo<EChartsOption>(
    () => ({
      grid: { left: 50, right: 50, bottom: 108, top: 20 },
      legend: [
        {
          textStyle: {
            height: 14,
            rich: {
              subText: {
                color: "#ACB6C3",
              },
            },
          },
          formatter: `{name}{subText|(${formatMessage("leftYAxis")})}`,
          left: "auto",
          right: "50%",
          bottom: 0,
          data: map((item: any) => item.name)(
            extendNewStocksAbilityBarSerieData
          ),
        },
        {
          textStyle: {
            height: 10,
            rich: {
              subText: {
                color: "#ACB6C3",
              },
            },
          },
          formatter: `{name}{subText|(${formatMessage("rightYAxis")})}`,
          left: "53%",
          right: 0,
          bottom: 1,
          data: map((item: any) => item.name)(
            extendNewStocksAbilityLineSerieData
          ),
          itemHeight: 4,
        },
      ],
      xAxis: {
        type: "category",
        axisTick: { show: false },
        boundaryGap: true,
        axisLabel: {
          margin: 52,
          hideOverlap: false,
          interval: 0,
        },
      },
      yAxis: [
        {
          type: "value",
          splitLine: { show: false },
          axisLabel: { formatter: (val: number) => formatPercentage(val) },
        },
        {
          type: "value",
          splitLine: { show: false },
          axisLabel: { formatter: (val: number) => formatPercentage(val) },
        },
      ],
      series: [
        ...(map((item: SerieDataType) => ({
          name: item.name,
          type: "bar",
          barWidth: 35,
          data: map((v: SerieDataType["data"][number]) => ({
            value: v,
            label: {
              position: v[1] >= 0 ? "top" : "bottom",
            },
          }))(item.data),
        }))(extendNewStocksAbilityBarSerieData) as BarSeriesOption[]),
        ...map(
          (item: SerieDataType): LineSeriesOption => ({
            name: item.name,
            type: "line",
            yAxisIndex: 1,
            data: item.data,
          })
        )(extendNewStocksAbilityLineSerieData),
      ],
    }),
    [
      extendNewStocksAbilityBarSerieData,
      extendNewStocksAbilityLineSerieData,
      formatMessage,
    ]
  );
  return (
    <div className={style.PositionImprovementAbilityWrap}>
      <SecondLevelName
        name={formatMessage("positionImprovementAbility")}
        rateNum={abilityRating}
      />
      <SimilarRanking
        rank={rank}
        count={sameTypeCount}
        className={style.SimilarRank}
      />
      <div className={style.SubContent}>
        <div className={style.SubTitle}>
          {formatMessage("fundManagerStealthTradingAbility")}
        </div>
        <div className={style.Text}>
          {formatMessage("fundManagerStealthTradingAbilitySummary", {
            ableTurnoverAbility: formatMessage(
              turnoverAbility > 0 ? "able" : "unable"
            ),
            turnoverAbility: formatPercentage(turnoverAbility),
          })}
        </div>
        <div className={style.ChartWrap}>
          <BaseEcharts options={tradeAbilityOptions} height={256} />
        </div>
      </div>
      <div className={style.SubContent}>
        <div className={style.SubTitle}>
          {formatMessage("fundManagersWillingnessAndAbility")}
        </div>
        <div className={style.Text}>
          {formatMessage("fundManagersWillingnessAndAbilitySummary", {
            desireGrade: formatNilMessage(formatMessage)(
              LEVEL_MESSAGE_KEY_MAP_4,
              desireGrade
            ),
            newStockQuality: formatNilMessage(formatMessage)(
              LEVEL_MESSAGE_KEY_MAP_5,
              newStockQuality
            ),
            newStockRatio: formatPercentage(fastProp("value")(newStockRatio)),
            newStockRatioRank: formatNilMessage(formatMessage)(
              LEVEL_MESSAGE_KEY_MAP_6,
              fastProp("sameTypeRank")(newStockRatio)
            ),
            newStockReturnOfMarket: formatPercentage(
              fastProp("value")(newStockReturnOfMarket)
            ),
            newStockReturnOfMarketRank: formatNilMessage(formatMessage)(
              LEVEL_MESSAGE_KEY_MAP_6,
              fastProp("sameTypeRank")(newStockReturnOfMarket)
            ),
          })}
        </div>
        <div className={style.ChartWrap}>
          <BaseEcharts options={extendNewStocksAbilityOptions} height={256} />
        </div>
      </div>
    </div>
  );
});
