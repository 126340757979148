import { Tooltip } from "antd";
import React from "react";
import { identity } from "lodash/fp";
import cn from "classnames";
import style from "./index.module.less";
import { RequiredJumpProps } from "./interface";

interface BaseNavigate extends RequiredJumpProps {
  navigateTo?: () => any;
  className?: string;
}

export default React.memo<BaseNavigate>(
  ({ name, navigateTo = identity, className }) => (
    <Tooltip title={name}>
      <p className={cn(style.NavigateToPage, className)} onClick={navigateTo}>
        {name}
      </p>
    </Tooltip>
  )
);
