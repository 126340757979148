import React, { useMemo } from "react";
import { floor, flow, map, prop } from "lodash/fp";

import { EvaluationReportAbilitySummaryBody } from "@/model/fundDetail";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp, normalize } from "@/util/opt";
import { factorRatingsOrder } from "@/components/fundInfoExport/constants";

import style from "../index.module.less";
import { useGetDefaultRadarOptions } from "../hooks";
import SecondLevelName from "../../../secondLevelName";
import BaseEcharts from "../../../echarts/baseEcharts";

export default React.memo(
  ({
    evaluationReportAbilitySummary,
  }: {
    evaluationReportAbilitySummary:
      | EvaluationReportAbilitySummaryBody
      | undefined;
  }) => {
    const formatMessage = useFormatMessage();

    const factorRatings = useMemo(
      () => fastProp("factorRatings")(evaluationReportAbilitySummary),
      [evaluationReportAbilitySummary]
    );
    const sortedFactorRatings = useMemo(
      () =>
        flow(normalize("factorCode"), (mapper) =>
          map((factorCode: string) => mapper[factorCode])(factorRatingsOrder)
        )(factorRatings) as EvaluationReportAbilitySummaryBody["factorRatings"],
      [factorRatings]
    );

    const factorRatingNameMap = useMemo(
      () => normalize("factorName")(sortedFactorRatings),
      [sortedFactorRatings]
    );

    const dataSource = useMemo(() => {
      return [
        {
          name: formatMessage("theFund"),
          value: map(
            (
              item: EvaluationReportAbilitySummaryBody["factorRatings"][number]
            ) => item?.fundFactorRating
          )(sortedFactorRatings),
        },
        {
          name: formatMessage("averageOfTheSameType"),
          value: map(
            (
              item: EvaluationReportAbilitySummaryBody["factorRatings"][number]
            ) => item?.sameTypeFundFactorRating
          )(sortedFactorRatings),
        },
      ];
    }, [formatMessage, sortedFactorRatings]);

    const options = useGetDefaultRadarOptions({
      legend: {
        orient: "vertical",
        right: 22,
        left: "auto",
        top: 0,
        itemGap: 14,
      },
      radar: {
        indicator: map(
          (
            item: EvaluationReportAbilitySummaryBody["factorRatings"][number]
          ) => ({ name: item?.factorName, max: 5 })
        )(sortedFactorRatings),
        axisName: {
          formatter: (name) => {
            const ratingNum =
              flow(
                prop(`${name}.fundFactorRating`),
                floor
              )(factorRatingNameMap) || 0;
            let res = `${name}\n`;
            const starStr = `{star|}`;
            for (let index = 0; index < ratingNum; index++) {
              res += starStr;
              if (index < ratingNum - 1) res += " ";
            }
            return res;
          },
        },
      },
      series: [
        {
          type: "radar",
          areaStyle: {
            opacity: 0.85,
          },
          symbol: "none",
          z: 2,
          data: [dataSource[0]],
        },
        {
          type: "radar",
          areaStyle: {
            opacity: 0.85,
          },
          symbol: "none",
          z: 1,
          data: [dataSource[1]],
        },
      ],
    });

    return (
      <div className={style.AbilitySummaryWrap}>
        <SecondLevelName
          name={formatMessage("abilitySummary")}
          rateNum={fastProp("totalRating")(evaluationReportAbilitySummary)}
          hideColon
        />
        <div className={style.AbilityChartWrap}>
          <BaseEcharts
            option={options}
            height={260}
            opts={{ devicePixelRatio: 4 }}
          />
        </div>
      </div>
    );
  }
);
