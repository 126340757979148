import React, { useMemo } from "react";
import { merge, map, flow, prop, flatten, max, min, isEmpty } from "lodash/fp";
import { ChartProps, SeriesOption } from "../yieldTrendChart/type";
import {
  getChartOption,
  generateDataFieldInSerie,
  getSplitNumber,
} from "../yieldTrendChart/helper";
import BaseChart from "../baseChart";

export const getYxiasFixedNumberSplitLineConfit = (
  series: SeriesOption[],
  splitLineNumber: number
) => {
  if (isEmpty(series)) {
    return {};
  }
  const values: number[] = flow(
    map((item: SeriesOption) =>
      map((v: number[]) => v?.[1])(prop("data")(item))
    ),
    flatten
  )(series);
  const maxValue = max(values) || 0;
  const minValue = min(values) || 0;
  const distance =
    Math.round((((maxValue - minValue) * 1.1) / splitLineNumber) * 10000) /
    10000;
  const _min = minValue - (maxValue - minValue) * 0.1;
  return {
    max: maxValue,
    min: _min,
    interval: distance,
  };
};

const generateSerieConfig = (serie: SeriesOption) => ({
  ...serie,
  type: "line",
  smooth: false,
  // symbol: "none",
  showSymbol: false,
  lineStyle: {
    width: 1,
  },
});

export default React.memo<ChartProps>(
  ({
    dates = [],
    series,
    height = 300,
    showDataZoom = true,
    showLegend = true,
    saveImgEnable = false,
    splitNumber,
    fillEmptyDate = false,
    gridOptions = {},
  }) => {
    const finalSeries = useMemo(
      () =>
        map(
          flow(
            generateDataFieldInSerie("maxDrowdown")(
              dates,
              "yield",
              fillEmptyDate
            ),
            generateSerieConfig
          )
        )(series),
      [series, dates, fillEmptyDate]
    );
    const chartOption = useMemo(
      () =>
        merge(
          getChartOption({
            height,
            showLegend,
            showDataZoom,
            saveImgEnable,
            dates,
            series: finalSeries,
            splitNumber,
            gridOptions,
          })
        )({
          yAxis: showDataZoom
            ? { max: 0 }
            : getYxiasFixedNumberSplitLineConfit(
                finalSeries,
                getSplitNumber({
                  splitNumber,
                  showDataZoom,
                  showLegend,
                  height,
                })
              ),
        }),
      [
        height,
        showLegend,
        showDataZoom,
        saveImgEnable,
        dates,
        finalSeries,
        splitNumber,
        gridOptions,
      ]
    );

    return <BaseChart option={chartOption} height={height} />;
  }
);
