import { companyNameMapSelector } from "@/selectors/company";
import { fundIdMapSelector } from "@/selectors/fund";
import { managerNameMapSelector } from "@/selectors/manager";
import { fastProp } from "@/util/opt";
import { compact, flow, keys, map } from "lodash/fp";
import alreadyFund from "@/assets/alreadyFund.svg";
import alreadyFundOpen from "@/assets/alreadyFundOpened.svg";
import alreadyFundCompany from "@/assets/alreadyFundCompany.svg";
import alreadyFundCompanyOpen from "@/assets/alreadyFundCompanyOpened.svg";
import alreadyFundManager from "@/assets/alreadyFundManager.svg";
import alreadyFundManagerOpen from "@/assets/alreadyFundManagerOpened.svg";

export const FUNDS = "FUNDS";
export const FUND_COMPANY = "FUND_COMPANY";
export const FUND_MANAGER = "FUND_MANAGER";
export const alreadySelectConfig = {
  FUNDS: {
    id: FUNDS,
    title: "selectedFundIds",
    idMapSelector: fundIdMapSelector,
    icon: alreadyFund,
    iconActive: alreadyFundOpen,
  },
  FUND_COMPANY: {
    id: FUND_COMPANY,
    title: "selectedFundCompany",
    idMapSelector: companyNameMapSelector,
    icon: alreadyFundCompany,
    iconActive: alreadyFundCompanyOpen,
  },
  FUND_MANAGER: {
    id: FUND_MANAGER,
    title: "selectedFundManager",
    idMapSelector: managerNameMapSelector,
    icon: alreadyFundManager,
    iconActive: alreadyFundManagerOpen,
  },
};

export const getCompareIds = (
  selectedCompareIds: Record<string, boolean>
): string[] =>
  flow(
    keys,
    map((v: string) => {
      if (fastProp(v)(selectedCompareIds)) {
        return v;
      }
    }),
    compact
  )(selectedCompareIds);
