import { RootState } from "@/store";
import { createSelector } from "@reduxjs/toolkit";
import { portfolioAnalysisSelector } from "../selectors";

export const portfolioRiskDismantleSelector = createSelector(
  portfolioAnalysisSelector,
  (
    _: RootState,
    id: string,
    sectionAndscenarioId: { section: string; scenarioId: number | undefined }
  ) => sectionAndscenarioId,
  (portfolioAnalysis, sectionAndscenarioId) =>
    sectionAndscenarioId?.scenarioId
      ? portfolioAnalysis?.riskDismantle?.[sectionAndscenarioId.section]?.[
          sectionAndscenarioId?.scenarioId
        ]
      : null
);
