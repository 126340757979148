import getMessage from "@/util/getMessage";

export const optionalOptions = [
  {
    label: getMessage("fundOptional"),
    value: "fundOptional",
  },
  {
    label: getMessage("fundManagerOptional"),
    value: "fundManagerOptional",
  },
];

export const FUND = "FUND";
export const FUND_MANAGER = "FUND_MANAGER";
