// import { useFormatMessage } from "@/util/formatMessage";
import LoadingComponent from "@/components/LoadingComponent";
import { useAppDispatch } from "@/hooks/redux";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import {
  fetchGetCurrentAndHistoryFundManagers,
  fetchGetCurrentFundManagerReturn,
} from "@/store/fundDetailSlice";
import { Space } from "antd";
import React, { useEffect } from "react";
import { AssetDetailComponent } from "../../constant";
import CurrentFundManger from "./components/currentFundManger";
import SuccessiveFundManagers from "./components/successiveFundManagers";
import style from "./index.module.less";

const requestList = [
  fetchGetCurrentAndHistoryFundManagers,
  fetchGetCurrentFundManagerReturn,
  fetchFundAndBenchmarkDailyReturns,
];
export default React.memo<AssetDetailComponent>(({ fundId }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (fundId) {
      dispatch(fetchGetCurrentAndHistoryFundManagers(fundId));
    }
  }, [fundId, dispatch]);
  return (
    <LoadingComponent actions={requestList}>
      <Space direction="vertical" size={20} className={style.fullWidth}>
        <CurrentFundManger fundId={fundId} />
        <SuccessiveFundManagers fundId={fundId} />
      </Space>
    </LoadingComponent>
  );
});
