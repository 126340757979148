import { forEach, isArray } from "lodash/fp";
import { FORMAT_TYPES as exportTypes } from "./types";
import ExportXlsxUtil from "./exportXlsxUtil";

export const FORMAT_TYPES = exportTypes;

export default (data) =>
  (type) =>
  (wsName, autoAddTimeSuffix = true, sheetProperties, views) => {
    const exportXlsxUtils = new ExportXlsxUtil(sheetProperties, views);
    if (isArray(data)) {
      forEach((sheet) => {
        exportXlsxUtils.addData({ ...sheet, title: sheet?.title || wsName });
      })(data);
    } else {
      exportXlsxUtils.addData({ ...data, title: data?.title || wsName });
    }
    return exportXlsxUtils.downloadXlsx(wsName, autoAddTimeSuffix, type);
  };
