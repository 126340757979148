import { FactorSelectionType } from "@/model/fundDetail";
import { FormatMessageFunc } from "@/util/formatMessage";
import { isEqual, isNil } from "lodash/fp";

export const CHART_COLOR = ["#1A396A", "#CFB47F", "#9CACBC", "#957F54"];

export const LEVEL_MESSAGE_KEY_MAP_1 = {
  HIGH: "high",
  MEDIUM: "moderate",
  LOW: "low",
};

export const LEVEL_MESSAGE_KEY_MAP_2 = {
  HIGH: "higher",
  MEDIUM: "commonly",
  LOW: "lower",
};

export const LEVEL_MESSAGE_KEY_MAP_3 = {
  HIGH: "higherThan",
  MEDIUM: "EQUAL",
  LOW: "lowerThan",
};

export const LEVEL_MESSAGE_KEY_MAP_4 = {
  HIGH: "strong",
  MEDIUM: "commonly",
  LOW: "weak",
};

export const LEVEL_MESSAGE_KEY_MAP_5 = {
  HIGH: "preferably",
  MEDIUM: "commonly",
  LOW: "backward",
};

export const LEVEL_MESSAGE_KEY_MAP_6 = {
  HIGH: "forward",
  MEDIUM: "commonly",
  LOW: "backward",
};

export const getCompareValueMsgKey = (
  factorSelection: Partial<FactorSelectionType> | undefined = {}
) => {
  const { fundValue, sameTypeAvg } = factorSelection || {};
  if (
    isNil(fundValue) ||
    isNil(sameTypeAvg) ||
    isNaN(fundValue) ||
    isNaN(sameTypeAvg)
  )
    return "--";
  if (fundValue < sameTypeAvg) {
    return "LOW";
  } else if (isEqual(fundValue)(sameTypeAvg)) return "MEDIUM";
  else if (fundValue > sameTypeAvg) return "HIGH";
  return "--";
};

export const formatNilMessage =
  (formatMessage: FormatMessageFunc) =>
  (map: Record<string, string>, key: string | undefined) => {
    return key ? formatMessage(map[key]) : "--";
  };

export const factorRatingsOrder = [
  "fund_rank_performance", // "综合业绩",
  "fund_rank_selection", // "个股选择能力",
  "fund_rank_rebalance", // "调仓改善能力",
  "fund_rank_capture", // "行情捕获能力",
  "fund_rank_allocation", // "行业配置能力",
];

export const managerFactorRatingsOrder = [
  "fund_unrank_performance", // "综合业绩分位数",
  "fund_unrank_selection", // "个股选择能力分位数",
  "fund_unrank_rebalance", // "调仓改善能力分位数",
  "fund_unrank_capture", // "行情捕获能力分位数",
  "fund_unrank_allocation", // "行业配置能力分位数",
];

export const getPDFDownloadLink = (
  fundId: string,
  refer: string,
  reportDate: string
) =>
  `http://121.46.249.135:18695/fund-report/create/fund-evaluate?referer=${encodeURIComponent(
    refer
  )}&code=${fundId}&reportDate=${reportDate}`;
