import React, { useMemo } from "react";
import { Select } from "antd";
import type { SelectProps } from "antd/es/select";
import { useFormatMessage } from "@/util/formatMessage";
import { isEmpty, map, toArray } from "lodash/fp";
import { freQuencyOptions } from "../performancePreview/constant";

export default React.memo<SelectProps<string>>(
  ({ value, options, className, ...props }) => {
    const formatMessage = useFormatMessage();

    const handleOptions = useMemo(
      () => (isEmpty(options) ? toArray(freQuencyOptions) : options),
      [options]
    );
    const { Option } = Select;
    return (
      <Select value={value} className={className} {...props}>
        {map(({ id, message }) => (
          <Option key={id} value={id}>
            {formatMessage(message)}
          </Option>
        ))(handleOptions)}
      </Select>
    );
  }
);
