import React, { useContext, useEffect, useMemo } from "react";
import PortfolioAnalysisSubtitle from "../../components/portfolioAnalysisSubtitle";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { Row, Col, Table, Space } from "antd";
import ErrorBoundary from "@/components/errorBoundary/index";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import { formatPercentage } from "@/util/numberFormatter";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import {
  includes,
  isEmpty,
  map,
  flow,
  keys,
  prop,
  uniq,
  groupBy,
  sumBy,
  forEach,
  findIndex,
  filter,
} from "lodash/fp";
import style from "../index.module.less";
import FundDistributionChart, {
  FundDistributionChartOpts,
} from "../../components/fundDistributionChart";
import mapper from "../mapper";
import { useAppSelector } from "@/hooks/redux";
import { PortfolioAnalysisProps } from "../..";
import { fastProp, floatEqual, mapIndexed, normalize } from "@/util/opt";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { errorValidator } from "@/components/errorBoundary/constant";
import { useCreation } from "ahooks";
import { fetchGetPositionTrendStrategy } from "@/store/portfolioAnalysis";
import {
  CASH,
  chartColors,
  getCategoryType,
  getTableData,
  Level,
} from "../constant";
import { useGetExpandedRowKeys } from "../../hooks";

const percentage = (value: number) => formatPercentage(value);

const needTime = 1;

const useGetBarChartDataSource = (
  id: string,
  formatMessage: FormatMessageFunc
) => {
  const { currentPositionStrategy, categoryTreeMap } = useAppSelector((state) =>
    mapper(state, id)
  );

  const level = useMemo<Level[]>(() => {
    const fundTypeDataMap = normalizeDailyReturnsMap(
      fastProp("strategyId")(currentPositionStrategy),
      fastProp("weights")(currentPositionStrategy)
    );
    return flow(
      keys,
      map((strategyId: string) => {
        const categoryType = getCategoryType(
          strategyId,
          categoryTreeMap,
          formatMessage
        );
        return {
          parentType: categoryType,
          type:
            strategyId === CASH
              ? formatMessage(CASH)
              : prop(`${strategyId}.name`)(categoryTreeMap),
          strategyId,
          weight: fastProp(strategyId)(fundTypeDataMap),
          key: strategyId,
        };
      }),
      filter((item: Level) => !floatEqual(0)(item.weight))
    )(fundTypeDataMap);
  }, [categoryTreeMap, currentPositionStrategy, formatMessage]);

  const firstLevel = useMemo(
    () => uniq(map(fastProp("parentType"))(level)),
    [level]
  );

  const secondLevel = useMemo(
    () => uniq(map(fastProp("type"))(level)),
    [level]
  );

  const typeGroup = useMemo(() => groupBy("parentType")(level), [level]);

  const dataSource = useMemo(
    () => getTableData(level, formatMessage),
    [level, formatMessage]
  );

  const seriseData = useMemo(() => {
    const totalData = map((key: string) =>
      sumBy("weight")(fastProp(key)(typeGroup))
    )(firstLevel);
    const seriseList = map((v: string) => {
      const data: any[] = [];
      forEach((t: string) => {
        const childrenType = map((item: Record<string, any>) => item.type)(
          fastProp(t)(typeGroup)
        );
        if (includes(v)(childrenType)) {
          data.push(prop(`${v}.weight`)(normalize("type")(level)));
        } else {
          data.push(0);
        }
      })(firstLevel);
      return {
        type: v,
        data: data,
      };
    })(secondLevel);
    return { totalData, seriseList };
  }, [secondLevel, firstLevel, typeGroup, level]);

  const { totalData, seriseList } = seriseData;

  const chartData = useMemo<FundDistributionChartOpts["options"]>(() => {
    return {
      yAxis: {
        type: "category",
        data: firstLevel,
        boundaryGap: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
      },
      grid: {
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLabel: {
          formatter(val: number) {
            return percentage(val);
          },
        },
      },
      legend: {
        show: false,
      },
      series: mapIndexed((item: Record<string, any>) => ({
        name: item.type,
        data: item.data,
      }))(seriseList),
      color: chartColors,
    };
  }, [firstLevel, seriseList]);
  return {
    chartData,
    dataSource,
    seriseData,
    allExpandedRowKeys: firstLevel,
    totalData,
    firstLevel,
  };
};

export default React.memo<PortfolioAnalysisProps>((props) => {
  const { id } = props;
  const formatMessage = useFormatMessage();

  const { runningTime } = useContext(PortfolioAnalysisContext);
  const { chartData, dataSource, allExpandedRowKeys, totalData, firstLevel } =
    useGetBarChartDataSource(id, formatMessage);
  const { dispatch } = useContext(PortfolioAnalysisContext);

  useEffect(() => {
    if (id) {
      dispatch(needTime, fetchGetPositionTrendStrategy(id));
    }
  }, [dispatch, id]);

  const { onExpandAllRows, expandable, expandedRowKeys } =
    useGetExpandedRowKeys(allExpandedRowKeys);

  const tablecolumns = useMemo(
    () => [
      {
        dataIndex: "type",
        align: "left",
        width: 200,
        title: (
          <Space size={4}>
            <span onClick={onExpandAllRows} className={style.ExpendToFund}>
              {!isEmpty(expandedRowKeys) ? (
                <MinusSquareOutlined />
              ) : (
                <PlusSquareOutlined />
              )}
            </span>
            {formatMessage("assetAllocation")}
          </Space>
        ),
        render: (text: string, record: any) => {
          return (
            <Space size={4}>
              {record.parentType && (
                <span
                  className={style.fundTypeColor}
                  style={{
                    background:
                      chartColors[
                        findIndex((item: string) => item === record.parentType)(
                          firstLevel
                        )
                      ],
                  }}
                ></span>
              )}
              {text}
            </Space>
          );
        },
      },
      {
        dataIndex: "weight",
        title: formatMessage("totalPercent"),
        render: percentage,
        align: "right",
      },
    ],
    [formatMessage, expandedRowKeys, onExpandAllRows, firstLevel]
  );

  const errorKey = useCreation(
    () => errorValidator.lessOneDay(needTime, runningTime),
    [runningTime]
  );

  return (
    <>
      <PortfolioAnalysisSubtitle
        name={
          <Space size="small">
            <span>{formatMessage("fundDistribution")}</span>
            <span className={style.portfolioCurrent}>
              ({formatMessage("current")})
            </span>
          </Space>
        }
      />
      <ErrorBoundary errKey={errorKey}>
        <Row>
          <Col span={18}>
            <FundDistributionChart
              options={chartData}
              totalData={totalData}
              height={400}
            />
          </Col>
          <Col span={6}>
            <Table
              dataSource={dataSource}
              columns={tablecolumns as any}
              pagination={false}
              bordered={true}
              expandable={expandable}
              className={style.tableStyle}
              tableLayout="fixed"
              scroll={{ y: 300 }}
            />
          </Col>
        </Row>
      </ErrorBoundary>
    </>
  );
});
