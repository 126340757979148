import Excel from "exceljs";
import { saveAs } from "file-saver";
import { map, merge, some } from "lodash/fp";
import { forEachIndexed } from "../opt";
import { forEachTree } from "../opt/tree";
import { dateFormat } from "../dateFormat";

export default class ExportXlsxUtil {
  constructor(sheetProperties, views) {
    this.workbook = new Excel.Workbook();
    if (views) {
      this.workbook.views = views;
    }
    this.sheets = [];
    this.sheetProperties = {
      outlineProperties: {
        summaryBelow: false,
      },
      defaultColWidth: 20,
      ...sheetProperties,
    };
  }

  addSheet(name, sheetOptions) {
    const newSheet = this.workbook.addWorksheet(
      name || `new sheet ${this.sheets.length + 1}`,
      sheetOptions
    );
    this.sheets.push(newSheet);
    return newSheet;
  }

  downloadXlsx(fileName, autoAddTimeSuffix, fileType = "xlsx") {
    return this.workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        // eslint-disable-next-line no-undef
        new Blob([buffer]),
        `${fileName}${autoAddTimeSuffix ? `_${dateFormat()}` : ""}.${fileType}`
      );
    });
  }

  addSimpleTableSheet(sheet, columns, dataSource) {
    sheet.columns = map(({ exportProperties, label, align, prop: key }) => ({
      header: exportProperties?.label || label,
      key: exportProperties?.prop || key,
      alignment: {
        horizontal: align,
      },
      width: exportProperties?.width,
    }))(columns);
    sheet.properties = {
      ...sheet.properties,
      ...this.sheetProperties,
    };
    sheet.addRows(dataSource);
    sheet.eachRow((row) => {
      row.eachCell((cell, colNumber) => {
        if (columns?.[colNumber - 1]?.wrapText) {
          cell.alignment = {
            ...cell.alignment,
            wrapText: true,
          };
        }
        if (columns?.[colNumber - 1]?.numFmt) {
          cell.numFmt = columns?.[colNumber - 1]?.numFmt;
        }
      });
    });
  }

  addTreeTableSheet(sheet, columns, dataSource) {
    try {
      sheet.columns = map(({ exportProperties, label, align, prop: key }) => ({
        header: exportProperties?.label || label,
        key: exportProperties?.prop || key,
        alignment: {
          horizontal: align,
        },
        width: exportProperties?.width,
      }))(columns);
      sheet.properties = {
        ...sheet.properties,
        ...this.sheetProperties,
      };
      forEachTree((item, depth) => {
        const row = sheet.addRow(item);
        row.outlineLevel = depth;
        forEachIndexed(({ numFmt = "", align }, index) => {
          const cell = row.getCell(index + 1);
          cell.numFmt = numFmt;
          cell.alignment = {
            ...cell.alignment,
            horizontal: align,
          };
        })(columns);
      })(dataSource);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  addSheetJsSheet({
    data: sheetData,
    columnWidths,
    formats,
    title,
    sheetOptions,
  }) {
    const curSheet = this.addSheet(
      title,
      merge(sheetOptions)({ properties: this.sheetProperties })
    );
    forEachIndexed((rowData, index) => {
      const row = curSheet.addRow(rowData);
      if (index !== 0) {
        row.eachCell((cell, colNumber) => {
          cell.numFmt = formats?.[colNumber - 1] || "@";
        });
      }
    })(sheetData);
    if (columnWidths && columnWidths.length) {
      forEachIndexed((width, index) => {
        const columns = curSheet.getColumn(index + 1);
        if (width) {
          columns.width = width;
        }
      })(columnWidths);
    }
  }

  addData({
    title,
    dataSource,
    columns,
    sheetOptions,
    data,
    columnWidths,
    formats,
  }) {
    if (columns && dataSource) {
      const curSheet = this.addSheet(title, sheetOptions);
      if (some((v) => v?.children?.length)(dataSource)) {
        this.addTreeTableSheet(curSheet, columns, dataSource);
      } else {
        this.addSimpleTableSheet(curSheet, columns, dataSource);
      }
    } else {
      this.addSheetJsSheet({
        data,
        columnWidths,
        formats,
        title,
        sheetOptions,
      });
    }
  }
}
