import React, {
  Key,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  isEmpty,
  pullAt,
  size,
  prop,
  has,
  remove,
  concat,
  map,
  keys,
  values,
  flow,
  flatten,
  filter,
  uniq,
  find,
  pullAll,
  join,
  compact,
  pull,
  first,
} from "lodash/fp";
import {
  TreeDataNode,
  Modal,
  Input,
  Button,
  Tree,
  Checkbox,
  Space,
  Popconfirm,
  Popover,
  Card,
} from "antd";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import cn from "classnames";
import { useMemoizedFn } from "ahooks";
import { AutoResizer, BaseTableProps } from "react-base-table";
import { arrayToMap, fastProp, normalize } from "@/util/opt";
import { useFormatMessage } from "@/util/formatMessage";
import VirtualTableComponent from "@/components/virtualTable";

import useModal from "@/hooks/useModal";

import style from "./index.module.less";
import { CheckboxProps } from "antd/lib/checkbox";

type TableProps<T> = BaseTableProps<T>;
type RowKey = string;
const VirtualTable = VirtualTableComponent<any>();

type SelectTreeNodeModalProps<T> = {
  title: string;
  searchPlaceholder?: string;
  btnText?: string;
  disabled?: boolean;
  defaultTreeSelectedKey: Key;
  treeData: TreeDataNode[]; // 只包含分类节点
  categoryData: Record<Key, T[]>;
  children?: React.ReactNode;
  listColumns: TableProps<T>["columns"];
  maxSelection?: number;
  rowKey?: RowKey;
  batchAddKey?: RowKey;
  errorTip?: string;
  value?: RowKey[];
  onFilter?: (node: T, searchValue: string) => boolean;
  onSelect?: (rowKeys: RowKey[]) => void;
  showBatchAdd?: boolean;
  icon?: React.ReactNode;
  resultItemTitleKey?: string;
  defaultSelectedValue?: RowKey[];
};

type ModalContentProps<T> = Omit<
  SelectTreeNodeModalProps<T>,
  "btnText" | "disabled" | "children"
> & {
  closeModal: () => void;
  open: boolean;
};

function ModalContent<T>({
  value,
  open,
  title,
  searchPlaceholder,
  defaultTreeSelectedKey,
  treeData,
  categoryData,
  listColumns,
  maxSelection,
  rowKey = "id",
  batchAddKey = "id",
  errorTip,
  onFilter,
  onSelect,
  closeModal,
  showBatchAdd = true,
  resultItemTitleKey = "name",
  defaultSelectedValue = [],
}: ModalContentProps<T>) {
  const formatMessage = useFormatMessage();
  const [searchValue, setSearchValue] = useState("");
  const [treeSelectedKeys, setTreeSelectedKeys] = useState<Key[]>(
    defaultTreeSelectedKey ? [defaultTreeSelectedKey] : []
  );
  const [selectIds, setSelectIds] = useState<RowKey[]>([]);
  const selectIdMap = useMemo(() => arrayToMap(selectIds), [selectIds]);
  // data of current selected catetegory
  const listTableData = useMemo(() => {
    let tableData = categoryData?.[treeSelectedKeys?.[0]] || [];
    if (searchValue && onFilter) {
      tableData = filter((item: T) => onFilter(item, searchValue))(tableData);
    }
    return map((item: T) => ({
      ...item,
      checked: !!selectIdMap?.[prop(rowKey)(item)],
    }))(tableData);
  }, [
    searchValue,
    categoryData,
    treeSelectedKeys,
    selectIdMap,
    rowKey,
    onFilter,
  ]);
  const listTableDataMap = useMemo(
    () => normalize(rowKey)(listTableData),
    [rowKey, listTableData]
  );
  // all leaf node data
  const allData = useMemo(
    () => normalize(rowKey)(flow(values, flatten)(categoryData)),
    [rowKey, categoryData]
  );
  const allDataSize = useMemo(() => size(allData), [allData]);
  const selectedDataSize = useMemo(() => size(selectIds), [selectIds]);
  const _maxSelection = maxSelection || allDataSize;
  const indeterminate = useMemo(
    () => !isEmpty(selectIds) && size(selectIds) < _maxSelection,
    [selectIds, _maxSelection]
  );

  useEffect(() => {
    setSelectIds(value || []);
  }, [value]);

  const handleSure = useMemoizedFn(() => {
    closeModal();
    onSelect?.(selectIds);
  });

  const onSearch = useMemoizedFn((value: string) => {
    setSearchValue(value);
  });

  const onClearSelected = useMemoizedFn(() => {
    setSelectIds([]);
  });

  const onSelectItem = useMemoizedFn((rowKey: RowKey) => {
    if (!rowKey) return;
    if (has(rowKey)(selectIdMap)) {
      setSelectIds(remove<RowKey>((id) => id === rowKey)(selectIds));
    } else {
      setSelectIds(concat(selectIds)(rowKey));
    }
  });

  const onSelectAll = useMemoizedFn(() => {
    if (find((item) => !prop("checked")(item))(listTableData)) {
      setSelectIds(flow(keys, concat(selectIds), uniq)(listTableDataMap));
    } else {
      setSelectIds(pullAll(keys(listTableDataMap))(selectIds));
    }
  });

  const rowSelection = useMemo<TableProps<T>["rowSelection"]>(
    () => ({
      type: "checkbox",
    }),
    []
  );

  const _listColumns = useMemo<TableProps<T>["columns"]>(
    () => [
      {
        align: "left",
        key: rowKey,
        width: 40,
        cellRenderer: ({ rowData }: { rowData: T }) => (
          <Checkbox
            checked={prop("checked")(rowData)}
            onChange={() => onSelectItem(prop(rowKey)(rowData))}
          />
        ),
      },
      ...(listColumns || []),
    ],
    [rowKey, listColumns, onSelectItem]
  );

  const isCheckAll = useMemo(
    () =>
      size(
        fastProp((first(treeSelectedKeys) as string) || "")(categoryData)
      ) === size(selectIds),
    [categoryData, selectIds, treeSelectedKeys]
  );

  const headerRenderer = useMemoizedFn(({ cells }) => {
    const newCells = pullAt(0)(cells);
    return (
      <>
        <div className={style.HeaderCheckBoxRenderer}>
          <Checkbox
            checked={isCheckAll}
            onChange={onSelectAll}
            indeterminate={indeterminate}
          />
        </div>
        {newCells}
      </>
    );
  });

  const [batchState, setBatchState] = useState<boolean>(false);
  const [textArea, setTextArea] = useState<RowKey>("");
  const [errorCodes, setErrorCodes] = useState<RowKey[]>([]);
  const [validCodes, setValidCodes] = useState<RowKey[]>([]);

  const onChangeTextArea = useMemoizedFn((e: { target: { value: RowKey } }) => {
    setTextArea(e.target.value);
  });

  const onReset = useMemoizedFn(() => {
    setTextArea("");
    setErrorCodes([]);
    setValidCodes([]);
    setBatchState(false);
  });

  const onClearInvalidCode = useMemoizedFn(() => {
    setTextArea(validCodes.join("\n"));
    setErrorCodes([]);
  });

  const onBatchAdd = useMemoizedFn(() => {
    if (!textArea) {
      return;
    }

    const allDataBatchAddIdMap = normalize(batchAddKey)(
      flow(values, flatten)(categoryData)
    );

    const keys = flow(
      filter((item) => item !== ""),
      map((item: string) => item.trim())
    )(textArea.split("\n"));
    const notExistKeys = filter(
      (key: RowKey) => !fastProp(key)(allDataBatchAddIdMap)
    )(keys);
    const _validKeys = filter((key: RowKey) =>
      fastProp(key)(allDataBatchAddIdMap)
    )(keys);
    if (size(notExistKeys)) {
      setValidCodes(_validKeys);
      return setErrorCodes(notExistKeys);
    }
    const showKeys = flow(
      map((v: RowKey) => prop([v, rowKey])(allDataBatchAddIdMap)),
      (keys: RowKey[]) => uniq([...keys, ...(selectIds || [])]),
      compact
    )(_validKeys);
    onSelect?.(showKeys);
    onReset();
  });

  const onSortEnd = useCallback(
    ({ newIndex, oldIndex }: { newIndex: number; oldIndex: number }) => {
      setSelectIds(moveItem(selectIds, oldIndex, newIndex));
    },
    [selectIds]
  );

  const onCheckedResultItem = useCallback(
    (e: { target: { "factor-id"?: RowKey; checked: boolean } }) => {
      if (e.target["factor-id"]) {
        if (e.target.checked) {
          setSelectIds([...selectIds, e.target["factor-id"]]);
        } else {
          setSelectIds(pull(e.target["factor-id"])(selectIds));
        }
      }
    },
    [selectIds]
  );

  const resetDefault = useCallback(() => {
    setSelectIds(defaultSelectedValue || []);
  }, [defaultSelectedValue]);

  return (
    <>
      <Modal
        title={title}
        open={open}
        width={980}
        onCancel={closeModal}
        onOk={handleSure}
        footer={
          <Footer
            resetDefault={resetDefault}
            onOk={handleSure}
            onCancel={closeModal}
          />
        }
      >
        <div className={style.ModalContainer}>
          <div>
            <Input.Search
              className={style.Search}
              onSearch={onSearch}
              placeholder={searchPlaceholder}
            />
          </div>
          <div className={style.Content}>
            <div className={style.TreeWrap}>
              <Tree
                className={style.Tree}
                treeData={treeData}
                defaultExpandAll
                onSelect={(selectedKeys: Key[]) =>
                  setTreeSelectedKeys(selectedKeys)
                }
                selectedKeys={treeSelectedKeys}
              />
            </div>
            <div className={style.SelectUserTable}>
              {showBatchAdd && (
                <div className={style.BatchAdd}>
                  <Popover
                    open={batchState}
                    trigger="click"
                    placement="bottomLeft"
                    zIndex={99}
                    getPopupContainer={(triggerNode: any) =>
                      triggerNode.parentNode
                    }
                    content={
                      <Card
                        size="small"
                        title={formatMessage("pleaseUserInputBatchAdd")}
                        bordered={false}
                        actions={[
                          <Space>
                            <Button
                              size="small"
                              onClick={() => {
                                onReset();
                              }}
                            >
                              {formatMessage("cancel")}
                            </Button>
                            <Button
                              size="small"
                              type="primary"
                              onClick={onClearInvalidCode}
                              disabled={!size(errorCodes)}
                            >
                              {formatMessage("clearInvalidCode")}
                            </Button>
                            <Button
                              size="small"
                              type="primary"
                              onClick={onBatchAdd}
                            >
                              {formatMessage("ok")}
                            </Button>
                          </Space>,
                        ]}
                        className={style.BatchAddBox}
                      >
                        <Input.TextArea
                          className={style.BatchAddText}
                          value={textArea}
                          onChange={onChangeTextArea}
                        />
                        <div className={style.BatchAddError}>
                          {size(errorCodes) ? (
                            <>
                              <div className={style.BatchAddErrorTip}>
                                {formatMessage(errorTip || "")}
                              </div>
                              <div className={style.BatchAddErrorContent}>
                                {join(" ")(errorCodes)}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </Card>
                    }
                  >
                    <Button
                      size="small"
                      icon={<PlusOutlined />}
                      className={style.BatchAddButton}
                      onClick={() => setBatchState(true)}
                    >
                      {formatMessage("BatchAdd")}
                    </Button>
                  </Popover>
                </div>
              )}
              <div className={style.ListWrap}>
                <AutoResizer>
                  {({ width, height }) => (
                    <VirtualTable
                      rowSelection={rowSelection}
                      columns={_listColumns}
                      data={listTableData}
                      className={style.BaseTable}
                      height={height}
                      headerRenderer={headerRenderer}
                      headerHeight={36}
                      width={width}
                      rowKey={rowKey}
                    />
                  )}
                </AutoResizer>
              </div>
            </div>
            <div className={style.SelectedPanel}>
              <div className={style.Header}>
                <Space>
                  <h4>{formatMessage("selected")}</h4>
                  <p>
                    (
                    <span className={style.SelectSize}>{selectedDataSize}</span>
                    /<span>{allDataSize}</span>)
                  </p>
                </Space>
                <Popconfirm
                  title={formatMessage("sureClearSelected")}
                  onConfirm={onClearSelected}
                  okText={formatMessage("ok")}
                  cancelText={formatMessage("cancel")}
                  placement="bottom"
                >
                  <Button size="small" type="default">
                    {formatMessage("clear")}
                  </Button>
                </Popconfirm>
              </div>
              <div className={style.SelectedWrap}>
                <SortableContainerUl useDragHandle onSortEnd={onSortEnd}>
                  {selectIds.map((key, index) => {
                    const data = find(
                      (item: Record<string, any>) =>
                        fastProp(rowKey)(item) === key
                    )(allData);
                    return (
                      <SortableItem
                        key={`item-${key}`}
                        index={index}
                        factorId={key}
                        onChange={onCheckedResultItem}
                        disabled={prop(`disabled`)(data)}
                        disabledDrag={prop(`disabled`)(data)}
                      >
                        {prop(resultItemTitleKey)(data)}
                      </SortableItem>
                    );
                  })}
                </SortableContainerUl>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function SelectTreeNodeModal<T>({
  children,
  btnText,
  disabled,
  icon,
  ...resetProps
}: SelectTreeNodeModalProps<T>) {
  const { visible, openModal, closeModal } = useModal();

  return (
    <>
      {children || (
        <Button
          onClick={openModal}
          disabled={disabled}
          icon={icon || <PlusOutlined />}
        >
          {btnText}
        </Button>
      )}
      {visible && (
        <ModalContent closeModal={closeModal} open={visible} {...resetProps} />
      )}
    </>
  );
}

const SortableContainerUl = SortableContainer(
  ({ children }: { children: any }) => {
    return <ul>{children}</ul>;
  }
);
const DragHandle = SortableHandle(() => <MenuOutlined />);

const SortableItem = SortableElement(
  ({
    children,
    factorId,
    disabledDrag,
    onChange,
  }: {
    children: ReactNode;
    factorId: string;
    disabledDrag?: boolean;
    onChange: CheckboxProps["onChange"];
  }) => (
    <li
      factor-id={factorId}
      className={cn(style.DragItem, style.Drag, disabledDrag && style.Disabled)}
    >
      <Checkbox
        disabled={disabledDrag}
        onChange={onChange}
        factor-id={factorId}
        checked
      />
      <div className={style.DragHandleWrap}>
        <DragHandle />
      </div>
      {children}
    </li>
  )
);

function moveItem<T>(arr: T[], from: number, to: number) {
  const [min, max] = from > to ? [to, from] : [from, to];
  const startArr = arr.slice(0, min);
  const endArr = arr.slice(max + 1);
  const movedItem = arr[from];
  const moveArr =
    from > to
      ? [movedItem, ...arr.slice(min, max)]
      : [...arr.slice(min + 1, max + 1), movedItem];
  return [...startArr, ...moveArr, ...endArr];
}

interface FooterProps {
  resetDefault: () => void;
  onCancel: () => void;
  onOk: () => void;
}

const Footer = (props: FooterProps) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Button onClick={props.resetDefault}>
        {formatMessage("resetDefault")}
      </Button>
      <Button onClick={props.onCancel}>{formatMessage("cancel")}</Button>
      <Button onClick={props.onOk} type="primary">
        {formatMessage("ok")}
      </Button>
    </>
  );
};

export default SelectTreeNodeModal;
