import React from "react";
import { flow, prop } from "lodash/fp";

import { useFormatMessage } from "@/util/formatMessage";
import { useAppSelector } from "@/hooks/redux";
import { fastProp } from "@/util/opt";
import style from "../index.module.less";
import SimilarRanking from "../../../similarRanking";
import MarketAbilityChart from "./marketAbilityChart";
import { AbilityLevel, MarketCapabilityLevel } from "./contants";
import { useGetAbilitySummary } from "../../secondPage/hook";
import SecondLevelName from "../../../secondLevelName";

export default React.memo(({ fundId }: { fundId: string }) => {
  const formatMessage = useFormatMessage();

  // const fundDetailReturn = useAppSelector(
  //   prop(`fundDetail.${fundId}.fundDetailReturn`)
  // );

  const marketCapabilityDetail = useAppSelector(
    prop(`fundDetail.${fundId}.marketCapabilityDetail`)
  );

  const {
    marketCapabilitySameTypeRank,
    sameTypeFundCount,
    marketCapabilityLevel,
    defensiveAbilityLevel,
    offensiveAbilityLevel,
    risingMarketCaptureRank,
    fallingMarketCaptureRank,
  } = marketCapabilityDetail || {};

  const { factorRatingsMap } = useGetAbilitySummary(fundId);

  return (
    <div className={style.MarketCaptureAbilityWrap}>
      <SecondLevelName
        name={formatMessage("marketCaptureAbility")}
        rateNum={prop("fund_rank_capture.fundFactorRating")(factorRatingsMap)}
      />
      <SimilarRanking
        rank={marketCapabilitySameTypeRank}
        count={sameTypeFundCount}
        className={style.SimilarRank}
      />
      <div className={style.Text}>
        {formatMessage("marketCaptureAbilitySummary", {
          marketCapabilityLevel: flow(
            fastProp(marketCapabilityLevel),
            formatMessage
          )(MarketCapabilityLevel),
          offensiveAbilityLevel: flow(
            fastProp(offensiveAbilityLevel),
            formatMessage
          )(AbilityLevel),
          defensiveAbilityLevel: flow(
            fastProp(defensiveAbilityLevel),
            formatMessage
          )(AbilityLevel),
          fundName: formatMessage("theFund"),
          risingMarketCaptureRank: `${risingMarketCaptureRank}/${sameTypeFundCount}`,
          fallingMarketCaptureRank: `${fallingMarketCaptureRank}/${sameTypeFundCount}`,
        })}
      </div>
      <MarketAbilityChart
        name={formatMessage("marketRiseStageRank")}
        type="RISE"
        markAreaColor="rgba(212,170,140,0.3)"
        fundId={fundId}
      />
      <MarketAbilityChart
        name={formatMessage("marketFallStageRank")}
        type="FALL"
        markAreaColor="rgba(17, 128, 204,0.1)"
        fundId={fundId}
      />
    </div>
  );
});
