import { getFileApi } from "@/api/fileInfo";
import {
  getFundBasic,
  getFundDetailIndustryTrend,
  getFundDetailReturn,
  getFundDetailSectorTrend,
  getFundDetailStockPositionTrend,
  getFundSummaryResult,
  getCurrentAndHistoryFundManagers,
  getAnnouncementType,
  getNewsInfo,
  getAnnouncementInfo,
  getFundDailyNetValue,
  getFundDetailETFIndustryTrend,
  getFundDetailIndustryTrendDate,
  getETFFundAttributionBrinsonResult,
  getFundAttributionBrinsonResult,
  getFundAttributionNetValueResult,
  getNetValueAttributionModel,
  getEvaluationReportInfo,
  getEvaluationReportAbilitySummary,
  getComprehensivePerformance,
  getIndustryAbilityEvaluation,
  getEvaluationReportRiskSummary,
  getStockSelectionAbility,
  getTurnoverImprovementAbility,
  getMarketCapabilityDetail,
  getRiskDetail,
  getManagerEvaluation,
} from "@/api/fundDetail";
import { getFundManagerDetailDailyReturnApi } from "@/api/fundManagerDetail";
import {
  FundBasicBody,
  FundBasicType,
  FundDetailIndustryBody,
  FundDetailManagersBody,
  FundManagerImgBody,
  AnnouncementBody,
  NewsBody,
  FundStockPositionBody,
  FundDetailETFIndustryBody,
  ETFFundAttributionBrinsonBody,
  FundAttributionBrinsonBody,
  FundNetValueAttributionParams,
  netValueAttributionModalBody,
  ManagerEvaluationBody,
} from "@/model/fundDetail";
import createSocketThunk from "@/util/createSocketThunk";
import { blobToBase64 } from "@/util/file";
import getMessage from "@/util/getMessage";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { fastProp } from "@/util/opt";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { first, prop, set, startsWith, update } from "lodash/fp";

export const fetchGetFundBasic = avoidMultiRequestActionThunk<FundBasicBody>(
  (body) => `fundDetail.${body.fundId}.basic`,
  createAsyncThunk("fundDetail/basic", async (body) => {
    const response = await getFundBasic(body);
    return response;
  })
);
export const fetchGetFundDetailReturn =
  avoidMultiRequestActionThunk<FundBasicBody>(
    (body) => `fundDetail.${body.fundId}.fundDetailReturn`,
    createAsyncThunk(
      "fundDetail/fundDetailReturn",
      async (body: FundBasicBody) => {
        return getFundDetailReturn(body);
      }
    )
  );
export const fetchGetFundDailyNetValue = avoidMultiRequestActionThunk<string>(
  (fundId) => `fundDetail.${fundId}.fundDailyNetValue`,
  createAsyncThunk("fundDetail/fundDailyNetValue", async (fundId: string) => {
    return getFundDailyNetValue(fundId);
  })
);
export const fetchGetFundDetailStockPositionTrend =
  avoidMultiRequestActionThunk<FundStockPositionBody>(
    (body) =>
      `fundDetail.${body.fundId}.fundDetailStockPositionTrend.${body.industryId}`,
    createAsyncThunk(
      "fundDetail/fundDetailStockPositionTrend",
      async (body: FundStockPositionBody) => {
        const response = await getFundDetailStockPositionTrend(body);
        return response;
      }
    )
  );
export const fetchGetFundDetailIndustryTrend =
  avoidMultiRequestActionThunk<FundDetailIndustryBody>(
    (body) =>
      `fundDetail.${body.fundId}.fundDetailIndustryTrend.${body.sectorId}`,
    createAsyncThunk(
      "fundDetail/fundDetailIndustryTrend",
      async (body: FundDetailIndustryBody) => {
        const response = await getFundDetailIndustryTrend(body);
        return response;
      }
    )
  );
export const fetchGetFundDetailETFIndustryTrend =
  avoidMultiRequestActionThunk<FundDetailETFIndustryBody>(
    (body) =>
      `fundDetail.${body.fundId}.fundDetailETFIndustryTrend.${body.sectorId}.${body.date}`,
    createAsyncThunk(
      "fundDetail/fundDetailETFIndustryTrend",
      async (body: FundDetailETFIndustryBody) => {
        const response = await getFundDetailETFIndustryTrend(body);
        return response;
      }
    )
  );
export const fetchFundDetailIndustryTrendDate =
  avoidMultiRequestActionThunk<string>(
    (fundId) => `fundDetail.${fundId}.fundDetailIndustryTrendDate`,
    createAsyncThunk(
      "fundDetail/fundDetailIndustryTrendDate",
      async (fundId: string) => {
        const response = await getFundDetailIndustryTrendDate(fundId);
        return response;
      }
    )
  );
export const fetchGetFundDetailSectorTrend =
  avoidMultiRequestActionThunk<string>(
    (fundId) => `fundDetail.${fundId}.fundDetailSectorTrend`,
    createAsyncThunk(
      "fundDetail/fundDetailSectorTrend",
      async (fundId: string) => {
        const response = await getFundDetailSectorTrend(fundId);
        return response;
      }
    )
  );
export const fetchGetFundSummaryResult = createSocketThunk(
  "fundDetail/FINISH_FUND_SUMMARY",
  "FINISH_FUND_SUMMARY",
  (fundId: string) => {
    return getFundSummaryResult({ fundId });
  }
);
export const fetchGetFundAttributionBrinsonResult = createSocketThunk(
  "fundDetail/FINISH_SINGLE_FUND_BRINSON_ATTRIBUTION",
  "FINISH_SINGLE_FUND_BRINSON_ATTRIBUTION",
  async (v: FundAttributionBrinsonBody) => {
    const response = await getFundAttributionBrinsonResult(v);
    return response;
  }
);
export const fetchGetETFFundAttributionBrinsonResult = createSocketThunk(
  "fundDetail/FINISH_ATTRIBUTION_BRINSON",
  "FINISH_ATTRIBUTION_BRINSON",
  async (v: ETFFundAttributionBrinsonBody) => {
    const response = await getETFFundAttributionBrinsonResult(v);
    return response;
  }
);
export const fetchGetCurrentAndHistoryFundManagers =
  avoidMultiRequestActionThunk<string>(
    (fundId) => `fundDetail.${fundId}.fundDetailFundManagers`,
    createAsyncThunk(
      "fundDetail/fundDetailFundManagers",
      async (fundId: string) => {
        const response = await getCurrentAndHistoryFundManagers(fundId);
        return response;
      }
    )
  );
export const fetchGetCurrentFundManagerReturn = avoidMultiRequestActionThunk(
  (body) => `fundDetail.${body.fundId}.currentFundManagerReturn.${body.id}`,
  createAsyncThunk(
    "fundDetail/currentFundManagerReturn",
    async (body: FundDetailManagersBody) => {
      const response = await getFundManagerDetailDailyReturnApi([body.id]);
      return first(response as any[]);
    }
  )
);
export const fetchGetNewsInfo = createAsyncThunk(
  "fundDetail/NewsInfo ",
  async (params: NewsBody) => {
    const NewsInfo = await getNewsInfo(params);
    return NewsInfo;
  }
);
export const fetchGetAnnouncementInfo = createAsyncThunk(
  "fundDetail/AnnouncementInfo ",
  async (params: AnnouncementBody) => {
    const AnnouncementInfo = await getAnnouncementInfo(params);
    return AnnouncementInfo;
  }
);
export const fetchGetAnnouncementType = avoidMultiRequestActionThunk<void>(
  () => `fundDetail.AnnouncementType`,
  createAsyncThunk("fundDetail/AnnouncementType", async () => {
    const response = await getAnnouncementType();
    return response;
  })
);

export const fetchGetFundManagerImg =
  avoidMultiRequestActionThunk<FundManagerImgBody>(
    (body) => `fundDetail.${body.fundId}.fundManagersImg.${body.managerId}`,
    createAsyncThunk("fundDetail/fundManagersImg", async (body) => {
      const response = await getFileApi(body.url);
      const base64Url = await blobToBase64(response);
      return base64Url;
    })
  );
// 净值归因
export const fetchNetValueAttribution = createSocketThunk(
  "fundDetail/FINISH_FUND_NET_ASSET_VALUE_ATTRIBUTION",
  "FINISH_FUND_NET_ASSET_VALUE_ATTRIBUTION",
  async (v: FundNetValueAttributionParams) => {
    const response = await getFundAttributionNetValueResult(v);
    return response;
  }
);

//净值归因模型
export const fetchNetValueAttributionModel = avoidMultiRequestActionThunk<void>(
  () => `fundDetail.netValueAttributionModel`,
  createAsyncThunk("fundDetail/netValueAttributionModel", async () => {
    const response = await getNetValueAttributionModel();
    return response;
  })
);

// 评价报告-基本信息
export const fetchEvaluationReportInfo = avoidMultiRequestActionThunk<string>(
  (fundId) => `fundDetail.${fundId}.evaluationReportInfo`,
  createAsyncThunk("fundDetail/evaluationReportInfo", async (fundId) => {
    const response = await getEvaluationReportInfo(fundId);
    return response;
  })
);

// 评价报告-能力评价总览
export const fetchEvaluationReportAbilitySummary =
  avoidMultiRequestActionThunk<string>(
    (fundId) => `fundDetail.${fundId}.evaluationReportAbilitySummary`,
    createAsyncThunk(
      "fundDetail/evaluationReportAbilitySummary",
      async (fundId) => {
        const response = await getEvaluationReportAbilitySummary(fundId);
        return response;
      }
    )
  );

// 评价报告-风险探针
export const fetchEvaluationReportRiskSummary =
  avoidMultiRequestActionThunk<string>(
    (fundId) => `fundDetail.${fundId}.evaluationReportRiskSummary`,
    createAsyncThunk(
      "fundDetail/evaluationReportRiskSummary",
      async (fundId) => {
        const response = await getEvaluationReportRiskSummary(fundId);
        return response;
      }
    )
  );

// 评价报告-风险探针
export const fetchManagerEvaluation = createAsyncThunk<
  ManagerEvaluationBody,
  string
>("fundDetail/managerEvaluation", async (fundId) => {
  const response = await getManagerEvaluation(fundId);
  return response;
});

// 评价报告-个股选择能力
export const fetchStockSelectionAbility = avoidMultiRequestActionThunk<string>(
  (fundId) => `fundDetail.${fundId}.stockSelectionAbility`,
  createAsyncThunk("fundDetail/stockSelectionAbility", async (fundId) => {
    const response = await getStockSelectionAbility(fundId);
    return response;
  })
);

// 评价报告-调仓改善能力
export const fetchTurnoverImprovementAbility =
  avoidMultiRequestActionThunk<string>(
    (fundId) => `fundDetail.${fundId}.turnoverImprovementAbility`,
    createAsyncThunk(
      "fundDetail/turnoverImprovementAbility",
      async (fundId) => {
        const response = await getTurnoverImprovementAbility(fundId);
        return response;
      }
    )
  );

//综合业绩
export const fetchComprehensivePerformance =
  avoidMultiRequestActionThunk<string>(
    (fundCode) => `fundDetail.${fundCode}.comprehensivePerformance`,
    createAsyncThunk(
      "fundDetail/comprehensivePerformance",
      async (fundCode: string) => {
        const response = await getComprehensivePerformance(fundCode);
        return response;
      }
    )
  );
//行业配置能力
export const fetchIndustryAbilityEvaluation =
  avoidMultiRequestActionThunk<string>(
    (fundCode) => `fundDetail.${fundCode}.industryAbilityEvaluation`,
    createAsyncThunk(
      "fundDetail/industryAbilityEvaluation",
      async (fundCode: string) => {
        const response = await getIndustryAbilityEvaluation(fundCode);
        return response;
      }
    )
  );

//行情捕获能力
export const fetchMarketCapabilityDetail = avoidMultiRequestActionThunk<string>(
  (fundCode) => `fundDetail.${fundCode}.marketCapabilityDetail`,
  createAsyncThunk(
    "fundDetail/marketCapabilityDetail",
    async (fundCode: string) => {
      const response = await getMarketCapabilityDetail(fundCode);
      return response;
    }
  )
);

//风险探针详情
export const fetchRiskDetail = avoidMultiRequestActionThunk<string>(
  (fundCode) => `fundDetail.${fundCode}.riskDetail`,
  createAsyncThunk("fundDetail/riskDetail", async (fundCode: string) => {
    const response = await getRiskDetail(fundCode);
    return response;
  })
);

type InitStateType = {
  [key: string]: FundBasicType | string[] | any;
  announcementType?: any[];
  netValueAttributionModal?: netValueAttributionModalBody[];
};
const initialState: InitStateType = {};
const fetchGetFundSummaryResultFinish: string =
  fetchGetFundSummaryResult.finish.type;
const fetchGetFundSummaryResultError: string =
  fetchGetFundSummaryResult.error.type;
const fetchGetFundSummaryResultProgress: string =
  fetchGetFundSummaryResult.progress.type;

const fetchGetFundAttributionBrinsonResultFinish: string =
  fetchGetFundAttributionBrinsonResult.finish.type;
const fetchGetFundAttributionBrinsonResultError: string =
  fetchGetFundAttributionBrinsonResult.error.type;
const fetchGetFundAttributionBrinsonResultProgress: string =
  fetchGetFundAttributionBrinsonResult.progress.type;

const fetchGetETFFundAttributionBrinsonResultFinish: string =
  fetchGetETFFundAttributionBrinsonResult.finish.type;
const fetchGetETFFundAttributionBrinsonResultError: string =
  fetchGetETFFundAttributionBrinsonResult.error.type;
const fetchGetETFFundAttributionBrinsonResultProgress: string =
  fetchGetETFFundAttributionBrinsonResult.progress.type;

const fetchNetValueAttributionResultFinish: string =
  fetchNetValueAttribution.finish.type;
const fetchNetValueAttributionResultError: string =
  fetchNetValueAttribution.error.type;
const fetchNetValueAttributionResultProgress: string =
  fetchNetValueAttribution.progress.type;

const fundDetailSlice = createSlice({
  name: "fundDetail",
  initialState,
  reducers: {
    updateFundDetailInfo: (state, action) => {
      const { fundId, key, value } = action.payload;
      if (fundId && key && value) {
        state[fundId] = {
          ...fastProp(fundId)(state),
          [key]: value,
        };
      }
    },
  },
  extraReducers: {
    [fetchGetFundBasic.fulfilled.type]: (state, action) => {
      const res = update("fundStyle", (v) => {
        if (startsWith("MyTag")(v)) return `${getMessage(v)}型`;
        return v;
      })(action.payload);
      state[action.meta?.arg.fundId] = {
        ...state[action.meta?.arg.fundId],
        ...res,
      };
    },
    [fetchGetFundDetailReturn.fulfilled.type]: (state, action) => {
      state[action.meta?.arg.fundId] = {
        ...state[action.meta?.arg.fundId],
        fundDetailReturn: action.payload,
      };
    },
    [fetchGetFundDailyNetValue.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = {
        ...state[action.meta?.arg],
        fundDailyNetValue: action.payload,
      };
    },
    [fetchGetFundDetailStockPositionTrend.fulfilled.type]: (state, action) => {
      const { fundId, industryId } = action.meta?.arg;
      const oldFundDetailStockPositionTrend = prop(
        `${fundId}.fundDetailStockPositionTrend`
      )(state);
      state[fundId] = {
        ...state[fundId],
        fundDetailStockPositionTrend: {
          ...oldFundDetailStockPositionTrend,
          [industryId]: action.payload,
        },
        industryId: industryId,
      };
    },
    [fetchGetFundDetailIndustryTrend.fulfilled.type]: (state, action) => {
      const oldFundDetailIndustryTrend = prop(
        `${action.meta?.arg.fundId}.fundDetailIndustryTrend`
      )(state);
      state[action.meta?.arg.fundId] = {
        ...state[action.meta?.arg.fundId],
        fundDetailIndustryTrend: {
          ...oldFundDetailIndustryTrend,
          [action.meta?.arg.sectorId]: action.payload,
        },
        selectedSectorId: action.meta?.arg.sectorId,
      };
    },
    [fetchGetFundDetailETFIndustryTrend.fulfilled.type]: (state, action) => {
      const { fundId, sectorId, date } = action?.meta?.arg;
      if (fundId && sectorId && date && action.payload) {
        state[fundId] = set(
          `fundDetailETFIndustryTrend.${sectorId}.${date}`,
          action.payload
        )(state[fundId]);
      }
    },
    [fetchFundDetailIndustryTrendDate.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = {
        ...state[action.meta?.arg],
        fundDetailIndustryTrendDate: action.payload,
      };
    },
    [fetchGetFundDetailSectorTrend.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = {
        ...state[action.meta?.arg],
        fundDetailSectorTrend: action.payload,
      };
    },
    [fetchGetFundSummaryResultFinish]: (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        fundSummaryProgress: action.meta,
        fundSummaryTask: fastProp("view")(action.payload),
      };
    },
    [fetchGetFundSummaryResultError]: (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        fundSummaryProgress: action.error,
      };
    },
    [fetchGetFundSummaryResultProgress]: (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        fundSummaryProgress: action.meta,
      };
    },
    [fetchGetFundAttributionBrinsonResultFinish]: (state, action) => {
      state[action.meta?.arg.assetId] = {
        ...state[action.meta?.arg.assetId],
        fundAttributionBrinsonProgress: action.meta,
        fundAttributionBrinsonResult: action.payload,
      };
    },

    [fetchGetFundAttributionBrinsonResultError]: (state, action) => {
      state[action.meta?.arg.assetId] = {
        ...state[action.meta?.arg.assetId],
        fundAttributionBrinsonProgress: action.error,
      };
    },
    [fetchGetFundAttributionBrinsonResultProgress]: (state, action) => {
      state[action.meta?.arg.assetId] = {
        ...state[action.meta?.arg.assetId],
        fundAttributionBrinsonProgress: action?.payload,
      };
    },
    [fetchGetETFFundAttributionBrinsonResultFinish]: (state, action) => {
      state[action.meta?.arg.portfolioId] = {
        ...state[action.meta?.arg.portfolioId],
        fundAttributionBrinsonProgress: action.meta,
        fundAttributionBrinsonResult: action.payload,
      };
    },

    [fetchGetETFFundAttributionBrinsonResultError]: (state, action) => {
      state[action.meta?.arg.portfolioId] = {
        ...state[action.meta?.arg.portfolioId],
        fundAttributionBrinsonProgress: action.error,
      };
    },
    [fetchGetETFFundAttributionBrinsonResultProgress]: (state, action) => {
      state[action.meta?.arg.portfolioId] = {
        ...state[action.meta?.arg.portfolioId],
        fundAttributionBrinsonProgress: action?.payload,
      };
    },
    [fetchGetCurrentAndHistoryFundManagers.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = {
        ...state[action.meta?.arg],
        fundDetailFundManagers: action.payload,
      };
    },
    [fetchGetCurrentFundManagerReturn.fulfilled.type]: (state, action) => {
      const oldCurrentFundManagerReturn = prop(
        `${action.meta?.arg.fundId}.currentFundManagerReturn`
      )(state);
      const id = action.meta?.arg?.id;
      state[action.meta?.arg.fundId] = {
        ...state[action.meta?.arg.fundId],
        currentFundManagerReturn: {
          ...oldCurrentFundManagerReturn,
          [id]: action.payload,
        },
      };
    },
    [fetchGetNewsInfo.fulfilled.type]: (state, action) => {
      state[action.meta?.arg.fundId] = {
        ...state[action.meta?.arg.fundId],
        NewsInfo: action.payload,
      };
    },
    [fetchGetAnnouncementInfo.fulfilled.type]: (state, action) => {
      state[action.meta?.arg.fundId] = {
        ...state[action.meta?.arg.fundId],
        AnnouncementInfo: action.payload,
      };
    },
    [fetchGetFundManagerImg.fulfilled.type]: (state, action) => {
      state[action.meta?.arg.fundId] = {
        ...state[action.meta?.arg.fundId],
        fundManagersImg: {
          [action.meta?.arg?.managerId]: action.payload,
        },
      };
    },
    [fetchGetAnnouncementType.fulfilled.type]: (state, action) => {
      state.announcementType = action.payload;
    },
    [fetchNetValueAttributionResultFinish]: (state, action) => {
      const { fundId, section, factorModelId } = action.meta?.arg;
      state[fundId] = set(
        `netValueAttributionResult.${factorModelId}.netValueAttributionProgress.${section}`,
        action.meta
      )(state[fundId]);
      state[fundId] = set(
        `netValueAttributionResult.${factorModelId}.netValueAttribution.${section}`,
        action.payload
      )(state[fundId]);
    },

    [fetchNetValueAttributionResultError]: (state, action) => {
      const { fundId, section, factorModelId } = action.meta?.arg;
      state[fundId] = set(
        `netValueAttributionResult.${factorModelId}.netValueAttributionProgress.${section}`,
        action.error
      )(state[fundId]);
    },
    [fetchNetValueAttributionResultProgress]: (state, action) => {
      const { fundId, section, factorModelId } = action.meta?.arg;
      state[fundId] = set(
        `netValueAttributionResult.${factorModelId}.netValueAttributionProgress.${section}`,
        action.payload
      )(state[fundId]);
    },
    [fetchEvaluationReportInfo.fulfilled.type]: (state, action) => {
      const fundId = action.meta?.arg;
      state[fundId] = set(
        `evaluationReportInfo`,
        action.payload
      )(state[fundId]);
    },
    [fetchStockSelectionAbility.fulfilled.type]: (state, action) => {
      const fundId = action.meta?.arg;
      state[fundId] = set(
        `stockSelectionAbility`,
        action.payload
      )(state[fundId]);
    },
    [fetchTurnoverImprovementAbility.fulfilled.type]: (state, action) => {
      const fundId = action.meta?.arg;
      state[fundId] = set(
        `turnoverImprovementAbility`,
        action.payload
      )(state[fundId]);
    },
    [fetchEvaluationReportAbilitySummary.fulfilled.type]: (state, action) => {
      const fundId = action.meta?.arg;
      state[fundId] = set(
        `evaluationReportAbilitySummary`,
        action.payload
      )(state[fundId]);
    },
    [fetchEvaluationReportRiskSummary.fulfilled.type]: (state, action) => {
      const fundId = action.meta?.arg;
      state[fundId] = set(
        `evaluationReportRiskSummary`,
        action.payload
      )(state[fundId]);
    },
    [fetchNetValueAttributionModel.fulfilled.type]: (state, action) => {
      state.netValueAttributionModel = action.payload;
    },
    [fetchComprehensivePerformance.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = {
        ...state[action.meta?.arg],
        comprehensivePerformance: action.payload,
      };
    },
    [fetchIndustryAbilityEvaluation.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = {
        ...state[action.meta?.arg],
        industryAbilityEvaluation: action.payload,
      };
    },
    [fetchMarketCapabilityDetail.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = {
        ...state[action.meta?.arg],
        marketCapabilityDetail: action.payload,
      };
    },
    [fetchRiskDetail.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = {
        ...state[action.meta?.arg],
        riskDetail: action.payload,
      };
    },
  },
});

export const { updateFundDetailInfo } = fundDetailSlice.actions;
export default fundDetailSlice.reducer;
