import { useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { Col, Empty, Row, Table, TableProps, Tabs } from "antd";
import React, { useMemo, useState } from "react";
import style from "../index.module.less";
import BarChart from "@/echarts/barChart";
import { concat, isEmpty, isNil, map, prop, size } from "lodash/fp";
import { fastProp, fastSize } from "@/util/opt";
import {
  formatPercentage,
  fixedNumberWithCommas,
} from "@/util/numberFormatter";
import mapper from "../mapper";
import { useCreation } from "ahooks";
import { formatYAxisEllipsis, formatYAxisText } from "../constant";
import { useGetBarChartOptions } from "../hook";
import { sortAntdTable } from "@/util/sortTable";
import BaseChart from "@/base-components/charts/baseChart";
import { EChartsOption } from "echarts";

const { TabPane } = Tabs;

export const AssetContribution = ({
  contributionNames,
  contributionMap,
}: {
  contributionNames: string[];
  contributionMap: Record<string, any>;
}) => {
  const options = useMemo<EChartsOption>(
    () => ({
      grid: {
        bottom: 10,
        right: 50,
        top: 10,
      },
      xAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        axisLabel: {
          formatter: (val: number) => formatPercentage(val),
        },
        min: (value: any) => {
          const min = Number(value?.min);
          if (min < 0) return min / (1 - 0.6);
          return 0;
        },
        max: (value: any) => {
          const max = Number(value?.max);
          if (max > 0) return max / (1 - 0.4);
          return 0;
        },
      },
      yAxis: {
        type: "category",
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        data: contributionNames,
        // axisLabel: {
        //   formatter: (val: string) => val,
        // },
        axisLabel: {
          formatter: (val: string) => formatYAxisText(val),
          margin: 48,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
        formatter: (...args: any) => {
          const { name, value } = args[0][0] || {};
          return `${name || "--"}: ${formatPercentage(value)}`;
        },
      },
      series: [
        {
          barMaxWidth: 40,
          colorBy: "data",
          showBackground: true,
          backgroundStyle: {
            color: "#EFF2F8",
          },
          data: map((v: string) => {
            const value = prop(`${v}.contribution`)(contributionMap);
            return {
              value,
              itemStyle: {
                color: value >= 0 ? "#F26060" : "#55AB8A",
              },
              label: {
                show: true,
                color: value >= 0 ? "#F26060" : "#55AB8A",
                position: value >= 0 ? "right" : "left",
                formatter: ({ data }: any) => {
                  return formatPercentage(fastProp("value")(data));
                },
              },
            };
          })(contributionNames),
        },
      ],
    }),
    [contributionMap, contributionNames]
  );
  const dataSource = useGetBarChartOptions(
    contributionNames,
    contributionMap,
    options
  );
  return isEmpty(contributionNames) ? (
    <Empty />
  ) : (
    <BarChart height={400} options={dataSource} />
  );
};

export const ReturnContribution = ({
  contributionsNames,
  type,
  contributionsMap,
  maxSize,
}: {
  contributionsNames: string[];
  contributionsMap: Record<string, any>;
  maxSize: number;
  type?: string;
}) => {
  const newContributionNames = useMemo(
    () =>
      concat(
        new Array(
          maxSize > size(contributionsNames)
            ? maxSize - size(contributionsNames)
            : 0
        ).fill("")
      )(contributionsNames),
    [contributionsNames, maxSize]
  );
  const options = useMemo(
    () => ({
      grid: {
        bottom: 10,
        top: 10,
        right: 0,
        left: 0,
        containLabel: true,
      },
      legend: {
        top: 0,
        left: 0,
        align: "left",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
        formatter: (...args: any) => {
          const value = args[0][0]?.value;
          if (isNil(value)) return "";
          return `${args[0][0]?.name || "--"}: ${formatPercentage(value)}`;
        },
      },
      xAxis: {
        type: "value",
        show: false,
        min: (value: any) => {
          const min = Number(value?.min);
          if (min < 0) return min / (1 - 0.5);
          return 0;
        },
        max: (value: any) => {
          const max = Number(value?.max);
          if (max > 0) return max / (1 - 0.5);
          return 0;
        },
      },
      yAxis: {
        type: "category",
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        position: type === "lowest" ? "left" : "right",
        data: formatYAxisEllipsis(newContributionNames),
        // axisLabel: {
        //   formatter: (val: string) => val,
        // },
        axisLabel: {
          formatter: (val: string) => formatYAxisText(val),
        },
      },
      series: [
        {
          type: "bar",
          data: map((v: string) => prop(`${v}.contribution`)(contributionsMap))(
            newContributionNames
          ),
          showBackground: true,
          backgroundStyle: {
            color: "#EFF2F8",
          },
          itemStyle: {
            color: type === "lowest" ? "#55AB8A" : "#F26060",
          },
          label: {
            show: true,
            position: type === "lowest" ? "left" : "right",
            color: type === "lowest" ? "#55AB8A" : "#F26060",
            formatter: ({ data }: any) => {
              return formatPercentage(data);
            },
          },
          barMaxWidth: 40,
          barMinWidth: 20,
        },
      ],
    }),
    [contributionsMap, newContributionNames, type]
  );
  return isEmpty(contributionsMap) ? (
    <Empty />
  ) : (
    <BaseChart option={options} height={400} />
  );
};

type TablePropsType = TableProps<any>;

const useCalculateScrollY = (dataSourceSize: number) => {
  return useCreation(
    () =>
      dataSourceSize * 35 > 350
        ? {
            y: 350,
          }
        : undefined,
    [dataSourceSize]
  );
};
export const FundCompanyContribution = ({
  fundCompanyContribution,
  portfolioType,
}: {
  fundCompanyContribution: Record<string, string | number>[];
  portfolioType: string;
}) => {
  const formatMessage = useFormatMessage();

  const extraColumns = useMemo(
    () =>
      portfolioType === "aum"
        ? [
            {
              key: "positionScale",
              dataIndex: "positionScale",
              title: formatMessage("positionSize"),
              render: (positionScale: number) =>
                fixedNumberWithCommas(positionScale),
              align: "right",
              sortDirections: ["descend", "ascend", "descend"],
              sorter: sortAntdTable("positionScale"),
              width: 110,
            },
          ]
        : [],
    [formatMessage, portfolioType]
  );
  const columns = useMemo<TablePropsType["columns"]>(
    () => [
      {
        key: "serialNumber",
        dataIndex: "serialNumber",
        title: formatMessage("serialNumber"),
        align: "left",
        render: (text, record, index) => {
          return index + 1;
        },
        width: 50,
      },
      {
        key: "name",
        dataIndex: "name",
        title: formatMessage("fundCompanys"),
        align: "center",
        ellipsis: true,
      },
      {
        key: "contribution",
        dataIndex: "contribution",
        title: formatMessage("performanceContribution"),
        render: formatPercentage,
        align: "right",
        defaultSortOrder: "descend",
        sortDirections: ["descend", "ascend", "descend"],
        sorter: sortAntdTable("contribution"),
        width: 110,
      },
      ...(extraColumns as []),
    ],
    [extraColumns, formatMessage]
  );
  const bordered = useMemo(
    () => !isEmpty(fundCompanyContribution),
    [fundCompanyContribution]
  );
  const scroll = useCalculateScrollY(size(fundCompanyContribution));
  return (
    <Table
      className={style.FundCompanyContribution}
      pagination={false}
      columns={columns}
      dataSource={fundCompanyContribution}
      bordered={bordered}
      scroll={scroll}
    />
  );
};

export default React.memo(({ id, range }: { id: string; range: string }) => {
  const [menuSelectedKeys, setMenuSelectedKeys] = useState("assetContribution");
  const formatMessage = useFormatMessage();

  const {
    fundCompanyContributionSelector: fundCompanyContribution,
    assetContributionSelector: { assetContributionNames, assetContributionMap },
    topFundContributionsSelector: {
      topFundContributionsNames,
      topFundContributionsMap,
    },
    lowestFundContributionsSelector: {
      lowestFundContributionsNames,
      lowestFundContributionsMap,
    },
  } = useAppSelector((state) => mapper(state, id, range));

  const maxSize = useMemo(() => {
    return fastSize(topFundContributionsNames) >
      fastSize(lowestFundContributionsNames)
      ? fastSize(topFundContributionsNames) || 0
      : fastSize(lowestFundContributionsNames) || 0;
  }, [topFundContributionsNames, lowestFundContributionsNames]);

  return (
    <Row gutter={14}>
      <Col span={12}>
        <div className={style.BarChartBox}>
          <Tabs onChange={setMenuSelectedKeys} activeKey={menuSelectedKeys}>
            <TabPane
              forceRender
              tab={formatMessage("assetContribution")}
              key="assetContribution"
            >
              <AssetContribution
                contributionMap={assetContributionMap}
                contributionNames={assetContributionNames}
              />
            </TabPane>
            <TabPane
              forceRender
              tab={formatMessage("fundComponyContribution")}
              key="fundComponyContribution"
            >
              <FundCompanyContribution
                portfolioType="portfolio"
                fundCompanyContribution={fundCompanyContribution}
              />
            </TabPane>
          </Tabs>
        </div>
      </Col>
      <Col span={12}>
        <div className={style.BarChartBox}>
          <Tabs activeKey="top10ContributionFund">
            <TabPane
              forceRender
              tab={formatMessage("top10ContributionFund")}
              key="top10ContributionFund"
            >
              <Row align="middle">
                <Col span={12}>
                  <ReturnContribution
                    type="lowest"
                    contributionsNames={lowestFundContributionsNames}
                    contributionsMap={lowestFundContributionsMap}
                    maxSize={maxSize}
                  />
                </Col>
                <Col span={12}>
                  <ReturnContribution
                    type="top"
                    contributionsNames={topFundContributionsNames}
                    contributionsMap={topFundContributionsMap}
                    maxSize={maxSize}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </Col>
    </Row>
  );
});
