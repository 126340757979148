import { TradingDates } from "../tradingDate";

export type NormalDatePeriodName =
  | "RECENT_WEEK"
  | "RECENT_MONTH"
  | "RECENT_THREE_MONTH"
  // | "RECENT_SIX_MONTH"
  | "RECENT_HALF_YEAR"
  | "FROM_THIS_YEAR"
  | "RECENT_YEAR"
  | "RECENT_TWO_YEAR"
  | "RECENT_THREE_YEAR"
  | "RECENT_FIVE_YEAR"
  | "FROM_CREATION";

export type DatePeriodName = NormalDatePeriodName | "COMMON_TIME";
export type PeriodDate = [start: string, end: string];
export type DatesArray = TradingDates[];

export const DatePeriodMessage = {
  COMMON_TIME: "commonTime",
  RECENT_WEEK: "RECENT_WEEK",
  RECENT_MONTH: "RECENT_MONTH",
  RECENT_THREE_MONTH: "RECENT_THREE_MONTH",
  RECENT_SIX_MONTH: "RECENT_SIX_MONTH",
  RECENT_HALF_YEAR: "RECENT_HALF_YEAR",
  FROM_THIS_YEAR: "FROM_THIS_YEAR",
  RECENT_YEAR: "RECENT_YEAR",
  RECENT_THREE_YEAR: "RECENT_THREE_YEAR",
  FROM_CREATION: "FROM_CREATION",
  RECENT_TWO_YEAR: "RECENT_TWO_YEARS",
  RECENT_FIVE_YEAR: "RECENT_FIVE_YEAR",
};
