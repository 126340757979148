import React, { ReactElement, ReactNode, useEffect, useRef } from "react";
import { Breadcrumb, Layout } from "antd";
import cn from "classnames";
import { LeftOutlined } from "@ant-design/icons";
import { useFormatMessage } from "@/util/formatMessage";
import style from "./index.module.less";
import {
  concat,
  dropRight,
  findIndex,
  includes,
  last,
  map,
  set,
  size,
  take,
} from "lodash/fp";
import NavigationContext from "./context";
import { useGenerateProviderValues, generateIdentify } from "./hook";
import { NavigationStacks, NavigationContextIdentify } from "./interface";
import { fastProp } from "@/util/opt";
import { useCreation, useMemoizedFn, useSafeState } from "ahooks";
import { shallowEqual } from "react-redux";
import { useWindowSizeContext } from "../windowSizeProvider";

const notShownKey = ["mockPortfolio", "compareManage", "myOptional", "aum"];
const validateRootMessageKey = (key: string) => {
  if (includes(key)(notShownKey)) return undefined;
  return key;
};

// function WithCommonProps({
//   children,
//   contentHeight,
//   contentWidth,
// }: {
//   children: ReactElement;
//   contentHeight: number;
//   contentWidth: number;
// }) {
//   return React.cloneElement(children, { contentWidth, contentHeight });
// }

export default React.memo<{
  rootKey: string;
  children: ReactElement;
}>(({ rootKey, children }) => {
  const formatMessage = useFormatMessage();
  const [navigationStacks, setNavigationStacks] = useSafeState<
    NavigationContextIdentify[]
  >([]);
  useEffect(() => {
    if (rootKey) setNavigationStacks([]);
  }, [rootKey, setNavigationStacks]);

  const handleNavigationStacks = useMemoizedFn((value: NavigationStacks) =>
    setNavigationStacks((stacks) => {
      const existNavigationIndex = findIndex<NavigationContextIdentify>(
        ({ id, params }) =>
          id === value.id && shallowEqual(params, value.params)
      )(stacks);
      if (existNavigationIndex !== -1) {
        return take(existNavigationIndex + 1)(stacks);
      }
      return concat(stacks)(
        set(
          "identifyId",
          generateIdentify(value.id, size(stacks))
        )(value) as NavigationContextIdentify
      );
    })
  );
  const stackBack = useMemoizedFn(() => setNavigationStacks(dropRight(1)));
  const providerValues = useGenerateProviderValues(handleNavigationStacks);

  const activeKey = useCreation(
    () => fastProp("identifyId")(last(navigationStacks)) || rootKey,
    [navigationStacks, rootKey]
  );
  const getRendererClassName = useMemoizedFn((key: string) =>
    cn({
      [style.Hidden]: key !== activeKey,
      [style.FullHeight]: true,
    })
  );
  const activeMessageKey = useCreation(() => {
    const Key = fastProp("id")(last(navigationStacks)) || rootKey;
    return validateRootMessageKey(Key);
  }, [activeKey, navigationStacks, rootKey]);
  const { containerHeight } = useWindowSizeContext();
  const contentHeight = containerHeight - (activeMessageKey ? 48 : 16) - 16;
  const withStackBackProviderValues = useCreation(
    () => ({
      stackBack,
      ...providerValues,
      contentHeight,
    }),
    [providerValues, stackBack]
  );

  const rootEleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    rootEleRef?.current?.scrollTo(0, 0);
  }, [navigationStacks]);
  return (
    <NavigationContext.Provider value={withStackBackProviderValues}>
      <div className={style.Container} ref={rootEleRef}>
        <Breadcrumb className={style.Breadcrumb}>
          {activeKey && (
            <Breadcrumb.Item className={style.BreadcrumbItem}>
              {size(navigationStacks) > 0 && (
                <>
                  <LeftOutlined onClick={stackBack} />
                  &nbsp;&nbsp;
                </>
              )}{" "}
              {activeMessageKey && formatMessage(activeMessageKey)}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
        <Layout.Content
          className={style.Content}
          style={{ minHeight: contentHeight }}
        >
          <div className={getRendererClassName(rootKey)}>
            {children}
            {/* <WithCommonProps contentWidth={0} contentHeight={contentHeight}>{children}</WithCommonProps> */}
          </div>
          {map<NavigationContextIdentify, ReactNode>(
            ({ Component, params, identifyId }) => (
              <div
                className={getRendererClassName(identifyId)}
                key={identifyId}
              >
                <Component {...params} />
              </div>
            )
          )(navigationStacks)}
        </Layout.Content>
      </div>
    </NavigationContext.Provider>
  );
});
