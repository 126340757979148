import { ServerToken } from "@/model/fileInfo";
import { PromiseType } from "@/model/promise";
import { post, get } from "@/util/axios";
import { fastProp } from "@/util/opt";

const getFileServerTokenApi = (): PromiseType<string> =>
  get<ServerToken>("/fileauth").then((data) => fastProp("token")(data));

export const getFileApi = (url: string): PromiseType<Blob> =>
  getFileServerTokenApi().then((token) =>
    post(
      `/datafileRES?token=${token}`,
      { filePath: url },
      {
        responseType: "blob",
      },
      "/assets"
    )
  );
