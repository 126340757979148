import { normalize } from "@/util/opt";
import { map } from "lodash/fp";

export const YIELD_FACTORS_INFO = [
  {
    id: "f7aaeaf3-c97b-4b3f-9cb3-7dbf3d8596ad",
    key: "oneWeekReturn",
    section: "RECENT_WEEK",
  },
  {
    id: "71c964ab-779a-497b-becf-8abe658739c3",
    key: "oneMonthReturn",
    section: "RECENT_MONTH",
  },
  {
    id: "2d6aada8-319f-4e5b-b9e8-88509b14a7c6",
    key: "threeMonthReturn",
    section: "RECENT_THREE_MONTH",
  },
  {
    id: "b5b3fe13-339e-4e37-b493-00abd661fc0e",
    key: "halfYearReturn",
    section: "RECENT_HALF_YEAR",
  },
  {
    id: "c0a42b00-c295-44a2-8f50-bf843b333958",
    key: "thisYearReturn",
    section: "FROM_THIS_YEAR",
  },
  {
    id: "21eac6b5-80ed-46b7-a8aa-561f8ca8c456",
    key: "oneYearReturn",
    section: "RECENT_YEAR",
  },
  {
    id: "a92aedbf-227e-4a03-9772-97182fa163b0",
    key: "twoYearReturn",
    section: "RECENT_TWO_YEAR",
  },
  {
    id: "125dce85-1809-4336-a745-ae53c384d3d8",
    key: "threeYearReturn",
    section: "RECENT_THREE_YEAR",
  },
];

export const YIELD_FACTORS_IDS = map("id")(YIELD_FACTORS_INFO);
export const YIELD_FACTORS_SECTION = map("section")(YIELD_FACTORS_INFO);
export const YIELD_FACTORS_KEYS = map("key")(YIELD_FACTORS_INFO);

export const YIELD_FACTORS_IDS_MAP = normalize("id")(YIELD_FACTORS_INFO);
export const YIELD_FACTORS_SECTION_MAP =
  normalize("section")(YIELD_FACTORS_INFO);
