import { useMemo } from "react";
import { BarChartOpts } from "@/echarts/barChart";
import { map, prop } from "lodash/fp";
import { formatPercentage } from "@/util/numberFormatter";
export const useGetBarChartOptions = (
  data: string[],
  dataMap: Record<string, any>,
  options: any
): BarChartOpts["options"] => {
  return useMemo<BarChartOpts["options"]>(
    () => ({
      categories: data,
      grid: {
        bottom: 10,
        right: 50,
      },
      yAxis: {
        type: "category",
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
        formatter: (...args: any) => {
          const value = args[0][0].value || ["", 0];
          return `${value[0] || "--"}: ${formatPercentage(value[1])}`;
        },
      },
      xAxis: {
        type: "value",
        axisLabel: {
          formatter: formatPercentage,
        },
        min: (value: any) => {
          const min = Number(value.min);
          if (value.min < 0) return min / (1 - 0.5);
          return 0;
        },
        max: (value: any) => {
          const max = Number(value.max);
          if (value.max > 0) return max / (1 - 0.5);
          return 0;
        },
      },
      legend: {
        show: false,
      },
      data: {
        weight: map((v: string) => prop(`${v}.contribution`)(dataMap))(data),
      },
      ...options,
    }),
    [data, dataMap, options]
  );
};
