import { factorRatioFuncs } from "@/constant/portfolioAnalysis";
import { FROM_CREATION } from "@/constant/statisticRange";
import { RootState } from "@/store";
import { fastProp, mapIndexed, normalize } from "@/util/opt";
import { getPreviousTradingDate } from "@/util/processedDates";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { createSelector } from "@reduxjs/toolkit";
import { concat, first, flow, fromPairs, last, map } from "lodash/fp";
import {
  getAumPortfolioReturns,
  getCalculateBasicBrinsonData,
  names,
  needCalcFactors,
} from "./constant";

export const brinsonBasicInfoSelector = createSelector(
  (state: RootState) => state.aum.brinsonAttribution,
  (result) => {
    return getCalculateBasicBrinsonData(result);
  }
);

export const aumPortfolioBasicInfoSelector = createSelector(
  (state: RootState) => state.aum.portfolioPerformanceTrend,
  (portfolioTrend) => {
    return fastProp(FROM_CREATION)(portfolioTrend);
  }
);

export const brinsonLineChartSelector = createSelector(
  brinsonBasicInfoSelector,
  aumPortfolioBasicInfoSelector,
  (state: RootState) => state.tradingDates.tradingDateList,
  (state: RootState) => state.tradingDates.processedTradingDates,
  (state: RootState) => state.entities.riskFreeRate,
  (
    result,
    portfolioReturnInfo,
    tradingDates,
    processedTradingDates,
    riskFreeRates
  ) => {
    const {
      cumulativePortfolioActualAttributions,
      cumulativeBenchmarkActualAttributions,
      cumulativeSelectionAttributions,
      cumulativeAllocationAttributions,
      dates,
    } = result;
    const portfolioDailyReturnsMap = normalizeDailyReturnsMap(
      fastProp("dates")(portfolioReturnInfo) || [],
      fastProp("portfolioReturns")(portfolioReturnInfo) || []
    );
    const benchmarkDailyReturnsMap = normalizeDailyReturnsMap(
      dates,
      fastProp("benchmarkReturns")(portfolioReturnInfo) || []
    );

    const portfolioDaliReturns = getAumPortfolioReturns({
      dates,
      portfolioDailyReturnsMap,
      benchmarkDailyReturnsMap,
      riskFreeRates,
    });

    const res = flow(
      map((v: string) => {
        const func = fastProp(v)(factorRatioFuncs);
        return [v, func ? func(portfolioDaliReturns) : null];
      }),
      fromPairs
    )(needCalcFactors);

    const firstDate = getPreviousTradingDate(
      tradingDates,
      processedTradingDates,
      first(dates) as string
    );
    const chartDates = concat(firstDate)(dates);
    const firstChartData = {
      date: firstDate,
      portfolioAttribution: 0,
      benchmarkAttribution: 0,
      allocationAttribution: 0,
      selectionFundAttribution: 0,
    };
    const chartData = mapIndexed((date: string, index: number) => ({
      date,
      portfolioAttribution: cumulativePortfolioActualAttributions[index],
      benchmarkAttribution: cumulativeBenchmarkActualAttributions[index],
      allocationAttribution: cumulativeAllocationAttributions[index],
      selectionFundAttribution: cumulativeSelectionAttributions[index],
    }))(dates);

    return {
      dates: fastProp("dates")(result) || [],
      listData: {
        ...res,
        portfolioActualAttribution: last(cumulativePortfolioActualAttributions),
        benchmarkActualAttribution: last(cumulativeBenchmarkActualAttributions),
        allocationAttribution: last(cumulativeAllocationAttributions),
        // 选基金业绩
        selectionAttribution: last(cumulativeSelectionAttributions),
      },
      chartData: {
        names: names,
        dates: chartDates,
        dataSource: normalize("date")(concat(firstChartData)(chartData)),
      },
    };
  }
);

export const brinsonTreeChartDataSelector = createSelector(
  brinsonBasicInfoSelector,
  (result) => {
    const {
      cumulativeTimingOrInteractionAttribution,
      cumulativePortfolioActualAttribution,
      cumulativeAllocationAttribution,
      cumulativeBenchmarkActualAttribution,
      cumulativeSelectionAttribution,
      cumulativeActiveAttribution,
      otherAttribution,
    } = result;

    return {
      portfolioAttribution: cumulativePortfolioActualAttribution,
      benchmarkAttribution: cumulativeBenchmarkActualAttribution,
      activeAttribution: cumulativeActiveAttribution,
      allocationAttribution: cumulativeAllocationAttribution,
      selectionAttribution: cumulativeSelectionAttribution,
      timingOrInteractionAttribution: cumulativeTimingOrInteractionAttribution,
      otherAttribution: otherAttribution,
    };
  }
);
