import {
  getFundManagerDetailBasicInfoApi,
  getFundManagerDetailDailyReturnApi,
  getFundManagerScaleTrendApi,
  getFundManagerManageFundInfoApi,
  getFundManagerManageFundPerformanceInfoApi,
  GetFundManagerManageFundPerformanceInfoParam,
  getFundManagerYearReportDateApi,
  getFundManagerReportDateApi,
  GetFundManagerIndustryInfoParam,
  getFundManagerIndustryInfoApi,
  getFundManagerMarketValueInfoApi,
} from "@/api/fundManagerDetail";
import {
  FundManagerDetailBasicInfo,
  FundManagerDetailDailyReturnInfo,
  FundManagerIndustryInfo,
  FundManagerManageFundInfo,
  FundManagerManagePerformanceInfo,
  FundManagerScaleTrend,
  FundManagerScaleTrendInfo,
  MarketValueInfo,
  FundManagerReportDateType,
} from "@/model/fundManagerDetail";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { identity, orderBy, set, first } from "lodash/fp";
import { blobToBase64 } from "@/util/file";
import { getFileApi } from "@/api/fileInfo";
import { fastProp } from "@/util/opt";

type ManagerDetailType = {
  basicInfo: FundManagerDetailBasicInfo;
  managerAvatar: string;
  dailyReturnInfo: {
    dates: string[];
    returns: number[];
  };
  scaleTrend: FundManagerScaleTrend;
  fundManageInfo: FundManagerManageFundInfo;
  yield: {
    benchmarkId: string;
    activeRange: string;
  };
  drawDown: {
    benchmarkId: string;
    activeRange: string;
  };
  manageFund: {
    scaleRange: string;
    performanceRankRange: string;
    yieldBenchmarkId: string;
    yieldRange: string;
  };
  fundManagerPerformanceInfo: {
    [propsName: string]: FundManagerManagePerformanceInfo;
  };
  positionInfo: {
    industrySectorId: string;
    industryReportDate: string;
    positionReportDate: string;
  };
  reportDate: string[];
  industryInfo: {
    [propsName: string]: Record<string, number>;
  };
  marketValueInfo: {
    [propsName: string]: Record<string, number>;
  };
};
type InitStateType = {
  [propsName: string]: ManagerDetailType | any;
};
const initialState: InitStateType = {};

export const getFundManagerDetailBasicInfo =
  avoidMultiRequestActionThunk<string>(
    (id) => `fundManagerDetail.${id}.basicInfo`,
    createAsyncThunk(
      "fundManagerDetail/getFundManagerDetailBasicInfo",
      async (id) => {
        const response = await getFundManagerDetailBasicInfoApi(id);
        return response;
      }
    )
  );

export const getFundManagerDetailDailyReturn =
  avoidMultiRequestActionThunk<string>(
    (id) => `fundManagerDetail.${id}.dailyReturnInfo`,
    createAsyncThunk(
      "fundManagerDetail/getFundManagerDetailDailyReturn",
      async (id) => {
        const response = await getFundManagerDetailDailyReturnApi([id]);
        return first(response as FundManagerDetailDailyReturnInfo[]);
      }
    )
  );

export const getFundManagerScaleTrend = avoidMultiRequestActionThunk<string>(
  (id) => `fundManagerDetail.${id}.scaleTrend`,
  createAsyncThunk("fundManagerDetail/getFundManagerScaleTrend", async (id) => {
    const response = await getFundManagerScaleTrendApi([id]);
    return first(response as FundManagerScaleTrendInfo[]);
  })
);

export const getFundManagerManageFundInfo =
  avoidMultiRequestActionThunk<string>(
    (id) => `fundManagerDetail.${id}.fundManageInfo`,
    createAsyncThunk(
      "fundManagerDetail/getFundManagerManageFundInfo",
      async (id: string) => {
        const response = await getFundManagerManageFundInfoApi(id);
        return response;
      }
    )
  );

export const getFundManagerManageFundPerformanceInfo =
  avoidMultiRequestActionThunk<GetFundManagerManageFundPerformanceInfoParam>(
    (params) =>
      `fundManagerDetail.${params.id}.fundManagerPerformanceInfo.${params.section}`,
    createAsyncThunk(
      "fundManagerDetail/getFundManagerManageFundPerformanceInfo",
      async (param: GetFundManagerManageFundPerformanceInfoParam) => {
        const response = await getFundManagerManageFundPerformanceInfoApi(
          param
        );
        return response;
      }
    )
  );

export const getFundManagerAvatar = avoidMultiRequestActionThunk<{
  id: string;
  url: string;
}>(
  (param) => `fundManagerDetail.${param.id}.managerAvatar`,
  createAsyncThunk("fundManagerDetail/getFundManagerAvatar", async (param) => {
    const response = await getFileApi(param.url);
    const base64Url = await blobToBase64(response);
    return base64Url;
  })
);
export const getFundManagerYearReportDate =
  avoidMultiRequestActionThunk<string>(
    (id) => `fundManagerDetail.${id}.reportYearDate`,
    createAsyncThunk(
      "fundManagerDetail/getFundManagerYearReportDate",
      async (id) => {
        const response = await getFundManagerYearReportDateApi([id]);
        return fastProp(id)(response as FundManagerReportDateType);
      }
    )
  );

export const getFundManagerReportDate = avoidMultiRequestActionThunk<string>(
  (id) => `fundManagerDetail.${id}.reportDate`,
  createAsyncThunk("fundManagerDetail/getFundManagerReportDate", async (id) => {
    const response = await getFundManagerReportDateApi([id]);
    return response;
  })
);

export const getFundManagerIndustryInfo =
  avoidMultiRequestActionThunk<GetFundManagerIndustryInfoParam>(
    (params) =>
      `fundManagerDetail.${params.id}.industryInfo.${params.sectorId}.${params.reportDate}`,
    createAsyncThunk(
      "fundManagerDetail/getFundManagerIndustryInfo",
      async (params) => {
        const param = {
          ids: [params.id],
          sectorId: params.sectorId,
          reportDate: params.reportDate,
        };
        const response = await getFundManagerIndustryInfoApi(param);
        return fastProp("industryWeight")(
          first(response as FundManagerIndustryInfo[])
        );
      }
    )
  );
export const getFundManagerMarketValueInfo = avoidMultiRequestActionThunk<{
  id: string;
  reportDate: string;
}>(
  (params) =>
    `fundManagerDetail.${params.id}.marketValueInfo.${params.reportDate}`,
  createAsyncThunk(
    "fundManagerDetail/getFundManagerMarketValueInfo",
    async (params) => {
      const param = {
        ids: [params.id],
        reportDate: params.reportDate,
      };
      const response = await getFundManagerMarketValueInfoApi(param);
      return fastProp("stockMarketValue")(first(response as MarketValueInfo[]));
    }
  )
);

const fundManagerDetailSlice = createSlice({
  name: "fundManagerDetail",
  initialState,
  reducers: {
    updateManagerDetailInfo(state, action) {
      const { key, value, id } = action?.payload;
      if (id && key && value) {
        state[id] = set(key, value)(state[id]);
      }
    },
  },
  extraReducers: {
    [getFundManagerDetailBasicInfo.fulfilled.type]: (state, action) => {
      const id = action.meta?.arg;
      if (id) {
        state[id] = set("basicInfo", action.payload)(state[id]);
      }
    },
    [getFundManagerDetailDailyReturn.fulfilled.type]: (state, action) => {
      const id = action.meta?.arg;
      if (id) {
        state[id] = set("dailyReturnInfo", action.payload)(state[id]);
      }
    },
    [getFundManagerScaleTrend.fulfilled.type]: (state, action) => {
      const id = action.meta?.arg;
      if (id) {
        state[id] = set("scaleTrend", action.payload)(state[id]);
      }
    },
    [getFundManagerManageFundInfo.fulfilled.type]: (state, action) => {
      const id = action.meta?.arg;
      if (id) {
        state[id] = set("fundManageInfo", action.payload)(state[id]);
      }
    },
    [getFundManagerManageFundPerformanceInfo.fulfilled.type]: (
      state,
      action
    ) => {
      const { id, section } = action.meta?.arg;
      if (id && section) {
        state[id] = set(
          `fundManagerPerformanceInfo.${section}`,
          action.payload
        )(state[id]);
      }
    },
    [getFundManagerAvatar.fulfilled.type]: (state, action) => {
      const id = action.meta?.arg?.id;
      if (id) {
        state[id] = set("managerAvatar", action.payload)(state[id]);
      }
    },
    [getFundManagerReportDate.fulfilled.type]: (state, action) => {
      const id = action.meta?.arg;
      if (id) {
        state[id] = set(
          "reportDate",
          orderBy(identity, "desc")(action.payload)
        )(state[id]);
      }
    },
    [getFundManagerYearReportDate.fulfilled.type]: (state, action) => {
      const id = action.meta?.arg;
      if (id) {
        state[id] = set(
          "reportYearDate",
          orderBy(identity, "desc")(action.payload)
        )(state[id]);
      }
    },
    [getFundManagerIndustryInfo.fulfilled.type]: (state, action) => {
      const { id, reportDate, sectorId } = action.meta?.arg;
      if (id) {
        state[id] = set(
          `industryInfo.${sectorId}.${reportDate}`,
          action.payload
        )(state[id]);
      }
    },
    [getFundManagerMarketValueInfo.fulfilled.type]: (state, action) => {
      const { id, reportDate } = action.meta?.arg;
      if (id) {
        state[id] = set(
          `marketValueInfo.${reportDate}`,
          action.payload
        )(state[id]);
      }
    },
  },
});

export const { updateManagerDetailInfo } = fundManagerDetailSlice.actions;
export default fundManagerDetailSlice.reducer;
