import { RootState } from "@/store";
import { normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { optionalFundManagerGroupList } from "./interface";

export const optionalFundManagerGroupListSelector = createSelector(
  (state: RootState) => state.workbench.optionalFundManagerGroupList,
  (groupList): optionalFundManagerGroupList[] => groupList
);

export const optionalFundManagerGroupMapSelector = createSelector(
  optionalFundManagerGroupListSelector,
  (groupList): Record<string, optionalFundManagerGroupList> =>
    normalize<optionalFundManagerGroupList>("id")(groupList)
);
