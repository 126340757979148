/**
 * 最大回撤
 */
import { flow, forEach, map, max } from "lodash/fp";
import { formatArrayNilToZero } from "../numberFormatter";
import getCumulativeReturns from "./cumulativeReturns";

export default (dailyReturns: number[]) => {
  const netValues = flow(
    getCumulativeReturns,
    (cumulativeReturns) => [0, ...cumulativeReturns],
    map((returns) => returns + 1)
  )(formatArrayNilToZero(dailyReturns));
  let maxDailyReturn = -1;
  const drawdownList: number[] = [];
  forEach((dailyReturn: number) => {
    const newMaxDailyReturn =
      dailyReturn > maxDailyReturn ? dailyReturn : maxDailyReturn;
    maxDailyReturn = newMaxDailyReturn;
    drawdownList.push((newMaxDailyReturn - dailyReturn) / newMaxDailyReturn);
  })(netValues);
  return max(drawdownList);
};
