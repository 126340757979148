import {
  companyDailyReturnResponse,
  companyInfoResponse,
  companyManageFundResponse,
  companyScaleTrendResponse,
  scaleRankTrendItem,
} from "@/model/fundCompanyDetail";
import { PromiseType } from "@/model/promise";
import { get } from "@/util/axios";

// 基金公司基础信息
export const getCompanyInfo = (id: string): PromiseType<companyInfoResponse> =>
  get(`/company/info/${id}`);

// 基金公司旗下特定基金类型平均日收益
export type companyDailyReturnParm = {
  id: string;
  fundType: string;
};
export const getCompanyDailyReturn = ({
  id,
  fundType,
}: companyDailyReturnParm): PromiseType<companyDailyReturnResponse> =>
  get(`/company/return/${fundType}`, { id });

// 基金公司规模走势
export const getCompanyScaleTrend = (
  id: string
): PromiseType<companyScaleTrendResponse> => get("company/scale_trend", { id });

// 基金公司规模排名
export const getCompanyScaleRank = (
  id: string
): PromiseType<scaleRankTrendItem[]> => get("company/scale_trend/rank", { id });

// 基金公司旗下特定类型基金信息
export type companyManageFundParm = {
  fundType: string;
  id: string;
  section: string;
};
export const getCompanyManageFund = ({
  id,
  fundType,
  section,
}: companyManageFundParm): PromiseType<companyManageFundResponse[]> =>
  get(`/company/manage_fund/${fundType}`, { id, section });

// 基金公司代表基金
export const getRepresentativeFund = (id: string): PromiseType<any> =>
  get("company/representative_fund", { id });
