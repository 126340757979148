import { createSelector } from "@reduxjs/toolkit";
import { fastProp, zipWithMinus, mapIndexed } from "@/util/opt";
import { last, map, flow, slice, uniqBy, size } from "lodash/fp";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import getCumulativeAttributionsByParams from "@/util/quant/cumulativeAttributions";
import { brinsonAttributionSelector } from "../commonSelector";
import { periodType, dateType } from "@/model/fundDetail";
import moment from "moment";
import { MONTHLY, QUARTER, YEARLY } from "../../constant";

export const getInitalResultSelector = createSelector(
  brinsonAttributionSelector,
  (result) => {
    // 日期
    const dates = fastProp("dates")(result) || [];

    // 基金净值业绩
    const dailyPortfolioActualReturns =
      fastProp("dailyPortfolioActualReturns")(result) || [];
    // 基金净值累计业绩
    const cumulativePortfolioActualAttributions = getCumulativeReturns(
      dailyPortfolioActualReturns
    );

    // 基金持仓业绩
    const dailyPortfolioReturns =
      fastProp("dailyPortfolioReturns")(result) || [];
    const cumulativePortfolioAttributions = getCumulativeReturns(
      dailyPortfolioReturns
    );

    // 基准净值业绩
    const dailyBenchmarkActualReturns =
      fastProp("dailyBenchmarkActualReturns")(result) || [];
    const cumulativeBenchmarkActualAttributions = getCumulativeReturns(
      dailyBenchmarkActualReturns
    );

    // 基准持仓业绩
    const dailyBenchmarkReturns =
      fastProp("dailyBenchmarkReturns")(result) || [];
    // 基准持仓累计业绩
    const cumulativeBenchmarkAttributions = getCumulativeReturns(
      dailyBenchmarkReturns
    );

    // 配置日业绩
    const dailyAllocationAttributions =
      fastProp("dailyAllocationAttributions")(result) || [];

    // 选基金日业绩
    const dailySelectionAttributions =
      fastProp("dailySelectionAttributions")(result) || [];

    // 持仓链接算法
    const getPositionCumulativeAttributions = getCumulativeAttributionsByParams(
      dailyPortfolioReturns,
      dailyBenchmarkReturns,
      cumulativePortfolioAttributions,
      cumulativeBenchmarkAttributions
    );

    // 净值链接算法
    const getActualCumulativeAttributions = getCumulativeAttributionsByParams(
      dailyPortfolioActualReturns,
      dailyBenchmarkActualReturns,
      cumulativePortfolioActualAttributions,
      cumulativeBenchmarkActualAttributions
    );

    // 配置业绩
    const cumulativeAllocationAttributions = getPositionCumulativeAttributions(
      dailyAllocationAttributions
    );
    // 选基金业绩
    const cumulativeSelectionAttributions = getPositionCumulativeAttributions(
      dailySelectionAttributions
    );
    // 交叉项日业绩
    const dailyTimingOrInteractionAttributions = zipWithMinus(
      dailyPortfolioReturns,
      dailyBenchmarkReturns,
      dailyAllocationAttributions,
      dailySelectionAttributions
    );
    // 交叉项业绩
    const cumulativeTimingOrInteractionAttributions =
      getPositionCumulativeAttributions(dailyTimingOrInteractionAttributions);
    // 超额净值日业绩
    const dailyActiveReturns = zipWithMinus(
      dailyPortfolioActualReturns,
      dailyBenchmarkActualReturns
    );

    // 超额持仓日业绩
    const dailyActivePositionReturns = zipWithMinus(
      dailyPortfolioReturns,
      dailyBenchmarkReturns
    );

    // 超额净值业绩
    const cumulativeActiveAttributions =
      getActualCumulativeAttributions(dailyActiveReturns);
    // 超额持仓业绩
    const cumulativeActivePositionAttributions =
      getPositionCumulativeAttributions(dailyActivePositionReturns);

    return {
      ...result,
      dates: dates,
      // 超额净值日业绩
      dailyActiveReturns,
      // 超额持仓日业绩
      dailyActivePositionReturns,

      // 组合净值业绩
      cumulativePortfolioActualAttribution: last(
        cumulativePortfolioActualAttributions
      ),
      cumulativePortfolioActualAttributions,

      // 基准净值业绩
      cumulativeBenchmarkActualAttribution: last(
        cumulativeBenchmarkActualAttributions
      ),
      cumulativeBenchmarkActualAttributions,

      // 配置业绩
      cumulativeAllocationAttribution: last(cumulativeAllocationAttributions),
      cumulativeAllocationAttributions,

      // 选基金业绩
      cumulativeSelectionAttribution: last(cumulativeSelectionAttributions),
      cumulativeSelectionAttributions,

      // 交叉项业绩
      cumulativeTimingOrInteractionAttribution: last(
        cumulativeTimingOrInteractionAttributions
      ),
      cumulativeTimingOrInteractionAttributions,
      // 交叉项日业绩
      dailyTimingOrInteractionAttributions,

      // 其他业绩
      otherAttribution:
        (last(cumulativeActiveAttributions) || 0) -
        (last(cumulativeActivePositionAttributions) || 0),

      // 超额业绩
      cumulativeActiveAttribution: last(cumulativeActiveAttributions),
    };
  }
);
export const processDates = (period: string) => (dates: string[]) =>
  flow(
    mapIndexed((date: string, index: number) => ({
      date: fastProp(period)(formats)(date),
      periodStartIndex: index,
    })),
    uniqBy(({ date }) => date),
    mapIndexed(
      (
        { date, periodStartIndex }: dateType,
        index: number,
        array: { [x: string]: { periodStartIndex: number } }
      ) => ({
        date,
        periodStartIndex,
        periodEndIndex: array[index + 1]
          ? array[index + 1].periodStartIndex - 1
          : size(dates) - 1,
      })
    )
  )(dates);
const formats = {
  [MONTHLY]: (date: string) => moment(date).format("YYYY-MM"),
  [QUARTER]: (date: string) => {
    const dateMoment = moment(date);
    return `${dateMoment.format("YYYY")}Q${dateMoment.quarter()}`;
  },
  [YEARLY]: (date: string) => moment(date).format("YYYY"),
};

export const getDetailTableData = (
  dailyPortfolioReturns: number[],
  dailyPortfolioActualReturns: number[],
  dailyBenchmarkReturns: number[],
  dailyBenchmarkActualReturns: number[],
  dailyActiveReturns: number[],
  dailyActivePositionReturns: number[],
  dailyTimingOrInteractionAttributions: number[],
  dailyAllocationAttributions: number[],
  dailySelectionAttributions: number[],
  period: string
) =>
  flow(
    processDates(period),
    map(({ date, periodStartIndex, periodEndIndex }: periodType) => {
      const sliced = slice(periodStartIndex, periodEndIndex + 1);
      // 组合持仓日收益
      const slicedDailyPortfolioReturns = sliced(dailyPortfolioReturns);
      // 组合净值日收益
      const slicedDailyPortfolioActualReturns = sliced(
        dailyPortfolioActualReturns
      );
      // 基准持仓日收益
      const slicedDailyBenchmarkReturns = sliced(dailyBenchmarkReturns);
      // 基准净值日收益
      const slicedDailyBenchmarkActualReturns = sliced(
        dailyBenchmarkActualReturns
      );

      // 组合持仓累计收益
      const slicedCumulativePortfolioReturns = getCumulativeReturns(
        slicedDailyPortfolioReturns
      );
      // 组合净值累计收益
      const slicedCumulativePortfolioActualReturns = getCumulativeReturns(
        slicedDailyPortfolioActualReturns
      );
      // 基准持仓累计收益
      const slicedCumulativeBenchmarkReturns = getCumulativeReturns(
        slicedDailyBenchmarkReturns
      );
      // 基准净值累计收益
      const slicedCumulativeBenchmarkActualReturns = getCumulativeReturns(
        slicedDailyBenchmarkActualReturns
      );
      // 持仓链接算法
      const getLastPositionCumulativeAttribution = flow(
        sliced,
        getCumulativeAttributionsByParams(
          slicedDailyPortfolioReturns,
          slicedDailyBenchmarkReturns,
          slicedCumulativePortfolioReturns,
          slicedCumulativeBenchmarkReturns
        ),
        last
      );
      return {
        id: date,
        name: date,
        key: date,
        title: date,
        // 超额净值业绩
        activeAttribution:
          getLastPositionCumulativeAttribution(dailyActiveReturns),
        // 配置业绩
        allocationAttribution: getLastPositionCumulativeAttribution(
          dailyAllocationAttributions
        ),
        // 选基金业绩
        selectionAttribution: getLastPositionCumulativeAttribution(
          dailySelectionAttributions
        ),
        // 组合净值业绩
        portfolioActualAttribution: last(
          slicedCumulativePortfolioActualReturns
        ),
        // 基准净值业绩
        benchmarkActualAttribution: last(
          slicedCumulativeBenchmarkActualReturns
        ),
        // 交叉项业绩
        timingOrInteractionAttribution: getLastPositionCumulativeAttribution(
          dailyTimingOrInteractionAttributions
        ),
        // 超额净值业绩-超额持仓业绩
        otherAttribution:
          getLastPositionCumulativeAttribution(dailyActiveReturns) -
          getLastPositionCumulativeAttribution(dailyActivePositionReturns),
      };
    })
  );

export const getAttributionsObject = (
  // 超额净值业绩
  activeAttribution: number,
  // 超额持仓业绩
  activePositionAttribution: number,
  // 交叉项业绩
  timingOrInteractionAttribution: number,
  // 配置业绩
  allocationAttribution: number,
  // 选基金业绩
  selectionAttribution: number,
  // 组合净值业绩
  portfolioActualAttribution: number,
  // 基准净值业绩
  benchmarkActualAttribution: number
) => ({
  activeAttribution,
  allocationAttribution,
  selectionAttribution,
  portfolioActualAttribution,
  benchmarkActualAttribution,
  timingOrInteractionAttribution,
  // 其他业绩 = 超额净值业绩 - 超额持仓业绩
  otherAttribution: activeAttribution - activePositionAttribution,
});
