import { flow, isEmpty, toNumber } from "lodash/fp";
import { toStandardDeviation } from "../math";
import tradingDateCount from "@/constant/tradingDateCount";
import { formatArrayNilToZero } from "../numberFormatter";
/**
 * 年化波动率
 * link: [http://ci.betalpha.com:61009/pages/viewpage.action?pageId=11601078]
 */

export default (dailyReturns: number[]): number => {
  if (isEmpty(dailyReturns)) return 0;
  return flow(
    formatArrayNilToZero,
    toStandardDeviation,
    toNumber,
    (res) => res * Math.sqrt(tradingDateCount)
  )(dailyReturns);
};
