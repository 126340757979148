import React, { useEffect, useMemo, useState } from "react";
import { map, last, forEach, flow, reverse, sortBy, isEmpty } from "lodash/fp";
import { Spin } from "antd";
import { useMemoizedFn } from "ahooks";
import { useFormatMessage } from "@/util/formatMessage";
// import { colors } from "@/util/colors";
import { normalize, mapIndexed } from "@/util/opt";
import {
  getAumSectorDistributions,
  AumSectorDistributionsParam,
} from "@/api/aum";
import { CodeWeight, SectorDistribution, SectorWeight } from "@/model/aum";
import { useAppSelector } from "@/hooks/redux";
import { useRequest } from "@/hooks/request";
import {
  industryDataIdAsKeyMapSelector,
  defaultSectorCategorySelector,
} from "@/selectors/sectorCategories";
import { DistributionChangeChart } from "@/base-components/charts";
import DisclosureDateCascader from "@/base-components/disclosureDateCascader";
import EmptyContent from "@/components/emptyContent";
import SubTitle from "../../components/subTitle";
import { CommonProps } from "../../type";
import WeightTable from "./table";

import style from "../../index.module.less";

export default React.memo<CommonProps>(({ assetPortfolioId }) => {
  const formatMessage = useFormatMessage();
  const industryMap = useAppSelector(industryDataIdAsKeyMapSelector);
  const defaultSectorCategory = useAppSelector(defaultSectorCategorySelector);
  const [date, setDate] = useState<string>("");
  const { loading, data, request } = useRequest<
    AumSectorDistributionsParam,
    SectorDistribution[]
  >(getAumSectorDistributions);

  const { sectorByDateMap, dates } = useMemo(() => {
    if (!data) return {};
    return {
      sectorByDateMap: normalize<SectorDistribution, SectorWeight[]>(
        "tradingDate",
        (item) => item?.sectorInfoList
      )(data),
      dates: map((item: SectorDistribution) => item?.tradingDate)(data),
    };
  }, [data]);

  const series = useMemo(() => {
    const result: Record<string, [string, number][]> = {};
    forEach((item: SectorDistribution) => {
      forEach((sectorWeight: SectorWeight) => {
        const sectorName = sectorWeight?.code;
        if (!result?.[sectorName]) {
          result[sectorName] = [];
        }
        result[sectorName].push([item?.tradingDate, sectorWeight?.weight]);
      })(item?.sectorInfoList);
    })(data);
    return mapIndexed((values: [string, number][], sectorName: string) => ({
      name: sectorName,
      data: values,
    }))(result);
  }, [data]);

  const tableData = useMemo(
    () =>
      map((item: SectorWeight) => ({
        code: item?.code,
        name: item?.code,
        weight: item?.weight,
        children: flow(
          map((codeWeight: CodeWeight) => ({
            name: industryMap?.[codeWeight?.code]?.name,
            weight: codeWeight?.weight,
            code: codeWeight?.code,
          })),
          sortBy("weight"),
          reverse
        )(item?.industryInfoList),
      }))(sectorByDateMap?.[date] || []),
    [sectorByDateMap, date, industryMap]
  );

  const changeChartDateHandler = useMemoizedFn((event: any) => {
    if (event?.name) setDate(event?.name);
  });

  useEffect(() => {
    if (assetPortfolioId && defaultSectorCategory?.id) {
      request({
        id: assetPortfolioId,
        sectorCategoryId: defaultSectorCategory?.id,
      });
    }
  }, [assetPortfolioId, defaultSectorCategory?.id, request]);

  useEffect(() => {
    if (dates) {
      setDate(last(dates) || "");
    }
  }, [dates]);

  return (
    <div>
      <SubTitle title={formatMessage("sectorDistribution")} />
      <Spin spinning={loading}>
        <div className={style.ChartContainer} style={{ height: 400 }}>
          <div className={style.Chart}>
            {isEmpty(series) ? (
              <EmptyContent>
                <strong>{formatMessage("noData")}</strong>
              </EmptyContent>
            ) : (
              <DistributionChangeChart
                type="bar"
                series={series}
                dates={dates || []}
                height={400}
                showDataZoom={false}
                selectedDate={date}
                onEvents={{ click: changeChartDateHandler }}
              />
            )}
          </div>
          <div className={style.SectorContent}>
            <div className={style.DisclosureDateWrap}>
              <DisclosureDateCascader
                dates={dates || []}
                value={date}
                onChange={setDate}
              />
            </div>
            <WeightTable height={400 - 32 - 16} dataSource={tableData} />
          </div>
        </div>
      </Spin>
    </div>
  );
});
