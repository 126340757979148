import React from "react";
import { Button, Space } from "antd";
import { useCreation, useMemoizedFn } from "ahooks";
import { map } from "lodash/fp";
import TradingDatePicker from "@/components/tradingDatePicker";
import SelectFundDialog from "@/components/portfolioCompoents/selectFundDialog";
import { PlusOutlined } from "@ant-design/icons";
import trash from "@/assets/trash.svg";
import FundWeightTable from "./fundWeightTable";
import type { ManualCreatePortfolio } from "../constant";
import { checkDisabledRangeDate, addFundWeights } from "../constant";
import type { Updater } from "../hooks";
import { useGenerateAsset } from "../hooks";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";

export type ChangingPositionItemProps = {
  index: number;
  changingPositionData: ManualCreatePortfolio;
  prevTurnoverDate: string | null;
  nextTurnoverDate: string | null;
  onUpdate: (key: string) => (updater: Updater) => any;
  onRemove: () => any;
};

export default React.memo<ChangingPositionItemProps>(
  ({
    index,
    changingPositionData,
    onUpdate,
    onRemove,
    prevTurnoverDate,
    nextTurnoverDate,
  }) => {
    const fundIds = useCreation(
      () => map("fundId")(changingPositionData?.weights),
      [changingPositionData?.weights]
    );
    const generateAsset = useGenerateAsset();
    const onChangeFundIds = useMemoizedFn((ids: string[]) =>
      onUpdate("weights")(addFundWeights(generateAsset, ids))
    );

    const disabledDate = useMemoizedFn(
      checkDisabledRangeDate(prevTurnoverDate, nextTurnoverDate)
    );
    const formatMessage = useFormatMessage();
    return (
      <div className={style.ChangingPositionItem}>
        <div className={style.ItemHeader}>
          <h3 className={style.FlexTitle}>
            {formatMessage("theWarehouse")}
            {index}
            <img className={style.RemoveIcon} src={trash} onClick={onRemove} />
          </h3>

          <Space size={12}>
            <Space>
              <p>{formatMessage("warehouseDate")}：</p>
              <TradingDatePicker
                value={changingPositionData?.turnoverDate}
                onChange={onUpdate("turnoverDate")}
                disabledDate={disabledDate}
              />
            </Space>
            <SelectFundDialog fundIds={fundIds} onChange={onChangeFundIds}>
              {(onChangeVisible) => (
                <Button
                  onClick={onChangeVisible}
                  className={style.ShadowButton}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  {formatMessage("addFund")}
                </Button>
              )}
            </SelectFundDialog>
          </Space>
        </div>
        <FundWeightTable
          editable
          dataSource={changingPositionData}
          onChange={onUpdate}
        />
      </div>
    );
  }
);
