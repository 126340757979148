import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import React, { useContext, useEffect } from "react";
import warehouseAnalysisIcon from "@/assets/portfolioAnalysisIcons/warehouseAnalysis.png";
import { useFormatMessage } from "@/util/formatMessage";
import ButtonGroup from "@/components/buttonGroup";
import style from "./index.module.less";
import { Select } from "antd";
import { size } from "lodash/fp";
import { errorValidator } from "@/components/errorBoundary/constant";
import { turnoverOptionsConfig } from "./constant";
import LineChart from "@/echarts/lineChart";
import {
  useButtonGroup,
  useGetSummaryText,
  useGetTurnoverAnalysisData,
  useManageTurnoverDate,
  useManageTurnoverTableConfigs,
  useGetLineChartConfig,
  useManageSelectedTurnoverDate,
} from "./hooks";
import SummaryCard from "../components/summaryCard";
import PositionHistoryTable from "../../portfolioList/components/positionHistoryTable";
import {
  fetchPortfolioSummary,
  fetchTurnoverDates,
  fetchBackTestingTurnover,
} from "@/store/portfolioAnalysis";
import ErrorBoundary from "@/components/errorBoundary";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import { useAppSelector } from "@/hooks/redux";
import { analysisBasicInfoSelector } from "../selectors";
import LoadingComponent from "@/components/LoadingComponent";

const loadingActions = [
  fetchPortfolioSummary,
  fetchTurnoverDates,
  fetchBackTestingTurnover,
];
const needTime = 2;

export default React.memo<{
  id: string;
}>(({ id }) => {
  const { runningTime, dispatch } = useContext(PortfolioAnalysisContext);
  const { startDate, endDate } = useAppSelector((state) =>
    analysisBasicInfoSelector(state, id)
  );
  const formatMessage = useFormatMessage();
  const {
    turnoverDate,
    turnoverDateMap,
    turnoverEndDate,
    turnoverDateOptions,
  } = useManageTurnoverDate(id);
  const { buttonGroupList, buttonGroupValue, setButtonGroupValue } =
    useButtonGroup(turnoverDate);

  const { selectedTurnoverDate, setSelectedTurnoverDate } =
    useManageSelectedTurnoverDate(buttonGroupValue, turnoverEndDate);

  useEffect(() => {
    if (buttonGroupValue === turnoverOptionsConfig.allRange) {
      dispatch(needTime, fetchTurnoverDates(id));
    }
  }, [buttonGroupValue, dispatch, id, selectedTurnoverDate]);
  useEffect(() => {
    if (
      selectedTurnoverDate &&
      buttonGroupValue === turnoverOptionsConfig.warehouseTime
    ) {
      dispatch(
        needTime,
        fetchBackTestingTurnover({
          id,
          date: selectedTurnoverDate,
        })
      );
    }
  }, [buttonGroupValue, dispatch, id, selectedTurnoverDate]);
  const {
    unTurnoverDailyReturns,
    turnoverViews,
    dates: turnoverTradingDates,
  } = useGetTurnoverAnalysisData(id, selectedTurnoverDate as string);
  const { series, options, events } = useGetLineChartConfig({
    id,
    turnoverDateMap,
    onChangeButtonGroupValue: setButtonGroupValue,
    onChangeTurnoverDate: setSelectedTurnoverDate,
    buttonGroupValue,
    turnoverTradingDates,
    selectedTurnoverDate,
    unTurnoverDailyReturns,
  });
  const { dataSource, updateColumns, scroll } = useManageTurnoverTableConfigs(
    selectedTurnoverDate,
    turnoverViews
  );
  const summaryText = useGetSummaryText(
    buttonGroupValue,
    size(turnoverDate),
    selectedTurnoverDate,
    series
  );

  const tip: CardLayoutTipType = [
    [formatMessage("warehouseAnalysis"), formatMessage("warehouseAnalysisTip")],
  ];
  return (
    <CardLayout
      icon={warehouseAnalysisIcon}
      title={formatMessage("warehouseAnalysis")}
      tip={tip}
    >
      <ErrorBoundary
        errKey={errorValidator.lessTwoTradingDate(needTime, runningTime)}
      >
        <LoadingComponent actions={loadingActions}>
          <div className={style.analysisCard}>
            <header className={style.WarehouseAnalysisHeader}>
              <div>
                <ButtonGroup
                  onChange={setButtonGroupValue}
                  groups={buttonGroupList}
                  value={buttonGroupValue}
                />
              </div>
              <div>
                {buttonGroupValue === turnoverOptionsConfig.allRange ? (
                  <span>
                    {formatMessage("allRangeDate", {
                      start: startDate,
                      end: endDate,
                    })}
                  </span>
                ) : (
                  <Select
                    value={selectedTurnoverDate}
                    options={turnoverDateOptions}
                    onChange={setSelectedTurnoverDate}
                  />
                )}
              </div>
            </header>
            <div className={style.MarginTop12}>
              <LineChart options={options} series={series} events={events} />
            </div>
            {buttonGroupValue === turnoverOptionsConfig.warehouseTime && (
              <div className={style.MarginTop12}>
                <PositionHistoryTable
                  dataSource={dataSource}
                  updateColumns={updateColumns}
                  scroll={scroll}
                />
              </div>
            )}
            {summaryText && (
              <SummaryCard
                className={style.MarginTop12}
                summaryText={summaryText}
              />
            )}
          </div>
        </LoadingComponent>
      </ErrorBoundary>
    </CardLayout>
  );
});
