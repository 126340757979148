import { flow, max, slice, sum, toArray } from "lodash/fp";
import { useCreation } from "ahooks";

import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";
import { industryDataIdAsKeyMapSelector } from "@/selectors/sectorCategories";
import { mapIndexed } from "@/util/opt";

export const useGetLargeFundName = (obj: Record<string, number>): string => {
  const fundMap = useAppSelector(fundIdMapSelector);
  return useCreation(() => {
    if (!obj) return "--";
    let benchmarkKey = "";
    const largeVal = max(toArray(obj));
    mapIndexed((val: number, key: string) => {
      if (val === largeVal) benchmarkKey = key;
    })(obj);
    return fundMap?.[benchmarkKey]?.name || "--";
  }, [fundMap, obj]);
};

export const useGetTopThreeIndustryWeight = (obj: Record<string, number>) => {
  return useCreation(() => {
    const objToArray = (flow(toArray)(obj) as Array<number>).sort(
      (a, b) => b - a
    );
    return sum(slice(0)(3)(objToArray));
  }, [obj]);
};
export const useGetMaxValueIndustryName = (
  obj: Record<string, number>
): string => {
  const industryMap = useAppSelector(industryDataIdAsKeyMapSelector);
  return useCreation(() => {
    if (!obj) return "--";
    let industryCode = "";
    const largeVal = max(toArray(obj));
    mapIndexed((val: number, key: string) => {
      if (val === largeVal) industryCode = key;
    })(obj);
    return industryMap?.[industryCode]?.name || "--";
  }, [industryMap, obj]);
};
