import { useFormatMessage } from "@/util/formatMessage";
import {
  expandObjectArrayToObject,
  fastProp,
  fastSize,
  mapIndexed,
} from "@/util/opt";
import { flow, map } from "lodash/fp";
import { FundBasicDataSource, FundBasicIndicatorsType } from "../constant";

export const useTableDataSource = (
  data: { [key: string]: any },
  dataIndicators: FundBasicIndicatorsType[],
  columnSize?: number
) => {
  const formatMessage = useFormatMessage();
  const dataSource = map((v: FundBasicIndicatorsType) => ({
    ...v,
    message: formatMessage(v.messageKey),
    value: fastProp(v.id)(data),
  }))(dataIndicators);

  const tmp = [];
  const size = fastSize(dataSource);
  for (let i = 0; i < size; i++) {
    const idx = Math.floor(i / (columnSize || 2));
    if (tmp[idx]) {
      tmp[idx].push(dataSource[i]);
    } else {
      tmp[idx] = [dataSource[i]];
    }
  }
  const res = mapIndexed((v: FundBasicDataSource[], idx: number) =>
    flow(
      mapIndexed(
        ({ value, message, id }: FundBasicDataSource, index: number) => ({
          [index * 2]: { value: message },
          [index * 2 + 1]: { value: value, id },
        })
      ),
      expandObjectArrayToObject,
      (data) => ({
        ...data,
        key: idx,
      })
    )(v)
  )(tmp);
  return res;
};
