import { useCreation } from "ahooks";
import { Radio, Select, Space } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { isEmpty, prop, size } from "lodash/fp";
import React, { useEffect, useMemo } from "react";
import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import style from "@/views/portfolioManage/portfolioAnalysis/riskAnalysis/index.module.less";
import ErrorBoundary from "@/components/errorBoundary";
import { errorValidator } from "@/components/errorBoundary/constant";
import { fetchPredictMaxLoss } from "@/store/aum";
import LineChart from "@/echarts/lineChart";
import EmptyContent from "@/components/emptyContent";
import SummaryCard from "@/views/portfolioManage/portfolioAnalysis/components/summaryCard";
import LoadingComponent from "@/components/LoadingComponent";
import {
  useChartDataSource,
  useParamsRange,
} from "@/views/portfolioManage/portfolioAnalysis/riskAnalysis/hook";
import { CommonProps } from "@/views/aum/type";
import { useDispatch } from "react-redux";
import { useGetAumOrganization, getOrganizationName } from "@/hooks/aum";
import { useAppSelector } from "@/hooks/redux";
import SubTitle from "@/views/aum/components/subTitle";
import PredictMaxLossIcon from "@/assets/portfolioAnalysisIcons/predictMaxloss.svg";
import { getSection } from "@/views/portfolioManage/portfolioAnalysis/riskAnalysis/constant";

const needTime = 100;

export default React.memo<CommonProps>(
  ({ id, assetPortfolioId, managerName }) => {
    const formatMessage = useFormatMessage();
    // const { runningTime } = useContext(PortfolioAnalysisContext);
    const organizations = useGetAumOrganization("SELECTOR");
    const organizationName =
      getOrganizationName(organizations, id) || managerName || "";
    const {
      computationType,
      onChangeComputationType,
      earningStatisticsButtonOptions,
      future,
      setFuture,
      futureOptions,
      confidenceType,
      setConfidenceType,
      confidenceTypeOptions,
    } = useParamsRange();
    const dispatch = useDispatch();
    useEffect(() => {
      if (assetPortfolioId && computationType && confidenceType && future) {
        dispatch(
          fetchPredictMaxLoss({
            id: assetPortfolioId,
            computationType,
            confidenceType,
            future,
          })
        );
      }
    }, [
      assetPortfolioId,
      computationType,
      confidenceType,
      dispatch,
      future,
      id,
    ]);

    const selectedParams = useMemo(
      () => getSection(computationType, confidenceType, future),
      [computationType, confidenceType, future]
    );

    const baseDataSource = useAppSelector(
      prop(`aum.${assetPortfolioId}.predictMaxLoss.${selectedParams}`)
    );
    const { options, series, dates, summaryText } = useChartDataSource(
      computationType,
      confidenceType,
      future,
      organizationName,
      baseDataSource,
      "aum"
    );
    const errKey = useCreation(
      () => errorValidator.lessOneHundredTradingDate(needTime, size(dates)),
      [dates]
    );
    const tip: CardLayoutTipType = [
      [
        formatMessage("predictMaximumLoss"),
        formatMessage("predictMaximumLossTip"),
      ],
      [
        formatMessage("performanceBenchmark"),
        formatMessage("performanceBenchmarkTip"),
      ],
    ];
    return (
      <LoadingComponent actions={fetchPredictMaxLoss}>
        <CardLayout
          title={formatMessage("predictMaximumLoss")}
          icon={PredictMaxLossIcon}
          className={style.Card}
          tip={tip}
        >
          <SubTitle title={formatMessage("predictMaximumLoss")} />
          <div>
            <Radio.Group
              optionType="button"
              options={earningStatisticsButtonOptions}
              value={computationType}
              buttonStyle="solid"
              onChange={onChangeComputationType}
            />
          </div>
          <Space className={style.SelectContent}>
            <span>{formatMessage("future")}</span>
            <Select
              className={style.RangeSelect}
              value={future}
              onSelect={setFuture}
              options={futureOptions}
            />
            <span>{formatMessage("AnTradingDay")}</span>
            <Select
              className={style.RangeSelect}
              value={confidenceType}
              onSelect={setConfidenceType}
              options={confidenceTypeOptions}
            />
            <span>{formatMessage("confidenceTypeByMaxLoss")}</span>
          </Space>
          <ErrorBoundary errKey={errKey}>
            {isEmpty(dates) ? (
              <EmptyContent className={style.EmptyContent}>
                <strong>{formatMessage("noData")}</strong>
              </EmptyContent>
            ) : (
              <LineChart options={options} series={series} />
            )}
            <SummaryCard
              summaryText={formatMessage("valueAtRiskSummaryText", summaryText)}
            />
          </ErrorBoundary>
        </CardLayout>
      </LoadingComponent>
    );
  }
);
