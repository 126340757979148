import { useMemoizedFn } from "ahooks";
import { Button, message, Modal, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { includes, pull } from "lodash/fp";
import React, { useEffect, useState } from "react";
import { MAX_SELECTION_SIZE } from "./constant";
import CreateScenario from "./createScenario";
import { useGetScenarioTableData, useManageScenarioColumns } from "./hooks";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";
import { useAppDispatch } from "@/hooks/redux";
import { deleteScenario } from "@/store/scenarioList";

export type ScenarioManageProps = {
  startDate: string;
  endDate: string;
  maxSelectionSize?: number;
  value?: string[];
  onChange?: (value: string[]) => any;
};
export default React.memo<ScenarioManageProps>(
  ({
    startDate,
    endDate,
    value,
    onChange,
    maxSelectionSize = MAX_SELECTION_SIZE,
  }) => {
    const formatMessage = useFormatMessage();
    const [visible, setVisible] = useState(false);
    const { dataSource, scroll } = useGetScenarioTableData(startDate, endDate);
    const dispatch = useAppDispatch();
    const onRemoveScenario = useMemoizedFn((id: string) =>
      dispatch(deleteScenario(id))
        .unwrap()
        .then(() => {
          if (value && onChange && includes(id)(value))
            onChange(pull(id)(value));
          message.success(formatMessage("deleteSuccess"));
        })
    );
    const {
      columns,
      rowClassName,
      selectedScenario,
      setSelectedScenario,
      validScenariosData,
    } = useManageScenarioColumns(
      maxSelectionSize,
      dataSource,
      onRemoveScenario
    );
    const openModal = useMemoizedFn(() => {
      setVisible(true);
      setSelectedScenario(value || []);
    });
    useEffect(() => {
      if (validScenariosData && onChange) onChange(validScenariosData);
    }, [onChange, validScenariosData]);
    const closeModal = useMemoizedFn(() => setVisible(false));
    const onOk = useMemoizedFn(() => {
      closeModal();
      onChange && onChange(selectedScenario);
    });
    return (
      <>
        <Button onClick={openModal} icon={<SettingOutlined />}>
          {formatMessage("scenarioSetting")}
        </Button>
        <Modal
          title={formatMessage("scenarioSetting")}
          visible={visible}
          onOk={onOk}
          width="800px"
          onCancel={closeModal}
        >
          <div>
            <CreateScenario />
          </div>
          <Table
            dataSource={dataSource}
            scroll={scroll}
            pagination={false}
            rowKey="id"
            className={style.ScenarioTable}
            columns={columns}
            rowClassName={rowClassName}
          />
        </Modal>
      </>
    );
  }
);
