import { useFormatMessage } from "@/util/formatMessage";
import { Radio, RadioProps } from "antd";
import { flow, map, pick, set, toArray } from "lodash/fp";
import React from "react";
import {
  FROM_CREATION,
  RECENT_FIVE_YEAR,
  RECENT_THREE_YEAR,
  RECENT_TWO_YEAR,
  statisticRange,
} from "@/constant/statisticRange";

const options = flow(
  pick([RECENT_TWO_YEAR, RECENT_THREE_YEAR, RECENT_FIVE_YEAR, FROM_CREATION]),
  set(`${RECENT_TWO_YEAR}.message`, "towYears"),
  set(`${RECENT_THREE_YEAR}.message`, "threeYears"),
  set(`${RECENT_FIVE_YEAR}.message`, "fiveYears"),
  toArray
)(statisticRange);

export default React.memo<RadioProps>((props) => {
  const formatMessage = useFormatMessage();
  return (
    <Radio.Group size="small" {...props}>
      {map(({ id, message }) => (
        <Radio.Button key={id} value={id}>
          {formatMessage(message)}
        </Radio.Button>
      ))(options)}
    </Radio.Group>
  );
});
