import LoadingComponent from "@/components/LoadingComponent";
import { fetchChangeMonitor } from "@/store/aum";
import { useFormatMessage } from "@/util/formatMessage";
import { Card, Space } from "antd";
import React, { useState } from "react";
import AumSubTitle from "../concentrationMonitor/aumSubTitle";
import ChangeMonitorTable from "./changeMonitorTable";
import style from "./index.module.less";

const lossOptions = [
  {
    label: "fundLoss",
    value: "FUND",
  },
  {
    label: "stockLoss",
    value: "STOCK",
  },
];
const profitOptions = [
  {
    label: "fundProfit",
    value: "FUND",
  },
  {
    label: "stockProfit",
    value: "STOCK",
  },
];

export default React.memo(
  ({
    assetPortfolioId,
    isShowSubOrganizations,
  }: {
    assetPortfolioId: string;
    isShowSubOrganizations: boolean;
  }) => {
    const formatMessage = useFormatMessage();
    const [type, setType] = useState("table");

    return (
      <Card size="small">
        <Space direction="vertical" className={style.FullWidth} size={20}>
          <AumSubTitle
            title={formatMessage("changeDetection")}
            type={type}
            changeType={setType}
          />
          <LoadingComponent actions={fetchChangeMonitor}>
            <Space direction="vertical" size={20} className={style.FullWidth}>
              <ChangeMonitorTable
                showType={type}
                Options={lossOptions}
                dataKey="deficitList"
                aumGrpId={assetPortfolioId}
                firstColor="#00B867"
                tableKey="loss"
                key="loss"
                isShowSubOrganizations={isShowSubOrganizations}
              />
              <ChangeMonitorTable
                showType={type}
                Options={profitOptions}
                dataKey="profitList"
                aumGrpId={assetPortfolioId}
                firstColor="#FF0A00"
                tableKey="profits"
                key="profits"
                isShowSubOrganizations={isShowSubOrganizations}
              />
            </Space>
          </LoadingComponent>
        </Space>
      </Card>
    );
  }
);
