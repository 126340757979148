import { getFactorsFormatterName } from "@/constant/factorFormatter";
import {
  Competition,
  CompetitionDetail,
  CompetitionFactors,
  CompetitionFactorsParam,
  CompetitionPortFactors,
  CompetitionRankBasic,
  RankBasicParam,
  WantCompetitionParam,
} from "@/model/competition";
import { FactorCategory } from "@/model/factors";
import { PromiseType } from "@/model/promise";
import { get, getWithCache, post } from "@/util/axios";
import { fastProp } from "@/util/opt";
import { keys, map } from "lodash/fp";

// export type CreateCompetitionParam = Omit<Competition, "competitionId" | "createUserId">
export type UpdateCompetitionParam = Omit<Competition, "createUserId">;
export type SetCompetitionValidateParam = {
  competitionId: string;
  validate: boolean;
};
export type Competitor = {
  competitorId: string;
  competitorName: string;
};
// 比赛列表
export const getCompetitions = (): PromiseType<Competition[]> =>
  get("/competition/competition-list");

// 创建比赛
export const createOrUpdateCompetition = (params: UpdateCompetitionParam) =>
  post("/competition/admin/createOrUpdate", params, {
    config: {
      showLoading: true,
    },
  });

// export const updateCompetition = (params: UpdateCompetitionParam) =>
//   put("/competition/admin/update", params);

// admin get competition
export const getAllCompetitions = (): PromiseType<Competition[]> =>
  get("/competition/admin/query");

export const setCompetitionValidate = (params: SetCompetitionValidateParam) =>
  post("/competition/admin/validate", params);

export const getCompetitors = (
  competitionId: string
): PromiseType<Competitor[]> =>
  get(`/competition/competitors/${competitionId}`);

export const validateCompetitionName = (name: string) =>
  get("/competition/admin/dupName", { name });

export const getCompetitionPortFactorsApi = (): Promise<FactorCategory[]> =>
  getWithCache<CompetitionPortFactors>("/competition/portfolio/factors").then(
    (v) => {
      return map((key: string) => ({
        id: key,
        nameKey: key,
        factorViews: map((i: string) => ({
          id: i,
          name: fastProp(i)(fastProp(key)(v)),
          categoryId: key,
          disabled: false,
          sorter: false,
          width: null,
          format: getFactorsFormatterName(fastProp(i)(fastProp(key)(v))),
        }))(keys(fastProp(key)(v))),
      }))(keys(v));
    }
  );
//比赛详情
export const getCompetitionDetail = (
  competitionId: string
): PromiseType<CompetitionDetail> =>
  get(`competition/competition-detail/${competitionId}`);

//比赛详情排行基本信息
export const getCompetitionRankBasicInfo = (
  params: RankBasicParam
): PromiseType<CompetitionRankBasic> =>
  post(`competition/portfolio/list`, params, {
    config: {
      showLoading: true,
    },
  });

//比赛详情组合因子数据
export const getCompetitionFactorsApi = (
  params: CompetitionFactorsParam
): PromiseType<CompetitionFactors> =>
  post(`competition/portfolio/factors-summary`, params);

//参加比赛
export const getWantCompetition = (
  params: WantCompetitionParam
): PromiseType<void> =>
  post(`competition/join-competition`, params, {
    config: {
      showLoading: true,
    },
  });
