/* eslint-disable @typescript-eslint/ban-types */
import { AxiosExtraConfig } from "@/global";
import { AxiosRequestConfig } from "axios";

export const CONFIG = {
  BASE_URL: "/api",
  MOCKER_URL: "https://yapi.betalpha.com/mock/113/api",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArrayAndObject = Array<any> | object | null | undefined;
export interface Request {
  method: "GET" | "POST" | "DELETE" | "PUT";
  url: string;
  data?: ArrayAndObject;
  baseURL?: string;
}

export interface RequestOptions {
  headers?: object;
  mocker?: boolean;
  cache?: boolean;
  withoutAuth?: boolean;
  deleteCache?: string;
  config?: AxiosExtraConfig;
  zip?: boolean;
  responseType?: AxiosRequestConfig<any>["responseType"];
}

export interface OnRequest extends Request {
  options?: RequestOptions;
}

export interface AxiosRequest extends Request {
  headers?: object;
}
