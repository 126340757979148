export type AumPortfolioType =
  | "FULL"
  | "PARTIAL_BOND"
  | "PARTIAL_STOCK"
  | "NON_GOODS"
  | "ACTIVE_RIGHT";
export type AumPortfolio = {
  assertPortfolioId: string;
  organizationId: string;
  organizationType: string;
  type: AumPortfolioType;
};
export type OrganizationType = "RANK" | "SELECTOR";
export type Organization = {
  organizationId: string;
  organizationName: string;
  children?: Organization[];
};

export type CodeWeight = {
  code: string;
  weight: number;
};

export type CodeWeightChange = CodeWeight & {
  change: number;
};

export type SectorWeight = {
  code: string;
  weight: number;
  industryInfoList: CodeWeight[];
};

export type IndustryDistribution = {
  date: string;
  dailyIndustryInfoList: CodeWeightChange[];
};

export type SectorDistribution = {
  tradingDate: string;
  sectorInfoList: SectorWeight[];
};

export type HoldStockStyle = {
  tradingDate: string;
  dailyStyleInfoList: CodeWeight[];
};

export interface ChangeMonitorBody {
  aumGrpId: string;
  type: string;
  section?: string;
}

export interface subGrpList {
  grpId: string;
  name: string;
  scale: number;
  customers: number;
}
export interface profOrDefList {
  code: string;
  name: string;
  cumulativeReturn: string;
  profitValue: string;
  customers: number;
  scale: number;
  weight: number;
  subGrpList: subGrpList;
  fundManagerIds?: string[];
}
export interface ChangeMonitorResponse {
  id: string;
  profitList: profOrDefList[];
  deficitList: profOrDefList[];
}

export interface subOrgs {
  orgName: string;
  orgScale: number;
  customers: number;
}

export interface ChangeDetection {
  name: string;
  code: string;
  weight: number;
  customers: number;
  scale: number;
  fundManagerIds?: string[];
  subOrgs: subOrgs[];
}

export interface ConcentrationDetectionBody {
  grpId: string;
  type: string;
}

export interface ConcentrationDetectionResponse {
  code: string;
  weight: number;
  customers: number;
  scale: number;
  fundManagerIds?: string[];
  subOrgs: subOrgs[];
}

export interface AumPerformanceDisassembleParams {
  dissectType: string;
  section: string;
  portfolioId: string;
}

export type AssetType = {
  tradingDate: string;
  weight: Record<string, number>;
};

export type AssetTypeTrend = {
  assetTypeList: AssetType[];
};

export type FundTypeDissect = {
  portfolioId: number;
  date: string;
  cashWeight: number;
  bondNavWeight: number;
  stockNavWeight: number;
  fundNavWeight: number;
  warranWeight: number;
  mmtWeight: number;
  reverseRepoWeight: number;
  otherWeight: number;
};

export type AssetAllocationDistributionResponse = {
  fundTypeDissects: FundTypeDissect[];
};

export type HeavyBondScale = { bondTypeName: string; scale: number };

export type HeavyBond = {
  bondTypeName: string;
  weight: number;
};

export type TradingDayHeavyBond = {
  tradingDate: string;
  heavyBondList: Array<HeavyBond>;
};

export type HeavyPositionBondListResponse = {
  heavyBondScales: Array<HeavyBondScale>;
  tradingDayHeavyBondList: Array<TradingDayHeavyBond>;
};

export type BenchmarkSectionReturnsParamType = {
  benchmarkCategory: string;
  benchmarkCode: string;
  startDate: string;
  endDate: string;
  periodType: string;
};

export interface ReturnContributionProp {
  name: string;
  contribution: number;
}

export interface BrinsonAttributionBody {
  id: string;
  section: string;
}

export interface BrinsonAttributionResponse {
  annualSharpeRatio: number;
  dailyAllocationAttributions: number[];
  dailyBenchmarkActualReturns: number[];
  dailyBenchmarkReturns: number[];
  dailyPortfolioActualReturns: number[];
  dailyPortfolioReturns: number[];
  dailySelectionAttributions: number[];
  dates: string[];
  informationRatio: number;
  maxDrawdown: number;
  trackingError: number;
}

export interface RankBody {
  pageNum: number;
  pageSize: number;
  portfolioId: string;
  queryType: string;
  factorToRank: string;
  sortOrder: string;
}

export interface portfolioList {
  factorValues: number;
  portfolioId: string;
  portfolioName: string;
  rank: null;
  foundDate: string;
  historyEnd: string;
  dailyRiseAndFall: string;
}
export interface RankResponse {
  numOfPortfolios: number;
  portfolioList: portfolioList[];
}

export interface factorValueBody {
  portfolioIds: string[];
  factorIds: string[];
  key?: string;
}

export interface factorValueResponse {
  portfolioId: string;
  factorValues: Record<string, string>;
}

export type DrawAndIndexFundParams = BrinsonAttributionSummaryDataParams;
export type DrawAndIndexFundResponse = Record<string, number>;

export interface DrawAndIndexSummaryParams {
  customerId: string;
  customerBenchmarkType: CUSTOMER_BENCHMARK_TYPE.CUSTOMER_AVG;
  section: string;
}

export interface DrawAndIndexSummaryResponse {
  dates: string[];
  dailyDrawDown: number[];
  benchmarkMaxDrawDown: number;
  benchmarkDrawDownStd: number;
  dataMissing: boolean;
}

export interface BrinsonAttributionSummaryDataParams {
  customerId: string;
  section: string;
}

export interface BrinsonAttributionSummaryDataResponse {
  allocationAvg: number;
  allocationStd: number;
  selectionAvg: number;
  selectionStd: number;
  timingAvg: number;
  timingStd: number;
  dataMissing: boolean;
}

export interface FactorEvaluationParams {
  customerId: string;
}

export interface FactorEvaluationData {
  /**
   * 因子code
   */
  factorCode: string;
  /**
   * 因子值
   */
  factorValue: number;
  /**
   * 客户平均因子值
   */
  customerAvgFactorValue: number;
  /**
   * 因子标准差
   */
  factorStdValue: number;
  /**
   * 有效数据是否不足
   */
  dataMissing: boolean;
}

export type FactorEvaluationResponse = FactorEvaluationData[];
export type AssetTypeDistributionParams = {
  customerId: string;
  customerBenchmarkType: CUSTOMER_BENCHMARK_TYPE;
  groupType: GROUP_TYPE;
};

export enum CUSTOMER_BENCHMARK_TYPE {
  CUSTOMER_AVG = "CUSTOMER_AVG",
  CUSTOMER_AUM = "CUSTOMER_AUM",
}

export enum GROUP_TYPE {
  ASSET_POSITION_TYPE = "ASSET_POSITION_TYPE",
  CATEGORY_POSITION_TYPE = "CATEGORY_POSITION_TYPE",
}
