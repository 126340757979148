import React, { useEffect, useRef, useState } from "react";
import ReactEcharts, { EChartsReactProps } from "echarts-for-react";
import { useSize } from "ahooks";
// import type {
//   EChartsOption,
// } from "echarts";

interface BaseChartProps extends Partial<EChartsReactProps> {
  option: any;
  style?: Record<string, any>;
  width?: number;
  height?: number;
  onEvents?: EChartsReactProps["onEvents"];
  chartRef?: React.LegacyRef<ReactEcharts>;
}

export default React.memo<BaseChartProps>(
  ({ option, width, height, onEvents, chartRef = null, ...restProps }) => {
    // const chartRef = useRef<any>(null);
    const containerRef = useRef<any>(null);
    const [style, setStyle] = useState({ width: width || "100%", height });
    const size = useSize(containerRef);

    useEffect(() => {
      // chartRef.current?.resize();
      if (size && size?.width && size?.height) {
        setStyle({
          width: width || size?.width,
          height: height || size?.height,
        });
      }
    }, [size, width, height, setStyle]);

    return (
      <div ref={containerRef} style={{ width: "100%" }}>
        <ReactEcharts
          ref={chartRef}
          option={option}
          style={style}
          notMerge
          onEvents={onEvents || {}}
          {...restProps}
        />
      </div>
    );
  }
);
