import { useFormatMessage } from "@/util/formatMessage";
import { Card } from "antd";
import React, { useMemo } from "react";
import dayjs from "dayjs";
import LineChart, { LineChartOpts } from "@/echarts/lineChart";
import { fastProp } from "@/util/opt";
import { useAppSelector } from "@/hooks/redux";
import { first, isEmpty, map } from "lodash/fp";
import noStart from "@/assets/noStart.png";
import oneStart from "@/assets/oneStart.png";
import twoStart from "@/assets/twoStart.png";
import threeStart from "@/assets/threeStart.png";
import fourStart from "@/assets/fourStart.png";
import fiveStart from "@/assets/fiveStart.png";
import mapper from "../mapper";
import style from "../index.module.less";
import EmptyContent from "@/components/emptyContent";
const formatRating = (num: number) => {
  switch (num) {
    case 0:
      return "ratingNull";
    case 1:
      return "oneStar";
    case 2:
      return "twoStar";
    case 3:
      return "threeStar";
    case 4:
      return "fourStar";
    case 5:
      return "fiveStar";
    default:
      return "valueNull";
  }
};
const useGetLineChartDataSource = (fundId: string) => {
  const formatMessage = useFormatMessage();
  const { fundRatingTrend } = useAppSelector((state) => mapper(state, fundId));
  const fundRating = useMemo(
    () => fastProp("fundRating")(fundRatingTrend),
    [fundRatingTrend]
  );
  const fundName = useMemo(
    () => fastProp("name")(fundRatingTrend),
    [fundRatingTrend]
  );
  const processedDates = useMemo(
    () => map(fastProp("reportDate"))(fundRating),
    [fundRating]
  );
  const bfRatings = useMemo(
    () => map(fastProp("bfRating"))(fundRating),
    [fundRating]
  );
  const options = useMemo<LineChartOpts["options"]>(() => {
    return {
      xAxis: {
        data: processedDates,
        nameGap: 40,
        axisLabel: {
          formatter(value: string) {
            return `${dayjs(value).format("YYYY/MM/DD")}`;
          },
          align: "right",
        },
      },
      yAxis: {
        min: "dataMin",
        max: "dataMax",
        axisLabel: {
          formatter(value: number) {
            return "{" + formatRating(value) + "|" + "}";
          },
          rich: {
            value: {
              lineHeight: 30,
              align: "center",
            },
            ratingNull: {
              height: 15,
              align: "center",
              backgroundColor: {
                image: noStart,
              },
            },
            oneStar: {
              height: 15,
              align: "center",
              backgroundColor: {
                image: oneStart,
              },
            },
            twoStar: {
              height: 15,
              align: "center",
              backgroundColor: {
                image: twoStart,
              },
            },
            threeStar: {
              height: 15,
              align: "center",
              backgroundColor: {
                image: threeStart,
              },
            },
            fourStar: {
              height: 15,
              align: "center",
              backgroundColor: {
                image: fourStart,
              },
            },
            fiveStar: {
              height: 15,
              align: "center",
              backgroundColor: {
                image: fiveStart,
              },
            },
            valueNull: {},
          },
          hideOverlap: true,
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        valueFormatter: (value) => {
          return formatMessage(formatRating(value as number));
        },
      },
    };
  }, [formatMessage, processedDates]);
  const series = useMemo<LineChartOpts["series"]>(
    () => [
      {
        type: "line",
        lineStyle: {
          width: 1.5,
        },
        name: fundName,
        data: bfRatings,
      },
    ],
    [bfRatings, fundName]
  );
  return { options, series };
};

export default React.memo(({ fundId }: { fundId: string }): JSX.Element => {
  const formatMessage = useFormatMessage();
  const { options, series } = useGetLineChartDataSource(fundId);
  return (
    <Card bordered={false} size="small">
      <div className={style.Title}>{formatMessage("fundRatingChange")}</div>
      {isEmpty(fastProp("data")(first(series))) ? (
        <EmptyContent
          message={formatMessage("noData")}
          className={style.EmptyContent}
        />
      ) : (
        <LineChart
          width="100%"
          height={400}
          series={series}
          options={options}
        />
      )}
    </Card>
  );
});
