import SelectFundDialog from "@/components/portfolioCompoents/selectFundDialog";
import { Button, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import style from "./index.module.less";
import { useCreation, useMemoizedFn } from "ahooks";
import { isEmpty, map, omit, prop, size } from "lodash/fp";
import { addFundWeights } from "../../manualCreatePortfolio/constant";
import {
  useGenerateFundConfigurationAsset,
  useManageErrorField,
  useManageConfigurationAssets,
  useManageFundConfigurationForm,
} from "../hooks";
import FundConfigurationTable from "./fundConfigurationTable";
import ErrorField from "../../manualCreatePortfolio/components/errorField";
import FundConfigurationForm from "./fundConfigurationForm";
import EmptyContent from "@/components/emptyContent";
import { modelsConfig, validateErrorField } from "../constant";
import { AssetAllocateParams } from "@/model/portfolioAnalysis";
import { useFormatMessage } from "@/util/formatMessage";
import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";
import { fastProp } from "@/util/opt";
import { FundConfigurationAssetsUpdater } from "../interface";

export type FundConfigurationAllocationRef = {
  getData: () => AssetAllocateParams;
};

export default React.memo<{
  onError: React.Dispatch<React.SetStateAction<boolean>>;
  defaultIds?: string[];
}>(({ onError, defaultIds = [] }) => {
  const formatMessage = useFormatMessage();
  const { configurationAssets, updateAssets } = useManageConfigurationAssets();
  const {
    formData,
    onUpdateFormData,
    startDate,
    endDate,
    netValueStart,
    availableDateRange,
  } = useManageFundConfigurationForm(configurationAssets);
  const errorField = useManageErrorField({
    assets: configurationAssets,
    startDate,
    endDate,
    netValueStart,
    formData,
  });
  useEffect(() => {
    const newErrorField = omit(["fundLess3MothError"])(errorField) as any;
    onError(validateErrorField(newErrorField));
  }, [errorField, onError]);
  const fundIds = useCreation(
    () => map("fundId")(configurationAssets),
    [configurationAssets]
  );
  const generateAsset = useGenerateFundConfigurationAsset();
  const onChangeFundIds = useMemoizedFn((ids: string[]) => {
    updateAssets(addFundWeights(generateAsset, ids));
    onUpdateFormData("modelType")(modelsConfig.equalWeight);
  });
  useEffect(() => {
    if (!isEmpty(defaultIds)) {
      onChangeFundIds(defaultIds);
    }
  }, [defaultIds, onChangeFundIds]);
  const fundIdMap = useAppSelector(fundIdMapSelector);
  const someFundLess3MothError = useCreation(() => {
    const fundId = fastProp("fundLess3MothError")(errorField);
    if (fundId)
      return `${prop([fundId, "name"])(fundIdMap)} ${formatMessage(
        "someFundLess3MothErrorMessage"
      )}`;
    return "";
  }, [errorField, formatMessage, fundIdMap]);
  const onUpdateAssets = useMemoizedFn(
    (updater: FundConfigurationAssetsUpdater) =>
      updateAssets((data) => {
        const result = updater(data);
        const isRemove = size(data) !== size(result);
        if (isRemove) onUpdateFormData("modelType")(modelsConfig.equalWeight);
        return result;
      })
  );
  return (
    <SelectFundDialog fundIds={fundIds} onChange={onChangeFundIds}>
      {(onChangeVisible) => (
        <>
          <Card className={style.Card}>
            <div className={style.Header}>
              <h3>{formatMessage("OptionalFund")}</h3>
              {!isEmpty(configurationAssets) && (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={onChangeVisible}
                >
                  {formatMessage("addFund")}
                </Button>
              )}
            </div>
            {isEmpty(configurationAssets) ? (
              <EmptyContent
                message={formatMessage("isEmptyConfigurationAssets")}
                className={style.EmptyContent}
              >
                <Button
                  icon={<PlusOutlined />}
                  className={style.OptionsButton}
                  onClick={onChangeVisible}
                >
                  {formatMessage("addFund")}
                </Button>
              </EmptyContent>
            ) : (
              <>
                <FundConfigurationTable
                  editable
                  dataSource={configurationAssets}
                  assetIntersectionStartDate={startDate}
                  assetIntersectionEndDate={endDate}
                  onChange={onUpdateAssets}
                  errorField={errorField}
                />
                {errorField.fundLess3MothError ? (
                  <ErrorField
                    className={style.MarginTop8}
                    message={someFundLess3MothError}
                  />
                ) : errorField.fundMinWeightError ? (
                  <ErrorField
                    className={style.MarginTop8}
                    message={formatMessage("ConfigurationAssetsMinWeightError")}
                  />
                ) : (
                  errorField.fundMaxWeightError && (
                    <ErrorField
                      className={style.MarginTop8}
                      message={formatMessage(
                        "ConfigurationAssetsMaxWeightError"
                      )}
                    />
                  )
                )}
              </>
            )}
          </Card>
          <FundConfigurationForm
            startDate={startDate}
            endDate={endDate}
            availableDateRange={availableDateRange}
            formData={formData}
            updateFormData={onUpdateFormData}
            errorField={errorField}
          />
        </>
      )}
    </SelectFundDialog>
  );
});
