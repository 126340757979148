import React, { useMemo } from "react";
import { fastNth, mapIndexed } from "@/util/opt";
import type { BarSeriesOption, EChartsOption } from "echarts";
import {
  concat,
  keys,
  map,
  values,
  flow,
  isEmpty,
  merge,
  omit,
} from "lodash/fp";
import EchartsWrapper, { WrapperProps } from "./echartsWrapper";

export interface BarChartOpts extends WrapperProps {
  options?: EChartsOption & {
    categories: string[];
    data: Record<string, any>;
    series?: BarSeriesOption[];
  };
}

const useGetOptions = (options: BarChartOpts["options"]) =>
  useMemo(() => {
    const { categories, data, series } = options || {};
    if (isEmpty(categories) || isEmpty(data)) return { series: [], source: [] };
    const dataKeys = keys(data);
    const dataValues = values(data);
    const normalizedSeries = mapIndexed((_: any, index: number) => ({
      type: "bar",
      ...fastNth(index)(series || []),
    }))(dataKeys);
    const source = flow(
      mapIndexed((category: string, index: number) => [
        category,
        ...map<number[], number>((value) => fastNth(index)(value))(dataValues),
      ]),
      concat([["name", ...dataKeys]])
    )(categories);
    return { series: normalizedSeries, source };
  }, [options]);

export default React.memo<BarChartOpts>(
  ({ options, events = {}, width, showDataZoom, height }) => {
    const { series, source } = useGetOptions(options);
    const handleOptions = useMemo(
      () =>
        merge({
          grid: {
            left: 0,
            right: 0,
            top: 50,
            bottom: 50,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            top: 0,
            left: 0,
            align: "left",
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
          },
          series,
          dataset: {
            source,
          },
        })(omit(["categories", "data", "series"])(options)),
      [options, series, source]
    );
    return (
      <EchartsWrapper
        events={events}
        width={width}
        height={height}
        showDataZoom={showDataZoom}
        options={handleOptions as EChartsOption}
      />
    );
  }
);
