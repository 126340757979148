import { fastProp, mapIndexed } from "@/util/opt";
import { Table, TableProps } from "antd";
import { ColumnType } from "antd/lib/table";
import { concat, map, flow, fromPairs } from "lodash/fp";
import React, { useMemo } from "react";

interface HorizontalTableProps extends TableProps<any> {
  titleKey?: string;
  headingColumn: ColumnType<any>;
}

export default React.memo<HorizontalTableProps>(
  ({
    dataSource,
    columns,
    titleKey = "name",
    headingColumn,
    id = "id",
    ...rest
  }) => {
    const handleColumns = useMemo(
      () =>
        flow(
          mapIndexed((data: Record<string, any>, index: number) => ({
            ...data,
            title: headingColumn?.render
              ? headingColumn?.render(fastProp(titleKey)(data), data, index)
              : fastProp(titleKey)(data),
            dataIndex: fastProp(id)(data),
          })),
          concat(headingColumn)
        )(dataSource),
      [dataSource, titleKey, headingColumn, id]
    );

    const handleDataSource = useMemo(
      () =>
        map((column: ColumnType<any>) => ({
          ...column,
          ...fromPairs(
            map((item: Record<string, any>) => [
              fastProp(id)(item),
              fastProp(fastProp("dataIndex")(column))(item),
            ])(dataSource)
          ),
        }))(columns),
      [columns, dataSource, id]
    );

    return (
      <Table columns={handleColumns} dataSource={handleDataSource} {...rest} />
    );
  }
);
