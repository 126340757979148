import { PromiseType } from "@/model/promise";
import { fundsReplaceOpt } from "@/model/replaceFund";
import { post } from "@/util/axios";
import { socketHttp } from "@/util/socket";

// 基金的收益走势
export const getFundsDailyReturn = (
  fundIds: string[]
): PromiseType<Record<string, string>> =>
  post("/openfunds/daily_return", fundIds);

// 获取可替代的基金列表基本信息
export const getMainFilterList = (meta: fundsReplaceOpt) =>
  socketHttp("START_FIND_ALTERNATIVE_FUND", {
    meta,
  });
