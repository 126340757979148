import React, { useMemo } from "react";
import {
  findIndex,
  first,
  isEqual,
  last,
  map,
  prop,
  set,
  size,
  toLower,
} from "lodash/fp";
import cn from "classnames";

import { useFormatMessage } from "@/util/formatMessage";
import { useAppSelector } from "@/hooks/redux";

import style from "../index.module.less";
import BaseEcharts from "../../../echarts/baseEcharts";
import { EChartsOption } from "echarts";
import {
  EvaluationReportInfoBody,
  MarketRankDetailsParam,
  MarketCapabilityDetailParam,
} from "@/model/fundDetail";
import { formatPercentage } from "@/util/numberFormatter";
import { fastProp } from "@/util/opt";
import cumulativeReturns from "@/util/quant/cumulativeReturns";
import { getLastDateOfQuarter } from "./contants";

export default React.memo(
  ({
    name,
    type,
    markAreaColor,
    fundId,
  }: {
    name: string;
    type: string;
    markAreaColor: string;
    fundId: string;
  }) => {
    const formatMessage = useFormatMessage();

    const marketCapabilityDetail: MarketCapabilityDetailParam = useAppSelector(
      prop(`fundDetail.${fundId}.marketCapabilityDetail`)
    );

    const fundEvaluationReportInfo: EvaluationReportInfoBody = useAppSelector(
      prop(`fundDetail.${fundId}.evaluationReportInfo`)
    );
    const returns = fastProp("returns")(fundEvaluationReportInfo);
    const {
      fundReturns: fundDailyReturns,
      dates,
      benchmarkReturns: benchmarkDailyReturns,
    } = returns || {};

    const {
      marketRisingRankingDetails,
      marketFallRankingDetails,
      sameTypeFundCount,
    } = marketCapabilityDetail || {};

    const markAreaData = useMemo(() => {
      return map((item: MarketRankDetailsParam) => [
        {
          xAxis: item?.startDate,
        },
        {
          xAxis: item?.endDate,
        },
      ])(
        type === "RISE" ? marketRisingRankingDetails : marketFallRankingDetails
      );
    }, [marketRisingRankingDetails, marketFallRankingDetails, type]);

    const marketRankData = useMemo(() => {
      return type === "RISE"
        ? marketRisingRankingDetails
        : marketFallRankingDetails;
    }, [marketFallRankingDetails, marketRisingRankingDetails, type]);

    const quarterOfDates = useMemo(() => getLastDateOfQuarter(dates), [dates]);

    const options = useMemo<EChartsOption>(
      () => ({
        graphic: {
          type: "text",
          right: 0,
          bottom: 0,
          style: {
            text: `（${first(dates)} ~ ${last(dates)}）`,
            fontSize: 12,
            lineHeight: 12,
            fill: "#999999",
            fontFamily: "SourceHanSansCN-Normal, SourceHanSansCN",
          },
        },
        grid: {
          left: 60,
          top: 20,
          bottom: 100,
          right: 20,
        },
        legend: {
          itemHeight: 4,
        },
        xAxis: {
          data: dates,
          axisLabel: {
            fontSize: 12,
            hideOverlap: false,
            formatter: (v: string) => fastProp(v)(quarterOfDates) ?? "",
            interval: (_: number, value: string) =>
              fastProp(value)(quarterOfDates),
          },
          axisLine: { onZero: true },
        },
        series: [
          {
            name: formatMessage("theFund"),
            type: "line",
            data: cumulativeReturns(set(0, 0)(fundDailyReturns), true),
            markArea: {
              itemStyle: { color: markAreaColor },
              data: markAreaData as any,
            },
          },
          {
            name: formatMessage("csIndex800"),
            type: "line",
            data: cumulativeReturns(set(0, 0)(benchmarkDailyReturns), true),
          },
        ],
      }),
      [
        dates,
        formatMessage,
        fundDailyReturns,
        markAreaColor,
        markAreaData,
        benchmarkDailyReturns,
        quarterOfDates,
      ]
    );

    const calculateLeftVal = (rankDataItem: MarketRankDetailsParam) => {
      const { startDate } = rankDataItem || {};
      if (startDate) {
        const index = findIndex(isEqual(startDate))(dates);
        return formatPercentage(index / size(dates));
      } else return "-9999%";
    };
    return (
      <div className={style.TableChartWrap}>
        <div className={style.TableTitle}>
          {name}（
          {formatMessage("highestIncreaseThreeQuarters", {
            riseOrFall: formatMessage(toLower(type)),
          })}
          ）
        </div>
        <div className={style.TableWrap}>
          <div className={style.TableCol}>
            <div className={style.Col}>{formatMessage("periodIncome")}：</div>
            <div className={style.Col}>{formatMessage("excessIncome")}：</div>
            <div className={style.Col}>
              {formatMessage("similarRankings")}：
            </div>
          </div>
          <div className={style.DataColWrap}>
            {map((item: MarketRankDetailsParam) => {
              return (
                <div
                  className={cn(style.TableCol, style.DataCol)}
                  style={{ left: calculateLeftVal(item) }}
                  key={item.startDate}
                >
                  <div className={style.Col}>
                    {formatPercentage(item?.sectionReturn)}
                  </div>
                  <div className={style.Col}>
                    {formatPercentage(item?.excessReturn)}
                  </div>
                  <div className={style.Col}>
                    {item?.sameTypeRank}/{sameTypeFundCount}
                  </div>
                </div>
              );
            })(marketRankData)}
          </div>
        </div>
        <div className={style.ChartWrap}>
          <BaseEcharts
            options={options}
            height={336}
            opts={{ devicePixelRatio: 4 }}
          />
        </div>
      </div>
    );
  }
);
