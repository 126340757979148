import getMessage from "@/util/getMessage";
import { includes } from "lodash/fp";

export const getCostPerformance = (prosperity: string, congestion: string) => {
  // prosperity景气度，congestion拥挤度
  if (includes("HIGH")(prosperity) && includes("HIGH")(congestion)) {
    //拥挤度高，景气度高
    return getMessage("ORDINARY");
  }
  if (includes("MEDIUM")(prosperity) && includes("HIGH")(congestion)) {
    //拥挤度高，景气度一般
    return getMessage("lower");
  }
  if (includes("LOW")(prosperity) && includes("HIGH")(congestion)) {
    //拥挤度高，景气度低
    return getMessage("lower");
  }
  if (includes("HIGH")(prosperity) && includes("MEDIUM")(congestion)) {
    //拥挤度一般，景气度高
    return getMessage("higher");
  }
  if (includes("MEDIUM")(prosperity) && includes("MEDIUM")(congestion)) {
    //拥挤度一般，景气度一般
    return getMessage("ORDINARY");
  }
  if (includes("LOW")(prosperity) && includes("MEDIUM")(congestion)) {
    //拥挤度一般，景气度低
    return getMessage("ORDINARY");
  }
  if (includes("HIGH")(prosperity) && includes("LOW")(congestion)) {
    //拥挤度低，景气度高
    return getMessage("higher");
  }
  if (includes("MEDIUM")(prosperity) && includes("LOW")(congestion)) {
    //拥挤度低，景气度一般
    return getMessage("ORDINARY");
  }
  if (includes("LOW")(prosperity) && includes("LOW")(congestion)) {
    //拥挤度低，景气度低
    return getMessage("ORDINARY");
  }
};

export const getMatchDegree = (level: string) => {
  if (includes("HIGH")(level)) {
    return getMessage("lower");
  }
  if (includes("MEDIUM")(level)) {
    return getMessage("ORDINARY");
  }
  if (includes("LOW")(level)) {
    return getMessage("higher");
  }
};

export const getProsperity = (prosperity: string) => {
  if (includes("HIGH")(prosperity)) {
    return getMessage("high");
  }
  if (includes("MEDIUM")(prosperity)) {
    return getMessage("middle");
  }
  if (includes("LOW")(prosperity)) {
    return getMessage("low");
  }
};

export const getRiskLevel = (level: string) => {
  if (includes("HIGH")(level)) {
    return getMessage("Strong");
  }
  if (includes("MEDIUM")(level)) {
    return getMessage("moderate");
  }
  if (includes("LOW")(level)) {
    return getMessage("lower");
  }
};

export const CHART_COLOR = ["#9CACBC", "#1A396A", "#CFB47F"];
export const riskLevel = ["value", "balance", "growth"];
