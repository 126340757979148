import { BenchmarkInterface } from "@/model/entities";
import { flow, sortBy } from "lodash/fp";
import { fastProp } from "@/util/opt";
import { mapTree } from "@/util/opt/tree";
import getMessage from "./getMessage";

export const transformData = (data: BenchmarkInterface[]) =>
  flow(
    sortBy<BenchmarkInterface>((item) => -fastProp("sort")(item)),
    mapTree("children", (item: BenchmarkInterface) => ({
      ...item,
      name: getMessage(fastProp("name")(item)),
      nameKey: fastProp("name")(item),
    }))
  )(data);
