import getMaxDrawdown from "@/util/quant/maxDrawdown";
import getSharpeRatio from "@/util/quant/sharpeRatio";
import getInformationRatio from "@/util/quant/informationRatio";
import getTrackDeviation from "@/util/quant/trackDeviation";
import getAnnualVolatility from "@/util/quant/annualVolatility";
import getAnnualReturns from "@/util/quant/annualReturns";
import getCalmarRatio from "@/util/quant/calmarRatio";
import getSortinoRatio from "@/util/quant/sortinoRatio";
import getBeta from "@/util/quant/beta";
import getAlpha from "@/util/quant/alpha";
import getSkewness from "@/util/quant/skewness";
import getKurtosis from "@/util/quant/kurtosis";
import getDownwardVolatilty from "@/util/quant/downwardVolatilty";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import getVar from "@/util/quant/var";
import getStutzer from "@/util/quant/stutzer";
import getAnnualActiveRisk from "@/util/quant/annualizedActiveRisk";
import getHurst from "@/util/quant/hurst";
import { map, flow, last, first, set, size, pick, update } from "lodash/fp";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { formatNilToZero } from "@/util/numberFormatter";
import {
  fromThisYearValidator,
  FROM_CREATION,
  FROM_THIS_YEAR,
  RangeInterface,
  RECENT_FIVE_YEAR,
  RECENT_HALF_YEAR,
  RECENT_MONTH,
  RECENT_THREE_MONTH,
  RECENT_THREE_YEAR,
  RECENT_TWO_YEAR,
  RECENT_YEAR,
  statisticRange,
} from "./statisticRange";
import { getCumulativeCalculateRangeDate } from "./statisticRangeCalculator/cumulativeCalculator";

export type factorRatioFuncProps = {
  dailyReturns: number[];
  riskFreeRates: number[];
  dailyActiveReturns: number[];
  dailyBenchmarkReturns: number[];
  dates: string[];
  startDateNetValue: number;
};
export const factorRatioFuncs = {
  // 最大回撤
  maxDrawdown: ({ dailyReturns }: factorRatioFuncProps) =>
    getMaxDrawdown(dailyReturns),
  // 基准最大回撤
  benchmarkMaxDrawdown: ({ dailyBenchmarkReturns }: factorRatioFuncProps) =>
    getMaxDrawdown(dailyBenchmarkReturns),
  //年化波动率
  annualVolatility: ({ dailyReturns }: factorRatioFuncProps) =>
    getAnnualVolatility(dailyReturns),
  //年化收益率
  annualReturn: ({ dailyReturns }: factorRatioFuncProps) =>
    getAnnualReturns(dailyReturns),
  //年化收益率
  benchmarkAnnualReturn: ({ dailyBenchmarkReturns }: factorRatioFuncProps) =>
    getAnnualReturns(dailyBenchmarkReturns),

  // 卡玛比率
  calmarRatio: ({ dailyReturns, riskFreeRates }: factorRatioFuncProps) =>
    getCalmarRatio(dailyReturns, riskFreeRates),
  // 索提诺比率
  sortinoRatio: ({ dailyReturns, riskFreeRates }: factorRatioFuncProps) =>
    getSortinoRatio(dailyReturns, riskFreeRates),

  // beta
  beta: ({
    dailyReturns,
    dailyBenchmarkReturns,
    riskFreeRates,
  }: factorRatioFuncProps) =>
    getBeta(dailyReturns, dailyBenchmarkReturns, riskFreeRates),
  // alpha
  alpha: ({
    dailyReturns,
    dailyBenchmarkReturns,
    riskFreeRates,
  }: factorRatioFuncProps) =>
    getAlpha(dailyReturns, dailyBenchmarkReturns, riskFreeRates),

  // 偏度
  skewness: ({ dailyReturns }: factorRatioFuncProps) =>
    getSkewness(dailyReturns),

  // 峰度
  kurtosis: ({ dailyReturns }: factorRatioFuncProps) =>
    getKurtosis(dailyReturns),
  // 夏普比率
  sharpe: ({ dailyReturns, riskFreeRates }: factorRatioFuncProps) =>
    getSharpeRatio(dailyReturns, riskFreeRates),
  // 基准夏普比率
  benchmarkSharpe: ({
    dailyBenchmarkReturns,
    riskFreeRates,
  }: factorRatioFuncProps) =>
    getSharpeRatio(dailyBenchmarkReturns, riskFreeRates),
  // 信息比率
  infoRatio: ({ dailyActiveReturns }: factorRatioFuncProps) =>
    getInformationRatio(dailyActiveReturns),
  // 跟踪误差
  trackDeviation: ({
    dailyReturns,
    dailyBenchmarkReturns,
  }: factorRatioFuncProps) =>
    getTrackDeviation(dailyReturns, dailyBenchmarkReturns),
  // 组合业绩
  portfolioReturn: ({ dailyReturns }: factorRatioFuncProps) =>
    last(getCumulativeReturns(dailyReturns)),
  // 基准业绩
  benchmarkAttribution: ({ dailyBenchmarkReturns }: factorRatioFuncProps) =>
    last(getCumulativeReturns(dailyBenchmarkReturns)),
  //年化主动风险
  annualizedActiveRisk: ({
    dailyReturns,
    dailyBenchmarkReturns,
  }: factorRatioFuncProps) =>
    getAnnualActiveRisk(dailyReturns, dailyBenchmarkReturns),
  //下行波动率
  downwardVolatilty: ({ dailyReturns }: factorRatioFuncProps) =>
    getDownwardVolatilty(dailyReturns),
  //stutzer
  stutzer: ({ dailyReturns, dailyBenchmarkReturns }: factorRatioFuncProps) =>
    getStutzer(dailyReturns, dailyBenchmarkReturns),
  //赫斯特指数
  hurst: ({ dailyReturns }: factorRatioFuncProps) => getHurst(dailyReturns),
  //var
  var: ({ dailyReturns, startDateNetValue }: factorRatioFuncProps) =>
    getVar(dailyReturns, 0.95, startDateNetValue),
};

//近1月、近3月、近6月、今年以来、近1年、近2年、近3年、近5年、成立以来
export const portfolioAnalysisStatisticRange = flow(
  pick([
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    FROM_THIS_YEAR,
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    RECENT_THREE_YEAR,
    RECENT_FIVE_YEAR,
    FROM_CREATION,
  ]),
  update(
    FROM_THIS_YEAR,
    set(
      "checkIsValid",
      (
        startDate: string,
        endDate: string,
        processedTradingDate: Record<string, any>,
        tradingDateSize: number
      ) =>
        fromThisYearValidator({
          startDate,
          endDate,
          // 以endDate结束的那一年作为验证今年开始的日期
          thisYearDate: endDate,
          processedTradingDate,
          tradingDateSize,
        })
    )
  )
)(statisticRange) as Record<string, RangeInterface>;

export const getPortfolioReturns = ({
  range,
  dates,
  portfolioDailyReturnsMap,
  benchmarkDailyReturnsMap,
  netValueReturnMap,
  riskFreeRates,
  tradingDateList,
  processedTradingDates,
}: {
  range: string;
  dates: string[];
  portfolioDailyReturnsMap: Record<string, number>;
  benchmarkDailyReturnsMap: Record<string, number>;
  netValueReturnMap: Record<string, number>;
  riskFreeRates: Record<string, number>;
  tradingDateList: string[];
  processedTradingDates: Record<string, any>;
}): factorRatioFuncProps => {
  const newDates = getCumulativeCalculateRangeDate({
    dates,
    range,
    tradingDateList,
    processedTradingDates,
    thisYearDate: last(dates),
  });
  const dailyReturns = map((v: string) =>
    formatNilToZero(fastProp(v)(portfolioDailyReturnsMap))
  )(newDates);
  const dailyBenchmarkReturns = flow(
    map((v: string) => formatNilToZero(fastProp(v)(benchmarkDailyReturnsMap))),
    (dailyReturns: number[]) =>
      size(dates) === size(newDates) ? set(0, 0)(dailyReturns) : dailyReturns
  )(newDates);
  const dailyActiveReturns = mapIndexed(
    (dailyReturn: number, index: number) =>
      dailyReturn - fastNth(index)(dailyBenchmarkReturns)
  )(dailyReturns);
  const newRiskFreeRates = map((v: string) =>
    formatNilToZero(fastProp(v)(riskFreeRates))
  )(newDates);
  const startDateNetValue = fastProp(first(newDates) as string)(
    netValueReturnMap
  );
  return {
    dailyReturns: dailyReturns,
    dailyBenchmarkReturns: dailyBenchmarkReturns,
    dailyActiveReturns: dailyActiveReturns,
    dates: newDates,
    riskFreeRates: newRiskFreeRates,
    startDateNetValue,
  };
};
