import { Card, Space, Tabs } from "antd";
import React, { useState } from "react";
import cn from "classnames";
import { useFormatMessage } from "@/util/formatMessage";
import { map } from "lodash/fp";
import CompetitionBasicInfo from "./components/competitionBasicInfo";
import style from "./index.module.less";
import { useGetCompetitionRangeTabs } from "./hook";
import EmptyContent from "@/components/emptyContent";
import { fastProp } from "@/util/opt";
const { TabPane } = Tabs;

const noArrivateDateTip = {
  recent_week: "CompetitionDateNoOneWeekTip",
  recent_month: "CompetitionDateNoOneMonthTip",
  recent_three_month: "CompetitionDateNoThreeMonthTip",
};

const CompetitionDetail = ({
  competitionId,
}: {
  competitionId: string;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  const [type, setType] = useState<string>("from_creation");
  const competitionRangeTabs = useGetCompetitionRangeTabs();

  return (
    <Space direction="vertical" className={style.FullWidth}>
      <CompetitionBasicInfo competitionId={competitionId} type={type} />
      <Card className={style.RootContainer}>
        <Tabs className={cn(style.Tabs)} onChange={setType}>
          {map(({ id, name, Component, isArriveDate }) => (
            <TabPane key={id} tab={formatMessage(name)}>
              {isArriveDate ? (
                <Component type={id} competitionId={competitionId} />
              ) : (
                <EmptyContent
                  message={formatMessage(
                    fastProp(id)(noArrivateDateTip) || "noData"
                  )}
                  className={style.EmptyCompetition}
                ></EmptyContent>
              )}
            </TabPane>
          ))(competitionRangeTabs)}
        </Tabs>
      </Card>
    </Space>
  );
};

export default React.memo(CompetitionDetail);
