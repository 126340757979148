import dayjs from "dayjs";
import {
  concat,
  first,
  flatten,
  flow,
  forEach,
  isEmpty,
  map,
  omit,
  pick,
  prop,
  size,
  slice,
  some,
  sum,
  take,
  update,
  last,
  toArray,
  maxBy,
} from "lodash/fp";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FormatMessageFunc } from "@/util/formatMessage";
import { factorsFormatter } from "@/constant/factorFormatter";
import { ColumnsType } from "antd/lib/table";
import { netValueAttribution } from "@/model/portfolioAnalysis";
import { fastNth, fastProp, forEachIndexed, mapIndexed } from "@/util/opt";
import { getPreviousTradingDate } from "@/util/processedDates";
import { bigNumber } from "@/util/math";
import { Tooltip } from "antd";
import { treeDataType } from "@/model/fundDetail";
import { formatPercentage } from "@/util/numberFormatter";
import style from "./index.module.less";
import { getThisYear } from "@/constant/statisticRange";
import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";

export const customOptions = flow(
  omit(["RECENT_MONTH", "RECENT_THREE_MONTH"]),
  update("FROM_THIS_YEAR", (config) => ({
    ...config,
    checkIsValid: (
      start: string,
      end: string,
      processedTradingDate: Record<string, any>,
      tradingDateSize: number
    ) => {
      const beginThisYearDate = getThisYear(end);
      const begainThisYearStart = dayjs(start).isBefore(beginThisYearDate)
        ? beginThisYearDate
        : start;
      // 组合当前自然年运行1个自然月，且包含有15个交易日
      if (
        dayjs(end).diff(begainThisYearStart, "month") >= 1 &&
        config.checkIsValid(start, end, processedTradingDate, tradingDateSize)
      )
        return true;
      return false;
    },
  }))
)(portfolioAnalysisStatisticRange);

export const getFactorTableColumns = (
  formatMessage: FormatMessageFunc
): ColumnsType<any> => [
  {
    title: formatMessage("maxDrawdown"),
    dataIndex: "maxDrawdown",
    align: "center",
    render: factorsFormatter?.maxDrawdown,
  },
  {
    title: formatMessage("sharpeRatio"),
    dataIndex: "annualSharpeRatio",
    align: "center",
    render: factorsFormatter?.sharpeRatio,
  },
  {
    title: formatMessage("portfolioAttribution"),
    dataIndex: "assetValue",
    align: "center",
    render: factorsFormatter?.yield,
  },
  {
    title: formatMessage("valueOfPerformance"),
    dataIndex: "worthValue",
    align: "center",
    render: factorsFormatter?.yield,
  },
  {
    title: formatMessage("sizeOfPerformance"),
    dataIndex: "scaleValue",
    align: "center",
    render: factorsFormatter?.yield,
  },
  {
    title: formatMessage("marketPerformance"),
    dataIndex: "marketRiskValue",
    align: "center",
    render: factorsFormatter?.yield,
  },
];

export const groupDataByIndexArray =
  (...arr: (string | number)[][]) =>
  (groupedIndexArray: number[]) => {
    const reserveArr = map<any, (string | number)[][]>(() => [])(arr);
    forEachIndexed((dataSize: number, index: number) => {
      const startIndex = sum(take(index)(groupedIndexArray));
      forEachIndexed((item: number[], idx: number) => {
        reserveArr[idx].push(slice(startIndex, startIndex + dataSize)(item));
      })(arr);
    })(groupedIndexArray);
    return reserveArr;
  };

export const NamesMap = {};

const MIN_TRADING_DATE_SIZE = 15;
export const getChartData = (
  result: netValueAttribution,
  tradingDates: string[],
  processedTradingDates: Record<string, any>
) => {
  const { dates, performace } = result.dailyPerformace || {};
  if (isEmpty(dates) || isEmpty(performace)) return {};
  const { assetValues, marketRiskValues, scaleValues, worthValues } =
    performace || {};

  const monthTradingDateNum = prop("monthPerformance.monthTradingDateNum")(
    result
  );
  // 将日收益数据按照每个月交易日个数进行划分
  const groupedData = groupDataByIndexArray(
    dates,
    assetValues,
    marketRiskValues,
    scaleValues,
    worthValues
  )(monthTradingDateNum);

  // 展平后得到日收益
  const [nDates, nAssetValues, nMarketRiskValues, nScaleValues, nWorthValues] =
    map(flatten)(groupedData);

  const firstDate = getPreviousTradingDate(
    tradingDates,
    processedTradingDates,
    first(nDates) as string
  );
  return {
    dates: concat(firstDate)(nDates as string[]),
    dataSource: {
      portfolioAttribution: isEmpty(nAssetValues)
        ? nAssetValues
        : concat(0)(nAssetValues as number[]),
      marketPerformance: isEmpty(nMarketRiskValues)
        ? nMarketRiskValues
        : concat(0)(nMarketRiskValues as number[]),
      sizeOfPerformance: isEmpty(nScaleValues)
        ? nScaleValues
        : concat(0)(nScaleValues as number[]),
      valueOfPerformance: isEmpty(nWorthValues)
        ? nWorthValues
        : concat(0)(nWorthValues as number[]),
    },
  };
};

const { convert, toNumber, plus } = bigNumber;
const getFactorAttribution = (
  marketRiskAttribution: number,
  scaleAttribution: number,
  valueAttribution: number
) => {
  if (some(isNaN)([marketRiskAttribution, scaleAttribution, valueAttribution]))
    return NaN;
  return flow(
    convert,
    plus(scaleAttribution),
    plus(marketRiskAttribution),
    toNumber
  )(valueAttribution);
};
export const getFormatStatisticsTableData = (result: netValueAttribution) => {
  const { dates, performace, monthTradingDateNum } =
    fastProp("monthPerformance")(result) || {};
  if (isEmpty(dates) || isEmpty(performace)) return [];
  const {
    assetValues,
    marketRiskValues,
    scaleValues,
    worthValues,
    otherValues,
  } = performace || {};
  return mapIndexed((date: string, index: number) => {
    const marketRiskAttribution = fastNth(index)(marketRiskValues);
    const scaleAttribution = fastNth(index)(scaleValues);
    const valueAttribution = fastNth(index)(worthValues);
    const dataMissing =
      fastNth(index)(monthTradingDateNum) < MIN_TRADING_DATE_SIZE;
    return {
      id: date,
      date,
      portfolioAttribution: fastNth(index)(assetValues),
      marketRiskAttribution,
      scaleAttribution,
      valueAttribution,
      otherAttribution: fastNth(index)(otherValues),
      dataMissing,
      factorAttribution: getFactorAttribution(
        marketRiskAttribution,
        scaleAttribution,
        valueAttribution
      ),
    };
  })(dates);
};

export const getStatisticsTableColumnsAndDataSource = (
  data: any[],
  formatMessage: FormatMessageFunc
) => {
  const columns: ColumnsType<any> = [
    {
      dataIndex: "name",
      title: "名称",
      width: 150,
      align: "left",
      fixed: "left",
    },
  ];
  const indicatorsData = {
    portfolioAttribution: {
      id: "portfolioAttribution",
      name: formatMessage("portfolioAttribution"),
    },
    marketRiskAttribution: {
      id: "marketRiskAttribution",
      name: formatMessage("marketPerformance"),
    },
    scaleAttribution: {
      id: "scaleAttribution",
      name: formatMessage("sizeOfPerformance"),
    },
    valueAttribution: {
      id: "valueAttribution",
      name: formatMessage("valueOfPerformance"),
    },
    otherAttribution: {
      id: "otherAttribution",
      name: formatMessage("otherAttributions"),
    },
    factorAttribution: {
      id: "factorAttribution",
      name: formatMessage("factorAttribution"),
    },
  };
  forEach((statistic: any) => {
    const id = fastProp("id")(statistic);
    const dataMissing = fastProp("dataMissing")(statistic);
    columns.push({
      dataIndex: id,
      width: 120,
      title: dataMissing ? (
        <div>
          <span>{id}</span>
          <Tooltip title={formatMessage("netValueLess15DateWarning")}>
            <InfoCircleOutlined className={style.WarningIcon} />
          </Tooltip>
        </div>
      ) : (
        id
      ),
    });
    forEachIndexed((value: Record<string, any>, key: string) => {
      value[id] = factorsFormatter.yield(fastProp(key)(statistic));
    })(indicatorsData);
  })(data);
  return {
    columns,
    dataSource: [
      {
        ...fastProp("factorAttribution")(indicatorsData),
        children: flow(
          pick([
            "valueAttribution",
            "scaleAttribution",
            "marketRiskAttribution",
          ]),
          toArray
        )(indicatorsData),
      },
      fastProp("otherAttribution")(indicatorsData),
      fastProp("portfolioAttribution")(indicatorsData),
    ],
  };
};

export const getNetValueTreeData = (
  netValueAttribution: netValueAttribution,
  formatMessage: FormatMessageFunc
): treeDataType => {
  const { assetValue, marketRiskValue, scaleValue, worthValue, otherValue } =
    netValueAttribution.allPerformace || {};
  const factorValue = getFactorAttribution(
    marketRiskValue,
    scaleValue,
    worthValue
  );
  return {
    name: formatMessage("portfolioAttribution"),
    value: assetValue,
    children: [
      {
        name: formatMessage("factorAttribution"),
        value: factorValue,
        children: [
          {
            name: formatMessage("valueOfPerformance"),
            value: worthValue,
          },
          {
            name: formatMessage("sizeOfPerformance"),
            value: scaleValue,
          },
          {
            name: formatMessage("marketPerformance"),
            value: marketRiskValue,
          },
        ],
      },
      {
        name: formatMessage("otherAttributions"),
        value: otherValue,
      },
    ],
  };
};

export const getStatisticsText = (
  netValueAttribution: netValueAttribution,
  formatMessage: FormatMessageFunc
) => {
  if (isEmpty(netValueAttribution.allPerformace)) return null;
  const { marketRiskValue, scaleValue, worthValue } =
    netValueAttribution.allPerformace || {};
  const dates = netValueAttribution?.dailyPerformace?.dates;
  const startDate = first(dates);
  const endDate = last(dates);
  const maxValue = maxBy((v: number) => Math.abs(v))([
    marketRiskValue,
    scaleValue,
    worthValue,
  ]);
  const maxPercentValue = formatPercentage(maxValue);
  if (maxValue === marketRiskValue) {
    return formatMessage("marketRiskValueTip", {
      from: startDate,
      to: endDate,
      value: maxPercentValue,
    });
  }
  if (maxValue === scaleValue) {
    return formatMessage("scaleValueTip", {
      from: startDate,
      to: endDate,
      value: maxPercentValue,
    });
  }
  if (maxValue === worthValue) {
    return formatMessage("worthValueTip", {
      from: startDate,
      to: endDate,
      value: maxPercentValue,
    });
  }
};

export const getDateRangeText = (
  netValueAttribution: netValueAttribution,
  formatMessage: FormatMessageFunc
) => {
  if (
    !netValueAttribution?.dailyPerformace?.dates ||
    !netValueAttribution?.monthPerformance?.monthTradingDateNum
  )
    return null;
  const dates = netValueAttribution?.dailyPerformace?.dates;
  const start = first(dates);
  const end = last(dates);
  if (!start || !end) return null;
  const monthTradingDateNum =
    netValueAttribution?.monthPerformance?.monthTradingDateNum;
  const dateMonthSize = flow(size)(monthTradingDateNum);
  if (!dateMonthSize) return `${start || ""} - ${end || ""}`;
  return `${start || ""} - ${end || ""} ${formatMessage("sumMonthSize", {
    size: dateMonthSize,
  })}`;
};
