import { getWithCache, get } from "@/util/axios";
import { socketHttp } from "@/util/socket";
import type { FactorCategory } from "@/model/factors";

type ApiFactorCategory = {
  openFundFactorTypeView: {
    id: string;
    name: string;
  };
  openFundFactorViews: {
    id: string;
    name: string;
  }[];
};

export const getFundFactors = (): Promise<FactorCategory[]> =>
  getWithCache<ApiFactorCategory[]>("/openfunds/factors").then((v) =>
    v.map((item) => ({
      id: item.openFundFactorTypeView.id,
      name: item.openFundFactorTypeView.name,
      factorViews: item.openFundFactorViews.map((factor) => ({
        id: factor.id,
        categoryId: item.openFundFactorTypeView.id,
        name: factor.name,
        disabled: false,
        sorter: true,
      })),
    }))
  );

export const getFactorValue = ({
  assetIds,
  factorId,
  date,
}: {
  assetIds: string[];
  factorId: string;
  date: string;
}) =>
  socketHttp("START_ASSET_FACTOR_EXPOSURE", {
    meta: {
      assetIds,
      date,
      factorId,
    },
  });

export const getPortfolioFactorValue = ({
  portfolioIds,
  factorId,
}: {
  portfolioIds: string[];
  factorId: string;
}) =>
  socketHttp("START_ASSET_PORTFOLIO_FACTOR_EXPOSURE", {
    meta: {
      portfolioIds,
      factorId,
    },
  });

export const getBenchmarkYieldFactors = (benchmarkId: string) =>
  get("/fund/detail/return_benchmark", {
    benchmarkId,
  });
