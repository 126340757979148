import { Form, InputNumber, Modal } from "antd";
import React, { useMemo, useState } from "react";

import { AssetItemType, PositionAssetTransactionParam } from "@/model/customer";
import { useFormatMessage } from "@/util/formatMessage";

import style from "./index.module.less";
import { useMemoizedFn } from "ahooks";
import { merge } from "lodash/fp";
import { positionAssetTransaction } from "@/store/customerAccountAnalysis";
import { useAppSelector } from "@/hooks/redux";
import TradingDatePicker from "@/components/tradingDatePicker";
import {
  useGetIsActionLoading,
  useLargeThanZeroValidator,
  useNotNullValidator,
} from "../hooks";
import dayjs from "dayjs";
import { customerAnalysisSelector } from "@/views/customerManage/customerAccountAnalysis/selectors";
import { fastProp } from "@/util/opt";
import { RootState } from "@/store";

const { Item } = Form;

export default React.memo(
  ({
    record,
    accountId,
    setPurchaseAndRedeemList,
  }: {
    record: AssetItemType;
    accountId: string;
    setPurchaseAndRedeemList: React.Dispatch<
      React.SetStateAction<PositionAssetTransactionParam["assets"]>
    >;
  }) => {
    const formatMessage = useFormatMessage();
    const [isShowModal, setIsShowModal] = useState(false);

    const [form] = Form.useForm();

    const { assetType, assetCode } = record;
    const handleOk = useMemoizedFn(() => {
      form.validateFields().then((values) => {
        setPurchaseAndRedeemList((preState) => [
          ...preState,
          merge({
            assetType,
            transactionType: "PURCHASE",
            assetCode,
          })(values),
        ]);
        setIsShowModal(false);
      });
    });
    const confirmLoading = useGetIsActionLoading(positionAssetTransaction);

    const notNullValidator = useNotNullValidator();
    const largeThanZeroValidator = useLargeThanZeroValidator();

    const accountAnalysisData = useAppSelector((state: RootState) =>
      customerAnalysisSelector(state, accountId)
    );
    const { from, to } = useMemo(
      () => fastProp("accountInfo")(accountAnalysisData) || {},
      [accountAnalysisData]
    );
    const disabledDate = useMemoizedFn(
      (date: string) => dayjs(date).isBefore(from) || dayjs(date).isAfter(to)
    );

    return (
      <div>
        <a onClick={() => setIsShowModal(true)}>
          {formatMessage("increasePosition")}
        </a>
        {isShowModal && (
          <Modal
            title={formatMessage("increasePosition")}
            open={isShowModal}
            okText={`${formatMessage("confirm")}${formatMessage(
              "increasePosition"
            )}`}
            onOk={handleOk}
            onCancel={() => setIsShowModal(false)}
            confirmLoading={confirmLoading}
          >
            <Form
              labelCol={{ span: 7 }}
              labelAlign="left"
              preserve={false}
              form={form}
              className={style.Form}
            >
              {assetType === "OPEN_FUND" ? (
                <>
                  <Item
                    label={`${formatMessage("buyIn")}${formatMessage(
                      "portion"
                    )}`}
                    name={"share"}
                    rules={[
                      {
                        validator: largeThanZeroValidator(
                          `${formatMessage("buyIn")}${formatMessage("portion")}`
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      className={style.FullWidth}
                      placeholder={`${formatMessage(
                        "pleaseEnter"
                      )}${formatMessage("buyIn")}${formatMessage("portion")}`}
                      precision={2}
                    />
                  </Item>
                  <Item
                    label={formatMessage("serviceCharge")}
                    name="handlingFee"
                    initialValue={0}
                    rules={[
                      {
                        validator: largeThanZeroValidator(
                          formatMessage("transactionCost"),
                          true
                        ),
                      },
                    ]}
                  >
                    {/* {purchaseFeeType === "feeRatio" ? (
                      <InputNumber<number>
                        formatter={(value) =>
                          `${multiplyFloadNum(2)(value || 0)(100)}%`
                        }
                        parser={(value) =>
                          Number(value?.replace("%", "")) / 100
                        }
                        step={0.0001}
                        className={style.FullWidth}
                        max={0.05}
                        min={0}
                        precision={4}
                      />
                    ) : ( */}
                    <InputNumber<number>
                      className={style.FullWidth}
                      min={0}
                      precision={2}
                    />
                    {/* )} */}
                  </Item>
                </>
              ) : (
                <>
                  <Item
                    label={formatMessage("numberOfSharesPurchased")}
                    name={"share"}
                    rules={[
                      {
                        validator: largeThanZeroValidator(
                          formatMessage("numberOfSharesPurchased")
                        ),
                      },
                    ]}
                    initialValue={100}
                  >
                    <InputNumber<number>
                      placeholder={formatMessage("atLeastBuy", {
                        value: formatMessage("stockNumber", { number: 100 }),
                      })}
                      formatter={(value) =>
                        Math.floor(Number(value) / 100) * 100 + ""
                      }
                      min={100}
                      precision={0}
                      className={style.FullWidth}
                      step={100}
                    />
                  </Item>
                  <Item
                    label={formatMessage("purchasePrice")}
                    name={"price"}
                    rules={[
                      {
                        validator: largeThanZeroValidator(
                          formatMessage("purchasePrice")
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={formatMessage("pleaseEnter")}
                      className={style.FullWidth}
                    />
                  </Item>
                  <Item
                    label={formatMessage("serviceCharge")}
                    name={"handlingFee"}
                    initialValue={0}
                    rules={[
                      {
                        validator: largeThanZeroValidator(
                          formatMessage("serviceCharge"),
                          true
                        ),
                      },
                    ]}
                  >
                    {/* <InputNumber<number>
                      formatter={(value) =>
                        `${multiplyFloadNum(2)(value || 0)(1000)}‰`
                      }
                      parser={(value) => Number(value?.replace("‰", "")) / 1000}
                      step={0.00001}
                      className={style.FullWidth}
                      max={0.003}
                      min={0}
                      precision={5}
                    /> */}
                    <InputNumber<number>
                      className={style.FullWidth}
                      min={0}
                      precision={2}
                    />
                  </Item>
                </>
              )}
              <Item
                label={formatMessage("purchaseDate")}
                name={"date"}
                rules={[
                  {
                    validator: notNullValidator(formatMessage("purchaseDate")),
                  },
                ]}
              >
                <TradingDatePicker
                  className={style.FullWidth}
                  disabledDate={disabledDate}
                />
              </Item>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
);
