import RadarChart from "@/echarts/radarChart";
import React, { useMemo } from "react";
import cn from "classnames";
import style from "./index.module.less";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { getRadarOptions } from "../constant";
import { useAppSelector } from "@/hooks/redux";
import { currentManagerInfoSelector } from "../../selectors";
import { RootState } from "@/store";
import { keys, values } from "lodash/fp";

export default React.memo<{
  id: string;
}>(({ id }) => {
  const managerInfo = useAppSelector((state: RootState) =>
    currentManagerInfoSelector(state, id)
  );

  const abilityValues = useMemo(
    () => fastProp("abilityValues")(managerInfo),
    [managerInfo]
  );
  const radarKeys: string[] = useMemo(
    () => keys(abilityValues),
    [abilityValues]
  );
  const radarValues: string[] = useMemo(
    () => values(abilityValues),
    [abilityValues]
  );

  const dataSource = useMemo(() => {
    return mapIndexed((key: string, index: number) => {
      const value = fastNth(index)(radarValues);
      return {
        name: key,
        value,
        max: 100,
      };
    })(radarKeys);
  }, [radarKeys, radarValues]);

  const options = useMemo(
    () => getRadarOptions(dataSource, radarKeys, radarValues),
    [dataSource, radarKeys, radarValues]
  );
  return (
    <div className={cn(style.GreyBackground, style.ManagerAbility)}>
      <RadarChart
        width="100%"
        height="100%"
        options={options}
        dataSource={dataSource}
      />
    </div>
  );
});
