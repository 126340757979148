import React, { useEffect, useMemo, useState } from "react";
import { Modal, Table } from "antd";
import cn from "classnames";

import LoadingComponent from "@/components/LoadingComponent";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  AssetItemType,
  FixedInvestmentDetailBody,
  InvestmentRecord,
} from "@/model/customer";
import { fetchFixedInvestmentDetail } from "@/store/customerAccountAnalysis";
import { useFormatMessage } from "@/util/formatMessage";

import style from "./index.module.less";
import { orderBy, prop } from "lodash/fp";
import type { ColumnsType } from "antd/es/table";
import { fixedNumberWithCommas, toFixedNumber } from "@/util/numberFormatter";
import { fastProp, normalize } from "@/util/opt";
import { useGetFrequencyOptions } from "../hooks";

export default React.memo(
  ({ record, accountId }: { record: AssetItemType; accountId: string }) => {
    const dispatch = useAppDispatch();
    const formatMessage = useFormatMessage();
    const [isShowDetail, setIsShowDetail] = useState(false);

    const { assetCode, investmentPeriods, assetName } = record;

    useEffect(() => {
      if (isShowDetail && accountId && assetCode)
        dispatch(fetchFixedInvestmentDetail({ accountId, assetCode }));
    }, [accountId, assetCode, dispatch, isShowDetail]);

    const columns: ColumnsType<InvestmentRecord> = useMemo(
      () => [
        {
          title: formatMessage("DATE"),
          dataIndex: "date",
          key: "date",
          align: "left",
        },
        {
          title: `${formatMessage("amountOfMoney")}(${formatMessage("YUAN")})`,
          dataIndex: "investAmount",
          key: "investAmount",
          align: "center",
          render: toFixedNumber(2),
        },
        {
          title: formatMessage("fixedInvestmentStatus"),
          dataIndex: "status",
          key: "status",
          align: "right",
          render: (v: boolean) =>
            `${formatMessage("fixedInvestment")}${
              v ? formatMessage("success") : formatMessage("fail")
            }`,
        },
      ],
      [formatMessage]
    );

    const fixedInvestmentDetailInfo: FixedInvestmentDetailBody =
      useAppSelector(
        prop(`customerAnalysis.${accountId}.fixedInvestmentDetail.${assetCode}`)
      ) || {};

    const {
      investmentRecords,
      investmentPerPeriod,
      cumulativeInvest,
      frequency,
      investmentPeriods: periods,
    } = fixedInvestmentDetailInfo;

    const dataSource = useMemo(
      () =>
        orderBy((item: InvestmentRecord) => item?.date)("desc")(
          investmentRecords
        ),
      [investmentRecords]
    );

    const frequencyOptions = useGetFrequencyOptions();
    const frequencyMap = normalize("value")(frequencyOptions);

    const frequencyLabel = useMemo(() => {
      const dayof = fastProp("dayOf")(frequency);
      if (fastProp("number")(frequency) === 2)
        return `${formatMessage("everyTwoWeek")}${prop(
          `WEEK_2.children.${dayof - 1}.label`
        )(frequencyMap)}${formatMessage("fixedInvestment")}`;
      switch (fastProp("period")(frequency)) {
        case "WEEK":
          return `${formatMessage("everyWeek")}${prop(
            `WEEK.children.${dayof - 1}.label`
          )(frequencyMap)}${formatMessage("fixedInvestment")}`;
        case "MONTH":
          return `${formatMessage("everyMonth")}${prop(
            `MONTH.children.${dayof - 1}.label`
          )(frequencyMap)}${formatMessage("fixedInvestment")}`;
        case "DAY":
          return formatMessage("everyDay");
        default:
          return "--";
      }
    }, [formatMessage, frequency, frequencyMap]);

    return (
      <div className={style.FixedInvestmentDetailWrap}>
        <div className={style.TriggerBtn} onClick={() => setIsShowDetail(true)}>
          {formatMessage("fixedInvestmentPeriods", { investmentPeriods })}
          <a>{">"}</a>
        </div>
        {isShowDetail && (
          <Modal
            title={formatMessage("fixedInvestmentDetail")}
            open={isShowDetail}
            footer={null}
            onCancel={() => setIsShowDetail(false)}
            className={style.FixedInvestmentModal}
            width={1000}
          >
            <LoadingComponent actions={fetchFixedInvestmentDetail}>
              <div className={style.FixedInvestmentModalContentWrap}>
                <div className={cn(style.FormDesc, style.Header)}>
                  {assetName}
                </div>
                <div className={style.InvestPlanWrap}>
                  <div className={style.InvestPlanItem}>
                    <div>{formatMessage("fixedInvestmentNumber")}</div>
                    <div>
                      <span>{periods}</span>
                    </div>
                  </div>
                  <div className={style.InvestPlanItem}>
                    <div>{formatMessage("accumulateFixedInvestment")}</div>
                    <div>
                      <span>¥ {fixedNumberWithCommas(cumulativeInvest)}</span>
                    </div>
                  </div>
                  <div className={style.InvestPlanItem}>
                    <div>{formatMessage("investmentPlan")}</div>
                    <div>
                      {`${frequencyLabel}`}
                      <span>
                        ¥ {fixedNumberWithCommas(investmentPerPeriod)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={style.FixedInvestmentRecordsWrap}>
                  <div className={style.TableTitle}>
                    {formatMessage("fixedInvestmentRecords")}
                  </div>
                  <Table
                    pagination={false}
                    className={style.Table}
                    scroll={{ y: 350 }}
                    columns={columns}
                    dataSource={dataSource}
                  />
                </div>
              </div>
            </LoadingComponent>
          </Modal>
        )}
      </div>
    );
  }
);
