import { Table, Space, Pagination } from "antd";
import React, { useEffect, useMemo } from "react";
import style from "../index.module.less";
import { useFormatMessage } from "@/util/formatMessage";
import { useGetNewsInfoColumns, useGetTablePagination } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import basicMapper from "../../../../mapper";
import { prop } from "lodash/fp";
import { fetchGetNewsInfo } from "@/store/fundDetailSlice";
import dayjs from "dayjs";
import { fastNth } from "@/util/opt";

export default React.memo(
  ({ fundId, code }: { fundId: string; code: string }): JSX.Element => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();
    const { columns, publishTime, keyword } =
      useGetNewsInfoColumns(formatMessage);
    const { fundBasic } = useAppSelector((state) => basicMapper(state, fundId));

    const { pageInfo, changePages } = useGetTablePagination(
      prop("NewsInfo.totalSize")(fundBasic)
    );

    const { pageNum, pageSize } = useMemo(() => {
      return {
        pageNum: pageInfo?.current || 1,
        pageSize: pageInfo?.pageSize || 15,
      };
    }, [pageInfo]);

    useEffect(() => {
      if (code) {
        dispatch(
          fetchGetNewsInfo({
            fundCode: code,
            pageNum: pageNum,
            fundId: fundId,
            pageSize: pageSize as number,
            from: publishTime
              ? dayjs(fastNth(0)(publishTime)).valueOf()
              : undefined,
            to: publishTime
              ? dayjs(fastNth(1)(publishTime)).valueOf()
              : undefined,
            keyWord: keyword,
          })
        );
      }
    }, [dispatch, code, fundId, pageNum, pageSize, publishTime, keyword]);

    const tableData = useMemo(
      () => prop("NewsInfo.result")(fundBasic),
      [fundBasic]
    );

    return (
      <Space direction="vertical" className={style.fullWidth}>
        <Table
          bordered={false}
          columns={columns as any}
          dataSource={tableData}
          className={style.NewTable}
          scroll={{
            y: 300,
          }}
          pagination={false}
        />
        <Pagination
          className={style.TablePagination}
          {...pageInfo}
          showSizeChanger
          showQuickJumper
          onChange={changePages}
        />
      </Space>
    );
  }
);
