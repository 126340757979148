import { TaskData } from "@/util/socket";

export interface CustomerListItem {
  customerId: number;
  displayTags: string[];
  customerAccountId: string;
  customerName: string;
  accountCount: number;
  productionNum: number;
  totalAsset: number;
  cumulativeReturn: number;
  accountFoundDate: string;
  accountManager: string;
  task?: TaskData & { id: number };
  sectionProfit: {
    RECENT_THREE_YEAR: number;
    FROM_CREATION: number;
    FROM_THIS_YEAR: number;
    RECENT_MONTH: number;
    RECENT_THREE_MONTH: number;
    RECENT_TRADING_DAY: number;
    RECENT_HALF_YEAR: number;
    RECENT_WEEK: number;
    RECENT_YEAR: number;
  };
}
export type CustomerListBody = CustomerListItem[];
export interface CustomerBasicInfoBody {
  aumId: string;
  customerNum: number;
  customerNumChange: number;
  assetScale: number;
  assetScaleChange: number;
  lastDayYield: number;
  recentWeekYield: number;
  recentMonthYield: number;
  managerName: string;
  assetTypeDistribution: {
    FIXEDINCOME_MARKET: number;
    EQUITY_MARKET: number;
    COMMODITY_MARKET: number;
    CURRENCY_MARKET: number;
  };
}
export interface CustomerVolatilityRequestParam {
  queryType: "VOLATILITY" | "GAIN_AND_LOSS";
  sortOrder: "ASC" | "DESC";
}
export interface CustomerVolatilityBody {
  customerVolatilityInfoList: {
    customerId: string;
    customerName: string;
    dayYield: number;
    profit: number;
  }[];
}

export interface CustomerSummaryBody {
  avgProductNum: number;
  largestWeightProduct: string;
  productWeight: number;
  largestWeightIndustry: string;
  industryWeight: number;
  largestWeightStyle: string;
  styleWeight: number;
  largestFundWeightProduct: string;
  largestStockWeightProduct: string;
  productFundWeight: number;
  productStockWeight: number;
}

export interface childrenAccountBody {
  accountName: string;
  totalAsset: number;
  cumulativeProfit: number;
  holdAsset: number;
  yesterdayProfit: number;
  accountId: string;
}

export interface AccountInfoBody {
  totalAsset: number;
  netAssets: number;
  totalLiability: number;
  subCustomerDetails: childrenAccountBody[];
  allAssetScale: number;
  from: string;
  to: string;
}

export interface RangeIncomeBody {
  [key: string]: number;
}

export interface purchaseRedeemBody {
  assetCode: string;
  createDate: string;
  type: "PURCHASE" | "REDEMPTION" | "APPEND_INVEST" | "REDUCE_INVEST";
  assetType: string;
  scale: number;
  accountName: string;
  share: number;
}
export interface AssetChangeParam {
  customerId: string;
  section: string;
}

export interface CumulativeIncomeParam extends AssetChangeParam {
  type: string;
}

export interface fundAsset {
  fundName: string;
  fundCode: string;
  category: string;
  recentMonthReturn: RangeIncomeBody[];
  incomeRate: number;
  holdDays: number;
  incomeCash: number;
  totalPurchase: number;
}

export interface stockAsset {
  stockName: string;
  stockCode: string;
  purchaseDate: string;
  stockMarket: string;
  industry: string;
  recentPriceRate: number;
  recentPoint: number;
  totalProfit: number;
  holdDays: number;
  tranche: number;
}
export interface AssetListBody {
  cash: number;
  fundInfos: fundAsset[];
  stockInfos: stockAsset[];
}

// export interface assetWeightTrendBody {
//     date:string;
//     assetWeight:number;
// }

export interface PositionTrendBody {
  assetWeightTrend: Record<string, number>;
  benchmarkDailyReturn: Record<string, number>;
  benchmarkIndex: number;
  assetNum: number;
  benchmarkCode: string;
  benchmarkName: string;
}

export interface AssetTypeDistributionBody {
  fundPositionTrend: PositionTrendBody;
  stockPositionTrend: PositionTrendBody;
  cashPositionTrend: PositionTrendBody;
  bondPositionTrend: PositionTrendBody;
  currencyPositionTrend: PositionTrendBody;
  equityPositionTrend: PositionTrendBody;
  otherPositionTrend: PositionTrendBody;
  dataMissing: boolean;
}
export interface OrganizationTypeBody {
  name: string;
  portfolioId: string;
}

export interface InvestmentDetailBody {
  topFiveFundScale: RangeIncomeBody;
  otherFundScaleSum: number;
}

export enum SummaryResult {
  /**
   * 显著高于
   */
  SIGNIFICANTLY_HIGHER_THAN = "SIGNIFICANTLY_HIGHER_THAN",
  /**
   * 几乎等于
   */
  ALMOST_EQUAL = "ALMOST_EQUAL",
  /**
   * 显著低于
   */
  SIGNIFICANTLY_LOWER_THAN = "SIGNIFICANTLY_LOWER_THAN",
}

export type AssetTypeDistributionSummaryType = {
  cumulativeRateSummary: {
    cumulativeRate: number; //客户的累计收益率
    benchmarkCumulativeRate: number; //基准累计收益率
    cumulativeRateStandard: SummaryResult; //累计收益率与用户池指标对比
  } | null;
  assetsWeightSummary: {
    weight: number; //客户资产权重
    weightStandard: SummaryResult; //客户资产权重与用户池对比
  } | null;
  accountSelfSummary: {
    preMonthWeight: number; //上月客户资产权重
    curMonthWeight: number; //当月客户资产权重
    ringRatio: number; //环比率
  } | null;
};
export interface AssetTypeDistributionSummaryBody {
  fundSummaryView: AssetTypeDistributionSummaryType;
  stockSummaryView: AssetTypeDistributionSummaryType;
  bondSummaryView: AssetTypeDistributionSummaryType;
  cashSummaryView: AssetTypeDistributionSummaryType;
}

export interface BrinsonAttributionResponse {
  dailyPortfolioReturns: number[];
  dailyPortfolioActualReturns: number[];
  dailyBenchmarkReturns: number[];
  dailyBenchmarkPositionReturns: number[];
  dates: string[];
  dailyAllocationAttributions: number[];
  subAttributions: {
    id: string;
    dailyAllocationAttributions: number[];
    dailySelectionAttributions: number[];
    dailyTimingAttributions: number[];
  }[];
  taskId: string;
  dailyAShareAttributions: number[];
  dailyNonAShareAttributions: number[];
  dailyNonEquityAttributions: number[];
  dailyAShareBenchmarkReturns: number[];
  dailyOtherReturns: number[];
}

export interface RiskDissectResponse {
  fundRiskChart: {
    fundId: string;
    weight: number;
    currentRisk: number;
    stressScenariosRisk: number;
    categoryId: string;
    industryName: string;
  }[];
  text: {
    riskLevel: string;
    maxRiskFund: string;
    maxRiskFundValue: number;
    minRiskFund: string;
    minRiskFundValue: number;
    maxRiskFundRatio: number;
  };
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface CustomerInfoProp {
  id?: string;
  name: string;
  idNumber: string;
  customerId: string;
  accountId: string;
  gender: Gender;
  birthday: string;
  phoneNumber: string;
  birthplace: string;
  company: string;
  industry: string;
  job: string;
  companyAddress: string;
  customerSource: string;
  assetScale: number;
  expectedReturn: number;
  expectedRisk: string;
  expectedInvestment: number;
  holdingTime: string;
  fundPreference: string;
  industryPreference: string;
  uploadAssets: subAccountNameProp[];
  allTags: string[];
  displayTags: string[];
  enterAccount: boolean;
}

export interface tagItem {
  id: string;
  name: string;
  frequency: number;
  userId: string;
  isEdit?: boolean;
}

export enum TransactionType {
  "PURCHASE" = "PURCHASE", //买入
  "REDEMPTION" = "REDEMPTION", //卖出
  "APPEND_INVEST" = "APPEND_INVEST", //转入
  "REDUCE_INVEST" = "REDUCE_INVEST", //转出
}

export enum AssetType {
  "STOCK" = "STOCK",
  "OPEN_FUND" = "OPEN_FUND",
  "BOND" = "BOND",
  "CASH" = "CASH",
}
export interface subAccountNameProp {
  subAccountName: string;
  assetCode: string;
  investScale: number;
  transactionType: TransactionType;
  investDate: string;
  assetType: string;
  share: number;
  id?: string;
  dividendPolicy?: string;
  transactionPrice?: number;
  handlingFee?: number;
}

export interface SaveCustomerParam {
  accountId?: string | null;
  overwrite: boolean;
  customerId: number | null;
  // customerName: string;
  enterAccount: boolean;
  uploadAssets: subAccountNameProp[];
}

export interface AssetsType {
  /**
   *资产名称
   */
  assetName: string;
  /**
   *资产名称
   */
  assetCode: string;
  /**
   *持有份额
   */
  holdingShare: number;
  /**
   *市场价
   */
  marketPrice: number;
  /**
   *持有收益
   */
  income: number;
  /**
   *持有金额
   */
  amount: number;
  /**
   *持有时长
   */
  holdDays: number;
  /**
   *定投计划
   */
  investmentPlan: string;
}

export interface AssetsPositionBody {
  totalScale: number;
  cashScale: number;
  fundAssets: AssetsType[];
  stockAssets: AssetsType[];
  bondAssets: AssetsType[];
}

export interface CustomerTurnoverRecordItem {
  subAccountName: string;
  assetCode: string;
  investScale: number;
  transactionType: TransactionType;
  investDate: string;
  assetType: AssetType;
  share: number;
  feeRatio: number | string;
  createDate: string;
}

export interface PositionTrendResponse {
  [key: string]: number;
}

export interface PositionAssetsListItem {
  accountId: string;
  totalScale: number;
  cashScale: number;
  assets: AssetItemType[];
}
export interface AssetItemType {
  assetName: string;
  assetCode: string;
  assetType: string;
  holdingShare: number;
  marketPrice: number;
  holdingIncome: number;
  holdingAmount: number;
  holdingDays: number;
  investmentPeriods: number;
  investStartDate: number;
  investPlanId: string;
  /**
   * 资产对应账户id，不由后端返回，由hook增加
   */
  accountId: string;
}

export type PositionAssetsListBody = PositionAssetsListItem[];

export interface SaveOrUpdateInvestPlanParam {
  accountId: string;
  assetCode: string;
  investScale: number;
  feeRatio: number;
  frequency: {
    period: "DAY" | "WEEK" | "MONTH" | "QUARTER";
    number: number;
    dayOf: number;
  };
  remark: string;
}

export interface PutInvestPlanStatusParam {
  accountId: string;
  assetCode: string;
  date: string;
}

export interface FixedInvestmentDetailParam {
  accountId: string;
  assetCode: string;
}

export interface InvestmentRecord {
  date: string;
  investAmount: number;
  status: boolean;
}
export interface FixedInvestmentDetailBody {
  investmentPeriods: number;
  cumulativeInvest: number;
  investmentPerPeriod: number;
  frequency: SaveOrUpdateInvestPlanParam["frequency"];
  investmentRecords: InvestmentRecord[];
}

export interface PositionAssetTransactionParam {
  accountId?: string;
  accountName?: string;
  parentAccountId?: string;
  assets: {
    assetType: "STOCK" | "OPEN_FUND" | "BOND" | "CASH";
    date: string;
    amount?: number;
    transactionType: purchaseRedeemBody["type"];
    assetCode?: string;
    feeRatio?: number;
    price?: number;
    share?: number;
  }[];
}

export interface NewAssetsItem {
  assetCode: string;
  assetType: string;
  transactionType?: string;
  price?: number;
  date: string;
  feeRatio?: number;
  dividendPolicy: string;
  handlingFee?: number;
  share?: number;
  amount?: number;
  investScale?: number;
}

export interface AddAssetInfoProp {
  accountId: string;
  customerNewAssets: NewAssetsItem[];
}

export interface FundsDailyReturnParam {
  fundCodes: string[];
  section: string;
}
export type FundsDailyReturnItem = {
  id: string;
  dailyReturns: {
    dates: string[];
    dailyReturn: number[];
  };
};

export type FundsDailyReturnBody = FundsDailyReturnItem[];
