import { useCallback, useMemo } from "react";
import { Space, Button, Tooltip } from "antd";
import { map, indexOf, keyBy, isEmpty, size, isArray } from "lodash/fp";
import { CheckCircleOutlined } from "@ant-design/icons";
import { CheckBoxItem } from "@/model/checkBoxGroup";
import { UNLIMITED } from "@/constant/fundFilter";
import { fastProp } from "@/util/opt";
import style from "./checkBoxGroup.module.less";

type CheckBoxGroupProps = {
  list: CheckBoxItem[];
  value: string[];
  onChange: (v: string[]) => void;
  buttonSize?: "large" | "middle" | "small";
  spacingSize?: number | [number, number];
  hideUnlimited?: boolean;
};
export const choiceStyle = (name: string, checked: boolean): boolean => {
  return size(name) > 6 || (size(name) > 5 && checked);
};
const CheckBoxGroup = ({
  list,
  value,
  onChange,
  buttonSize,
  spacingSize,
}: CheckBoxGroupProps): JSX.Element => {
  const valueMap = useMemo(() => keyBy((v) => v)(value), [value]);
  const checkItem = useCallback(
    (v: string) => {
      if (!isArray(value)) {
        if (v === UNLIMITED)
          onChange({
            ...(value as Record<string, any>),
            industryIds: [UNLIMITED],
          } as any);
        return;
      }
      const newValues: string[] = [...value];
      const idx = indexOf(v)(newValues);
      !fastProp(v)(valueMap) ? newValues.push(v) : newValues.splice(idx, 1);
      if (v === UNLIMITED) {
        newValues.splice(0);
        newValues.push(UNLIMITED);
      }
      if (fastProp(UNLIMITED)(valueMap)) {
        const unlimitedIex = indexOf(UNLIMITED)(newValues);
        newValues.splice(unlimitedIex, 1);
      }
      if (isEmpty(newValues)) {
        newValues.push(UNLIMITED);
      }
      onChange(newValues);
    },
    [onChange, value, valueMap]
  );
  return (
    <Space className={style.Button} size={spacingSize || [8, 4]} wrap>
      {map((item: CheckBoxItem) =>
        !item.render ? (
          <Button
            size={buttonSize || "small"}
            key={item.id}
            type={fastProp(item.id)(valueMap) ? "primary" : undefined}
            onClick={() => checkItem(item.id)}
          >
            {choiceStyle(item.name, fastProp(item.id)(valueMap)) ? (
              <Tooltip title={item.name}>
                <span
                  className={
                    choiceStyle(item.name, fastProp(item.id)(valueMap))
                      ? style.SizeM
                      : ""
                  }
                >
                  {item.name}
                </span>
              </Tooltip>
            ) : (
              <>{item.name}</>
            )}
            {fastProp(item.id)(valueMap) && <CheckCircleOutlined />}
          </Button>
        ) : (
          <div key={item.id}>{item.render()}</div>
        )
      )(list)}
    </Space>
  );
};

export default CheckBoxGroup;
