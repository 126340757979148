import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { Card, Row, Col, Space } from "antd";
import { keys, map, orderBy, prop } from "lodash/fp";
import React, { useEffect, useMemo, useState } from "react";
import style from "../index.module.less";
import PieCard from "../../components/pieCard";
import SectorCategoriesSelect from "@/components/sectorCategoriesSelect";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchFundManagerCompareIndustryInfo } from "@/store/compareManage";
import {
  fundManagerMapSelector,
  selectedYearReportDateSelector,
} from "../selectors";
import ReportDate from "./reportDate";
import { fastProp, mapIndexed, normalize } from "@/util/opt";
import { colors } from "@/util/colors";
import { getIndustyPieChartOptions, isOther } from "../../fundCompare/constant";
import LoadingComponent from "@/components/LoadingComponent";
import { size } from "lodash";
import { defaultSectorId } from "@/constant/sectors";
import { useCreation } from "ahooks";

const useGetChartData = (
  managerIds: string[],
  selectedReportDate: string,
  formatMessage: FormatMessageFunc
) => {
  const baseData = useAppSelector(
    prop("compareManage.fundManager.industryInfo")
  );
  const sectorCategoryMap = useAppSelector(sectorCategoriesMapSelector);
  const fundManagerMap = useAppSelector(fundManagerMapSelector);
  const stockIndustryDistribution = useMemo(() => {
    const industry = fastProp(selectedReportDate)(baseData);
    return size(industry) ? normalize("fundManagerId")(industry) : [];
  }, [baseData, selectedReportDate]);

  const pieCardData = useMemo(
    () =>
      map((id: string) => {
        const industryInfo = prop(`${id}.industryWeight`)(
          stockIndustryDistribution
        );
        const pieData = mapIndexed((industry: string, index: number) => {
          return {
            name: isOther(industry)
              ? formatMessage(industry)
              : prop(`${industry}.name`)(sectorCategoryMap),
            value: fastProp(industry)(industryInfo),
            color: colors[index],
          };
        })(keys(industryInfo));
        return {
          name: prop(`${id}.name`)(fundManagerMap),
          pieData: orderBy("value", "desc")(pieData),
        };
      })(managerIds),
    [
      managerIds,
      stockIndustryDistribution,
      sectorCategoryMap,
      fundManagerMap,
      formatMessage,
    ]
  );
  return pieCardData;
};

export default React.memo<{
  managerIds: string[];
}>(({ managerIds }): JSX.Element => {
  const reportDate = useAppSelector(selectedYearReportDateSelector);
  const [selectedSectorId, setSelectedSectorId] = useState(defaultSectorId);
  const [selectedReportDate, setSelectedReportDate] = useState("");
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();
  const pieCardData = useGetChartData(
    managerIds,
    selectedReportDate,
    formatMessage
  );
  useEffect(() => {
    setSelectedReportDate(reportDate);
  }, [reportDate]);
  useEffect(() => {
    if (selectedReportDate) {
      dispatch(
        fetchFundManagerCompareIndustryInfo({
          reportDate: selectedReportDate,
          ids: managerIds,
          sectorId: selectedSectorId,
        })
      );
    }
  }, [managerIds, dispatch, selectedSectorId, selectedReportDate]);
  const pieOptions = useCreation(
    () => getIndustyPieChartOptions(formatMessage),
    []
  );
  return (
    <Card>
      <Space direction="vertical" className={style.fullWidth} size={12}>
        <Row justify="space-between" align="middle">
          <Col>
            <h3 className={style.margin16}>
              {formatMessage("industrialPreference")}
            </h3>
          </Col>
          <Col>
            <Space size={12}>
              <Col>
                <Space>
                  <span>{formatMessage("industryDistribution")}</span>
                  <SectorCategoriesSelect
                    value={selectedSectorId}
                    onChange={setSelectedSectorId}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <span>{formatMessage("endOfDate")}</span>
                  <ReportDate
                    value={selectedReportDate}
                    onChange={setSelectedReportDate}
                    type="industryReportDate"
                  />
                </Space>
              </Col>
            </Space>
          </Col>
        </Row>
        <LoadingComponent actions={fetchFundManagerCompareIndustryInfo}>
          <Row gutter={16}>
            {map(({ name, pieData }) => (
              <Col span={12} key={name} className={style.marginBottom12}>
                <PieCard
                  title={name}
                  dataSource={pieData}
                  pieOptions={pieOptions}
                />
              </Col>
            ))(pieCardData)}
          </Row>
        </LoadingComponent>
      </Space>
    </Card>
  );
});
