import { formatPercentage } from "@/util/numberFormatter";
import { fastProp, normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import {
  compact,
  first,
  flatten,
  flow,
  last,
  map,
  maxBy,
  orderBy,
  prop,
  update,
  concat,
  isEmpty,
} from "lodash/fp";
import { RootState } from "@/store";
import { portfolioAnalysisSelector } from "../../selectors";
import { fundCodeMapSelector, fundIdMapSelector } from "@/selectors/fund";
import { getPreviousTradingDate } from "@/util/processedDates";
import { getChartFactorInfo } from "./constant";
import { get10ContributionInfo } from "@/views/aum/deepPerformance/performanceAttribution/performanceDismantling/constant";
import { ReturnContributionProp } from "@/model/aum";

export const performanceDismantlingSelector = createSelector(
  portfolioAnalysisSelector,
  (_: RootState, id: string, section: string) => section,
  (portfolioAnalysis, section) =>
    portfolioAnalysis?.performanceDismantling?.[section]
);

export const assetContributionSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => {
    const assetContribution = performanceDismantling?.assetContribution;
    const newAssetContribution = orderBy(
      (item: any) => Math.abs(fastProp("contribution")(item)),
      "asc"
    )(assetContribution);
    return {
      assetContributionNames: map(fastProp("name"))(newAssetContribution),
      assetContributionMap: normalize("name")(newAssetContribution),
    };
  }
);

export const fundContributionSelector = createSelector(
  performanceDismantlingSelector,
  fundCodeMapSelector,
  (performanceDismantling, fundCodeMap) => {
    const allFundContributions = map((item: ReturnContributionProp) => ({
      ...item,
      name: prop(`${item?.name}.name`)(fundCodeMap),
    }))(performanceDismantling?.allFundContributions || []);
    const fundContribution = get10ContributionInfo(allFundContributions);
    return {
      fundContributionNames: fundContribution?.contributionNames,
      fundContributionMap: fundContribution?.contributionMap,
    };
  }
);

export const companyContributionSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => {
    const companyContribution = get10ContributionInfo(
      performanceDismantling?.fundCompanyContribution
    );
    return {
      companyContributionNames: companyContribution?.contributionNames,
      companyContributionMap: companyContribution?.contributionMap,
    };
  }
);

export const categoryMaxAndMinSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => ({
    maxAssetContribution: fastProp("maxCategoryInfo")(performanceDismantling),
    minAssetContribution: fastProp("minCategoryInfo")(performanceDismantling),
    portfolioReturn: fastProp("portfolioInfo")(performanceDismantling),
    benchmarkAttribution: prop("benchmarkInfo")(performanceDismantling),
  })
);

export const fundCompanyContributionSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => performanceDismantling?.fundCompanyContribution
);

export const topFundContributionsSelector = createSelector(
  performanceDismantlingSelector,
  fundIdMapSelector,
  (performanceDismantling, fundIdMap) => {
    const topFundContributions = performanceDismantling?.topFundContributions;
    const newTopFundContributions = flow(
      orderBy("contribution", "asc"),
      map(update("name", (name) => prop(`${name}.name`)(fundIdMap)))
    )(topFundContributions);
    return {
      topFundContributionsNames: map(fastProp("name"))(newTopFundContributions),
      topFundContributionsMap: normalize("name")(newTopFundContributions),
    };
  }
);

export const fundDisassembleTableSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => performanceDismantling?.fundDisassembleTable
);

export const dismantlingLineChartSelector = createSelector(
  performanceDismantlingSelector,
  (state: RootState) => state.tradingDates.tradingDateList,
  (state: RootState) => state.tradingDates.processedTradingDates,
  (performanceDismantling, tradingDates, processedTradingDates) => {
    const {
      dates,
      portfolioInfo,
      benchmarkInfo,
      maxCategoryInfo,
      minCategoryInfo,
    } = performanceDismantling || {};
    const firstDate = getPreviousTradingDate(
      tradingDates,
      processedTradingDates,
      first(dates) as string
    );
    const chartDates = concat(firstDate)(dates);
    const chartPortfolioInfo = getChartFactorInfo(portfolioInfo);
    const chartBenchmarkInfo = getChartFactorInfo(benchmarkInfo);
    const chartMaxCategoryInfo = getChartFactorInfo(maxCategoryInfo);
    const chartMinCategoryInfo = getChartFactorInfo(minCategoryInfo);
    return {
      dates: chartDates,
      portfolioInfo: chartPortfolioInfo,
      benchmarkInfo: chartBenchmarkInfo,
      maxCategoryInfo: chartMaxCategoryInfo,
      minCategoryInfo: chartMinCategoryInfo,
    };
  }
);

export const dismantlingDatesSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => performanceDismantling?.dates
);

export const dismantlingTextSelector = createSelector(
  performanceDismantlingSelector,
  dismantlingDatesSelector,
  fundIdMapSelector,
  (performanceDismantling, dismantlingDates, fundIdMap) => {
    if (isEmpty(dismantlingDates)) return {};
    const { fundDisassembleTable } = performanceDismantling || {};
    const fundDisassembles = flow(
      fastProp("children"),
      map(fastProp("children")),
      compact,
      flatten,
      map(update("attribution", (value: number) => Math.abs(value))),
      maxBy("attribution")
    )(fundDisassembleTable);
    return {
      from: first(dismantlingDates) as string,
      to: last(dismantlingDates) as string,
      fundName:
        prop(`${fastProp("name")(fundDisassembles)}.name`)(fundIdMap) || "--",
      attributionRatio: formatPercentage(
        fastProp("attributionRatio")(fundDisassembles)
      ),
    };
  }
);
export const lowestFundContributionsSelector = createSelector(
  performanceDismantlingSelector,
  fundIdMapSelector,
  (performanceDismantling, fundIdMap) => {
    const lowestFundContributions =
      performanceDismantling?.lowestFundContributions;
    const newLowestFundContributions = flow(
      orderBy((item: any) => Math.abs(fastProp("contribution")(item)), "asc"),
      map(update("name", (name) => prop(`${name}.name`)(fundIdMap)))
    )(lowestFundContributions);
    return {
      lowestFundContributionsNames: map(fastProp("name"))(
        newLowestFundContributions
      ),
      lowestFundContributionsMap: normalize("name")(newLowestFundContributions),
    };
  }
);
export default createSelector(
  performanceDismantlingSelector,
  assetContributionSelector,
  categoryMaxAndMinSelector,
  fundCompanyContributionSelector,
  topFundContributionsSelector,
  fundDisassembleTableSelector,
  dismantlingLineChartSelector,
  dismantlingDatesSelector,
  dismantlingTextSelector,
  lowestFundContributionsSelector,
  fundContributionSelector,
  companyContributionSelector,
  (
    performanceDismantlingSelector,
    assetContributionSelector,
    categoryMaxAndMinSelector,
    fundCompanyContributionSelector,
    topFundContributionsSelector,
    fundDisassembleTableSelector,
    dismantlingLineChartSelector,
    dismantlingDatesSelector,
    dismantlingTextSelector,
    lowestFundContributionsSelector,
    fundContributionSelector,
    companyContributionSelector
  ) => ({
    performanceDismantlingSelector,
    assetContributionSelector,
    categoryMaxAndMinSelector,
    fundCompanyContributionSelector,
    topFundContributionsSelector,
    fundDisassembleTableSelector,
    dismantlingLineChartSelector,
    dismantlingDatesSelector,
    dismantlingTextSelector,
    lowestFundContributionsSelector,
    fundContributionSelector,
    companyContributionSelector,
  })
);
