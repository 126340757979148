import { RuleRender } from "antd/lib/form";
import { dupName } from "@/api/portfolioAnalysis";
import { findIndex, keys, sumBy, values } from "lodash/fp";
import { fastProp } from "./opt";
import getMessage from "./getMessage";
import { conditionsParam } from "@/model/entities";
import { benchmarkDupName } from "@/api/customBenchmark";

export const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

export const portfolioNameReg = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/;

export const nameUniqValidator =
  (oldName?: string): RuleRender =>
  () => ({
    validateTrigger: "validateFields",
    async validator(_, value) {
      if (oldName && value === oldName) return Promise.resolve();
      try {
        await dupName(value);
        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(new Error(keys(error?.message)?.[0]));
      }
    },
  });
export const benchmarkValidator: RuleRender = () => ({
  validator(_, value) {
    if (!fastProp("benchmarkId")(value)) {
      return Promise.reject(
        new Error(getMessage("performanceBenchmarkIsNull"))
      );
    }
    return Promise.resolve();
  },
  validateTrigger: "validateFields",
});

export const BenchmarkNameUniqValidator =
  (oldName?: string): RuleRender =>
  () => ({
    validateTrigger: "validateFields",
    async validator(_, value) {
      if (oldName && value === oldName) return Promise.resolve();
      try {
        await benchmarkDupName(value);
        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(new Error(values(error?.message)?.[0]));
      }
    },
  });

export const customBenchmarkValidator: RuleRender = () => ({
  validator(_, value) {
    if (
      parseFloat(sumBy("weight")(value).toPrecision(10)) !== 1 ||
      findIndex(
        (item: conditionsParam) => sumBy("weight")(item.benchmarkWeights) !== 1
      )(value) !== -1
    ) {
      return Promise.reject(
        new Error(getMessage("customBenchmarkConditionTip"))
      );
    }
    return Promise.resolve();
  },
  validateTrigger: "validateFields",
});
