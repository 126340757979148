import { useAppSelector } from "@/hooks/redux";
import { ScenarioListResponse } from "@/model/portfolioAnalysis";
import { scenarioMapSelector } from "@/selectors/scenario";
import { getLineChartOptions } from "@/util/chart";
import { colors, PORTFOLIO_COLOR, YellowColor } from "@/util/colors";
import { useFormatMessage } from "@/util/formatMessage";
import { formatArrayNilToZero } from "@/util/numberFormatter";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { LegendComponentOption, LineSeriesOption } from "echarts";
import {
  compact,
  concat,
  filter,
  flatten,
  flow,
  map,
  max,
  min,
} from "lodash/fp";
import { useMemo } from "react";
import { getCumulativeSeries } from "../warehouseAnalysis/constant";
import { DailyReturnsWithName } from "../warehouseAnalysis/interface";
import {
  getLegendConfig,
  getScenarioColumns,
  getScenarioSeriesConfig,
  getScenarioTableData,
  scenarioTooltipFormatter,
} from "./constant";
import { ScenarioDailyReturnInfo } from "./interface";

export const useGetScenarioDailyReturnsInfo = ({
  tradingDate,
  portfolioInfo,
  benchmarkInfo,
  selectedScenarios,
}: {
  tradingDate: string[];
  selectedScenarios: string[];
  portfolioInfo: DailyReturnsWithName;
  benchmarkInfo: DailyReturnsWithName;
}) => {
  const scenarioMap = useAppSelector(scenarioMapSelector);
  const scenarioSeries = useMemo<ScenarioDailyReturnInfo[]>(
    () =>
      compact(
        mapIndexed((id: string, index: number) => {
          const scenario = fastProp(id)(scenarioMap) as ScenarioListResponse;
          if (!scenario) return null;
          const slicedDate = filter<string>(
            (date) => date >= scenario.startDate && date <= scenario.endDate
          )(tradingDate);
          const scenarioDailyReturns = map<string, number>((date) =>
            fastProp(date)(portfolioInfo.dailyReturnsMap)
          )(slicedDate);
          const benchmarkDailyReturns = map<string, number>((date) =>
            fastProp(date)(benchmarkInfo.dailyReturnsMap)
          )(slicedDate);
          return {
            ...scenario,
            dates: slicedDate,
            color: fastNth(index + 3)(colors),
            dailyReturns: scenarioDailyReturns,
            benchmarkDailyReturns,
          };
        })(selectedScenarios)
      ),
    [
      benchmarkInfo.dailyReturnsMap,
      portfolioInfo.dailyReturnsMap,
      scenarioMap,
      selectedScenarios,
      tradingDate,
    ]
  );
  return scenarioSeries;
};

export const useGetScenarioLineChartConfigs = ({
  tradingDate,
  portfolioInfo,
  benchmarkInfo,
  scenarioReturnInfo,
}: {
  tradingDate: string[];
  portfolioInfo: DailyReturnsWithName;
  benchmarkInfo: DailyReturnsWithName;
  scenarioReturnInfo: ScenarioDailyReturnInfo[];
}) => {
  const [portfolioSeries, benchmarkSeries] = useMemo(
    () =>
      getCumulativeSeries(tradingDate, [
        {
          name: portfolioInfo.name,
          color: PORTFOLIO_COLOR,
          dailyReturns: formatArrayNilToZero(portfolioInfo.dailyReturns),
        },
        {
          name: benchmarkInfo.name,
          color: YellowColor,
          dailyReturns: benchmarkInfo.dailyReturns,
        },
      ]),
    [
      benchmarkInfo.dailyReturns,
      benchmarkInfo.name,
      portfolioInfo.dailyReturns,
      portfolioInfo.name,
      tradingDate,
    ]
  );
  const [maxData, minData] = useMemo(() => {
    const allData = flow(
      map("data"),
      flatten,
      map((item) => fastNth(1)(item))
    )([portfolioSeries, benchmarkSeries]);
    return [max(allData), min(allData)];
  }, [benchmarkSeries, portfolioSeries]);

  const scenarioSeries = useMemo(
    () =>
      flatten(
        map<ScenarioDailyReturnInfo, LineSeriesOption[]>((item) => {
          const maxScenarioData = map<string, [string, number]>((date) => [
            date,
            maxData,
          ])(item.dates);
          const minScenarioData = map<string, [string, number]>((date) => [
            date,
            minData,
          ])(item.dates);
          return [
            getScenarioSeriesConfig(item.name, item.color, maxScenarioData),
            getScenarioSeriesConfig(item.name, item.color, minScenarioData),
          ];
        })(scenarioReturnInfo)
      ),
    [maxData, minData, scenarioReturnInfo]
  );
  const series = useMemo(
    () => concat([portfolioSeries, benchmarkSeries])(scenarioSeries),
    [benchmarkSeries, portfolioSeries, scenarioSeries]
  );
  const options = useMemo(
    () =>
      getLineChartOptions(tradingDate, [], {
        legend: getLegendConfig({
          portfolioSeries,
          benchmarkSeries,
          scenarioSeries,
        }) as LegendComponentOption,
        tooltip: {
          formatter: scenarioTooltipFormatter,
        },
      }),
    [benchmarkSeries, portfolioSeries, scenarioSeries, tradingDate]
  );
  return {
    series,
    options,
  };
};

export const useGetScenarioTableInfo = (
  scenarioReturnInfo: ScenarioDailyReturnInfo[]
) => {
  const formatMessage = useFormatMessage();
  const riskFreeRates = useAppSelector((state) => state.entities.riskFreeRate);
  const processedTradingDates = useAppSelector(
    (state) => state.tradingDates.processedTradingDates
  );
  const dataSource = useMemo(
    () =>
      getScenarioTableData(
        scenarioReturnInfo,
        riskFreeRates,
        processedTradingDates
      ),
    [processedTradingDates, riskFreeRates, scenarioReturnInfo]
  );
  return { dataSource, columns: getScenarioColumns(formatMessage) };
};
