import { flow, pick, set } from "lodash/fp";
import {
  RECENT_FIVE_YEAR,
  RECENT_YEAR,
  RECENT_THREE_YEAR,
  FROM_CREATION,
  RECENT_WEEK,
  RECENT_MONTH,
  RECENT_THREE_MONTH,
  RECENT_HALF_YEAR,
  RECENT_TWO_YEAR,
  yieldStatisticRange,
} from "@/constant/statisticRange";
import getMessage from "@/util/getMessage";

export const statisticRangeList = flow(
  pick([
    RECENT_WEEK,
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    RECENT_THREE_YEAR,
    RECENT_FIVE_YEAR,
    FROM_CREATION,
  ]),
  set("FROM_CREATION.message", getMessage("sinceTheIndustry"))
)(yieldStatisticRange);
