import React, { ReactNode } from "react";
import cn from "classnames";
import EmptyContent from "@/assets/emptyContent.png";
import style from "./emptyContent.module.less";

export default React.memo<{
  className?: string;
  message?: string;
  children?: ReactNode;
  style?: Record<string, any>;
}>(({ className, message, children, style: styles }) => {
  return (
    <div className={cn(style.Container, className)} style={styles}>
      <img src={EmptyContent} />
      {message && <p className={style.Message}>{message}</p>}
      <div className={style.Message}>{children}</div>
    </div>
  );
});
