import { FundsInterface } from "@/model/entities";
import { TableProps, Table, Popconfirm, Tooltip } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import cn from "classnames";
import { useCreation, useMemoizedFn } from "ahooks";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import React from "react";
import { toFixedNumber } from "@/util/numberFormatter";
import style from "./index.module.less";
import { isNil, pullAt, set, size, update } from "lodash/fp";
import { useTransferAssetToFundAsset } from "../../manualCreatePortfolio/hooks";
import { Asset } from "../../manualCreatePortfolio/constant";
import type {
  ErrorField,
  FundConfigurationAsset,
  FundConfigurationAssetsUpdater,
} from "../interface";
import NumberInput from "@/components/numberInput";
import dayjs from "dayjs";

type FundWeightProps = {
  dataSource: FundConfigurationAsset[];
  onChange?: (updater: FundConfigurationAssetsUpdater) => any;
  editable?: boolean;
  className?: string;
  assetIntersectionStartDate?: string;
  assetIntersectionEndDate?: string;
  errorField?: ErrorField;
};

export const useValidateIntersectionDateError = (
  intersectionStartDate: string | undefined,
  intersectionEndDate: string | undefined
) => {
  const assetIntersectionStartDay = useCreation(
    () => dayjs(intersectionStartDate),
    [intersectionStartDate]
  );
  const assetIntersectionEndDay = useCreation(
    () => dayjs(intersectionEndDate),
    [intersectionEndDate]
  );
  const validateStartDateError = useMemoizedFn(
    (date: string) =>
      intersectionEndDate && assetIntersectionEndDay.isBefore(date)
  );
  const validateEndDateError = useMemoizedFn(
    (date: string) =>
      intersectionStartDate && assetIntersectionStartDay.isAfter(date)
  );
  return {
    validateStartDateError,
    validateEndDateError,
  };
};

type FundWeightTableProps = TableProps<FundsInterface & Asset>;
export const useGetFundWeightTableColumns = ({
  onChange,
  errorField,
  assetIntersectionStartDate,
  assetIntersectionEndDate,
}: Pick<
  FundWeightProps,
  | "onChange"
  | "errorField"
  | "assetIntersectionStartDate"
  | "assetIntersectionEndDate"
>) => {
  const formatMessage = useFormatMessage();
  const onRemoveAsset = useMemoizedFn(
    (index: number) => onChange && onChange(pullAt(index))
  );
  const onChangeMinWeight = useMemoizedFn(
    (index: number) => (value: number | null) =>
      onChange && onChange(update(index, set("minWeight", value)))
  );
  const onChangeMaxWeight = useMemoizedFn(
    (index: number) => (value: number | null) =>
      onChange && onChange(update(index, set("maxWeight", value)))
  );
  const { validateStartDateError, validateEndDateError } =
    useValidateIntersectionDateError(
      assetIntersectionStartDate,
      assetIntersectionEndDate
    );
  return useCreation<FundWeightTableProps["columns"]>(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: formatMessage("fundName"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
        fixed: "left",
      },
      {
        key: "code",
        dataIndex: "code",
        title: formatMessage("fundCode"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
        fixed: "left",
      },
      {
        key: "investType",
        dataIndex: "investType",
        title: formatMessage("policyType"),
      },
      {
        key: "netValue",
        dataIndex: "netValue",
        width: 200,
        align: "right",
        title: (
          <div>
            {formatMessage("latestNetUnitValue")}
            {errorField && errorField.latestNetUnitValueZeroError && (
              <Tooltip title={formatMessage("lackLatestNetUnitValue")}>
                <ExclamationCircleFilled className={style.ErrorAlert} />
              </Tooltip>
            )}
          </div>
        ),
        render: (value: number, record: any) => {
          const isLackNetValueError = isNil(value);
          return (
            <div>
              {toFixedNumber(4)(value)}
              <span className={style.GreyColor}>
                （{fastProp("tradingDay")(record)}）
              </span>
              {isLackNetValueError && (
                <Tooltip title={formatMessage("lackLatestNetUnitValue")}>
                  <ExclamationCircleFilled className={style.ErrorAlert} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        key: "tradingDay",
        dataIndex: "tradingDay",
        title: (
          <div>
            {formatMessage("netValueStartAndEndDate")}
            {errorField &&
              (errorField.fundIntersectionDateError ||
                errorField.netValueStartDateZeroError) && (
                <ExclamationCircleFilled className={style.ErrorAlert} />
              )}
          </div>
        ),
        render: (_, record) => {
          const netValueStartDate = fastProp("netValueStartDate")(record);
          const startDateError = validateStartDateError(netValueStartDate);
          const endDateError = validateEndDateError(_);
          const startDateZeroError = isNil(netValueStartDate);
          return (
            <div>
              <span className={startDateError ? style.ErrorField : undefined}>
                {fastProp("netValueStartDate")(record) || "--"}
              </span>{" "}
              {formatMessage("to")}{" "}
              <span className={endDateError ? style.ErrorField : undefined}>
                {_ || "--"}
              </span>
              {(startDateError || endDateError) && (
                <Tooltip
                  title={
                    startDateError
                      ? "最晚净值起始日期晚于最早最新单位净值日期"
                      : "最早最新单位净值日期早于最晚净值起始日期"
                  }
                >
                  <ExclamationCircleFilled className={style.ErrorAlert} />
                </Tooltip>
              )}
              {startDateZeroError && (
                <Tooltip title={formatMessage("lackNetValueStartDate")}>
                  <ExclamationCircleFilled className={style.ErrorAlert} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        key: "minWeight",
        dataIndex: "minWeight",
        width: 150,
        title: "持仓下限",
        render: (minWeight: number, record: any, index: number) => {
          const weightError = minWeight > fastProp("maxWeight")(record);
          return (
            <div className={style.Flex}>
              <NumberInput
                type="PERCENTAGE"
                min={0}
                max={1}
                className={cn({
                  [style.WeightInput]: true,
                  [style.InputError]: weightError,
                })}
                value={minWeight}
                onChange={onChangeMinWeight(index)}
              />
              {weightError && (
                <Tooltip title="持仓下限不能大于持仓上限">
                  <ExclamationCircleFilled className={style.ErrorAlert} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        key: "maxWeight",
        dataIndex: "maxWeight",
        width: 100,
        title: "持仓上限",
        render: (maxWeight: number, record: any, index: number) => (
          <NumberInput
            type="PERCENTAGE"
            min={0}
            max={1}
            className={style.WeightInput}
            value={maxWeight}
            onChange={onChangeMaxWeight(index)}
          />
        ),
      },
      {
        key: "operator",
        dataIndex: "operator",
        fixed: "right",
        width: 60,
        align: "center",
        title: formatMessage("operator"),
        render: (_: any, record: any, index: number) => (
          <Popconfirm
            title={formatMessage("deleteFundConfirm")}
            onConfirm={() => onRemoveAsset(index)}
            okText={formatMessage("ok")}
            cancelText={formatMessage("cancel")}
          >
            <span className={style.Operator}>{formatMessage("delete")}</span>
          </Popconfirm>
        ),
      },
    ],
    [
      formatMessage,
      errorField,
      validateStartDateError,
      validateEndDateError,
      onChangeMinWeight,
      onChangeMaxWeight,
      onRemoveAsset,
    ]
  );
};

export default React.memo<FundWeightProps>(
  ({
    dataSource,
    onChange,
    className,
    errorField,
    assetIntersectionStartDate,
    assetIntersectionEndDate,
  }) => {
    const formatMessage = useFormatMessage();
    const columns = useGetFundWeightTableColumns({
      onChange,
      errorField,
      assetIntersectionStartDate,
      assetIntersectionEndDate,
    });
    const transfer = useTransferAssetToFundAsset();
    const fundAndAssetDataSource = useCreation(
      () => transfer(dataSource),
      [transfer, dataSource]
    );
    const scroll = useCreation(
      () => (size(dataSource) > 10 ? { y: 407, x: "max-content" } : undefined),
      [dataSource]
    );

    return (
      <Table
        className={cn(style.FundConfigurationTable, className)}
        rowKey="fundId"
        columns={columns}
        dataSource={fundAndAssetDataSource}
        pagination={false}
        scroll={scroll}
        summary={(pageData) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                {formatMessage("fundTotaSize", { size: size(pageData) })}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    );
  }
);
