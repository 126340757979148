import React, { useEffect } from "react";
import PieCard from "@/views/compareManage/components/pieCard";
import { useFormatMessage } from "@/util/formatMessage";
import SectorCategoriesSelect from "@/components/sectorCategoriesSelect";
import { Space } from "antd";
import style from "../index.module.less";
import {
  useGetIndustryPieChartData,
  useManageIndustrySectorId,
  useManageReportDate,
} from "../hooks";
import { BaseFundManagerDetailInfoProps } from "../../interface";
import ReportDate from "./reportDate";
import { useAppDispatch } from "@/hooks/redux";
import { getFundManagerIndustryInfo } from "@/store/fundManagerDetailSlice";
import LoadingComponent from "@/components/LoadingComponent";
import { useCreation } from "ahooks";
import { getIndustyPieChartOptions } from "@/views/compareManage/fundCompare/constant";

export default React.memo<BaseFundManagerDetailInfoProps>(({ id }) => {
  const formatMessage = useFormatMessage();
  const [sectorId, updateSectorId] = useManageIndustrySectorId(id);
  const [reportDate, changeReportDate] = useManageReportDate(
    id,
    "positionInfo.industryReportDate"
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id && sectorId && reportDate) {
      dispatch(getFundManagerIndustryInfo({ id, sectorId, reportDate }));
    }
  }, [dispatch, id, reportDate, sectorId]);
  const dataSource = useGetIndustryPieChartData(id, reportDate, sectorId);
  const pieOptions = useCreation(
    () => getIndustyPieChartOptions(formatMessage),
    []
  );
  return (
    <div className={style.IndustryCard}>
      <LoadingComponent actions={getFundManagerIndustryInfo}>
        <PieCard
          title={formatMessage("industrialPreference")}
          operation={
            <Space>
              <Space size={8}>
                <p>{formatMessage("industryDistribution")}</p>
                <SectorCategoriesSelect
                  value={sectorId}
                  onChange={updateSectorId}
                />
              </Space>
              <ReportDate
                value={reportDate}
                onChange={changeReportDate}
                id={id}
                type="industryReportDate"
              />
            </Space>
          }
          dataSource={dataSource}
          pieOptions={pieOptions}
        />
      </LoadingComponent>
    </div>
  );
});
