/**
 * alpha
 */
import MLR from "ml-regression-multivariate-linear";
import { first, last, map, isEmpty } from "lodash/fp";
import tradingDateCount from "@/constant/tradingDateCount";
import { zipWithMinus } from "../opt";
import { formatQuantArrayNilToZero } from "../numberFormatter";

export default (
  dailyReturns: number[],
  benchmarkReturns: number[],
  riskFreeRate: number[]
) => {
  if (
    isEmpty(dailyReturns) ||
    isEmpty(benchmarkReturns) ||
    isEmpty(riskFreeRate)
  )
    return 0;

  const [formatDailyReturns, formatBenchmarkReturns, formatRiskFreeRate] =
    formatQuantArrayNilToZero(dailyReturns, benchmarkReturns, riskFreeRate);
  const dailySubFreeRate = map<number, number[]>((value) => [value])(
    zipWithMinus(formatDailyReturns, formatRiskFreeRate)
  );
  const benchmarkSubRate = map<number, number[]>((value) => [value])(
    zipWithMinus(formatBenchmarkReturns, formatRiskFreeRate)
  );
  const mlr = new MLR(benchmarkSubRate, dailySubFreeRate);
  const [, alpha] = [first(first(mlr.weights)), first(last(mlr.weights))];
  return (alpha || 0) * tradingDateCount;
};
