/**
 * 动态回撤
 */
import { map } from "lodash/fp";
import { formatArrayNilToZero } from "../numberFormatter";
import { computeCumulativeReturns } from "./cumulativeReturns";

export default (data: number[]) => {
  let previousReturn: number | any = null;
  return map<string | number, number>((item) => {
    const cumulativeValue = computeCumulativeReturns(
      previousReturn,
      item,
      true
    );
    previousReturn = cumulativeValue > 0 ? 0 : cumulativeValue;
    return previousReturn;
  })(formatArrayNilToZero(data));
};
