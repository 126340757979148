import React, { useMemo } from "react";
import { Table } from "antd";
import { map } from "lodash/fp";
import { useGetUsers } from "@/hooks/users";
import { useFormatMessage } from "@/util/formatMessage";
import { User } from "@/model/login";

import style from "./index.module.less";

type OnDelete = (id: string) => void;
type TableProps = {
  ids?: string[];
  onDelete?: OnDelete;
};

const useColumns = (onDelete?: OnDelete) => {
  const formatMessage = useFormatMessage();

  return [
    {
      title: formatMessage("serialNumber"),
      dataIndex: "index",
      width: 100,
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: formatMessage("username"),
      dataIndex: "username",
    },
    {
      title: formatMessage("operator"),
      dataIndex: "operate",
      width: 100,
      render: (_: any, record: User) => (
        <span
          onClick={() => {
            onDelete?.(record?.id);
          }}
          className={style.Delete}
        >
          {formatMessage("delete")}
        </span>
      ),
    },
  ];
};

export default React.memo<TableProps>(({ ids, onDelete }) => {
  const columns = useColumns(onDelete);
  const { userMap } = useGetUsers();
  const dataSource = useMemo(
    () => map<string, User>((id) => userMap?.[id])(ids),
    [ids, userMap]
  );

  return (
    <Table
      className={style.Table}
      columns={columns}
      dataSource={dataSource || []}
      rowKey="id"
      pagination={{
        hideOnSinglePage: true,
        showQuickJumper: true,
        size: "small",
      }}
      scroll={{ y: 300 }}
    />
  );
});
