import { Row } from "antd";
import { map } from "lodash/fp";
import ColorNumber from "@/components/colorNumber";
import style from "./index.module.less";
import { MOCK_TRADING_FACTORS } from "../constant";
import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { customerAnalysisSelector } from "../selectors";
import { fastProp } from "@/util/opt";

export type ValueTagProps = {
  id: string;
  value: number;
  message: string;
  section: string;
};
const ValueTag = ({
  message,
  value,
  formatter = "percentage",
}: {
  value: number;
  message: string;
  formatter?: "percentage" | "fixedNumber";
}): JSX.Element => {
  return (
    <Row className={style.ValueTag}>
      <span>{message}:</span>
      <ColorNumber
        value={value}
        formatter={formatter}
        className={style.ColorNumberItem}
      />
    </Row>
  );
};

const TradeValueSection = ({
  customerId,
  formatter = "percentage",
}: {
  customerId: string;
  formatter?: "percentage" | "fixedNumber";
}): JSX.Element => {
  const customerAnalysis = useAppSelector((state: RootState) =>
    customerAnalysisSelector(state, customerId)
  );
  const rangeIncome = fastProp("rangeIncome")(customerAnalysis);

  return (
    <>
      <Row className={style.ValueTagWrapper}>
        {map((v: ValueTagProps) => (
          <ValueTag
            message={v.message}
            value={fastProp(v?.section)(rangeIncome)}
            key={v.id}
            formatter={formatter}
          />
        ))(MOCK_TRADING_FACTORS)}
      </Row>
    </>
  );
};

export default TradeValueSection;
