import React from "react";
import InfoIcon from "@/assets/infoIcon.svg";
import cn from "classnames";
import { modelsConfig } from "../../constant";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";

export default React.memo<{
  modelType?: string;
}>(({ modelType }) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      {modelType == modelsConfig.equalWeight && (
        <div className={style.AlertFlex}>
          <img src={InfoIcon} className={style.AlertIcon} />
          <span>{formatMessage("equalWeightDescription")}</span>
        </div>
      )}
      {modelType === modelsConfig.marketWeight && (
        <div className={style.AlertFlex}>
          <img src={InfoIcon} className={style.AlertIcon} />
          {formatMessage("marketWeightDescription")}
        </div>
      )}
      {modelType === modelsConfig.maximizationSharpe && (
        <div className={cn(style.InfoAlert, style.AlertFlex)}>
          <img src={InfoIcon} className={style.AlertIcon} />
          <div>
            <p>{formatMessage("maximizationSharpeDescriptionOne")}</p>
            <p>{formatMessage("maximizationSharpeDescriptionTwo")}</p>
          </div>
        </div>
      )}
      {modelType === modelsConfig.minimizationRisk && (
        <div className={cn(style.InfoAlert, style.AlertFlex)}>
          <img src={InfoIcon} className={style.AlertIcon} />
          <div>
            <p>{formatMessage("minimizationRiskDescriptionOne")}</p>
            <p>{formatMessage("minimizationRiskDescriptionTwo")}</p>
          </div>
        </div>
      )}
      {modelType === modelsConfig.maximizationOfExpected && (
        <div className={cn(style.InfoAlert, style.AlertFlex)}>
          <img src={InfoIcon} className={style.AlertIcon} />
          <div>
            <p>{formatMessage("maximizationOfExpectedDescriptionOne")}</p>
            <p>{formatMessage("maximizationOfExpectedDescriptionTwo")}</p>
          </div>
        </div>
      )}
    </>
  );
});
