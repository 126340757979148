import { useFormatMessage } from "@/util/formatMessage";
import React, { useState } from "react";
import { Tabs } from "antd";
import { fastProp } from "@/util/opt";
import { first } from "lodash/fp";
import { CommonProps } from "@/views/aum/type";
import SubTitle from "@/views/aum/components/subTitle";
import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import ButtonGroup from "@/components/buttonGroup";
import PerformanceDismantling from "./performanceDismantling";
import style from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/index.module.less";
import performanceAttributionIcon from "@/assets/portfolioAnalysisIcons/performanceAttribution.png";
import { buttonGroupList } from "./constant";
import BrinsonAttribution from "./brinsonAttribution/index";

const { TabPane } = Tabs;

export default React.memo<Omit<CommonProps, "id">>(({ assetPortfolioId }) => {
  const formatMessage = useFormatMessage();
  const [buttonGroupValue, setButtonGroupValue] = useState(
    fastProp("value")(first(buttonGroupList))
  );

  const tip: CardLayoutTipType = [
    [
      formatMessage("PerformanceDismantling"),
      formatMessage("PerformanceDismantlingTipForAum"),
    ],
    [formatMessage("BRINSON"), formatMessage("BRINSONTipForAum")],
    [
      formatMessage("performanceBenchmark"),
      formatMessage("performanceBenchmarkTip"),
    ],
  ];
  return (
    <CardLayout
      title={formatMessage("performanceAttribution")}
      icon={performanceAttributionIcon}
      tip={tip}
    >
      <SubTitle title={formatMessage("performanceAttribution")} />
      <div className={style.PerformanceAttribution}>
        <div className={style.ButtonGroup}>
          <ButtonGroup
            groups={buttonGroupList}
            onChange={setButtonGroupValue}
            value={buttonGroupValue}
          />
        </div>
      </div>
      <Tabs className={style.Tabs} activeKey={buttonGroupValue}>
        <TabPane forceRender key="PerformanceDismantling">
          <PerformanceDismantling assetPortfolioId={assetPortfolioId} />
        </TabPane>
        <TabPane forceRender key="BRINSON">
          <BrinsonAttribution assetPortfolioId={assetPortfolioId} />
        </TabPane>
      </Tabs>
    </CardLayout>
  );
});
