import React, { useMemo } from "react";
import { fastProp } from "@/util/opt";
import { formatPercentage, toFixedNumber } from "@/util/numberFormatter";
import { Table, Tooltip } from "antd";
import { useFormatMessage, FormatMessageFunc } from "@/util/formatMessage";
// import LineChart, { LineChartOpts } from "@/echarts/lineChart";
import { YieldTrendChart, SeriesOption } from "@/base-components/charts";
// import { LineSeriesOption } from "echarts";
// import dayjs from "dayjs";
import { identity, map, orderBy, prop } from "lodash/fp";
// import { DATEFORMAT } from "@/util/dateFormat";
import style from "./index.module.less";
// import { getRectangleLegendConfig } from "@/util/chart";

const toFixedNumber2 = toFixedNumber(2);
const percentage = (value: number) => formatPercentage(value);
const getColumns = (
  formatMessage: FormatMessageFunc,
  from: string,
  to: string,
  fundName: string
) => [
  {
    title: <div>--</div>,
    dataIndex: "id",
    align: "center",
    render: () => {
      return <span>{fundName}</span>;
    },
  },
  {
    title: (
      <Tooltip
        title={[
          formatMessage("maxDrawdown"),
          <br />,
          formatMessage("fromToMaxDrawDown", { from, to }),
        ]}
      >
        <span>{formatMessage("maxDrawdown")}</span>
      </Tooltip>
    ),
    dataIndex: "maxDrawdown",
    align: "center",
    render: percentage,
  },
  {
    title: (
      <Tooltip
        title={[
          formatMessage("annualizedSharpeRatio"),
          <br />,
          formatMessage("annualizedSharpeRatioTips", { from, to }),
          formatMessage("selectedBenchmark", {
            name: formatMessage("benchmarkAttribution"),
          }),
        ]}
      >
        <span>{formatMessage("annualizedSharpeRatio")}</span>
      </Tooltip>
    ),
    dataIndex: "sharpeRatio",
    align: "center",
    render: toFixedNumber2,
  },
  {
    title: (
      <Tooltip
        title={[
          formatMessage("informationRatio"),
          <br />,
          formatMessage("fromToInformationRatio", { from, to }),
          formatMessage("annualizedInformationRatioTip"),
          formatMessage("selectedBenchmark", {
            name: formatMessage("benchmarkAttribution"),
          }),
        ]}
      >
        <span>{formatMessage("informationRatio")}</span>
      </Tooltip>
    ),
    dataIndex: "informationRatio",
    align: "center",
    render: toFixedNumber2,
  },
  {
    title: (
      <Tooltip
        title={[
          formatMessage("trackDeviation"),
          <br />,
          formatMessage("trackDeviationDescriptionTips", { from, to }),
          formatMessage("selectedBenchmark", {
            name: formatMessage("benchmarkAttribution"),
          }),
        ]}
      >
        <span>{formatMessage("trackDeviation")}</span>
      </Tooltip>
    ),
    dataIndex: "trackDeviation",
    align: "center",
    render: percentage,
  },
  {
    title: (
      <Tooltip
        title={[
          formatMessage("benchmarkAttribution"),
          <br />,
          formatMessage("totalPerformance", { from, to }),
          formatMessage("selectedBenchmark", {
            name: formatMessage("benchmarkAttribution"),
          }),
        ]}
      >
        <span>{formatMessage("benchmarkAttribution")}</span>
      </Tooltip>
    ),
    dataIndex: "cumulativeBenchmarkReturn",
    align: "center",
    render: percentage,
  },
  {
    title: (
      <Tooltip
        title={[
          formatMessage("fundAttribution"),
          <br />,
          formatMessage("fundTotal", { from, to }),
        ]}
      >
        <span>{formatMessage("fundAttribution")}</span>
      </Tooltip>
    ),
    dataIndex: "fundAttribution",
    align: "center",
    render: percentage,
  },
  {
    title: (
      <Tooltip
        title={[
          formatMessage("activeAttribution"),
          <br />,
          formatMessage("activeAttributionTips", { from, to }),
          formatMessage("selectedBenchmark", {
            name: formatMessage("benchmarkAttribution"),
          }),
        ]}
      >
        <span>{formatMessage("activeAttribution")}</span>
      </Tooltip>
    ),
    dataIndex: "activeAttribution",
    align: "center",
    render: percentage,
  },
  {
    title: (
      <Tooltip
        title={[
          formatMessage("allocationAttribution"),
          <br />,
          formatMessage("fundEarning", { name: formatMessage("sector") }),
        ]}
      >
        <span>{formatMessage("allocationAttribution")}</span>
      </Tooltip>
    ),
    dataIndex: "cumulativeAllocationReturn",
    align: "center",
    render: percentage,
  },
  {
    title: (
      <Tooltip
        title={[
          formatMessage("selectionAttribution"),
          <br />,
          formatMessage("fundEarning", { name: formatMessage("stock") }),
        ]}
      >
        <span>{formatMessage("selectionAttribution")}</span>
      </Tooltip>
    ),
    dataIndex: "cumulativeSelectionReturn",
    align: "center",
    render: percentage,
  },
  {
    title: (
      <Tooltip
        title={[
          formatMessage("timingAttribution"),
          <br />,
          formatMessage("fundEarning", {
            name: formatMessage("timingAttribution"),
          }),
        ]}
      >
        <span>{formatMessage("timingAttribution")}</span>
      </Tooltip>
    ),
    dataIndex: "cumulativeTimingOrInteractionAttribution",
    align: "center",
    render: percentage,
  },
];
export default React.memo<{
  summary: Record<string, any>;
  exportTitle: string;
  to: string;
  from: string;
  fundName: string;
}>(({ from, to, summary, fundName }) => {
  const formatMessage = useFormatMessage();
  const columns: any = useMemo(
    () => getColumns(formatMessage, from, to, fundName),
    [formatMessage, from, fundName, to]
  );
  const dates = useMemo(
    () => orderBy(identity, "asc")(fastProp("dates")(summary)) as string[],
    [summary]
  );

  const chartData = useMemo(() => fastProp("chartData")(summary), [summary]);
  const names = useMemo(() => fastProp("names")(summary), [summary]);
  const series = useMemo<SeriesOption[]>(
    () =>
      map((v: string) => ({
        name: formatMessage(v),
        data: map((date: string) => [
          date,
          prop(`${date}.${v}`)(chartData) || 0,
        ])(dates) as [string, number][],
      }))(names),
    [names, formatMessage, dates, chartData]
  );
  return (
    <div className={style.Summary}>
      <div className={style.Title}>{formatMessage("summary")}</div>
      <Table
        columns={columns}
        bordered
        dataSource={fastProp("tableData")(summary)}
        pagination={false}
        className={style.Table}
      />
      {/* <LineChart options={options} series={series} height={400} /> */}
      <YieldTrendChart dates={dates} series={series} height={400} />
    </div>
  );
});
