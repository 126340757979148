/**
 * 累计收益
 */
import { map, isNil } from "lodash/fp";
import { formatArrayNilToZero } from "../numberFormatter";
import { eqNaN } from "../opt/index";

const compute = (
  previousCumulativeReturn = NaN,
  dailyReturn: number | string,
  isReturn: boolean
) => {
  if (eqNaN(dailyReturn) || isNil(dailyReturn)) {
    return previousCumulativeReturn;
  }
  if (isNaN(previousCumulativeReturn) || isNil(previousCumulativeReturn)) {
    return dailyReturn;
  }
  return isReturn
    ? (1 + previousCumulativeReturn) * (dailyReturn as number) +
        previousCumulativeReturn
    : previousCumulativeReturn + (dailyReturn as number);
};

export const computeCumulativeReturns = compute;

export default (data: number[], isReturn = true) => {
  let previousReturn: number | any = null;
  return map<string | number, number>((item) => {
    if (eqNaN(item) || isNil(item)) {
      return null;
    }
    if (isNaN(previousReturn) || isNil(previousReturn)) {
      previousReturn = item;
      return item;
    }
    previousReturn = compute(previousReturn, item, isReturn);
    return previousReturn;
  })(formatArrayNilToZero(data));
};
