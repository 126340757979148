import Card from "@/components/card";
import ColorNumber from "@/components/colorNumber";
import {
  AssetTypeDistributionSummaryType,
  SummaryResult,
} from "@/model/customer";
import { useFormatMessage } from "@/util/formatMessage";
import { formatNilToZero } from "@/util/numberFormatter";
import React, { useCallback, useMemo } from "react";
import style from "../typeDistribution/index.module.less";

export interface AssetSummaryProps {
  itemKey: string;
  title: string;
  subTitle: string;
  icon: string;
  value: AssetTypeDistributionSummaryType;
}

const summaryMapKey = {
  [SummaryResult.SIGNIFICANTLY_HIGHER_THAN]: "great",
  [SummaryResult.ALMOST_EQUAL]: "common",
  [SummaryResult.SIGNIFICANTLY_LOWER_THAN]: "poor",
};

export default React.memo<
  AssetSummaryProps & { benchmarkName: string; dataMissing: boolean }
>(({ icon, title, subTitle, itemKey, value, benchmarkName, dataMissing }) => {
  const formatMessage = useFormatMessage();

  const monthWeightChange = useMemo(
    () =>
      formatNilToZero(value?.accountSelfSummary?.curMonthWeight) /
        formatNilToZero(value?.accountSelfSummary?.preMonthWeight) -
      1,
    [
      value?.accountSelfSummary?.curMonthWeight,
      value?.accountSelfSummary?.preMonthWeight,
    ]
  );

  const userName = useMemo(
    () => formatMessage("averageCustomerPerformance"),
    [formatMessage]
  );

  const getAssetCardSummaryContent = useCallback(
    (key) => {
      if (dataMissing)
        return <div>{formatMessage("lessThan10CustomerTips")}</div>;
      switch (key) {
        case "fund":
          return (
            <div>
              {formatMessage("customerAnalysisAssetFundSummary", {
                benchmark: benchmarkName,
                cumulativeRateRiseOrFall:
                  formatNilToZero(
                    value?.cumulativeRateSummary?.benchmarkCumulativeRate
                  ) > 0
                    ? formatMessage("riseUp")
                    : formatMessage("fallDown"),
                cumulativeRate: (
                  <ColorNumber
                    value={formatNilToZero(
                      value?.cumulativeRateSummary?.benchmarkCumulativeRate
                    )}
                    formatter="absolutePercentage"
                  />
                ),
                winOrLoss:
                  formatNilToZero(
                    value?.cumulativeRateSummary?.cumulativeRate
                  ) -
                    formatNilToZero(
                      value?.cumulativeRateSummary?.benchmarkCumulativeRate
                    ) >
                  0
                    ? formatMessage("win")
                    : formatMessage("lose"),
                excessReturn: (
                  <ColorNumber
                    value={
                      formatNilToZero(
                        value?.cumulativeRateSummary?.cumulativeRate
                      ) -
                      formatNilToZero(
                        value?.cumulativeRateSummary?.benchmarkCumulativeRate
                      )
                    }
                    formatter="absolutePercentage"
                  />
                ),
                userName: userName || "--",
                benchmarkSelectionAbility: formatMessage(
                  `${
                    summaryMapKey[
                      value?.cumulativeRateSummary?.cumulativeRateStandard ||
                        SummaryResult.ALMOST_EQUAL
                    ]
                  }BenchmarkSelectionAbility`,
                  { userName }
                ),
                compareIndex: formatMessage(
                  `${
                    summaryMapKey[
                      value?.assetsWeightSummary?.weightStandard ||
                        SummaryResult.ALMOST_EQUAL
                    ]
                  }CompareIndex`
                ),
                monthWeightChange:
                  monthWeightChange > 0
                    ? formatMessage("riseMonthWeightChange", {
                        assetType: title,
                        value: (
                          <ColorNumber
                            formatter="absolutePercentage"
                            value={monthWeightChange}
                          />
                        ),
                      })
                    : formatMessage("fallMonthWeightChange", {
                        assetType: title,
                        value: (
                          <ColorNumber
                            formatter="absolutePercentage"
                            value={monthWeightChange}
                          />
                        ),
                      }),
                fundMarketRiskSummary:
                  formatNilToZero(value?.accountSelfSummary?.ringRatio) > 0
                    ? formatNilToZero(
                        value?.cumulativeRateSummary?.benchmarkCumulativeRate
                      ) > 0
                      ? formatMessage("fundMarketRiskSummary1", {
                          assetType: title,
                        })
                      : formatMessage("fundMarketRiskSummary2", {
                          assetType: title,
                        })
                    : formatNilToZero(
                        value?.cumulativeRateSummary?.benchmarkCumulativeRate
                      ) > 0
                    ? formatMessage("fundMarketRiskSummary3", {
                        assetType: title,
                      })
                    : formatMessage("fundMarketRiskSummary4", {
                        assetType: title,
                      }),
              })}
            </div>
          );
        case "stock":
          return (
            <div>
              {formatMessage("customerAnalysisAssetStockSummary", {
                benchmark: benchmarkName,
                cumulativeRateRiseOrFall:
                  formatNilToZero(
                    value?.cumulativeRateSummary?.benchmarkCumulativeRate
                  ) > 0
                    ? formatMessage("riseUp")
                    : formatMessage("fallDown"),
                cumulativeRate: (
                  <ColorNumber
                    value={formatNilToZero(
                      value?.cumulativeRateSummary?.benchmarkCumulativeRate
                    )}
                    formatter="absolutePercentage"
                  />
                ),
                winOrLoss:
                  formatNilToZero(
                    value?.cumulativeRateSummary?.cumulativeRate
                  ) -
                    formatNilToZero(
                      value?.cumulativeRateSummary?.benchmarkCumulativeRate
                    ) >
                  0
                    ? formatMessage("win")
                    : formatMessage("lose"),
                excessReturn: (
                  <ColorNumber
                    value={
                      formatNilToZero(
                        value?.cumulativeRateSummary?.cumulativeRate
                      ) -
                      formatNilToZero(
                        value?.cumulativeRateSummary?.benchmarkCumulativeRate
                      )
                    }
                    formatter="absolutePercentage"
                  />
                ),
                userName: userName || "--",
                stockSelectionAbility: formatMessage(
                  `${
                    summaryMapKey[
                      value?.cumulativeRateSummary?.cumulativeRateStandard ||
                        SummaryResult.ALMOST_EQUAL
                    ]
                  }StockSelectionAbility`,
                  { userName }
                ),
                compareStock: formatMessage(
                  `${
                    summaryMapKey[
                      value?.assetsWeightSummary?.weightStandard ||
                        SummaryResult.ALMOST_EQUAL
                    ]
                  }CompareStock`
                ),
                monthWeightChange:
                  monthWeightChange > 0
                    ? formatMessage("riseMonthWeightChange", {
                        assetType: title,
                        value: (
                          <ColorNumber
                            formatter="absolutePercentage"
                            value={monthWeightChange}
                          />
                        ),
                      })
                    : formatMessage("fallMonthWeightChange", {
                        assetType: title,
                        value: (
                          <ColorNumber
                            formatter="absolutePercentage"
                            value={monthWeightChange}
                          />
                        ),
                      }),
                fundMarketRiskSummary:
                  formatNilToZero(value?.accountSelfSummary?.ringRatio) > 0
                    ? formatNilToZero(
                        value?.cumulativeRateSummary?.benchmarkCumulativeRate
                      ) > 0
                      ? formatMessage("fundMarketRiskSummary1", {
                          assetType: title,
                        })
                      : formatMessage("fundMarketRiskSummary2", {
                          assetType: title,
                        })
                    : formatNilToZero(
                        value?.cumulativeRateSummary?.benchmarkCumulativeRate
                      ) > 0
                    ? formatMessage("fundMarketRiskSummary3", {
                        assetType: title,
                      })
                    : formatMessage("fundMarketRiskSummary4", {
                        assetType: title,
                      }),
              })}
            </div>
          );
        case "bond":
          return (
            <div>
              {formatMessage("customerAnalysisAssetBondSummary", {
                benchmark: benchmarkName,
                cumulativeRateRiseOrFall:
                  formatNilToZero(
                    value?.cumulativeRateSummary?.benchmarkCumulativeRate
                  ) > 0
                    ? formatMessage("riseUp")
                    : formatMessage("fallDown"),
                cumulativeRate: (
                  <ColorNumber
                    value={formatNilToZero(
                      value?.cumulativeRateSummary?.benchmarkCumulativeRate
                    )}
                    formatter="absolutePercentage"
                  />
                ),
                winOrLoss:
                  formatNilToZero(
                    value?.cumulativeRateSummary?.cumulativeRate
                  ) -
                    formatNilToZero(
                      value?.cumulativeRateSummary?.benchmarkCumulativeRate
                    ) >
                  0
                    ? formatMessage("win")
                    : formatMessage("lose"),
                excessReturn: (
                  <ColorNumber
                    value={
                      formatNilToZero(
                        value?.cumulativeRateSummary?.cumulativeRate
                      ) -
                      formatNilToZero(
                        value?.cumulativeRateSummary?.benchmarkCumulativeRate
                      )
                    }
                    formatter="absolutePercentage"
                  />
                ),
                userName: userName || "--",
                bondSelectionAbility: formatMessage(
                  `${
                    summaryMapKey[
                      value?.cumulativeRateSummary?.cumulativeRateStandard ||
                        SummaryResult.ALMOST_EQUAL
                    ]
                  }BondSelectionAbility`,
                  { userName }
                ),
                compareBond: formatMessage(
                  `${
                    summaryMapKey[
                      value?.assetsWeightSummary?.weightStandard ||
                        SummaryResult.ALMOST_EQUAL
                    ]
                  }CompareBond`
                ),
                monthWeightChange:
                  monthWeightChange > 0
                    ? formatMessage("riseMonthWeightChange", {
                        assetType: title,
                        value: (
                          <ColorNumber
                            formatter="absolutePercentage"
                            value={monthWeightChange}
                          />
                        ),
                      })
                    : formatMessage("fallMonthWeightChange", {
                        assetType: title,
                        value: (
                          <ColorNumber
                            formatter="absolutePercentage"
                            value={monthWeightChange}
                          />
                        ),
                      }),
                fundMarketRiskSummary:
                  formatNilToZero(value?.accountSelfSummary?.ringRatio) > 0
                    ? formatNilToZero(
                        value?.cumulativeRateSummary?.benchmarkCumulativeRate
                      ) > 0
                      ? formatMessage("fundMarketRiskSummary1", {
                          assetType: title,
                        })
                      : formatMessage("fundMarketRiskSummary2", {
                          assetType: title,
                        })
                    : formatNilToZero(
                        value?.cumulativeRateSummary?.benchmarkCumulativeRate
                      ) > 0
                    ? formatMessage("fundMarketRiskSummary3", {
                        assetType: title,
                      })
                    : formatMessage("fundMarketRiskSummary4", {
                        assetType: title,
                      }),
              })}
            </div>
          );
        case "cash":
          return (
            <div>
              {formatMessage(
                `${
                  summaryMapKey[
                    value?.assetsWeightSummary?.weightStandard ||
                      SummaryResult.ALMOST_EQUAL
                  ]
                }CustomerAnalysisAssetCashSummary`
              )}
            </div>
          );
        default:
          return <></>;
      }
    },
    [
      benchmarkName,
      dataMissing,
      formatMessage,
      monthWeightChange,
      title,
      userName,
      value?.accountSelfSummary?.ringRatio,
      value?.assetsWeightSummary?.weightStandard,
      value?.cumulativeRateSummary?.benchmarkCumulativeRate,
      value?.cumulativeRateSummary?.cumulativeRate,
      value?.cumulativeRateSummary?.cumulativeRateStandard,
    ]
  );

  return (
    <Card
      size="small"
      title={
        <div className={style.SummaryCardHeader}>
          <div className={style.IconWrapper}>
            <img src={icon} />
          </div>
          <div className={style.TitleWrapper}>
            <div className={style.Title}>{title}</div>
            <div className={style.SubTitle}>{subTitle}</div>
          </div>
        </div>
      }
      className={style.SummaryCard}
      key={itemKey}
    >
      <div className={style.SummaryCardContent}>
        <div className={style.SummaryInfoBox}>
          <div className={style.InfoType}>{formatMessage("conclusion")}：</div>
          {getAssetCardSummaryContent(itemKey)}
        </div>
      </div>
    </Card>
  );
});
