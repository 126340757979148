import { filter, flow, map, prop, update } from "lodash/fp";
import { FormatMessageFunc } from "@/util/formatMessage";
import simpleCreate from "@/assets/portfolioIcons/simpleCreate.png";
import manualCreate from "@/assets/portfolioIcons/manualCreate.png";
import fundConfiguration from "@/assets/portfolioIcons/fundConfiguration.png";
import fromAboveToBelow from "@/assets/portfolioIcons/fromAboveToBelow.png";
import dayjs from "dayjs";
import { fixedNumberWithCommas } from "@/util/numberFormatter";
import { fastProp, normalize } from "@/util/opt";
import { pickerValue } from "./changingPositionHistory";
import {
  TurnoverRecordHistoryAssetType,
  TurnoverRecordHistory,
} from "@/model/portfolioList";
import getMessage from "@/util/getMessage";

export const getPortfolioManageOptions = (formatMessage: FormatMessageFunc) => [
  {
    label: formatMessage("portfolioManage"),
    value: "portfolioManage",
  },
  {
    label: formatMessage("backTestingScheme"),
    value: "backTestingScheme",
  },
];

export const createPortfolioType = [
  {
    id: "simpleCreate",
    icon: simpleCreate,
    nameKey: "simpleCreate",
    descriptionKey: "simpleCreateDesc",
  },
  {
    id: "manualCreate",
    icon: manualCreate,
    nameKey: "manualCreate",
    descriptionKey: "manualCreateDesc",
  },
  {
    id: "fundConfiguration",
    icon: fundConfiguration,
    nameKey: "fundAllocation",
    descriptionKey: "fundAllocationDesc",
  },
  {
    id: "fromAboveToBelow",
    icon: fromAboveToBelow,
    nameKey: "fromAboveToBelow",
    descriptionKey: "fromAboveToBelowDesc",
  },
];

export const defaultFactors = [
  "latestScale",
  "dailyRiseAndFall",
  "Fund_Sharpe_Ratio", //夏普比率
  "Fund_Max_Drawdown", //最大回撤
  "Fund_Total_Yield", //成立以来累计收益
  "Fund_Return_Annual", //历史年化收益
  "Fund_Vol_Annual", //历史年华波动率
  "foundDate",
  "historyEnd",
  "ownerName",
];

export const MOCK_PORTFOLIO_LIST = "MOCK_PORTFOLIO_LIST";

// 当前时间是否为3点之后
export const checkIsAfterThreeClock = (createTime?: string) =>
  dayjs(createTime).isAfter(dayjs().hour(15).minute(0).second(0));

export const ALL = "ALL";
export const SALE = "SALE";
export const BUYIN = "BUYIN";

export const transactionTypeOptions = [
  {
    label: getMessage("all"),
    value: ALL,
  },
  {
    label: getMessage("buyIn"),
    value: BUYIN,
  },
  {
    label: getMessage("sellOut"),
    value: SALE,
  },
];
export const transactionTypeOptionsMap = normalize("value")(
  transactionTypeOptions
);

export const getTradeType = (beforeScale: number, afterScale: number) => {
  if (afterScale - beforeScale > 0) {
    return BUYIN;
  }
  return SALE;
};
export const filterTradeType = (
  currentTradeType: string,
  formTradeType: string
) => {
  if (formTradeType === ALL) return true;
  return currentTradeType === formTradeType;
};
export type ChangingPositionHistoryAssetType = Omit<
  TurnoverRecordHistoryAssetType,
  "tradeFee"
> & {
  tradeFee: string;
  tradeType: string;
  tradeTypeText: string;
  tradeCash: string;
  [propsName: string]: any;
};
export type ChangingPositionHistoryType = {
  date: string;
  assets: ChangingPositionHistoryAssetType;
};
export const getChangingPositionHistoryDataSource = (
  pickerValues: Omit<pickerValue, "transactionType">,
  turnoverRecordData: TurnoverRecordHistory[],
  fundsMap: Record<string, any>,
  getFundManagersByDateAndId: (
    date: string,
    fundId: string
  ) => Record<string, any>
) => {
  const { warehouseStartDate, warehouseEndDate } = pickerValues;
  return flow(
    filter<TurnoverRecordHistory>(
      (item) =>
        item?.date >= warehouseStartDate && item.date <= warehouseEndDate
    ),
    map<TurnoverRecordHistory, ChangingPositionHistoryType>((turnoverItem) =>
      update(
        "assets",
        flow(
          map<TurnoverRecordHistoryAssetType, ChangingPositionHistoryAssetType>(
            (asset) => {
              const {
                fundId,
                beforeScale,
                beforeWeight,
                afterScale,
                afterWeight,
                tradeFee,
              } = asset || {};
              const tradeType = getTradeType(beforeScale, afterScale);
              return {
                beforeWeight,
                afterWeight,
                beforeScale,
                afterScale,
                fundId,
                ...fastProp(fundId)(fundsMap),
                fundManagers: getFundManagersByDateAndId(
                  turnoverItem?.date,
                  fundId
                ),
                tradeFee: fixedNumberWithCommas(tradeFee),
                tradeType,
                tradeTypeText: prop(`${tradeType}.label`)(
                  transactionTypeOptionsMap
                ),
                tradeCash: fixedNumberWithCommas(
                  Math.abs(afterScale - beforeScale)
                ),
              };
            }
          )
        )
      )(turnoverItem)
    )
  )(turnoverRecordData);
};
