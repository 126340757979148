import React, { useMemo } from "react";
import { Space, Card, Table } from "antd";
import { AssetDetailComponent } from "../../constant";
import style from "../assetIncome/index.module.less";
import { useFormatMessage } from "@/util/formatMessage";
// import CumulativeChart from "@/components/cumulativeChart";
import { getRiskIndicatorsColumns } from "@/views/compareManage/fundCompare/constant";
import { useAppSelector } from "@/hooks/redux";
import YieldStatisticRangePicker from "@/components/yieldStatisticRangePicker";
import {
  useGetRiskIndicatorsData,
  useRequestBenchmarkDailyReturnAndFactors,
} from "../../hooks";
import mapper from "./mapper";
// import { useGetChartData } from "../../hooks";
import { statisticOptions } from "../../constant";
import BenchmarkSelect from "@/components/benchmarkSelect";
import { YieldDrawDownChart } from "@/base-components/charts";
import { getSlicedDateReturnsAndRangeDates } from "@/util/business-core/dailyReturn";
import { DatePeriodName } from "@/util/business-core/datePeriod";

export default React.memo<AssetDetailComponent>(({ fundId, updateHandler }) => {
  const formatMessage = useFormatMessage();
  const {
    benchmarkId,
    statisticRange,
    benchmarkInfo,
    fundInfo,
    sameTypeInfo,
    dates,
    // benchmarkDates,
  } = useAppSelector((state) => mapper(state, fundId));
  // const chartDataInfo = useGetChartData({
  //   range: statisticRange,
  //   fundInfo,
  //   sameTypeInfo,
  //   benchmarkInfo,
  //   dates,
  //   benchmarkDates,
  // });
  const { tradingDateList } = useAppSelector((state) => state.tradingDates);
  const { datesWithPreviousOneDay, dateReturnsWithPreviousOneDay } = useMemo(
    () =>
      getSlicedDateReturnsAndRangeDates({
        name: statisticRange as DatePeriodName,
        targetDates: tradingDateList,
        targetDateReturns: [
          { dates: fundInfo?.dates, dailyReturns: fundInfo?.dailyReturns },
        ],
        otherDateReturns: [
          {
            dates: sameTypeInfo?.dates,
            dailyReturns: sameTypeInfo?.dailyReturns,
          },
          {
            dates: benchmarkInfo?.dates,
            dailyReturns: benchmarkInfo?.dailyReturns,
          },
        ],
      }),
    [fundInfo, sameTypeInfo, benchmarkInfo, tradingDateList, statisticRange]
  );
  const series = [
    {
      name: fundInfo?.name,
      color: fundInfo?.color,
      ...(dateReturnsWithPreviousOneDay?.[0] || {}),
    },
    {
      name: sameTypeInfo?.name,
      color: sameTypeInfo?.color,
      ...(dateReturnsWithPreviousOneDay?.[1] || {}),
    },
    {
      name: benchmarkInfo?.name,
      color: benchmarkInfo?.color,
      ...(dateReturnsWithPreviousOneDay?.[2] || {}),
    },
  ];
  useRequestBenchmarkDailyReturnAndFactors(benchmarkId);
  const riskIndicatorsTableData = useGetRiskIndicatorsData({
    range: statisticRange,
    dates,
    fundInfo,
    sameTypeInfo,
    benchmarkInfo,
    calculatedDates: dates,
  });
  const columns = useMemo(
    () => getRiskIndicatorsColumns(formatMessage),
    [formatMessage]
  );
  return (
    <Space direction="vertical" className={style.fullWidth} size={20}>
      <Card bordered={false} className={style.Card}>
        <Space direction="vertical" className={style.fullWidth}>
          <div className={style.Flex}>
            <h3>{formatMessage("dynamicRetracement")}</h3>
            <Space size={12}>
              <BenchmarkSelect
                className={style.CardHeader}
                value={benchmarkId}
                onChange={updateHandler("assetRiskBenchmarkId")}
              />
              <Space>
                <span>{formatMessage("periodOfTime")}</span>
                <YieldStatisticRangePicker
                  value={statisticRange}
                  onChange={updateHandler("assetRiskStatisticRange")}
                  options={statisticOptions}
                />
              </Space>
            </Space>
          </div>
          {/* <CumulativeChart
            dates={chartDataInfo.dates}
            dataSource={chartDataInfo.chartData}
            type="maxDrawdown"
          /> */}
          <YieldDrawDownChart
            height={400}
            dates={datesWithPreviousOneDay}
            series={series}
          />
        </Space>
      </Card>
      <Card bordered={false} className={style.Card}>
        <Space direction="vertical" className={style.fullWidth}>
          <h3>{formatMessage("indicatorsOfRisk")}</h3>
          <Table
            columns={columns as any}
            dataSource={riskIndicatorsTableData}
            pagination={false}
            className={style.Table}
          />
        </Space>
      </Card>
    </Space>
  );
});
