import { useCallback, useEffect, useMemo, useState } from "react";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import {
  concat,
  first,
  flow,
  forEach,
  isNumber,
  map,
  maxBy,
  prop,
  size,
} from "lodash/fp";
import { formatNilToZero } from "@/util/numberFormatter";
import { dataSourceTimeSelector } from "@/selectors/dataSource";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  YIELD_FACTORS_SECTION,
  YIELD_FACTORS_SECTION_MAP,
} from "@/constant/yieldFactors";
import getMaxDrawdown from "@/util/quant/maxDrawdown";
import { getBenchmarkYieldFactors } from "@/store/factors";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import getAnnualReturns from "@/util/quant/annualReturns";
import getSharpeRatio from "@/util/quant/sharpeRatio";
import getInformationRatio from "@/util/quant/informationRatio";
import getSortinoRatio from "@/util/quant/sortinoRatio";
import getAlpha from "@/util/quant/alpha";
import getAnnualStandardDeviation from "@/util/quant/annualStandardDeviation";
import getDownwardVolatilty from "@/util/quant/downwardVolatilty";
import getBeta from "@/util/quant/beta";
import {
  Button,
  Menu,
  TableColumnProps,
  TablePaginationConfig,
  DatePicker,
  Input,
} from "antd";
import ColorNumber from "@/components/colorNumber";
import QuarterbackRank from "@/components/quarterbackRank";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import {
  CloudDownloadOutlined,
  CaretDownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import style from "./index.module.less";
import { download } from "@/util/download";
import dayjs from "dayjs";
import { ALL_PUBLISH } from "./constant";
import {
  useCalculateRangeDate,
  useGetFactorsRangeDate,
} from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { getStatisticsRangeStartDate } from "@/constant/statisticRangeCalculator/helper";
import { RangePickerProps } from "antd/lib/date-picker";
import { FactorRankValueType } from "@/model/fundDetail";

const { RangePicker } = DatePicker;
const { Search } = Input;

export const useRequestBenchmarkDailyReturnAndFactors = (
  benchmarkId: string
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (benchmarkId) {
      dispatch(fetchFundAndBenchmarkDailyReturns(benchmarkId));
      dispatch(getBenchmarkYieldFactors(benchmarkId));
    }
  }, [benchmarkId, dispatch]);
};

type ChartDataProps = {
  range: string;
  dates: string[];
  benchmarkDates: string[];
  fundInfo: {
    id: string;
    name: string;
    color: string;
    dailyReturnsMap: Record<string, number>;
  };
  sameTypeInfo: ChartDataProps["fundInfo"];
  benchmarkInfo: ChartDataProps["fundInfo"];
};
export const useGetChartData = ({
  range,
  fundInfo,
  sameTypeInfo,
  benchmarkInfo,
  dates,
  benchmarkDates,
}: ChartDataProps) => {
  const calculatedDates = useCalculateRangeDate(
    [dates, dates],
    benchmarkDates,
    range
  );
  const maxDates = useMemo(
    () => maxBy<string[]>(size)(calculatedDates) || [],
    [calculatedDates]
  );
  const chartData = useMemo(
    () =>
      map(({ name, dailyReturnsMap, color }) => ({
        name,
        color,
        dailyReturns: map<string, [string, number]>((date) => [
          date,
          formatNilToZero(fastProp(date)(dailyReturnsMap)),
        ])(maxDates),
      }))([fundInfo, sameTypeInfo, benchmarkInfo]),
    [benchmarkInfo, fundInfo, maxDates, sameTypeInfo]
  );
  return { chartData, dates: maxDates };
};

export const useGetYieldIndicatorsData = ({
  range,
  dates,
  fundInfo,
  sameTypeInfo,
  benchmarkInfo,
}: Omit<ChartDataProps, "benchmarkDates"> & { calculatedDates: string[] }) => {
  const dataSourceTime = useAppSelector(dataSourceTimeSelector);
  const rangeStartDate = getStatisticsRangeStartDate(range, dataSourceTime);
  const riskFreeRate = useAppSelector(prop("entities.riskFreeRate"));
  const benchmarkDailyReturnsMap = useMemo(
    () => fastProp("dailyReturnsMap")(benchmarkInfo),
    [benchmarkInfo]
  );
  const getFactorRangeDate = useGetFactorsRangeDate();
  const factorRangeDate = useMemo(
    () => getFactorRangeDate(dates, range),
    [dates, getFactorRangeDate, range]
  );
  return useMemo(
    () =>
      map((fund: ChartDataProps["fundInfo"]) => {
        const firstDay = first<string>(dates);
        if (firstDay && rangeStartDate && firstDay > rangeStartDate)
          return fund;
        const dailyReturnsMap = fastProp("dailyReturnsMap")(fund);
        const dailyReturns: number[] = [];
        const benchmarkReturns: number[] = [];
        forEach((date: string) => {
          dailyReturns.push(formatNilToZero(fastProp(date)(dailyReturnsMap)));
          benchmarkReturns.push(
            formatNilToZero(fastProp(date)(benchmarkDailyReturnsMap))
          );
        })(factorRangeDate);
        const activeReturns = mapIndexed(
          (dailyReturn: number, index: number) =>
            dailyReturn - fastNth(index)(benchmarkReturns)
        )(dailyReturns);
        const fundRiskFreeRate = map<string, number>((date) =>
          formatNilToZero(fastProp(date)(riskFreeRate))
        )(factorRangeDate);
        return {
          id: fastProp("id")(fund),
          name: fastProp("name")(fund),
          annualReturns: getAnnualReturns(dailyReturns),
          sharpeRatio: getSharpeRatio(dailyReturns, fundRiskFreeRate),
          sortinoRatio: getSortinoRatio(dailyReturns, fundRiskFreeRate),
          informationRatio: getInformationRatio(activeReturns),
          alpha: getAlpha(dailyReturns, benchmarkReturns, fundRiskFreeRate),
        };
      })([fundInfo, sameTypeInfo]),
    [
      benchmarkDailyReturnsMap,
      dates,
      factorRangeDate,
      fundInfo,
      rangeStartDate,
      riskFreeRate,
      sameTypeInfo,
    ]
  );
};

export const useGetRiskIndicatorsData = ({
  range,
  dates,
  fundInfo,
  sameTypeInfo,
  benchmarkInfo,
}: Omit<ChartDataProps, "benchmarkDates"> & { calculatedDates: string[] }) => {
  const dataSourceTime = useAppSelector(dataSourceTimeSelector);
  const rangeStartDate = getStatisticsRangeStartDate(range, dataSourceTime);
  const riskFreeRate = useAppSelector(prop("entities.riskFreeRate"));
  const benchmarkDailyReturnsMap = useMemo(
    () => fastProp("dailyReturnsMap")(benchmarkInfo),
    [benchmarkInfo]
  );
  const getFactorRangeDate = useGetFactorsRangeDate();
  const factorCalculatedDates = useMemo(
    () => getFactorRangeDate(dates, range),
    [dates, getFactorRangeDate, range]
  );
  return useMemo(
    () =>
      map((fund: ChartDataProps["fundInfo"]) => {
        const firstDay = first<string>(dates);
        if (firstDay && rangeStartDate && firstDay > rangeStartDate)
          return fund;
        const dailyReturnsMap = fastProp("dailyReturnsMap")(fund);
        const dailyReturns: number[] = [];
        const benchmarkReturns: number[] = [];
        forEach((date: string) => {
          dailyReturns.push(formatNilToZero(fastProp(date)(dailyReturnsMap)));
          benchmarkReturns.push(
            formatNilToZero(fastProp(date)(benchmarkDailyReturnsMap))
          );
        })(factorCalculatedDates);
        const fundRiskFreeRate = map<string, number>((date) =>
          formatNilToZero(fastProp(date)(riskFreeRate))
        )(factorCalculatedDates);
        return {
          id: fastProp("id")(fund),
          name: fastProp("name")(fund),
          maxDrawdown: getMaxDrawdown(dailyReturns),
          annualStandardDeviation: getAnnualStandardDeviation(dailyReturns),
          downwardVolatilty: getDownwardVolatilty(dailyReturns),
          beta: getBeta(dailyReturns, benchmarkReturns, fundRiskFreeRate),
        };
      })([fundInfo, sameTypeInfo]),
    [
      benchmarkDailyReturnsMap,
      dates,
      factorCalculatedDates,
      fundInfo,
      rangeStartDate,
      riskFreeRate,
      sameTypeInfo,
    ]
  );
};

type rankInfo = {
  rank: number;
  size: number;
};
export const useGetRangeOfBenefitsColumns = () => {
  const formatMessage = useFormatMessage();
  return useMemo(
    () =>
      flow(
        map<string, TableColumnProps<any>>((id) => ({
          title: formatMessage(id),
          dataIndex: id,
          align: "right",
          className: "factorDataColumn",
          render: (dataSource: number | rankInfo, _, index: number) => {
            if (index <= 2)
              return (
                <ColorNumber
                  value={dataSource as number}
                  formatter="percentage"
                />
              );
            const rank = fastProp("rank")(dataSource as rankInfo);
            const size = fastProp("size")(dataSource as rankInfo);
            if (index === 3) {
              return (
                <div>
                  {isNumber(rank) ? rank : "--"} / {size}
                </div>
              );
            }
            return <QuarterbackRank rank={rank} size={size} />;
          },
        })),
        concat({
          title: formatMessage("assetType"),
          dataIndex: "name",
        })
      )(YIELD_FACTORS_SECTION),
    [formatMessage]
  );
};

export type getRangeOfBenefitsDataSource = {
  factorRankValue: Record<string, FactorRankValueType>;
  sameTypeFactors: Record<string, number>;
  benchmarkFactors: Record<string, number>;
  fundName: string;
  sameTypeName: string;
  benchmarkName: string;
};
export const useGetRangeOfBenefitsDataSource = ({
  fundName,
  sameTypeName,
  benchmarkName,
  factorRankValue,
  sameTypeFactors,
  benchmarkFactors,
}: getRangeOfBenefitsDataSource) => {
  const formatMessage = useFormatMessage();
  return useMemo(() => {
    const fundDataSource: Record<string, number> = {};
    const sameTypeDataSource: Record<string, number> = {};
    const benchmarkDataSource: Record<string, number> = {};
    const rankInfoDataSource: Record<string, { rank: number; size: number }> =
      {};

    forEach((item: Record<string, any>) => {
      const { value, rank, size, section } = item || {};
      fundDataSource[section] = value;
      rankInfoDataSource[section] = { rank, size };
      sameTypeDataSource[section] = fastProp(
        prop(`${section}.key`)(YIELD_FACTORS_SECTION_MAP)
      )(sameTypeFactors);
      benchmarkDataSource[section] = fastProp(
        prop(`${section}.key`)(YIELD_FACTORS_SECTION_MAP)
      )(benchmarkFactors);
    })(factorRankValue);
    return [
      {
        name: fundName,
        ...fundDataSource,
      },
      {
        name: sameTypeName,
        ...sameTypeDataSource,
      },
      {
        name: benchmarkName,
        ...benchmarkDataSource,
      },
      {
        name: formatMessage("similarRankings"),
        ...rankInfoDataSource,
      },
      {
        name: formatMessage("quarterbackRank"),
        ...rankInfoDataSource,
      },
    ];
  }, [
    benchmarkFactors,
    benchmarkName,
    factorRankValue,
    formatMessage,
    fundName,
    sameTypeFactors,
    sameTypeName,
  ]);
};

export const useGetTablePagination = (total: number) => {
  const formatMessage = useFormatMessage();
  const [pageInfo, setPageInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 15,
    showTotal: (total) =>
      formatMessage("totalSum", {
        total,
      }),
    total: total,
  });
  useEffect(() => {
    setPageInfo((info) => ({
      ...info,
      total: total,
    }));
  }, [total]);
  const changePages = useCallback(
    (current?: number, pageSize?: number) =>
      setPageInfo((info) => ({
        ...info,
        ...(current ? { current } : {}),
        ...(pageSize ? { pageSize } : {}),
      })),
    []
  );

  return {
    pageInfo,
    changePages,
  };
};

export const useGetNewsInfoColumns = (formatMessage: FormatMessageFunc) => {
  const [publishTime, setpublishTime] =
    useState<RangePickerProps["value"]>(undefined);
  const [keyword, setKeyword] = useState("");
  const columns = useMemo(
    () => [
      {
        title: formatMessage("serialNumber"),
        dataIndex: "id",
        align: "center",
        width: 80,
        render: (_: any, __: any, index: number) => index + 1,
      },
      {
        title: formatMessage("NewHeadlines"),
        dataIndex: "title",
        align: "left",
        filterIcon: (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        filterDropdown: () => (
          <div>
            <Search onSearch={setKeyword} enterButton allowClear />
          </div>
        ),
        render: (text: string, record: Record<string, any>) => {
          return (
            <a target="_blank" href={record.contentUrl}>
              {text}
            </a>
          );
        },
      },
      {
        title: formatMessage("releaseTime"),
        dataIndex: "createTime",
        align: "left",
        width: 120,
        render: (text: string) => dayjs(text).format("YYYY-MM-DD"),
        filterIcon: <CaretDownOutlined />,
        filterDropdown: () => (
          <div className={style.FilterPart} id="filterNewTime">
            {formatMessage("releaseTime")}：
            <RangePicker
              value={publishTime}
              onChange={setpublishTime}
              open={true}
              getPopupContainer={() =>
                document.getElementById("filterNewTime") as any
              }
            />
          </div>
        ),
      },
    ],
    [formatMessage, publishTime]
  );
  return { publishTime, columns, keyword };
};

export const useGetAnnouncementColumns = (
  formatMessage: FormatMessageFunc,
  changePages: (data: any) => any,
  announcementTypeList: any[]
) => {
  const [AnnouncementType, setAnnouncementType] = useState<string>(ALL_PUBLISH);
  const [publishTime, setpublishTime] =
    useState<RangePickerProps["value"]>(undefined);
  const [keyword, setKeyword] = useState("");

  const columns = useMemo(
    () => [
      {
        title: formatMessage("serialNumber"),
        dataIndex: "id",
        align: "center",
        width: 80,
        render: (_: any, __: any, index: number) => index + 1,
      },
      {
        title: formatMessage("announcementTitle"),
        dataIndex: "title",
        align: "left",
        filterIcon: (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        filterDropdown: () => (
          <div>
            <Search onSearch={setKeyword} enterButton allowClear />
          </div>
        ),
        render: (text: string) => <a>{text}</a>,
      },
      {
        title: formatMessage("announcementType"),
        dataIndex: "label",
        align: "left",
        width: 120,
        render: (text: string) => {
          return formatMessage(text);
        },
        filterDropdown: () => (
          <div>
            <Menu selectedKeys={[AnnouncementType]} mode="inline">
              {map((key: string) => (
                <Menu.Item
                  onClick={() => {
                    changePages(1);
                    setAnnouncementType(key);
                  }}
                  key={key}
                >
                  {formatMessage(key)}
                </Menu.Item>
              ))(announcementTypeList)}
            </Menu>
          </div>
        ),
      },
      {
        title: formatMessage("releaseTime"),
        dataIndex: "createTime",
        align: "left",
        width: 120,
        render: (text: string) => dayjs(text).format("YYYY-MM-DD"),
        filterIcon: <CaretDownOutlined />,
        filterDropdown: () => (
          <div className={style.FilterPart} id="anaFilterNewTime">
            {formatMessage("releaseTime")}：
            <RangePicker
              value={publishTime}
              onChange={setpublishTime}
              open={true}
              getPopupContainer={() =>
                document.getElementById("anaFilterNewTime") as any
              }
            />
          </div>
        ),
      },
      {
        title: formatMessage("download"),
        dataIndex: "createTime",
        align: "center",
        width: 50,
        render: (_: string, record: Record<string, any>) => {
          return (
            <Button
              type="link"
              onClick={() => download(record.contentUrl, record.title)}
            >
              <CloudDownloadOutlined className={style.downIcon} />
            </Button>
          );
        },
      },
    ],
    [
      AnnouncementType,
      formatMessage,
      announcementTypeList,
      changePages,
      publishTime,
    ]
  );
  return { AnnouncementType, columns, publishTime, keyword };
};
