import {
  getFactorFilterList,
  getFundTypeTags,
  getIndustryPreference,
} from "@/api/factorFund";
import { Fund } from "@/components/fundResultTable";
import { UNLIMITED } from "@/constant/fundFilter";
import {
  ClassicFilterList,
  FundTypeTags,
  SeniorFilterList,
} from "@/model/factorFund";
import createSocketThunk from "@/util/createSocketThunk";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { fastProp } from "@/util/opt";
import { TaskData } from "@/util/socket";
import { industryId } from "@/views/preferredFund/constant";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { orderBy } from "lodash/fp";

export const fetchFundType = createAsyncThunk(
  "factorFund/fundTypes",
  async () => {
    const response = await getFundTypeTags();
    return response;
  }
);

export const fetchFactorFilterList = createSocketThunk(
  "factorFund/factorFilterList",
  "FINISH_FUND_DETAIL_TAG_FILTER",
  async (v: ClassicFilterList | SeniorFilterList) => {
    const response = await getFactorFilterList(v);
    return response;
  }
);
export const fetchIndustryPreference = avoidMultiRequestActionThunk<string>(
  (id) => `factorFund.industryPreference.${id}`,
  createAsyncThunk("factorFund/industryPreference", async (id) => {
    const response = await getIndustryPreference(id);
    return response;
  })
);
interface FactorFundSliceState {
  fundTypeTags: FundTypeTags[];
  factorFilterList: Fund[];
  filterTaskProgress?: TaskData | undefined;
  industryPreference: {
    [key: string]: Record<string, string>;
  };
  classicFilter: ClassicFilterList;
  seniorFilter: SeniorFilterList;
  factorFilterType: string;
  collapsePanel: string;
  fold: boolean;
}

const fetchFactorFilterListStart: string = fetchFactorFilterList.start.type;
const fetchFactorFilterListProgress: string =
  fetchFactorFilterList.progress.type;
const fetchFactorFilterListFinish: string = fetchFactorFilterList.finish.type;
const fetchFactorFilterListError: string = fetchFactorFilterList.error.type;
export const creatClassicFilterListState: ClassicFilterList = {
  fundTypeTag: [UNLIMITED],
  ratingTag: [UNLIMITED],
  fundStyleTag: [UNLIMITED],
  fundYieldTag: { id: UNLIMITED },
  fundRiskTag: { id: UNLIMITED },
  fundStyleGrowthRatingTag: [UNLIMITED],
  fundStyleValueRatingTag: [UNLIMITED],
  fundStyleMassRatingTag: [UNLIMITED],
  fundStyleCmpstRatingTag: [UNLIMITED],
};
export const creatSeniorFilterListState: SeniorFilterList = {
  fundTypeTag: [UNLIMITED],
  foundDateTag: {
    foundDate: [UNLIMITED],
  },
  fundScaleTag: {
    fundScale: [UNLIMITED],
  },
  ratingTag: [UNLIMITED],
  operateModeTag: [UNLIMITED],
  fundManagerNumTag: [UNLIMITED],
  fundStyleTag: [UNLIMITED],
  fundIndustryTag: { categoryId: industryId, industryIds: [UNLIMITED] },
  purchaseTag: [UNLIMITED],
  redemptionTag: [UNLIMITED],
  assetTypeTag: [UNLIMITED],
  fundYieldTag: { id: UNLIMITED },
  fundRiskTag: { id: UNLIMITED },
  fundManagerIdTag: null,
  workTimeTag: {
    foundDate: [UNLIMITED],
  },
  educationTag: [UNLIMITED],
  achievementTag: {
    filterTag: "RATE_RETURN",
    range: UNLIMITED,
  },
  managerManageScaleTag: {
    filterTag: "NOW",
    manageScales: [UNLIMITED],
  },
  manageFundNumTag: {
    filterTag: "NOW",
    fundNums: [UNLIMITED],
  },
  fundCompanyIdTag: null,
  companyFoundDateTag: {
    foundDate: [UNLIMITED],
  },
  companyManageScaleTag: {
    fundScale: [UNLIMITED],
  },
  fundNumberTag: [UNLIMITED],
  companyManagerNumTag: [UNLIMITED],
  fundStyleGrowthRatingTag: [UNLIMITED],
  fundStyleValueRatingTag: [UNLIMITED],
  fundStyleMassRatingTag: [UNLIMITED],
  fundStyleCmpstRatingTag: [UNLIMITED],
  fundRiskLevelList: [UNLIMITED],
  fundStabilityList: [UNLIMITED],
  fundThemeList: [UNLIMITED],
};
const initialState: FactorFundSliceState = {
  fundTypeTags: [],
  factorFilterList: [],
  industryPreference: {},
  classicFilter: creatClassicFilterListState,
  seniorFilter: creatSeniorFilterListState,
  factorFilterType: "classicFilter",
  collapsePanel: "fundDetail",
  fold: false,
};

const factorFundSlice = createSlice({
  name: "factorFund",
  initialState,
  reducers: {
    setClassicFilter: (state: FactorFundSliceState, action) => {
      state.classicFilter = action.payload;
    },
    setSeniorFilter: (state: FactorFundSliceState, action) => {
      state.seniorFilter = action.payload;
    },
    setFactorFilterType: (state: FactorFundSliceState, action) => {
      state.factorFilterType = action.payload;
    },
    setCollapsePanel: (state: FactorFundSliceState, action) => {
      state.collapsePanel = action.payload;
    },
    setSeniorFilterTag: (state: FactorFundSliceState, action) => {
      const defaultTagValue = fastProp(`${action.payload?.tagKey}`)(
        creatSeniorFilterListState
      );
      state.seniorFilter = {
        ...state.seniorFilter,
        [action.payload?.tagKey]: defaultTagValue,
      };
    },
    setClassicFilterTag: (state: FactorFundSliceState, action) => {
      const defaultTagValue = fastProp(`${action.payload?.tagKey}`)(
        creatClassicFilterListState
      );
      state.classicFilter = {
        ...state.classicFilter,
        [action.payload?.tagKey]: defaultTagValue,
      };
    },
    setFilterFold: (state: FactorFundSliceState, action) => {
      state.fold = action.payload;
    },
  },
  extraReducers: {
    [fetchFundType.fulfilled.type]: (state, action) => {
      state.fundTypeTags = action.payload;
    },
    [fetchIndustryPreference.fulfilled.type]: (state, action) => {
      state.industryPreference[action.meta.arg] = action.payload;
    },
    [fetchFactorFilterListStart]: (state, action) => {
      state.filterTaskProgress = action?.meta;
    },
    [fetchFactorFilterListProgress]: (state, action) => {
      state.filterTaskProgress = action?.payload;
    },
    [fetchFactorFilterListFinish]: (state, action) => {
      state.factorFilterList = orderBy<Fund>(
        "code",
        "asc"
      )(action?.payload?.view);
      state.filterTaskProgress = action?.meta;
    },
    [fetchFactorFilterListError]: (state, action) => {
      state.filterTaskProgress = action.error;
    },
  },
});

export const {
  setClassicFilter,
  setSeniorFilter,
  setFactorFilterType,
  setCollapsePanel,
  setSeniorFilterTag,
  setClassicFilterTag,
  setFilterFold,
} = factorFundSlice.actions;
export default factorFundSlice.reducer;
