import { useFormatMessage } from "@/util/formatMessage";
import { Empty, Table } from "antd";
import React, { useMemo } from "react";
import { useAppSelector } from "@/hooks/redux";
import { isEmpty } from "lodash/fp";
import { fastProp } from "@/util/opt";
import mapper from "../mapper";
import style from "../index.module.less";
import { useGetFactors } from "../../../hooks";
import { BaseTableProps, lineChartTableFactor } from "../constant";
import {
  getDataSource,
  getColumns,
} from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/performanceDismantling/dismantingLineTableHelper";
import {
  getSeries,
} from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/performanceDismantling/dismantingLineChartHelper";
import { YieldTrendChart } from "@/base-components/charts";

export const LineChartTable = ({
  categoryMaxAndMin,
  factorRatioData,
}: {
  categoryMaxAndMin: BaseTableProps;
  factorRatioData: Record<string, number>;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  const dataSource = useMemo(
    () => getDataSource(categoryMaxAndMin, factorRatioData),
    [categoryMaxAndMin, factorRatioData]
  );
  const columns = useMemo(
    () => getColumns(categoryMaxAndMin, formatMessage),
    [categoryMaxAndMin, formatMessage]
  );
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      className={style.DismantlingTable}
      bordered
    />
  );
};

export default React.memo(({ range, id }: { range: string; id: string }) => {
  const { dismantlingLineChartSelector: dismantlingLineChart } = useAppSelector(
    (state) => mapper(state, id, range)
  );
  const dates = useMemo(
    () => fastProp("dates")(dismantlingLineChart),
    [dismantlingLineChart]
  );
  const series = useMemo(
    () => getSeries(dismantlingLineChart),
    [dismantlingLineChart]
  );

  const { categoryMaxAndMinSelector: categoryMaxAndMin } = useAppSelector(
    (state) => mapper(state, id, range)
  );

  const factorRatioData = useGetFactors(id, range, lineChartTableFactor);

  return (
    <>
      <LineChartTable
        categoryMaxAndMin={categoryMaxAndMin}
        factorRatioData={factorRatioData}
      />
      <br />
      {isEmpty(dates) ? (
        <Empty />
      ) : (
        <YieldTrendChart
          dates={dates}
          series={series as any}
          height={400}
          showDataZoom
          showLegend
        />
      )}
    </>
  );
});
