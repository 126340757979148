import ColorNumber from "@/components/colorNumber";
import {
  FROM_CREATION,
  RECENT_HALF_YEAR,
  RECENT_MONTH,
  RECENT_THREE_MONTH,
  RECENT_THREE_YEAR,
  RECENT_WEEK,
  RECENT_YEAR,
  statisticRange,
} from "@/constant/statisticRange";
import LineAndScatterChart from "../components/lineAndScatter";
import { useFormatMessage } from "@/util/formatMessage";
import { Card, Col, Row, Space } from "antd";
import { first, flow, last, pick, set, toArray } from "lodash/fp";
import React, { useEffect, useState } from "react";
import AssetTable from "../components/assetTable";
import { useGetLineCharDataSource } from "./hook";
import style from "./index.module.less";
import { useDispatch } from "react-redux";
import {
  fetchAssetChangeInfo,
  fetchAssetListInfo,
  fetchPurchaseRedeemInfo,
} from "@/store/customerAccountAnalysis";
import LoadingComponent from "@/components/LoadingComponent";
import { useCreation } from "ahooks";
import { useGetStatisticRange } from "@/hooks/statisticRange";
import StatisticRange from "@/components/statisticRange";

const rangeOptions = flow(
  pick([
    RECENT_WEEK,
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    RECENT_YEAR,
    RECENT_THREE_YEAR,
    FROM_CREATION,
  ]),
  set(`${RECENT_WEEK}.message`, "近1周"),
  set(`${RECENT_MONTH}.message`, "近1月"),
  set(`${RECENT_THREE_MONTH}.message`, "近3月"),
  set(`${RECENT_HALF_YEAR}.message`, "近6月"),
  set(`${RECENT_YEAR}.message`, "近1年"),
  set(`${RECENT_THREE_YEAR}.message`, "近3年"),
  set(`${FROM_CREATION}.message`, "InvestmentToDate"),
  toArray
)(statisticRange);

export default React.memo(
  ({
    customerId,
    totalAccountId,
  }: {
    customerId: string;
    totalAccountId: string;
  }) => {
    const [statisticRange, setStatisticRange] = useState(FROM_CREATION);
    const formatMessage = useFormatMessage();
    const { dates, series, accumulateProfitAndLoss, allDates } =
      useGetLineCharDataSource(customerId, statisticRange, totalAccountId);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        fetchAssetChangeInfo({
          customerId: customerId,
          section: statisticRange,
        })
      );
      dispatch(
        fetchPurchaseRedeemInfo({
          customerId: customerId,
          section: statisticRange,
        })
      );
    }, [dispatch, customerId, statisticRange]);

    useEffect(() => {
      dispatch(fetchAssetListInfo(customerId));
    }, [dispatch, customerId]);

    const calcStatisticRangeList = useGetStatisticRange(2);
    const statisticRangeList = useCreation(
      () =>
        calcStatisticRangeList(
          first(allDates) || "",
          last(allDates) || "",
          rangeOptions
        ),
      [allDates, rangeOptions]
    );

    return (
      <Card size="small">
        <Row gutter={12}>
          <Col span={12}>
            <LoadingComponent
              actions={[fetchAssetChangeInfo, fetchPurchaseRedeemInfo]}
            >
              <div className={style.CardTop}>
                <div className={style.CardTopLeft}>
                  <h4>{formatMessage("assetChange")}</h4>
                  <div className={style.ProfitLoss}>
                    <span>
                      {formatMessage("accumulateProfitAndLoss")}(
                      {formatMessage("YUAN")}):
                    </span>
                    <span className={style.ProfitLossNumber}>
                      <ColorNumber
                        value={accumulateProfitAndLoss}
                        formatter="parseToThousandth"
                      />
                    </span>
                  </div>
                </div>
                <div className={style.CardTopRight}>
                  <div className={style.Purchase}>
                    <Space size={"small"}>
                      <Space>
                        <span
                          className={style.Icon}
                          style={{ background: "#f90900" }}
                        />
                        {formatMessage("PURCHASE")}
                      </Space>
                      <Space>
                        <span
                          className={style.Icon}
                          style={{ background: "#00b867" }}
                        />
                        {formatMessage("REDEMPTION")}
                      </Space>
                      <Space>
                        <span
                          className={style.Icon}
                          style={{ background: "#0099DB" }}
                        />
                        {formatMessage("APPEND_INVEST")}
                      </Space>
                      <Space>
                        <span
                          className={style.Icon}
                          style={{ background: "#9051BE" }}
                        />
                        {formatMessage("REDUCE_INVEST")}
                      </Space>
                    </Space>
                  </div>
                  <StatisticRange
                    statisticRangeList={statisticRangeList}
                    value={statisticRange}
                    onChange={setStatisticRange}
                    className={style.YieldRangePicker}
                  />
                </div>
              </div>
              <LineAndScatterChart
                dates={dates}
                series={series as any}
                height={380}
                showDataZoom
              />
            </LoadingComponent>
          </Col>

          <Col span={12}>
            <LoadingComponent actions={fetchAssetListInfo}>
              <AssetTable customerId={customerId} />
            </LoadingComponent>
          </Col>
        </Row>
      </Card>
    );
  }
);
