import { Radio, Space } from "antd";
import React from "react";
import assetIcon from "@/assets/customerAnalysisIcons/assetIcon.png";
import { useFormatMessage } from "@/util/formatMessage";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { subAccountNameProp } from "@/model/customer";
import UploadPortfolio from "./components/uploadPortfolio";
import EntryPortfolio from "./components/entryPortfolio";
import style from "./index.module.less";

interface AssetInfoProp {
  tableData: subAccountNameProp[];
  setTableData: (value: Record<string, any>[]) => void;
  disabled: boolean;
}

export default React.memo<
  AssetInfoProp & {
    method: boolean;
    setMethod: (value: boolean) => void;
  }
>(({ tableData, setTableData, disabled, method, setMethod }) => {
  const formatMessage = useFormatMessage();

  const head = window.location.origin;

  return (
    <div className={style.FullWidth}>
      <div className={style.TitleContent}>
        <Space align="start" size={0}>
          <img src={assetIcon} />
          <span className={style.SubTitle}>
            {formatMessage("assetInformation")}
          </span>
        </Space>
      </div>
      <div className={style.AssetContent}>
        <div className={style.AssetInfoHead}>
          <div className={style.AssetInfoHeadLeft}>
            {formatMessage("addMethod")}：
            <Radio.Group
              onChange={(e) => setMethod(e.target.value)}
              value={method}
            >
              <Radio value={true}>{formatMessage("uploadCombination")}</Radio>
              <Radio value={false}>{formatMessage("entryCombination")}</Radio>
            </Radio.Group>
          </div>
          <a
            download
            href={`${head}/static/customer.xlsx`}
            className={style.DownLoad}
          >
            <Space>
              <CloudDownloadOutlined />
              {formatMessage("downloadTemplate")}
            </Space>
          </a>
        </div>
        {method ? (
          <div>
            <UploadPortfolio
              tableData={tableData}
              setTableData={setTableData}
              disabled={disabled}
            />
          </div>
        ) : (
          <EntryPortfolio
            tableData={tableData}
            setTableData={setTableData}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
});
