import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { formatPercentage } from "@/util/numberFormatter";
import { fastProp } from "@/util/opt";
import { Empty, Space, Table, TableProps } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import style from "../index.module.less";
import { concat, flow, includes, map, pick, prop, pull } from "lodash/fp";
import { isEmpty } from "lodash";
import { AnalysisDissectFundDisassembleTable } from "@/model/portfolioAnalysis";
import { fundIdMapSelector } from "@/selectors/fund";
import { otherAttribution } from "@/constant/attributionPeriod";

type TablePropsType = TableProps<any>;
type SummaryData = {
  performance: number;
  attribution: number;
  attributionRatio: number;
  excessAttribution: number;
};
const TableSummary = ({
  summaryData,
}: {
  summaryData: SummaryData;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  const { performance, attribution, attributionRatio, excessAttribution } =
    summaryData;
  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>
          {formatMessage("total")}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} align="right">
          {formatPercentage(performance)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3} align="right">
          {formatPercentage(attribution)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4} align="right">
          {formatPercentage(attributionRatio)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5} align="right">
          {formatPercentage(excessAttribution)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};

export default React.memo(
  ({
    fundDisassembleTable,
    closeModal,
  }: {
    fundDisassembleTable: Record<string, any>;
    closeModal?: () => any;
  }) => {
    const formatMessage = useFormatMessage();
    const fundDisassembleTableData = useMemo(
      () =>
        flow(
          fastProp("children"),
          map((v: AnalysisDissectFundDisassembleTable) => ({
            ...v,
            key: v.name,
          }))
        )(fundDisassembleTable),
      [fundDisassembleTable]
    );
    const summaryData = useMemo(
      () =>
        pick([
          "performance",
          "attribution",
          "attributionRatio",
          "excessAttribution",
        ])(fundDisassembleTable) as SummaryData,
      [fundDisassembleTable]
    );
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const allExpandedRowKeys = useMemo(
      () => map(fastProp("key"))(fundDisassembleTableData),
      [fundDisassembleTableData]
    );
    useEffect(() => {
      setExpandedRowKeys(allExpandedRowKeys);
    }, [allExpandedRowKeys]);
    const onExpandAllRows = useCallback(() => {
      setExpandedRowKeys(isEmpty(expandedRowKeys) ? allExpandedRowKeys : []);
    }, [allExpandedRowKeys, expandedRowKeys]);
    const fundIdMap = useAppSelector(fundIdMapSelector);
    const columns = useMemo<TablePropsType["columns"]>(
      () => [
        {
          key: "expendToFund",
          dataIndex: "name",
          title: (
            <Space size={4}>
              <span onClick={onExpandAllRows} className={style.ExpendToFund}>
                {!isEmpty(expandedRowKeys) ? (
                  <MinusSquareOutlined />
                ) : (
                  <PlusSquareOutlined />
                )}
              </span>
              {formatMessage("expendToFund")}
            </Space>
          ),
          render: (value: string) => {
            if (value === otherAttribution) return formatMessage("other");
            return prop(`${value}.name`)(fundIdMap) ? (
              <ToFundDetailPage
                name={prop(`${value}.name`)(fundIdMap)}
                id={prop(`${value}.fundId`)(fundIdMap)}
                beforeJump={closeModal}
              />
            ) : (
              value
            );
          },
          ellipsis: true,
        },
        {
          key: "code",
          dataIndex: "code",
          title: formatMessage("code"),
          render: (_, record) => {
            if (fastProp("children")(record)) {
              return "";
            }
            return (
              <ToFundDetailPage
                name={prop(`${record.name}.code`)(fundIdMap)}
                id={prop(`${record.name}.fundId`)(fundIdMap)}
                beforeJump={closeModal}
              />
            );
          },
        },
        {
          key: "performance",
          dataIndex: "performance",
          title: formatMessage("performance"),
          render: formatPercentage,
          align: "right",
          sortDirections: ["descend", "ascend", "descend"],
          sorter: (a, b) => a.performance - b.performance,
        },
        {
          key: "performanceContribution",
          dataIndex: "attribution",
          title: formatMessage("performanceContribution"),
          render: formatPercentage,
          align: "right",
          defaultSortOrder: "descend",
          sortDirections: ["descend", "ascend", "descend"],
          sorter: (a, b) => a.attribution - b.attribution,
        },
        {
          key: "ContributionWeight",
          dataIndex: "attributionRatio",
          title: formatMessage("ContributionWeight"),
          render: formatPercentage,
          align: "right",
          sortDirections: ["descend", "ascend", "descend"],
          sorter: (a, b) => a.attributionRatio - b.attributionRatio,
        },
        {
          key: "excessContribution",
          dataIndex: "excessAttribution",
          title: formatMessage("excessContribution"),
          render: formatPercentage,
          align: "right",
          sortDirections: ["descend", "ascend", "descend"],
          sorter: (a, b) => a.excessAttribution - b.excessAttribution,
        },
      ],
      [expandedRowKeys, formatMessage, fundIdMap, onExpandAllRows, closeModal]
    );
    const onExpand = useCallback(
      (key: string) => {
        if (includes(key)(expandedRowKeys)) {
          setExpandedRowKeys(pull(key));
        } else {
          setExpandedRowKeys(concat(key));
        }
      },
      [expandedRowKeys]
    );
    const expandable = useMemo<TablePropsType["expandable"]>(
      () => ({
        expandedRowKeys,
        onExpand: (_, record) => onExpand(record.key),
      }),
      [expandedRowKeys, onExpand]
    );
    return isEmpty(fundDisassembleTableData) ? (
      <Empty />
    ) : (
      <Table
        columns={columns}
        bordered
        pagination={false}
        scroll={{
          y: 400,
        }}
        className={style.DismantlingTable}
        dataSource={fundDisassembleTableData}
        expandable={expandable}
        summary={() => <TableSummary summaryData={summaryData} />}
      />
    );
  }
);
