import { useAppDispatch } from "@/hooks/redux";
import {
  fetchGetFundBasic,
  fetchGetFundDailyNetValue,
  fetchGetFundDetailReturn,
  fetchGetFundSummaryResult,
} from "@/store/fundDetailSlice";
import React, { useEffect, useRef } from "react";
import { fetchReturnFactors } from "@/store/compareManage";
import AssetDetail from "./assetDetail";
import FundBasicInfo from "./fundBasicInfo";
import style from "./index.module.less";
import AlreadySelectAnchor, {
  AlreadyRef,
} from "@/components/alreadySelectAnchor";
import { useAddCompareIds } from "@/components/alreadySelectAnchor/hooks";
import { FUNDS } from "@/components/alreadySelectAnchor/constant";

type FundDetailInfoProps = {
  id: string;
};
const FundDetailInfo = ({ id }: FundDetailInfoProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const userId = sessionStorage.getItem("userId") as string;
  useEffect(() => {
    if (id) {
      dispatch(fetchGetFundBasic({ fundId: id, userId }));
      dispatch(fetchGetFundSummaryResult(id));
      dispatch(fetchGetFundDetailReturn({ fundId: id, userId }));
      dispatch(fetchGetFundDailyNetValue(id));
      dispatch(fetchReturnFactors());
    }
  }, [dispatch, id, userId]);

  const alreadyRef = useRef<AlreadyRef>(null);

  const { comparedSelectIds, validateIsChecked, onChangeIds, addSelectIds } =
    useAddCompareIds(FUNDS);

  return (
    <div
      onClick={alreadyRef?.current?.onCloseModal}
      onScroll={alreadyRef?.current?.onCloseModal}
      className={style.Content}
    >
      <FundBasicInfo
        fundId={id}
        onOpenAnchorModal={alreadyRef?.current?.onOpenModal}
        addSelectIds={addSelectIds}
        validateIsChecked={validateIsChecked}
      />
      <AssetDetail fundId={id} />
      <AlreadySelectAnchor
        ref={alreadyRef}
        type={FUNDS}
        selectIds={comparedSelectIds}
        onChangeIds={onChangeIds}
      />
    </div>
  );
};

export default React.memo(FundDetailInfo);
