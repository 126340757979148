import { useAppSelector } from "@/hooks/redux";
import { fetchPerformanceDismantling } from "@/store/portfolioAnalysis";
import { Space } from "antd";
import React, { useCallback, useContext, useMemo, useState } from "react";
import DismantlingBarChart from "./compoents/dismantlingBarChart";
import DismantlingLineChart from "./compoents/dismantlingLineChart";
import DismantlingTreeTable from "./compoents/dismantlingTreeTable";
import style from "./index.module.less";
import CustomHeader from "../components/customHeader";
import { dismantlingDatesSelector, dismantlingTextSelector } from "./mapper";
import SummaryCard from "../../components/summaryCard";
import { useFormatMessage } from "@/util/formatMessage";
import { isEmpty } from "lodash/fp";
import { PortfolioAnalysisProps } from "../..";
import { analysisBasicInfoSelector } from "../../selectors";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import LoadingComponent from "@/components/LoadingComponent";
import ErrorBoundary from "@/components/errorBoundary";
import { errorValidator } from "@/components/errorBoundary/constant";
import { useCreation } from "ahooks";
import { AsyncThunk } from "@reduxjs/toolkit";
import mapper from "./mapper";
import { useDispatch } from "react-redux";
import { fastProp } from "@/util/opt";

export const needTime = 2;
type ActionType = AsyncThunk<any, any, any>;
type PerformanceDismantlingProp = PortfolioAnalysisProps & {
  action?: ActionType;
  closeModal?: () => any;
};
const PerformanceDismantling = (
  props: PerformanceDismantlingProp
): JSX.Element => {
  const { id, action, closeModal } = props;
  const formatMessage = useFormatMessage();
  const { startDate, endDate } = useAppSelector((state) =>
    analysisBasicInfoSelector(state, id)
  );
  const upStartDate = fastProp("startDate")(props);
  const upEndDate = fastProp("endDate")(props);
  const { runningTime, dispatch } = useContext(PortfolioAnalysisContext);
  const dispatchApp = useDispatch();
  const [statisticRange, setStatisticRange] = useState("FROM_CREATION");
  const [componentRange, setComponentRange] = useState("FROM_CREATION");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const startCalculate = useCallback(() => {
    setBtnDisabled(true);
    if (action) {
      dispatchApp(
        action({
          id,
          section: statisticRange,
        })
      );
    } else {
      dispatch(
        needTime,
        fetchPerformanceDismantling({
          id,
          section: statisticRange,
        })
      );
    }

    setComponentRange(statisticRange);
  }, [dispatch, id, statisticRange, action, dispatchApp]);

  const dates = useAppSelector((state) =>
    dismantlingDatesSelector(state, id, componentRange)
  );
  const dismantlingText = useAppSelector((state) =>
    dismantlingTextSelector(state, id, componentRange)
  );
  const summaryText = useMemo(
    () =>
      isEmpty(dismantlingText)
        ? ""
        : formatMessage("PerformanceDismantlingSummaryText", {
            from: dismantlingText.from,
            to: dismantlingText.to,
            fundName: dismantlingText.fundName,
            attributionRatio: dismantlingText.attributionRatio,
          }),
    [formatMessage, dismantlingText]
  );
  const errorKey = useCreation(
    () => errorValidator.lessTwoTradingDate(action ? 0 : needTime, runningTime),
    [runningTime]
  );
  const { fundDisassembleTableSelector: fundDisassembleTable } = useAppSelector(
    (state) => mapper(state, id, componentRange)
  );
  return (
    <LoadingComponent actions={[fetchPerformanceDismantling]}>
      <Space direction="vertical" className={style.FullWidth}>
        <CustomHeader
          value={statisticRange}
          title="statisticTime"
          onChange={(value) => {
            setStatisticRange(value);
            setBtnDisabled(false);
          }}
          startDate={upStartDate || startDate}
          endDate={upEndDate || endDate}
          dates={dates}
          onStartCalculate={startCalculate}
          disabled={btnDisabled || !!errorKey}
        />
        <ErrorBoundary errKey={errorKey}>
          {isEmpty(dates) ? (
            <div className={style.Empty}></div>
          ) : (
            <Space direction="vertical">
              <DismantlingLineChart range={componentRange} id={id} />
              <DismantlingBarChart range={componentRange} id={id} />
              <DismantlingTreeTable
                fundDisassembleTable={fundDisassembleTable}
                closeModal={closeModal}
              />
              {summaryText ? <SummaryCard summaryText={summaryText} /> : null}
            </Space>
          )}
        </ErrorBoundary>
      </Space>
    </LoadingComponent>
  );
};

export default React.memo(PerformanceDismantling);
