import React from "react";
import style from "./index.module.less";

export default React.memo(({ number }: { number: number }) => {
  return (
    <div className={style.RankImg}>
      <span>NO{number}</span>
    </div>
  );
});
