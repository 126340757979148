import React, { useMemo } from "react";
import cn from "classnames";
import { Card, Space } from "antd";
import CumulativeChart from "@/components/cumulativeChart";
import { useFormatMessage } from "@/util/formatMessage";
import getMaxDrawdown from "@/util/quant/maxDrawdown";
import { fastProp } from "@/util/opt";
import ColorNumber from "@/components/colorNumber";
import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import {
  useGetManagerCumulativeChartData,
  useManageBenchmarkIdAndRange,
} from "../hooks";
import { BaseFundManagerDetailInfoProps } from "../../interface";
import { currentManagerInfoSelector } from "../../selectors";
import style from "./index.module.less";
import LoadingComponent from "@/components/LoadingComponent";
import { getFundManagerDetailBasicInfo } from "@/store/fundManagerDetailSlice";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import { drop, first, isEmpty, last } from "lodash/fp";
import EmptyContent from "@/components/emptyContent";
import { statisticRangeList } from "../constant";
import BenchmarkSelect from "@/components/benchmarkSelect";
import CustomStaticRange from "@/views/portfolioManage/portfolioAnalysis/components/customStaticRange";

const actions = [
  getFundManagerDetailBasicInfo,
  fetchFundAndBenchmarkDailyReturns,
];
export default React.memo<
  BaseFundManagerDetailInfoProps & {
    className?: string;
  }
>(({ className, id }) => {
  const formatMessage = useFormatMessage();
  const { benchmarkId, activeRange, updateBenchmarkId, updateActiveRange } =
    useManageBenchmarkIdAndRange(id, "drawDown");
  const managerInfo = useAppSelector((state: RootState) =>
    currentManagerInfoSelector(state, id)
  );
  const {
    dates,
    chartData,
    dailyReturns,
    benchmarkDailyReturns,
    benchmarkName,
    initDates,
  } = useGetManagerCumulativeChartData(id, benchmarkId, activeRange);
  const managerDrawdown = useMemo(
    // dailyReturns是计算累计收益往前面推了一个交易日的，所以应该去掉第一天
    () => getMaxDrawdown(drop(1)(dailyReturns)),
    [dailyReturns]
  );
  const benchmarkDrawdown = useMemo(
    // dailyReturns是计算累计收益往前面推了一个交易日的，所以应该去掉第一天
    () => getMaxDrawdown(drop(1)(benchmarkDailyReturns)),
    [benchmarkDailyReturns]
  );
  return (
    <LoadingComponent actions={actions}>
      <Card
        title={formatMessage("managerDrawdown")}
        className={cn(className)}
        extra={
          <Space size={16}>
            <BenchmarkSelect value={benchmarkId} onChange={updateBenchmarkId} />
            <Space>
              <p>{formatMessage("periodOfTime")}: </p>
              <CustomStaticRange
                className={style.StatisticRange}
                value={activeRange}
                onChange={updateActiveRange}
                startDate={first(initDates) || ""}
                endDate={last(initDates) || ""}
                staticRange={statisticRangeList as any}
              />
            </Space>
          </Space>
        }
      >
        {isEmpty(dates) ? (
          <EmptyContent
            className={style.EmptyContent}
            message={formatMessage("noData")}
          />
        ) : (
          <>
            <CumulativeChart
              dates={dates}
              dataSource={chartData}
              type="maxDrawdown"
            />
            <div className={style.MaxDrawdownDesc}>
              <p>
                {fastProp("name")(managerInfo)}:{" "}
                {formatMessage("rangeMaxDrawdown")}:{" "}
                <ColorNumber
                  formatter="percentage"
                  value={managerDrawdown as number}
                />
              </p>
              <p>
                {benchmarkName}: {formatMessage("rangeMaxDrawdown")}:{" "}
                <ColorNumber
                  formatter="percentage"
                  value={benchmarkDrawdown as number}
                />
              </p>
            </div>
          </>
        )}
      </Card>
    </LoadingComponent>
  );
});
