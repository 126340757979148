import { Card, Row, Col } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { fetchCategoryAssetDistribution } from "@/store/compareManage";
import StackBarChart, { StackBarChartOpts } from "@/echarts/stackBarChart";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { map } from "lodash/fp";
import { CategoryAssetDistribution } from "@/model/compareManage";
import { fastProp, mapIndexed } from "@/util/opt";
import { isEmpty, prop } from "lodash/fp";
import LoadingComponent from "@/components/LoadingComponent";
import { categoryNameMapping } from "../constant";
import { fundIdMapSelector } from "@/selectors/fund";
import ReportDateSelector from "./reportDateSelector";
import { getRectangleLegendConfig } from "@/util/chart";
import { selectedReportDateSelector } from "../selectors";
import { RedColor } from "@/util/colors";

type DataSourceType = CategoryAssetDistribution & {
  name: string;
};
const omitMinus = (value: number) => (value < 0 ? 0 : value);
const useGetChartOptions = (selectedDate: string) => {
  const formatMessage = useFormatMessage();
  const baseData = useAppSelector<Record<string, CategoryAssetDistribution[]>>(
    prop("compareManage.fundCompare.categoryAssetDistribution")
  );

  const categoryDistributionData = useMemo(
    () => fastProp(selectedDate)(baseData),
    [baseData, selectedDate]
  );

  const fundsMap = useAppSelector(fundIdMapSelector);
  const dataSource = useMemo(
    () =>
      map<CategoryAssetDistribution, DataSourceType>(
        ({
          fundId,
          stocktonav,
          bondtonav,
          cashtonav,
          fundtonav,
          othertonav,
        }) => ({
          stocktonav: omitMinus(stocktonav),
          bondtonav: omitMinus(bondtonav),
          cashtonav: omitMinus(cashtonav),
          fundtonav: omitMinus(fundtonav),
          othertonav: omitMinus(othertonav),
          fundId,
          name: prop(`${fundId}.name`)(fundsMap),
        })
      )(categoryDistributionData),
    [categoryDistributionData, fundsMap]
  );
  return useMemo<StackBarChartOpts["options"]>(
    () => ({
      legend: {
        top: 0,
        left: 0,
        ...getRectangleLegendConfig(),
        data: mapIndexed((messageKey: string) => formatMessage(messageKey))(
          categoryNameMapping
        ),
      },
      grid: {
        top: 40,
        bottom: 0,
      },
      yAxis: {
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        data: map("name")(dataSource),
      },
      xAxis: {
        show: false,
        max: "dataMax",
      },
      series: mapIndexed((messageKey: string, key: string) => ({
        name: formatMessage(messageKey),
        data: map<DataSourceType, number>((data) => fastProp(key)(data))(
          dataSource
        ),
        barMaxWidth: 40,
        showBackground: true,
        backgroundStyle: {
          color: "#eee",
        },
        markLine: {
          silent: true,
          symbol: "none",
          label: {
            formatter: () => "100.00%",
          },
          itemStyle: {
            color: RedColor,
          },
          data: [
            {
              name: "Morning Peak",
              xAxis: 1,
            },
          ],
        },
      }))(categoryNameMapping),
    }),
    [dataSource, formatMessage]
  );
};
export default React.memo<{
  className?: string;
  fundIds: string[];
}>(({ className, fundIds }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const reportDate = useAppSelector(selectedReportDateSelector);
  const [selectedDate, setSelectedDate] = useState(reportDate as string);
  useEffect(() => {
    if (!isEmpty(fundIds) && selectedDate) {
      dispatch(
        fetchCategoryAssetDistribution({
          fundIds,
          date: selectedDate,
        })
      );
    }
  }, [dispatch, fundIds, selectedDate]);
  const chartOptions = useGetChartOptions(selectedDate);
  return (
    <Card bordered={false} className={className}>
      <LoadingComponent actions={fetchCategoryAssetDistribution}>
        <Row justify="space-between" align="middle">
          <Col>
            <h3>{formatMessage("assetAllocationType")}</h3>
          </Col>
          <Col>
            <ReportDateSelector
              value={selectedDate}
              onChange={setSelectedDate}
            />
          </Col>
        </Row>
        <StackBarChart options={chartOptions} />
      </LoadingComponent>
    </Card>
  );
});
