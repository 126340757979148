import { Radio, Tabs } from "antd";
import React, { useState } from "react";

import { useFormatMessage } from "@/util/formatMessage";

import style from "./index.module.less";
import { AssetDetailComponent } from "../../constant";
import BrinsonAttribution from "./components/brinsonAttribution";
import NetValueAttribution from "./components/netValueAttribution";

const { TabPane } = Tabs;

export default React.memo<AssetDetailComponent>((props) => {
  const formatMessage = useFormatMessage();
  const buttonGroupOptions = [
    { label: formatMessage("BRINSON"), value: "BRINSON" },
    {
      label: formatMessage("NetValueAttribution"),
      value: "NetValueAttribution",
    },
  ];
  const [tabActiveKey, setTabActiveKey] = useState("BRINSON");

  return (
    <>
      <Radio.Group
        options={buttonGroupOptions}
        onChange={({ target: { value } }) => setTabActiveKey(value)}
        value={tabActiveKey}
        optionType="button"
        buttonStyle="solid"
      />
      <Tabs className={style.Tabs} activeKey={tabActiveKey}>
        <TabPane forceRender key="BRINSON">
          <BrinsonAttribution {...props} />
        </TabPane>
        <TabPane forceRender key="NetValueAttribution">
          <NetValueAttribution {...props} />
        </TabPane>
      </Tabs>
    </>
  );
});
