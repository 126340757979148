import { Button, Input, message, Select, Table } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import cn from "classnames";
import style from "./index.module.less";
import {
  //   BOND,
  CASH,
  OPEN_FUND,
  STOCK,
  useGetCashColumns,
  useGetChangeAssetInfo,
  useGetFundColumns,
  useGetStockColumns,
} from "./hooks";
import {
  concat,
  flow,
  groupBy,
  includes,
  keys,
  map,
  remove,
  find,
  filter,
  size,
  prop,
  isBoolean,
} from "lodash/fp";
import {
  PlusOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { fastProp, mapIndexed, normalize } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import { v4 } from "uuid";
import { subAccountNameProp } from "@/model/customer";
import { customerAnalysisSelector } from "@/views/customerManage/customerAccountAnalysis/selectors";
import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { EntryAssetInfoProp } from "../entryPortfolio";
import dayjs from "dayjs";

const { Option } = Select;

export default React.memo(
  ({
    tableData,
    setTableData,
    disabled,
    isAddAsset = true,
    totalAccountId,
    isAddAccount,
    setIsAddAccount,
  }: EntryAssetInfoProp) => {
    const formatMessage = useFormatMessage();
    const uuid = v4();
    const inputRef = useRef<any>(null);

    const changeAssetInfo = useGetChangeAssetInfo(tableData, setTableData);

    const assetInfoByName = useMemo(
      () =>
        groupBy((item: Record<string, any>) => item?.subAccountName || "")(
          tableData
        ),
      [tableData]
    );

    const addAssetPortfolio = useMemoizedFn((name: string, type: string) => {
      const param: Record<string, any> = {
        id: uuid,
        subAccountName: name,
        assetType: type,
        assetCode: type === CASH ? CASH : "",
      };
      if (type !== CASH) {
        param.handlingFee = 0;
        param.transactionType = "PURCHASE";
      }
      if (type === OPEN_FUND) {
        param.dividendPolicy = "REINVESTMENT";
      }

      setTableData(concat(tableData)([param] as any[]));
    });

    const deleteAssetInfo = useMemoizedFn((id: string) => {
      const newData = remove((item: Record<string, any>) => item?.id === id)(
        tableData
      );
      setTableData(newData);
    });

    const onChangeAccountName = useMemoizedFn((name: string, value: string) => {
      const assetInfoByNameId = flow(
        fastProp(name),
        map((item: Record<string, any>) => item?.id)
      )(assetInfoByName);
      const newData = map((item: Record<string, any>) => ({
        ...item,
        subAccountName: includes(item?.id)(assetInfoByNameId)
          ? value
          : item?.subAccountName,
      }))(tableData);
      setTableData(newData);
    });

    const [errorIndex, setIndex] = useState<number | null>(null);

    const onBlurAccountName = useMemoizedFn(
      (name: string, value: string, index: number) => {
        let val = value;
        if (!val) {
          message.warning(formatMessage("pleaseEntryAccountName"));
          setIndex(index);
          return false;
        }
        if (size(tableData) !== 0 && fastProp(val)(assetInfoByName)) {
          message.warning(formatMessage("accountNameNotRepeat"));
          setIndex(index);
          val = "";
        } else {
          setIndex(null);
        }

        onChangeAccountName(name, val);
      }
    );

    const addAccountInfo = useMemoizedFn(() => {
      if (
        find((item: subAccountNameProp) => !item?.subAccountName)(tableData)
      ) {
        message.warning(formatMessage("pleaseEntryAccountName"));
        return false;
      }
      setTableData(
        concat(tableData as subAccountNameProp[])([
          {
            id: uuid,
            subAccountName: "",
          },
        ] as subAccountNameProp[])
      );
    });

    const deleteAccount = useMemoizedFn((name: string) => {
      const assetInfoByNameId = flow(
        fastProp(name),
        map((item: Record<string, any>) => item?.id)
      )(assetInfoByName);
      const newData = filter(
        (item: Record<string, any>) => !includes(item?.id)(assetInfoByNameId)
      )(tableData);
      setTableData(newData);
    });

    const onAddAccount = useMemoizedFn(() => {
      setIsAddAccount && setIsAddAccount(true);
      setTableData([
        {
          id: uuid,
          subAccountName: "",
        },
      ]);
    });

    const totalAccountInfo = useAppSelector((state: RootState) =>
      customerAnalysisSelector(state, totalAccountId || "")
    );
    const accountInfo = fastProp("accountInfo")(totalAccountInfo);
    const totalSubCustomerDetails = fastProp("subCustomerDetails")(accountInfo);

    const disabledDate = useMemoizedFn((date: string) => {
      const totalSubCustomerDetailsMap = normalize("accountId")(
        totalSubCustomerDetails
      );
      const selectedName = tableData[0]?.subAccountName;
      const from = prop(`${selectedName}.from`)(totalSubCustomerDetailsMap);
      const to = prop(`${selectedName}.to`)(totalSubCustomerDetailsMap);

      return isBoolean(isAddAccount) && !isAddAccount
        ? dayjs(date).isBefore(from) || dayjs(date).isAfter(to)
        : false;
    });

    const fundColumns = useGetFundColumns(
      changeAssetInfo,
      deleteAssetInfo,
      disabledDate
    );
    const stockColumns = useGetStockColumns(
      changeAssetInfo,
      deleteAssetInfo,
      disabledDate
    );
    const cashColumns = useGetCashColumns(
      changeAssetInfo,
      deleteAssetInfo,
      disabledDate
    );

    return (
      <>
        {mapIndexed((name: string, index: number) => {
          const currentData = fastProp(name)(assetInfoByName);
          const assetInfoByType = groupBy("assetType")(currentData);
          const fundData = fastProp(OPEN_FUND)(assetInfoByType);
          const stockData = fastProp(STOCK)(assetInfoByType);
          const cashData = fastProp(CASH)(assetInfoByType);
          // const bondData = fastProp(BOND)(assetInfoByType);

          return (
            <div className={style.AddAseetPortfolio}>
              <div className={style.accountHeader}>
                <div className={style.AccountInput}>
                  {formatMessage("accountName")}：
                  {isAddAsset || isAddAccount ? (
                    <Input
                      className={cn(
                        style.AssetName,
                        errorIndex === index && style.ErrorInput
                      )}
                      size="middle"
                      // value={name || ""}
                      ref={inputRef}
                      onBlur={(e) =>
                        onBlurAccountName(name, e.target.value, index)
                      }
                    />
                  ) : (
                    <Select
                      value={name}
                      onChange={(value: string) =>
                        onChangeAccountName(name, value)
                      }
                      dropdownMatchSelectWidth={false}
                      className={style.AssetInput}
                    >
                      {map((item: Record<string, any>) => (
                        <Option key={item?.accountId} value={item?.accountId}>
                          {item?.accountName}
                        </Option>
                      ))(totalSubCustomerDetails)}
                    </Select>
                  )}
                  {!isAddAsset && !isAddAccount && (
                    <Button
                      type="link"
                      icon={<PlusCircleOutlined />}
                      onClick={() => onAddAccount()}
                      disabled={disabled}
                    >
                      {formatMessage("addAccount")}
                    </Button>
                  )}
                </div>
                {isAddAsset && (
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => deleteAccount(name)}
                    disabled={disabled}
                  />
                )}
              </div>
              <Table
                className={style.AssetTable}
                columns={cashColumns as any}
                dataSource={cashData as any}
                footer={() => (
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => addAssetPortfolio(name, CASH)}
                    disabled={disabled || errorIndex === index}
                  >
                    {formatMessage("addCash")}
                  </Button>
                )}
                pagination={false}
                size="small"
              />
              <Table
                className={style.AssetTable}
                columns={fundColumns as any}
                dataSource={fundData}
                footer={() => (
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => addAssetPortfolio(name, OPEN_FUND)}
                    disabled={disabled || errorIndex === index}
                  >
                    {formatMessage("addFund")}
                  </Button>
                )}
                pagination={false}
                size="small"
              />
              <Table
                className={style.AssetTable}
                columns={stockColumns as any}
                dataSource={stockData}
                footer={() => (
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => addAssetPortfolio(name, STOCK)}
                    disabled={disabled || errorIndex === index}
                  >
                    {formatMessage("addStock")}
                  </Button>
                )}
                pagination={false}
                size="small"
              />
            </div>
          );
        })(keys(assetInfoByName))}
        {(isAddAsset || size(keys(assetInfoByName)) < 1) && (
          <Button
            type="link"
            icon={<PlusCircleOutlined />}
            onClick={() => addAccountInfo()}
            disabled={disabled}
          >
            {formatMessage("addAccount")}
          </Button>
        )}
      </>
    );
  }
);
