import { getPriorWeight } from "@/api/portfolioList";
import {
  RECENT_THREE_MONTH,
  RECENT_HALF_YEAR,
  RECENT_YEAR,
  RECENT_THREE_YEAR,
  FROM_CREATION,
  statisticRange,
  RECENT_FIVE_YEAR,
  RECENT_TWO_YEAR,
} from "@/constant/statisticRange";
import { weightRoundTo } from "@/util/formatter";
import getMessage from "@/util/getMessage";
import { fastProp } from "@/util/opt";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { flow, fromPairs, keys, map, pick, set, zipObject } from "lodash/fp";
import { RiskFreeRateProps } from "../../fundConfiguration/fundAllocation/components/riskFreeRate";
import {
  categorySourceType,
  constrainsItem,
  modelAllocateConfig,
} from "../interface";

// 均值方差模型
export const meanVarianceModel = "meanVarianceModel";
// BL模型
export const blackLittleManModel = "blackLittleManModel";

export const configurationModelOptions = [
  {
    label: getMessage(meanVarianceModel),
    value: meanVarianceModel,
  },
  {
    label: getMessage(blackLittleManModel),
    value: blackLittleManModel,
  },
];

// 风险最小化
export const MIN_RISK = "MIN_RISK";
// 预期收益最大化
export const MAX_RETURN = "MAX_RETURN";
// 夏普比最大化
export const MAX_SHARP_RATIO = "MAX_SHARP_RATIO";

export const selectConfigurationTargets = [
  {
    label: getMessage(MIN_RISK),
    value: MIN_RISK,
  },
  {
    label: getMessage(MAX_RETURN),
    value: MAX_RETURN,
  },
  {
    label: getMessage(MAX_SHARP_RATIO),
    value: MAX_SHARP_RATIO,
  },
];

export const modelDurationOptions = flow(
  pick([
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    RECENT_THREE_YEAR,
    RECENT_FIVE_YEAR,
    FROM_CREATION,
  ])
)(statisticRange);

// 风险偏好
export const riskAppetiteOptions = [
  {
    label: getMessage("LowRiskPreference"),
    value: 3,
  },
  {
    label: getMessage("MediumRiskAppetite"),
    value: 6,
  },
  {
    label: getMessage("HighRiskAppetite"),
    value: 9,
  },
];

// 获取不同模型下的goalConfig
export const getModelGoalConfigs = {
  // 均值方差模型
  meanVarianceModel: {
    configType: "optimizeWeight",
    constrains: [],
    dataRange: FROM_CREATION,
    goal: MIN_RISK,
    goalConfig: {
      goalType: "minRisk",
      annualizedMinReturn: null, // 年化预期收益率
    },
  },
  // BL模型
  blackLittleManModel: {
    configType: "optimizeWeight",
    dataRange: FROM_CREATION,
    goal: "MAXIMIZE_EXPECT_RETURN_SUB_VARIANCE",
    goalConfig: {
      goalType: "blackLitterman",
      riskParam: 3,
      riskFreeConfig: {
        type: "defaultRiskFree",
      },
      yieldViews: [],
      weightView: {
        priorWeights: {},
      },
    },
  },
};

// 模型配置辅助函数
export const configurationModelHelper = {
  MIN_RISK: (value: number) => ({
    goalType: "minRisk",
    annualizedMinReturn: value,
  }),
  MAX_RETURN: (value: number) => ({
    goalType: "maxReturn",
    annualizedMaxRisk: value,
  }),
  MAX_SHARP_RATIO: (value: RiskFreeRateProps["value"]) => ({
    goalType: "maxShareRatio",
    riskFreeConfig: value || {
      type: "defaultRiskFree",
    },
  }),
};

// 初始化大类权重约束
export const initCategoryWeight = (
  allocateConfig: modelAllocateConfig,
  values: string[]
): modelAllocateConfig => {
  const constrains = map((v: CheckboxValueType) => ({
    id: v,
    maxWeight: 1,
    minWeight: 0,
  }))(values) as constrainsItem[];
  return {
    ...allocateConfig,
    constrains,
  };
};

// 初始化期望资产权重和个人预期年化收益
export const initBlackLittleManWeight = (
  allocateConfig: modelAllocateConfig,
  values: string[],
  categorySource: categorySourceType
) => {
  const yieldViews = map((v: string) => ({
    categoryId: v,
    value: null,
  }))(values);
  let goalConfig = set("yieldViews", yieldViews)(allocateConfig.goalConfig);
  return getPriorWeight({
    categorySource,
    categories: values as string[],
  })
    .then((res) => {
      const originMap = initWeightView(res as any);
      goalConfig = set("weightView", {
        priorWeights: originMap,
      })(goalConfig);
      return Promise.resolve({
        ...allocateConfig,
        goalConfig,
      });
    })
    .catch(() => {
      const weightView = zipObject(values as string[], map(() => null)(values));
      goalConfig = set("weightView", {
        priorWeights: weightView,
      })(goalConfig);
      return Promise.resolve({
        ...allocateConfig,
        goalConfig,
      });
    });
};

export const initWeightView = (res: { [key: string]: number }) => {
  const indexes = keys(res);
  return flow(
    map((v: string) => ({
      id: v,
      value: fastProp(v)(res),
    })),
    weightRoundTo(1, "value"),
    map(({ id, value }: { id: string; value: number }) => [id, value]),
    fromPairs
  )(indexes);
};
