import { Card, Divider, Space } from "antd";
import FundBasicInfoTitle from "./components/fundBasicInfoTitle";
import NavUnitSection from "./components/navUnitSection";
import style from "../../index.module.less";
import CompareTable from "./components/compareTable";
import RankValueSection from "./components/rankValueSection";

const FundBasicInfo = ({
  fundId,
  onOpenAnchorModal,
  addSelectIds,
  validateIsChecked,
}: {
  fundId: string;
  onOpenAnchorModal?: () => any;
  addSelectIds: (id: string) => void;
  validateIsChecked: (id: string) => boolean;
}): JSX.Element => {
  return (
    <Card
      className={style.FundBasicInfoCard}
      size="small"
      bordered={false}
      title={
        <FundBasicInfoTitle
          fundId={fundId}
          onOpenAnchorModal={onOpenAnchorModal}
          addSelectIds={addSelectIds}
          validateIsChecked={validateIsChecked}
        />
      }
    >
      <Space>
        <Space className={style.NavUnitAndNavCom}>
          <NavUnitSection fundId={fundId} />
          <Divider className={style.Divider} type="vertical" />
          <RankValueSection fundId={fundId} />
        </Space>
        <CompareTable fundId={fundId} />
      </Space>
    </Card>
  );
};

export default FundBasicInfo;
