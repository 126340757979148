import React, { useEffect, useRef, useMemo } from "react";
import G6, { TreeGraph, GraphOptions, GraphData } from "@antv/g6";
import { useSize } from "ahooks";
import { isEmpty } from "lodash/fp";

interface BaseChartProps {
  option?: Omit<GraphOptions, "container">;
  height?: number;
  data?: GraphData;
  backgroundColor?: string;
}

const defaultOption = {
  height: 500,
};

function createTreeGraph(options: GraphOptions, data?: GraphData) {
  const treeGraph = new G6.TreeGraph(options);
  treeGraph.data(data);
  treeGraph.render();
  treeGraph.fitCenter();
  treeGraph.on("node:mouseenter", (evt: any) => {
    const { item } = evt;
    treeGraph.setItemState(item, "hover", true);
  });
  treeGraph.on("node:mouseleave", (evt: any) => {
    const { item } = evt;
    treeGraph.setItemState(item, "hover", false);
  });
  treeGraph.on("itemcollapsed", (e: any) => {
    const marker = e.item
      .get("group")
      .find((ele: any) => ele.get("name") === "collapse-icon");
    if (marker) {
      marker.attr(
        "symbol",
        e.item.getModel().collapsed ? G6.Marker.expand : G6.Marker.collapse
      );
    }
  });
  return treeGraph;
}

export default React.memo<BaseChartProps>(
  ({ option, data, height = 500, backgroundColor = "#fff" }) => {
    const ref = useRef<any>(null);
    const graphRef = useRef<TreeGraph | null>();
    const size = useSize(ref);

    const _options = useMemo(
      () => ({
        container: ref.current,
        ...defaultOption,
        ...option,
        width: size?.width,
      }),
      [size, option]
    );

    useEffect(() => {
      if (graphRef.current) {
        graphRef.current.changeData(data);
        graphRef.current.fitCenter();
      }
    }, [data]);

    useEffect(() => {
      if (graphRef.current && size?.width) {
        graphRef.current.changeSize(size?.width, size?.height);
      }
    }, [size]);

    useEffect(() => {
      if (graphRef.current) {
        graphRef.current.destroy();
        graphRef.current = createTreeGraph(_options, data);
      }
      // eslint-disable-next-line
    }, [_options]);

    useEffect(() => {
      if (!graphRef.current && size?.width && !isEmpty(data)) {
        graphRef.current = createTreeGraph(_options, data);
      }
    }, [size, data, _options]);

    return <div ref={ref} style={{ width: "100%", height, backgroundColor }} />;
  }
);
