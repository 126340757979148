import { getUserConfig, updateUserConfig } from "@/api/userConfig";
import { useAppSelector } from "@/hooks/redux";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { notDelistingFundsMapSelector } from "@/selectors/fund";
import { fastHas, fastProp } from "@/util/opt";
import { useCreation, useMemoizedFn } from "ahooks";
import {
  isEmpty,
  size,
  update,
  set,
  identity,
  omit,
  reject,
  keys,
} from "lodash/fp";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  alreadySelectConfig,
  FUNDS,
  FUND_COMPANY,
  FUND_MANAGER,
  getCompareIds,
} from "./constant";

export type changeIdsKeyArg = "delete" | "select";
export const useAddCompareIds = (type = FUNDS) => {
  const [selectCompareIds, setSelectCompareIds] = useState<
    Record<string, boolean>
  >({});
  const notDelistingFundsMap = useAppSelector(notDelistingFundsMapSelector);

  useEffect(() => {
    getUserConfig(type).then((res) => {
      const config = fastProp("config")(res);
      if (type === FUNDS) {
        const fundIds = keys(config);
        // 去掉退市基金
        const delistingFundIds = reject((id: string) =>
          fastHas(id)(notDelistingFundsMap)
        )(fundIds);
        setSelectCompareIds(omit(delistingFundIds)(config));
      } else {
        setSelectCompareIds(config);
      }
    });
  }, [notDelistingFundsMap, type]);

  const comparedIds = useCreation(
    () => getCompareIds(selectCompareIds),
    [selectCompareIds]
  );
  const addSelectIds = useMemoizedFn((id: string) => {
    const addIds = set(id, size(comparedIds) < 4)(selectCompareIds);
    setSelectCompareIds(addIds);
    updateUserConfig({ moduleId: type, config: addIds });
  });

  const onChangeIds = useMemoizedFn((key: changeIdsKeyArg) => (id: string) => {
    let newSelectIds;
    if (key === "delete") {
      newSelectIds = omit([id])(selectCompareIds) as Record<string, boolean>;
    } else {
      newSelectIds = update(id, (v) => !v)(selectCompareIds);
    }
    updateUserConfig({ moduleId: type, config: newSelectIds });
    setSelectCompareIds(newSelectIds);
  });

  const validateIsChecked = useCallback(
    (id: string) => {
      // 如果存在selectCompareIds中，说明选中了
      if (fastHas(id)(selectCompareIds)) return true;
      // 如果不在未退市基金中，说明已经退市，则不可选中
      if (!fastHas(id)(notDelistingFundsMap) && type === FUNDS) return true;
      return false;
    },
    [notDelistingFundsMap, selectCompareIds, type]
  );

  return {
    comparedSelectIds: selectCompareIds,
    onChangeIds,
    addSelectIds,
    validateIsChecked,
  };
};

export const useCompareIds = ({
  type = FUNDS,
  comparedIds,
  onCloseModal = identity,
}: {
  type: "FUNDS" | "FUND_COMPANY" | "FUND_MANAGER";
  comparedIds: string[];
  onCloseModal: () => any;
}) => {
  const { idMapSelector } = useCreation(
    () => fastProp(type)(alreadySelectConfig),
    [type]
  );
  const idsMap: Record<string, any> = useAppSelector(idMapSelector);

  const {
    goToFundComparePage,
    goToFundCompanyComparePage,
    goToFundManagerComparePage,
  } = useContext(PlatformNavigationContext);
  const goToCompare = useMemoizedFn(() => {
    if (!isEmpty(comparedIds) && type === FUNDS) {
      goToFundComparePage({ fundIds: comparedIds });
    }
    if (!isEmpty(comparedIds) && type === FUND_COMPANY) {
      goToFundCompanyComparePage({ companyIds: comparedIds });
    }
    if (!isEmpty(comparedIds) && type === FUND_MANAGER) {
      goToFundManagerComparePage({ managerIds: comparedIds });
    }
    onCloseModal();
  });
  const disabledGoToCompare = useCreation(
    () => size(comparedIds) < 2,
    [comparedIds]
  );

  return {
    idsMap,
    goToCompare,
    disabledGoToCompare,
  };
};
