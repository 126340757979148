import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import cn from "classnames";

import { useFormatMessage } from "@/util/formatMessage";
import { RECENT_MONTH } from "@/constant/statisticRange";
import LoadingComponent from "@/components/LoadingComponent";
import YieldStatisticRangePicker from "@/components/yieldStatisticRangePicker";
import { fetchFundCompanyCompareDistribution } from "@/store/compareManage";
import { useAppDispatch } from "@/hooks/redux";
import { DISTRIBUTION_TYPE } from "@/api/compareManage";

import style from "./index.module.less";
import { riskDistributionOptions } from "../constant";
import { useDistributionTable } from "../hook";

type riskDistributionProps = {
  className?: string;
  companyIds: string[];
};

export default React.memo<riskDistributionProps>(
  ({ className, companyIds }) => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();
    const [activeRange, setActiveRange] = useState(RECENT_MONTH);

    useEffect(() => {
      dispatch(
        fetchFundCompanyCompareDistribution({
          section: activeRange,
          ids: companyIds,
          distributionType: DISTRIBUTION_TYPE.MAXDRAWN,
        })
      );
    }, [activeRange, companyIds, dispatch]);

    const { columns, dataSource } = useDistributionTable({
      range: activeRange,
      companyIds,
      distributionType: DISTRIBUTION_TYPE.MAXDRAWN,
    });

    return (
      <LoadingComponent actions={fetchFundCompanyCompareDistribution}>
        <Card
          bordered={false}
          title={formatMessage("riskDistribution")}
          className={cn(className, style.CardHeadBorder)}
        >
          <div className={style.IncomeRange}>
            <YieldStatisticRangePicker
              value={activeRange}
              options={riskDistributionOptions}
              onChange={(e: any) => setActiveRange(e)}
            />
          </div>
          <Table
            bordered
            className={style.IncomeTable}
            pagination={false}
            columns={columns}
            dataSource={dataSource}
          />
        </Card>
      </LoadingComponent>
    );
  }
);
