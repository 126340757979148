import {
  compact,
  first,
  flow,
  join,
  last,
  map,
  set,
  sum,
  uniq,
  uniqBy,
} from "lodash/fp";
import getCumulative from "@/util/quant/cumulativeReturns";
import getAnnualReturns from "@/util/quant/annualReturns";
import getSharpeRatio from "@/util/quant/sharpeRatio";
import getMaxDrawdown from "@/util/quant/maxDrawdown";
import getAnnualVolatility from "@/util/quant/annualVolatility";
import { ScenarioDailyReturnInfo } from "./interface";
import { fastNth, fastProp } from "@/util/opt";
import { TableColumnsType, Tooltip } from "antd";
import { factorsFormatter } from "@/constant/factorFormatter";
import { getNatureDateAndTradingDate } from "@/util/processedDates";
import style from "./index.module.less";
import ColorNumber from "@/components/colorNumber";
import { LineSeriesOption } from "echarts";
import { formatPercentage } from "@/util/numberFormatter";
import { FormatMessageFunc } from "@/util/formatMessage";
import getMessage from "@/util/getMessage";
import { getTextWidth } from "@/util/letterAspectRatio";
import { getRectangleLegendConfig, getSquareLegendConfig } from "@/util/chart";

export const getScenarioColumns = (
  formatMessage: FormatMessageFunc
): TableColumnsType<any> => [
  {
    dataIndex: "name",
    title: formatMessage("scenarioName"),
    width: 200,
    render: (name: string, record: any) => (
      <div className={style.ScenarioName}>
        <span
          className={style.Square}
          style={{ backgroundColor: fastProp("color")(record) }}
        ></span>
        <Tooltip title={name}>
          <p className={style.Name}>{name}</p>
        </Tooltip>
      </div>
    ),
    fixed: "left",
  },
  {
    dataIndex: "startDate",
    title: formatMessage("startDate"),
  },
  {
    dataIndex: "endDate",
    title: formatMessage("endDate"),
  },
  {
    dataIndex: "effectiveDate",
    title: formatMessage("affectDayNum"),
  },
  {
    dataIndex: "cumulativeReturns",
    title: formatMessage("cumulativeIncome"),
    align: "right",
    render: (value: number) => (
      <ColorNumber value={value} formatValue={factorsFormatter.yield} />
    ),
  },
  {
    dataIndex: "benchmarkCumulativeReturns",
    title: formatMessage("benchmarkCumulativeReturn"),
    align: "right",
    render: (value: number) => (
      <ColorNumber value={value} formatValue={factorsFormatter.yield} />
    ),
  },
  {
    dataIndex: "annualReturns",
    title: formatMessage("annualYield"),
    align: "right",
    render: (value: number) => (
      <ColorNumber value={value} formatValue={factorsFormatter.yield} />
    ),
  },
  {
    dataIndex: "sharpeRatio",
    title: formatMessage("sharpeRatio"),
    align: "right",
    render: factorsFormatter.sharpeRatio,
  },
  {
    dataIndex: "maxDrawdown",
    title: formatMessage("maxDrawdown"),
    align: "right",
    render: factorsFormatter.maxDrawdown,
  },
  {
    dataIndex: "annualVolatility",
    title: formatMessage("annualizedVolatility"),
    align: "right",
    render: factorsFormatter.volatility,
  },
];

export const getScenarioTableData = (
  scenariosConfig: ScenarioDailyReturnInfo[],
  riskFreeRates: Record<string, number>,
  processedDates: Record<string, any>
) =>
  map<ScenarioDailyReturnInfo, any>((item) => {
    const dailyReturns = set(0, 0)(item.dailyReturns);
    const benchmarkDailyReturns = set(0, 0)(item.benchmarkDailyReturns);
    const riskFreeRate = map<string, number>((date) =>
      fastProp(date)(riskFreeRates)
    )(item.dates);
    const cumulativeReturns = last(getCumulative(dailyReturns));
    const benchmarkCumulativeReturns = last(
      getCumulative(benchmarkDailyReturns)
    );
    const annualReturns = getAnnualReturns(dailyReturns);
    const sharpeRatio = getSharpeRatio(dailyReturns, riskFreeRate);
    const maxDrawdown = getMaxDrawdown(dailyReturns);
    const annualVolatility = getAnnualVolatility(dailyReturns);

    const [effectiveDate] = getNatureDateAndTradingDate(
      first(item.dates) as string,
      last(item.dates) as string
    )(processedDates);
    return {
      name: item.name,
      cumulativeReturns,
      benchmarkCumulativeReturns,
      annualReturns,
      sharpeRatio,
      maxDrawdown,
      annualVolatility,
      startDate: item.startDate,
      endDate: item.endDate,
      color: item.color,
      effectiveDate,
    };
  })(scenariosConfig);

export const getScenarioSeriesConfig = (
  name: string,
  color: string,
  data: [string, number][]
): LineSeriesOption => ({
  name,
  color: color,
  type: "line",
  areaStyle: {
    opacity: 0.3,
  },
  lineStyle: {
    color: "transparent",
    width: 1.5,
  },
  itemStyle: {
    opacity: 0,
  },
  stack: name,
  data: data,
});

export const scenarioTooltipFormatter = (info: any) => {
  const [portfolioTooltip, benchmarkTooltip, ...seriesTooltip] =
    uniqBy("seriesName")(info) || [];
  const name = fastProp("name")(first(info));
  const portfolioAndBenchmarkContent = flow(
    map(({ seriesName, value, marker }: any) =>
      !value
        ? ""
        : `<p style="margin: 8px 0;">${marker}${seriesName}:  ${formatPercentage(
            fastNth(1)(value)
          )}</p>`
    ),
    join("")
  )(compact([portfolioTooltip, benchmarkTooltip]));
  const seriesContent = flow(
    map(
      ({ seriesName, marker }) =>
        `<p style="margin: 8px 0;">${marker}${getMessage(
          "scenarioName"
        )}: ${seriesName}</p>`
    ),
    join("")
  )(compact(seriesTooltip));
  return `<div>
    <p>${name}</p>
    ${portfolioAndBenchmarkContent + seriesContent}
  </div>`;
};

export const getLegendWidth = (legends: string[]) =>
  sum(map<string, number>((legend) => getTextWidth(legend, 14) + 30)(legends));

export const getLegendConfig = ({
  portfolioSeries,
  benchmarkSeries,
  scenarioSeries,
}: {
  portfolioSeries: LineSeriesOption;
  benchmarkSeries: LineSeriesOption;
  scenarioSeries: LineSeriesOption[];
}) => [
  {
    left: 0,
    top: 0,
    ...getRectangleLegendConfig(),
    data: [portfolioSeries.name, benchmarkSeries.name],
  },
  {
    left: getLegendWidth([
      portfolioSeries.name as string,
      benchmarkSeries.name as string,
    ]),
    top: -2,
    itemStyle: {
      opacity: 1,
    },
    ...getSquareLegendConfig(),
    data: uniq(map("name")(scenarioSeries)),
  },
];
