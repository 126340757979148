import React, { useMemo, useContext, useRef } from "react";
import { TableColumnType, Table, Button, Space } from "antd";
import { useGetUsers } from "@/hooks/users";
import { useAppSelector } from "@/hooks/redux";
import { userSelector } from "@/selectors/user";
import {
  Competition,
  presentRangeText,
  PresentRange,
  getCompetitionStatusText,
  CompetitionStatus,
  CompeteUsers,
} from "@/model/competition";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useFormatMessage } from "@/util/formatMessage";
import BrowseImg from "./components/browseImg";
import ValidateSwitch from "./components/validateSwitch";
import JoinUser from "./components/joinUser";
import PreviewInfo, { CompetitionModalRef } from "./components/previewInfo";
import style from "./index.module.less";
import { useMemoizedFn } from "ahooks";

export const useColumns = (userId: string) => {
  const formatMessage = useFormatMessage();
  const { userMap } = useGetUsers();
  const { goToCompetitionDetail, goToCompetitionEdit } = useContext(
    PlatformNavigationContext
  );
  const competitionModalRef = useRef<CompetitionModalRef>(null);
  const openModal = useMemoizedFn((competitionInfo) => {
    competitionModalRef.current?.openModal(competitionInfo);
  });

  return useMemo<TableColumnType<Competition>[]>(
    () => [
      {
        title: formatMessage("competitionName"),
        dataIndex: "name",
        width: 200,
        ellipsis: true,
        render: (name, record) => (
          <a
            type="link"
            onClick={() => {
              goToCompetitionDetail({ competitionId: record?.competitionId });
            }}
          >
            {name}
          </a>
        ),
      },
      {
        title: formatMessage("startDate"),
        dataIndex: "startDate",
        width: 100,
      },
      {
        title: formatMessage("endDate"),
        dataIndex: "endDate",
        width: 100,
      },
      {
        title: formatMessage("competitionStatusEnrollDeadline"),
        dataIndex: "enterEndDate",
        width: 100,
      },
      {
        title: formatMessage("competitionIntroduction"),
        dataIndex: "summary",
        width: 150,
        ellipsis: true,
      },
      {
        title: formatMessage("competitionRules"),
        dataIndex: "regulation",
        width: 150,
        ellipsis: true,
      },
      {
        title: formatMessage("cardImg"),
        dataIndex: "entranceImageName",
        width: 100,
        ellipsis: true,
        render: (value: string, record: Competition) => (
          <BrowseImg name={value} url={record?.entranceImage} />
        ),
      },
      {
        title: formatMessage("detailImg"),
        dataIndex: "bannerImageName",
        width: 100,
        ellipsis: true,
        render: (value: string, record: Competition) => (
          <BrowseImg name={value} url={record?.bannerImage} />
        ),
      },
      {
        title: formatMessage("joinCompetitionUser"),
        dataIndex: "competeUsers",
        render: (ids: CompeteUsers, record: Competition) => (
          <JoinUser data={record} />
        ),
        width: 100,
      },
      {
        title: formatMessage("creator"),
        dataIndex: "createUserId",
        render: (userId) => userMap?.[userId]?.username,
        width: 250,
        ellipsis: true,
      },
      {
        title: formatMessage("competitionDisplay"),
        dataIndex: "presentRange",
        width: 120,
        render: (value: PresentRange) =>
          formatMessage(presentRangeText?.[value]),
      },
      {
        title: formatMessage("competitionEnable"),
        dataIndex: "validate",
        render: (value, record: Competition) => (
          <ValidateSwitch
            data={record}
            disabled={userId !== record?.createUserId}
          />
        ),
        width: 100,
      },
      {
        title: formatMessage("competitionStatus"),
        dataIndex: "status",
        width: 100,
        render: (value: CompetitionStatus) =>
          formatMessage(getCompetitionStatusText(value)),
      },
      {
        title: formatMessage("operator"),
        dataIndex: "competitionId",
        fixed: "right",
        width: 100,
        render: (id, record: Competition) => {
          return (
            <>
              <Space size={0}>
                {userId === record?.createUserId && (
                  <a
                    type="link"
                    onClick={() => {
                      goToCompetitionEdit({ competitionId: id });
                    }}
                  >
                    {formatMessage("edit")}
                  </a>
                )}
                <Button type="link" onClick={() => openModal(record)}>
                  {formatMessage("preview")}
                </Button>
              </Space>
              <PreviewInfo ref={competitionModalRef} />
            </>
          );
        },
      },
    ],
    [
      formatMessage,
      userMap,
      userId,
      goToCompetitionEdit,
      goToCompetitionDetail,
      openModal,
    ]
  );
};

type TableProps = {
  competitions: Competition[];
};

export default React.memo<TableProps>(({ competitions }) => {
  const user = useAppSelector(userSelector);
  const columns = useColumns(user?.id);

  return (
    <Table
      className={style.Table}
      columns={columns}
      dataSource={competitions}
      rowKey="id"
      pagination={{
        hideOnSinglePage: true,
        showQuickJumper: true,
        size: "small",
      }}
      scroll={{ x: "100%" }}
    />
  );
});
