import React, { LegacyRef, useEffect } from "react";
import cn from "classnames";
import { useAppDispatch } from "@/hooks/redux";

import PageHeader from "../../pageHeader";
import style from "./index.module.less";
import MarketCaptureAbility from "./marketCaptureAbility";
import LoadingComponent from "@/components/LoadingComponent";
import { fetchMarketCapabilityDetail } from "@/store/fundDetailSlice";

export default React.forwardRef(
  ({ fundId }: { fundId: string }, ref: LegacyRef<HTMLDivElement>) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(fetchMarketCapabilityDetail(fundId));
    }, [dispatch, fundId]);

    return (
      <div className={style.PageWrap} ref={ref}>
        <LoadingComponent actions={fetchMarketCapabilityDetail}>
          <PageHeader pageNumber={4} />
          <div className={cn(style.PageContentWrap, style.FourthPageWrap)}>
            <MarketCaptureAbility fundId={fundId} />
          </div>
        </LoadingComponent>
      </div>
    );
  }
);
