import cn from "classnames";
import { useFormatMessage } from "@/util/formatMessage";
import { useFundOptionalService } from "@/views/myOptional/fundOptional/hooks";
import { optionalFundGroupList } from "@/views/myOptional/fundOptional/interface";
import { useCreation } from "ahooks";
import { Tree } from "antd";
import { map } from "lodash/fp";
import React, { Key } from "react";
import { AllMarket, OptionalPool } from "../constant";
import { PreferredPool } from "../constant";
import style from "../index.module.less";
export default React.memo(
  ({
    className,
    onSelectedType,
    selectedType,
  }: {
    className?: string;
    onSelectedType: (value: Key[]) => void;
    selectedType: Key[];
  }) => {
    const formatMessage = useFormatMessage();
    const { groups } = useFundOptionalService();
    const treeData = useCreation(
      () => [
        {
          title: formatMessage("AllMarket"),
          key: AllMarket,
        },
        {
          title: formatMessage("preferredPool"),
          key: PreferredPool,
        },
        {
          title: formatMessage("optionalPool"),
          key: OptionalPool,
          disabled: true,
          children: map((item: optionalFundGroupList) => ({
            title: item?._groupName || "",
            key: item?._groupName || "",
          }))(groups as any),
        },
      ],
      [formatMessage]
    );
    return (
      <>
        <Tree
          className={cn(style.Tree, className)}
          treeData={treeData}
          defaultSelectedKeys={[AllMarket]}
          defaultExpandAll={true}
          onSelect={(selectedKeys: Key[]) => onSelectedType(selectedKeys)}
          selectedKeys={selectedType}
        />
      </>
    );
  }
);
