import React, { LegacyRef, useEffect } from "react";
import cn from "classnames";

import { useAppDispatch } from "@/hooks/redux";
import {
  fetchStockSelectionAbility,
  fetchTurnoverImprovementAbility,
} from "@/store/fundDetailSlice";

import PageHeader from "../../pageHeader";
import style from "./index.module.less";
import IndividualStockSelectionAbility from "./individualStockSelectionAbility";
import PositionImprovementAbility from "./positionImprovementAbility";
import LoadingComponent from "@/components/LoadingComponent";

export default React.forwardRef(
  ({ fundId }: { fundId: string }, ref: LegacyRef<HTMLDivElement>) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (fundId) {
        dispatch(fetchStockSelectionAbility(fundId));
        dispatch(fetchTurnoverImprovementAbility(fundId));
      }
    }, [dispatch, fundId]);

    return (
      <div className={style.PageWrap} ref={ref}>
        <LoadingComponent
          actions={[
            fetchStockSelectionAbility,
            fetchTurnoverImprovementAbility,
          ]}
        >
          <PageHeader pageNumber={3} />
          <div className={cn(style.PageContentWrap, style.ThirdPageWrap)}>
            <IndividualStockSelectionAbility fundId={fundId} />
            <PositionImprovementAbility fundId={fundId} />
          </div>
        </LoadingComponent>
      </div>
    );
  }
);
