import { flow, map, pick } from "lodash/fp";
import { statisticRange } from "@/constant/statisticRange";
import { FormatMessageFunc } from "@/util/formatMessage";
import { featureListItem } from "./components/preferredFilter";

export const industryId = "sw21"; //申万行业分类2021版id
// export const industryId = "19d0f6fb-08df-420a-97e2-5358269f92c6"; //申万行业分类id
export const ZZindustryId = "cs"; //中证行业分类id
export const UNDER_FIVE = "UNDER_FIVE";
export const FIVE_TO_TEN = "FIVE_TO_TEN";
export const TEN_TO_FIFTY = "TEN_TO_FIFTY";
export const FIFTY_TO_HUNDRED = "FIFTY_TO_HUNDRED";
export const MORE_THAN_HUNDRED = "MORE_THAN_HUNDRED";

export const fundScaleTagList = [
  {
    id: UNDER_FIVE,
    name: "UNDER_FIVE",
  },
  {
    id: FIVE_TO_TEN,
    name: "FIVE_TO_TEN",
  },
  {
    id: TEN_TO_FIFTY,
    name: "TEN_TO_FIFTY",
  },
  {
    id: FIFTY_TO_HUNDRED,
    name: "FIFTY_TO_HUNDRED",
  },
  {
    id: MORE_THAN_HUNDRED,
    name: "MORE_THAN_HUNDRED",
  },
];

export const getFeaturesConditions = (
  formatMessage: FormatMessageFunc,
  industriesChildren: featureListItem[],
  hotThemeList: featureListItem[],
  ratingTagList: featureListItem[],
  fundStyleTagList: featureListItem[],
  riskLevelList: featureListItem[],
  statisticRangeList: featureListItem[]
) => {
  return [
    {
      id: "fundTheme",
      name: "fundTheme",
      children: hotThemeList,
    },
    {
      id: "fundRatings",
      name: "ratingTag",
      children: ratingTagList,
    },
    {
      id: "fundStyles",
      name: "fundStyle",
      children: fundStyleTagList,
    },
    {
      id: "assetTypes",
      name: "assetTypeTag",
      children: [
        {
          id: "EQUITY",
          name: formatMessage("EQUITY"),
        },
        {
          id: "FIXED",
          name: formatMessage("FIXED"),
        },
      ],
    },
    {
      id: "fundRiskLevels",
      name: "fofriskLevel",
      children: riskLevelList,
    },
    {
      id: "sectionReturn",
      name: "rangeOfBenefits",
      children: map((item: featureListItem) => ({
        ...item,
        name: formatMessage(item.name),
      }))(statisticRangeList),
    },
    {
      id: "fundScale",
      name: "fundScaleTag",
      children: map((item: featureListItem) => ({
        ...item,
        name: formatMessage(item.name),
      }))(fundScaleTagList),
    },
    {
      id: "fundIndustries",
      name: "heavyWarehouseIndustry",
      children: industriesChildren,
    },
  ];
};

export const tagColor = ["#4798FF", "#FF4747", "#1098CD"];

export const statisticRangeList = flow(
  pick([
    "RECENT_TRADING_DAY",
    "RECENT_WEEK",
    "RECENT_MONTH",
    "RECENT_THREE_MONTH",
    "RECENT_HALF_YEAR",
    "RECENT_YEAR",
    "RECENT_TWO_YEARS",
    "RECENT_THREE_YEAR",
    "RECENT_FIVE_YEAR",
    "FROM_CREATION",
  ]),
  map((item: Record<string, any>) => {
    return {
      id: item.id,
      name: item.message,
    };
  })
)(statisticRange);

export const sortRule = [
  {
    id: "Fund_Return_Annual",
    name: "历史年化收益",
  },
  // {
  //   id: "9360b1f8-506f-4c94-bd26-735c6cbbe215",
  //   name: "过去一个月累积收益",
  // },
  {
    id: "Fund_Sharpe_Ratio",
    name: "夏普比率",
  },
  {
    id: "Fund_Max_Drawdown",
    name: "最大回撤",
  },
];

export const maxDrawal = "Fund_Max_Drawdown";
export const shapeRatio = "Fund_Sharpe_Ratio";

export const defaultPerferredFactors = [
  "Fund_Return_12M",//过去一年累计收益
  "Fund_Return_Annual", //历史年化收益
  "Fund_Sharpe_Ratio", //夏普比率
  "Fund_Max_Drawdown", //最大回撤
  "Fund_Vol_12M",//过去一年年化波动率
];
