import { Card, Row, Col, Space } from "antd";
import React, { ReactNode, useMemo } from "react";
import PieChart, { PieChartOpts } from "@/echarts/pieChart";
import { map, isEmpty } from "lodash/fp";
import cn from "classnames";
import { PieSeriesOption } from "echarts";
import { formatPercentage } from "@/util/numberFormatter";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";
import EmptyContent from "@/components/emptyContent";

export default React.memo<{
  title: string;
  className?: string;
  dataSource: PieSeriesOption["data"];
  operation?: ReactNode;
  showSameAverageValue?: boolean;
  cardTitle?: string;
  pieOptions?: PieChartOpts["options"];
}>(
  ({
    title,
    className,
    dataSource,
    operation,
    showSameAverageValue = false,
    cardTitle,
    pieOptions,
  }) => {
    const formatMessage = useFormatMessage();
    const options = useMemo<PieChartOpts["options"]>(
      () => ({
        series: {
          name: title,
          data: dataSource,
        },
        ...pieOptions,
      }),
      [dataSource, title, pieOptions]
    );

    return (
      <Card className={cn(style.PieCard, className)}>
        <div className={style.PieCardTitle}>
          <span>{title}</span>
          <div className={style.Operation}>{operation}</div>
        </div>
        {isEmpty(dataSource) ? (
          <EmptyContent
            className={style.EmptyContent}
            message={formatMessage("noData")}
          />
        ) : (
          <Row gutter={16} className={style.CardRow}>
            <Col span={12}>
              <PieChart height="100%" options={options} />
            </Col>
            <Col span={12}>
              <Card
                title={cardTitle ? cardTitle : formatMessage("industry")}
                extra={
                  <Space
                    className={showSameAverageValue ? style.InfoCardSpace : ""}
                  >
                    <span>{formatMessage("ratioOfNetAssets")}</span>
                    {showSameAverageValue && (
                      <span>{formatMessage("sameAverage")}</span>
                    )}
                  </Space>
                }
                className={style.InfoCard}
              >
                {map(({ name, value, color, sameAverageValue }) => (
                  <div className={style.Legend}>
                    <div className={style.NameContainer}>
                      <span
                        className={style.Dot}
                        style={{ backgroundColor: color }}
                      ></span>
                      <p className={style.Name}>{name}</p>
                    </div>
                    <Space
                      className={
                        showSameAverageValue ? style.InfoCardSpace : ""
                      }
                    >
                      <p className={style.Value}>{formatPercentage(value)}</p>
                      {showSameAverageValue && (
                        <>
                          &nbsp;&nbsp;
                          <p className={style.Value}>
                            {formatPercentage(sameAverageValue)}
                          </p>
                        </>
                      )}
                    </Space>
                  </div>
                ))(dataSource)}
              </Card>
            </Col>
          </Row>
        )}
      </Card>
    );
  }
);
