import { CustomBenchmarkInterface } from "@/model/entities";
import { PromiseType } from "@/model/promise";
import { socketHttp } from "@/util/socket";
import { del, get, put } from "@/util/axios";

// 自定义基准创建
export const createCustomBenchmarkApi = (params: CustomBenchmarkInterface) =>
  socketHttp("START_CREATE_ASSET_BENCHMARK_MANAGE_PORTFOLIO", {
    meta: params,
  });

//自定义基准列表
export const getCustomBenchmarkListApi = (): PromiseType<
  CustomBenchmarkInterface[]
> => get(`/asset/benchmarks`);

//自定义基准更新
export const editCustomBenchmarkApi = ({
  id,
  ...res
}: CustomBenchmarkInterface) =>
  put(`/asset/benchmarks/${id}`, res, {
    config: {
      showLoading: true,
    },
  });

// 删除基准
export const deleteCustomBenchmarkApi = (id: string) =>
  del(`/asset/benchmarks/${id}`, undefined);

// 检测自定义基准是否重名
export const benchmarkDupName = (name: string) =>
  get(
    "asset/benchmarks/dupName",
    { name },
    {
      config: {
        showError: false,
        showLoading: true,
      },
    }
  );
