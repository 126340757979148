import { BarChartOpts } from "@/echarts/barChart";
import { useAppSelector } from "@/hooks/redux";
import { companyNameMapSelector } from "@/selectors/company";
import { getLastDateOfYear, getRectangleLegendConfig } from "@/util/chart";
import { colors, SCALE_COLORS } from "@/util/colors";
import { useFormatMessage } from "@/util/formatMessage";
import {
  fixedHundredMillion,
  formatNilToZero,
  formatPercentage,
} from "@/util/numberFormatter";
import { fastHas, fastNth, fastProp, mapIndexed, normalize } from "@/util/opt";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { useCreation } from "ahooks";
import { Space, TableProps } from "antd";
import {
  compact,
  filter,
  flatten,
  flow,
  fromPairs,
  groupBy,
  map,
  mapValues,
  maxBy,
  prop,
  size,
  some,
  sortBy,
  sumBy,
  values,
  zipObject,
} from "lodash/fp";
import { distributionsSelector } from "./selectors";
import style from "./components/index.module.less";
import {
  CompareDistributionResponse,
  distributionListItem,
} from "@/model/compareManage";
import { useGetBenchmarkDailyReturn } from "@/hooks/benchmark";
import { useCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { useCalculateScaleRangeDate } from "@/constant/statisticRangeCalculator/rangeCalculator";
import { DISTRIBUTION_TYPE } from "@/api/compareManage";

export const useIncomeTrendChart = (
  companyIds: string[],
  fundType: string,
  benchmarkId: string,
  section: string
) => {
  const benchmarkInfo = useGetBenchmarkDailyReturn(benchmarkId);

  const fundCompanyDetail = useAppSelector((state) => state.fundCompanyDetail);
  const fundDates = useCreation<string[][]>(
    () =>
      flow(
        map((v: string) =>
          prop([v, "companyDailyReturn", fundType, "dates"])(fundCompanyDetail)
        ),
        compact
      )(companyIds),
    [companyIds, fundCompanyDetail, fundType]
  );
  const companyNameMap = useAppSelector(companyNameMapSelector);
  const companyInfo = useCreation(
    () =>
      mapIndexed((v: string, index: number) => {
        const { dates, returns } =
          prop([v, "companyDailyReturn", fundType])(fundCompanyDetail) || {};
        return {
          name: prop([v, "name"])(companyNameMap),
          color: fastNth(index)(colors),
          dailyReturnsMap: normalizeDailyReturnsMap(
            dates,
            returns as unknown as number[]
          ),
        };
      })(companyIds),
    [companyIds, companyNameMap, fundCompanyDetail, fundType]
  );
  const calculatedDates = useCalculateRangeDate(
    fundDates,
    benchmarkInfo.dates,
    section
  );

  const maxDates = useCreation(
    () => maxBy<string[]>(size)(calculatedDates) || [],
    [calculatedDates]
  );

  const chartData = useCreation(
    () =>
      map(({ name, dailyReturnsMap, color }) => ({
        name,
        color,
        dailyReturns: map<string, [string, number]>((date) => [
          date,
          formatNilToZero(fastProp(date)(dailyReturnsMap)),
        ])(maxDates),
      }))([...companyInfo, benchmarkInfo]),
    [benchmarkInfo, companyInfo, maxDates]
  );
  return {
    chartData,
    dates: maxDates,
  };
};

export const useScaleTrendChart = (companyIds: string[], section: string) => {
  const companyNameMap = useAppSelector(companyNameMapSelector);
  const fundCompanyDetail = useAppSelector((state) => state.fundCompanyDetail);
  const allDates = useCreation<string[][]>(
    () =>
      flow(
        map((v: string) =>
          prop([v, "companyScaleTrend", "dates"])(fundCompanyDetail)
        ),
        compact
      )(companyIds),
    [companyIds, fundCompanyDetail]
  );
  const scales = useCreation(
    () =>
      map((v: string) => {
        const { dates, scales } =
          prop([v, "companyScaleTrend"])(fundCompanyDetail) || {};
        const scalesMap = zipObject(dates, scales);
        return {
          id: v,
          name: prop([v, "name"])(companyNameMap),
          scalesMap,
        };
      })(companyIds),
    [companyIds, fundCompanyDetail, companyNameMap]
  );

  const calculatedDates = useCalculateScaleRangeDate(
    allDates,
    undefined,
    section
  );
  const categories = flow(
    maxBy(size),
    filter((date) => some(({ scalesMap }) => fastHas(date)(scalesMap))(scales))
  )(calculatedDates) as string[];
  const formatMessage = useFormatMessage();
  return useCreation<BarChartOpts["options"]>(() => {
    const yearOfDates = getLastDateOfYear(categories);
    return {
      categories,
      grid: {
        top: 60,
        bottom: 40,
      },
      yAxis: {
        axisLabel: {
          formatter(val: number) {
            return fixedHundredMillion(val);
          },
        },
      },
      legend: getRectangleLegendConfig(),
      tooltip: {
        valueFormatter: (value) => {
          return fixedHundredMillion(value as number);
        },
      },
      xAxis: {
        nameGap: 40,
        axisLabel: {
          interval: (index: number, value: string) => {
            const year = fastProp(value)(yearOfDates);
            return year ? true : false;
          },
          formatter(value: string) {
            return `${fastProp(value)(yearOfDates)}${formatMessage("year")}`;
          },
          align: "right",
        },
      },
      data: flow(
        map(({ name, scalesMap }) => [
          name,
          map((date: string) => fastProp(date)(scalesMap))(categories),
        ]),
        fromPairs
      )(scales),
      series: mapIndexed((_: any, index: number) => ({
        color: fastNth(index)(SCALE_COLORS),
        barMaxWidth: 40,
      }))(scales),
    };
  }, [categories, formatMessage, scales]);
};

type distributionTableProps = {
  companyIds: string[];
  range: string;
  distributionType: DISTRIBUTION_TYPE;
};
type itemType = distributionListItem & { id: string; sum: number };
export const useDistributionTable = ({
  companyIds = [],
  range,
  distributionType,
}: distributionTableProps) => {
  const formatMessage = useFormatMessage();
  const companyNameMap = useAppSelector(companyNameMapSelector);
  const distributions = useAppSelector((state) =>
    distributionsSelector(state, {
      section: range,
      distributionType,
    })
  );

  /**
   * 设置表格第一列标题
   */
  const distributionTitle = {
    [DISTRIBUTION_TYPE.INCOME]: (
      <Space>
        {formatMessage("incomeRange")}
        <span className={style.ByYearlyEarning}>
          ({formatMessage("byYearlyEarning")})
        </span>
      </Space>
    ),
    [DISTRIBUTION_TYPE.MAXDRAWN]: (
      <Space>{formatMessage("drawdownRange")}</Space>
    ),
  };

  const dataSource = useCreation(
    () =>
      flow(
        map(({ id, distributionList }: CompareDistributionResponse) =>
          map((v: distributionListItem) => ({
            ...v,
            id,
            sum: sumBy("value")(distributionList),
          }))(distributionList)
        ),
        flatten,
        groupBy("name"),
        values,
        sortBy((val: { order: number }[]) => val[0].order),
        map((data: itemType[]) => {
          const range = fastProp("name")(fastNth(0)(data));
          return {
            range,
            ...flow(
              normalize("id"),
              mapValues(
                ({ sum, value }: itemType) =>
                  `${formatPercentage(value / sum)}(${value})`
              )
            )(data),
          };
        })
      )(distributions),
    [distributions]
  );
  const columns = useCreation<TableProps<any>["columns"]>(
    () => [
      {
        dataIndex: "range",
        key: "range",
        title: distributionTitle[distributionType],
        align: "left",
        ellipsis: true,
      },
      ...companyIds.map(
        (v: string) =>
          ({
            dataIndex: v,
            key: v,
            title: prop([v, "name"])(companyNameMap),
            align: "left",
            ellipsis: true,
          } as any)
      ),
    ],
    [companyIds, companyNameMap, distributionTitle, distributionType]
  );
  return {
    columns,
    dataSource,
  };
};
