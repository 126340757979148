import messages from "./en-messages.json";
// import enUS from "antd/lib/locale/en_US";
import enUS from "antd/locale/en_US";

const locales = () => {
  return {
    locale: "en",
    messages,
    antd: enUS,
  };
};
export default locales();
