import {
  indexOf,
  isEmpty,
  find,
  findLast,
  first,
  last,
  findIndex,
  slice,
} from "lodash/fp";
import dayjs from "dayjs";

export type TradingDates = string[];

export const DATEFORMAT = "YYYY-MM-DD";
export const TIME_FORMAT_DATE = "YYYY-MM-DD HH:mm:ss";

/**
 *
 * @param date
 * @param dates
 * @returns 是否是交易日  boolean
 */
export function isTradingDate(date: string, dates: TradingDates): boolean {
  if (isEmpty(date)) return false;
  if (indexOf(date)(dates) > -1) {
    return true;
  }
  return false;
}
/**
 *
 * @param date
 * @param dates
 * @param includeSelf 是否包含当前传入的日期 默认不包含
 * @returns 某个日期的下一个交易日 string
 */
export function getNextTradingDate(
  date: string,
  dates: TradingDates,
  includeSelf?: boolean
): string {
  if (isEmpty(dates)) return "";
  let operateFunc = (itemDate: string) => itemDate > date;
  if (includeSelf) {
    operateFunc = (itemDate: string) => itemDate >= date;
  }
  return find<string>(operateFunc)(dates) || "";
}

/**
 *
 * @param date
 * @param dates
 * @returns 某个日期的上一个交易日 string
 */
export function getPreviousTradingDate(
  date: string,
  dates: TradingDates,
  includeSelf?: boolean
): string {
  if (isEmpty(dates)) {
    return "";
  }
  let operateFunc = (itemDate: string) => itemDate < date;
  if (includeSelf) {
    operateFunc = (itemDate: string) => itemDate <= date;
  }
  return findLast<string>(operateFunc)(dates) || "";
}

/**
 *
 * @param targetRangeDates 目标区间
 * @param dates 使用区间
 * @param dailyReturns 使用
 * @returns 获取区间范围内的日期和日收益
 */
export function getRangeReturnsAndDates(
  targetRangeDates: TradingDates,
  dates: TradingDates,
  dailyReturns: number[]
) {
  if (!dates || !targetRangeDates) {
    return {
      dates: [],
      dailyReturns: [],
    };
  }
  const startDate =
    (first(dates) as string) < (first(targetRangeDates) as string)
      ? first(targetRangeDates)
      : first(dates);
  const endDate =
    (last(dates) as string) > (last(targetRangeDates) as string)
      ? last(targetRangeDates)
      : last(dates);
  const startIndex = findIndex<string>((date) => date === startDate)(dates);
  const endIndex = findIndex<string>((date) => date === endDate)(dates);
  return {
    dates: slice(startIndex, endIndex + 1)(dates),
    dailyReturns: slice(startIndex, endIndex + 1)(dailyReturns),
  };
}

/**
 *
 * @param start 开始时间
 * @param end 结束时间
 * @returns 通过开始时间到结束时间获取中间时长
 */
export function getYearByStartAndEnd(
  start: string,
  end?: string | undefined | null
): string {
  if (!start) return "";
  const current = dayjs(end || undefined);
  const year = current.diff(start, "year");
  const day = current.subtract(year, "year").diff(start, "day");
  return `${year + Number((day / 365).toFixed(1))}`;
}
