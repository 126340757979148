import React, { useMemo } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { isEmpty, compact, size, uniq, difference } from "lodash/fp";
import style from "../../fundCompare/index.module.less";

export default React.memo<{
  fundCompanyIds?: string[];
  companyIds: any;
}>(({ fundCompanyIds, companyIds }) => {
  const uniqCompanyIds = useMemo(() => uniq<string>(companyIds), [companyIds]);
  const formatMessage = useFormatMessage();
  const deleteIds = difference(uniqCompanyIds)(compact(fundCompanyIds));
  const addIds = difference(compact(fundCompanyIds))(uniqCompanyIds);
  const repeatSize = useMemo(() => size(uniqCompanyIds), [uniqCompanyIds]);

  return isEmpty(deleteIds) && isEmpty(addIds) ? (
    <div className={style.Tip}>
      {repeatSize && repeatSize === 1 ? (
        <div>{formatMessage("chooseEqualFundCompanyTip")}</div>
      ) : null}
      {repeatSize && repeatSize > 1 ? (
        <div>{formatMessage("chooseSameFundCompanyTip")}</div>
      ) : null}
    </div>
  ) : null;
});
