import CustomerSummary from "./customerSummary";
import AssetsChange from "./assetsChange";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { fastProp } from "@/util/opt";
import { FormatMessageFunc } from "@/util/formatMessage";
import TypeDistribution from "./typeDistribution";
// import InvestmentDetails from "./investmentDetails";
// import AccountAnalysis from "./accountAnalysis";
// import ProfitSource from "./profitSource";
import { Tag } from "antd";
import { chartColors } from "../../aum/positionStructure/concentrationMonitor/constant";
// import DrawalAndIndex from "./drawalAndIndex";
// import RiskSource from "./riskSource";
import { size } from "lodash/fp";

export const menus = [
  {
    id: "customerSummary",
    messageKey: "customerSummary",
    Component: CustomerSummary,
  },
  {
    id: "assetsChange",
    messageKey: "assetsChange",
    Component: AssetsChange,
  },
  {
    id: "typeDistribution",
    messageKey: "typeDistribution",
    Component: TypeDistribution,
  },
  // {
  //   id: "investmentDetails",
  //   messageKey: "investmentDetails",
  //   Component: InvestmentDetails,
  // },
  // {
  //   id: "accountAnalysis",
  //   messageKey: "accountAnalysis",
  //   Component: AccountAnalysis,
  // },
  // {
  //   id: "profitSource",
  //   messageKey: "profitSource",
  //   Component: ProfitSource,
  // },
  // {
  //   id: "drawalAndIndex",
  //   messageKey: "drawalAndIndex",
  //   Component: DrawalAndIndex,
  // },
  // {
  //   id: "riskSource",
  //   messageKey: "riskSource",
  //   Component: RiskSource,
  // },
];

export const totalAccount = [
  {
    id: "totalAssets",
    message: "总资产",
  },
  {
    id: "netAssets",
    message: "净资产",
  },
  {
    id: "totalLiabilities",
    message: "总负债",
  },
];

export const MOCK_TRADING_FACTORS = [
  {
    id: "71c964ab-779a-497b-becf-8abe658739c3",
    message: "近1月",
    section: "RECENT_MONTH",
    value: -0.234,
  },
  {
    id: "2d6aada8-319f-4e5b-b9e8-88509b14a7c6",
    message: "近3月",
    section: "RECENT_THREE_MONTH",
    value: 2.234,
  },
  {
    id: "b5b3fe13-339e-4e37-b493-00abd661fc0e",
    message: "近6月",
    section: "RECENT_HALF_YEAR",
    value: 1.234,
  },
  {
    id: "21eac6b5-80ed-46b7-a8aa-561f8ca8c456",
    message: "近1年",
    section: "RECENT_YEAR",
    value: 4.34,
  },
  {
    id: "125dce85-1809-4336-a745-ae53c384d3d8",
    message: "近3年",
    section: "RECENT_THREE_YEAR",
    value: 2.34,
  },
  {
    id: "foundNet",
    message: "开户以来",
    section: "FROM_CREATION",
    value: 7.234,
  },
];

export const getAssetsColumns = (formatMessage: FormatMessageFunc) => [
  {
    title: "基金名称",
    dataIndex: "name",
    render: (text: string, record: Record<string, any>) => (
      <>
        <div>
          <ToFundDetailPage name={fastProp("name")(record)} id={text} />
          (<ToFundDetailPage name={fastProp("code")(record)} id={text} />)
        </div>
        <div>
          {formatMessage("purchaseDate")}:{fastProp("date")(record)}
          <Tag color="#108ee9">{fastProp("type")(record)}</Tag>
        </div>
      </>
    ),
  },
  {
    title: "持有时长",
    dataIndex: "holdTime",
  },
  {
    title: "累计收益",
    dataIndex: "cumulateYield",
  },
  {
    title: "总申购",
    dataIndex: "cumulateYield",
  },
  {
    title: "总赎回",
    dataIndex: "cumulateYield",
  },
];

export const getBrinsonScaleChartStyle = (
  weight: number,
  index: number,
  maxFirWidth: number,
  firstColor?: string
) => {
  const chartColorsLength = size(chartColors);
  return {
    width: `calc(${(90 * Math.abs(weight)) / (1.4 * maxFirWidth)}% + 8px)`,
    background: firstColor || chartColors[index % chartColorsLength],
  };
};
