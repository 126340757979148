import {
  getCustomerPositionTrend,
  getCustomerTurnoverRecord,
} from "@/api/customerAnalysis";
import { FROM_CREATION, statisticRange } from "@/constant/statisticRange";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useRequest } from "@/hooks/request";
import {
  CustomerTurnoverRecordItem,
  PositionTrendResponse,
  purchaseRedeemBody,
} from "@/model/customer";
import { fundCodeMapSelector } from "@/selectors/fund";
import { allStocksMapSelector } from "@/selectors/stocks";
import { RootState } from "@/store";
import { fetchAssetChangeInfo } from "@/store/customerAccountAnalysis";
import { useFormatMessage } from "@/util/formatMessage";
import getMessage from "@/util/getMessage";
import { fixedHundred, fixedNumber } from "@/util/numberFormatter";
import { fastProp } from "@/util/opt";
import { FUND } from "@/views/myOptional/constant";
import { useCreation } from "ahooks";
import dayjs from "dayjs";
import {
  flow,
  forEach,
  groupBy,
  identity,
  keys,
  map,
  orderBy,
  prop,
} from "lodash/fp";
import { useEffect, useMemo } from "react";
import {
  CASH,
  STOCK,
} from "../../addCustomer/components/addAssetPortfolio/hooks";
import { LineOrScatterChartOpts } from "../../customerAccountAnalysis/components/lineAndScatter";
import { customerAnalysisSelector } from "../../customerAccountAnalysis/selectors";
import { lineColor } from "../../customerAccountAnalysis/typeDistribution/hooks";

const transforData = (
  dates: string[],
  type: string,
  positionTrend: Record<string, PositionTrendResponse>
) => {
  return map((date: string) => [date, prop(`${date}.${type}`)(positionTrend)])(
    dates
  );
};

export const useGetLineCharDataSource = (
  accountId: string,
  activeRange: string
) => {
  const formatMessage = useFormatMessage();
  const fundCodeMap = useAppSelector(fundCodeMapSelector);
  const stocksCodeMap = useAppSelector(allStocksMapSelector);
  const dispatch = useAppDispatch();
  const { data: positionTrend, request: fetchCustomerPositionTrend } =
    useRequest<
      {
        accountId: string;
        section: string;
      },
      Record<string, PositionTrendResponse>
    >(getCustomerPositionTrend);

  useEffect(() => {
    fetchCustomerPositionTrend({ accountId, section: activeRange });
    dispatch(
      fetchAssetChangeInfo({
        customerId: accountId,
        section: activeRange,
      })
    );
  }, [accountId, activeRange, fetchCustomerPositionTrend, dispatch]);

  const customerAnalysis = useAppSelector((state: RootState) =>
    customerAnalysisSelector(state, accountId)
  );

  const dates = useMemo(
    () => orderBy(identity, "asc")(keys(positionTrend)) as string[],
    [positionTrend]
  );

  const { stockData, bondData, cashData, openFundData } = useMemo(() => {
    const stockData = transforData(dates, "STOCK", positionTrend || {});
    const bondData = transforData(dates, "BOND", positionTrend || {});
    const cashData = transforData(dates, "CASH", positionTrend || {});
    const openFundData = transforData(dates, "OPEN_FUND", positionTrend || {});
    return { stockData, bondData, cashData, openFundData };
  }, [dates, positionTrend]);

  const { assetChange } = customerAnalysis;

  // const rangeAssetChange = useCreation(
  //   () => fastProp(statisticRange)(assetChange),
  //   [assetChange, statisticRange]
  // );

  const { data: rangePurchaseRedeem, request: fetchCustomerTurnoverRecord } =
    useRequest<
      {
        accountId: string;
        section: string;
      },
      CustomerTurnoverRecordItem[]
    >(getCustomerTurnoverRecord);

  useEffect(() => {
    fetchCustomerTurnoverRecord({ accountId, section: activeRange });
  }, [accountId, activeRange, fetchCustomerTurnoverRecord]);

  const { purchaseData, redeemData, appendInvestDate, reduceInvestDate } =
    useMemo(() => {
      const { purchaseDate, redeemDate, appendInvestDate, reduceInvestDate } =
        transforTypeData(rangePurchaseRedeem);
      const getReducedData = (data: purchaseRedeemBody[]) =>
        flow(
          map((item: purchaseRedeemBody) => {
            const date = dayjs(item?.createDate).format("YYYY-MM-DD");
            return [
              date,
              prop(`${date}.${item.assetType}`)(positionTrend),
              item.scale || item?.share,
              prop(`${item.assetCode}.name`)(fundCodeMap) ||
                prop(`${item.assetCode}.name`)(stocksCodeMap) ||
                formatMessage("cash"),
              item.accountName,
              (prop(`${item.assetCode}.name`)(fundCodeMap) && FUND) ||
                (prop(`${item.assetCode}.name`)(stocksCodeMap) && STOCK) ||
                (item.assetCode === "CASH" && CASH),
            ];
          })
        )(data);
      return {
        purchaseData: getReducedData(purchaseDate),
        redeemData: getReducedData(redeemDate),
        appendInvestDate: getReducedData(appendInvestDate),
        reduceInvestDate: getReducedData(reduceInvestDate),
      };
    }, [
      rangePurchaseRedeem,
      fundCodeMap,
      positionTrend,
      stocksCodeMap,
      formatMessage,
    ]);

  const series = useMemo<LineOrScatterChartOpts["series"]>(
    () => [
      {
        type: "line",
        name: formatMessage("fundScale"),
        symbol: "none",
        color: lineColor[0],
        datasetIndex: 0,
        triggerLineEvent: true,
        lineStyle: {
          width: 1.5,
        },
        data: openFundData,
        tooltip: {
          valueFormatter: (value: any) => {
            return fixedHundred(value);
          },
        },
      },
      {
        type: "line",
        name: formatMessage("stockSize"),
        symbol: "none",
        color: lineColor[1],
        datasetIndex: 0,
        triggerLineEvent: true,
        lineStyle: {
          width: 1.5,
        },
        data: stockData,
        tooltip: {
          valueFormatter: (value: any) => {
            return fixedHundred(value);
          },
        },
      },
      {
        type: "line",
        name: formatMessage("bondSize"),
        symbol: "none",
        color: lineColor[2],
        datasetIndex: 0,
        triggerLineEvent: true,
        lineStyle: {
          width: 1.5,
        },
        data: bondData,
        tooltip: {
          valueFormatter: (value: any) => {
            return fixedHundred(value);
          },
        },
      },
      {
        type: "line",
        name: formatMessage("cashScale"),
        symbol: "none",
        color: lineColor[3],
        datasetIndex: 0,
        triggerLineEvent: true,
        lineStyle: {
          width: 1.5,
        },
        data: cashData,
        tooltip: {
          valueFormatter: (value: any) => {
            return fixedHundred(value);
          },
        },
      },
      {
        type: "scatter",
        name: formatMessage("PURCHASE"),
        color: "red",
        data: purchaseData,
        datasetIndex: 1,
        triggerLineEvent: false,
        symbolSize: 12,
        tooltip: {
          valueFormatter: (value: any) => {
            return value;
          },
        },
      },
      {
        type: "scatter",
        name: formatMessage("REDEMPTION"),
        color: "green",
        data: redeemData,
        datasetIndex: 1,
        triggerLineEvent: false,
        symbolSize: 12,
        tooltip: {
          valueFormatter: (value: any) => {
            return value;
          },
        },
      },
      {
        type: "scatter",
        name: formatMessage("APPEND_INVEST"),
        color: "#0099DB",
        data: appendInvestDate,
        datasetIndex: 1,
        triggerLineEvent: false,
        symbolSize: 12,
        tooltip: {
          valueFormatter: (value: any) => {
            return value;
          },
        },
      },
      {
        type: "scatter",
        name: formatMessage("REDUCE_INVEST"),
        color: "#9051BE",
        data: reduceInvestDate,
        datasetIndex: 1,
        triggerLineEvent: false,
        symbolSize: 12,
        tooltip: {
          valueFormatter: (value: any) => {
            return value;
          },
        },
      },
    ],
    [
      stockData,
      bondData,
      cashData,
      openFundData,
      purchaseData,
      redeemData,
      appendInvestDate,
      reduceInvestDate,
      formatMessage,
    ]
  );
  const allDates = useCreation(
    () => flow(fastProp(FROM_CREATION), keys)(assetChange),
    [assetChange, statisticRange]
  );

  return {
    dates,
    series,
    allDates: orderBy(identity, "asc")(allDates) as string[],
    turnoverRecord: groupBy(
      (item: CustomerTurnoverRecordItem) => item?.createDate
    )(orderBy("createDate", "desc")(rangePurchaseRedeem)),
  };
};

export const PURCHASE = "PURCHASE"; //买入
export const REDEMPTION = "REDEMPTION"; //卖出
export const APPEND_INVEST = "APPEND_INVEST"; //转入
export const REDUCE_INVEST = "REDUCE_INVEST"; //转出

export const transforTypeData = (
  rangePurchaseRedeem: CustomerTurnoverRecordItem[] | undefined
) => {
  const purchaseDate: purchaseRedeemBody[] = [];
  const redeemDate: purchaseRedeemBody[] = [];
  const appendInvestDate: purchaseRedeemBody[] = [];
  const reduceInvestDate: purchaseRedeemBody[] = [];
  forEach((item: purchaseRedeemBody) => {
    if (item.type === PURCHASE) {
      purchaseDate.push(item);
    } else if (item.type === REDEMPTION) {
      redeemDate.push(item);
    } else if (item.type === APPEND_INVEST) {
      appendInvestDate.push(item);
    } else {
      reduceInvestDate.push(item);
    }
  })(rangePurchaseRedeem || []);
  return { purchaseDate, redeemDate, appendInvestDate, reduceInvestDate };
};

export const dealScatterTipData = (value: number, assetType: string) => {
  let assetTypeName = "";
  if (assetType === STOCK) {
    assetTypeName = "股";
  }
  if (assetType === FUND) {
    assetTypeName = getMessage("part");
  }
  return value >= 10000
    ? `${fixedHundred(value)}万${assetTypeName}`
    : `${fixedNumber(value || 0)}${assetTypeName}`;
};

export const dealTipData = (value: number) => {
  return value >= 10000
    ? `${fixedHundred(value)}万`
    : `${fixedNumber(value || 0)}${getMessage("YUAN")}`;
};
