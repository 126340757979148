import { flow, last, prop, set } from "lodash/fp";
import React, { useEffect, useState } from "react";
import { useCreation, useMemoizedFn } from "ahooks";
import { Card, Space, Button, Tooltip } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { fastProp } from "@/util/opt";
import { PerformanceBenchmarkSelectValue } from "@/components/portfolioCompoents/performanceBenchmarkSelect";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import TradingDatePicker from "@/components/tradingDatePicker";
import { startBackTesting as startBackTestingAction } from "@/store/portfolioAnalysis";
import { getLastMaxNavDate } from "../constant";
import style from "./index.module.less";
import NumberInput from "@/components/numberInput";
import { useFormatMessage } from "@/util/formatMessage";
import SaveScheme, { SaveSchemeFormType } from "./saveScheme";
import dayjs from "dayjs";
import { serializeTurnoverPositions } from "./constant";
import { dataSourceTimeSelector } from "@/selectors/dataSource";
import {
  updateFundConfiguration,
  updateManualCreatePortfolio,
  updateModelConfiguration,
} from "@/store/createPortfolio";
import { SaveBackTestingSchemeParams } from "@/api/portfolioList";
import { BackTestingFormProps } from "../interface";
import BenchmarkSelect from "@/components/benchmarkSelect";

export type BackTestingForm = {
  startDate: string;
  endDate: string;
  benchmarkId: string;
  initScale: number;
  benchmarkType: string;
  id?: string;
};

export type SaveSchemeParams = Omit<
  SaveBackTestingSchemeParams,
  "allocateRequest"
>;

export const useGetDataGeneratorByType = (
  type: BackTestingFormProps["type"]
) => {
  const { backTestingFormData } = useAppSelector(
    (state) => state.createPortfolio[type]
  );
  const dispatch = useAppDispatch();
  const setFormData = useMemoizedFn(
    (updater: (data: BackTestingForm) => BackTestingForm) => {
      if (type === "manualCreatePortfolio") {
        return dispatch(
          updateManualCreatePortfolio("backTestingFormData", updater)
        );
      }
      if (type === "fundConfiguration") {
        dispatch(updateFundConfiguration("backTestingFormData", updater));
      }
      if (type === "modelAllocation") {
        dispatch(updateModelConfiguration("backTestingFormData", updater));
      }
    }
  );
  return { backTestingFormData, setFormData };
};

const useManageBackTestingForm = ({ data, type }: BackTestingFormProps) => {
  const { backTestingFormData, setFormData } = useGetDataGeneratorByType(type);
  const lastMaxNavDate = useCreation(() => getLastMaxNavDate(data), [data]);
  const lastTurnoverDate = useCreation(
    () => flow(last, fastProp("turnoverDate"))(data),
    [data]
  );
  const startDate = useCreation(() => data[0]?.turnoverDate, [data]);
  const dataSourceTime = useAppSelector(dataSourceTimeSelector);
  const onUpdate = useMemoizedFn(
    (key: keyof BackTestingForm) => (value: string | null | number) => {
      const prevFormValue = fastProp(key)(backTestingFormData);
      if (prevFormValue !== value) setFormData(set(key, value));
    }
  );
  useEffect(() => {
    if (!startDate) return;
    onUpdate("startDate")(startDate);
  }, [onUpdate, startDate]);
  useEffect(() => {
    if (
      backTestingFormData.endDate < lastTurnoverDate ||
      backTestingFormData.endDate <= backTestingFormData.startDate ||
      backTestingFormData.endDate > lastMaxNavDate
    ) {
      onUpdate("endDate")(lastMaxNavDate || dataSourceTime);
    }
  }, [
    dataSourceTime,
    onUpdate,
    lastMaxNavDate,
    backTestingFormData,
    lastTurnoverDate,
  ]);
  return {
    onUpdate,
    lastMaxNavDate,
    lastTurnoverDate,
    formData: backTestingFormData,
  };
};

const useManageStartBackTestButton = () => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const backTestingTask = useAppSelector(
    prop("portfolioAnalysis.backTestingTask")
  );
  useEffect(() => {
    setButtonDisabled(!!backTestingTask);
  }, [backTestingTask]);
  return { buttonDisabled, setButtonDisabled };
};

export default React.memo<BackTestingFormProps>((props) => {
  const formatMessage = useFormatMessage();
  const { formData, onUpdate, lastMaxNavDate, lastTurnoverDate } =
    useManageBackTestingForm(props);
  const dispatch = useAppDispatch();
  const { buttonDisabled, setButtonDisabled } = useManageStartBackTestButton();
  const startBackTesting = useMemoizedFn(() => {
    setButtonDisabled(true);
    dispatch(
      startBackTestingAction({
        startDate: formData.startDate as string,
        endDate: formData.endDate as string,
        benchmark: formData.benchmarkId as string,
        initScale: formData.initScale,
        benchmarkType: formData.benchmarkType,
        turnoverPositions: serializeTurnoverPositions(props.data),
      })
    );
  });
  const endDisabledDate = useMemoizedFn((date: string) => {
    const day = dayjs(date);
    return (
      (formData.startDate && !day.isAfter(formData.startDate)) ||
      (lastTurnoverDate && day.isBefore(lastTurnoverDate)) ||
      (lastMaxNavDate && day.isAfter(lastMaxNavDate))
    );
  });

  const onSaveScheme = useMemoizedFn((basicData: SaveSchemeFormType) => {
    return props.onSaveScheme({
      ...formData,
      ...basicData,
      turnoverPositions: serializeTurnoverPositions(props.data),
    });
  });
  const performanceBenchmarkSelectOnChange = useMemoizedFn(
    (v: PerformanceBenchmarkSelectValue) => {
      onUpdate("benchmarkId")(v.benchmarkId);
      onUpdate("benchmarkType")(v.benchmarkType as string);
    }
  );

  const startDateEqualEndDate = formData.endDate === formData.startDate;

  return (
    <>
      <Card
        title={formatMessage("setBackTestParameters")}
        className={style.Card}
      >
        <Space className={style.FullWidth} size={25}>
          <Space>
            <p>{formatMessage("startDate")}：</p>
            <TradingDatePicker value={formData.startDate} disabled />
          </Space>
          <Space>
            <p>{formatMessage("endDate")}：</p>
            <TradingDatePicker
              value={formData.endDate}
              onChange={onUpdate("endDate")}
              disabledDate={endDisabledDate}
              className={
                startDateEqualEndDate ? style.ErrorDatePicker : undefined
              }
            />
            {startDateEqualEndDate && (
              <Tooltip title={formatMessage("startDateEqualEndDateErrorTip")}>
                <ExclamationCircleFilled className={style.ErrorIcon} />
              </Tooltip>
            )}
          </Space>

          <Space>
            <p>{formatMessage("performanceBenchmark")}：</p>
            <BenchmarkSelect
              value={formData.benchmarkId}
              onChange={performanceBenchmarkSelectOnChange}
              returnDataType="object"
            />
          </Space>

          <Space>
            <p>{formatMessage("initScale")}：</p>
            <NumberInput
              type="THOUSANDTH"
              min={10000}
              max={10000000000}
              precision={2}
              addonAfter={formatMessage("YUAN")}
              className={style.ScaleInput}
              value={formData.initScale}
              onChange={onUpdate("initScale")}
            />
          </Space>

          <Space>
            <Button
              disabled={
                buttonDisabled || startDateEqualEndDate || props.disabled
              }
              onClick={startBackTesting}
              type="primary"
            >
              {formatMessage("startBackTest")}
            </Button>
            <SaveScheme
              disabled={startDateEqualEndDate || props.disabled}
              onOk={onSaveScheme}
              id={formData?.id}
            />
          </Space>
        </Space>
      </Card>
    </>
  );
});
