import { FormatXMLElementFn, PrimitiveType, Options } from "intl-messageformat";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export type FormatMessageFunc = (
  id: string,
  values?: Record<
    string,
    PrimitiveType | FormatXMLElementFn<string, string> | JSX.Element
  >,
  opts?: Options
) => string;

export function useFormatMessage(): FormatMessageFunc {
  const intl = useIntl();
  return useCallback(
    (
      id: string,
      values?: Record<
        string,
        PrimitiveType | FormatXMLElementFn<string, string> | JSX.Element
      >,
      opts?: Options
    ): string => {
      if (!id) return "";
      return intl.formatMessage({ id }, values, opts) as string;
    },
    [intl]
  );
}
