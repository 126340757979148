import { getWithCache } from "@/util/axios";
import { PromiseType } from "@/model/promise";
import { CategoryResponse } from "@/model/entities";
import { sectorCategories } from "@/model/sectorCategory";

export const getRiskFreeRates = () =>
  getWithCache("/risk-free-rates", undefined, {
    config: {
      showLoading: true,
    },
  });

export const getBenchmarks = () =>
  getWithCache("/benchmarks/mine", undefined, {
    config: {
      showLoading: true,
    },
  });

// 获取资产大类
export const getCategory = (): PromiseType<CategoryResponse[]> =>
  getWithCache("/openfunds/category", undefined, {
    config: {
      showLoading: true,
    },
  });

export const getSectorCategories = (): PromiseType<sectorCategories> =>
  getWithCache("/sector-categories");

//获取大类对应的基准
export const getCategoryToBenchmark = (): PromiseType<Record<string,string>> =>
getWithCache("/asset/analyze/category2benchmark");