import { map, prop, set, size, sumBy, update } from "lodash/fp";
import React, { useMemo, useRef, useState } from "react";
import { useCreation, useMemoizedFn, useSize } from "ahooks";
import { Button, Table, TableProps, Timeline } from "antd";
import cn from "classnames";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { useFormatMessage } from "@/util/formatMessage";
import { isEmpty } from "lodash/fp";
import EmptyContent from "@/components/emptyContent";
import { Updater } from "../interface";
import { SummaryNode } from "../../../manualCreatePortfolio/components/fundWeightTable";
import style from "../../../manualCreatePortfolio/components/index.module.less";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { factorsFormatter } from "@/constant/factorFormatter";
import { SliderAndInput } from "@/components/portfolioCompoents/fundListTable";
import {
  Asset,
  ManualCreatePortfolio,
} from "@/views/portfolioManage/manualCreatePortfolio/constant";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const useGetFundWeightTableColumns = (
  onChange: (updater: (assets: Asset[]) => Asset[]) => any,
  originData: Asset[]
) => {
  const formatMessage = useFormatMessage();
  const onChangeAssetWeight = useMemoizedFn(
    (index: number) => (value: number | null) =>
      onChange(update(index, set("weight", value)))
  );
  const backToNormal = useMemoizedFn(() =>
    onChange(
      mapIndexed((data: Asset, index: number) => ({
        ...data,
        weight: fastProp("weight")(fastNth(index)(originData)),
      }))
    )
  );
  return useCreation<TableProps<any>["columns"]>(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: formatMessage("fundName"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
        fixed: "left",
      },
      {
        key: "code",
        dataIndex: "code",
        title: formatMessage("fundCode"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
        fixed: "left",
      },
      {
        key: "investType",
        dataIndex: "investType",
        title: formatMessage("policyType"),
      },
      {
        key: "netValue",
        dataIndex: "netValue",
        width: 150,
        title: formatMessage("latestNetUnitValue"),
        render: (netValue: number) => (
          <div>{factorsFormatter.netValue(netValue)}</div>
        ),
      },
      {
        key: "tradingDay",
        dataIndex: "tradingDay",
        title: formatMessage("netValueStartAndEndDate"),
        render: (_, record) => {
          return (
            <div>
              {fastProp("netValueStartDate")(record) || "--"}{" "}
              {formatMessage("to")} {_ || "--"}
            </div>
          );
        },
      },
      {
        key: "weight",
        dataIndex: "weight",
        width: 250,
        title: (
          <div>
            {formatMessage("positionWeight")}

            <Button
              size="small"
              onClick={backToNormal}
              className={style.EqualWeightButton}
            >
              恢复默认
            </Button>
          </div>
        ),
        render: (_, record, index) => (
          <SliderAndInput
            value={record.weight}
            onChange={onChangeAssetWeight(index)}
          />
        ),
      },
    ],
    [backToNormal, formatMessage, onChangeAssetWeight]
  );
};

export default React.memo<{
  originData: ManualCreatePortfolio[];
  data: ManualCreatePortfolio[];
  onUpdate: (index: number) => (updater: Updater) => any;
  errorField?: Record<string, any>;
}>(({ originData, data, onUpdate, errorField }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const dataSource = useMemo(
    () => fastProp("weights")(fastNth(activeIndex)(data)),
    [activeIndex, data]
  );
  const originDataSource = useMemo<Asset[]>(
    () => fastProp("weights")(fastNth(activeIndex)(originData)),
    [activeIndex, originData]
  );
  const formatMessage = useFormatMessage();
  const dates = useCreation<string[]>(() => map("turnoverDate")(data), [data]);
  const tableRef = useRef(null);
  const tableSize = useSize(tableRef);
  const indexUpdater = useCreation(
    () => onUpdate(activeIndex),
    [onUpdate, activeIndex]
  );
  const columns = useGetFundWeightTableColumns(indexUpdater, originDataSource);
  const timeLineStyle = useCreation(
    () => ({
      height: (tableSize?.height || 45) - 45,
    }),
    [tableSize?.height]
  );

  const scroll = useCreation(
    () => (size(dataSource) > 10 ? { y: 407, x: "max-content" } : undefined),
    [dataSource]
  );
  const positionWeight = useCreation(
    () => sumBy("weight")(dataSource),
    [dataSource]
  );

  return isEmpty(data) ? (
    <EmptyContent message={formatMessage("noData")} />
  ) : (
    <div className={style.DisplayContainer}>
      <div className={style.DisplayTime}>
        <h3 className={style.DisplayTimeTitle}>
          {formatMessage("openPosition")} / {formatMessage("theWarehouse")}
        </h3>
        <Timeline className={style.DisplayTimeLine} style={timeLineStyle}>
          {mapIndexed((date: string, index: number) => (
            <Timeline.Item
              key={date}
              className={cn({
                [style.FirstAsset]: index === 0,
                [style.ActiveAsset]: activeIndex === index,
                [style.NotActiveAsset]: activeIndex !== index && index !== 0,
              })}
            >
              <span onClick={() => setActiveIndex(index)}>
                {date} &nbsp;&nbsp;{" "}
                {prop(index)(errorField) && (
                  <ExclamationCircleOutlined
                    className={style.ExclamationCircleOutlined}
                  />
                )}
              </span>
            </Timeline.Item>
          ))(dates)}
        </Timeline>
      </div>
      <div className={style.DisplayTable}>
        {!isEmpty(dataSource) && (
          <Table
            className={style.FundWeightTable}
            rowKey="fundId"
            ref={tableRef}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={scroll}
            summary={() =>
              !isEmpty(dataSource) && (
                <Table.Summary fixed>
                  <SummaryNode
                    positionWeight={positionWeight}
                    size={size(dataSource)}
                  />
                </Table.Summary>
              )
            }
          />
        )}
      </div>
    </div>
  );
});
