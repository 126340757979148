import {
  deleteCustomerAccountApi,
  getCustomerBasicInfo,
  getCustomerInfo,
  getCustomerListApi,
  getCustomerSummary,
  getCustomerVolatility,
  createAsset,
  onAddCustomerApi,
  onSaveCustomerInfo,
} from "@/api/customerList";
import {
  AddAssetInfoProp,
  CustomerInfoProp,
  CustomerVolatilityRequestParam,
  SaveCustomerParam,
} from "@/model/customer";
import createSocketThunk from "@/util/createSocketThunk";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { TaskData } from "@/util/socket";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { set, isEmpty, isNil } from "lodash/fp";
import { clearCustomerAccountAnalysisData } from "./customerAccountAnalysis";

export const fetchCustomerList = createAsyncThunk(
  "customer/customerList",
  async (_, { dispatch }) => {
    dispatch(clearCustomerInfo());
    dispatch(clearCustomerAccountAnalysisData());
    const response = await getCustomerListApi();
    return response;
  }
);

export const fetchCustomerBasicInfo = avoidMultiRequestActionThunk<void>(
  "customerList.customerBasicInfo",
  createAsyncThunk("customer/customerBasicInfo", async () => {
    const response = await getCustomerBasicInfo();
    return response;
  })
);

export const fetchCustomerVolatility =
  avoidMultiRequestActionThunk<CustomerVolatilityRequestParam>(
    (data) => {
      const { queryType, sortOrder } = data;
      return `customerList.customerVolatility.${queryType}.${sortOrder}`;
    },
    createAsyncThunk("customer/customerVolatility", async (params) => {
      const response = await getCustomerVolatility(params);
      return response;
    })
  );

export const fetchCustomerSummary = avoidMultiRequestActionThunk<void>(
  "customerList.customerSummary",
  createAsyncThunk("customer/customerSummary", async () => {
    const response = await getCustomerSummary();
    return response;
  })
);

export const deleteCustomerAccount = createAsyncThunk(
  "customer/delete",
  async (id: number, { dispatch }) => {
    const response = await deleteCustomerAccountApi(id);
    dispatch(fetchCustomerList());
    return response;
  }
);

export const saveCustomerInfo = createSocketThunk(
  "createCustomer/saveCustomerInfo",
  "FINISH_UPLOAD_CUSTOMER_PORTFOLIO",
  (params: SaveCustomerParam) => {
    return onSaveCustomerInfo(params);
  }
);

export const fetchCustomerInfo = avoidMultiRequestActionThunk<number>(
  "customerList.fetchCustomerInfo",
  createAsyncThunk("customer/fetchCustomerInfo", async (id) => {
    const response = await getCustomerInfo(id);
    return response;
  })
);

export const saveCustomerBasicInfo = createAsyncThunk(
  "customerList/update",
  async (params: CustomerInfoProp) => {
    const response = await onAddCustomerApi(params);
    return response;
  }
);

export const onSaveAssetInfo = createAsyncThunk(
  "customerList/addAsset",
  async (params: AddAssetInfoProp) => {
    const response = await createAsset(params);
    return response;
  }
);

interface initCustomers {
  customerList: Record<string, any>[] | undefined;
  customerBasicInfo: Record<string, any>;
  customerVolatility: Record<string, any>;
  customerSummary: Record<string, any>;
  saveCustomerInfoProgress?: (TaskData & { id: number }) | undefined;
  customerDetail: Record<number, CustomerInfoProp>;
}

const initialState: initCustomers = {
  customerList: undefined,
  customerBasicInfo: {},
  customerVolatility: {},
  customerSummary: {},
  customerDetail: {},
};

const saveCustomerInfoFinish: string = saveCustomerInfo.finish.type;
const saveCustomerInfoError: string = saveCustomerInfo.error.type;
const saveCustomerInfoProgress: string = saveCustomerInfo.progress.type;

const customerListSlice = createSlice({
  name: "customerList",
  initialState,
  reducers: {
    clearCustomerInfo: (state) => {
      state.customerList = undefined;
      state.customerBasicInfo = {};
      state.customerVolatility = {};
      state.customerSummary = {};
    },
    clearCustomerInfoProgress: (state) => {
      state.saveCustomerInfoProgress = undefined;
    },
  },
  extraReducers: {
    [fetchCustomerList.fulfilled.type]: (state, action) => {
      state.customerList = action.payload;
    },
    [fetchCustomerBasicInfo.fulfilled.type]: (state, action) => {
      state.customerBasicInfo = action.payload;
    },
    [fetchCustomerVolatility.fulfilled.type]: (state, action) => {
      const {
        meta: {
          arg: { queryType, sortOrder },
        },
      } = action;
      state.customerVolatility = set(`${queryType}.${sortOrder}`)(
        action.payload
      )(state.customerVolatility);
    },
    [fetchCustomerSummary.fulfilled.type]: (state, action) => {
      state.customerSummary = action.payload;
    },
    [saveCustomerInfoFinish]: (state, action) => {
      if (!isEmpty(action.payload.view) && !isNil(state.customerList)) {
        if (state.customerList.some((v) => v?.id === action.meta?.arg?.id)) {
          state.customerList = state.customerList.map((customerInfo) => {
            if (customerInfo.id === action.meta?.arg?.id) {
              return {
                ...customerInfo,
                ...(action?.payload.view || {}),
              };
            }
            return customerInfo;
          });
        } else {
          state.customerList.unshift(action.payload.view);
        }
      }
      state.saveCustomerInfoProgress = {
        ...action.meta,
        id: action.meta?.arg?.customerId,
      };
    },
    [saveCustomerInfoError]: (state, action) => {
      state.saveCustomerInfoProgress = {
        ...action.error,
        id: action.meta?.arg?.customerId,
      };
    },
    [saveCustomerInfoProgress]: (state, action) => {
      state.saveCustomerInfoProgress = {
        ...action.payload,
        id: action.meta?.arg?.customerId,
      };
    },
    [fetchCustomerInfo.fulfilled.type]: (state, action) => {
      state.customerDetail[action.meta?.arg] = action.payload;
    },
  },
});

export const { clearCustomerInfo, clearCustomerInfoProgress } =
  customerListSlice.actions;

export default customerListSlice.reducer;
