import {
  toFixedNumber,
  formatPercentage,
  numberColor,
  parseToThousandth,
} from "@/util/numberFormatter";
import { useMemo } from "react";

type ColorNumberProps = {
  value: number;
  formatValue?: (v: number) => string | number;
  formatter?:
    | "percentage"
    | "fixedNumber"
    | "parseToThousandth"
    | "absolutePercentage";
  className?: string;
};
const ColorNumber = ({
  value,
  formatValue,
  formatter,
  className,
}: ColorNumberProps): JSX.Element => {
  const color = useMemo(() => numberColor(value), [value]);
  const showValue = useMemo(() => {
    if (formatter === "percentage")
      return formatPercentage(value, { digit: 2 });
    if (formatter === "absolutePercentage")
      return formatPercentage(Math.abs(value), { digit: 2 });
    if (formatter === "fixedNumber") return toFixedNumber(2)(value);
    if (formatter === "parseToThousandth") return parseToThousandth(value);
    if (formatValue) return formatValue(value);
    return value;
  }, [value, formatValue, formatter]);
  return (
    <span className={className} style={{ color }}>
      {showValue}
    </span>
  );
};
export default ColorNumber;
