import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import { useFormatMessage } from "@/util/formatMessage";
import { useCreation, useMemoizedFn, useUpdateEffect } from "ahooks";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { PortfolioAnalysisProps } from "../..";
import PortfolioAnalysisSubtitle from "../../components/portfolioAnalysisSubtitle";
import { useGetStatisticRange } from "../../hooks";
import { analysisBasicInfoSelector } from "../../selectors";
import ErrorBoundary from "@/components/errorBoundary";
import { errorValidator } from "@/components/errorBoundary/constant";
import {
  Button,
  Popover,
  Radio,
  RadioChangeEvent,
  Table,
  TableProps as AntdTableProps,
} from "antd";
import {
  compact,
  flow,
  groupBy,
  join,
  keys,
  map,
  maxBy,
  min,
  minBy,
  prop,
  size,
  values,
} from "lodash/fp";
import { FROM_CREATION, RangeInterface } from "@/constant/statisticRange";
import {
  fastHas,
  fastNth,
  fastProp,
  mapIndexed,
  mapValuesIndexed,
  normalize,
} from "@/util/opt";
import { toFixedNumber } from "@/util/numberFormatter";
import style from "../index.module.less";
import { scaleLinear } from "d3-scale";
import { fundIdMapSelector } from "@/selectors/fund";
import SummaryCard from "../../components/summaryCard";
import RangePicker from "@/components/rangePicker";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { fetchFundCorrelation } from "@/store/portfolioAnalysis";
import { isEmpty, isNil } from "lodash";
import EmptyContent from "@/components/emptyContent";
import { getNatureDateAndTradingDate } from "@/util/processedDates";
import { getTableScroll } from "@/util/tableFormat";
import LoadingComponent from "@/components/LoadingComponent";
import { fundCorrelationResponse } from "@/model/portfolioAnalysis";
import { assetsType } from "@/model/portfolioList";

const customRange = "customRange";
const defaultRangePicker = {
  from: "",
  to: "",
};
const defaultCustomMessage = "custom";
const color: any = scaleLinear()
  .domain([-1, 0, 1])
  .range(["#2cb645", "#fff", "#ff3c01"] as any);
type TableProps = AntdTableProps<any>;
const useNeedTime = (id: string) => {
  const [needTime, setNeedTime] = useState(NaN);
  const { endDate } = useAppSelector((state) =>
    analysisBasicInfoSelector(state, id)
  );
  const assets = useAppSelector(
    prop(`portfolioAnalysis.${id}.assetPortfoliosPositionDetail.${endDate}`)
  );
  useEffect(() => {
    if (fastHas("assets")(assets) && !isEmpty(fastProp("assets")(assets))) {
      setNeedTime(2);
    }
  }, [assets]);
  return useCreation(
    () => ({
      needTime,
    }),
    [needTime]
  );
};
export const useGetNetValueStart = (id: string) => {
  const basicInfo = useAppSelector((state) =>
    analysisBasicInfoSelector(state, id)
  );
  const { latestPosition, endDate } = basicInfo;
  const fundIdMap = useAppSelector(fundIdMapSelector);

  const fundData = useMemo(
    () => map((v: assetsType) => fastProp(v.fundId)(fundIdMap))(latestPosition),
    [fundIdMap, latestPosition]
  );
  const startDate = useMemo(
    () =>
      flow(maxBy("netValueStartDate"), fastProp("netValueStartDate"))(fundData),
    [fundData]
  );
  const netEndDate = useMemo(
    () => flow(minBy("netValueEndDate"), fastProp("netValueEndDate"))(fundData),
    [fundData]
  );
  return {
    startDate,
    netEndDate: min([endDate, netEndDate]),
  };
};
const useStatisticRange = (id: string, needTime: number) => {
  const { dispatch } = useContext(PortfolioAnalysisContext);
  const { endDate } = useAppSelector((state) =>
    analysisBasicInfoSelector(state, id)
  );
  const { startDate } = useGetNetValueStart(id);
  const calcStatisticRangeList = useGetStatisticRange(2);
  const statisticRangeList = useCreation(
    () =>
      calcStatisticRangeList(
        startDate,
        endDate,
        portfolioAnalysisStatisticRange
      ),
    [startDate, endDate, portfolioAnalysisStatisticRange]
  );

  const [range, setRange] = useState(FROM_CREATION);

  const onChangeRange = useMemoizedFn((e: RadioChangeEvent) => {
    setRange(e?.target.value);
  });
  useEffect(() => {
    if (range !== customRange) {
      dispatch(
        needTime,
        fetchFundCorrelation({
          portfolioId: id,
          section: range,
        })
      );
    }
  }, [dispatch, id, needTime, range]);
  return useMemo(
    () => ({
      statisticRangeList,
      range,
      onChangeRange,
      minDate: startDate,
      maxDate: endDate,
    }),
    [endDate, onChangeRange, range, startDate, statisticRangeList]
  );
};

const useRangePicker = () => {
  const [rangePicker, setRangePicker] = useState(defaultRangePicker);

  const rangePickerValue = useCreation(
    () => [rangePicker.from, rangePicker.to],
    [rangePicker]
  );

  const onChangeRangePicker = useMemoizedFn((value: string[]) => {
    setRangePicker({
      from: fastNth(0)(value),
      to: fastNth(1)(value),
    });
  });
  const { processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  const customRangeDiff = useMemo(() => {
    const [from, to] = rangePickerValue;
    const [, tradingDate] = getNatureDateAndTradingDate(
      from,
      to
    )(processedTradingDates);
    return tradingDate;
  }, [rangePickerValue, processedTradingDates]);

  return useMemo(
    () => ({
      rangePicker,
      rangePickerValue,
      onChangeRangePicker,
      customRangeDiff,
    }),
    [rangePicker, customRangeDiff, onChangeRangePicker, rangePickerValue]
  );
};
const useErrorKey = (needTime: number) => {
  const { runningTime } = useContext(PortfolioAnalysisContext);
  const [lessTwoDay, setLessTwoDay] = useState(false);

  const errorKey = useCreation(() => {
    if (lessTwoDay) return "lessPositionData";
    if (runningTime < 1) return "lessOneDay";
    return errorValidator.lessPositionData(2, runningTime);
  }, [lessTwoDay, runningTime]);

  const disabledCustom = useCreation(
    () => (!!errorKey && !lessTwoDay) || isNaN(needTime),
    [errorKey, lessTwoDay, needTime]
  );

  return useMemo(
    () => ({
      setLessTwoDay,
      errorKey,
      disabledCustom,
    }),
    [disabledCustom, errorKey, setLessTwoDay]
  );
};
const useTableDataSource = (
  id: string,
  range: string,
  rangePickerValue: string[]
) => {
  const formatMessage = useFormatMessage();
  const sectionId = useCreation(() => {
    if (range !== customRange) return range;
    return join("-")(rangePickerValue);
  }, [range, rangePickerValue]);
  const prevFundCorrelation = useAppSelector(
    prop(`portfolioAnalysis.${id}.fundCorrelation.${sectionId}`)
  );
  const [fundCorrelation, setFundCorrelation] =
    useState<fundCorrelationResponse>();
  useEffect(() => {
    if (range !== customRange) {
      setFundCorrelation(prevFundCorrelation);
    }
  }, [prevFundCorrelation, range]);
  const onChangeFundCorrelation = useMemoizedFn(() => {
    setFundCorrelation(prevFundCorrelation);
  });
  const fundIdMap = useAppSelector(fundIdMapSelector);
  const correlation = useMemo(
    () => fastProp("correlation")(fundCorrelation),
    [fundCorrelation]
  );
  const text = useMemo(
    () => fastProp("text")(fundCorrelation),
    [fundCorrelation]
  );
  const xFundCodeGroup = useCreation(
    () => groupBy("xFundId")(correlation),
    [correlation]
  );
  const yFundCodeGroup = useCreation(
    () => groupBy("yFundId")(correlation),
    [correlation]
  );
  const dataSource = useCreation(
    () =>
      flow(
        mapValuesIndexed((v: any, xFundId: string) => ({
          code: xFundId,
          key: xFundId,
          ...normalize("xFundId")(yFundCodeGroup[xFundId]),
          ...normalize("yFundId")(v),
        })),
        values
      )(xFundCodeGroup),
    [xFundCodeGroup, yFundCodeGroup]
  );
  const columns = useCreation<TableProps["columns"]>(
    () => [
      {
        title: formatMessage("fundName"),
        dataIndex: "code",
        key: "code",
        ellipsis: true,
        width: 138,
        fixed: "left",
        render: (v) => prop([v, "name"])(fundIdMap),
      },
      ...flow(keys, (data) =>
        mapIndexed((v: string, idx: number) => ({
          title: prop([v, "name"])(fundIdMap),
          dataIndex: v,
          key: v,
          width: 100,
          align: "center",
          render: (v: any, _: any, index: number) => {
            if (index < idx) return "";
            const value = fastProp("value")(v);
            const backgroundColor = color(value);
            return (
              <div className={style.RenderCell} style={{ backgroundColor }}>
                {toFixedNumber(2)(value)}
              </div>
            );
          },
        }))(data)
      )(yFundCodeGroup),
      {
        className: style.HiddenCell,
      },
    ],
    [formatMessage, fundIdMap, yFundCodeGroup]
  );
  const summaryText = useCreation(() => {
    const { min, max } = text || {};
    return {
      ...text,
      min: {
        value: toFixedNumber(2)(fastProp("value")(min)),
        xFundId: prop([fastProp("xFundId")(min), "name"])(fundIdMap),
        yFundId: prop([fastProp("yFundId")(min), "name"])(fundIdMap),
      },
      max: {
        value: toFixedNumber(2)(fastProp("value")(max)),
        xFundId: prop([fastProp("xFundId")(max), "name"])(fundIdMap),
        yFundId: prop([fastProp("yFundId")(max), "name"])(fundIdMap),
      },
    };
  }, [fundIdMap, text]);
  const scroll = useCreation(
    () =>
      getTableScroll({
        dataSource,
        columns,
        maxYScroll: 300,
      }),
    [columns, dataSource]
  );

  return useMemo(
    () => ({
      dataSource,
      columns,
      scroll,
      summaryText,
      onChangeFundCorrelation,
    }),
    [columns, dataSource, onChangeFundCorrelation, scroll, summaryText]
  );
};
export default React.memo<PortfolioAnalysisProps>((props) => {
  const { id } = props;
  const { needTime } = useNeedTime(id);
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();
  const [customMessage, setCustomMessage] = useState(
    formatMessage(defaultCustomMessage)
  );
  const [popoverVisible, setPopoverVisible] = useState(false);
  const {
    rangePicker,
    rangePickerValue,
    onChangeRangePicker,
    customRangeDiff,
  } = useRangePicker();
  const { statisticRangeList, range, onChangeRange, minDate, maxDate } =
    useStatisticRange(id, needTime);
  const { dataSource, columns, summaryText, scroll, onChangeFundCorrelation } =
    useTableDataSource(id, range, rangePickerValue);

  const { setLessTwoDay, errorKey, disabledCustom } = useErrorKey(needTime);
  useUpdateEffect(() => {
    if (range !== customRange) {
      setLessTwoDay(false);
    }
  }, [setLessTwoDay, range]);
  const onCalculate = useMemoizedFn(() => {
    setCustomMessage(join("-")(rangePickerValue));
    setPopoverVisible(false);
    setLessTwoDay(false);
    if (!isNil(customRangeDiff) && customRangeDiff < 2) {
      setLessTwoDay(true);
    }
    if (rangePicker.from !== rangePicker.to) {
      dispatch(
        fetchFundCorrelation({
          portfolioId: id,
          ...rangePicker,
        })
      ).finally(() => onChangeFundCorrelation());
    }
  });
  const errorTip = useMemo(
    () =>
      customRangeDiff &&
      customRangeDiff > 1 &&
      customRangeDiff < 10 &&
      range === customRange,
    [customRangeDiff, range]
  );
  return (
    <LoadingComponent actions={fetchFundCorrelation}>
      <PortfolioAnalysisSubtitle name={formatMessage("correlationNalysis")} />
      <div className={style.FundCorrelationRange}>
        <Radio.Group onChange={onChangeRange} buttonStyle="solid" value={range}>
          {map((v: RangeInterface) => (
            <Radio.Button value={v.id} key={v.id}>
              {formatMessage(v.message)}
            </Radio.Button>
          ))(statisticRangeList)}
          <Popover
            visible={popoverVisible}
            trigger="click"
            placement="bottom"
            onOpenChange={setPopoverVisible}
            getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            content={
              <div className={style.PopoverInner}>
                <RangePicker
                  startDateTitle={formatMessage("startDate")}
                  endDateTitle={formatMessage("endDate")}
                  rangeSeparator=""
                  value={rangePickerValue}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={onChangeRangePicker}
                  className={style.PopoverInnerRangePicker}
                />
                <Button
                  disabled={size(compact(rangePickerValue)) !== 2}
                  onClick={onCalculate}
                  type="primary"
                >
                  {formatMessage("Calculate")}
                </Button>
              </div>
            }
          >
            <Radio.Button
              disabled={disabledCustom}
              value={customRange}
              key={customRange}
            >
              {customMessage}
              &nbsp;
              {range === customRange ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownOutlined />
              )}
            </Radio.Button>
          </Popover>
        </Radio.Group>
        {errorTip ? (
          <p className={style.FundCorrelationErrorTip}>
            {formatMessage("fundCorrelationErrorTip")}
          </p>
        ) : null}
      </div>
      <ErrorBoundary errKey={errorKey}>
        {!isEmpty(dataSource) ? (
          <>
            <Table
              className={style.FundCorrelationTable}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              bordered
              scroll={scroll}
            />
            <SummaryCard
              summaryText={formatMessage("FundCorrelationSummaryText", {
                startDate: fastProp("startDate")(summaryText),
                currentDate: fastProp("currentDate")(summaryText),
                maxXFund: prop("max.xFundId")(summaryText),
                maxYFund: prop("max.yFundId")(summaryText),
                maxValue: prop("max.value")(summaryText),
                minXFund: prop("min.xFundId")(summaryText),
                minYFund: prop("min.yFundId")(summaryText),
                minValue: prop("min.value")(summaryText),
              })}
            />
          </>
        ) : (
          <EmptyContent className={style.EmptyContent}>
            <strong>{formatMessage("noData")}</strong>
          </EmptyContent>
        )}
      </ErrorBoundary>
    </LoadingComponent>
  );
});
