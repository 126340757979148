import { checkNeedTime } from "@/providers/portfolioAnalysisProvider/provider";

const errorMessage = {
  lessOneWeek: {
    id: "lessOneWeek",
    message: "lessOneWeek",
  },
  lessOneDay: {
    id: "lessOneDay",
    message: "lessOneDay",
  },
  lessTwoTradingDate: {
    id: "lessTwoTradingDate",
    message: "lessTwoTradingDate",
  },
  less10TradingDates: {
    id: "less10TradingDates",
    message: "less10TradingDates",
  },
  less15TradingDates: {
    id: "less15TradingDates",
    message: "less15TradingDates",
  },
  allLess15TradingDates: {
    id: "allLess15TradingDates",
    message: "allLess15TradingDates",
  },
  lessOneHundredTradingDate: {
    id: "lessOneHundredTradingDate",
    message: "lessOneHundredTradingDate",
  },
  lessPositionData: {
    id: "lessPositionData",
    message: "lessPositionData",
  },
};
export default errorMessage;

const validator =
  (errorKey: string) => (needTime: number, runningTime: number) => {
    return checkNeedTime(needTime, runningTime) ? errorKey : null;
  };
export const errorValidator = {
  lessOneWeek: validator(errorMessage.lessOneWeek.id),
  lessOneDay: validator(errorMessage.lessOneDay.id),
  lessTwoTradingDate: validator(errorMessage.lessTwoTradingDate.id),
  less10TradingDates: validator(errorMessage.less10TradingDates.id),
  less15TradingDates: validator(errorMessage.less15TradingDates.id),
  lessOneHundredTradingDate: validator(
    errorMessage.lessOneHundredTradingDate.id
  ),
  lessPositionData: validator(errorMessage.lessPositionData.id),
};
