import React, { useState } from "react";
import { Cascader } from "antd";
import { useMemoizedFn } from "ahooks";
import { SaveOrUpdateInvestPlanParam } from "@/model/customer";
import { fastNth, fastProp } from "@/util/opt";
import { isNil } from "lodash/fp";
import { useGetFrequencyOptions } from "../hooks";

export default React.memo(
  ({
    value,
    onChange,
  }: {
    value?: SaveOrUpdateInvestPlanParam["frequency"];
    onChange?: (
      v: SaveOrUpdateInvestPlanParam["frequency"] | undefined
    ) => void;
  }) => {
    const frequencyOptions = useGetFrequencyOptions();
    const [cascaderVal, setCascaderVal] = useState(
      value
        ? ([
            fastProp("number")(value) === 2
              ? "WEEK_2"
              : fastProp("period")(value),

            ...(fastProp("period")(value) === "DAY"
              ? []
              : [fastProp("dayOf")(value)]),
          ] as (string | number)[])
        : undefined
    );

    const handleChange = useMemoizedFn(
      (changeVal: (string | number)[] | undefined) => {
        setCascaderVal(changeVal);
        if (isNil(changeVal)) onChange?.(undefined);
        else if (fastNth(0)(changeVal) === "WEEK_2") {
          onChange?.({
            period: "WEEK",
            number: 2,
            dayOf: fastNth(1)(changeVal),
          });
        } else {
          onChange?.({
            period: fastNth(0)(
              changeVal
            ) as SaveOrUpdateInvestPlanParam["frequency"]["period"],
            number: 1,
            dayOf: fastNth(1)(changeVal) || 1,
          });
        }
      }
    );

    return (
      <Cascader
        options={frequencyOptions}
        value={cascaderVal}
        onChange={handleChange}
      />
    );
  }
);
