import React, { useMemo } from "react";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { Card, Col, Row, Space } from "antd";
import cn from "classnames";
import earningsRiskIcon from "@/assets/earningsRisk.png";
import investmentStyleIcon from "@/assets/investmentStyle.png";
import fundManagerIcon from "@/assets/fundManager.png";
import fundCompanyIcon from "@/assets/fundCompany.png";
import style from "../index.module.less";
import { fastProp, normalize } from "@/util/opt";
import TaskResult from "@/components/taskResult";
import { useAppSelector } from "@/hooks/redux";
import mapper from "../mapper";
import {
  fixedNumber,
  formatNil,
  formatNilToZero,
} from "@/util/numberFormatter";
import ColorNumber from "@/components/colorNumber";
import { isEmpty, join, map, size, prop } from "lodash/fp";
import getAnnualReturns from "@/util/quant/annualReturns";
import getSharpeRatio from "@/util/quant/sharpeRatio";
import getMaxDrawdown from "@/util/quant/maxDrawdown";
import getAnnualVolatility from "@/util/quant/annualVolatility";
import { getYearByStartAndEnd } from "@/util/business-core/tradingDate";
import { isOther } from "@/views/compareManage/fundCompare/constant";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { normalizeDailyReturnsMap } from "@/util/transformer";

const defaultManagerData = [
  {
    id: "default",
    name: "--",
    workTime: "",
    rating: 0,
    scale: 0,
    num: 0,
    ratingNum: 0,
    industryPreference: "--",
  },
];

const earningsRiskFactors = {
  annualReturns: "Fund_Return_Annual",
  sharpeRatio: "Fund_Sharpe_Ratio",
  maxDrawdown: "Fund_Max_Drawdown_LY", // 近一年最大回撤
  wave: "Fund_Vol_12M",
};

const formatFastProp = (key: string) => (object?: { [x: string]: any }) => {
  return formatNil(prop(key)(object));
};
const compareBenchmark = (
  dailyValue: any,
  benchmarkValue: any,
  benchmarkName = "--",
  formatMessage: FormatMessageFunc
) => {
  if (dailyValue > benchmarkValue)
    return formatMessage("SuperioroBenchmark", { benchmark: benchmarkName });
  return formatMessage("WorseBenchmark", { benchmark: benchmarkName });
};
export const maxDrawdownCompareBenchmark = (
  dailyValue: any,
  benchmarkValue: any,
  benchmarkName = "--",
  formatMessage: FormatMessageFunc
) => {
  if (dailyValue > benchmarkValue)
    return formatMessage("WorseBenchmark", { benchmark: benchmarkName });
  return formatMessage("SuperioroBenchmark", { benchmark: benchmarkName });
};
const EarningsRisk = ({ data }: { data: any }): JSX.Element => {
  const {
    assetDailyReturn,
    benchmarkDailyReturn,
    factorRankAndSize,
    assetDailyReturnAll,
    benchmarkName,
  } = data || {};
  const { dailyReturns, dates } = assetDailyReturn || {};
  const { dailyReturns: benchmarkDaily, dates: benchmarkDates } =
    benchmarkDailyReturn || {};
  const { dailyReturns: dailyReturnsAll, dates: dailyReturnsAllDates } =
    assetDailyReturnAll || {};

  const benchmarkDailyReturnMap = normalizeDailyReturnsMap(
    benchmarkDates,
    benchmarkDaily
  );
  //基准近一年收益
  const benchmark1YDailyReturn = map<string, number>((date) =>
    formatNilToZero(fastProp(date)(benchmarkDailyReturnMap))
  )(dates);
  const annualReturns = getAnnualReturns(dailyReturns);
  const benchmarkAnnualReturns = getAnnualReturns(benchmark1YDailyReturn);
  const riskFreeRate = useAppSelector(prop("entities.riskFreeRate"));
  const fundRiskFreeRate = map<string, number>((date) =>
    formatNilToZero(fastProp(date)(riskFreeRate))
  )(dailyReturnsAllDates);
  const benchmarkFundRiskFreeRate = map<string, number>((date) =>
    formatNilToZero(fastProp(date)(riskFreeRate))
  )(benchmarkDates);
  const sharpeRatio = getSharpeRatio(dailyReturnsAll, fundRiskFreeRate);
  const benchmarkSharpeRatio = getSharpeRatio(
    benchmarkDaily,
    benchmarkFundRiskFreeRate
  );
  const maxDrawdown = getMaxDrawdown(dailyReturns);

  const benchmarkMaxDrawdown = getMaxDrawdown(benchmark1YDailyReturn);
  const annualVolatility = getAnnualVolatility(dailyReturns);
  const benchmarkAnnualVolatility = getAnnualVolatility(benchmark1YDailyReturn);
  const factorRankAndSizeMap = useMemo(
    () => normalize("factorId")(factorRankAndSize),
    [factorRankAndSize]
  );
  const formatMessage = useFormatMessage();
  return (
    <div className={style.FundSummaryItem}>
      <div className={cn(style.ItemHeader, style.RiskHeader)}>
        <img src={earningsRiskIcon} />
        <div className={style.ItemHeaderTitle}>
          <p>收益风险</p>
          <p className={style.ItemHeaderTitleName}>Earnings risk</p>
        </div>
      </div>
      <div
        className={cn(style.FundSummaryItemContent, style.RiskBackgroundIcon)}
      >
        <Space size="large" direction="vertical">
          <span>
            {formatMessage("annualReturnsDescribe", {
              ratio: (
                <ColorNumber
                  value={annualReturns}
                  formatter="percentage"
                  className={style.colorNUmber}
                />
              ),
              compareBenchmark: compareBenchmark(
                annualReturns,
                benchmarkAnnualReturns,
                benchmarkName,
                formatMessage
              ),
              rank: `${formatFastProp(
                `${earningsRiskFactors.annualReturns}.rank`
              )(factorRankAndSizeMap)}/${formatFastProp(
                `${earningsRiskFactors.annualReturns}.size`
              )(factorRankAndSizeMap)}`,
            })}
          </span>
          <span>
            {formatMessage("annualVolatilityDescribe", {
              ratio: (
                <ColorNumber
                  value={annualVolatility as number}
                  formatter="percentage"
                />
              ),
              compareBenchmark: maxDrawdownCompareBenchmark(
                annualVolatility,
                benchmarkAnnualVolatility,
                benchmarkName,
                formatMessage
              ),
              rank: `${formatFastProp(`${earningsRiskFactors.wave}.rank`)(
                factorRankAndSizeMap
              )}/${formatFastProp(`${earningsRiskFactors.wave}.size`)(
                factorRankAndSizeMap
              )}`,
            })}
          </span>
          <span>
            {formatMessage("sharpeRatioDescribe", {
              ratio: (
                <ColorNumber
                  value={sharpeRatio as number}
                  formatter="fixedNumber"
                />
              ),
              compareBenchmark: compareBenchmark(
                sharpeRatio,
                benchmarkSharpeRatio,
                benchmarkName,
                formatMessage
              ),
              rank: `${formatFastProp(
                `${earningsRiskFactors.sharpeRatio}.rank`
              )(factorRankAndSizeMap)}/${formatFastProp(
                `${earningsRiskFactors.sharpeRatio}.size`
              )(factorRankAndSizeMap)}`,
            })}
          </span>
          <span>
            {formatMessage("maxDrawdownDescribe", {
              ratio: (
                <ColorNumber
                  value={maxDrawdown as number}
                  formatter="percentage"
                />
              ),
              compareBenchmark: maxDrawdownCompareBenchmark(
                maxDrawdown,
                benchmarkMaxDrawdown,
                benchmarkName,
                formatMessage
              ),
              rank: `${formatFastProp(
                `${earningsRiskFactors.maxDrawdown}.rank`
              )(factorRankAndSizeMap)}/${formatFastProp(
                `${earningsRiskFactors.maxDrawdown}.size`
              )(factorRankAndSizeMap)}`,
            })}
          </span>
        </Space>
      </div>
    </div>
  );
};
const InvestmentStyle = ({ data }: { data: any }): JSX.Element => {
  const formatMessage = useFormatMessage();
  return (
    <div className={style.FundSummaryItem}>
      <div className={cn(style.ItemHeader, style.InvestmentHeader)}>
        <img src={investmentStyleIcon} />
        <div className={style.ItemHeaderTitle}>
          <p>投资风格</p>
          <p className={style.ItemHeaderTitleName}>Investment style</p>
        </div>
      </div>
      <div
        className={cn(
          style.FundSummaryItemContent,
          style.InvestmentBackgroundIcon
        )}
      >
        <Space size="large" direction="vertical">
          <span>
            {formatMessage("InvestmentStyleFirstParagraph", {
              type: formatFastProp("firstInvestType")(data),
              ratio: (
                <ColorNumber value={data?.stocktonav} formatter="percentage" />
              ),
            })}
          </span>
          <span>
            {formatMessage("InvestmentStyleSecondParagraph", {
              style: formatFastProp("fundStyle")(data),
              stabilityOfStyle: formatFastProp("stabilityOfStyle")(data),
            })}
          </span>
          <span>
            {formatMessage("InvestmentStyleThirdParagraph", {
              top3IndustryNames: join("、")(data?.top3IndustryNames),
              ratio: (
                <ColorNumber
                  value={data?.top3IndustryWeight}
                  formatter="percentage"
                />
              ),
            })}
          </span>
        </Space>
      </div>
    </div>
  );
};
// const dateDiff = (date: string[], endDate: string[]) => {
//   if (!date || isEmpty(date)) return "--";
//   const day = dayjs(endDate ? join("-")(endDate) : undefined);
//   const manageDate = dayjs(join("-")(date));
//   return day.diff(manageDate, "year");
// };
const FundManager = ({ data }: { data: any }): JSX.Element => {
  const dataSource = useMemo(
    () => (isEmpty(data) ? defaultManagerData : data),
    [data]
  );
  const dataSize = useMemo(() => size(dataSource), [dataSource]);
  const sectorCategoryMap = useAppSelector(sectorCategoriesMapSelector);
  const formatMessage = useFormatMessage();
  return (
    <div className={style.FundSummaryItem}>
      <div className={cn(style.ItemHeader, style.FundManagerHeader)}>
        <img src={fundManagerIcon} />
        <div className={style.ItemHeaderTitle}>
          <p>基金经理</p>
          <p className={style.ItemHeaderTitleName}>Fund manager</p>
        </div>
      </div>
      <div
        className={cn(
          style.FundSummaryItemContent,
          style.FundManagerBackgroundIcon
        )}
      >
        <Space direction="vertical">
          {map((v: any) => (
            <Space
              key={v?.id}
              size={dataSize > 1 ? "small" : "large"}
              direction="vertical"
            >
              <span>
                {formatMessage("FundManagerDescribe", {
                  name: formatFastProp("name")(v),
                  workTime:
                    getYearByStartAndEnd(v?.startDate, v?.endDate) || "--",
                  rating: formatFastProp("rating")(v),
                })}
              </span>
              <span>
                {formatMessage("FundManagerScale", {
                  scale: fixedNumber(v?.manageScale),
                  num: formatFastProp("manageNum")(v),
                  ratingNum:
                    v?.manageFundRating5 > 0
                      ? formatMessage("manageFundRating5", {
                          num: formatFastProp("manageFundRating5")(v),
                        })
                      : "",
                })}
              </span>
              <span>
                {formatMessage("FundManagerIndustryPreference", {
                  industryPreference: isOther(v?.industryPreference)
                    ? formatMessage(v?.industryPreference)
                    : formatMessage(
                        prop(`${v?.industryPreference}.name`)(sectorCategoryMap)
                      ),
                  ratio: (
                    <ColorNumber
                      value={v?.annualReturn}
                      formatter="percentage"
                    />
                  ),
                  maxDrawdown: (
                    <ColorNumber
                      value={v?.annualizedMaxDrawdown}
                      formatter="percentage"
                    />
                  ),
                })}
              </span>
            </Space>
          ))(dataSource)}
        </Space>
      </div>
    </div>
  );
};
const FundCompany = ({ data }: { data: any }): JSX.Element => {
  const formatMessage = useFormatMessage();
  return (
    <div className={style.FundSummaryItem}>
      <div className={cn(style.ItemHeader, style.FundCompanyHeader)}>
        <img src={fundCompanyIcon} />
        <div className={style.ItemHeaderTitle}>
          <p>基金公司</p>
          <p className={style.ItemHeaderTitleName}>Fund company</p>
        </div>
      </div>
      <div
        className={cn(
          style.FundSummaryItemContent,
          style.FundCompanyBackgroundIcon
        )}
      >
        <Space size="large" direction="vertical">
          <span>
            {formatMessage("FundCompanyDescribe", {
              name: formatFastProp("name")(data),
              year: formatFastProp("foundYear")(data),
              rating: formatFastProp("rating")(data),
            })}
          </span>
          <span>
            {formatMessage("FundCompanyScaleDescribe", {
              scale: fixedNumber(data.manageScale),
              rank: `${formatFastProp("rank")(data)}/${formatFastProp("size")(
                data
              )}`,
            })}
          </span>
        </Space>
      </div>
    </div>
  );
};
export default React.memo(({ fundId }: { fundId: string }): JSX.Element => {
  const formatMessage = useFormatMessage();
  const {
    fundSummaryProgress: task,
    fundReturnRiskSummary,
    fundPositionStyleSummary,
    fundManagerSummary,
    fundCompanySummary,
  } = useAppSelector((state) => mapper(state, fundId));
  return (
    <>
      <Card bordered={false} size="small">
        {task !== undefined && (
          <TaskResult task={task}>
            <div>
              <div className={style.Title}>{formatMessage("fundSummary")}</div>
              <Row gutter={8} wrap={false}>
                <Col span={6}>
                  <EarningsRisk data={fundReturnRiskSummary} />
                </Col>
                <Col span={6}>
                  <InvestmentStyle data={fundPositionStyleSummary} />
                </Col>
                <Col span={6}>
                  <FundManager data={fundManagerSummary} />
                </Col>
                <Col span={6}>
                  <FundCompany data={fundCompanySummary} />
                </Col>
              </Row>
            </div>
          </TaskResult>
        )}
      </Card>
    </>
  );
});
