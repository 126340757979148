import { Card, Row, Col, Table, Space } from "antd";
import { useCreation } from "ahooks";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import SectorCategoriesSelect from "@/components/sectorCategoriesSelect";
import {
  map,
  isEmpty,
  flow,
  every,
  reject,
  prop,
  join,
  first,
  keys,
} from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import style from "../../index.module.less";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fastNth, fastProp, floatEqual, getProp, normalize } from "@/util/opt";
import { defaultBenchmarkInfoSelector } from "../selectors";
import { REQUEST_FAILED, REQUEST_RESOLVED } from "@/constant/socket";
import { useManageSectorCategories } from "@/views/compareManage/hooks";
import { brinsonRangeOptions } from "../constant";
import YieldStatisticRangePicker from "@/components/yieldStatisticRangePicker";
import {
  useManageDatesData,
  datesInterface,
  useFundDates,
  useManageBrinsonDates,
  useGetDataSource,
  useGetTableData,
  useGetColumnsData,
  dataSourceInterface,
} from "../../hooks";
import { baseSelector } from "../selectors";
import { COMMON_TIME } from "@/constant/statisticRange";
import { benchmarksTypes } from "@/model/fundDetail";
import { benchmarkMapSelector } from "@/selectors/benchmarks";
import { formatPercentage } from "@/util/numberFormatter";
import {
  fetchETFFundCompareBrinson,
  fetchFundCompareBrinson,
} from "@/store/compareManage";

export default React.memo<{
  className?: string;
  fundIds: string[];
}>(({ className, fundIds }) => {
  const [brinsonRange, setBrinsonRange] = useState(COMMON_TIME);
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const [startAttribution, setStartAttribution] = useState(false);
  const [sectorId, setSectorId] = useManageSectorCategories();
  const benchmarks = useSelector(defaultBenchmarkInfoSelector);
  const benchmarkMap = useSelector(benchmarkMapSelector);
  // 基金原始成立日期和数据更新日期
  const fundDates = useFundDates() as datesInterface[];
  // 时段选择
  const brinsonDatesRangeOptions = useManageDatesData(
    fundDates as datesInterface[],
    brinsonRangeOptions
  );
  // 基金归因开始结束日期
  const fundIdBrinsonDates = useManageBrinsonDates(
    fundIds,
    fundDates,
    brinsonRange,
    brinsonDatesRangeOptions
  );

  const fundDetail = useAppSelector(prop("fundDetail"));
  const ETFDates = useCreation(
    () =>
      flow(
        map((id: string) => ({
          id: id,
          fundDetailIndustryTrendDate: prop(
            `${id}.fundDetailIndustryTrendDate`
          )(fundDetail),
        })),
        normalize("id")
      )(keys(fundDetail)),
    [fundDetail]
  );

  useEffect(() => {
    if (!isEmpty(fundIds) && sectorId) {
      map((item: any) => {
        const { startDate, endDate, fundId } = item;
        const isETF = prop(`${fundId}.fundDetailIndustryTrendDate.isETF`)(
          ETFDates
        );
        if (startDate && endDate) {
          if (isETF) {
            dispatch(
              fetchETFFundCompareBrinson({
                portfolioId: fundId,
                sectorCategoryId: sectorId,
                benchmarkId: flow(first, fastProp("benchmarkId"))(benchmarks),
                from: startDate,
                to: endDate,
                attributionMeasurement: "RELATIVE",
                attributionMethod: "BRINSON_IMPROVE",
                usingFixedBenchmark: false,
              })
            );
          } else {
            dispatch(
              fetchFundCompareBrinson({
                assetId: fundId as string,
                sectorCategoryId: sectorId,
                benchmarks,
                from: startDate,
                to: endDate,
              })
            );
          }

          setStartAttribution(true);
        }
      })(fundIdBrinsonDates);
    }
  }, [benchmarks, dispatch, fundIdBrinsonDates, fundIds, sectorId, ETFDates]);

  const dataSource: dataSourceInterface[] = useGetDataSource(fundIds, ETFDates);

  const tableData = useGetTableData(dataSource, fundIds);
  const columns = useGetColumnsData(dataSource);

  const { brinsonAttribution } = useSelector(baseSelector);

  const brinsonAttributionProgress = useMemo(
    () =>
      flow(
        map((fundId) => {
          return getProp(`${fundId}.fundAttributionBrinsonProgress`)(
            brinsonAttribution
          );
        }),
        map("progress")
      )(fundIds),
    [brinsonAttribution, fundIds]
  );
  const successBrinson = useMemo(
    () =>
      every(
        (progress: any) =>
          progress === REQUEST_FAILED || progress === REQUEST_RESOLVED
      )(brinsonAttributionProgress),
    [brinsonAttributionProgress]
  );
  useEffect(() => {
    if (successBrinson) {
      setStartAttribution(false);
    }
  }, [successBrinson]);

  const benchmarkNames = useMemo(() => {
    return flow(
      reject(({ weight }: benchmarksTypes) => floatEqual(0)(weight)),
      map(
        ({ weight, benchmarkId }: benchmarksTypes) =>
          `${prop(`${benchmarkId}.name`)(benchmarkMap)}*${formatPercentage(
            weight
          )}`
      )
    )(benchmarks);
  }, [benchmarks, benchmarkMap]);
  return (
    <Card bordered={false} className={className}>
      <Row justify="space-between" align="middle" className={style.RowTitle}>
        <Col>
          <h3>{formatMessage("BRINSON")}</h3>
        </Col>
        <Col>
          <Space size={12}>
            <Col>
              <Space>
                <span>{formatMessage("benchmark")}</span>
                {join("+")(benchmarkNames)}
              </Space>
            </Col>
            <Col>
              <Space>
                <span>{formatMessage("industry")}</span>
                <SectorCategoriesSelect
                  value={sectorId}
                  onChange={setSectorId}
                  disabled={startAttribution}
                />
              </Space>
            </Col>
            <Col>
              <Space>
                <span>{formatMessage("periodOfTime")}</span>
                <YieldStatisticRangePicker
                  disabled={startAttribution}
                  value={brinsonRange}
                  onChange={(e: any) => setBrinsonRange(e)}
                  options={brinsonDatesRangeOptions}
                />
              </Space>
            </Col>
          </Space>
        </Col>
      </Row>
      <Table
        columns={columns as any}
        className={style.Table}
        dataSource={tableData}
        bordered
        components={{
          body: {
            cell: (cellRecord: any) => {
              const { rowSpan, children, ...restProps } = cellRecord || {};
              //如果需要合并单元格，说明此时是进度状态
              if (rowSpan) {
                // 获取当前是在第几行
                const index = flow(fastNth(1), fastProp("key"))(children);
                // 只需要展示第一行的进度状态，其它行的都去掉
                if (parseInt(index, 10) === 0) {
                  return (
                    <td rowSpan={rowSpan} {...restProps}>
                      {children}
                    </td>
                  );
                }
                return null;
              }
              return <td {...restProps}>{children}</td>;
            },
          },
        }}
        rowKey="key"
        pagination={false}
        scroll={{ y: 400 }}
      />
    </Card>
  );
});
