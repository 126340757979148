import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { isEmpty, map, prop } from "lodash/fp";
import React from "react";
import { useMarketIndex } from "./hooks";
import MarketIndexDialog from "./marketIndexDialog";
import style from "../index.module.less";
import { Button, Space } from "antd";
import { SyncOutlined, CloseOutlined } from "@ant-design/icons";
import { useFormatMessage } from "@/util/formatMessage";

export default React.memo<{
  onChange: (checkedValue: CheckboxValueType[]) => void;
}>(({ onChange }) => {
  const formatMessage = useFormatMessage();
  const { factorMap, categoryTypes, deleteIndex } = useMarketIndex(onChange);
  return (
    <Space wrap>
      {/* <Space> */}
      {map((v: string) => (
        <span className={style.IndexItem} key={v}>
          <span>{prop([v, "name"])(factorMap)}</span>
          &nbsp;&nbsp;
          <CloseOutlined onClick={() => deleteIndex(v)} />
        </span>
      ))(categoryTypes)}
      {/* </Space> */}
      <MarketIndexDialog onChange={onChange} selectedIds={categoryTypes} />
      {!isEmpty(categoryTypes) && (
        <Button
          icon={<SyncOutlined />}
          type="link"
          onClick={() => onChange([])}
        >
          {formatMessage("reset")}
        </Button>
      )}
    </Space>
  );
});
