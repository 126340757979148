import React, { useCallback, useMemo } from "react";
import { Row, Col, Card, Space } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import {
  fetchFundCompanyCompareBaseInfo,
  clearFundCompanyResult,
} from "@/store/compareManage";
import SelectCompare from "../components/selectCompare";
import { useManageComparesTarget } from "../hooks";
import CompareResult from "./compareResult";
import { useAppDispatch } from "@/hooks/redux";
import style from "../fundCompare/index.module.less";
import FundCompanyTip from "./components/fundCompanyTip";
import EmptyContent from "@/components/emptyContent";
import { uniq } from "lodash/fp";

export type FundCompanyOpt = {
  companyIds?: string[];
};

export default React.memo<FundCompanyOpt>((props) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const onStart = useCallback(
    (fundCompanyIds) => {
      dispatch(clearFundCompanyResult());
      Promise.all([dispatch(fetchFundCompanyCompareBaseInfo(fundCompanyIds))]);
    },
    [dispatch]
  );
  const uniqCompanyIds = useMemo(
    () => uniq(props?.companyIds),
    [props?.companyIds]
  );
  const {
    compareIds,
    isComparing,
    onStartCompare,
    addCompareTarget,
    updateCompareTarget,
    removeCompareTarget,
    clearCompareTarget,
  } = useManageComparesTarget(onStart, uniqCompanyIds);
  return (
    <>
      <Card size="small" bordered={false} className={style.AddCard}>
        <Space direction="vertical" size={20}>
          <Row>
            <Col>
              <h4>{formatMessage("addFundCompany")}</h4>
            </Col>
            <Col className={style.TextColor}>
              &nbsp;(
              {formatMessage("maxCompareFundCompanyTip", {
                count: 4,
              })}
              )
            </Col>
            <Col>
              <FundCompanyTip
                fundCompanyIds={compareIds}
                companyIds={props.companyIds}
              />
            </Col>
          </Row>
          <SelectCompare
            compares={compareIds}
            onChange={updateCompareTarget}
            onAdd={addCompareTarget}
            onClear={clearCompareTarget}
            onRemove={removeCompareTarget}
            onStart={onStartCompare}
            buttonDisabled={isComparing}
            type="companyName"
          />
        </Space>
      </Card>
      {isComparing ? (
        <CompareResult companyIds={compareIds} />
      ) : (
        <EmptyContent
          message={formatMessage("noData")}
          className={style.Empty}
        />
      )}
    </>
  );
});
