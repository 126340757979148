import { Empty } from "antd";
import React, { useMemo } from "react";
import { useAppSelector } from "@/hooks/redux";
import { isEmpty } from "lodash/fp";
import { fastProp } from "@/util/opt";
import mapper from "../mapper";
import { LineChartTable } from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/performanceDismantling/compoents/dismantlingLineChart";
import {
  getSeries,
} from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/performanceDismantling/dismantingLineChartHelper";
import { YieldTrendChart } from "@/base-components/charts";

export default React.memo(({ id }: { id: string }) => {
  const {
    dismantlingLineChartSelector: dismantlingLineChart,
    categoryMaxAndMinSelector: categoryMaxAndMin,
    factorRatioSelector: factorRatioData,
  } = useAppSelector((state) => mapper(state, id));

  const dates = useMemo(
    () => fastProp("dates")(dismantlingLineChart),
    [dismantlingLineChart]
  );
  const series = useMemo(
    () => getSeries(dismantlingLineChart),
    [dismantlingLineChart]
  );

  return (
    <>
      <LineChartTable
        categoryMaxAndMin={categoryMaxAndMin}
        factorRatioData={factorRatioData}
      />
      <br />
      {isEmpty(dates) ? (
        <Empty />
      ) : (
        <YieldTrendChart
          dates={dates}
          series={series as any}
          height={400}
          showDataZoom
          showLegend
        />
      )}
    </>
  );
});
