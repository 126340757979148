import { fastNth } from "../opt";
import { identity, orderBy, size } from "lodash/fp";
import { formatArrayNilToZero } from "../numberFormatter";

export default (
  dailyReturns: number[],
  X: number,
  startDateNetValue: number
) => {
  if (size(dailyReturns) < 100) return NaN;
  if (!startDateNetValue) return NaN;
  // 单日收益率排序
  const sortedDailyReturns = orderBy(
    identity,
    "asc"
  )(formatArrayNilToZero(dailyReturns));
  const index = Math.floor(size(dailyReturns) * (1 - X));
  const result = fastNth(index - 1)(sortedDailyReturns);
  const sumCumulativeReturn = result * startDateNetValue;
  return sumCumulativeReturn > 0 ? 0 : sumCumulativeReturn;
};
