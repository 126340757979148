import { useCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { getCalculateScaleRangeDate } from "@/constant/statisticRangeCalculator/rangeCalculator";
import { BarChartOpts } from "@/echarts/barChart";
import { useGetBenchmarkDailyReturn } from "@/hooks/benchmark";
import { useAppSelector } from "@/hooks/redux";
import {
  companyManageFundResponse,
  scaleRankTrendItem,
} from "@/model/fundCompanyDetail";
import { companyNameMapSelector } from "@/selectors/company";
import { getLastDateOfYear } from "@/util/chart";
import { BlueColor, SCALE_COLORS } from "@/util/colors";
import { useFormatMessage } from "@/util/formatMessage";
import {
  fixedHundredMillion,
  formatNil,
  formatNilToZero,
} from "@/util/numberFormatter";
import { fastHas, fastNth, fastProp, mapIndexed } from "@/util/opt";
import { getTableScroll } from "@/util/tableFormat";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { useCreation } from "ahooks";
import dayjs from "dayjs";
import {
  filter,
  flow,
  map,
  maxBy,
  orderBy,
  prop,
  size,
  zipObject,
} from "lodash/fp";
import {
  companyScaleTrendSelector,
  companyManageFundSelector,
  companyDailyReturnSelector,
  companyScaleRankSelector,
} from "./selector";

export const useScaleTrendChart = (id: string, range: string) => {
  const formatMessage = useFormatMessage();
  const companyNameMap = useAppSelector(companyNameMapSelector);
  const companyScaleTrend = useAppSelector((state) =>
    companyScaleTrendSelector(state, id)
  );
  const { dates, scales } = companyScaleTrend || {};
  const scalesMap = useCreation(
    () => zipObject(dates, scales),
    [dates, scales]
  );

  const categories = useCreation(
    () =>
      filter((date: string) => fastHas(date)(scalesMap))(
        getCalculateScaleRangeDate(dates, range)
      ),
    [getCalculateScaleRangeDate, dates, range, scalesMap]
  );

  return useCreation<BarChartOpts["options"]>(() => {
    const yearOfDates = getLastDateOfYear(categories);
    return {
      categories,
      grid: {
        top: 60,
        bottom: 40,
        right: 10,
      },
      yAxis: {
        axisLabel: {
          formatter(val: number) {
            return fixedHundredMillion(val);
          },
        },
        max: "dataMax",
      },
      tooltip: {
        valueFormatter: (value) => {
          return fixedHundredMillion(value as number);
        },
      },
      xAxis: {
        type: "category",
        nameGap: 40,
        axisLabel: {
          interval: (index: number, value: string) => {
            const year = fastProp(value)(yearOfDates);
            return year ? true : false;
          },
          formatter(value: string) {
            return `${dayjs(value).format("YYYY")}${formatMessage("year")}`;
          },
          align: "center",
        },
      },
      data: {
        [prop([id, "name"])(companyNameMap)]: map((date: string) =>
          fastProp(date)(scalesMap)
        )(categories),
      },
      series: [
        {
          color: fastNth(0)(SCALE_COLORS),
          barMaxWidth: 40,
        },
      ],
      legend: {
        show: false,
      },
    };
  }, [categories, companyNameMap, id, scalesMap, formatMessage]);
};

export const useScaleTrendTableData = (id: string) => {
  const scaleRankTrend = useAppSelector((state) =>
    companyScaleRankSelector(state, id)
  );

  const tableData = useCreation(
    () =>
      flow(
        mapIndexed((v: scaleRankTrendItem, idx: number) => ({
          key: v.date,
          ...v,
          scaleChange:
            idx === 0
              ? v.scale
              : v.scale - fastProp("scale")(fastNth(idx - 1)(scaleRankTrend)),
          rank: `${formatNil(v.rank)} / ${formatNil(v.count)}`,
        })),
        orderBy("date", "desc")
      )(scaleRankTrend),
    [scaleRankTrend]
  );

  const scroll = useCreation(
    () =>
      getTableScroll({
        dataSource: scaleRankTrend,
        lineHeight: 44,
        maxYScroll: 354,
      }),
    [scaleRankTrend]
  );

  return {
    tableData,
    scroll,
  };
};

export const useIncomeTrendTable = (
  id: string,
  fundType: string,
  section: string
) => {
  const companyManageFund = useAppSelector((state) =>
    companyManageFundSelector(state, id, fundType, section)
  );

  const tableData = useCreation(
    () =>
      flow(
        map((v: companyManageFundResponse) => ({
          ...v,
          sorter:
            prop(["factorRankView", "value"])(v) || Number.MIN_SAFE_INTEGER,
        })),
        orderBy("sorter", "desc")
      )(companyManageFund),
    [companyManageFund]
  );

  const scroll = useCreation(
    () =>
      getTableScroll({
        dataSource: companyManageFund,
        lineHeight: 44,
        maxYScroll: 310,
      }),
    [companyManageFund]
  );
  return {
    tableData,
    scroll,
  };
};

export const useIncomeTrendChart = (
  id: string,
  fundType: string,
  section: string,
  benchmarkId: string
) => {
  const benchmarkInfo = useGetBenchmarkDailyReturn(benchmarkId);

  const companyDailyReturn = useAppSelector((state) =>
    companyDailyReturnSelector(state, id, fundType)
  );
  const companyNameMap = useAppSelector(companyNameMapSelector);
  const { dates, returns } = companyDailyReturn || {};
  const companyInfo = useCreation(
    () => ({
      name: prop([id, "name"])(companyNameMap),
      color: BlueColor,
      dailyReturnsMap: normalizeDailyReturnsMap(
        dates,
        returns as unknown as number[]
      ),
    }),
    [dates, returns]
  );

  const calculatedDates = useCalculateRangeDate(
    [dates, dates],
    benchmarkInfo.dates,
    section
  );

  const maxDates = useCreation(
    () => maxBy<string[]>(size)(calculatedDates) || [],
    [calculatedDates]
  );

  const chartData = useCreation(
    () =>
      map(({ name, dailyReturnsMap, color }) => ({
        name,
        color,
        dailyReturns: map<string, [string, number]>((date) => [
          date,
          formatNilToZero(fastProp(date)(dailyReturnsMap)),
        ])(maxDates),
      }))([companyInfo, benchmarkInfo]),
    [benchmarkInfo, companyInfo, maxDates]
  );

  return {
    chartData,
    dates: maxDates,
  };
};
