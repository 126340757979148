import { FundsInterface } from "@/model/entities";
import { TableProps, Table, Popconfirm, Button, Tooltip } from "antd";
import cn from "classnames";
import { InfoCircleFilled } from "@ant-design/icons";
import { useCreation, useMemoizedFn } from "ahooks";
import {
  SliderAndInput,
  validateWeightMaxError,
} from "@/components/portfolioCompoents/fundListTable";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import React from "react";
import { formatPercentage, toFixedNumber } from "@/util/numberFormatter";
import { Updater, useTransferAssetToFundAsset } from "../hooks";
import style from "./index.module.less";
import {
  Asset,
  ErrorField,
  ManualCreatePortfolio,
  setEqualWeight,
} from "../constant";
import { compact, isEmpty, pullAt, set, size, update } from "lodash/fp";
import { getTableSummaryNode } from "@/components/portfolioCompoents/constant";

type FundWeightProps = {
  dataSource: ManualCreatePortfolio;
  onChange?: (key: string) => (updater: Updater) => any;
  editable?: boolean;
  className?: string;
  tableRef?: any;
};

type FundWeightTableProps = TableProps<FundsInterface & Asset>;
export const useGetFundWeightTableColumns = (
  onChange: (updater: Updater) => any,
  errorField?: ErrorField,
  editable?: boolean
) => {
  const formatMessage = useFormatMessage();
  const onChangeAssetWeight = useMemoizedFn(
    (index: number) => (value: number | null) =>
      onChange(update(index, set("weight", value)))
  );
  const onRemoveAsset = useMemoizedFn((index: number) =>
    onChange(pullAt(index))
  );
  const setAssetsEqualWeight = useMemoizedFn(() => onChange(setEqualWeight));
  return useCreation<FundWeightTableProps["columns"]>(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: formatMessage("fundName"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
      },
      {
        key: "code",
        dataIndex: "code",
        title: formatMessage("fundCode"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
      },
      {
        key: "investType",
        dataIndex: "investType",
        title: formatMessage("policyType"),
      },
      {
        key: "netValue",
        dataIndex: "netValue",
        width: 150,
        title: (
          <div>
            {formatMessage("latestNetUnitValue")}
            {errorField?.latestNetUnitValueZeroError && (
              <Tooltip title={formatMessage("lackLatestNetUnitValue")}>
                <InfoCircleFilled className={style.ErrorIcon} />
              </Tooltip>
            )}
          </div>
        ),
        render: (_, record) => {
          const netValueLabel = toFixedNumber(4)(fastProp("netValue")(record));
          return (
            <div>
              {netValueLabel}
              {netValueLabel === "--" && (
                <Tooltip title={formatMessage("lackLatestNetUnitValue")}>
                  <InfoCircleFilled className={style.ErrorIcon} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        key: "tradingDay",
        dataIndex: "tradingDay",
        title: (
          <div>
            {formatMessage("netValueStartAndEndDate")}
            {(errorField?.maturityDateError ||
              errorField?.netValueStartDateZeroError ||
              errorField?.netValueStartDateError) && (
              <InfoCircleFilled className={style.ErrorIcon} />
            )}
          </div>
        ),
        render: (_, record) => {
          const maturityDateError = fastProp("maturityDateError")(record);
          const netValueStartDateError = fastProp("netValueStartDateError")(
            record
          );
          return (
            <div>
              {fastProp("netValueStartDate")(record) || (
                <>
                  --{" "}
                  <Tooltip title={formatMessage("lackNetValueStartDate")}>
                    <InfoCircleFilled className={style.ErrorIcon} />
                  </Tooltip>
                </>
              )}{" "}
              {formatMessage("to")} {_ || "--"}
              {(maturityDateError || netValueStartDateError) && (
                <Tooltip
                  title={
                    maturityDateError
                      ? "基金净值结束日期早于当前调仓日期"
                      : "基金净值起始日晚于当前调仓日期"
                  }
                >
                  <InfoCircleFilled className={style.ErrorIcon} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        key: "weight",
        dataIndex: "weight",
        width: editable ? 250 : 300,
        title: (
          <div>
            {formatMessage("positionWeight")}
            {errorField?.fundWeightZeroError && (
              <Tooltip title="总权重不能为0">
                <InfoCircleFilled className={style.ErrorIcon} />
              </Tooltip>
            )}
            {editable && (
              <Button
                size="small"
                onClick={setAssetsEqualWeight}
                className={style.EqualWeightButton}
              >
                {formatMessage("equalWeight")}
              </Button>
            )}
          </div>
        ),
        render: (_, record, index) =>
          editable ? (
            <SliderAndInput
              value={record.weight}
              onChange={onChangeAssetWeight(index)}
            />
          ) : (
            formatPercentage(_)
          ),
      },
      ...compact([
        editable
          ? {
              key: "operator",
              dataIndex: "operator",
              title: formatMessage("operator"),
              render: (_: any, record: any, index: number) => (
                <Popconfirm
                  title={formatMessage("deleteFundConfirm")}
                  onConfirm={() => onRemoveAsset(index)}
                  okText={formatMessage("ok")}
                  cancelText={formatMessage("cancel")}
                >
                  <span className={style.Operator}>
                    {formatMessage("delete")}
                  </span>
                </Popconfirm>
              ),
            }
          : undefined,
      ]),
    ],
    [
      errorField,
      formatMessage,
      onChangeAssetWeight,
      onRemoveAsset,
      setAssetsEqualWeight,
    ]
  );
};

export const SummaryNode = React.memo<{
  size: number;
  positionWeight: number;
}>(({ size, positionWeight }) => {
  const formatMessage = useFormatMessage();
  return useCreation(
    () =>
      getTableSummaryNode([
        {
          align: "left",
          index: 0,
          colSpan: 5,
          render: formatMessage("fundTotalNum", { num: size }),
        },
        {
          align: "left",
          index: 1,
          render: (
            <div
              className={cn(
                style.SummaryPosition,
                validateWeightMaxError(positionWeight)
                  ? style.PositionError
                  : ""
              )}
            >
              <p>{formatMessage("RemainingAvailablePositions")}</p>
              <p className={style.Position}>
                {formatPercentage(1 - positionWeight)}
              </p>
            </div>
          ),
        },
      ]),
    [formatMessage, positionWeight, size]
  );
});

export default React.memo<FundWeightProps>(
  ({ dataSource, onChange, editable, className, tableRef }) => {
    const onChangeAssets = useMemoizedFn(
      (updater: Updater) => onChange && onChange("weights")(updater)
    );
    const columns = useGetFundWeightTableColumns(
      onChangeAssets,
      dataSource.errorField,
      editable
    );
    const transfer = useTransferAssetToFundAsset();
    const fundAndAssetDataSource = useCreation(
      () => transfer(dataSource.weights),
      [transfer, dataSource.weights]
    );
    const scroll = useCreation(
      () =>
        size(dataSource.weights) > 10
          ? { y: 407, x: "max-content" }
          : undefined,
      [dataSource]
    );
    return (
      <Table
        className={cn(style.FundWeightTable, className)}
        rowKey="fundId"
        ref={tableRef}
        columns={columns}
        dataSource={fundAndAssetDataSource}
        pagination={false}
        scroll={scroll}
        summary={() =>
          !isEmpty(fundAndAssetDataSource) && (
            <Table.Summary fixed>
              <SummaryNode
                positionWeight={dataSource.sumWeight}
                size={size(fundAndAssetDataSource)}
              />
            </Table.Summary>
          )
        }
      ></Table>
    );
  }
);
