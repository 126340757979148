/* eslint-disable @typescript-eslint/no-explicit-any */
class AxiosRequestCache {
  cache: Map<any, any>;
  constructor() {
    this.cache = new Map();
  }

  addCache(uuid: string, promise: any) {
    this.cache.set(uuid, promise);
  }

  deleteCache(uuid: string) {
    this.cache.delete(uuid);
  }

  getCache(uuid: string) {
    if (this.cache.has(uuid)) return this.cache.get(uuid);
    return null;
  }
}

export const axiosRequestCache = new AxiosRequestCache();
