import { DatePeriodName } from "@/util/business-core/datePeriod";

export const defaultOptions: DatePeriodName[] = [
  // "COMMON_TIME",
  "RECENT_MONTH",
  "RECENT_THREE_MONTH",
  "RECENT_HALF_YEAR",
  "FROM_THIS_YEAR",
  "RECENT_YEAR",
  "RECENT_THREE_YEAR",
  "FROM_CREATION",
];
