import dayjs from "dayjs";
import { useMemoizedFn } from "ahooks";
import { Form, Input, InputNumber, Modal, Popover, Space } from "antd";
import { merge } from "lodash/fp";
import React, { useMemo, useState } from "react";

import TradingDatePicker from "@/components/tradingDatePicker";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { AssetItemType } from "@/model/customer";
import {
  fetchPositionAssetsList,
  putInvestPlanStatus,
  saveOrUpdateInvestPlan,
} from "@/store/customerAccountAnalysis";
import { useFormatMessage } from "@/util/formatMessage";

import {
  useGetIsActionLoading,
  useLargeThanZeroValidator,
  useNotNullValidator,
} from "../hooks";
import style from "./index.module.less";
import FixedInvestFrequencyCascader from "./fixedInvestFrequencyCascader";
import { RootState } from "@/store";
import { customerAnalysisSelector } from "@/views/customerManage/customerAccountAnalysis/selectors";
import { fastProp } from "@/util/opt";

const { Item } = Form;
export default React.memo(
  ({
    assetItem,
    accountId,
  }: {
    assetItem: AssetItemType;
    accountId: string;
  }) => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();

    const { assetName, assetCode, investStartDate, investPlanId } = assetItem;

    const [isShowPopover, setIsShowPopover] = useState(false);
    const [isShowManagement, setIsShowManagement] = useState(false);
    const [isShowTerminateModal, setIsShowTerminateModal] = useState(false);

    const [form] = Form.useForm();

    const handleManagementOk = useMemoizedFn(() => {
      form.validateFields().then((values) => {
        if (accountId && assetCode)
          dispatch(
            saveOrUpdateInvestPlan(merge({ accountId, assetCode })(values))
          ).then(() => {
            dispatch(
              fetchPositionAssetsList({
                accountId,
                forceToRefetch: true,
              })
            );
            setIsShowManagement(false);
          });
      });
    });

    const [isTerminateDateInvalid, setIsTerminateDateInvalid] = useState(false);
    const handleTerminateModalOk = useMemoizedFn(() => {
      if (terminateDate)
        dispatch(
          putInvestPlanStatus({
            accountId,
            assetCode,
            date: terminateDate,
          })
        ).then(() => {
          dispatch(
            fetchPositionAssetsList({
              accountId,
              forceToRefetch: true,
            })
          );
          setIsShowTerminateModal(false);
        });
      else {
        setIsTerminateDateInvalid(true);
      }
    });

    const [terminateDate, setTerminateDate] = useState("");

    const managementModalConfirmLoading = useGetIsActionLoading(
      saveOrUpdateInvestPlan
    );

    const pauseOrContinueModalLoading =
      useGetIsActionLoading(putInvestPlanStatus);

    const notNullValidator = useNotNullValidator();
    const largeThanZeroValidator = useLargeThanZeroValidator();

    const accountAnalysisData = useAppSelector((state: RootState) =>
      customerAnalysisSelector(state, accountId)
    );
    const { from, to } = useMemo(
      () => fastProp("accountInfo")(accountAnalysisData) || {},
      [accountAnalysisData]
    );
    const newFixedInvestmentDisabledDate = useMemoizedFn(
      (date: string) => dayjs(date).isBefore(from) || dayjs(date).isAfter(to)
    );
    const terminateDisabledDate = useMemoizedFn((date: string) =>
      dayjs(date).isBefore(investStartDate)
    );
    return (
      <div className={style.FixedInvestmentManageWrapper}>
        <Popover
          trigger="click"
          placement="bottom"
          content={
            <Space direction="vertical">
              {investPlanId ? (
                <a
                  onClick={() => {
                    setIsShowPopover(false);
                    setIsShowTerminateModal(true);
                  }}
                >
                  {formatMessage("terminateFixedInvestment")}
                </a>
              ) : (
                <a
                  onClick={() => {
                    setIsShowPopover(false);
                    setIsShowManagement(true);
                  }}
                >
                  {formatMessage("newFixedInvestment")}
                </a>
              )}
            </Space>
          }
          className={style.PopConfirm}
          open={isShowPopover}
          onOpenChange={(newVisibleState) => setIsShowPopover(newVisibleState)}
        >
          <a>{formatMessage("fixedInvestment")}</a>
        </Popover>
        {isShowManagement && (
          <Modal
            title={formatMessage("newFixedInvestment")}
            open={isShowManagement}
            okText={formatMessage("preservation")}
            onOk={handleManagementOk}
            onCancel={() => setIsShowManagement(false)}
            width={440}
            confirmLoading={managementModalConfirmLoading}
          >
            <div className={style.FormDesc}>{assetName}</div>
            <Form
              labelCol={{ span: 6 }}
              labelAlign="left"
              form={form}
              preserve={false}
              className={style.Form}
            >
              <Item
                label={formatMessage("fixedInvestmentAmount")}
                name="investScale"
                rules={[
                  {
                    validator: largeThanZeroValidator(
                      formatMessage("fixedInvestmentAmount")
                    ),
                  },
                ]}
              >
                <InputNumber
                  step={0.01}
                  precision={2}
                  className={style.FullWidth}
                  min={0}
                />
              </Item>
              {/* <Item label={formatMessage("transactionCost")}>
                <Radio.Group
                  value={purchaseFeeType}
                  onChange={({ target: { value } }) =>
                    setPurchaseFeeType(value)
                  }
                >
                  <Radio value={"handlingFee"}>
                    {formatMessage("serviceCharge")}
                  </Radio>
                </Radio.Group>
              </Item> */}
              <Item
                label={formatMessage("serviceCharge")}
                name="handlingFee"
                initialValue={0}
                rules={[
                  {
                    validator: largeThanZeroValidator(
                      formatMessage("transactionCost"),
                      true
                    ),
                  },
                ]}
              >
                <InputNumber<number>
                  className={style.FullWidth}
                  min={0}
                  precision={2}
                />
              </Item>
              <Item
                label={formatMessage("investFrequency")}
                name="frequency"
                rules={[
                  {
                    validator: notNullValidator(
                      formatMessage("investFrequency")
                    ),
                  },
                ]}
              >
                <FixedInvestFrequencyCascader />
              </Item>
              <Item
                label={formatMessage("startDate")}
                name="startDate"
                rules={[
                  {
                    validator: notNullValidator(formatMessage("startDate")),
                  },
                ]}
              >
                <TradingDatePicker
                  className={style.FullWidth}
                  disabledDate={newFixedInvestmentDisabledDate}
                />
              </Item>
              <Item
                label={formatMessage("endDate")}
                name="endDate"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (dayjs(value).isBefore(getFieldValue("startDate"))) {
                        return Promise.reject(
                          new Error(formatMessage("fromToError"))
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <TradingDatePicker
                  className={style.FullWidth}
                  disabledDate={newFixedInvestmentDisabledDate}
                />
              </Item>
              <Item label={formatMessage("remarks")} name="remark">
                <Input.TextArea
                  placeholder={formatMessage("descPlaceholder")}
                />
              </Item>
            </Form>
          </Modal>
        )}
        {isShowTerminateModal && (
          <Modal
            title={formatMessage("terminateFixedInvestment")}
            open={isShowTerminateModal}
            okText={formatMessage("preservation")}
            onOk={handleTerminateModalOk}
            onCancel={() => setIsShowTerminateModal(false)}
            confirmLoading={pauseOrContinueModalLoading}
          >
            <div className={style.FormDesc}>
              {formatMessage("confirmTerminateFixedInvestment")}
            </div>
            <Item
              label={formatMessage("terminateDate")}
              labelCol={{ span: 6 }}
              labelAlign="left"
              className={style.Form}
              {...(isTerminateDateInvalid
                ? {
                    validateStatus: "error",
                    help: formatMessage("pleaseEnterCorrect", {
                      label: formatMessage("terminateDate"),
                    }),
                  }
                : {})}
            >
              <TradingDatePicker
                value={terminateDate}
                onChange={(date) => {
                  setTerminateDate(date);
                  setIsTerminateDateInvalid(false);
                }}
                className={style.FullWidth}
                disabledDate={terminateDisabledDate}
              />
            </Item>
          </Modal>
        )}
      </div>
    );
  }
);
