import { assetAllocate } from "@/api/portfolioAnalysis";
import { AssetAllocate, AssetAllocateParams } from "@/model/portfolioAnalysis";
import { fastProp } from "@/util/opt";
import { CSI300_ID } from "@/views/compareManage/constant";
import {
  getDefaultErrorField,
  getFundConfigurationFormData,
} from "@/views/portfolioManage/fundConfiguration/constant";
import {
  ErrorField,
  FundConfigurationAsset,
  FundConfigurationForm,
} from "@/views/portfolioManage/fundConfiguration/interface";
import { BackTestingForm } from "@/views/portfolioManage/manualCreatePortfolio/backTestingAllocation/backTestingForm";
import {
  generateManualCreatePortfolioData,
  ManualCreatePortfolio,
} from "@/views/portfolioManage/manualCreatePortfolio/constant";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BENCHMARK } from "@/components/portfolioCompoents/performanceBenchmarkSelect";
import { orderBy, set } from "lodash/fp";
import { AppThunk } from ".";
import { resetBackTestingTask } from "./portfolioAnalysis";
import {
  categoryModelConfig,
  getCategoryAllocateParam,
} from "@/views/portfolioManage/fromAboveToBelow/interface";
import { ASSET_CATEGORY } from "@/views/portfolioManage/fromAboveToBelow/constant";
import {
  getModelGoalConfigs,
  meanVarianceModel,
} from "@/views/portfolioManage/fromAboveToBelow/modelAllocation/constant";
import { getCategoryAllocate } from "@/api/portfolioList";

type CreatePortfolioState = {
  manualCreatePortfolio: {
    backTestingFormData: BackTestingForm;
    allocationData: ManualCreatePortfolio[];
  };
  fundConfiguration: {
    configurationAssets: FundConfigurationAsset[];
    errorField: ErrorField;
    fundConfigurationFormData: FundConfigurationForm;
    assetAllocateData: AssetAllocate[];
    manualPortfolioAllocateData: ManualCreatePortfolio[];
    backTestingFormData: BackTestingForm;
  };
  modelAllocation: {
    modelAllocationData: categoryModelConfig;
    configurationAssets: FundConfigurationAsset[];
    fundConfigurationFormData: FundConfigurationForm;
    errorField: ErrorField;
    modelPortfolioAllocateData: ManualCreatePortfolio[];
    assetAllocateData: AssetAllocate[];
    backTestingFormData: BackTestingForm;
  };
};

const getDefaultBackTestingFormData = () => ({
  startDate: "",
  endDate: "",
  benchmarkId: CSI300_ID,
  initScale: 100000,
  benchmarkType: BENCHMARK,
});
const getDefaultManualCreatePortfolio = () => ({
  backTestingFormData: getDefaultBackTestingFormData(),
  allocationData: [generateManualCreatePortfolioData()],
});
const getDefaultFundConfiguration = () => ({
  configurationAssets: [],
  errorField: getDefaultErrorField(),
  fundConfigurationFormData: getFundConfigurationFormData(),
  assetAllocateData: [],
  manualPortfolioAllocateData: [],
  backTestingFormData: getDefaultBackTestingFormData(),
});
const getDefaultModelAllocation = () => ({
  modelAllocationData: {
    categorySource: ASSET_CATEGORY,
    categoryAssets: [],
    categoryTypes: [],
    allocateConfig: getModelGoalConfigs.meanVarianceModel,
    configurationModel: meanVarianceModel,
  } as any,
  configurationAssets: [],
  fundConfigurationFormData: getFundConfigurationFormData(),
  errorField: getDefaultErrorField(),
  modelPortfolioAllocateData: [],
  assetAllocateData: [],
  backTestingFormData: getDefaultBackTestingFormData(),
});
const initialState: CreatePortfolioState = {
  manualCreatePortfolio: getDefaultManualCreatePortfolio(),
  fundConfiguration: getDefaultFundConfiguration(),
  modelAllocation: getDefaultModelAllocation(),
};

export const assetFundConfigurationAllocate = createAsyncThunk(
  "createPortfolio/assetFundConfigurationAllocate",
  async (params: AssetAllocateParams) => {
    const response = await assetAllocate(params);
    return response;
  }
);

export const fetchGetCategoryAllocate = createAsyncThunk(
  "createPortfolio/modelPortfolioCategoryAllocate",
  async (params: getCategoryAllocateParam) => {
    const response = await getCategoryAllocate(params);
    return response;
  }
);

const createPortfolio = createSlice({
  name: "createPortfolio",
  initialState,
  reducers: {
    updateFundConfiguration(
      state: CreatePortfolioState,
      action: {
        type: string;
        payload: {
          key: keyof CreatePortfolioState["fundConfiguration"];
          value: any;
        };
      }
    ) {
      const { key, value } = action.payload;
      if (key && value)
        state.fundConfiguration = set(key, value)(state.fundConfiguration);
    },
    resetFundConfiguration(state: CreatePortfolioState, action) {
      state.fundConfiguration = {
        ...getDefaultFundConfiguration(),
        ...(action?.payload || {}),
      };
    },
    updateManualCreatePortfolio(
      state: CreatePortfolioState,
      action: {
        type: string;
        payload: {
          key: keyof CreatePortfolioState["manualCreatePortfolio"];
          value: any;
        };
      }
    ) {
      const { key, value } = action.payload;
      if (key && value)
        state.manualCreatePortfolio = set(
          key,
          value
        )(state.manualCreatePortfolio);
    },
    resetManualCreatePortfolio(state: CreatePortfolioState, action) {
      state.manualCreatePortfolio = {
        ...getDefaultManualCreatePortfolio(),
        ...(action.payload || {}),
      };
    },
    updateModelConfiguration(
      state: CreatePortfolioState,
      action: {
        type: string;
        payload: {
          key: keyof CreatePortfolioState["modelAllocation"];
          value: any;
        };
      }
    ) {
      const { key, value } = action.payload;
      if (key && value) {
        let updateValue = value;
        if (key === "configurationAssets") {
          updateValue = orderBy<FundConfigurationAsset>(
            "categoryOrder",
            "asc"
          )(value);
        }
        state.modelAllocation = set(key, updateValue)(state.modelAllocation);
      }
    },
    resetModelConfiguration(state: CreatePortfolioState, action) {
      state.modelAllocation = {
        ...getDefaultModelAllocation(),
        ...(action.payload || {}),
      };
    },
  },
  extraReducers: {
    [assetFundConfigurationAllocate.fulfilled.type]: (state, action) => {
      state.fundConfiguration.assetAllocateData = action.payload;
    },
    [fetchGetCategoryAllocate.fulfilled.type]: (state, action) => {
      state.modelAllocation.assetAllocateData = action.payload;
    },
  },
});

export default createPortfolio.reducer;

export const updateFundConfiguration =
  (
    key: keyof CreatePortfolioState["fundConfiguration"],
    updater: (value: any) => any
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const storeValue = fastProp(key)(state.createPortfolio.fundConfiguration);
    // 基金配置表单信息和基金信息改变后应该reset上一次的回测信息
    if (
      key === "backTestingFormData" ||
      key === "manualPortfolioAllocateData"
    ) {
      dispatch(resetBackTestingTask());
    }
    return dispatch(
      createPortfolio.actions.updateFundConfiguration({
        key,
        value: updater(storeValue),
      })
    );
  };

export const updateManualCreatePortfolio =
  (
    key: keyof CreatePortfolioState["manualCreatePortfolio"],
    updater: (value: any) => any
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const storeValue = fastProp(key)(
      state.createPortfolio.manualCreatePortfolio
    );
    // 手动创建任意状态改变都应该reset上一次的回测信息
    dispatch(resetBackTestingTask());
    return dispatch(
      createPortfolio.actions.updateManualCreatePortfolio({
        key,
        value: updater(storeValue),
      })
    );
  };

export const resetManualCreatePortfolio =
  (arg: any): AppThunk =>
  (dispatch) => {
    dispatch(resetBackTestingTask());
    return dispatch(createPortfolio.actions.resetManualCreatePortfolio(arg));
  };

export const resetFundConfiguration =
  (arg: any): AppThunk =>
  (dispatch) => {
    dispatch(resetBackTestingTask());
    return dispatch(createPortfolio.actions.resetFundConfiguration(arg));
  };

export const updateModelConfiguration =
  (
    key: keyof CreatePortfolioState["modelAllocation"],
    updater: (value: any) => any
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const storeValue = fastProp(key)(state.createPortfolio.modelAllocation);
    dispatch(resetBackTestingTask());
    return dispatch(
      createPortfolio.actions.updateModelConfiguration({
        key,
        value: updater(storeValue),
      })
    );
  };

export const resetModelConfiguration =
  (arg: any): AppThunk =>
  (dispatch) => {
    dispatch(resetBackTestingTask());
    return dispatch(createPortfolio.actions.resetModelConfiguration(arg));
  };
