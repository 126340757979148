import { Button, Select, Space, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { useCreation, useMemoizedFn } from "ahooks";
import { first, isEmpty, last, map, pick, size } from "lodash/fp";

import { useFormatMessage } from "@/util/formatMessage";
import CustomStaticRange from "@/views/portfolioManage/portfolioAnalysis/components/customStaticRange";
import ErrorBoundary from "@/components/errorBoundary";
import LineChart from "@/echarts/lineChart";
import EchartsWrapper from "@/echarts/echartsWrapper";

import SummaryCard from "@/views/portfolioManage/portfolioAnalysis/components/summaryCard";
import { mapIndexed } from "@/util/opt";
import { customOptions } from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/netValueAttribution/constant";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchNetValueAttribution } from "@/store/fundDetailSlice";
import {
  baseFundInfoSelector,
  fundDailyNetValueDatesSelector,
} from "@/views/fundDetail/selectors";

import style from "./index.module.less";
import { AssetDetailComponent } from "../../../../constant";
import TaskResult from "@/components/taskResult";
import {
  useCumulativeFactorsData,
  useGetFundLineChartDataAndOptions,
  useGetFundNetValueAttributionData,
  useGetFundStatisticsTableColumnsAndData,
  useGetFundTreeChartOptions,
} from "./hook";
import {
  defaultModelId,
  getFundDateRangeText,
  getFundFactorTableColumns,
  getFundStatisticsText,
} from "./constant";
import { netValueAttributionModalBody } from "@/model/fundDetail";

const useManageHeaderState = (id: string) => {
  const dispatch = useAppDispatch();
  const fundDailyNetValueDates = useAppSelector((state) =>
    fundDailyNetValueDatesSelector(state, id)
  );
  const runningTime = size(fundDailyNetValueDates);
  const [range, setRange] = useState("FROM_CREATION");
  const [model, setModel] = useState(defaultModelId);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    setButtonDisabled(false);
  }, [range, model]);
  const onStartCalculate = useMemoizedFn(() => {
    if (id && range) {
      dispatch(
        fetchNetValueAttribution({
          fundId: id,
          section: range,
          factorModelId: model,
        })
      );
      setButtonDisabled(true);
    }
  });
  return {
    range,
    runningTime,
    onChangeRange: setRange,
    buttonDisabled,
    onStartCalculate,
    model,
    onChangeModel: setModel,
  };
};
const basicFactors = ["maxDrawdown", "sharpeRatio"];
export default React.memo<AssetDetailComponent>(({ fundId }) => {
  const {
    range,
    runningTime,
    onChangeRange,
    buttonDisabled,
    onStartCalculate,
    model,
    onChangeModel,
  } = useManageHeaderState(fundId);

  const {
    errorBoundary,
    netValueAttribution,
    factors,
    factorsMap,
    fundNetValueAttributionProgress,
    netValueAttributionModel,
  } = useGetFundNetValueAttributionData(
    fundId,
    range,
    runningTime,
    baseFundInfoSelector,
    model
  );

  const dates = useAppSelector((state) =>
    fundDailyNetValueDatesSelector(state, fundId)
  );
  const [startDate, endDate]: [string, string] = [
    first(dates) || "",
    last(dates) || "",
  ];
  const formatMessage = useFormatMessage();

  const { assetCumulativeReturn, cumnlateFactor } = useCumulativeFactorsData(
    netValueAttribution,
    factors
  );

  const { dataSource, columns } = useMemo<{
    dataSource: any;
    columns: any;
  }>(() => {
    return {
      dataSource: [
        {
          ...pick(basicFactors)(netValueAttribution),
          assetCumulativeReturn,
          ...cumnlateFactor,
        },
      ],
      columns: getFundFactorTableColumns(formatMessage, factors),
    };
  }, [
    netValueAttribution,
    formatMessage,
    factors,
    assetCumulativeReturn,
    cumnlateFactor,
  ]);

  const { options, series } = useGetFundLineChartDataAndOptions(
    netValueAttribution,
    factorsMap
  );

  const { columns: statisticColumns, dataSource: statisticTableData } =
    useGetFundStatisticsTableColumnsAndData(netValueAttribution, factors);

  const treeChartOptions = useGetFundTreeChartOptions(
    netValueAttribution,
    factorsMap
  );

  const dateRangeText = useCreation(
    () => getFundDateRangeText(netValueAttribution, formatMessage),
    [netValueAttribution, formatMessage]
  );

  const statisticsText = useCreation(
    () =>
      getFundStatisticsText(netValueAttribution, formatMessage, cumnlateFactor),
    [netValueAttribution, formatMessage]
  );

  const staticRangeOptions = useCreation(
    () =>
      mapIndexed((item: { message: string }) => ({
        ...item,
        message: item.message + "_FUND_NET_VALUE_LABEL",
      }))(customOptions),
    []
  );

  return (
    <Space className={style.rootWrapper} direction="vertical">
      <div className={style.topBarWrapper}>
        <Space>
          <span>{formatMessage("attributionTime")}</span>
          <CustomStaticRange
            value={range}
            onChange={onChangeRange}
            startDate={startDate}
            endDate={endDate}
            staticRange={staticRangeOptions}
          />
          {dateRangeText && <span>( {dateRangeText} )</span>}
          <Button
            type="primary"
            onClick={onStartCalculate}
            disabled={buttonDisabled || !!errorBoundary}
          >
            {formatMessage("startCalculate")}
          </Button>
        </Space>
        <div className={style.selectWrapper}>
          <Space>
            {formatMessage("modelSelection")}
            <Select value={model} onChange={onChangeModel}>
              {map((item: netValueAttributionModalBody) => (
                <Select.Option value={item.factorModelId}>
                  {item?.factorModelName}
                </Select.Option>
              ))(netValueAttributionModel || [])}
            </Select>
          </Space>
        </div>
      </div>

      <div className={style.contentWrapper}>
        <ErrorBoundary errKey={errorBoundary}>
          {isEmpty(fundNetValueAttributionProgress) ? (
            <div className={style.Empty} />
          ) : (
            <TaskResult task={fundNetValueAttributionProgress}>
              <>
                <Table
                  columns={columns}
                  bordered
                  dataSource={dataSource}
                  pagination={false}
                  className={cn(style.Table, style.BrinsonTable)}
                />
                <LineChart height={400} series={series} options={options} />
                <div className={style.Flex}>
                  <div className={style.Chart}>
                    <EchartsWrapper options={treeChartOptions} />
                  </div>
                  <div className={style.detailTable}>
                    <Table
                      columns={statisticColumns}
                      bordered
                      dataSource={statisticTableData}
                      pagination={false}
                      defaultExpandAllRows
                      scroll={{ x: "100%" }}
                      className={cn(style.BrinsonTable)}
                    />
                  </div>
                </div>
                {statisticsText ? (
                  <SummaryCard summaryText={statisticsText} />
                ) : null}
              </>
            </TaskResult>
          )}
        </ErrorBoundary>
      </div>
    </Space>
  );
});
