import NumberInput from "@/components/numberInput";
import { useFormatMessage } from "@/util/formatMessage";
import { useMemoizedFn } from "ahooks";
import { Radio, RadioChangeEvent } from "antd";
import { isNil } from "lodash/fp";
import React, { useEffect } from "react";
import { riskFreeRateConfig } from "../../constant";
import { RiskFreeType } from "../../interface";
import style from "./index.module.less";

export type RiskFreeRateProps = {
  value?: {
    type: RiskFreeType;
    riskFreeRate?: undefined | null | number;
  };
  onChange?: (values: RiskFreeRateProps["value"]) => any;
};

export default React.memo<RiskFreeRateProps>(({ onChange, value }) => {
  const onChangeType = useMemoizedFn(
    (e: RadioChangeEvent) =>
      onChange &&
      onChange({
        ...value,
        type: e.target.value,
      })
  );
  const onChangeRiskFreeRate = useMemoizedFn(
    (riskFreeRate: number | null) =>
      onChange &&
      onChange({
        type: value?.type as RiskFreeType,
        riskFreeRate,
      })
  );
  useEffect(() => {
    if (value?.type === "specificRiskFree" && isNil(value?.riskFreeRate)) {
      onChangeRiskFreeRate(0.015);
    }
  }, [onChangeRiskFreeRate, value]);
  const formatMessage = useFormatMessage();
  return (
    <>
      <Radio.Group value={value?.type} onChange={onChangeType}>
        <Radio value={riskFreeRateConfig.specificRiskFree}>
          指定无风险利率
        </Radio>
        {value && value.type === "specificRiskFree" && (
          <NumberInput
            type="PERCENTAGE"
            size="small"
            value={value.riskFreeRate as number}
            onChange={onChangeRiskFreeRate}
            className={style.RiskFreeInput}
            placeholder="输入数值"
            min={0}
            max={0.03}
            addonAfter={`% ${formatMessage("RiskFreeRateRange")}`}
            precision={2}
          />
        )}
        <Radio value={riskFreeRateConfig.defaultRiskFree}>
          一年定期存款利率
        </Radio>
      </Radio.Group>
    </>
  );
});
