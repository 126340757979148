import { ButtonGroupItem } from "@/components/buttonGroup";

export const buttonGroupList: ButtonGroupItem[] = [
  {
    id: "PerformanceDismantling",
    messageKey: "PerformanceDismantling",
    value: "PerformanceDismantling",
  },
  {
    id: "BRINSON",
    messageKey: "BRINSON",
    value: "BRINSON",
  },
];
