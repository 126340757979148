import React from "react";

import betalphaIcon from "@/assets/fundEvaluationReport/betalphaFundResearch.svg";

import style from "./index.module.less";

export default React.forwardRef(
  (
    { pageNumber }: { pageNumber: number },
    ref: React.LegacyRef<HTMLDivElement>
  ) => {
    return (
      <div ref={ref} className={style.PageHeader}>
        <div className={style.Content}>
          <div className={style.Icon}>
            <img src={betalphaIcon} />
          </div>
          <div className={style.PageNumWrap}>
            {String(pageNumber).padStart(2, "0")}
          </div>
        </div>
        <div className={style.Divider} />
      </div>
    );
  }
);
