import React, { LegacyRef, useEffect } from "react";
import cn from "classnames";
import { prop } from "lodash/fp";

import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  fetchEvaluationReportAbilitySummary,
  fetchEvaluationReportInfo,
  fetchEvaluationReportRiskSummary,
  fetchRiskDetail,
} from "@/store/fundDetailSlice";
import {
  EvaluationReportAbilitySummaryBody,
  EvaluationReportInfoBody,
  EvaluationReportRiskSummaryBody,
} from "@/model/fundDetail";

import PageHeader from "../../pageHeader";
import style from "./index.module.less";
import RiskSummaryChart from "./riskSummaryChart";
import AbilitySummaryChart from "./abilitySummaryChart";
import BasicInfo from "./basicInfo";
import PerformanceTrend from "./performanceTrend";
import LoadingComponent from "@/components/LoadingComponent";

export default React.forwardRef(
  (
    {
      fundId,
    }: {
      fundId: string;
    },
    ref: LegacyRef<HTMLDivElement>
  ) => {
    const dispatch = useAppDispatch();

    const evaluationReportInfo: EvaluationReportInfoBody = useAppSelector(
      prop(`fundDetail.${fundId}.evaluationReportInfo`)
    );
    const evaluationReportAbilitySummary: EvaluationReportAbilitySummaryBody =
      useAppSelector(
        prop(`fundDetail.${fundId}.evaluationReportAbilitySummary`)
      );
    const evaluationReportRiskSummary: EvaluationReportRiskSummaryBody =
      useAppSelector(prop(`fundDetail.${fundId}.evaluationReportRiskSummary`));
    const riskDetail = useAppSelector(prop(`fundDetail.${fundId}.riskDetail`));

    useEffect(() => {
      if (fundId) {
        dispatch(fetchEvaluationReportInfo(fundId));
        dispatch(fetchEvaluationReportAbilitySummary(fundId));
        dispatch(fetchEvaluationReportRiskSummary(fundId));
        dispatch(fetchRiskDetail(fundId));
      }
    }, [dispatch, fundId]);

    return (
      <div className={style.PageWrap} ref={ref}>
        <LoadingComponent
          actions={[
            fetchEvaluationReportRiskSummary,
            fetchEvaluationReportInfo,
            fetchEvaluationReportAbilitySummary,
          ]}
        >
          <PageHeader pageNumber={1} />
          <div className={cn(style.PageContentWrap, style.FirstPageWrap)}>
            <BasicInfo
              fundId={fundId}
              evaluationReportInfo={evaluationReportInfo}
            />
            <div className={style.Divider} />
            <PerformanceTrend fundId={fundId} />
            <AbilitySummaryChart
              evaluationReportAbilitySummary={evaluationReportAbilitySummary}
            />
            <RiskSummaryChart
              evaluationReportRiskSummary={evaluationReportRiskSummary}
              riskDetail={riskDetail}
            />
          </div>
        </LoadingComponent>
      </div>
    );
  }
);
