import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Button } from "antd";
import { AssetDetailComponent } from "../../../../constant";
import { useFormatMessage } from "@/util/formatMessage";
import mapper from "./mapper";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { formatPercentage } from "@/util/numberFormatter";
import SectorCategoriesSelect from "@/components/sectorCategoriesSelect";
import { getProp, fastProp, fastNth, mapIndexed } from "@/util/opt";
import style from "./index.module.less";
import {
  fetchFundDetailIndustryTrendDate,
  fetchGetETFFundAttributionBrinsonResult,
  fetchGetFundAttributionBrinsonResult,
} from "@/store/fundDetailSlice";
import BenchmarkSelect from "@/components/benchmarkSelect";
import { positionIndexBenchmarkListSelector } from "@/selectors/benchmarks";
import { CSI300_ID } from "@/views/compareManage/constant";
import TaskResult from "@/components/taskResult";
import Summary from "./components/summary";
import TimeRange from "@/components/timeRange";
import positionMapper from "../../../assetPosition/mapper";
import DetailTable from "./components/performanceDistribution";
import DetailChart from "./components/treeChart";
import { benchmarksTypes } from "@/model/fundDetail";

export default React.memo<AssetDetailComponent>(({ fundId, updateHandler }) => {
  const formatMessage = useFormatMessage();
  const {
    sectorCategoryId,
    benchmarks,
    benchmarkMap,
    attributionDate,
    config,
    fundAttributionBrinsonProgress,
    detailBarChartData,
    brinsonSummaryResult,
    brinsonDetailTableResult,
    fundBasicTitle,
  } = useAppSelector((state) => mapper(state, fundId));
  const [startAttributeButtonDisabled, setStartAttributeButtonDisabled] =
    useState(false);
  const dispatch = useAppDispatch();

  const options = useAppSelector(positionIndexBenchmarkListSelector);

  const [benchmarkId, setBenchmarkId] = useState<string>(CSI300_ID);

  useEffect(() => {
    dispatch(fetchFundDetailIndustryTrendDate(fundId));
  }, [dispatch, fundId]);
  const { industryTrendDate } = useAppSelector((state) =>
    positionMapper(state, fundId)
  );
  const isETF = useMemo(
    () => fastProp("isETF")(industryTrendDate),
    [industryTrendDate]
  );

  const startAttribute = useCallback(() => {
    setStartAttributeButtonDisabled(true);
    if (isETF) {
      dispatch(
        fetchGetETFFundAttributionBrinsonResult({
          portfolioId: fundId,
          sectorCategoryId,
          benchmarkId: benchmarkId,
          from: fastNth(0)(attributionDate),
          to: fastNth(1)(attributionDate),
          attributionMeasurement: "RELATIVE",
          attributionMethod: "BRINSON_IMPROVE",
          usingFixedBenchmark: false,
        })
      );
    } else {
      dispatch(
        fetchGetFundAttributionBrinsonResult({
          assetId: fundId,
          sectorCategoryId,
          benchmarks,
          from: fastNth(0)(attributionDate),
          to: fastNth(1)(attributionDate),
        })
      );
    }
  }, [
    attributionDate,
    benchmarks,
    dispatch,
    fundId,
    sectorCategoryId,
    isETF,
    benchmarkId,
  ]);

  const fundName = useMemo(
    () => fastProp("name")(fundBasicTitle),
    [fundBasicTitle]
  );
  return (
    <div className={style.BrinsonRoot}>
      <div className={style.Type}>
        <span className={style.MarginLeft5}>
          <span>{formatMessage("benchmark")}:&nbsp;&nbsp;</span>
          {isETF ? (
            <BenchmarkSelect
              className={style.MarginRight10}
              value={benchmarkId}
              onChange={(value: string) => {
                setStartAttributeButtonDisabled(false);
                setBenchmarkId(value);
              }}
              customOptions={options}
            />
          ) : (
            mapIndexed(
              ({ benchmarkId, weight }: benchmarksTypes, index: number) => (
                <>
                  {index === 0 && (
                    <>
                      <span>
                        {getProp(`${benchmarkId}.name`)(benchmarkMap)}*
                      </span>
                      <span>{formatPercentage(weight)}+</span>
                    </>
                  )}
                  {index === 2 && (
                    <>
                      <span>
                        {getProp(`${benchmarkId}.name`)(benchmarkMap)}*
                      </span>
                      <span>{formatPercentage(weight)}</span>
                    </>
                  )}
                </>
              )
            )(benchmarks)
          )}
        </span>
        <div className={style.MarginRight10}>
          <span>{formatMessage("periodOfTime")}</span>&nbsp;&nbsp;
          <TimeRange
            value={attributionDate || []}
            minDate={fastProp("minDate")(config)}
            maxDate={fastProp("maxDate")(config)}
            onChange={(value) => {
              setStartAttributeButtonDisabled(false);
              updateHandler("attributionDate")(value);
            }}
          />
        </div>
        <div>
          <span>{formatMessage("industry")}</span>&nbsp;&nbsp;
          <SectorCategoriesSelect
            className={style.MarginRight10}
            value={sectorCategoryId}
            onChange={(value) => {
              setStartAttributeButtonDisabled(false);
              updateHandler("sectorCategoryId")(value);
            }}
          />
        </div>
        <div className={style.Flex}>
          <Button
            type="primary"
            className={style.StartAtt}
            onClick={startAttribute}
            disabled={startAttributeButtonDisabled}
          >
            {formatMessage("startAttribution")}
          </Button>
        </div>
      </div>
      <div className={style.Result}>
        {fundAttributionBrinsonProgress !== undefined ? (
          <TaskResult task={fundAttributionBrinsonProgress}>
            <>
              <Summary
                summary={brinsonSummaryResult}
                from={fastProp("from")(brinsonSummaryResult)}
                to={fastProp("to")(brinsonSummaryResult)}
                exportTitle={formatMessage("BRINSON")}
                fundName={fundName}
              />
              <DetailChart
                detailBarChartData={detailBarChartData}
                fundName={fundName}
                isETF={isETF}
              />
              <DetailTable
                brinsonDetailTableResult={brinsonDetailTableResult}
                period={fastProp("period")(brinsonDetailTableResult)}
                exportTitle={formatMessage("BRINSON")}
                selectPeriod={updateHandler("period")}
                selectablePeriods={fastProp("selectablePeriods")(
                  brinsonDetailTableResult
                )}
                isETF={isETF}
              />
            </>
          </TaskResult>
        ) : (
          <div>
            <div className={style.EmptyResult}></div>
          </div>
        )}
      </div>
    </div>
  );
});
