import React, { useContext, useRef, useState } from "react";
import { Steps, Card, Button, message, Space } from "antd";
import { map } from "lodash/fp";
import { useMemoizedFn } from "ahooks";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useFormatMessage } from "@/util/formatMessage";
import style from "./index.module.less";
import { manualCreatePortfolioSteps } from "./constant";
import FundAllocation from "./fundAllocation";
import BackTestingAllocation from "./backTestingAllocation";
import SavePortfolio, { SavePortfolioRef } from "./savePortfolio";
import { useAppSelector } from "@/hooks/redux";
import { useCloseModal } from "./hooks";

// 保存组合
const useManageSavePortfolio = () => {
  const savePortfolioRef = useRef<SavePortfolioRef>(null);
  const formatMessage = useFormatMessage();
  const onSavePortfolio = useMemoizedFn((stackBack: () => any) => {
    savePortfolioRef.current?.onSavePortfolio().then(() => {
      message.success(formatMessage("createPortfolioSuccess"));
      stackBack();
    });
  });
  return {
    savePortfolioRef,
    onSavePortfolio,
  };
};
// 管理总的操作步骤
const useManageStepOperate = () => {
  const { stackBack } = useContext(PlatformNavigationContext);
  const { onSavePortfolio, savePortfolioRef } = useManageSavePortfolio();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { allocationData, backTestingFormData } = useAppSelector(
    (state) => state.createPortfolio.manualCreatePortfolio
  );
  const [steps, setSteps] = useState(manualCreatePortfolioSteps.fundAllocation);
  const goBack = useMemoizedFn(() => {
    setSteps((step) => step - 1);
    setButtonDisabled(false);
  });
  const goNext = useMemoizedFn(() => setSteps((step) => step + 1));
  const onNext = useMemoizedFn(() => {
    if (steps === manualCreatePortfolioSteps.savePortfolio) {
      return onSavePortfolio(stackBack);
    }
    return goNext();
  });
  return {
    goBack,
    onNext,
    steps,
    savePortfolioRef,
    allocationData,
    buttonDisabled,
    setButtonDisabled,
    backTestingFormData,
  };
};

const { Step } = Steps;
export default React.memo((props?: { fundIds: string[] }) => {
  const formatMessage = useFormatMessage();
  const {
    steps,
    goBack,
    onNext,
    buttonDisabled,
    allocationData,
    savePortfolioRef,
    setButtonDisabled,
    backTestingFormData,
  } = useManageStepOperate();
  const cancel = useCloseModal("manualCreatePortfolio");
  return (
    <div className={style.LayoutContainer}>
      <div className={style.LayoutScrollYContent}>
        <Card className={style.Card}>
          <Steps current={steps} className={style.StepContainer}>
            {map(({ message }) => <Step key={message} title={message} />)(
              manualCreatePortfolioSteps.steps
            )}
          </Steps>
        </Card>
        {steps === manualCreatePortfolioSteps.fundAllocation && (
          <FundAllocation
            defaultIds={props?.fundIds}
            onError={setButtonDisabled}
          />
        )}
        {steps === manualCreatePortfolioSteps.backTestingAllocation && (
          <BackTestingAllocation
            data={allocationData}
            type="manualCreatePortfolio"
          />
        )}
        {steps === manualCreatePortfolioSteps.savePortfolio && (
          <SavePortfolio
            data={allocationData}
            onError={setButtonDisabled}
            ref={savePortfolioRef}
            formData={backTestingFormData}
          />
        )}
      </div>
      <div className={style.LayoutFooter}>
        <Space>
          <Button className={style.OperateButton} onClick={cancel}>
            {formatMessage("cancel")}
          </Button>
          {steps !== manualCreatePortfolioSteps.fundAllocation && (
            <Button
              className={style.OperateButton}
              type="primary"
              onClick={goBack}
            >
              {formatMessage("thePreviousStep")}
            </Button>
          )}
          <Button
            className={style.OperateButton}
            type="primary"
            disabled={buttonDisabled}
            onClick={onNext}
          >
            {steps === manualCreatePortfolioSteps.savePortfolio
              ? formatMessage("savePortfolio")
              : formatMessage("theNextStep")}
          </Button>
        </Space>
      </div>
    </div>
  );
});
