import { Card, Row, Col, Table, TableColumnProps } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { map, concat, prop, sumBy, forEach, fromPairs } from "lodash/fp";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { fetchTop10PositionStocks } from "@/store/compareManage";
import { formatPercentage, addZeroIndex } from "@/util/numberFormatter";
import inStyle from "./index.module.less";
import style from "../../index.module.less";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { isEmpty } from "lodash/fp";
import { flow } from "lodash";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { fundIdMapSelector } from "@/selectors/fund";
import { allStocksMapSelector } from "@/selectors/stocks";
import ReportDateSelector from "./reportDateSelector";
import { selectedReportDateSelector } from "../selectors";

const getColumns = (
  formatMessage: FormatMessageFunc,
  fundIds: string[],
  date: string
) =>
  flow(
    map<string, TableColumnProps<any>>((id) => ({
      title: date,
      dataIndex: id,
      align: "center",
      width: 250,
      render: (value, record, index) => {
        if (index >= 1 && index <= 10) {
          return (
            <div className={inStyle.Top10StocksColumn}>
              <span>{fastProp("stockName")(value)}</span>
              <span>{formatPercentage(fastProp("weight")(value))}</span>
            </div>
          );
        }
        if (index >= 11 && index <= 12) {
          return formatPercentage(value);
        }
        return value;
      },
    })),
    concat({
      title: formatMessage("endOfDate"),
      dataIndex: "index",
      align: "center",
      width: 100,
    })
  )(fundIds);

const useGetTableData = (selectedDate: string) => {
  const formatMessage = useFormatMessage();
  const baseData = useAppSelector(
    prop("compareManage.fundCompare.top10PositionStocks")
  );
  const top10PositionStocks = useMemo(
    () => fastProp(selectedDate)(baseData),
    [baseData, selectedDate]
  );
  const fundsMap = useAppSelector(fundIdMapSelector);
  const stocksMap = useAppSelector(allStocksMapSelector);
  const { fundNameRow, totalRow, concentrationRow } = useMemo(() => {
    const fundNameRow: any = {};
    const totalRow: any = {};
    const concentrationRow: any = {};
    forEach(({ fundId, stockWeightList, concentrationRatio }) => {
      const sumNetValue = sumBy("weight")(stockWeightList);
      fundNameRow[fundId] = prop(`${fundId}.name`)(fundsMap);
      totalRow[fundId] = sumNetValue;
      concentrationRow[fundId] = concentrationRatio;
    })(top10PositionStocks);
    return {
      fundNameRow: {
        index: formatMessage("serialNumber"),
        ...fundNameRow,
      },
      totalRow: {
        index: formatMessage("total"),
        ...totalRow,
      },
      concentrationRow: {
        index: formatMessage("concentration"),
        ...concentrationRow,
      },
    };
  }, [formatMessage, fundsMap, top10PositionStocks]);

  const top10StockData = useMemo(
    () =>
      mapIndexed((_: undefined, idx: number) => {
        const topStocks = flow(
          map(({ fundId, stockWeightList }) => {
            const { stockCode, weight } = fastNth(idx)(stockWeightList) || {};
            return [
              fundId,
              {
                stockName: prop(`${stockCode}.name`)(stocksMap),
                weight,
              },
            ];
          }),
          fromPairs
        )(top10PositionStocks);
        return {
          index: addZeroIndex(idx + 1),
          ...topStocks,
        };
      })(new Array(10)),
    [stocksMap, top10PositionStocks]
  );
  return [fundNameRow, ...top10StockData, totalRow, concentrationRow];
};

export default React.memo<{
  className?: string;
  fundIds: string[];
}>(({ className, fundIds }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const selectedDefaultDate = useAppSelector(selectedReportDateSelector);
  const [selectedDate, setSelectedDate] = useState(
    selectedDefaultDate as string
  );
  useEffect(() => {
    if (!isEmpty(fundIds) && selectedDate) {
      dispatch(
        fetchTop10PositionStocks({
          fundIds,
          date: selectedDate,
        })
      );
    }
  }, [dispatch, fundIds, selectedDate]);
  const columns = useMemo(
    () => getColumns(formatMessage, fundIds, selectedDate),
    [selectedDate, formatMessage, fundIds]
  );
  const dataSource = useGetTableData(selectedDate);
  return (
    <Card bordered={false} className={className}>
      <Row justify="space-between" align="middle" className={style.RowTitle}>
        <Col>
          <h3>{formatMessage("top10Stocks")}</h3>
        </Col>
        <Col>
          <ReportDateSelector value={selectedDate} onChange={setSelectedDate} />
        </Col>
      </Row>
      <Table
        columns={columns}
        bordered
        dataSource={dataSource}
        pagination={false}
        className={style.Table}
      />
    </Card>
  );
});
