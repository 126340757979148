import { prop } from "lodash/fp";
import { factorsFormatter } from "@/constant/factorFormatter";
import { FormatMessageFunc } from "@/util/formatMessage";
import { TableProps } from "antd";

type TablePropsType = TableProps<any>;

export const getDataSource = (
  categoryMaxAndMin: Record<string, any>,
  factorRatioData: any
) => {
  return [
    {
      key: 1,
      maxAssetContribution: prop("maxAssetContribution.returnValue")(
        categoryMaxAndMin
      ),
      minAssetContribution: prop("minAssetContribution.returnValue")(
        categoryMaxAndMin
      ),
      portfolioReturn: prop("portfolioReturn.returnValue")(categoryMaxAndMin),
      benchmarkAttribution: prop("benchmarkAttribution.returnValue")(
        categoryMaxAndMin
      ),
      ...factorRatioData,
    },
  ];
};

export const getColumns = (
  categoryMaxAndMin: Record<string, any>,
  formatMessage: FormatMessageFunc
): TablePropsType["columns"] => {
  return [
    {
      dataIndex: "maxDrawdown",
      key: "maxDrawdown",
      title: formatMessage("maxDrawdown"),
      align: "center",
      render: factorsFormatter.maxDrawdown,
    },
    {
      dataIndex: "sharpe",
      key: "sharpe",
      title: formatMessage("sharpe"),
      align: "center",
      render: factorsFormatter.sharpeRatio,
    },
    {
      dataIndex: "infoRatio",
      key: "infoRatio",
      title: formatMessage("infoRatio"),
      align: "center",
      render: factorsFormatter.informationRatio,
    },
    {
      dataIndex: "trackDeviation",
      key: "trackDeviation",
      title: formatMessage("trackDeviation"),
      align: "center",
      render: factorsFormatter.trackDeviation,
    },
    {
      dataIndex: "portfolioReturn",
      key: "portfolioReturn",
      title: formatMessage("portfolioReturn"),
      align: "center",
      render: factorsFormatter.yield,
    },
    {
      dataIndex: "benchmarkAttribution",
      key: "benchmarkAttribution",
      title: formatMessage("benchmarkAttribution"),
      align: "center",
      render: factorsFormatter.yield,
    },
    {
      dataIndex: "maxAssetContribution",
      key: "maxAssetContribution",
      title: prop("maxAssetContribution.name")(categoryMaxAndMin) || "--",
      align: "center",
      render: factorsFormatter.yield,
    },
    {
      dataIndex: "minAssetContribution",
      key: "minAssetContribution",
      title: prop("minAssetContribution.name")(categoryMaxAndMin) || "--",
      align: "center",
      render: factorsFormatter.yield,
    },
  ];
};
