import dayjs from "dayjs";
import { filter, minBy, maxBy, first, concat } from "lodash/fp";
import { dateFormat, getTime } from "@/util/dateFormat";
import { fastProp } from "@/util/opt";
import {
  FROM_THIS_YEAR,
  getThisYear,
  yieldStatisticRange,
} from "../statisticRange";

export const getIntersectionDate = (
  startDates: string[],
  endDates: string[]
) => {
  const maxStartDates = maxBy(getTime)(startDates) as string;
  const minEndDates = minBy(getTime)(endDates) as string;
  if (!maxStartDates || !minEndDates || minEndDates < maxStartDates)
    return null;
  return [maxStartDates, minEndDates];
};

export const getStatisticsRangeStartDate = (
  range: string,
  dataSource: string
) => {
  if (!dataSource) return dataSource;
  if (range === FROM_THIS_YEAR) return getThisYear();
  const { period, count } = fastProp(range)(yieldStatisticRange);
  if (!period || !count) return null;
  return dateFormat(dayjs(dataSource).subtract(period, count).add(1, "day"));
};

export const getRangeDate = (thisYearDate: string) => (dates: string[]) => {
  return filter<string>((item) => item >= thisYearDate)(dates);
};

export const addStartDate = (firstDate: string) => (dates: string[]) => {
  if (first(dates) === firstDate) return dates;
  return concat(firstDate)(dates);
};
