import React, { useMemo } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { some, size, isEmpty, remove } from "lodash/fp";
import { colors } from "@/util/colors";
import CompareSelector, { CompareInfo } from "./compareSelector";
import { fastNth, mapIndexed } from "@/util/opt";
import { Button } from "antd";
import { MAX_COMPARE_SELECT_SIZE } from "../constant";
import { PromiseType } from "@/model/promise";
import { optionsType } from "../interface";
import style from "./index.module.less";

export default React.memo<{
  compares: string[];
  onChange: (index: number) => (value: string) => any;
  onAdd: () => void;
  onClear: () => void;
  onRemove: (id: number) => any;
  onStart: () => PromiseType<any>;
  buttonDisabled?: boolean;
  type: CompareInfo["type"];
  data?: optionsType[][] | undefined;
}>(
  ({
    compares,
    onChange,
    onAdd,
    onClear,
    onRemove,
    onStart,
    type,
    buttonDisabled = false,
    data,
  }) => {
    const formatMessage = useFormatMessage();
    const compareSize = size(compares);
    const selectAvailable = useMemo(
      () => compareSize < MAX_COMPARE_SELECT_SIZE,
      [compareSize]
    );
    const startCompareDisabled = useMemo(
      () =>
        compareSize <= 1 ||
        compareSize > MAX_COMPARE_SELECT_SIZE ||
        some(isEmpty)(compares) ||
        buttonDisabled,
      [compareSize, compares, buttonDisabled]
    );
    return (
      <Row wrap gutter={12}>
        {mapIndexed((fund: string, index: number) => (
          <Col key={fund + index}>
            <CompareSelector
              type={type}
              color={colors[index]}
              value={fund}
              onRemove={() => onRemove(index)}
              removeIds={remove<string>((id) => id === fund)(compares)}
              onChange={onChange(index)}
              hideRemoveIcon={index === 0}
              customData={fastNth(index)(data || [])}
            />
          </Col>
        ))(compares)}
        {selectAvailable && (
          <Col>
            <Button
              className={style.AddCompare}
              onClick={onAdd}
              icon={<PlusOutlined />}
            >
              {formatMessage("addCompare")}
            </Button>
          </Col>
        )}
        <Col>
          <Button onClick={onClear}>{formatMessage("emptySelected")}</Button>
        </Col>
        <Col>
          <Button
            type="primary"
            disabled={startCompareDisabled}
            onClick={onStart}
            className={style.CompareBtn}
          >
            {formatMessage("startCompare")}
          </Button>
        </Col>
      </Row>
    );
  }
);
