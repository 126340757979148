import React, { useMemo } from "react";
import { first, flow, join, map, split } from "lodash/fp";

import {
  EvaluationReportRiskSummaryBody,
  LEVEL_ENUM,
  RiskDetailParam,
} from "@/model/fundDetail";
import { useFormatMessage } from "@/util/formatMessage";
// import { useAppSelector } from "@/hooks/redux";
// import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { fastProp, mapIndexed } from "@/util/opt";

import style from "../index.module.less";
import { GuageChart } from "./guageChart";
import SecondLevelName from "../../../secondLevelName";

export default React.memo(
  ({
    evaluationReportRiskSummary,
    riskDetail,
  }: {
    evaluationReportRiskSummary: EvaluationReportRiskSummaryBody | undefined;
    riskDetail?: RiskDetailParam;
  }) => {
    const formatMessage = useFormatMessage();

    const { totalRisk, styleRisk, industryRisk, mainFactorRisk } =
      evaluationReportRiskSummary || {};

    // const industryMap = useAppSelector(sectorCategoriesMapSelector);

    const top3IndustryInfo = useMemo(
      () =>
        flow(
          fastProp("industryRiskDetails"),
          map(fastProp("industryName")),
          join("、")
        )(riskDetail),
      [riskDetail]
    );

    const factorCongestionInfo = useMemo(
      () =>
        flow(
          fastProp("factorCrowding"),
          mapIndexed(
            (value: string, key: string) =>
              `${key}${formatMessage(`${value}_CROWDING`)}`
          ),
          join("、")
        )(mainFactorRisk),
      [formatMessage, mainFactorRisk]
    );

    const riskLevelSummary = useMemo(() => {
      switch (fastProp("riskLevel")(styleRisk)) {
        case LEVEL_ENUM.LOW:
          return formatMessage("higher");
        case LEVEL_ENUM.MEDIUM:
          return formatMessage("commonly");
        case LEVEL_ENUM.HIGH:
          return formatMessage("lower");
        default:
          return "";
      }
    }, [formatMessage, styleRisk]);

    const fundStyleSummary = useMemo(() => {
      const fundStyleMap = {
        g: formatMessage("fundStyle_G"),
        v: formatMessage("fundStyle_V"),
        m: formatMessage("fundStyle_M"),
      };
      return flow(
        fastProp("fundStyle"),
        split("MyTag_rating23_"),
        join(""),
        first,
        (val) => fastProp(val)(fundStyleMap)
      )(styleRisk);
    }, [formatMessage, styleRisk]);

    return (
      <div className={style.RiskSummaryWrap}>
        <SecondLevelName name={formatMessage("riskProbe")} hideColon>
          <div className={style.TotalRisk}>
            {totalRisk && formatMessage(`${totalRisk}_RISK_TITLE`)}
          </div>
        </SecondLevelName>
        <div className={style.RiskChartWrap}>
          <GuageChart
            formatMessage={formatMessage}
            riskItem={styleRisk}
            title={formatMessage("styleDynamicAlert")}
          >
            <div className={style.Summary}>
              <div className={style.First}>
                {formatMessage("fundsStyle")}：{fundStyleSummary}
              </div>
              <div className={style.Second}>
                {formatMessage("matchingDegreeWithMarketAdvantageStyle")}
                {riskLevelSummary}
              </div>
            </div>
          </GuageChart>
          <GuageChart
            formatMessage={formatMessage}
            riskItem={industryRisk}
            title={formatMessage("recentSituationOfHeavyWarehouseIndustry")}
          >
            <div className={style.Summary}>
              <div className={style.First}>{top3IndustryInfo}</div>
              <div className={style.Second}>{`${formatMessage(
                `${fastProp("industryCongestion")(industryRisk)}_CROWDING`
              )}、${formatMessage(
                `${fastProp("industryProsperity")(industryRisk)}_PROSPERITY`
              )}`}</div>
            </div>
          </GuageChart>
          <GuageChart
            formatMessage={formatMessage}
            riskItem={mainFactorRisk}
            title={formatMessage("recentStatusOfMainFactors")}
          >
            <div className={style.Summary}>
              <div className={style.First}>{factorCongestionInfo}</div>
            </div>
          </GuageChart>
        </div>
      </div>
    );
  }
);
