import { Card, Space } from "antd";
import { fastProp } from "@/util/opt";
import { map, pick, prop } from "lodash/fp";
import React, { useMemo } from "react";
import { useFormatMessage, FormatMessageFunc } from "@/util/formatMessage";
import { fixedNumber } from "@/util/numberFormatter";
import HorizontalTable from "@/components/horizontalTable";
import Rating from "@/components/rating";
import AddOptional from "@/components/addOptional";
import { getRiskLevel } from "@/util/riskLevel";
import ToFundManagerDetailPage from "@/components/navigateToPage/toFundManagerDetailPage";
import style from "../../index.module.less";
import inStyle from "./index.module.less";
import { useAppSelector } from "@/hooks/redux";
import { factorsFormatter } from "@/constant/factorFormatter";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import ToFundCompanyDetailPage from "@/components/navigateToPage/toFundCompanyDetailPage";

type SubscriptionRedemptionStatus = {
  purchStatus: string;
  redemStatus: string;
};
type FundManagerStatus = {
  id?: string;
  name?: string;
}[];
const useGetTableData = () => {
  const compareFundTask = useAppSelector(
    prop("compareManage.fundCompare.compareFundTask")
  );
  const formatMessage = useFormatMessage();
  return useMemo(() => {
    return map((item: Record<string, any>) => ({
      ...pick([
        "foundDate",
        "fundBenchmark",
        "fundCode",
        "fundId",
        "fundNetVal",
        "fundScale",
        "fundName",
        "fundScore",
        "fundSecondInvestType",
        "fundCompany",
        "riskLevel",
        "fundManager",
      ])(item),
      subscriptionRedemptionStatus: {
        purchStatus: fastProp("purchStatus")(item),
        redemStatus: fastProp("redemStatus")(item),
      },
      render: (
        value:
          | string
          | number
          | SubscriptionRedemptionStatus
          | FundManagerStatus,
        record: Record<string, any>
      ) => {
        switch (fastProp("dataIndex")(record)) {
          case "fundCode":
            return (
              <ToFundDetailPage
                name={value as string}
                id={fastProp("fundId")(item)}
              />
            );
          case "fundCompany":
            return (
              <ToFundCompanyDetailPage
                name={value as string}
                id={fastProp("fundCompanyId")(item)}
              />
            );
          case "rating":
            return <Rating rating={value as number} />;
          case "fundNetVal":
            return fixedNumber(value as number, { digit: 4 });
          case "fundScale":
            return factorsFormatter.scaleWithHundredMillion(value as number);
          case "subscriptionRedemptionStatus":
            return `${
              fastProp("purchStatus")(value as SubscriptionRedemptionStatus) ||
              ""
            }${
              fastProp("redemStatus")(value as SubscriptionRedemptionStatus) ||
              ""
            }`;
          case "riskLevel":
            return getRiskLevel(formatMessage, value as string);
          case "fundManager":
            return map<any, JSX.Element>(({ name, score, id } = {}) => (
              <div className={inStyle.ManagerColumn}>
                <ToFundManagerDetailPage
                  id={id}
                  name={name}
                  className={inStyle.ManagerName}
                />
                <Rating rating={score} />
              </div>
            ))(value as FundManagerStatus);
          case "fundScore":
            return <Rating rating={(value as number) || 0} />;
          default:
            return value;
        }
      },
      width: 400,
    }))(compareFundTask);
  }, [compareFundTask, formatMessage]);
};

const getColumns = (formatMessage: FormatMessageFunc) => [
  {
    columnName: formatMessage("fundCode"),
    dataIndex: "fundCode",
  },
  {
    columnName: formatMessage("fundType"),
    dataIndex: "fundSecondInvestType",
  },
  {
    columnName: formatMessage("fundManagers"),
    dataIndex: "fundManager",
  },
  {
    columnName: formatMessage("fundCompanies"),
    dataIndex: "fundCompany",
  },
  {
    columnName: formatMessage("foundDate"),
    dataIndex: "foundDate",
  },
  {
    columnName: formatMessage("latestNetUnitValue"),
    dataIndex: "fundNetVal",
  },
  {
    columnName: formatMessage("latestScaleWithHundredMillion"),
    dataIndex: "fundScale",
  },
  {
    columnName: formatMessage("fundRating"),
    dataIndex: "fundScore",
  },
  {
    columnName: formatMessage("fofriskLevel"),
    dataIndex: "riskLevel",
  },
  {
    columnName: formatMessage("performanceBenchmark"),
    dataIndex: "fundBenchmark",
  },
  {
    columnName: formatMessage("subscriptionRedemptionStatus"),
    dataIndex: "subscriptionRedemptionStatus",
  },
];

export default React.memo<{
  className?: string;
}>(({ className }) => {
  const tableData = useGetTableData();

  const formatMessage = useFormatMessage();
  const headingColumn = useMemo(
    () => ({
      title: formatMessage("fundName"),
      dataIndex: "columnName",
      width: 200,
      render: (fundName: string, record: Record<string, any>) => (
        <div className={style.FundNameWrap}>
          <ToFundDetailPage name={fundName} id={fastProp("fundId")(record)} />
          {fastProp("fundId")(record) && (
            <AddOptional id={fastProp("fundId")(record)} />
          )}
        </div>
      ),
    }),
    [formatMessage]
  );
  const columns = useMemo(() => getColumns(formatMessage), [formatMessage]);

  return (
    <Card bordered={false} className={className}>
      <Space direction="vertical" className={style.fullWidth}>
        <h3>{formatMessage("basicInfo")}</h3>
        <HorizontalTable
          id="fundId"
          titleKey="fundName"
          key="dataIndex"
          headingColumn={headingColumn}
          columns={columns}
          dataSource={tableData}
          className={style.BasicInfoTable}
          pagination={false}
          bordered
        />
      </Space>
    </Card>
  );
});
