import { useFormatMessage } from "@/util/formatMessage";
import { Col, Form, Input, Row, Space } from "antd";
import React from "react";
import demandIcon from "@/assets/customerAnalysisIcons/demandIcon.png";
import NumberInput from "@/components/numberInput";
import style from "./index.module.less";

export default React.memo(({ disabled }: { disabled: boolean }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={style.FullWidth}>
      <div className={style.TitleContent}>
        <Space align="start" size={0}>
          <img src={demandIcon} />
          <span className={style.SubTitle}>
            {formatMessage("demandInformation")}
          </span>
        </Space>
      </div>
      <Space direction="vertical" size={16} className={style.FullWidth}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item
              label={formatMessage("expectedIncome")}
              name="expectedReturn"
            >
              <NumberInput
                disabled={disabled}
                addonAfter="%"
                type="PERCENTAGE"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("expectedRisk")}
              name="expectedRisk"
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("expectedInput")}
              name="expectedInvestment"
            >
              <NumberInput
                disabled={disabled}
                suffix={formatMessage("thousandYuan")}
                type="THOUSANDTH"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item
              label={formatMessage("holdingPeriod")}
              name="holdingTime"
              rules={[{ max: 20, message: formatMessage("noMoreThan20Words") }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("fundPreference")}
              name="fundPreference"
              rules={[{ max: 20, message: formatMessage("noMoreThan20Words") }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("industrialPreference")}
              name="industryPreference"
              rules={[{ max: 20, message: formatMessage("noMoreThan20Words") }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </div>
  );
});
