import { ApiResponse } from "./response";
export interface LoginEntity {
  username: string;
  password: string;
  captcha: string;
  sessionId: string;
  datasourceId: string;
}

export const createEmptyLogin = (
  sessionId: string,
  datasourceId: string
): LoginEntity => ({
  username: "",
  password: "",
  captcha: "",
  sessionId,
  datasourceId,
});

export interface LoginResponse extends ApiResponse {
  id: string;
  admin: number;
  authorization: string;
  username: string;
}

export interface RegisterEntity {
  username: string;
  password: string;
  confirmedPassword: string;
}

export const createEmptyRegister = (): RegisterEntity => ({
  username: "",
  password: "",
  confirmedPassword: "",
});

export interface LoginFormStatusInterface {
  username: boolean;
  password: boolean;
}

export const createLoginFormStatus: LoginFormStatusInterface = {
  username: false,
  password: false,
};

export interface RegisterFormStatusInterface extends LoginFormStatusInterface {
  confirmedPassword: boolean;
}

export const createRegisterFormStatus: RegisterFormStatusInterface = {
  username: false,
  password: false,
  confirmedPassword: false,
};

export interface DataSource {
  sort: number;
  id: string;
  lastUpdateDate: string;
}

export interface UserConfig {
  config: string;
  configId: string;
  moduleId: string;
  updateTime: number;
  userId: string;
}

export interface User {
  createTime: string;
  frozen: number;
  id: string;
  nickname: string;
  username: string;
}
