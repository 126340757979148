import React, { useMemo } from "react";
import { fastProp } from "@/util/opt";
// import TreeChart from "@/echarts/treeChart";
import {
  PerformanceTreeChart,
  PerformanceTreeData,
} from "@/base-components/treeChart";
import style from "./index.module.less";
import { useFormatMessage, FormatMessageFunc } from "@/util/formatMessage";
// import { treeDataType } from "@/model/fundDetail";

const getTreeData = (
  detailBarChartData: Record<string, any>,
  formatMessage: FormatMessageFunc,
  fundName: string,
  isETF: boolean
) => {
  const {
    portfolioAttribution,
    benchmarkAttribution,
    activeAttribution,

    allocationAttribution,
    nonAShareAttribution,
    nonEquityAttribution,
    otherAttribution,
    aShareAttribution,

    aShareAllocationAttribution,
    aShareSelectionAttribution,
    aShareTimingAttribution,
    aShareOtherAttribution,
  } = detailBarChartData;
  const fourLevel = [
    {
      id: "allocationAttribution",
      name: formatMessage("allocationAttribution"),
      value: aShareAllocationAttribution,
    },
    {
      id: "selectionAttribution",
      name: formatMessage("selectionAttribution"),
      value: aShareSelectionAttribution,
    },
    {
      id: "timingAttribution",
      name: formatMessage("timingAttribution"),
      value: aShareTimingAttribution,
    },
    {
      id: "forthFloorOtherAttributions",
      name: isETF
        ? formatMessage("otherAttributions")
        : formatMessage("forthFloorOtherAttributions"),
      value: aShareOtherAttribution,
    },
  ];
  const threeLevel = [
    {
      id: "assetallocationAttribution",
      name: formatMessage("assetallocationAttribution"),
      value: allocationAttribution,
    },
    {
      id: "aShareAttribution",
      name: formatMessage("aShareAttribution"),
      value: aShareAttribution,
      children: fourLevel,
    },
    {
      id: "nonAShareAttribution",
      name: formatMessage("nonAShareAttribution"),
      value: nonAShareAttribution,
    },
    {
      id: "nonEquityAttribution",
      name: formatMessage("nonEquityAttribution"),
      value: nonEquityAttribution,
    },
    {
      id: "threeFloorOtherAttributions",
      name: formatMessage("threeFloorOtherAttributions"),
      value: otherAttribution,
    },
  ];
  const childrenlevel = isETF ? fourLevel : threeLevel;
  return {
    id: "portfolioAttribution",
    name: fundName,
    value: portfolioAttribution,
    children: [
      {
        id: "benchmarkAttribution",
        name: formatMessage("benchmarkAttribution"),
        value: benchmarkAttribution,
      },
      {
        id: "activeAttribution",
        name: formatMessage("activeAttribution"),
        value: activeAttribution,
        children: [...childrenlevel],
      },
    ],
  };
};

export default React.memo<{
  detailBarChartData: Record<string, any>;
  fundName: string;
  isETF: boolean;
}>(({ detailBarChartData, fundName, isETF }) => {
  const formatMessage = useFormatMessage();
  const treeData: PerformanceTreeData = useMemo(
    () => getTreeData(detailBarChartData, formatMessage, fundName, isETF),
    [detailBarChartData, formatMessage, fundName, isETF]
  );
  const startDate: string = useMemo(
    () => fastProp("startDate")(detailBarChartData),
    [detailBarChartData]
  );
  const endDate: string = useMemo(
    () => fastProp("endDate")(detailBarChartData),
    [detailBarChartData]
  );
  return (
    <div className={style.Tree}>
      <div className={style.Title}>
        {formatMessage("PerformanceDismantling")}-{formatMessage("treeView")}
      </div>
      <div className={style.TreeChart}>
        <span className={style.MarginLeft}>
          {formatMessage("attributionData")}:
        </span>
        <span>{startDate}</span>~<span>{endDate}</span>
        <PerformanceTreeChart data={treeData} backgroundColor="#f5f7fb" />
      </div>
    </div>
  );
});
