import React, { useMemo } from "react";
import { fastProp } from "@/util/opt";
import { Table } from "antd";
import { useFormatMessage, FormatMessageFunc } from "@/util/formatMessage";
import LineChart, { LineChartOpts } from "@/echarts/lineChart";
import { LineSeriesOption } from "echarts";
import { map, prop } from "lodash/fp";
import style from "../index.module.less";
import { useGetFactors } from "@/views/portfolioManage/portfolioAnalysis/hooks";
import { lineChartTableFactor as factors } from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/performanceDismantling/constant";
import { factorsFormatter } from "@/constant/factorFormatter";
import cn from "classnames";
import { getBrinsonAndNetValueLineChartOptions } from "../../constant";

const getColumns = (formatMessage: FormatMessageFunc) => [
  {
    title: <span>{formatMessage("maxDrawdown")}</span>,
    dataIndex: "maxDrawdown",
    align: "center",
    render: factorsFormatter?.maxDrawdown,
  },
  {
    title: <span>{formatMessage("sharpeRatio")}</span>,
    dataIndex: "sharpe",
    align: "center",
    render: factorsFormatter?.sharpeRatio,
  },
  {
    title: <span>{formatMessage("infoRatio")}</span>,
    dataIndex: "infoRatio",
    align: "center",
    render: factorsFormatter?.informationRatio,
  },
  {
    title: <span>{formatMessage("trackDeviation")}</span>,
    dataIndex: "trackDeviation",
    align: "center",
    render: factorsFormatter?.trackDeviation,
  },
  {
    title: <span>{formatMessage("benchmarkAttribution")}</span>,
    dataIndex: "benchmarkActualAttribution",
    align: "center",
    render: factorsFormatter?.yield,
  },
  {
    title: <span>{formatMessage("portfolioAttribution")}</span>,
    dataIndex: "portfolioActualAttribution",
    align: "center",
    render: factorsFormatter?.yield,
  },
  {
    title: <span>{formatMessage("allocationAttribution")}</span>,
    dataIndex: "allocationAttribution",
    align: "center",
    render: factorsFormatter?.yield,
  },
  {
    title: <span>{formatMessage("selectionFundAttribution")}</span>,
    dataIndex: "selectionAttribution",
    align: "center",
    render: factorsFormatter?.yield,
  },
  {
    title: <span>{formatMessage("interactionAttribution")}</span>,
    dataIndex: "timingOrInteractionAttribution",
    align: "center",
    render: factorsFormatter?.yield,
  },
];
export default React.memo<{
  brinsonLineChart: Record<string, any>;
  id: string;
  section: string;
}>(({ brinsonLineChart, id, section }) => {
  const formatMessage = useFormatMessage();
  const factorsRatio = useGetFactors(id, section, factors);
  const listTableData = useMemo(
    () => fastProp("listData")(brinsonLineChart),
    [brinsonLineChart]
  );
  const listData = useMemo(
    () => [{ ...factorsRatio, ...listTableData }],
    [factorsRatio, listTableData]
  );
  const chartData = useMemo(
    () => fastProp("chartData")(brinsonLineChart),
    [brinsonLineChart]
  );
  const { dataSource, dates, names } = chartData;

  const columns: any = useMemo(
    () => getColumns(formatMessage),
    [formatMessage]
  );

  const options = useMemo<LineChartOpts["options"]>(
    () => getBrinsonAndNetValueLineChartOptions(dates),
    [dates]
  );
  const series = useMemo<LineChartOpts["series"]>(
    () =>
      map<string, LineSeriesOption>((v) => ({
        type: "line",
        name: formatMessage(v),
        showSymbol: false,
        smooth: false,
        lineStyle: {
          width: 1.5,
        },
        data: map((date: string) => prop(`${date}.${v}`)(dataSource) || 0)(
          dates
        ),
      }))(names),
    [names, formatMessage, dates, dataSource]
  );
  return (
    <div>
      <Table
        columns={columns}
        bordered
        dataSource={listData}
        pagination={false}
        className={cn(style.Table, style.BrinsonTable)}
      />
      <LineChart options={options} series={series} height={400} />
    </div>
  );
});
