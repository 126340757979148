import { getNatureDateAndTradingDate } from "@/util/processedDates";

export const MAX_SELECTION_SIZE = 10;
/**
 * 获取两个时间范围的交集中交易日天数
 * @param {string} originStartDate
 * @param {string} originEndDate
 * @param {Object} processedTradingDates
 * @returns
 */
export const getIntersectionTradingDates =
  (
    originStartDate: string,
    originEndDate: string,
    processedTradingDates: Record<string, any>
  ) =>
  (startDate: string, endDate: string) => {
    const maxStart = originStartDate > startDate ? originStartDate : startDate;
    const minEnd = originEndDate < endDate ? originEndDate : endDate;
    if (maxStart >= minEnd) return 0;
    const [, tradingDate] = getNatureDateAndTradingDate(
      maxStart,
      minEnd
    )(processedTradingDates);
    return tradingDate;
  };
