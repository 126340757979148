import {
  assetPortfoliosPositionDetail,
  WarehouseTurnoverReturn,
  brinsonReturnType,
  PositionTrendResponse,
  PositionTrendStrategyResponse,
  fundCorrelationResponse,
  AssetAllocateParams,
  AssetAllocate,
  valueAtRiskResponse,
  ScenarioListResponse,
  RiskDismantleResponse,
} from "@/model/portfolioAnalysis";
import { PromiseType } from "@/model/promise";
import { portfolioSummaryType } from "@/store/portfolioAnalysis";
import { del, get, post } from "@/util/axios";
import { socketHttp } from "@/util/socket";

/**
 * 组合介绍
 */
export const getPortfolioAnalysisIntroduction = (
  id: string
): PromiseType<portfolioSummaryType> =>
  get(`/back-testing-analysis/returns/summary/${id}`);

//组合区间回报

export const getPortfolioAnalysisRangeReturn = (id: string) =>
  get(`/back-testing-analysis/return/section/${id}`, undefined, {
    mocker: true,
  });

export type BackTestingParam = {
  startDate: string;
  endDate: string;
  initScale: number;
  benchmark: string;
  benchmarkType: string;
  turnoverPositions: {
    turnoverDate: string;
    weights: {
      fundId: string;
      weight: string;
    }[];
  }[];
};
export const startBackTesting = (meta: BackTestingParam) =>
  socketHttp("START_BACK_TESTING_TASK", {
    meta,
  });

export type BackTestingTurnoverParm = {
  id: string;
  date: string;
};
// 回测的未调仓收益
export const getBackTestingTurnover = ({
  id,
  date,
}: BackTestingTurnoverParm): PromiseType<WarehouseTurnoverReturn[]> =>
  get(`/back-testing-analysis/returns/turnover/${id}`, { date });

// 回测调仓日
export const getTurnoverDates = (id: string): PromiseType<string[]> =>
  get(`/asset/asset-portfolios/analysis/turnover/${id}`);

// 业绩拆解
export const getBackTestingAnalysisDissect = ({
  id,
  section,
}: {
  id: string;
  section: string;
}): PromiseType => get(`back-testing-analysis/dissect/${id}/${section}`);

export const getAssetPortfolioDissect = ({
  id,
  section,
}: {
  id: string;
  section: string;
}): PromiseType => get(`/asset_portfolio/dissect/${id}/${section}`);

// Brinson归因
export const getBrinsonReturn = ({
  id,
  section,
}: {
  id: string;
  section: string;
}): PromiseType<brinsonReturnType[]> =>
  get(`back-testing-analysis/attribution/brinson/${id}/${section}`);
// 回测持仓详情
export const getAssetPositionDetail = ({
  id,
  date,
}: {
  id: string;
  date: string;
}): PromiseType<assetPortfoliosPositionDetail> =>
  get(`back-testing-analysis/position/detail/${id}`, { date });

// 检测组合是否重名
export const dupName = (name: string) =>
  post(
    "asset-portfolios/dupName",
    { name },
    {
      config: {
        showError: false,
      },
    }
  );

// 基金持仓走势
export const getPositionTrend = (
  id: string
): PromiseType<PositionTrendResponse> =>
  get(`back-testing-analysis/position/trend/${id}`);

// 组合策略类型走势
export const getPositionTrendStrategy = (
  id: string
): PromiseType<PositionTrendStrategyResponse> =>
  get(`back-testing-analysis/position/trend/strategy/`, { id });
export type netValueAttributionParam = {
  id: string;
  section: string;
};
export const getNetValueAttribution = (param: netValueAttributionParam) =>
  get(
    `/back-testing-analysis/attribution/net-value/${param.id}/${param.section}`
  );

export interface fundCorrelationParam {
  portfolioId: string;
  section?: string;
  from?: string;
  to?: string;
}
export const getFundCorrelation = (
  param: fundCorrelationParam
): PromiseType<fundCorrelationResponse> =>
  get("asset/asset-portfolios/fund_correlation", param);
// 基金配置
export const assetAllocate = (
  allocateData: AssetAllocateParams
): PromiseType<AssetAllocate[]> =>
  post("/asset/analyze/asset-allocate", allocateData, {
    config: {
      showLoading: true,
    },
  });
export type ScaleFirstDateParam = {
  fundIds: string[];
};
// 获取基金规模数据最早的日期
export const getScaleFirstDate = (
  param: ScaleFirstDateParam
): PromiseType<string> =>
  post(`openfunds/scale_first`, param.fundIds, {
    config: {
      showLoading: false,
    },
  });

// 在险价值
export interface valueAtRiskParam {
  id: string;
  computationType: string;
  confidenceType: string;
  future: number;
}
export const getValueAtRisk = (
  param: valueAtRiskParam
): PromiseType<valueAtRiskResponse> =>
  get("asset/asset-portfolios/estimate_max_loss", param);
//压力场景
export const getScenarioList = (): PromiseType<ScenarioListResponse[]> =>
  get(`scenario/list`);

//风险拆解
export type riskDismantleParam = {
  id: string;
  section: string;
  scenarioId: number;
};
export const getRiskDismantle = (
  param: riskDismantleParam
): PromiseType<RiskDismantleResponse> =>
  get(`/asset/asset-portfolios/risk/dissect/${param.section}`, {
    portfolioId: param.id,
    scenarioId: param.scenarioId,
  });

export const validateScenarioName = (name: string) =>
  get(
    "/scenario/dupName",
    { name },
    {
      config: {
        showError: false,
        showLoading: true,
      },
    }
  );
//压力场景
export const getScenarioListApi = (): PromiseType<ScenarioListResponse[]> =>
  get(`scenario/list`);

//创建情景
export type CreateScenarioParams = {
  name: string;
  startDate: string;
  endDate: string;
};
export const createScenarioApi = (
  params: CreateScenarioParams
): PromiseType<ScenarioListResponse> =>
  post("/scenario/create", params, {
    config: {
      showLoading: true,
    },
  });

// 删除情景
export const deleteScenarioApi = (id: string) =>
  del(`/scenario/delete/${id}`, undefined);
