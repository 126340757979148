import React from "react";
import cn from "classnames";
import { Card, Space } from "antd";
import CumulativeChart from "@/components/cumulativeChart";
import { useFormatMessage } from "@/util/formatMessage";
import {
  useGetManagerCumulativeChartData,
  useManageBenchmarkIdAndRange,
} from "../hooks";
import { BaseFundManagerDetailInfoProps } from "../../interface";
import LoadingComponent from "@/components/LoadingComponent";
import { getFundManagerDetailBasicInfo } from "@/store/fundManagerDetailSlice";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import { first, isEmpty, last } from "lodash/fp";
import EmptyContent from "@/components/emptyContent";
import style from "./index.module.less";
import { statisticRangeList } from "../constant";
import BenchmarkSelect from "@/components/benchmarkSelect";
import CustomStaticRange from "@/views/portfolioManage/portfolioAnalysis/components/customStaticRange";

const actions = [
  getFundManagerDetailBasicInfo,
  fetchFundAndBenchmarkDailyReturns,
];

export default React.memo<
  BaseFundManagerDetailInfoProps & {
    className?: string;
  }
>(({ className, id }) => {
  const formatMessage = useFormatMessage();
  const { benchmarkId, activeRange, updateBenchmarkId, updateActiveRange } =
    useManageBenchmarkIdAndRange(id, "yield");
  const { dates, chartData, initDates } = useGetManagerCumulativeChartData(
    id,
    benchmarkId,
    activeRange
  );
  return (
    <LoadingComponent actions={actions}>
      <Card
        title={formatMessage("profitability")}
        className={cn(className)}
        extra={
          <Space size={16}>
            <BenchmarkSelect value={benchmarkId} onChange={updateBenchmarkId} />
            <Space>
              <p>{formatMessage("periodOfTime")}: </p>
              <CustomStaticRange
                className={style.StatisticRange}
                value={activeRange}
                onChange={updateActiveRange}
                startDate={first(initDates) || ""}
                endDate={last(initDates) || ""}
                staticRange={statisticRangeList as any}
              />
            </Space>
          </Space>
        }
      >
        {isEmpty(dates) ? (
          <EmptyContent
            className={style.EmptyContent}
            message={formatMessage("noData")}
          />
        ) : (
          <CumulativeChart
            dates={dates}
            dataSource={chartData}
            type="cumulative"
          />
        )}
      </Card>
    </LoadingComponent>
  );
});
