import { useFormatMessage } from "@/util/formatMessage";
import React from "react";
import style from "./index.module.less";

type CompanyBasicRank = {
  scale: string;
  messageKey: string;
  rank: number;
  companyCount: string;
};
export default React.memo<CompanyBasicRank>(
  ({ scale, messageKey, rank = "--", companyCount = "--" }) => {
    const formatMessage = useFormatMessage();
    return (
      <div className={style.RankItem}>
        <h2 className={style.ScaleNum}>{scale}</h2>
        <p className={style.ScaleDesc}>{formatMessage(messageKey)}</p>
        <p className={style.CompanyRank}>
          {rank} / {companyCount}
        </p>
        <p>{formatMessage("rank")}</p>
      </div>
    );
  }
);
