import PortfolioBasicInfo, {
  validateFormError,
} from "@/components/portfolioCompoents/portfolioBasicInfo";
import FundReplaceRule, {
  validateFundReplaceError,
} from "@/components/fundReplaceRule";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useMemoizedFn } from "ahooks";
import cn from "classnames";
import style from "./index.module.less";
import FundPosition from "./fundPosition";
import { InitSimpleDataType } from "@/components/portfolioCompoents/hook";
import { useFundPosition } from "./hook";
import { compact, isEmpty, map, mapValues, pick } from "lodash/fp";
import { assetsType } from "@/model/portfolioList";
import { useForm } from "antd/lib/form/Form";
import { formatPortfolioValue } from "@/components/portfolioCompoents/constant";
import { useValidateDeListFunds } from "@/hooks/modal";
import { useGetPositionDate } from "../manualCreatePortfolio/hooks";
import { defalueCashHold } from "@/components/fundReplaceRule/constant";
import { fastProp } from "@/util/opt";

const fundListPick = ["fundId", "weight", "purchFeeRatio", "redeemFeeRatio"];
export default forwardRef<
  {
    getFormData: () => any;
  },
  {
    initSimpleData: InitSimpleDataType;
    onError: (error: boolean) => any;
    className?: string;
    propsFundIds?: string[];
  }
>(({ onError, initSimpleData, className, propsFundIds = [] }, ref) => {
  const [form] = useForm();
  const [fundIds, fundList, onChange, onChangeFundIds] = useFundPosition();
  useEffect(() => {
    if (!isEmpty(propsFundIds)) {
      onChangeFundIds(propsFundIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsFundIds]);
  const [replaceRule, setReplaceRule] =
    useState<Record<string, any>>(defalueCashHold);
  const [fundPositionError, setFundPositionError] = useState<boolean>(true);
  const [formError, setFormError] = useState<boolean>(true);
  const positionDate = useGetPositionDate(initSimpleData);

  const validate = useValidateDeListFunds(
    positionDate,
    map(pick(fundListPick))(fundList) as assetsType[]
  );
  useImperativeHandle(ref, () => ({
    getFormData: () =>
      form
        .validateFields()
        .then((values) =>
          validate().then((assets: assetsType[]) => {
            return {
              ...formatPortfolioValue(values),
              assets,
              replaceRule: {
                ...replaceRule,
                replaceFund: mapValues((ids: string[]) => compact(ids))(
                  fastProp("replaceFund")(replaceRule)
                ),
              },
            };
          })
        )
        .catch((e) => {
          setFormError(e);
          return Promise.reject();
        }),
  }));
  const onValuesChange = useMemoizedFn(
    (changeValue, allValue: InitSimpleDataType) => {
      setFormError(validateFormError(allValue));
    }
  );
  useEffect(() => {
    onError(
      fundPositionError || formError || validateFundReplaceError(replaceRule)
    );
  }, [onError, fundPositionError, formError, form, replaceRule]);

  return (
    <div className={cn(style.LayoutScrollYContent, className)}>
      <PortfolioBasicInfo
        form={form}
        onValuesChange={onValuesChange}
        initialValues={initSimpleData}
      />
      <br />
      <FundPosition
        fundIds={fundIds}
        fundList={fundList}
        onChange={onChange}
        onChangeFundIds={onChangeFundIds}
        onError={setFundPositionError}
      />
      <FundReplaceRule
        className={style.FundReplaceRule}
        fundList={fundList}
        value={replaceRule}
        onChange={setReplaceRule}
      />
    </div>
  );
});
