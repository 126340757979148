import { useFormatMessage } from "@/util/formatMessage";
import { Button, Form, Radio, RadioChangeEvent, Space } from "antd";
import React from "react";
import { riskAppetiteOptions } from "../constant";
import RiskFreeRate from "@/views/portfolioManage/fundConfiguration/fundAllocation/components/riskFreeRate";
import style from "../index.module.less";
import InfoIcon from "@/assets/infoIcon.svg";
import { modelComponentProps, YieldView } from "../../interface";
import { useBlackLittleManModel } from "../hooks";
import { fastProp } from "@/util/opt";
import { map, prop } from "lodash/fp";
import NumberInput from "@/components/numberInput";
import { categoryTypeMap } from "../../constant";
import ErrorField from "../../../manualCreatePortfolio/components/errorField";
import { SyncOutlined } from "@ant-design/icons";
import { useGetIndexInfo, useGetModelDurationOptions } from "./hooks";

const FormItem = Form.Item;
export default React.memo<modelComponentProps>(
  ({
    modelAllocationData,
    updateModelAllocation,
    categoryModelErrorField,
    buttonDisabled,
  }) => {
    const formatMessage = useFormatMessage();
    const {
      dataRange,
      goalConfig,
      categoryTypes,
      categorySource,
      updateAllocateConfig,
      updateGoalConfig,
      onResetWeightView,
      updateYieldView,
    } = useBlackLittleManModel({
      modelAllocationData,
      updateModelAllocation,
      buttonDisabled,
    });
    const { factorMap } = useGetIndexInfo();
    const { modelOptions } = useGetModelDurationOptions();

    return (
      <div>
        <FormItem
          labelCol={{ span: 3 }}
          labelAlign="left"
          label={formatMessage("ModelDuration")}
        >
          <Radio.Group
            className={style.ModelDuration}
            optionType="button"
            options={modelOptions}
            value={dataRange}
            onChange={(e: RadioChangeEvent) =>
              updateAllocateConfig("dataRange")(e.target.value)
            }
          />
        </FormItem>
        <FormItem
          labelCol={{ span: 3 }}
          labelAlign="left"
          label={formatMessage("riskFreeRate")}
        >
          <RiskFreeRate
            value={fastProp("riskFreeConfig")(goalConfig)}
            onChange={updateGoalConfig("riskFreeConfig")}
          />
        </FormItem>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          label={formatMessage("ExpectedAssetWeight")}
        >
          <Space wrap>
            {map((v: string) => (
              <Space key={v}>
                <span>
                  {categorySource === "ASSET_CATEGORY"
                    ? prop([v, "label"])(categoryTypeMap)
                    : prop([v, "name"])(factorMap)}
                </span>
                <NumberInput
                  type="PERCENTAGE"
                  min={0}
                  max={1}
                  precision={2}
                  size="small"
                  placeholder="0~100"
                  addonAfter="%"
                  className={style.WeightInput}
                  value={prop(["weightView", "priorWeights", v])(goalConfig)}
                  onChange={updateGoalConfig(["weightView", "priorWeights", v])}
                />
              </Space>
            ))(categoryTypes)}
            <Button
              icon={<SyncOutlined />}
              type="link"
              onClick={onResetWeightView}
            >
              {formatMessage("reset")}
            </Button>
          </Space>
          {categoryModelErrorField.weightViewError && (
            <ErrorField
              message={formatMessage("weightViewError")}
              className={style.MarginTop8}
            />
          )}
        </FormItem>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          label={formatMessage("IndividualExpectedAnnualizedIncome")}
        >
          <Space wrap>
            {map(({ categoryId, value }: YieldView) => (
              <Space key={categoryId}>
                <span>
                  {categorySource === "ASSET_CATEGORY"
                    ? prop([categoryId, "label"])(categoryTypeMap)
                    : prop([categoryId, "name"])(factorMap)}
                </span>
                <NumberInput
                  type="PERCENTAGE"
                  min={0}
                  max={1}
                  precision={2}
                  size="small"
                  placeholder="0~100"
                  addonAfter="%"
                  className={style.WeightInput}
                  value={value}
                  onChange={updateYieldView(categoryId)}
                />
              </Space>
            ))(prop(["yieldViews"])(goalConfig))}
          </Space>
        </FormItem>
        <FormItem
          labelCol={{ span: 3 }}
          labelAlign="left"
          label={formatMessage("RiskAppetite")}
        >
          <Radio.Group
            className={style.RadioGroupItem}
            options={riskAppetiteOptions}
            value={fastProp("riskParam")(goalConfig)}
            onChange={(e: RadioChangeEvent) =>
              updateGoalConfig("riskParam")(e.target.value)
            }
          />
        </FormItem>
        <div className={style.DescriptionTip}>
          <img src={InfoIcon} className={style.AlertIcon} />
          <p>{formatMessage("modelAllocationRiskDescription")}</p>
        </div>
      </div>
    );
  }
);
