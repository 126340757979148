import { AppThunk } from "@/store";
import {
  ActionCreatorWithPayload,
  AsyncThunk,
  isAsyncThunkAction,
} from "@reduxjs/toolkit";
import { isEmpty, isString, prop } from "lodash/fp";

export type ReduxBaseAsyncThunk<RequestType = void> = AsyncThunk<
  any,
  RequestType,
  any
>;
export type ReduxBaseActionCreator = ActionCreatorWithPayload<any, any>;
export const avoidMultiRequestActionThunk = <
  RequestType = void,
  T = ReduxBaseAsyncThunk<RequestType>
>(
  keytar: string | ((requestData: RequestType) => string),
  action: ReduxBaseAsyncThunk<RequestType> | ReduxBaseActionCreator,
  shouldRequestValidator?: (data: any) => boolean
): T => {
  function thunkSetter(requestData: RequestType): AppThunk {
    return (dispatch, getState) => {
      const data = prop(isString(keytar) ? keytar : keytar(requestData))(
        getState()
      );
      if (
        shouldRequestValidator ? shouldRequestValidator(data) : isEmpty(data)
      ) {
        return dispatch(action(requestData as RequestType));
      }
      return data;
    };
  }
  if (isAsyncThunkAction(action)) {
    const appAction = action as ReduxBaseAsyncThunk<RequestType>;
    thunkSetter.fulfilled = appAction.fulfilled;
    thunkSetter.pending = appAction.pending;
    thunkSetter.rejected = appAction.rejected;
    thunkSetter.typePrefix = appAction.typePrefix;
    return thunkSetter as unknown as T;
  }
  return thunkSetter as unknown as T;
};
