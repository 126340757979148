import React, { memo, useMemo } from "react";
import { Table, TableColumnType } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { formatPercentage } from "@/util/numberFormatter";
import ColorNumber from "@/components/colorNumber";

import style from "../../index.module.less";

export interface DataItem {
  name: string;
  weight: number;
  changeWeight: number | undefined;
}

interface TableProps {
  height: number;
  dataSource: DataItem[];
}

const useColumns = () => {
  const formatMessage = useFormatMessage();
  return useMemo<TableColumnType<DataItem>[]>(
    () => [
      {
        title: formatMessage("industry"),
        dataIndex: "name",
        width: 130,
        align: "left",
      },
      {
        title: formatMessage("Weight"),
        dataIndex: "weight",
        align: "center",
        render: (v: number) => formatPercentage(v),
      },
      {
        title: formatMessage("ChangeOverPeriod"),
        dataIndex: "changeWeight",
        align: "center",
        render: (v: number) => <ColorNumber value={v} formatter="percentage" />,
      },
    ],
    [formatMessage]
  );
};

export default memo<TableProps>(({ height, dataSource }) => {
  const columns = useColumns();

  return (
    <Table
      columns={columns}
      dataSource={dataSource || []}
      pagination={false}
      className={style.CommonTable}
      scroll={{ y: height - 43, x: "100%" }}
      rowKey="code"
      bordered
    />
  );
});
