import {
  FROM_CREATION,
  RECENT_HALF_YEAR,
  RECENT_THREE_MONTH,
  RECENT_TWO_YEAR,
  RECENT_YEAR,
  statisticRange,
} from "@/constant/statisticRange";
import { useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { getNatureDateAndTradingDate } from "@/util/processedDates";
import { useCreation } from "ahooks";
import { Radio } from "antd";
import dayjs from "dayjs";
import { filter, map, pick, toArray } from "lodash/fp";
import React from "react";
import { getAddRangeDate } from "../constant";

const statisticRangeOptions = toArray(
  pick([
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    FROM_CREATION,
  ])(statisticRange)
);

export default React.memo<{
  startDate: string;
  endDate: string;
  value: string;
  onChange: (value: string) => any;
}>(({ startDate, endDate, value, onChange }) => {
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  const [, tradingDateCount] = useCreation(
    () =>
      getNatureDateAndTradingDate(startDate, endDate)(processedTradingDates),
    [endDate, processedTradingDates, startDate]
  );
  const formatMessage = useFormatMessage();
  const groupData = useCreation(() => {
    if (tradingDateCount < 10) return [];
    const addRangeDateGetter = getAddRangeDate(
      tradingDateList,
      processedTradingDates
    );
    const intersectionEndDay = dayjs(endDate);
    return filter(({ id }) => {
      const startAddRangeDate = addRangeDateGetter(id, startDate);
      return intersectionEndDay.isSameOrAfter(startAddRangeDate);
    })(statisticRangeOptions);
  }, [
    endDate,
    processedTradingDates,
    startDate,
    tradingDateCount,
    tradingDateList,
  ]);
  return (
    <Radio.Group
      value={value}
      onChange={(e) => onChange(e.target.value)}
      buttonStyle="solid"
    >
      {map(({ id, message }) => (
        <Radio.Button value={id} key={id}>
          {formatMessage(message)}
        </Radio.Button>
      ))(groupData)}
    </Radio.Group>
  );
});
