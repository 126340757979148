import { useState, useCallback, useEffect, useMemo } from "react";
import {
  set,
  pullAt,
  map,
  prop,
  first,
  isEmpty,
  last,
  size,
  flow,
  min,
  findIndex,
  fromPairs,
  reduce,
  max,
  minBy,
  keys,
} from "lodash/fp";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { formatter } from "@/constant/factorFormatter";
import { useAppSelector } from "@/hooks/redux";
import { LabeledValue } from "antd/lib/select";
import { fastNth, fastProp, getProp, mapIndexed, normalize } from "@/util/opt";
import { CSI300_ID } from "./constant";
import {
  RangeInterface,
  FROM_CREATION,
  COMMON_TIME,
} from "@/constant/statisticRange";
import { useGetStatisticRange } from "@/views/portfolioManage/portfolioAnalysis/hooks";
import { dateFormat, getTime } from "@/util/dateFormat";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { baseSelector } from "./fundCompare/selectors";
import { subAttributionsType } from "@/model/fundDetail";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import getCumulativeAttributionsByParams from "@/util/quant/cumulativeAttributions";
import ColorNumber from "@/components/colorNumber";
import { sortAntdTable } from "@/util/sortTable";
import { getNextTradingDate } from "@/util/processedDates";
import { useFormatMessage } from "@/util/formatMessage";
import BrinsonStatus from "./fundCompare/components/brinsonStatus";
import { REQUEST_PENDING, REQUEST_FAILED } from "@/constant/socket";
import style from "./index.module.less";
import { isInProgress } from "@/constant/socket";
import { getIntersectionDate } from "@/constant/statisticRangeCalculator/helper";
import { getStartDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { defaultSectorId } from "@/constant/sectors";
import { useCreation } from "ahooks";

export const useManageComparesTarget = (
  onStartFunc: (compareIds: string[]) => any,
  defaultIds?: string[]
): {
  compareIds: string[];
  isComparing: boolean;
  onStartCompare: () => any;
  addCompareTarget: () => void;
  updateCompareTarget: (index: any) => (value: string) => void;
  removeCompareTarget: (value: any) => void;
  clearCompareTarget: () => void;
} => {
  const [compareIds, setCompareIds] = useState([""]);
  useEffect(() => {
    if (defaultIds && !isEmpty(defaultIds)) {
      setCompareIds(defaultIds);
    }
  }, [defaultIds]);
  const updateInfo = useCallback(
    (callback: (ids: string[]) => string[]) =>
      setCompareIds(callback(compareIds)),
    [compareIds]
  );
  const [isComparing, setIsComparing] = useState(false);
  const changeCompareStatus = useCallback(
    (callback: (ids: string[]) => string[]) => {
      setIsComparing(false);
      updateInfo(callback);
    },
    [updateInfo]
  );

  const addCompareTarget = useCallback(
    () => changeCompareStatus((target) => [...target, ""]),
    [changeCompareStatus]
  );
  const clearCompareTarget = useCallback(
    () => changeCompareStatus((target) => map(() => "")(target)),
    [changeCompareStatus]
  );
  const updateCompareTarget = useCallback(
    (index) => (value: string) =>
      changeCompareStatus(
        (ids) => set(index, value)(ids) as unknown as string[]
      ),
    [changeCompareStatus]
  );
  const removeCompareTarget = useCallback(
    (index) => changeCompareStatus(pullAt(index)),
    [changeCompareStatus]
  );

  const onStartCompare = useCallback(() => {
    setIsComparing(true);
    return onStartFunc(compareIds);
  }, [compareIds, onStartFunc]);
  return {
    compareIds,
    isComparing,
    onStartCompare,
    addCompareTarget,
    updateCompareTarget,
    removeCompareTarget,
    clearCompareTarget,
  };
};

export const useManageFundReportDate = (): [
  string,
  LabeledValue[],
  React.Dispatch<React.SetStateAction<string>>
] => {
  const reportDate = useAppSelector<string[]>(
    prop("compareManage.fundCompare.financialReportDate")
  );
  const [date, setDate] = useState(last(reportDate) || "");
  const options = useMemo(
    () =>
      map<string, LabeledValue>((date) => ({
        label: date,
        value: date,
      }))(reportDate),
    [reportDate]
  );
  useEffect(() => {
    if (!isEmpty(reportDate)) setDate(last(reportDate) || "");
  }, [reportDate]);
  return [date, options, setDate];
};

export const useManageSectorCategories = (): [
  string,
  React.Dispatch<React.SetStateAction<string>>
] => {
  const [sectorId, setSectorId] = useState<string>(defaultSectorId);
  return [sectorId, setSectorId];
};

export const useManageBenchmark = (): [
  string,
  React.Dispatch<React.SetStateAction<string>>
] => {
  const [benchmark, setBenchmark] = useState(CSI300_ID);
  return [benchmark, setBenchmark];
};

export type datesInterface = {
  fundId: string;
  startDate: string;
  endDate: string;
};

export const useManageDatesData = (
  dates: datesInterface[],
  staticRange: Record<string, RangeInterface>
) => {
  const calcStatisticRangeList = useGetStatisticRange(2);
  //计算区间
  return useMemo(() => {
    const statisticRangeLists = map((item: datesInterface) => {
      const { startDate, endDate } = item;
      const statisticRangeList = calcStatisticRangeList(
        startDate as string,
        endDate as string,
        staticRange as Record<string, RangeInterface>
      );
      return statisticRangeList;
    })(dates);
    const statisticRangeListKeys = map((item: any) => map("id")(item))(
      statisticRangeLists
    );
    const arrSize = flow(map((arr: []) => size(arr)))(statisticRangeListKeys);
    // 查找arrSize最小值下标
    const minSize = min(arrSize) as number;
    const minIndex = findIndex((v) => minSize === v)(arrSize);
    const ranges = fastNth(minIndex)(statisticRangeLists);
    return ranges;
  }, [calcStatisticRangeList, dates, staticRange]);
};

export const useFundDates = () => {
  const compareFundTask = useAppSelector(
    prop("compareManage.fundCompare.compareFundTask")
  );
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  const fundDetail = useAppSelector(prop("fundDetail"));
  const timeRange = useCreation(
    () =>
      flow(
        keys,
        map((id: string) => [
          id,
          prop(`${id}.fundDetailIndustryTrendDate`)(fundDetail),
        ]),
        fromPairs
      )(fundDetail),
    []
  );
  return useMemo(
    () =>
      map((item: Record<string, any>) => {
        const { fundId, assetDailyReturnView } = item;
        const ETFData = fastProp(fundId)(timeRange);
        const endDate = fastProp("isETF")(ETFData)
          ? flow(fastProp("dates"), last)(ETFData)
          : last(fastProp("dates")(assetDailyReturnView));
        const startDate = fastProp("isETF")(ETFData)
          ? flow(fastProp("dates"), first)(ETFData)
          : (first(fastProp("dates")(assetDailyReturnView)) as string);
        const nextDate = getNextTradingDate(
          tradingDateList,
          processedTradingDates,
          startDate
        );
        return {
          fundId,
          startDate: nextDate,
          endDate,
        };
      })(compareFundTask),
    [compareFundTask, processedTradingDates, tradingDateList, timeRange]
  );
};

export const useManageBrinsonDates = (
  fundIds: string[],
  fundDates: datesInterface[],
  brinsonRange: string,
  brinsonDatesRange: any[]
) => {
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  return useMemo(() => {
    const brinsonDatesRangeMap =
      normalize<RangeInterface>("id")(brinsonDatesRange);

    if (brinsonRange === COMMON_TIME) {
      const [maxStartDate, minEndDate] =
        getIntersectionDate(
          map("startDate")(fundDates),
          map("endDate")(fundDates)
        ) || [];
      return map((fundId) => {
        return {
          fundId,
          startDate: maxStartDate,
          endDate: minEndDate,
        };
      })(fundIds);
    }
    if (brinsonRange === FROM_CREATION) {
      return fundDates;
    }
    return getRangeDate(
      brinsonRange,
      brinsonDatesRangeMap,
      fundDates,
      tradingDateList,
      processedTradingDates
    );
  }, [
    brinsonDatesRange,
    brinsonRange,
    fundDates,
    fundIds,
    processedTradingDates,
    tradingDateList,
  ]);
};

export const getRangeDate = (
  brinsonRange: string,
  brinsonDatesRangeMap: Record<string, any>,
  fundDates: datesInterface[],
  tradingDateList: string[],
  processedTradingDates: Record<string, any>
) => {
  const { period, count } = fastProp(brinsonRange)(brinsonDatesRangeMap);
  if (period && count) {
    return map((v: datesInterface) => {
      const { endDate, fundId } = v;
      const date = getStartDate(
        tradingDateList,
        processedTradingDates
      )(dateFormat(dayjs(endDate).subtract(period, count)));
      return {
        fundId,
        startDate: date,
        endDate,
      };
    })(fundDates);
  }
  return fundDates;
};

export const useGetDataSource = (
  fundIds: string[],
  ETFDates: Record<string, any>
) => {
  const { brinsonAttribution } = useSelector(baseSelector);
  return useMemo(
    () =>
      map((fundId: string) => {
        const result = getProp(`${fundId}.fundAttributionBrinsonResult`)(
          brinsonAttribution
        );
        const { subAttributions } = result || {};
        const isETF = prop(`${fundId}.fundDetailIndustryTrendDate.isETF`)(
          ETFDates
        );

        if (isETF) {
          const dailyBenchmarkPositionReturns = fastProp(
            "dailyBenchmarkPositionReturns"
          )(result);
          const dailyPortfolioReturns = fastProp("dailyPortfolioReturns")(
            result
          );

          const cumulativePortfolioReturns = getCumulativeReturns(
            dailyPortfolioReturns
          );
          const cumulativeBenchmarkPositionReturns = getCumulativeReturns(
            dailyBenchmarkPositionReturns
          );
          const dailyBenchmarkReturns = fastProp("dailyBenchmarkReturns")(
            result
          );

          // 持仓 连接算法
          const getPositionCumulativeAttributions =
            getCumulativeAttributionsByParams(
              dailyPortfolioReturns,
              dailyBenchmarkPositionReturns,
              cumulativePortfolioReturns,
              cumulativeBenchmarkPositionReturns
            );

          const nullBenchmarkRetures = map(() => 0)(dailyBenchmarkReturns);
          const getAsboluteLastBenchmarkCumulativeAttribution = flow(
            getCumulativeAttributionsByParams(
              dailyBenchmarkPositionReturns,
              nullBenchmarkRetures,
              cumulativeBenchmarkPositionReturns,
              nullBenchmarkRetures
            ),
            last
          );

          const getAsboluteLastCumulativeAttribution = flow(
            getCumulativeAttributionsByParams(
              dailyPortfolioReturns,
              nullBenchmarkRetures,
              cumulativePortfolioReturns,
              nullBenchmarkRetures
            ),
            last
          );
          const calculatedSubAttributions = flow(
            mapIndexed((subAttribution: Record<string, any>) => {
              const id = fastProp("id")(subAttribution);
              const subDailyPortfolioReturns =
                fastProp("dailyTotalReturns")(subAttribution);
              const subDailyBenchmarkReturns = fastProp(
                "dailyBenchmarkReturns"
              )(subAttribution);

              const subDailyAllocationAttributions = fastProp(
                "dailyAllocationAttributions"
              )(subAttribution);
              const subDailySelectionAttributions = fastProp(
                "dailySelectionAttributions"
              )(subAttribution);
              const subCumulativeSelectionAttributions =
                getPositionCumulativeAttributions(
                  subDailySelectionAttributions
                );
              const subCumulativeAllocationAttributions =
                getPositionCumulativeAttributions(
                  subDailyAllocationAttributions
                );
              // const subCumulativeActiveAttribution = last(subCumulativeActiveAttributions);
              const subCumulativeSelectionAttribution = last(
                subCumulativeSelectionAttributions
              );
              const subCumulativeAllocationAttribution = last(
                subCumulativeAllocationAttributions
              );
              const subCumulativeBenchmarkAttribution =
                getAsboluteLastBenchmarkCumulativeAttribution(
                  subDailyBenchmarkReturns
                );
              const subCumulativePortfolioAttribution =
                getAsboluteLastCumulativeAttribution(subDailyPortfolioReturns);
              const subCumulativeActiveAttribution =
                (subCumulativePortfolioAttribution as number) -
                (subCumulativeBenchmarkAttribution as number);

              return {
                id,
                subCumulativeAllocationAttributions: last(
                  subCumulativeAllocationAttributions
                ),
                subCumulativeSelectionAttributions: last(
                  subCumulativeSelectionAttributions
                ),
                subCumulativeTimingAttributions:
                  subCumulativeActiveAttribution -
                  (subCumulativeSelectionAttribution as number) -
                  (subCumulativeAllocationAttribution as number),
              };
            })
          )(subAttributions);

          return {
            fundId,
            calculatedSubAttributions,
          };
        } else {
          // 基金净值业绩
          const dailyPortfolioReturns =
            fastProp("dailyPortfolioActualReturns")(result) || [];
          // 基金净值累计业绩
          const cumulativePortfolioAttributions = getCumulativeReturns(
            dailyPortfolioReturns
          );

          // A股+非A股+权益类的基准净值业绩
          const dailyBenchmarkReturns =
            fastProp("dailyBenchmarkReturns")(result) || [];
          // A股+非A股+权益类的基准净值累计业绩
          const cumulativeBenchmarkAttributions = getCumulativeReturns(
            dailyBenchmarkReturns
          );

          // 净值 连接算法
          const getActualCumulativeAttributions =
            getCumulativeAttributionsByParams(
              // 基金净值
              dailyPortfolioReturns,
              // 基准净值
              dailyBenchmarkReturns,
              cumulativePortfolioAttributions,
              cumulativeBenchmarkAttributions
            );
          const calculatedSubAttributions = mapIndexed(
            (subAttribution: Array<subAttributionsType>) => {
              const id = fastProp("id")(subAttribution);
              const subDailyAllocationAttributions =
                fastProp("dailyAllocationAttributions")(subAttribution) || [];
              const subDailySelectionAttributions =
                fastProp("dailySelectionAttributions")(subAttribution) || [];
              const subDailyTimingAttributions =
                fastProp("dailyTimingAttributions")(subAttribution) || [];
              const subCumulativeAllocationAttributions =
                getActualCumulativeAttributions(
                  subDailyAllocationAttributions
                ) || [];
              const subCumulativeSelectionAttributions =
                getActualCumulativeAttributions(
                  subDailySelectionAttributions
                ) || [];
              const subCumulativeTimingAttributions =
                getActualCumulativeAttributions(subDailyTimingAttributions);
              return {
                id,
                subCumulativeAllocationAttributions: last(
                  subCumulativeAllocationAttributions
                ),
                subCumulativeSelectionAttributions: last(
                  subCumulativeSelectionAttributions
                ),
                subCumulativeTimingAttributions: last(
                  subCumulativeTimingAttributions
                ),
              };
            }
          )(subAttributions);

          return {
            fundId,
            calculatedSubAttributions,
          };
        }
      })(fundIds),
    [brinsonAttribution, fundIds, ETFDates]
  );
};

export interface dataSourceInterface {
  fundId: string;
  calculatedSubAttributions: [];
}
export const useGetTableData = (
  dataSource: dataSourceInterface[],
  fundIds: string[]
) => {
  const sectorCategoryMap = useAppSelector(sectorCategoriesMapSelector);

  const { brinsonAttribution } = useSelector(baseSelector);

  const industrys = useGetIndustryData(dataSource);

  type progressType = {
    [key: string]: number;
  };

  // 所有基金都在请求，没有数据返回时
  const progressData: progressType = flow(
    map((fundId: string) => {
      const task = getProp(`${fundId}.fundAttributionBrinsonProgress`)(
        brinsonAttribution
      );
      return [[`${fundId}Progress`], task?.progress];
    }),
    fromPairs
  )(fundIds);

  const dataSourceMap = normalize("fundId")(dataSource);
  const data = useMemo(() => {
    const tableData: Record<string, any>[] = [];
    map((industry: string) => {
      const data = map((fundId: string) => {
        const industryAttributions: any =
          getProp(`${fundId}.calculatedSubAttributions`)(dataSourceMap) || {};
        const task = getProp(`${fundId}.fundAttributionBrinsonProgress`)(
          brinsonAttribution
        );
        const industryAttributionsMap = normalize("id")(industryAttributions);
        const {
          subCumulativeAllocationAttributions,
          subCumulativeSelectionAttributions,
          subCumulativeTimingAttributions,
        } = fastProp(industry)(industryAttributionsMap) || {};

        return !isEmpty(industryAttributions)
          ? {
              [`${fundId}SubCumulativeAllocationAttributions`]:
                subCumulativeAllocationAttributions,
              [`${fundId}SubCumulativeSelectionAttributions`]:
                subCumulativeSelectionAttributions,
              [`${fundId}subCumulativeTimingAttributions`]:
                subCumulativeTimingAttributions,
            }
          : { [`${fundId}Progress`]: task?.progress };
      })(fundIds);
      let tempData = {};
      data.forEach((item) => {
        tempData = {
          ...tempData,
          ...item,
          ...{
            name: getProp(`${industry}.name`)(sectorCategoryMap),
          },
        };
      });
      tableData.push({ ...tempData });
    })(industrys);
    return tableData;
  }, [
    brinsonAttribution,
    dataSourceMap,
    fundIds,
    industrys,
    sectorCategoryMap,
  ]);
  if (!isEmpty(industrys)) {
    return data;
  }
  return [progressData];
};

export const useGetColumnsData = (dataSource: any) => {
  const formatMessage = useFormatMessage();
  const compareFundTask = useAppSelector(
    prop("compareManage.fundCompare.compareFundTask")
  );
  const compareFundTaskMap = useMemo(
    () => normalize("fundId")(compareFundTask),
    [compareFundTask]
  );
  const { brinsonAttribution } = useSelector(baseSelector);

  const industruysList = useGetIndustryData(dataSource);

  const fundColumns = useMemo(
    () =>
      map((item: any) => {
        const { fundId } = item;
        const task = prop(`${fundId}.fundAttributionBrinsonProgress`)(
          brinsonAttribution
        );
        const progress = fastProp("progress")(task);
        // task为空，说明还没请求过，这时候也显示进度信息
        return isEmpty(task) ||
          isInProgress(task) ||
          progress === REQUEST_PENDING ||
          progress === REQUEST_FAILED
          ? {
              title: getProp(`${fundId}.fundName`)(compareFundTaskMap),
              dataIndex: `${fundId}Progress`,
              key: `${fundId}Progress`,
              align: "center",
              width: 400,
              onCell: () => ({
                rowSpan: size(industruysList) || 1,
              }),
              render: (_: any, record: any, index: number) => (
                <div key={index}>
                  <BrinsonStatus
                    task={task as any}
                    className={style.TaskResult}
                  />
                </div>
              ),
            }
          : {
              title: getProp(`${fundId}.fundName`)(compareFundTaskMap),
              align: "center",
              width: 400,
              children: [
                {
                  title: formatMessage("allocationEffect"),
                  align: "right",
                  dataIndex: `${fundId}SubCumulativeAllocationAttributions`,
                  key: `${fundId}SubCumulativeAllocationAttributions`,
                  width: 100,
                  render: formatter.percent2,
                  sorter: sortAntdTable(
                    `${fundId}SubCumulativeAllocationAttributions`
                  ),
                },
                {
                  title: formatMessage("selectEffect"),
                  align: "right",
                  dataIndex: `${fundId}SubCumulativeSelectionAttributions`,
                  key: `${fundId}SubCumulativeSelectionAttributions`,
                  width: 100,
                  render: formatter.percent2,
                  sorter: sortAntdTable(
                    `${fundId}SubCumulativeSelectionAttributions`
                  ),
                },
                {
                  title: formatMessage("incomeTiming"),
                  align: "right",
                  dataIndex: `${fundId}subCumulativeTimingAttributions`,
                  key: `${fundId}subCumulativeTimingAttributions`,
                  width: 100,
                  sorter: sortAntdTable(
                    `${fundId}subCumulativeTimingAttributions`
                  ),
                  render: (v: number) => (
                    <ColorNumber value={v} formatter="percentage" />
                  ),
                },
              ],
            };
      })(dataSource),
    [
      dataSource,
      brinsonAttribution,
      compareFundTaskMap,
      formatMessage,
      industruysList,
    ]
  );

  return useMemo(
    () =>
      !isEmpty(industruysList)
        ? [
            {
              dataIndex: "name",
              key: "name",
              width: 100,
              title: formatMessage("industry"),
              align: "center",
            },
            ...fundColumns,
          ]
        : [...fundColumns],
    [formatMessage, fundColumns, industruysList]
  );
};

export const useGetIndustryData = (dataSource: dataSourceInterface[]) => {
  const list = map((item: dataSourceInterface) =>
    fastProp("calculatedSubAttributions")(item)
  )(dataSource);

  const industrysList = useMemo(
    () =>
      reduce((acc: [], cur: []) => {
        return size(cur) > size(acc) ? cur : acc;
      }, [])(list),
    [list]
  );

  const industrys = useMemo(() => map("id")(industrysList), [industrysList]);
  return industrys;
};

export const useGetMaxEndAndMinStartDate = (
  fundDates: string[][],
  benchmarkDates: string[] | undefined
) => {
  const concatDate = map("dates")(fundDates).concat([benchmarkDates]);
  const { maxEndDate, minStartDate } = useMemo(() => {
    const maxEndDate = flow(map(last), max)(concatDate) as string;
    const minStartDate =
      flow(
        map<string[], string | undefined>(first),
        minBy(getTime)
      )(fundDates) || "";
    return { maxEndDate, minStartDate };
  }, [fundDates, concatDate]);

  return { maxEndDate, minStartDate };
};
