import CumulativeChart from "@/components/cumulativeChart";
import LoadingComponent from "@/components/LoadingComponent";
import { COMMON_TIME, yieldStatisticRange } from "@/constant/statisticRange";
import { useCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { useGetBenchmarkDailyReturn } from "@/hooks/benchmark";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  fetchFundManagerCompareDailyReturn,
  fetchFundManagerCompareScaleChange,
} from "@/store/compareManage";
import { colors } from "@/util/colors";
import { useFormatMessage } from "@/util/formatMessage";
import { formatNilToZero } from "@/util/numberFormatter";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import FundManagerSelect from "@/views/fundDetail/fundDetailInfo/assetDetail/containers/assetManager/components/fundManagerSelect";
import { useCreation } from "ahooks";
import { Card, Space } from "antd";
import { concat, map, maxBy, omit, prop, size } from "lodash/fp";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ChartRangePicker from "../../components/chartRangePicker";
import { CSI300_ID } from "../../constant";
import style from "../index.module.less";
import { fundManagerMapSelector } from "../selectors";
import EmptyContent from "@/components/emptyContent";
import { useGetMaxEndAndMinStartDate } from "../../hooks";

const statisticOptions = omit(["RECENT_TRADING_DAY", "FROM_THIS_YEAR"])(
  yieldStatisticRange
);

const useGetChartData = (
  managerIds: string[],
  activeRange: string,
  benchmarkId: string
) => {
  const managerDailyReturn = useAppSelector(
    prop("compareManage.fundManager.managerDailyReturn")
  );
  const fundManagerMap = useSelector(fundManagerMapSelector);
  const fundManagersInfo = useMemo(
    () =>
      mapIndexed(({ id, dates, returns }: any, index: number) => {
        return {
          id: id,
          name: prop(`${id}.name`)(fundManagerMap),
          color: fastNth(index)(colors),
          dailyReturnsMap: normalizeDailyReturnsMap(dates, returns),
          dates,
          returns,
        };
      })(managerDailyReturn),
    [managerDailyReturn, fundManagerMap]
  );
  const benchmarkInfo = useGetBenchmarkDailyReturn(benchmarkId);

  const { maxEndDate, minStartDate } = useGetMaxEndAndMinStartDate(
    map("dates")(fundManagersInfo),
    fastProp("dates")(benchmarkInfo)
  );

  const calculatedDates = useCalculateRangeDate(
    map("dates")(fundManagersInfo),
    fastProp("dates")(benchmarkInfo),
    activeRange
  );
  const maxDates = useCreation(
    () => maxBy<string[]>(size)(calculatedDates) || [],
    [calculatedDates]
  );

  const fundManagerAndBenchmarkInfo = useMemo(
    () => concat(fundManagersInfo)(benchmarkInfo),
    [benchmarkInfo, fundManagersInfo]
  );
  const chartData = useMemo(
    () =>
      map(({ name, dailyReturnsMap, color }) => ({
        name,
        color,
        dailyReturns: map<string, [string, number]>((date) => [
          date,
          formatNilToZero(fastProp(date)(dailyReturnsMap)),
        ])(maxDates),
      }))(fundManagerAndBenchmarkInfo),
    [fundManagerAndBenchmarkInfo, maxDates]
  );

  return { dates: maxDates, chartData, minStartDate, maxEndDate };
};

export default React.memo<{
  managerIds: string[];
}>(({ managerIds }): JSX.Element => {
  const [activeRange, setActiveRange] = useState(COMMON_TIME);
  const [benchmarkId, setBenchmarkId] = useState(CSI300_ID);
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchFundManagerCompareDailyReturn(managerIds));
  }, [managerIds, dispatch]);
  const { dates, chartData, maxEndDate, minStartDate } = useGetChartData(
    managerIds,
    activeRange,
    benchmarkId
  );
  return (
    <LoadingComponent actions={fetchFundManagerCompareScaleChange}>
      <Card>
        <Space direction="vertical" className={style.fullWidth}>
          <h3>{formatMessage("profitability")}</h3>
          <div className={style.FundManagerSelect}>
            <Space>
              <FundManagerSelect
                activeRange={activeRange}
                benchmarkId={benchmarkId}
                staticRange={statisticOptions as any}
                onRangeChange={(v) => setActiveRange(v)}
                onBenchmarkChange={(v) => setBenchmarkId(v)}
                startDate={minStartDate}
                endDate={maxEndDate}
              />
              <ChartRangePicker
                dateList={dates}
                className={style.RangePicker}
              />
            </Space>
          </div>
          {size(dates) ? (
            <CumulativeChart
              dates={dates}
              dataSource={chartData}
              type="cumulative"
            />
          ) : (
            <EmptyContent
              className={style.EmptyContent}
              message={formatMessage("noData")}
            />
          )}
        </Space>
      </Card>
    </LoadingComponent>
  );
});
