import React from "react";
import { Form } from "antd";
import cn from "classnames";
import {
  FundConfigurationForm,
  FundConfigurationFormUpdater,
} from "../../interface";
import style from "./index.module.less";
import NumberInput from "@/components/numberInput";
import ModelRangeRatio from "../../components/modelRangeRatio";
import RiskFreeRate from "./riskFreeRate";
import Description from "./description";
import ErrorField from "@/views/portfolioManage/manualCreatePortfolio/components/errorField";
import { useFormatMessage } from "@/util/formatMessage";

export type ModelForm = {
  formData: FundConfigurationForm;
  startDate: string;
  endDate: string;
  updateFormData: FundConfigurationFormUpdater;
  riskValueError?: boolean;
};

export default React.memo<ModelForm>(
  ({ formData, updateFormData, startDate, endDate, riskValueError }) => {
    const formatMessage = useFormatMessage();
    return formData.modelType ? (
      <div className={cn(style.GreyLayout, style.MarginTop16)}>
        {formData.modelType === "maximizationSharpe" && (
          <Form.Item
            label={formatMessage("riskFreeRate")}
            className={cn(style.FormItem, style.FormLabelWidth110)}
          >
            <RiskFreeRate
              onChange={updateFormData("riskFreeConfig")}
              value={formData.riskFreeConfig}
            />
          </Form.Item>
        )}
        {formData.modelType === "minimizationRisk" && (
          <Form.Item
            label={formatMessage("yearExpectedReturnDescription")}
            className={cn(style.FormItem, style.FormLabelWidth110)}
          >
            <NumberInput
              type="PERCENTAGE"
              value={formData.annualizedMinReturn as number}
              min={0}
              max={1}
              onChange={updateFormData("annualizedMinReturn")}
              precision={2}
              allowClear
              size="small"
              placeholder="0~100"
              addonBefore=">="
              addonAfter="%"
              className={style.RiskInput}
            />
          </Form.Item>
        )}
        {formData.modelType === "maximizationOfExpected" && (
          <Form.Item
            label={formatMessage("annualRisk")}
            className={cn(style.FormItem, style.FormLabelWidth110)}
          >
            <NumberInput
              type="PERCENTAGE"
              value={formData.riskValue as number}
              min={0}
              max={1}
              onChange={updateFormData("riskValue")}
              precision={2}
              allowClear
              size="small"
              placeholder="0~100"
              addonBefore="<="
              addonAfter="%"
              className={cn({
                [style.RiskInput]: true,
                [style.InputError]: riskValueError,
              })}
            />
            {riskValueError && (
              <ErrorField
                message={formatMessage("pleaseInputRiskMaxValue")}
                className={style.MarginTop8}
              />
            )}
          </Form.Item>
        )}
        {formData.modelType !== "equalWeight" &&
          formData.modelType !== "marketWeight" && (
            <Form.Item
              label={formatMessage("ModelDuration")}
              className={cn(style.FormItem, style.FormLabelWidth110)}
            >
              <ModelRangeRatio
                startDate={startDate}
                endDate={endDate}
                value={formData.dataRange}
                onChange={updateFormData("dataRange")}
              />
            </Form.Item>
          )}
        <Description modelType={formData.modelType} />
      </div>
    ) : (
      <div className={style.EmptyContent} />
    );
  }
);
