import { Card, Space } from "antd";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { prop } from "lodash/fp";
import React, { useMemo } from "react";
import { useFormatMessage, FormatMessageFunc } from "@/util/formatMessage";
import HorizontalTable from "@/components/horizontalTable";
import Rating from "@/components/rating";
import ToFundManagerDetailPage from "@/components/navigateToPage/toFundManagerDetailPage";
import style from "../../index.module.less";
import { useAppSelector } from "@/hooks/redux";
import { fixedNumber, formatPercentage } from "@/util/numberFormatter";
import { getYearByStartAndEnd } from "@/util/business-core/tradingDate";
import { fundIdMapSelector } from "@/selectors/fund";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import ToFundCompanyDetailPage from "@/components/navigateToPage/toFundCompanyDetailPage";
import { AddFundManagerOptional } from "@/components/addOptional";

const getColumns = (formatMessage: FormatMessageFunc) => [
  {
    columnName: formatMessage("manageScale"),
    dataIndex: "manageScale",
  },
  {
    columnName: formatMessage("manageYearNum"),
    dataIndex: "manageStartDate",
  },
  {
    columnName: formatMessage("managementNum"),
    dataIndex: "manageAmount",
  },
  {
    columnName: formatMessage("investType"),
    dataIndex: "investType",
  },
  {
    columnName: formatMessage("betalphaManagerRating"),
    dataIndex: "score",
  },
  {
    columnName: formatMessage("representFund"),
    dataIndex: "majorFund",
  },
  {
    columnName: formatMessage("annualizedIncome"),
    dataIndex: "annualizedReturn",
  },
  {
    columnName: formatMessage("fundCompanies"),
    dataIndex: "company",
  },
];
const ManagerStartDate = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string | null;
}) => {
  const formatMessage = useFormatMessage();
  const manageStartDate = `${getYearByStartAndEnd(
    startDate,
    endDate
  )}${formatMessage("year")}`;
  return <div>{manageStartDate}</div>;
};

export default React.memo<{
  className?: string;
  managerIds: string[];
}>(({ className, managerIds }) => {
  const dataSource = useAppSelector(
    prop("compareManage.fundManager.basicInfo")
  );
  const fundsMap = useAppSelector(fundIdMapSelector);
  const formatMessage = useFormatMessage();
  const tableData = useMemo(
    () =>
      mapIndexed((id: string, index: number) => {
        const currentData = fastNth(index)(dataSource);
        const majorFundId = fastProp("majorFund")(currentData) as string;
        return {
          ...currentData,
          id,
          majorFund: prop(`${majorFundId}.name`)(fundsMap),
          render: (value: string | number, record: Record<string, any>) => {
            switch (fastProp("dataIndex")(record)) {
              case "majorFund":
                return (
                  <ToFundDetailPage name={value as string} id={majorFundId} />
                );
              case "company":
                return (
                  <ToFundCompanyDetailPage
                    name={value as string}
                    id={fastProp("companyId")(currentData)}
                  />
                );
              case "manageStartDate":
                return (
                  <ManagerStartDate
                    startDate={value as string}
                    endDate={record?.manageEndDate}
                  />
                );
              case "manageScale":
                return fixedNumber(value as number);
              case "manageAmount":
                return formatMessage("fundTotalCount", {
                  count: value,
                });
              case "annualizedReturn":
                return formatPercentage(value as number);
              case "score":
                return <Rating rating={(value as number) || 0} />;
              default:
                return value;
            }
          },
          width: 400,
        };
      })(managerIds),
    [dataSource, formatMessage, fundsMap, managerIds]
  );

  const headingColumn = useMemo(
    () => ({
      title: formatMessage("fundManagers"),
      dataIndex: "columnName",
      width: 200,
      render: (fundManagerName: string, record: Record<string, any>) => (
        <div className={style.FundNameWrap}>
          <ToFundManagerDetailPage
            name={fundManagerName}
            id={fastProp("id")(record)}
          />
          {fastProp("id")(record) && (
            <AddFundManagerOptional id={fastProp("id")(record)} />
          )}
        </div>
      ),
    }),
    [formatMessage]
  );
  const columns = useMemo(() => getColumns(formatMessage), [formatMessage]);

  return (
    <Card bordered={false} className={className}>
      <Space direction="vertical" className={style.fullWidth}>
        <h3>{formatMessage("basicInfo")}</h3>
        <HorizontalTable
          id="id"
          titleKey="name"
          key="dataIndex"
          headingColumn={headingColumn}
          columns={columns}
          dataSource={tableData}
          className={style.BasicInfoTable}
          pagination={false}
          bordered
        />
      </Space>
    </Card>
  );
});
