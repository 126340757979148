import React from "react";
import ManagerIntroCard from "./components/managerIntroCard";
import EarningAbility from "./components/earningAbility";
import ManagerDrawdown from "./components/managerDrawdown";
import style from "./index.module.less";
import { BaseFundManagerDetailInfoProps } from "../interface";

export default React.memo<BaseFundManagerDetailInfoProps>(({ id }) => {
  return (
    <>
      <ManagerIntroCard id={id} />
      <EarningAbility id={id} className={style.MarginTop16} />
      <ManagerDrawdown id={id} className={style.MarginTop16} />
    </>
  );
});
