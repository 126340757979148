import { useFormatMessage } from "@/util/formatMessage";
import { Button, Card, Checkbox, Radio, Space } from "antd";
import { compact, filter, flow, isEmpty, map, size, values } from "lodash/fp";
import React, { useMemo } from "react";
import { ReplaceRules } from "../portfolioCompoents/constant";
import style from "./index.module.less";
import cn from "classnames";
import { Form } from "antd";
// import { CheckboxChangeEvent } from "antd/lib/checkbox";
import AlternateFund from "./components/alternateFund";
import { fastProp } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import {
  defalutIntraGroupAllcation,
  defaultalternativeFund,
  defaultReplaceFund,
  intraGroupAllcationInfo,
  replaceRuleConfig,
} from "./constant";
import { useGetFundReplace } from "./hooks";
import NumberInput from "../numberInput";

export const validateFundReplaceError = (replaceInfo: Record<string, any>) => {
  if (replaceInfo?.rule === replaceRuleConfig.alternateFund) {
    if (replaceInfo.isAppoint) {
      return !flow(
        values,
        filter((item: string[]) => !!size(compact(item))),
        size
      )(replaceInfo.replaceFund);
    } else {
      return isEmpty(replaceInfo.replaceFundPool);
    }
  }
  if (replaceInfo?.rule === replaceRuleConfig.replaceFund) {
    return replaceInfo?.similarPosition || replaceInfo?.similarNetValue
      ? false
      : true;
  }
  return false;
};

const RulesButtonGroup = ({
  selectedRule,
  onchangRule,
}: {
  selectedRule: string;
  onchangRule: (v: string) => void;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  const handleRule = useMemoizedFn((v: string) => {
    onchangRule(v);
  });
  return (
    <div>
      <Space>
        {map(({ id, messageKey }: { id: string; messageKey: string }) => (
          <div
            className={
              selectedRule === id
                ? style.SelectedRulesButton
                : style.RulesButtonGroup
            }
            key={id}
          >
            <Button
              className={style.RuleBtn}
              key={id}
              type="primary"
              onClick={() => handleRule(id)}
            >
              {formatMessage(messageKey)}
            </Button>
          </div>
        ))(ReplaceRules)}
      </Space>
    </div>
  );
};

type FundReplaceRuleProps = {
  value: Record<string, any>;
  fundList: Record<string, any>[];
  onChange: (data: Record<string, any>) => void;
  className?: string;
};

const FundReplaceRule = ({
  className,
  fundList,
  value,
  onChange,
}: FundReplaceRuleProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const fundIds = useMemo(() => {
    return map((item: Record<string, any>) => item?.fundId)(fundList);
  }, [fundList]);

  const { changeReplace } = useGetFundReplace(onChange);

  const changeRadio = useMemoizedFn((key: string) => {
    if (value?.rule === key) return;
    switch (key) {
      case replaceRuleConfig.alternateFund:
        onChange(defaultalternativeFund);
        break;
      case replaceRuleConfig.intraGroupAllcation:
        onChange(defalutIntraGroupAllcation);
        break;
      case replaceRuleConfig.replaceFund:
        onChange(defaultReplaceFund);
        break;
      default:
        onChange({ rule: key });
        break;
    }
  });

  return (
    <Card className={className}>
      <Space size="large">
        <h4>{formatMessage("fundReplaceRule")}:</h4>
        <RulesButtonGroup
          selectedRule={value?.rule}
          onchangRule={changeRadio}
        />
      </Space>
      {value?.rule === replaceRuleConfig.cash && (
        <div className={style.FundReplaceRuleBody}>
          <span>{formatMessage("cashInfo")}</span>
        </div>
      )}
      {value?.rule === replaceRuleConfig.intraGroupAllcation && (
        <div className={style.FundReplaceRuleBody}>
          <Space direction="vertical" className={style.FullWidth} size={20}>
            <Radio.Group
              onChange={(e) => changeReplace("allocateType")(e.target.value)}
              value={fastProp("allocateType")(value)}
            >
              <Radio value="EQUAL">{formatMessage("equalDistribution")}</Radio>
              <Radio value="SCALE">
                {formatMessage("marketValueAllocation")}
              </Radio>
            </Radio.Group>
            <p className={style.MarginLeft}>
              {formatMessage(
                fastProp(fastProp("allocateType")(value))(
                  intraGroupAllcationInfo
                )
              )}
            </p>
          </Space>
        </div>
      )}
      {value?.rule === replaceRuleConfig.replaceFund && (
        <div className={style.ReplaceFundBody}>
          <Form.Item
            label={formatMessage("alternativeIndex")}
            className={cn(style.FormItem, style.FormLabelWidth110)}
          >
            <Space>
              <Checkbox
                checked={fastProp("similarPosition")(value)}
                onChange={(e) =>
                  changeReplace("similarPosition")(e.target.checked)
                }
              >
                <span className={style.checkboxItem}>
                  {formatMessage("similarPositions")}
                </span>
              </Checkbox>
              <Checkbox
                checked={fastProp("similarNetValue")(value)}
                onChange={(e) =>
                  changeReplace("similarNetValue")(e.target.checked)
                }
              >
                <span className={style.checkboxItem}>
                  {formatMessage("netWorthCorrelation")}
                </span>
              </Checkbox>
            </Space>
          </Form.Item>
          <Form.Item
            label={formatMessage("largeTransaction")}
            className={cn(style.FormItem, style.FormLabelWidth110)}
          >
            <Space>
              <Checkbox
                checked={fastProp("blockTrade")(value)}
                onChange={(e) => changeReplace("blockTrade")(e.target.checked)}
              >
                <span className={style.checkboxItem}>
                  {formatMessage("showOnlyFundsThatSupportLargeTransactions")}
                </span>
              </Checkbox>
            </Space>
          </Form.Item>
          <Form.Item
            label={formatMessage("purchFeeRatio")}
            className={cn(style.FormItem, style.FormLabelWidth110)}
          >
            <NumberInput
              type="PERCENTAGE"
              min={0}
              max={0.05}
              precision={2}
              placeholder=""
              addonAfter="%"
              className={style.WeightInput}
              value={fastProp("purchFeeRatio")(value)}
              onChange={changeReplace("purchFeeRatio")}
            />
          </Form.Item>
          <Form.Item
            label={formatMessage("redeemFeeRatio")}
            className={cn(style.FormItem, style.FormLabelWidth110)}
          >
            <NumberInput
              type="PERCENTAGE"
              min={0}
              max={0.05}
              precision={2}
              placeholder=""
              addonAfter="%"
              className={style.WeightInput}
              value={fastProp("redeemFeeRatio")(value)}
              onChange={changeReplace("redeemFeeRatio")}
            />
          </Form.Item>
          {value?.rule !== replaceRuleConfig.cash && (
            <div className={style.ReplaceTip}>
              {formatMessage("fundReplaceRuleTip")}
            </div>
          )}
        </div>
      )}

      {value?.rule === replaceRuleConfig.alternateFund && (
        <AlternateFund
          className={style.ReplaceFundBody}
          ruleInfo={value}
          changeReplaceRule={changeReplace}
          fundIds={fundIds}
          fundList={fundList}
        />
      )}
    </Card>
  );
};

export default React.memo(FundReplaceRule);
