import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PortfolioAnalysisSubtitle from "../../components/portfolioAnalysisSubtitle";
import ToFundManagerDetailPage from "@/components/navigateToPage/toFundManagerDetailPage";
import { useFormatMessage } from "@/util/formatMessage";
import { Space, Table, TableProps } from "antd";
import style from "../index.module.less";
import { PortfolioAnalysisProps } from "../..";
import TradingDatePicker from "@/components/tradingDatePicker";
import { useMemoizedFn } from "ahooks";
import { useAppSelector } from "@/hooks/redux";
import { analysisBasicInfoSelector } from "../../selectors";
import { fetchAssetPositionDetail } from "@/store/portfolioAnalysis";
import { formatPercentage, toFixedNumber } from "@/util/numberFormatter";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { fundIdMapSelector } from "@/selectors/fund";
import { fastProp, normalize } from "@/util/opt";
import {
  concat,
  first,
  flow,
  groupBy,
  includes,
  isEmpty,
  last,
  map,
  prop,
  pull,
  sumBy,
  uniq,
} from "lodash/fp";
import { assetPortfoliosPositionDetailAsset } from "@/model/portfolioAnalysis";
import mapper from "../mapper";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { getDisabledDate } from "@/util/processedDates";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import ErrorBoundary from "@/components/errorBoundary";
import {
  categoryIdMapSelector,
  categoryTreeMapSelector,
} from "@/selectors/category";
import { managerNameMapSelector } from "@/selectors/manager";
import { errorValidator } from "@/components/errorBoundary/constant";
import LoadingComponent from "@/components/LoadingComponent";

const needTime = 1;
type TablePropsType = TableProps<any>;
type SummaryData = {
  weight: number;
  scale: number;
  share: number;
};
const useGetDataSource = (id: string, date: string) => {
  const {
    assetPosition: { assetPositionDetail },
  } = useAppSelector((state) => mapper(state, id));
  const categoryTreeMap = useAppSelector(categoryTreeMapSelector);
  const categoryIdMap = useAppSelector(categoryIdMapSelector);
  const fundIdMap = useAppSelector(fundIdMapSelector);
  const assetPosition = useMemo(
    () => fastProp(date)(assetPositionDetail),
    [assetPositionDetail, date]
  );
  const assets = useMemo(
    () => fastProp("assets")(assetPosition),
    [assetPosition]
  );
  const assetsGroup = useMemo(
    () =>
      flow(
        map((v: assetPortfoliosPositionDetailAsset) => ({
          ...v,
          parentType: prop(
            `${prop(`${v?.fundId}.categoryId`)(fundIdMap)}.name`
          )(categoryIdMap),
        })),
        groupBy("parentType")
      )(assets),
    [assets, categoryIdMap, fundIdMap]
  );
  const level = useMemo(
    () =>
      map((v: assetPortfoliosPositionDetailAsset) => {
        const categoryId = prop(`${v?.fundId}.categoryId`)(fundIdMap);
        const path = prop(`${categoryId}.path`)(categoryTreeMap);
        return {
          parentType: prop(`${first(path)}.name`)(categoryIdMap),
          type: prop(`${last(path)}.name`)(categoryIdMap),
        };
      })(assets),
    [assets, categoryIdMap, categoryTreeMap, fundIdMap]
  );
  const firstLevel = useMemo(
    () => uniq(map(fastProp("parentType"))(level)),
    [level]
  );
  const secondLevel = useMemo(
    () => uniq(map(fastProp("type"))(level)),
    [level]
  );
  const levelMap = useMemo(() => normalize("type")(level), [level]);
  const secondLevelData = useMemo(
    () =>
      flow(
        map((v: string) => {
          const children = assetsGroup?.[v];
          return {
            children,
            scale: sumBy("scale")(children),
            share: sumBy("share")(children),
            weight: sumBy("weight")(children),
            key: v,
            type: v,
            parentType: prop(`${v}.parentType`)(levelMap),
          };
        }),
        groupBy("parentType")
      )(secondLevel),
    [assetsGroup, levelMap, secondLevel]
  );
  const allExpandedRowKeys = useMemo(() => {
    const secondLevel = map(fastProp("type"))(level);
    return [...firstLevel, ...secondLevel];
  }, [firstLevel, level]);
  const dataSource = useMemo(
    () =>
      map((v: string) => {
        const children = secondLevelData?.[v];
        return {
          children,
          weight: sumBy("weight")(children),
          scale: sumBy("scale")(children),
          share: sumBy("share")(children),
          key: v,
          type: v,
        };
      })(firstLevel),
    [firstLevel, secondLevelData]
  );
  const summaryData = useMemo<SummaryData>(
    () => ({
      weight: sumBy("weight")(dataSource),
      scale: sumBy("scale")(dataSource),
      share: sumBy("share")(dataSource),
    }),
    [dataSource]
  );
  return useMemo(
    () => ({
      allExpandedRowKeys,
      dataSource,
      summaryData,
    }),
    [allExpandedRowKeys, dataSource, summaryData]
  );
};
const TableSummary = ({
  summaryData,
}: {
  summaryData: SummaryData;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  const { weight, scale, share } = summaryData;
  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>
          {formatMessage("total")}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} align="right">
          {formatPercentage(weight)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4} align="right">
          {toFixedNumber(2)(scale)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5} align="right">
          {toFixedNumber(2)(share)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} />
      </Table.Summary.Row>
    </Table.Summary>
  );
};

export default React.memo<PortfolioAnalysisProps>((props) => {
  const { id } = props;
  const formatMessage = useFormatMessage();
  const { startDate, endDate } = useAppSelector((state) =>
    analysisBasicInfoSelector(state, id)
  );
  const [tradingDate, setTradingDate] = useState(endDate);
  useEffect(() => {
    if (endDate) setTradingDate(endDate);
  }, [endDate]);
  const processedTradingDates = useAppSelector(
    (store) => store.tradingDates.processedTradingDates
  );
  const startDisabledDate = useMemoizedFn((value: any) => {
    return getDisabledDate({
      time: value,
      start: startDate,
      end: endDate,
      tradingDate: processedTradingDates,
    });
  });
  const { dispatch } = useContext(PortfolioAnalysisContext);
  useEffect(() => {
    if (id && tradingDate) {
      dispatch(needTime, fetchAssetPositionDetail({ id, date: tradingDate }));
    }
  }, [dispatch, id, tradingDate]);
  const fundIdMap = useAppSelector(fundIdMapSelector);
  const { allExpandedRowKeys, dataSource, summaryData } = useGetDataSource(
    id,
    tradingDate
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  useEffect(() => {
    setExpandedRowKeys(allExpandedRowKeys);
  }, [allExpandedRowKeys]);
  const onExpandAllRows = useCallback(() => {
    setExpandedRowKeys(isEmpty(expandedRowKeys) ? allExpandedRowKeys : []);
  }, [allExpandedRowKeys, expandedRowKeys]);
  const managerNameMap = useAppSelector(managerNameMapSelector);
  const column = useMemo<TablePropsType["columns"]>(
    () => [
      {
        dataIndex: "type",
        key: "expendToFund",
        title: (
          <Space size={4}>
            <span onClick={onExpandAllRows} className={style.ExpendToFund}>
              {!isEmpty(expandedRowKeys) ? (
                <MinusSquareOutlined />
              ) : (
                <PlusSquareOutlined />
              )}
            </span>
            {formatMessage("expendToFund")}
          </Space>
        ),
      },
      {
        key: "fundNameAndCode",
        title: formatMessage("fundNameAndCode"),
        dataIndex: "fundId",
        render: (fundId) => {
          if (!fundId) return "";
          const { name, code } = fastProp(fundId)(fundIdMap) || {};
          return <ToFundDetailPage name={`${name} (${code})`} id={fundId} />;
        },
      },
      {
        key: "fundManagers",
        title: formatMessage("fundManagers"),
        dataIndex: "fundManagers",
        render: (fundManagers: string[]) =>
          map((v: string) => (
            <>
              <ToFundManagerDetailPage
                name={prop(`${v}.name`)(managerNameMap)}
                id={v}
              />{" "}
            </>
          ))(fundManagers),
      },
      {
        key: "weight",
        title: formatMessage("Weight"),
        dataIndex: "weight",
        align: "right",
        render: (value) => {
          return formatPercentage(value);
        },
      },
      {
        key: "ScaleWan",
        title: formatMessage("ScaleWan"),
        dataIndex: "scale",
        align: "right",
        render: (value) => {
          return toFixedNumber(2)(value);
        },
      },
      {
        key: "portion",
        title: formatMessage("portion"),
        dataIndex: "share",
        align: "right",
        render: toFixedNumber(2),
      },
      {
        key: "NetValue",
        title: formatMessage("NetValue"),
        dataIndex: "navAdj",
        align: "right",
        render: toFixedNumber(4),
      },
    ],
    [expandedRowKeys, formatMessage, fundIdMap, managerNameMap, onExpandAllRows]
  );
  const onExpand = useCallback(
    (key: string) => {
      if (includes(key)(expandedRowKeys)) {
        setExpandedRowKeys(pull(key));
      } else {
        setExpandedRowKeys(concat(key));
      }
    },
    [expandedRowKeys]
  );
  const expandable = useMemo<TablePropsType["expandable"]>(
    () => ({
      expandedRowKeys,
      onExpand: (_, record) => onExpand(record.key),
    }),
    [expandedRowKeys, onExpand]
  );
  const { runningTime } = useContext(PortfolioAnalysisContext);
  return (
    <>
      <PortfolioAnalysisSubtitle name={formatMessage("positionDetail")} />
      <ErrorBoundary errKey={errorValidator.lessOneDay(needTime, runningTime)}>
        <Space direction="vertical" className={style.detailSpace}>
          <div>
            <Space>
              <span>{formatMessage("analysisDate")}</span>
              <TradingDatePicker
                value={tradingDate}
                disabledDate={startDisabledDate}
                onChange={setTradingDate}
                allowClear={false}
              />
            </Space>
          </div>
          <LoadingComponent actions={fetchAssetPositionDetail}>
            <Table
              pagination={false}
              dataSource={dataSource}
              columns={column}
              bordered
              expandable={expandable}
              className={style.PositionTable}
              summary={() => <TableSummary summaryData={summaryData} />}
            />
          </LoadingComponent>
        </Space>
      </ErrorBoundary>
    </>
  );
});
