import { useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollToPosition } from "@/scroll";
import { idToHash } from "./constant";

export const useNavigateByHash = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return useCallback(
    (id: string) =>
      navigate({
        pathname: location.pathname,
        hash: idToHash(id),
      }),
    [location.pathname, navigate]
  );
};

export const useScrollHandler = (): [
  React.MutableRefObject<null>,
  (selector: string) => void
] => {
  const rootRef = useRef(null);
  const onScroll = useCallback(
    (id: string) =>
      scrollToPosition({
        rootRef: rootRef.current,
        selector: idToHash(id),
        behavior: "smooth",
      }),
    []
  );
  return [rootRef, onScroll];
};
