import { message, Space } from "antd";
import React, { ReactNode, useMemo } from "react";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";
import tagIcon from "@/assets/customerAnalysisIcons/tagIcon.png";
import AddTag from "./addTag";
import { fastProp, normalize } from "@/util/opt";
import { filter, map, prop, size } from "lodash/fp";
import { CloseCircleFilled } from "@ant-design/icons";
import { useMemoizedFn } from "ahooks";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import cn from "classnames";

interface CustomerTagProp {
  showTag: string[];
  setShowTag: (data: string[]) => void;
  disabled: boolean;
  selectedTag: string[];
  setSelectedTag: (data: string[]) => void;
}

export default React.memo<CustomerTagProp>(
  ({ showTag, setShowTag, disabled, selectedTag, setSelectedTag }) => {
    const formatMessage = useFormatMessage();

    const options = useAppSelector(
      (state: RootState) => state.createCustomer.allCustomerTag
    );

    const optionMap = useMemo(() => normalize("id")(options), [options]);

    const deleteTag = useMemoizedFn((id: string) => {
      const newData = filter((key: string) => key !== id)(selectedTag);
      setSelectedTag(newData);
    });

    const deleteShowTag = useMemoizedFn((id: string) => {
      const newData = filter((key: string) => key !== id)(showTag);
      setShowTag(newData);
    });

    const onChangeShowTag = useMemoizedFn((id: string) => {
      if (size(showTag) >= 3) {
        message.warning(formatMessage("maxTagNumber"));
        return false;
      }
      const newTag = (showTag || []).includes(id)
        ? showTag || []
        : (showTag || []).concat([id]);
      setShowTag(newTag);
    });
    //   const confirm = useMemoizedFn((e: any) => {

    //   });
    return (
      <div className={style.FullWidth}>
        <div className={style.TitleContent}>
          <Space align="start" size={0}>
            <img src={tagIcon} />
            <span className={style.SubTitle}>
              {formatMessage("customerTag")}
            </span>
          </Space>
        </div>
        <div>
          <span className={style.ShowTag}>
            {formatMessage("analysisPageShowTag")}
          </span>
          <span className={style.DragTip}>({formatMessage("tagDragTip")})</span>
        </div>
        <div className={style.DndPart}>
          <DndProvider backend={HTML5Backend}>
            <DropContainer>
              {map((id: string) =>
                fastProp(id)(optionMap) ? (
                  <div
                    key={id}
                    className={cn(
                      style.SelectedTag,
                      style.DroppedTag,
                      disabled && style.DisabledSelectedTag
                    )}
                  >
                    {prop(`${id}.name`)(optionMap)}
                    {!disabled && (
                      <CloseCircleFilled
                        className={style.DeleteIcon}
                        onClick={() => deleteShowTag(id)}
                      />
                    )}
                  </div>
                ) : null
              )(showTag)}
            </DropContainer>
            <div className={style.TagPart}>
              <AddTag
                selectedTag={selectedTag}
                onChangeTag={setSelectedTag}
                disabled={disabled}
              />
              {map((id: string) =>
                fastProp(id)(optionMap) ? (
                  <DragItem
                    className={style.DragItem}
                    id={id}
                    onChangeShowTag={onChangeShowTag}
                    disabled={disabled}
                  >
                    <div
                      key={id}
                      className={cn(
                        style.SelectedTag,
                        disabled && style.DisabledSelectedTag
                      )}
                    >
                      {prop(`${id}.name`)(optionMap)}
                      {!disabled && (
                        <CloseCircleFilled
                          className={style.DeleteIcon}
                          onClick={() => deleteTag(id)}
                        />
                      )}
                    </div>
                  </DragItem>
                ) : null
              )(selectedTag)}
            </div>
          </DndProvider>
        </div>
      </div>
    );
  }
);

interface DragItemProps {
  children: ReactNode;
  id: string;
  className?: string;
  onChangeShowTag: (id: string) => void;
}

const DragItem = React.memo<DragItemProps & { disabled: boolean }>(
  ({ children, id, onChangeShowTag, className, disabled }) => {
    const [, dragRef] = useDrag(() => ({
      type: "Box",
      item: { id },
      end: (item) => {
        onChangeShowTag(item?.id);
        //   console.log("args", item, monitor.getDropResult());
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }));
    return (
      <div ref={disabled ? null : dragRef} className={className}>
        {children}
      </div>
    );
  }
);
interface DropContainerProps {
  children: ReactNode;
}

const DropContainer = React.memo<DropContainerProps>(({ children }) => {
  const [, drop] = useDrop(() => ({
    accept: "Box",
    // drop: (item, monitor) => {
    //   console.log("containerDrop", item, monitor.didDrop());
    // },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  return (
    <div ref={drop} className={style.DropBox}>
      {children}
    </div>
  );
});
