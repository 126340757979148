import { orderBy, identity, first } from "lodash/fp";
import { RootState } from "@/store";
import { createSelector } from "@reduxjs/toolkit";

export const preferredFundListSelector = createSelector(
  (state: RootState) => state.preferredFund.preferredFundList,
  (preferredFund) => preferredFund
);

export const preferredUpdateSelector = createSelector(
  (state: RootState) => state.preferredFund.preferredDate,
  (preferredDate) => orderBy(identity, "desc")(preferredDate) as string[]
);

export const preferredFilterSelector = createSelector(
  (state: RootState) => state.preferredFund.preferredFilter,
  preferredUpdateSelector,
  (preferredFilter, preferredDate) => {
    const { date } = preferredFilter;
    return { ...preferredFilter, date: date || first(preferredDate) };
  }
);

export const preferredResultSelector = createSelector(
  (state: RootState) => state.preferredFund.preferredResultData,
  (preferredResultData) => preferredResultData
);
