import React, { useEffect, useRef, useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { fastProp, forEachIndexed } from "@/util/opt";
import type {
  LegendComponentOption,
  TooltipComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
  BarSeriesOption,
  GridComponentOption,
} from "echarts";
import { isNumber, map, flow, size } from "lodash/fp";
import { getChartDataZoomConfig, getTooltipItem } from "@/util/chart";
import { formatPercentage } from "@/util/numberFormatter";
const percentage = (value: number) => formatPercentage(value);

export interface FundDistributionChartOpts {
  events?: Record<string, (...args: any[]) => void>;
  width?: number | string;
  height?: number | string;
  options: {
    grid?: GridComponentOption;
    xAxis?: XAXisComponentOption;
    yAxis?: YAXisComponentOption;
    tooltip?: TooltipComponentOption;
    legend?: LegendComponentOption;
    series?: BarSeriesOption[];
    color?: string[];
  };
  showDataZoom?: boolean;
  totalData: number[];
}

export default React.memo<FundDistributionChartOpts>(
  ({
    options,
    events = {},
    width = "100%",
    showDataZoom,
    height = 300,
    totalData,
  }) => {
    const serise = useMemo(() => {
      if (!size(fastProp("series")(options))) return [];
      return flow(
        map<BarSeriesOption, BarSeriesOption>((data) => ({
          type: "bar",
          stack: "total",
          colorBy: "data",
          legendHoverLink: false,
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
            // barBorderRadius: [0, 5, 5, 0],
          },
          label: {
            show: false,
          },
          barWidth: "40",
          ...data,
        })),
        (res) =>
          res.concat([
            {
              name: "total",
              type: "bar",
              barGap: "-100%",
              color: "transparent",
              label: {
                show: true,
                formatter: (param: Record<string, any>) => {
                  return percentage(param.value);
                },
                position: "right",
                color: "#0E1D32",
              },
              // itemStyle: {
              //   color: "transparent", // 柱状图颜色设为透明
              // },
              barWidth: "40",
              data: totalData,
            },
          ])
      )(fastProp("series")(options));
    }, [options, totalData]);
    const handleOptions = useMemo(
      () => ({
        grid: {
          left: 0,
          right: 55,
          top: 50,
          bottom: 50,
          containLabel: true,
          ...fastProp("grid")(options),
        },
        tooltip: {
          trigger: "axis",
          type: "shadow",
          formatter: (param: Record<string, any>) => {
            let showHtml = "";
            forEachIndexed((item: Record<string, any>, index: number) => {
              showHtml +=
                index === 0
                  ? `<h5>
                ${item.name}
                </h5>`
                  : "";
              if (item.seriesName !== "total" && item.value) {
                return (showHtml += getTooltipItem(
                  item.color,
                  item.seriesName,
                  percentage(item.value),
                  10,
                  5
                ));
              }
            })(param);
            return showHtml;
          },
          // valueFormatter: (value: number) => formatPercentage(value),
          ...fastProp("tooltip")(options),
        },
        legend: {
          top: 0,
          left: 0,
          right: 50,
          align: "left",
          ...fastProp("legend")(options),
        },
        xAxis: {
          type: "value",
          nameGap: 20,
          ...fastProp("xAxis")(options),
        },
        yAxis: {
          type: "category",
          max: "dataMax",
          axisTick: {
            lineStyle: {
              type: "dashed",
            },
          },
          ...fastProp("yAxis")(options),
        },
        ...(showDataZoom
          ? {
              dataZoom: [getChartDataZoomConfig()],
            }
          : {}),
        series: serise,
        color: fastProp("color")(options),
      }),
      [options, showDataZoom, serise]
    );
    const echartStyle = useMemo(
      () => ({
        width: isNumber(width) ? `${width}px` : width,
        height: isNumber(height) ? `${height}px` : height,
      }),
      [height, width]
    );
    const chartRef = useRef<any>(null);
    useEffect(() => {
      if (chartRef.current) {
        chartRef.current?.resize();
      }
    }, []);
    return (
      <ReactEcharts
        option={handleOptions}
        ref={chartRef}
        onEvents={events}
        style={echartStyle}
      />
    );
  }
);
