export const benchmarkIds = [
  "20a02dc5-d754-11e8-a419-c4b301c5b6bb", //策略指数
  "5c350af1-180e-45bc-b020-1f07b72f8627", //ETF指数
  "cbb61223-bbdd-11e8-8b62-c4b301c5b6bb", //风格指数
  "7e9d2a66-bbdd-11e8-a5a7-c4b301c5b6bb", //宽基指数
];

export const marketIndexIds = [
  "20a02dc5-d754-11e8-a419-c4b301c5b6bb", //策略指数
  "e0da4f26-bbdd-11e8-b051-c4b301c5b6bb", //申万行业指数
  "cbb61223-bbdd-11e8-8b62-c4b301c5b6bb", //风格指数
  "7e9d2a66-bbdd-11e8-a5a7-c4b301c5b6bb", //宽基指数
];

export const POSITION_TYPE = "RELATION";

export const  customIndexId= "739bae2e-bbee-11e8-be20-c4b301c5b6bb"
