import { del, get, getWithCache, post, put } from "@/util/axios";
import { PromiseType } from "@/model/promise";
import {
  assetPortfolios,
  assetsType,
  TurnoverRecordHistory,
  TurnoverRecord,
  portfolioIds,
  dailyEarningsType,
  PortfoliosSimple,
} from "@/model/portfolioList";
import {
  InitSimpleDataRequestType,
  InitSimpleDataType,
} from "@/components/portfolioCompoents/hook";
import { SaveSchemeFormType } from "@/views/portfolioManage/manualCreatePortfolio/backTestingAllocation/saveScheme";
import { socketHttp } from "@/util/socket";
import { AssetAllocateParams } from "@/model/portfolioAnalysis";
import { getCategoryAllocateParam } from "@/views/portfolioManage/fromAboveToBelow/interface";

// 组合列表
export const getPortfolioList = (): PromiseType<assetPortfolios[]> =>
  get("asset-portfolios", undefined);

export const getPortfolioDailyEarnings = (
  body: portfolioIds
): PromiseType<dailyEarningsType[]> =>
  get("portfolio/daily_return", { ...body });
export const deletePortfolio = (portfolioId: string): PromiseType<void> =>
  del(`/asset-portfolios/${portfolioId}`, null);

export const getPortfolioListFactors = () => get("/asset-portfolios/factors");
export const getPortfolioListFactorsData = () => get("/asset-portfolios/list");

export const createSimplePortfolio = (data: PortfoliosSimple) =>
  post(
    "/asset-portfolios/simple",
    {
      ...data,
    },
    {
      config: {
        showLoading: true,
      },
    }
  );

// 获取调仓日期
export const getEffectiveDate = (): PromiseType<string> =>
  getWithCache("/asset-portfolios/trading-dates/effective-date", undefined, {
    config: {
      showLoading: true,
    },
  });

// 获取查询调仓记录
export const getTurnoverRecord = (
  portfolioId: string
): PromiseType<TurnoverRecordHistory[]> =>
  get(`/asset-portfolios/turnover-result/${portfolioId}`, undefined, {
    config: {
      showLoading: true,
    },
  });

// 调仓接口
export const changeTurnoverRecord = ({
  portfolioId,
  assets,
  turnoverDate
}: {
  portfolioId: string;
  assets: assetsType[];
  turnoverDate: string;
}): PromiseType<TurnoverRecordHistory[]> =>
  post(
    "/asset-portfolios/turnover-record",
    {
      portfolioId,
      assets,
      turnoverDate
    },
    {
      config: {
        showLoading: true,
      },
    }
  );

// 获取指定日期的调仓记录
export const getTurnoverRecordByDate = (
  portfolioId: string,
  date?: string
): PromiseType<TurnoverRecord> =>
  get(
    `/asset-portfolios/turnover-record/${portfolioId}`,
    { date },
    {
      config: {
        showLoading: true,
      },
    }
  );

// 获取组合在某一天的持仓信息
export const getPortfolioPositionRecord = (
  portfolioId: string,
  date: string
): PromiseType<{
  assets: assetsType[];
}> =>
  get(`/back-testing-analysis/position/${date}/${portfolioId}`, undefined, {
    config: {
      showLoading: true,
    },
  });

export const getFundManagerByFundId = (
  date: string,
  fundIds: string[]
): PromiseType<Record<string, string[]>> =>
  post(`/openfunds/fundManager/${date}`, fundIds);

export type EditPortfolioParam = InitSimpleDataRequestType & {
  portfolioId: string;
};
export const editPortfolio = ({ portfolioId, ...res }: EditPortfolioParam) =>
  put(`/asset-portfolios/${portfolioId}`, res, {
    config: {
      showLoading: true,
    },
  });

export type SaveBackTestingSchemeParams = SaveSchemeFormType & {
  id?: string;
  startDate: string;
  endDate: string;
  initScale: number;
  benchmarkId: string;
  benchmarkType: string;
  allocateRequest?: {
    type: AssetAllocateParams["type"] | "manual";
    allocateConfig: AssetAllocateParams["allocateConfig"];
    turnoverConfig: AssetAllocateParams["turnoverConfig"];
  };
  turnoverPositions: {
    turnoverDate: string;
    weights: {
      weight: number;
      fundId: string;
    }[];
  }[];
};
// 保存回测
export const saveBackTestingScheme = (params: SaveBackTestingSchemeParams) =>
  socketHttp("START_BACK_TESTING_PLAN", { meta: params });

export const getBackTestingSchemeList = () =>
  get("/back-testing-analysis/plan/list");

export const deleteBackTestingScheme = (id: string) =>
  del(`/asset-portfolios/${id}`, null, {
    config: {
      showLoading: true,
    },
  });

export type EditBackTestingSchemeParam = Pick<
  InitSimpleDataType,
  "name" | "shareRange" | "description"
> & {
  id: string;
};
export const editBackTestingScheme = ({
  id,
  ...res
}: EditBackTestingSchemeParam) =>
  put(`/back-testing-analysis/plan/${id}`, res, {
    config: {
      showLoading: true,
    },
  });

// 先验资产权重
export const getPriorWeight = (param: {
  categorySource: string;
  categories: string[];
}) => post("/asset/analyze/prior-weight", param);

// 大类模型配置
export const getCategoryAllocate = (param: getCategoryAllocateParam) =>
  post("asset/analyze/category-allocate", param, {
    config: {
      showLoading: true,
    },
  });
