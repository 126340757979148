import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { BaseFundManagerDetailInfoProps } from "../interface";
import IndustryTrend from "./components/industryTrend";
import StockMarketValueTop10Position from "./components/stockMarketValueTop10Position";
import style from "../index.module.less";
import { useAppDispatch } from "@/hooks/redux";
import {
  getFundManagerReportDate,
  getFundManagerYearReportDate,
} from "@/store/fundManagerDetailSlice";
import LoadingComponent from "@/components/LoadingComponent";

export default React.memo<BaseFundManagerDetailInfoProps>(({ id }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    Promise.all([
      dispatch(getFundManagerReportDate(id)),
      dispatch(getFundManagerYearReportDate(id)),
    ]);
  }, [dispatch, id]);
  return (
    <LoadingComponent
      actions={[getFundManagerReportDate, getFundManagerYearReportDate]}
    >
      <Row gutter={12} className={style.MarginTop16}>
        <Col span={12}>
          <IndustryTrend id={id} />
        </Col>
        <Col span={12}>
          <StockMarketValueTop10Position id={id} />
        </Col>
      </Row>
    </LoadingComponent>
  );
});
