import {
  AssetPortfolio,
  MainIndex,
  Optional,
  Categories,
  OptimizationFund,
  IndustryIndexStateType,
} from "@/model/dataBoard";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getUserAssetPortfolio,
  getUserOptional,
  getMainIndex,
  getIndustryIndexCard,
  getCategories,
  getOptimizationFund,
  getOptimizationFundDate,
  getIndustryIndexGraph,
} from "@/api/home";
import { set } from "lodash/fp";

export const fetchAssetPortfolio = createAsyncThunk(
  "dataBoard/assetPortfolio",
  async (v: { period: string }) => {
    const response = await getUserAssetPortfolio(v);
    return response;
  }
);

export const fetchOptional = createAsyncThunk(
  "dataBoard/optional",
  async (v: { userId: string; section: string }) => {
    const response = await getUserOptional(v);
    return response;
  }
);

export const fetchMainIndex = createAsyncThunk(
  "dataBoard/mainIndex",
  async (period: string) => {
    const response = await getMainIndex(period);
    return response;
  }
);

export const fetchIndustryIndex = createAsyncThunk(
  "dataBoard/industryIndex/card",
  async (period: string) => {
    const response = await getIndustryIndexCard(period);
    return response;
  }
);
export const fetchIndustryIndexGraph = createAsyncThunk(
  "dataBoard/industryIndex/graph",
  async (param: { section: string; benchmarkId: string }) => {
    const response = await getIndustryIndexGraph(param);
    return response;
  }
);

export const fetchCategories = createAsyncThunk(
  "dataBoard/categories",
  async (name: string) => {
    const response = await getCategories(name);
    return response;
  }
);

export const fetchOptimizationFundDate = createAsyncThunk(
  "dataBoard/optimizationFundDate",
  async () => {
    const response = await getOptimizationFundDate();
    return response;
  }
);

export const fetchOptimizationFund = createAsyncThunk(
  "dataBoard/optimizationFund",
  async (date: string | any) => {
    const response = await getOptimizationFund(date);
    return response;
  }
);
interface DataBoardSliceState {
  assetPortfolio: AssetPortfolio[];
  optional: Record<string, Optional[]>;
  mainIndex: MainIndex[];
  industryIndex: IndustryIndexStateType;
  categories: Categories[];
  optimizationFundDate: string[];
  optimizationFund: OptimizationFund[];
}

const initialState: DataBoardSliceState = {
  assetPortfolio: [],
  optional: {},
  mainIndex: [],
  industryIndex: { industryCardValue: [], industryGraphValue: {} },
  categories: [],
  optimizationFundDate: [],
  optimizationFund: [],
};

const dataBoardSlice = createSlice({
  name: "dataBoard",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAssetPortfolio.fulfilled.type]: (state, action) => {
      state.assetPortfolio = action.payload;
    },
    [fetchOptional.fulfilled.type]: (state, action) => {
      state.optional = action.payload;
    },
    [fetchMainIndex.fulfilled.type]: (state, action) => {
      state.mainIndex = action.payload;
    },
    [fetchIndustryIndex.fulfilled.type]: (state, action) => {
      state.industryIndex.industryCardValue = action.payload;
    },
    [fetchIndustryIndexGraph.fulfilled.type]: (state, action) => {
      state.industryIndex.industryGraphValue = set(
        `${action.meta.arg.section}.${action.meta.arg.benchmarkId}`,
        action.payload
      )(state.industryIndex.industryGraphValue);
    },
    [fetchCategories.fulfilled.type]: (state, action) => {
      state.categories = action.payload;
    },
    [fetchOptimizationFundDate.fulfilled.type]: (state, action) => {
      state.optimizationFundDate = action.payload;
    },
    [fetchOptimizationFund.fulfilled.type]: (state, action) => {
      state.optimizationFund = action.payload?.preferredFunds;
    },
  },
});

export default dataBoardSlice.reducer;
