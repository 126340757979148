import StatisticRange from "@/components/statisticRange";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { Space, Row, Col, Table, TableColumnsType } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import PortfolioAnalysisSubtitle from "../../components/portfolioAnalysisSubtitle";
import {
  map,
  omit,
  prop,
  flow,
  uniq,
  sumBy,
  isEmpty,
  fromPairs,
  size,
  filter,
  max,
  min,
  last,
} from "lodash/fp";
import style from "../index.module.less";
import { PortfolioAnalysisProps } from "../..";
import { fetchPortfolioRiskDismantle } from "@/store/portfolioAnalysis";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { analysisBasicInfoSelector } from "../../selectors";
import ScenarioSelect from "../../components/ScenarioSelect";
import BarChart, { BarChartOpts } from "@/echarts/barChart";
import { formatPercentage } from "@/util/numberFormatter";
import { fastProp } from "@/util/opt";
import mapper, { scenarioListSelector } from "../mapper";
import { portfolioRiskDismantleSelector } from "../selectors";
import { RootState } from "@/store";
import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { fundIdMapSelector } from "@/selectors/fund";
import ErrorBoundary from "@/components/errorBoundary";
import SummaryCard from "../../components/summaryCard";
import RiskDismantleTableSummary from "../../components/riskDismantleTableSummary";
import { useGetExpandedRowKeys, useGetStatisticRange } from "../../hooks";
import {
  getCategoryType,
  getTableData,
  Level,
} from "../../positionThrough/constant";
import { getRiskDismantleBarChartOption } from "../constant";
import { useCreation } from "ahooks";
import LoadingComponent from "@/components/LoadingComponent";
import { fetchPortfolioScenarioList } from "@/store/scenarioList";
import { useGetNetValueStart } from "./correlationNalysis";
import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";
import {
  FROM_CREATION,
  RangeInterface,
  // RangeInterface,
  RECENT_MONTH,
} from "@/constant/statisticRange";
import { getNatureDateAndTradingDate } from "@/util/processedDates";
import { ScenarioListResponse } from "@/model/portfolioAnalysis";

type SummaryData = {
  weight: number;
  currentRisk: number;
  stressScenariosRisk: number;
};

const percentage = (value: number) => formatPercentage(value);

const statisticRangeLists = omit([RECENT_MONTH])(
  portfolioAnalysisStatisticRange
);

const requestList = [fetchPortfolioScenarioList, fetchPortfolioRiskDismantle];

const useGetBasicsDataSource = (
  id: string,
  riskRange: string,
  scenarioId: number | undefined,
  formatMessage: FormatMessageFunc
) => {
  const data = useMemo(
    () => ({
      section: riskRange,
      scenarioId,
    }),
    [riskRange, scenarioId]
  );
  const riskDismantle = useAppSelector((state: RootState) =>
    portfolioRiskDismantleSelector(state, id, data)
  );
  const { categoryTreeMap } = useAppSelector((state) => mapper(state));

  const fundRiskChart = useMemo(
    () => fastProp("fundRiskChart")(riskDismantle) || [],
    [riskDismantle]
  );
  const level = useMemo(() => {
    return flow(
      map((item: Record<string, any>) => {
        const categoryType = getCategoryType(
          item.categoryId,
          categoryTreeMap,
          formatMessage
        );
        return {
          parentType: categoryType,
          type: prop(`${item.categoryId}.name`)(categoryTreeMap),
          categoryId: item.categoryId,
          weight: item.weight,
          key: item.fundId,
          currentRisk: item.currentRisk,
          fundId: item.fundId,
          stressScenariosRisk: item.stressScenariosRisk,
        };
      })
    )(fundRiskChart);
  }, [categoryTreeMap, fundRiskChart, formatMessage]);

  const firstLevel = useMemo(
    () => uniq(map(fastProp("parentType"))(level)),
    [level]
  );

  return {
    level,
    firstLevel,
  };
};

const useTableColumnsAndTableData = (
  formatMessage: FormatMessageFunc,
  level: Level[],
  onExpandAllRows: () => void,
  expandedRowKeys: string[]
) => {
  const fundIdMap = useAppSelector(fundIdMapSelector);

  const tablecolumns = useMemo(
    () => [
      {
        dataIndex: "type",
        align: "left",
        title: (
          <Space size={4}>
            <span onClick={onExpandAllRows} className={style.ExpendToFund}>
              {!isEmpty(expandedRowKeys) ? (
                <MinusSquareOutlined />
              ) : (
                <PlusSquareOutlined />
              )}
            </span>
            {formatMessage("expendToFund")}
          </Space>
        ),
        render: (_: any, record: Record<string, any>) => {
          if (record?.fundId) {
            const { name, code } = fastProp(record.fundId)(fundIdMap) || {};
            return (
              <div className={style.fundType}>
                <ToFundDetailPage
                  name={`${name} (${code})`}
                  id={record.fundId}
                />
              </div>
            );
          } else {
            return record?.type;
          }
        },
      },
      {
        dataIndex: "weight",
        title: formatMessage("investmentWeight"),
        render: percentage,
        align: "center",
        width: 100,
        defaultSortOrder: "descend",
        sortDirections: ["descend", "ascend", "descend"],
        sorter: (a: Record<string, any>, b: Record<string, any>) =>
          a.weight - b.weight,
      },
      {
        dataIndex: "currentRisk",
        title: formatMessage("currentRisk"),
        render: percentage,
        align: "center",
        width: 90,
      },
      {
        dataIndex: "stressScenariosRisk",
        title: formatMessage("stressScenario"),
        width: 90,
        render: (_: any, record: Record<string, any>) => {
          return (
            <span>
              {percentage(record.stressScenariosRisk)}{" "}
              {record.stressScenariosRisk > record.currentRisk ? (
                <ArrowUpOutlined className={style.upIcon} />
              ) : (
                <ArrowDownOutlined className={style.downIcon} />
              )}
            </span>
          );
        },
        align: "center",
      },
    ],
    [formatMessage, fundIdMap, onExpandAllRows, expandedRowKeys]
  );

  const tableData = useMemo(
    () => getTableData(level, formatMessage),
    [level, formatMessage]
  );

  const summaryData = useMemo<SummaryData>(
    () => ({
      weight: sumBy("weight")(tableData),
      currentRisk: sumBy("currentRisk")(tableData),
      stressScenariosRisk: sumBy("stressScenariosRisk")(tableData),
    }),
    [tableData]
  );

  return { tableData, tablecolumns, summaryData };
};

const useSummaryText = (
  id: string,
  riskRange: string,
  scenarioId: number | undefined,
  formatMessage: FormatMessageFunc
) => {
  const riskDismantle = useAppSelector((state: RootState) =>
    portfolioRiskDismantleSelector(state, id, {
      section: riskRange,
      scenarioId,
    })
  );
  const summaryText = useMemo(
    () => fastProp("text")(riskDismantle) || {},
    [riskDismantle]
  );

  const fundIdMap = useAppSelector(fundIdMapSelector);

  const { maxRiskFund, maxRiskFundRatio, riskLevel } = summaryText;

  const summaryTextData = useMemo(() => {
    const riskLevelText =
      riskLevel === "HIGH"
        ? formatMessage("higher")
        : riskLevel === "LOW"
        ? formatMessage("lower")
        : formatMessage("moderate");
    const maxRiskFundName = prop(`${maxRiskFund}.name`)(fundIdMap);
    return { riskLevelText, maxRiskFundName };
  }, [formatMessage, fundIdMap, riskLevel, maxRiskFund]);

  const { riskLevelText, maxRiskFundName } = summaryTextData;
  return {
    riskLevel: riskLevelText,
    maxRiskFund: maxRiskFundName,
    maxRiskFundRatio: percentage(maxRiskFundRatio),
  };
};

const useGetBarChart = (
  formatMessage: FormatMessageFunc,
  firstLevel: string[],
  tableData: Record<string, any>[],
  summaryData: Record<string, any>
) => {
  const chartDatas = useMemo(() => {
    return fromPairs([
      [
        formatMessage("currentRisk"),
        flow(
          map((item: Record<string, Level>) => item.currentRisk),
          (res) => [fastProp("currentRisk")(summaryData), ...res]
        )(tableData),
      ],
      [
        formatMessage("stressScenario"),
        flow(
          map((item: Record<string, Level>) => item.stressScenariosRisk),
          (res) => [fastProp("stressScenariosRisk")(summaryData), ...res]
        )(tableData),
      ],
    ]);
  }, [tableData, formatMessage, summaryData]);

  const dataSource = useCreation(() => {
    const categories = size(firstLevel)
      ? [formatMessage("total")].concat(firstLevel)
      : [];
    return getRiskDismantleBarChartOption(
      categories,
      formatMessage,
      chartDatas
    );
  }, [formatMessage, firstLevel, chartDatas]);
  return { dataSource };
};

const useGetOptionsAndTradingDay = (
  startDate: string,
  netEndDate: string,
  processedTradingDates: Record<string, any>
) => {
  const scenarioList = useAppSelector((state) => scenarioListSelector(state));
  const optionList = useCreation(() => {
    if (!startDate && !netEndDate) return [];
    return flow(
      filter((item: ScenarioListResponse) => {
        const [, tradingDate] = getNatureDateAndTradingDate(
          max([item.startDate, startDate]) as string,
          min([item.endDate, netEndDate]) as string
        )(processedTradingDates);
        return item.defaults && tradingDate >= 10;
      })
    )(scenarioList);
  }, [scenarioList, startDate, netEndDate, processedTradingDates]);

  const errorBoundary = useCreation(
    () => (!size(optionList) ? "less10TradingDates" : ""),
    [optionList]
  );

  return { optionList, errorBoundary };
};

export default React.memo((props: PortfolioAnalysisProps) => {
  const { id } = props;
  const [riskRange, setRiskRange] = useState(FROM_CREATION);
  const [scenarioId, setScenarioId] = useState<number>();
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const { endDate } = useAppSelector((state) =>
    analysisBasicInfoSelector(state, id)
  );
  const processedTradingDates = useAppSelector(
    (state) => state.tradingDates.processedTradingDates
  );
  //持仓基金的开始时间和结束时间
  const { startDate, netEndDate } = useGetNetValueStart(id);

  //持仓基金最短收益数据和压力场景的交集，不足10交易日提示
  const { optionList, errorBoundary } = useGetOptionsAndTradingDay(
    startDate,
    netEndDate,
    processedTradingDates
  );

  useEffect(() => {
    if (size(optionList)) {
      setScenarioId(fastProp("id")(last(optionList)));
    }
  }, [optionList]);

  useEffect(() => {
    if (!errorBoundary && scenarioId)
      dispatch(
        fetchPortfolioRiskDismantle({ id, section: riskRange, scenarioId })
      );
  }, [id, riskRange, errorBoundary, scenarioId, dispatch]);

  const calcStatisticRangeList = useGetStatisticRange(10);
  const statisticRangeList = useCreation(
    () =>
      calcStatisticRangeList(
        startDate,
        netEndDate,
        statisticRangeLists as Record<string, RangeInterface>
      ),
    [startDate, netEndDate, statisticRangeLists]
  );

  const { level, firstLevel } = useGetBasicsDataSource(
    id,
    riskRange,
    scenarioId,
    formatMessage
  );

  const { onExpandAllRows, expandable, expandedRowKeys } =
    useGetExpandedRowKeys(firstLevel);

  const { tableData, tablecolumns, summaryData } = useTableColumnsAndTableData(
    formatMessage,
    level,
    onExpandAllRows,
    expandedRowKeys
  );

  const { dataSource } = useGetBarChart(
    formatMessage,
    firstLevel,
    tableData,
    summaryData
  );

  const { currentRisk } = summaryData;

  const { riskLevel, maxRiskFund, maxRiskFundRatio } = useSummaryText(
    id,
    riskRange,
    scenarioId,
    formatMessage
  );

  return (
    <LoadingComponent actions={requestList}>
      <PortfolioAnalysisSubtitle name={formatMessage("riskDismantle")} />
      <Space direction="vertical" className={style.fullWidth}>
        <Space>
          {formatMessage("currentRiskModelDuration")}
          <StatisticRange
            className={style.rangeSelect}
            value={riskRange}
            statisticRangeList={statisticRangeList}
            onChange={(v) => setRiskRange(v)}
          />
          {formatMessage("stressScenario")}
          <ScenarioSelect
            className={style.scenarioSelect}
            onChange={(v) => setScenarioId(v)}
            value={scenarioId}
            optionList={optionList}
          />
          {formatMessage("positionTime")}:{endDate}
        </Space>

        <ErrorBoundary errKey={errorBoundary}>
          <Row gutter={16}>
            <Col span={14}>
              <BarChart
                options={dataSource as BarChartOpts["options"]}
                height={350}
              />
            </Col>
            <Col span={10}>
              <Table
                dataSource={tableData}
                columns={tablecolumns as TableColumnsType}
                pagination={false}
                bordered={true}
                expandable={expandable}
                className={style.tableStyle}
                summary={() => (
                  <RiskDismantleTableSummary summaryData={summaryData} />
                )}
                scroll={{ y: 300 }}
              />
            </Col>
          </Row>
          <SummaryCard
            summaryText={formatMessage("riskDismantleSummaryText", {
              riskLevelValue: percentage(currentRisk),
              riskLevel: riskLevel,
              maxRiskFund: maxRiskFund,
              maxRiskFundRatio: maxRiskFundRatio,
            })}
          />
        </ErrorBoundary>
      </Space>
    </LoadingComponent>
  );
});
