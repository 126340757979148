/* eslint-disable @typescript-eslint/no-explicit-any */
import { fastProp } from "./opt";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setLocalStorage(key: string, value: any): void {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export function getLocalStorage<T = string>(key: string, defaultValue: T): T {
  let value = defaultValue;
  try {
    const storageValue = fastProp(key)(localStorage);
    if (storageValue) {
      value = JSON.parse(storageValue);
    }
  } catch (e) {
    value = defaultValue;
  }
  return value;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}
