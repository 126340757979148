import { prop, some, isNil } from "lodash/fp";
import qs from "qs";
import pako from "pako";
import { v4 } from "uuid";
import { PromiseType } from "@/model/promise";
import { createAxios } from "./createAxios";
import { CONFIG, OnRequest, RequestOptions } from "./config";
import { fastProp, forEachIndexed } from "../opt/index";
import { getAuthorizationKey } from "../authorizationKey";
import { to } from "../awaitToJs";
import { axiosRequestCache } from "./requestCache";
import RequestQueue from "./requestQueue";

const queue = new RequestQueue(5);

const axios = createAxios();

// eslint-disable-next-line @typescript-eslint/ban-types
type ArrayAndObject = Array<any> | object | null | undefined;

const { File } = window;
const processData = (data: ArrayAndObject, isZip: boolean) => {
  let finalData: any = null;
  if (some((item: any) => item instanceof File)(data)) {
    finalData = new FormData();
    forEachIndexed((value: string | Blob, key: string) => {
      if (finalData instanceof FormData) finalData.append(key, value);
    })(data);
  } else if (data) {
    finalData = JSON.stringify(data);
  }
  if (isZip) return pako.gzip(JSON.stringify(data), { level: 8, to: "string" });
  return finalData;
};

const { BASE_URL, MOCKER_URL } = CONFIG;
const onRequest = (request: OnRequest) => {
  const { method, url, data, options, baseURL } = request;
  let apiPrefix = BASE_URL;
  if (baseURL) apiPrefix = baseURL;
  if (process.env.NODE_ENV === "development") {
    if (prop("mocker")(options)) apiPrefix = MOCKER_URL;
  }
  const auth = getAuthorizationKey();
  const isZip = fastProp("zip")(options);
  const presetPromise = Promise.resolve(processData(data, isZip));
  return presetPromise.then((res) =>
    axios({
      url,
      method,
      data: res,
      headers: {
        clientVersion: "webapp",
        ...(isZip && {
          "content-type": "text/plain",
          "Content-Encoding": "gzip",
        }),
        ...options?.headers,
        ...(!options?.withoutAuth && !isNil(auth)
          ? { Authorization: auth }
          : {}),
      },
      responseType: options?.responseType || "json",
      baseURL: apiPrefix,
      config: {
        showError: true,
        ...options?.config,
      },
    })
  );
};

const handleRequest = async (request: OnRequest) => {
  const { method, url, data, options, baseURL } = request;
  const cache = options?.cache;
  if (cache) {
    const cachedPromise = axiosRequestCache.getCache(url);
    if (cachedPromise) {
      const [, res] = await to(cachedPromise);
      if (res) return Promise.resolve(res);
    }
  }
  const uuid = v4();
  const promise = queue.inQueue(uuid, () =>
    onRequest({ method, url, data, options, baseURL })
  );
  if (cache) axiosRequestCache.addCache(url, promise);
  const deleteCache = options?.deleteCache;
  if (deleteCache) axiosRequestCache.deleteCache(deleteCache);
  return promise;
};

type Query = Record<string, any>;

export const get = <T>(
  url: string,
  query?: Query,
  options?: RequestOptions,
  baseURL?: string
): PromiseType<T> => {
  const Url = query
    ? `${url}?${qs.stringify(query, { arrayFormat: "comma" })}`
    : url;
  return handleRequest({
    method: "GET",
    url: Url,
    data: null,
    options,
    baseURL,
  });
};

export const post = <T>(
  url: string,
  data: ArrayAndObject,
  options?: RequestOptions,
  baseURL?: string
): PromiseType<T> =>
  handleRequest({
    method: "POST",
    url,
    data,
    options,
    baseURL,
  });

export const put = <T>(
  url: string,
  data: ArrayAndObject,
  options?: RequestOptions
): PromiseType<T> =>
  handleRequest({
    method: "PUT",
    url,
    data,
    options,
  });

export const del = <T>(
  url: string,
  data: ArrayAndObject,
  options?: RequestOptions,
  baseURL?: string
): PromiseType<T> =>
  handleRequest({
    method: "DELETE",
    url,
    data,
    options,
    baseURL,
  });

export const getWithCache = <T>(
  url: string,
  query?: Query,
  options?: RequestOptions,
  baseUrl?: string
): Promise<T> =>
  get(
    url,
    query,
    {
      cache: true,
      ...options,
    },
    baseUrl
  );
