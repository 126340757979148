import React, { useState, useMemo, useEffect, useContext } from "react";
import { Radio, Space, Row, Col, Table, Select } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { BYDAY, BYWEEK } from "../../constant";
import { FROM_CREATION } from "@/constant/statisticRange";
import { RangeInterface } from "@/constant/statisticRange";
import moment from "moment";
import {
  useBenchmarkReturns,
  useStatisticRangeMap,
  useTableData,
  getColumns,
  useGetChartData,
} from "@/views/portfolioManage/portfolioAnalysis/hooks";
import PortfolioAnalysisSubtitle from "../../components/portfolioAnalysisSubtitle";
import { RootState } from "@/store";
import { tradingDatesSelector } from "@/selectors/tradingDates";
import { normalize } from "@/util/opt";
import { formatter } from "@/constant/factorFormatter";
import ErrorBoundary from "@/components/errorBoundary";
import { checkNeedTime } from "@/providers/portfolioAnalysisProvider/provider";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import { map, keys, last, isNil, drop, first, filter, concat } from "lodash/fp";
import style from "../../index.module.less";
import SummaryCard from "../../components/summaryCard";
import { analysisBasicInfoSelector } from "../../selectors";
import { useAppSelector } from "@/hooks/redux";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { getChartData, getSummaryText, getSumData } from "../constant";
import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import EchartsWrapper from "@/echarts/echartsWrapper";
import { EChartsOption } from "echarts";
import { getCumulativeCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { getStatisticsRangeStartDate } from "@/constant/statisticRangeCalculator/helper";

const { Option } = Select;

export default React.memo<{
  id: string;
}>(({ id }) => {
  const formatMessage = useFormatMessage();
  const { runningTime } = useContext(PortfolioAnalysisContext);
  const { tradingDate, dailyReturn, benchmark, portfolioName } = useAppSelector(
    (state: RootState) => analysisBasicInfoSelector(state, id)
  );
  const [errorBoundary, setErrorBoundary] = useState<string>();

  const [incomeData, setIncomeData] = useState<string>("byWeek");
  const needTime = 1;
  useEffect(() => {
    if (checkNeedTime(needTime, runningTime)) {
      if (incomeData === BYWEEK) {
        setErrorBoundary("lessOneWeek");
      } else {
        setErrorBoundary("lessTwoTradingDate");
      }
    } else {
      const lastDay = dayjs(first(tradingDate)).add(7, "day");
      if (incomeData === BYWEEK && lastDay.isAfter(last(tradingDate))) {
        setErrorBoundary("lessOneWeek");
      } else {
        setErrorBoundary("");
      }
    }
  }, [incomeData, needTime, runningTime, tradingDate]);

  const [revenueValue, setRevenueValue] = useState(0.005);
  const firstDay = new Date(first(tradingDate) as string);
  const isFriday = firstDay.getDay() === 5;
  //如果开始回测日期是周五，去掉回测开始日期，从下周开始计算收益
  const tradingDates = isFriday
    ? (drop(1)(tradingDate) as string[])
    : tradingDate;
  const dailyReturnMap = useMemo(
    () =>
      isFriday
        ? normalizeDailyReturnsMap(tradingDates, drop(1)(dailyReturn))
        : normalizeDailyReturnsMap(tradingDates, dailyReturn),
    [dailyReturn, isFriday, tradingDates]
  );

  const { benchmarkReturnMap } = useBenchmarkReturns(benchmark);

  const { tradingDateList, processedTradingDates } =
    useAppSelector(tradingDatesSelector);

  const statisticRange = useStatisticRangeMap(
    tradingDates,
    portfolioAnalysisStatisticRange
  );

  const statisticRangeMap = normalize<RangeInterface>("id")(statisticRange);
  const rangeList = keys(statisticRangeMap);

  const rangeDates = useMemo(() => {
    let listData: Record<string, any> = {};
    return map((range: string) => {
      const date: string[] = getCumulativeCalculateRangeDate({
        dates: tradingDates,
        range: range,
        tradingDateList,
        processedTradingDates,
        thisYearDate: last(tradingDates),
      });
      const startDate = getStatisticsRangeStartDate(
        range,
        last(tradingDates) as string
      );

      const tempDates: string[] = [];
      const firstDate = first(date) as string;
      // 自然周收益为当前整周的收益
      map((value: string) => {
        // 如果value的周数和年数和firstDate的一样，那么就把value放到tempDates的最前面
        if (
          moment(value).week() === moment(firstDate).week() &&
          moment(value).year() === moment(firstDate).year()
        ) {
          tempDates.push(value);
        }
      })(tradingDates);
      const filterDate = filter((v: string) => v < firstDate)(tempDates);

      let dates: string[] = [];
      const includeIsNature: string[] = [];
      // 成立以来去掉建仓日
      if (range === FROM_CREATION) {
        dates = date;
      } else {
        const withoutIsNature: string[] = concat(filterDate)(date);

        // 如果区间日期是非交易日周六或者周日，date取值是下一个交易日周一，这样
        // 整周是从下周计算，现在要加上上一周的收益
        const firstWithoutIsNature = first(withoutIsNature) as string;
        map((value: string) => {
          if (!isNil(startDate)) {
            if (startDate < firstWithoutIsNature) {
              if (
                moment(value).week() === moment(startDate).week() &&
                moment(value).year() === moment(startDate).year()
              ) {
                includeIsNature.push(value);
              }
            }
          }
        })(tradingDates);

        dates =
          incomeData === BYWEEK
            ? concat(includeIsNature)(withoutIsNature)
            : date;
      }

      const {
        sumAllByPortfolio,
        sumAllByBenchmark,
        profitNumberByPortfolio,
        lossNumberByPortfolio,
        profitNumberByBenchmark,
        lossNumberByBenchmark,
      } = getSumData(incomeData, dates, dailyReturnMap, benchmarkReturnMap);
      listData = {
        id: range,
        dates: dates,
        sumAllByPortfolio: sumAllByPortfolio,
        sumAllByBenchmark: sumAllByBenchmark,
        profitNumberByPortfolio: profitNumberByPortfolio,
        lossNumberByPortfolio: lossNumberByPortfolio,
        profitNumberByBenchmark: profitNumberByBenchmark,
        lossNumberByBenchmark: lossNumberByBenchmark,
      };
      return listData;
    })(rangeList);
  }, [
    benchmarkReturnMap,
    dailyReturnMap,
    incomeData,
    processedTradingDates,
    rangeList,
    tradingDateList,
    tradingDates,
  ]);

  const rangeDatesMap: Record<string, any> = useMemo(
    () => normalize("id")(rangeDates),
    [rangeDates]
  );

  const fromCreation = useMemo(
    () => rangeDatesMap?.FROM_CREATION,
    [rangeDatesMap]
  );
  const {
    curveyPortfolio,
    curveyBenchmark,
    groupSumByBenchmark,
    groupSum,
    histogramX,
  } =
    useMemo(
      () => getChartData(tradingDates, fromCreation, revenueValue),
      [fromCreation, revenueValue, tradingDates]
    ) || {};

  const options = useGetChartData(
    histogramX,
    groupSum,
    groupSumByBenchmark,
    portfolioName,
    incomeData,
    curveyPortfolio,
    curveyBenchmark,
    formatMessage
  );
  const columns = useMemo(() => getColumns(formatMessage), [formatMessage]);

  const summaryText = useMemo(
    () =>
      getSummaryText(
        tradingDates,
        fromCreation,
        revenueValue,
        incomeData,
        formatMessage
      ),
    [tradingDates, fromCreation, revenueValue, incomeData, formatMessage]
  );
  const tableList = useTableData(
    errorBoundary,
    formatMessage,
    portfolioAnalysisStatisticRange,
    rangeList,
    rangeDatesMap
  );

  return (
    <>
      <PortfolioAnalysisSubtitle name={formatMessage("incomeDistribution")} />

      <Radio.Group
        onChange={(v) => setIncomeData(v.target.value)}
        value={incomeData}
        optionType="button"
        buttonStyle="solid"
      >
        <Radio.Button value={BYWEEK}>{formatMessage("byWeek")}</Radio.Button>
        <Radio.Button value={BYDAY}>{formatMessage("byDay")}</Radio.Button>
      </Radio.Group>
      <div className={style.MarginTop}>
        <span className={style.MarginLeft}>
          {formatMessage("revenueInterval")}
        </span>
        <Select
          size="small"
          value={formatter.percent2(revenueValue)}
          className={style.RevenueSelect}
          onChange={(value) => setRevenueValue(Number(value))}
        >
          <Option value="0.0025">{"0.25%"}</Option>
          <Option value="0.005">{"0.50%"}</Option>
          <Option value="0.01">{"1.00%"}</Option>
          <Option value="0.02">{"2.00%"}</Option>
        </Select>
      </div>
      <Space direction="vertical" className={style.fullWidth}>
        <ErrorBoundary errKey={errorBoundary}>
          <Row gutter={8}>
            <Col span={16}>
              <EchartsWrapper options={options as EChartsOption} height={400} />
            </Col>
            <Col span={8}>
              <Table
                columns={columns}
                dataSource={tableList}
                bordered
                rowKey="key"
                pagination={false}
                className={style.Table}
              />
            </Col>
          </Row>
        </ErrorBoundary>
        {!isEmpty(errorBoundary)
          ? null
          : summaryText && <SummaryCard summaryText={summaryText} />}
      </Space>
    </>
  );
});
