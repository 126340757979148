import { flow, fromPairs, map } from "lodash/fp";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getFundFactors,
  getFactorValue as getFactorValueApi,
  getPortfolioFactorValue as getPortfolioFactorValueApi,
  getBenchmarkYieldFactors as getBenchmarkYieldFactorsApi,
} from "@/api/factors";
import { getFactorsFormatterName } from "@/constant/factorFormatter";
import type { FactorCategory, BenchmarkYieldFactors } from "@/model/factors";
import createSocketThunk from "@/util/createSocketThunk";
import { mapTree } from "@/util/opt/tree";

export const getFactors = createAsyncThunk("factors/getFactors", async () => {
  const response = await getFundFactors();
  return response;
});

export const getFactorValue = createSocketThunk(
  "factors/getFactorsValues",
  "FINISH_ASSET_FACTOR_EXPOSURE",
  (
    {
      assetIds,
      factorId,
      date,
    }: { assetIds: string[]; factorId: string; date: string },
    { getState }
  ) => {
    const state = getState();
    const factorValues = state.factors.factorValues?.[factorId];
    if (assetIds?.some((id) => factorValues?.[id] === undefined)) {
      return getFactorValueApi({ assetIds, factorId, date });
    }
  }
);
export const getPortfolioFactorValue = createSocketThunk(
  "factors/getPortfolioFactorValues",
  "FINISH_ASSET_PORTFOLIO_FACTOR_EXPOSURE",
  (
    { portfolioIds, factorId }: { portfolioIds: string[]; factorId: string },
    { getState }
  ) => {
    const state = getState();
    const factorValues = state.factors.factorValues?.[factorId];
    if (portfolioIds?.some((id) => factorValues?.[id] === undefined)) {
      return getPortfolioFactorValueApi({ portfolioIds, factorId });
    }
  }
);

export const getBenchmarkYieldFactors = createAsyncThunk(
  "factors/getBenchmarkYieldFactors",
  async (benchmarkId: string) => {
    const response = await getBenchmarkYieldFactorsApi(benchmarkId);
    return response;
  }
);

type FactorsStore = {
  factorTree: FactorCategory[];
  portfolioFactorTree: FactorCategory[];
  factorValues: Record<string, Record<string, number | undefined | null>>;
  benchmarkYieldFactors: Record<string, BenchmarkYieldFactors>;
};

const initialState: FactorsStore = {
  factorTree: [],
  portfolioFactorTree: [],
  factorValues: {},
  benchmarkYieldFactors: {},
};
const getFactorValueFinish: string = getFactorValue.finish.type;
const getFactorValueError: string = getFactorValue.error.type;
const getPortfolioFactorValueFinish: string =
  getPortfolioFactorValue.finish.type;
const getPortfolioFactorValueError: string = getPortfolioFactorValue.error.type;

const factorsSlice = createSlice({
  name: "dataBoard",
  initialState,
  reducers: {},
  extraReducers: {
    [getFactors.fulfilled.type]: (
      state,
      action: PayloadAction<FactorCategory[]>
    ) => {
      state.factorTree = [
        {
          id: "basic",
          nameKey: "openFundInfo",
          factorViews: [
            {
              id: "secondInvestType",
              nameKey: "fundType",
              isBasicInfo: true,
            },
            {
              id: "fundManager",
              nameKey: "fundManagers",
              isBasicInfo: true,
            },
            {
              id: "fundCompany",
              nameKey: "fundCompanies",
              isBasicInfo: true,
            },
            {
              id: "foundDate",
              nameKey: "foundDate",
              isBasicInfo: true,
              sorter: true,
            },
            {
              id: "benchmark",
              nameKey: "benchmark",
              isBasicInfo: true,
            },
            {
              id: "fundScale",
              nameKey: "latestScaleWithHundredMillion",
              isBasicInfo: true,
              sorter: true,
              align: "right",
              format: "scaleWithHundredMillion",
            },
          ],
        },
        {
          id: "portfolioBasic",
          nameKey: "portfolioInfo",
          factorViews: [
            {
              id: "historyEnd",
              nameKey: "dataUpdateDate",
              isBasicInfo: true,
              sorter: true,
            },
            {
              id: "foundDate",
              nameKey: "foundDate",
              isBasicInfo: true,
              sorter: true,
            },
            {
              id: "ownerName",
              nameKey: "creator",
              isBasicInfo: true,
            },
            {
              id: "latestNetReinstatementValue",
              nameKey: "latestNetValue",
              isBasicInfo: true,
              sorter: true,
              align: "right",
              format: "float4",
            },
            {
              id: "latestScale",
              nameKey: "latestPortfolioScale",
              isBasicInfo: true,
              sorter: true,
              align: "right",
              format: "scaleWithTenThousand",
            },
            {
              id: "dailyRiseAndFall",
              nameKey: "dailyYieldReturn",
              isBasicInfo: true,
              sorter: true,
              align: "right",
              format: "percent2",
            },
            {
              id: "dailyRevenue",
              nameKey: "dailyYield",
              isBasicInfo: true,
              sorter: true,
              format: "float2",
              align: "right",
            },
          ],
        },
        ...mapTree<FactorCategory>("factorViews", (item) => ({
          ...item,
          format: item.factorViews
            ? undefined
            : getFactorsFormatterName(item.name),
          align: "right",
        }))(action.payload),
      ];
    },
    [getFactorValueError]: (state, action) => {
      const factorId = action?.meta?.arg?.factorId;
      if (factorId) {
        const factorValues: Record<string, number | undefined | null> = {
          ...state.factorValues?.[factorId],
          ...flow(
            map((assetId: string) => [assetId, null]),
            fromPairs
          )(action?.meta?.arg?.assetIds),
        };
        state.factorValues[factorId] = factorValues;
      }
    },
    [getFactorValueFinish]: (state, action) => {
      const factorId = action?.meta?.arg?.factorId;
      if (factorId) {
        const factorValues: Record<string, number | undefined | null> = {
          ...state.factorValues?.[factorId],
          ...flow(
            map((assetId: string) => {
              if (
                action?.payload?.assetId2FactorExposure?.[assetId] !== undefined
              ) {
                return [
                  assetId,
                  action?.payload?.assetId2FactorExposure?.[assetId],
                ];
              }
              return [assetId, null];
            }),
            fromPairs
          )(action?.meta?.arg?.assetIds),
        };
        state.factorValues[factorId] = factorValues;
      }
    },
    [getPortfolioFactorValueError]: (state, action) => {
      const factorId = action?.meta?.arg?.factorId;
      if (factorId) {
        const factorValues: Record<string, number | undefined | null> = {
          ...state.factorValues?.[factorId],
          ...flow(
            map((portfolioId: string) => [portfolioId, null]),
            fromPairs
          )(action?.meta?.arg?.portfolioIds),
        };
        state.factorValues[factorId] = factorValues;
      }
    },
    [getPortfolioFactorValueFinish]: (state, action) => {
      const factorId = action?.meta?.arg?.factorId;
      if (factorId) {
        const factorValues: Record<string, number | undefined | null> = {
          ...state.factorValues?.[factorId],
          ...flow(
            map((portfolioId: string) => {
              if (
                action?.payload?.assetId2FactorExposure?.[portfolioId] !==
                undefined
              ) {
                return [
                  portfolioId,
                  action?.payload?.assetId2FactorExposure?.[portfolioId],
                ];
              }
              return [portfolioId, null];
            }),
            fromPairs
          )(action?.meta?.arg?.portfolioIds),
        };
        state.factorValues[factorId] = factorValues;
      }
    },
    [getBenchmarkYieldFactors.fulfilled.type]: (state, action) => {
      const benchmarkId = action?.meta?.arg;
      if (benchmarkId) {
        state.benchmarkYieldFactors[benchmarkId] = action.payload;
      }
    },
  },
});

export default factorsSlice.reducer;
