import { map } from "lodash/fp";
import { fastProp } from "@/util/opt";
import { ManualCreatePortfolio } from "../constant";

export const serializeTurnoverPositions = (data: ManualCreatePortfolio[]) =>
  map((item: ManualCreatePortfolio) => ({
    turnoverDate: item.turnoverDate || "",
    weights: map((weight: any) => ({
      fundId: fastProp("fundId")(weight),
      weight: fastProp("weight")(weight),
    }))(item.weights),
  }))(data);

export const schemeConfig = {
  COVERING: "COVERING",
  CREAT_NEW: "CREAT_NEW",
  isCover(type: string) {
    return type === this.COVERING;
  },
};
