// import moment from "moment";
import dayjs from "dayjs";
import { DATEFORMAT } from "./dateFormat";

export const disabledDateForEarlierOneDay =
  (date?: string, include = false) =>
  (currentDate: dayjs.Dayjs) => {
    const compareDate = dayjs(date || undefined).format(DATEFORMAT);
    if (include) {
      return currentDate.format(DATEFORMAT) <= compareDate;
    }
    return currentDate.format(DATEFORMAT) < compareDate;
  };

export const disabledDateForExceedRange =
  (
    from: string | undefined,
    to: string | undefined,
    includeFrom = false,
    includeTo = false
  ) =>
  (currentDate: dayjs.Dayjs) => {
    const _currentDate = currentDate.format(DATEFORMAT);
    const fromDate = dayjs(from || undefined).format(DATEFORMAT);

    if (includeFrom ? _currentDate <= fromDate : _currentDate < fromDate) {
      return true;
    }
    if (to) {
      const toDate = dayjs(to || undefined).format(DATEFORMAT);
      return includeTo ? _currentDate >= toDate : _currentDate > toDate;
    }
    return false;
  };
