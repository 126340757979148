import { getPortfolioPerformanceTrend } from "@/api/aum";
import {
  getAccountInfoApi,
  getAssetChangeInfoApi,
  getAssetListInfoApi,
  getAssetTypeDistributionInfo,
  getAssetTypeDistributionSummary,
  getBrinsonAttributionData,
  getBrinsonAttributionSummaryData,
  getDrawAndIndexFundData,
  getDrawAndIndexSummary,
  getFactorEvaluation,
  getFixedInvestmentDetail,
  getFundCumulativeIncomeApi,
  getFundsDailyReturn,
  getIndustryList,
  getInvestmentDetailInfo,
  getOrganizationTypeApi,
  getPositionAssetsList,
  getPurchaseRedeemInfo,
  getRangeIncomeInfoApi,
  getRiskDissectData,
  positionAssetTransactionApi,
  putInvestPlanStatusApi,
  saveOrUpdateInvestPlanApi,
  getCustomerAssetPositionDetail,
} from "@/api/customerAnalysis";
import { DatePeriodValue } from "@/base-components/datePeriodSelect";
import {
  AssetTypeDistributionParams,
  BrinsonAttributionBody,
  BrinsonAttributionSummaryDataParams,
  DrawAndIndexFundParams,
  DrawAndIndexSummaryParams,
  FactorEvaluationParams,
} from "@/model/aum";
import {
  AssetChangeParam,
  AssetListBody,
  CumulativeIncomeParam,
  FixedInvestmentDetailParam,
  FundsDailyReturnParam,
  OrganizationTypeBody,
  PositionAssetTransactionParam,
  PutInvestPlanStatusParam,
  RangeIncomeBody,
  SaveOrUpdateInvestPlanParam,
} from "@/model/customer";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { fastProp } from "@/util/opt";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { map, set, setWith } from "lodash/fp";

export const fetchAccountInfo = avoidMultiRequestActionThunk<string>(
  (customerId) => `customerAnalysis.${customerId}.accountInfo`,
  createAsyncThunk(
    "customerAnalysis.accountInfo",
    async (customerId: string) => {
      const response = await getAccountInfoApi(customerId);
      return response;
    }
  )
);

export const fetchRangeIncomeInfo = avoidMultiRequestActionThunk<string>(
  (customerId) => `customerAnalysis.${customerId}.rangeIncome`,
  createAsyncThunk("customerAnalysis/rangeIncome", async (customerId) => {
    const response = await getRangeIncomeInfoApi(customerId);
    return response;
  })
);

export const fetchAssetChangeInfo =
  avoidMultiRequestActionThunk<AssetChangeParam>(
    (data) =>
      `customerAnalysis.${data?.customerId}.assetChange.${data?.section}`,
    createAsyncThunk("customerAnalysis/assetChange", async (data) => {
      const response = await getAssetChangeInfoApi(data);
      return response;
    })
  );

export const fetchAssetListInfo = avoidMultiRequestActionThunk<string>(
  (customerId) => `customerAnalysis.${customerId}.assetList`,
  createAsyncThunk("customerAnalysis/assetList", async (customerId) => {
    const response = await getAssetListInfoApi(customerId);
    return response;
  })
);
//申购赎回
export const fetchPurchaseRedeemInfo =
  avoidMultiRequestActionThunk<AssetChangeParam>(
    (data) =>
      `customerAnalysis.${data?.customerId}.purchaseRedeem.${data?.section}`,
    createAsyncThunk("customerAnalysis/purchaseRedeem", async (customerId) => {
      const response = await getPurchaseRedeemInfo(customerId);
      return response;
    })
  );

//资产类型分布
export const fetchAssetTypeDistributionInfo =
  avoidMultiRequestActionThunk<AssetTypeDistributionParams>(
    ({ customerId, customerBenchmarkType, groupType }) =>
      `customerAnalysis.${customerId}.assetTypeDistribution.${customerBenchmarkType}.${groupType}`,
    createAsyncThunk(
      "customerAnalysis/assetTypeDistribution",
      async (params) => {
        const response = await getAssetTypeDistributionInfo(params);
        return response;
      }
    )
  );

//资产类型分布总结
export const fetchAssetTypeDistributionSummary =
  avoidMultiRequestActionThunk<string>(
    (customerId) =>
      `customerAnalysis.${customerId}.assetTypeDistributionSummary`,
    createAsyncThunk(
      "customerAnalysis/assetTypeDistributionSummary",
      async (customerId) => {
        const response = await getAssetTypeDistributionSummary(customerId);
        return response;
      }
    )
  );

//投资详情
export const fetchInvestmentDetailInfo = avoidMultiRequestActionThunk<string>(
  (customerId) => `customerAnalysis.${customerId}.investmentDetail`,
  createAsyncThunk("customerAnalysis/investmentDetail", async (customerId) => {
    const response = await getInvestmentDetailInfo(customerId);
    return response;
  })
);

//机构
export const fetchOrganizationTypeList = avoidMultiRequestActionThunk<void>(
  "customerList.organizationType",
  createAsyncThunk("customer/organizationType", async () => {
    const response = await getOrganizationTypeApi();
    return response;
  })
);

//基金累计收益走势
export const fetchFundCumulativeIncomeInfo =
  avoidMultiRequestActionThunk<CumulativeIncomeParam>(
    (data) =>
      `customerAnalysis.${data?.customerId}.fundCumulativeIncome.${data?.section}.${data.type}`,
    createAsyncThunk("customerAnalysis/fundCumulativeIncome", async (data) => {
      const response = await getFundCumulativeIncomeApi(data);
      return response;
    })
  );

// brinson归因
export const fetchBrinsonAttributionData =
  avoidMultiRequestActionThunk<BrinsonAttributionBody>(
    (data) =>
      `customerAnalysis.${data?.id}.brinsonAttribution.${data?.section}`,
    createAsyncThunk("customerAnalysis/brinsonAttribution", async (data) => {
      const response = await getBrinsonAttributionData(data);
      return response;
    })
  );
// brinson归因
export const fetchRiskDissectData =
  avoidMultiRequestActionThunk<BrinsonAttributionBody>(
    (data) => `customerAnalysis.${data?.id}.riskDissect.${data?.section}`,
    createAsyncThunk("customerAnalysis/riskDissect", async (data) => {
      const response = await getRiskDissectData(data);
      return response;
    })
  );
// brinson归因
export const fetchIndustryList = avoidMultiRequestActionThunk<void>(
  () => `customerAnalysis.industryList`,
  createAsyncThunk("customerAnalysis/industryList", async () => {
    const response = await getIndustryList();
    return response;
  })
);

export const fetchDrawAndIndexFundData =
  avoidMultiRequestActionThunk<DrawAndIndexFundParams>(
    (params) =>
      `customerAnalysis.${params.customerId}.drawAndIndex.${params.section}`,
    createAsyncThunk("customerAnalysis/drawAndIndex", async (params) => {
      const response = await getDrawAndIndexFundData(params);
      return response;
    })
  );
export const fetchDrawAndIndexSummary =
  avoidMultiRequestActionThunk<DrawAndIndexSummaryParams>(
    (params) =>
      `customerAnalysis.${params.customerId}.drawAndIndexSummary.${params.customerBenchmarkType}.${params.section}`,
    createAsyncThunk("customerAnalysis/drawAndIndexSummary", async (params) => {
      const response = await getDrawAndIndexSummary(params);
      return response;
    })
  );

export const fetchFundsDailyReturn = createAsyncThunk(
  "customerAnalysis/fundsDailyReturn",
  async (params: FundsDailyReturnParam) => {
    const response = await getFundsDailyReturn(params);
    return response;
  }
);

export const fetchBrinsonAttributionSummaryData =
  avoidMultiRequestActionThunk<BrinsonAttributionSummaryDataParams>(
    (params) =>
      `customerAnalysis.${fastProp("customerId")(
        params
      )}.brinsonAttributionSummaryData.${fastProp("section")(params)}`,
    createAsyncThunk(
      "customerAnalysis/brinsonAttributionSummaryData",
      async (params) => {
        const response = await getBrinsonAttributionSummaryData(params);
        return response;
      }
    )
  );

export const fetchFactorEvaluation =
  avoidMultiRequestActionThunk<FactorEvaluationParams>(
    (params) =>
      `customerAnalysis.${fastProp("customerId")(params)}.factorEvaluation`,
    createAsyncThunk("customerAnalysis/factorEvaluation", async (params) => {
      const response = await getFactorEvaluation(params);
      return response;
    })
  );

export const fetchPositionAssetsList = avoidMultiRequestActionThunk<{
  accountId: string;
  forceToRefetch?: boolean;
}>(
  ({ accountId, forceToRefetch }) =>
    forceToRefetch ? "" : `customerAnalysis.${accountId}.positionAssetsList`,
  createAsyncThunk(
    "customerAnalysis/positionAssetsList",
    async ({ accountId }) => {
      const response = await getPositionAssetsList(accountId);
      return response;
    }
  )
);
export const fetchFixedInvestmentDetail = createAsyncThunk(
  "customerAnalysis/fixedInvestmentDetail",
  async (params: FixedInvestmentDetailParam) => {
    const response = await getFixedInvestmentDetail(params);
    return response;
  }
);

export const saveOrUpdateInvestPlan = createAsyncThunk<
  void,
  SaveOrUpdateInvestPlanParam
>("customerAnalysis/saveOrUpdateInvestPlan", async (params) => {
  const response = await saveOrUpdateInvestPlanApi(params);
  return response;
});
export const positionAssetTransaction = createAsyncThunk<
  void,
  PositionAssetTransactionParam
>("customerAnalysis/positionAssetTransaction", async (params) => {
  const response = await positionAssetTransactionApi(params);
  return response;
});

export const putInvestPlanStatus = createAsyncThunk<
  void,
  PutInvestPlanStatusParam
>("customerAnalysis/putInvestPlanStatus", async (params) => {
  const response = await putInvestPlanStatusApi(params);
  return response;
});

export const fetchPortfolioPerformanceTrend = createAsyncThunk(
  "customerAnalysis/performance/trend",
  async ({ customerId, section }: { customerId: string; section: string }) => {
    const response = await getPortfolioPerformanceTrend(
      customerId,
      section as DatePeriodValue
    );
    return response;
  }
);
export const fetchAssetPositionDetail = createAsyncThunk(
  "customerAnalysis/AssetPositionDetail",
  async ({ accountId, date }: { accountId: string; date: string }) => {
    const res = await getCustomerAssetPositionDetail({ id: accountId, date });
    return res;
  }
);

type customerAnalysisType = {
  rangeIncome: RangeIncomeBody[];
  assetChange: RangeIncomeBody[];
  assetList: AssetListBody[];
  purchaseRedeem: RangeIncomeBody[];
};

type initCustomersAccountAnalysis = {
  [key: string]: customerAnalysisType | any;
  organizationType: OrganizationTypeBody[];
  fundIncomeMap: Record<string, any>;
  AccountId: string;
  industryList: Record<string, string>;
};

const initialState: initCustomersAccountAnalysis = {
  organizationType: [],
  fundIncomeMap: {},
  AccountId: "",
  industryList: {},
};

const customerAccountAnalysisSlice = createSlice({
  name: "customerAnalysis",
  initialState,
  reducers: {
    updateFundIncomeInfo: (state, action) => {
      const { value } = action.payload;
      if (value) {
        state.fundIncomeMap = {
          ...state.fundIncomeMap,
          ...value,
        };
      }
    },
    // 修改客户分析id
    updateCustomerAccountId: (state, action) => {
      state.AccountId = action.payload;
    },
    clearCustomerAccountAnalysisData: () => {
      return { ...initialState };
    },
  },
  extraReducers: {
    [fetchAccountInfo.fulfilled.type]: (state, action) => {
      state[action?.meta?.arg] = set("accountInfo", {
        ...action.payload,
        subCustomerDetails: map((item: Record<string, any>) => ({
          ...item,
          parentId: action?.meta?.arg,
        }))(fastProp("subCustomerDetails")(action.payload)),
      })(state[action.meta?.arg]);
    },
    [fetchRangeIncomeInfo.fulfilled.type]: (state, action) => {
      state[action?.meta?.arg] = set(
        "rangeIncome",
        action.payload
      )(state[action.meta?.arg]);
    },
    [fetchAssetChangeInfo.fulfilled.type]: (state, action) => {
      const { customerId, section } = action?.meta?.arg;
      state[customerId] = set(
        `assetChange.${section}`,
        action.payload
      )(state[customerId]);
    },
    [fetchAssetListInfo.fulfilled.type]: (state, action) => {
      state[action?.meta?.arg] = set(
        "assetList",
        action.payload
      )(state[action?.meta?.arg]);
    },
    [fetchPurchaseRedeemInfo.fulfilled.type]: (state, action) => {
      const { customerId, section } = action?.meta?.arg;
      state[customerId] = set(
        `purchaseRedeem.${section}`,
        action.payload
      )(state[customerId]);
    },
    [fetchAssetTypeDistributionInfo.fulfilled.type]: (state, action) => {
      const { customerId, customerBenchmarkType, groupType } =
        action?.meta?.arg;
      state[customerId] = set(
        `assetTypeDistribution.${customerBenchmarkType}.${groupType}`,
        action.payload
      )(state[customerId]);
    },
    [fetchAssetTypeDistributionSummary.fulfilled.type]: (state, action) => {
      state[action?.meta?.arg] = set(
        "assetTypeDistributionSummary",
        action.payload
      )(state[action?.meta?.arg]);
    },
    [fetchBrinsonAttributionData.fulfilled.type]: (state, action) => {
      const { id, section } = action?.meta?.arg;
      state[id] = set(
        `brinsonAttribution.${section}`,
        action.payload
      )(state[id]);
    },
    [fetchRiskDissectData.fulfilled.type]: (state, action) => {
      const { id, section } = action?.meta?.arg;
      state[id] = set(`riskDissect.${section}`, action.payload)(state[id]);
    },
    [fetchIndustryList.fulfilled.type]: (state, action) => {
      state.industryList = action.payload;
    },
    [fetchPortfolioPerformanceTrend.fulfilled.type]: (state, action) => {
      const { customerId, section } = action?.meta?.arg;
      state[customerId] = set(
        `portfolioPerformanceTrend.${section}`,
        action.payload
      )(state[customerId]);
    },
    [fetchOrganizationTypeList.fulfilled.type]: (state, action) => {
      state.organizationType = action.payload;
    },
    [fetchFundCumulativeIncomeInfo.fulfilled.type]: (state, action) => {
      const { customerId, section, type } = action?.meta?.arg;
      state[customerId] = set(
        `fundCumulativeIncome.${section}.${type}`,
        action.payload
      )(state[customerId]);
    },
    [fetchDrawAndIndexFundData.fulfilled.type]: (state, action) => {
      const { customerId, section } = action?.meta?.arg;
      state[customerId] = set(
        `drawAndIndex.${section}`,
        action.payload
      )(state[customerId]);
    },
    [fetchDrawAndIndexSummary.fulfilled.type]: (state, action) => {
      const { customerId, section, customerBenchmarkType } = action?.meta?.arg;
      state[customerId] = set(
        `drawAndIndexSummary.${customerBenchmarkType}.${section}`,
        action.payload
      )(state[customerId]);
    },
    [fetchFundsDailyReturn.fulfilled.type]: (state, action) => {
      return set("fundsDailyReturn", action.payload)(state);
    },
    [fetchBrinsonAttributionSummaryData.fulfilled.type]: (state, action) => {
      const { customerId, section } = action?.meta?.arg;
      state[customerId] = set(
        `brinsonAttributionSummaryData.${section}`,
        action.payload
      )(state[customerId]);
    },
    [fetchFactorEvaluation.fulfilled.type]: (state, action) => {
      const { customerId } = action?.meta?.arg;
      state[customerId] = set(
        `factorEvaluation`,
        action.payload
      )(state[customerId]);
    },
    [fetchPositionAssetsList.fulfilled.type]: (state, action) => {
      const { accountId } = action?.meta?.arg;
      state[accountId] = set(
        `positionAssetsList`,
        action.payload
      )(state[accountId]);
    },
    [fetchFixedInvestmentDetail.fulfilled.type]: (state, action) => {
      const { accountId, assetCode } = action?.meta?.arg;
      state[accountId] = setWith(Object)(
        `fixedInvestmentDetail.${assetCode}`,
        action.payload
      )(state[accountId]);
    },
    [fetchAssetPositionDetail.fulfilled.type]: (state, action) => {
      const { accountId, date } = action?.meta?.arg;
      state[accountId] = set(
        `assetPositionDetail.${date}`,
        action.payload
      )(state[accountId]);
    },
    [fetchInvestmentDetailInfo.fulfilled.type]: (state, action) => {
      state[action?.meta?.arg] = set(
        "investmentDetail",
        action.payload
      )(state[action?.meta?.arg]);
    },
  },
});
export const {
  updateFundIncomeInfo,
  updateCustomerAccountId,
  clearCustomerAccountAnalysisData,
} = customerAccountAnalysisSlice.actions;

export default customerAccountAnalysisSlice.reducer;
