import React, { useEffect } from "react";
import FundConfigurationWeightTable from "./components/fundConfigurationWeightTable";
import style from "../index.module.less";
import BackTestingFormComponent, {
  SaveSchemeParams,
} from "../../manualCreatePortfolio/backTestingAllocation/backTestingForm";
import type { BackTestingForm } from "../../manualCreatePortfolio/backTestingAllocation/backTestingForm";
import { Card } from "antd";
import { useManageAllocateData, useManageError } from "./hooks";
import BackTestingResult from "../../manualCreatePortfolio/backTestingAllocation/backTestingResult";
import { ManualCreatePortfolio } from "../../manualCreatePortfolio/constant";
import OptimizedErrorField from "./components/optimizedErrorField";
import { useFormatMessage } from "@/util/formatMessage";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useMemoizedFn } from "ahooks";
import { saveBackTestingScheme } from "@/store/portfolioList";
import { useGetAssetAllocateData } from "../hooks";

export type BackTestingAllocationReturnType = {
  allocationData: ManualCreatePortfolio[];
  formData: BackTestingForm;
};
export type BackTestingAllocationRef = {
  getData: () => BackTestingAllocationReturnType;
};

export default React.memo<{
  onError: React.Dispatch<React.SetStateAction<boolean>>;
}>(({ onError }) => {
  const formatMessage = useFormatMessage();
  const { onUpdateAssets, originFundAsset, manualPortfolioAllocateData } =
    useManageAllocateData();
  const { backTestingFormData } = useAppSelector(
    (state) => state.createPortfolio.fundConfiguration
  );
  const { error, errorField } = useManageError(
    originFundAsset,
    manualPortfolioAllocateData
  );
  useEffect(() => {
    onError(error);
  }, [error, onError]);

  const dispatch = useAppDispatch();
  const assetAllocateData = useGetAssetAllocateData();
  const onSaveScheme = useMemoizedFn((data: SaveSchemeParams) => {
    return dispatch(
      saveBackTestingScheme({ ...data, allocateRequest: assetAllocateData })
    ).unwrap();
  });

  return (
    <>
      <Card className={style.Card}>
        <div className={style.Header}>
          <h3>{formatMessage("OptionalFund")}</h3>
        </div>
        <FundConfigurationWeightTable
          originData={originFundAsset}
          data={manualPortfolioAllocateData}
          onUpdate={onUpdateAssets}
          errorField={errorField}
        />
        <OptimizedErrorField fundAsset={originFundAsset} />
      </Card>
      <BackTestingFormComponent
        data={manualPortfolioAllocateData}
        type="fundConfiguration"
        disabled={error}
        onSaveScheme={onSaveScheme}
      />
      <BackTestingResult
        startDate={backTestingFormData.startDate}
        endDate={backTestingFormData.endDate}
      />
    </>
  );
});
