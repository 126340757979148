import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { setLocalStorage } from "@/util/localStorage";
import { AUTHORIZATION } from "@/const";

export default function HomeRouteElement() {
  const location = useLocation();
  const exec = /(^|&)authorization=([^&]*)(&|$)/.exec(
    location.search.substr(1)
  );
  const authorization = exec && exec[2];
  if (authorization) {
    setLocalStorage(AUTHORIZATION, authorization);
  }

  return <Navigate to="/manage" />;
}
