import React, { useMemo } from "react";
import {
  first,
  merge,
  map,
  flow,
  prop,
  nth,
  values as lodashValues,
  max,
  min,
  isEmpty,
  reduce,
  forEach,
  flatten,
  isNumber,
} from "lodash/fp";
import {
  createChartOptionTitle,
  createChartOptionTooltip,
  createChartOptionDataZoom,
  createChartOptionToolbox,
  createChartOptionDateXAxis,
  createBarChartOptionDateXAxis,
  createChartOptionValueYAxis,
} from "../yieldTrendChart/helper";
import BaseChart from "../baseChart";
import { ChartProps, SeriesOption } from "./type";
import { colors } from "../helper/colors";
import { hexTorgba } from "../util/colorTransform";

export const getYxiasFixedNumberSplitLineConfit = (
  series: SeriesOption[],
  splitLineNumber: number,
  dates: string[]
) => {
  if (isEmpty(series)) {
    return {};
  }
  const valueItems: Record<string, number>[] = map((item: SeriesOption) =>
    reduce(
      (result: any, valueItem: [date: string, value: number]) => ({
        ...result,
        [valueItem?.[0]]: valueItem?.[1],
      }),
      {}
    )(prop("data")(item))
  )(series);
  const date2Value: Record<string, number> = {};
  forEach((date: string) => {
    if (!date2Value?.[date]) {
      date2Value[date] = 0;
    }
    forEach((valueItem: Record<string, number>) => {
      date2Value[date] += valueItem?.[date] || 0;
    })(valueItems);
  })(dates);
  const values: number[] = lodashValues(date2Value);
  const maxValue = max(values) || 0;
  const minValue = flow(first, prop("data"), map(nth(1)), min)(series);
  const distance =
    Math.round((((maxValue - minValue) * 1.2) / splitLineNumber) * 10000) /
    10000;
  const _min = minValue - (maxValue - minValue) * 0.1;
  return {
    max: splitLineNumber * distance + _min,
    min: _min,
    interval: distance,
  };
};

function getSeriesMaxAddValue(
  series: ChartProps["series"]
): Record<string, number> {
  const value = flow(
    map(prop("data")),
    flatten,
    reduce(
      (
        result: Record<string, number>,
        valueItem: [date: string, value: number]
      ) => {
        return {
          ...result,
          [valueItem[0]]: (result?.[valueItem[0]] || 0) + (valueItem[1] || 0),
        };
      },
      {}
    )
  )(series);
  return value;
}

const getSerie =
  ({
    selectedDate,
    type,
    maxYaxis,
  }: Pick<ChartProps, "selectedDate" | "type"> & { maxYaxis: number }) =>
  (serie: SeriesOption, index: number) => {
    const color = serie?.color || colors?.[index];
    let result = {
      ...serie,
      color,
      ...(type === "bar"
        ? {
            type: "bar",
            stack: "Total",
            barMaxWidth: 30,
            areaStyle: {},
            animation: false,
            emphasis: {
              itemStyle: {
                opacity: 0.6,
              },
            },
          }
        : {
            type: "line",
            stack: "Total",
            smooth: false,
            showSymbol: false,
            lineStyle: {
              width: 1,
            },
            areaStyle: {
              opacity: 0.1,
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: hexTorgba(color), // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: hexTorgba(color, 0), // 100% 处的颜色
                  },
                ],
              },
            },
            animation: false,
          }),
    };
    if (selectedDate && index === 0) {
      result = merge(result)({
        markLine: {
          data: [
            [
              {
                xAxis: selectedDate,
                yAxis: 0,
                symbol: "triangle",
                symbolRotate: 360,
                symbolSize: [10, 8],
                symbolOffset: [0, "50%"],
              },
              {
                xAxis: selectedDate,
                yAxis: maxYaxis,
                symbol: "triangle",
                symbolRotate: 180,
                symbolSize: [10, 8],
                symbolOffset: [0, "50%"],
              },
            ],
          ],
          lineStyle: {
            color: "#000000",
          },
          silent: true,
        },
      });
    }
    return result;
  };

const createLegend = ({
  title,
  showLegend,
  legendPosition,
  type,
}: Pick<ChartProps, "type" | "title" | "showLegend" | "legendPosition">) => {
  const itemConfig =
    type === "bar"
      ? {
          icon: "rect",
          itemWidth: 14,
          itemHeight: 14,
        }
      : {
          icon: "rect",
          borderRadius: 4,
          itemWidth: 20,
          itemHeight: 4,
        };
  if (legendPosition === "right") {
    return {
      ...itemConfig,
      top: title ? 25 + 10 : 10,
      show: showLegend,
      type: "scroll",
      orient: "vertical",
      right: 10,
      bottom: 10,
    };
  }
  return {
    ...itemConfig,
    top: title ? 25 : 0,
    show: showLegend,
    type: "scroll",
    orient: "horizontal",
    left: "left",
  };
};

export default React.memo<ChartProps>(
  ({
    title,
    dates = [],
    series,
    height = 300,
    splitNumber,
    legendPosition = "top",
    selectedDate,
    type = "line",
    showDataZoom = true,
    showLegend = true,
    saveImgEnable = false,
    boundaryGap = false,
    dateMode,
    onEvents,
  }) => {
    const finalSeries = useMemo(() => {
      const maxAddValue = getSeriesMaxAddValue(series);
      return series.map(
        getSerie({
          selectedDate,
          type,
          maxYaxis: maxAddValue?.[selectedDate || ""] || 1,
        })
      );
    }, [series, selectedDate, type]);
    const chartOption = useMemo(
      () => ({
        title: createChartOptionTitle(title),
        tooltip: createChartOptionTooltip(),
        legend: createLegend({ title, showLegend, legendPosition, type }),
        grid: {
          left: 0,
          top:
            20 +
            (showLegend && legendPosition === "top" ? 30 : 0) +
            (title ? 20 : 0),
          bottom: 0 + (showDataZoom ? 48 : 0),
          ...(legendPosition === "top" ? { right: 10 } : {}),
          containLabel: true,
        },
        dataZoom: createChartOptionDataZoom(showDataZoom),
        toolbox: createChartOptionToolbox(saveImgEnable),
        xAxis:
          type === "bar"
            ? createBarChartOptionDateXAxis({ dates })
            : createChartOptionDateXAxis({ dates, boundaryGap, dateMode }),
        yAxis: merge(
          createChartOptionValueYAxis({
            splitNumber,
            height,
            showLegend,
            showDataZoom,
          })
        )({
          // 限制y轴的最大值显示
          max: (value: { max: number }) => {
            return isNumber(value.max) ? value.max : 1;
          },
        }),
        series: finalSeries,
      }),
      [
        showLegend,
        showDataZoom,
        saveImgEnable,
        dates,
        finalSeries,
        type,
        height,
        splitNumber,
        legendPosition,
        title,
        dateMode,
        boundaryGap,
      ]
    );

    return (
      <BaseChart option={chartOption} height={height} onEvents={onEvents} />
    );
  }
);
