import {
  CategoryAssetDistributionOpt,
  getCategoryAssetDistribution,
  getFundFinancialReport,
  getStockIndustryDistribution,
  StockIndustryDistributionOpt,
} from "@/api/compareManage";
import { getFundsDailyReturn, getMainFilterList } from "@/api/replaceFund";
import { fundsReplaceOpt, fundsReplaceState } from "@/model/replaceFund";
import createSocketThunk from "@/util/createSocketThunk";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { identity, orderBy, set } from "lodash/fp";

export const fetchFundFinancialReport = createAsyncThunk(
  "replaceFund/fundFinancialReport",
  async (fundIds: string[]) => {
    const response = await getFundFinancialReport(fundIds);
    return response;
  }
);

export const fetchCategoryAssetDistribution =
  avoidMultiRequestActionThunk<CategoryAssetDistributionOpt>(
    (data) => `replaceFund.categoryAssetDistribution.${data?.date}`,
    createAsyncThunk("replaceFund/categoryAssetDistribution", async (data) => {
      const response = await getCategoryAssetDistribution(data);
      return response;
    })
  );

export const fetchStockIndustryDistribution =
  avoidMultiRequestActionThunk<StockIndustryDistributionOpt>(
    (data) =>
      `replaceFund.stockIndustryDistribution.${data?.sectorId}.${data?.reportDate}`,
    createAsyncThunk("replaceFund/stockIndustryDistribution", async (data) => {
      const response = await getStockIndustryDistribution(data);
      return response;
    })
  );

export const fetchFundsDailyReturns = createAsyncThunk(
  "replaceFund/fundsDailyReturns",
  async (fundIds: string[]) => {
    const response = await getFundsDailyReturn(fundIds);
    return response;
  }
);

export const fetchReplaceList = createSocketThunk(
  "replaceFund/replaceFundList",
  "FINISH_FIND_ALTERNATIVE_FUND",
  (meta: fundsReplaceOpt) => {
    const response = getMainFilterList(meta);
    return response;
  }
);

const getDefaultFundsReplaceParm = () => ({
  id: "",
  similarPosition: false,
  similarNetValue: false,
  blockTrade: false,
});

const initialState: fundsReplaceState = {
  fundsReplaceFilter: getDefaultFundsReplaceParm(),
  financialReportDate: [],
  categoryAssetDistribution: {},
  stockIndustryDistribution: {},
  fundsDailyReturns: [],
  replaceFundList: [],
};

const fetchMainFilterListStart: string = fetchReplaceList.start.type;
const fetchMainFilterListProgress: string = fetchReplaceList.progress.type;
const fetchMainFilterListFinish: string = fetchReplaceList.finish.type;
const fetchMainFilterListError: string = fetchReplaceList.error.type;

const replaceFundSlice = createSlice({
  name: "replaceFund",
  initialState,
  reducers: {
    updateReplaceFilter: (state: fundsReplaceState, action) => {
      const { key, value } = action.payload;
      state.fundsReplaceFilter = {
        ...state.fundsReplaceFilter,
        [key]: value,
      };
    },
    clearReplaceFilter: (state: fundsReplaceState) => {
      state.fundsReplaceFilter = getDefaultFundsReplaceParm();
    },
  },
  extraReducers: {
    [fetchFundFinancialReport.fulfilled.type]: (state, action) => {
      state.financialReportDate = orderBy<string>(
        identity,
        "desc"
      )(action.payload);
    },
    [fetchCategoryAssetDistribution.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.categoryAssetDistribution[action.meta?.arg?.date] =
          action.payload;
      }
    },
    [fetchStockIndustryDistribution.fulfilled.type]: (state, action) => {
      const { sectorId, reportDate } = action.meta?.arg;
      if (action.payload) {
        state.stockIndustryDistribution[sectorId] = set(
          `${reportDate}`,
          action.payload
        )(state.stockIndustryDistribution[sectorId]);
      }
    },
    [fetchFundsDailyReturns.fulfilled.type]: (state, action) => {
      state.fundsDailyReturns = action.payload;
    },
    [fetchMainFilterListStart]: (state, action) => {
      state.filterTaskProgress = action.meta;
    },
    [fetchMainFilterListProgress]: (state, action) => {
      state.filterTaskProgress = action.payload;
    },
    [fetchMainFilterListFinish]: (state, action) => {
      state.categoryAssetDistribution = {};
      state.stockIndustryDistribution = {};
      state.replaceFundList = action?.payload?.view;
      state.filterTaskProgress = action?.meta;
    },
    [fetchMainFilterListError]: (state, action) => {
      state.filterTaskProgress = action.error;
    },
  },
});

export const { updateReplaceFilter, clearReplaceFilter } =
  replaceFundSlice.actions;
export default replaceFundSlice.reducer;
