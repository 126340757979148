import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";

import { useAppDispatch } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { fetchManagerEvaluation } from "@/store/fundDetailSlice";

import PageHeader from "../../pageHeader";
import style from "./index.module.less";
import FundManagerEvaluation from "./fundManagerEvaluation";
import { ManagerEvaluationBody } from "@/model/fundDetail";
import LoadingComponent from "@/components/LoadingComponent";
import { map, set, size, take, takeRight } from "lodash/fp";
import FundHistoryInfo from "./FundHistoryInfo";

export default React.memo(
  ({
    fundId,
    setSixPageDoms,
  }: {
    fundId: string;
    setSixPageDoms: React.Dispatch<React.SetStateAction<HTMLDivElement[]>>;
  }) => {
    const dispatch = useAppDispatch();
    const formatMessage = useFormatMessage();

    const [managerEvaluation, setManagerEvaluation] =
      useState<ManagerEvaluationBody>();

    useEffect(() => {
      const fetchData = async () => {
        setManagerEvaluation(
          (await dispatch(fetchManagerEvaluation(fundId)))
            .payload as ManagerEvaluationBody
        );
      };
      if (fundId) {
        fetchData();
      }
    }, [dispatch, fundId]);

    const { fundManagerInfos } = managerEvaluation || {};
    const fundManagerInfoSize = useMemo(
      () => size(fundManagerInfos),
      [fundManagerInfos]
    );

    return fundManagerInfoSize > 2 ? (
      <>
        <div
          className={style.PageWrap}
          ref={(dom) => {
            setSixPageDoms((prevState) => {
              return set("0")(dom)(prevState);
            });
          }}
        >
          <LoadingComponent actions={[fetchManagerEvaluation]}>
            <PageHeader pageNumber={6} />
            <div className={cn(style.PageContentWrap, style.SixthPageWrap)}>
              <h1>三、{formatMessage("fundManagerEvaluation")}</h1>
              <div className={style.FundManagerEvaluationWrap}>
                <div className={style.ManagerInfoList}>
                  {map(
                    (
                      item: ManagerEvaluationBody["fundManagerInfos"][number]
                    ) => (
                      <FundManagerEvaluation
                        fundManagerInfo={item}
                        fundId={fundId}
                      />
                    )
                  )(take(4)(fundManagerInfos))}
                </div>
              </div>
            </div>
          </LoadingComponent>
        </div>
        <div
          className={style.PageWrap}
          ref={(dom) => {
            setSixPageDoms((prevState) => {
              return set("1")(dom)(prevState);
            });
          }}
        >
          <LoadingComponent actions={[fetchManagerEvaluation]}>
            <PageHeader pageNumber={7} />
            <div className={cn(style.PageContentWrap, style.SixthPageWrap)}>
              <div className={style.FundManagerEvaluationWrap}>
                <div className={style.ManagerInfoList}>
                  {map(
                    (
                      item: ManagerEvaluationBody["fundManagerInfos"][number]
                    ) => (
                      <FundManagerEvaluation
                        fundManagerInfo={item}
                        fundId={fundId}
                      />
                    )
                  )(takeRight(fundManagerInfoSize - 4)(fundManagerInfos))}
                </div>
                {fundManagerInfoSize > 4 && <div className={style.Divider} />}
                <FundHistoryInfo
                  managerEvaluation={managerEvaluation}
                  fundId={fundId}
                />
              </div>
            </div>
          </LoadingComponent>
        </div>
      </>
    ) : (
      <div
        className={style.PageWrap}
        ref={(dom) => {
          setSixPageDoms((prevState) => {
            return set("0")(dom)(prevState);
          });
        }}
      >
        <LoadingComponent actions={[fetchManagerEvaluation]}>
          <PageHeader pageNumber={6} />
          <div className={cn(style.PageContentWrap, style.SixthPageWrap)}>
            <h1>三、{formatMessage("fundManagerEvaluation")}</h1>
            <div className={style.FundManagerEvaluationWrap}>
              <div className={style.ManagerInfoList}>
                {map(
                  (item: ManagerEvaluationBody["fundManagerInfos"][number]) => (
                    <FundManagerEvaluation
                      fundManagerInfo={item}
                      fundId={fundId}
                    />
                  )
                )(fundManagerInfos)}
              </div>
              <div className={style.Divider} />
              <FundHistoryInfo
                managerEvaluation={managerEvaluation}
                fundId={fundId}
              />
            </div>
          </div>
        </LoadingComponent>
      </div>
    );
  }
);
