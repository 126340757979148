import { RootState } from "@/store";
import { fastProp } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { DataSource } from "@/model/login";
import { first, flow, orderBy } from "lodash/fp";

export const dataSourceSelector = createSelector(
  (state: RootState) => state.users.dataSource,
  (dataSource) => {
    return flow(orderBy("sort", "asc"), first)(dataSource) as DataSource;
  }
);

export const dataSourceIdSelector = createSelector(
  dataSourceSelector,
  (dataSource) => fastProp("id")(dataSource) as DataSource["id"]
);

export const dataSourceTimeSelector = createSelector(
  dataSourceSelector,
  (dataSource) =>
    fastProp("lastUpdateDate")(dataSource) as DataSource["lastUpdateDate"]
);
