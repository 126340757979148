import ColorNumber from "@/components/colorNumber";
import { childrenAccountBody } from "@/model/customer";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import { Space } from "antd";
import React from "react";
import style from "./index.module.less";

export default React.memo(
  ({
    childrenInfo,
    index,
  }: {
    childrenInfo: childrenAccountBody;
    index: number;
  }) => {
    const formatMessage = useFormatMessage();
    return (
      <div className={style.AccountBox} key={index}>
        <div className={style.AccountName}>
          <div className={style.AccountNameLbl}>
            {fastProp("accountName")(childrenInfo)}
          </div>
        </div>
        <div className={style.AccountTotalInfo}>
          <p>
            {formatMessage("totalAssets")}({formatMessage("YUAN")})
          </p>
          <ColorNumber
            value={fastProp("totalAsset")(childrenInfo)}
            formatter="parseToThousandth"
            className={style.ChildrenTotalAsset}
          />
        </div>
        <div className={style.AccountInfo}>
          <Space size="middle">
            <div className={style.AccountBoxItem}>
              <p>
                {formatMessage("accumulateProfitAndLoss")}(
                {formatMessage("YUAN")})
              </p>
              <ColorNumber
                value={fastProp("cumulativeProfit")(childrenInfo)}
                formatter="parseToThousandth"
                className={style.NumberSize}
              />
            </div>
            <div className={style.AccountBoxItem}>
              <p>
                {formatMessage("yesterdayProfitAndLoss")}(
                {formatMessage("YUAN")})
              </p>
              <ColorNumber
                value={fastProp("yesterdayProfit")(childrenInfo)}
                formatter="parseToThousandth"
                className={style.NumberSize}
              />
            </div>
            <div className={style.AccountBoxItem}>
              <p>{formatMessage("heldAssets")}</p>
              <span className={style.HoldAsset}>
                {fastProp("holdAsset")(childrenInfo)}
              </span>
            </div>
          </Space>
        </div>
      </div>
    );
  }
);
