import { FROM_CREATION } from "@/constant/statisticRange";
import { BackTestingScheme } from "@/model/portfolioList";
import { FORMAT_TYPES } from "@/util/exportXlsx";
import { roundTo } from "@/util/numberFormatter";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { flow, map, prop, sumBy } from "lodash/fp";
import {
  invertGoalConfig,
  modelsConfig,
  periodFrequencyConfig,
  riskFreeRateConfig,
} from "../../fundConfiguration/constant";
import { BackTestingForm } from "../../manualCreatePortfolio/backTestingAllocation/backTestingForm";
import {
  Asset,
  getDefaultErrorField,
  ManualCreatePortfolio,
} from "../../manualCreatePortfolio/constant";
import { validateDataError } from "../../manualCreatePortfolio/hooks";
import { categoryModelConfig } from "../../fromAboveToBelow/interface";
export const exportDataColumn = [
  {
    prop: "turnoverDate",
    label: "调仓日\n*最早日期默认为建仓日\n调仓日需为交易日",
    wrapText: true,
  },
  {
    prop: "code",
    label: "基金代码\n*基金初始净值日期需早于所属调仓日",
    wrapText: true,
  },
  {
    prop: "weight",
    label:
      "调仓权重\n*权重格式为小数\n单日基金权重之和不可超过1\n单日基金权重之和不足1部分自动归为现金",
    wrapText: true,
    format: FORMAT_TYPES.TWO_FLOAT,
  },
];

export const initBenchmarkFormData = (
  schemeData: BackTestingScheme
): BackTestingForm => ({
  id: fastProp("id")(schemeData),
  startDate: fastProp("startDate")(schemeData),
  endDate: fastProp("endDate")(schemeData),
  benchmarkId: fastProp("benchmark")(schemeData),
  initScale: fastProp("initScale")(schemeData),
  benchmarkType: fastProp("benchmarkType")(schemeData),
});

export const getManualCreatePortfolioData = (
  turnoverPositions: BackTestingScheme["turnoverPositions"],
  assetGenerator: (fundIds: string[]) => Asset[]
) =>
  flow(
    map<any, ManualCreatePortfolio>((item) => {
      const turnoverDate = fastProp("turnoverDate")(item) as string;
      const weights = fastProp("weights")(item);
      const fundIds = map("fundId")(weights);
      const sumWeight = roundTo(4)(sumBy("weight")(weights));
      const assets = flow(
        assetGenerator,
        mapIndexed((data: Asset, index: number) => ({
          ...data,
          weight: fastProp("weight")(fastNth(index)(weights)),
        }))
      )(fundIds) as Asset[];
      return {
        turnoverDate,
        weights: assets,
        sumWeight: sumWeight,
        errorField: getDefaultErrorField(),
      };
    }),
    map(validateDataError)
  )(turnoverPositions);

export const fundConfigurationFormDataHelper = {
  getModelType(allocateRequest: BackTestingScheme["allocateRequest"]) {
    const configType = prop("allocateConfig.configType")(allocateRequest);
    const goal = prop("allocateConfig.goal")(allocateRequest);
    // 如果是优化权重，那么需要根据goal去获取模型信息
    if (configType === modelsConfig.optimizeWeight)
      return fastProp(goal)(invertGoalConfig);
    return configType;
  },
  getFormData(allocateRequest: BackTestingScheme["allocateRequest"]) {
    const modelType = this.getModelType(allocateRequest);
    const foundDate = prop("turnoverConfig.foundDate")(allocateRequest);
    const allocateConfig = fastProp("allocateConfig")(allocateRequest);
    const turnoverConfig = fastProp("turnoverConfig")(allocateRequest);
    const riskFreeConfig =
      prop("goalConfig.riskFreeConfig")(allocateConfig) ||
      riskFreeRateConfig.defaultRiskFreeConfig();
    const annualizedMinReturn =
      prop("goalConfig.annualizedMinReturn")(allocateConfig) || null;
    const riskValue =
      prop("goalConfig.annualizedMaxRisk")(allocateConfig) || null;
    const dataRange = fastProp("dataRange")(allocateConfig) || FROM_CREATION;
    const rebalanceType = prop("frequency.type")(turnoverConfig) || "";
    const periodicFrequency =
      prop("frequency.interval")(turnoverConfig) || periodFrequencyConfig.MONTH;
    const specifiedDates = prop("frequency.dates")(turnoverConfig) || [];
    const turnoverRule = fastProp("turnoverRule")(turnoverConfig) || "";
    return {
      modelType,
      riskFreeConfig,
      annualizedMinReturn,
      riskValue,
      dataRange,
      foundDate,
      rebalanceType,
      periodicFrequency,
      specifiedDates,
      turnoverRule,
      netValueStart: "",
    };
  },
};

export const modelConfigurationFormDataHelper = {
  getModelAllocationData(data: categoryModelConfig) {
    return {
      ...data,
      categoryTypes: map(fastProp("categoryId"))(data?.categoryAssets),
      // todo 临时解决 不稳定方法
      configurationModel:
        data?.allocateConfig?.goalConfig?.goalType === "blackLitterman"
          ? "blackLittleManModel"
          : "meanVarianceModel",
    };
  },
};
