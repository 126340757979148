import { useFormatMessage } from "@/util/formatMessage";
import { Button } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { identity, map } from "lodash/fp";
import React, { useCallback } from "react";

export interface ButtonGroupItem {
  id: string;
  messageKey: string;
  value?: string;
}

type ButtonGroupProps = {
  groups: ButtonGroupItem[];
  value: string;
  onChange?: (v: string) => void;
};
const ButtonGroups = ({
  groups,
  value,
  onChange = identity,
}: ButtonGroupProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const onHandleChange = useCallback(
    (v: string) => () => {
      onChange(v);
    },
    [onChange]
  );
  return (
    <ButtonGroup>
      {map(({ id, messageKey }: ButtonGroupItem) => (
        <Button
          onClick={onHandleChange(id)}
          key={id}
          type={value === id ? "primary" : "default"}
        >
          {formatMessage(messageKey)}
        </Button>
      ))(groups)}
    </ButtonGroup>
  );
};

export default React.memo(ButtonGroups);
