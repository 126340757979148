import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  // 基金对比
  getReturnFactors,
  getFundFinancialReport,
  getCompareBenchmark,
  getCategoryAssetDistribution,
  getStockIndustryDistribution,
  StockIndustryDistributionOpt,
  getTop10PositionStocks,
  getFundCompareReturnInfo,
  // 基金经理对比
  getFundManagerCompareBaseInfo,
  // 基金公司对比
  getFundCompanyCompareBaseInfo,
  getCompareInfo,
  CompareDistributionOpt,
  getCompareDistribution,
  DISTRIBUTION_TYPE,
} from "@/api/compareManage";
import type { CategoryAssetDistributionOpt } from "@/api/compareManage";
import { getETFFundAttributionBrinsonResult, getFundAttributionBrinsonResult } from "@/api/fundDetail";
import {
  CategoryAssetDistribution,
  CompareDistributionResponse,
  fundManagerCompareIndustryOpt,
  FundManagerCompareTop10StocksOpt,
} from "@/model/compareManage";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import createSocketThunk from "@/util/createSocketThunk";
import { fastProp, normalize } from "@/util/opt";
import { flatten, flow, identity, orderBy, set, uniq, values } from "lodash/fp";
import { TaskData } from "@/util/socket";
import { ETFFundAttributionBrinsonBody, FundAttributionBrinsonBody } from "@/model/fundDetail";
import {
  getFundManagerDetailDailyReturnApi,
  getFundManagerIndustryInfoApi,
  getFundManagerMarketValueInfoApi,
  getFundManagerReportDateApi,
  getFundManagerScaleTrendApi,
  getFundManagerYearReportDateApi,
} from "@/api/fundManagerDetail";
/**
 * 基金对比
 */
export const fetchReturnFactors = avoidMultiRequestActionThunk<void>(
  "compareManage.returnFactors",
  createAsyncThunk("compareManage/fundCompare/returnFactors", async () => {
    const response = await getReturnFactors();
    return response;
  })
);

export const fetchFundFinancialReport = createAsyncThunk(
  "compareManage/fundCompare/fundFinancialReport",
  async (fundIds: string[]) => {
    const response = await getFundFinancialReport(fundIds);
    return response;
  }
);

export const fetchCompareBenchmark = avoidMultiRequestActionThunk<void>(
  "compareManage.compareBenchmark",
  createAsyncThunk("compareManage/fundCompare/compareBenchmark", async () => {
    const response = await getCompareBenchmark();
    return response;
  })
);
export const fetchStockIndustryDistribution =
  avoidMultiRequestActionThunk<StockIndustryDistributionOpt>(
    (data) =>
      `compareManage.fundCompare.stockIndustryDistribution.${data?.sectorId}.${data.reportDate}`,
    createAsyncThunk(
      "compareManage/fundCompare/stockIndustryDistribution",
      async (data) => {
        const response = await getStockIndustryDistribution(data);
        return response;
      }
    )
  );

export const fetchCategoryAssetDistribution =
  avoidMultiRequestActionThunk<CategoryAssetDistributionOpt>(
    (data) =>
      `compareManage.fundCompare.categoryAssetDistribution.${data.date}`,
    createAsyncThunk(
      "compareManage/fundCompare/categoryAssetDistribution",
      async (data) => {
        const response = await getCategoryAssetDistribution(data);
        return response;
      }
    )
  );

export const fetchTop10PositionStocks =
  avoidMultiRequestActionThunk<CategoryAssetDistributionOpt>(
    (data) => `compareManage.fundCompare.top10PositionStocks.${data.date}`,
    createAsyncThunk(
      "compareManage/fundCompare/top10PositionStocks",
      async (data) => {
        const response = await getTop10PositionStocks(data);
        return response;
      }
    )
  );

export const fetchFundCompareBrinson = createSocketThunk(
  "compareManage/fundCompare/FINISH_SINGLE_FUND_BRINSON_ATTRIBUTION",
  "FINISH_SINGLE_FUND_BRINSON_ATTRIBUTION",
  async (v: FundAttributionBrinsonBody) => {
    const response = await getFundAttributionBrinsonResult(v);
    return response;
  }
);

export const fetchETFFundCompareBrinson = createSocketThunk(
  "compareManage/fundCompare/FINISH_ATTRIBUTION_BRINSON",
  "FINISH_ATTRIBUTION_BRINSON",
  async (v: ETFFundAttributionBrinsonBody) => {
    const response = await getETFFundAttributionBrinsonResult(v);
    return response;
  }
);

export const fetchFundCompareReturnInfo = createSocketThunk(
  "compareManage/fundCompare/fundCompareReturnInfo",
  "FINISH_FUND_COMPARE",
  (fundIds: string[]) => {
    return getFundCompareReturnInfo({ fundIds });
  }
);

export const fetchCompareInfo = createAsyncThunk(
  "compareManage/fundCompare/getCompareInfo",
  async (fundIds: string[]) => {
    const response = await getCompareInfo(fundIds);
    return response;
  }
);

/**
 * 基金经理对比
 */
export const fetchFundManagerCompareBaseInfo = createAsyncThunk(
  "compareManage/fundManagerCompare/fundManagerCompareBaseInfo",
  async (fundIds: string[]) => {
    const response = await getFundManagerCompareBaseInfo(fundIds);
    return response;
  }
);

export const fetchFundManagerCompareDailyReturn = createAsyncThunk(
  "compareManage/fundManagerCompare/fundManagerCompareDailyReturn",
  async (ids: string[]) => {
    const response = await getFundManagerDetailDailyReturnApi(ids);
    return response;
  }
);

export const fetchFundManagerCompareScaleChange = createAsyncThunk(
  "compareManage/fundManagerCompare/fundManagerCompareScaleChange",
  async (managerIds: string[]) => {
    const response = await getFundManagerScaleTrendApi(managerIds);
    return response;
  }
);
export const fetchFundManagerCompareIndustryInfo =
  avoidMultiRequestActionThunk<fundManagerCompareIndustryOpt>(
    (data) =>
      `compareManage.fundManagerCompare.IndustryInfo.${data.reportDate}`,
    createAsyncThunk(
      "compareManage/fundManagerCompare/stockIndustryDistribution",
      async (data) => {
        const response = await getFundManagerIndustryInfoApi(data);
        return response;
      }
    )
  );
export const fetchFundManagerCompareTop10PositionStocks =
  avoidMultiRequestActionThunk<FundManagerCompareTop10StocksOpt>(
    (data) =>
      `compareManage.fundManagerCompare.top10PositionStocks.${data.reportDate}`,
    createAsyncThunk(
      "compareManage/fundManagerCompare/top10PositionStocks",
      async (data) => {
        const response = await getFundManagerMarketValueInfoApi(data);
        return response;
      }
    )
  );
export const getFundManagerReportDate = createAsyncThunk(
  "compareManage.fundManagerCompare.reportDate",
  async (ids: string[]) => {
    const response = await getFundManagerReportDateApi(ids);
    return response;
  }
);
export const getFundManagerYearReportDate = createAsyncThunk(
  "compareManage.fundManagerCompare.yearReportDate",
  async (ids: string[]) => {
    const response = await getFundManagerYearReportDateApi(ids);
    return response;
  }
);

/**
 * 基金公司对比
 */
export const fetchFundCompanyCompareBaseInfo = createAsyncThunk(
  "compareManage/fundCompanyCompare/fundCompanyCompareBaseInfo",
  async (fundIds: string[]) => {
    const response = await getFundCompanyCompareBaseInfo(fundIds);
    return response;
  }
);

export const fetchFundCompanyCompareDistribution =
  avoidMultiRequestActionThunk<CompareDistributionOpt>(
    (param) =>
      `compareManage.fundCompany.distributions.${param.section}.${param.distributionType}`,
    createAsyncThunk(
      "portfolioAnalysis/turnoverAnalysis/compareDistribution",
      async (parm: CompareDistributionOpt) => {
        const response = await getCompareDistribution(parm);
        return response;
      }
    )
  );

type FundCompareType = {
  financialReportDate: string[];
  categoryAssetDistribution: Record<string, CategoryAssetDistribution[]>;
  stockIndustryDistribution: Record<string, any>;
  top10PositionStocks: Record<string, any>;
  compareFundTask: Record<string, any>;
  compareFundProgress?: TaskData | undefined;
  brinsonAttribution: Record<string, any>;
};
export type FundManagerType = {
  basicInfo: any[];
  managerDailyReturn: any[];
  ScaleChangeInfo: any[];
  industryInfo: Record<string, any>;
  top10PositionStocks: Record<string, any>;
  reportDate: string[];
  yearReportDate: string[];
};
type FundCompanyType = {
  basicInfo: any[];
  distributions: {
    [DISTRIBUTION_TYPE.INCOME]: Record<string, CompareDistributionResponse[]>;
    [DISTRIBUTION_TYPE.MAXDRAWN]: Record<string, CompareDistributionResponse[]>;
  };
};
type CompareManageState = {
  fundCompare: FundCompareType;
  fundManager: FundManagerType;
  fundCompany: FundCompanyType;
  returnFactors: Record<string, string>;
  compareBenchmark: Record<string, string>;
};

const getDefaultFundCompareResult = () => ({
  financialReportDate: [],
  // categoryAssetDistribution: [],
  categoryAssetDistribution: {},
  stockIndustryDistribution: {},
  top10PositionStocks: {},
  compareFundTask: {},
  brinsonAttribution: {},
});

const getDefaultFundManagerResult = () => ({
  basicInfo: [],
  managerDailyReturn: [],
  ScaleChangeInfo: [],
  industryInfo: [],
  top10PositionStocks: {},
  reportDate: [],
  yearReportDate: [],
});

const getDefaultFundCompanyResult = () => ({
  basicInfo: [],
  distributions: {
    [DISTRIBUTION_TYPE.INCOME]: {},
    [DISTRIBUTION_TYPE.MAXDRAWN]: {},
  },
});
const initialState: CompareManageState = {
  fundCompare: {
    ...getDefaultFundCompareResult(),
  },
  fundManager: {
    ...getDefaultFundManagerResult(),
  },
  fundCompany: {
    ...getDefaultFundCompanyResult(),
  },
  returnFactors: {},
  compareBenchmark: {},
};

const fetchFundCompareReturnInfoFinish: string =
  fetchFundCompareReturnInfo.finish.type;
const fetchFundCompareReturnInfoError: string =
  fetchFundCompareReturnInfo.error.type;
const fetchFundCompareReturnInfoProgress: string =
  fetchFundCompareReturnInfo.progress.type;

const fetchFundCompareBrinsonFinish: string =
  fetchFundCompareBrinson.finish.type;
const fetchFundCompareBrinsonError: string = fetchFundCompareBrinson.error.type;
const fetchFundCompareBrinsonProgress: string =
  fetchFundCompareBrinson.progress.type;

const fetchETFFundCompareBrinsonFinish: string =
  fetchETFFundCompareBrinson.finish.type;
const fetchETFFundCompareBrinsonError: string = fetchETFFundCompareBrinson.error.type;
const fetchETFFundCompareBrinsonProgress: string =
  fetchETFFundCompareBrinson.progress.type;

const compareManageSlice = createSlice({
  name: "fundCompare",
  initialState,
  reducers: {
    // 修改基金对比信息
    updateFundCompareInfo: (state: CompareManageState, action) => {
      const { key, value } = action.payload;
      state.fundCompare[key as keyof FundCompareType] = value;
    },
    clearFundCompareResult: (state: CompareManageState) => {
      state.fundCompare = {
        ...getDefaultFundCompareResult(),
      };
    },
    // 修改基金经理对比信息
    updateFundManagerInfo: (state: CompareManageState, action) => {
      const { key, value } = action.payload;
      state.fundManager[key as keyof FundManagerType] = value;
    },
    clearFundManagerResult: (state: CompareManageState) => {
      state.fundManager = {
        ...getDefaultFundManagerResult(),
      };
    },
    // 修改基金公司对比信息
    updateFundCompanyInfo: (state: CompareManageState, action) => {
      const { key, value } = action.payload;
      state.fundCompany[key as keyof FundCompanyType] = value;
    },
    clearFundCompanyResult: (state: CompareManageState) => {
      state.fundCompany = {
        ...getDefaultFundCompanyResult(),
      };
    },
  },
  extraReducers: {
    [fetchReturnFactors.fulfilled.type]: (state, action) => {
      state.returnFactors = action.payload;
    },
    [fetchCompareBenchmark.fulfilled.type]: (state, action) => {
      state.compareBenchmark = action.payload;
    },
    // 基金对比
    [fetchFundFinancialReport.fulfilled.type]: (state, action) => {
      state.fundCompare.financialReportDate = orderBy<string>(
        identity,
        "asc"
      )(action.payload);
    },
    [fetchCategoryAssetDistribution.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.fundCompare.categoryAssetDistribution[
          action.meta?.arg?.date || ""
        ] = action.payload;
      }
    },
    [fetchStockIndustryDistribution.fulfilled.type]: (state, action) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { sectorId, reportDate } = action.meta?.arg;
      if (action.payload) {
        state.fundCompare.stockIndustryDistribution[sectorId] = set(
          `${reportDate}`,
          action.payload
        )(state.fundCompare.stockIndustryDistribution[sectorId]);
      }
    },
    [fetchTop10PositionStocks.fulfilled.type]: (state, action) => {
      // state.fundCompare.top10PositionStocks = normalize("fundId")(
      if (action.payload) {
        state.fundCompare.top10PositionStocks[action.meta?.arg?.date || ""] =
          normalize("fundId")(action.payload);
      }
    },
    [fetchFundCompareBrinsonFinish]: (state, action) => {
      state.fundCompare.brinsonAttribution[action.meta?.arg.assetId] = {
        fundAttributionBrinsonProgress: action.meta,
        fundAttributionBrinsonResult: action.payload,
      };
    },

    [fetchFundCompareBrinsonError]: (state, action) => {
      state.fundCompare.brinsonAttribution[action.meta?.arg.assetId] = {
        fundAttributionBrinsonProgress: action.error,
      };
    },
    [fetchFundCompareBrinsonProgress]: (state, action) => {
      state.fundCompare.brinsonAttribution[action.meta?.arg.assetId] = {
        fundAttributionBrinsonProgress: action.payload,
      };
    },
    [fetchETFFundCompareBrinsonFinish]: (state, action) => {
      state.fundCompare.brinsonAttribution[action.meta?.arg.portfolioId] = {
        fundAttributionBrinsonProgress: action.meta,
        fundAttributionBrinsonResult: action.payload,
      };
    },
    [fetchETFFundCompareBrinsonError]: (state, action) => {
      state.fundCompare.brinsonAttribution[action.meta?.arg.portfolioId] = {
        fundAttributionBrinsonProgress: action.error,
      };
    },
    [fetchETFFundCompareBrinsonProgress]: (state, action) => {
      state.fundCompare.brinsonAttribution[action.meta?.arg.portfolioId] = {
        fundAttributionBrinsonProgress: action.payload,
      };
    },
    [fetchFundCompareReturnInfoFinish]: (state, action) => {
      state.fundCompare.compareFundTask = fastProp("views")(action.payload);
      state.fundCompare.compareFundProgress = action?.meta;
    },
    [fetchFundCompareReturnInfoError]: (state, action) => {
      state.fundCompare.compareFundProgress = action.error;
    },
    [fetchFundCompareReturnInfoProgress]: (state, action) => {
      state.fundCompare.compareFundProgress = action.payload;
    },
    // 基金经理对比
    [fetchFundManagerCompareBaseInfo.fulfilled.type]: (state, action) => {
      state.fundManager.basicInfo = action.payload;
    },
    [fetchFundManagerCompareDailyReturn.fulfilled.type]: (state, action) => {
      state.fundManager.managerDailyReturn = action.payload;
    },
    [fetchFundManagerCompareScaleChange.fulfilled.type]: (state, action) => {
      state.fundManager.ScaleChangeInfo = action.payload;
    },
    [fetchFundManagerCompareIndustryInfo.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.fundManager.industryInfo[action.meta?.arg?.reportDate || ""] =
          action.payload;
      }
    },
    [fetchFundManagerCompareTop10PositionStocks.fulfilled.type]: (
      state,
      action
    ) => {
      if (action.payload) {
        state.fundManager.top10PositionStocks[
          action.meta?.arg?.reportDate || ""
        ] = action.payload;
      }
    },
    [getFundManagerReportDate.fulfilled.type]: (state, action) => {
      state.fundManager.reportDate = orderBy(
        identity,
        "desc"
      )(action.payload) as string[];
    },
    [getFundManagerYearReportDate.fulfilled.type]: (state, action) => {
      const data = flow(
        values,
        flatten,
        uniq,
        orderBy(identity, "desc")
      )(action.payload);
      state.fundManager.yearReportDate = flow(orderBy(identity, "desc"))(data);
    },

    // 基金公司对比
    [fetchFundCompanyCompareBaseInfo.fulfilled.type]: (state, action) => {
      state.fundCompany.basicInfo = action.payload;
    },
    [fetchFundCompanyCompareDistribution.fulfilled.type]: (state, action) => {
      const {
        section,
        distributionType,
      }: { section: string; distributionType: DISTRIBUTION_TYPE } =
        // eslint-disable-next-line no-unsafe-optional-chaining
        action.meta?.arg;
      state.fundCompany.distributions[distributionType][section] =
        action.payload;
    },
  },
});

export const {
  updateFundCompareInfo,
  updateFundManagerInfo,
  updateFundCompanyInfo,
  clearFundCompareResult,
  clearFundCompanyResult,
  clearFundManagerResult,
} = compareManageSlice.actions;
export default compareManageSlice.reducer;
