import { Button, message, Modal, Space } from "antd";
import React, { ReactNode, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { SettingOutlined } from "@ant-design/icons";
import { useMemoizedFn } from "ahooks";
import { v4 } from "uuid";
import { NewAssetsItem, subAccountNameProp } from "@/model/customer";
import AddAssetPortfolio from "../../addCustomer/components/addAssetPortfolio/index";
import style from "./index.module.less";
import { useAppDispatch } from "@/hooks/redux";
import { first, flow, isEmpty, map } from "lodash/fp";
import dayjs from "dayjs";
import { fastProp } from "@/util/opt";
import {
  clearCustomerAccountAnalysisData,
  fetchAccountInfo,
  fetchPositionAssetsList,
  positionAssetTransaction,
  updateCustomerAccountId,
} from "@/store/customerAccountAnalysis";
import { validateEntryAssetInfo } from "../../addCustomer/constant";
import {
  OPEN_FUND,
  STOCK,
} from "../../addCustomer/components/addAssetPortfolio/hooks";
import { CASH } from "@/components/portfolioCompoents/constant";

type addAssetInfoProps = {
  accountId: string;
  totalAccountId: string;
  customerName: string;
  children?: (onChangeVisible: () => void) => ReactNode;
};

export default React.memo<addAssetInfoProps>(
  ({ children, totalAccountId, customerName, accountId }) => {
    const formatMessage = useFormatMessage();
    const [visible, setVisible] = useState(false);
    const uuid = v4();
    const [assetInfo, setAssetInfo] = useState<subAccountNameProp[]>([
      {
        id: uuid,
        subAccountName: "",
      },
    ] as subAccountNameProp[]);
    const [isAddAccount, setIsAddAccount] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const onChangeVisible = useMemoizedFn(() => {
      setVisible(!visible);
    });
    const onclose = useMemoizedFn(() => {
      setVisible(false);
      setIsAddAccount(false);
      setAssetInfo([
        {
          id: uuid,
          subAccountName: "",
        },
      ] as subAccountNameProp[]);
    });
    const onSave = useMemoizedFn(() => {
      const newAssets = map((item: subAccountNameProp) => {
        const param: NewAssetsItem = {
          assetCode: item?.assetCode,
          assetType: item?.assetType,
          transactionType: item?.transactionType,
          date: dayjs(item?.investDate).format("YYYY-MM-DD"),
          dividendPolicy: item?.dividendPolicy as string,
        };
        if (item?.assetType === CASH) {
          param.amount = item.investScale;
        }
        if (item?.assetType !== CASH) {
          param.handlingFee = item.handlingFee;
        }

        if (item?.assetType === STOCK) {
          param.price = item?.transactionPrice;
          param.share = Number(item.share);
        }
        if (item?.assetType === OPEN_FUND) {
          param.share = item?.share;
        }
        return param;
      })(assetInfo);

      dispatch(
        positionAssetTransaction(
          !isAddAccount
            ? {
                accountId: isEmpty(assetInfo)
                  ? ""
                  : flow(first, fastProp("subAccountName"))(assetInfo),
                assets: newAssets as any,
              }
            : {
                accountName: flow(first, fastProp("subAccountName"))(assetInfo),
                parentAccountId: totalAccountId,
                assets: newAssets as any,
              }
        )
      )
        .unwrap()
        .then(() => {
          dispatch(clearCustomerAccountAnalysisData());
          dispatch(
            fetchPositionAssetsList({
              accountId: accountId,
              forceToRefetch: true,
            })
          );
          dispatch(updateCustomerAccountId(accountId));
          dispatch(fetchAccountInfo(totalAccountId));
          message.success(formatMessage("saveSucceeded"));
        });
      setAssetInfo([
        {
          id: uuid,
          subAccountName: "",
        },
      ] as subAccountNameProp[]);
      setVisible(false);
      setIsAddAccount(false);
    });

    return (
      <>
        {children ? (
          children(onChangeVisible)
        ) : (
          <Button
            icon={<SettingOutlined />}
            type="primary"
            onClick={onChangeVisible}
            ghost
          >
            {formatMessage("addAsset")}
          </Button>
        )}
        <Modal
          visible={visible}
          title={formatMessage("assetAdjustment")}
          width={1100}
          onCancel={onclose}
          destroyOnClose
          getContainer={false}
          footer={
            <>
              <Space>
                <Button onClick={onclose}>{formatMessage("cancel")}</Button>
                <Button
                  className={style.OperateButton}
                  type="primary"
                  onClick={onSave}
                  disabled={
                    !validateEntryAssetInfo(assetInfo) || isEmpty(assetInfo)
                  }
                >
                  {formatMessage("preservation")}
                </Button>
              </Space>
            </>
          }
        >
          <div className={style.AddAssetPortfolioPart}>
            <AddAssetPortfolio
              tableData={assetInfo}
              setTableData={setAssetInfo as any}
              isAddAsset={false}
              totalAccountId={totalAccountId}
              customerName={customerName}
              isAddAccount={isAddAccount}
              setIsAddAccount={setIsAddAccount}
            />
          </div>
        </Modal>
      </>
    );
  }
);
