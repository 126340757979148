import { SUCCESS } from "@/constant/socket";
import { AppDispatch } from "@/store";
import socket from "./createBaseSocket";
export * from "./interface";
import { EmitSocketResult, EmitSocketPayload } from "./interface";
import getLang from "../getLang";
import bindSocketListener from "./bindSocketListener";

export const socketHttp = (
  startEventName: string,
  payload: EmitSocketPayload
) => {
  return new Promise<EmitSocketResult>((resolve, reject) => {
    socket
      .emit(
        startEventName,
        {
          ...payload,
          task: {
            ...(payload?.task || {}),
            clientVersion: "webapp",
            locale: getLang(),
          },
        },
        (result) => {
          const { status, errors } = result || {};
          if (status === SUCCESS) {
            return resolve(result);
          }
          return reject({
            status,
            errors,
          });
        }
      )
      .catch(reject);
  });
};

export const startSocket = (dispatch: AppDispatch) => {
  socket.connect();
  return bindSocketListener(dispatch);
};
