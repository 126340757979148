import { v4 } from "uuid";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp, normalize } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import { Button, InputNumber, Select } from "antd";
import { Dayjs } from "dayjs";
import { concat, filter, map, set } from "lodash/fp";
import { useMemo } from "react";
import TradingDatePicker from "@/components/tradingDatePicker";
import style from "./index.module.less";
import FundStockSelect from "./fundStockSelect";

const { Option } = Select;

export const STOCK = "STOCK";
export const OPEN_FUND = "OPEN_FUND";
export const BOND = "BOND";
export const CASH = "CASH";

export const useGetChangeAssetInfo = (
  tableData: Record<string, any>[],
  setTableData: (value: any) => void
) => {
  const uuid = v4();
  const tableDataMap = useMemo(() => normalize("id")(tableData), [tableData]);
  return useMemoizedFn(
    (id: string) =>
      (type: string) =>
      (key: string) =>
      (value: number | string | Dayjs | null) => {
        const oldCurrentData = fastProp(id)(tableDataMap);
        let newCurrentData = set(key, value)(oldCurrentData);
        if (value === "PURCHASE" && newCurrentData?.assetType === STOCK)
          newCurrentData = set("share", 100)(newCurrentData);
        const newData = id
          ? map((item: Record<string, any>) => {
              return item?.id === id ? newCurrentData : item;
            })(tableData)
          : concat([{ id: uuid, assetType: type, [key]: value }] as Record<
              string,
              any
            >)(tableData);
        setTableData(
          filter((item: Record<string, any>) => item?.assetType)(newData)
        );
      }
  );
};

export const useGetFundColumns = (
  changeAssetInfo: (
    id: string
  ) => (
    type: string
  ) => (key: string) => (value: number | string | Dayjs | null) => void,
  deleteAssetInfo: (id: string) => void,
  disabledDate?: ((date: string) => boolean) | undefined
) => {
  const formatMessage = useFormatMessage();

  return [
    {
      title: formatMessage("selectedAssets"),
      dataIndex: "assetCode",
      key: "assetCode",
      width: 120,
      render: (text: string, row: Record<string, any>) => {
        return (
          <FundStockSelect
            selectorType="funds"
            value={text}
            onChange={changeAssetInfo(row?.id)(OPEN_FUND)("assetCode")}
          />
        );
      },
    },
    {
      title: formatMessage("code"),
      dataIndex: "assetCode",
      key: "assetCode",
    },
    {
      title: formatMessage("transactionType"),
      dataIndex: "transactionType",
      align: "left",
      render: (text: string, row: Record<string, any>) => {
        return (
          <Select
            value={text}
            onChange={changeAssetInfo(row?.id)(OPEN_FUND)("transactionType")}
            className={style.AssetInput}
          >
            <Option label={formatMessage("PURCHASE")} value="PURCHASE">
              {formatMessage("PURCHASE")}
            </Option>
            <Option label={formatMessage("REDEMPTION")} value="REDEMPTION">
              {formatMessage("REDEMPTION")}
            </Option>
          </Select>
        );
      },
    },
    {
      // title: (
      //   <span>
      //     {formatMessage("assetAmount")}/{formatMessage("portion")}
      //   </span>
      // ),
      title: formatMessage("portion"),
      dataIndex: "share",
      align: "left",
      render: (_: any, row: Record<string, any>) => {
        return (
          <InputNumber
            value={row?.share}
            addonAfter={formatMessage("part")}
            className={style.AssetInput}
            min={1}
            disabled={!row?.assetCode}
            onChange={changeAssetInfo(row?.id)(OPEN_FUND)("share")}
          />
        );
      },
    },
    {
      title: formatMessage("transactionDate"),
      dataIndex: "investDate",
      align: "left",
      render: (text: string, row: Record<string, any>) => {
        return (
          <TradingDatePicker
            className={style.AssetInput}
            format="YYYY-MM-DD"
            value={text}
            onChange={(date) =>
              changeAssetInfo(row?.id)(OPEN_FUND)("investDate")(date)
            }
            disabled={!row?.assetCode}
            disabledDate={disabledDate}
          />
        );
      },
    },
    {
      title: formatMessage("serviceCharge"),
      dataIndex: "handlingFee",
      align: "left",
      render: (text: number, row: Record<string, any>) => {
        return (
          <InputNumber
            value={text}
            className={style.AssetInput}
            max={100}
            min={0}
            disabled={!row?.assetCode}
            onChange={changeAssetInfo(row?.id)(OPEN_FUND)("handlingFee")}
          />
        );
      },
    },
    {
      title: formatMessage("dividendMethod"),
      dataIndex: "dividendPolicy",
      align: "left",
      render: (text: string, row: Record<string, any>) => {
        return (
          <Select
            value={text}
            onChange={changeAssetInfo(row?.id)(OPEN_FUND)("dividendPolicy")}
            className={style.AssetInput}
          >
            <Option
              label={formatMessage("dividendReinvestment")}
              value="REINVESTMENT"
            >
              {formatMessage("dividendReinvestment")}
            </Option>
            <Option label={formatMessage("dividendToCash")} value="TURNTOCASH">
              {formatMessage("dividendToCash")}
            </Option>
          </Select>
        );
      },
    },
    {
      title: formatMessage("operator"),
      dataIndex: "operate",
      key: "operate",
      render: (_: any, row: Record<string, any>) => (
        <Button type="link" onClick={() => deleteAssetInfo(row?.id)}>
          {formatMessage("delete")}
        </Button>
      ),
    },
  ];
};

export const useGetStockColumns = (
  changeAssetInfo: (
    id: string
  ) => (
    type: string
  ) => (key: string) => (value: number | string | Dayjs | null) => void,
  deleteAssetInfo: (id: string) => void,
  disabledDate?: (date: string) => boolean
) => {
  const formatMessage = useFormatMessage();
  return [
    {
      title: formatMessage("selectedAssets"),
      dataIndex: "assetCode",
      key: "assetCode",
      width: 150,
      render: (text: string, row: Record<string, any>) => {
        return (
          <FundStockSelect
            selectorType="stocks"
            value={text}
            onChange={changeAssetInfo(row?.id)(OPEN_FUND)("assetCode")}
          />
        );
      },
    },
    {
      title: formatMessage("code"),
      dataIndex: "assetCode",
      key: "assetCode",
    },
    {
      title: formatMessage("transactionType"),
      dataIndex: "transactionType",
      align: "left",
      render: (text: string, row: Record<string, any>) => {
        return (
          <Select
            value={text}
            onChange={(type: string) => {
              changeAssetInfo(row?.id)(STOCK)("transactionType")(type);
            }}
            className={style.AssetInput}
          >
            <Option label={formatMessage("PURCHASE")} value="PURCHASE">
              {formatMessage("PURCHASE")}
            </Option>
            <Option label={formatMessage("REDEMPTION")} value="REDEMPTION">
              {formatMessage("REDEMPTION")}
            </Option>
          </Select>
        );
      },
    },
    {
      title: formatMessage("sharesNumber"),
      dataIndex: "share",
      align: "left",
      render: (text: number, row: Record<string, any>) => {
        return (
          <InputNumber
            value={text}
            className={style.AssetInput}
            min={row?.transactionType === "PURCHASE" ? 100 : 1}
            step={row?.transactionType === "PURCHASE" ? 100 : 1}
            disabled={!row?.assetCode}
            onBlur={(e) => {
              const val = e.target.value;
              const value = Math.floor(Number(val) / 100) * 100;
              const data =
                row?.transactionType === "PURCHASE"
                  ? value < 100
                    ? 100
                    : value
                  : val;
              return changeAssetInfo(row?.id)(STOCK)("share")(data);
            }}
          />
        );
      },
    },
    {
      title: formatMessage("transactionPrice"),
      dataIndex: "transactionPrice",
      align: "left",
      render: (text: number, row: Record<string, any>) => {
        return (
          <InputNumber
            value={text}
            className={style.AssetInput}
            min={1}
            disabled={!row?.assetCode}
            onChange={changeAssetInfo(row?.id)(STOCK)("transactionPrice")}
          />
        );
      },
    },
    {
      title: formatMessage("transactionDate"),
      dataIndex: "investDate",
      align: "left",
      render: (text: string, row: Record<string, any>) => {
        return (
          <TradingDatePicker
            className={style.AssetInput}
            format="YYYY-MM-DD"
            value={text}
            onChange={(date) =>
              changeAssetInfo(row?.id)(STOCK)("investDate")(date)
            }
            disabled={!row?.assetCode}
            disabledDate={disabledDate}
          />
        );
      },
    },
    {
      title: <span>{formatMessage("serviceCharge")}</span>,
      dataIndex: "handlingFee",
      align: "left",
      render: (text: number, row: Record<string, any>) => {
        return (
          // <NumberInput
          //   max={100}
          //   min={0}
          //   placeholder=""
          //   className={style.AssetInput}
          //   value={text}
          //   onChange={changeAssetInfo(row?.id)(STOCK)("handlingFee")}
          // />
          <InputNumber
            value={text}
            className={style.AssetInput}
            max={100}
            min={0}
            disabled={!row?.assetCode}
            onChange={changeAssetInfo(row?.id)(STOCK)("handlingFee")}
          />
        );
      },
    },
    {
      title: formatMessage("operator"),
      dataIndex: "operate",
      key: "operate",
      render: (_: any, row: Record<string, any>) => (
        <Button type="link" onClick={() => deleteAssetInfo(row?.id)}>
          {formatMessage("delete")}
        </Button>
      ),
    },
  ];
};

export const useGetCashColumns = (
  changeAssetInfo: (
    id: string
  ) => (
    type: string
  ) => (key: string) => (value: number | string | Dayjs | null) => void,
  deleteAssetInfo: (id: string) => void,
  disabledDate?: (date: string) => boolean
) => {
  const formatMessage = useFormatMessage();
  return [
    {
      title: formatMessage("selectedAssets"),
      dataIndex: "assetCode",
      key: "assetCode",
    },
    {
      title: formatMessage("transactionType"),
      dataIndex: "transactionType",
      align: "left",
      render: (text: string, row: Record<string, any>) => {
        return (
          <Select
            value={text}
            onChange={changeAssetInfo(row?.id)(CASH)("transactionType")}
            className={style.AssetInput}
          >
            <Option
              label={formatMessage("APPEND_INVEST")}
              value="APPEND_INVEST"
            >
              {formatMessage("APPEND_INVEST")}
            </Option>
            <Option
              label={formatMessage("REDUCE_INVEST")}
              value="REDUCE_INVEST"
            >
              {formatMessage("REDUCE_INVEST")}
            </Option>
          </Select>
        );
      },
    },
    {
      title: formatMessage("amountOfMoney"),
      dataIndex: "investScale",
      align: "left",
      render: (text: number, row: Record<string, any>) => {
        return (
          <InputNumber
            value={text}
            className={style.AssetInput}
            min={1}
            disabled={!row?.assetCode}
            onChange={changeAssetInfo(row?.id)(STOCK)("investScale")}
          />
        );
      },
    },
    {
      title: formatMessage("transactionDate"),
      dataIndex: "investDate",
      align: "left",
      render: (text: string, row: Record<string, any>) => {
        return (
          <TradingDatePicker
            className={style.AssetInput}
            format="YYYY-MM-DD"
            value={text}
            onChange={(date) =>
              changeAssetInfo(row?.id)(OPEN_FUND)("investDate")(date)
            }
            disabled={!row?.assetCode}
            disabledDate={disabledDate}
          />
        );
      },
    },
    {
      title: formatMessage("operator"),
      dataIndex: "operate",
      key: "operate",
      render: (_: any, row: Record<string, any>) => (
        <Button type="link" onClick={() => deleteAssetInfo(row?.id)}>
          {formatMessage("delete")}
        </Button>
      ),
    },
  ];
};
