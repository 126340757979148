import { useFormatMessage } from "@/util/formatMessage";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchIndustryAbilityEvaluation } from "@/store/fundDetailSlice";
import { formatPercentage } from "@/util/numberFormatter";
import { fundInfoExportProp } from ".";
import { useGetAbilitySummary, useGetBarChartAndSummaryData } from "./hook";
import SecondLevelName from "../../secondLevelName";
import SimilarRanking from "../../similarRanking";
import style from "./index.module.less";
import { flow, join, map, prop } from "lodash/fp";
import { fastProp } from "@/util/opt";
import { compareLevel, CompareLevel, dispersionDegreeMap } from "./contants";
import BaseEcharts from "../../echarts/baseEcharts";
// import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";

export default React.memo<fundInfoExportProp>(({ fundCode }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const {
    sameTypeRank,
    top3IndustryInEquity,
    top3IndustryWeightRating,
    // preferenceAllocation,
    industryAllocationCompareWithSameType,
    brinsonAllocationContribute,
    factorModelAllocationContribute,
    options,
  } = useGetBarChartAndSummaryData(fundCode);
  useEffect(() => {
    dispatch(fetchIndustryAbilityEvaluation(fundCode));
  }, [dispatch, fundCode]);
  const { factorRatingsMap } = useGetAbilitySummary(fundCode);

  // const industryMap = useAppSelector(sectorCategoriesMapSelector);
  const industryRiskDetails = useAppSelector(
    prop(`fundDetail.${fundCode}.riskDetail.industryRiskDetails`)
  );

  return (
    <>
      <SecondLevelName
        name={formatMessage("industryConfigurationCapability")}
        rateNum={prop("fund_rank_allocation.fundFactorRating")(
          factorRatingsMap
        )}
      />
      <SimilarRanking
        rank={Number(sameTypeRank?.rank)}
        count={Number(sameTypeRank?.count)}
      />
      <div className={style.Summary}>
        {formatMessage("industryAbilityEvaluationSummary1", {
          top3IndustryInEquity: formatPercentage(top3IndustryInEquity),
          top3IndustryWeightRating: flow(
            fastProp(top3IndustryWeightRating),
            formatMessage
          )(dispersionDegreeMap),
          preferenceAllocation: flow(
            map(prop("industryName")),
            join("、")
          )(industryRiskDetails),
        })}
      </div>
      <div className={style.Summary}>
        {formatMessage("industryAbilityEvaluationSummary2", {
          industryAllocationCompareWithSameType: flow(
            fastProp(industryAllocationCompareWithSameType),
            formatMessage
          )(CompareLevel),
          fundBrinsonContribute: formatPercentage(
            brinsonAllocationContribute?.fundContribute
          ),
          brinsonSameTypeContribute: compareLevel(
            Number(brinsonAllocationContribute?.fundContribute),
            Number(brinsonAllocationContribute?.sameTypeContribute)
          ),
          fundFactorContribute: formatPercentage(
            factorModelAllocationContribute?.fundContribute
          ),
          factorSameTypeContribute: compareLevel(
            Number(factorModelAllocationContribute?.fundContribute),
            Number(factorModelAllocationContribute?.sameTypeContribute)
          ),
        })}
      </div>
      <BaseEcharts
        options={options}
        height={270}
        opts={{ devicePixelRatio: 4 }}
      />
    </>
  );
});
