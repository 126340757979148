import Rating from "@/components/rating";
import React, { ReactNode } from "react";
import fundManagerAvatar from "@/assets/fundManagerAvatar.svg";
import cn from "classnames";
import style from "./index.module.less";
import { map } from "lodash/fp";
import { equalNil } from "@/util/opt";

export default React.memo<{
  avatar: string;
  name: string;
  rating: number;
  tags: string[];
  category: string;
}>(({ avatar, name, rating, tags, category }) => {
  return (
    <div className={cn(style.GreyBackground, style.ManagerInfoContainer)}>
      <img src={avatar || fundManagerAvatar} className={style.ManagerImg} />
      <h3>{name}</h3>
      <Rating rating={rating} className={style.ManagerRating} />
      <div className={style.ManagerTagContainer}>
        {!equalNil(category) && (
          <span className={style.ManagerTags}>{category}</span>
        )}
        {tags &&
          map<string, ReactNode>(
            (tag) =>
              tag && (
                <span className={style.ManagerTags} key={tag}>
                  {tag}
                </span>
              )
          )(tags)}
      </div>
    </div>
  );
});
