import { RootState } from "@/store";
import { fastProp, getProp } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { last, map } from "lodash/fp";
import { benchmarksCodeMapSelector } from "@/selectors/benchmarks";
import { defaultBenchmarksTypes } from "@/model/fundDetail";
import { getDefaultBenchmarkInfo } from "@/views/fundDetail/fundDetailInfo/assetDetail/constant";

export const baseSelector = createSelector(
  (state: RootState) => state.compareManage.fundCompare,
  (fundCompare) => fundCompare
);

export const selectedReportDateSelector = createSelector(
  baseSelector,
  (fundCompare) => last(fastProp("financialReportDate")(fundCompare))
);

export const defaultBenchmarkInfoSelector = createSelector(
  benchmarksCodeMapSelector,
  (benchmarksCodeMap) => {
    const defaultInfo = getDefaultBenchmarkInfo();
    return map(
      ({ type, defaultBenchmarkCode, weight }: defaultBenchmarksTypes) => ({
        type,
        weight,
        benchmarkId: getProp(`${defaultBenchmarkCode}.id`)(
          benchmarksCodeMap
        ) as unknown as string,
      })
    )(defaultInfo);
  }
);
