import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";
import { FROM_CREATION, RangeInterface } from "@/constant/statisticRange";
import { useAppDispatch } from "@/hooks/redux";
import { fetchAccountInfo } from "@/store/customerAccountAnalysis";
import CustomStaticRange from "@/views/portfolioManage/portfolioAnalysis/components/customStaticRange";
import { Select, Space } from "antd";
import { first, flow, keys, last, map } from "lodash/fp";
import React, { useEffect, useState } from "react";
import { totalAccountProp } from "../../customerAccountAnalysis/customerSummary";
import { useGetAccountList } from "../../customerAccountAnalysis/customerSummary/hook";
import LineAndScatterChart from "../../customerAccountAnalysis/components/lineAndScatter";
import style from "./index.module.less";
import TurnRecordTable from "./turnRecordTable";
import { useGetLineCharDataSource } from "./hook";

const { Option } = Select;

export default React.memo(
  ({
    customerId,
    totalAccountId,
    customerName,
  }: {
    customerId: string;
    totalAccountId: string;
    customerName: string;
  }) => {
    const dispatch = useAppDispatch();

    const [accountId, setAccountId] = useState(customerId);
    const [activeRange, setActiveRange] = useState(FROM_CREATION);
    const [date, setDate] = useState("");

    useEffect(() => {
      if (customerId) {
        dispatch(fetchAccountInfo(customerId));
      }
    }, [dispatch, customerId]);

    const accountList = useGetAccountList({ totalAccountId, customerName });

    const { dates, series, allDates, turnoverRecord } =
      useGetLineCharDataSource(accountId, activeRange);

    const handleChartClick = (event: any) => {
      if (event?.data) setDate(event.data?.[0]);
    };

    return (
      <div className={style.WareHousePart}>
        <div>
          {" "}
          <Space>
            <Select
              value={accountId}
              onChange={(id: string) => setAccountId(id)}
              dropdownMatchSelectWidth={false}
              className={style.AccountSelect}
            >
              {map((item: totalAccountProp) => (
                <Option key={item.id} value={item.id}>
                  {item.message}
                </Option>
              ))(accountList)}
            </Select>
            <CustomStaticRange
              className={style.StatisticRange}
              value={activeRange}
              onChange={setActiveRange}
              startDate={first(allDates) || ""}
              endDate={last(allDates) || ""}
              staticRange={
                portfolioAnalysisStatisticRange as Record<
                  string,
                  RangeInterface
                >
              }
            />
          </Space>
        </div>
        <LineAndScatterChart
          dates={dates}
          series={series as any}
          height={380}
          showDataZoom
          showLegend
          events={{ click: handleChartClick }}
        />
        <TurnRecordTable
          data={turnoverRecord as any}
          activeDate={date || (flow(keys, first)(turnoverRecord) as string)}
          setDate={setDate}
        />
      </div>
    );
  }
);
