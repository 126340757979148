import React, { ReactElement, useMemo } from "react";

import { EvaluationReportRiskSummaryBody } from "@/model/fundDetail";
import { FormatMessageFunc } from "@/util/formatMessage";

import style from "../index.module.less";
import { useGetFactorEvaluationOptions } from "../hooks";
import BaseEcharts from "../../../echarts/baseEcharts";

declare type Values<T> = T[keyof T];

export const GuageChart = React.memo(
  ({
    riskItem,
    formatMessage,
    children,
    title,
  }: {
    riskItem:
      | Values<Omit<EvaluationReportRiskSummaryBody, "totalRisk">>
      | undefined;
    formatMessage: FormatMessageFunc;
    children?: ReactElement;
    title?: string;
  }) => {
    const CHART_WIDTH = useMemo(() => 240, []);

    const { riskLevel, riskScore } = riskItem || {};
    const gaugeData = useMemo(
      () => [
        {
          value: riskScore,
          title: {
            offsetCenter: ["0%", "80%"],
          },
          detail: {
            show: false,
          },
        },
      ],
      [riskScore]
    );
    const options = useGetFactorEvaluationOptions(gaugeData, CHART_WIDTH);
    return (
      <div className={style.GuageChart}>
        <div className={style.Title}>{title}</div>
        <BaseEcharts
          options={options}
          width={CHART_WIDTH}
          height={240}
          opts={{ devicePixelRatio: 4 }}
        />
        <div className={style.RiskLevel}>
          {(riskLevel && formatMessage(`${riskLevel}_RISK`)) || "--"}
        </div>
        {children}
      </div>
    );
  }
);
