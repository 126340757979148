import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Checkbox, Input, Modal, Space } from "antd";
import type { CheckboxProps } from "antd";
import { pull } from "lodash/fp";
import cn from "classnames";
import {
  SettingOutlined,
  RightOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { useFormatMessage } from "@/util/formatMessage";
import type { Factor } from "@/model/factors";
import { arrToObj, fastProp, size } from "@/util/opt";
import { useGetIndexInfo } from "./hooks";
import style from "./selectIndexDialog.module.less";
import { useCreation } from "ahooks";

const DragHandle = SortableHandle(() => <MenuOutlined />);

const SortableItem = SortableElement(
  ({
    children,
    factorId,
    disabledDrag,
    onChange,
  }: {
    children: ReactNode;
    factorId: string;
    disabledDrag?: boolean;
    onChange: CheckboxProps["onChange"];
  }) => (
    <li
      className={cn(
        style.FactorItem,
        style.Drag,
        disabledDrag && style.Disabled
      )}
      factor-id={factorId}
    >
      <Checkbox
        disabled={disabledDrag}
        onChange={onChange}
        factor-id={factorId}
        checked
      />
      &nbsp;&nbsp;
      <DragHandle />
      &nbsp;&nbsp;
      {children}
    </li>
  )
);

const SortableContainerUl = SortableContainer(
  ({ children }: { children: JSX.Element[] }) => {
    return (
      <ul className={cn(style.Factors, style.SelectedFactors)}>{children}</ul>
    );
  }
);

export default function SelectIndexDialog(
  props: SelectIndexDialogProps
): JSX.Element {
  const formatMessage = useFormatMessage();
  const { categories, categoryMap, factorMap } = useGetIndexInfo(props.type);
  const {
    selectIds,
    changeSelectedIds,
    selectIdsMap,
    changeSelectedIdsByItem,
    clearSelected,
    resetDefault,
    onSortEnd,
    onChecked,
  } = useSelected(factorMap, props.defaultFactors);
  const [visible, setVisible] = useState<boolean>();
  const onChangeVisible = useCallback(() => {
    setVisible(!visible);
    if (!visible) {
      changeSelectedIds(props.selectedIds);
    }
  }, [setVisible, visible, props.selectedIds, changeSelectedIds]);
  const onCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);
  const propsOnOk = props.onOk;
  const onOk = useCallback(() => {
    propsOnOk && propsOnOk(selectIds);
    setVisible(false);
  }, [selectIds, propsOnOk, setVisible]);
  const { onSearch, selectCategory, changeSelectCategory, showFactors } =
    useShowFactor(categoryMap);
  const allFactorSize = useCreation(
    () => size(fastProp("ALL")(categoryMap)),
    [categoryMap]
  );
  return (
    <>
      {props.children ? (
        <span onClick={onChangeVisible}>{props.children}</span>
      ) : (
        <Button icon={<SettingOutlined />} onClick={onChangeVisible}>
          {formatMessage("setIndex")}
        </Button>
      )}
      <Modal
        visible={visible}
        title={formatMessage("setIndex")}
        footer={
          <Footer setDefault={resetDefault} onOk={onOk} onCancel={onCancel} />
        }
        width={800}
        onCancel={onCancel}
        className={style.IndexModal}
      >
        <Input.Search
          onSearch={onSearch}
          placeholder={formatMessage("pleaseEnterIndexNameSearch")}
          className={style.Search}
        />
        <div className={style.IndexBody}>
          <div className={style.Left}>
            <ul className={style.Category}>
              {categories.map((v) => (
                <li
                  onClick={() => changeSelectCategory(v.id)}
                  key={v.id}
                  className={cn(
                    style.CategoryItem,
                    v.id === selectCategory && style.CategorySelected
                  )}
                >
                  {v.name}
                  &nbsp;&nbsp;
                  <RightOutlined />
                </li>
              ))}
            </ul>
            <ul className={style.Factors}>
              {showFactors.map((v) => (
                <li
                  key={v.id}
                  factor-id={v.id}
                  onClick={v.disabled ? undefined : changeSelectedIdsByItem}
                  className={cn(style.FactorItem, v.disabled && style.Disabled)}
                >
                  <Checkbox
                    disabled={v.disabled}
                    onChange={onChecked}
                    factor-id={v.id}
                    checked={selectIdsMap[v.id]}
                  />
                  &nbsp;&nbsp;
                  {v.name}
                </li>
              ))}
            </ul>
          </div>
          <div className={style.Right}>
            <div className={style.Head}>
              <Space>
                {formatMessage("selectedIndex")}
                <span>{`(${size(selectIds)}/${allFactorSize})`}</span>
              </Space>
              <Button size="small" onClick={clearSelected}>
                {formatMessage("clear")}
              </Button>
            </div>
            <SortableContainerUl useDragHandle onSortEnd={onSortEnd}>
              {selectIds.map((id, index) => (
                <SortableItem
                  key={`item-${id}`}
                  index={index}
                  factorId={id}
                  onChange={onChecked}
                  disabled={factorMap[id]?.disabled}
                  disabledDrag={factorMap[id]?.disabled}
                >
                  {factorMap[id]?.name}
                </SortableItem>
              ))}
            </SortableContainerUl>
          </div>
        </div>
      </Modal>
    </>
  );
}

function Footer(props: FooterProps) {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Button onClick={props.setDefault}>
        {formatMessage("resetDefault")}
      </Button>
      <Button onClick={props.onCancel}>{formatMessage("cancel")}</Button>
      <Button onClick={props.onOk} type="primary">
        {formatMessage("ok")}
      </Button>
    </>
  );
}

function useShowFactor(categoryMap: Record<string, Factor[]>) {
  const [search, onSearch] = useState("");
  const [selectCategory, changeSelectCategory] = useState<string>("ALL");
  return useMemo(() => {
    const showFactors = categoryMap[selectCategory];
    return {
      search,
      onSearch,
      selectCategory,
      changeSelectCategory,
      showFactors: showFactors?.filter((v) => v.name?.includes(search)),
    };
  }, [categoryMap, search, selectCategory]);
}

function useSelected(
  factorsMap: Record<string, Factor>,
  defaultFactors: string[]
) {
  const [selectIds, changeSelectedIds] = useState<string[]>([]);
  return {
    selectIds,
    changeSelectedIds,
    resetDefault: useCallback(() => {
      changeSelectedIds(defaultFactors);
    }, [changeSelectedIds, defaultFactors]),
    onChecked: useCallback(
      (e: { target: { "factor-id"?: string; checked: boolean } }) => {
        if (e.target["factor-id"]) {
          if (e.target.checked) {
            changeSelectedIds([...selectIds, e.target["factor-id"]]);
          } else {
            changeSelectedIds(pull(e.target["factor-id"])(selectIds));
          }
        }
      },
      [changeSelectedIds, selectIds]
    ),
    changeSelectedIdsByItem: useCallback(
      (e: { target: any }) => {
        const factorId = e.target.getAttribute("factor-id");
        if (factorId) {
          if (!selectIds.includes(factorId)) {
            changeSelectedIds([...selectIds, factorId]);
          } else {
            changeSelectedIds(pull(factorId)(selectIds));
          }
        }
      },
      [changeSelectedIds, selectIds]
    ),
    selectIdsMap: arrToObj(selectIds),
    clearSelected: useCallback(() => {
      changeSelectedIds(selectIds.filter((v) => factorsMap[v].disabled));
    }, [changeSelectedIds, selectIds, factorsMap]),
    onSortEnd: useCallback(
      ({ newIndex, oldIndex }: { newIndex: number; oldIndex: number }) => {
        changeSelectedIds(moveItem(selectIds, oldIndex, newIndex));
      },
      [selectIds, changeSelectedIds]
    ),
  };
}

function moveItem<T>(arr: T[], from: number, to: number) {
  const [min, max] = from > to ? [to, from] : [from, to];
  const startArr = arr.slice(0, min);
  const endArr = arr.slice(max + 1);
  const movedItem = arr[from];
  const moveArr =
    from > to
      ? [movedItem, ...arr.slice(min, max)]
      : [...arr.slice(min + 1, max + 1), movedItem];
  return [...startArr, ...moveArr, ...endArr];
}
export interface SelectIndexDialogProps {
  children?: React.Component;
  selectedIds: string[];
  onOk?: (ids: string[]) => void;
  defaultFactors: string[];
  type: "fund" | "portfolio" | "preferredFund" | "comPortfolio";
}

interface FooterProps {
  setDefault: () => void;
  onCancel: () => void;
  onOk: () => void;
}
