import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { find, forEach, invert, last, toArray } from "lodash/fp";
import { useCreation } from "ahooks";

import { useFormatMessage } from "@/util/formatMessage";
import { DistributionChangeChart } from "@/base-components/charts";
import { getAssetTypeTrend } from "@/api/aum";
import { useRequest } from "@/hooks/request";
import { AssetType, AssetTypeTrend } from "@/model/aum";
import { SeriesOption } from "@/base-components/charts/distributionChangeChart/type";
import { mapIndexed } from "@/util/opt";
import AssertTypePieChart from "@/base-components/charts/assertTypePieChart";
import {
  categoryTypeIds,
  categoryTypeMap,
} from "@/views/portfolioManage/fromAboveToBelow/constant";

import style from "./index.module.less";
import SubTitle from "../../components/subTitle";

export default React.memo<{ id: string; assetPortfolioId: string }>(
  ({ assetPortfolioId }) => {
    const formatMessage = useFormatMessage();
    const [selectedDate, setSelectedDate] = useState("");
    const { loading, data, request } = useRequest<string, AssetTypeTrend>(
      getAssetTypeTrend
    );

    useEffect(() => {
      if (assetPortfolioId) request(assetPortfolioId);
    }, [assetPortfolioId, request]);

    const {
      series,
      dates,
    }: {
      series: SeriesOption[];
      dates: string[];
    } = useCreation(() => {
      let series: SeriesOption[] = [];
      const dates: string[] = [];
      const tmpSeriesObj: Record<string, any> = {};
      const categoryIdToType = invert(categoryTypeIds);
      forEach((assetType: AssetType) => {
        dates.push(assetType.tradingDate);
        mapIndexed((value: number, key: string) => {
          if (!tmpSeriesObj[key]) {
            tmpSeriesObj[key] = {
              name: (
                categoryTypeMap?.[categoryIdToType?.[key]] as {
                  label: string;
                  value: string;
                }
              )?.label,
              data: [[assetType.tradingDate, value]],
            };
          } else {
            tmpSeriesObj[key].data.push([assetType.tradingDate, value]);
          }
        })(assetType.weight);
      })(data?.assetTypeList);

      series = toArray(tmpSeriesObj);
      setSelectedDate(last(dates) || "");
      return { series, dates };
    }, [data?.assetTypeList]);

    const pieChartData = useCreation(
      () =>
        mapIndexed((serie: SeriesOption) => ({
          name: serie.name,
          value: find((dateArr: any) => dateArr[0] === selectedDate)(
            serie.data
          )?.[1],
        }))(series),
      [selectedDate, series]
    );

    const handleChartClick = (event: any) => {
      setSelectedDate(event?.data?.[0] || last(dates));
    };

    return (
      <div>
        <SubTitle title={formatMessage("fundCategoryDistribution")} />
        <Spin spinning={loading}>
          <div className={style.ChartContainer}>
            <div className={style.Chart}>
              <DistributionChangeChart
                series={series}
                dates={dates}
                height={400}
                showDataZoom={false}
                boundaryGap={true}
                onEvents={{ click: handleChartClick }}
              />
            </div>
            <div className={style.Content}>
              <AssertTypePieChart
                pieChartData={pieChartData}
                selectedDate={selectedDate}
                title={formatMessage("fundCategoryDistribution")}
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
);
