import { fastProp, getProp, mapIndexed, normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { fundBasicInfoSelector, fundBasicTitleSelector } from "../../../../../mapper";
import { first, prop, map, last, defaultTo, max, concat } from "lodash/fp";
import { RootState } from "@/store";
import { getDefaultBenchmarkInfo } from "../../../../constant";
import { getStatisticsRangeStartDate } from "@/constant/statisticRangeCalculator/helper";
import { RECENT_THREE_MONTH } from "@/constant/statisticRange";
import {
  getNextTradingDate,
  getPreviousTradingDate,
} from "@/util/processedDates";
import {
  benchmarksCodeMapSelector,
  benchmarkMapSelector,
} from "@/selectors/benchmarks";
import getMaxDrawdown from "@/util/quant/maxDrawdown";
import getSharpeRatio from "@/util/quant/sharpeRatio";
import { defaultBenchmarksTypes } from "@/model/fundDetail";
import {
  getSelectablePeriods,
  getDetailTableData,
  getETFDetailTableData
} from "./brinson-result-mapper/utils";
import { getInitalResultSelector } from "./brinson-result-mapper/index";
import {
  latestNetValueSelector,
  beforeNetValueSelector,
} from "@/views/fundDetail/fundDetailInfo/mapper";
import {
  fundAttributionBrinsonProgressSelector,
  fundAttributionBrinsonResultSelector,
  fundAttributionBrinsonProgressArgSelector,
  fundDetailIsETFSelector,
} from "./common-selector";
import { defaultSectorId } from "@/constant/sectors";

export const defaultBenchmarkInfoSelector = createSelector(
  benchmarksCodeMapSelector,
  (benchmarksCodeMap) => {
    const defaultInfo = getDefaultBenchmarkInfo();
    return map(
      ({ type, defaultBenchmarkCode, weight }: defaultBenchmarksTypes) => ({
        type,
        weight,
        benchmarkId: getProp(`${defaultBenchmarkCode}.id`)(benchmarksCodeMap),
      })
    )(defaultInfo);
  }
);
export const benchmarkInfoSelector = createSelector(
  fundBasicInfoSelector,
  defaultBenchmarkInfoSelector,
  (fundBasicInfo, defaultBenchmarkInfo) =>
    fastProp("benchmarks")(fundBasicInfo) || defaultBenchmarkInfo
);
export const sectorCategoryIdSelector = createSelector(
  fundBasicInfoSelector,
  (fundBasicInfo) =>
    fastProp(`sectorCategoryId`)(fundBasicInfo) || defaultSectorId
);

export const periodSelector = createSelector(
  fundBasicInfoSelector,
  (fundBasicInfo) => fastProp("period")(fundBasicInfo)
);
export const brinsonSummaryResultSelector = createSelector(
  fundAttributionBrinsonProgressArgSelector,
  getInitalResultSelector,
  (state: RootState) => state.entities.riskFreeRate,
  (state: RootState) => state.tradingDates.tradingDateList,
  (state: RootState) => state.tradingDates.processedTradingDates,
  fundDetailIsETFSelector,
  (meta, result, riskFreeRates, tradingDates, processedTradingDates,isETF) => {
    // 基金净值累计业绩
    const cumulativePortfolioAttributions = fastProp(
      isETF ? "cumulativePortfolioReturns" : "cumulativePortfolioAttributions"
    )(result);
    const dailyPortfolioReturns = fastProp(isETF ? "dailyPortfolioActualReturns" : "dailyPortfolioReturns")(result);
    // A股+非A股+权益类的基准净值累计业绩
    const cumulativeBenchmarkAttributions = fastProp(
      isETF ? "cumulativeBenchmarkReturns": "cumulativeBenchmarkAttributions"
    )(result);

    // 超额业绩 = 基金净值业绩 - 基准净值业绩
    const cumulativeActiveAttributions = fastProp(
      "cumulativeActiveAttributions"
    )(result);

    const dates = prop("dates")(result);
    const cumulativeAShareAllocationAttributions = fastProp(
      isETF ? "cumulativeAllocationAttributions" : "cumulativeAShareAllocationAttributions"
    )(result);
    const cumulativeAShareSelectionAttributions = fastProp(
      isETF ? "cumulativeSelectionAttributions" : "cumulativeAShareSelectionAttributions"
    )(result);
    const cumulativeAShareTimingAttributions = fastProp(
      isETF ? "cumulativeTimingOrInteractionAttributions" : "cumulativeAShareTimingAttributions"
    )(result);
    const firstDate = getPreviousTradingDate(
      tradingDates,
      processedTradingDates,
      first(dates) as string
    );
    const firstChartData = {
      date: firstDate,
      fundAttribution: 0,
      benchmarkAttribution: 0,
      allocationAttribution: 0,
      selectionAttribution: 0,
      timingAttribution: 0,
      activeAttribution: 0,
    };
    const chartData = mapIndexed((date: string, index: number) => ({
      date,
      fundAttribution: cumulativePortfolioAttributions[index],
      benchmarkAttribution: cumulativeBenchmarkAttributions[index],
      allocationAttribution: cumulativeAShareAllocationAttributions[index],
      selectionAttribution: cumulativeAShareSelectionAttributions[index],
      timingAttribution: cumulativeAShareTimingAttributions[index],
      // 超额业绩
      activeAttribution: cumulativeActiveAttributions[index],
    }))(dates);
    const names = [
      "benchmarkAttribution",
      "fundAttribution",
      "activeAttribution",
      "allocationAttribution",
      "selectionAttribution",
      "timingAttribution",
    ];
    const tableData = [
      {
        activeAttribution: last(cumulativeActiveAttributions),
        maxDrawdown: getMaxDrawdown(concat(0)(dailyPortfolioReturns)),
        sharpeRatio: getSharpeRatio(
          fastProp("dailyPortfolioReturns")(result),
          map((date: string) => fastProp(date)(riskFreeRates))(dates || [])
        ),
        informationRatio: fastProp("informationRatio")(result),
        trackDeviation: fastProp("trackDeviation")(result),
        cumulativeBenchmarkReturn: last(cumulativeBenchmarkAttributions),
        fundAttribution: last(cumulativePortfolioAttributions),
        cumulativeAllocationReturn: last(
          cumulativeAShareAllocationAttributions
        ),
        cumulativeSelectionReturn: last(cumulativeAShareSelectionAttributions),
        cumulativeTimingOrInteractionAttribution: 0,
      },
    ];
    return {
      from: fastProp("from")(meta),
      to: fastProp("to")(meta),
      dates: concat(firstDate)(dates),
      tableData: tableData,
      benchmarkName: "benchmarkAttribution",
      chartData: normalize("date")(concat(firstChartData)(chartData)),
      names,
    };
  }
);
export const brinsonDetailTableDataSelector = createSelector(
  getInitalResultSelector,
  fundAttributionBrinsonProgressArgSelector,
  periodSelector,
  fundDetailIsETFSelector,
  (result, meta, period,isETF) => {
    const selectablePeriods = getSelectablePeriods(
      prop("from")(meta),
      prop("to")(meta)
    );
    const defaultPeriod = last(selectablePeriods);
    const processedPeriod = defaultTo(defaultPeriod)(period);

    const dailyPortfolioReturns =
      fastProp("dailyPortfolioReturns")(result) || [];
    const dailyBenchmarkReturns =
      fastProp("dailyBenchmarkReturns")(result) || [];
    const dailyActiveReturns = fastProp("dailyActiveReturns")(result) || [];

    const dailyAllocationAttributions =
      fastProp("dailyAllocationAttributions")(result) || [];
    const dailyAShareAttributions =
      fastProp("dailyAShareAttributions")(result) || [];
    const dailyNonAShareAttributions =
      fastProp("dailyNonAShareAttributions")(result) || [];
    const dailyNonEquityAttributions =
      fastProp("dailyNonEquityAttributions")(result) || [];
    const dailyThirdFloorOtherAttributionReturns =
      fastProp("dailyThirdFloorOtherAttributionReturns")(result) || [];

    const dailyAShareAllocationAttributions =
      fastProp("dailyAShareAllocationAttributions")(result) || [];
    const dailyAShareSelectionAttributions =
      fastProp("dailyAShareSelectionAttributions")(result) || [];
    const dailyAShareTimingAttributions =
      fastProp("dailyAShareTimingAttributions")(result) || [];
    const dailyAShareOtherReturns =
      fastProp("dailyAShareOtherReturns")(result) || [];

    const dates = prop("dates")(result);
    // 行业业绩
    const subAttributions = fastProp("subAttributions")(result) || [];
    return {
      period: processedPeriod,
      selectablePeriods: selectablePeriods,
      data:
      isETF ? getETFDetailTableData(
            fastProp("dailyPortfolioActualReturns")(result),
            dailyPortfolioReturns,
            dailyBenchmarkReturns,
            fastProp("dailyBenchmarkPositionReturns")(result),
            dailyActiveReturns,
            fastProp("dailyPositionActiveReturns")(result),
            dailyAllocationAttributions,
            fastProp("dailySelectionAttributions")(result),
            subAttributions,
            processedPeriod
      )(dates) :
       getDetailTableData(
        dailyPortfolioReturns,
        dailyBenchmarkReturns,
        dailyActiveReturns,

        // 资产配置业绩
        dailyAllocationAttributions,
        dailyAShareAttributions,
        dailyNonAShareAttributions,
        dailyNonEquityAttributions,
        dailyThirdFloorOtherAttributionReturns,

        dailyAShareAllocationAttributions,
        dailyAShareSelectionAttributions,
        dailyAShareTimingAttributions,
        dailyAShareOtherReturns,

        subAttributions,
        processedPeriod
      )(dates),
    };
  }
);

export const detailBarChartDataSelector = createSelector(
  fundAttributionBrinsonProgressArgSelector,
  getInitalResultSelector,
  fundDetailIsETFSelector,
  (meta, result, isETF) => {
    const cumulativePortfolioAttributions = fastProp(
      "cumulativePortfolioAttributions"
    )(result);
    const cumulativeBenchmarkAttributions = fastProp(
      "cumulativeBenchmarkAttributions"
    )(result);
    const cumulativeActiveAttributions = fastProp(
      "cumulativeActiveAttributions"
    )(result);

    const cumulativeAllocationAttributions = fastProp(
      "cumulativeAllocationAttributions"
    )(result);
    const cumulativeThirdFloorOtherAttributionReturns = fastProp(
      "cumulativeThirdFloorOtherAttributionReturns"
    )(result);
    const cumulativeNonAShareAttributions = fastProp(
      "cumulativeNonAShareAttributions"
    )(result);
    const cumulativeNonEquityAttributions = fastProp(
      "cumulativeNonEquityAttributions"
    )(result);
    const cumulativeAShareAttributions = fastProp(
      "cumulativeAShareAttributions"
    )(result);
    const cumulativeAShareAllocationAttributions = fastProp(
      "cumulativeAShareAllocationAttributions"
    )(result);
    const cumulativeAShareSelectionAttributions = fastProp(
      "cumulativeAShareSelectionAttributions"
    )(result);
    const cumulativeAShareTimingAttributions = fastProp(
      "cumulativeAShareTimingAttributions"
    )(result);
    const cumulativeForthFloorOtherAttributions = fastProp(
      "cumulativeForthFloorOtherAttributions"
    )(result);
    const selectionAttribution = fastProp("cumulativeSelectionAttribution")(result);
    const timingOrInteractionAttribution = fastProp("cumulativeTimingOrInteractionAttribution")(result);
    const cumulativeFundAttributions = fastProp("cumulativeFundAttributions")(result);
    const cumulativeBenchmarkReturn = fastProp("cumulativeBenchmarkReturn")(result);
    return {
      startDate: fastProp("from")(meta),
      endDate: fastProp("to")(meta),
      portfolioAttribution: isETF ? last(cumulativeFundAttributions) :last(cumulativePortfolioAttributions),
      benchmarkAttribution: isETF ? cumulativeBenchmarkReturn: last(cumulativeBenchmarkAttributions),
      activeAttribution: last(cumulativeActiveAttributions),

      allocationAttribution: last(cumulativeAllocationAttributions),
      nonAShareAttribution: last(cumulativeNonAShareAttributions),
      nonEquityAttribution: last(cumulativeNonEquityAttributions),
      otherAttribution: last(cumulativeThirdFloorOtherAttributionReturns),
      aShareAttribution: last(cumulativeAShareAttributions),

      aShareAllocationAttribution:isETF ? last(cumulativeAllocationAttributions): last(cumulativeAShareAllocationAttributions) ,
      aShareSelectionAttribution:isETF ? selectionAttribution : last(cumulativeAShareSelectionAttributions),
      aShareTimingAttribution: isETF ? timingOrInteractionAttribution : last(cumulativeAShareTimingAttributions),
      aShareOtherAttribution: isETF ? fastProp("otherAttribution")(result): last(cumulativeForthFloorOtherAttributions),
    };
  }
);
const configSelector = createSelector(
  (state: RootState) => state.tradingDates.tradingDateList,
  (state: RootState) => state.tradingDates.processedTradingDates,
  beforeNetValueSelector,
  latestNetValueSelector,
  fundBasicInfoSelector,
  (tradingDates, processedTradingDates, beforeNetValue, latestNetValue,fundBasicInfo) => {
    const ETFTimeRange = prop(`fundDetailIndustryTrendDate`)(fundBasicInfo)
    const isETF = fastProp("isETF")(ETFTimeRange)
    const dates = fastProp("dates")(ETFTimeRange)
    const from = isETF ? first(dates) : fastProp("tradingDate")(beforeNetValue);
    const to = isETF ? last(dates) : fastProp("tradingDate")(latestNetValue);
    return {
      start: getStatisticsRangeStartDate(RECENT_THREE_MONTH, to as string),
      end: to,
      maxDate: to,
      minDate: getNextTradingDate(tradingDates, processedTradingDates, from),
    };
  }
);

const attributeDateSelector = createSelector(
  configSelector,
  fundBasicInfoSelector,
  (config, fundBasicInfo) => {
    const { minDate, start, end } = config;
    const date = fastProp("attributionDate")(fundBasicInfo);
    const attributionDate = date || [max([start, minDate]), end];
    return attributionDate;
  }
);
export default createSelector(
  fundAttributionBrinsonProgressSelector,
  fundAttributionBrinsonResultSelector,
  benchmarkMapSelector,
  benchmarkInfoSelector,
  sectorCategoryIdSelector,
  attributeDateSelector,
  configSelector,
  brinsonSummaryResultSelector,
  brinsonDetailTableDataSelector,
  detailBarChartDataSelector,
  fundBasicTitleSelector,
  (
    fundAttributionBrinsonProgress,
    fundAttributionBrinsonResult,
    benchmarkMap,
    benchmarks,
    sectorCategoryId,
    attributionDate,
    config,
    brinsonSummaryResult,
    brinsonDetailTableResult,
    detailBarChartData,
    fundBasicTitle
  ) => ({
    fundAttributionBrinsonProgress,
    fundAttributionBrinsonResult,
    benchmarkMap,
    benchmarks,
    sectorCategoryId,
    attributionDate,
    config,
    brinsonSummaryResult,
    brinsonDetailTableResult,
    detailBarChartData,
    fundBasicTitle,
  })
);
