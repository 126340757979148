import ColorNumber from "@/components/colorNumber";
import { fastProp, mapIndexed, normalize } from "@/util/opt";
import { Card, Select, Space, Tag } from "antd";
import cn from "classnames";
import { map, prop } from "lodash/fp";
import React, { useCallback, useEffect, useMemo } from "react";
import TradeValueSection from "../components/tradeValueSection";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";
import AccountCard from "../components/accountCard";
import { childrenAccountBody } from "@/model/customer";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { customerAnalysisSelector } from "../selectors";
import {
  fetchAccountInfo,
  fetchRangeIncomeInfo,
  updateCustomerAccountId,
} from "@/store/customerAccountAnalysis";
import { isEmpty } from "lodash/fp";
import wallet from "@/assets/customerAnalysisIcons/wallet.png";
import totalAsset from "@/assets/customerAnalysisIcons/totalAsset.png";
import netAsset from "@/assets/customerAnalysisIcons/netAsset.png";
import totalLiabilities from "@/assets/customerAnalysisIcons/totalLiabilities.png";
import LoadingComponent from "@/components/LoadingComponent";
import { useGetAccountList } from "./hook";

const { Option } = Select;

export type totalAccountProp = {
  id: string;
  message: string;
};

export default React.memo(
  ({
    customerId,
    customerName,
    totalAccountId,
    customerTags,
  }: {
    customerId: string;
    customerName: string;
    totalAccountId: string;
    customerTags: string[];
  }) => {
    const formatMessage = useFormatMessage();

    const customerAnalysis = useAppSelector((state: RootState) =>
      customerAnalysisSelector(state, customerId)
    );
    const totalAccountInfo = useAppSelector((state: RootState) =>
      customerAnalysisSelector(state, totalAccountId)
    );
    const allCustomerTag = useAppSelector((state: RootState) =>
      prop("createCustomer.allCustomerTag")(state)
    );
    const customerTagMap = useMemo(
      () => normalize("id")(allCustomerTag),
      [allCustomerTag]
    );
    const accountInfo = fastProp("accountInfo")(totalAccountInfo);

    const totalSubCustomerDetails = fastProp("subCustomerDetails")(accountInfo);

    const customerAccountInfo = fastProp("accountInfo")(customerAnalysis);

    const subCustomerDetails =
      fastProp("subCustomerDetails")(customerAccountInfo);

    const rangeIncome = fastProp("rangeIncome")(customerAnalysis);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (customerId) {
        dispatch(fetchAccountInfo(customerId));
      }
    }, [dispatch, customerId]);

    useEffect(() => {
      dispatch(fetchRangeIncomeInfo(customerId));
    }, [dispatch, customerId]);

    const accountList = useGetAccountList({ totalAccountId, customerName });

    const changeAccountType = useCallback(
      (value: string) => dispatch(updateCustomerAccountId(value)),
      [dispatch]
    );

    const totalSubCustomerDetailsMap = useMemo(
      () => normalize("accountId")(totalSubCustomerDetails),
      [totalSubCustomerDetails]
    );

    const totalLiability = useMemo(
      () =>
        fastProp("totalAsset")(customerAccountInfo) -
        fastProp("allAssetScale")(customerAccountInfo),
      [customerAccountInfo]
    );

    // const { goToCustomerHeldAssetManage } = useContext(
    //   PlatformNavigationContext
    // );

    return (
      <LoadingComponent actions={fetchAccountInfo}>
        <Card
          title={
            <Space>
              <h3>{customerName}</h3>
              {map((id: string) =>
                fastProp(id)(customerTagMap) ? (
                  <Tag color="blue" key={id}>
                    {prop(`${id}.name`)(customerTagMap)}
                  </Tag>
                ) : null
              )(customerTags)}
            </Space>
          }
          size="small"
          extra={
            <div>
              {/* <a
                onClick={() => {
                  goToCustomerHeldAssetManage({
                    customerId,
                    customerName,
                    totalAccountId,
                  });
                }}
              >
                {formatMessage("customerHeldAssetManage")}
              </a> */}
              <Select
                value={customerId}
                onChange={changeAccountType}
                dropdownMatchSelectWidth={false}
                className={style.AccountSelect}
              >
                {map((item: totalAccountProp) => (
                  <Option key={item.id} value={item.id}>
                    {item.message}
                  </Option>
                ))(accountList)}
              </Select>
            </div>
          }
        >
          <div className={style.CardContent}>
            {!fastProp(customerId)(totalSubCustomerDetailsMap) ? (
              <div className={style.CardLeft}>
                <div className={style.NumberInfo}>
                  <p>
                    <Space size={5} align="center">
                      <img src={wallet} />
                      <span>
                        {formatMessage("totalAssets")}({formatMessage("YUAN")})
                      </span>
                    </Space>
                  </p>
                  <ColorNumber
                    value={fastProp("totalAsset")(customerAccountInfo)}
                    formatter="parseToThousandth"
                    className={style.TotalAssets}
                  />
                </div>
                <div className={style.NumberInfo}>
                  <Space size={5} align="center">
                    <img src={wallet} />
                    <p>
                      {formatMessage("assets")}({formatMessage("YUAN")})
                    </p>
                  </Space>
                  <ColorNumber
                    value={fastProp("allAssetScale")(customerAccountInfo)}
                    formatter="parseToThousandth"
                    className={style.TotalLiabilities}
                  />
                </div>
                <div className={style.NumberInfo}>
                  <Space size={5} align="center">
                    <img src={wallet} />
                    <p>
                      {formatMessage("cash")}({formatMessage("YUAN")})
                    </p>
                  </Space>
                  <ColorNumber
                    value={totalLiability}
                    formatter="parseToThousandth"
                    className={style.TotalLiabilities}
                  />
                </div>
              </div>
            ) : (
              <div className={style.subInfo}>
                <div className={style.subInfoCard} key={1}>
                  <div className={style.subInfoName}>
                    <img src={totalAsset} />
                    <span>
                      {formatMessage("totalAssets")}({formatMessage("YUAN")})
                    </span>
                  </div>
                  <div className={style.subInfoNumber}>
                    <ColorNumber
                      value={fastProp("totalAsset")(customerAccountInfo)}
                      formatter="parseToThousandth"
                      className={cn(style.subTotalAssets, style.Block)}
                    />
                  </div>
                </div>
                <div className={style.subInfoCard} key={2}>
                  <div className={style.subInfoName}>
                    <img src={netAsset} />
                    <span>
                      {formatMessage("assets")}({formatMessage("YUAN")})
                    </span>
                  </div>
                  <div className={style.subInfoNumber}>
                    <ColorNumber
                      value={fastProp("allAssetScale")(customerAccountInfo)}
                      formatter="parseToThousandth"
                      className={cn(style.subTotalAssets, style.Red)}
                    />
                  </div>
                </div>
                <div className={style.subInfoCard} key={3}>
                  <div className={style.subInfoName}>
                    <img src={totalLiabilities} />
                    <span>
                      {formatMessage("cash")}({formatMessage("YUAN")})
                    </span>
                  </div>
                  <div className={style.subInfoNumber}>
                    <ColorNumber
                      value={totalLiability}
                      formatter="parseToThousandth"
                      className={cn(style.subTotalAssets, style.Green)}
                    />
                  </div>
                </div>
              </div>
            )}
            {!isEmpty(subCustomerDetails) && (
              <div className={style.CardCenter}>
                {mapIndexed((item: childrenAccountBody, index: number) => {
                  return <AccountCard childrenInfo={item} index={index} />;
                })(subCustomerDetails || [])}
              </div>
            )}
            <div className={style.CardRight}>
              <p>{formatMessage("FROM_LATELY_TRADING_DATE")}</p>
              <ColorNumber
                value={fastProp("RECENT_TRADING_DAY")(rangeIncome)}
                // formatter="percentage"
                formatter="fixedNumber"
                className={style.TradeNumber}
              />
              <div className={style.TradeValueSection}>
                <TradeValueSection
                  customerId={customerId}
                  formatter="fixedNumber"
                />
              </div>
            </div>
          </div>
        </Card>
      </LoadingComponent>
    );
  }
);
