import { ChartProps } from "@/base-components/charts/yieldTrendChart/type";
import { CHART_COLOR } from "@/components/fundInfoExport/constants";
import { formatter, getFactorsFormatterName } from "@/constant/factorFormatter";
import { useAppSelector } from "@/hooks/redux";
import {
  ComprehensivePerformanceParam,
  EvaluationReportAbilitySummaryBody,
  EvaluationReportInfoBody,
  IndustryAbilityEvaluationParam,
} from "@/model/fundDetail";
import { getLastDateOfYear } from "@/util/chart";
import { useFormatMessage } from "@/util/formatMessage";
import { formatPercentage } from "@/util/numberFormatter";
import { fastProp, normalize, zipWithMinus } from "@/util/opt";
import cumulativeReturns from "@/util/quant/cumulativeReturns";
import dayjs from "dayjs";
import { BarSeriesOption, EChartsOption } from "echarts";
import { flow, keys, map, prop, set } from "lodash/fp";
import { useMemo } from "react";
import { useFundReportContext } from "../../../context";

export const useGetLineChartData = (fundCode: string) => {
  // const fundCodeMap = useAppSelector(fundCodeMapSelector);
  const contextValue = useFundReportContext();
  const fundMap = contextValue?.fundMap;
  const fundName = prop(`${fundCode}.name`)(fundMap);
  const fundEvaluationReportInfo: EvaluationReportInfoBody = useAppSelector(
    prop(`fundDetail.${fundCode}.evaluationReportInfo`)
  );
  const returns = fastProp("returns")(fundEvaluationReportInfo);
  const {
    fundReturns: fundDailyReturns,
    dates,
    benchmarkReturns: benchmarkDailyReturns,
  } = returns || {};
  const formatMessage = useFormatMessage();

  const series = useMemo(() => {
    const fundReturns = cumulativeReturns(set(0, 0)(fundDailyReturns), true);
    const benchmarkReturns = cumulativeReturns(
      set(0, 0)(benchmarkDailyReturns),
      true
    );
    return [
      {
        name: formatMessage("theFund"),
        color: CHART_COLOR[0],
        dates,
        returns: fundReturns,
      },
      {
        name: formatMessage("csIndex800"),
        color: CHART_COLOR[1],
        dates,
        returns: benchmarkReturns,
      },
      {
        name: formatMessage("returnExcess"),
        color: CHART_COLOR[2],
        dates,
        returns: zipWithMinus(fundReturns, benchmarkReturns),
      },
    ];
  }, [benchmarkDailyReturns, dates, formatMessage, fundDailyReturns]);

  const yearOfDates = useMemo(() => getLastDateOfYear(dates), [dates]);

  const chartOptions = useMemo<Partial<ChartProps>>(
    () => ({
      legendOptions: {
        left: "center",
        bottom: 36,
        top: "auto",
        itemGap: 30,
        padding: 0,
        textStyle: { color: "#666666", fontSize: 14 },
      },
      gridOptions: { left: 0, top: 30, bottom: 65, right: 20 },
      xAxisOptions: {
        boundaryGap: false,
        axisLabel: {
          color: "#666666",
          formatter: (v: string) => dayjs(v).format("YYYY年"),
          hideOverlap: false,
          interval: (_: number, value: string) => fastProp(value)(yearOfDates),
          fontFamily: "DINAlternate",
        },
        axisTick: { show: true },
        axisLine: { onZero: true },
      },
      yAxisOptions: {
        splitLine: { show: false },
        axisLabel: {
          fontWeight: "bold",
          color: "#222222",
          fontFamily: "DINAlternate",
        },
      },
    }),
    [yearOfDates]
  );

  return { dates, chartOptions, fundName, series };
};

const factorNameMap = {
  avg_return_3y_ann: "annualizedIncome",
  csi800_excess_rtn_win_ratio_3y: "marketSuccessRate",
  MY_calmar_ratio_3y_2: "karmaRatio",
  sharpe_ratio_3y_ann: "sharpeRatio",
};
export const useComprehenPerformance = (fundCode: string) => {
  const comPerformance: ComprehensivePerformanceParam = useAppSelector(
    prop(`fundDetail.${fundCode}.comprehensivePerformance`)
  );
  const formatMessage = useFormatMessage();

  const { factorPerformance, summary } = comPerformance || {};
  const factorPerformanceMap = useMemo(
    () => normalize("factorCode")(factorPerformance),
    [factorPerformance]
  );
  const dataSource = useMemo(
    () =>
      map((id: string) => {
        const key = getFactorsFormatterName(id);
        const formatterFunc = formatter[key] || formatPercentage;
        const item = fastProp(id)(factorPerformanceMap);
        return {
          name: flow(fastProp(id), formatMessage)(factorNameMap),
          fundPerformance: formatterFunc(item?.fundFactorValue),
          marketPerformance: formatterFunc(item?.benchmarkFactorValue),
          sameTypeFactorRank: item?.sameTypeFactorRank,
        };
      })(keys(factorNameMap)),
    [factorPerformanceMap, formatMessage]
  );
  return { dataSource, summary };
};

export const useGetBarChartAndSummaryData = (fundCode: string) => {
  // const formatMessage = useFormatMessage()
  const industryAbilityEvaluation: IndustryAbilityEvaluationParam =
    useAppSelector(prop(`fundDetail.${fundCode}.industryAbilityEvaluation`));
  const {
    industryAllocationRating,
    sameTypeRank,
    top3IndustryInEquity,
    top3IndustryWeightRating,
    preferenceAllocation,
    industryAllocationCompareWithSameType,
    brinsonAllocationContribute,
    factorModelAllocationContribute,
  } = industryAbilityEvaluation || {};

  const formatMessage = useFormatMessage();

  const chartDatas = useMemo(
    () => [
      {
        name: formatMessage("theFund"),
        data: [
          brinsonAllocationContribute?.fundContribute,
          factorModelAllocationContribute?.fundContribute,
        ],
      },
      {
        name: formatMessage("sameTypeFundAvgValue"),
        data: [
          brinsonAllocationContribute?.sameTypeContribute,
          factorModelAllocationContribute?.sameTypeContribute,
        ],
      },
    ],
    [
      brinsonAllocationContribute?.fundContribute,
      brinsonAllocationContribute?.sameTypeContribute,
      factorModelAllocationContribute?.fundContribute,
      factorModelAllocationContribute?.sameTypeContribute,
      formatMessage,
    ]
  );

  const options = useMemo<EChartsOption>(
    () => ({
      legend: {
        itemGap: 100,
        data: [formatMessage("theFund"), formatMessage("sameTypeFundAvgValue")],
      },
      xAxis: {
        data: [
          formatMessage("BRINSON"),
          formatMessage("multifactorAttribution"),
        ],
        type: "category",
        axisTick: { show: false },
        boundaryGap: true,
        axisLabel: {
          margin: 20,
        },
      },
      yAxis: { show: false },
      series: map((item: any) => ({
        name: item.name,
        type: "bar",
        barWidth: 68,
        barGap: "6%",
        label: {
          show: true,
          fontFamily: "DINAlternate",
          fontSize: 18,
          fontWeight: "bold",
          formatter: (params: any) => {
            const value = prop("value")(params);
            return formatPercentage(value);
          },
        },
        // data: [
        //   [formatMessage("BRINSON"), item.brinson],
        //   [formatMessage("multifactorAttribution"), item.multiFactor],
        // ],
        data: map((v: number) => ({
          value: v,
          label: {
            position: v >= 0 ? "top" : "bottom",
          },
        }))(item.data),
      }))(chartDatas) as BarSeriesOption[],
    }),
    [chartDatas, formatMessage]
  );

  return {
    industryAllocationRating,
    sameTypeRank,
    top3IndustryInEquity,
    top3IndustryWeightRating,
    preferenceAllocation,
    industryAllocationCompareWithSameType,
    brinsonAllocationContribute,
    factorModelAllocationContribute,
    options,
  };
};

export const useGetAbilitySummary = (fundCode: string) => {
  const fundEvaluationReportAbilitySummary: EvaluationReportAbilitySummaryBody =
    useAppSelector(
      prop(`fundDetail.${fundCode}.evaluationReportAbilitySummary`)
    );

  const { factorRatings: factorRatingsOrigin, totalRating } =
    fundEvaluationReportAbilitySummary || {};
  return {
    factorRatingsMap: normalize("factorCode")(factorRatingsOrigin),
    totalRating,
  };
};
