import { fastProp } from "@/util/opt";
import dayjs from "dayjs";
import { forEach, invert } from "lodash/fp";

export const MarketCapabilityLevel = {
  HIGH: "upstream",
  MEDIUM: "midstream",
  LOW: "downstream",
};
export const AbilityLevel = {
  HIGH: "Strong",
  MEDIUM: "ORDINARY",
  LOW: "POOR",
};

export const getLastDateOfQuarter = (processedDates: string[]) => {
  const dates: Record<string, string> = {};
  let dateCount = 0;
  forEach((date: string) => {
    const dateYear = dayjs(date).get("year").toString();
    const dateQuarter = dayjs(date).quarter();
    const quarterDate = `${dateYear}Q${dateQuarter}`;
    const prevDate = fastProp(quarterDate)(dates);

    if (prevDate) {
      if (dateCount < 33) {
        dates[quarterDate] = date;
        dateCount++;
      }
    } else {
      dateCount = 0;
      dates[quarterDate] = date;
    }
  })(processedDates);
  return invert(dates);
};
