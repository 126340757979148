import { useFormatMessage } from "@/util/formatMessage";
import { useCreation } from "ahooks";
import { Radio, Select, Space } from "antd";
import { isEmpty, prop } from "lodash/fp";
import React, { useContext, useEffect, useMemo } from "react";
import { PortfolioAnalysisProps } from "../..";
import PortfolioAnalysisSubtitle from "../../components/portfolioAnalysisSubtitle";
import style from "../index.module.less";
import ErrorBoundary from "@/components/errorBoundary";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import { errorValidator } from "@/components/errorBoundary/constant";
import { fetchValueAtRisk } from "@/store/portfolioAnalysis";
import LineChart from "@/echarts/lineChart";
import EmptyContent from "@/components/emptyContent";
import SummaryCard from "../../components/summaryCard";
import LoadingComponent from "@/components/LoadingComponent";
import { useChartDataSource, useParamsRange } from "../hook";
import { analysisBasicInfoSelector } from "@/views/portfolioManage/portfolioAnalysis/selectors";
import { RootState } from "@/store";
import { useAppSelector } from "@/hooks/redux";
import { getSection } from "../constant";

const needTime = 100;

export default React.memo<PortfolioAnalysisProps>((props) => {
  const { id } = props;
  const { runningTime } = useContext(PortfolioAnalysisContext);
  const { dispatch } = useContext(PortfolioAnalysisContext);

  const formatMessage = useFormatMessage();
  const {
    computationType,
    onChangeComputationType,
    earningStatisticsButtonOptions,
    future,
    setFuture,
    futureOptions,
    confidenceType,
    setConfidenceType,
    confidenceTypeOptions,
  } = useParamsRange();
  useEffect(() => {
    dispatch(
      needTime,
      fetchValueAtRisk({
        id,
        computationType,
        confidenceType,
        future,
      })
    );
  }, [computationType, confidenceType, dispatch, future, id]);
  const portfolioSummary = useAppSelector((state: RootState) =>
    analysisBasicInfoSelector(state, id)
  );
  const { portfolioName } = portfolioSummary;
  const sectionId = useMemo(
    () => getSection(computationType, confidenceType, future),
    [computationType, confidenceType, future]
  );

  const valueAtRisk = useAppSelector(
    prop(`portfolioAnalysis.${id}.valueAtRisk.${sectionId}`)
  );
  const { options, series, dates, summaryText } = useChartDataSource(
    computationType,
    confidenceType,
    future,
    portfolioName,
    valueAtRisk,
    "portfolio"
  );
  const errKey = useCreation(
    () => errorValidator.lessOneHundredTradingDate(needTime, runningTime),
    [runningTime]
  );
  return (
    <LoadingComponent actions={fetchValueAtRisk}>
      <PortfolioAnalysisSubtitle name={formatMessage("valueAtRisk")} />
      <div className={style.EarningStatisticsButtonGroup}>
        <Radio.Group
          optionType="button"
          options={earningStatisticsButtonOptions}
          value={computationType}
          buttonStyle="solid"
          onChange={onChangeComputationType}
        />
      </div>
      <Space>
        <span>{formatMessage("future")}</span>
        <Select
          className={style.RangeSelect}
          value={future}
          onSelect={setFuture}
          options={futureOptions}
        />
        <span>{formatMessage("AnTradingDay")}</span>
        <Select
          className={style.RangeSelect}
          value={confidenceType}
          onSelect={setConfidenceType}
          options={confidenceTypeOptions}
        />
        <span>{formatMessage("confidenceTypeOption")}</span>
      </Space>
      <ErrorBoundary errKey={errKey}>
        {isEmpty(dates) ? (
          <EmptyContent className={style.EmptyContent}>
            <strong>{formatMessage("noData")}</strong>
          </EmptyContent>
        ) : (
          <LineChart options={options} series={series} />
        )}
        <SummaryCard
          summaryText={formatMessage("valueAtRiskSummaryText", summaryText)}
        />
      </ErrorBoundary>
    </LoadingComponent>
  );
});
