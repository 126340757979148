import { useFormatMessage } from "@/util/formatMessage";
import { Button, Card, Space, Tooltip } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import style from "./index.module.less";
import { useCreation, useMemoizedFn } from "ahooks";
import cn from "classnames";
import { identity, keys, prop } from "lodash/fp";
import { alreadySelectConfig, FUNDS, getCompareIds } from "./constant";
import { addZeroIndex } from "@/util/numberFormatter";
import { CheckCircleOutlined } from "@ant-design/icons";
import { changeIdsKeyArg, useCompareIds } from "./hooks";

type alreadySelectAnchorProps = {
  type?: "FUNDS" | "FUND_COMPANY" | "FUND_MANAGER";
  selectIds: Record<string, boolean>;
  onChangeIds: (key: changeIdsKeyArg) => (v: string) => void;
  onClick?: void;
};

export type AlreadyRef = {
  onOpenModal: () => any;
  onCloseModal: () => any;
};

const useManageModalStatus = () => {
  const [compareModalStatus, setCompareModalStatus] = useState(false);

  const onCloseModal = useMemoizedFn(() => {
    setCompareModalStatus(false);
  });
  const onOpenModal = useMemoizedFn(() => {
    setCompareModalStatus(true);
  });

  const toggleModalStatus = useMemoizedFn(() =>
    setCompareModalStatus(!compareModalStatus)
  );
  return { compareModalStatus, onCloseModal, onOpenModal, toggleModalStatus };
};
export default forwardRef<AlreadyRef, alreadySelectAnchorProps>(
  ({ type = FUNDS, selectIds, onChangeIds, onClick = identity }, ref) => {
    const formatMessage = useFormatMessage();

    const { compareModalStatus, onCloseModal, onOpenModal, toggleModalStatus } =
      useManageModalStatus();
    const comparedIds = useCreation(
      () => getCompareIds(selectIds),
      [selectIds]
    );
    const { idsMap, goToCompare, disabledGoToCompare } = useCompareIds({
      type,
      comparedIds,
      onCloseModal,
    });

    const alreadyFundOnClick = useMemoizedFn((e) => {
      e.stopPropagation();
      onClick();
    });

    useImperativeHandle(ref, () => ({
      onOpenModal,
      onCloseModal,
    }));

    return (
      <Space
        size={0}
        onClick={alreadyFundOnClick}
        className={cn(
          style.AlreadySelect,
          compareModalStatus ? style.Open : style.Close
        )}
      >
        <img
          className={style.Image}
          src={
            compareModalStatus
              ? prop([type, "iconActive"])(alreadySelectConfig)
              : prop([type, "icon"])(alreadySelectConfig)
          }
          onClick={toggleModalStatus}
        />
        <Card
          size="small"
          bordered={false}
          title={formatMessage(prop([type, "title"])(alreadySelectConfig))}
          className={
            compareModalStatus ? style.AlreadySelectIds : style.HideSelectIds
          }
          actions={[
            <Button
              block
              size="small"
              type="primary"
              disabled={disabledGoToCompare}
              onClick={goToCompare}
            >
              {formatMessage("gotoCompare")}
            </Button>,
          ]}
          extra={
            <Button
              type="link"
              size="small"
              icon={<CloseOutlined />}
              onClick={toggleModalStatus}
            />
          }
        >
          <ul className={style.SelectedList}>
            {keys(selectIds).map((v: string, idx: number) => {
              const isSelected = comparedIds.includes(v);
              const disabled = comparedIds.length >= 4 && !isSelected;
              return (
                <li
                  key={v}
                  className={cn(
                    style.SelectedComparedFunds,
                    isSelected && style.Selected,
                    disabled && style.DisabledChecked
                  )}
                >
                  <div
                    onClick={
                      disabled ? undefined : () => onChangeIds("select")(v)
                    }
                    className={style.SelectedComparedFundsName}
                  >
                    {addZeroIndex(idx + 1)}&nbsp;&nbsp;
                    <Tooltip title={prop(`${v}.name`)(idsMap)}>
                      <span className={style.Truncation}>
                        {prop(`${v}.name`)(idsMap)}
                      </span>
                    </Tooltip>
                  </div>
                  <Space>
                    <CheckCircleOutlined
                      className={cn(
                        style.ComparedIcon,
                        !isSelected && style.HiddenCheckCircle
                      )}
                    />
                    <DeleteOutlined
                      className={style.DeleteOutlined}
                      onClick={() => onChangeIds("delete")(v)}
                    />
                  </Space>
                </li>
              );
            })}
          </ul>
        </Card>
      </Space>
    );
  }
);
