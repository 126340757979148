import React from "react";
import { useFormatMessage } from "@/util/formatMessage";
import PositionTrend from "./components/positionTrend";
import PositionDetail from "./components/positionDetail";
import FundDistribution from "./components/fundDistribution";
import CardLayout from "@/components/cardLayout";
import {
  fetchPortfolioSummary,
  fetchGetPositionTrend,
  fetchGetPositionTrendStrategy,
} from "@/store/portfolioAnalysis";
import positionThroughIcon from "@/assets/portfolioAnalysisIcons/positionThrough.png";
import { PortfolioAnalysisProps } from "..";
import LoadingComponent from "@/components/LoadingComponent";

const actions = [
  fetchPortfolioSummary,
  fetchGetPositionTrend,
  fetchGetPositionTrendStrategy,
];

export default React.memo<PortfolioAnalysisProps>((props) => {
  const formatMessage = useFormatMessage();

  return (
    <LoadingComponent actions={actions}>
      <CardLayout
        title={formatMessage("positionThrough")}
        icon={positionThroughIcon}
      >
        <PositionDetail {...props} />
        <PositionTrend {...props} />
        <FundDistribution {...props} />
      </CardLayout>
    </LoadingComponent>
  );
});
