/**
 * 新建比赛界面
 */
import React, { useEffect, useContext, useState } from "react";
import { Form, Input, DatePicker, Radio, Button, Space, message } from "antd";
import moment from "moment";
import { size, values } from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import {
  disabledDateForEarlierOneDay,
  disabledDateForExceedRange,
} from "@/util/disabledDateHelper";
import { DATEFORMAT } from "@/util/dateFormat";
import { validateCompetitionName } from "@/api/competition";
import {
  createOrUpdateCompetition,
  fetchCompetitionDetail,
} from "@/store/competition";
import { useAppDispatch } from "@/hooks/redux";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { Competition, presentRangeText } from "@/model/competition";
import {
  basicValidateOfName,
  competitionInitValues,
  transformFormValuesToServerValues,
  transformServerValuesToFormValues,
} from "./constant";
import UploadImg from "./uploadImg";
import AddCompetitionUser from "./addCompetitionUser";
import PreviewModal from "./previewModal/index";
import style from "./index.module.less";
import { useMemoizedFn } from "ahooks";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const disabledDateForDateRange = (enterEndDate: string) => {
  if (!enterEndDate) {
    return disabledDateForEarlierOneDay("");
  }
  return disabledDateForEarlierOneDay(enterEndDate, true);
};

const CreateCompetition = ({
  competitionId,
}: {
  competitionId: string;
}): JSX.Element => {
  const isUpdate = !!competitionId;
  const formatMessage = useFormatMessage();
  const [info, setInfo] = useState({});
  const dispatch = useAppDispatch();
  const { goToCompetitionManage, stackBack } = useContext(
    PlatformNavigationContext
  );
  const [form] = Form.useForm();

  const onSubmit = useMemoizedFn(async () => {
    try {
      const values = await form.validateFields();
      if (isUpdate) {
        dispatch(
          createOrUpdateCompetition({
            ...transformFormValuesToServerValues(values),
            competitionId,
          })
        ).then((result: any) => {
          if (!result?.error?.name) {
            message.success(formatMessage("competitionHasUpdate"));
            goToCompetitionManage();
          }
        });
      } else {
        dispatch(
          createOrUpdateCompetition(transformFormValuesToServerValues(values))
        ).then((result: any) => {
          if (!result?.error?.name) {
            message.success(formatMessage("competitionHasCreate"));
            form.resetFields();
            goToCompetitionManage();
          }
        });
      }
    } catch (e) {
      // message.error(formatMessage("formErrorTip"));
    }
  });

  useEffect(() => {
    if (isUpdate) {
      dispatch(fetchCompetitionDetail(competitionId)).then(({ payload }) => {
        setInfo(payload as Competition);
        form.setFieldsValue(
          transformServerValuesToFormValues(payload as Competition)
        );
      });
    } else {
      form.setFieldsValue(competitionInitValues);
    }
  }, [dispatch, competitionId, isUpdate, form]);

  return (
    <div className={style.Container}>
      <Form
        name="competitionForm"
        // initialValues={competitionInitValues}
        form={form}
        labelAlign="left"
        labelCol={{ span: 3 }}
        className={style.Form}
      >
        <Form.Item
          label={formatMessage("competitionName")}
          name="name"
          required
          validateTrigger={["onChange", "onBlur"]}
          rules={[
            {
              validator: async (rule, value) => {
                const error = basicValidateOfName(value, formatMessage);
                if (error) {
                  throw new Error(error);
                }
              },
            },
            {
              validator: async (rule, value) => {
                if (isUpdate) return;
                if (basicValidateOfName(value, formatMessage)) return;
                try {
                  await validateCompetitionName(value);
                } catch (error: any) {
                  throw new Error(values(error?.message)?.[0]);
                }
              },
              validateTrigger: "onBlur",
            },
          ]}
        >
          <Input
            className={style.NameInput}
            placeholder={formatMessage("competitionNamePlaceholder")}
          />
        </Form.Item>

        <Form.Item
          label={formatMessage("competitionStatusEnrollDeadline")}
          required
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.dateRange !== currentValues.dateRange
          }
        >
          {({ getFieldValue }) => {
            const dateRange: moment.Moment[] = getFieldValue("dateRange");
            const startDate = dateRange
              ? dateRange?.[0].format(DATEFORMAT)
              : undefined;
            return (
              <>
                <Form.Item
                  noStyle
                  name="enterEndDate"
                  rules={[
                    {
                      required: true,
                      message: formatMessage("pleaseSelectEnrollDeadline"),
                    },
                    {
                      validator: async (rule, value) => {
                        if (
                          startDate &&
                          value &&
                          value.format(DATEFORMAT) >= startDate
                        ) {
                          throw new Error(
                            formatMessage(
                              "enrollDeadlineCannotGreaterThanStartDate"
                            )
                          );
                        }
                      },
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDateForExceedRange(
                      undefined,
                      startDate,
                      false,
                      true
                    )}
                  />
                </Form.Item>
                {getFieldValue("enterEndDate") &&
                  dateRange?.[0] &&
                  dateRange?.[0].diff(getFieldValue("enterEndDate"), "days") <=
                    3 && (
                    <span className={style.EnrollDeadlineTip}>
                      {formatMessage("enrollDeadlineTip")}
                    </span>
                  )}
              </>
            );
          }}
        </Form.Item>

        <Form.Item
          label={formatMessage("competitionDate")}
          required
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.enterEndDate !== currentValues.enterEndDate
          }
        >
          {({ getFieldValue }) => (
            <Form.Item
              // label={formatMessage("competitionDate")}
              name="dateRange"
              noStyle
              rules={[
                {
                  required: true,
                  message: formatMessage("pleaseSelectStartAndEndDate"),
                },
              ]}
            >
              <RangePicker
                disabledDate={disabledDateForDateRange(
                  getFieldValue("enterEndDate")
                )}
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          label={formatMessage("competitionRules")}
          name="regulation"
          rules={[
            {
              required: true,
              message: formatMessage("pleaseInputCompetitionRule"),
            },
          ]}
        >
          <TextArea
            className={style.RegulationArea}
            rows={4}
            showCount
            placeholder={formatMessage("pleaseInputCompetitionRule")}
            maxLength={1000}
          />
        </Form.Item>

        <Form.Item
          label={formatMessage("competitionSummary")}
          name="summary"
          rules={[
            {
              required: true,
              message: formatMessage("pleaseInputCompetitionSummary"),
            },
          ]}
        >
          <TextArea
            className={style.RegulationArea}
            rows={4}
            showCount
            placeholder={formatMessage("pleaseInputCompetitionSummary")}
            maxLength={200}
          />
        </Form.Item>

        <Form.Item
          label={formatMessage("competitionDisplay")}
          name="presentRange"
          required
        >
          <Radio.Group>
            <Radio value="PUBLIC">
              {formatMessage(presentRangeText.PUBLIC)}
            </Radio>
            <Radio value="PRIVATE">
              {formatMessage(presentRangeText.PRIVATE)}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={formatMessage("imgSetting")} required>
          <Form.Item
            labelCol={{ span: 0 }}
            required
            name="entrance"
            rules={[
              {
                validator: async (rule, value) => {
                  if (!value?.img) {
                    throw new Error(formatMessage("mustUploadImg"));
                  }
                  if (size(value?.imgName) > 28) {
                    throw new Error(formatMessage("uploadImgNameLimit"));
                  }
                },
              },
            ]}
          >
            <UploadImg
              limitSize={250 * 1024}
              width={543}
              height={254}
              text={formatMessage("uploadCardImg")}
              className={style.CardUpload}
            >
              {competitionId && <PreviewModal info={info as Competition} />}
            </UploadImg>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 0 }}
            name="banner"
            rules={[
              {
                validator: async (rule, value) => {
                  if (!value?.img) {
                    throw new Error(formatMessage("mustUploadImg"));
                  }
                  if (size(value?.imgName) > 28) {
                    throw new Error(formatMessage("uploadImgNameLimit"));
                  }
                },
              },
            ]}
          >
            <UploadImg
              limitSize={150 * 1024}
              width={543}
              height={180}
              text={formatMessage("uploadDetailImg")}
              className={style.CardUpload}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={formatMessage("joinCompetitionUser")}
          required
          name="competeUsers"
          rules={[
            {
              validator: async (rule, value) => {
                if (!size(value)) {
                  throw new Error(formatMessage("pleaseChooseCompetitor"));
                }
              },
            },
          ]}
        >
          <AddCompetitionUser />
        </Form.Item>
      </Form>
      <footer className={style.Footer}>
        <Space size={8}>
          <Button onClick={stackBack}>{formatMessage("cancel")}</Button>
          <Button type="primary" onClick={onSubmit}>
            {formatMessage("ok")}
          </Button>
        </Space>
      </footer>
    </div>
  );
};

export default React.memo(CreateCompetition);
