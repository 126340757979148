import React, { useMemo } from "react";
import { findIndex, flow, reverse, sortBy, values } from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import { formatPercentage } from "@/util/numberFormatter";
import { HoldStockStyleChartProps } from "./type";
import BaseChart from "../baseChart";
import { hexTorgb } from "../util/colorTransform";

const xAxisData = ["value", "balance", "growth"];
const yAxisData = ["smallCap", "midCap", "bigCap"];

const BOTTOM = 30;
const LEFT = 50;

export default React.memo<HoldStockStyleChartProps>(
  ({ data = {}, height = 300, color = "#0171ff" }) => {
    const formatMessage = useFormatMessage();
    const chartOption = useMemo(() => {
      const sortedValues = flow(
        values,
        sortBy((v) => v),
        reverse
      )(data);
      const resultData = [
        [formatMessage("value"), formatMessage("smallCap"), data?.SMALL_VALUE],
        [formatMessage("value"), formatMessage("midCap"), data?.MID_VALUE],
        [formatMessage("value"), formatMessage("bigCap"), data?.LARGE_VALUE],
        [
          formatMessage("balance"),
          formatMessage("smallCap"),
          data?.SMALL_BALANCE,
        ],
        [formatMessage("balance"), formatMessage("midCap"), data?.MID_BALANCE],
        [
          formatMessage("balance"),
          formatMessage("bigCap"),
          data?.LARGE_BALANCE,
        ],
        [
          formatMessage("growth"),
          formatMessage("smallCap"),
          data?.SMALL_GROWTH,
        ],
        [formatMessage("growth"), formatMessage("midCap"), data?.MID_GROWTH],
        [formatMessage("growth"), formatMessage("bigCap"), data?.LARGE_GROWTH],
      ];
      return {
        grid: {
          left: LEFT,
          right: 0,
          top: 0,
          bottom: BOTTOM,
        },
        xAxis: {
          type: "category",
          data: xAxisData?.map((v) => formatMessage(v)),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: yAxisData?.map((v) => formatMessage(v)),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        label: {
          show: true,
          color: "#000",
          formatter: (params: any) => formatPercentage(params?.value?.[2]),
        },
        colorBy: "data",
        symbol: "rect",
        animation: false,
        series: [
          {
            type: "scatter",
            data: resultData,
            symbolKeepAspect: true,
            universalTransition: true,
            symbolSize: (height - BOTTOM) / 3 - 2,
            emphasis: {
              scale: 1,
            },
            itemStyle: {
              color: (params: any) => {
                const value = params?.value?.[2];
                const index = findIndex((v) => v === value)(sortedValues);
                return `rgba(${
                  /rgb\((.*)\)/.exec(hexTorgb(color))?.[1] || "1,113,255"
                }, ${1 - ((index >= 0 ? index : 0) / 3) * 0.3})`;
              },
            },
          },
        ],
      };
    }, [height, data, formatMessage, color]);

    return (
      <BaseChart
        option={chartOption}
        width={height - BOTTOM + LEFT}
        height={height}
      />
    );
  }
);
