import PerformancePreview from "./performancePreview";
import RiskAnalysis from "./riskAnalysis";
import PositionPenetrate from "./positionPenetrate";
import portfolioOverview from "./portfolioOverview";

export const menus = [
  {
    id: "portfolioOverview",
    messageKey: "portfolioOverview",
    Component: portfolioOverview,
  },
  {
    id: "performancePreview",
    messageKey: "performancePreview",
    Component: PerformancePreview,
  },
  {
    id: "riskAnalysis",
    messageKey: "riskAnalysis",
    Component: RiskAnalysis,
  },
  {
    id: "positionPenetrate",
    messageKey: "positionThrough",
    Component: PositionPenetrate,
  },
];
