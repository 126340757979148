import React, { useMemo } from "react";
import { fastProp } from "@/util/opt";
import type {
  EChartsOption,
  PieSeriesOption,
  TooltipComponentOption,
} from "echarts";
import { map, prop, merge, omit } from "lodash/fp";
import EchartsWrapper, { WrapperProps } from "./echartsWrapper";

export interface PieChartOpts extends WrapperProps {
  options: EChartsOption & {
    series?: PieSeriesOption;
  };
}
export default React.memo<PieChartOpts>(
  ({ options, events = {}, width = "100%", height = 300 }) => {
    const handleOptions = useMemo(
      () =>
        merge({
          grid: {
            left: 0,
            right: 0,
            top: 50,
            bottom: 0,
            containLabel: true,
          },
          tooltip: {
            trigger: "item" as TooltipComponentOption["trigger"],
          },
          legend: {
            top: 0,
            left: 0,
            show: false,
          },
          series: [
            {
              type: "pie",
              radius: ["60%", "90%"],
              color: map("color")(prop("series.data")(options)),
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              labelLine: {
                show: false,
              },
              ...fastProp("series")(options),
            },
          ],
        })(omit(["series"])(options)),
      [options]
    );
    return (
      <EchartsWrapper
        width={width}
        height={height}
        events={events}
        options={handleOptions}
        showDataZoom={false}
      />
    );
  }
);
