import { useEffect } from "react";
import { fetchUsers } from "@/store/users";
import { usersSelector, usersMapSelector } from "@/selectors/user";
import { useAppDispatch, useAppSelector } from "./redux";

export const useGetUsers = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);
  const users = useAppSelector(usersSelector);
  const userMap = useAppSelector(usersMapSelector);
  return { users, userMap };
};
