import { isObject } from "lodash/fp";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDatePeriodReturn,
  DatePeriodReturnParam,
} from "@/api/datePeriodReturn";

export const fetchDatePeriodReturnAction = createAsyncThunk(
  "datePeriodReturn/getDatePeriodReturn",
  async (params: DatePeriodReturnParam) => {
    const response = await getDatePeriodReturn(params);
    return response;
  }
);

type DatePeriodReturn = Record<string, number>;

type DatePeriodReturnStore = {
  funds: Record<string, DatePeriodReturn>;
};

const initialState: DatePeriodReturnStore = {
  funds: {},
};

const datePeriodReturnSlice = createSlice({
  name: "datePeriodReturns",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDatePeriodReturnAction.fulfilled.type]: (state, action) => {
      const targetValue = state.funds[action.meta?.arg?.section];
      state.funds[action.meta?.arg?.section] = {
        ...(isObject(targetValue) ? targetValue : {}),
        ...action.payload,
      };
    },
  },
});

export default datePeriodReturnSlice.reducer;
