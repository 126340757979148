import React, { ReactElement, useState } from "react";
import { isEmpty, identity } from "lodash/fp";
import { useMemoizedFn } from "ahooks";
import { Button, Popover, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useFormatMessage } from "@/util/formatMessage";
import { useFundOptionalService } from "@/views/myOptional/fundOptional/hooks";
import fileIcon from "@/assets/fileIcon.svg";
import { optionalFundGroupList } from "@/views/myOptional/fundOptional/interface";
import NewGroupDialog from "@/views/myOptional/components/newGroupDialog";
import { CollectionIcon } from "@/components/icons";
import { mapIndexed } from "@/util/opt";
import style from "./index.module.less";

function BatchFundAddOptional({
  className = "",
  ids = [],
  disabled = false,
}: BatchFundAddOptionalProp): ReactElement {
  const [visible, setVisible] = useState(false);
  const { groups, addToGroup } = useFundOptionalService();
  const formatMessage = useFormatMessage();
  const onSelectItem = useMemoizedFn((group: optionalFundGroupList) => {
    if (isEmpty(ids)) return;
    addToGroup(ids, group.groupName).then(() => {
      message.success(formatMessage("addOptionalSuccessTip"));
    });
    setVisible(false);
  });

  return (
    <Popover
      title={formatMessage("addTo")}
      content={
        <div className={style.BatchPop}>
          <div className={style.PopContent}>
            {mapIndexed((group: optionalFundGroupList, index: number) => (
              <div
                className={style.PopContentItem}
                onClick={() => {
                  onSelectItem(group);
                }}
                key={index}
              >
                <img src={fileIcon} />
                <p className={style.PopContentItemName}>
                  {group.groupName === ""
                    ? formatMessage("unnamedGroup")
                    : group.groupName}
                </p>
              </div>
            ))(groups as any)}
          </div>
          <NewGroupDialog setPopoverVisible={identity} type="FUND">
            <Button className={style.AddNewGroup} block icon={<PlusOutlined />}>
              {formatMessage("addNewGroup")}
            </Button>
          </NewGroupDialog>
        </div>
      }
      placement="bottom"
      trigger="click"
      zIndex={9}
      visible={visible}
      onOpenChange={setVisible}
    >
      <Button
        icon={<CollectionIcon style={{ fontSize: 16 }} />}
        className={className}
        disabled={disabled}
      >
        {formatMessage("addUseSelect")}
      </Button>
    </Popover>
  );
}

export default React.memo(BatchFundAddOptional);

interface BatchFundAddOptionalProp {
  className?: string;
  ids?: string[];
  disabled?: boolean;
}
