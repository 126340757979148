import { portfolioAnalysisConfig } from "@/providers/portfolioAnalysisProvider/context";
import React from "react";
import PortfolioAnalysis from "../portfolioAnalysis";

export default React.memo<{
  id: string;
}>(({ id }) => (
  <PortfolioAnalysis
    id={id}
    analysisType={portfolioAnalysisConfig.SIMULATION}
  />
));
