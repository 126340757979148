import React, { memo } from "react";
import { map } from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import ColorNumber from "@/components/colorNumber";

import style from "./index.module.less";

export type IndexDataItem = {
  title: string;
  value: any;
  type?: "yield";
  transformer?: (value: number) => string;
};

interface KeyIndexProps {
  data?: IndexDataItem[];
  children?: React.ReactNode;
}

export default memo<KeyIndexProps>(({ data, children }) => {
  const formatMessage = useFormatMessage();
  return (
    <div className={style.keyIndicator}>
      <div className={style.Indicators}>
        {map((item: IndexDataItem) => {
          const displayValue = item.transformer
            ? item.transformer(item.value)
            : item.value;
          return (
            <div className={style.keyIndicatorItem} key={item.title}>
              <div className={style.keyIndicatorItemTitle}>
                {/* { item?.type === "yield" &&  } */}
                {formatMessage(item.title)}
              </div>
              <div className={style.keyIndicatorItemValue}>
                {item?.type === "yield" && (
                  <ColorNumber
                    formatValue={item?.transformer}
                    value={item?.value}
                  />
                )}
                {!item?.type && displayValue}
              </div>
            </div>
          );
        })(data)}
      </div>
      <div className={style.keyIndicatorEnd}>{children}</div>
    </div>
  );
});
