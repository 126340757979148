import React, { useMemo } from "react";
import { Card, Modal, Space } from "antd";
import { isString } from "lodash/fp";
import cn from "classnames";
import question from "@/assets/portfolioAnalysisIcons/question.png";
import style from "./cardLayout.module.less";
import { mapIndexed } from "@/util/opt";

export type CardLayoutTipType = [string, string][];
interface CardLayoutProps {
  icon?: string;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  tip?: string | CardLayoutTipType;
}
const CardLayout = ({
  icon,
  title,
  children,
  className,
  tip,
}: CardLayoutProps): JSX.Element => {
  const getContent: string | React.ReactNode = useMemo(() => {
    if (isString(tip)) return tip;
    return (
      <div>
        {mapIndexed((val: [string, string]) => (
          <>
            <div>
              <b className={style["mr-8"]}>{val[0] + ":"}</b>
              <span dangerouslySetInnerHTML={{ __html: val[1] }}></span>
            </div>
            <br />
          </>
        ))(tip)}
      </div>
    );
  }, [tip]);
  const handleTipClick = () => {
    tip &&
      Modal.info({
        title: "提示",
        content: getContent,
        width: 1000,
      });
  };
  return (
    <Card
      size="small"
      className={cn(style.CardLayout, className)}
      title={
        <Space className={style.CardLayoutTitle}>
          <img src={icon} />
          <span>{title}</span>
          <img
            className={style.Question}
            src={question}
            onClick={handleTipClick}
          />
        </Space>
      }
    >
      {children}
    </Card>
  );
};

export default React.memo(CardLayout);
