import { Card, Row, Col } from "antd";
import React, { useMemo, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { SCALE_COLORS } from "@/util/colors";
import inStyle from "./index.module.less";
import { useAppSelector } from "@/hooks/redux";
import { prop, map, keys, orderBy, identity } from "lodash/fp";
import { fastProp, mapIndexed, fastNth } from "@/util/opt";
import {
  DatePeriodPicker,
  Option,
  DatePeriodValue,
} from "@/base-components/datePeriodSelect";
import { ScaleChangeChart } from "@/base-components/charts";
import { getSlicedDateDatas } from "@/util/business-core/dailyReturn";
import { DatePeriodName } from "@/util/business-core/datePeriod";

const datePeriodOptions: Option[] = [
  "COMMON_TIME",
  "RECENT_TWO_YEAR",
  "RECENT_THREE_YEAR",
  "RECENT_FIVE_YEAR",
  "FROM_CREATION",
];

export default React.memo<{
  className?: string;
}>(({ className }) => {
  const formatMessage = useFormatMessage();
  const compareFundTask = useAppSelector(
    prop("compareManage.fundCompare.compareFundTask")
  );
  const [scaleRange, setScaleRange] = useState<DatePeriodValue>("COMMON_TIME");
  const { dates, dateDatas } = useMemo(() => {
    return getSlicedDateDatas({
      name: scaleRange as DatePeriodName,
      targetDateDatas: mapIndexed((task: any, index: number) => {
        const { fundScaleMap } = task || {};
        const dates = orderBy<string>(identity, "asc")(keys(fundScaleMap));
        return {
          name: task?.fundName,
          color: fastNth(index)(SCALE_COLORS),
          data: map((date: string) => ({ date, value: fundScaleMap?.[date] }))(
            dates
          ),
        };
      })(compareFundTask),
      dataTransform: (data) => [
        fastProp("date")(data),
        fastProp("value")(data),
      ],
    });
  }, [compareFundTask, scaleRange]);

  return (
    <Card bordered={false} className={className}>
      <Row justify="space-between" align="top">
        <Col>
          <h3>{formatMessage("fundScaleChange")}</h3>
        </Col>
        <Col>
          <DatePeriodPicker
            options={datePeriodOptions}
            value={scaleRange}
            onChange={setScaleRange}
          />
        </Col>
      </Row>
      <div className={inStyle.BarChart}>
        <ScaleChangeChart
          title={`(${formatMessage("million")})`}
          series={dateDatas as any}
          height={400}
          dates={dates}
          showDataZoom={false}
        />
        {/* <BarChart options={dataSource} /> */}
      </div>
    </Card>
  );
});
