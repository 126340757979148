import { Radio, Space, Table } from "antd";
import { map, sumBy } from "lodash/fp";
import React, { useEffect, useMemo, useState } from "react";
import {
  DatePeriodPicker,
  Option,
  DatePeriodValue,
} from "@/base-components/datePeriodSelect";
import { useAppDispatch } from "@/hooks/redux";
import { ChangeMonitorBody } from "@/model/aum";
import { fetchChangeMonitor } from "@/store/aum";
import { useFormatMessage } from "@/util/formatMessage";

import { itemType } from "../concentrationMonitor";
import { useGetColumns, useGetDataSource } from "./hook";
import style from "./index.module.less";

const datePeriodOptions: Option[] = [
  "RECENT_WEEK",
  "RECENT_MONTH",
  "RECENT_THREE_MONTH",
  "RECENT_HALF_YEAR",
  "RECENT_YEAR",
  "FROM_THIS_YEAR",
];
export default React.memo(
  ({
    showType,
    Options,
    dataKey,
    aumGrpId,
    firstColor,
    tableKey,
    isShowSubOrganizations,
  }: {
    showType: string;
    Options: itemType[];
    dataKey: string;
    aumGrpId: string;
    firstColor: string;
    tableKey: string;
    isShowSubOrganizations: boolean;
  }) => {
    const formatMessage = useFormatMessage();

    const [type, setType] = useState("FUND");
    const [fundType, setFundType] = useState("EQUITY");
    const [section, setSection] = useState<DatePeriodValue>("RECENT_WEEK");

    const dataSource = useGetDataSource(
      aumGrpId,
      type,
      fundType,
      dataKey,
      section
    );

    const columns = useGetColumns(
      showType,
      type,
      section,
      dataSource,
      tableKey,
      isShowSubOrganizations,
      firstColor
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
      const params: ChangeMonitorBody = {
        aumGrpId: aumGrpId,
        type: type === "FUND" ? fundType : type,
      };
      if (type === "FUND") {
        params.section = section;
      }
      if (aumGrpId) {
        dispatch(fetchChangeMonitor(params));
      }
    }, [dispatch, fundType, section, type, aumGrpId]);

    const scroll = useMemo(
      () =>
        type === "table"
          ? { x: sumBy("width")(columns) }
          : { x: "max-content" },
      [columns, type]
    );

    return (
      <>
        <Space direction="vertical" className={style.FullWidth} size={15}>
          <Radio.Group
            buttonStyle="solid"
            onChange={(e) => setType(e.target.value)}
            value={type}
          >
            {map((item: itemType) => {
              return (
                <Radio.Button value={item.value}>
                  {formatMessage(item.label)}
                </Radio.Button>
              );
            })(Options)}
          </Radio.Group>
          {type === "FUND" && (
            <div className={style.SelectedPart}>
              <Radio.Group
                onChange={(e) => setFundType(e.target.value)}
                value={fundType}
              >
                <Radio value="EQUITY">{formatMessage("EQUITY")}</Radio>
                <Radio value="FIXED">{formatMessage("FIXED")}</Radio>
              </Radio.Group>

              <DatePeriodPicker
                options={datePeriodOptions}
                value={section}
                onChange={setSection}
                buttonStyle="solid"
              />
            </div>
          )}
          <Table
            className={showType === "table" ? style.FundTable : style.FundChart}
            rowKey="code"
            columns={columns}
            bordered={false}
            dataSource={dataSource}
            pagination={false}
            scroll={scroll}
          />
        </Space>
      </>
    );
  }
);
