import { FormatMessageFunc } from "@/util/formatMessage";
import { formatPercentage } from "@/util/numberFormatter";
import { concat } from "lodash/fp";

export const getSeries = (
  indexData: (number | null)[],
  fundWeights: number[],
  benchmarkWeight: number[],
  typeName: string,
  dataMissing: boolean,
  formatMessage: FormatMessageFunc,
  isShowMoney: boolean
) => {
  const moneySeries = [
    {
      color: "#017AFF",
      name: formatMessage("indexRiseAndFall", { typeName }),
      data: indexData,
      symbol: "none",
      tooltip: {
        valueFormatter: formatPercentage,
      },
      connectNulls: true,
    },
  ];
  const otherSeries = [
    {
      color: "#FFA62A",
      name: formatMessage("CustomerHoldingWeight", { typeName }),
      symbol: "none",
      yAxisIndex: 1,
      tooltip: {
        valueFormatter: formatPercentage,
      },
      data: fundWeights,
      connectNulls: false,
    },
    ...(dataMissing
      ? []
      : [
          {
            color: "#FF0A00",
            name: formatMessage("benchmarkHoldingWeight", { typeName }),
            symbol: "none",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: formatPercentage,
            },
            data: benchmarkWeight,
            connectNulls: false,
          },
        ]),
  ];
  return isShowMoney ? concat(moneySeries)(otherSeries) : otherSeries;
};

export const getAssetType = (
  formatMessage: FormatMessageFunc,
  type: string
) => ({
  cashPositionTrend: formatMessage("cash"),
  fundPositionTrend: formatMessage("fund"),
  stockPositionTrend: formatMessage("stockNavWeight"),
  bondPositionTrend:
    type === "ASSET_POSITION_TYPE"
      ? formatMessage("bond")
      : formatMessage("FIXED"),
  currencyPositionTrend: formatMessage("MONEY"),
  equityPositionTrend: formatMessage("EQUITY"),
  otherPositionTrend: formatMessage("otherClass"),
});
export const defaultId = "52f78339-821c-49cc-ab06-19ea1db100e5";
