import getMessage from "@/util/getMessage";
import { normalize } from "@/util/opt";

export const fromAboveToBelowSteps = {
  allocationModel: 0,
  fundAllocation: 1,
  backTestingAllocation: 2,
  savePortfolio: 3,
  steps: [
    {
      message: getMessage("allocationModel"),
    },
    {
      message: getMessage("allocationFund"),
    },
    {
      message: getMessage("allocationbackTest"),
    },
    {
      message: getMessage("savePortfolio"),
    },
  ],
};

export const ASSET_CATEGORY = "ASSET_CATEGORY";
export const MARKET_INDEX = "MARKET_INDEX";

export const categorySourceType = [
  {
    label: getMessage("ASSET_CATEGORY"),
    value: ASSET_CATEGORY,
  },
  {
    label: getMessage("MARKET_INDEX"),
    value: MARKET_INDEX,
  },
];

export const PARTIAL_STOCK = "PARTIAL_STOCK";
export const PARTIAL_DEBT = "PARTIAL_DEBT";
export const SPECIAL = "SPECIAL";
export const CURRENCY = "CURRENCY";

export const categoryType = [
  {
    label: getMessage("EQUITY"),
    value: PARTIAL_STOCK,
  },
  {
    label: getMessage("FIXED"),
    value: PARTIAL_DEBT,
  },
  {
    label: getMessage("COMMODITY"),
    value: SPECIAL,
  },
  {
    label: getMessage("MONEY"),
    value: CURRENCY,
  },
];

export const categoryTypeMap = normalize("value")(categoryType);

export const categoryTypeIds = {
  PARTIAL_STOCK: "1a09ec72-c975-11e9-8760-022a0f1091f9",
  PARTIAL_DEBT: "33d17b06-c975-11e9-8760-022a0f1091f9",
  SPECIAL: "2ca0bb62-c975-11e9-8760-022a0f1091f9",
  CURRENCY: "7aca60d9-7403-11ea-99b3-c4b301c5b6bb",
};

export const categoryTypeIdsOrder = {
  "1a09ec72-c975-11e9-8760-022a0f1091f9": 1,
  "33d17b06-c975-11e9-8760-022a0f1091f9": 10,
  "2ca0bb62-c975-11e9-8760-022a0f1091f9": 100,
  "7aca60d9-7403-11ea-99b3-c4b301c5b6bb": 1000,
};
