import VirtualList from "rc-virtual-list";
import ColorNumber from "@/components/colorNumber";
import { RECENT_MONTH } from "@/constant/statisticRange";
import { useGetCumulativeChartCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { updateFundIncomeInfo } from "@/store/customerAccountAnalysis";
import { fetchGetFundDetailReturn } from "@/store/fundDetailSlice";
import { useFormatMessage } from "@/util/formatMessage";
import { parseToThousandth } from "@/util/numberFormatter";
import { fastProp, normalize } from "@/util/opt";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Col, List, Row, Tag } from "antd";
import { prop, size, forEach, map, isEmpty, flow, set } from "lodash/fp";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import style from "./index.module.less";
import LineChart, { LineChartOpts } from "@/echarts/lineChart";
import cn from "classnames";
import cumulativeReturns from "@/util/quant/cumulativeReturns";

export const useGetRecentYeildChart = (fundIds: string[]) => {
  const dispatch = useAppDispatch();
  const fundDetail = useAppSelector(prop("fundDetail"));
  const getCumulativeChartCalculateRangeDate =
    useGetCumulativeChartCalculateRangeDate();

  const fundIncomeMap = useMemo(() => {
    return map((fundId: string) => {
      const fundDetailReturn = prop(`${fundId}.fundDetailReturn`)(fundDetail);
      const fundDailyReturn = fastProp("fundDailyReturn")(fundDetailReturn);
      const tradingDates = fastProp("tradingDates")(fundDetailReturn);
      const fundDailyReturnMap = normalizeDailyReturnsMap(
        tradingDates,
        fundDailyReturn
      );
      const dates = getCumulativeChartCalculateRangeDate(
        tradingDates,
        RECENT_MONTH
      );
      return [
        fundId,
        map((date: string) => fastProp(date)(fundDailyReturnMap))(dates),
      ];
    })(fundIds);
  }, [fundDetail, fundIds, getCumulativeChartCalculateRangeDate]);

  const fundIncomeUpdater = useCallback(
    (value: any) =>
      dispatch(
        updateFundIncomeInfo({
          value,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    fundIncomeUpdater(fundIncomeMap);
  }, [fundIncomeMap, fundIncomeUpdater]);

  const userId = sessionStorage.getItem("userId") as string;
  useEffect(() => {
    forEach((fundId: string) => {
      dispatch(fetchGetFundDetailReturn({ fundId: fundId, userId }));
    })(fundIds);
  }, [dispatch, fundIds, userId]);
};

export default React.memo(
  ({ assetList }: { assetList: Record<string, any>[] }) => {
    const [, setDom] = useState<HTMLDivElement | null>();

    return (
      <div
        className={style.FundVirtualizerCard}
        ref={(ref) => {
          setDom(ref);
        }}
      >
        <AssetList assetList={assetList} />
      </div>
    );
  }
);

export const RowVirtualizerFixed = ({
  assetList,
}: {
  assetList: Record<string, any>[];
}) => {
  const parentRef = React.useRef(null);

  const rowVirtualizer = useVirtualizer({
    horizontal: false,
    count: size(assetList),
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100,
    overscan: 1,
    paddingStart: 5,
  });
  return (
    <>
      <div ref={parentRef} className={style.List}>
        <div className={style.RowWrap}>
          {rowVirtualizer
            .getVirtualItems()
            .map((virtualRow: Record<string, any>) => (
              <li key={virtualRow.index} className={style.Row}>
                <AssetItem assetInfo={assetList[virtualRow.index]} />
              </li>
            ))}
        </div>
      </div>
    </>
  );
};

export const AssetList = ({
  assetList,
}: {
  assetList: Record<string, any>[];
}) => {
  const ContainerHeight = 400;
  return (
    <>
      <List itemLayout="vertical" size="small" className={style.AssetList}>
        <VirtualList
          data={assetList}
          height={ContainerHeight}
          itemHeight={47}
          itemKey="fundCode"
          // onScroll={onScroll}
        >
          {(item: Record<string, any>) => (
            <List.Item key={item.id} className={style.RowWrap}>
              <div className={style.Row}>
                <AssetItem assetInfo={item} />
              </div>
            </List.Item>
          )}
        </VirtualList>
      </List>
    </>
  );
};

const AssetItem = ({ assetInfo }: { assetInfo: Record<string, any> }) => {
  const formatMessage = useFormatMessage();
  const sectorCategoryMap = useAppSelector(sectorCategoriesMapSelector);
  const fundCode = fastProp("fundCode")(assetInfo);
  const fundsDailyReturn = useAppSelector(
    prop(`customerAnalysis.fundsDailyReturn`)
  );
  const fundsDailyReturnMap = normalize("id")(fundsDailyReturn);

  const { dates, dailyReturns } = useMemo(
    () => flow(prop(`${fundCode}.dailyReturn`))(fundsDailyReturnMap) || {},
    [fundCode, fundsDailyReturnMap]
  );

  // const dailyReturnsMap = normalizeDailyReturnsMap(dates, dailyReturns);

  const cumulativeReturn = useMemo(
    () => cumulativeReturns(set(0, 0)(dailyReturns), true),
    [dailyReturns]
  );

  const series = useMemo<LineChartOpts["series"]>(
    () => [
      {
        name: fundCode,
        type: "line",
        showSymbol: false,
        smooth: false,
        animation: false,
        lineStyle: {
          width: 1.5,
        },
        areaStyle: {
          opacity: 0.3,
        },
        data: cumulativeReturn,
      },
    ],
    [fundCode, cumulativeReturn]
  );

  const options = useMemo<LineChartOpts["options"]>(() => {
    return {
      grid: {
        right: 0,
        top: 50,
      },
      xAxis: {
        boundaryGap: false,
        data: dates,
        nameGap: 40,
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        show: false,
        min: "dataMin",
        max: "dataMax",
        axisLabel: {
          show: false,
        },
      },
      legend: {
        show: false,
        right: 0,
        orient: "vertical",
        type: "scroll",
      },
      tooltip: {
        show: false,
      },
    };
  }, [dates]);
  switch (assetInfo?.type) {
    case "stock":
      return (
        <div className={style.AssetItemCard}>
          <div className={style.StockTop}>
            <div className={style.TopContent}>
              <div className={style.Name}>
                {fastProp("stockName")(assetInfo)}
              </div>
              <Tag className={style.Tag} color="processing">
                {fastProp("stockCode")(assetInfo)}
              </Tag>
              {fastProp("industry")(assetInfo) && (
                <Tag className={style.Tag} color="processing">
                  {prop(`${fastProp("industry")(assetInfo)}.name`)(
                    sectorCategoryMap
                  )}
                </Tag>
              )}
              <Tag className={style.Tag} color="processing">
                {formatMessage(fastProp("stockMarket")(assetInfo))}
              </Tag>
            </div>
            <div className={style.BuyDate}>
              {formatMessage("purchaseDate")}:
              {fastProp("purchaseDate")(assetInfo)}
            </div>
          </div>
          <Row className={style.CardContent}>
            <Col xl={6} xxl={5} className={style.Item}>
              {/* <ColorNumber
                value={fastProp("recentPriceRate")(assetInfo)}
                formatter="percentage"
              /> */}

              <ColorNumber
                value={fastProp("recentPoint")(assetInfo)}
                formatter="parseToThousandth"
              />
              <p className={style.DetalText}>{formatMessage("latestPoints")}</p>
            </Col>
            <Col xl={6} xxl={4} className={style.Item}>
              <p className={style.DetalText}>
                {formatMessage("holdingTime")}:
                {formatMessage("days", {
                  number: fastProp("holdDays")(assetInfo),
                })}
              </p>
            </Col>
            <Col xl={6} xxl={4} className={style.Item}>
              <ColorNumber
                value={fastProp("totalProfit")(assetInfo)}
                formatter="parseToThousandth"
              />
              <p className={style.DetalText}>
                {formatMessage("totalProfitOrLoss")}
              </p>
            </Col>
            <Col xl={6} xxl={4} className={style.Item}>
              <p className={style.BlocakColor}>
                {parseToThousandth(fastProp("share")(assetInfo))}
              </p>
              <p className={style.DetalText}>
                {formatMessage("holdSharesNum")}
              </p>
            </Col>
          </Row>
        </div>
      );
      break;
    default:
      return (
        <div className={style.AssetItemCard}>
          <div className={style.StockTop}>
            <div className={style.StockTopLeft}>
              <div className={style.TopContent}>
                <div className={style.Name}>
                  {fastProp("fundName")(assetInfo)}
                </div>
                <Tag color="processing">{fastProp("fundCode")(assetInfo)}</Tag>
                {fastProp("category")(assetInfo) && (
                  <Tag color="processing">
                    {fastProp("category")(assetInfo)}
                  </Tag>
                )}
              </div>
            </div>
            <div className={style.BuyDate}>
              {formatMessage("purchaseDate")}:
              {fastProp("purchaseDate")(assetInfo)}
            </div>
          </div>
          <Row className={style.CardContent}>
            <Col xl={6} xxl={5} className={cn(style.Item, style.ChartWrap)}>
              {!isEmpty(dates) ? (
                <LineChart
                  options={options}
                  series={series}
                  showDataZoom={false}
                  height={50}
                />
              ) : (
                <div className={style.DefaultImg}></div>
              )}
            </Col>
            <Col xl={6} xxl={4} className={style.Item}>
              {/* <ColorNumber
                value={fastProp("cumulativeRate")(assetInfo)}
                formatter="percentage"
              /> */}
              <p className={style.DetalText}>
                {formatMessage("holdingTime")}:
                {formatMessage("days", {
                  number: fastProp("holdDays")(assetInfo),
                })}
              </p>
            </Col>
            <Col xl={6} xxl={4} className={style.Item}>
              {/* <ColorNumber
                value={fastProp("incomeCash")(assetInfo)}
                formatter="parseToThousandth"
              />
              <p className={style.DetalText}>
                {formatMessage("holdIncome")}({formatMessage("YUAN")})
              </p> */}
              <ColorNumber
                value={fastProp("totalProfit")(assetInfo)}
                formatter="parseToThousandth"
              />
              <p className={style.DetalText}>
                {formatMessage("totalProfitOrLoss")}
              </p>
            </Col>
            <Col xl={6} xxl={4} className={style.Item}>
              {/* <p className={style.BlocakColor}>
                {parseToThousandth(fastProp("totalPurchase")(assetInfo))}
              </p>
              <p className={style.DetalText}>
                {formatMessage("totalPurchase")}({formatMessage("YUAN")})
              </p> */}
              <p className={style.BlocakColor}>
                {parseToThousandth(fastProp("share")(assetInfo))}
              </p>
              <p className={style.DetalText}>{formatMessage("holdingShare")}</p>
            </Col>
          </Row>
        </div>
      );
      break;
  }
};
