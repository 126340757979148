import { useContext, useEffect, useMemo, useState } from "react";
import { ButtonGroupItem } from "@/components/buttonGroup";
import { isEmpty, last, map, prop, reject } from "lodash/fp";
import {
  defaultSeriesConfig,
  getAllRangeChartConfigs,
  getTurnoverDateChartConfigs,
  turnoverOptionsConfig,
} from "./constant";
import { arrayToMap, fastNth, fastProp } from "@/util/opt";
import { useFormatMessage } from "@/util/formatMessage";
import { WarehouseTurnoverReturn } from "@/model/portfolioAnalysis";
import { TurnoverRecordHistoryAssetType } from "@/model/portfolioList";
import {
  ChangingPositionHistoryAssetType,
  getTradeType,
  transactionTypeOptionsMap,
} from "../../portfolioList/constant";
import { fundIdMapSelector } from "@/selectors/fund";
import {
  fixedNumberWithCommas,
  formatNilToZero,
  roundTo,
} from "@/util/numberFormatter";
import { useMemoizedFn, useSafeState } from "ahooks";
import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import {
  analysisBasicInfoSelector,
  portfolioAnalysisSelector,
} from "../selectors";
import { useGetBenchmarkDailyReturn } from "@/hooks/benchmark";
import { normalizeDailyReturns } from "@/util/transformer";
import { useGetFundManagersByDate } from "../../portfolioList/hooks";
import { LineSeriesOption } from "echarts";
import { ColumnType } from "antd/lib/table";
import { getTableScroll } from "@/util/tableFormat";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import { portfolioAnalysisConfig } from "@/providers/portfolioAnalysisProvider/context";

export const useGetTurnoverAnalysisData = (
  id: string,
  selectedTurnoverDate: string
) => {
  const { turnoverAnalysis } = useAppSelector((state: RootState) =>
    portfolioAnalysisSelector(state, id)
  );
  return useMemo(
    () => ({
      ...((fastProp(selectedTurnoverDate)(
        fastProp("backTestingTurnover")(turnoverAnalysis)
      ) || {
        dates: [],
        unTurnoverDailyReturns: [],
        turnoverViews: [],
      }) as WarehouseTurnoverReturn),
    }),
    [selectedTurnoverDate, turnoverAnalysis]
  );
};

export const useManageSelectedTurnoverDate = (
  buttonGroupValue: string,
  turnoverEndDate: string | undefined
) => {
  const [selectedTurnoverDate, setSelectedTurnoverDate] =
    useSafeState<string>();
  useEffect(() => {
    if (
      buttonGroupValue === turnoverOptionsConfig.warehouseTime &&
      turnoverEndDate &&
      !selectedTurnoverDate
    )
      setSelectedTurnoverDate(turnoverEndDate);
  }, [
    buttonGroupValue,
    selectedTurnoverDate,
    setSelectedTurnoverDate,
    turnoverEndDate,
  ]);
  return {
    selectedTurnoverDate,
    setSelectedTurnoverDate,
  };
};
export const useManageTurnoverDate = (id: string) => {
  const { turnoverAnalysis } = useAppSelector((state) =>
    portfolioAnalysisSelector(state, id)
  );
  const turnoverDate = useMemo<string[]>(
    () => prop("turnoverDates.turnoverDates")(turnoverAnalysis),
    [turnoverAnalysis]
  );
  return useMemo(
    () => ({
      turnoverDate,
      turnoverDateMap: arrayToMap(turnoverDate),
      turnoverEndDate: last(turnoverDate),
      turnoverDateOptions: map((date: string) => ({
        label: date,
        value: date,
      }))(turnoverDate),
    }),
    [turnoverDate]
  );
};
const options = turnoverOptionsConfig.getTurnoverOptions();
export const useButtonGroup = (turnoverDate: string[]) => {
  const buttonGroupList = useMemo<ButtonGroupItem[]>(
    () =>
      isEmpty(turnoverDate)
        ? reject<ButtonGroupItem>(
            ({ id }) => id === turnoverOptionsConfig.warehouseTime
          )(options)
        : options,
    [turnoverDate]
  );
  const [buttonGroupValue, setButtonGroupValue] = useState<string>(
    turnoverOptionsConfig.allRange
  );
  return useMemo(
    () => ({
      buttonGroupList,
      buttonGroupValue,
      setButtonGroupValue,
    }),
    [buttonGroupList, buttonGroupValue]
  );
};

export const useGetSummaryText = (
  buttonGroupValue: string,
  turnoverDateSize: number,
  turnoverDate: string | undefined,
  chartSeries: LineSeriesOption[]
) => {
  const formatMessage = useFormatMessage();
  if (buttonGroupValue === turnoverOptionsConfig.allRange) {
    if (!turnoverDateSize) return null;
    return formatMessage("turnoverSizeTip", {
      size: turnoverDateSize,
    });
  } else {
    const turnoverYield = roundTo(4)(
      fastNth(1)(
        last(fastProp("data")(fastNth(0)(chartSeries))) as [string, number]
      )
    );
    const unTurnoverYield = roundTo(4)(
      fastNth(1)(
        last(fastProp("data")(fastNth(1)(chartSeries))) as [string, number]
      )
    );
    if (
      !turnoverYield ||
      !unTurnoverYield ||
      !turnoverDate ||
      turnoverYield === unTurnoverYield
    )
      return null;
    return formatMessage("turnoverYieldTip", {
      turnoverDate,
      type:
        turnoverYield > unTurnoverYield
          ? formatMessage("optimize")
          : formatMessage("deterioration"),
    });
  }
};

export const useGetPortfolioAndBenchmarkInfo = (id: string) => {
  const { tradingDate, dailyReturn, benchmark, portfolioName } = useAppSelector(
    (state: RootState) => analysisBasicInfoSelector(state, id)
  );

  const portfolioInfo = useMemo(
    () => ({
      name: portfolioName,
      ...normalizeDailyReturns(tradingDate, dailyReturn),
    }),
    [dailyReturn, portfolioName, tradingDate]
  );
  const benchmarkInfo = useGetBenchmarkDailyReturn(benchmark);
  const benchmarkDailyReturns = useMemo(
    () =>
      map((date: string) =>
        formatNilToZero(fastProp(date)(benchmarkInfo.dailyReturnsMap))
      )(tradingDate),
    [benchmarkInfo.dailyReturnsMap, tradingDate]
  );
  return {
    tradingDate,
    portfolioInfo,
    benchmarkInfo: useMemo(
      () => ({
        ...benchmarkInfo,
        allDailyReturns: benchmarkInfo.dailyReturns,
        dailyReturns: benchmarkDailyReturns,
      }),
      [benchmarkDailyReturns, benchmarkInfo]
    ),
  };
};
export const useGetLineChartConfig = ({
  id,
  turnoverDateMap,
  onChangeButtonGroupValue,
  onChangeTurnoverDate,
  buttonGroupValue,
  selectedTurnoverDate,
  unTurnoverDailyReturns,
  turnoverTradingDates,
}: {
  id: string;
  turnoverTradingDates: string[];
  selectedTurnoverDate: string | undefined;
  turnoverDateMap: Record<string, any>;
  onChangeButtonGroupValue: React.Dispatch<React.SetStateAction<string>>;
  onChangeTurnoverDate: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  unTurnoverDailyReturns: number[];
  buttonGroupValue: string;
}) => {
  const { tradingDate, portfolioInfo, benchmarkInfo } =
    useGetPortfolioAndBenchmarkInfo(id);
  const formatMessage = useFormatMessage();
  const unTurnoverInfo = useMemo(
    () => ({
      name: formatMessage("unTurnoverAttribution"),
      ...normalizeDailyReturns(turnoverTradingDates, unTurnoverDailyReturns),
    }),
    [formatMessage, turnoverTradingDates, unTurnoverDailyReturns]
  );
  const allRangeConfigs = useMemo(
    () =>
      buttonGroupValue === turnoverOptionsConfig.allRange &&
      getAllRangeChartConfigs({
        turnoverDateMap,
        onChangeButtonGroupValue,
        onChangeTurnoverDate,
        portfolioInfo,
        benchmarkInfo,
        tradingDate,
      }),
    [
      benchmarkInfo,
      buttonGroupValue,
      onChangeButtonGroupValue,
      onChangeTurnoverDate,
      portfolioInfo,
      tradingDate,
      turnoverDateMap,
    ]
  );
  const turnoverDateChartConfigs = useMemo(
    () =>
      buttonGroupValue === turnoverOptionsConfig.warehouseTime &&
      getTurnoverDateChartConfigs({
        selectedTurnoverDate,
        portfolioInfo,
        benchmarkInfo,
        unTurnoverInfo,
        turnoverTradingDates,
      }),
    [
      benchmarkInfo,
      buttonGroupValue,
      portfolioInfo,
      selectedTurnoverDate,
      turnoverTradingDates,
      unTurnoverInfo,
    ]
  );
  return allRangeConfigs || turnoverDateChartConfigs || defaultSeriesConfig;
};

export const useGetTurnoverTableData = (
  selectedTurnoverDate: string | undefined,
  turnoverViews: TurnoverRecordHistoryAssetType[]
) => {
  const fundsMap = useAppSelector(fundIdMapSelector);
  const { onRequestFundManagers, getFundManagersByDateAndId } =
    useGetFundManagersByDate();
  const fundIds = useMemo(() => map("fundId")(turnoverViews), [turnoverViews]);
  useEffect(() => {
    if (selectedTurnoverDate) {
      onRequestFundManagers(selectedTurnoverDate, fundIds);
    }
  }, [fundIds, onRequestFundManagers, selectedTurnoverDate]);

  return useMemo(
    () =>
      map<TurnoverRecordHistoryAssetType, ChangingPositionHistoryAssetType>(
        (asset) => {
          const {
            fundId,
            beforeScale,
            beforeWeight,
            afterScale,
            afterWeight,
            tradeFee,
          } = asset || {};
          const tradeType = getTradeType(beforeScale, afterScale);
          return {
            beforeWeight,
            afterWeight,
            beforeScale,
            afterScale,
            fundId,
            ...fastProp(fundId)(fundsMap),
            fundManagers: getFundManagersByDateAndId(
              selectedTurnoverDate as string,
              fundId
            ),
            tradeFee: fixedNumberWithCommas(tradeFee),
            tradeType,
            tradeTypeText: prop(`${tradeType}.label`)(
              transactionTypeOptionsMap
            ),
            tradeCash: fixedNumberWithCommas(
              Math.abs(afterScale - beforeScale)
            ),
          };
        }
      )(turnoverViews),
    [fundsMap, getFundManagersByDateAndId, selectedTurnoverDate, turnoverViews]
  );
};

export const useManageTurnoverTableConfigs = (
  selectedTurnoverDate: string | undefined,
  turnoverViews: TurnoverRecordHistoryAssetType[]
) => {
  const { analysisType } = useContext(PortfolioAnalysisContext);
  const updateColumns = useMemoizedFn((columns: ColumnType<any>[]) => {
    if (portfolioAnalysisConfig.isSimulation(analysisType)) return columns;
    return reject<ColumnType<any>>(({ dataIndex }) => dataIndex === "tradeFee")(
      columns
    );
  });
  const dataSource = useGetTurnoverTableData(
    selectedTurnoverDate,
    turnoverViews
  );
  const scroll = useMemo(
    () =>
      getTableScroll({
        dataSource,
        lineHeight: 41,
        maxYScroll: 410,
      }),
    [dataSource]
  );
  return { dataSource, scroll, updateColumns };
};
