import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useFormatMessage } from "@/util/formatMessage";
import { useManageTableState } from "@/views/portfolioManage/portfolioList/hooks";
import { useMemoizedFn } from "ahooks";
import {
  Space,
  Card,
  Table,
  Input,
  Button,
  Pagination,
  Modal,
  message,
} from "antd";
import { first, prop, size } from "lodash/fp";
import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import style from "./index.module.less";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  fetchCompetitionRankBasicInfo,
  fetchWantCompetition,
} from "@/store/competition";
import { fastProp, normalize } from "@/util/opt";
import { PlusOutlined } from "@ant-design/icons";
import { competitionDetailSelector } from "../competitionDetail/selectors";
import CompetitionHead from "./components/competitionHead";
import {
  portfolioIndex,
  useGetPortfolioColumns,
  useGetRowSelection,
} from "./hook";
import EmptyContent from "@/components/emptyContent";
import { dailyRiseAndFall } from "../competitionDetail/constant";
import { useGetFactorData } from "../competitionDetail/hook";
const { Search } = Input;

const Competition = ({
  competitionId,
  type,
}: {
  competitionId: string;
  type: string;
}): JSX.Element => {
  const { stackBack } = useContext(PlatformNavigationContext);
  const formatMessage = useFormatMessage();
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const goToSimulationPortfolioPage = useMemoizedFn(() =>
    navigate("/manage/portfolioList")
  );
  const portfolioColumns = useGetPortfolioColumns();
  const { setSearchValue, pageInfo, changePages, dataSource } =
    useManageTableState(portfolioIndex, "myPortfolio");
  const dataSourceMap = useMemo(
    () => normalize("id")(dataSource),
    [dataSource]
  );
  const competitionDetail = useAppSelector(competitionDetailSelector);

  const onSearch = useMemoizedFn((v) => {
    setSearchValue(v);
    changePages(1);
  });

  const [selectedPortfolioId, rowSelection] = useGetRowSelection();
  const { selectedTag } = useGetFactorData();

  const handleOk = useMemoizedFn(() => {
    dispatch(
      fetchWantCompetition({
        competitionId: competitionId,
        portfolioId: first(selectedPortfolioId) as string,
      })
    )
      .unwrap()
      .then(() => {
        message.success(formatMessage("entryCompetitionSuccess"));
        dispatch(
          fetchCompetitionRankBasicInfo({
            competitionId: competitionId,
            factorId:
              type === "Day_Yield"
                ? dailyRiseAndFall
                : `${type}_${selectedTag}`,
            type: type,
          })
        );
        stackBack();
      })
      .finally(() => {
        setVisible(false);
      });
  });

  return (
    <div className={style.PortfolioList}>
      <Space direction="vertical" className={style.FullWidth}>
        <CompetitionHead competitionDetail={competitionDetail} />
        <Card title={formatMessage("chooseCompetitionGroup")} size="small">
          <Space direction="vertical" className={style.FullWidth} size={10}>
            <div className={style.ChooseHead}>
              <Search
                enterButton={formatMessage("inquire")}
                onSearch={onSearch}
                className={style.SearchStyle}
              />
              <Button
                type="primary"
                onClick={() => goToSimulationPortfolioPage()}
              >
                {formatMessage("toCreatePortfolio")}
              </Button>
            </div>
            {size(dataSource) ? (
              <div className={style.ListTable}>
                <Table
                  columns={portfolioColumns}
                  pagination={false}
                  rowKey="id"
                  dataSource={dataSource}
                  rowSelection={rowSelection}
                  tableLayout="fixed"
                  scroll={{ y: 400 }}
                />
                <Pagination
                  className={style.TablePagination}
                  {...pageInfo}
                  showSizeChanger
                  showQuickJumper
                  onChange={changePages}
                />
              </div>
            ) : (
              <div className={style.EmptyCompetition}>
                <EmptyContent
                  className={style.EmptyContent}
                  message={formatMessage("emptyCompetitionTip")}
                >
                  <Button
                    icon={<PlusOutlined />}
                    className={style.AddBenchmark}
                    // ghost
                    type="primary"
                    onClick={() => goToSimulationPortfolioPage()}
                  >
                    {formatMessage("toCreatePortfolio")}
                  </Button>
                </EmptyContent>
              </div>
            )}
          </Space>
        </Card>
      </Space>
      <br />
      <footer className={style.LayoutFooter}>
        <Space>
          <Button onClick={stackBack} type="default">
            {" "}
            {formatMessage("cancel")}
          </Button>
          <Button
            disabled={size(selectedPortfolioId) ? false : true}
            onClick={() => setVisible(true)}
            type="primary"
          >
            {" "}
            {formatMessage("ok")}
          </Button>
        </Space>
      </footer>
      <Modal
        title={formatMessage("entryCompetitionTip")}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={320}
        footer={null}
      >
        <div className={style.CompetitionModal}>
          <Space direction="vertical">
            <div className={style.CompetitionModalTip}>
              <Space>
                <InfoCircleOutlined />
                {formatMessage("confirmEntryInformation")}
              </Space>
            </div>
            <p>
              {formatMessage("competitionGroup")}:
              <span className={style.CompetitionContent}>
                {prop(`${first(selectedPortfolioId)}.name`)(dataSourceMap) ||
                  "--"}
              </span>
            </p>
            <p>
              {formatMessage("competitionZone")}:
              <span className={style.CompetitionContent}>
                {fastProp("name")(competitionDetail) || "--"}
              </span>
            </p>
            <div className={style.ModalFooter}>
              <Space>
                <Button
                  onClick={() => setVisible(false)}
                  type="default"
                  size="middle"
                  className={style.Button}
                >
                  {" "}
                  {formatMessage("cancel")}
                </Button>
                <Button
                  disabled={size(selectedPortfolioId) ? false : true}
                  onClick={() => handleOk()}
                  type="primary"
                  size="middle"
                  className={style.Button}
                >
                  {" "}
                  {formatMessage("ok")}
                </Button>
              </Space>
            </div>
          </Space>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(Competition);
