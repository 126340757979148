import React from "react";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import style from "./index.module.less";

export default React.memo<{
  collapsed: boolean;
  setCollapsed: (arg: boolean) => void;
  className?: string;
}>(({ collapsed, setCollapsed, className }) => (
  <span className={className} onClick={() => setCollapsed(!collapsed)}>
    {collapsed ? (
      <MenuUnfoldOutlined className={style.Icon} />
    ) : (
      <MenuFoldOutlined className={style.Icon} />
    )}
  </span>
));
