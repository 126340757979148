import React, { useEffect } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import style from "../index.module.less";
import BasicInfo from "./components/basicInfo";
import { useAppDispatch } from "@/hooks/redux";
import {
  clearFundManagerResult,
  fetchFundManagerCompareDailyReturn,
  getFundManagerReportDate,
  getFundManagerYearReportDate,
} from "@/store/compareManage";
import { Space } from "antd";
import Profitability from "./components/profitability";
import ManagerDrawal from "./components/managerDrawal";
import ManageFundScaleChange from "./components/manageFundScaleChange";
import Top10Stocks from "./components/top10Stocks";
import IndustryPreference from "./components/industryPreference";
import LoadingComponent from "@/components/LoadingComponent";

export default React.memo<{
  managerIds: string[];
}>(({ managerIds }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  useEffect(
    () => () => {
      dispatch(clearFundManagerResult());
    },
    [dispatch]
  );
  useEffect(() => {
    dispatch(getFundManagerReportDate(managerIds));
    dispatch(getFundManagerYearReportDate(managerIds));
  }, [dispatch, managerIds]);
  return (
    <div className={style.Result}>
      <h2 className={style.margin20}>{formatMessage("compareResult")}</h2>
      <Space direction="vertical" className={style.fullWidth} size={12}>
        <BasicInfo managerIds={managerIds} />
        <LoadingComponent actions={fetchFundManagerCompareDailyReturn}>
          <Profitability managerIds={managerIds} />
          <ManagerDrawal managerIds={managerIds} />
        </LoadingComponent>
        <ManageFundScaleChange managerIds={managerIds} />
        <IndustryPreference managerIds={managerIds} />
        <Top10Stocks managerIds={managerIds} />
      </Space>
    </div>
  );
});
