/**
 * 跟踪误差
 */
import { flow, isEmpty, toNumber } from "lodash/fp";
import { zipWithMinus } from "@/util/opt";
import tradingDateCount from "@/constant/tradingDateCount";
import { toStandardDeviation } from "../math";
import { formatArrayNilToZero } from "../numberFormatter";

export default (dailyReturns: number[], dailyBenchmarkReturns: number[]) => {
  if (isEmpty(dailyReturns) || isEmpty(dailyBenchmarkReturns)) return 0;
  const subArr = zipWithMinus(
    formatArrayNilToZero(dailyReturns),
    formatArrayNilToZero(dailyBenchmarkReturns)
  );
  return flow(
    toStandardDeviation,
    toNumber,
    (res) => res * Math.sqrt(tradingDateCount)
  )(subArr);
};
