import { formatPercentage } from "@/util/numberFormatter";
import { Progress, ProgressProps } from "antd";

const ReProgress = ({ percent, ...props }: ProgressProps): JSX.Element => {
  return (
    <Progress
      {...props}
      percent={(percent as number) * 100}
      format={(percent) => formatPercentage((percent as number) / 100)}
    />
  );
};

export default ReProgress;
