import { ReactNode, useCallback, useMemo } from "react";
import { ColumnsType } from "antd/lib/table";
import { EChartsOption } from "echarts";
import { useMemoizedFn } from "ahooks";
import { compact, isEmpty, isNil, isNumber, merge, prop } from "lodash/fp";

import { fastProp, mapIndexed } from "@/util/opt";
import {
  EvaluationReportInfoBody,
  MarketCapabilityDetailParam,
  StockSelectionAbilityBody,
  TurnoverImprovementAbilityBody,
} from "@/model/fundDetail";
import { useFormatMessage } from "@/util/formatMessage";
import star from "@/assets/fundEvaluationReport/star.svg";
import {
  CHART_COLOR,
  getCompareValueMsgKey,
} from "@/components/fundInfoExport/constants";

import Rate from "../../rate";
import style from "./index.module.less";
import {
  useComprehenPerformance,
  useGetBarChartAndSummaryData,
} from "../secondPage/hook";
import {
  dispersionDegreeMap,
  RatePerformanceMap,
} from "../secondPage/contants";
import { useAppSelector } from "@/hooks/redux";
import { toAverage } from "@/util/math";
import { getNumberLength } from "@/util/numberFormatter";

type BaseInfoTableDataType = {
  key: number;
  colomn1: ReactNode;
  colomn1Val: ReactNode;
  colomn2: ReactNode;
  colomn2Val: ReactNode;
};
export const useGetBasicInfoTableDataSource = (
  fundEvaluationReportInfo: EvaluationReportInfoBody | undefined
) => {
  const formatHundredMillion = useCallback((value: number) => {
    if (isNil(value) || isNaN(value) || !isNumber(value)) return "--";
    const length = getNumberLength(value);
    if (length > 8) {
      const final = value / 10 ** 8;
      return `${final.toFixed(2)} 亿`;
    }
    const final = value / 10 ** 4;
    return `${final.toFixed(2)} 万`;
  }, []);

  return useMemo(
    () => [
      {
        key: 1,
        colomn1: "fundEvaluation",
        colomn1Val: (
          <Rate
            value={fastProp("fundEvaluation")(fundEvaluationReportInfo) || 0}
          />
        ),
        colomn2: "fundManager",
        colomn2Val: fastProp("fundManager")(fundEvaluationReportInfo),
      },
      {
        key: 2,
        colomn1: "evaluationFundType",
        colomn1Val: `${fastProp("fundTypeName")(fundEvaluationReportInfo)}型`,
        colomn2: "fundCompany",
        colomn2Val: fastProp("fundCompany")(fundEvaluationReportInfo),
      },
      {
        key: 3,
        colomn1: "currentScale",
        colomn1Val: formatHundredMillion(
          fastProp("fundScale")(fundEvaluationReportInfo)
        ),
        colomn2: "foundDate",
        colomn2Val: fastProp("foundDate")(fundEvaluationReportInfo),
      },
    ],
    [formatHundredMillion, fundEvaluationReportInfo]
  );
};

export const useGetBasicInfoTableColumns = () => {
  const formatMessage = useFormatMessage();
  const formatNilData = useMemoizedFn((val: any) => {
    if (isNil(val) || isEmpty(val)) return "--";
    return val;
  });
  return useMemo<ColumnsType<BaseInfoTableDataType>>(
    () => [
      {
        key: "colomn1",
        dataIndex: "colomn1",
        width: 110,
        align: "center",
        className: style.Label,
        render: (value: string) => formatMessage(value),
      },
      {
        key: "colomn1Val",
        dataIndex: "colomn1Val",
        align: "left",
        render: formatNilData,
      },
      {
        key: "colomn2",
        dataIndex: "colomn2",
        width: 110,
        align: "center",
        className: style.Label,
        render: (value: string) => formatMessage(value),
      },
      {
        key: "colomn2Val",
        dataIndex: "colomn2Val",
        align: "left",
        render: formatNilData,
      },
    ],
    [formatMessage, formatNilData]
  );
};

export const useGetFactorEvaluationOptions = (
  gaugeData: Record<string, any>[],
  width: number
) => {
  const pixelRatio = useMemo(() => width / 260, [width]);
  return useMemo<EChartsOption>(
    () => ({
      graphic: [
        {
          type: "circle",
          left: "center",
          top: "middle",
          shape: {
            r: 75 * 0.5 * pixelRatio,
          },
          style: {
            fill: "#E6ECF5",
          },
        },
      ],
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      xAxis: { show: false },
      series: [
        {
          type: "gauge",
          anchor: {
            show: true,
            showAbove: true,
            size: 20 * pixelRatio,
            itemStyle: {
              color: "#A7B5C7",
            },
          },
          pointer: {
            icon: "path://M0 1000 L250 0 L500 1000 Z",
            width: 10 * pixelRatio,
            length: "70%",
            offsetCenter: [0, "8%"],
            itemStyle: {
              color: "#aebbc4",
            },
          },
          min: 0,
          max: 100,
          progress: {
            show: true,
            overlap: true,
            roundCap: false,
            width: 10 * pixelRatio,
          },
          axisTick: {
            show: true,
            splitNumber: 8,
            length: 8,
            distance: 6,
            lineStyle: {
              width: 2,
              color: {
                type: "linear",
                x: 50,
                y: 120,
                x2: 200,
                y2: 120,
                global: true,
                colorStops: [
                  {
                    offset: 0,
                    color: "#C1DFCF",
                  },
                  {
                    offset: 0.33,
                    color: "#DDC6A8",
                  },
                  {
                    offset: 0.66,
                    color: "#DDC6A8",
                  },
                  {
                    offset: 1,
                    color: "#D9AEA8",
                  },
                ],
              },
            },
          },
          axisLabel: {
            show: false,
          },
          axisLine: {
            roundCap: false,
          },
          splitLine: {
            show: false,
          },
          data: gaugeData,
          title: {
            fontSize: 18 * pixelRatio,
          },
        },
      ],
    }),
    [gaugeData, pixelRatio]
  );
};

export const useGetDefaultRadarOptions = (options: EChartsOption) =>
  useMemo<EChartsOption>(() => {
    const starIcon = new Image();
    starIcon.src = star;
    return merge<EChartsOption>({
      grid: {
        left: 0,
        right: 0,
        top: 50,
        bottom: 0,
        containLabel: true,
      },
      legend: {
        show: true,
        icon: "rect",
        left: "center",
        bottom: 0,
        itemHeight: 9,
        itemGap: 34,
        textStyle: {
          fontSize: 14,
          color: "#666666",
        },
      },
      color: CHART_COLOR,
      radar: {
        radius: 80,
        splitArea: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            color: "#D3D4D4",
          },
        },
        axisName: {
          color: "#666666",
          fontSize: 14,
          rich: {
            star: {
              lineHeight: 20,
              fontSize: 12,
              align: "center",
              verticalAlign: "bottom",
              backgroundColor: {
                image: starIcon,
              },
            },
          },
        },
      },
    })(options);
  }, [options]);

type MapperKeyType = 1 | 3 | "HIGH" | "LOW" | "HIGHER" | "LOWER";
export const useGetFundTags = (fundId: string) => {
  const formatMessage = useFormatMessage();

  const { summary } = useComprehenPerformance(fundId);
  const { performanceRating, riskLevel, holdingExperience } = useMemo(
    () => summary || {},
    [summary]
  );

  const { top3IndustryWeightRating, industryAllocationCompareWithSameType } =
    useGetBarChartAndSummaryData(fundId);

  const {
    positionStockDisperseGrade,
    topStockAdjustGrade,
    brinsonSelection,
    multiFactorSelection,
  }: StockSelectionAbilityBody =
    useAppSelector(prop(`fundDetail.${fundId}.stockSelectionAbility`)) || {};

  const { extendNewStocksAbility }: TurnoverImprovementAbilityBody =
    useAppSelector(prop(`fundDetail.${fundId}.turnoverImprovementAbility`)) ||
    {};

  const { desireGrade, newStockQuality } = extendNewStocksAbility || {};

  const {
    marketCapabilityLevel,
    offensiveAbilityLevel,
    defensiveAbilityLevel,
  }: MarketCapabilityDetailParam =
    useAppSelector(prop(`fundDetail.${fundId}.marketCapabilityDetail`)) || {};

  const tagData = useMemo(
    () => ({
      performanceRating,
      riskLevel,
      holdingExperience,
      top3IndustryWeightRating,
      industryAllocationCompareWithSameType,
      positionStockDisperseGrade,
      topStockAdjustGrade,
      stockSelectionAbility: getCompareValueMsgKey({
        fundValue: toAverage([
          fastProp("fundValue")(brinsonSelection),
          fastProp("fundValue")(multiFactorSelection),
        ]),
        sameTypeAvg: toAverage([
          fastProp("sameTypeAvg")(brinsonSelection),
          fastProp("sameTypeAvg")(multiFactorSelection),
        ]),
      }),
      desireGrade,
      newStockQuality,
      marketCapabilityLevel,
      offensiveAndDefensiveAbilityLevel:
        offensiveAbilityLevel === defensiveAbilityLevel
          ? offensiveAbilityLevel
          : null,
    }),
    [
      brinsonSelection,
      defensiveAbilityLevel,
      desireGrade,
      holdingExperience,
      industryAllocationCompareWithSameType,
      marketCapabilityLevel,
      multiFactorSelection,
      newStockQuality,
      offensiveAbilityLevel,
      performanceRating,
      positionStockDisperseGrade,
      riskLevel,
      top3IndustryWeightRating,
      topStockAdjustGrade,
    ]
  );

  const mapper = useMemo(
    () => ({
      ...RatePerformanceMap,
      ...dispersionDegreeMap,
    }),
    []
  );

  return useMemo(() => {
    const excellentFundTags: string[] = [];
    const poorFundTags: string[] = [];

    mapIndexed((item: MapperKeyType, key: string) => {
      if (item === 1 || item === "HIGH" || item === "HIGHER")
        excellentFundTags.push(formatMessage(`${key}${mapper[item]}`));
      else if (item === 3 || item === "LOW" || item == "LOWER")
        poorFundTags.push(formatMessage(`${key}${mapper[item]}`));
    })(tagData);

    return {
      excellentFundTags: compact(excellentFundTags.join(",").split(",")),
      poorFundTags: compact(poorFundTags.join(",").split(",")),
    };
  }, [formatMessage, mapper, tagData]);
};
