/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  assign,
  forEach,
  identity,
  isEmpty,
  map,
  mapKeys,
  mapValues,
  prop,
  replace,
  some,
  isNaN,
  isNil,
  sum,
} from "lodash/fp";
import { zipWith } from "lodash";

export const forEachIndexed = forEach.convert({ cap: false });
export const mapIndexed = map.convert({ cap: false });
export const mapValuesIndexed = mapValues.convert({ cap: false });
export const mapKeysIndexed = mapKeys.convert({ cap: false });
export const someIndexed = some.convert({ cap: false });

export const fastProp = (key: string) => (object?: { [x: string]: any }) =>
  object &&
  Object.prototype.toString.apply(object) !== "[object Null]" &&
  Object.prototype.toString.apply(object) !== "[object Undefined]"
    ? object[key]
    : null;

export const equalNaN = (value: any): boolean =>
  isNaN(value) || value === "NaN" || Number.isNaN(value);

export const equalNil = (value: any): boolean =>
  value === null ||
  value === undefined ||
  isNil(value) ||
  value === "null" ||
  value === "undefined";

export const fastHas = (key: string) => (object: Record<string, unknown>) =>
  // eslint-disable-next-line no-prototype-builtins
  object != null && object.hasOwnProperty(key);

export const size = (arr: any) => arr && arr.length;

export const getProp = (key: string) => (object: { [x: string]: any }) => {
  if (key === undefined || key === null) return undefined;
  const keysArr: string[] = key.split(".");
  const length = keysArr.length;
  let index = 0;
  while (object != null && index < length) {
    object = object[keysArr[index++]] || "";
  }
  return object;
};

export function normalize<T>(key?: string): (input: T[]) => Record<string, T>;
export function normalize<T extends Record<string | symbol, unknown>>(
  key?: string
): (input: T[]) => Record<string, T>;
export function normalize<T extends Record<string | symbol, unknown>, R = T>(
  key: string,
  getValue: (v: T, index: number, preV?: R) => R
): (input: T[]) => Record<string, R>;

export function normalize<T extends Record<string | symbol, any>, R = T>(
  key?: string,
  getValue?: (v: T, index: number, preV?: R) => R
) {
  return (input: T[]): any => {
    if (getValue) {
      const res: Record<string, R> = {};
      const inputLen = input?.length;
      for (let index = 0; index < inputLen; index += 1) {
        if (key === undefined) {
          const value = input[index] as unknown as string;
          res[value] = getValue(input[index], index, res[value]);
        } else if (input[index]?.[key] !== undefined) {
          if (res[input[index]?.[key]]) {
            res[input[index]?.[key]] = getValue(
              input[index],
              index,
              res[input[index]?.[key]]
            );
          } else {
            res[input[index]?.[key]] = getValue(input[index], index);
          }
        }
      }
      return res;
    }
    const res: Record<string, T> = {};
    const inputLen = input?.length;
    for (let index = 0; index < inputLen; index += 1) {
      if (key === undefined) {
        const value = input[index] as unknown as string;
        res[value] = input[index];
      } else if (input[index]?.[key] !== undefined) {
        res[input[index]?.[key]] = input[index];
      }
    }
    return res;
  };
}

export const includeStr =
  (keyword: string) =>
  (str: string | string[]): boolean =>
    (str && str.indexOf(keyword) > -1) as boolean;

type ObjKey = string | number | symbol;

export function arrToObj(arr: ObjKey[]): Record<ObjKey, boolean> {
  const res: Record<ObjKey, boolean> = {};
  for (let i = 0; i < arr.length; i++) {
    res[arr[i]] = true;
  }
  return res;
}
export const floatEqual = (v: number) => (compareV: number) =>
  !equalNaN(v) && !equalNaN(compareV) && Math.abs(v - compareV) < 0.0000001;

export const arrayToMap = (array: string[]): Record<string, boolean> => {
  const object = {} as Record<string, boolean>;
  forEach<string>((value) => {
    object[value] = true;
  })(array);
  return object;
};

export const eqNaN = (str: string | number) => str === "NaN";

export const fastSize = (arr: Array<any>) =>
  arr !== null && arr !== undefined && arr.length;

export const fastNth = (index: number) => (arr: any[]) => arr && arr[index];

export const overrideBy =
  <O extends Record<string, any>, N extends Record<string, any>>(newObj: N) =>
  (oldObj: O): N & O => ({
    ...oldObj,
    ...newObj,
  });

export const zipWithMinus = (...arrs: number[][]) =>
  zipWith<number, number>(...arrs, (firstV, ...values) => {
    let res = firstV;
    forEach((value: number) => {
      res -= value;
    })(values);
    return res;
  });
export const zipWithPlus = (...arrs: number[][]) =>
  zipWith(...arrs, (...args) => sum(args));

export function escapeRegExpFn(target: string) {
  // eslint-disable-next-line no-useless-escape
  return replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")(target);
}

export const expandObjectArrayToObject = (arrs = []) => {
  if (isEmpty(arrs)) return {};
  let obj = {};
  forEach((item) => {
    obj = assign(item)(obj);
  })(arrs);
  return obj;
};

/**
 *
 * @param {String | Function | null | undefined} key
 * @returns {Function}
 * 辅助函数：根据传入的key类型来返回对于的操作，这个key一般是传入来获取数组item中某一项的值
 * 1.如果为null或undefined，表示直接获取这个值，返回identity
 * 2.如果是函数，则直接返回这个函数
 * 3.如果包含.则认为是取object的某一个项，用getProp
 * 4.如果不包含.，则直接用fastProp
 * 目前我们的获取item操作用法中大致包含上面四种类型
 */
export const getOperateFunc = (
  key: string | null | undefined | ((...arg: any[]) => any)
) => {
  if (!key) return identity;
  if (typeof key === "function") return key;
  if (includeStr(".")(key)) return prop(key);
  return fastProp(key);
};

export const approachEqual = (
  number: number,
  benchmark: number,
  precision = 7,
  waterMark = 1
) => Math.abs(number - benchmark) * Math.pow(10, precision) < waterMark;

export const parseUrlString = (url: string) => {
  const query = url.split("?")[1];
  if (!query) return {};
  const parts = query.split("&");
  const params = Object.create(null);

  for (let i = 0, ii = parts.length; i < ii; ++i) {
    const param = parts[i].split("=");
    const key = param[0].toLowerCase();
    const value = param.length > 1 ? param[1] : "";
    params[decodeURIComponent(key)] = decodeURIComponent(value);
  }

  return params;
};
