import { SameTypeRankParam } from "@/model/fundDetail";
import { FormatMessageFunc } from "@/util/formatMessage";
import getMessage from "@/util/getMessage";
import { fastProp } from "@/util/opt";
// import { includes } from "lodash/fp";
import style from "./index.module.less";

export const getColumns = (formatMessage: FormatMessageFunc) => {
  return [
    {
      title: " ",
      dataIndex: "name",
      key: "name",
      width: 150,
      align: "center",
      className: style.FactorName,
    },
    {
      title: formatMessage("FundPerformance"),
      dataIndex: "fundPerformance",
      key: "fundPerformance",
      width: 150,
      align: "center",
      className: style.RankNum,
    },
    {
      title: formatMessage("MarketPerformance"),
      dataIndex: "marketPerformance",
      key: "marketPerformance",
      width: 150,
      align: "center",
      className: style.RankNum,
    },
    {
      title: formatMessage("rankByCategory"),
      dataIndex: "sameTypeFactorRank",
      key: "sameTypeFactorRank",
      width: 150,
      align: "center",
      render: (data: SameTypeRankParam) => {
        return (
          <span className={style.RankByCategory}>
            <span className={style.RankNum}>{data?.rank}</span>/{data?.count}
          </span>
        );
      },
    },
  ];
};

export const getComprePerformanceOptions = (
  yearOfDates: Record<string, any>
) => ({
  grid: { left: 0, top: 20, bottom: 60, right: 20 },
  xAxis: {
    boundaryGap: false,
    axisTick: {
      show: false,
    },
    axisLabel: {
      hideOverlap: false,
      interval: (index: number, value: string) => {
        const year = fastProp(value)(yearOfDates);
        return year ? true : false;
      },
      formatter(value: string) {
        return `${fastProp(value)(yearOfDates)}${getMessage("year")}`;
      },
      align: "right",
    },
  },
  yAxis: {
    splitLine: { show: false },
    axisLabel: {
      fontWeight: "bold",
      color: "#222222",
      fontFamily: "DINAlternate",
    },
  },
  legend: {
    left: "center",
    bottom: 28,
    top: "auto",
    itemGap: 30,
    // padding: 0,
  },
});

export const RatePerformanceMap = {
  1: "GOOD",
  2: "ORDINARY",
  3: "POOR",
};
export const dispersionDegreeMap = {
  HIGH: "high",
  MEDIUM: "moderate",
  LOW: "low",
  HIGHER: "HIGHER",
  LOWER: "LOWER",
};

export const CompareLevel = {
  LOWER: "lowerThan",
  HIGHER: "overtop",
  EQUAL: "EQUAL",
};
export const riskLevelMap = {
  1: "highYieldLowRisk",
  2: "matchBenefitsWithRisks",
  3: "lowYieldAndLowRisk",
};
export const riskLevelMap1 = {
  1: "higher",
  2: "ORDINARY",
  3: "POOR",
};

export const compareLevel = (fistVal: number, secondVal: number) => {
  if (fistVal > secondVal) {
    return getMessage("overtop");
  }
  if (fistVal < secondVal) {
    return getMessage("lowerThan");
  }
  if (fistVal == secondVal) {
    return getMessage("EQUAL");
  }
};
