import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  login,
  socketAuthorization as socketAuthorizationAction,
  getDataSource as getDataSourceApi,
  getUserProfile,
  getUsers,
} from "@/api/userConfig";
import createSocketThunk from "@/util/createSocketThunk";
import type {
  LoginEntity,
  LoginResponse,
  DataSource,
  User,
} from "@/model/login";

export const socketAuthorization = createSocketThunk(
  "users/socketAuthorization",
  null,
  (authorization: string, { getState }) => {
    const state = getState();
    const dataSource = state.users.dataSource;
    return socketAuthorizationAction(authorization, dataSource?.[0]?.id);
  }
);

export const fetchUserById = createAsyncThunk<LoginResponse, LoginEntity>(
  "/users/Login",
  async (loginInfo, thunkApi) => {
    try {
      const response = await login(loginInfo);
      return response;
    } catch (error: any) {
      throw thunkApi.rejectWithValue({
        message: error.message,
        status: error.response.status,
      });
    }
  }
);

export const getDataSource = createAsyncThunk(
  "/users/getDataSource",
  async () => {
    const response = await getDataSourceApi();
    return response;
  }
);

export const fetchUserProfile = createAsyncThunk("/users/profile", async () => {
  const response = await getUserProfile();
  return response;
});

export const fetchUsers = createAsyncThunk("/users/list", async () => {
  const response = await getUsers();
  return response;
});

interface LoginState {
  dataSource: DataSource[];
  user: User | Record<string, any>;
  users: User[];
}

const initialState: LoginState = {
  dataSource: [],
  user: {},
  users: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [getDataSource.fulfilled.type]: (state, action) => {
      state.dataSource = action.payload;
    },
    [fetchUserProfile.fulfilled.type]: (state, action) => {
      state.user = action.payload;
    },
    [fetchUsers.fulfilled.type]: (state, action) => {
      state.users = action.payload;
    },
  },
});

export default usersSlice.reducer;
