import {
  addTagApi,
  deleteTagApi,
  editTagApi,
  editTagParam,
  getAllCustomerTagApi,
} from "@/api/createCustomer";
import { tagItem } from "@/model/customer";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash/fp";

export const fetchAllcustomerTag = avoidMultiRequestActionThunk<void>(
  "createCustomer.allcustomerTag",
  createAsyncThunk("createCustomer/allcustomerTag", async () => {
    const response = await getAllCustomerTagApi();
    return response;
  })
);

export const createCustomerTag = createAsyncThunk(
  "createCustomer/createCustomerTag",
  async (name: string) => {
    const response = await addTagApi(name);
    return response;
  }
);
export const editCustomerTag = createAsyncThunk(
  "createCustomer/editCustomerTag",
  async (data: editTagParam) => {
    const response = await editTagApi(data);
    return response;
  }
);
export const deleteCustomerTag = createAsyncThunk(
  "createCustomer/deleteCustomerTag",
  async (id: string) => {
    const response = await deleteTagApi(id);
    return response;
  }
);

interface CreateCustomerState {
  allCustomerTag: tagItem[];
}

const initialState: CreateCustomerState = {
  allCustomerTag: [],
};

const createCustomerSlice = createSlice({
  name: "createCustomer",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllcustomerTag.fulfilled.type]: (state, action) => {
      state.allCustomerTag = action.payload;
    },
    [deleteCustomerTag.fulfilled.type]: (state, action) => {
      if (action.meta?.arg) {
        state.allCustomerTag = state.allCustomerTag.filter(
          ({ id }) => id !== action.meta?.arg
        );
      }
    },
    [createCustomerTag.fulfilled.type]: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.allCustomerTag.unshift(action.payload);
      }
    },
    [editCustomerTag.fulfilled.type]: (state, action) => {
      state.allCustomerTag = state.allCustomerTag.map((item) => {
        if (item.id === action.meta?.arg?.id) {
          return {
            ...item,
            ...(action?.payload || {}),
          };
        }
        return item;
      });
    },
  },
});

export default createCustomerSlice.reducer;
