import React, { useMemo } from "react";

import { useFormatMessage } from "@/util/formatMessage";

import style from "../index.module.less";
import BaseEcharts from "../../../echarts/baseEcharts";
import { EChartsOption } from "echarts";
import { identity, join, map, prop, zip } from "lodash/fp";
import { toFixedNumber } from "@/util/numberFormatter";
import {
  EvaluationReportRiskSummaryBody,
  IndustryRiskDetailParam,
} from "@/model/fundDetail";
import { useAppSelector } from "@/hooks/redux";
// import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { getCostPerformance, getProsperity, getRiskLevel } from "../constants";
import SecondLevelName from "../../../secondLevelName";

export default React.memo(({ fundId }: { fundId: string }) => {
  const formatMessage = useFormatMessage();

  const marketCapabilityDetail = useAppSelector(
    prop(`fundDetail.${fundId}.riskDetail`)
  );
  const { industryRiskDetails } = marketCapabilityDetail || {};
  // const industryMap = useAppSelector(sectorCategoriesMapSelector);

  const { valueA, valueB, industries } = useMemo(() => {
    const valueA = map(
      (item: IndustryRiskDetailParam) => item?.industryProsperity
    )(industryRiskDetails);
    const valueB = map(
      (item: IndustryRiskDetailParam) => item?.industryCongestion
    )(industryRiskDetails);
    const industries = map(
      (item: IndustryRiskDetailParam) => item?.industryName
    )(industryRiskDetails);
    return { valueA, valueB, industries };
  }, [industryRiskDetails]);

  const evaluationReportRiskSummary: EvaluationReportRiskSummaryBody =
    useAppSelector(prop(`fundDetail.${fundId}.evaluationReportRiskSummary`));

  const { industryRisk } = evaluationReportRiskSummary || {};

  const options = useMemo<EChartsOption>(
    () => ({
      grid: { containLabel: true },
      xAxis: { type: "value", show: false, min: 0, max: 100 },
      legend: { itemGap: 80 },
      yAxis: {
        type: "category",
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          formatter: identity,
        },
      },
      series: [
        {
          name: formatMessage("prosperityScore"),
          type: "bar",
          label: {
            position: "insideLeft",
            color: "white",
            offset: [20, 0],
            formatter: (params) => {
              const value = prop("value.0")(params);
              return toFixedNumber(2)(value);
            },
          },
          data: zip(valueA)(industries) as [number, string][],
        },
        {
          name: formatMessage("crowdingScore"),
          type: "bar",
          label: {
            position: "insideLeft",
            color: "white",
            offset: [20, 0],
            formatter: (params) => {
              const value = prop("value.0")(params);
              return toFixedNumber(2)(value);
            },
          },
          data: zip(valueB)(industries) as [number, string][],
        },
      ],
    }),
    [industries, valueA, valueB, formatMessage]
  );

  return (
    <div className={style.RecentSituationOfHeavyWarehouseIndustryWrap}>
      <SecondLevelName
        name={formatMessage("recentSituationOfHeavyWarehouseIndustry")}
      >
        {industryRisk?.riskLevel && (
          <span className={style.RiskStatus1}>
            {formatMessage("risk")}
            {getProsperity(industryRisk?.riskLevel)}
          </span>
        )}
      </SecondLevelName>
      <div className={style.Text}>
        {formatMessage("heavyWarehouseIndustrySummary", {
          industries: join("、")(industries),
          prosperity: getProsperity(industryRisk?.industryProsperity),
          crowdDegree: getProsperity(industryRisk?.industryCongestion),
          costPerformance: getCostPerformance(
            industryRisk?.industryProsperity,
            industryRisk?.industryCongestion
          ),
          risk: (
            <span>
              {formatMessage("risk")}
              {getRiskLevel(industryRisk?.riskLevel)}
            </span>
          ),
        })}
      </div>
      <div className={style.ChartWrap}>
        <BaseEcharts
          options={options}
          height={272}
          opts={{ devicePixelRatio: 4 }}
        />
      </div>
    </div>
  );
});
