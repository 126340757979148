import {
  AddAssetInfoProp,
  CustomerBasicInfoBody,
  CustomerInfoProp,
  CustomerListBody,
  CustomerSummaryBody,
  CustomerVolatilityBody,
  CustomerVolatilityRequestParam,
  SaveCustomerParam,
} from "@/model/customer";
import { PromiseType } from "@/model/promise";
import { get, del, post } from "@/util/axios";
import { socketHttp } from "@/util/socket";

export const getCustomerListApi = (): PromiseType<CustomerListBody> =>
  get("/customer/manage/all");

export const getCustomerBasicInfo = (): PromiseType<CustomerBasicInfoBody> =>
  get("/customer/manager/portfolio/basic-info");

export const getCustomerVolatility = (
  params: CustomerVolatilityRequestParam
): PromiseType<CustomerVolatilityBody> =>
  get("/customer/manager/portfolio/customer-account/volatility", params);

export const getCustomerSummary = (): PromiseType<CustomerSummaryBody> =>
  get("/customer/manager/portfolio/summary");

export const deleteCustomerAccountApi = (id: number): PromiseType<void> =>
  del(`/customer/manage/${id}`, {});

export const getCustomerInfo = (id: number): PromiseType<CustomerInfoProp> =>
  get(`/customer/manage/${id}`);

//保存客户资产信息
export const onSaveCustomerInfo = (meta: SaveCustomerParam) =>
  socketHttp("START_UPLOAD_CUSTOMER_PORTFOLIO", {
    meta,
  });

//添加客户
export const onAddCustomerApi = (params: CustomerInfoProp) =>
  post("/customer/manage/create/customer", params);

//新增账户
export const createAsset = (params: AddAssetInfoProp) =>
  post("/customer/turnover/add/assets", params, {
    config: {
      showLoading: true,
    },
  });
