import React from "react";
import { size, pull } from "lodash/fp";
import { useMemoizedFn } from "ahooks";
import { useFormatMessage } from "@/util/formatMessage";
import SelectUserModal from "@/components/selectUserModal";
import Table from "./table";

import style from "./index.module.less";

type Props = {
  value?: string[];
  onChange?: (ids: string[]) => void;
};

export default React.memo<Props>(({ value, onChange }): JSX.Element => {
  const formatMessage = useFormatMessage();
  const onDeleteUser = useMemoizedFn((id: string) => {
    onChange?.(pull(id)(value || []));
  });
  return (
    <div>
      <div className={style.AddBar}>
        <SelectUserModal
          value={value}
          onSelect={(ids) => {
            onChange?.(ids);
          }}
        />
        <span className={style.HasCompetitionText}>
          {formatMessage("hasJoinCompetitionCount", { count: size(value) })}
        </span>
      </div>
      <div className={style.TableWrap}>
        <Table ids={value} onDelete={onDeleteUser} />
      </div>
    </div>
  );
});
