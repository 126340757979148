import { useFormatMessage } from "@/util/formatMessage";
import { Button, message, Space } from "antd";
import { useMemoizedFn } from "ahooks";
import React, { useContext, useRef, useState } from "react";
import style from "./index.module.less";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { InitSimpleDataType } from "@/components/portfolioCompoents/hook";
import { createSimplePortfolio } from "@/store/portfolioList";
import CreateForm from "./createForm";
import { useAppDispatch } from "@/hooks/redux";

export const InitSimpleData: InitSimpleDataType = {
  name: "",
  initScale: 100000,
  shareRange: {
    shareType: "PUBLIC",
    groupId: "",
  },
  strategy: "",
  description: "",
  benchmark: {
    benchmarkId: "",
    benchmarkType: "BENCHMARK",
  },
};
export default React.memo((props?: { fundIds: string[] }) => {
  const formatMessage = useFormatMessage();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const { stackBack } = useContext(PlatformNavigationContext);
  const createFormRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const onSubmit = useMemoizedFn(() => {
    createFormRef.current?.getFormData().then((data: any) => {
      setButtonDisabled(true);
      dispatch(createSimplePortfolio(data))
        .unwrap()
        .then(() => {
          message.success(formatMessage("createPortfolioSuccess"));
          stackBack();
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    });
  });
  return (
    <div className={style.CreateSimplePortfolio}>
      <CreateForm
        ref={createFormRef}
        initSimpleData={InitSimpleData}
        onError={setButtonDisabled}
        propsFundIds={props?.fundIds}
      />
      <br />
      <footer className={style.LayoutFooter}>
        <Space>
          <Button onClick={stackBack} type="default">
            {" "}
            {formatMessage("cancel")}
          </Button>
          <Button disabled={buttonDisabled} onClick={onSubmit} type="primary">
            {" "}
            {formatMessage("ok")}
          </Button>
        </Space>
      </footer>
    </div>
  );
});
