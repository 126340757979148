import React, { useRef } from "react";
import BasicInfo from "./components/basicInfo";
import ScaleTrend from "./components/scaleTrend";
import IncomeTrend from "./components/incomeTrend";
import RepresentativeFund from "./components/representativeFund";
import AlreadySelectAnchor, {
  AlreadyRef,
} from "@/components/alreadySelectAnchor";
import { useAddCompareIds } from "@/components/alreadySelectAnchor/hooks";
import { FUND_COMPANY } from "@/components/alreadySelectAnchor/constant";

type FundCompanyDetailInfoProps = {
  id: string;
};

export default React.memo<FundCompanyDetailInfoProps>(({ id }) => {
  const alreadyRef = useRef<AlreadyRef>(null);
  const { comparedSelectIds, validateIsChecked, onChangeIds, addSelectIds } =
    useAddCompareIds(FUND_COMPANY);
  return (
    <div
      onClick={alreadyRef?.current?.onCloseModal}
      onScroll={alreadyRef?.current?.onCloseModal}
    >
      <BasicInfo
        id={id}
        onOpenAnchorModal={alreadyRef?.current?.onOpenModal}
        addSelectIds={addSelectIds}
        validateIsChecked={validateIsChecked}
      />
      <ScaleTrend id={id} />
      <IncomeTrend id={id} />
      <RepresentativeFund id={id} />
      <AlreadySelectAnchor
        ref={alreadyRef}
        type={FUND_COMPANY}
        selectIds={comparedSelectIds}
        onChangeIds={onChangeIds}
      />
    </div>
  );
});
