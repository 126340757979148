import { REQUEST_FAILED, REQUEST_RESOLVED } from "@/constant/socket";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  benchmarkWeightsParam,
  conditionsParam,
  CustomBenchmarkInterface,
} from "@/model/entities";
import { customBenchmarkDefualtIdSelector } from "@/selectors/customBenchmark";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp, normalize } from "@/util/opt";
import { TaskData } from "@/util/socket";
import { useCreation, useMemoizedFn, useUpdateEffect } from "ahooks";
import { message } from "antd";
import { first, flow, isArray, map, prop, set, update } from "lodash/fp";
import { useEffect, useMemo, useRef, useState } from "react";
import { delElementInArray } from "../fundReplaceRule/hooks";
import { useForm } from "antd/lib/form/Form";
import {
  createCustomBenchmark,
  deleteCustomBenchmark,
} from "@/store/customBenchmark";

export const dealWithBenchmarkData = (data: Record<string, any>[]) => {
  return map((item: Record<string, any>) => ({
    ...item,
    conditions: flow(fastProp("conditions"), first, fastProp("config"))(item),
  }))(data);
};

const getDefaultFormData = (): CustomBenchmarkInterface => ({
  name: "",
  conditions: [],
  frequency: "DAY",
  categoryIds: [],
});

export const useGetBenchmarkDataAndFunction = () => {
  const formatMessage = useFormatMessage();
  const customBenchmarkList = useAppSelector(
    prop("customBenchmark.customBenchmarkList")
  );
  const defaultSelectedId = useAppSelector(customBenchmarkDefualtIdSelector);
  const [selectedId, setSelectedId] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState("benchmarkDetail");
  const [formData, setFormData] = useState<CustomBenchmarkInterface>(
    getDefaultFormData() as CustomBenchmarkInterface
  );
  const [initialValues, setInitialValues] = useState(getDefaultFormData());

  const [disabled, setDisable] = useState(true);
  const dispatch = useAppDispatch();

  const [form] = useForm();
  const formRef = useRef(null);

  const onChangeVisible = useMemoizedFn(() => {
    setVisible(!visible);
  });

  const conditionsMap = useMemo(() => {
    return flow(fastProp("conditions"), normalize("categoryId"))(formData);
  }, [formData]);

  const onChangeValue = useMemoizedFn((key: string) => (value: any) => {
    setFormData(update(key, () => value));
  });

  const onChangeCategoryId = useMemoizedFn(
    (key: string) => (value: string | any[]) => {
      if (isArray(value)) {
        const newConditions = map((key: string) => ({
          categoryId: key,
          benchmarkWeights: prop(`${key}.benchmarkWeights`)(conditionsMap) || [
            { type: "BENCHMARK", weight: 0, benchmarkId: "" },
          ],
          weight: prop(`${key}.weight`)(conditionsMap) || 0,
        }))(value);
        const categoryIds = map((item: conditionsParam) => item.categoryId)(
          newConditions
        );
        onChangeValue(key)(newConditions);
        onChangeValue("categoryIds")(categoryIds);
      } else {
        onChangeValue(key)(value);
      }
    }
  );

  const onclose = useMemoizedFn(() => {
    setVisible(false);
    setSelectedId(defaultSelectedId);
  });

  const createBenchmark = useMemoizedFn(() => {
    setType("addBenchmark");
    setFormData(getDefaultFormData());
    setDisable(false);
    setSelectedId("");
    setInitialValues(getDefaultFormData());
  });

  const onChangeBenchmark = useMemoizedFn((id: string) => {
    if (id === selectedId) return;
    setSelectedId(id);
    setDisable(true);
  });

  const editCustomBenchmark = useMemoizedFn(() => {
    setDisable(false);
  });

  const delCustomBenchmark = useMemoizedFn((id: string) => {
    return dispatch(deleteCustomBenchmark(id))
      .unwrap()
      .then(() => {
        setSelectedId(defaultSelectedId);
        message.success(formatMessage("deleteSuccess"));
      })
      .catch((err: any) => {
        if (err?.status === 500) {
          form.setFields([{ name: "name", errors: [err?.message] }]);
        }
        return Promise.reject();
      });
  });

  const onOk = useMemoizedFn(() => {
    form.validateFields().then(() => {
      dispatch(createCustomBenchmark(formData))
        .unwrap()
        .then(() => {
          setDisable(true);
          setType("benchmarkDetail");
        });
    });
  });

  useEffect(() => {
    if (formRef.current) {
      form.setFieldsValue(formData);
    }
  }, [formData, form]);

  const task = useAppSelector<TaskData | undefined>(
    (store) => store.customBenchmark.createCustomBenchmarkProgress
  );

  useUpdateEffect(() => {
    if (task?.progress === REQUEST_RESOLVED) {
      message.success(formatMessage("customBenchmarkSuccess"));
      setDisable(true);
    }
    if (task?.progress === REQUEST_FAILED) {
      message.error({
        content: prop("fieldError.message")(task),
        duration: 5,
      });
      setDisable(false);
    }
  }, [formatMessage, task]);

  useEffect(() => {
    setSelectedId(defaultSelectedId);
  }, [defaultSelectedId]);

  const customBenchmarkListMap = useCreation(() => {
    return normalize("id")(customBenchmarkList);
  }, [customBenchmarkList]);

  useUpdateEffect(() => {
    if (selectedId) {
      const selectedData = fastProp(selectedId)(customBenchmarkListMap);
      const categoryIds = map((item: conditionsParam) => item.categoryId)(
        fastProp("conditions")(selectedData)
      );
      setFormData({
        ...selectedData,
        categoryIds: categoryIds,
      });
      setInitialValues({ ...selectedData, categoryIds });
    }
  }, [selectedId, customBenchmarkList]);

  return {
    customBenchmarkList,
    visible,
    type,
    formData,
    selectedId,
    disabled,
    task,
    form,
    initialValues,
    formRef,
    onChangeVisible,
    onChangeCategoryId,
    onChangeValue,
    onclose,
    createBenchmark,
    onChangeBenchmark,
    editCustomBenchmark,
    delCustomBenchmark,
    onOk,
  };
};

export const useGetBenchmarkChangeValue = (
  conditions: conditionsParam[],
  onChangeValue: (key: string) => (value: any) => void
) => {
  const onChangeConditions = useMemoizedFn(
    (category: benchmarkWeightsParam[]) =>
      (index: number) =>
      (key: string) =>
      (benchmarkIndex: number) =>
      (benchmarkKey: string) =>
      (value: number | string | null) => {
        const data = set(`${benchmarkIndex}.${benchmarkKey}`, value)(category);
        onChangeValue("conditions")(set(`${index}.${key}`, data)(conditions));
      }
  );
  const onChangeParentWeight = useMemoizedFn(
    (index: number) => (key: string) => (value: number | null) => {
      onChangeValue("conditions")(set(`${index}.${key}`, value)(conditions));
    }
  );

  const addComponentBenchmark = useMemoizedFn(
    (benchmarkWeights: benchmarkWeightsParam[]) =>
      (index: number) =>
      (key: string) => {
        const newBenchmarkWeights = benchmarkWeights.concat([
          {
            type: "BENCHMARK",
            weight: 0,
            benchmarkId: "",
          },
        ]);
        onChangeValue("conditions")(
          set(`${index}.${key}`, newBenchmarkWeights)(conditions)
        );
      }
  );

  const delComponentBenchmark = useMemoizedFn(
    (category: benchmarkWeightsParam[]) =>
      (index: number) =>
      (key: string) =>
      (benchmarkIndex: number) => {
        const data = delElementInArray(benchmarkIndex + 1, category);
        onChangeValue("conditions")(set(`${index}.${key}`, data)(conditions));
      }
  );
  return {
    onChangeConditions,
    onChangeParentWeight,
    addComponentBenchmark,
    delComponentBenchmark,
  };
};
