import { useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { Card, Col, Row, Table } from "antd";
import React, { useMemo } from "react";
import style from "../index.module.less";
import { fastProp } from "@/util/opt";
import {
  FundBasicDataSource,
  FundBasicIndicatorsType,
  operatingCosts,
} from "@/views/fundDetail/constant";
import { map } from "lodash/fp";
import { formatPercentage } from "@/util/numberFormatter";
import mapper from "../mapper";

const { Column } = Table;
type CustomTableProps = {
  title: string;
  data: any[];
  render: (text: any, record: any, index: any) => string;
};
const CustomTable = ({ title, data, render }: CustomTableProps) => {
  return (
    <>
      <p className={style.FundBusinessRuleTableTitle}>{title}</p>
      <Table showHeader={false} pagination={false} bordered dataSource={data}>
        <Column key="0" dataIndex="0" render={render} />
        <Column key="1" dataIndex="1" ellipsis render={render} />
      </Table>
    </>
  );
};
const useTableDataSource = (
  data: { [key: string]: any },
  dataIndicators: FundBasicIndicatorsType[]
) => {
  const formatMessage = useFormatMessage();
  const dataSource = map((v: FundBasicIndicatorsType) => ({
    ...v,
    message: formatMessage(v.messageKey),
    value: fastProp(v.id)(data),
  }))(dataIndicators);
  return useMemo(
    () =>
      map(({ value, message, id }: FundBasicDataSource) => ({
        0: { value: message },
        1: { value: value, id },
        key: id,
      }))(dataSource),
    [dataSource]
  );
};
const fundOperatingCostsRender = (v: any) =>
  v.id ? formatPercentage(v?.value, { digit: 2 }) : v?.value;
export default React.memo(({ fundId }: { fundId: string }): JSX.Element => {
  const formatMessage = useFormatMessage();
  const { fundOperatingCosts } = useAppSelector((state) =>
    mapper(state, fundId)
  );
  const fundOperatingCostsDataSource = useTableDataSource(
    fundOperatingCosts,
    operatingCosts
  );
  return (
    <Card bordered={false} size="small">
      <Col className={style.Title}>{formatMessage("fundBusinessRule")}</Col>
      <Row gutter={8}>
        <Col span={8}>
          <CustomTable
            render={fundOperatingCostsRender}
            data={fundOperatingCostsDataSource}
            title={formatMessage("OperatingCosts")}
          />
        </Col>
      </Row>
    </Card>
  );
});
