import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { Card, Col, Row, Select, Space } from "antd";
import PieCard from "@/views/compareManage/components/pieCard";
import { fastHas, fastProp, mapIndexed, normalize } from "@/util/opt";
import { useAppSelector } from "@/hooks/redux";
import {
  filter,
  flow,
  identity,
  isEmpty,
  last,
  map,
  orderBy,
  prop,
} from "lodash/fp";
import { FundDetailSectorTrend } from "@/model/fundDetail";
import {
  FundBasicIndicatorsType,
  sectorTrend,
} from "@/views/fundDetail/constant";
import { colors, paletteColors } from "@/util/colors";
import style from "../index.module.less";
import LineChart, { LineChartOpts } from "@/echarts/lineChart";
import dayjs from "dayjs";
import { LineSeriesOption } from "echarts";
import mapper from "../mapper";
import { FROM_CREATION } from "@/constant/statisticRange";
import ScaleRangeSelector from "./scaleRangeSelector";
import LoadingComponent from "@/components/LoadingComponent";
import { fetchGetFundDetailSectorTrend } from "@/store/fundDetailSlice";
import { getCalculateScaleRangeDate } from "@/constant/statisticRangeCalculator/rangeCalculator";

export const DataRange = ({
  date,
  onChange,
  value,
}: {
  date: string[];
  onChange: (v: string) => void;
  value: string;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  const options = useMemo(
    () =>
      map((v: string) => ({
        value: v,
        key: v,
      }))(date),
    [date]
  );
  return (
    <Space size="small">
      <span>{formatMessage("deadline")}</span>
      <Select
        size="small"
        value={value}
        onChange={onChange}
        options={options}
        className={style.DeadlineSelect}
      />
    </Space>
  );
};
const useGetPieCardData = (
  range: string,
  fundDetailSectorTrendMap: Record<string, FundDetailSectorTrend>,
  sameTypeFundSectionWeightMap: Record<string, FundDetailSectorTrend>
) => {
  const formatMessage = useFormatMessage();
  return useMemo(() => {
    const newData = fastProp(range)(fundDetailSectorTrendMap);
    const sameAverageValues = fastProp(range)(sameTypeFundSectionWeightMap);
    return flow(
      mapIndexed((v: FundBasicIndicatorsType, index: number) => ({
        name: formatMessage(v.messageKey),
        value: fastProp(v.id)(newData),
        sameAverageValue: fastProp(v.id)(sameAverageValues),
        color: colors[index],
      })),
      orderBy("value", "desc")
    )(sectorTrend);
  }, [
    formatMessage,
    fundDetailSectorTrendMap,
    range,
    sameTypeFundSectionWeightMap,
  ]);
};
const useGetLineChartDataSource = (
  allDates: string[],
  fundDetailSectorTrendMap: Record<string, FundDetailSectorTrend>,
  scaleRange: string
) => {
  const formatMessage = useFormatMessage();

  const categories = useMemo(
    () =>
      filter((date: string) => fastHas(date)(fundDetailSectorTrendMap))(
        getCalculateScaleRangeDate(allDates, scaleRange)
      ),
    [allDates, fundDetailSectorTrendMap, scaleRange]
  );
  const options = useMemo<LineChartOpts["options"]>(() => {
    return {
      grid: {
        right: 100,
        top: 20,
      },
      xAxis: {
        data: categories,
        nameGap: 40,
        axisLabel: {
          formatter(value: string) {
            return `${dayjs(value).format("YYYY/MM/DD")}`;
          },
        },
      },
      yAxis: {
        min: "dataMin",
        max: "dataMax",
      },
      legend: {
        right: 0,
        orient: "vertical",
        type: "scroll",
      },
      tooltip: {},
    };
  }, [categories]);
  const series = useMemo<LineChartOpts["series"]>(
    () =>
      map<FundBasicIndicatorsType, LineSeriesOption>(({ id, messageKey }) => ({
        type: "line",
        name: formatMessage(messageKey),
        showSymbol: false,
        smooth: false,
        areaStyle: {
          opacity: 0.5,
        },
        lineStyle: {
          width: 1.5,
        },
        stack: "Total",
        data: map(
          (date: string) => prop(`${date}.${id}`)(fundDetailSectorTrendMap) || 0
        )(categories),
      }))(sectorTrend),
    [categories, formatMessage, fundDetailSectorTrendMap]
  );
  return { options, series };
};

export default React.memo(({ fundId }: { fundId: string }): JSX.Element => {
  const formatMessage = useFormatMessage();
  const { fundSectorWeightTrend, sameTypeFundSectionWeightMap } =
    useAppSelector((state) => mapper(state, fundId));
  const [dateRange, changeDateRange] = useState("");
  const sectorTrendDate = useMemo(() => {
    return flow(
      orderBy("reportDate", "asc"),
      map(fastProp("reportDate"))
    )(fundSectorWeightTrend);
  }, [fundSectorWeightTrend]);
  const sectorTrendRange = useMemo(
    () => last(sectorTrendDate),
    [sectorTrendDate]
  );
  useEffect(
    () => changeDateRange(sectorTrendRange as string),
    [sectorTrendRange]
  );
  const fundDetailSectorTrendMap = useMemo(() => {
    return normalize("reportDate")(fundSectorWeightTrend);
  }, [fundSectorWeightTrend]);

  const pieData = useGetPieCardData(
    dateRange,
    fundDetailSectorTrendMap as Record<string, FundDetailSectorTrend>,
    sameTypeFundSectionWeightMap as Record<string, FundDetailSectorTrend>
  );
  const [scaleRange, setScaleRange] = useState(FROM_CREATION);
  const { options, series } = useGetLineChartDataSource(
    sectorTrendDate,
    fundDetailSectorTrendMap as Record<string, FundDetailSectorTrend>,
    scaleRange
  );
  const changeScaleRange = useCallback(
    (e) => setScaleRange(e.target.value),
    []
  );
  const descSectorTrendDate = useMemo(
    () => orderBy(identity, "desc")(sectorTrendDate),
    [sectorTrendDate]
  );
  return (
    <Card bordered={false} size="small">
      <Col className={style.Title}>{formatMessage("AssetAllocation")}</Col>
      <Row gutter={8} wrap={false}>
        <Col span={12}>
          <LoadingComponent actions={fetchGetFundDetailSectorTrend}>
            <PieCard
              title={formatMessage("AssetAllocationDistribution")}
              dataSource={pieData}
              operation={
                !isEmpty(descSectorTrendDate) && (
                  <DataRange
                    date={descSectorTrendDate as string[]}
                    value={dateRange}
                    onChange={changeDateRange}
                  />
                )
              }
              showSameAverageValue
              cardTitle={formatMessage("assets")}
            />
          </LoadingComponent>
        </Col>
        <Col span={12}>
          <Card className={style.PieCard}>
            <div className={style.PieCardTitle}>
              <span>
                {formatMessage("ChangesInAssetAllocationDistribution")}
              </span>
              <ScaleRangeSelector
                value={scaleRange}
                onChange={changeScaleRange}
              />
            </div>
            <LoadingComponent actions={fetchGetFundDetailSectorTrend}>
              <LineChart
                options={options}
                series={series}
                color={paletteColors}
                height={300}
              />
            </LoadingComponent>
          </Card>
        </Col>
      </Row>
    </Card>
  );
});
