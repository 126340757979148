import { isNaN, isNumber, isFinite, isNil, map, isNull } from "lodash/fp";
import { floatEqual, equalNaN } from "./opt";
import getLang from "./getLang";

export const toFixedNumber =
  (digit: number) =>
  (number: number | undefined): string => {
    if (isNaN(number) || !isNumber(number)) return "--";
    return number.toFixed(digit);
  };

type formatPercentageOptions = {
  digit?: number;
  symbol?: string;
  scale?: number;
  defaultDisplay?: string;
  full?: boolean;
  zeroDashed?: boolean;
};
const initPercentageOptions: Required<formatPercentageOptions> = {
  digit: 2,
  symbol: "%",
  scale: 100,
  defaultDisplay: "--",
  full: false,
  zeroDashed: false,
};
export const formatPercentage = (
  number: number | string | undefined | null,
  options?: formatPercentageOptions
): string => {
  const { digit, symbol, defaultDisplay, zeroDashed, scale, full } = {
    ...initPercentageOptions,
    ...options,
  };
  if (isFinite(number)) {
    if (!full) {
      const digits = 10 ** digit;
      const result = Math.round(Number(number) * digits * scale) / digits;
      if (isNaN(result) || (zeroDashed && result === 0)) {
        return defaultDisplay;
      }
      const formatResult = `${result.toFixed(digit)}${symbol}`;
      if (Object.is(result, -0)) return `-${formatResult}`;
      return formatResult;
    }

    const result = Number(number) * scale;
    return `${result}${symbol}`;
  }
  return defaultDisplay;
};

export const numberColor = (number: number): string | undefined => {
  if (isNil(number) || isNaN(number)) return undefined;
  if (floatEqual(0)(number)) return "";
  return number > 0 ? "#FF0A00" : "#00B867";
};

export const numberWithSymbol = (
  value: number,
  formateValue?: string | number
): string => {
  return `${value > 0 ? "+" : ""}${formateValue || value}`;
};

export const addZeroIndex = (number: number) => {
  if (number < 0) return "--";
  if (number > 0 && number < 10) return `0${number}`;
  return number;
};

export const formatNil = <T>(value: T, nilFormat = "--"): string | T => {
  if (isNil(value) || equalNaN(value)) {
    return nilFormat;
  }
  return value;
};

export const formatNilToZero = (value: number | "NaN" | undefined): number => {
  if (value === "NaN" || isNil(value) || isNaN(value)) return 0;
  return value;
};

export const formatArrayNilToZero = (arrays: any[]): number[] =>
  map<any, number>(formatNilToZero)(arrays);

export const formatQuantArrayNilToZero = (...arrays: any[]) =>
  map<any[], number[]>(formatArrayNilToZero)(arrays);

export const getNumberLength = (value: number) =>
  Math.floor(Math.log10(Math.abs(value))) + 1;
type FixedNumberOptions = {
  digit?: number;
  zeroDashed?: boolean;
  normal?: boolean;
};
export const fixedNumber = (
  number: number,
  options: FixedNumberOptions = {}
) => {
  const { digit, zeroDashed, normal } = { digit: 2, normal: false, ...options };
  if (isFinite(number)) {
    const digits = 10 ** digit;
    const result = Math.round(Number(number) * digits) / digits;
    if (isNaN(result) || (zeroDashed && result === 0)) {
      return "--";
    }
    if (normal) {
      return result.toFixed(digit);
    }
    const length = getNumberLength(result);
    const lang = getLang();
    if (lang === "en") {
      if (length > 9) {
        const final = result / 1000000000;
        return `${final.toFixed(digit)} B`;
      }

      if (length > 6) {
        const final = result / 1000000;
        return `${final.toFixed(digit)} M`;
      }
    }
    if (lang === "zh") {
      if (length > 8) {
        const final = result / 100000000;
        return `${final.toFixed(digit)} 亿`;
      }
      if (length > 6) {
        const final = result / 1000000;
        return `${final.toFixed(digit)} 百万`;
      }
      if (length > 4) {
        const final = result / 10000;
        return `${final.toFixed(digit)} 万`;
      }
    }

    return result.toFixed(digit);
  }
  return "--";
};

export const fixedNumberWithCommas = (
  number: number,
  options: FixedNumberOptions = {}
) => {
  const { digit = 2 } = options;
  if (isNumber(number) && !isNaN(number)) {
    const parts = number.toFixed(digit).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  return "--";
};

export const fixedHundredMillion = (value: number) =>
  toFixedNumber(2)(value / 100000000);

export const fixedHundred = (value: number) => toFixedNumber(2)(value / 10000);
export const fixedThousand = (value: number) => toFixedNumber(2)(value / 1000);
export const roundTo = (digit: number) => {
  const p = 10 ** digit;
  return (number: number) => Math.round(number * p) / p; // 保留到小数点后n位
};

export const floorTo = (digit: number) => {
  const p = 10 ** digit;
  return (number: number) => Math.floor(number * p) / p; // 保留到小数点后n位向下取
};

export const equalZero = (value: number) =>
  Object.is(value, 0) || Object.is(value, -0);

/**
 2  * 数字转为千分位字符
 3  * @param {Number} num
 4  * @param {Number} point 保留几位小数，默认2位
 5  */
export const parseToThousandth = (num: number, point = 2) => {
  if (isFinite(num)) {
    if (isNaN(num) || isNull(num)) return "--";
    if (num === 0) return num.toFixed(point);
    const [sInt, sFloat] = num
      .toFixed(point) // Number.isInteger(num) ? `${num}` : num.toFixed(point)
      .split(".");
    const sIntNum = sInt.replace(/\d(?=(\d{3})+$)/g, "$&,");
    return sFloat ? `${sIntNum}.${sFloat}` : `${sIntNum}`;
  }
  return "--";
};
