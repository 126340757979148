import { identity } from "lodash/fp";
import { createContext } from "react";
import { NavigationContext } from "./interface";

export default createContext<NavigationContext>({
  goToFundComparePage: identity,
  goToFundManagerComparePage: identity,
  goToFundCompanyComparePage: identity,
  goToFundDetailPage: identity,
  goToFundManagerDetailPage: identity,
  goToFundCompanyDetailPage: identity,
  goToSimpleCreatePortfolio: identity,
  goToFundConfiguration: identity,
  goToPortfolioEdit: identity,
  goToPortfolioChangingPosition: identity,
  goToPortfolioChangingPositionHistory: identity,
  goToManualCreatePortfolio: identity,
  goToBackTestingDetail: identity,
  goToPortfolioAnalysis: identity,
  stackBack: identity,
  goToFromAboveToBelow: identity,
  goToCompetitionManage: identity,
  goToCreateCompetitionPage: identity,
  goToCompetitionDetail: identity,
  goToCompetitionEdit: identity,
  goToWantCompetePage: identity,
  goToCustomerAccountAnalysis: identity,
  goToCustomerAumAnalysis: identity,
  goToCreateCustomerPage: identity,
  contentHeight: 0,
});
