import { toLower } from "lodash";
import { filter, some, size, flow } from "lodash/fp";
import { fastProp, includeStr } from "./opt";

export const searchData = <T>(
  data: T[],
  searchText: string,
  searchKeys: (keyof T)[]
): Array<T> => {
  const lowerText = toLower(searchText);
  if (size(searchText) === 0) {
    return [];
  }
  return filter((item: T) =>
    some((searchKey) =>
      flow(
        (v) => fastProp(searchKey as string)(v),
        toLower,
        includeStr(lowerText)
      )(item)
    )(searchKeys)
  )(data);
};
