import type {
  LineSeriesOption,
  EChartsOption,
  ScatterSeriesOption,
} from "echarts";
import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { fastProp, normalize } from "@/util/opt";
import { useCreation } from "ahooks";
import {
  find,
  flow,
  groupBy,
  identity,
  keys,
  map,
  orderBy,
  prop,
  reduce,
} from "lodash/fp";
import { useMemo } from "react";
import { customerAnalysisSelector } from "../selectors";
import { WrapperProps } from "@/echarts/echartsWrapper";
import { purchaseRedeemBody } from "@/model/customer";
import dayjs from "dayjs";
import { useFormatMessage } from "@/util/formatMessage";
import { fixedHundred } from "@/util/numberFormatter";
import { fundCodeMapSelector } from "@/selectors/fund";
import { allStocksMapSelector } from "@/selectors/stocks";
import { FROM_CREATION } from "@/constant/statisticRange";
import { transforTypeData } from "../../heldAssetsManage/wareHouseRecord/hook";
import { FUND } from "@/views/myOptional/constant";
import {
  CASH,
  STOCK,
} from "../../addCustomer/components/addAssetPortfolio/hooks";

export interface LineOrScatterChartOpts extends WrapperProps {
  options: EChartsOption;
  series?: (LineSeriesOption | ScatterSeriesOption)[];
}

type ScatterSerieDataType = [
  string,
  number,
  number,
  string | undefined,
  string,
  string
];

export const useGetLineCharDataSource = (
  customerId: string,
  statisticRange: string,
  totalAccountId: string
) => {
  const formatMessage = useFormatMessage();
  const customerAnalysis = useAppSelector((state: RootState) =>
    customerAnalysisSelector(state, customerId)
  );
  const fundCodeMap = useAppSelector(fundCodeMapSelector);
  const stocksCodeMap = useAppSelector(allStocksMapSelector);
  const customerInfo = useAppSelector(prop("customerList.customerList"));
  const totalAccountInfo = useAppSelector((state: RootState) =>
    customerAnalysisSelector(state, totalAccountId)
  );
  const accountInfo = fastProp("accountInfo")(totalAccountInfo);

  const totalSubCustomerDetails = fastProp("subCustomerDetails")(accountInfo);

  const { assetChange, purchaseRedeem } = customerAnalysis;
  const rangeAssetChange = useCreation(
    () => fastProp(statisticRange)(assetChange),
    [assetChange, statisticRange]
  );
  const rangePurchaseRedeem = useCreation(
    () => fastProp(statisticRange)(purchaseRedeem),
    [purchaseRedeem, statisticRange]
  );

  const dates = useMemo(() => {
    return orderBy(identity, "asc")(keys(rangeAssetChange)) as string[];
  }, [rangeAssetChange]);

  const changeValueData = useMemo(() => {
    return map((date: string) => [date, fastProp(date)(rangeAssetChange)])(
      dates
    );
  }, [rangeAssetChange, dates]);

  const accountListMap = useMemo(
    () => normalize("accountId")(totalSubCustomerDetails),
    [totalSubCustomerDetails]
  );

  const accumulateProfitAndLoss = useMemo(() => {
    if (totalAccountId === customerId) {
      return flow(
        find(
          (v: Record<string, number | string>) =>
            fastProp("customerAccountId")(v) === customerId
        ),
        prop("sectionProfit.FROM_CREATION")
      )(customerInfo);
    }
    return prop(`${customerId}.cumulativeProfit`)(accountListMap);
  }, [customerId, customerInfo, totalAccountId, accountListMap]);
  const { purchaseData, redeemData, appendInvestData, reduceInvestData } =
    useMemo(() => {
      const { purchaseDate, redeemDate, appendInvestDate, reduceInvestDate } =
        transforTypeData(rangePurchaseRedeem);
      const getReducedData = (data: purchaseRedeemBody[]) =>
        flow(
          map((item: purchaseRedeemBody): ScatterSerieDataType => {
            const date = dayjs(item?.createDate).format("YYYY-MM-DD");
            return [
              date,
              fastProp(date)(rangeAssetChange),
              item.scale || item?.share,
              prop(`${item.assetCode}.name`)(fundCodeMap) ||
                prop(`${item.assetCode}.name`)(stocksCodeMap) ||
                (item.assetCode === "CASH" && formatMessage("cash")) ||
                undefined,
              item?.accountName,
              (prop(`${item.assetCode}.name`)(fundCodeMap) && FUND) ||
                (prop(`${item.assetCode}.name`)(stocksCodeMap) && STOCK) ||
                (item.assetCode === "CASH" && CASH),
            ];
          }),
          groupBy(
            (item: ScatterSerieDataType) =>
              `${item?.[0]}-${item?.[3]}-${item?.[4]}`
          ),
          map(
            reduce((prev: ScatterSerieDataType, cur: ScatterSerieDataType) => {
              const total = prev[2] + cur[2];
              cur[2] = total;
              return cur;
            })(["", 0, 0, "", "", ""])
          )
        )(data);
      return {
        purchaseData: getReducedData(purchaseDate),
        redeemData: getReducedData(redeemDate),
        appendInvestData: getReducedData(appendInvestDate),
        reduceInvestData: getReducedData(reduceInvestDate),
      };
    }, [
      rangePurchaseRedeem,
      rangeAssetChange,
      fundCodeMap,
      stocksCodeMap,
      formatMessage,
    ]);

  const series = useMemo<LineOrScatterChartOpts["series"]>(
    () => [
      {
        type: "line",
        name: "资产",
        symbol: "none",
        datasetIndex: 0,
        lineStyle: {
          width: 1.5,
        },
        data: changeValueData,
        tooltip: {
          valueFormatter: (value: any) => {
            return fixedHundred(value);
          },
        },
      },
      {
        type: "scatter",
        name: formatMessage("PURCHASE"),
        color: "red",
        data: purchaseData,
        datasetIndex: 1,
        symbolSize: 12,
        tooltip: {
          valueFormatter: (value: any) => {
            return value;
          },
        },
      },
      {
        type: "scatter",
        name: formatMessage("REDEMPTION"),
        color: "green",
        data: redeemData,
        datasetIndex: 1,
        symbolSize: 12,
        tooltip: {
          valueFormatter: (value: any) => {
            return value;
          },
        },
      },
      {
        type: "scatter",
        name: formatMessage("APPEND_INVEST"),
        color: "#0099DB",
        data: appendInvestData,
        datasetIndex: 1,
        symbolSize: 12,
        tooltip: {
          valueFormatter: (value: any) => {
            return value;
          },
        },
      },
      {
        type: "scatter",
        name: formatMessage("REDUCE_INVEST"),
        color: "#9051BE",
        data: reduceInvestData,
        datasetIndex: 1,
        symbolSize: 12,
        tooltip: {
          valueFormatter: (value: any) => {
            return value;
          },
        },
      },
    ],
    [
      changeValueData,
      formatMessage,
      purchaseData,
      redeemData,
      appendInvestData,
      reduceInvestData,
    ]
  );
  const allDates = useCreation(
    () => flow(fastProp(FROM_CREATION), keys)(assetChange),
    [assetChange, statisticRange]
  );
  return {
    dates,
    series,
    accumulateProfitAndLoss,
    allDates: orderBy(identity, "asc")(allDates) as string[],
  };
};
