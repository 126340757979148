import { Input, InputProps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  isUndefined,
  isNull,
  flow,
  indexOf,
  toString,
  size,
  identity,
  replace,
} from "lodash/fp";
import Big from "big.js";
import { bigNumber } from "@/util/math";
import { equalZero, fixedNumberWithCommas } from "@/util/numberFormatter";
import { eqNaN } from "@/util/opt";

export const PERCENTAGE = "PERCENTAGE";
export const TENTHOUSANDTH = "TENTHOUSANDTH";
export const INTEGER = "INTEGER";
export const FLOAT = "FLOAT";
export const THOUSANDTH = "THOUSANDTH";
export const THOUSAND = "THOUSAND";
interface NumberInputProps extends Omit<InputProps, "onChange"> {
  value?: number;
  onChange?: (v: number | null) => void;
  type:
    | "PERCENTAGE"
    | "TENTHOUSANDTH"
    | "INTEGER"
    | "FLOAT"
    | "THOUSANDTH"
    | "THOUSAND";
  max?: number;
  min?: number;
  precision?: number;
}
export const percentageFormart = (value: number) =>
  Number(new Big(value).times(100));

const { div, toNumber, convert, multiply } = bigNumber;

const genParser =
  (scale: number) =>
  (value: string): number => {
    let v = null;
    try {
      v = flow(convert, div(scale), toNumber)(Number(value));
    } catch (e) {
      v = NaN;
    }

    return v;
  };

export const percentageParser = genParser(100);
const thousand = genParser(1000);
const tenthousandthParser = genParser(10000);
const integerParser = (value: string) => parseInt(value, 10);
const floatParser = (value: string) => Number(value);
const thousandthParser = (value: string) =>
  flow(convert, toNumber)(Number(replace(/,/g, "")(value)));

const getDisplayValue = (
  type: NumberInputProps["type"],
  value: NumberInputProps["value"]
) => {
  if (isUndefined(value) || isNull(value)) {
    return "";
  }
  if (type === PERCENTAGE) {
    return percentageFormart(value);
  }
  if (type === TENTHOUSANDTH) {
    return flow(convert, multiply(10000), toNumber)(value);
  }
  if (type === THOUSAND) {
    return flow(convert, multiply(1000), toNumber)(value);
  }
  // 展示千分位
  if (type === THOUSANDTH) {
    return flow(convert, toNumber, fixedNumberWithCommas)(value);
  }
  return value;
};

function getPrecision(value: string | number | undefined) {
  const strValue = toString(value);
  const index = indexOf(".")(strValue);
  if (index !== -1) {
    return size(strValue) - index - 1;
  }
  return 0;
}

const getStep = (type: NumberInputProps["type"]) => {
  if (type === PERCENTAGE || type === FLOAT) return 0.01;
  if (type === THOUSAND) return 0.001;
  if (type === TENTHOUSANDTH) return 0.0001;
  return 1;
};

const getAddonAfter = (
  addonAfter: NumberInputProps["addonAfter"],
  type: NumberInputProps["type"]
) => {
  if (addonAfter === undefined) return undefined;
  if (addonAfter) return addonAfter;
  if (type === PERCENTAGE) return "%";
  if (type === TENTHOUSANDTH) return "‱";
  return null;
};

export default React.memo<NumberInputProps>(
  ({
    type,
    value,
    max,
    min = 0,
    addonAfter,
    onFocus,
    onBlur,
    onChange = identity,
    precision,
    ...inputNumberProps
  }) => {
    const [displayValue, setDisplayValue] = useState(
      getDisplayValue(type, value)
    );
    const [focus, setFocus] = useState(false);

    useEffect(() => {
      if (!focus) {
        setDisplayValue(getDisplayValue(type, value));
      }
    }, [focus, type, value]);

    const handleOnChange = useCallback(
      ({ target: { value: newValue } }) => {
        if (!isUndefined(precision)) {
          const newPrecision = getPrecision(newValue);
          let oldPrecision = getPrecision(value);
          if (type === PERCENTAGE) {
            oldPrecision -= 2;
          } else if (type === TENTHOUSANDTH) {
            oldPrecision -= 4;
          } else if (type === THOUSAND) {
            oldPrecision -= 3;
          }
          // 如果修改了小数位后面的位数并且大于设置的 maxPrecisionInput，则直接返回
          if (newPrecision > oldPrecision && newPrecision > precision) {
            return;
          }
        }
        setDisplayValue(newValue);
        let parser = integerParser;
        if (type === PERCENTAGE) {
          parser = percentageParser;
        } else if (type === TENTHOUSANDTH) {
          parser = tenthousandthParser;
        } else if (type === FLOAT) {
          parser = floatParser;
        } else if (type === "THOUSANDTH") {
          parser = thousandthParser;
        } else if (type === THOUSAND) {
          parser = thousand;
        }
        const parsedValue = parser(newValue);
        if (
          (newValue !== "0" && equalZero(parsedValue)) ||
          eqNaN(parsedValue)
        ) {
          return onChange(min);
        }
        if (!isUndefined(max) && parsedValue > max) {
          return onChange(max);
        }
        if (!isUndefined(min) && parsedValue <= min) {
          return onChange(min);
        }
        return onChange(parsedValue);
      },
      [max, precision, min, onChange, setDisplayValue, type, value]
    );

    const handleOnBlur = useCallback(
      (event) => {
        if (onBlur) onBlur(event);
        setFocus(false);
      },
      [onBlur]
    );

    const handleOnFocus = useCallback(
      (event) => {
        if (onFocus) onFocus(event);
        setFocus(true);
      },
      [onFocus]
    );

    return (
      <Input
        {...inputNumberProps}
        step={getStep(type)}
        addonAfter={getAddonAfter(addonAfter, type)}
        value={displayValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
      />
    );
  }
);
