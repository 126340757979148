import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { updateManagerDetailInfo } from "@/store/fundManagerDetailSlice";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import { useMemo } from "react";
import { BENCHMARK_COLOR, RedColor } from "@/util/colors";
import { formatNilToZero } from "@/util/numberFormatter";
import {
  baseManagerSelector,
  currentManagerInfoSelector,
  managerYieldInfoSelector,
} from "../selectors";
import { useGetBenchmarkDailyReturn } from "@/hooks/benchmark";
import { map } from "lodash/fp";
import { CSI300_ID } from "@/views/compareManage/constant";
import { FROM_CREATION } from "@/constant/statisticRange";
import { useGetCumulativeChartCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";

export const useManageBenchmarkIdAndRange = (
  id: string,
  key: "yield" | "drawDown"
) => {
  const baseManager = useAppSelector((state: RootState) =>
    baseManagerSelector(state, id)
  );
  const { benchmarkId, activeRange } = useMemo(() => {
    const benchmarkAndRangeInfo = fastProp(key)(baseManager);
    const benchmarkId =
      fastProp("benchmarkId")(benchmarkAndRangeInfo) || CSI300_ID;
    const activeRange =
      fastProp("activeRange")(benchmarkAndRangeInfo) || FROM_CREATION;
    return { benchmarkId, activeRange };
  }, [baseManager, key]);
  const dispatch = useAppDispatch();
  const updateBenchmarkId = useMemoizedFn((value: string) =>
    dispatch(
      updateManagerDetailInfo({
        id,
        key: `${key}.benchmarkId`,
        value,
      })
    )
  );
  const updateActiveRange = useMemoizedFn((value: string) =>
    dispatch(
      updateManagerDetailInfo({
        id,
        key: `${key}.activeRange`,
        value,
      })
    )
  );
  return {
    benchmarkId,
    activeRange,
    updateBenchmarkId,
    updateActiveRange,
  };
};

export const useGetManagerCumulativeChartData = (
  id: string,
  benchmarkId: string,
  activeRange: string
) => {
  const { dates, dailyReturnsMap } = useAppSelector((state: RootState) =>
    managerYieldInfoSelector(state, id)
  );

  const baseInfo = useAppSelector((state: RootState) =>
    currentManagerInfoSelector(state, id)
  );
  const getDates = useGetCumulativeChartCalculateRangeDate();
  const calcDates = useMemo(
    () => getDates(dates, activeRange),
    [activeRange, dates, getDates]
  );

  const benchmarkInfo = useGetBenchmarkDailyReturn(benchmarkId);
  const benchmarkDailyReturns = useMemo(
    () =>
      map<string, number>((date) =>
        formatNilToZero(fastProp(date)(benchmarkInfo.dailyReturnsMap))
      )(calcDates),
    [benchmarkInfo.dailyReturnsMap, calcDates]
  );

  const managerDailyReturns = useMemo(
    () =>
      map<string, number>((date) =>
        formatNilToZero(fastProp(date)(dailyReturnsMap))
      )(calcDates),
    [calcDates, dailyReturnsMap]
  );

  const managerChartInfo = useMemo(
    () => ({
      name: baseInfo?.name,
      color: RedColor,
      dailyReturns: mapIndexed((date: string, index: number) => [
        date,
        fastNth(index)(managerDailyReturns),
      ])(calcDates),
    }),
    [baseInfo?.name, calcDates, managerDailyReturns]
  );

  const benchmarkChartInfo = useMemo(
    () => ({
      ...benchmarkInfo,
      color: BENCHMARK_COLOR,
      dailyReturns: mapIndexed((date: string, index: number) => [
        date,
        fastNth(index)(benchmarkDailyReturns),
      ])(calcDates),
    }),
    [benchmarkDailyReturns, benchmarkInfo, calcDates]
  );

  return useMemo(
    () => ({
      dates: calcDates,
      chartData: [managerChartInfo, benchmarkChartInfo],
      dailyReturns: managerDailyReturns,
      benchmarkDailyReturns,
      benchmarkName: benchmarkChartInfo.name,
      initDates: dates,
    }),
    [
      calcDates,
      managerChartInfo,
      benchmarkChartInfo,
      managerDailyReturns,
      benchmarkDailyReturns,
      dates,
    ]
  );
};
