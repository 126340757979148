import { Button, Select, Space, Timeline, Tooltip } from "antd";
import cn from "classnames";
import { useMemoizedFn } from "ahooks";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import style from "./index.module.less";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useFormatMessage } from "@/util/formatMessage";
import TradingDatePicker from "@/components/tradingDatePicker";
import PositionHistoryTable from "./components/positionHistoryTable";
import { useGetTurnoverRecordHistory, useGetCurrentPortfolio } from "./hooks";
import { fastProp } from "@/util/opt";
import { assetPortfolios } from "@/model/portfolioList";
import { filter, flow, isEmpty, map, orderBy, reject, update } from "lodash/fp";
import dayjs from "dayjs";
import {
  transactionTypeOptions,
  getChangingPositionHistoryDataSource,
  ChangingPositionHistoryType,
  filterTradeType,
} from "./constant";
import { useGetFundManagersByDate } from "./hooks";
import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export type pickerValue = {
  warehouseStartDate: string;
  warehouseEndDate: string;
  transactionType: string;
};
const useManagePickValues = (
  portfolioData: assetPortfolios
): [pickerValue, (key: string) => (value: string) => void, () => any] => {
  const defaultValues = useMemo(
    () => ({
      warehouseStartDate: fastProp("historyStart")(portfolioData),
      warehouseEndDate: fastProp("historyEnd")(portfolioData),
      transactionType: "ALL",
    }),
    [portfolioData]
  );
  const [pickValues, setPickValues] = useState(defaultValues);
  useEffect(() => {
    if (!isEmpty(portfolioData)) {
      setPickValues(defaultValues);
    }
  }, [defaultValues, portfolioData]);
  const updater = useCallback(
    (key: string) => (value: string) =>
      setPickValues((originValue) => ({
        ...originValue,
        [key]: value,
      })),
    []
  );
  const reset = useCallback(
    () => setPickValues(defaultValues),
    [defaultValues]
  );
  return [pickValues, updater, reset];
};

const useGetDataSource = (pickerValues: pickerValue, portfolioId: string) => {
  const [dataSource, setDataSource] = useState<ChangingPositionHistoryType[]>(
    []
  );
  const [filterDataSource, setFilterDataSource] = useState<
    ChangingPositionHistoryType[]
  >([]);
  const turnoverRecordHistory = useGetTurnoverRecordHistory(portfolioId);
  const fundsMap = useAppSelector(fundIdMapSelector);
  const { onRequestFundManagers, getFundManagersByDateAndId } =
    useGetFundManagersByDate();
  const changeDataSource = useMemoizedFn(() =>
    setDataSource(
      getChangingPositionHistoryDataSource(
        pickerValues,
        turnoverRecordHistory,
        fundsMap,
        getFundManagersByDateAndId
      )
    )
  );
  useEffect(() => {
    if (!isEmpty(turnoverRecordHistory)) {
      const promises: Promise<any>[] = [];
      turnoverRecordHistory?.forEach((item) => {
        const fundIds = map("fundId")(item?.assets);
        promises.push(onRequestFundManagers(item?.date, fundIds));
      });
      Promise.allSettled(promises).then(changeDataSource);
    }
  }, [changeDataSource, onRequestFundManagers, turnoverRecordHistory]);
  useEffect(() => {
    setFilterDataSource(
      flow(
        map(
          update(
            "assets",
            filter((item: ChangingPositionHistoryType["assets"]) =>
              filterTradeType(
                fastProp("tradeType")(item),
                pickerValues.transactionType
              )
            )
          )
        ),
        reject((item) => isEmpty(fastProp("assets")(item)))
      )(orderBy("date", "desc")(dataSource))
    );
  }, [dataSource, pickerValues.transactionType]);
  return {
    dataSource: filterDataSource,
    changeDataSource,
  };
};

export default React.memo<{
  portfolioId: string;
}>(({ portfolioId }) => {
  const { stackBack } = useContext(PlatformNavigationContext);
  const formatMessage = useFormatMessage();
  const currentPortfolio = useGetCurrentPortfolio(portfolioId);
  const [pickerValues, updatePickerValues, reset] =
    useManagePickValues(currentPortfolio);
  const { dataSource, changeDataSource } = useGetDataSource(
    pickerValues,
    portfolioId
  );

  const disableDate = useCallback(
    (value: string) => {
      const valueDay = dayjs(value);
      return (
        valueDay.isBefore(fastProp("historyStart")(currentPortfolio)) ||
        valueDay.isAfter(fastProp("historyEnd")(currentPortfolio))
      );
    },
    [currentPortfolio]
  );

  const disableDateEnd = useCallback(
    (value: string) => {
      const valueDay = dayjs(value);
      return (
        valueDay.isBefore(pickerValues.warehouseStartDate) ||
        valueDay.isAfter(fastProp("historyEnd")(currentPortfolio))
      );
    },
    [currentPortfolio, pickerValues.warehouseStartDate]
  );
  return (
    <div
      className={cn(style.ChangingPositionForm, style.ChangingPositionHistory)}
    >
      <div className={style.LayoutScrollYContent}>
        <div className={style.FormCard}>
          <h3 className={style.CardTitle}>
            {fastProp("name")(currentPortfolio)}
          </h3>
          <div className={style.CardTitleFlex}>
            <div className={style.DatePicker}>
              <div className={style.DatePickerItem}>
                <p>{formatMessage("warehouseStartDate")}：</p>
                <TradingDatePicker
                  value={pickerValues.warehouseStartDate}
                  disabledDate={disableDate}
                  onChange={updatePickerValues("warehouseStartDate")}
                  className={style.Picker}
                />
              </div>
              <div className={style.DatePickerItem}>
                <p>{formatMessage("warehouseEndDate")}：</p>
                <TradingDatePicker
                  value={pickerValues.warehouseEndDate}
                  disabledDate={disableDateEnd}
                  onChange={updatePickerValues("warehouseEndDate")}
                  className={style.Picker}
                />
              </div>
              <div className={style.DatePickerItem}>
                <p>{formatMessage("transactionType")}：</p>
                <Select
                  options={transactionTypeOptions}
                  className={style.Picker}
                  value={pickerValues.transactionType}
                  onChange={updatePickerValues("transactionType")}
                />
              </div>
            </div>
            <div className={style.OperateArea}>
              <Button onClick={reset}>{formatMessage("reset")}</Button>
              <Button type="primary" onClick={changeDataSource}>
                {formatMessage("ok")}
              </Button>
            </div>
          </div>

          <Timeline className={style.TimeLine}>
            {map<ChangingPositionHistoryType, ReactNode>((item) => (
              <Timeline.Item key={item?.date}>
                <p className={style.TimeLineTitle}>
                  <Space>
                    {fastProp("source")(item)
                      ? formatMessage("systemWarehouseDate")
                      : formatMessage("warehouseDate")}
                    :{fastProp("date")(item)}
                    {fastProp("source")(item) && (
                      <Tooltip
                        title={formatMessage("warehouseAdjustmentTip")}
                        trigger="click"
                      >
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    )}
                  </Space>
                </p>
                <PositionHistoryTable dataSource={fastProp("assets")(item)} />
              </Timeline.Item>
            ))(dataSource)}
          </Timeline>
        </div>
      </div>
      <div className={style.LayoutFooter}>
        <Button type="primary" onClick={stackBack}>
          {formatMessage("cancel")}
        </Button>
      </div>
    </div>
  );
});
