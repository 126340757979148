import { assetPortfolios } from "@/model/portfolioList";
import { RootState } from "@/store";
import { normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";

export const portfolioListSelector = createSelector(
  (state: RootState) => state.portfolioList.portfolioList,
  (portfolioList) => portfolioList || []
);

export const portfolioListMapSelector = createSelector(
  portfolioListSelector,
  (portfolioList) => normalize<assetPortfolios>("id")(portfolioList)
);

export const backTestingSchemeSelector = createSelector(
  (state: RootState) => state.portfolioList.backTestingScheme,
  (backTestingScheme) => backTestingScheme || []
);
export const backTestingSchemeMapSelector = createSelector(
  backTestingSchemeSelector,
  (portfolioList) => normalize<any>("id")(portfolioList)
);

export const assetPortfolioIdMapSelector = createSelector(
  (state: RootState) => state.dataBoard.assetPortfolio,
  (assetPortfolio) => normalize("id")(assetPortfolio)
);

export const assetPortfolioMapSelector = createSelector(
  assetPortfolioIdMapSelector,
  portfolioListMapSelector,
  backTestingSchemeMapSelector,
  (assetPortfolio, portfolioListMap, backTestingSchemeMap) => ({
    ...assetPortfolio,
    ...portfolioListMap,
    ...backTestingSchemeMap,
  })
);
