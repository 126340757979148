import { useCreation } from "ahooks";
import AssertTypePieChart from "@/base-components/charts/assertTypePieChart";
import ColorNumber from "@/components/colorNumber";
import YieldStatisticRangePicker from "@/components/yieldStatisticRangePicker";
import {
  FROM_CREATION,
  RECENT_HALF_YEAR,
  RECENT_MONTH,
  RECENT_THREE_MONTH,
  RECENT_THREE_YEAR,
  RECENT_YEAR,
  statisticRange as defaultStatisticRange,
} from "@/constant/statisticRange";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  fetchAssetTypeDistributionInfo,
  fetchAssetTypeDistributionSummary,
} from "@/store/customerAccountAnalysis";
import { useFormatMessage } from "@/util/formatMessage";
import { Card, Col, Empty, Radio, Row, Space } from "antd";
import { find, first, flow, last, map, pick, prop, toArray } from "lodash/fp";
import React, { useEffect, useState } from "react";
import AssetsChangeChart from "../components/assetsChangeChart";
import {
  useGetAssetTypeDistributionSummaryData,
  useGetCurrentAssetTypeData,
  useGetLineChartData,
} from "./hooks";
import style from "./index.module.less";
import { fastProp } from "@/util/opt";
import LoadingComponent from "@/components/LoadingComponent";
import dayjs, { QUnitType, OpUnitType } from "dayjs";
import { formatPercentage } from "@/util/numberFormatter";
import AssetSummary, { AssetSummaryProps } from "../components/assetSummary";
import { CUSTOMER_BENCHMARK_TYPE, GROUP_TYPE } from "@/model/aum";

export default React.memo(
  ({
    customerId,
    totalAccountId,
  }: {
    customerId: string;
    totalAccountId: string;
  }) => {
    const formatMessage = useFormatMessage();
    const [type, setType] = useState<GROUP_TYPE>(
      GROUP_TYPE.ASSET_POSITION_TYPE
    );

    const customerAccountFoundDate = flow(
      find(
        (v: Record<string, any>) =>
          fastProp("customerAccountId")(v) === totalAccountId ||
          fastProp("customerAccountId")(v) === customerId
      ),
      fastProp("accountFoundDate")
    )(useAppSelector(prop("customerList.customerList")));
    const [statisticRange, setStatisticRange] = useState(FROM_CREATION);

    const { pieChartData: currentAssetTypeData } = useGetCurrentAssetTypeData({
      customerId,
      customerBenchmarkType: CUSTOMER_BENCHMARK_TYPE.CUSTOMER_AUM,
      groupType: type,
    });
    const { chartsData } = useGetLineChartData(
      customerId,
      statisticRange,
      type
    );

    const summaryData = useGetAssetTypeDistributionSummaryData(customerId);

    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(
        fetchAssetTypeDistributionInfo({
          customerId,
          customerBenchmarkType: CUSTOMER_BENCHMARK_TYPE.CUSTOMER_AVG,
          groupType: type,
        })
      );
      dispatch(
        fetchAssetTypeDistributionInfo({
          customerId,
          customerBenchmarkType: CUSTOMER_BENCHMARK_TYPE.CUSTOMER_AUM,
          groupType: type,
        })
      );

      dispatch(fetchAssetTypeDistributionSummary(customerId));
    }, [dispatch, customerId, type]);

    const { pieChartData: averageAssetTypeData, dataMissing } =
      useGetCurrentAssetTypeData({
        customerId,
        customerBenchmarkType: CUSTOMER_BENCHMARK_TYPE.CUSTOMER_AVG,
        groupType: type,
      });
    const rangeOptions = useCreation(() => {
      const endDate = flow(first, fastProp("dates"), last)(chartsData);
      const isOptionValid = (value: number, unit: QUnitType | OpUnitType) => {
        return dayjs(endDate).diff(customerAccountFoundDate, unit) >= value;
      };
      return flow(
        pick([
          ...(isOptionValid(1, "M") ? [RECENT_MONTH] : []),
          ...(isOptionValid(3, "M") ? [RECENT_THREE_MONTH] : []),
          ...(isOptionValid(6, "M") ? [RECENT_HALF_YEAR] : []),
          ...(isOptionValid(1, "year") ? [RECENT_YEAR] : []),
          ...(isOptionValid(3, "year") ? [RECENT_THREE_YEAR] : []),
          FROM_CREATION,
        ]),
        toArray
      )(defaultStatisticRange);
    }, [chartsData, customerAccountFoundDate]);

    const pieChartFormatter = (param: Record<string, any>) => {
      return `<div>
      <div style="color:${param?.color}">${param?.name}:${formatPercentage(
        param?.value
      )}</div>
    </div>`;
    };
    return (
      <Card size="small">
        <div className={style.AssetCardTop}>
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <Radio.Button value={GROUP_TYPE.ASSET_POSITION_TYPE}>
              {formatMessage("assetTypeDistribution")}
            </Radio.Button>
            <Radio.Button value={GROUP_TYPE.CATEGORY_POSITION_TYPE}>
              {formatMessage("largeClassDistribution")}
            </Radio.Button>
          </Radio.Group>
          <YieldStatisticRangePicker
            value={statisticRange}
            onChange={setStatisticRange}
            options={rangeOptions}
            className={style.YieldRangePicker}
          />
        </div>
        <div className={style.AssetCardCenter}>
          <Row gutter={50}>
            <Col span={8}>
              <div className={style.AssetDistributionLeft}>
                <Space
                  direction="vertical"
                  className={style.FullWidth}
                  size={20}
                >
                  <Card size="small">
                    <AssertTypePieChart
                      pieChartData={currentAssetTypeData}
                      title={formatMessage("currentAccount")}
                      formatter={pieChartFormatter}
                    />
                  </Card>
                  <div className={style.IconPart}>
                    <div className={style.VSIcon}></div>
                  </div>
                  <Card size="small">
                    <LoadingComponent actions={fetchAssetTypeDistributionInfo}>
                      {dataMissing ? (
                        <Empty
                          description={formatMessage("lessThan10CustomerTips")}
                        />
                      ) : (
                        <AssertTypePieChart
                          pieChartData={averageAssetTypeData}
                          title={formatMessage("averageCustomerPerformance")}
                          formatter={pieChartFormatter}
                        />
                      )}
                    </LoadingComponent>
                  </Card>
                </Space>
              </div>
            </Col>
            <Col span={16}>
              <div className={style.FullWidth}>
                <Space direction="vertical" className={style.FullWidth}>
                  {map((item: Record<string, any>) => (
                    <div className={style.fundCard}>
                      <div className={style.AssetType}>
                        {item?.type || formatMessage("fundNavWeight")}
                      </div>
                      <div
                        className={style.ChartCard}
                        style={{
                          borderLeft: `5px solid ${item.color}`,
                        }}
                      >
                        <div className={style.ChartCardContent}>
                          {item?.name !== undefined && (
                            <Space className={style.FullWidth}>
                              <h4>
                                {item.name}{" "}
                                {item.type !== "基金" && `（${item.code}）`}
                              </h4>
                              {item?.key !== "currencyPositionTrend" && (
                                <>
                                  <span className={style.CardText}>
                                    {formatMessage("price")}：
                                    <ColorNumber
                                      value={item?.benchmarkIndex}
                                      formatter="parseToThousandth"
                                    />
                                  </span>
                                  <span className={style.CardText}>
                                    {formatMessage(
                                      "accumulatedIncreaseOrDecrease"
                                    )}
                                    ：
                                    <ColorNumber
                                      value={item?.cumulativeWeight}
                                      formatter="percentage"
                                    />
                                  </span>
                                </>
                              )}
                            </Space>
                          )}
                          <AssetsChangeChart
                            series={item.series as any}
                            height={220}
                            dates={item.dates}
                            showLegend={true}
                            showDataZoom={false}
                            left={item?.name === undefined ? 40 : null}
                          />
                        </div>
                      </div>
                    </div>
                  ))(chartsData)}
                </Space>
              </div>
            </Col>
          </Row>
        </div>
        {false && (
          <div className={style.summaryBox}>
            {map((item: AssetSummaryProps) => (
              <AssetSummary
                {...item}
                benchmarkName={
                  find((v: Record<string, any>) => v.type === item.title)(
                    chartsData
                  )?.name ||
                  find((v: Record<string, any>) => v.type === undefined)(
                    chartsData
                  )?.name
                }
                dataMissing={dataMissing}
              />
            ))(summaryData)}
          </div>
        )}
      </Card>
    );
  }
);
