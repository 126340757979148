import React, { useMemo } from "react";
import { prop } from "lodash/fp";
import G6, { IShape } from "@antv/g6";
import { formatPercentage } from "@/util/numberFormatter";
import { mapTree } from "@/util/opt/tree";
import BaseChart from "../baseChart";
import type {
  Drag,
  PerformanceTreeData,
  PerformanceTreeChartProps,
} from "./type";

import "./index.css";

const defaultLabelCfg = {
  style: {
    fill: "#000",
    fontSize: 12,
  },
};

const defaultNodeStyle = {
  fill: "#91d5ff",
  stroke: "#40a9ff",
  radius: 5,
};

const defaultStateStyles = {
  hover: {
    opacity: 0.8,
  },
};

const ITEM_HEIHGT = 30;
const ITEM_WIDTH = 110;

function getDragCanvasConfig(drag: Drag) {
  if (!drag) return {};
  if (drag.x && drag.y) return { type: "drag-canvas" };
  if (drag.x) return { type: "drag-canvas", direction: "x" };
  if (drag.y) return { type: "drag-canvas", direction: "y" };
  return {};
}

function getBlockFillColor(value: number) {
  if (value > 0) {
    return "#e3587a";
  }
  if (value < 0) {
    return "#4ab52b";
  }
  return "#969696";
}

G6.registerNode(
  "performance-tree-node",
  {
    // options: {
    //   size: [60, 20],
    //   stroke: "#91d5ff",
    //   fill: "#91d5ff",
    // },
    draw(cfg, group) {
      //
      // const styles = this.getShapeStyle(cfg);
      const styles = {
        width: prop(`size.[0]`)(cfg) || 0,
        height: prop(`size.[1]`)(cfg) || 0,
      };
      const { labelCfg = {} } = cfg || {};
      const w = styles.width;
      const h = styles.height;
      const blockFillColor = getBlockFillColor(prop("value")(cfg));
      const showExpandIcon = !!cfg?.children;

      const keyShape = group?.addShape("rect", {
        attrs: {
          ...styles,
          height: styles.height,
          x: -w / 2,
          y: -h / 2,
          fill: blockFillColor,
          cursor: "pointer",
          radius: 4,
        },
      }) as IShape;

      group?.addShape("rect", {
        attrs: {
          x: -w / 2,
          y: -30 - h / 2,
          width: ITEM_WIDTH,
          height: 30,
          fill: cfg?.nameBg as string,
        },
      });

      showExpandIcon &&
        group?.addShape("marker", {
          attrs: {
            x: -w / 2 + 20,
            y: -18 - h / 2,
            r: 6,
            cursor: "pointer",
            symbol: cfg?.collapsed ? G6.Marker.expand : G6.Marker.collapse,
            stroke: "#666",
            lineWidth: 1,
            fill: "#fff",
          },
          name: "collapse-icon",
        });

      group?.addShape("text", {
        attrs: {
          ...labelCfg.style,
          text: cfg?.name,
          textAlign: showExpandIcon ? "left" : "center",
          cursor: "pointer",
          // x: 0,
          x: showExpandIcon ? -w / 2 + 40 : 0,
          y: -12 - h / 2,
        },
      });

      group?.addShape("text", {
        attrs: {
          ...labelCfg.style,
          text: formatPercentage(cfg?.value as number),
          x: 0,
          y: 20 - h / 2,
          textAlign: "center",
          cursor: "pointer",
          fill: "#fff",
        },
      });

      return keyShape;
    },
    update: undefined,
  },
  "rect"
);

const PerformanceTreeChart = React.memo<PerformanceTreeChartProps>(
  ({
    height = 500,
    data = {},
    drag = { x: true, y: false },
    backgroundColor = "#fff",
  }) => {
    const _data = useMemo(() => {
      if (data?.children) {
        return {
          ...data,
          nameBg: backgroundColor,
          children: mapTree<PerformanceTreeData>("children", (node) => ({
            ...node,
            nameBg: backgroundColor,
          }))(data?.children),
        };
      }
      return data;
    }, [data, backgroundColor]);
    const option = {
      height,
      linkCenter: true,
      modes: {
        default: [
          {
            type: "collapse-expand",
            // onChange: function onChange(item: any, collapsed: boolean) {
            //   const data = item.getModel();
            //   data.collapsed = collapsed;
            //   return true;
            // },
          },
          getDragCanvasConfig(drag),
        ],
      },
      defaultNode: {
        type: "performance-tree-node",
        size: [ITEM_WIDTH, ITEM_HEIHGT],
        style: defaultNodeStyle,
        labelCfg: defaultLabelCfg,
      },
      defaultEdge: {
        type: "cubic-vertical",
      },
      nodeStateStyles: defaultStateStyles,
      edgeStateStyles: defaultStateStyles,
      layout: {
        type: "compactBox",
        direction: "TB",
        getId: function getId(d: any) {
          return d.id;
        },
        // getHeight: function getHeight() {
        //   return 100;
        // },
        // getWidth: function getWidth() {
        //   return 16;
        // },
        getVGap: function getVGap() {
          return 50;
        },
        getHGap: function getHGap() {
          return 60;
        },
      },
    } as any;

    return (
      <BaseChart
        option={option}
        data={_data}
        backgroundColor={backgroundColor}
        height={height}
      />
    );
  }
);

export default PerformanceTreeChart;
