import { benchmarkMapSelector } from "@/selectors/benchmarks";
import { fundIdMapSelector } from "@/selectors/fund";
import { RootState } from "@/store";
import moment from "moment";
import { fastProp } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { last, map, prop } from "lodash/fp";
import getAnnualReturns from "@/util/quant/annualReturns";
import getAnnualVolatility from "@/util/quant/annualVolatility";
import getSharpeRatio from "@/util/quant/sharpeRatio";
import getMaxDrawdown from "@/util/quant/maxDrawdown";
import { RECENT_YEAR } from "@/constant/statisticRange";
import { getCumulativeCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { formatNilToZero } from "@/util/numberFormatter";

export const baseManagerSelector = createSelector(
  (state: RootState) => state.fundManagerDetail,
  (_: RootState, id: string) => id,
  (managerDetail, id) => prop(id)(managerDetail) || {}
);

export const managerYieldInfoSelector = createSelector(
  baseManagerSelector,
  (managerDetail) => {
    const dates = prop("dailyReturnInfo.dates")(managerDetail) || [];
    const returns = prop("dailyReturnInfo.returns")(managerDetail) || [];
    const dailyReturnsMap = normalizeDailyReturnsMap(dates, returns);
    return { dates, dailyReturnsMap, returns };
  }
);

const managerBenchmarkInfoSelector = createSelector(
  baseManagerSelector,
  benchmarkMapSelector,
  managerYieldInfoSelector,
  (state: RootState) => state.tradingDates,
  (state: RootState) => state.entities.riskFreeRate,
  (
    baseManager,
    benchmarkMap,
    managerYieldInfo,
    { processedTradingDates, tradingDateList },
    riskFreeRate
  ) => {
    const dates = fastProp("dates")(managerYieldInfo);
    const recentYearDate = getCumulativeCalculateRangeDate({
      dates,
      range: RECENT_YEAR,
      tradingDateList,
      processedTradingDates,
      thisYearDate: last(dates),
    });
    const recentYearRiskFreeRate = map<string, number>((date) =>
      fastProp(date)(riskFreeRate)
    )(recentYearDate);
    const benchmarkId = prop("basicInfo.ability.benchmarkId")(baseManager);
    const benchmarkName = prop(`${benchmarkId}.name`)(benchmarkMap);
    const benchmarkDailyReturns = prop(
      "basicInfo.ability.benchmarkDailyReturns"
    )(baseManager);
    const benchmarkDailyReturnsMap = normalizeDailyReturnsMap(
      fastProp("dates")(benchmarkDailyReturns),
      fastProp("dailyReturns")(benchmarkDailyReturns)
    );

    const benchmark1YDailyReturns = map<string, number>((date) =>
      formatNilToZero(fastProp(date)(benchmarkDailyReturnsMap))
    )(recentYearDate);

    const benchmarkAnnualizedReturn = getAnnualReturns(benchmark1YDailyReturns);
    const benchmarkAnnualizedVolatility = getAnnualVolatility(
      benchmark1YDailyReturns
    );
    const benchmarkSharpeRatio = getSharpeRatio(
      benchmark1YDailyReturns,
      recentYearRiskFreeRate
    );
    const benchmarkMaxDrawdown = getMaxDrawdown(benchmark1YDailyReturns);

    return {
      benchmarkId,
      benchmarkName,
      benchmarkSharpeRatio,
      benchmarkMaxDrawdown,
      benchmarkAnnualizedReturn,
      benchmarkAnnualizedVolatility,
    };
  }
);
export const currentManagerInfoSelector = createSelector(
  baseManagerSelector,
  fundIdMapSelector,
  managerBenchmarkInfoSelector,
  (baseManager, fundIdMap, managerBenchmarkInfo) => {
    const basicInfo = fastProp("basicInfo")(baseManager);
    const info = fastProp("info")(basicInfo);
    const ability = fastProp("ability")(basicInfo);
    const majorFund = fastProp("majorFund")(info);
    const majorFundName = prop(`${majorFund}.name`)(fundIdMap);
    const workAge = moment(fastProp("manageEndDate")(info) || undefined).diff(
      fastProp("manageStartDate")(info),
      "year"
    );

    return {
      ...info,
      ...ability,
      ...managerBenchmarkInfo,
      managerAvatar: fastProp("managerAvatar")(baseManager),
      workAge,
      majorFundName,
    };
  }
);
