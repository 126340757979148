import { useMemoizedFn } from "ahooks";
import { TableProps, Tooltip } from "antd";
import cn from "classnames";
import {
  flatten,
  flow,
  map,
  max,
  maxBy,
  prop,
  sumBy,
  size,
  orderBy,
  forEach,
} from "lodash/fp";
import { useCallback, useMemo } from "react";
import ToFundCompanyDetailPage from "@/components/navigateToPage/toFundCompanyDetailPage";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { fastNth, fastProp, mapIndexed, normalize } from "@/util/opt";
import { ChangeDetection, profOrDefList } from "@/model/aum";
import { formatPercentage } from "@/util/numberFormatter";
import { managerNameMapSelector } from "@/selectors/manager";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { dealScaleData, moldOptions } from "./constant";
import RankTitle from "./rankTitle";
import style from "./index.module.less";
import {
  getChartColumns,
  getMoldSubColumns,
  getScaleColumns,
} from "./constant";
import ToFundManagerDetailPage from "@/components/navigateToPage/toFundManagerDetailPage";
import { holdStockStyleMsg } from "@/constant/holdStockStyle";
import { allStocksMapSelector } from "@/selectors/stocks";

export const useGetFundBasicColumns = () => {
  const formatMessage = useFormatMessage();
  const fundMap = useAppSelector(fundIdMapSelector);
  const fundManagerMap = useAppSelector(managerNameMapSelector);
  return useCallback(
    (type: string) =>
      type === "table"
        ? [
            {
              dataIndex: "code",
              title: formatMessage("fundName"),
              fixed: "left",
              width: 150,
              render: (text: string) => {
                return (
                  <ToFundDetailPage
                    name={prop(`${text}.name`)(fundMap)}
                    id={prop(`${text}.fundId`)(fundMap)}
                    className={style.FundNameStyle}
                  />
                );
              },
            },
            {
              dataIndex: "code",
              title: formatMessage("fundCode"),
              width: 100,
              render: (text: string) => {
                return (
                  <ToFundDetailPage
                    name={prop(`${text}.code`)(fundMap)}
                    id={prop(`${text}.fundId`)(fundMap)}
                  />
                );
              },
            },
            {
              dataIndex: "fundManagerIds",
              width: 120,
              title: formatMessage("fundManagers"),
              render: (text: string[]) => {
                return (
                  <div className={style.FundManger}>
                    {mapIndexed((id: string, index: number) => (
                      <div className={style.FundMangerStyle} key={id}>
                        <ToFundManagerDetailPage
                          name={prop(`${id}.name`)(fundManagerMap)}
                          id={prop(`${id}.id`)(fundManagerMap)}
                        />
                        <span>{index + 1 !== size(text) ? "," : ""}</span>
                      </div>
                    ))(text || [])}
                  </div>
                );
              },
            },
          ]
        : [
            {
              dataIndex: "code",
              width: 200,
              title: (
                <span className={style.NameTitle}>
                  {formatMessage("fundProducts")}
                </span>
              ),
              render: (text: string) => {
                return (
                  <ToFundDetailPage
                    name={prop(`${text}.name`)(fundMap)}
                    id={prop(`${text}.fundId`)(fundMap)}
                    className={style.FundNameStyle}
                  />
                );
              },
            },
          ],
    [fundMap, formatMessage, fundManagerMap]
  );
};

export const useGetBasicData = (
  concentrationData: ChangeDetection[] | profOrDefList[],
  firstKey: string,
  secChildKey: string,
  secKey: string
) => {
  const { maxWeight, totalScale, subNum, maxsubTotleScale } = useMemo(() => {
    const maxWeight = flow(
      map((o: ChangeDetection | profOrDefList) =>
        Math.abs(fastProp(firstKey)(o))
      ),
      max
    )(concentrationData);
    const totalScale = flow(sumBy(firstKey))(concentrationData);
    const maxsubTotleScale = flow(
      map(fastProp(secChildKey)),
      map(sumBy(secKey)),
      max
    )(concentrationData);
    const subNum = flow(
      maxBy((o: ChangeDetection | profOrDefList) =>
        size(fastProp(secChildKey)(o))
      ),
      fastProp(secChildKey),
      size
    )(concentrationData);
    return { maxWeight, totalScale, subNum, maxsubTotleScale };
  }, [concentrationData, firstKey, secChildKey, secKey]);
  return { maxWeight, totalScale, subNum, maxsubTotleScale };
};

export const getSubColumns = (
  subNum: number,
  subKey: string,
  nameKey: string,
  scaleKey: string,
  formatMessage: FormatMessageFunc
) => {
  return flow(
    mapIndexed((_: any, index: number) => [
      {
        dataIndex: "orgName",
        title: <RankTitle number={index + 1} />,
        className: style.TableLeftBorder,
        width: 100,
        render: (_: any, record: Record<string, any>) => {
          const value =
            flow(fastProp(subKey), fastNth(index), fastProp(nameKey))(record) ||
            "--";
          return (
            <Tooltip title={value}>
              <p className={style.OrgName}>{value}</p>
            </Tooltip>
          );
        },
      },
      {
        dataIndex: `orgScale`,
        title: formatMessage("retentionScale"),
        width: 100,
        render: (_: any, record: Record<string, any>) => {
          return flow(
            fastProp(subKey),
            fastNth(index),
            fastProp(scaleKey),
            dealScaleData
          )(record);
        },
      },
      {
        dataIndex: `customers`,
        title: formatMessage("positionCustomersNumber"),
        width: 100,
        render: (_: any, record: Record<string, any>) => {
          return (
            flow(
              fastProp(subKey),
              fastNth(index),
              fastProp("customers")
            )(record) || "--"
          );
        },
      },
    ]),
    flatten
  )(new Array(subNum));
};

export const useGetColumns = (
  classification: string,
  type: string,
  concentrationData: ChangeDetection[],
  isShowSubOrganizations: boolean
) => {
  const formatMessage = useFormatMessage();
  const { maxWeight, totalScale, subNum, maxsubTotleScale } = useGetBasicData(
    concentrationData,
    "weight",
    "subOrgs",
    "orgScale"
  );
  const getFundBasicColumns = useGetFundBasicColumns();
  const fundManagerMap = useAppSelector(managerNameMapSelector);
  const fundCompanys = useAppSelector((state) => state.entities.companyName);
  const companyMapByName = useMemo(
    () => normalize("name")(fundCompanys),
    [fundCompanys]
  );
  const basicColumns = useMemo(() => {
    if (classification === "FUND") {
      return getFundBasicColumns(type);
    } else if (classification === "FUND_MANAGER") {
      return [
        {
          dataIndex: "code",
          width: type === "chart" ? 200 : 150,
          fixed: "left",
          title: (
            <span className={cn(type === "chart" && style.NameTitle)}>
              {formatMessage("fundManagers")}
            </span>
          ),
          render: (text: string) => {
            return (
              <ToFundCompanyDetailPage
                name={prop(`${text}.name`)(fundManagerMap)}
                id={text}
              />
            );
          },
        },
      ];
    } else {
      return [
        {
          dataIndex: "code",
          width: type === "chart" ? 200 : 150,
          fixed: "left",
          title: (
            <span className={cn(type === "chart" && style.NameTitle)}>
              {formatMessage("fundCompanys")}
            </span>
          ),
          render: (text: string) => {
            return (
              <ToFundCompanyDetailPage
                name={text}
                id={prop(`${text}.id`)(companyMapByName)}
                className={style.FundNameStyle}
              />
            );
          },
        },
      ];
    }
  }, [
    classification,
    formatMessage,
    type,
    getFundBasicColumns,
    fundManagerMap,
    companyMapByName,
  ]);

  const subColumns = getSubColumns(
    subNum,
    "subOrgs",
    "orgName",
    "orgScale",
    formatMessage
  );
  return useMemo<TableProps<any>["columns"]>(
    () =>
      type === "table"
        ? [
            ...basicColumns,
            ...getScaleColumns(formatMessage),
            ...(subColumns as any),
          ]
        : [
            ...basicColumns,
            ...getChartColumns(
              totalScale,
              maxWeight || 0,
              maxsubTotleScale,
              formatMessage,
              isShowSubOrganizations
            ),
          ],
    [
      formatMessage,
      basicColumns,
      subColumns,
      type,
      maxWeight,
      totalScale,
      maxsubTotleScale,
      isShowSubOrganizations,
    ]
  );
};

export const TooltipContent = ({
  name,
  scale,
  customers,
  showCustomers = true,
}: {
  name: string;
  scale: number;
  customers: number;
  showCustomers?: boolean;
}) => {
  const formatMessage = useFormatMessage();
  return (
    <div className={style.TooltipContent}>
      <p>{name}</p>
      <div>
        {formatMessage("positionSize")}: {dealScaleData(scale)}
      </div>
      {showCustomers && (
        <div>
          {formatMessage("positionCustomersNumber")}: {customers}
        </div>
      )}
    </div>
  );
};

export const useGetDataSource = (
  grpId: string,
  classification: string,
  dataKey: string,
  fundType?: string
) => {
  const data = useAppSelector(
    prop(
      `aum.${grpId}.positionStructure.concentrationData.${dataKey}.${
        classification === "FUND" ? fundType : classification
      }`
    )
  );

  const dataSource = useMemo(
    () =>
      flow(
        orderBy("weight", "desc"),
        forEach((item: ChangeDetection) =>
          orderBy("orgScale", "desc")(item?.subOrgs)
        )
      )(data),
    [data]
  );
  return dataSource;
};

export const useGetMoldColumns = (
  mold: string,
  type: string,
  concentrationData: ChangeDetection[],
  isShowSubOrganizations: boolean
) => {
  const formatMessage = useFormatMessage();
  const { maxWeight, totalScale, subNum, maxsubTotleScale } = useGetBasicData(
    concentrationData,
    "weight",
    "subOrgs",
    "orgScale"
  );

  const subColumns = getMoldSubColumns(
    subNum,
    "subOrgs",
    "orgName",
    "orgScale",
    formatMessage
  );

  const name = useMemo(() => {
    const moldOptionsMap = normalize("value")(moldOptions);
    return prop(`${mold}.label`)(moldOptionsMap);
  }, [mold]);
  const getRender = useGetMoldRender();
  return useMemo<TableProps<any>["columns"]>(
    () =>
      type === "table"
        ? [
            {
              dataIndex: "code",
              width: 120,
              fixed: "left",
              title: formatMessage(name),
              render: (text) => {
                return getRender(mold, text);
              },
            },
            {
              dataIndex: "scale",
              width: 120,
              title: (
                <span>
                  {formatMessage("Scale")}
                  {formatMessage("estimate")}
                </span>
              ),
              render: (text: number) => {
                return <span>{dealScaleData(text)}</span>;
              },
            },
            {
              dataIndex: "weight",
              width: 120,
              title: (
                <span>
                  {formatMessage("concentration")}
                  {formatMessage("estimate")}
                </span>
              ),
              render: (text: number) => {
                return formatPercentage(text);
              },
            },
            ...(subColumns as any),
          ]
        : [
            {
              dataIndex: "code",
              width: 200,
              title: formatMessage(name),
              render: (text) => {
                return getRender(mold, text);
              },
            },
            ...getChartColumns(
              totalScale,
              maxWeight || 0,
              maxsubTotleScale,
              formatMessage,
              isShowSubOrganizations,
              mold
            ),
          ],
    [
      formatMessage,
      subColumns,
      type,
      maxWeight,
      totalScale,
      maxsubTotleScale,
      name,
      getRender,
      mold,
      isShowSubOrganizations,
    ]
  );
};

export const useGetMoldRender = () => {
  const formatMessage = useFormatMessage();
  const industryMap = useAppSelector(sectorCategoriesMapSelector);
  const stockMap = useAppSelector(allStocksMapSelector);
  return useMemoizedFn((type: string, value: string) => {
    if (type === "STOCK_STYLE") {
      return flow(fastProp(value), formatMessage)(holdStockStyleMsg);
    } else if (type === "INDUSTRY") {
      return prop(`${value}.name`)(industryMap) || formatMessage(value);
    } else {
      return prop(`${value}.name`)(stockMap);
    }
  });
};

export const useGetSummaryData = (concentrationData: ChangeDetection[]) => {
  const totalScale = useMemo(
    () => flow(sumBy("scale"))(concentrationData),
    [concentrationData]
  );
  const maxWeightObject = useMemo(
    () => flow(maxBy("weight"))(concentrationData),
    [concentrationData]
  );
  const totalRatio = useMemo(
    () => flow(sumBy("weight"))(concentrationData),
    [concentrationData]
  );
  return { totalScale, maxWeightObject, totalRatio };
};
