import { Tooltip } from "antd";
import { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const CircleToolTip = (props: {
  title: string;
  visible: boolean;
}): JSX.Element => {
  const { title, visible } = props;
  const [mouseHandle, setMouseHandle] = useState<boolean>(false);
  return (
    <>
      <Tooltip title={title} visible={visible || mouseHandle} placement="right">
        <ExclamationCircleOutlined
          onMouseEnter={() => setMouseHandle(true)}
          onMouseLeave={() => setMouseHandle(false)}
          style={{ color: "rgba(0,0,0,.45)" }}
        />
      </Tooltip>
    </>
  );
};
export default CircleToolTip;
