import React, { ReactNode, useEffect, useState } from "react";
import { useAppDispatch } from "@/hooks/redux";
import {
  fetchAllStocks,
  fetchRiskFreeRate,
  fetchBenchmarks,
  fetchAllFund,
  fetchCompanyName,
  fetchManagerName,
  fetchIndustryThemeList,
  fetchCategory,
  fetchSectorCategories,
} from "@/store/entities";
import { fetchUserProfile } from "@/store/users";
import { fetchCompareBenchmark } from "@/store/compareManage";
import { fetchTradingDates } from "@/store/tradingDates";
import { getDataSource } from "@/store/users";
import { fetchOptionalFundGroupList } from "@/store/workbench";
import { startSocket } from "@/util/socket";
import LoadingContext from "./context";
import style from "./index.module.less";
import { addGlobalLoading, subGlobalLoading } from "@/store/global";
// import { getUserProfile } from "@/api/userConfig";
import { fetchCustomBenchmarkList } from "@/store/customBenchmark";

const useStartSocket = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(addGlobalLoading());
    startSocket(dispatch).finally(() => {
      setLoading(false);
      dispatch(subGlobalLoading());
    });
  }, [dispatch]);
  return loading;
};

export default React.memo<{
  children: ReactNode;
}>(({ children }) => {
  const socketLoading = useStartSocket();
  const dispatch = useAppDispatch();
  useEffect(() => {
    Promise.all([
      dispatch(fetchAllFund()),
      dispatch(fetchManagerName()),
      dispatch(fetchCompanyName()),
      dispatch(getDataSource()),
      dispatch(fetchTradingDates()),
      dispatch(fetchRiskFreeRate()),
      dispatch(fetchBenchmarks()),
      dispatch(fetchAllStocks()),
      dispatch(fetchIndustryThemeList()),
      dispatch(fetchCategory()),
      dispatch(fetchCompareBenchmark()),
      dispatch(fetchSectorCategories()),
      dispatch(fetchOptionalFundGroupList()),
      dispatch(fetchCustomBenchmarkList()),
      dispatch(fetchUserProfile()),
    ]);
  }, [dispatch]);
  return (
    <LoadingContext.Provider value={{ commonApiLoading: socketLoading }}>
      {socketLoading ? <div className={style.FullLoading} /> : children}
    </LoadingContext.Provider>
  );
});
