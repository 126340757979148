import { useAppSelector } from "@/hooks/redux";
import { orderedNotDelistingFundsSelector } from "@/selectors/fund";
import { useFormatMessage } from "@/util/formatMessage";
import { searchData } from "@/util/search";
import { useCreation, useMemoizedFn } from "ahooks";
import { Cascader, CascaderProps, Input } from "antd";
import { filter, first, identity, prop, size } from "lodash/fp";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { AllMarket } from "../../portfolioCompoents/constant";
import style from "../index.module.less";
import { useVirtualizer } from "@tanstack/react-virtual";
import { fastProp, normalize } from "@/util/opt";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import HighLight from "@/components/highLight";

type fundCascaderProps = Omit<CascaderProps<string>, "onChange" | "value"> & {
  onChange?: (value: string) => void;
  value?: string;
  ownIds?: string[];
};

export default React.memo(
  ({ onChange = identity, value, ownIds }: fundCascaderProps): JSX.Element => {
    const formatMessage = useFormatMessage();
    const allFund = useAppSelector(orderedNotDelistingFundsSelector);
    const [parentId, setParentId] = useState("");
    const cascaderRef = useRef<any>(null);
    const [inputValue, setInputValue] = useState("");
    const options = useCreation(
      () => [
        {
          name: formatMessage("AllMarket"),
          fundId: AllMarket,
          children: allFund,
        },
      ],
      [formatMessage, allFund]
    );
    const cascaderValue = useCreation(() => {
      if (!value) return "";
      return [AllMarket, value];
    }, [value]);

    const changeValue = useCallback(
      (v: string[] | any) => {
        cascaderRef?.current?.blur();
        onChange(v);
        setInputValue("");
      },
      [onChange]
    );

    const optionsMap = useMemo(() => {
      return normalize("fundId")(options);
    }, [options]);

    const childrenInfo = useMemo(() => {
      return prop(`${parentId ? parentId : first(cascaderValue)}.children`)(
        optionsMap
      );
    }, [optionsMap, cascaderValue, parentId]);

    const filterChildInfo = useMemo(() => {
      return filter(
        (item: Record<string, any>) => !ownIds?.includes(item.fundId)
      )(childrenInfo);
    }, [childrenInfo, ownIds]);

    const dropdownRender = useMemoizedFn(() => {
      const selectedParentId = parentId || first(cascaderValue);
      return (
        <div className={style.customDropdown}>
          <div className={style.parentPart}>
            {options.map((item: Record<string, any>) => {
              return (
                <li
                  className={
                    selectedParentId === item.fundId
                      ? style.selectedRow
                      : style.parentRowStyle
                  }
                  onClick={() => setParentId(item.fundId)}
                  key={item.fundId}
                >
                  <div className={style.parentItem}>
                    {item.name}
                    <RightOutlined className={style.icon} />
                  </div>
                </li>
              );
            })}
          </div>
          {selectedParentId && (
            <div className={style.childPart}>
              <RowVirtualizerFixed
                childInfo={filterChildInfo as Record<string, any>[]}
                changeValue={changeValue}
                selectedValue={cascaderValue ? cascaderValue[1] : ""}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </div>
          )}
        </div>
      );
    });

    const displayRender = useMemoizedFn((label: any[]) => {
      const childInfoMap = normalize("fundId")(childrenInfo);
      return prop(`${label[1]}.name`)(childInfoMap);
    });

    const onDropdownVisibleChange = useMemoizedFn((value: boolean) => {
      if (!value) {
        setParentId("");
      }
    });

    return (
      <>
        <Cascader
          ref={cascaderRef}
          onChange={changeValue}
          placeholder={formatMessage("selectFundTip")}
          value={cascaderValue as string[]}
          dropdownRender={dropdownRender}
          displayRender={displayRender}
          onDropdownVisibleChange={onDropdownVisibleChange}
        />
      </>
    );
  }
);

export const RowVirtualizerFixed = ({
  childInfo,
  changeValue,
  selectedValue,
  inputValue,
  setInputValue,
}: {
  childInfo: Record<string, any>[];
  changeValue: (value: string) => void;
  selectedValue: string;
  inputValue: string;
  setInputValue: (value: string) => void;
}) => {
  const formatMessage = useFormatMessage();
  const parentRef = React.useRef(null);
  const options = useCreation(() => {
    if (!inputValue) return childInfo;
    return searchData(childInfo, inputValue, ["name", "code"]);
  }, [inputValue]);

  const rowVirtualizer = useVirtualizer({
    horizontal: false,
    count: size(options),
    getScrollElement: () => parentRef.current,
    estimateSize: () => 30,
    overscan: 20,
    paddingStart: 10,
  });

  const onSearchFund = useCallback(
    (inputValue: string) => {
      setInputValue(inputValue);
    },
    [setInputValue]
  );

  return (
    <div className={style.rowVirtualPart}>
      <div className={style.SeachPart}>
        <Input
          className={style.Search}
          placeholder={formatMessage("enterKeywords")}
          suffix={<SearchOutlined />}
          allowClear
          onChange={(e) => onSearchFund(e.target.value)}
          value={inputValue}
        />
      </div>
      <div
        ref={parentRef}
        className="List"
        style={{
          height: `200px`,
          width: `400px`,
          overflow: "auto",
        }}
      >
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: "98%",
            position: "relative",
          }}
        >
          {rowVirtualizer
            .getVirtualItems()
            .map((virtualRow: Record<string, any>) => (
              <li
                key={virtualRow.index}
                className={
                  selectedValue === fastProp("code")(options[virtualRow.index])
                    ? style.selectedRow
                    : style.rowStyle
                }
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: `${virtualRow.size}px`,
                  lineHeight: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
                onClick={() =>
                  changeValue(fastProp("code")(options[virtualRow.index]))
                }
              >
                <HighLight
                  value={`${fastProp("name")(
                    options[virtualRow.index]
                  )}(${fastProp("code")(options[virtualRow.index])})`}
                  keyValue={inputValue}
                  color="red"
                />
              </li>
            ))}
        </div>
      </div>
    </div>
  );
};
