import { flow, map, orderBy } from "lodash/fp";
import { getStocksFilterList } from "@/api/stocks";
import { Fund } from "@/components/fundResultTable";
import { StockFilter, StockPositions } from "@/model/stockBackCheck";
import createSocketThunk from "@/util/createSocketThunk";
import { createSlice } from "@reduxjs/toolkit";
import { fastProp } from "@/util/opt";
import { GREATER_THAN, INTERSECTION } from "@/constant/fundFilter";
import { TaskData } from "@/util/socket";

export const fetchStocksFilterList = createSocketThunk(
  "stockSlice/stocksFilterList",
  "FINISH_FUND_QUERY_BY_STOCK",
  async (v: StockFilter) => {
    const response = await getStocksFilterList(v);
    return response;
  }
);
const fetchMainFilterListStart: string = fetchStocksFilterList.start.type;
const fetchMainFilterListProgress: string = fetchStocksFilterList.progress.type;
const fetchMainFilterListFinish: string = fetchStocksFilterList.finish.type;
const fetchMainFilterListError: string = fetchStocksFilterList.error.type;
interface StockBackCheckSliceState {
  stocksFilterList: Fund[];
  filterTaskProgress?: TaskData;
  stocksFilter: StockFilter;
  stocksPositions: StockPositions[];
}
export const defaultStocksFilter = {
  stockPositions: [
    {
      id: 1,
      stockCode: "",
      positionRange: GREATER_THAN,
      position: 0,
    },
  ],
  resultRange: INTERSECTION,
};
const initialState: StockBackCheckSliceState = {
  stocksFilterList: [],
  stocksFilter: defaultStocksFilter,
  stocksPositions: [],
};

const stockBackCheckSlice = createSlice({
  name: "stockSlice",
  initialState,
  reducers: {
    addStocksFilter: (state: StockBackCheckSliceState, action) => {
      state.stocksFilter = action.payload;
    },
    setStockPositions: (state: StockBackCheckSliceState, action) => {
      state.stocksPositions = action.payload;
    },
  },
  extraReducers: {
    [fetchMainFilterListStart]: (state, action) => {
      state.filterTaskProgress = action?.meta;
    },
    [fetchMainFilterListProgress]: (state, action) => {
      state.filterTaskProgress = action?.payload;
    },
    [fetchMainFilterListFinish]: (state, action) => {
      state.stocksFilterList = flow(
        orderBy<Fund>("code", "asc"),
        map((v: Fund) => ({
          ...v,
          ...fastProp("stockPosition")(v),
        }))
      )(action?.payload?.view);
      state.filterTaskProgress = action?.meta;
    },
    [fetchMainFilterListError]: (state, action) => {
      state.filterTaskProgress = action.error;
    },
  },
});

export const { addStocksFilter, setStockPositions } =
  stockBackCheckSlice.actions;
export default stockBackCheckSlice.reducer;
