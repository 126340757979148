import { map } from "lodash/fp";
import React, { useRef, useState } from "react";
import { useCreation, useSize } from "ahooks";
import { Timeline } from "antd";
import cn from "classnames";
import FundWeightTable from "../components/fundWeightTable";
import { ManualCreatePortfolio } from "../constant";
import style from "./index.module.less";
import { fastNth, mapIndexed } from "@/util/opt";
import { useFormatMessage } from "@/util/formatMessage";
import { isEmpty } from "lodash/fp";
import EmptyContent from "@/components/emptyContent";

export default React.memo<{
  data: ManualCreatePortfolio[];
}>(({ data }) => {
  const [activeAsset, setActiveAsset] = useState<ManualCreatePortfolio>(
    fastNth(0)(data)
  );
  const formatMessage = useFormatMessage();
  const dates = useCreation<string[]>(() => map("turnoverDate")(data), [data]);
  const tableRef = useRef(null);
  const tableSize = useSize(tableRef);
  const timeLineStyle = useCreation(
    () => ({
      height: (tableSize?.height || 45) - 45,
    }),
    [tableSize?.height]
  );
  return isEmpty(data) ? (
    <EmptyContent message="暂无数据" />
  ) : (
    <div className={style.DisplayContainer}>
      <div className={style.DisplayTime}>
        <h3 className={style.DisplayTimeTitle}>
          {formatMessage("openPosition")} / {formatMessage("theWarehouse")}
        </h3>
        <Timeline className={style.DisplayTimeLine} style={timeLineStyle}>
          {mapIndexed((date: string, index: number) => (
            <Timeline.Item
              key={date}
              className={cn({
                [style.FirstAsset]: index === 0,
                [style.ActiveAsset]: activeAsset.turnoverDate === date,
                [style.NotActiveAsset]:
                  activeAsset.turnoverDate !== date && index !== 0,
              })}
            >
              <span onClick={() => setActiveAsset(fastNth(index)(data))}>
                {date}
              </span>
            </Timeline.Item>
          ))(dates)}
        </Timeline>
      </div>
      <div className={style.DisplayTable}>
        {!isEmpty(activeAsset) && (
          <FundWeightTable tableRef={tableRef} dataSource={activeAsset} />
        )}
      </div>
    </div>
  );
});
