import ColorNumber from "@/components/colorNumber";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { useAppSelector } from "@/hooks/redux";
import {
  AssetItemType,
  PositionAssetsListBody,
  PositionAssetsListItem,
  PositionAssetTransactionParam,
} from "@/model/customer";
import { useFormatMessage } from "@/util/formatMessage";
import {
  fixedNumberWithCommas,
  formatNil,
  toFixedNumber,
} from "@/util/numberFormatter";
import { fastProp, includeStr, mapIndexed, normalize } from "@/util/opt";
import { AsyncThunk } from "@reduxjs/toolkit";
import { Space, Tooltip } from "antd";
import { RuleObject } from "antd/es/form";
import { ColumnsType, ColumnType } from "antd/lib/table";
import {
  concat,
  filter,
  find,
  flatten,
  flow,
  groupBy,
  isArray,
  isEmpty,
  isNil,
  isNumber,
  map,
  prop,
  reduce,
  size,
  some,
  trim,
} from "lodash/fp";
import { ReactNode, useCallback, useMemo } from "react";
import { totalAccountProp } from "../../customerAccountAnalysis/customerSummary";
import FixedInvestmentDetail from "./components/fixedInvestmentDetail";
import FixedInvestmentManage from "./components/fixedInvestmentManage";
import Purchase from "./components/purchase";
import Redeem from "./components/redeem";
import TransferInAndOut, { TRANSFER_TYPE } from "./components/transferInAndOut";

import style from "./index.module.less";

export const useGetColumns = ({
  accountId,
  isTotalAccount,
  accountList,
  setPurchaseAndRedeemList,
}: {
  accountId: string;
  isTotalAccount: boolean;
  accountList: totalAccountProp[];
  setPurchaseAndRedeemList: React.Dispatch<
    React.SetStateAction<PositionAssetTransactionParam["assets"]>
  >;
}) => {
  const formatMessage = useFormatMessage();
  return useMemo<ColumnType<AssetItemType>[]>(
    () => [
      {
        title: formatMessage("assetsName"),
        dataIndex: "assetName",
        key: "assetName",
        width: 300,
        render: (assetName: string | ReactNode, record: AssetItemType) => {
          const { assetCode, assetType } = record;
          return assetCode ? (
            <div className={style.AssetName}>
              {assetType !== "STOCK" ? (
                <ToFundDetailPage
                  className={style.ToFundDetailPage}
                  name={assetName as string}
                  id={assetCode}
                />
              ) : (
                <Tooltip className={style.ToFundDetailPage} title={assetName}>
                  {assetName}
                </Tooltip>
              )}
              <div>({assetCode})</div>
            </div>
          ) : (
            assetName
          );
        },
      },
      {
        title: formatMessage("account"),
        dataIndex: "accountId",
        key: "accountId",
        ellipsis: true,
        align: "right",
        render: (accountId: string, record: AssetItemType) => {
          const accountName = flow(
            find((item: totalAccountProp) => item?.id === accountId),
            fastProp("message")
          )(accountList);
          const { assetCode } = record;
          return (
            assetCode && (
              <Tooltip
                className={style.InlineBlock}
                title={formatNil(accountName)}
              >
                {formatNil(accountName)}
              </Tooltip>
            )
          );
        },
      },
      {
        title: formatMessage("holdingShare"),
        dataIndex: "holdingShare",
        key: "holdingShare",
        ellipsis: true,
        align: "right",
        render: (holdingShare: number, record: AssetItemType) => {
          const { assetCode } = record;
          return (
            assetCode && (
              <Tooltip
                className={style.InlineBlock}
                title={toFixedNumber(2)(holdingShare)}
              >
                {toFixedNumber(2)(holdingShare)}
              </Tooltip>
            )
          );
        },
      },
      {
        title: formatMessage("marketPrice"),
        dataIndex: "marketPrice",
        key: "marketPrice",
        ellipsis: true,
        align: "right",
        render: (marketPrice: number, record: AssetItemType) => {
          const { assetCode } = record;
          return (
            assetCode && (
              <Tooltip
                className={style.InlineBlock}
                title={toFixedNumber(2)(marketPrice)}
              >
                {toFixedNumber(2)(marketPrice)}
              </Tooltip>
            )
          );
        },
      },
      {
        title: `${formatMessage("holdIncome")}(${formatMessage("YUAN")})`,
        dataIndex: "holdingIncome",
        key: "holdingIncome",
        ellipsis: true,
        align: "right",
        width: 120,
        render: (holdingIncome: number, record: AssetItemType) => {
          const { assetCode } = record;
          return (
            assetCode && (
              <Tooltip title={toFixedNumber(2)(holdingIncome)}>
                <div className={style.InlineBlock}>
                  <ColorNumber value={holdingIncome} formatter="fixedNumber" />
                </div>
              </Tooltip>
            )
          );
        },
      },
      {
        title: `${formatMessage("amount")}(${formatMessage("YUAN")})`,
        dataIndex: "holdingAmount",
        key: "holdingAmount",
        width: 120,
        ellipsis: true,
        align: "right",
        render: (holdingAmount: number, record: AssetItemType) => {
          const { assetCode } = record;
          return (
            assetCode && (
              <Tooltip
                className={style.InlineBlock}
                title={fixedNumberWithCommas(holdingAmount)}
              >
                {fixedNumberWithCommas(holdingAmount)}
              </Tooltip>
            )
          );
        },
      },
      {
        title: formatMessage("holdDays"),
        dataIndex: "holdingDays",
        key: "holdingDays",
        ellipsis: true,
        align: "right",
        render: (holdingDays: number, record: AssetItemType) => {
          const { assetCode } = record;
          return (
            assetCode && (
              <Tooltip
                className={style.InlineBlock}
                title={formatMessage("days", { number: holdingDays })}
              >
                {formatMessage("days", { number: holdingDays })}
              </Tooltip>
            )
          );
        },
      },
      {
        title: formatMessage("investmentPlan"),
        dataIndex: "investPlanId",
        key: "investPlanId",
        ellipsis: true,
        align: "right" as any,
        render: (
          investPlanId: AssetItemType["investPlanId"],
          record: AssetItemType
        ) => {
          const { accountId } = record;
          return (
            record?.assetCode &&
            (investPlanId ? (
              <FixedInvestmentDetail record={record} accountId={accountId} />
            ) : (
              "--"
            ))
          );
        },
      },
      ...(isTotalAccount
        ? []
        : [
            {
              title: formatMessage("operator"),
              dataIndex: "operator",
              key: "operator",
              width: 150,
              render: (
                customOperator: ReactNode | undefined,
                record: AssetItemType
              ) => {
                const { assetCode, assetType } = record;
                return customOperator
                  ? customOperator
                  : assetCode && (
                      <Space>
                        {assetType === "OPEN_FUND" && (
                          <FixedInvestmentManage
                            assetItem={record}
                            accountId={accountId}
                          />
                        )}
                        <Purchase
                          setPurchaseAndRedeemList={setPurchaseAndRedeemList}
                          record={record}
                          accountId={accountId}
                        />
                        <Redeem
                          setPurchaseAndRedeemList={setPurchaseAndRedeemList}
                          record={record}
                          accountId={accountId}
                        />
                      </Space>
                    );
              },
            },
          ]),
    ],
    [
      accountId,
      accountList,
      formatMessage,
      isTotalAccount,
      setPurchaseAndRedeemList,
    ]
  );
};

export const useGetDataSource = ({
  positionAssetsList,
  searchStr,
  ...restProps
}: {
  positionAssetsList: PositionAssetsListBody;
  searchStr: string;
  accountId: string;
  customerName: string;
  totalAccountId: string;
}) => {
  const formatMessage = useFormatMessage();
  return useMemo(() => {
    let cashScale = 0;
    return flow(
      map((item: PositionAssetsListItem) => {
        cashScale += item.cashScale;
        return map((v: AssetItemType) => {
          return {
            ...v,
            accountId: item.accountId,
          };
        })(item.assets);
      }),
      flatten,
      groupBy("assetType"),
      mapIndexed(
        (item: (AssetItemType & { cashScale: number })[], key: string) => ({
          key: key,
          assetName: `${formatMessage(key)} (${size(item)})`,
          children: filter(
            (v: AssetItemType) =>
              includeStr(searchStr)(fastProp("assetCode")(v)) ||
              includeStr(searchStr)(fastProp("assetName")(v))
          )(item),
          cashScale: reduce(
            (prev: number, curr: AssetItemType & { cashScale: number }) =>
              curr.cashScale + prev
          )(0)(item),
        })
      ),
      (data) =>
        concat(data)({
          key: "cashScale",
          assetName: (
            <div className={style.CashScale}>
              <div>{formatMessage("CASHAsset")}</div>
              <div>
                {formatMessage("amountOfMoney")}：
                {fixedNumberWithCommas(cashScale)}
              </div>
            </div>
          ),
          operator: (
            <Space>
              <TransferInAndOut
                {...restProps}
                transferType={TRANSFER_TYPE.IN}
              />
              <TransferInAndOut
                {...restProps}
                transferType={TRANSFER_TYPE.OUT}
              />
            </Space>
          ),
        }),
      mapIndexed((item: Record<string, any>, stickyRowIndex: number) => ({
        ...item,
        stickyRowIndex,
      }))
    )(positionAssetsList) as AssetItemType[];
  }, [formatMessage, positionAssetsList, restProps, searchStr]);
};

type ActionType = AsyncThunk<any, any, any>;
export const useGetIsActionLoading = (actions: ActionType | ActionType[]) => {
  const loadings = useAppSelector(prop("global.loadings"));
  const actionIsLoading = useCallback(
    (type: string) => {
      if (!type) return false;
      const loadingCount = fastProp(type)(loadings);
      return isNumber(loadingCount) && loadingCount > 0;
    },
    [loadings]
  );
  return useMemo(() => {
    if (isEmpty(actions)) return false;
    if (!isArray(actions)) {
      return actionIsLoading(actions.typePrefix);
    }
    return some((action: ActionType) => actionIsLoading(action.typePrefix))(
      actions
    );
  }, [actionIsLoading, actions]);
};

export const useGetFrequencyOptions = () => {
  const formatMessage = useFormatMessage();

  const WEEK_DAYS = useMemo(
    () => [
      {
        value: 1,
        label: formatMessage("monday"),
      },
      {
        value: 2,
        label: formatMessage("tuesday"),
      },
      {
        value: 3,
        label: formatMessage("wednesday"),
      },
      {
        value: 4,
        label: formatMessage("thursday"),
      },
      {
        value: 5,
        label: formatMessage("friday"),
      },
    ],
    [formatMessage]
  );

  const MONTH_DAYS = useMemo(
    () =>
      Array(31)
        .fill(31)
        .map((_: any, index: number) => ({
          value: index + 1,
          label: `${index + 1}${formatMessage("day")}`,
        })),
    [formatMessage]
  );

  return useMemo(
    () => [
      {
        label: formatMessage("everyDay"),
        value: "DAY",
      },
      {
        label: formatMessage("everyWeek"),
        value: "WEEK",
        children: WEEK_DAYS,
      },
      {
        label: formatMessage("everyTwoWeek"),
        value: "WEEK_2",
        children: WEEK_DAYS,
      },
      {
        label: formatMessage("everyMonth"),
        value: "MONTH",
        children: MONTH_DAYS,
      },
    ],
    [MONTH_DAYS, WEEK_DAYS, formatMessage]
  );
};

export const useNotNullValidator = () => {
  const formatMessage = useFormatMessage();
  return (message: string) => (rule: RuleObject, value: any) => {
    if (isNil(value) || trim(value) === "")
      return Promise.reject(
        formatMessage("pleaseEnterCorrect", {
          label: message,
        })
      );
    return Promise.resolve();
  };
};

export const useLargeThanZeroValidator = () => {
  const formatMessage = useFormatMessage();
  return (message: string, isCanEqZero = false) =>
    (rule: RuleObject, value: number) => {
      if (value > 0) return Promise.resolve();
      if (isCanEqZero && value === 0) return Promise.resolve();
      return Promise.reject(
        formatMessage("pleaseEnterCorrect", {
          label: message,
        })
      );
    };
};

type SubmitItemType = PositionAssetTransactionParam["assets"][number];
const TRANSACTION_TYPE: Record<string, string> = {
  PURCHASE: "increasePosition",
  REDEMPTION: "redeem",
};
export const useGetSubmitTableColumns = (
  positionAssetsList: PositionAssetsListBody,
  handleDeleteSubmitItem: (assetCode: string) => void
) => {
  const formatMessage = useFormatMessage();
  const assetCodeMap = flow(
    map(fastProp("assets")),
    flatten,
    normalize("assetCode")
  )(positionAssetsList);
  return useMemo<ColumnsType<SubmitItemType>>(
    () => [
      {
        title: formatMessage("assetsName"),
        dataIndex: "assetName",
        key: "assetName",
        width: 370,
        render: (_: string | ReactNode, record: SubmitItemType) => {
          const { assetCode, assetType } = record;
          const assetName = prop(`${assetCode}.assetName`)(assetCodeMap);
          return assetCode ? (
            <div className={style.AssetName}>
              {assetType !== "STOCK" ? (
                <ToFundDetailPage
                  className={style.ToFundDetailPage}
                  name={assetName as string}
                  id={assetCode}
                />
              ) : (
                <Tooltip className={style.ToFundDetailPage} title={assetName}>
                  {assetName}
                </Tooltip>
              )}
              <div>({assetCode})</div>
            </div>
          ) : (
            assetName
          );
        },
      },
      {
        title: formatMessage("Date"),
        dataIndex: "date",
        width: 120,
        key: "date",
        ellipsis: true,
        align: "left",
        render: (date: string) => {
          return (
            <Tooltip className={style.InlineBlock} title={date}>
              {date}
            </Tooltip>
          );
        },
      },
      {
        title: formatMessage("assetType"),
        dataIndex: "assetType",
        key: "assetType",
        ellipsis: true,
        align: "left",
        render: (assetType: string) => {
          return (
            <Tooltip
              className={style.InlineBlock}
              title={formatMessage(assetType)}
            >
              {formatMessage(assetType)}
            </Tooltip>
          );
        },
      },
      {
        title: formatMessage("transactionType"),
        dataIndex: "transactionType",
        key: "transactionType",
        ellipsis: true,
        align: "left",
        render: (transactionType: string) => {
          const message = formatMessage(TRANSACTION_TYPE[transactionType]);
          return (
            <Tooltip className={style.InlineBlock} title={message}>
              {message}
            </Tooltip>
          );
        },
      },
      {
        title: formatMessage("amountOfMoney"),
        dataIndex: "amount",
        key: "amount",
        ellipsis: true,
        align: "right",
        render: (amount: number) => {
          return (
            <Tooltip
              className={style.InlineBlock}
              title={toFixedNumber(2)(amount)}
            >
              {toFixedNumber(2)(amount)}
            </Tooltip>
          );
        },
      },
      {
        title: formatMessage("portion"),
        dataIndex: "share",
        key: "share",
        ellipsis: true,
        align: "right",
        render: (share: number) => {
          return (
            <Tooltip
              className={style.InlineBlock}
              title={toFixedNumber(2)(share)}
            >
              {toFixedNumber(2)(share)}
            </Tooltip>
          );
        },
      },
      {
        title: formatMessage("price"),
        dataIndex: "price",
        key: "price",
        ellipsis: true,
        align: "right",
        render: (price: number) => {
          return (
            <Tooltip
              className={style.InlineBlock}
              title={toFixedNumber(2)(price)}
            >
              {toFixedNumber(2)(price)}
            </Tooltip>
          );
        },
      },
      {
        title: formatMessage("serviceCharge"),
        dataIndex: "handlingFee",
        key: "handlingFee",
        ellipsis: true,
        align: "right",
        render: (handlingFee: number) => {
          return (
            <Tooltip
              className={style.InlineBlock}
              title={toFixedNumber(2)(handlingFee)}
            >
              {toFixedNumber(2)(handlingFee)}
            </Tooltip>
          );
        },
      },
      {
        title: formatMessage("operator"),
        dataIndex: "operator",
        key: "operator",
        render: (_: any, record: any) => {
          const { assetCode } = record;
          return (
            <Space>
              <a
                onClick={() => {
                  handleDeleteSubmitItem(assetCode);
                }}
              >
                {formatMessage("delete")}
              </a>
            </Space>
          );
        },
      },
    ],
    [assetCodeMap, formatMessage, handleDeleteSubmitItem]
  );
};
