import React, { useMemo } from "react";
import { fastProp } from "@/util/opt";
import type { BarSeriesOption, EChartsOption } from "echarts";
import { map, merge, omit } from "lodash/fp";
import EchartsWrapper, { WrapperProps } from "./echartsWrapper";

export interface StackBarChartOpts extends WrapperProps {
  options?: EChartsOption & {
    series: BarSeriesOption;
  };
}

export default React.memo<StackBarChartOpts>(
  ({ options, events, width, height }) => {
    const handleOptions = useMemo(
      () =>
        merge({
          grid: {
            left: 0,
            right: 0,
            top: 50,
            bottom: 30,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "none",
            },
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "category",
            axisLabel: {
              formatter: (name: string) => name,
            },
          },
          series: map<BarSeriesOption, BarSeriesOption>((data) => ({
            type: "bar",
            stack: "total",
            label: {
              show: false,
            },
            ...data,
          }))(fastProp("series")(options)),
        })(omit("series")(options)),
      [options]
    );
    return (
      <EchartsWrapper
        width={width}
        height={height}
        options={handleOptions}
        events={events}
      />
    );
  }
);
