import { PieChartData } from "@/base-components/charts/assertTypePieChart/type";
import { useGetCumulativeChartCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { LineChartOpts } from "@/echarts/lineChart";
import { useAppSelector } from "@/hooks/redux";
import {
  AssetTypeDistributionBody,
  AssetTypeDistributionSummaryBody,
  PositionTrendBody,
} from "@/model/customer";
import { RootState } from "@/store";
import { useFormatMessage } from "@/util/formatMessage";
import { formatNilToZero, toFixedNumber } from "@/util/numberFormatter";
import { fastProp, getProp, mapIndexed } from "@/util/opt";
import cumulativeReturns from "@/util/quant/cumulativeReturns";
import {
  compact,
  flow,
  identity,
  isNil,
  isNull,
  keys,
  last,
  map,
  orderBy,
  prop,
  set,
  sortBy,
} from "lodash/fp";
import { useMemo } from "react";
import { customerAnalysisSelector } from "../selectors";
import { getAssetType, getSeries } from "./constant";
import fundImg from "@/assets/customerAnalysisIcons/fund.svg";
import stockImg from "@/assets/customerAnalysisIcons/stock.svg";
import bondImg from "@/assets/customerAnalysisIcons/bond.svg";
import cashImg from "@/assets/customerAnalysisIcons/cash.svg";
import {
  AssetTypeDistributionParams,
  CUSTOMER_BENCHMARK_TYPE,
  GROUP_TYPE,
} from "@/model/aum";

export const lineColor = ["#0052BF", "#EB5B5B", "#FFB15A", "#8153C4"];
export type chartsData = {
  color: string;
  name: string;
  code: string;
  benchmarkIndex: number;
  dates: string[];
  series: LineChartOpts["series"];
};
export const useGetLineChartData = (
  customerId: string,
  activeRange: string,
  type: string
) => {
  const customerAnalysis = useAppSelector((state: RootState) =>
    customerAnalysisSelector(state, customerId)
  );
  const assetTypeDistribution: AssetTypeDistributionBody = prop(
    `assetTypeDistribution.${CUSTOMER_BENCHMARK_TYPE.CUSTOMER_AUM}.${type}`
  )(customerAnalysis);
  const fundPositionTrend = useMemo(
    () => fastProp("bondPositionTrend")(assetTypeDistribution),
    [assetTypeDistribution]
  );

  const dates = useMemo<string[]>(() => {
    const assetWeightTrend = keys(
      fastProp("assetWeightTrend")(fundPositionTrend)
    );
    return orderBy(identity, "asc")(assetWeightTrend) as string[];
  }, [fundPositionTrend]);

  const getCumulativeChartCalculateRangeDate =
    useGetCumulativeChartCalculateRangeDate();

  const calculatedDates = useMemo(
    () => getCumulativeChartCalculateRangeDate(dates, activeRange),
    [activeRange, getCumulativeChartCalculateRangeDate, dates]
  );
  const formatMessage = useFormatMessage();
  const assetType = getAssetType(formatMessage, type);

  const portfolioAssetTypeDistribution = prop(
    `assetTypeDistribution.${CUSTOMER_BENCHMARK_TYPE.CUSTOMER_AVG}.${type}`
  )(customerAnalysis);

  const { dataMissing } = portfolioAssetTypeDistribution || {};

  const chartsData = useMemo<chartsData[]>(() => {
    let index = 0;
    return flow(
      mapIndexed((item: PositionTrendBody, key: string) => {
        if (isNil(item) || key === "dataMissing") return null;
        const {
          assetWeightTrend,
          benchmarkDailyReturn,
          benchmarkIndex,
          benchmarkCode,
          benchmarkName,
        } = item || {};

        const benchmarkReturn = map((date: string) =>
          Number(
            toFixedNumber(8)(
              formatNilToZero(
                fastProp(date)(
                  getProp(`${key}.assetWeightTrend`)(
                    portfolioAssetTypeDistribution
                  )
                )
              )
            )
          )
        )(calculatedDates);

        const cumulateBenchmarkReturn =
          key !== "currencyPositionTrend"
            ? cumulativeReturns(
                set(
                  0,
                  0
                )(
                  map((date: string) =>
                    formatNilToZero(fastProp(date)(benchmarkDailyReturn))
                  )(calculatedDates)
                ),
                true
              )
            : map(
                (date: string) =>
                  Number(
                    toFixedNumber(8)(fastProp(date)(benchmarkDailyReturn))
                  ) || null
              )(calculatedDates);

        const assetWeight = map((date: string) =>
          Number(
            toFixedNumber(8)(formatNilToZero(fastProp(date)(assetWeightTrend)))
          )
        )(calculatedDates);

        return {
          key,
          type: fastProp(key)(assetType),
          color: lineColor[index++],
          name: benchmarkName || undefined,
          code: benchmarkCode || undefined,
          benchmarkIndex: benchmarkIndex,
          dates: calculatedDates,
          cumulativeWeight: last(cumulateBenchmarkReturn),
          series: getSeries(
            cumulateBenchmarkReturn,
            assetWeight,
            benchmarkReturn,
            fastProp(key)(assetType),
            dataMissing,
            formatMessage,
            !isNull(benchmarkName)
          ),
        };
      }),
      compact
    )(assetTypeDistribution || []);
  }, [
    assetTypeDistribution,
    calculatedDates,
    assetType,
    dataMissing,
    formatMessage,
    portfolioAssetTypeDistribution,
  ]);

  return { dates: calculatedDates, chartsData };
};

export const useGetCurrentAssetTypeData = ({
  customerId,
  customerBenchmarkType,
  groupType,
}: AssetTypeDistributionParams) => {
  const customerAnalysis = useAppSelector((state: RootState) =>
    customerAnalysisSelector(state, customerId)
  );
  const assetTypeDistribution: AssetTypeDistributionBody = prop(
    `assetTypeDistribution.${customerBenchmarkType}.${groupType}`
  )(customerAnalysis);

  const {
    fundPositionTrend,
    stockPositionTrend,
    cashPositionTrend,
    bondPositionTrend,
    dataMissing,
    equityPositionTrend,
    currencyPositionTrend,
    otherPositionTrend,
  } = assetTypeDistribution || {};

  const formatMessage = useFormatMessage();

  return useMemo<{ pieChartData: PieChartData; dataMissing: boolean }>(
    () => ({
      dataMissing,
      pieChartData: flow(
        mapIndexed((v: Record<string, number | string>) =>
          Number(toFixedNumber(4)(fastProp("value")(v))) ? v : null
        ),
        compact
      )([
        {
          name:
            groupType === GROUP_TYPE.ASSET_POSITION_TYPE
              ? formatMessage("fundNavWeight")
              : formatMessage("EQUITY"),
          value: getWeight(
            groupType === GROUP_TYPE.ASSET_POSITION_TYPE
              ? fundPositionTrend
              : equityPositionTrend
          ),
          color: lineColor[0],
        },
        {
          name:
            groupType === GROUP_TYPE.ASSET_POSITION_TYPE
              ? formatMessage("stockNavWeight")
              : formatMessage("FIXED"),
          value: getWeight(
            groupType === GROUP_TYPE.ASSET_POSITION_TYPE
              ? stockPositionTrend
              : bondPositionTrend
          ),
          color: lineColor[1],
        },
        {
          name:
            groupType === GROUP_TYPE.ASSET_POSITION_TYPE
              ? formatMessage("bond")
              : formatMessage("otherClass"),
          value: getWeight(
            groupType === GROUP_TYPE.ASSET_POSITION_TYPE
              ? bondPositionTrend
              : otherPositionTrend
          ),
          color: lineColor[3],
        },
        {
          name:
            groupType === GROUP_TYPE.ASSET_POSITION_TYPE
              ? formatMessage("cash")
              : formatMessage("MONEY"),
          value: getWeight(
            groupType === GROUP_TYPE.ASSET_POSITION_TYPE
              ? cashPositionTrend
              : currencyPositionTrend
          ),
          color: lineColor[2],
        },
      ]),
    }),
    [
      dataMissing,
      formatMessage,
      fundPositionTrend,
      stockPositionTrend,
      bondPositionTrend,
      cashPositionTrend,
      equityPositionTrend,
      currencyPositionTrend,
      otherPositionTrend,
      groupType,
    ]
  );
};

const getWeight = (data: PositionTrendBody) => {
  return isNull(data)
    ? 0
    : flow(fastProp("assetWeightTrend"), (assetWeightTrendData) => {
        const lastItemKey = flow(
          keys,
          sortBy(identity),
          last
        )(assetWeightTrendData);
        return lastItemKey && assetWeightTrendData[lastItemKey];
      })(data);
};

export const useGetAssetTypeDistributionSummaryData = (customerId: string) => {
  const formatMessage = useFormatMessage();
  const customerAnalysis = useAppSelector((state: RootState) =>
    customerAnalysisSelector(state, customerId)
  );
  const assetTypeDistributionSummary: AssetTypeDistributionSummaryBody =
    fastProp("assetTypeDistributionSummary")(customerAnalysis);

  return useMemo(
    () => [
      {
        itemKey: "fund",
        title: formatMessage("fund"),
        subTitle: formatMessage("fundPerformance"),
        icon: fundImg,
        value: assetTypeDistributionSummary?.fundSummaryView,
      },
      {
        itemKey: "stock",
        title: formatMessage("stock"),
        subTitle: formatMessage("stockPerformance"),
        icon: stockImg,
        value: assetTypeDistributionSummary?.stockSummaryView,
      },
      {
        itemKey: "bond",
        title: formatMessage("bond"),
        subTitle: formatMessage("bondPerformance"),
        icon: bondImg,
        value: assetTypeDistributionSummary?.bondSummaryView,
      },
      {
        itemKey: "cash",
        title: formatMessage("cash"),
        subTitle: formatMessage("cashPerformance"),
        icon: cashImg,
        value: assetTypeDistributionSummary?.cashSummaryView,
      },
    ],
    [
      assetTypeDistributionSummary?.bondSummaryView,
      assetTypeDistributionSummary?.cashSummaryView,
      assetTypeDistributionSummary?.fundSummaryView,
      assetTypeDistributionSummary?.stockSummaryView,
      formatMessage,
    ]
  );
};
