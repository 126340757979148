import { RootState } from "@/store";
import { fastProp, normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { first } from "lodash/fp";

export const baseSelector = createSelector(
  (state: RootState) => state.compareManage.fundManager,
  (fundManager) => fundManager
);

export const fundManagerMapSelector = createSelector(
  (state: RootState) => state.entities.managerName,
  (managerName) => {
    return normalize("id")(managerName);
  }
);
export const selectedReportDateSelector = createSelector(
  baseSelector,
  (fundCompare) => {
    return first(fastProp("reportDate")(fundCompare)) as string;
  }
);
export const selectedYearReportDateSelector = createSelector(
  baseSelector,
  (fundCompare) => {
    return first(fastProp("yearReportDate")(fundCompare)) as string;
  }
);
