import React, { useMemo } from "react";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { EChartsOption, RadarComponentOption } from "echarts";
import { isArray, map, max, size, merge, omit, isEmpty } from "lodash/fp";
import EchartsWrapper, { WrapperProps } from "./echartsWrapper";

interface RadarDataSource {
  name: string;
  max?: number;
  value: number | number[];
}
const getRadarIndicator = (dataSource: RadarDataSource[]) =>
  map<
    RadarDataSource,
    {
      name: string;
      max: number;
    }
  >((item) => ({
    name: item.name,
    max: item?.max || 100,
  }))(dataSource);

const getRadarData = (dataSource: RadarDataSource[]) => {
  if (isEmpty(dataSource)) return [];
  const values = map<RadarDataSource, number[]>((item) =>
    isArray(item.value) ? item.value : [item.value]
  )(dataSource);
  const maxSizeValues = max(
    map<number[], number>((value) => size(value))(values)
  );
  return mapIndexed((_: number, index: number) => ({
    value: map<number[], number>((value) => fastNth(index)(value))(values),
  }))(new Array(maxSizeValues).fill(0));
};

export interface RadarChartOpts extends WrapperProps {
  events?: Record<string, (...args: any[]) => void>;
  options?: EChartsOption & {
    radar?: RadarComponentOption;
  };
  dataSource: RadarDataSource[];
}

export default React.memo<RadarChartOpts>(
  ({ options, events, width, height, dataSource, ...restProps }) => {
    const handleOptions = useMemo(
      () =>
        merge({
          grid: {
            left: 0,
            right: 0,
            top: 50,
            bottom: 0,
            containLabel: true,
          },
          radar: [
            {
              indicator: getRadarIndicator(dataSource),
              radius: 80,
              splitArea: {
                show: false,
              },
              ...fastProp("radar")(options),
            },
          ],
          series: [
            {
              type: "radar",
              tooltip: {
                trigger: "item",
              },
              areaStyle: {},
              data: getRadarData(dataSource),
              ...fastProp("series")(options),
            },
          ],
        })(omit(["radar", "series"])(options)),
      [dataSource, options]
    );
    return (
      <EchartsWrapper
        width={width}
        height={height}
        events={events}
        options={handleOptions}
        {...restProps}
      />
    );
  }
);
