import { factorsFormatter } from "@/constant/factorFormatter";
import { BrinsonAttributionResponse } from "@/model/aum";
import { FormatMessageFunc } from "@/util/formatMessage";
import { fastNth, fastProp, mapIndexed, zipWithMinus } from "@/util/opt";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import getCumulativeAttributionsByParams from "@/util/quant/cumulativeAttributions";
import { flow, last, map, set, size } from "lodash/fp";
import { formatNilToZero } from "@/util/numberFormatter";

export const getBrinsonTableTitle = (formatMessage: FormatMessageFunc) => {
  return [
    {
      id: "maxDrawdown",
      title: formatMessage("maxDrawdown"),
      render: factorsFormatter?.maxDrawdown,
    },
    {
      id: "sharpe",
      title: formatMessage("sharpeRatio"),
      render: factorsFormatter?.sharpeRatio,
    },
    {
      id: "infoRatio",
      title: formatMessage("infoRatio"),
      render: factorsFormatter?.informationRatio,
    },
    {
      id: "trackDeviation",
      title: formatMessage("trackDeviation"),
      render: factorsFormatter?.trackDeviation,
    },
    {
      id: "benchmarkActualAttribution",
      title: formatMessage("benchmarkAttribution"),
      render: factorsFormatter?.yield,
    },
    {
      id: "portfolioActualAttribution",
      title: formatMessage("portfolioAttribution"),
      render: factorsFormatter?.yield,
    },
    {
      id: "allocationAttribution",
      title: formatMessage("allocationAttribution"),
      render: factorsFormatter?.yield,
    },
    {
      id: "selectionAttribution",
      title: formatMessage("selectionFundAttribution"),
      render: factorsFormatter?.yield,
    },
  ];
};

export const getCalculateBasicBrinsonData = (
  result: BrinsonAttributionResponse
) => {
  // 日期
  const dates = fastProp("dates")(result) || [];

  // 基金净值业绩
  const dailyPortfolioActualReturns =
    fastProp("dailyPortfolioActualReturns")(result) || [];
  // 基金净值累计业绩
  const cumulativePortfolioActualAttributions = getCumulativeReturns(
    dailyPortfolioActualReturns
  );

  // 基金持仓业绩
  const dailyPortfolioReturns = fastProp("dailyPortfolioReturns")(result) || [];
  const cumulativePortfolioAttributions = getCumulativeReturns(
    dailyPortfolioReturns
  );

  // 基准净值业绩
  const dailyBenchmarkActualReturns =
    fastProp("dailyBenchmarkActualReturns")(result) || [];
  const cumulativeBenchmarkActualAttributions = getCumulativeReturns(
    dailyBenchmarkActualReturns
  );

  // 基准持仓业绩
  const dailyBenchmarkReturns = fastProp("dailyBenchmarkReturns")(result) || [];
  // 基准持仓累计业绩
  const cumulativeBenchmarkAttributions = getCumulativeReturns(
    dailyBenchmarkReturns
  );
  // 配置日业绩
  const dailyAllocationAttributions =
    fastProp("dailyAllocationAttributions")(result) || [];

  // 选基金日业绩
  const dailySelectionAttributions =
    fastProp("dailySelectionAttributions")(result) || [];

  // 持仓链接算法
  const getPositionCumulativeAttributions = getCumulativeAttributionsByParams(
    dailyPortfolioReturns,
    dailyBenchmarkReturns,
    cumulativePortfolioAttributions,
    cumulativeBenchmarkAttributions
  );

  // 净值链接算法
  const getActualCumulativeAttributions = getCumulativeAttributionsByParams(
    dailyPortfolioActualReturns,
    dailyBenchmarkActualReturns,
    cumulativePortfolioActualAttributions,
    cumulativeBenchmarkActualAttributions
  );

  // 配置业绩
  const cumulativeAllocationAttributions = getPositionCumulativeAttributions(
    dailyAllocationAttributions
  );
  // 选基金业绩
  const cumulativeSelectionAttributions = getPositionCumulativeAttributions(
    dailySelectionAttributions
  );
  // 交叉项日业绩
  const dailyTimingOrInteractionAttributions = zipWithMinus(
    dailyPortfolioReturns,
    dailyBenchmarkReturns,
    dailyAllocationAttributions,
    dailySelectionAttributions
  );
  // 交叉项业绩
  const cumulativeTimingOrInteractionAttributions =
    getPositionCumulativeAttributions(dailyTimingOrInteractionAttributions);
  // 超额净值日业绩
  const dailyActiveReturns = zipWithMinus(
    dailyPortfolioActualReturns,
    dailyBenchmarkActualReturns
  );

  // 超额持仓日业绩
  const dailyActivePositionReturns = zipWithMinus(
    dailyPortfolioReturns,
    dailyBenchmarkReturns
  );

  // 超额净值业绩
  const cumulativeActiveAttributions =
    getActualCumulativeAttributions(dailyActiveReturns);
  // 超额持仓业绩
  const cumulativeActivePositionAttributions =
    getPositionCumulativeAttributions(dailyActivePositionReturns);
  return {
    ...result,
    dates: dates,
    // 超额净值日业绩
    dailyActiveReturns,
    // 超额持仓日业绩
    dailyActivePositionReturns,
    // 组合净值业绩
    cumulativePortfolioActualAttribution: last(
      cumulativePortfolioActualAttributions
    ),
    cumulativePortfolioActualAttributions,

    // 基准净值业绩
    cumulativeBenchmarkActualAttribution: last(
      cumulativeBenchmarkActualAttributions
    ),
    cumulativeBenchmarkActualAttributions,

    // 配置业绩
    cumulativeAllocationAttribution: last(cumulativeAllocationAttributions),
    cumulativeAllocationAttributions,

    // 选基金业绩
    cumulativeSelectionAttribution: last(cumulativeSelectionAttributions),
    cumulativeSelectionAttributions,

    // 交叉项业绩
    cumulativeTimingOrInteractionAttribution: last(
      cumulativeTimingOrInteractionAttributions
    ),
    cumulativeTimingOrInteractionAttributions,
    // 交叉项日业绩
    dailyTimingOrInteractionAttributions,

    // 其他业绩
    otherAttribution:
      (last(cumulativeActiveAttributions) || 0) -
      (last(cumulativeActivePositionAttributions) || 0),

    // 超额业绩
    cumulativeActiveAttribution: last(cumulativeActiveAttributions),
  };
};

export const needCalcFactors = [
  "maxDrawdown",
  "sharpe",
  "infoRatio",
  "trackDeviation",
];

export const getAumPortfolioReturns = ({
  dates,
  portfolioDailyReturnsMap,
  benchmarkDailyReturnsMap,
  riskFreeRates,
}: {
  dates: string[];
  portfolioDailyReturnsMap: Record<string, number>;
  benchmarkDailyReturnsMap: Record<string, number>;
  riskFreeRates: Record<string, number>;
}) => {
  const dailyReturns = map((v: string) =>
    formatNilToZero(fastProp(v)(portfolioDailyReturnsMap))
  )(dates);
  const dailyBenchmarkReturns = flow(
    map((v: string) => formatNilToZero(fastProp(v)(benchmarkDailyReturnsMap))),
    (dailyReturns: number[]) =>
      size(dates) === size(dates) ? set(0, 0)(dailyReturns) : dailyReturns
  )(dates);
  const dailyActiveReturns = mapIndexed(
    (dailyReturn: number, index: number) =>
      dailyReturn - fastNth(index)(dailyBenchmarkReturns)
  )(dailyReturns);
  const newRiskFreeRates = map((v: string) =>
    formatNilToZero(fastProp(v)(riskFreeRates))
  )(dates);
  return {
    dailyReturns,
    dailyBenchmarkReturns,
    dailyActiveReturns,
    dates,
    riskFreeRates: newRiskFreeRates,
  };
};

export const names = [
  "portfolioAttribution",
  "benchmarkAttribution",
  "allocationAttribution",
  "selectionFundAttribution",
];

export const getChartColumns = (formatMessage: FormatMessageFunc) => [
  {
    title: <span>{formatMessage("maxDrawdown")}</span>,
    dataIndex: "maxDrawdown",
    align: "center",
    render: factorsFormatter?.maxDrawdown,
  },
  {
    title: <span>{formatMessage("sharpeRatio")}</span>,
    dataIndex: "sharpe",
    align: "center",
    render: factorsFormatter?.sharpeRatio,
  },
  {
    title: <span>{formatMessage("infoRatio")}</span>,
    dataIndex: "infoRatio",
    align: "center",
    render: factorsFormatter?.informationRatio,
  },
  {
    title: <span>{formatMessage("trackDeviation")}</span>,
    dataIndex: "trackDeviation",
    align: "center",
    render: factorsFormatter?.trackDeviation,
  },
  {
    title: <span>{formatMessage("benchmarkAttribution")}</span>,
    dataIndex: "benchmarkActualAttribution",
    align: "center",
    render: factorsFormatter?.yield,
  },
  {
    title: <span>{formatMessage("portfolioAttribution")}</span>,
    dataIndex: "portfolioActualAttribution",
    align: "center",
    render: factorsFormatter?.yield,
  },
  {
    title: <span>{formatMessage("allocationAttribution")}</span>,
    dataIndex: "allocationAttribution",
    align: "center",
    render: factorsFormatter?.yield,
  },
  {
    title: <span>{formatMessage("selectionFundAttribution")}</span>,
    dataIndex: "selectionAttribution",
    align: "center",
    render: factorsFormatter?.yield,
  },
  // {
  //   title: <span>{formatMessage("interactionAttribution")}</span>,
  //   dataIndex: "timingOrInteractionAttribution",
  //   align: "center",
  //   render: factorsFormatter?.yield,
  // },
];
