export type HoldStockStyle =
  | "MID_VALUE"
  | "MID_GROWTH"
  | "MID_BALANCE"
  | "LARGE_VALUE"
  | "LARGE_GROWTH"
  | "LARGE_BALANCE"
  | "SMALL_BALANCE"
  | "SMALL_GROWTH"
  | "SMALL_VALUE";

export const holdStockStyleMsg = {
  OTHER: "other",
  MID_VALUE: "middleValue",
  MID_GROWTH: "middleGrouth",
  MID_BALANCE: "middleBalance",
  LARGE_VALUE: "lagerValue",
  LARGE_GROWTH: "largeGrouth",
  LARGE_BALANCE: "largeBalance",
  SMALL_BALANCE: "smallBalance",
  SMALL_GROWTH: "smallGrouth",
  SMALL_VALUE: "smallValue",
};
