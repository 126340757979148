import React, { useMemo } from "react";
import { DistributionChartProps } from "./type";
import BaseChart from "../baseChart";
import { getOption, getRectTreeOption } from "./helper";

export default React.memo<DistributionChartProps>(
  ({
    type = "radar",
    datas = [],
    title,
    height = 300,
    showLegend = true,
    saveImgEnable = false,
  }) => {
    const chartOption = useMemo(() => {
      if (type === "radar") {
        return getOption({ showLegend, saveImgEnable, datas, title });
      }
      if (type === "rectTree") {
        return getRectTreeOption({ datas, title });
      }
    }, [showLegend, saveImgEnable, datas, title, type]);

    return <BaseChart option={chartOption} height={height} />;
  }
);
