import React from "react";
import { AssetInfoProp } from "./uploadPortfolio";
import style from "./entryPortfolio.module.less";
import AddAssetPortfolio from "./addAssetPortfolio/index";

export type EntryAssetInfoProp = AssetInfoProp & {
  isAddAccount?: boolean;
  setIsAddAccount?: (value: boolean) => void;
};

export default React.memo(
  ({ tableData, setTableData, disabled }: EntryAssetInfoProp) => {
    return (
      <div className={style.EntryPortfolio}>
        <AddAssetPortfolio
          setTableData={setTableData}
          disabled={disabled}
          tableData={tableData}
        />
      </div>
    );
  }
);
