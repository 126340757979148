import React, { useMemo, useEffect, useState } from "react";
import { Card, Space, Table } from "antd";
import cn from "classnames";
import { map, prop, concat, maxBy, size, forEach, first, set } from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import getAnnualReturns from "@/util/quant/annualReturns";
import getSharpeRatio from "@/util/quant/sharpeRatio";
import getInformationRatio from "@/util/quant/informationRatio";
import getAnnualStandardDeviation from "@/util/quant/annualStandardDeviation";
import getSortinoRatio from "@/util/quant/sortinoRatio";
import { dataSourceTimeSelector } from "@/selectors/dataSource";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { colors, BENCHMARK_COLOR } from "@/util/colors";
import getMaxDrawdown from "@/util/quant/maxDrawdown";
import ColorNumber from "@/components/colorNumber";
import StatisticRange from "@/components/statisticRange";
import CumulativeChart from "@/components/cumulativeChart";
import getDownwardVolatilty from "@/util/quant/downwardVolatilty";
import getBeta from "@/util/quant/beta";
import getAlpha from "@/util/quant/alpha";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fastHas, fastNth, fastProp, mapIndexed } from "@/util/opt";
import {
  getYieldIndicatorsColumns,
  getRiskIndicatorsColumns,
  yieldAndRiskChartOptions,
} from "../constant";
// import CompareBenchmarkSelect from "../../components/compareBenchmarkSelect";
import inStyle from "./index.module.less";
import style from "../../index.module.less";
import { FundsInterface } from "@/model/entities";
import { formatNilToZero } from "@/util/numberFormatter";
import { CSI300_ID } from "../../constant";
import { useCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { getStatisticsRangeStartDate } from "@/constant/statisticRangeCalculator/helper";
import { COMMON_TIME } from "@/constant/statisticRange";
import ChartRangePicker from "../../components/chartRangePicker";
import BenchmarkSelect from "@/components/benchmarkSelect";
import { benchmarksIdMapSelector } from "@/selectors/benchmarks";

export const useGetDatesAndDailyReturns = (
  benchmarkId: string,
  range: string
) => {
  const compareFundTask = useAppSelector(
    prop("compareManage.fundCompare.compareFundTask")
  );
  const benchmarksIdMap = useAppSelector(benchmarksIdMapSelector);
  const riskFreeRate = useAppSelector(prop("entities.riskFreeRate"));
  const dailyReturns = useAppSelector(prop("dailyReturns"));
  const benchmarkDailyReturn = useMemo(
    () => fastProp(benchmarkId)(dailyReturns),
    [benchmarkId, dailyReturns]
  );
  const fundsInfo = useMemo(
    () =>
      mapIndexed(
        ({ fundId, fundName, assetDailyReturnView }: any, index: number) => ({
          id: fundId,
          name: fundName,
          color: fastNth(index)(colors),
          ...assetDailyReturnView,
          dailyReturnsMap: normalizeDailyReturnsMap(
            fastProp("dates")(assetDailyReturnView),
            fastProp("dailyReturns")(assetDailyReturnView)
          ),
        })
      )(compareFundTask),
    [compareFundTask]
  );
  const benchmarkInfo = useMemo(
    () => ({
      id: benchmarkId,
      color: BENCHMARK_COLOR,
      name: prop(`${benchmarkId}.name`)(benchmarksIdMap),
      dailyReturnsMap: normalizeDailyReturnsMap(
        fastProp("dates")(benchmarkDailyReturn),
        fastProp("dailyReturns")(benchmarkDailyReturn)
      ),
      ...benchmarkDailyReturn,
    }),
    [benchmarkDailyReturn, benchmarkId, benchmarksIdMap]
  );

  const calculatedDates = useCalculateRangeDate(
    map("dates")(fundsInfo),
    fastProp("dates")(benchmarkInfo),
    range
  );
  const fundAndBenchmarkInfo = useMemo(
    () => concat(fundsInfo)(benchmarkInfo),
    [benchmarkInfo, fundsInfo]
  );

  const benchmarkDailyReturnsMap = useMemo(
    () => fastProp("dailyReturnsMap")(benchmarkInfo),
    [benchmarkInfo]
  );

  const dataSourceTime = useAppSelector(dataSourceTimeSelector);
  const rangeStartDate = getStatisticsRangeStartDate(range, dataSourceTime);
  const tableData = useMemo(
    () =>
      mapIndexed((fund: FundsInterface, index: number) => {
        const dates = fastNth(index)(calculatedDates);
        const firstDay = first<string>(
          fastProp("dates")(fastNth(index)(fundAndBenchmarkInfo))
        );
        if (firstDay && rangeStartDate && firstDay > rangeStartDate)
          return fund;
        const dailyReturnsMap = fastProp("dailyReturnsMap")(fund);
        const dailyReturns: number[] = [];
        const benchmarkReturns: number[] = [];
        forEach((date: string) => {
          if (fastHas(date)(dailyReturnsMap)) {
            dailyReturns.push(formatNilToZero(fastProp(date)(dailyReturnsMap)));
            benchmarkReturns.push(
              formatNilToZero(fastProp(date)(benchmarkDailyReturnsMap))
            );
          }
        })(dates);
        const activeReturns = mapIndexed(
          (dailyReturn: number, index: number) =>
            dailyReturn - fastNth(index)(benchmarkReturns)
        )(dailyReturns);
        const fundRiskFreeRate = map<string, number>((date) =>
          formatNilToZero(fastProp(date)(riskFreeRate))
        )(dates);
        return {
          id: fastProp("id")(fund),
          name: fastProp("name")(fund),
          fundId: fastProp("id")(fund),
          annualReturns: getAnnualReturns(dailyReturns),
          sharpeRatio: getSharpeRatio(dailyReturns, fundRiskFreeRate),
          informationRatio: getInformationRatio(activeReturns),
          maxDrawdown: getMaxDrawdown(set(0, 0)(dailyReturns)),
          annualStandardDeviation: getAnnualStandardDeviation(dailyReturns),
          sortinoRatio: getSortinoRatio(dailyReturns, fundRiskFreeRate),
          downwardVolatilty: getDownwardVolatilty(dailyReturns),
          beta: getBeta(dailyReturns, benchmarkReturns, fundRiskFreeRate),
          alpha: getAlpha(dailyReturns, benchmarkReturns, fundRiskFreeRate),
        };
      })(fundsInfo),
    [
      benchmarkDailyReturnsMap,
      calculatedDates,
      fundAndBenchmarkInfo,
      fundsInfo,
      rangeStartDate,
      riskFreeRate,
    ]
  );

  const maxDates = useMemo(
    () => maxBy<string[]>(size)(calculatedDates) || [],
    [calculatedDates]
  );

  const chartData = useMemo(
    () =>
      map(({ name, dailyReturnsMap, color }) => ({
        name,
        color,
        dailyReturns: map<string, [string, number]>((date) => [
          date,
          formatNilToZero(fastProp(date)(dailyReturnsMap)),
        ])(maxDates),
      }))(fundAndBenchmarkInfo),
    [fundAndBenchmarkInfo, maxDates]
  );

  return useMemo(
    () => ({
      dates: maxDates,
      chartData,
      tableData,
    }),
    [chartData, maxDates, tableData]
  );
};

export default React.memo<{
  className?: string;
}>(({ className }) => {
  const formatMessage = useFormatMessage();
  const [benchmarkId, setBenchmarkId] = useState(CSI300_ID);
  const [activeRange, setActiveRange] = useState(COMMON_TIME);
  const dispatch = useAppDispatch();
  const { dates, chartData, tableData } = useGetDatesAndDailyReturns(
    benchmarkId,
    activeRange
  );
  useEffect(() => {
    if (benchmarkId) {
      dispatch(fetchFundAndBenchmarkDailyReturns(benchmarkId));
    }
  }, [benchmarkId, dispatch]);

  const [yieldColumns, riskColumns] = useMemo(
    () => [
      getYieldIndicatorsColumns(formatMessage),
      getRiskIndicatorsColumns(formatMessage),
    ],
    [formatMessage]
  );
  return (
    <Card
      title={formatMessage("benefitsAndRisks")}
      className={cn(className, inStyle.YieldCard)}
      extra={
        <Space size={12}>
          <BenchmarkSelect
            value={benchmarkId}
            onChange={setBenchmarkId}
            className={inStyle.BenchmarkSelect}
          />
          <Space>
            <p>{formatMessage("periodOfTime")}</p>
            <StatisticRange
              value={activeRange}
              onChange={setActiveRange}
              statisticRangeList={yieldAndRiskChartOptions}
            />
          </Space>
          <ChartRangePicker dateList={dates} />
        </Space>
      }
    >
      <Card bordered={false} className={inStyle.InnerCard}>
        <Space direction="vertical" className={style.fullWidth}>
          <h3>{formatMessage("incomeTrend")}</h3>
          <CumulativeChart
            dates={dates}
            dataSource={chartData}
            type="cumulative"
          />
        </Space>
      </Card>
      <Card bordered={false} className={inStyle.InnerCard}>
        <Space direction="vertical" className={style.fullWidth}>
          <h3>{formatMessage("dynamicRetracement")}</h3>
          <CumulativeChart
            dates={dates}
            dataSource={chartData}
            type="maxDrawdown"
          />
          <div className={inStyle.MaxDrawdown}>
            {map(({ name, maxDrawdown }) => (
              <p key={name}>
                {name}:&nbsp;&nbsp;
                {formatMessage("rangeMaxDrawdown")}&nbsp;&nbsp;
                <ColorNumber
                  value={-maxDrawdown}
                  formatter="absolutePercentage"
                />
              </p>
            ))(tableData)}
          </div>
        </Space>
      </Card>
      <Card bordered={false} className={inStyle.InnerCard}>
        <Space direction="vertical" className={style.fullWidth}>
          <h3>{formatMessage("incomeIndex")}</h3>
          <Table
            columns={yieldColumns as any}
            dataSource={tableData}
            pagination={false}
            className={style.Table}
          />
        </Space>
        <Space
          direction="vertical"
          className={cn(style.marginTop12, style.fullWidth)}
        >
          <h3>{formatMessage("indicatorsOfRisk")}</h3>
          <Table
            columns={riskColumns as any}
            dataSource={tableData}
            pagination={false}
            className={style.Table}
          />
        </Space>
      </Card>
    </Card>
  );
});
