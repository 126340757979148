import { RootState } from "@/store";
import { fastProp, mapIndexed, normalize } from "@/util/opt";
import { first, concat, last, defaultTo } from "lodash/fp";
import { createSelector } from "@reduxjs/toolkit";
import { brinsonAttributionSelector } from "./commonSelector";
import { getInitalResultSelector, getDetailTableData } from "./worker/index";
import { getPreviousTradingDate } from "@/util/processedDates";
import { frequencyData } from "../constant";
import { portfolioAnalysisSelector } from "@/views/portfolioManage/portfolioAnalysis/selectors";

export const brinsonLineChartSelector = createSelector(
  getInitalResultSelector,
  (state: RootState) => state.tradingDates.tradingDateList,
  (state: RootState) => state.tradingDates.processedTradingDates,
  (result, tradingDates, processedTradingDates) => {
    const cumulativePortfolioActualAttributions =
      fastProp("cumulativePortfolioActualAttributions")(result) || [];
    const cumulativeBenchmarkActualAttributions =
      fastProp("cumulativeBenchmarkActualAttributions")(result) || [];
    const cumulativeSelectionAttributions =
      fastProp("cumulativeSelectionAttributions")(result) || [];
    const cumulativeAllocationAttributions =
      fastProp("cumulativeAllocationAttributions")(result) || [];
    const cumulativeTimingOrInteractionAttributions =
      fastProp("cumulativeTimingOrInteractionAttributions")(result) || [];
    const dates = fastProp("dates")(result) || [];
    const firstDate = getPreviousTradingDate(
      tradingDates,
      processedTradingDates,
      first(dates) as string
    );
    const chartDates = concat(firstDate)(dates);
    const firstChartData = {
      date: firstDate,
      portfolioAttribution: 0,
      benchmarkAttribution: 0,
      allocationAttribution: 0,
      selectionFundAttribution: 0,
    };
    const chartData = mapIndexed((date: string, index: number) => ({
      date,
      portfolioAttribution: cumulativePortfolioActualAttributions[index],
      benchmarkAttribution: cumulativeBenchmarkActualAttributions[index],
      allocationAttribution: cumulativeAllocationAttributions[index],
      selectionFundAttribution: cumulativeSelectionAttributions[index],
    }))(dates);
    const names = [
      "portfolioAttribution",
      "benchmarkAttribution",
      "allocationAttribution",
      "selectionFundAttribution",
    ];
    return {
      dates: dates,
      listData: {
        portfolioActualAttribution: last(cumulativePortfolioActualAttributions),
        benchmarkActualAttribution: last(cumulativeBenchmarkActualAttributions),

        allocationAttribution: last(cumulativeAllocationAttributions),
        // 选基金业绩
        selectionAttribution: last(cumulativeSelectionAttributions),
        timingOrInteractionAttribution: last(
          cumulativeTimingOrInteractionAttributions
        ),
      },

      chartData: {
        names: names,
        dates: chartDates,
        dataSource: normalize("date")(concat(firstChartData)(chartData)),
      },
    };
  }
);

export const brinsonTreeChartDataSelector = createSelector(
  getInitalResultSelector,
  (result) => {
    const cumulativeTimingOrInteractionAttribution = fastProp(
      "cumulativeTimingOrInteractionAttribution"
    )(result);
    const cumulativePortfolioActualAttribution = fastProp(
      "cumulativePortfolioActualAttribution"
    )(result);
    const cumulativeAllocationAttribution = fastProp(
      "cumulativeAllocationAttribution"
    )(result);

    const cumulativeBenchmarkActualAttribution = fastProp(
      "cumulativeBenchmarkActualAttribution"
    )(result);
    const cumulativeSelectionAttribution = fastProp(
      "cumulativeSelectionAttribution"
    )(result);
    const cumulativeActiveAttribution = fastProp("cumulativeActiveAttribution")(
      result
    );
    const otherAttribution = fastProp("otherAttribution")(result);

    return {
      portfolioAttribution: cumulativePortfolioActualAttribution,
      benchmarkAttribution: cumulativeBenchmarkActualAttribution,
      activeAttribution: cumulativeActiveAttribution,

      allocationAttribution: cumulativeAllocationAttribution,
      selectionAttribution: cumulativeSelectionAttribution,
      timingOrInteractionAttribution: cumulativeTimingOrInteractionAttribution,
      otherAttribution: otherAttribution,
    };
  }
);

export const brinsonAttributionPeriodSelector = createSelector(
  portfolioAnalysisSelector,
  (brinsonAttributionInfo) =>
    brinsonAttributionInfo?.brinsonAttribution?.frequency ||
    first(frequencyData)
);

export const brinsonTableDataSelector = createSelector(
  getInitalResultSelector,
  brinsonAttributionPeriodSelector,
  (result, period) => {
    const defaultPeriod: string = first(frequencyData) as string;
    const processedPeriod: string = defaultTo(defaultPeriod)(period);
    const dailyPortfolioReturns = fastProp("dailyPortfolioReturns")(result);
    const dailyBenchmarkReturns = fastProp("dailyBenchmarkReturns")(result);
    const dailyBenchmarkActualReturns = fastProp("dailyBenchmarkActualReturns")(
      result
    );
    const dailyActiveReturns = fastProp("dailyActiveReturns")(result);
    const dailyActivePositionReturns = fastProp("dailyActivePositionReturns")(
      result
    );
    const dailyTimingOrInteractionAttributions = fastProp(
      "dailyTimingOrInteractionAttributions"
    )(result);
    const dailyAllocationAttributions = fastProp("dailyAllocationAttributions")(
      result
    );
    const dailySelectionAttributions = fastProp("dailySelectionAttributions")(
      result
    );
    const dailyPortfolioActualReturns = fastProp("dailyPortfolioActualReturns")(
      result
    );
    const dates = fastProp("dates")(result);
    return getDetailTableData(
      dailyPortfolioReturns,
      dailyPortfolioActualReturns,
      dailyBenchmarkReturns,
      dailyBenchmarkActualReturns,
      dailyActiveReturns,
      dailyActivePositionReturns,
      dailyTimingOrInteractionAttributions,
      dailyAllocationAttributions,
      dailySelectionAttributions,
      processedPeriod
    )(dates);
  }
);

export default createSelector(
  brinsonAttributionSelector,
  brinsonLineChartSelector,
  brinsonTreeChartDataSelector,
  brinsonTableDataSelector,
  brinsonAttributionPeriodSelector,
  (
    brinsonAttributionReturn,
    brinsonLineChart,
    brinsonTreeChartData,
    brinsonTableData,
    frequency
  ) => ({
    brinsonAttributionReturn,
    brinsonLineChart,
    brinsonTreeChartData,
    brinsonTableData,
    frequency,
  })
);
