import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { updateModelConfiguration } from "@/store/createPortfolio";
import { arrayToMap, fastProp, floatEqual, normalize } from "@/util/opt";
import { useCreation, useMemoizedFn, useUpdateEffect } from "ahooks";
import { RadioChangeEvent } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import {
  flow,
  isEmpty,
  isNil,
  map,
  set,
  toArray,
  update,
  values,
  filter,
  sum,
  prop,
  sumBy,
} from "lodash/fp";
import { useEffect, useMemo, useState } from "react";
import { RiskFreeRateProps } from "../../fundConfiguration/fundAllocation/components/riskFreeRate";
import { FundConfigurationAsset } from "../../fundConfiguration/interface";
import { categoryTypeIds } from "../constant";
import {
  blackLittermanGoalConfig,
  categoryModelConfig,
  constrainsItem,
  modelAllocateConfig,
  modelComponentProps,
} from "../interface";
import {
  configurationModelHelper,
  getModelGoalConfigs,
  initBlackLittleManWeight,
  initCategoryWeight,
} from "./constant";

export const useUpdateModelAllocation = () => {
  const dispatch = useAppDispatch();
  const { modelAllocationData, configurationAssets } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation
  );
  const { configurationModel, categoryTypes, allocateConfig, categorySource } =
    modelAllocationData;
  const emptyCategoryTypes = useCreation(
    () => isEmpty(categoryTypes),
    [categoryTypes]
  );

  const updateModelAllocationData = useMemoizedFn(
    (updater: (formValue: categoryModelConfig) => categoryModelConfig) =>
      dispatch(updateModelConfiguration("modelAllocationData", updater))
  );
  const onUpdateModelAllocation = useMemoizedFn(
    (key: keyof categoryModelConfig) => (value: any) => {
      const oldValue = fastProp(key)(modelAllocationData);
      // 如果相同的话就不必更新了
      if (oldValue === value) return;
      updateModelAllocationData(set(key, value));
    }
  );

  const onConfigurationModel = useMemoizedFn((e: RadioChangeEvent) => {
    const model = e.target.value;
    onUpdateModelAllocation("configurationModel")(model);
    if (model === "meanVarianceModel") {
      onUpdateModelAllocation("allocateConfig")(
        initCategoryWeight(
          fastProp(model)(getModelGoalConfigs),
          categoryTypes as string[]
        )
      );
    }
    if (model === "blackLittleManModel") {
      initBlackLittleManWeight(
        fastProp(model)(getModelGoalConfigs),
        categoryTypes,
        categorySource
      ).then((res) => onUpdateModelAllocation("allocateConfig")(res));
    }
  });

  const onUpdateCategoryTypes = useMemoizedFn((values: CheckboxValueType[]) => {
    onUpdateModelAllocation("categoryTypes")(values);
    if (configurationModel === "meanVarianceModel") {
      onUpdateModelAllocation("allocateConfig")(
        initCategoryWeight(allocateConfig, values as string[])
      );
    }
    if (configurationModel === "blackLittleManModel") {
      initBlackLittleManWeight(
        allocateConfig,
        values as string[],
        categorySource
      ).then((res) => onUpdateModelAllocation("allocateConfig")(res));
    }
    if (!isEmpty(configurationAssets)) {
      const list =
        categorySource === "ASSET_CATEGORY"
          ? map((v: string) => fastProp(v)(categoryTypeIds))(values as string[])
          : values;
      const filterMap = arrayToMap(list);
      const assets = filter((data: FundConfigurationAsset) =>
        fastProp(fastProp("category")(data))(filterMap)
      )(configurationAssets);
      dispatch(updateModelConfiguration("configurationAssets", () => assets));
    }
  });

  return useMemo(
    () => ({
      modelAllocationData,
      emptyCategoryTypes,
      onUpdateModelAllocation,
      onConfigurationModel,
      onUpdateCategoryTypes,
    }),
    [
      emptyCategoryTypes,
      modelAllocationData,
      onConfigurationModel,
      onUpdateCategoryTypes,
      onUpdateModelAllocation,
    ]
  );
};

export const useMeanVarianceModel = ({
  modelAllocationData,
  updateModelAllocation,
}: Omit<modelComponentProps, "categoryModelErrorField">) => {
  const {
    allocateConfig: { goal, dataRange, goalConfig, constrains },
    categoryTypes,
    configurationModel,
    categorySource,
  } = modelAllocationData;
  const helper = fastProp(goal)(configurationModelHelper);
  const updateAllocateConfig = useMemoizedFn(
    (key: keyof modelAllocateConfig) => (value: any) => {
      const allocateConfig = modelAllocationData.allocateConfig;
      updateModelAllocation("allocateConfig")(set(key, value)(allocateConfig));
    }
  );

  useUpdateEffect(() => {
    if (configurationModel !== "blackLittleManModel") {
      updateAllocateConfig("goalConfig")(helper(null));
    }
  }, [goal, configurationModel]);

  const onChangeGoalConfig = useMemoizedFn(
    (v: number | RiskFreeRateProps["value"] | null) => {
      updateAllocateConfig("goalConfig")(helper(v));
    }
  );

  const constrainsMap = normalize("id")(constrains as constrainsItem[]);
  const updateConstrains = useMemoizedFn(
    (key: "maxWeight" | "minWeight", id: string) => (v: number | null) => {
      const newData = update([id, key], () => v)(constrainsMap);
      updateAllocateConfig("constrains")(toArray(newData));
    }
  );

  return useMemo(
    () => ({
      goal,
      dataRange,
      goalConfig,
      constrains,
      categoryTypes,
      categorySource,
      onChangeGoalConfig,
      updateAllocateConfig,
      updateConstrains,
    }),
    [
      categoryTypes,
      constrains,
      dataRange,
      goal,
      goalConfig,
      categorySource,
      onChangeGoalConfig,
      updateAllocateConfig,
      updateConstrains,
    ]
  );
};

export const useBlackLittleManModel = ({
  modelAllocationData,
  updateModelAllocation,
}: Omit<modelComponentProps, "categoryModelErrorField">) => {
  const {
    categoryTypes,
    categorySource,
    allocateConfig: { dataRange, goalConfig },
  } = modelAllocationData;

  const updateAllocateConfig = useMemoizedFn(
    (key: keyof modelAllocateConfig) => (value: any) => {
      const allocateConfig = modelAllocationData.allocateConfig;
      updateModelAllocation("allocateConfig")(set(key, value)(allocateConfig));
    }
  );

  const updateGoalConfig = useMemoizedFn(
    (key: keyof blackLittermanGoalConfig | any[]) => (value: any) => {
      updateAllocateConfig("goalConfig")(set(key, value)(goalConfig));
    }
  );

  const updateYieldView = useMemoizedFn((key: string) => (v: number | null) => {
    const yieldViewMap = flow(
      fastProp("yieldViews"),
      normalize("categoryId")
    )(goalConfig);
    const newYieldView = values(set([key, "value"], v)(yieldViewMap));
    updateGoalConfig("yieldViews")(newYieldView);
  });

  const onResetWeightView = useMemoizedFn(() => {
    const allocateConfig = modelAllocationData.allocateConfig;
    initBlackLittleManWeight(
      allocateConfig,
      categoryTypes,
      categorySource
    ).then((res) => updateModelAllocation("allocateConfig")(res));
  });

  return useMemo(
    () => ({
      dataRange,
      goalConfig,
      categoryTypes,
      categorySource,
      updateAllocateConfig,
      updateGoalConfig,
      onResetWeightView,
      updateYieldView,
    }),
    [
      dataRange,
      goalConfig,
      categoryTypes,
      categorySource,
      updateAllocateConfig,
      updateGoalConfig,
      onResetWeightView,
      updateYieldView,
    ]
  );
};

export type categoryModelErrorField = {
  categoryTypesEmpty: boolean;
  riskValueError: boolean;
  weightViewError: boolean;
  constrainsMaxWeightError: boolean;
};

const defaultCategoryModelError = () => ({
  categoryTypesEmpty: true,
  riskValueError: false,
  weightViewError: false,
  constrainsMaxWeightError: false,
});

export const useCategoryModelError = (): categoryModelErrorField => {
  const {
    categoryTypes,
    allocateConfig: { goalConfig, constrains },
  } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation.modelAllocationData
  );
  const [errorField, setErrorField] = useState(defaultCategoryModelError);
  useEffect(() => {
    setErrorField(set("categoryTypesEmpty", isEmpty(categoryTypes)));
  }, [categoryTypes]);

  useEffect(() => {
    if (goalConfig.goalType === "maxReturn") {
      setErrorField(
        set("riskValueError", isNil(fastProp("annualizedMaxRisk")(goalConfig)))
      );
    } else {
      setErrorField(set("riskValueError", false));
    }
  }, [goalConfig]);

  useEffect(() => {
    if (goalConfig.goalType === "blackLitterman") {
      const weightView = flow(
        prop(["weightView", "priorWeights"]),
        values,
        sum
      )(goalConfig);
      setErrorField(set("weightViewError", !floatEqual(1)(weightView)));
    } else {
      setErrorField(set("weightViewError", false));
    }
  }, [goalConfig]);

  useEffect(() => {
    if (goalConfig.goalType === "blackLitterman") {
      setErrorField(set("constrainsMaxWeightError", false));
    } else {
      const constrainsMaxWeight = sumBy("maxWeight")(constrains);
      setErrorField(set("constrainsMaxWeightError", constrainsMaxWeight < 1));
    }
  }, [constrains, goalConfig]);

  return errorField;
};
