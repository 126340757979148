import { useAppSelector } from "@/hooks/redux";
import { ScenarioListResponse } from "@/model/portfolioAnalysis";
import { useFormatMessage } from "@/util/formatMessage";
import { arrayToMap, fastHas, fastProp } from "@/util/opt";
import { getTableScroll } from "@/util/tableFormat";
import { useMemoizedFn } from "ahooks";
import { Checkbox, Popconfirm, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  compact,
  concat,
  flow,
  map,
  pull,
  reject,
  size,
  take,
  uniq,
} from "lodash/fp";
import { useMemo, useState } from "react";
import { getIntersectionTradingDates } from "./constant";
import style from "./index.module.less";

export const useGetScenarioTableData = (startDate: string, endDate: string) => {
  const processedTradingDates = useAppSelector(
    (state) => state.tradingDates.processedTradingDates
  );
  const getTradingDates = useMemo(
    () =>
      getIntersectionTradingDates(startDate, endDate, processedTradingDates),
    [endDate, processedTradingDates, startDate]
  );
  const scenarioData = useAppSelector(
    (state) => state.scenarioList.scenarioList
  );
  const dataSource = useMemo(
    () =>
      flow(
        map((item: ScenarioListResponse) => {
          const { startDate, endDate } = item || {};
          if (!startDate || !endDate) return undefined;
          const intersectionTradingDates = getTradingDates(startDate, endDate);
          if (intersectionTradingDates < 2) {
            return {
              ...item,
              disabled: true,
            };
          }
          return item;
        }),
        compact
      )(scenarioData),
    [getTradingDates, scenarioData]
  );
  const scroll = useMemo(
    () =>
      getTableScroll({
        dataSource,
        maxYScroll: 350,
      }),
    [dataSource]
  );
  return { dataSource, scroll };
};

export const useManageScenarioColumns = (
  maxSelectionSize: number,
  scenarioDataSource: any[],
  onRemoveScenario: (id: string) => Promise<any>
) => {
  const formatMessage = useFormatMessage();
  const [selectedScenario, setSelectedScenario] = useState<string[]>([]);
  const selectedScenarioSize = size(selectedScenario);
  const selectedScenarioMap = useMemo(
    () => arrayToMap(selectedScenario),
    [selectedScenario]
  );
  const onSelect = useMemoizedFn((id: string) => {
    const data = fastHas(id)(selectedScenarioMap)
      ? pull(id)(selectedScenario)
      : concat(selectedScenario)(id);
    setSelectedScenario(data);
  });
  const onRemove = useMemoizedFn((id: string) =>
    onRemoveScenario(id).then(() => setSelectedScenario(pull(id)))
  );
  const validScenariosData = useMemo<string[]>(
    () =>
      flow(
        reject(fastProp("disabled")),
        take(maxSelectionSize),
        map("id")
      )(scenarioDataSource),
    [maxSelectionSize, scenarioDataSource]
  );

  const onSelectAll = useMemoizedFn(() => {
    if (
      size(validScenariosData) === size(selectedScenario) ||
      size(selectedScenario) === maxSelectionSize
    ) {
      setSelectedScenario([]);
      return;
    }
    if (size(selectedScenario) === 0) {
      setSelectedScenario(
        take(maxSelectionSize)(
          uniq(concat(selectedScenario)(validScenariosData))
        )
      );
      return;
    }
    setSelectedScenario(
      take(maxSelectionSize)(uniq(concat(selectedScenario)(validScenariosData)))
    );
  });

  const disabledValidate = useMemoizedFn((record: ScenarioListResponse) => {
    if (fastProp("disabled")(record)) return true;
    return (
      selectedScenarioSize >= maxSelectionSize &&
      !fastHas(fastProp("id")(record))(selectedScenarioMap)
    );
  });

  const columns = useMemo<ColumnsType<any>>(
    () => [
      {
        dataIndex: "id",
        width: 60,
        title: (
          <Checkbox
            checked={selectedScenarioSize === maxSelectionSize}
            indeterminate={
              selectedScenarioSize && selectedScenarioSize < maxSelectionSize
                ? true
                : undefined
            }
            onChange={onSelectAll}
          />
        ),
        align: "center",
        render: (id: string, record: any) => (
          <Checkbox
            disabled={disabledValidate(record)}
            checked={fastHas(id)(selectedScenarioMap)}
            onChange={() => onSelect(id)}
          />
        ),
      },
      {
        dataIndex: "name",
        title: formatMessage("scenarioName"),
        ellipsis: true,
        render: (name: string) => (
          <Tooltip title={name}>
            <p>{name}</p>
          </Tooltip>
        ),
      },
      {
        dataIndex: "startDate",
        title: formatMessage("startDate"),
      },
      {
        dataIndex: "endDate",
        title: formatMessage("endDate"),
      },
      {
        dataIndex: "id",
        title: formatMessage("operator"),
        width: 80,
        align: "center",
        render: (id: string, record: ScenarioListResponse) =>
          !fastProp("defaults")(record) && (
            <Popconfirm
              title={formatMessage("delecteScenarioConfirm")}
              onConfirm={() => onRemove(id)}
              okText={formatMessage("ok")}
              cancelText={formatMessage("cancel")}
            >
              <p className={style.DeleteButton}>{formatMessage("delete")}</p>
            </Popconfirm>
          ),
      },
    ],
    [
      selectedScenarioSize,
      maxSelectionSize,
      onSelectAll,
      disabledValidate,
      selectedScenarioMap,
      onSelect,
      formatMessage,
      onRemove,
    ]
  );

  const rowClassName = useMemoizedFn((record: any) =>
    disabledValidate(record) ? style.DisabledColumn : ""
  );
  return {
    columns,
    rowClassName,
    selectedScenario,
    setSelectedScenario,
    validScenariosData,
  };
};
