import { useFormatMessage } from "@/util/formatMessage";
import { useCreation, useMemoizedFn } from "ahooks";
import { prop, last, map, flow } from "lodash/fp";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { formatPercentage } from "@/util/numberFormatter";
import { useMemo, useState } from "react";
import {
  confidenceTypeOption,
  earningStatisticsButtonGroup,
  compareVar,
  getValueAtRiskChartOptions,
  getValueAtRiskChartSeries,
  COEFFICIENT95,
  day30,
  futureOption,
  PARAMETER,
  valueAtRiskRangeItemType,
} from "./constant";
import { LineChartOpts } from "@/echarts/lineChart";
import { getLastDateOfYear } from "@/util/chart";
import { LineSeriesOption } from "echarts";
import { RadioChangeEvent } from "antd";
import { valueAtRiskResponse } from "@/model/portfolioAnalysis";

export const useParamsRange = () => {
  const formatMessage = useFormatMessage();
  const [computationType, setComputationType] = useState(PARAMETER);
  const onChangeComputationType = useMemoizedFn((e: RadioChangeEvent) => {
    setComputationType(e?.target.value);
  });
  const earningStatisticsButtonOptions = useCreation(
    () =>
      flow(
        map((v: valueAtRiskRangeItemType) => ({
          label: formatMessage(v.message as string),
          value: v.id,
        }))
      )(earningStatisticsButtonGroup),
    [formatMessage]
  );

  const [future, setFuture] = useState(day30);
  const futureOptions = useMemo(
    () =>
      map<Record<number, valueAtRiskRangeItemType>, any>(
        (v: valueAtRiskRangeItemType) => ({
          label: v.message,
          value: v.id,
        })
      )(futureOption),
    []
  );

  const [confidenceType, setConfidenceType] = useState(COEFFICIENT95);
  const confidenceTypeOptions = useMemo(
    () =>
      map<Record<string, valueAtRiskRangeItemType>, any>(
        (v: valueAtRiskRangeItemType) => ({
          label: formatPercentage(v.message, { digit: 0 }),
          value: v.id,
        })
      )(confidenceTypeOption),
    []
  );

  return useMemo(
    () => ({
      computationType,
      onChangeComputationType,
      earningStatisticsButtonOptions,
      future,
      setFuture,
      futureOptions,
      confidenceType,
      setConfidenceType,
      confidenceTypeOptions,
    }),
    [
      computationType,
      confidenceType,
      confidenceTypeOptions,
      earningStatisticsButtonOptions,
      future,
      futureOptions,
      onChangeComputationType,
    ]
  );
};

export const useChartDataSource = (
  computationType: string,
  confidenceType: string,
  future: number,
  portfolioDataName: string,
  valueAtRisk: Record<string, valueAtRiskResponse>,
  portfolioType: string
) => {
  const formatMessage = useFormatMessage();
  const dates = useCreation(
    () => fastProp("dates")(valueAtRisk),
    [valueAtRisk]
  );
  const portfolioVar = fastProp("portfolioVar")(valueAtRisk);
  const benchmarkVar = fastProp("benchmarkVar")(valueAtRisk);
  const benchmarkVarData = useCreation(() => {
    return mapIndexed((v: string, index: number) => [
      v,
      fastNth(index)(benchmarkVar),
    ])(dates);
  }, [dates, benchmarkVar]);

  const portfolioVarData = useCreation(() => {
    return mapIndexed((v: string, index: number) => [
      v,
      fastNth(index)(portfolioVar),
    ])(dates);
  }, [dates, portfolioVar]);

  const series = useMemo<LineSeriesOption[]>(
    () =>
      getValueAtRiskChartSeries(
        portfolioVarData,
        benchmarkVarData,
        portfolioDataName,
        formatMessage
      ),
    [benchmarkVarData, formatMessage, portfolioDataName, portfolioVarData]
  );

  const yearOfDates = getLastDateOfYear(dates);
  const options = useMemo<LineChartOpts["options"]>(
    () =>
      getValueAtRiskChartOptions(
        dates,
        yearOfDates,
        portfolioType,
        formatMessage
      ),
    [dates, formatMessage, portfolioType, yearOfDates]
  );
  const summaryText = useMemo<Record<string, any>>(
    () => ({
      date: last(dates),
      computationType: formatMessage(
        prop(`${computationType}.message`)(earningStatisticsButtonGroup)
      ),
      analysisType:
        portfolioType === "aum"
          ? formatMessage("measure")
          : formatMessage("valueAtRisk"),
      future,
      confidenceType: formatPercentage(
        1 - prop(`${confidenceType}.message`)(confidenceTypeOption),
        { digit: 0 }
      ),
      portfolioVar: formatPercentage(last(portfolioVar)),
      benchmarkVar: formatPercentage(last(benchmarkVar)),
      compare: compareVar(
        last(portfolioVar),
        last(benchmarkVar),
        formatMessage
      ),
    }),
    [
      benchmarkVar,
      computationType,
      confidenceType,
      dates,
      formatMessage,
      future,
      portfolioType,
      portfolioVar,
    ]
  );

  return useMemo(
    () => ({
      options,
      series,
      dates,
      summaryText,
    }),
    [options, series, dates, summaryText]
  );
};
