import { useFormatMessage } from "@/util/formatMessage";
import { useCreation, useMemoizedFn, useUpdateEffect } from "ahooks";
import { Button, Dropdown, Menu, message } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { InitSimpleDataType } from "@/components/portfolioCompoents/hook";
import { prop } from "lodash/fp";
import {
  REQUEST_FAILED,
  REQUEST_RESOLVED,
  TASK_PROCESS,
} from "@/constant/socket";
import { useGetCurrentScheme } from "../../portfolioList/backTestingScheme/hooks";
import { useAppSelector } from "@/hooks/redux";
import { schemeConfig } from "./constant";
import ManageSchemeModal, { ManageSchemeModalRef } from "./manageSchemeModal";

export type SaveSchemeFormType = Pick<
  InitSimpleDataType,
  "name" | "shareRange" | "description" | "id"
>;

export type SaveSchemeProps = {
  disabled?: boolean;
  initFormValues?: SaveSchemeFormType;
  onOk: (value: SaveSchemeFormType) => Promise<any>;
  id?: string;
};

const useGetInitFormValues = (
  id?: string,
  initFormValues?: SaveSchemeFormType
) => {
  const currentScheme = useGetCurrentScheme(id as string);
  return useCreation(
    () =>
      id
        ? {
            name: currentScheme?.name || "",
            shareRange: currentScheme?.shareRange || {},
            description: currentScheme?.description || "",
          }
        : initFormValues,
    [id, initFormValues]
  );
};

const useManageCreationTask = () => {
  const formatMessage = useFormatMessage();
  const task = useAppSelector(
    (state) => state.portfolioList.saveBackTestingSchemeProgress
  );
  useUpdateEffect(() => {
    if (task?.progress === REQUEST_RESOLVED) {
      message.success(formatMessage("saveSchemeSuccess"));
    }
    if (task?.progress === REQUEST_FAILED) {
      message.error({
        content: prop("fieldError.message")(task),
        duration: 5,
      });
    }
  }, [formatMessage, task]);
  return task;
};

export default React.memo<SaveSchemeProps>(
  ({ disabled, onOk, id, initFormValues }) => {
    const task = useManageCreationTask();
    const [schemeType, setSchemeType] = useState(schemeConfig.CREAT_NEW);
    const handleInitFormValue = useGetInitFormValues(id, initFormValues);
    const manageSchemeRef = useRef<ManageSchemeModalRef>(null);

    const formatMessage = useFormatMessage();
    const coverModal = useMemoizedFn((type: string) => {
      if (type === schemeConfig.COVERING) {
        manageSchemeRef.current?.openModal(
          handleInitFormValue as SaveSchemeFormType
        );
      } else {
        manageSchemeRef.current?.openModal();
      }

      setSchemeType(type);
    });

    const handleOnOk = useMemoizedFn((basicData: SaveSchemeFormType) => {
      return onOk({
        ...basicData,
        id: schemeType && schemeConfig.isCover(schemeType) ? id : undefined,
      });
    });

    const menu = useMemo(
      () => (
        <Menu>
          <Menu.Item onClick={() => coverModal(schemeConfig.COVERING)}>
            {formatMessage("coverBackTestScheme")}
          </Menu.Item>
          <Menu.Item onClick={() => coverModal(schemeConfig.CREAT_NEW)}>
            {formatMessage("saveAsBackTestScheme")}
          </Menu.Item>
        </Menu>
      ),
      [coverModal, formatMessage]
    );
    return (
      <>
        {id ? (
          <Dropdown overlay={menu} disabled={disabled}>
            <Button
              type="primary"
              key="saveAndCoverButtom"
              loading={
                task?.status === TASK_PROCESS &&
                task?.progress !== REQUEST_FAILED
              }
            >
              {formatMessage("saveBackTestScheme")}
            </Button>
          </Dropdown>
        ) : (
          <Button
            key="saveButtom"
            disabled={disabled}
            onClick={() => manageSchemeRef.current?.openModal()}
            type="primary"
            loading={
              task?.status === TASK_PROCESS && task?.progress !== REQUEST_FAILED
            }
          >
            {formatMessage("saveBackTestScheme")}
          </Button>
        )}
        <ManageSchemeModal
          title={
            schemeConfig.isCover(schemeType)
              ? formatMessage("coverBackTestScheme")
              : formatMessage("saveBackTestScheme")
          }
          onOk={handleOnOk}
          ref={manageSchemeRef}
          id={id}
          schemeType={schemeType}
        />
      </>
    );
  }
);
