import React, { memo, useMemo } from "react";
import { Select } from "antd";
import { map, isObject } from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import {
  DatePeriodName,
  DatePeriodMessage,
} from "@/util/business-core/datePeriod";
// import { TradingDateRangePicker } from "@/components/datePicker";
// import { usePeriodDates } from "./helper/hook";
import { defaultOptions } from "./helper/constant";
import { DatePeriodProps, Option } from "./type";

export const useOptions = (options: DatePeriodProps["options"]) => {
  const formatMessage = useFormatMessage();

  return useMemo(
    () =>
      map((option: Option) => {
        if (isObject(option)) {
          return {
            label: formatMessage(option?.label),
            value: option?.value,
          };
        }
        return {
          label: formatMessage(DatePeriodMessage[option] || option),
          value: option,
        };
      })(options || defaultOptions),
    [formatMessage, options]
  );
};

const DatePeriodSelect = memo<DatePeriodProps>(
  ({ value, options, onChange, className }) => {
    const _options = useOptions(options);

    return (
      <Select
        className={className}
        style={{ width: 200 }}
        value={value}
        options={_options}
        onChange={(value) => onChange?.(value as DatePeriodName)}
      />
    );
  }
);

export default DatePeriodSelect;
