import { Key, useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "@/hooks/redux";
import { FundsInterface } from "@/model/entities";
import { searchData } from "@/util/search";
import {
  compact,
  concat,
  flow,
  includes,
  isEmpty,
  map,
  prop,
  pull,
  size,
  uniq,
  filter,
  first,
  fromPairs,
} from "lodash/fp";
import { arrayToMap, fastHas, fastProp } from "@/util/opt";
import {
  fundCodeSuffixMapSelector,
  fundIdMapSelector,
  orderedFundSelector,
  orderedNotDelistingFundsSelector,
} from "@/selectors/fund";
import { useCreation, useMemoizedFn } from "ahooks";
import { ShareRange } from "./shareRangeSelect";
import { getCategoryTypeId, getMaxSelectionData } from "./constant";
import { PerformanceBenchmarkSelectValue } from "./performanceBenchmarkSelect";
import { categoryTreeMapSelector } from "@/selectors/category";
import { useFundOptionalService } from "@/views/myOptional/fundOptional/hooks";
import { useGetPreferredFundsByDate } from "@/views/preferredFund/hook";

export type InitSimpleDataType = {
  id?: string;
  name: string;
  initScale: number;
  shareRange: ShareRange;
  strategy: string;
  description: string;
  date?: string;
  benchmark: PerformanceBenchmarkSelectValue;
};
export type InitSimpleDataRequestType = Omit<InitSimpleDataType, "benchmark"> &
  PerformanceBenchmarkSelectValue;

export const usePortfolioBasicInfo = (
  initSimpleData: InitSimpleDataType
): [
  InitSimpleDataType,
  (key: string) => (value: string | number | { [key: string]: string }) => void
] => {
  const [simpleData, changeSimpleData] = useState(initSimpleData);
  const updateSimpleData = useCallback(
    (key: string) => (value: string | number | { [key: string]: string }) => {
      changeSimpleData({
        ...simpleData,
        [key]: value,
      });
    },
    [simpleData]
  );
  return [simpleData, updateSimpleData];
};

export const useGetFundData = (
  enableDelistingFund: boolean,
  selectedType: Key[]
) => {
  const [fundData, setFundData] = useState<FundsInterface[]>([]);

  const allFund = useAppSelector(
    enableDelistingFund ? orderedNotDelistingFundsSelector : orderedFundSelector
  );
  const { recentPreferredFund } = useGetPreferredFundsByDate();
  const { groups } = useFundOptionalService();
  const allTypeFund = useCreation(
    () => ({
      AllMarket: allFund || [],
      preferredPool: recentPreferredFund || [],
      ...flow(
        map((item: Record<string, any>) => [item._groupName, item.fundViews]),
        fromPairs
      )(groups || []),
    }),
    [allFund, recentPreferredFund, groups]
  );

  const SelectedTypeData = useMemo(() => {
    return fastProp(first(selectedType) as string)(allTypeFund);
  }, [allTypeFund, selectedType]);

  const allFundMap = useAppSelector(fundIdMapSelector);

  useEffect(() => {
    setFundData(SelectedTypeData);
  }, [SelectedTypeData]);
  const onSearchFund = useMemoizedFn((v: string) => {
    if (v) {
      const search = searchData<FundsInterface>(allFund, v, ["name", "code"]);
      setFundData(search);
    } else {
      setFundData(SelectedTypeData);
    }
  });
  const allFundSize = useCreation(() => size(allFund), [allFund]);
  return {
    fundData,
    allFundMap,
    allFundSize,
    onSearchFund,
  };
};

export type OnSelectFund = (
  type: "select" | "all" | "delete" | "clear" | "reset"
) => (id: string) => void;
export function useSelectFundDialogData({
  fundIds,
  maxSelectionSize,
  disabledFundIds,
  alreadyCheckedIds,
  enableDelistingFund,
  showCategoryType,
  categoryIds,
  selectedType,
}: {
  fundIds: string[];
  maxSelectionSize: number;
  disabledFundIds?: string[];
  alreadyCheckedIds?: string[];
  enableDelistingFund: boolean;
  showCategoryType: boolean;
  categoryIds: string[];
  selectedType: Key[];
}) {
  const { fundData, allFundSize, allFundMap, onSearchFund } = useGetFundData(
    enableDelistingFund,
    selectedType
  );
  const [selectFundIds, setSelectFundIds] = useState<string[]>(fundIds);
  useEffect(() => {
    setSelectFundIds(fundIds);
  }, [fundIds]);
  const selectFundIdsMap = useCreation(
    () => arrayToMap(selectFundIds),
    [selectFundIds]
  );
  // 禁用的基金id
  const disabledFundIdsMap = useCreation(
    () => arrayToMap(disabledFundIds || []),
    [disabledFundIds]
  );
  const selectionAndAlreadyCheckedIdsMap = useCreation(
    () => ({
      ...selectFundIdsMap,
      ...arrayToMap(alreadyCheckedIds || []),
    }),
    [selectFundIdsMap, alreadyCheckedIds]
  );
  const categoryTreeMap = useAppSelector(categoryTreeMapSelector);
  const disabledCategory = useMemoizedFn((id: string) => {
    if (!showCategoryType || isEmpty(categoryIds)) return false;
    const categoryId = prop([id, "categoryId"])(allFundMap);
    return !includes(
      getCategoryTypeId({
        categoryTreeMap,
        categoryId,
      })
    )(categoryIds);
  });
  const onSelectFund = useMemoizedFn<OnSelectFund>(
    (type: "select" | "all" | "delete" | "clear" | "reset") => (id: string) => {
      switch (type) {
        case "select":
          return setSelectFundIds((ids: string[]) => {
            return fastHas(id)(selectFundIdsMap)
              ? flow(pull(id), uniq)(ids)
              : flow(concat(ids), uniq)(id);
          });
        case "all":
          if (size(selectFundIds) >= maxSelectionSize) {
            return setSelectFundIds([]);
          } else {
            return setSelectFundIds(
              flow(
                getMaxSelectionData((id: string) => {
                  return (
                    !fastHas(id)(selectionAndAlreadyCheckedIdsMap) &&
                    !fastHas(id)(disabledFundIdsMap) &&
                    !disabledCategory(id)
                  );
                }, maxSelectionSize - size(selectFundIds)),
                map(fastProp("fundId")),
                concat(selectFundIds)
              )(fundData)
            );
          }
        case "delete":
          return setSelectFundIds((ids: string[]) => pull(id)(ids));
        case "clear":
          return setSelectFundIds([]);
        case "reset":
          return setSelectFundIds(fundIds);
      }
    }
  );
  const checkBoxDisabled = useMemoizedFn((id: string) => {
    // 当前选择的长度大于等于最大长度则都不可以选择
    if (size(selectFundIds) >= maxSelectionSize) return true;
    // 如果在不可选择的基金idList中不可选择
    if (fastHas(id)(disabledFundIdsMap)) return true;
    return fastHas(id)(selectionAndAlreadyCheckedIdsMap);
  });
  const normalizedFunds = useMemo(
    () =>
      map<FundsInterface, FundsInterface>(({ fundId, ...res }) => ({
        fundId,
        checked: fastHas(fundId)(selectionAndAlreadyCheckedIdsMap),
        disabled: checkBoxDisabled(fundId) || disabledCategory(fundId),
        ...res,
      }))(fundData),
    [
      checkBoxDisabled,
      disabledCategory,
      fundData,
      selectionAndAlreadyCheckedIdsMap,
    ]
  );
  const selectedFunds = useCreation(
    () =>
      map<string, FundsInterface>((id) => fastProp(id)(allFundMap))(
        selectFundIds
      ),
    [selectFundIds, allFundMap]
  );
  return {
    fundData: normalizedFunds,
    allFundSize,
    onSelectFund,
    onSearchFund,
    selectedFunds,
    selectFundIds,
  };
}

export const useBatchAddBox = ({
  selectFundIds,
  onSelectFund,
}: {
  selectFundIds: string[];
  onSelectFund: OnSelectFund;
}) => {
  const fundCodeSuffixMap = useAppSelector(fundCodeSuffixMapSelector);
  const [batchState, setBatchState] = useState<boolean>(false);
  const [textArea, setTextArea] = useState<string>("");
  const [errorCodes, setErrorCodes] = useState<string[]>([]);
  const [validCodes, setValidCodes] = useState<string[]>([]);

  const onChangeTextArea = useMemoizedFn((e: { target: { value: string } }) => {
    setTextArea(e.target.value);
  });

  const onReset = useMemoizedFn(() => {
    setTextArea("");
    setErrorCodes([]);
    setValidCodes([]);
    setBatchState(false);
  });

  const onBatchAdd = useMemoizedFn(() => {
    if (!textArea) {
      return;
    }
    const codes = textArea.split("\n").filter((item) => item !== "");
    const notExistCodes = filter((code: string) => !fundCodeSuffixMap?.[code])(
      codes
    );
    const _validCodes = filter((code: string) => fundCodeSuffixMap?.[code])(
      codes
    );
    if (size(notExistCodes)) {
      setValidCodes(_validCodes);
      return setErrorCodes(notExistCodes);
    }
    const showCodes = flow(
      map((v: string) => prop([v, "fundId"])(fundCodeSuffixMap)),
      (fundIds: string[]) => uniq([...fundIds, ...selectFundIds]),
      compact
    )(_validCodes);
    onSelectFund("select")(showCodes);
    onReset();
  });

  const onClearInvalidCode = useMemoizedFn(() => {
    setTextArea(validCodes.join("\n"));
    setErrorCodes([]);
  });

  return {
    textArea,
    onChangeTextArea,
    setTextArea,
    batchState,
    setBatchState,
    onBatchAdd,
    errorCodes,
    onClearInvalidCode,
    onReset,
  };
};
