import { useFormatMessage } from "@/util/formatMessage";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Space,
  Tag,
  Tooltip,
  Popconfirm,
} from "antd";
import React, { ReactNode, useEffect, useMemo } from "react";
import {
  SettingOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import style from "./index.module.less";
import { CategoryResponse, CustomBenchmarkInterface } from "@/model/entities";
import { flow, map, prop, size } from "lodash/fp";
import { fastProp, normalize } from "@/util/opt";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchCustomBenchmarkList } from "@/store/customBenchmark";
import { useGetBenchmarkDataAndFunction } from "./hook";
import EmptyContent from "../emptyContent";
import CategoryType from "./categoryType";
import { BenchmarkNameUniqValidator, portfolioNameReg } from "@/util/formRules";
import { REQUEST_FAILED, TASK_PROCESS } from "@/constant/socket";
import {
  assetBigCategorySelector,
  categoryIdMapSelector,
} from "@/selectors/category";
import { formatPercentage } from "@/util/numberFormatter";

type customBenchmarkProps = {
  children?: ReactNode;
};

const formLabelCol = {
  span: 4,
};

export default React.memo<customBenchmarkProps>(({ children }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();

  const categoryOption = useAppSelector(assetBigCategorySelector);
  const categoryOptionMap = useAppSelector(categoryIdMapSelector);
  const {
    customBenchmarkList,
    visible,
    type,
    formData,
    selectedId,
    disabled,
    task,
    form,
    initialValues,
    formRef,
    onChangeVisible,
    onChangeCategoryId,
    onChangeValue,
    onclose,
    createBenchmark,
    onChangeBenchmark,
    editCustomBenchmark,
    delCustomBenchmark,
    onOk,
  } = useGetBenchmarkDataAndFunction();

  const conditionMap = useMemo(() => {
    return flow(fastProp("conditions"), normalize("categoryId"))(formData);
  }, [formData]);

  useEffect(() => {
    dispatch(fetchCustomBenchmarkList());
  }, [dispatch]);

  return (
    <>
      {children ? (
        <span onClick={onChangeVisible}>{children}</span>
      ) : (
        <Button
          icon={<SettingOutlined />}
          type="primary"
          onClick={onChangeVisible}
          ghost
        >
          {formatMessage("manageBenchmark")}
        </Button>
      )}
      <Modal
        visible={visible}
        title={formatMessage("manageBenchmark")}
        width={910}
        onCancel={onclose}
        destroyOnClose
        getContainer={false}
        footer={
          <>
            <Button onClick={onclose}>{formatMessage("cancel")}</Button>
            <Button
              onClick={onOk}
              type="primary"
              loading={
                task?.status === TASK_PROCESS &&
                task?.progress !== REQUEST_FAILED
              }
              disabled={disabled}
            >
              {formatMessage("preservation")}
            </Button>
          </>
        }
      >
        <div className={style.BenchmarkModal}>
          <div className={style.BenchmarkModalLeft}>
            <div className={style.AddButton}>
              <Button
                icon={<PlusOutlined />}
                block
                ghost
                type="primary"
                onClick={() => createBenchmark()}
              >
                {formatMessage("addBenchmark")}
              </Button>
            </div>
            {size(customBenchmarkList) ? (
              <div className={style.BenchmarkList}>
                {map((customBenchmarkItem: CustomBenchmarkInterface) => {
                  return (
                    <div
                      className={
                        selectedId === customBenchmarkItem?.id
                          ? style.SelectedBenchmarkItem
                          : style.BenchmarkItem
                      }
                      key={customBenchmarkItem?.id}
                      onClick={() =>
                        onChangeBenchmark(customBenchmarkItem?.id as string)
                      }
                    >
                      <Tooltip title={customBenchmarkItem?.name}>
                        <div className={style.CustomBenchmarkName}>
                          {customBenchmarkItem?.name}
                        </div>
                      </Tooltip>
                    </div>
                  );
                })(customBenchmarkList)}
              </div>
            ) : (
              <div className={style.EmptyBenchmarkList}>
                <EmptyContent
                  className={style.EmptyContent}
                  message={formatMessage("emptyCustomBenchmarkTip")}
                />
              </div>
            )}
          </div>
          {size(customBenchmarkList) || type === "addBenchmark" ? (
            <div className={style.BenchmarkModalRight}>
              <div className={style.BenchmarkHeader}>
                <h3>{formatMessage(type)}</h3>
                {formData?.id && fastProp("source")(formData) !== "SYSTEM" && (
                  <div>
                    <Space>
                      <Popconfirm
                        title={formatMessage("deleteBenchmarkTip")}
                        onConfirm={() =>
                          delCustomBenchmark(fastProp("id")(formData))
                        }
                        okText={formatMessage("ok")}
                        cancelText={formatMessage("cancel")}
                      >
                        <Button type="link" danger icon={<DeleteOutlined />} />
                      </Popconfirm>

                      <Button
                        type="link"
                        icon={
                          <EditOutlined onClick={() => editCustomBenchmark()} />
                        }
                      ></Button>
                    </Space>
                  </div>
                )}
              </div>
              <Form
                validateTrigger="onBlur"
                form={form}
                initialValues={initialValues}
                labelCol={formLabelCol}
                className={style.CustomBenchmarkForm}
                ref={formRef}
              >
                <Form.Item
                  label={formatMessage("BenchmarkName")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: formatMessage("customBenchmarkNameIsEmpty"),
                    },
                    {
                      pattern: portfolioNameReg,
                      message: formatMessage("customBenchmarkInfoNameRule"),
                    },
                    {
                      max: 30,
                      message: formatMessage("customBenchmarkNameMax"),
                    },
                    BenchmarkNameUniqValidator(
                      initialValues ? initialValues?.name : ""
                    ),
                  ]}
                >
                  <Input
                    disabled={disabled}
                    value={formData?.name}
                    onChange={(e) => onChangeValue("name")(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label={formatMessage("assetTypeTag")}
                  name="categoryIds"
                  rules={[
                    {
                      required: true,
                      message: formatMessage("assetCategoryIsEmpty"),
                    },
                  ]}
                >
                  <Checkbox.Group
                    onChange={onChangeCategoryId("conditions")}
                    value={formData?.categoryIds}
                    disabled={disabled}
                  >
                    {map((item: CategoryResponse) => {
                      return (
                        <Tag key={item.categoryId}>
                          <Checkbox value={item.categoryId}>
                            {item.name}
                          </Checkbox>
                          {flow(prop(`${item.categoryId}.weight`), (res) =>
                            formatPercentage(res, {
                              digit: 0,
                              defaultDisplay: "",
                            })
                          )(conditionMap)}
                        </Tag>
                      );
                    })(categoryOption)}
                  </Checkbox.Group>
                </Form.Item>
                <div className={style.CustomBenchmarkTip}>
                  {formatMessage("customBenchmarkEditTip")}
                </div>
                {size(formData?.conditions) ? (
                  <CategoryType
                    disabled={disabled}
                    conditions={formData?.conditions}
                    categoryOptionMap={categoryOptionMap}
                    onChangeValue={onChangeValue}
                  />
                ) : null}
              </Form>
            </div>
          ) : (
            <div className={style.EmptyBenchmark}>
              <h3>{formatMessage(type)}</h3>
              <EmptyContent
                className={style.EmptyContent}
                message={formatMessage("emptyCustomBenchmarkTip")}
              >
                <Button
                  icon={<PlusOutlined />}
                  className={style.AddBenchmark}
                  ghost
                  type="primary"
                  onClick={() => createBenchmark()}
                >
                  {formatMessage("addBenchmark")}
                </Button>
              </EmptyContent>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
});
