import { first, flow, fromPairs, set } from "lodash/fp";
import { fastNth, mapIndexed } from "./opt";
import { getPreviousTradingDate } from "./processedDates";
import getCumulativeReturns from "./quant/cumulativeReturns";
import getDynamicMaxDrawdown from "./quant/dynamicMaxDrawdown";

export type CalculatorFunc = (
  returns: number[],
  dates: string[],
  ...args: any[]
) => [number[], string[]];
export const addFirstZeroDate = (
  dates: string[],
  tradingDateList: string[],
  processedTradingDates: Record<string, any>
) => [
  getPreviousTradingDate(
    tradingDateList,
    processedTradingDates,
    first(dates) as string
  ) as string,
  ...(dates || []),
];

// 获取将第一天置为0的累计收益，因为图的第一天是置为0的，如果要和图上对应，那么算指标第一天也需要置为0
export const getCumulativeReturnsWithFirstDayZero = (
  returns: number[],
  isReturn = true
) => getCumulativeReturns(set(0, 0)(returns), isReturn);
export const cumulativeReturn: CalculatorFunc = (
  returns: number[],
  dates: string[]
) => [getCumulativeReturnsWithFirstDayZero(returns, true), dates];
export const cumulativeNetValue: CalculatorFunc = (
  returns: number[],
  dates: string[]
) => [getCumulativeReturnsWithFirstDayZero(returns, false), dates];
export const dayilreturn: CalculatorFunc = (
  returns: number[],
  dates: string[]
) => [returns, dates];

export const cumulativeDynamicMaxDrawdown: CalculatorFunc = (
  returns: number[],
  dates: string[]
) => [getDynamicMaxDrawdown(set(0, 0)(returns)), dates];

export const normalizeDailyReturnsMap = (
  dates: string[],
  dailyReturns: number[]
) =>
  flow(
    mapIndexed((date: string, index: number) => [
      date,
      fastNth(index)(dailyReturns),
    ]),
    fromPairs
  )(dates);

export type DailyReturns = {
  dates: string[];
  dailyReturns: number[];
  dailyReturnsMap: Record<string, number>;
};
export const normalizeDailyReturns = (
  dates: string[],
  dailyReturns: number[]
): DailyReturns => ({
  dates,
  dailyReturns,
  dailyReturnsMap: normalizeDailyReturnsMap(dates, dailyReturns),
});
