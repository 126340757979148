import { ButtonGroupItem } from "@/components/buttonGroup";
import { LineChartOpts } from "@/echarts/lineChart";
import { treeDataType } from "@/model/fundDetail";
import { getLastDateOfYear, getRectangleLegendConfig } from "@/util/chart";
import { DATEFORMAT } from "@/util/dateFormat";
import { FormatMessageFunc } from "@/util/formatMessage";
import getMessage from "@/util/getMessage";
import { formatPercentage, toFixedNumber } from "@/util/numberFormatter";
import { fastProp } from "@/util/opt";
import dayjs from "dayjs";
import { dateFormat } from "@/util/dateFormat";

export const buttonGroupList: ButtonGroupItem[] = [
  {
    id: "PerformanceDismantling",
    messageKey: "PerformanceDismantling",
    value: "PerformanceDismantling",
  },
  {
    id: "NetValueAttribution",
    messageKey: "NetValueAttribution",
    value: "NetValueAttribution",
  },
  {
    id: "BRINSON",
    messageKey: "BRINSON",
    value: "BRINSON",
  },
];

export const frequency = [
  {
    id: "MONTHLY",
    message: "MONTHLY",
  },
  {
    id: "QUARTER",
    message: "QUARTER",
  },
  {
    id: "YEARLY",
    message: "YEARLY",
  },
];
export const frequencyData = ["MONTHLY", "QUARTER", "YEARLY"];

export const MONTHLY = "MONTHLY";
export const QUARTER = "QUARTER";
export const YEARLY = "YEARLY";

const toFixedNumber2 = toFixedNumber(2);
export const commonIndicators = {
  maxDrawdown: {
    id: "maxDrawdown",
    message: "maxDrawdown",
    render: formatPercentage,
  },
  sharpeRatio: {
    id: "sharpeRatio",
    message: "sharpeRatio",
    render: toFixedNumber2,
  },
  informationRatio: {
    id: "informationRatio",
    message: "informationRatio",
    render: toFixedNumber2,
  },
  trackingError: {
    id: "trackDeviation",
    message: "trackDeviation",
    render: formatPercentage,
  },
  portfolioAttribution: {
    id: "portfolioAttribution",
    message: "portfolioAttribution",
    render: formatPercentage,
  },
  benchmarkAttribution: {
    id: "benchmarkAttribution",
    message: "benchmarkAttribution",
    render: formatPercentage,
  },
  otherAttribution: {
    id: "otherAttribution",
    message: "otherAttributions",
    name: getMessage("otherAttributions"),
    render: formatPercentage,
  },
};
export const brinsonIndicatorsInfo = {
  ...commonIndicators,
  portfolioActualAttribution: {
    id: "portfolioActualAttribution",
    message: "portfolioAttribution",
    name: getMessage("portfolioAttribution"),
    render: formatPercentage,
  },
  benchmarkActualAttribution: {
    id: "benchmarkActualAttribution",
    message: "benchmarkAttribution",
    name: getMessage("benchmarkAttribution"),
    render: formatPercentage,
  },
  allocationAttribution: {
    id: "allocationAttribution",
    message: "allocationAttribution",
    name: getMessage("allocationAttribution"),
    render: formatPercentage,
  },
  selectionAttribution: {
    id: "selectionAttribution",
    message: "selectionFundAttribution",
    name: getMessage("selectionFundAttribution"),
    render: formatPercentage,
  },
  activeAttribution: {
    id: "activeAttribution",
    message: "activeAttribution",
    name: getMessage("activeAttribution"),
  },
  timingOrInteractionAttribution: {
    id: "timingOrInteractionAttribution",
    message: "timingAttribution",
    name: getMessage("timingAttribution"),
    render: formatPercentage,
  },
};

export const fixedNameTableColumn = {
  dataIndex: "name",
  title: getMessage("name"),
  width: 150,
  fixed: "left",
  align: "left",
};
export const NetValueAttributionOmitRange = [
  "RECENT_MONTH",
  "RECENT_THREE_MONTH",
];

export type LineChartNames = {
  id: string;
  message: string;
  color: string;
};
export const dismantlingLineChartNames: LineChartNames[] = [
  {
    id: "portfolioInfo",
    message: "portfolioAttribution",
    color: "#1A7FFF",
  },
  {
    id: "benchmarkInfo",
    message: "benchmarkAttribution",
    color: "#FFA62A",
  },
  {
    id: "maxCategoryInfo",
    color: "#FF0A00",
    message: "",
  },
  {
    id: "minCategoryInfo",
    color: "#62D9AB",
    message: "",
  },
];

export const generateOptions = ({
  dates,
  legend,
  portfolioType = "",
}: {
  dates: string[];
  legend: string[];
  portfolioType?: string;
}) => {
  const yearOfDates = getLastDateOfYear(dates);
  return {
    grid: {
      top: 40,
    },
    xAxis: {
      data: dates,
      nameGap: 40,
      axisLabel: {
        align: "right",
        interval: (index: number, value: string) => {
          const year = fastProp(value)(yearOfDates);
          return year ? true : false;
        },
        formatter(value: string) {
          return portfolioType === "aum"
            ? `${dateFormat(value, "YYYY-MM")}`
            : `${fastProp(value)(yearOfDates)}${getMessage("year")}`;
        },
      },
    },
    yAxis: {
      min: "dataMin",
      max: "dataMax",
      axisLabel: {
        formatter(value: number) {
          return formatPercentage(value);
        },
      },
    },
    legend: {
      left: 0,
      top: 0,
      ...getRectangleLegendConfig(),
      data: legend,
    },
    tooltip: {
      valueFormatter: (value: any) => {
        return formatPercentage(value);
      },
    },
  };
};

export const getBrinsonAndNetValueLineChartOptions = (
  dates: string[]
): LineChartOpts["options"] => ({
  grid: {
    right: 40,
    top: 40,
  },
  xAxis: {
    data: dates,
    nameGap: 40,
    axisLabel: {
      formatter(value: string) {
        return `${dayjs(value).format(DATEFORMAT)}`;
      },
    },
  },
  yAxis: {
    min: "dataMin",
    max: "dataMax",
  },
  legend: {
    left: 0,
    top: 0,
    ...getRectangleLegendConfig(),
    orient: "horizontal",
  },
});

export const attributionTooltipFormatter =
  (formatMessage: FormatMessageFunc) => (params: treeDataType) => {
    if (params.name === formatMessage("otherAttributions")) {
      return formatMessage("otherAttributionTip", {
        value: formatPercentage(params.value),
      });
    }
    return [formatPercentage(params.value)] + `<br/>` + [params.name];
  };
