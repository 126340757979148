import { mapIndexed } from "@/util/opt";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import cn from "classnames";
import React from "react";
import style from "./rating.module.less";

export default React.memo<{
  totalRating?: number;
  rating: number;
  className?: string | undefined;
}>(({ totalRating = 5, rating, className }) => (
  <div className={cn(style.RatingRoot, className)}>
    {mapIndexed((item: any, index: number) =>
      index + 1 <= rating ? (
        <StarFilled className={style.fillColor} key={index} />
      ) : (
        <StarOutlined key={index} />
      )
    )(new Array(totalRating))}
  </div>
));
