import { marketIndexIds } from "@/constant/benchmarks";
import { RangeInterface, statisticRange } from "@/constant/statisticRange";
import { useAppSelector } from "@/hooks/redux";
import { BenchmarkInterface } from "@/model/entities";
import { marketIndexListSelector } from "@/selectors/benchmarks";
import { dateFormat } from "@/util/dateFormat";
import getMessage from "@/util/getMessage";
import { arrToObj, fastProp, normalize } from "@/util/opt";
import {
  getNatureDateAndTradingDate,
  getNextTradingDate,
} from "@/util/processedDates";
import { getAddRangeDate } from "@/views/portfolioManage/fundConfiguration/constant";
import { useCreation, useMemoizedFn } from "ahooks";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import dayjs from "dayjs";
import {
  filter,
  first,
  flatten,
  flow,
  map,
  maxBy,
  prop,
  pull,
  remove,
  toArray,
} from "lodash/fp";
import { useMemo, useState } from "react";
import { modelDurationOptions } from "../constant";

export const useMarketIndex = (
  onChange: (checkedValue: CheckboxValueType[]) => void
) => {
  const { categoryTypes } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation.modelAllocationData
  );
  const { factorMap } = useGetIndexInfo();
  const deleteIndex = useMemoizedFn((id: string) => {
    onChange(remove<string>((v) => v === id)(categoryTypes));
  });
  return {
    categoryTypes,
    factorMap,
    deleteIndex,
  };
};

export const useGetIndexInfo = () => {
  const marketIndexList = useAppSelector(
    marketIndexListSelector
  ) as BenchmarkInterface[];
  const marketIndexListMap = useCreation(
    () => normalize<BenchmarkInterface>("id")(marketIndexList),
    [marketIndexList]
  );
  const factorMap = useCreation(
    () =>
      flow(
        map(fastProp("children")),
        flatten,
        normalize("id")
      )(marketIndexList),
    [marketIndexList]
  );
  return {
    marketIndexList,
    marketIndexListMap,
    factorMap,
  };
};

export const useShowFactor = (
  categoryMap: Record<string, BenchmarkInterface>
) => {
  const [search, onSearch] = useState("");
  const [selectCategory, changeSelectCategory] = useState<string>(
    first(marketIndexIds) as string
  );
  return useCreation(() => {
    const showFactors = prop([selectCategory, "children"])(
      categoryMap
    ) as BenchmarkInterface[];
    return {
      search,
      onSearch,
      selectCategory,
      changeSelectCategory,
      showFactors: showFactors?.filter((v: BenchmarkInterface) =>
        v.name?.includes(search)
      ),
    };
  }, [categoryMap, search, selectCategory]);
};

export const useSelected = () => {
  const [selectIds, changeSelectedIds] = useState<string[]>([]);
  const onChecked = useMemoizedFn(
    (e: { target: { "factor-id"?: string; checked: boolean } }) => {
      if (e.target["factor-id"]) {
        if (e.target.checked) {
          changeSelectedIds([...selectIds, e.target["factor-id"]]);
        } else {
          changeSelectedIds(pull(e.target["factor-id"])(selectIds));
        }
      }
    }
  );

  const changeSelectedIdsByItem = useMemoizedFn((e: { target: any }) => {
    const factorId = e.target.getAttribute("factor-id");
    if (factorId) {
      if (!selectIds.includes(factorId)) {
        changeSelectedIds([...selectIds, factorId]);
      } else {
        changeSelectedIds(pull(factorId)(selectIds));
      }
    }
  });

  const clearSelected = useMemoizedFn((id: string) => {
    changeSelectedIds(pull(id)(selectIds));
  });

  return {
    selectIds,
    selectIdsMap: arrToObj(selectIds),
    changeSelectedIds,
    onChecked,
    changeSelectedIdsByItem,
    clearSelected,
  };
};

export const useGetModelDurationOptions = () => {
  const {
    categoryTypes,
    categorySource,
    allocateConfig: { dataRange },
  } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation.modelAllocationData
  );
  const { factorMap } = useGetIndexInfo();
  const [indexStartDate, indexEndDate] = useMemo(() => {
    if (categorySource === "ASSET_CATEGORY") return ["", ""];
    const indexes = map((v: string) => fastProp(v)(factorMap))(categoryTypes);
    const startDate = flow(
      maxBy("historyStart"),
      fastProp("historyStart")
    )(indexes);
    const endDate = flow(maxBy("historyEnd"), fastProp("historyEnd"))(indexes);
    return [startDate, endDate];
  }, [categorySource, categoryTypes, factorMap]);

  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  const [, tradingDateCount] = useCreation(
    () =>
      getNatureDateAndTradingDate(
        indexStartDate,
        indexEndDate
      )(processedTradingDates),
    [indexEndDate, processedTradingDates, indexStartDate]
  );
  const modelOptions = useMemo(() => {
    if (categorySource === "ASSET_CATEGORY") return modelDurationOptions;
    if (tradingDateCount < 10) return [];
    const addRangeDateGetter = getAddRangeDate(
      tradingDateList,
      processedTradingDates
    );
    const intersectionEndDay = dayjs(indexEndDate);
    return filter(({ id }) => {
      const startAddRangeDate = addRangeDateGetter(id, indexStartDate);
      return intersectionEndDay.isSameOrAfter(startAddRangeDate);
    })(modelDurationOptions);
  }, [
    categorySource,
    indexEndDate,
    indexStartDate,
    processedTradingDates,
    tradingDateCount,
    tradingDateList,
  ]);

  const startDateAddRange = useMemo(() => {
    const { period, count } = statisticRange[dataRange];
    return dataRange === "FROM_CREATION"
      ? indexStartDate
      : getNextTradingDate(
          tradingDateList,
          processedTradingDates,
          dateFormat(
            dayjs(indexStartDate).add(
              period as number,
              count as dayjs.ManipulateType
            )
          )
        );
  }, [dataRange, indexStartDate, processedTradingDates, tradingDateList]);

  return {
    modelOptions: flow(
      toArray,
      map((v: RangeInterface) => ({
        label: getMessage(v.message),
        value: v.id,
      }))
    )(modelOptions),
    indexStartDate: startDateAddRange,
    indexEndDate,
  };
};
