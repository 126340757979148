import { isNil, prop } from "lodash/fp";
import React from "react";
import { fastProp } from "@/util/opt";
import TaskResult from "@/components/taskResult";
import { useAppSelector } from "@/hooks/redux";
import style from "./index.module.less";
import PortfolioAnalysis from "../../portfolioAnalysis";
import { portfolioAnalysisConfig } from "@/providers/portfolioAnalysisProvider/context";

export default React.memo<{
  startDate: string;
  endDate: string;
}>(({ startDate, endDate }) => {
  const backTestingTask = useAppSelector(
    prop("portfolioAnalysis.backTestingTask")
  );
  return isNil(backTestingTask) ? null : (
    <TaskResult className={style.ResultTitle} task={backTestingTask}>
      <PortfolioAnalysis
        id={fastProp("backTestingId")(backTestingTask)}
        startDate={startDate}
        endDate={endDate}
        analysisType={portfolioAnalysisConfig.BACK_TEST}
      />
    </TaskResult>
  );
});
