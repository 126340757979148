import { Space } from "antd";
import React from "react";
import cn from "classnames";
import style from "../index.module.less";

const summaryCard = ({
  summaryText,
  className,
}: {
  summaryText: string;
  className?: string;
}): JSX.Element => {
  return (
    <Space direction="vertical" className={cn(style.fullWidth, className)}>
      <div className={style.summaryCard}>{summaryText}</div>
    </Space>
  );
};

export default React.memo(summaryCard);
