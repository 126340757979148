import { useState, useCallback, useEffect, useMemo } from "react";
import { PaginationProps, TablePaginationConfig } from "antd";
import { size, slice } from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";

const defaultPageInfo = {
  current: 1,
  total: 0,
  pageSize: 10,
};

type paginationData<T> = {
  pageInfo: PaginationProps;
  paginationData: T[];
  changePages: (page?: number, pageSize?: number) => void;
};
export function usePaginationData<T = any>(
  data: T[],
  initialStatePageInfo?: TablePaginationConfig
): paginationData<T> {
  const formatMessage = useFormatMessage();
  const [pageInfo, setPageInfo] = useState<TablePaginationConfig>({
    ...defaultPageInfo,
    ...(initialStatePageInfo || {}),
    showTotal: (total) =>
      formatMessage("totalSum", {
        total,
      }),
    total: size(data),
  });
  useEffect(() => {
    setPageInfo((info) => ({
      ...info,
      total: size(data),
    }));
  }, [data]);
  const changePages = useCallback(
    (current?: number, pageSize?: number) =>
      setPageInfo((info) => ({
        ...info,
        ...(current ? { current } : {}),
        ...(pageSize ? { pageSize } : {}),
      })),
    []
  );

  const paginationData = useMemo(() => {
    const { current, pageSize } = pageInfo || {};
    const start = ((current || 1) - 1) * (pageSize || 0);
    return slice(start, start + (pageSize || 0))(data);
  }, [data, pageInfo]);

  return {
    pageInfo,
    paginationData,
    changePages,
  };
}
