import MultiLoadableComponentLayout from "@/components/multiLoadableComponentLayout";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { fetchAllcustomerTag } from "@/store/createCustomer";
import {
  fetchOrganizationTypeList,
  updateCustomerAccountId,
} from "@/store/customerAccountAnalysis";
import { useFormatMessage } from "@/util/formatMessage";
import { useCreation } from "ahooks";
import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import HeldAssetsManage from "../heldAssetsManage/index";
import { menus } from "./constant";

export default React.memo(
  ({
    customerAccountId,
    customerName,
    customerTags,
  }: {
    customerAccountId: string;
    customerName: string;
    customerTags: string[];
  }) => {
    const AccountId = useAppSelector(
      (state: RootState) => state?.customerAnalysis?.AccountId
    );
    const componentProps = useCreation(
      () => ({
        customerId: AccountId,
        customerName,
        totalAccountId: customerAccountId,
        customerTags: customerTags,
      }),
      [customerAccountId, customerName, AccountId]
    );
    const dispatch = useAppDispatch();
    useEffect(() => {
      dispatch(fetchOrganizationTypeList());
      dispatch(fetchAllcustomerTag());
      dispatch(updateCustomerAccountId(customerAccountId));
    }, [customerAccountId, dispatch]);

    const formatMessage = useFormatMessage();
    const [type, setType] = useState("accountAnalysis");

    return (
      <>
        <Radio.Group
          value={type}
          onChange={(e) => setType(e.target.value)}
          buttonStyle="solid"
        >
          <Radio.Button value="accountAnalysis">
            {formatMessage("customerAccountAnalysis")}
          </Radio.Button>
          <Radio.Button value="heldAssetManage">
            {formatMessage("customerHeldAssetManage")}
          </Radio.Button>
        </Radio.Group>
        {type === "accountAnalysis" ? (
          <MultiLoadableComponentLayout
            componentProps={componentProps}
            menus={menus}
          />
        ) : (
          <HeldAssetsManage
            customerId={AccountId}
            totalAccountId={customerAccountId}
            customerName={customerName}
          />
        )}
      </>
    );
  }
);
