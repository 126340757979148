import { Card, Space, Row, Col } from "antd";
import React from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { map, prop } from "lodash/fp";
import { useAppSelector } from "@/hooks/redux";
import type { FundCompanyBasicInfo } from "@/model/compareManage";
import style from "../../index.module.less";
import ShieldInfo from "./shieldInfo";
import LoadingComponent from "@/components/LoadingComponent";
import { fetchFundCompanyCompareBaseInfo } from "@/store/compareManage";

export default React.memo<{
  className?: string;
}>(({ className }) => {
  const dataSource = useAppSelector(
    prop("compareManage.fundCompany.basicInfo")
  );
  const formatMessage = useFormatMessage();
  return (
    <LoadingComponent actions={fetchFundCompanyCompareBaseInfo}>
      <Card bordered={false} className={className}>
        <Space direction="vertical" className={style.fullWidth}>
          <h3>{formatMessage("basicInfo")}</h3>
          <Row>
            {map<FundCompanyBasicInfo, JSX.Element>((item) => (
              <Col span={6}>
                <ShieldInfo {...item} />
              </Col>
            ))(dataSource)}
          </Row>
        </Space>
      </Card>
    </LoadingComponent>
  );
});
