import React from "react";

type CaptchaImgProps = {
  src: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  children?: React.Component | string;
  className?: string;
};

const CaptchaImg = ({
  src,
  onClick,
  children,
  className,
}: CaptchaImgProps): JSX.Element => {
  return (
    <span className={className} onClick={onClick}>
      <img src={src} alt="" />
      {children}
    </span>
  );
};

export default CaptchaImg;
