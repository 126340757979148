import { getChartBaseOptions } from "@/util/chart";
import { useSize } from "ahooks";
import { EChartsOption } from "echarts";
import ReactEcharts, { EChartsReactProps } from "echarts-for-react";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { getStyle } from "./utils";

export type WrapperProps = {
  className?: string;
  options?: EChartsOption;
  showDataZoom?: boolean;
  width?: number | string;
  height?: number | string;
  events?: Record<string, (...args: any[]) => void>;
} & Partial<EChartsReactProps>;

export default forwardRef<any, WrapperProps>(
  (
    { className, options, showDataZoom, width, height, events, ...restProps },
    ref
  ) => {
    const style = useMemo(() => getStyle(width, height), [height, width]);
    const containerRef = useRef<any>(null);
    const size = useSize(containerRef);
    const [chartStyle, setChartStyle] = useState(style);
    useEffect(() => {
      if (size && size?.width && size.height) {
        setChartStyle(getStyle(size.width, size.height));
      }
    }, [size]);
    const option = useMemo(
      () => getChartBaseOptions(options, showDataZoom),
      [options, showDataZoom]
    );

    const chartRef = useRef<any>(null);
    useEffect(() => {
      if (chartRef) chartRef.current?.resize();
    }, []);
    useImperativeHandle(ref, () => chartRef.current);

    return (
      <div style={style} className={className} ref={containerRef}>
        <ReactEcharts
          ref={chartRef}
          option={option}
          onEvents={events}
          style={chartStyle}
          {...restProps}
        />
      </div>
    );
  }
);
