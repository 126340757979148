import { Pagination, Space, Table, Tag } from "antd";
import React, { useEffect, useMemo } from "react";
import cn from "classnames";
import SelectIndexDialog from "@/components/fundResultTable/selectIndexDialog";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { filter, map, prop, size, sumBy } from "lodash/fp";
import { useGetIndexInfo } from "@/components/fundResultTable/hooks";
import trophyImg from "@/assets/trophy.svg";
import ColorNumber from "@/components/colorNumber";
import { useAppDispatch } from "@/hooks/redux";
import {
  fetchCompetitionDetailInfo,
  fetchCompetitionFactorsData,
  fetchCompetitionPortFactors,
  fetchCompetitionRankBasicInfo,
} from "@/store/competition";
import { usePaginationData } from "@/hooks/pagination";
import { PortfolioRank } from "@/model/competition";
import { fastProp } from "@/util/opt";

import { dailyRiseAndFall, defaultFactors } from "../constant";
import CompetitionRankShow from "./competitionRankShow";
import {
  useGetColumns,
  useGetFactorData,
  useGetPortfolioBasicData,
  useGetPortfolioFactorData,
} from "../hook";
import style from "../index.module.less";
import { getFactorsFormatter } from "@/constant/factorFormatter";

type CompetitionRankProp = {
  type: string;
  competitionId: string;
};

const CompetitionRank = ({
  type,
  competitionId,
}: CompetitionRankProp): JSX.Element => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();

  const { factorMap } = useGetIndexInfo("comPortfolio");
  const { selectedFactorCode, changeFactorCode, selectedTag, setSelectedTag } =
    useGetFactorData();

  const columns = useGetColumns(
    selectedTag,
    selectedFactorCode,
    factorMap,
    type
  );
  const { portfolioRanks, myPortfolioRank, competitionFactorsDataMap } =
    useGetPortfolioBasicData(competitionId, type);
  const { dataSource } = useGetPortfolioFactorData(type, selectedFactorCode);
  const { pageInfo, paginationData, changePages } =
    usePaginationData(dataSource);

  const myRankFactorData = useMemo(() => {
    return prop(`${fastProp("portfolioId")(myPortfolioRank)}.factors`)(
      competitionFactorsDataMap
    );
  }, [competitionFactorsDataMap, myPortfolioRank]);

  const selectedFactorValue = useMemo(
    () => map((key: string) => `${type}_${key}`)(selectedFactorCode),
    [selectedFactorCode, type]
  );

  const filterSelectedFactorCode = useMemo(
    () => filter((key: string) => key !== selectedTag)(selectedFactorCode),
    [selectedFactorCode, selectedTag]
  );

  useEffect(() => {
    dispatch(fetchCompetitionPortFactors());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCompetitionDetailInfo(competitionId));
  }, [dispatch, competitionId]);

  useEffect(() => {
    if (selectedTag && competitionId && selectedTag) {
      dispatch(
        fetchCompetitionRankBasicInfo({
          competitionId: competitionId,
          factorId:
            type === "Day_Yield" ? dailyRiseAndFall : `${type}_${selectedTag}`,
          type: type,
        })
      );
    }
  }, [dispatch, competitionId, selectedTag, type]);
  useEffect(() => {
    if (size(portfolioRanks) && selectedFactorCode) {
      dispatch(
        fetchCompetitionFactorsData({
          competitionId: competitionId,
          factorIds:
            type === "Day_Yield" ? [dailyRiseAndFall] : selectedFactorValue,
          portfolioIds: map((item: PortfolioRank) =>
            fastProp("portfolioId")(item)
          )(portfolioRanks),
          type: type,
        })
      );
    }
  }, [
    dispatch,
    competitionId,
    type,
    portfolioRanks,
    selectedFactorCode,
    selectedFactorValue,
  ]);

  const scroll = useMemo(
    () => ({ x: sumBy("width")(columns), y: 410 }),
    [columns]
  );
  return (
    <>
      <Space direction="vertical" className={style.FullWidth} size={20}>
        {type !== "Day_Yield" && (
          <div className={style.RankHeader}>
            <div>
              {map((key: string) => {
                return (
                  <Tag
                    key={key}
                    className={cn(
                      style.RankTag,
                      selectedTag === key && style.SelectedTag
                    )}
                    onClick={() => setSelectedTag(key)}
                  >
                    {prop(`${key}.name`)(factorMap)}
                  </Tag>
                );
              })(selectedFactorCode)}
            </div>
            <SelectIndexDialog
              selectedIds={selectedFactorCode}
              onOk={changeFactorCode}
              defaultFactors={defaultFactors}
              type="comPortfolio"
            />
          </div>
        )}
        <div className={style.Rank}>
          <img src={trophyImg} />
          <span className={style.RankingListName}>
            {formatMessage("rankingList")}
          </span>
        </div>
        {fastProp("inCompetition")(myPortfolioRank) && (
          <div className={style.MyRankBox}>
            <div className={style.TopLeft}>{formatMessage("MyRank")}</div>
            <div className={style.RankContent}>
              <div className={style.RankContentLeft}>
                <Space size={0}>
                  <div className={style.RankNum}>
                    <CompetitionRankShow
                      rankNum={fastProp("rankNum")(myPortfolioRank)}
                    />
                  </div>
                  <div className={style.SelectedIndexShow}>
                    <ColorNumber
                      value={
                        prop(
                          `${fastProp("portfolioId")(
                            myPortfolioRank
                          )}.factors.${
                            type === "Day_Yield"
                              ? dailyRiseAndFall
                              : `${type}_${selectedTag}`
                          }`
                        )(competitionFactorsDataMap) || undefined
                      }
                      className={style.SelectedIndexValue}
                      formatValue={getFactorsFormatter(selectedTag)}
                    />
                    <div className={style.SelectedIndexName}>
                      {type === "Day_Yield"
                        ? formatMessage("dailyYieldReturn")
                        : prop(`${selectedTag}.name`)(factorMap)}
                    </div>
                  </div>
                  <div className={style.PortfolioBasicInfo}>
                    <PortfolioBasicInfo
                      formatMessage={formatMessage}
                      portfolioRank={myPortfolioRank}
                    />
                  </div>
                </Space>
              </div>
              <div className={style.RankContentRight}>
                {type !== "Day_Yield" &&
                  map((key: string) => {
                    return (
                      <div className={style.IndexBox}>
                        <Space direction="vertical">
                          <div>{prop(`${key}.name`)(factorMap)}</div>
                          <ColorNumber
                            value={fastProp(`${type}_${key}`)(myRankFactorData)}
                            formatValue={getFactorsFormatter(key)}
                          />
                        </Space>
                      </div>
                    );
                  })(filterSelectedFactorCode)}
              </div>
            </div>
          </div>
        )}
        <div className={style.ListTable}>
          <Table
            rowKey="portfolioId"
            dataSource={paginationData}
            columns={columns}
            pagination={false}
            scroll={scroll}
          />
          <Pagination
            className={style.TablePagination}
            {...pageInfo}
            showSizeChanger
            showQuickJumper
            onChange={changePages}
          />
        </div>
      </Space>
    </>
  );
};

export default React.memo(CompetitionRank);

export const PortfolioBasicInfo = ({
  formatMessage,
  portfolioRank,
}: {
  formatMessage: FormatMessageFunc;
  portfolioRank: PortfolioRank;
}) => {
  return (
    <div className={style.PortfolioInfo}>
      <div className={style.PortfolioName}>
        {fastProp("portfolioName")(portfolioRank) || "--"}
      </div>
      <div className={style.ManagerInfo}>
        <span className={style.Controller}>{formatMessage("controller")}:</span>
        <span className={style.OwnerName}>
          {fastProp("ownerName")(portfolioRank) || "--"}
        </span>
      </div>
      <div className={style.UpdateDate}>
        {formatMessage("dataUpdateDate")}:
        {fastProp("snapshotUpdateDate")(portfolioRank) || "--"}
      </div>
    </div>
  );
};
