import { RootState } from "@/store";
import { fastProp } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";

export const competitionDetailSelector = createSelector(
  (state: RootState) => state.competition.competitionDetail,
  (competitionDetail) => competitionDetail
);

export const competitionListBasicInfoSelector = createSelector(
  (state: RootState) => state.competition.competitionRankListBasicInfo,
  (_: RootState, type: string) => type,
  (competitionList, type) => fastProp(type)(competitionList) || {}
);

export const competitionFactorsDataSelector = createSelector(
  (state: RootState) => state.competition.competitionFactorsData,
  (_: RootState, type: string) => type,
  (competitionFactorsData, type) => fastProp(type)(competitionFactorsData) || {}
);
