import { TableProps, Table, Timeline } from "antd";
import cn from "classnames";
import { useCreation, useMemoizedFn, useSize } from "ahooks";
import {
  FundList,
  SummaryNode,
} from "@/components/portfolioCompoents/fundListTable";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { useFormatMessage } from "@/util/formatMessage";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import NumberInput from "@/components/numberInput";
import { formatPercentage } from "@/util/numberFormatter";
import { useTransferAssetToFundAsset } from "../hooks";
import { isEmpty, map, set, size, sumBy, update } from "lodash/fp";
import style from "../components/index.module.less";
import TableStyle from "@/components/portfolioCompoents/index.module.less";
import { Asset, ManualCreatePortfolio } from "../constant";
import { assetsType } from "@/model/portfolioList";
import { useValidateDeListFunds } from "@/hooks/modal";

type FundWeightProps = {
  dataSource: ManualCreatePortfolio[];
  className?: string;
  positionDate: string;
  onChange: (data: Record<string, any>[]) => void;
};

const useGetFundListData = (
  data: Asset[]
): [
  (Asset & FundList)[],
  React.Dispatch<React.SetStateAction<(Asset & FundList)[]>>
] => {
  const [assetData, setAssetData] = useState<(Asset & FundList)[]>([]);
  const transfer = useTransferAssetToFundAsset();
  const formateData = useCreation(() => transfer(data), [transfer, data]);
  useEffect(() => {
    setAssetData(
      map<Asset, Asset & FundList>((item) => ({
        ...item,
        editable: true,
        removable: false,
        purchFeeRatio: 0,
        redeemFeeRatio: 0,
      }))(formateData)
    );
  }, [data, formateData]);
  // const updateAssetData = useMemoizedFn((updater: number | null) => {
  //   setAssetData(update(index));
  // })
  return [assetData, setAssetData];
};

type FundWeightTableProps = TableProps<FundList>;
export const useGetFundWeightTableColumns = (
  setFundListData: React.Dispatch<React.SetStateAction<(Asset & FundList)[]>>
) => {
  const formatMessage = useFormatMessage();
  const onChangePurchFeeRatio = useMemoizedFn(
    (index: number) => (value: number | null) =>
      setFundListData(update(index, set("purchFeeRatio", value)))
  );
  const onChangeRedeemFeeRatio = useMemoizedFn(
    (index: number) => (value: number | null) =>
      setFundListData(update(index, set("redeemFeeRatio", value)))
  );

  return useCreation<FundWeightTableProps["columns"]>(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: formatMessage("fundName"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
      },
      {
        key: "code",
        dataIndex: "code",
        title: formatMessage("fundCode"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
      },
      {
        key: "investType",
        dataIndex: "investType",
        title: formatMessage("policyType"),
      },
      {
        key: "weight",
        dataIndex: "weight",
        title: formatMessage("positionWeight"),
        render: (weight) => formatPercentage(weight),
      },

      {
        key: "purchFeeRatio",
        dataIndex: "purchFeeRatio",
        title: formatMessage("purchFeeRatio"),
        render: (_, record, index) => (
          <NumberInput
            onChange={onChangePurchFeeRatio(index)}
            min={0}
            max={0.05}
            precision={2}
            className={TableStyle.RatioNumberInput}
            size="small"
            disabled={!fastProp("editable")(record)}
            value={fastProp("purchFeeRatio")(record)}
            type={"PERCENTAGE"}
          />
        ),
      },
      {
        key: "redeemFeeRatio",
        dataIndex: "redeemFeeRatio",
        title: formatMessage("redeemFeeRatio"),
        render: (_, record, index) => (
          <NumberInput
            className={TableStyle.RatioNumberInput}
            precision={2}
            size="small"
            min={0}
            max={0.05}
            onChange={onChangeRedeemFeeRatio(index)}
            disabled={!fastProp("editable")(record)}
            value={fastProp("redeemFeeRatio")(record)}
            type={"PERCENTAGE"}
          />
        ),
      },
    ],
    [formatMessage, onChangePurchFeeRatio, onChangeRedeemFeeRatio]
  );
};

export const fundTrimToAssetType = (
  fundData: (Asset & FundList)[]
): assetsType[] =>
  map<Asset & FundList, assetsType>((item) => ({
    fundId: item.fundId,
    purchFeeRatio: item.purchFeeRatio,
    redeemFeeRatio: item.redeemFeeRatio,
    weight: item.weight,
  }))(fundData);

export default forwardRef<
  {
    getData: () => Promise<any>;
  },
  FundWeightProps
>(({ dataSource, className, positionDate, onChange }, ref) => {
  const [activeAsset, setActiveAsset] = useState<ManualCreatePortfolio>(
    fastNth(0)(dataSource)
  );
  const formatMessage = useFormatMessage();
  const [fundListData, setFundListData] = useGetFundListData(
    activeAsset.weights
  );
  const columns = useGetFundWeightTableColumns(setFundListData);
  const dates = useCreation<string[]>(
    () => map("turnoverDate")(dataSource),
    [dataSource]
  );
  const scroll = useCreation(
    () =>
      size(activeAsset.weights) > 10 ? { y: 407, x: "max-content" } : undefined,
    [activeAsset.weights]
  );
  const tableRef = useRef(null);
  const tableSize = useSize(tableRef);
  const timeLineStyle = useCreation(
    () => ({
      height: (tableSize?.height || 45) - 45,
    }),
    [tableSize?.height]
  );
  const positionWeight = useCreation(
    () => sumBy("weight")(fundListData),
    [fundListData]
  );
  const validate = useValidateDeListFunds(
    positionDate,
    fundTrimToAssetType(fundListData)
  );
  useImperativeHandle(ref, () => ({
    getData: validate,
  }));
  useEffect(() => {
    validate().then((result) => {
      onChange(result);
    });
  }, [fundListData, onChange, validate]);
  return (
    <div className={style.DisplayContainer}>
      <div className={style.DisplayTime}>
        <h3 className={style.DisplayTimeTitle}>
          {formatMessage("openPosition")} / {formatMessage("theWarehouse")}
        </h3>
        <Timeline className={style.DisplayTimeLine} style={timeLineStyle}>
          {mapIndexed((date: string, index: number) => (
            <Timeline.Item
              key={date}
              className={
                activeAsset.turnoverDate === date
                  ? style.ActiveAsset
                  : style.NotActiveAsset
              }
            >
              <span onClick={() => setActiveAsset(fastNth(index)(dataSource))}>
                {date}
              </span>
            </Timeline.Item>
          ))(dates)}
        </Timeline>
      </div>
      <div className={style.DisplayTable}>
        <Table
          className={cn(style.FundWeightTable, className)}
          rowKey="fundId"
          columns={columns}
          ref={tableRef}
          dataSource={fundListData}
          pagination={false}
          scroll={scroll}
          summary={() =>
            !isEmpty(fundListData) && (
              <Table.Summary fixed>
                <SummaryNode
                  positionWeight={positionWeight}
                  fundListSize={size(fundListData)}
                />
              </Table.Summary>
            )
          }
        />
      </div>
    </div>
  );
});
