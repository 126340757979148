import { useAppDispatch } from "@/hooks/redux";
import {
  getFundManagerScaleTrend,
  getFundManagerManageFundInfo,
} from "@/store/fundManagerDetailSlice";
import React, { useEffect } from "react";
import cn from "classnames";
import { BaseFundManagerDetailInfoProps } from "../interface";
import ManageFundScale from "./components/manageFundScale";
import ManageFundTab from "./components/manageFundTab";
import PerformanceRanking from "./components/performanceRanking";
import style from "../index.module.less";
import { Col, Row } from "antd";
import RepresentFundYield from "./components/representFundYield";

export default React.memo<BaseFundManagerDetailInfoProps>(({ id }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getFundManagerScaleTrend(id));
    dispatch(getFundManagerManageFundInfo(id));
  }, [dispatch, id]);
  return (
    <>
      <ManageFundScale id={id} />
      <ManageFundTab className={style.MarginTop16} id={id} />
      <Row className={cn(style.MarginTop16, style.RowContainer)} gutter={16}>
        <Col span={12}>
          <PerformanceRanking id={id} />
        </Col>
        <Col span={12}>
          <RepresentFundYield id={id} />
        </Col>
      </Row>
    </>
  );
});
