import { Competition } from "@/model/competition";
import { useFormatMessage } from "@/util/formatMessage";
import { useMemoizedFn } from "ahooks";
import { Button } from "antd";
import React, { useRef } from "react";
import PreviewInfo, {
  CompetitionModalRef,
} from "../../manage/components/previewInfo";

export default React.memo(({ info }: { info: Competition }) => {
  const formatMessage = useFormatMessage();
  const competitionModalRef = useRef<CompetitionModalRef>(null);
  const openModal = useMemoizedFn((competitionInfo, e) => {
    e.stopPropagation();
    competitionModalRef.current?.openModal(competitionInfo);
  });
  return (
    <div>
      <Button
        size="small"
        type="primary"
        ghost
        onClick={(e) => openModal(info, e)}
      >
        {formatMessage("preview")}
      </Button>
      <PreviewInfo ref={competitionModalRef} />
    </div>
  );
});
