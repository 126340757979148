import { throttle } from "lodash/fp";
import React, {
  NamedExoticComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { fastHas, mapIndexed } from "@/util/opt";
import { useLocation } from "react-router-dom";
import { useNavigateByHash } from "./hooks";
import Renderer from "./renderer";
import style from "./index.module.less";
import { hashToId } from "./constant";
import { Space } from "antd";

export type menu = {
  id: string;
  messageKey: string;
  Component: NamedExoticComponent<any>;
  children?: Omit<menu, "Component">[];
};
export interface MultiLoadableComponentOptions {
  // propId: string;
  menus: menu[];
  className?: string;
  componentProps: Record<string, any>;
}

export default React.memo<MultiLoadableComponentOptions>(
  ({ componentProps, menus }) => {
    const [loadableComponents, setLoadableComponents] = useState<
      Record<string, boolean>
    >({});
    const navigateTo = useNavigateByHash();
    const debounceNavigate = useMemo(
      /*  */
      () => throttle(500)(navigateTo),
      [navigateTo]
    );
    const location = useLocation();
    useEffect(() => {
      const id = hashToId(location.hash);
      if (id && !fastHas(id)(loadableComponents)) {
        setLoadableComponents({
          ...loadableComponents,
          [id]: true,
        });
      }
    }, [loadableComponents, location.hash]);
    return (
      <div className={style.Root}>
        <Space direction="vertical" size={18} className={style.FullWidth}>
          {mapIndexed(({ id, Component }: menu, index: number) => (
            <Renderer
              navigateTo={debounceNavigate}
              isRender={fastHas(id)(loadableComponents)}
              id={id}
              key={id + index}
              Component={Component}
              componentProps={componentProps}
            />
          ))(menus)}
        </Space>
      </div>
    );
  }
);
