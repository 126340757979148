import { isNil, isNumber, prop } from "lodash/fp";
import { getTime } from "@/util/dateFormat";
import { equalNaN } from "@/util/opt";
import type { CompareFn, SortOrder } from "antd/es/table/interface";

const inValidNumber = {
  ascend: Number.MIN_SAFE_INTEGER,
  descend: Number.MAX_SAFE_INTEGER,
};

type Sorter = "descend" | "ascend";
const sortNumber = (valA: number, valB: number, sorter: Sorter) => {
  if (equalNaN(valA)) valA = inValidNumber[sorter];
  if (equalNaN(valB)) valB = inValidNumber[sorter];
  return valA - valB;
};
const sortDate = (valA: string, valB: string) => getTime(valA) - getTime(valB);
export const sortAntdTable =
  <T>(key: React.Key | readonly React.Key[]): CompareFn<T> =>
  (a: T, b: T, sorter?: SortOrder): number => {
    if (!sorter) return 0;
    const valA = prop(key)(a);
    const valB = prop(key)(b);
    if (isNil(valA) && isNil(valB)) return 0;
    if (isNil(valA) || equalNaN(valA)) return sorter === "ascend" ? 1 : -1; // valA 始终在最前
    if (isNil(valB) || equalNaN(valB)) return sorter === "ascend" ? -1 : 1; // valB 始终在最
    if (isNumber(valA) || isNumber(valB)) return sortNumber(valA, valB, sorter);
    if (!equalNaN(Date.parse(valA)) || !equalNaN(Date.parse(valB)))
      return sortDate(valA, valB);
    return valA - valB;
  };
