import { Input, Space } from "antd";
import React, { ReactNode } from "react";
import style from "../index.module.less";
import cn from "classnames";
import NumberInput from "@/components/numberInput";
import { identity } from "lodash/fp";

type categoryInputProps = {
  label: string | ReactNode;
  maxValue: number;
  minValue: number;
  onChangeMinValue?: (v: number | null) => void;
  onChangeMaxValue?: (v: number | null) => void;
};
export default React.memo<categoryInputProps>(
  ({
    label,
    maxValue,
    minValue,
    onChangeMinValue = identity,
    onChangeMaxValue = identity,
  }) => {
    return (
      <Space align="center" className={style.CategoryWeight}>
        <span>{label}</span>
        <Input.Group className={style.InputGroup} compact>
          <NumberInput
            type="PERCENTAGE"
            min={0}
            max={1}
            precision={2}
            allowClear={undefined}
            placeholder="0~100"
            addonAfter={undefined}
            className={style.InputNum}
            value={minValue}
            onChange={onChangeMinValue}
          />
          <Input placeholder="~" disabled className={style.InputCompact} />
          <NumberInput
            type="PERCENTAGE"
            min={0}
            max={1}
            precision={2}
            allowClear={undefined}
            placeholder="0~100"
            addonAfter={undefined}
            className={cn(style.InputNum, style.InputMax)}
            value={maxValue}
            onChange={onChangeMaxValue}
          />
          %
        </Input.Group>
      </Space>
    );
  }
);
