import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchGetCategoryAllocate } from "@/store/createPortfolio";
import { fastProp } from "@/util/opt";
import { useCreation, useMemoizedFn, useSafeState } from "ahooks";
import { groupBy, map, omit } from "lodash/fp";
import { useContext, useEffect, useRef, useState } from "react";
import { serializeAllocateDataHelper } from "../fundConfiguration/constant";
import { categoryTypeIds, fromAboveToBelowSteps } from "./constant";
import { getCategoryAllocateParam } from "./interface";
import { updateModelConfiguration } from "@/store/createPortfolio";
import { SavePortfolioRef } from "../manualCreatePortfolio/savePortfolio";
import { useFormatMessage } from "@/util/formatMessage";
import { message } from "antd";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";

const useGetCategoryAssets = () => {
  const {
    configurationAssets,
    modelAllocationData: { categoryTypes, categorySource },
  } = useAppSelector((state) => state.createPortfolio.modelAllocation);
  return useMemoizedFn(() => {
    const data = groupBy("category")(configurationAssets);
    return map((v: string) => {
      const categoryTypeId =
        categorySource === "ASSET_CATEGORY" ? fastProp(v)(categoryTypeIds) : v;
      const categoryAsset = fastProp(categoryTypeId)(data);
      return {
        categoryId: v,
        assetIds: map(fastProp("fundId"))(categoryAsset),
      };
    })(categoryTypes);
  });
};

export const useGetAssetAllocateData = () => {
  const {
    configurationAssets,
    fundConfigurationFormData,
    modelAllocationData: { allocateConfig, categorySource },
  } = useAppSelector((state) => state.createPortfolio.modelAllocation);
  const getCategoryAssets = useGetCategoryAssets();
  return useCreation(() => {
    const assetModelConfig = serializeAllocateDataHelper.serializeData(
      configurationAssets,
      fundConfigurationFormData
    );
    return {
      assetModelConfig: omit(["type"])(assetModelConfig),
      categoryModelConfig: {
        allocateConfig,
        categoryAssets: getCategoryAssets(),
        categorySource,
      },
      type: "category",
    };
  }, [
    allocateConfig,
    categorySource,
    configurationAssets,
    fundConfigurationFormData,
    getCategoryAssets,
  ]);
};

export const useWatchAllocateData = (currentStep: number) => {
  const assetAllocateData = useGetAssetAllocateData();
  const [formStateChanged, setFormStateChanged] = useSafeState(false);
  useEffect(() => {
    if (currentStep === fromAboveToBelowSteps.allocationModel) {
      setFormStateChanged(false);
    }
  }, [currentStep, setFormStateChanged]);

  useEffect(() => {
    // 从第二步返回第一步如果数据有更新，需要重新发请求
    setFormStateChanged(true);
  }, [assetAllocateData, setFormStateChanged]);
  return useCreation(
    () => ({ assetAllocateData, formStateChanged }),
    [assetAllocateData, formStateChanged]
  );
};

const useManageFundAllocation = (currentStep: number) => {
  const dispatch = useAppDispatch();
  const { assetAllocateData, formStateChanged } =
    useWatchAllocateData(currentStep);
  return useMemoizedFn((goNext: () => any) => {
    if (formStateChanged) {
      return dispatch(
        fetchGetCategoryAllocate(assetAllocateData as getCategoryAllocateParam)
      )
        .unwrap()
        .then(() => {
          dispatch(
            updateModelConfiguration("modelPortfolioAllocateData", () => [])
          );
          goNext();
        });
    }
    goNext();
    return Promise.resolve();
  });
};

export const useManageSavePortfolio = () => {
  const savePortfolioRef = useRef<SavePortfolioRef>(null);
  const formatMessage = useFormatMessage();
  const onStartSavePortfolio = useMemoizedFn((stackBack: () => any) => {
    savePortfolioRef.current?.onSavePortfolio().then(() => {
      message.success(formatMessage("createPortfolioSuccess"));
      stackBack();
    });
  });
  return { savePortfolioRef, onStartSavePortfolio };
};

export const useManageStepOperator = () => {
  const [steps, setSteps] = useState(fromAboveToBelowSteps.allocationModel);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const goNext = useMemoizedFn(() => setSteps((step) => step + 1));
  const { stackBack } = useContext(PlatformNavigationContext);
  const onStartAllocation = useManageFundAllocation(steps);
  const { savePortfolioRef, onStartSavePortfolio } = useManageSavePortfolio();
  const goBack = useMemoizedFn(() => {
    setSteps((step) => step - 1);
    setButtonDisabled(false);
  });
  const onNext = useMemoizedFn(() => {
    if (steps === fromAboveToBelowSteps.fundAllocation) {
      return onStartAllocation(goNext);
    } else if (steps === fromAboveToBelowSteps.savePortfolio) {
      return onStartSavePortfolio(stackBack);
    } else {
      return goNext();
    }
  });
  return {
    steps,
    onNext,
    goBack,
    buttonDisabled,
    setButtonDisabled,
    savePortfolioRef,
  };
};
