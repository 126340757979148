import React from "react";
import { first, isEmpty, last, merge } from "lodash/fp";

import { useFormatMessage } from "@/util/formatMessage";
import YieldTrendChart from "@/base-components/charts/yieldTrendChart";
import EmptyContent from "@/components/emptyContent";

import style from "./index.module.less";
import { useGetLineChartData } from "./pages/secondPage/hook";

export default React.memo(
  ({ fundId, showDate = false }: { fundId: string; showDate?: boolean }) => {
    const formatMessage = useFormatMessage();

    const { dates, series, chartOptions } = useGetLineChartData(fundId);

    const finalChartOptions = merge(chartOptions)(
      showDate
        ? {
            graphicOptions: {
              type: "text",
              right: 0,
              bottom: 36,
              style: {
                text: `（${first(dates)} ~ ${last(dates)}）`,
                fontSize: 12,
                lineHeight: 12,
                fill: "#999999",
                fontFamily: "SourceHanSansCN-Normal, SourceHanSansCN",
              },
            },
          }
        : {}
    );
    return (
      <>
        {isEmpty(dates) ? (
          <EmptyContent className={style.EmptyContent}>
            <strong>{formatMessage("noData")}</strong>
          </EmptyContent>
        ) : (
          <YieldTrendChart
            dates={dates}
            series={series}
            showDataZoom={false}
            height={220}
            onZero
            {...finalChartOptions}
            opts={{ devicePixelRatio: 4 }}
          />
        )}
      </>
    );
  }
);
