import getMessage from "@/util/getMessage";
import { fixedNumber } from "@/util/numberFormatter";
import { fastProp } from "@/util/opt";
import { TableProps } from "antd";
import style from "./index.module.less";

export const getPositionTableColumns = (
  date: string
): TableProps<any>["columns"] => [
  {
    title: getMessage("endOfDate"),
    dataIndex: "index",
  },
  {
    title: date,
    align: "center",
    dataIndex: "value",
    render: (value: string | number | Record<string, any>) => {
      if (typeof value === "string")
        return <div className={style.CenterName}>{value}</div>;
      if (typeof value === "number")
        return <div className={style.CenterName}>{fixedNumber(value)}</div>;
      return (
        <div className={style.SpaceAroundName}>
          <p>{fastProp("name")(value)}</p>
          <p>{fixedNumber(fastProp("value")(value))}</p>
        </div>
      );
    },
  },
];
