import { AssetAllocate } from "@/model/portfolioAnalysis";
import { weightRoundTo } from "@/util/formatter";
import { formatArrayNilToZero } from "@/util/numberFormatter";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { filter, flow, isEmpty, keys, map, some, sumBy } from "lodash/fp";
import { ManualCreatePortfolio } from "../../manualCreatePortfolio/constant";
import { ErrorField } from "./interface";

export const transferAssetAllocateToAsset = (
  assets: AssetAllocate["assets"]
): {
  fundId: string;
  weight: number;
}[] => {
  const fundIds = keys(assets);
  const weights = flow(
    map((id: string) => fastProp(id)(assets)),
    formatArrayNilToZero,
    weightRoundTo()
  )(fundIds);
  return mapIndexed((id: string, index: number) => ({
    fundId: id,
    weight: fastNth(index)(weights),
  }))(fundIds);
};

export const getDefaultErrorField = (): ErrorField => ({
  fundPositionError: false,
  optimizedError: false,
});

export const validateErrorField = (errorFiled: ErrorField) =>
  some((error) => !!error)(errorFiled);

export const getFundErrorAssetStatus = (
  manualPortfolioAllocateData: ManualCreatePortfolio[]
): boolean => {
  const fundErrorAsset = filter("errorField")(manualPortfolioAllocateData);
  return !isEmpty(fundErrorAsset);
};

export const getWeightError = (
  manualPortfolioAllocateData: ManualCreatePortfolio[]
): boolean =>
  some(({ weights }) => sumBy("weight")(weights) > 1)(
    manualPortfolioAllocateData
  );
