import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  factorsItem,
  FundNetValueAttributionBody,
  netValueAttributionModalBody,
} from "@/model/fundDetail";
import { checkNeedTime } from "@/providers/portfolioAnalysisProvider/provider";
import { categoryTreeMapSelector } from "@/selectors/category";
import { fundIdMapSelector } from "@/selectors/fund";
import { fetchNetValueAttributionModel } from "@/store/fundDetailSlice";
import { getTreeChartOptions } from "@/util/chart";
import { useFormatMessage } from "@/util/formatMessage";
import { formatNilToZero, formatPercentage } from "@/util/numberFormatter";
import { fastNth, fastProp, getProp, mapIndexed, normalize } from "@/util/opt";
import { TaskData } from "@/util/socket";
import { categoryTypeIds } from "@/views/portfolioManage/fromAboveToBelow/constant";
import { getBrinsonAndNetValueLineChartOptions } from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/constant";
import { useCreation } from "ahooks";
import { LineSeriesOption } from "echarts";
import {
  filter,
  first,
  flow,
  fromPairs,
  isEmpty,
  keys,
  last,
  map,
  prop,
} from "lodash/fp";
import { useEffect, useMemo, useState } from "react";
import {
  defaultModelId,
  getFundChartData,
  getFundFormatStatisticsTableData,
  getFundNetValueTreeData,
  getFundStatisticsTableColumnsAndDataSource,
} from "./constant";

const needTime = 15;

export const useGetFundNetValueAttributionData = (
  id: string,
  range: string,
  runningTime: number,
  portfolioAnalysisSelector: any,
  model: string
) => {
  const [errorBoundary, setErrorBoundary] = useState<string>();
  useEffect(() => {
    if (checkNeedTime(needTime, runningTime)) {
      setErrorBoundary("less15TradingDates");
    }
  }, [runningTime]);
  const portfolioInfo = useAppSelector((state) =>
    portfolioAnalysisSelector(state, id)
  );
  const netValueAttribution = useCreation(
    () =>
      (getProp(
        `netValueAttributionResult.${model}.netValueAttribution.${range}`
      )(portfolioInfo) || {}) as FundNetValueAttributionBody,
    [model, portfolioInfo, range]
  );
  const fundNetValueAttributionProgress = useCreation(
    () =>
      (getProp(
        `netValueAttributionResult.${model}.netValueAttributionProgress.${range}`
      )(portfolioInfo) || {}) as TaskData,
    [model, portfolioInfo, range]
  );
  useEffect(() => {
    if (
      !isEmpty(netValueAttribution) &&
      flow(prop("monthTradingDateNum"), isEmpty)(netValueAttribution)
    ) {
      setErrorBoundary("allLess15TradingDates");
    }
  }, [netValueAttribution]);

  const netValueAttributionModel = useAppSelector(
    prop("fundDetail.netValueAttributionModel")
  );

  const allFundMap = useAppSelector(fundIdMapSelector);

  const sectorCategoryMap = useAppSelector(categoryTreeMapSelector);

  const categoryId = prop(`${id}.categoryId`)(allFundMap);
  //判断基金是否是固收类
  const isFixedType = useMemo(() => {
    const path = prop(`${categoryId}.path`)(sectorCategoryMap);
    return first(path) !== categoryTypeIds.PARTIAL_DEBT;
  }, [categoryId, sectorCategoryMap]);

  const modalData = useMemo(() => {
    if (isFixedType) {
      return filter(
        (item: netValueAttributionModalBody) =>
          item?.factorModelId === defaultModelId
      )(netValueAttributionModel);
    } else {
      return netValueAttributionModel;
    }
  }, [isFixedType, netValueAttributionModel]);

  const factors = useCreation(() => {
    const modalMap = normalize("factorModelId")(netValueAttributionModel);
    return prop(`${model}.factors`)(modalMap);
  }, [netValueAttributionModel, model]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchNetValueAttributionModel());
  }, [dispatch]);

  return {
    netValueAttribution,
    errorBoundary,
    factors,
    factorsMap: normalize("factorId")(factors),
    fundNetValueAttributionProgress,
    netValueAttributionModel: modalData,
  };
};

export function useGetFundLineChartDataAndOptions(
  netValueAttribution: FundNetValueAttributionBody,
  factorsMap: Record<string, any>
) {
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  const { dates, dataSource } = useMemo(() => {
    return getFundChartData(
      netValueAttribution,
      tradingDateList,
      processedTradingDates,
      factorsMap
    );
  }, [netValueAttribution, processedTradingDates, tradingDateList, factorsMap]);
  const formatMessage = useFormatMessage();
  const options = useCreation(
    () => getBrinsonAndNetValueLineChartOptions(dates || []),
    [dates]
  );
  const series = useCreation(
    () =>
      map<string, LineSeriesOption>((v) => ({
        type: "line",
        name: formatMessage(v),
        showSymbol: false,
        lineStyle: {
          width: 1.5,
        },
        smooth: false,
        data: mapIndexed((_: string, index: number) =>
          formatNilToZero(fastNth(index)(fastProp(v)(dataSource)))
        )(dates),
      }))(keys(dataSource)),
    [formatMessage, dataSource, dates]
  );
  return { options, series };
}

export const useGetFundStatisticsTableColumnsAndData = (
  netValueAttribution: FundNetValueAttributionBody,
  factors: factorsItem[]
) => {
  const formatData = useCreation(
    () => getFundFormatStatisticsTableData(netValueAttribution, factors),
    [netValueAttribution]
  );
  const formatMessage = useFormatMessage();
  return useCreation(
    () =>
      getFundStatisticsTableColumnsAndDataSource(
        formatData,
        formatMessage,
        factors
      ),
    [formatData, formatMessage, factors]
  );
};

export function useGetFundTreeChartOptions(
  netValueAttribution: FundNetValueAttributionBody,
  factorsMap: Record<string, any>
) {
  const formatMessage = useFormatMessage();
  const treeData = useCreation(
    () =>
      getFundNetValueTreeData(netValueAttribution, formatMessage, factorsMap),
    [netValueAttribution, formatMessage]
  );
  return useCreation(
    () =>
      getTreeChartOptions(treeData, {
        tooltip: {
          formatter: (params: any) => {
            return [formatPercentage(params.value)] + `<br/>` + [params.name];
          },
        },
      }),
    [formatMessage, treeData]
  );
}

export const useCumulativeFactorsData = (
  netValueAttribution: FundNetValueAttributionBody,
  factors: factorsItem[]
) => {
  const { portfolioCumulativeReturns, factorAttributions } =
    netValueAttribution;
  const factorAttributionsMap = normalize("factorId")(factorAttributions);
  const cumnlateFactor = useMemo(
    () =>
      flow(
        map((item: factorsItem) => [
          item?.factorId,
          flow(
            prop(`${item?.factorId}.attributions`),
            last
          )(factorAttributionsMap),
        ]),
        fromPairs
      )(factors),
    [factors, factorAttributionsMap]
  );
  return {
    assetCumulativeReturn: last(portfolioCumulativeReturns),
    cumnlateFactor,
  };
};
