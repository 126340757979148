import { useGetConfirm } from "@/hooks/modal";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchMoveOptionalFund } from "@/store/workbench";
import { useFormatMessage } from "@/util/formatMessage";
import { arrayToMap, fastProp } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import { Popover, Tooltip, Button } from "antd";
import {
  filter,
  flow,
  isEmpty,
  join,
  map,
  reject,
  find,
  prop,
} from "lodash/fp";
import React, { useState } from "react";
import { fundManagerMapSelector } from "@/views/compareManage/fundManagerCompare/selectors";
import style from "../index.module.less";
import { optionalFundManagerGroupList, FundManager } from "../interface";
import { optionalFundManagerGroupListSelector } from "../selector";

type movePopoverProps = {
  className?: string;
  groupId: string;
  fundManagerIds: string[];
  btnType?: string;
  type: string;
};
export default React.memo<movePopoverProps>(
  ({ groupId, fundManagerIds, type, btnType = "text" }) => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();
    const allGroupList = useAppSelector(optionalFundManagerGroupListSelector);
    const groupList = reject(
      (v: optionalFundManagerGroupList) => v.id === groupId
    )(allGroupList);
    const confirm = useGetConfirm();
    const onMoveOptionalFund = useMemoizedFn(
      (toGroupId: string) => (fundManagerIds: string[]) => {
        dispatch(
          fetchMoveOptionalFund({
            toGroupId,
            fromGroupId: groupId,
            fundManagerIds,
            type,
          })
        );
      }
    );
    const fundManagerMap = useAppSelector(fundManagerMapSelector);
    const [visible, setVisible] = useState<boolean>(false);
    const onMoveFunds = useMemoizedFn(
      (toGroupId: string, groupName: string) => {
        const newGroup = find(
          (v: optionalFundManagerGroupList) => v?.id === toGroupId
        )(allGroupList);
        const fundList = map(({ fundManagerId, ...res }) => ({
          ...res,
          fundManagerId,
          name: prop(`${fundManagerId}.name`)(fundManagerMap),
        }))(newGroup?.fundManagerViews);
        const fundIdsMap = arrayToMap(fundManagerIds);
        const filterFunds = filter((v: FundManager) =>
          fastProp(v.fundManagerId)(fundIdsMap)
        )(fundList);
        if (isEmpty(filterFunds)) {
          setVisible(false);
          onMoveOptionalFund(toGroupId)(fundManagerIds);
        } else {
          const message = flow(map(fastProp("name")), join("、"))(filterFunds);
          confirm({
            content: formatMessage("optionalMovePopverMessage", {
              message,
              newGroupName:
                groupName === null ? formatMessage("unnamedGroup") : groupName,
            }),
            onOk: () => onMoveOptionalFund(toGroupId)(fundManagerIds),
          });
        }
      }
    );
    const handleVisible = useMemoizedFn(() => setVisible(!visible));
    const handleVisibleChange = useMemoizedFn((newVisible) => {
      setVisible(newVisible);
    });
    return (
      <Popover
        placement="bottom"
        trigger="click"
        content={
          <ul className={style.MovePopover}>
            {map((v: optionalFundManagerGroupList) => (
              <Tooltip
                title={
                  v.groupName === null
                    ? formatMessage("unnamedGroup")
                    : v.groupName
                }
                placement="left"
              >
                <li
                  key={v.groupName}
                  className={style.MovePopoverItem}
                  onClick={() => onMoveFunds(v.id, v.groupName)}
                >
                  <div className={style.Item}>
                    {v.groupName === null
                      ? formatMessage("unnamedGroup")
                      : v.groupName}
                  </div>
                </li>
              </Tooltip>
            ))(groupList)}
          </ul>
        }
        visible={visible}
        onOpenChange={handleVisibleChange}
      >
        <Button
          className={style.MoveToButton}
          type={btnType === "default" ? "default" : "text"}
          disabled={btnType === "default" && isEmpty(fundManagerIds)}
          onClick={handleVisible}
        >
          {formatMessage("moveTo")}
        </Button>
      </Popover>
    );
  }
);
