import ColorNumber from "@/components/colorNumber";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import Rating from "@/components/rating";
import {
  RangeInterface,
  statisticRange,
  yieldStatisticRange,
} from "@/constant/statisticRange";
import { LineChartOpts } from "@/echarts/lineChart";
import { getRectangleLegendConfig } from "@/util/chart";
import getMessage from "@/util/getMessage";
import { fixedHundredMillion, formatNil } from "@/util/numberFormatter";
import { arrayToMap, fastHas, fastProp, normalize } from "@/util/opt";
import { TableProps } from "antd";
import { SeriesOption } from "echarts";
import {
  filter,
  last,
  flow,
  map,
  pick,
  prop,
  keys,
  size,
  first,
  // set,
} from "lodash/fp";
import {
  RECENT_FIVE_YEAR,
  RECENT_YEAR,
  RECENT_THREE_YEAR,
  FROM_CREATION,
  RECENT_WEEK,
  RECENT_MONTH,
  RECENT_THREE_MONTH,
  RECENT_HALF_YEAR,
  RECENT_TWO_YEAR,
} from "@/constant/statisticRange";
import {
  getTradingDate,
  dateProcess,
  getPreviousTradingDate,
} from "@/util/processedDates";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import { compact } from "lodash";
import { sortAntdTable } from "@/util/sortTable";

export const getManageDateData = (dates: string[], manageDates: string[]) => {
  const manageDatesMap = arrayToMap(manageDates);
  return map<string, number>((date) =>
    fastHas(date)(manageDatesMap) ? 0 : (null as any)
  )(dates);
};

export const getOptions = (
  dates: string[],
  series: SeriesOption[]
): LineChartOpts["options"] => ({
  grid: {
    top: 70,
  },
  xAxis: [
    {
      type: "category",
      nameGap: 40,
      data: dates,
    },
  ],
  legend: [
    {
      ...getRectangleLegendConfig(),
      data: [getMessage("manageScale")],
      left: 0,
    },
    // {
    //   icon: "circle",
    //   itemWidth: 10,
    //   left: 90,
    //   data: [getMessage("takeOverOrOpen")],
    // },
    // {
    //   icon: "emptyCircle",
    //   itemWidth: 8,
    //   left: 180,
    //   data: [getMessage("leaveOfficeOrWindUp")],
    // },
  ],
  tooltip: {
    formatter(values: any) {
      const { marker, seriesName, name, value } = first(values) || {};
      return `<div>
      <p>${name}</p>
      <p style="margin: 8px 0;">${marker}${seriesName}:  ${fixedHundredMillion(
        value
      )}</p>
      </div>`;
    },
  },
  yAxis: [
    {
      axisLine: {
        show: true,
      },
      axisLabel: {
        formatter(val: number) {
          return fixedHundredMillion(val);
        },
      },
    },
  ],
  series,
});

type TableColumns = TableProps<any>["columns"];
const baseColumns: TableColumns = [
  {
    title: getMessage("serialNumber"),
    dataIndex: "index",
    key: "index",
    align: "center",
    width: 60,
    fixed: "left",
    render: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: getMessage("fundName"),
    dataIndex: "name",
    key: "name",
    width: 100,
    render: (name: string, record: any) => (
      <ToFundDetailPage name={name} id={fastProp("fundId")(record)} />
    ),
    fixed: "left",
  },
  {
    title: getMessage("fundCode"),
    dataIndex: "code",
    key: "code",
    width: 100,
    render: (code: string, record: any) => (
      <ToFundDetailPage name={code} id={fastProp("fundId")(record)} />
    ),
    fixed: "left",
  },
  {
    title: getMessage("fundType"),
    dataIndex: "fundType",
    key: "fundType",
    width: 180,
  },
];
export const getTableColumns = (): TableColumns => [
  ...baseColumns,
  {
    title: getMessage("foundDate"),
    dataIndex: "foundDate",
    key: "foundDate",
    sorter: sortAntdTable("foundDate"),
    width: 150,
  },
  {
    title: getMessage("earningsSinceInception"),
    dataIndex: "fromCreationReturn",
    key: "fromCreationReturn",
    width: 120,
    defaultSortOrder: "descend",
    sorter: sortAntdTable("fromCreationReturn"),
    render: (value: number) => (
      <ColorNumber value={value} formatter="percentage" />
    ),
  },
  {
    title: getMessage("commencementDate"),
    dataIndex: "startDate",
    key: "startDate",
    sorter: sortAntdTable("startDate"),
    width: 150,
  },
  {
    title: getMessage("earningsDuringTenure"),
    dataIndex: "fundDuringReturn",
    key: "fundDuringReturn",
    sorter: sortAntdTable("fundDuringReturn"),
    render: (value: number) => (
      <ColorNumber value={value} formatter="percentage" />
    ),
    width: 120,
  },
  {
    title: `${getMessage("fundScale")}(${getMessage("million")})`,
    dataIndex: "fundScale",
    key: "fundScale",
    width: 120,
    sorter: sortAntdTable("fundScale"),
    render: (fundScale: number) => fixedHundredMillion(fundScale),
  },
  {
    title: getMessage("fundRating"),
    dataIndex: "fundRating",
    key: "fundRating",
    width: 100,
    render: (fundRating: number) => <Rating rating={fundRating} />,
  },
  {
    title: getMessage("recentYearYieldRanking"),
    dataIndex: "fundRank",
    width: 150,
    key: "fundRank",
    render: (fundRank: number, record: any) => (
      <p>
        {formatNil(fundRank)} / {formatNil(fastProp("fundRankCount")(record))}
      </p>
    ),
  },
];

export const getPerformanceRankTableColumns = (
  section: string
): TableColumns => [
  ...baseColumns,
  {
    title: getMessage(prop(`${section}.message`)(statisticRange)),
    dataIndex: "value",
    width: 110,
    render: (value: number) => (
      <ColorNumber value={value} formatter="percentage" />
    ),
  },
  {
    title: getMessage("similarRankings"),
    dataIndex: "rank",
    width: 150,
    render: (rank: number, record: any) => (
      <p>
        {formatNil(rank)} / {formatNil(fastProp("size")(record))}
      </p>
    ),
  },
];

export const performanceAndYieldOptions = flow(
  pick([
    RECENT_WEEK,
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    RECENT_THREE_YEAR,
    RECENT_FIVE_YEAR,
    FROM_CREATION,
  ])
  // set("FROM_CREATION.message", getMessage("sinceTheIndustry"))
)(yieldStatisticRange) as Record<string, RangeInterface>;

export const getDuringReturns = (
  processedTradingDates: Record<string, any>,
  tradingDateList: string[],
  startDate: string,
  fundDailyReturn: Record<string, any>,
  endDate: string
) => {
  const navStartDate = getTradingDate(
    tradingDateList,
    processedTradingDates,
    startDate
  );
  const dailyReturnDates = keys(fundDailyReturn);
  let duringReturnDates: string[] = [];
  if (endDate) {
    const currIndex = dateProcess(endDate, processedTradingDates);
    const navEndDate = currIndex
      ? endDate
      : getPreviousTradingDate(tradingDateList, processedTradingDates, endDate);
    duringReturnDates = filter<string>(
      (date: string) => date >= navStartDate && date <= navEndDate
    )(dailyReturnDates);
  } else {
    duringReturnDates = filter<string>((date: string) => date >= navStartDate)(
      dailyReturnDates
    );
  }
  const duringReturns = map((date: string) => {
    return fastProp(date)(fundDailyReturn);
  })(duringReturnDates);
  return last(getCumulativeReturns(duringReturns)) as number;
};

export const getRangeScales = (funds: [], rangeDates: string[]) => {
  const scaleAndDateMap = normalize("date")(funds);
  return map((date: string) => size(prop(`${date}.fundIds`)(scaleAndDateMap)))(
    rangeDates
  );
};

export const getRangeDates = (dates: string[], rangeDates: string[]) => {
  return flow(
    map((date: string) => {
      const begin = first(rangeDates) as string;
      const end = last(rangeDates) as string;
      if (date >= begin && date <= end) return date;
      return null;
    }),
    compact
  )(dates);
};
