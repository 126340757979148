import { useAppSelector } from "@/hooks/redux";
import { fastProp } from "@/util/opt";
import { getDetailTableData } from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/brinsonAttribution/worker";
import { useMemo } from "react";
import {
  brinsonBasicInfoSelector,
  brinsonTreeChartDataSelector,
} from "./mapper";

export const useGetBrinsonTableData = (frequency: string) => {
  const basicBrinsonData = useAppSelector(brinsonBasicInfoSelector);
  return useMemo(
    () =>
      getDetailTableData(
        fastProp("dailyPortfolioReturns")(basicBrinsonData),
        fastProp("dailyPortfolioActualReturns")(basicBrinsonData),
        fastProp("dailyBenchmarkReturns")(basicBrinsonData),
        fastProp("dailyBenchmarkActualReturns")(basicBrinsonData),
        fastProp("dailyActiveReturns")(basicBrinsonData),
        fastProp("dailyActivePositionReturns")(basicBrinsonData),
        fastProp("dailyTimingOrInteractionAttributions")(basicBrinsonData),
        fastProp("dailyAllocationAttributions")(basicBrinsonData),
        fastProp("dailySelectionAttributions")(basicBrinsonData),
        frequency
      )(fastProp("dates")(basicBrinsonData)),
    [basicBrinsonData, frequency]
  );
};

export const useGetSummaryData = () => {
  const brinsonAttributionReturn = useAppSelector(brinsonBasicInfoSelector);
  const dates = useMemo(
    () => fastProp("dates")(brinsonAttributionReturn),
    [brinsonAttributionReturn]
  );

  const brinsonTreeChartData = useAppSelector(brinsonTreeChartDataSelector);

  const allocationAttribution = useMemo(
    () => fastProp("allocationAttribution")(brinsonTreeChartData),
    [brinsonTreeChartData]
  );
  const selectionAttribution = useMemo(
    () => fastProp("selectionAttribution")(brinsonTreeChartData),
    [brinsonTreeChartData]
  );

  const { value, name } = useMemo(() => {
    if (Math.abs(allocationAttribution) > Math.abs(selectionAttribution)) {
      return {
        name: "allocationAttribution",
        value: allocationAttribution,
      };
    } else {
      return {
        name: "selectionAttribution",
        value: selectionAttribution,
      };
    }
  }, [allocationAttribution, selectionAttribution]);
  return { dates, value, name };
};
