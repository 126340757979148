import { useFormatMessage } from "@/util/formatMessage";
import { fastNth, fastProp, size } from "@/util/opt";
import {
  Button,
  Card,
  Cascader,
  CascaderProps,
  Input,
  Select,
  Space,
} from "antd";
import {
  first,
  flow,
  identity,
  isString,
  keys,
  last,
  map,
  omit,
  prop,
  remove,
  values,
} from "lodash/fp";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import style from "./index.module.less";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ADD_CUSTOM, CREATE_CUSTOM } from "./constant";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import NumberInput from "../numberInput";
import { useCreation } from "ahooks";
import { treeToMap } from "@/util/opt/tree";
import { fetchCustomBenchmarkList } from "@/store/customBenchmark";

export const BENCHMARK_MANAGE = "BENCHMARK_MANAGE";
export const BENCHMARK = "BENCHMARK";
interface Option {
  value: string;
  label?: React.ReactNode;
  message?: string;
  disabled?: boolean;
  children?: Option[];
  isLeaf?: boolean;
}
type CustomChildrenItemProps = {
  message: string;
  benchmarkId: string;
};
type CustomBenchmarks = {
  id: number;
  value: string;
  weight: 0;
};
const customBenchmarks: CustomBenchmarks[] = [
  {
    id: 0,
    value: "",
    weight: 0,
  },
];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomChildrenItem = ({
  message,
  benchmarkId,
}: CustomChildrenItemProps): JSX.Element => {
  const deleteCustomBenchmark = useCallback(
    (e) => {
      e.stopPropagation();
      // eslint-disable-next-line no-console
      console.log(benchmarkId);
    },
    [benchmarkId]
  );
  return (
    <p className={style.CustomChildrenItem}>
      <span>{message}</span>
      <DeleteOutlined
        className={style.DeleteOutlined}
        onClick={deleteCustomBenchmark}
      />
    </p>
  );
};
type CreateCustomCardProps = {
  onCloseCard: (v: boolean) => void;
};
type BenchmarkSelectProps = {
  className?: string;
  selectedIds?: string[];
  onChange?: (v: string) => void;
  value: string;
};
const BenchmarkSelect = ({
  className,
  selectedIds = [],
  value,
  onChange = identity,
}: BenchmarkSelectProps): JSX.Element => {
  const benchmark = useAppSelector(
    (state) => state.compareManage.compareBenchmark
  );
  const optionsData = useMemo(
    () =>
      flow(
        omit(selectedIds),
        keys,
        map((v: string) => ({
          value: v,
          label: benchmark?.[v],
        }))
      )(benchmark),
    [benchmark, selectedIds]
  );
  return (
    <Select
      className={className}
      options={optionsData}
      value={benchmark?.[value]}
      onChange={onChange}
    />
  );
};
type CreateCustomItemProps = {
  onChange?: (id: number | null) => void;
  customId: number;
  selectBenchmarkData: CustomBenchmarks[];
  onChangeValue?: (key: string, v: string | number) => void;
  data: CustomBenchmarks;
};

const CreateCustomItem = ({
  onChange = identity,
  customId,
  selectBenchmarkData,
  onChangeValue = identity,
  data,
}: CreateCustomItemProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const dataSize = useMemo(
    () => size(selectBenchmarkData),
    [selectBenchmarkData]
  );
  const selectIds = useMemo(
    () => map(fastProp("value"))(selectBenchmarkData),
    [selectBenchmarkData]
  );
  const onChangeItem = useCallback(
    (id: number | null) => {
      onChange(id);
    },
    [onChange]
  );
  const lastId = useMemo(
    () => fastProp("id")(last(selectBenchmarkData)),
    [selectBenchmarkData]
  );
  const onChangeHandler = useCallback(
    (v: string) => {
      onChangeValue("value", v);
    },
    [onChangeValue]
  );
  return (
    <Space>
      <BenchmarkSelect
        className={style.BenchmarkSelect}
        onChange={onChangeHandler}
        value={data.value}
        selectedIds={selectIds}
      />
      <NumberInput
        className={style.NumberInput}
        value={data.weight}
        min={0}
        max={100}
        onChange={(v) => onChangeValue("weight", v as unknown as string)}
        type={"PERCENTAGE"}
      />
      <Button
        type="link"
        className={lastId === customId ? "" : style.AddPlusOutlined}
        onClick={() => onChangeItem(null)}
        icon={<PlusOutlined />}
      >
        {formatMessage("add")}
      </Button>
      {dataSize === 1 ? null : (
        <DeleteOutlined
          onClick={() => onChangeItem(customId)}
          className={style.DeleteOutlined}
        />
      )}
    </Space>
  );
};
const useCreateCustom = () => {
  const [benchmarkName, setBenchmarkName] = useState("");
  const benchmark = useAppSelector(
    (state) => state.compareManage.compareBenchmark
  );
  const onChangeBenchmarkName = useCallback((e: { target: any }) => {
    setBenchmarkName(e.target.value);
  }, []);
  const [customBenchmarkData, setCustomBenchmarkData] =
    useState(customBenchmarks);
  const onChangeCustomBenchmarkValue = useCallback(
    (id: number) => (key: string, v: string | number) => {
      setCustomBenchmarkData(
        map((custom: CustomBenchmarks) => {
          if (custom.id === id) {
            return {
              ...custom,
              [key]: v,
            };
          }
          return custom;
        })(customBenchmarkData)
      );
    },
    [customBenchmarkData]
  );
  const canAdd = useMemo(
    () => size(customBenchmarkData) < size(values(benchmark)),
    [benchmark, customBenchmarkData]
  );
  const onChangeCustomBenchmark = useCallback(
    (id: number | null) => {
      if (!id) {
        if (canAdd) {
          setCustomBenchmarkData([
            ...customBenchmarkData,
            {
              id: fastProp("id")(last(customBenchmarkData)) + 1,
              value: "",
              weight: 0,
            },
          ]);
        }
      } else {
        setCustomBenchmarkData(remove((v: CustomBenchmarks) => v.id === id));
      }
    },
    [canAdd, customBenchmarkData]
  );
  return {
    customBenchmarkData,
    benchmarkName,
    onChangeBenchmarkName,
    onChangeCustomBenchmarkValue,
    onChangeCustomBenchmark,
  };
};
const CreateCustomCard = ({
  onCloseCard,
}: CreateCustomCardProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const onCancel = useCallback(() => {
    onCloseCard(false);
  }, [onCloseCard]);
  const {
    customBenchmarkData,
    benchmarkName,
    onChangeBenchmarkName,
    onChangeCustomBenchmarkValue,
    onChangeCustomBenchmark,
  } = useCreateCustom();
  return (
    <Card
      size="small"
      bordered={false}
      actions={[
        <div className={style.CreateCustomCardActions}>
          <Space>
            <Button onClick={onCancel} size="small" type="default">
              {formatMessage("cancel")}
            </Button>
            <Button size="small" type="primary">
              {formatMessage("preservation")}
            </Button>
          </Space>
        </div>,
      ]}
    >
      <Space direction="vertical">
        <h4>{formatMessage("addBenchmark")}</h4>
        <Space>
          <span>{formatMessage("BenchmarkName")}:</span>
          <Input
            className={style.BenchmarkNameInput}
            placeholder={formatMessage("pleaseEnter")}
            onChange={onChangeBenchmarkName}
            value={benchmarkName}
          />
        </Space>
        <span>*{formatMessage("benchmarkIngredient")}:</span>
      </Space>
      <div className={style.AddCreateCustom}>
        <div>
          <Space>
            <h4 className={style.IngredientIndex}>
              {formatMessage("IngredientIndex")}
            </h4>
            <h4>{formatMessage("Weight")}</h4>
          </Space>
        </div>
        <Space className={style.CreateCustomItemBody} direction="vertical">
          {map((v: CustomBenchmarks) => (
            <CreateCustomItem
              onChange={onChangeCustomBenchmark}
              customId={v.id}
              data={v}
              onChangeValue={onChangeCustomBenchmarkValue(v.id)}
              selectBenchmarkData={customBenchmarkData}
            />
          ))(customBenchmarkData)}
        </Space>
      </div>
    </Card>
  );
};
const usePerformanceBenchmark = () => {
  const formatMessage = useFormatMessage();
  const benchmark = useAppSelector(
    (state) => state.compareManage.compareBenchmark
  );
  const customBenchmarkList = useAppSelector(
    (state) => state.customBenchmark.customBenchmarkList
  );
  const [showCustomStatus, setShowCustomStatus] = useState(true);
  const mainCreateCustom = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const customOptions = useMemo<Option>(
    () => ({
      value: BENCHMARK_MANAGE,
      label: "自定义业绩基准",
      children: [
        {
          value: ADD_CUSTOM,
          label: (
            <Button
              className={style.AddBenchmark}
              icon={<PlusOutlined />}
              type="link"
              onClick={() => setShowCustomStatus(true)}
            >
              {formatMessage("AddBenchmark")}
            </Button>
          ),
          children: showCustomStatus
            ? [
                {
                  value: CREATE_CUSTOM,
                  label: (
                    <div
                      onClick={mainCreateCustom}
                      className={style.CreateCustom}
                    >
                      <CreateCustomCard onCloseCard={setShowCustomStatus} />
                    </div>
                  ),
                },
              ]
            : undefined,
        },
      ],
    }),
    [formatMessage, mainCreateCustom, showCustomStatus]
  );
  const customBenchmark = useMemo<Option[]>(
    () => [
      {
        value: BENCHMARK_MANAGE,
        label: "自定义业绩基准",
        children: [
          ...map((item: Record<string, any>) => ({
            value: item.id,
            label: item.name,
          }))(customBenchmarkList),
          {
            value: "seat",
          },
        ],
      },
    ],
    [customBenchmarkList]
  );
  const options = useMemo<Option[]>(
    () => [
      {
        value: BENCHMARK,
        label: "常用业绩基准",
        children: [
          ...flow(
            keys,
            map((v: string) => ({
              value: v,
              label: benchmark?.[v],
            }))
          )(benchmark),
          {
            value: "seat",
          },
        ],
      },
      // customOptions,
      ...customBenchmark,
    ],
    [benchmark, customBenchmark]
  );
  const optionsMap = useCreation(
    () => treeToMap({ idKey: "value", needPath: true })(options),
    [options]
  );
  return useMemo(
    () => ({
      options,
      optionsMap,
    }),
    [options, optionsMap]
  );
};
export type PerformanceBenchmarkSelectValue = {
  benchmarkId: string;
  benchmarkType?: string;
};
type PerformanceBenchmarkSelectProps = Omit<
  CascaderProps<string>,
  "onChange" | "value"
> & {
  onChange?: (...args: any[]) => void;
  value?: string | PerformanceBenchmarkSelectValue;
};
const PerformanceBenchmarkSelect = ({
  onChange = identity,
  value,
  ...res
}: PerformanceBenchmarkSelectProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const { options, optionsMap } = usePerformanceBenchmark();
  const onChangeCascader = useCallback(
    (value: any) => {
      onChange({
        benchmarkId: last(value) as string,
        benchmarkType: first(value) as string,
      });
    },
    [onChange]
  );

  useEffect(() => {
    dispatch(fetchCustomBenchmarkList());
  }, [dispatch]);
  const cascaderValue = useCreation(() => {
    if (!value) return "";
    if (isString(value)) return prop(`${value}.path`)(optionsMap);
    return [value?.benchmarkType, value?.benchmarkId];
  }, [value, optionsMap]);
  return (
    <Cascader
      {...res}
      onChange={onChangeCascader}
      options={options}
      placeholder={formatMessage("PleaseSelect")}
      allowClear={false}
      value={cascaderValue}
      dropdownRender={(menus) => (
        <div className={style.CascaderDropdown}>{menus}</div>
      )}
      displayRender={(label, selectedOptions) => {
        if (!selectedOptions || size(selectedOptions) <= 1) return "";
        // const parentId = fastProp("value")(fastNth(0)(selectedOptions));
        const ownerId = fastProp("value")(fastNth(1)(selectedOptions));
        // if (parentId === BENCHMARK_MANAGE)
        //   return <>{prop(`${ownerId}.message`)(optionsMap)}</>;
        if (ownerId === ADD_CUSTOM) return "";
        return label[1];
      }}
    />
  );
};

export default React.memo(PerformanceBenchmarkSelect);
