import React from "react";
import { useCreation } from "ahooks";
import MultiLoadableComponentLayout from "@/components/multiLoadableComponentLayout";
import { menus } from "./constant";
// import style from "./index.module.less";
import { CommonProps } from "../type";

export default React.memo<CommonProps>(
  ({ id, assetPortfolioId, managerName }) => {
    const componentProps = useCreation(
      () => ({ id, assetPortfolioId, managerName }),
      [id, assetPortfolioId, managerName]
    );
    return (
      <div>
        <MultiLoadableComponentLayout
          componentProps={componentProps}
          menus={menus}
        />
      </div>
    );
  }
);
