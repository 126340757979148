import {
  findIndex,
  first,
  last,
  omit,
  slice,
  size,
  set,
  merge,
} from "lodash/fp";
import dayjs from "dayjs";
import { mapIndexed } from "@/util/opt";
import { formatPercentage } from "@/util/numberFormatter";
import cumulativeReturns from "@/util/quant/cumulativeReturns";
import dynamicMaxDrawdown from "@/util/quant/dynamicMaxDrawdown";
import { getDateReturnFitTargetDates } from "@/util/business-core/dailyReturn";
import {
  dataZoomConfig,
  tickLineColor,
  yAxisLabelColor,
  xAxisLabelColor,
} from "../helper";
import { ChartProps, ValueType, SeriesOption } from "./type";

export const valueFormatter =
  (type?: ValueType, formatter?: any) => (value: number) => {
    if (type === "netValue") {
      return formatPercentage(value, { digit: 4, symbol: "", scale: 1 });
    }
    if (formatter) {
      return formatter(value);
    }
    return formatPercentage(value);
  };

export const getSplitNumber = ({
  splitNumber,
  height,
  showLegend,
  showDataZoom,
}: Pick<
  ChartProps,
  "splitNumber" | "height" | "showLegend" | "showDataZoom"
>) => {
  if (splitNumber) {
    return splitNumber;
  }
  const availableHeight =
    (height || 300) - (showLegend ? 50 : 20) - (showDataZoom ? 60 : 12);
  return Math.floor(availableHeight / 50);
};

export const createChartOptionTitle = (title: ChartProps["title"]) => {
  if (title) {
    return {
      text: title,
      textAlign: "left",
      textStyle: { fontWeight: "normal", fontSize: 14 },
    };
  }
  return { text: "" };
};

export const createChartOptionTooltip = (
  type?: ChartProps["type"],
  formatter?: any
) => ({
  trigger: "axis",
  valueFormatter: valueFormatter(type, formatter),
  backgroundColor: "rgba(255,  255, 255, 0.7)",
});

export const createChartOptionGrid = ({
  showLegend,
  showDataZoom,
  title,
  gridOptions,
}: Pick<ChartProps, "showLegend" | "showDataZoom" | "title" | "gridOptions">) =>
  merge({
    left: 0,
    right: showLegend ? 50 : 10,
    top: 20 + (showLegend ? 30 : 0) + (title ? 20 : 0),
    bottom: 12 + (showDataZoom ? 48 : 0),
    containLabel: true,
  })(gridOptions);

export const createChartOptionDataZoom = (
  showDataZoom: ChartProps["showDataZoom"]
) => (showDataZoom ? dataZoomConfig : null);

export const createChartOptionToolbox = (
  saveImgEnable: ChartProps["saveImgEnable"]
) => ({
  feature: {
    saveAsImage: saveImgEnable ? {} : null,
  },
  right: 20,
});

function calInterval(dates: string[]) {
  const dateSize = size(dates);
  if (dateSize < 7) {
    return 0;
  }
  if (dateSize < 130) {
    return Math.floor(size(dates) / 5);
  }
  return "auto";
}

export const createChartOptionDateXAxis = ({
  dates,
  boundaryGap,
  dateMode,
  xAxisOptions,
}: Pick<ChartProps, "dates" | "boundaryGap" | "dateMode" | "xAxisOptions">) => {
  let option = {
    type: "category",
    data: dates,
    boundaryGap: boundaryGap || false,
    position: "bottom",
    splitLine: {
      // show: false,
    },
    axisLine: {
      show: true,
      onZero: false,
      lineStyle: {
        color: tickLineColor,
      },
    },
    axisTick: {
      // show: !boundaryGap,
      show: false,
      lineStyle: {
        color: tickLineColor,
      },
      // interval: (index: number, value: string) => {
      //   if (value === first(dates) || value === last(dates)) {
      //     return true;
      //   }
      //   // return false;
      // },
    },
    axisLabel: {
      margin: 10,
      color: xAxisLabelColor,
      // interval: (index: number, value: string) => {
      //   if (value === first(dates) || value === last(dates)) {
      //     return true;
      //   }
      // //   return false;
      // },
      hideOverlap: true,
      interval: calInterval(dates),
      showMinLabel: true,
      showMaxLabel: true,
      formatter: function (value: string) {
        //   if (value === first(dates)) {
        //     return "{alignRightLabel|" + value + "}";
        //   }
        if (value === last(dates)) {
          return `{alignLeftLabel|${value}}`;
        }
        return value;
      },
      rich: {
        alignRightLabel: {
          align: "right",
          width: 130,
        },
        alignLeftLabel: {
          align: "left",
          width: 130,
        },
      },
    },
  };
  if (dateMode === "twoLine") {
    option = merge(option)({
      axisLabel: {
        interval: "auto",
        formatter: function (value: string) {
          return `${dayjs(value).format("YYYY")}\n${dayjs(value).format(
            "MM-DD"
          )}`;
        },
        lineHeight: 18,
      },
    });
  }
  return merge(option)(xAxisOptions);
};

export const createBarChartOptionDateXAxis = ({
  dates,
}: Pick<ChartProps, "dates">) => ({
  type: "category",
  data: dates,
  position: "bottom",
  axisLine: {
    show: true,
    onZero: false,
    lineStyle: {
      color: tickLineColor,
    },
  },
  axisTick: {
    show: false,
  },
  axisLabel: {
    margin: 15,
    color: xAxisLabelColor,
    formatter: function (value: string) {
      return `${dayjs(value).format("YYYY")}\n${dayjs(value).format("MM-DD")}`;
    },
    lineHeight: 18,
  },
});

export const createChartOptionValueYAxis = ({
  splitNumber,
  height,
  showLegend,
  showDataZoom,
  type,
  formatter,
  yAxisOptions,
}: Pick<
  ChartProps,
  | "type"
  | "splitNumber"
  | "height"
  | "showLegend"
  | "showDataZoom"
  | "formatter"
  | "yAxisOptions"
>) =>
  merge({
    type: "value",
    splitNumber: getSplitNumber({
      splitNumber,
      height,
      showLegend,
      showDataZoom,
    }),
    axisLabel: {
      formatter: valueFormatter(type, formatter),
      color: yAxisLabelColor,
    },
    splitLine: {
      lineStyle: {
        type: "dashed",
      },
    },
  })(yAxisOptions);

export const createChartOptionLegend = ({
  title,
  showLegend,
  legendOptions,
}: Pick<ChartProps, "showLegend" | "title" | "legendOptions">) =>
  merge({
    top: title ? 25 : 0,
    icon: "rect",
    borderRadius: 4,
    left: "left",
    itemHeight: 4,
    show: showLegend,
  })(legendOptions);

export const getChartOption = ({
  title,
  height,
  showLegend,
  showDataZoom,
  saveImgEnable,
  dates,
  series,
  splitNumber,
  boundaryGap,
  dateMode,
  type,
  formatter,
  gridOptions,
  legendOptions,
  xAxisOptions,
  yAxisOptions,
  graphicOptions,
}: ChartProps) => {
  const result = {
    title: createChartOptionTitle(title),
    tooltip: createChartOptionTooltip(type, formatter),
    legend: createChartOptionLegend({ title, showLegend, legendOptions }),
    grid: createChartOptionGrid({
      showDataZoom,
      showLegend,
      title,
      gridOptions,
    }),
    dataZoom: createChartOptionDataZoom(showDataZoom),
    toolbox: createChartOptionToolbox(saveImgEnable),
    xAxis: createChartOptionDateXAxis({
      dates,
      boundaryGap,
      dateMode,
      xAxisOptions,
    }),
    yAxis: createChartOptionValueYAxis({
      splitNumber,
      height,
      showLegend,
      showDataZoom,
      type,
      formatter,
      yAxisOptions,
    }),
    series,
    ...(graphicOptions ? { graphic: graphicOptions } : {}),
  };
  return result;
};

export const generateSerieDataOfYieldTrendChart = (
  yields: number[],
  dates: string[]
): [date: string, value: number][] => {
  return mapIndexed((date: string, index: number) => [date, yields?.[index]])(
    dates
  );
};

const generateSerieFieldData = (
  targetDates: string[],
  dates: string[],
  returns: number[],
  fillEmptyDate: boolean
) => {
  const serieData = mapIndexed((date: string, index: number) => [
    date,
    returns?.[index] === undefined ? null : returns?.[index],
  ])(dates);
  if (!fillEmptyDate) return serieData;
  // 前置空数据补足
  const endIndexFromLeft = findIndex((date) => date === first(dates))(
    targetDates
  );
  // 后置空数据补足
  const startIndexFromEnd = findIndex((date) => date === last(dates))(
    targetDates
  );
  return [
    ...mapIndexed((date: string) => [date, 0])(
      slice(0, endIndexFromLeft)(targetDates)
    ),
    ...serieData,
    ...mapIndexed((date: string) => [date, 0])(
      slice(startIndexFromEnd + 1, size(targetDates) + 1)(targetDates)
    ),
  ];
};

export const generateDataFieldInSerie =
  (quantType: "cumulative" | "maxDrowdown") =>
  (targetDates: string[], type: ValueType, fillEmptyDate: boolean) =>
  (serie: SeriesOption): SeriesOption => {
    let data = [];
    if (serie.data) {
      data = serie.data;
    } else if (serie.dates && serie.returns) {
      data = generateSerieFieldData(
        targetDates,
        serie.dates,
        serie.returns,
        fillEmptyDate
      );
    } else if (serie.dates && serie.dailyReturns) {
      const result = getDateReturnFitTargetDates(targetDates, {
        dates: serie.dates,
        dailyReturns: serie.dailyReturns,
      });
      let resultReturns: number[] = [];
      switch (quantType) {
        case "cumulative":
          resultReturns = result.dailyReturns
            ? cumulativeReturns(
                set(0, 0)(result.dailyReturns),
                type === "yield"
              )
            : [];
          break;
        case "maxDrowdown":
          resultReturns = result.dailyReturns
            ? dynamicMaxDrawdown(set(0, 0)(result.dailyReturns))
            : [];
          break;
        default:
          break;
      }
      data = generateSerieFieldData(
        targetDates,
        result.dates || [],
        resultReturns,
        fillEmptyDate
      );
    }
    return {
      ...(omit(["data", "dates", "dailyReturns", "returns"])(
        serie
      ) as SeriesOption),
      data,
    };
  };
