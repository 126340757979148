/**
 * Stutzer指数算法
 */
import { flow, isEmpty, map, size, sum } from "lodash/fp";
import { zipWithMinus } from "../opt";
import { toStandardDeviation, div, toAverage } from "../math";
import { equalZero, formatQuantArrayNilToZero } from "../numberFormatter";

export default (dailyReturns: number[], benchmarkDailyReturns: number[]) => {
  if (isEmpty(dailyReturns) || isEmpty(benchmarkDailyReturns)) return 0;
  const [formatDailyReturns, formatBenchmarkDailyReturns] =
    formatQuantArrayNilToZero(dailyReturns, benchmarkDailyReturns);
  const subDailyReturns = zipWithMinus(
    formatDailyReturns,
    formatBenchmarkDailyReturns
  );
  const average = toAverage(formatDailyReturns);
  const std = toStandardDeviation(subDailyReturns);
  const ceta = -(sum(subDailyReturns) / size(subDailyReturns)) / std;
  if (equalZero(ceta)) return 0;
  const I = -Math.log(
    flow(
      map<number, number>((dailyReturn) =>
        Math.pow(Math.E, ceta * dailyReturn)
      ),
      sum,
      div(size(dailyReturns))
    )(formatDailyReturns)
  );
  return (Math.abs(average) / average) * Math.sqrt(2 * Math.abs(I));
};
