import { Dispatch } from "redux";
import {
  createAction,
  AsyncThunkOptions,
  unwrapResult,
} from "@reduxjs/toolkit";
import { PromiseFunctionType } from "@/model/promise";
import { REQUEST_PENDING, REQUEST_RESOLVED } from "@/constant/socket";
import socket from "./socket/createBaseSocket";
import {
  SocketProgressData,
  SocketErrorData,
  EmitSocketResult,
  SocketFinishData,
} from "./socket/interface";
import { requestCache } from "./socket/bindSocketListener";

type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
};

export const requestFinishCache = new Map();

export default function createSocketThunk<
  ThunkApiConfig extends AsyncThunkConfig,
  ThunkArg = void
>(
  typePrefix: string,
  finishEventName: string | null,
  payloadCreator: PromiseFunctionType<EmitSocketResult, ThunkArg>,
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>
) {
  const start = createAction(
    typePrefix + "/start",
    (payload: EmitSocketResult, arg: ThunkArg) => ({
      payload,
      meta: {
        arg,
        progress: REQUEST_PENDING,
        requestStatus: "start" as const,
      },
    })
  );

  const progress = createAction(
    typePrefix + "/progress",
    (payload: SocketProgressData, arg: ThunkArg) => ({
      payload,
      meta: {
        arg,
        requestStatus: "progress" as const,
      },
    })
  );

  const finish = createAction(
    typePrefix + "/finish",
    (payload: SocketFinishData, arg: ThunkArg) => ({
      payload: payload,
      meta: {
        arg,
        requestStatus: "finish" as const,
        progress: REQUEST_RESOLVED,
      },
    })
  );

  const error = createAction(
    typePrefix + "/error",
    (error: SocketErrorData | null, arg: ThunkArg, payload?: any) => ({
      payload,
      error:
        options && options.serializeError
          ? options.serializeError(error)
          : error,
      meta: {
        arg,
        requestStatus: "error" as const,
      },
    })
  );

  function actionCreator(arg: Parameters<typeof payloadCreator>[0]) {
    const progressDispatcher =
      (dispatch: Dispatch) => (data: SocketProgressData) =>
        dispatch(progress(data, arg));

    const finishDispatcher = (dispatch: Dispatch) => (data: SocketFinishData) =>
      dispatch(finish(data, arg));

    const errorDispatcher = (dispatch: Dispatch) => (err: SocketErrorData) =>
      dispatch(error(err, arg));
    if (finishEventName) {
      socket.on(finishEventName, (data: SocketFinishData) => {
        const taskId = data.taskId;
        if (requestFinishCache.has(taskId)) {
          requestFinishCache.get(taskId)(data);
          requestFinishCache.delete(taskId);
        }
      });
    }
    return (dispatch: Dispatch, getState: any, extra: any) => {
      const promise = (async function () {
        let finalAction: ReturnType<typeof start | typeof error> | undefined =
          undefined;
        try {
          const result = await payloadCreator(arg, {
            dispatch,
            getState,
            extra,
          });
          if (result && result.taskId) {
            if (result.taskId) {
              requestFinishCache.set(result.taskId, finishDispatcher(dispatch));
              requestCache.set(result.taskId, {
                progress: progressDispatcher(dispatch),
                error: errorDispatcher(dispatch),
              });
            }
            finalAction = start(result, arg);
          }
        } catch (err: any) {
          finalAction = error(err, arg);
        }
        if (finalAction) {
          dispatch(finalAction);
        }
        return finalAction;
      })();

      return Object.assign(promise as Promise<any>, {
        arg,
        unwrap() {
          return promise.then<any>((action) => {
            if (action) {
              return unwrapResult(action);
            }
          });
        },
      });
    };
  }

  return Object.assign(actionCreator, {
    start,
    progress,
    finish,
    error,
    typePrefix,
  });
}
