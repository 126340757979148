import { usePaginationData } from "@/hooks/pagination";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { fastNth, fastProp, getProp, mapIndexed } from "@/util/opt";
import { searchData } from "@/util/search";
import { sortAntdTable } from "@/util/sortTable";
import exportXlsx from "@/util/exportXlsx";
import { getUserId } from "@/util/userInfo";
import { factorsFormatter } from "@/constant/factorFormatter";
import { useCreation, useMemoizedFn } from "ahooks";
import { Button, message, Popconfirm, TableProps, Tooltip } from "antd";
import { SaveSchemeFormType } from "@/views/portfolioManage/manualCreatePortfolio/backTestingAllocation/saveScheme";
import { filter, flatten, flow, isEmpty, map, prop } from "lodash/fp";
import React, { useContext, useEffect, useRef, useState } from "react";
import style from "../index.module.less";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import {
  deleteBackTestingScheme,
  editBackTestingScheme,
  getBackTestingSchemeList,
} from "@/store/portfolioList";
import {
  exportDataColumn,
  fundConfigurationFormDataHelper,
  getManualCreatePortfolioData,
  initBenchmarkFormData,
  modelConfigurationFormDataHelper,
} from "./constant";
import {
  Asset,
  ManualCreatePortfolio,
} from "../../manualCreatePortfolio/constant";
import { fundIdMapSelector } from "@/selectors/fund";
import dayjs from "dayjs";
import { BackTestingScheme } from "@/model/portfolioList";
import { find } from "lodash/fp";
import {
  resetFundConfiguration,
  resetManualCreatePortfolio,
  resetModelConfiguration,
} from "@/store/createPortfolio";
import { useGenerateAsset } from "../../manualCreatePortfolio/hooks";
import { useGenerateFundConfigurationAsset } from "../../fundConfiguration/hooks";
import { useGenerateFundConfigurationAsset as useFromAboveToBelowGenerateFundConfigurationAsset } from "../../fromAboveToBelow/fundAllocation/hooks";
import ManageSchemeModal, {
  ManageSchemeModalRef,
} from "../../manualCreatePortfolio/backTestingAllocation/manageSchemeModal";
import { useGetConfirm } from "@/hooks/modal";
import { benchmarksIdMapSelector } from "@/selectors/benchmarks";

const useTransformTurnoverPositionToExportData = () => {
  const fundIdsMap = useAppSelector(fundIdMapSelector);
  return useMemoizedFn((turnoverPosition: ManualCreatePortfolio[]) =>
    flow(
      map<ManualCreatePortfolio, any>((v) => {
        return map<
          Asset,
          {
            weight: number;
            code: string;
            turnoverDate: string;
          }
        >((item) => {
          return {
            weight: fastProp("weight")(item),
            code: prop(`${fastProp("fundId")(item)}.code`)(fundIdsMap),
            turnoverDate: v?.turnoverDate
              ? dayjs(v?.turnoverDate).format("YYYY/MM/DD")
              : "",
          };
        })(v?.weights);
      }),
      flatten
    )(turnoverPosition)
  );
};
const OperateRenderer = React.memo<{
  hasPermission: boolean;
  onEdit: (data: any) => any;
  record: any;
  goToBackTestingDetail: () => any;
  onDelete: () => any;
  onExport: (name: string, data: ManualCreatePortfolio[]) => any;
}>(
  ({
    hasPermission,
    onEdit,
    record,
    onDelete,
    goToBackTestingDetail,
    onExport,
  }) => {
    const formatMessage = useFormatMessage();
    const manageSchemeRef = useRef<ManageSchemeModalRef>(null);
    const openModal = useMemoizedFn((initFormValues) => {
      manageSchemeRef.current?.openModal(initFormValues);
    });
    const initFormValues = useCreation(
      () => ({
        name: fastProp("name")(record),
        shareRange: fastProp("shareRange")(record),
        description: fastProp("description")(record),
      }),
      [record]
    );
    return (
      <div className={style.ListTableOperate}>
        {
          <Button type="link" onClick={goToBackTestingDetail}>
            查看
          </Button>
        }
        {hasPermission && (
          <Button type="link" onClick={() => openModal(initFormValues)}>
            {formatMessage("edit")}
          </Button>
        )}
        {
          <Button
            onClick={() =>
              onExport(
                fastProp("name")(record),
                fastProp("turnoverPositions")(record)
              )
            }
            type="link"
          >
            导出
          </Button>
        }
        {hasPermission && (
          <Popconfirm title="确认删除回测方案?" onConfirm={onDelete}>
            <Button type="link">删除</Button>
          </Popconfirm>
        )}
        <ManageSchemeModal
          onOk={onEdit}
          title={formatMessage("saveBackTestScheme")}
          ref={manageSchemeRef}
        />
      </div>
    );
  }
);

export const useGetColumns = () => {
  const userId = useCreation(() => getUserId(), []);
  const { goToBackTestingDetail } = useContext(PlatformNavigationContext);
  const formatMessage = useFormatMessage();
  const onGoToBackTestingDetail = useMemoizedFn((id: string) =>
    goToBackTestingDetail({ id })
  );
  const dispatch = useAppDispatch();
  const onDelete = useMemoizedFn((id: string) => {
    dispatch(deleteBackTestingScheme(id))
      .unwrap()
      .then(() => {
        message.success(formatMessage("deleteSuccess"));
      });
  });
  const onEditScheme = useMemoizedFn(
    (id: string) => (data: SaveSchemeFormType) => {
      return dispatch(
        editBackTestingScheme({
          ...data,
          id,
        })
      )
        .unwrap()
        .then(() => {
          message.success(formatMessage("updateSchemeSuccess"));
        });
    }
  );

  const transform = useTransformTurnoverPositionToExportData();
  const onExport = useMemoizedFn(
    (name: string, turnoverPosition: ManualCreatePortfolio[]) => {
      exportXlsx({
        title: "回测方案",
        dataSource: transform(turnoverPosition),
        columns: exportDataColumn,
      })("xlsx")(name);
    }
  );

  return useCreation<TableProps<any>["columns"]>(
    () => [
      {
        title: "方案名称",
        fixed: "left",
        dataIndex: "name",
        width: 80,
        render: (name, record) => (
          <Tooltip title={name}>
            <span
              className={style.Link}
              onClick={() => onGoToBackTestingDetail(fastProp("id")(record))}
            >
              {name}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "保存日期",
        dataIndex: "saveDate",
        width: 80,
        sorter: sortAntdTable("saveDate"),
      },
      {
        title: "开始日期",
        dataIndex: "startDate",
        width: 80,
        sorter: sortAntdTable("startDate"),
      },
      {
        title: "结束日期",
        dataIndex: "endDate",
        width: 80,
        sorter: sortAntdTable("endDate"),
      },
      {
        title: "业绩基准",
        dataIndex: "benchmarkName",
        width: 80,
        align: "right",
      },
      {
        title: formatMessage("initScaleWan"),
        dataIndex: "initScale",
        width: 80,
        align: "right",
        render: factorsFormatter.scaleWithTenThousand,
        sorter: sortAntdTable("initScaleWan"),
      },
      {
        title: "累计收益",
        dataIndex: "accumulatedYield",
        width: 80,
        align: "right",
        render: factorsFormatter.yield,
        sorter: sortAntdTable("accumulatedYield"),
      },
      {
        title: "创建者",
        dataIndex: "userName",
        width: 80,
      },
      {
        title: "方案说明",
        dataIndex: "description",
        width: 80,
        render: (description: string) => (
          <Tooltip title={description}>
            <div className={style.TextOverflow}>{description}</div>
          </Tooltip>
        ),
      },
      {
        title: "操作",
        width: 150,
        fixed: "right",
        dataIndex: "userId",
        render: (id: string, record) => (
          <OperateRenderer
            hasPermission={id === userId}
            onEdit={onEditScheme(fastProp("id")(record))}
            record={record}
            onDelete={() => onDelete(fastProp("id")(record))}
            goToBackTestingDetail={() =>
              onGoToBackTestingDetail(fastProp("id")(record))
            }
            onExport={onExport}
          />
        ),
      },
    ],
    []
  );
};

export const useManageBackTestingScheme = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getBackTestingSchemeList());
  }, [dispatch]);
  const backTestingScheme = useAppSelector(
    prop("portfolioList.backTestingScheme")
  );
  const compareBenchmark = useAppSelector(benchmarksIdMapSelector);
  return useCreation(
    () =>
      map((item: any) => ({
        ...item,
        benchmarkName: prop(`${fastProp("benchmark")(item)}.name`)(
          compareBenchmark
        ),
      }))(backTestingScheme),
    [backTestingScheme, compareBenchmark]
  );
};

export const useManageTableState = (type: "myScheme" | "allScheme") => {
  const [searchValue, setSearchValue] = useState("");
  const schemeList = useManageBackTestingScheme();
  const filterTypeSchemeList = useCreation(() => {
    if (type === "allScheme") return schemeList;
    const userId = getUserId();
    return filter((item: any) => fastProp("userId")(item) === userId)(
      schemeList
    );
  }, [schemeList, type]);
  const filterDataSource = useCreation(() => {
    if (!searchValue) return filterTypeSchemeList;
    return searchData(filterTypeSchemeList, searchValue, ["name"]);
  }, [filterTypeSchemeList, searchValue]);
  const { pageInfo, paginationData, changePages } =
    usePaginationData(filterDataSource);
  return {
    setSearchValue,
    pageInfo,
    changePages,
    dataSource: paginationData,
  };
};

export const useGetCurrentScheme = (schemeId?: string) => {
  const schemeList = useAppSelector(
    (store) => store.portfolioList.backTestingScheme
  );

  return useCreation(
    () =>
      schemeId
        ? find<BackTestingScheme>(({ id }) => id == schemeId)(schemeList)
        : undefined,
    [schemeList, schemeId]
  );
};

const useHandleManualCreatePortfolio = () => {
  const generateAsset = useGenerateAsset();
  const dispatch = useAppDispatch();
  const { goToManualCreatePortfolio } = useContext(PlatformNavigationContext);
  return useMemoizedFn((currentScheme: BackTestingScheme) => {
    const turnoverPositions = fastProp("turnoverPositions")(currentScheme);
    const manualCreatePortfolioData = getManualCreatePortfolioData(
      turnoverPositions,
      generateAsset
    );
    if (!isEmpty(manualCreatePortfolioData)) {
      dispatch(
        resetManualCreatePortfolio({
          allocationData: getManualCreatePortfolioData(
            turnoverPositions,
            generateAsset
          ),
          backTestingFormData: initBenchmarkFormData(currentScheme),
        })
      );
      goToManualCreatePortfolio();
    }
  });
};

const useHandleFundConfiguration = () => {
  const dispatch = useAppDispatch();
  const { goToFundConfiguration } = useContext(PlatformNavigationContext);
  const generator = useGenerateFundConfigurationAsset();
  return useMemoizedFn((currentScheme: BackTestingScheme) => {
    const allocateRequest = fastProp("allocateRequest")(currentScheme);
    if (allocateRequest) {
      const constrains = prop("allocateConfig.constrains")(allocateRequest);
      const fundIds = map("id")(constrains);
      const configurationAssets = flow(
        generator,
        mapIndexed((item: any, index: number) => ({
          ...item,
          maxWeight: fastProp("maxWeight")(fastNth(index)(constrains)),
          minWeight: fastProp("minWeight")(fastNth(index)(constrains)),
        }))
      )(fundIds);
      dispatch(
        resetFundConfiguration({
          configurationAssets,
          fundConfigurationFormData:
            fundConfigurationFormDataHelper.getFormData(allocateRequest as any),
          backTestingFormData: initBenchmarkFormData(currentScheme),
        })
      );
      goToFundConfiguration();
    }
  });
};
const useHandleModelConfiguration = () => {
  const dispatch = useAppDispatch();
  const { goToFromAboveToBelow } = useContext(PlatformNavigationContext);
  const generator = useFromAboveToBelowGenerateFundConfigurationAsset("");
  return useMemoizedFn((currentScheme: BackTestingScheme) => {
    const { assetModelConfig, categoryModelConfig } =
      getProp("allocateRequest")(currentScheme) || {};
    if (assetModelConfig) {
      const constrains = prop("allocateConfig.constrains")(assetModelConfig);
      const fundIds = map("id")(constrains);
      const configurationAssets = flow(
        generator,
        mapIndexed((item: any, index: number) => ({
          ...item,
          maxWeight: fastProp("maxWeight")(fastNth(index)(constrains)),
          minWeight: fastProp("minWeight")(fastNth(index)(constrains)),
        }))
      )(fundIds);
      dispatch(
        resetModelConfiguration({
          modelAllocationData:
            modelConfigurationFormDataHelper.getModelAllocationData(
              categoryModelConfig
            ),
          configurationAssets,
          fundConfigurationFormData:
            fundConfigurationFormDataHelper.getFormData(assetModelConfig),
          backTestingFormData: initBenchmarkFormData(currentScheme),
        })
      );
      goToFromAboveToBelow();
    }
  });
};

export const useModifyConfiguration = (
  currentScheme: BackTestingScheme | undefined
) => {
  const manualCreatePortfolioHandler = useHandleManualCreatePortfolio();
  const fundConfigurationHandler = useHandleFundConfiguration();
  const modelConfigurationHandler = useHandleModelConfiguration();
  return useMemoizedFn(() => {
    if (!currentScheme) return;
    const type = prop("allocateRequest.type")(currentScheme);
    // type为空表示是手动构建保存的回测方案，手动构建保存回测方案的时候不会传allocateRequest
    if (isEmpty(type)) {
      return manualCreatePortfolioHandler(currentScheme);
    }
    // asset表示为基金配置保存的回测方案
    if (type === "asset") {
      return fundConfigurationHandler(currentScheme);
    }
    // category表示为自上而下保存的回测方案
    if (type === "category") {
      return modelConfigurationHandler(currentScheme);
    }
  });
};

export const useCloseModal = () => {
  const confirm = useGetConfirm();
  const { stackBack } = useContext(PlatformNavigationContext);
  const formatMessage = useFormatMessage();
  return useMemoizedFn(() =>
    confirm({
      onOk: stackBack,
      content: formatMessage("cancelModifyTip"),
    })
  );
};
