import { fastHas } from "@/util/opt";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEmpty, prop, reject } from "lodash/fp";
import { getFundManagerByFundId } from "@/api/portfolioList";
import { ManagerNameInterface } from "@/model/entities";

const initialState: Record<string, ManagerNameInterface> = {};

export const getFundManagersByDate = createAsyncThunk(
  "fundManagers/getLatestFundManagers",
  async (
    { date, fundIds }: { date: string; fundIds: string[] },
    { getState }
  ) => {
    const fundManagers = prop("fundManagers")(getState());
    if (date && !isEmpty(fundIds)) {
      const thisDateFundManagers = fundManagers[date];
      const requestIds = reject<string>((id) =>
        fastHas(id)(thisDateFundManagers)
      )(fundIds);
      if (!isEmpty(requestIds)) {
        const response = await getFundManagerByFundId(date, requestIds);
        return response;
      }
      return Promise.resolve();
    }
    return Promise.resolve();
  }
);

const fundManagersSlice = createSlice({
  name: "fundDetail",
  initialState,
  reducers: {},
  extraReducers: {
    [getFundManagersByDate.fulfilled.type]: (state, action) => {
      const date = action?.meta?.arg?.date;
      const payload = action.payload;
      if (date && !isEmpty(payload)) {
        state[date] = {
          ...(state[date] || {}),
          ...action.payload,
        };
      }
    },
  },
});

export default fundManagersSlice.reducer;
