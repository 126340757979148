import { Space, Row, Col } from "antd";
import React from "react";
import cn from "classnames";
import managerIntro from "@/assets/managerIntro.svg";
import fundManagement from "@/assets/fundManagement.svg";
import abilityPerformance from "@/assets/abilityPerformance.svg";
import fundStyle from "@/views/fundDetail/fundDetailInfo/assetDetail/containers/assetInfo/index.module.less";
import style from "./index.module.less";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { BaseFundManagerDetailInfoProps } from "../../interface";
import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { currentManagerInfoSelector } from "../../selectors";
import { isNil, isUndefined } from "lodash/fp";
import {
  fixedHundredMillion,
  formatPercentage,
  toFixedNumber,
} from "@/util/numberFormatter";
import LoadingComponent from "@/components/LoadingComponent";
import { getFundManagerDetailBasicInfo } from "@/store/fundManagerDetailSlice";
import { maxDrawdownCompareBenchmark } from "@/views/fundDetail/fundDetailInfo/assetDetail/containers/assetInfo/components/fundSummary";

const nilFormat = (value: any) =>
  isNil(value) || isUndefined(value) ? "--" : value;

const getCompareMessage =
  (benchmarkName: string, formatMessage: FormatMessageFunc) =>
  (managerValue: number, benchmarkValue: number) => {
    return managerValue > benchmarkValue
      ? formatMessage("SuperioroBenchmark", {
          benchmark: benchmarkName,
        })
      : formatMessage("WorseBenchmark", {
          benchmark: benchmarkName,
        });
  };

const ManagerIntroduction = ({ introduction }: { introduction: string }) => {
  const formatMessage = useFormatMessage();
  return (
    <div className={fundStyle.FundSummaryItem}>
      <div className={cn(fundStyle.ItemHeader, fundStyle.RiskHeader)}>
        <img src={managerIntro} />
        <div className={fundStyle.ItemHeaderTitle}>
          <p>{formatMessage("intro")}</p>
          <p className={fundStyle.ItemHeaderTitleName}>Personal introduction</p>
        </div>
      </div>
      <div
        className={cn(
          fundStyle.FundSummaryItemContent,
          fundStyle.InvestmentBackgroundIcon
        )}
      >
        {introduction ? (
          <Space size="large" direction="vertical">
            {introduction}
          </Space>
        ) : (
          <div className={style.NoDataContainer}>
            {formatMessage("noManagerIntro")}
          </div>
        )}
      </div>
    </div>
  );
};

const FundManagement = ({
  manageAmount,
  manageScale,
  majorFundName,
  workAge,
  managerName,
  investType,
}: {
  manageAmount: number;
  manageScale: number;
  majorFundName: string;
  workAge: number;
  managerName: string;
  investType: string;
}) => {
  const formatMessage = useFormatMessage();
  return (
    <div className={fundStyle.FundSummaryItem}>
      <div className={cn(fundStyle.ItemHeader, fundStyle.InvestmentHeader)}>
        <img src={fundManagement} />
        <div className={fundStyle.ItemHeaderTitle}>
          <p>{formatMessage("fundManagement")}</p>
          <p className={fundStyle.ItemHeaderTitleName}>Fund management</p>
        </div>
      </div>
      <div
        className={cn(
          fundStyle.FundSummaryItemContent,
          fundStyle.InvestmentBackgroundIcon
        )}
      >
        <Space size="large" direction="vertical">
          <div>
            {formatMessage("fundManagementTip", {
              managerName: managerName || "--",
              year: workAge,
              manageAmount: nilFormat(manageAmount),
              manageScale: nilFormat(fixedHundredMillion(manageScale)),
            })}
          </div>
          <div>
            {formatMessage("representFund")}: {nilFormat(majorFundName)}
          </div>
          <div>
            {formatMessage("mainManagedFundTypeTip", {
              investType: investType || "--",
            })}
          </div>
        </Space>
      </div>
    </div>
  );
};

const AbilityPerformance = ({
  ratingSd1Y,
  sharpeRatio1Y,
  maxDrawdown1Y,
  ratingSd1YRank,
  sharpeRatio1YRank,
  maxDrawdown1YRank,
  annualizedReturn1Y,
  annualizedReturn1YRank,
  sameTypeFundManagerCount,
  benchmarkName,
  benchmarkSharpeRatio,
  benchmarkMaxDrawdown,
  benchmarkAnnualizedReturn,
  benchmarkAnnualizedVolatility,
}: {
  ratingSd1Y: number;
  sharpeRatio1Y: number;
  maxDrawdown1Y: number;
  ratingSd1YRank: number;
  sharpeRatio1YRank: number;
  maxDrawdown1YRank: number;
  annualizedReturn1Y: number;
  annualizedReturn1YRank: number;
  sameTypeFundManagerCount: number;
  benchmarkName: string;
  benchmarkSharpeRatio: number;
  benchmarkMaxDrawdown: number;
  benchmarkAnnualizedReturn: number;
  benchmarkAnnualizedVolatility: number;
}) => {
  const formatMessage = useFormatMessage();
  const getCompareFunc = getCompareMessage(benchmarkName, formatMessage);
  return (
    <div className={fundStyle.FundSummaryItem}>
      <div className={cn(fundStyle.ItemHeader, fundStyle.FundManagerHeader)}>
        <img src={abilityPerformance} />
        <div className={fundStyle.ItemHeaderTitle}>
          <p>{formatMessage("AbilityPerformance")}</p>
          <p className={fundStyle.ItemHeaderTitleName}>Earning power</p>
        </div>
      </div>
      <div
        className={cn(
          fundStyle.FundSummaryItemContent,
          fundStyle.FundManagerBackgroundIcon
        )}
      >
        <Space size="large" direction="vertical">
          <div>
            {formatMessage("performanceAnnualizedReturn", {
              returnType: formatMessage("oneYearAnnualizedYield"),
              annualizedReturn: formatPercentage(annualizedReturn1Y),
              perfomanceType: getCompareFunc(
                annualizedReturn1Y,
                benchmarkAnnualizedReturn
              ),
              rank: `${nilFormat(annualizedReturn1YRank)} /
              ${nilFormat(sameTypeFundManagerCount)}`,
            })}
          </div>
          <div>
            {formatMessage("performanceAnnualizedReturn", {
              returnType: formatMessage("oneYearAnnualizedVolatility"),
              annualizedReturn: formatPercentage(ratingSd1Y),
              perfomanceType: maxDrawdownCompareBenchmark(
                ratingSd1Y,
                benchmarkAnnualizedVolatility,
                benchmarkName,
                formatMessage
              ),
              rank: `${nilFormat(ratingSd1YRank)} /
              ${nilFormat(sameTypeFundManagerCount)}`,
            })}
          </div>
          <div>
            {formatMessage("performanceAnnualizedReturn", {
              returnType: formatMessage("oneYearSharpeRatio"),
              annualizedReturn: toFixedNumber(2)(sharpeRatio1Y),
              perfomanceType: getCompareFunc(
                sharpeRatio1Y,
                benchmarkSharpeRatio
              ),
              rank: `${nilFormat(sharpeRatio1YRank)} /
              ${nilFormat(sameTypeFundManagerCount)}`,
            })}
          </div>
          <div>
            {formatMessage("performanceAnnualizedReturn", {
              returnType: formatMessage("oneYearMaxDrawdown"),
              annualizedReturn: formatPercentage(maxDrawdown1Y),
              perfomanceType: maxDrawdownCompareBenchmark(
                maxDrawdown1Y,
                benchmarkMaxDrawdown,
                benchmarkName,
                formatMessage
              ),
              rank: `${nilFormat(maxDrawdown1YRank)} /
              ${nilFormat(sameTypeFundManagerCount)}`,
            })}
          </div>
        </Space>
      </div>
    </div>
  );
};
export default React.memo<BaseFundManagerDetailInfoProps>(({ id }) => {
  const managerInfo = useAppSelector((state: RootState) =>
    currentManagerInfoSelector(state, id)
  );
  return (
    <LoadingComponent actions={getFundManagerDetailBasicInfo}>
      <Row gutter={8} wrap={false} className={style.BaseContainer}>
        <Col span={8}>
          <ManagerIntroduction introduction={managerInfo.introduction} />
        </Col>
        <Col span={8}>
          <FundManagement
            manageAmount={managerInfo?.manageAmount}
            manageScale={managerInfo?.manageScale}
            majorFundName={managerInfo?.majorFundName}
            workAge={managerInfo?.workAge}
            managerName={managerInfo?.name}
            investType={managerInfo?.investType}
          />
        </Col>
        <Col span={8}>
          <AbilityPerformance
            ratingSd1Y={managerInfo?.ratingSd1Y}
            sharpeRatio1Y={managerInfo?.sharpeRatio1Y}
            maxDrawdown1Y={managerInfo?.maxDrawdown1Y}
            ratingSd1YRank={managerInfo?.ratingSd1YRank}
            sharpeRatio1YRank={managerInfo?.sharpeRatio1YRank}
            maxDrawdown1YRank={managerInfo?.maxDrawdown1YRank}
            annualizedReturn1Y={managerInfo?.annualizedReturn1Y}
            annualizedReturn1YRank={managerInfo?.annualizedReturn1YRank}
            sameTypeFundManagerCount={managerInfo?.sameTypeFundManagerCount}
            benchmarkName={managerInfo?.benchmarkName}
            benchmarkSharpeRatio={managerInfo?.benchmarkSharpeRatio}
            benchmarkMaxDrawdown={managerInfo?.benchmarkMaxDrawdown}
            benchmarkAnnualizedReturn={managerInfo?.benchmarkAnnualizedReturn}
            benchmarkAnnualizedVolatility={
              managerInfo?.benchmarkAnnualizedVolatility
            }
          />
        </Col>
      </Row>
    </LoadingComponent>
  );
});
