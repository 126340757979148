import React, { useMemo } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { Card, Space, Table } from "antd";
import { useAppSelector } from "@/hooks/redux";
import { flow, orderBy } from "lodash/fp";
import style from "../index.module.less";
import tableStyle from "../../../index.module.less";
import { fastProp } from "@/util/opt";
import mapper from "../mapper";
import { getSuccessiveFundManagersColumns } from "../../../constant";

export default React.memo(({ fundId }: { fundId: string }): JSX.Element => {
  const formatMessage = useFormatMessage();
  const { dates, dailyReturnsMap, fundDetailFundManagers } = useAppSelector(
    (state) => mapper(state, fundId)
  );
  const columns = useMemo(
    () =>
      getSuccessiveFundManagersColumns(formatMessage, dates, dailyReturnsMap),
    [formatMessage, dates, dailyReturnsMap]
  );
  const tableData = useMemo(
    () =>
      flow(
        fastProp("historyFundManagers"),
        orderBy("endDate", "desc")
      )(fundDetailFundManagers),
    [fundDetailFundManagers]
  );
  return (
    <>
      <Card className={style.successiveManagerTable}>
        <Space direction="vertical" className={style.fullWidth}>
          <h3>{formatMessage("successiveFundManagers")}</h3>
          <Table
            bordered
            columns={columns as any}
            dataSource={tableData}
            pagination={false}
            className={tableStyle.Table}
          />
        </Space>
      </Card>
    </>
  );
});
