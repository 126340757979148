import { ExclamationCircleOutlined } from "@ant-design/icons";
import Tooltip from "antd/lib/tooltip";
import { fastHas, fastProp } from "@/util/opt";
import { OTHER_ID } from "@/views/fundDetail/fundDetailInfo/assetDetail/constant";
import PeriodRangePicker from "@/components/periodRangePicker";
import style from "./index.module.less";
import React, { useMemo } from "react";
import { formatPercentage } from "@/util/numberFormatter";
import { Table } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { sortAntdTable } from "@/util/sortTable";
import { isEmpty } from "lodash/fp";

const percentage = (value: number) => formatPercentage(value);

interface subIdType {
  subId: string;
}
export const FORMAT_TYPES = {
  PERCENT_TWO_FLOAT: "0.00%",
  PERCENT_THREE_FLOAT: "0.000%",
  TWO_FLOAT: "0.00",
  THREE_FLOAT: "0.000",
  FLOAT_CURRENCY: "#,##0.00",
  FOUT_FLOAT: "0.0000",
};

export default React.memo<{
  period: string;
  exportTitle: string;
  selectablePeriods: string[];
  brinsonDetailTableResult: Record<string, any>;
  selectPeriod: (period: string) => void;
  isETF: boolean;
}>(
  ({
    selectPeriod,
    selectablePeriods,
    period,
    brinsonDetailTableResult,
    isETF,
  }) => {
    const formatMessage = useFormatMessage();
    const dataSource = useMemo(
      () => fastProp("data")(brinsonDetailTableResult),
      [brinsonDetailTableResult]
    );
    const commonColumnConfigs = {
      render: percentage,
      align: "right",
      format: FORMAT_TYPES.PERCENT_TWO_FLOAT,
    };
    const subChildren = [
      {
        title: formatMessage("assetAllocationPerformance"),
        dataIndex: "assetAllocationPerformance",
        key: "assetAllocationPerformance",
        width: 120,
        sorter: sortAntdTable("assetAllocationPerformance"),
        ...commonColumnConfigs,
      },
      {
        title: formatMessage("aShareAttribution"),
        dataIndex: "aShareAttribution",
        key: "aShareAttribution",
        width: 120,
        sorter: sortAntdTable("aShareAttribution"),
        ...commonColumnConfigs,
      },
      {
        title: formatMessage("nonAShareAttribution"),
        dataIndex: "nonAShareAttribution",
        key: "nonAShareAttribution",
        width: 120,
        sorter: sortAntdTable("nonAShareAttribution"),
        ...commonColumnConfigs,
      },
      {
        title: formatMessage("nonEquityAttribution"),
        dataIndex: "nonEquityAttribution",
        key: "nonEquityAttribution",
        width: 150,
        sorter: sortAntdTable("nonEquityAttribution"),
        ...commonColumnConfigs,
      },
      {
        title: formatMessage("threeFloorOtherAttributions"),
        dataIndex: "threeFloorOtherAttributions",
        key: "threeFloorOtherAttributions",
        width: 150,
        sorter: sortAntdTable("threeFloorOtherAttributions"),
        ...commonColumnConfigs,
      },
    ];
    const basicColumns: any = [
      {
        title: formatMessage("periodAndAttribution"),
        dataIndex: "title",
        key: "title",
        width: 150,
        maxWidth: 200,
        align: "left",
        fixed: "left",
        render: (value: string, { subId }: subIdType) => {
          if (!isEmpty(subId)) {
            if (subId === OTHER_ID) {
              return (
                <div className={style.ChildrenRow}>
                  {formatMessage("other")}
                </div>
              );
            }
            return <div className={style.ChildrenRow}>{value}</div>;
          }
          if (value === "--") {
            return (
              <Tooltip title={formatMessage("industryWarning")}>
                <ExclamationCircleOutlined />
              </Tooltip>
            );
          }
          return value;
        },
      },
      {
        title: formatMessage("allocationAttribution"),
        dataIndex: "allocationAttribution",
        key: "allocationAttribution",
        width: 100,
        sorter: sortAntdTable("allocationAttribution"),
        ...commonColumnConfigs,
      },
      {
        title: formatMessage("selectionAttribution"),
        dataIndex: "selectionAttribution",
        key: "selectionAttribution",
        width: 100,
        sorter: sortAntdTable("selectionAttribution"),
        ...commonColumnConfigs,
      },
      {
        title: formatMessage("timingAttribution"),
        dataIndex: "timingOrInteractionAttribution",
        key: "timingOrInteractionAttribution",
        width: 100,
        sorter: sortAntdTable("timingOrInteractionAttribution"),
        ...commonColumnConfigs,
      },
      {
        title: isETF
          ? formatMessage("otherAttributions")
          : formatMessage("forthFloorOtherAttributions"),
        dataIndex: isETF ? "otherAttribution" : "forthFloorOtherAttributions",
        key: "forthFloorOtherAttributions",
        width: 140,
        sorter: sortAntdTable("forthFloorOtherAttributions"),
        ...commonColumnConfigs,
      },
      {
        title: formatMessage("fundAttribution"),
        dataIndex: "fundAttribution",
        key: "fundAttribution",
        width: 100,
        sorter: sortAntdTable("fundAttribution"),
        ...commonColumnConfigs,
      },
      {
        title: formatMessage("benchmarkAttribution"),
        dataIndex: "benchmarkAttribution",
        key: "benchmarkAttribution",
        width: 100,
        sorter: sortAntdTable("benchmarkAttribution"),
        ...commonColumnConfigs,
      },
      {
        title: formatMessage("activeAttribution"),
        dataIndex: "activeAttribution",
        key: "activeAttribution",
        width: 100,
        sorter: sortAntdTable("activeAttribution"),
        ...commonColumnConfigs,
      },
    ];
    const columns = isETF ? basicColumns : basicColumns.concat(subChildren);

    return (
      <div className={style.Distribution}>
        <div className={style.IndustryTitle}>
          {formatMessage("industryPerformanceDistribution")}
        </div>
        <PeriodRangePicker
          period={period}
          selectablePeriods={selectablePeriods}
          onChange={selectPeriod}
        />
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ y: 400 }}
          className={style.DetailTable}
          rowClassName={(record: any) => {
            return fastHas("subId")(record) ? style.ChildrenRow : "";
          }}
        />
      </div>
    );
  }
);
