import React, { ReactNode, useCallback, useMemo } from "react";
import { Spin } from "antd";
import { useAppSelector } from "@/hooks/redux";
import { AsyncThunk } from "@reduxjs/toolkit";
import { isArray, isEmpty, isNumber, prop, some } from "lodash/fp";
import { fastProp } from "@/util/opt";

type ActionType = AsyncThunk<any, any, any>;

export const useGetIsActionsLoading = (actions: ActionType | ActionType[]) => {
  const loadings = useAppSelector(prop("global.loadings"));
  const actionIsLoading = useCallback(
    (type: string) => {
      if (!type) return false;
      const loadingCount = fastProp(type)(loadings);
      return isNumber(loadingCount) && loadingCount > 0;
    },
    [loadings]
  );
  return useMemo(() => {
    if (isEmpty(actions)) return false;
    if (!isArray(actions)) {
      return actionIsLoading(actions.typePrefix);
    }
    return some((action: ActionType) => actionIsLoading(action.typePrefix))(
      actions
    );
  }, [actionIsLoading, actions]);
};

export default React.memo<{
  children: ReactNode;
  actions: ActionType | ActionType[];
}>(({ children, actions }) => {
  const spinning = useGetIsActionsLoading(actions);
  return <Spin spinning={spinning}>{children}</Spin>;
});
