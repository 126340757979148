import PortfolioSummary from "./portfolioSummary";
import WarehouseAnalysis from "./warehouseAnalysis";
import RiskAnalysis from "./riskAnalysis";
import ScenarioAnalysis from "./scenarioAnalysis";
import PerformanceAttribution from "./performanceAttribution";
import PerformancePreview from "./performancePreview";
import PositionThrough from "./positionThrough";
import { FormatMessageFunc } from "@/util/formatMessage";
import { formatPercentage } from "@/util/numberFormatter";
import { factorsFormatter } from "@/constant/factorFormatter";

const percentage = (value: number) => formatPercentage(value);

export const portfolioAnalysisMenus = [
  {
    id: "portfolioSummary",
    messageKey: "portfolioSummary",
    Component: PortfolioSummary,
    children: [
      {
        id: "keyIndicator",
        messageKey: "keyIndicator",
      },
      {
        id: "overallEvaluation",
        messageKey: "overallEvaluation",
      },
    ],
  },
  {
    id: "performancePreview",
    messageKey: "performancePreview",
    Component: PerformancePreview,
    children: [
      {
        id: "incomeStatistics",
        messageKey: "incomeStatistics",
      },
      {
        id: "netWorthRetreat",
        messageKey: "netWorthRetreat",
      },
      {
        id: "rangeReturns",
        messageKey: "rangeReturns",
      },
      {
        id: "incomeDistribution",
        messageKey: "incomeDistribution",
      },
    ],
  },
  {
    id: "positionThrough",
    messageKey: "positionThrough",
    Component: PositionThrough,
    children: [
      {
        id: "positionDetail",
        messageKey: "positionDetail",
      },
      {
        id: "positionTrend",
        messageKey: "positionTrend",
      },
      {
        id: "fundDistribution",
        messageKey: "fundDistribution",
      },
    ],
  },
  {
    id: "warehouseAnalysis",
    messageKey: "warehouseAnalysis",
    Component: WarehouseAnalysis,
  },
  {
    id: "riskAnalysis",
    messageKey: "riskAnalysis",
    Component: RiskAnalysis,
    children: [
      {
        id: "correlationNalysis",
        messageKey: "correlationNalysis",
      },
      {
        id: "valueAtRisk",
        messageKey: "valueAtRisk",
      },
      {
        id: "riskDismantle",
        messageKey: "riskDismantle",
      },
    ],
  },
  {
    id: "scenarioAnalysis",
    messageKey: "scenarioAnalysis",
    Component: ScenarioAnalysis,
  },
  {
    id: "performanceAttribution",
    messageKey: "performanceAttribution",
    Component: PerformanceAttribution,
  },
];

//累计收益
export const CUMULATIVEINCOME = "cumulativeIncome";
//累计净值
export const NETCUMVALUE = "NetCumValue";

export const RANGERETURN = "rangeReturns";
export const HISTORYRETURN = "historyReturns";

export const BYDAY = "byDay";
export const BYWEEK = "byWeek";

export const getIncomeStatisticsColumns = (
  formatMessage: FormatMessageFunc
) => [
  {
    title: formatMessage("cumulativeIncomeRate"),
    dataIndex: "accumulated_yield",
    align: "center",
    render: factorsFormatter.yield,
  },
  {
    title: formatMessage("benchmarkYield"),
    dataIndex: "benchmark_yield",
    align: "center",
    render: factorsFormatter.yield,
  },
  {
    title: formatMessage("sharpeRatio"),
    dataIndex: "annual_sharpe",
    align: "center",
    render: factorsFormatter.sharpeRatio,
  },
  {
    title: formatMessage("benchmarkSharpeRatio"),
    dataIndex: "benchmark_sharpe",
    align: "center",
    render: factorsFormatter.sharpeRatio,
  },
  {
    title: formatMessage("annualizedIncome"),
    dataIndex: "annual_yield",
    align: "center",
    render: factorsFormatter.yield,
  },
  {
    title: formatMessage("annualizedActiveYield"),
    dataIndex: "active_yield",
    align: "center",
    render: factorsFormatter.yield,
  },
  {
    title: formatMessage("infoRatio"),
    dataIndex: "info_ratio",
    align: "center",
    render: factorsFormatter.informationRatio,
  },
  {
    title: formatMessage("karmaRatio"),
    dataIndex: "calmar_ratio",
    align: "center",
    render: factorsFormatter.carmerRatio,
  },
  {
    title: formatMessage("sortinoRatio"),
    dataIndex: "sortino_ratio",
    align: "center",
    render: factorsFormatter.treynorRatio,
  },
  {
    title: formatMessage("alpha"),
    dataIndex: "alpha",
    align: "center",
    render: factorsFormatter.alpha,
  },
  {
    title: formatMessage("beta"),
    dataIndex: "beta",
    align: "center",
    render: factorsFormatter.alpha,
  },
];

export const getNetWorthColumns = (formatMessage: FormatMessageFunc) => [
  {
    title: formatMessage("maxDrawdown"),
    dataIndex: "maxDrawdown",
    align: "center",
    render: factorsFormatter.maxDrawdown,
  },
  {
    title: formatMessage("benchmarkMaxDrawdown"),
    dataIndex: "benchmarkMaxDrawdown",
    align: "center",
    render: factorsFormatter.maxDrawdown,
  },
  {
    title: formatMessage("annualizedVolatility"),
    dataIndex: "annualStandardDeviation",
    align: "center",
    render: percentage,
  },
  {
    title: formatMessage("VaRValue"),
    dataIndex: "var",
    align: "center",
    render: factorsFormatter.alpha,
  },
  {
    title: formatMessage("hurstIndex"),
    dataIndex: "hurst",
    align: "center",
    render: factorsFormatter.alpha,
  },
  {
    title: formatMessage("skewness"),
    dataIndex: "skewness",
    align: "center",
    render: factorsFormatter.skewness,
  },
  {
    title: formatMessage("kurtosis"),
    dataIndex: "kurtosis",
    align: "center",
    render: factorsFormatter.kurtosis,
  },
  {
    title: formatMessage("annualizedActiveRisk"),
    dataIndex: "annualizedActiveRisk",
    align: "center",
    render: percentage,
  },
  {
    title: formatMessage("downsideVolatility"),
    dataIndex: "downsideVolatility",
    align: "center",
    render: factorsFormatter.volatility,
  },
  {
    title: "stutzer",
    dataIndex: "stutzer",
    align: "center",
    render: factorsFormatter.alpha,
  },
];
export const getColumns = (formatMessage: FormatMessageFunc) => [
  {
    columnName: formatMessage("RECENT_WEEK"),
    dataIndex: "RECENT_WEEK",
  },
  {
    columnName: formatMessage("RECENT_MONTH"),
    dataIndex: "RECENT_MONTH",
  },
  {
    columnName: formatMessage("RECENT_THREE_MONTH"),
    dataIndex: "RECENT_THREE_MONTH",
  },
  {
    columnName: formatMessage("RECENT_HALF_YEAR"),
    dataIndex: "RECENT_HALF_YEAR",
  },
  {
    columnName: formatMessage("FROM_THIS_YEAR"),
    dataIndex: "FROM_THIS_YEAR",
  },
  {
    columnName: formatMessage("RECENT_YEAR"),
    dataIndex: "RECENT_YEAR",
  },
  {
    columnName: formatMessage("RECENT_TWO_YEAR"),
    dataIndex: "RECENT_TWO_YEAR",
  },
  {
    columnName: formatMessage("RECENT_THREE_YEAR"),
    dataIndex: "RECENT_THREE_YEAR",
  },
  {
    columnName: formatMessage("RECENT_FIVE_YEAR"),
    dataIndex: "RECENT_FIVE_YEAR",
  },
  {
    columnName: formatMessage("FROM_CREATION"),
    dataIndex: "FROM_CREATION",
  },
];

export const FUND_TREND = "FUND_TREND";
export const STRATEGY_TYPE_TREND = "STRATEGY_TYPE_TREND";
