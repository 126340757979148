import { useMemoizedFn } from "ahooks";
import { Radio, RadioChangeEvent, RadioProps } from "antd";
import { map } from "lodash/fp";
import React from "react";
import style from "./index.module.less";

type RadioOptions = {
  options: {
    id: string;
    message: string;
  }[];
  onChange?: (value: string) => any;
};

export default React.memo<RadioProps & RadioOptions>(
  ({ options, onChange, ...restProps }) => {
    const onChangeHandler = useMemoizedFn(
      (e: RadioChangeEvent) => onChange && onChange(e.target.value)
    );
    return (
      <Radio.Group
        {...restProps}
        className={style.RadioGroup}
        onChange={onChangeHandler}
        buttonStyle="solid"
      >
        {map(({ id, message }) => (
          <Radio.Button key={id} value={id}>
            {message}
          </Radio.Button>
        ))(options)}
      </Radio.Group>
    );
  }
);
