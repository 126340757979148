import { useEffect, useMemo } from "react";
import { find, first, flow, forEach, forOwn } from "lodash/fp";

import { RootState } from "@/store";
import {
  OrganizationType,
  AumPortfolioType,
  AumPortfolio,
  Organization,
} from "@/model/aum";
import {
  fetchOrganizations,
  fetchAumAssetPortfolios,
  fetchAumAssetPortfolioFactor,
  fetchAumAssetPortfolioSummary,
  fetchAumAssetPortfolioIndexInfo,
  fetchPortfolioPerformanceTrend,
} from "@/store/aum";
import { fastProp } from "@/util/opt";
import { DatePeriodValue } from "@/base-components/datePeriodSelect";

import { useAppDispatch, useAppSelector } from "./redux";

export const useGetAumOrganization = (organizationType: OrganizationType) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state: RootState) => state?.aum?.organizations?.[organizationType]
  );

  useEffect(() => {
    if (!data) {
      dispatch(fetchOrganizations(organizationType));
    }
  }, [dispatch, organizationType, data]);

  return data;
};

export const useGetAumAssetPortfolio = (
  organizationId: string,
  portfolioType: AumPortfolioType
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchAumAssetPortfolios(organizationId));
    }
  }, [dispatch, organizationId]);

  const assetPortfolios = useAppSelector(
    (state: RootState) => state?.aum?.assetPortfolios?.[organizationId]
  );
  const assetPortfolio = useMemo(
    () =>
      find<AumPortfolio>((item) => item?.type === portfolioType)(
        assetPortfolios
      ),
    [assetPortfolios, portfolioType]
  );
  const defaultComType = useMemo(
    () => flow(first, fastProp("type"))(assetPortfolios),
    [assetPortfolios]
  );
  return { assetPortfolio, assetPortfolios, defaultComType };
};

export const getOrganizationName = (
  organizationsArr: Organization[],
  organizationId: string
): string => {
  let res = "";
  forOwn((o: Organization) => {
    if (fastProp("organizationId")(o) === organizationId) {
      res = fastProp("organizationName")(o);
      return false;
    }
  })(organizationsArr);
  if (res === "")
    forEach((o: Record<string, any>) => {
      res = getOrganizationName(fastProp("children")(o), organizationId);
      if (res !== "") return false;
    })(organizationsArr);
  return res;
};

export const useGetAumPortfolioFactor = (id: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) dispatch(fetchAumAssetPortfolioFactor(id));
  }, [dispatch, id]);

  return useAppSelector(
    (state: RootState) => state?.aum?.portfolioFactor?.[id]
  );
};

export const useGetAumPortfolioSummary = (id: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) dispatch(fetchAumAssetPortfolioSummary(id));
  }, [dispatch, id]);

  return useAppSelector(
    (state: RootState) => state?.aum?.portfolioSummary?.[id]
  );
};

export const useGetAumPortfolioIndexInfo = (id: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) dispatch(fetchAumAssetPortfolioIndexInfo(id));
  }, [dispatch, id]);
  return useAppSelector(
    (state: RootState) => state?.aum?.portfolioIndexInfo?.[id]
  );
};

export const useGetPortfolioPerformanceTrend = (
  portfolioId: string,
  section: DatePeriodValue
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (portfolioId)
      dispatch(fetchPortfolioPerformanceTrend({ portfolioId, section }));
  }, [dispatch, portfolioId, section]);

  return useAppSelector(
    (state: RootState) => state?.aum?.portfolioPerformanceTrend?.[section]
  );
};
