import { distributionListItem } from "@/model/compareManage";
import { PromiseType } from "@/model/promise";
import { get, post } from "@/util/axios";
import { socketHttp } from "@/util/socket";

// 获取基金财报日期
export const getFundFinancialReport = (fundIds: string[]) =>
  post("/fund/compare/report_date", fundIds);

export const getReturnFactors = () => get("/fund/compare/range_return_factors");

export const getCompareBenchmark = () => get("/fund/compare/benchmark");

export type CategoryAssetDistributionOpt = {
  fundIds: string[];
  date: string;
};
export const getCategoryAssetDistribution = ({
  fundIds,
  date,
}: CategoryAssetDistributionOpt) =>
  post(`/fund/compare/sector_weight/${date}`, fundIds);

export type StockIndustryDistributionOpt = {
  reportDate: string;
  fundIds: string[];
  sectorId: string;
};
export const getStockIndustryDistribution = (
  data: StockIndustryDistributionOpt
) => post("/fund/compare/industry_weight", data);

export const getTop10PositionStocks = ({
  fundIds,
  date,
}: CategoryAssetDistributionOpt) =>
  post(`/fund/compare/top_position/${date}`, fundIds);

export const getFundCompareReturnInfo = (data: { fundIds: string[] }) =>
  socketHttp("START_FUND_COMPARE", {
    meta: data,
  });

/**
 * 基金经理对比
 */
export const getFundManagerCompareBaseInfo = (fundIds: string[]) =>
  post("/fund/compare/manager", fundIds);

/**
 * 基金公司对比
 */
export const getFundCompanyCompareBaseInfo = (companyIds: string[]) =>
  post("/fund/compare/company", companyIds);

export const getCompareInfo = (fundIds: string[]) =>
  post("/fund/compare/get_to_compare_info", fundIds);

/**
 * 基金公司对比 分布类型
 */
export enum DISTRIBUTION_TYPE {
  /**
   * 收益分布
   */
  INCOME = "INCOME",
  /**
   * 风险分布 最大回撤
   */
  MAXDRAWN = "MAXDRAWN",
}

export type CompareDistributionOpt = {
  section: string;
  ids: string[];
  distributionType: DISTRIBUTION_TYPE;
};

export const getCompareDistribution = ({
  section,
  ids,
  distributionType,
}: CompareDistributionOpt): PromiseType<distributionListItem[]> =>
  post(
    `fund_manager/compare/index_distribution/${section}/${distributionType}`,
    ids
  );
