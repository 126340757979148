import React from "react";
import { flow, max, reduce, toArray } from "lodash/fp";
import { useCreation } from "ahooks";

import { useFormatMessage } from "@/util/formatMessage";
import {
  useGetAumPortfolioFactor,
  useGetAumPortfolioSummary,
} from "@/hooks/aum";
import { fastProp, mapIndexed } from "@/util/opt";
import { factorsFormatter, formatter } from "@/constant/factorFormatter";
import ColorNumber from "@/components/colorNumber";
import { formatPercentage } from "@/util/numberFormatter";
import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";

import style from "./index.module.less";
import {
  useGetLargeFundName,
  useGetMaxValueIndustryName,
  useGetTopThreeIndustryWeight,
} from "./hooks";

export default React.memo<{ id: string; assetPortfolioId: string }>(
  ({ assetPortfolioId, id }) => {
    const formatMessage = useFormatMessage();
    const factorOverview = useGetAumPortfolioFactor(assetPortfolioId);
    const portfolioSummary = useGetAumPortfolioSummary(id);
    const fundMap = useAppSelector(fundIdMapSelector);

    const largerPartialStock = useGetLargeFundName(
      portfolioSummary?.top10PartialStockFundWeight
    );
    const largerPartialBond = useGetLargeFundName(
      portfolioSummary?.top10PartialBondFundWeight
    );
    const maxWeightIndustryName = useGetMaxValueIndustryName(
      portfolioSummary?.top10IndustryWeight
    );
    const topThreeIndustryWeight = formatPercentage(
      useGetTopThreeIndustryWeight(portfolioSummary?.top10IndustryWeight)
    );
    const evaluationLabel = useCreation(
      () => [
        {
          title: formatMessage("indexPerfomance"),
          key: "indexPerfomance",
          content: formatMessage("indexPerfomanceContent", {
            recentYearYield: (
              <ColorNumber
                formatValue={factorsFormatter.yield}
                value={fastProp("recentYearYield")(factorOverview)}
              />
            ),
            recentYearVolatile: factorsFormatter.volatility(
              fastProp("recentYearVolatile")(factorOverview)
            ),
            recentYearSharpeRatio: factorsFormatter.sharpeRatio(
              fastProp("recentYearSharpeRatio")(factorOverview)
            ),
            recentYearDrawdown: factorsFormatter.maxDrawdown(
              fastProp("recentYearDrawdown")(factorOverview)
            ),
          }),
        },
        {
          title: formatMessage("performanceMonitor"),
          key: "performanceMonitor",
          content: formatMessage("performanceMonitorContent", {
            timePointAUM: formatter.float2(factorOverview?.timePointAUM),
            top10PartialStockFundWeight: formatPercentage(
              flow(
                toArray,
                reduce((pre: number, cur: number) => pre + cur)(0)
              )(portfolioSummary?.top10PartialStockFundWeight)
            ),
            largerPartialStock,
            largerPartialStockWeight: formatPercentage(
              max(toArray(portfolioSummary?.top10PartialStockFundWeight))
            ),
            top10PartialBondFundWeight: formatPercentage(
              flow(
                toArray,
                reduce((pre: number, cur: number) => pre + cur)(0)
              )(portfolioSummary?.top10PartialBondFundWeight)
            ),
            largerPartialBond,
            largerPartialBondWeight: formatPercentage(
              max(toArray(portfolioSummary?.top10PartialBondFundWeight))
            ),
            maxLossPartialStockFundName:
              fundMap?.[portfolioSummary?.maxLossPartialStockFund.code]?.name ||
              "--",
            maxLossPartialStockFundValue:
              (
                <ColorNumber
                  formatValue={factorsFormatter.yield}
                  value={portfolioSummary?.maxLossPartialStockFund.value}
                />
              ) || "--",
            maxLossPartialBondFundName:
              fundMap?.[portfolioSummary?.maxLossPartialBondFund.code]?.name ||
              "--",
            maxLossPartialBondFundValue:
              (
                <ColorNumber
                  formatValue={factorsFormatter.yield}
                  value={portfolioSummary?.maxLossPartialBondFund.value}
                />
              ) || "--",
          }),
        },
        {
          title: formatMessage("positionThrough"),
          key: "positionThrough",
          content: formatMessage("positionThroughContent", {
            totalStockWeight: formatPercentage(
              portfolioSummary?.totalStockWeight
            ),
            maxWeightIndustryName,
            maxWeightIndustryValue: formatPercentage(
              max(toArray(portfolioSummary?.top10IndustryWeight))
            ),
            topThreeIndustryWeight,
          }),
        },
      ],
      [
        factorOverview,
        formatMessage,
        fundMap,
        largerPartialBond,
        largerPartialStock,
        maxWeightIndustryName,
        portfolioSummary?.maxLossPartialBondFund.code,
        portfolioSummary?.maxLossPartialBondFund.value,
        portfolioSummary?.maxLossPartialStockFund.code,
        portfolioSummary?.maxLossPartialStockFund.value,
        portfolioSummary?.top10IndustryWeight,
        portfolioSummary?.top10PartialBondFundWeight,
        portfolioSummary?.top10PartialStockFundWeight,
        portfolioSummary?.totalStockWeight,
        topThreeIndustryWeight,
      ]
    );
    return (
      <div className={style.evaluationWrapper}>
        <div className={style.title}>{formatMessage("overallEvaluation")}</div>
        <div className={style.content}>
          {mapIndexed((v: { title: string; key: string; content: string }) => (
            <div className={style.evaluationContent} key={fastProp("key")(v)}>
              <div className={style.type}>{fastProp("title")(v)}</div>
              <div className={style.description}>{fastProp("content")(v)}</div>
            </div>
          ))(evaluationLabel)}
        </div>
      </div>
    );
  }
);
