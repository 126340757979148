import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import {
  COMMON_TIME,
  FROM_CREATION,
  FROM_THIS_YEAR,
  RECENT_HALF_YEAR,
  RECENT_MONTH,
  RECENT_THREE_MONTH,
  RECENT_THREE_YEAR,
  RECENT_YEAR,
  yieldStatisticRange,
} from "@/constant/statisticRange";
import { FormatMessageFunc } from "@/util/formatMessage";
import { formatPercentage, toFixedNumber } from "@/util/numberFormatter";
import { fastProp } from "@/util/opt";
import { pick, flow, toArray } from "lodash/fp";

const toFixedNumber2 = toFixedNumber(2);
const percentage = (value: number) => formatPercentage(value);

export const getYieldIndicatorsColumns = (formatMessage: FormatMessageFunc) => [
  {
    title: formatMessage("fundName"),
    dataIndex: "name",
    width: 250,
    render: (val: string, record: Record<string, any>) => {
      return <ToFundDetailPage name={val} id={fastProp("fundId")(record)} />;
    },
  },
  {
    title: formatMessage("annualYield"),
    dataIndex: "annualReturns",
    width: 150,
    align: "right",
    render: percentage,
  },
  {
    title: formatMessage("sharpeRatio"),
    dataIndex: "sharpeRatio",
    width: 150,
    align: "right",
    render: toFixedNumber2,
  },
  {
    title: formatMessage("sortinoRatio"),
    dataIndex: "sortinoRatio",
    width: 150,
    align: "right",
    render: toFixedNumber2,
  },
  {
    title: formatMessage("infoRatio"),
    dataIndex: "informationRatio",
    width: 150,
    align: "right",
    render: toFixedNumber2,
  },
  {
    title: formatMessage("alpha"),
    dataIndex: "alpha",
    width: 150,
    align: "right",
    render: toFixedNumber2,
  },
  {
    title: "",
    dataIndex: "",
  },
];

export const getRiskIndicatorsColumns = (formatMessage: FormatMessageFunc) => [
  {
    title: formatMessage("fundName"),
    dataIndex: "name",
    width: 250,
    render: (val: string, record: Record<string, any>) => {
      return <ToFundDetailPage name={val} id={fastProp("fundId")(record)} />;
    },
  },
  {
    title: formatMessage("maxDrawdown"),
    dataIndex: "maxDrawdown",
    width: 150,
    align: "right",
    render: percentage,
  },
  {
    title: formatMessage("annualizedStandardDeviation"),
    dataIndex: "annualStandardDeviation",
    width: 150,
    align: "right",
    render: percentage,
  },
  {
    title: formatMessage("downsideRisks"),
    dataIndex: "downwardVolatilty",
    width: 150,
    align: "right",
    render: percentage,
  },
  {
    title: formatMessage("beta"),
    dataIndex: "beta",
    width: 150,
    align: "right",
    render: toFixedNumber2,
  },
  {
    title: "",
    dataIndex: "",
  },
];

export const categoryNameMapping = {
  stocktonav: "stock",
  bondtonav: "fixedIncome",
  cashtonav: "bankDeposits",
  fundtonav: "funds",
  othertonav: "other",
};

export const isOther = (str: string) => str === "other";

export const brinsonRangeOptions: Record<string, any> = flow(
  pick([
    "COMMON_TIME",
    "RECENT_YEAR",
    "RECENT_THREE_YEAR",
    "RECENT_FIVE_YEAR",
    "FROM_CREATION",
  ]),
  toArray
)(yieldStatisticRange);

export const yieldAndRiskChartOptions = flow(
  pick([
    COMMON_TIME,
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    FROM_THIS_YEAR,
    RECENT_YEAR,
    RECENT_THREE_YEAR,
    FROM_CREATION,
  ]),
  toArray
)(yieldStatisticRange);

export const getIndustyPieChartOptions = (
  formatMessage: FormatMessageFunc
) => ({
  tooltip: {
    position: ["50%", "50%"],
    formatter: ({ name, value, marker }: any) => {
      return `<p style="margin: 8px 0;">${marker} ${formatMessage(
        "IndustyPieChartTip",
        { name: name, value: formatPercentage(value) }
      )} </p>`;
    },
  },
});
