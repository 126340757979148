import { useFormatMessage } from "@/util/formatMessage";
import { Card } from "antd";
import React, { useEffect } from "react";
import FundConfigurationWeightTable from "./components/fundConfigurationWeightTable";
import { useManageAllocateData } from "./hooks";
import style from "./index.module.less";
import OptimizedErrorField from "../../fundConfiguration/backTestingAllocation/components/optimizedErrorField";
import BackTestingFormComponent, {
  SaveSchemeParams,
} from "../../manualCreatePortfolio/backTestingAllocation/backTestingForm";
import BackTestingResult from "../../manualCreatePortfolio/backTestingAllocation/backTestingResult";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useManageError } from "../../fundConfiguration/backTestingAllocation/hooks";
import { useGetAssetAllocateData } from "../hooks";
import { useMemoizedFn } from "ahooks";
import { saveBackTestingScheme } from "@/store/portfolioList";

export default React.memo<{
  onError: React.Dispatch<React.SetStateAction<boolean>>;
}>(({ onError }) => {
  const formatMessage = useFormatMessage();
  const { onUpdateAssets, originFundAsset, modelPortfolioAllocateData } =
    useManageAllocateData();
  const { backTestingFormData } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation
  );
  const { error, errorField } = useManageError(
    originFundAsset,
    modelPortfolioAllocateData
  );
  useEffect(() => {
    onError(error);
  }, [error, onError]);

  const dispatch = useAppDispatch();
  const assetAllocateData = useGetAssetAllocateData();
  const onSaveScheme = useMemoizedFn((data: SaveSchemeParams) => {
    return dispatch(
      saveBackTestingScheme({
        ...data,
        allocateRequest: assetAllocateData as any,
      })
    ).unwrap();
  });
  return (
    <>
      <Card className={style.Card}>
        <div className={style.Header}>
          <h3>{formatMessage("OptionalFund")}</h3>
        </div>
        <FundConfigurationWeightTable
          originData={originFundAsset}
          data={modelPortfolioAllocateData}
          onUpdate={onUpdateAssets}
          errorField={errorField}
        />
        <OptimizedErrorField fundAsset={originFundAsset} />
      </Card>
      <BackTestingFormComponent
        data={modelPortfolioAllocateData}
        type="modelAllocation"
        disabled={error}
        onSaveScheme={onSaveScheme}
      />
      <BackTestingResult
        startDate={backTestingFormData.startDate}
        endDate={backTestingFormData.endDate}
      />
    </>
  );
});
