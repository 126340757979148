import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Space } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import {
  fetchFundManagerCompareBaseInfo,
  clearFundManagerResult,
} from "@/store/compareManage";
import { useManageComparesTarget } from "../hooks";
import SelectCompare from "../components/selectCompare";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { managerNameMapSelector } from "@/selectors/manager";
import { first, map, pullAt } from "lodash/fp";
import CompareResult from "./compareResult";
import { ManagerNameInterface } from "@/model/entities";
import { fastProp } from "@/util/opt";
import { optionsType } from "../interface";
import style from "../fundCompare/index.module.less";
import EmptyContent from "@/components/emptyContent";
import { uniqManagerIds, validateIsFundRelatedManager } from "../constant";

export type FundManagerOpt = {
  // 正常情况下的基金经理ids
  managerIds?: string[];
  // 目标选基等地方选择基金去对比后会将基金对应的基金经理信息回显
  fundRelatedManagerIds: string[][];
};

const useHandleFundRelatedManagerIds = (managerIds: string[] | string[][]) => {
  // 判断是否是基金信息回显的基金经理信息
  const isFundRelatedManager = useMemo(
    () => validateIsFundRelatedManager(managerIds),
    [managerIds]
  );

  const uniqedManagerIds = useMemo(
    () =>
      isFundRelatedManager
        ? uniqManagerIds(managerIds as string[][])
        : managerIds,
    [isFundRelatedManager, managerIds]
  );

  const defaultManagerIds = useMemo(
    () =>
      isFundRelatedManager ? map(first)(uniqedManagerIds) : uniqedManagerIds,
    [isFundRelatedManager, uniqedManagerIds]
  );

  const managerNameMap = useAppSelector(managerNameMapSelector);
  const [managerInfo, changeManagerInfo] = useState<optionsType[][]>([]);

  useEffect(() => {
    if (isFundRelatedManager) {
      const managerInfo = map<string[], ManagerNameInterface[]>((ids) =>
        map<string, ManagerNameInterface>((id) => fastProp(id)(managerNameMap))(
          ids
        )
      )(uniqedManagerIds);
      changeManagerInfo(managerInfo);
    }
  }, [isFundRelatedManager, managerNameMap, uniqedManagerIds]);
  return {
    managerIds: defaultManagerIds,
    changeManagerInfo,
    managerInfo,
  };
};

export default React.memo<FundManagerOpt>((props) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const { managerIds, changeManagerInfo, managerInfo } =
    useHandleFundRelatedManagerIds(
      props.managerIds || props.fundRelatedManagerIds
    );
  const onStart = useCallback(
    (fundManagerIds) => {
      dispatch(clearFundManagerResult());
      Promise.all([dispatch(fetchFundManagerCompareBaseInfo(fundManagerIds))]);
    },
    [dispatch]
  );
  const {
    compareIds,
    isComparing,
    onStartCompare,
    addCompareTarget,
    updateCompareTarget,
    removeCompareTarget,
    clearCompareTarget,
  } = useManageComparesTarget(onStart, managerIds);
  const onRemove = useCallback(
    (index) => {
      removeCompareTarget(index);
      changeManagerInfo(pullAt(index)(managerInfo));
    },
    [changeManagerInfo, managerInfo, removeCompareTarget]
  );
  return (
    <>
      <Card size="small" bordered={false} className={style.AddCard}>
        <Space direction="vertical" size={20}>
          <Row>
            <Col>
              <h4>{formatMessage("addFundManager")}</h4>
            </Col>
            <Col className={style.TextColor}>
              &nbsp;(
              {formatMessage("maxCompareFundManagerTip", {
                count: 4,
              })}
              )
            </Col>
          </Row>
          <SelectCompare
            compares={compareIds}
            onChange={updateCompareTarget}
            onAdd={addCompareTarget}
            onClear={clearCompareTarget}
            onRemove={onRemove}
            onStart={onStartCompare}
            buttonDisabled={isComparing}
            data={managerInfo}
            type="managerName"
          />
        </Space>
      </Card>
      {isComparing ? (
        <CompareResult managerIds={compareIds} />
      ) : (
        <EmptyContent
          className={style.Empty}
          message={formatMessage("noData")}
        />
      )}
    </>
  );
});
