import { Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import { useContext, useMemo, useState } from "react";
import type { TableProps } from "antd";
import ColorNumber from "@/components/colorNumber";
import { assetPortfolios } from "@/model/portfolioList";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import style from "./index.module.less";

export const portfolioIndex = [
  "dailyRiseAndFall",
  "Fund_Total_Yield",//成立以来累计收益
  "Fund_Return_Annual",//历史年化收益
  "Fund_Vol_Annual",//历史年化波动率
  "Fund_Max_Drawdown",//最大回撤
  "Fund_Sharpe_Ratio",//夏普比率
  "foundDate",
  "historyEnd",
];

export const useGetPortfolioColumns = () => {
  const formatMessage = useFormatMessage();
  const { goToPortfolioAnalysis } = useContext(PlatformNavigationContext);
  return useMemo<ColumnType<any>[]>(
    () => [
      {
        title: formatMessage("assetName"),
        dataIndex: "name",
        ellipsis: true,
        render: (name: string, record: Record<string, any>) => (
          <Tooltip title={name}>
            <span
              className={style.Link}
              onClick={() =>
                goToPortfolioAnalysis({ id: fastProp("id")(record) })
              }
            >
              {name}
            </span>
          </Tooltip>
        ),
      },
      {
        title: formatMessage("dailyYieldReturn"),
        dataIndex: "dailyRiseAndFall",
        render: (text: number) => (
          <ColorNumber value={text} formatter="percentage" />
        ),
      },
      {
        title: formatMessage("fromCreateAccumulateReturn"),
        dataIndex: "Fund_Total_Yield",
        render: (text: number) => (
          <ColorNumber value={text} formatter="percentage" />
        ),
      },
      {
        title: formatMessage("fromCreateAnnualReturn"),
        dataIndex: "Fund_Return_Annual",
        render: (text: number) => (
          <ColorNumber value={text} formatter="percentage" />
        ),
      },
      {
        title: formatMessage("fromCreateAnnualVolatility"),
        dataIndex: "Fund_Vol_Annual",
        render: (text: number) => (
          <ColorNumber value={text} formatter="percentage" />
        ),
      },
      {
        title: formatMessage("fromCreateMaxDrawl"),
        dataIndex: "Fund_Max_Drawdown",
        render: (text: number) => (
          <ColorNumber value={text} formatter="percentage" />
        ),
      },
      {
        title: formatMessage("fromCreateSharpe"),
        dataIndex: "Fund_Sharpe_Ratio",
        render: (text: number) => (
          <ColorNumber value={text} formatter="percentage" />
        ),
      },
      {
        title: formatMessage("foundDate"),
        dataIndex: "foundDate",
      },
      {
        title: formatMessage("dataUpdateDate"),
        dataIndex: "historyEnd",
      },
    ],
    [formatMessage, goToPortfolioAnalysis]
  );
};

export type portfolioTableProps = TableProps<assetPortfolios>;

export function useGetRowSelection(): [
  string[],
  portfolioTableProps["rowSelection"]
] {
  const [selectedPortfolioId, setSelectedPortfolioId] = useState<string[]>([]);
  return [
    selectedPortfolioId,
    useMemo(
      () => ({
        type: "radio",
        hideOnSinglePage: false,
        onChange(selectedRowKeys: React.Key[]) {
          setSelectedPortfolioId(selectedRowKeys as string[]);
        },
      }),
      [setSelectedPortfolioId]
    ),
  ];
}
