import { identity } from "lodash/fp";
import { createContext } from "react";

export type AnalysisType = "SIMULATION" | "BACK_TEST" | string;

export const portfolioAnalysisConfig = {
  SIMULATION: "SIMULATION",
  BACK_TEST: "BACK_TEST",
  isSimulation(type: string) {
    return type === this.SIMULATION;
  },
  isBackTest(type: string) {
    return type === this.BACK_TEST;
  },
};

export interface PortfolioAnalysisContext {
  runningTime: number;
  dispatch: (needTime: number, event: any) => void;
  analysisType: AnalysisType;
}

export default createContext<PortfolioAnalysisContext>({
  runningTime: 0,
  dispatch: identity,
  analysisType: portfolioAnalysisConfig.SIMULATION as AnalysisType,
});
