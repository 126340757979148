import { ReactComponent as compareManage } from "./compareManage.svg";
import { ReactComponent as compareManageActive } from "./compareManageActive.svg";
import { ReactComponent as dataBoardActive } from "./dataBoardActive.svg";
import { ReactComponent as dataBoard } from "./dataBoard.svg";
import { ReactComponent as factorFund } from "./factorFund.svg";
import { ReactComponent as factorFundActive } from "./factorFundActive.svg";
import { ReactComponent as fundCompanyDetails } from "./fundCompany.svg";
import { ReactComponent as fundCompanyDetailsActive } from "./fundCompanyActive.svg";
import { ReactComponent as fundDetails } from "./fundDetails.svg";
import { ReactComponent as fundDetailsActive } from "./fundDetailsActive.svg";
import { ReactComponent as fundManagerDetails } from "./fundManager.svg";
import { ReactComponent as fundManagerDetailsActive } from "./fundManagerActive.svg";
import { ReactComponent as mockPortfolio } from "./mockPortfolio.svg";
import { ReactComponent as mockPortfolioActive } from "./mockPortfolioActive.svg";
import { ReactComponent as newsSearch } from "./newSearch.svg";
import { ReactComponent as newsSearchActive } from "./newSearchActive.svg";
import { ReactComponent as replaceFund } from "./replaceFund.svg";
import { ReactComponent as replaceFundActive } from "./replaceFundActive.svg";
import { ReactComponent as stockBackCheck } from "./stockBackCheck.svg";
import { ReactComponent as stockBackCheckActive } from "./stockBackCheckActive.svg";
import { ReactComponent as targetFund } from "./targetFund.svg";
import { ReactComponent as targetFundActive } from "./targetFundActive.svg";
import { ReactComponent as home } from "./home.svg";
import { ReactComponent as homeActive } from "./homeActive.svg";
import { ReactComponent as readFund } from "./readFund.svg";
import { ReactComponent as readFundActive } from "./readFundActive.svg";
import { ReactComponent as searchFund } from "./searchFund.svg";
import { ReactComponent as searchFundActive } from "./searchFundActive.svg";
import { ReactComponent as fundConfiguration } from "./fundConfiguration.svg";
import { ReactComponent as fundConfigurationActive } from "./fundConfigurationActive.svg";
import { ReactComponent as preferredFund } from "./preferredFund.svg";
import { ReactComponent as preferredFundActive } from "./preferredFundActive.svg";
import { ReactComponent as myOptional } from "./myOptional.svg";
import { ReactComponent as myOptionalActive } from "./myOptionalActive.svg";
import { ReactComponent as competition } from "./competition.svg";
import { ReactComponent as competitionActive } from "./competitionActive.svg";
import { ReactComponent as aum } from "./aum.svg";
import { ReactComponent as aumActive } from "./aumActive.svg";
import { ReactComponent as customerManage } from "./customerManage.svg";
import { ReactComponent as customerManageActive } from "./customerManageActive.svg";
import { ReactComponent as recommendedFund } from "./recommendedFund.svg";
import { ReactComponent as recommendedFundActive } from "./recommendedFundActive.svg";
import { ReactComponent as workbench } from "./workbench.svg";
import { ReactComponent as workbenchActive } from "./workbenchActive.svg";
import { ReactComponent as configurationManage } from "./configurationManage.svg";
import { ReactComponent as configurationManageActive } from "./configurationManageActive.svg";
import { ReactComponent as assetInformation } from "./assetInformation.svg";
import { ReactComponent as assetInformationActive } from "./assetInformationActive.svg";
import { ReactComponent as assetComparison } from "./assetComparison.svg";
import { ReactComponent as assetComparisonActive } from "./assetComparisonActive.svg";

const icons: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  compareManage,
  compareManageActive,
  dataBoard,
  dataBoardActive,
  factorFund,
  factorFundActive,
  fundCompanyDetails,
  fundCompanyDetailsActive,
  fundDetails,
  fundDetailsActive,
  fundManagerDetails,
  fundManagerDetailsActive,
  mockPortfolio,
  mockPortfolioActive,
  newsSearch,
  newsSearchActive,
  replaceFund,
  replaceFundActive,
  stockBackCheck,
  stockBackCheckActive,
  targetFund,
  targetFundActive,
  home,
  readFund,
  searchFund,
  fundConfiguration,
  homeActive,
  readFundActive,
  searchFundActive,
  fundConfigurationActive,
  preferredFund,
  preferredFundActive,
  myOptional,
  myOptionalActive,
  competition,
  competitionActive,
  aum,
  aumActive,
  customerManage,
  customerManageActive,
  recommendedFund,
  recommendedFundActive,
  workbench,
  workbenchActive,
  configurationManage,
  configurationManageActive,
  assetInformationActive,
  assetInformation,
  assetComparison,
  assetComparisonActive,
};
export default icons;
