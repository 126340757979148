/**
 * 信息比
 */
import { flow, isEmpty } from "lodash/fp";
import { toAverage, toStandardDeviation, div, times } from "../math";
import tradingDateCount from "@/constant/tradingDateCount";
import { equalZero, formatArrayNilToZero } from "../numberFormatter";

export default (dailyActiveReturns: number[]) => {
  if (isEmpty(dailyActiveReturns)) return 0;
  const formatDailyActiveReturns = formatArrayNilToZero(dailyActiveReturns);
  const standardDeviation = toStandardDeviation(formatDailyActiveReturns);
  if (equalZero(standardDeviation)) return NaN;
  return (
    flow(
      toAverage,
      div(standardDeviation),
      times(Math.sqrt(tradingDateCount))
    )(formatDailyActiveReturns) || 0
  );
};
