import { getETFFundFilterList, getETFTagsAndFactor } from "@/api/eftFundFilter";
import {
  ETFfFundFilter,
  ETFFundFilterParam,
  ETFTagFactorsProp,
} from "@/model/etfFilter";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { isEmpty } from "lodash/fp";

export const fetchETFFundList = createAsyncThunk(
  "etfFundFilter/etfFundList",
  async (param: ETFFundFilterParam) => {
    const response = await getETFFundFilterList(param);
    return response;
  }
);

export const fetchETFFundTagFactors = avoidMultiRequestActionThunk<void>(
  () => `etfFundFilter.etfFundTagFactors`,
  createAsyncThunk("etfFundFilter/etfFundTagFactors", async () => {
    const response = await getETFTagsAndFactor();
    return response;
  })
);

export const creatETFFilterListState: ETFFundFilterParam = {
  featureFactors: [],
  filterTags: [],
  generalFactorTags: [],
  evaluationDimensionTags: [],
};

type etfFundFilterSliceState = {
  // etfFundList: Record<string, ETFfFundFilter[]>;
  tagCategory: string;
  etfFundList: ETFfFundFilter[];
  etfFundTagFactors: ETFTagFactorsProp[];
  etfFilter: ETFFundFilterParam;
  oldETFFilter: ETFFundFilterParam;
};

const initialState: etfFundFilterSliceState = {
  tagCategory: "",
  etfFundList: [],
  etfFundTagFactors: [],
  etfFilter: creatETFFilterListState,
  oldETFFilter: creatETFFilterListState,
};

const etfFilterSlice = createSlice({
  name: "etfFundFilter",
  initialState,
  reducers: {
    setETFFilter: (state: etfFundFilterSliceState, action) => {
      state.etfFilter = action.payload;
    },
    updateOldETFFilter: (state: etfFundFilterSliceState, action) => {
      state.oldETFFilter = action.payload;
    },
    updateTagCategory: (state: etfFundFilterSliceState, action) => {
      state.tagCategory = action.payload;
    },
  },
  extraReducers: {
    [fetchETFFundList.fulfilled.type]: (state, action) => {
      state.etfFundList = action.payload;
    },
    [fetchETFFundTagFactors.fulfilled.type]: (state, action) => {
      state.etfFundTagFactors = action.payload;
    },
  },
});

export const { setETFFilter, updateOldETFFilter, updateTagCategory } =
  etfFilterSlice.actions;

export default etfFilterSlice.reducer;
