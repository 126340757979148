import { DailyReturns } from "@/api/dailyReturns";
import { benchmarksIdMapSelector } from "@/selectors/benchmarks";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import { fastProp } from "@/util/opt";
import { normalizeDailyReturns } from "@/util/transformer";
import { prop } from "lodash/fp";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "./redux";

export const useGetBenchmarkDailyReturn = (benchmarkId: string) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (benchmarkId) {
      dispatch(fetchFundAndBenchmarkDailyReturns(benchmarkId));
    }
  }, [benchmarkId, dispatch]);
  const benchmarksIdMap = useAppSelector(benchmarksIdMapSelector);
  const dailyReturns = useAppSelector((state) => state.dailyReturns);
  const benchmarkDailyReturns = useMemo<DailyReturns>(
    () => fastProp(benchmarkId)(dailyReturns),
    [benchmarkId, dailyReturns]
  );
  const benchmarkName = prop(`${benchmarkId}.name`)(benchmarksIdMap);
  return useMemo(
    () => ({
      ...normalizeDailyReturns(
        benchmarkDailyReturns?.dates,
        benchmarkDailyReturns?.dailyReturns
      ),
      name: benchmarkName,
    }),
    [
      benchmarkDailyReturns?.dailyReturns,
      benchmarkDailyReturns?.dates,
      benchmarkName,
    ]
  );
};
