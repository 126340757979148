import React, { useEffect } from "react";
import { Button, Card, Space } from "antd";
import { useCreation, useMemoizedFn } from "ahooks";
import { PlusOutlined } from "@ant-design/icons";
import { isEmpty, map } from "lodash/fp";
import SelectFundDialog from "@/components/portfolioCompoents/selectFundDialog";
import TradingDatePicker from "@/components/tradingDatePicker";
import EmptyContent from "@/components/emptyContent";
import ErrorField from "../components/errorField";
import style from "./index.module.less";
import type { ManualCreatePortfolio } from "../constant";
import FundWeightTable from "../components/fundWeightTable";
import { Updater, useGenerateAsset } from "../hooks";
import { checkDisabledRangeDate, addFundWeights } from "../constant";

export default React.memo<{
  className?: string;
  positionData: ManualCreatePortfolio | undefined;
  onUpdate: (key: string) => (updater: Updater) => any;
  firstChangingPositionDate: string | null;
  defaultIds: string[];
}>(
  ({
    className,
    positionData,
    onUpdate,
    firstChangingPositionDate,
    defaultIds,
  }) => {
    const fundIds = useCreation(
      () => map("fundId")(positionData?.weights),
      [positionData?.weights]
    );
    const generateAsset = useGenerateAsset();
    const onChangeFundIds = useMemoizedFn((ids: string[]) =>
      onUpdate("weights")(addFundWeights(generateAsset, ids))
    );
    useEffect(() => {
      if (!isEmpty(defaultIds)) {
        onChangeFundIds(defaultIds);
      }
    }, [defaultIds, onChangeFundIds]);
    const disabledDate = useMemoizedFn(
      checkDisabledRangeDate(null, firstChangingPositionDate)
    );
    return (
      <Card
        title="建仓"
        className={className}
        extra={
          <Space size={12}>
            <Space>
              {positionData?.errorField.dateEmptyError && (
                <ErrorField message="未设置日期" />
              )}
              <p>建仓日期：</p>
              <TradingDatePicker
                value={positionData?.turnoverDate}
                className={
                  positionData?.errorField.dateEmptyError
                    ? style.ErrorPicker
                    : ""
                }
                onChange={onUpdate("turnoverDate")}
                disabledDate={disabledDate}
              />
            </Space>
            <SelectFundDialog fundIds={fundIds} onChange={onChangeFundIds}>
              {(onChangeVisible) => (
                <Button
                  onClick={onChangeVisible}
                  className={style.ShadowButton}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  添加基金
                </Button>
              )}
            </SelectFundDialog>
          </Space>
        }
      >
        {positionData && fundIds.length ? (
          <FundWeightTable
            editable
            dataSource={positionData}
            onChange={onUpdate}
          />
        ) : (
          <EmptyContent message="还未建仓，先添加基金进行建仓" />
        )}
      </Card>
    );
  }
);
