import React, { ReactElement } from "react";
import { Button } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { useFundManagerOptionalService } from "@/views/myOptional/fundManagerOptional/hooks";
import { CollectionIcon, CollectionFillIcon } from "@/components/icons";
import { AddOptionalProp } from "./fundAddOptional";

function AddOptional({
  className = "",
  id = "",
  shape = "",
  size = "small",
}: AddOptionalProp): ReactElement {
  const { isOptionaled, cancel, add } = useFundManagerOptionalService();
  const formatMessage = useFormatMessage();
  if (isOptionaled(id)) {
    if (shape === "icon") {
      return (
        <Button
          icon={<CollectionFillIcon style={{ fontSize: 16 }} />}
          type="primary"
          shape="circle"
          className={className}
          onClick={() => {
            cancel(id);
          }}
          size={size}
          ghost
        />
      );
    }
    return (
      <Button
        icon={<CollectionFillIcon style={{ fontSize: 16 }} />}
        type="primary"
        ghost
        className={className}
        onClick={() => {
          cancel(id);
        }}
      >
        {formatMessage("cancelSelect")}
      </Button>
    );
  }

  if (shape === "icon") {
    return (
      <Button
        icon={<CollectionIcon style={{ fontSize: 16 }} />}
        shape="circle"
        className={className}
        onClick={() => {
          add(id);
        }}
        size={size}
      />
    );
  }

  return (
    <Button
      icon={<CollectionIcon style={{ fontSize: 16 }} />}
      className={className}
      onClick={() => {
        add(id);
      }}
    >
      {formatMessage("addUseSelect")}
    </Button>
  );
}

export default React.memo(AddOptional);
