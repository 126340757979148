import React, { useCallback, useEffect } from "react";
import { Row, Col, Card, Space } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import {
  fetchReturnFactors,
  fetchFundCompareReturnInfo,
  fetchFundFinancialReport,
  clearFundCompareResult,
} from "@/store/compareManage";
import { useAppDispatch } from "@/hooks/redux";
import SelectCompare from "../components/selectCompare";
import CompareResult from "./compareResult";
import { useManageComparesTarget } from "../hooks";
import style from "./index.module.less";
import EmptyContent from "@/components/emptyContent";
export interface FundCompareOpt {
  fundIds?: string[];
}
export default React.memo<FundCompareOpt>((props) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const onStart = useCallback(
    (fundIds) => {
      dispatch(clearFundCompareResult());
      Promise.all([
        dispatch(fetchFundFinancialReport(fundIds)),
        dispatch(fetchFundCompareReturnInfo(fundIds)),
      ]);
    },
    [dispatch]
  );
  const {
    compareIds,
    isComparing,
    onStartCompare,
    addCompareTarget,
    updateCompareTarget,
    removeCompareTarget,
    clearCompareTarget,
  } = useManageComparesTarget(onStart, props.fundIds);
  useEffect(() => {
    dispatch(fetchReturnFactors());
  }, [dispatch]);
  return (
    <>
      <Card size="small" bordered={false} className={style.AddCard}>
        <Space direction="vertical" size={20}>
          <Row>
            <Col>
              <h4>{formatMessage("addFund")}</h4>
            </Col>
            <Col className={style.TextColor}>
              &nbsp;(
              {formatMessage("maxCompareFundTip", {
                count: 4,
              })}
              )
            </Col>
          </Row>
          <SelectCompare
            compares={compareIds}
            onChange={updateCompareTarget}
            onAdd={addCompareTarget}
            onClear={clearCompareTarget}
            onRemove={removeCompareTarget}
            onStart={onStartCompare}
            buttonDisabled={isComparing}
            type="funds"
          />
        </Space>
      </Card>
      {isComparing ? (
        <CompareResult fundIds={compareIds} />
      ) : (
        <EmptyContent
          className={style.Empty}
          message={formatMessage("noData")}
        />
      )}
    </>
  );
});
