import React, { useContext, useMemo } from "react";
import PortfolioAnalysisSubtitle from "../../components/portfolioAnalysisSubtitle";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { Space, Table } from "antd";
import style from "../../index.module.less";
import { map, prop, forEach, flow, last, concat, keys, maxBy } from "lodash/fp";
import { fastProp } from "@/util/opt";
import CustomStaticRange from "../../components/customStaticRange";
import { getNetWorthColumns } from "../../constant";
import SummaryCard from "../../components/summaryCard";
import { useAppSelector } from "@/hooks/redux";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { formatNilToZero } from "@/util/numberFormatter";
import getAnnualVolatility from "@/util/quant/annualVolatility";
import { fundIdMapSelector } from "@/selectors/fund";
import CumulativeChart from "@/components/cumulativeChart";
import { formatPercentage } from "@/util/numberFormatter";
import cumulativeReturns from "@/util/quant/cumulativeReturns";
import { useGetFactors } from "../../hooks";
import { analysisBasicInfoSelector } from "../../selectors";
import { getGridTopOptions } from "../constant";
import { RootState } from "@/store";
import ErrorBoundary from "@/components/errorBoundary";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import { portfolioStaticRange } from "./IncomeStatistics";
import { RangeInterface } from "@/constant/statisticRange";
import { errorValidator } from "@/components/errorBoundary/constant";
import { useGetCumulativeChartCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";

const needTime = 1;
const percentage = (value: number) => formatPercentage(value);

const factors = [
  "maxDrawdown",
  "benchmarkMaxDrawdown",
  "annualVolatility",
  "skewness",
  "kurtosis",
  "var",
  "annualizedActiveRisk",
  "downwardVolatilty",
  "stutzer",
  "hurst",
];
export const useGetDatesAndNetWorth = (
  id: string,
  netRange: string,
  formatMessage: FormatMessageFunc
) => {
  const portfolioSummary = useAppSelector((state: RootState) =>
    analysisBasicInfoSelector(state, id)
  );
  const dailyReturns = useAppSelector(prop("dailyReturns"));
  const {
    tradingDate,
    dailyReturn,
    benchmark,
    startDate,
    endDate,
    fundSectionMaxDrawdown,
    portfolioName,
  } = portfolioSummary;
  const getCumulativeChartCalculateRangeDate =
    useGetCumulativeChartCalculateRangeDate();
  const calculatedDates = getCumulativeChartCalculateRangeDate(
    tradingDate,
    netRange
  );
  const fundsMap = useAppSelector(fundIdMapSelector);

  //业绩基准
  const performanceBenchmarkReturn = useMemo(
    () => fastProp(benchmark)(dailyReturns),
    [benchmark, dailyReturns]
  );

  const dailyReturnsMap = useMemo(
    () => normalizeDailyReturnsMap(tradingDate, dailyReturn),
    [tradingDate, dailyReturn]
  );

  const performanceBenchmarkReturnMap = useMemo(
    () =>
      normalizeDailyReturnsMap(
        fastProp("dates")(performanceBenchmarkReturn),
        fastProp("dailyReturns")(performanceBenchmarkReturn)
      ),
    [performanceBenchmarkReturn]
  );
  const [combinationInfo, performanceBenchmarkInfo] = useMemo(
    () => [
      {
        id: "combinationInfo",
        name: portfolioName,
        color: "#1A7FFF",
        dailyReturns: dailyReturn,
        dailyReturnsMap: dailyReturnsMap,
      },
      {
        id: "performanceBenchmarkInfo",
        name: formatMessage("performanceBenchmark"),
        color: "#FFA62A",
        dailyReturns: fastProp("dailyReturns")(performanceBenchmarkReturn),
        dailyReturnsMap: performanceBenchmarkReturnMap,
      },
    ],
    [
      dailyReturn,
      dailyReturnsMap,
      performanceBenchmarkReturn,
      performanceBenchmarkReturnMap,
      formatMessage,
      portfolioName,
    ]
  );

  const factorValues = useGetFactors(id, netRange, factors);
  const netData = useMemo(() => {
    const dayReturns: number[] = [];
    const benchmarkReturns: number[] = [];
    forEach((date: string) => {
      dayReturns.push(formatNilToZero(fastProp(date)(dailyReturnsMap)));
      benchmarkReturns.push(
        formatNilToZero(fastProp(date)(performanceBenchmarkReturnMap))
      );
    })(calculatedDates);

    const maxDrawdown = fastProp("maxDrawdown")(factorValues);
    const benchmarkMaxDrawdown = fastProp("benchmarkMaxDrawdown")(factorValues);
    const annualVolatility = fastProp("annualVolatility")(factorValues);

    const tableData = [
      {
        id: "factorData",
        maxDrawdown: maxDrawdown,
        benchmarkMaxDrawdown: fastProp("benchmarkMaxDrawdown")(factorValues),
        annualStandardDeviation: annualVolatility,
        var: fastProp("var")(factorValues),
        hurst: fastProp("hurst")(factorValues),
        skewness: fastProp("skewness")(factorValues),
        kurtosis: fastProp("kurtosis")(factorValues),
        annualizedActiveRisk: fastProp("annualizedActiveRisk")(factorValues),
        downsideVolatility: fastProp("downwardVolatilty")(factorValues),
        stutzer: fastProp("stutzer")(factorValues),
      },
    ];

    const amplitudeJudgment =
      maxDrawdown > benchmarkMaxDrawdown
        ? formatMessage("greaterThan")
        : formatMessage("lessThan");
    const controlAbility: number = maxDrawdown - benchmarkMaxDrawdown;
    const controlType =
      controlAbility < -0.1
        ? formatMessage("excellent")
        : controlAbility > 0.1
        ? formatMessage("poor")
        : formatMessage("moderate");
    const maxSubDrawdown = flow(
      fastProp(netRange),
      keys,
      map((item: string) => ({
        fundId: item,
        value: prop(`${netRange}.${item}`)(fundSectionMaxDrawdown),
      })),
      maxBy("value")
    )(fundSectionMaxDrawdown);
    const maxDrawdownName = prop(
      `${maxSubDrawdown ? maxSubDrawdown.fundId : ""}.name`
    )(fundsMap);
    const maxDrawdownValue = percentage(
      maxSubDrawdown ? maxSubDrawdown.value : ""
    );
    const annualizedTotalRisk = flow(
      getAnnualVolatility,
      percentage
    )(dailyReturn);
    const benchmarkIncome = flow(cumulativeReturns, last)(benchmarkReturns);
    const riskLevel =
      annualVolatility > (benchmarkIncome as number) * 1.25
        ? formatMessage("higher")
        : formatMessage("commonly");
    return {
      tableData,
      annualVolatility: percentage(annualVolatility),
      maxDrawdown: percentage(maxDrawdown),
      amplitudeJudgment,
      controlType,
      maxDrawdownName,
      maxDrawdownValue,
      annualizedTotalRisk,
      riskLevel,
    };
  }, [
    calculatedDates,
    fundsMap,
    dailyReturn,
    dailyReturnsMap,
    performanceBenchmarkReturnMap,
    factorValues,
    fundSectionMaxDrawdown,
    netRange,
    formatMessage,
  ]);

  const combinationAndBenchmarkInfo = useMemo(
    () => concat(combinationInfo)(performanceBenchmarkInfo),
    [combinationInfo, performanceBenchmarkInfo]
  );
  const chartData = useMemo(
    () =>
      map(({ name, dailyReturnsMap, color }) => ({
        name,
        color,
        dailyReturns: map<string, [string, number]>((date) => [
          date,
          formatNilToZero(fastProp(date)(dailyReturnsMap)),
        ])(calculatedDates),
      }))(combinationAndBenchmarkInfo),
    [combinationAndBenchmarkInfo, calculatedDates]
  );
  return { netData, chartData, dates: calculatedDates, startDate, endDate };
};

export default React.memo<{
  id: string;
  netRange: string;
  onChange: (key: string) => (value: any) => any;
}>(({ id, netRange, onChange }) => {
  const formatMessage = useFormatMessage();

  const tablecolumns = useMemo(
    () => getNetWorthColumns(formatMessage),
    [formatMessage]
  );

  const { netData, chartData, dates, startDate, endDate } =
    useGetDatesAndNetWorth(id, netRange, formatMessage);
  const {
    tableData,
    annualVolatility,
    maxDrawdown,
    amplitudeJudgment,
    controlType,
    maxDrawdownName,
    maxDrawdownValue,
    annualizedTotalRisk,
    riskLevel,
  } = netData;
  const { runningTime } = useContext(PortfolioAnalysisContext);
  const GridTopOptions = useMemo(() => getGridTopOptions(percentage), []);
  return (
    <>
      <PortfolioAnalysisSubtitle name={formatMessage("netWorthRetreat")} />
      <ErrorBoundary errKey={errorValidator.lessOneDay(needTime, runningTime)}>
        <div className={style.IncomeSelect}>
          <Space direction="vertical" className={style.fullWidth}>
            <div className={style.incomeSelectPart}>
              <span>{formatMessage("statisticalnterval")}</span>
              <CustomStaticRange
                className={style.StatisticRange}
                value={netRange}
                onChange={onChange("netRange")}
                startDate={startDate}
                endDate={endDate}
                staticRange={
                  portfolioStaticRange as Record<string, RangeInterface>
                }
              />
            </div>
          </Space>
        </div>
        <Space direction="vertical" className={style.fullWidth}>
          <div className={style.CumulativeChart}>
            <div className={style.CumulativeChartTip}>
              <Table
                dataSource={tableData}
                columns={tablecolumns as any}
                pagination={false}
                bordered={true}
                rowKey="id"
                tableLayout="fixed"
                className={style.Table}
              />
            </div>
            <CumulativeChart
              dates={dates}
              dataSource={chartData}
              type="maxDrawdown"
              options={GridTopOptions}
            />
            <SummaryCard
              summaryText={formatMessage(
                "portfolioAnalysisEquityBacktestSummary",
                {
                  annualizedTotalRisk: annualizedTotalRisk,
                  riskLevel: riskLevel,
                  annualizedVolatility: annualVolatility,
                  maxDrawdown: maxDrawdown,
                  amplitudeBenchmarkComparison: amplitudeJudgment,
                  drawdownControlAbilityType: controlType,
                  maxDrawdownName: maxDrawdownName,
                  drawdownValue: maxDrawdownValue,
                }
              )}
            />
          </div>
        </Space>
      </ErrorBoundary>
    </>
  );
});
