import React, { useEffect, useState } from "react";
import { Radio, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { first, flow, last, map, pick, toArray } from "lodash/fp";
import { useCreation } from "ahooks";

import {
  useGetAumOrganization,
  getOrganizationName,
  useGetAumPortfolioIndexInfo,
  useGetPortfolioPerformanceTrend,
} from "@/hooks/aum";
import { useFormatMessage } from "@/util/formatMessage";
import {
  DatePeriodPicker,
  DatePeriodValue,
} from "@/base-components/datePeriodSelect";
import { SeriesOption, YieldTrendChart } from "@/base-components/charts";
import { getPortfolioPerformance, getPortfolioSectionReturns } from "@/api/aum";
import { fastProp, mapIndexed } from "@/util/opt";
import BenchmarkSelect from "@/components/benchmarkSelect";
import performancePreviewIcon from "@/assets/portfolioAnalysisIcons/performancePreview.png";
import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import { formatPercentage } from "@/util/numberFormatter";
import { ValueType } from "@/base-components/charts/yieldTrendChart/type";
import { CSI300_ID } from "@/views/compareManage/constant";
import { useGetBenchmarkDailyReturn } from "@/hooks/benchmark";
import { getSlicedDateReturnsAndRangeDates } from "@/util/business-core/dailyReturn";
import { DatePeriodName } from "@/util/business-core/datePeriod";
import { useAppSelector } from "@/hooks/redux";
import ColorNumber from "@/components/colorNumber";
import { benchmarkMapSelector } from "@/selectors/benchmarks";
import { TradingDates } from "@/util/business-core/tradingDate";
import { factorsFormatter } from "@/constant/factorFormatter";
import { useRequest } from "@/hooks/request";
import { useGetStatisticRange } from "@/hooks/statisticRange";
import {
  FROM_CREATION,
  RECENT_HALF_YEAR,
  RECENT_MONTH,
  RECENT_THREE_MONTH,
  RECENT_THREE_YEAR,
  RECENT_WEEK,
  RECENT_YEAR,
  statisticRange,
} from "@/constant/statisticRange";
import { BENCHMARK_COLOR } from "@/util/colors";
import { colors } from "@/base-components/charts/helper/colors";

import style from "./index.module.less";
import PeriodTypePicker, { PeriodTypeValue } from "./periodTypePicker";
import { CommonProps } from "../../type";

export type SectionReturnType = {
  portfolio: Array<{ section: string; value: number }>;
  benchmark: Array<{ section: string; value: number }>;
};

export type TrendDataType = {
  benchmarkReturns: number[];
  dates: TradingDates;
  portfolioNetValues: number[];
  portfolioReturns: number[];
};

const datePeriodOptions = flow(
  pick([
    RECENT_WEEK,
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    RECENT_YEAR,
    RECENT_THREE_YEAR,
    FROM_CREATION,
  ]),
  toArray
)(statisticRange);

export default React.memo<CommonProps>(
  ({ id, assetPortfolioId, managerName }) => {
    const organizations = useGetAumOrganization("SELECTOR");
    const formatMessage = useFormatMessage();
    const organizationName =
      getOrganizationName(organizations, id) || managerName || "";
    const benchmarkMap = useAppSelector(benchmarkMapSelector);

    const [scaleRange, setScaleRange] =
      useState<DatePeriodValue>("FROM_CREATION");
    const trendData: TrendDataType = useGetPortfolioPerformanceTrend(
      assetPortfolioId,
      "FROM_CREATION"
    );
    const [benchmarkId, setBenchmarkId] = useState(CSI300_ID);
    const [periodType, setPeriodType] = useState<PeriodTypeValue>("SECTION");
    const indexInfo = useGetAumPortfolioIndexInfo(assetPortfolioId);
    const benchmark = useCreation(
      () => benchmarkMap[benchmarkId],
      [benchmarkId, benchmarkMap]
    );

    const {
      data: portfolioSectionReturns,
      request: fetchPortfolioSectionReturns,
    } = useRequest<
      { portfolioId: string; periodType: PeriodTypeValue },
      SectionReturnType
    >(getPortfolioSectionReturns);

    useEffect(() => {
      if (
        assetPortfolioId &&
        benchmark?.code &&
        indexInfo?.historyEnd &&
        indexInfo?.historyStart &&
        periodType
      ) {
        fetchPortfolioSectionReturns({
          portfolioId: assetPortfolioId,
          periodType,
        });
      }
    }, [
      assetPortfolioId,
      benchmark?.code,
      fetchPortfolioSectionReturns,
      indexInfo?.historyEnd,
      indexInfo?.historyStart,
      periodType,
    ]);

    const { data: portfolioPerformance, request: fetchPortfolioPerformance } =
      useRequest<
        {
          portfolioId: string;
          section: DatePeriodValue;
        },
        Record<string, Record<string, number | null>>
      >(getPortfolioPerformance);

    useEffect(() => {
      if (assetPortfolioId && scaleRange) {
        fetchPortfolioPerformance({
          portfolioId: assetPortfolioId,
          section: scaleRange,
        });
      }
    }, [assetPortfolioId, fetchPortfolioPerformance, scaleRange]);

    const trendTypeOptions: Array<{ title: string; value: ValueType }> = [
      {
        title: formatMessage("cumulativeIncomeTrend"),
        value: "yield",
      },
      {
        title: formatMessage("cumulativeNetWorthTrend"),
        value: "netValue",
      },
    ];
    const [trendType, setTrendType] = useState<ValueType>(
      trendTypeOptions[0].value
    );

    const benchmarkDailyReturn = useGetBenchmarkDailyReturn(benchmarkId);

    const { tradingDateList } = useAppSelector((state) => state.tradingDates);
    const { datesWithPreviousOneDay, dateReturnsWithPreviousOneDay } =
      useCreation(
        () =>
          getSlicedDateReturnsAndRangeDates({
            name: scaleRange as DatePeriodName,
            targetDates: tradingDateList,
            targetDateReturns: [
              {
                dates: trendData?.dates,
                dailyReturns: trendData?.portfolioReturns,
              },
            ],
            otherDateReturns: [
              {
                dates: trendData?.dates,
                dailyReturns: trendData?.benchmarkReturns,
              },
              {
                dates: benchmarkDailyReturn?.dates,
                dailyReturns: benchmarkDailyReturn?.dailyReturns,
              },
            ],
          }),
        [
          benchmarkDailyReturn?.dailyReturns,
          benchmarkDailyReturn?.dates,
          scaleRange,
          tradingDateList,
          trendData?.benchmarkReturns,
          trendData?.dates,
          trendData?.portfolioReturns,
        ]
      );

    const trendDataSeries: SeriesOption[] = useCreation(
      () => [
        {
          name: organizationName,
          color: colors[0],
          ...(dateReturnsWithPreviousOneDay?.[0] || {}),
        },
        {
          name: formatMessage("performanceBenchmark"),
          color: BENCHMARK_COLOR,
          ...(dateReturnsWithPreviousOneDay?.[1] || {}),
        },
        {
          name: fastProp("name")(benchmarkDailyReturn),
          color: colors[1],
          ...(dateReturnsWithPreviousOneDay?.[2] || {}),
        },
      ],
      [
        benchmarkDailyReturn,
        dateReturnsWithPreviousOneDay,
        formatMessage,
        organizationName,
      ]
    );

    const { dates, dateReturns } = useCreation(
      () =>
        getSlicedDateReturnsAndRangeDates({
          name: scaleRange as DatePeriodName,
          targetDates: tradingDateList,
          targetDateReturns: [
            {
              dates: trendData?.dates,
              dailyReturns: trendData?.portfolioNetValues,
            },
          ],
        }),
      [
        scaleRange,
        tradingDateList,
        trendData?.dates,
        trendData?.portfolioNetValues,
      ]
    );

    const netTrendDataSeries: SeriesOption[] = useCreation(
      () => [
        {
          color: colors[0],
          name: organizationName,
          dates: dateReturns?.[0]?.dates,
          returns: dateReturns?.[0]?.dailyReturns,
        },
      ],
      [dateReturns, dates, organizationName]
    );

    const sectionReturnsTableCol: ColumnsType<Record<string, any>> = [
      {
        dataIndex: "section",
        key: "section",
        align: "center",
        title: formatMessage("section"),
      },
      {
        dataIndex: "portfolio",
        key: "portfolio",
        align: "center",
        title: organizationName,
        render: (val: number) => (
          <ColorNumber value={val} formatValue={formatPercentage} />
        ),
      },
      {
        dataIndex: "benchmark",
        key: "benchmark",
        align: "center",
        title: formatMessage("performanceBenchmark"),
        render: (val: number) => (
          <ColorNumber value={val} formatValue={formatPercentage} />
        ),
      },
    ];
    const sectionReturnsTableDataSource = useCreation(() => {
      const tmpArray = flow(
        mapIndexed((v: Record<string, number | string>[], key: string) =>
          mapIndexed((item: Record<string, number | string>) => ({
            [key]: item?.value,
            section: formatMessage(fastProp("section")(item)),
          }))(v)
        )
      )(portfolioSectionReturns);
      return mapIndexed((v: Record<string, any>, key: number) => ({
        ...v,
        ...tmpArray[1][key],
      }))(tmpArray[0]);
    }, [formatMessage, portfolioSectionReturns]);

    const portfolioPerformanceTableCol: ColumnsType<Record<string, any>> = [
      {
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        dataIndex: "cumulativeYield",
        key: "cumulativeYield",
        align: "center",
        title: formatMessage("cumulativeYield"),
        render: (val: number) => (
          <ColorNumber value={val} formatValue={factorsFormatter.yield} />
        ),
      },
      {
        dataIndex: "maxDrawdown",
        key: "maxDrawdown",
        align: "center",
        title: formatMessage("maxDrawdown"),
        render: (val: number) => factorsFormatter.maxDrawdown(val),
      },
      {
        dataIndex: "volatility",
        key: "volatility",
        align: "center",
        title: formatMessage("volatility"),
        render: (val: number) => factorsFormatter.volatility(val),
      },
      {
        dataIndex: "sharpeRatio",
        key: "sharpeRatio",
        align: "center",
        title: formatMessage("sharpeRatio"),
        render: (val: number) => factorsFormatter.sharpeRatio(val),
      },
      {
        dataIndex: "calmarRatio",
        key: "calmarRatio",
        align: "center",
        title: formatMessage("calmarRatio"),
        render: (val: number) => factorsFormatter.carmerRatio(val),
      },
      {
        dataIndex: "sortinoRatio",
        key: "sortinoRatio",
        align: "center",
        title: formatMessage("sortinoRatio"),
        render: (val: number) => factorsFormatter.treynorRatio(val),
      },
      {
        dataIndex: "infoRatio",
        key: "infoRatio",
        align: "center",
        title: formatMessage("infoRatio"),
        render: (val: number) => factorsFormatter.informationRatio(val),
      },
    ];
    const portfolioPerformanceTabelDataSource = useCreation(
      () => [{ ...(portfolioPerformance || {}), name: organizationName }],
      [organizationName, portfolioPerformance]
    );

    const tip: CardLayoutTipType = [
      [formatMessage("incomeStatistics"), formatMessage("incomeStatisticsTip")],
    ];
    const calcStatisticRangeList = useGetStatisticRange(2);
    const statisticRangeList = useCreation(() => {
      const rangeList = calcStatisticRangeList(
        first(trendData?.dates) || "",
        last(trendData?.dates) || "",
        datePeriodOptions
      );
      return map((item: Record<string, any>) => ({
        ...item,
        label: item?.message,
        value: item?.id,
      }))(rangeList as any);
    }, [trendData?.dates, datePeriodOptions]);
    return (
      <CardLayout
        title={formatMessage("performancePreview")}
        icon={performancePreviewIcon}
        tip={tip}
      >
        <div className={style.performancePreviewWrapper}>
          <div className={style.title}>
            {formatMessage("performancePreview")}
          </div>
          <div className={style.chartConditionWrapper}>
            <div className={style.radioWrapper}>
              <Radio.Group
                value={trendType}
                onChange={(e) => {
                  setTrendType(e.target.value);
                }}
                buttonStyle="solid"
              >
                {mapIndexed((v: { title: string; value: ValueType }) => (
                  <Radio.Button value={v.value}>{v.title}</Radio.Button>
                ))(trendTypeOptions)}
              </Radio.Group>
            </div>
            <div>
              {formatMessage("benchmark")}
              <BenchmarkSelect
                value={benchmarkId}
                onChange={setBenchmarkId}
                className={style.benchmarkSelect}
              />
              <DatePeriodPicker
                options={statisticRangeList as any}
                value={scaleRange}
                onChange={setScaleRange}
              />
            </div>
          </div>
          <div className={style.chartWrapper}>
            <div className={style.chart}>
              <YieldTrendChart
                dates={trendType === "yield" ? datesWithPreviousOneDay : dates}
                series={
                  trendType === "yield" ? trendDataSeries : netTrendDataSeries
                }
                height={360}
                type={trendType}
              />
            </div>
            <div className={style.chartTable}>
              <PeriodTypePicker
                value={periodType}
                onChange={setPeriodType}
                className={style.periodTypePicker}
              />
              <div className={style.SectionReturnsTableWrapper}>
                <Table
                  columns={sectionReturnsTableCol}
                  dataSource={sectionReturnsTableDataSource}
                  pagination={false}
                  bordered
                />
              </div>
            </div>
          </div>
          <div className={style.bottomTableWrapper}>
            <Table
              columns={portfolioPerformanceTableCol}
              dataSource={portfolioPerformanceTabelDataSource}
              pagination={false}
              bordered
            />
          </div>
        </div>
      </CardLayout>
    );
  }
);
