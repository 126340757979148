import React, { useEffect, useMemo, useState } from "react";
import { Modal, Row, Col, Input, Table } from "antd";
import { size, flow, filter, includes } from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import { Competition } from "@/model/competition";
import useModal from "@/hooks/useModal";
import { getCompetitors } from "@/store/competition";
import { Competitor } from "@/api/competition";

import style from "./index.module.less";
import { useAppDispatch } from "@/hooks/redux";

type JoinUserProps = {
  data: Competition;
};

const useColumns = () => {
  const formatMessage = useFormatMessage();
  return [
    {
      title: formatMessage("serialNumber"),
      dataIndex: "index",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: formatMessage("username"),
      dataIndex: "competitorName",
    },
  ];
};

const ModalContent = React.memo<{
  data: Competition;
  closeModal: () => void;
  visible: boolean;
}>(({ data, visible, closeModal }) => {
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();
  const [searchValue, setSearchValue] = useState("");
  const [users, setUsers] = useState<Competitor[]>([]);
  const columns = useColumns();

  useEffect(() => {
    if (data?.competitionId) {
      dispatch(getCompetitors(data?.competitionId)).then(({ payload }) => {
        setUsers(payload as Competitor[]);
      });
    }
  }, [dispatch, data?.competitionId]);

  const dataSource = useMemo(
    () =>
      flow(
        filter((user: Competitor) => {
          if (!searchValue) return true;
          return includes(searchValue)(user?.competitorName);
        })
      )(users),
    [users, searchValue]
  );

  return (
    <Modal
      title={formatMessage("joinCompetitionUser")}
      visible={visible}
      width={900}
      okText={formatMessage("ok")}
      onCancel={closeModal}
      onOk={closeModal}
      cancelText={formatMessage("cancel")}
    >
      <>
        <Row justify="space-between" className={style.Top}>
          <Col>{`${formatMessage("competitionName")}：${data?.name}`}</Col>
          <Col>
            {formatMessage("hasJoinCompetitionCount", { count: size(users) })}
          </Col>
          <Col>
            <Input.Search
              className={style.Search}
              onSearch={(value) => {
                setSearchValue(value);
              }}
              placeholder={formatMessage("searchCompetitionUserPlaceholder")}
              enterButton={formatMessage("inquire")}
            />
          </Col>
        </Row>
        <Table
          className={style.Table}
          columns={columns}
          dataSource={dataSource || []}
          rowKey="id"
          pagination={{
            hideOnSinglePage: true,
            showQuickJumper: true,
            size: "small",
          }}
          scroll={{ y: 300 }}
        />
      </>
    </Modal>
  );
});

const JoinUser = ({ data }: JoinUserProps) => {
  const formatMessage = useFormatMessage();
  const { visible, openModal, closeModal } = useModal();
  return (
    <>
      <a onClick={openModal}>{formatMessage("view")}</a>
      {visible && (
        <ModalContent data={data} visible={visible} closeModal={closeModal} />
      )}
    </>
  );
};

export default React.memo(JoinUser);
