import { RootState } from "@/store";
import { filter } from "lodash/fp";
import { createSelector } from "@reduxjs/toolkit";
import { normalize } from "@/util/opt";
import { User } from "@/model/login";

export const userSelector = createSelector(
  (state: RootState) => state.users.user,
  (user) => user
);

export const usersSelector = createSelector(
  (state: RootState) => state?.users?.users,
  (users) => filter<User>((user) => !!user?.username)(users)
);

export const usersMapSelector = createSelector(usersSelector, (users) =>
  normalize<User>("id")(users)
);
