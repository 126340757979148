import React from "react";
import cn from "classnames";
import style from "./index.module.less";
import { Space, Tooltip } from "antd";
import { useFormatMessage } from "@/util/formatMessage";

export default React.memo(
  ({
    title,
    type,
    changeType,
  }: {
    title: string;
    type?: string;
    changeType?: (value: string) => void;
  }) => {
    const formatMessage = useFormatMessage();
    return (
      <div className={style.AumSubTitle}>
        <div className={style.AumSubTitleName}>{title}</div>
        {title && (
          <div>
            <Space>
              <Tooltip title={formatMessage("listMode")}>
                <div
                  className={cn(
                    style.Icon,
                    style.TableIcon,
                    type === "table" && style.SelectedTable
                  )}
                  onClick={() => changeType?.("table")}
                ></div>
              </Tooltip>
              <Tooltip title={formatMessage("chartMode")}>
                <div
                  className={cn(
                    style.Icon,
                    style.ChartIcon,
                    type === "chart" && style.SelectedChart
                  )}
                  onClick={() => changeType?.("chart")}
                ></div>
              </Tooltip>
            </Space>
          </div>
        )}
      </div>
    );
  }
);
