import TradingDatePicker from "@/components/tradingDatePicker";
import { mapIndexed } from "@/util/opt";
import { checkDisabledRangeDate } from "@/views/portfolioManage/manualCreatePortfolio/constant";
import { useCreation, useMemoizedFn } from "ahooks";
import Trash from "@/assets/trash.svg";
import { concat, last, pullAt } from "lodash/fp";
import React from "react";
import style from "./index.module.less";
import { getNextTradingDate } from "@/util/processedDates";
import { useAppSelector } from "@/hooks/redux";

export default React.memo<{
  dates: string[];
  startDate: string;
  endDate: string;
  onUpdate: (dates: string[]) => any;
}>(({ dates, onUpdate, startDate, endDate }) => {
  const onAddDates = useMemoizedFn((date) => onUpdate(concat(dates)(date)));
  const onRemoveDates = useMemoizedFn((index: number) =>
    onUpdate(pullAt(index)(dates))
  );
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  const nextTradingDate = useCreation(
    () => getNextTradingDate(tradingDateList, processedTradingDates, endDate),
    [tradingDateList, processedTradingDates, endDate]
  );
  const disabledDate = useMemoizedFn((date: string) =>
    checkDisabledRangeDate(last(dates) || startDate, nextTradingDate)(date)
  );
  return (
    <div className={style.AddSpecifiedDate}>
      {mapIndexed((date: string, index: number) => (
        <div key={date}>
          <TradingDatePicker
            className={style.DatePickerWidth}
            value={date}
            disabled
          />
          <img
            src={Trash}
            className={style.RemoveIcon}
            onClick={() => onRemoveDates(index)}
          />
        </div>
      ))(dates)}
      <TradingDatePicker
        value={null}
        className={style.DatePickerWidth}
        onChange={onAddDates}
        disabledDate={disabledDate}
      />
    </div>
  );
});
