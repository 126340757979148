import React, { useMemo, useState, useContext } from "react";
import PortfolioAnalysisSubtitle from "../../components/portfolioAnalysisSubtitle";
import { useFormatMessage } from "@/util/formatMessage";
import { Radio, Space, Table } from "antd";
import { useAppSelector } from "@/hooks/redux";
import style from "../../index.module.less";
import {
  CUMULATIVEINCOME,
  NETCUMVALUE,
  getIncomeStatisticsColumns,
} from "../../constant";
import IncomeSelect from "./incomeSelect";
import CumulativeChart from "@/components/cumulativeChart";
import { map, prop, flow, last, omit } from "lodash/fp";
import { fastProp, fastNth } from "@/util/opt";
import SummaryCard from "../../components/summaryCard";
import { FormatMessageFunc } from "@/util/formatMessage";
import { formatPercentage, toFixedNumber } from "@/util/numberFormatter";
import { formatNilToZero } from "@/util/numberFormatter";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { colors } from "@/util/colors";
import { analysisBasicInfoSelector } from "../../selectors";
import { useGetFactors } from "../../hooks";
import { RootState } from "@/store";
import { concat } from "lodash/fp";
import { useCreation } from "ahooks";
import { getGridTopOptions } from "../constant";
import {
  FROM_THIS_YEAR,
  RangeInterface,
  RECENT_FIVE_YEAR,
  RECENT_TWO_YEAR,
} from "@/constant/statisticRange";
import { tradingDatesSelector } from "@/selectors/tradingDates";
import { getValueColor } from "../../portfolioSummary/index";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import ErrorBoundary from "@/components/errorBoundary";
import { errorValidator } from "@/components/errorBoundary/constant";
import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";
import {
  getCumulativeCalculateRangeDate,
  useGetCumulativeChartCalculateRangeDate,
} from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { benchmarksIdMapSelector } from "@/selectors/benchmarks";

export const portfolioStaticRange = omit([
  FROM_THIS_YEAR,
  RECENT_TWO_YEAR,
  RECENT_FIVE_YEAR,
])(portfolioAnalysisStatisticRange);

const percentage = (value: number) => formatPercentage(value);
const factors = [
  "portfolioReturn",
  "benchmarkAttribution",
  "sharpe",
  "benchmarkSharpe",
  "annualReturn",
  "benchmarkAnnualReturn",
  "infoRatio",
  "calmarRatio",
  "sortinoRatio",
  "alpha",
  "beta",
];

export const useGetDatesAndDailyReturns = (
  id: string,
  legendIds: string,
  activeRange: string,
  selectedIncomeType: string,
  formatMessage: FormatMessageFunc
) => {
  const portfolioSummary = useAppSelector((state: RootState) =>
    analysisBasicInfoSelector(state, id)
  );

  const dailyReturns = useAppSelector(prop("dailyReturns"));

  const {
    tradingDate,
    dailyReturn,
    dailyNetValue,
    startDate,
    endDate,
    benchmark,
    portfolioName,
  } = portfolioSummary;

  const isCumulativeIncome = selectedIncomeType === "cumulativeIncome";

  const compareBenchmark = useAppSelector(benchmarksIdMapSelector);

  //最新累计净值
  const cumulativeNetWorth = flow(last, toFixedNumber(4))(dailyNetValue);

  const benchmarkDailyReturn = useMemo(
    () => fastProp(legendIds)(dailyReturns),
    [legendIds, dailyReturns]
  );
  //业绩基准
  const performanceBenchmarkReturn = useMemo(
    () => fastProp(benchmark)(dailyReturns),
    [benchmark, dailyReturns]
  );

  const { tradingDateList, processedTradingDates } =
    useAppSelector(tradingDatesSelector);
  const getCumulativeChartCalculateRangeDate =
    useGetCumulativeChartCalculateRangeDate();
  const calculatedDates = useMemo(
    () =>
      isCumulativeIncome
        ? getCumulativeChartCalculateRangeDate(tradingDate, activeRange)
        : getCumulativeCalculateRangeDate({
            dates: tradingDate,
            range: activeRange,
            tradingDateList,
            processedTradingDates,
          }),
    [
      activeRange,
      getCumulativeChartCalculateRangeDate,
      isCumulativeIncome,
      processedTradingDates,
      tradingDate,
      tradingDateList,
    ]
  );

  const dailyReturnsMap = useMemo(
    () => normalizeDailyReturnsMap(tradingDate, dailyReturn),
    [tradingDate, dailyReturn]
  );

  const netValueMap = useMemo(
    () => normalizeDailyReturnsMap(tradingDate, dailyNetValue),
    [tradingDate, dailyNetValue]
  );

  const performanceBenchmarkReturnMap = useMemo(
    () =>
      normalizeDailyReturnsMap(
        fastProp("dates")(performanceBenchmarkReturn),
        fastProp("dailyReturns")(performanceBenchmarkReturn)
      ),
    [performanceBenchmarkReturn]
  );

  const combinationInfo = useMemo(
    () => ({
      id: "1",
      name: portfolioName,
      color: "#1A7FFF",
      dailyReturnsMap: isCumulativeIncome ? dailyReturnsMap : netValueMap,
    }),
    [portfolioName, isCumulativeIncome, dailyReturnsMap, netValueMap]
  );

  const benchmarkInfo = useMemo(
    () => ({
      id: legendIds,
      color: fastNth(1)(colors),
      name: prop(`${legendIds}.name`)(compareBenchmark),
      dailyReturnsMap: normalizeDailyReturnsMap(
        fastProp("dates")(benchmarkDailyReturn),
        fastProp("dailyReturns")(benchmarkDailyReturn)
      ),
      // ...benchmarkDailyReturn,
    }),
    [benchmarkDailyReturn, legendIds, compareBenchmark]
  );

  const performanceBenchmarkInfo = useMemo(
    () => ({
      id: benchmark,
      color: "#FFA62A",
      name: formatMessage("performanceBenchmark"),
      dailyReturnsMap: performanceBenchmarkReturnMap,
      // ...performanceBenchmarkReturn,
    }),
    [performanceBenchmarkReturnMap, benchmark, formatMessage]
  );

  const combinationAndBenchmarkInfo = useMemo(
    () =>
      isCumulativeIncome
        ? concat(combinationInfo)([performanceBenchmarkInfo, benchmarkInfo])
        : [combinationInfo],
    [
      benchmarkInfo,
      combinationInfo,
      isCumulativeIncome,
      performanceBenchmarkInfo,
    ]
  );

  const chartData = useMemo(
    () =>
      map(({ name, dailyReturnsMap, color }) => ({
        name,
        color,
        dailyReturns: map<string, [string, number]>((date) => [
          date,
          formatNilToZero(fastProp(date)(dailyReturnsMap)),
        ])(calculatedDates),
      }))(combinationAndBenchmarkInfo),
    [combinationAndBenchmarkInfo, calculatedDates]
  );

  const factorIndicators = useGetFactors(id, activeRange, factors);

  const tableData = useCreation(
    () => [
      {
        id: "id",
        accumulated_yield: fastProp("portfolioReturn")(factorIndicators),
        benchmark_yield: fastProp("benchmarkAttribution")(factorIndicators),
        annual_sharpe: fastProp("sharpe")(factorIndicators),
        benchmark_sharpe: fastProp("benchmarkSharpe")(factorIndicators),
        annual_yield: fastProp("annualReturn")(factorIndicators),
        active_yield:
          fastProp("annualReturn")(factorIndicators) -
          fastProp("benchmarkAnnualReturn")(factorIndicators),
        info_ratio: fastProp("infoRatio")(factorIndicators),
        calmar_ratio: fastProp("calmarRatio")(factorIndicators),
        sortino_ratio: fastProp("sortinoRatio")(factorIndicators),
        alpha: fastProp("alpha")(factorIndicators),
        beta: fastProp("beta")(factorIndicators),
      },
    ],
    [factorIndicators]
  );

  //文本总结
  const summaryText = useMemo(() => {
    let incomeLevel = "";

    const accumulatedYield = fastProp("portfolioReturn")(factorIndicators);
    const perforYield = fastProp("benchmarkAttribution")(factorIndicators);

    if (
      (accumulatedYield as number) >
      Math.abs(perforYield) * 1.5 + perforYield
    ) {
      incomeLevel = formatMessage("excellent");
    } else if (
      (accumulatedYield as number) >
        Math.abs(perforYield) * 0.9 + perforYield &&
      (accumulatedYield as number) < Math.abs(perforYield) * 1.5 + perforYield
    ) {
      incomeLevel = formatMessage("commonly");
    } else {
      incomeLevel = formatMessage("poor");
    }

    let benchmarkPerformance = "";
    if ((accumulatedYield as number) > (perforYield as number)) {
      benchmarkPerformance = formatMessage("betterThan");
    } else {
      benchmarkPerformance = formatMessage("InferiorTo");
    }

    let summaryText = "";

    if ((perforYield as number) === 0) {
      summaryText = formatMessage("portfolioAnalysisIncomeStatisticsSummary1", {
        endTime: <span className={style.colorBlock}>{endDate}</span>,
        cumulativeNetWorth: (
          <span className={style.colorBlock}>{cumulativeNetWorth}</span>
        ),
        accumulatedYield: (
          <span className={getValueColor(accumulatedYield as number)}>
            {percentage(accumulatedYield as number)}
          </span>
        ),
      });
    } else {
      summaryText = formatMessage("portfolioAnalysisIncomeStatisticsSummary", {
        endTime: <span className={style.colorBlock}>{endDate}</span>,
        cumulativeNetWorth: (
          <span className={style.colorBlock}>{cumulativeNetWorth}</span>
        ),
        accumulatedYield: (
          <span className={getValueColor(accumulatedYield as number)}>
            {percentage(accumulatedYield as number)}
          </span>
        ),
        incomeLevel: <span className={style.colorBlock}>{incomeLevel}</span>,
        benchmarkPerformance: (
          <span className={style.colorBlock}>{benchmarkPerformance}</span>
        ),
      });
    }
    return summaryText;
  }, [endDate, formatMessage, cumulativeNetWorth, factorIndicators]);

  return {
    tableData,
    dates: calculatedDates,
    chartData,
    startDate,
    endDate,
    summaryText,
    isCumulativeIncome,
  };
};

export default React.memo<{
  id: string;
  activeRange: string;
  legendIds: string;
  onChange: (key: string) => (value: any) => any;
}>(({ id, activeRange, legendIds, onChange }) => {
  const { runningTime } = useContext(PortfolioAnalysisContext);
  const formatMessage = useFormatMessage();
  const [selectedIncomeType, setSelectedIncomeType] =
    useState("cumulativeIncome");

  const {
    dates,
    tableData,
    chartData,
    summaryText,
    startDate,
    endDate,
    isCumulativeIncome,
  } = useGetDatesAndDailyReturns(
    id,
    legendIds,
    activeRange,
    selectedIncomeType,
    formatMessage
  );
  const needTime = useMemo(
    () => (isCumulativeIncome ? 2 : 1),
    [isCumulativeIncome]
  );
  const tablecolumns = useMemo(
    () => getIncomeStatisticsColumns(formatMessage),
    [formatMessage]
  );
  const errorKey = useCreation(() => {
    if (isCumulativeIncome)
      return errorValidator.lessTwoTradingDate(needTime, runningTime);
    return errorValidator.lessOneDay(needTime, runningTime);
  }, [isCumulativeIncome, needTime, runningTime]);

  const GridTopOptions = useCreation(
    () => getGridTopOptions(isCumulativeIncome ? percentage : toFixedNumber(4)),
    [isCumulativeIncome]
  );
  return (
    <>
      <PortfolioAnalysisSubtitle name={formatMessage("incomeStatistics")} />
      <Radio.Group
        onChange={(v) => setSelectedIncomeType(v.target.value)}
        value={selectedIncomeType}
        optionType="button"
        buttonStyle="solid"
      >
        <Radio.Button value={CUMULATIVEINCOME}>
          {formatMessage("cumulativeIncome")}
        </Radio.Button>
        <Radio.Button value={NETCUMVALUE}>
          {formatMessage("NetCumValue")}
        </Radio.Button>
      </Radio.Group>
      <ErrorBoundary errKey={errorKey}>
        <div>
          <IncomeSelect
            startDate={startDate}
            endDate={endDate}
            activeRange={activeRange}
            legendIds={legendIds}
            selectedIncomeType={selectedIncomeType}
            onChange={onChange}
            className={style.IncomeSelect}
            staticRange={portfolioStaticRange as Record<string, RangeInterface>}
          />
          <Space direction="vertical" className={style.fullWidth}>
            <div className={style.CumulativeChart}>
              <div className={style.CumulativeChartTip}>
                <Table
                  dataSource={tableData}
                  columns={tablecolumns as any}
                  pagination={false}
                  bordered={true}
                  className={style.tableStyle}
                  tableLayout="fixed"
                />
              </div>
              <CumulativeChart
                dates={dates}
                dataSource={chartData}
                type={isCumulativeIncome ? "cumulative" : "cumulativeNetValue"}
                options={GridTopOptions}
              />
              <SummaryCard summaryText={summaryText} />
            </div>
          </Space>
        </div>
      </ErrorBoundary>
    </>
  );
});
