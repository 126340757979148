import { CategoryResponse } from "@/model/entities";
import { RootState } from "@/store";
import { normalize } from "@/util/opt";
import { listToTree, treeToMap } from "@/util/opt/tree";
import { createSelector } from "@reduxjs/toolkit";
import { filter } from "lodash/fp";

export const categoryTreeSelector = createSelector(
  (state: RootState) => state.entities.category,
  (category) => listToTree(category, "parentId", "categoryId")
);

export const categoryTreeMapSelector = createSelector(
  categoryTreeSelector,
  (categoryTree) =>
    treeToMap({ idKey: "categoryId", needPath: true })(categoryTree)
);

export const categoryIdMapSelector = createSelector(
  (state: RootState) => state.entities.category,
  (category) => normalize("categoryId")(category)
);

export const assetBigCategorySelector = createSelector(
  (state: RootState) => state.entities.category,
  (category) =>
    filter((item: CategoryResponse) => item?.parentId === "-1")(category)
);
