import React from "react";
import { Card } from "antd";
import style from "./index.module.less";
import DisplayFundWeightTable from "../components/displayFundWeightTable";
import BackTestingResult from "./backTestingResult";
import BackTestingForm, { SaveSchemeParams } from "./backTestingForm";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useMemoizedFn } from "ahooks";
import { saveBackTestingScheme } from "@/store/portfolioList";
import { BackTestingFormProps } from "../interface";
import { EditBackTestingSchemeParam } from "@/api/portfolioList";

export default React.memo<Omit<BackTestingFormProps, "onSaveScheme">>(
  ({ data, type }) => {
    const { backTestingFormData } = useAppSelector(
      (state) => state.createPortfolio.manualCreatePortfolio
    );
    const dispatch = useAppDispatch();
    const onSaveScheme = useMemoizedFn(
      (data: SaveSchemeParams | EditBackTestingSchemeParam) => {
        return dispatch(
          saveBackTestingScheme(data as SaveSchemeParams)
        ).unwrap();
      }
    );
    return (
      <>
        <Card className={style.Card}>
          <DisplayFundWeightTable data={data} />
        </Card>

        <BackTestingForm data={data} type={type} onSaveScheme={onSaveScheme} />
        <BackTestingResult
          startDate={backTestingFormData.startDate}
          endDate={backTestingFormData.endDate}
        />
      </>
    );
  }
);
