function repeatWord(word: string, num: number) {
  let result = "";
  for (let i = 0; i < num; i++) {
    result += word;
  }
  return result;
}
function repeatLetter(word: string, num: number) {
  let result = "";
  for (const letter of word) {
    result += repeatWord(letter, num);
  }
  return result;
}

export function hexTorgb(hex: string) {
  let hexNum: any = hex.substring(1);
  hexNum = "0x" + (hexNum.length < 6 ? repeatLetter(hexNum, 2) : hexNum);
  const r = hexNum >> 16;
  const g = (hexNum >> 8) & ("0xff" as any);
  const b = hexNum & ("0xff" as any);
  return `rgb(${r},${g},${b})`;
}

export function hexTorgba(hex?: string, opacity = 1) {
  if (!hex) return "";
  let hexNum: any = hex.substring(1);
  hexNum = "0x" + (hexNum.length < 6 ? repeatLetter(hexNum, 2) : hexNum);
  const r = hexNum >> 16;
  const g = (hexNum >> 8) & ("0xff" as any);
  const b = hexNum & ("0xff" as any);
  return `rgba(${r},${g},${b},${opacity})`;
}
