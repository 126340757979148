import {
  FundBasicBody,
  FundBasicType,
  FundDetailIndustryBody,
  FundDetailIndustryTrend,
  FundDetailSectorTrend,
  FundDetailStockPositionTrend,
  FundAttributionBrinsonBody,
  FundDetailFundManagers,
  NewsInfoBody,
  AnnouncementInfoBody,
  NewsBody,
  AnnouncementBody,
  FundStockPositionBody,
  FundDetailIndustryTrendDate,
  ETFFundAttributionBrinsonBody,
  FundNetValueAttributionParams,
  netValueAttributionModalBody,
  EvaluationReportInfoBody,
  EvaluationReportAbilitySummaryBody,
  ComprehensivePerformanceParam,
  IndustryAbilityEvaluationParam,
  EvaluationReportRiskSummaryBody,
  StockSelectionAbilityBody,
  TurnoverImprovementAbilityBody,
  MarketCapabilityDetailParam,
  RiskDetailParam,
  ManagerEvaluationBody,
} from "@/model/fundDetail";
import { PromiseType } from "@/model/promise";
import { get } from "@/util/axios";
import { socketHttp } from "@/util/socket";
import { netValueAttributionParam } from "./portfolioAnalysis";

// 基金基础信息
export const getFundBasic = (body: FundBasicBody): PromiseType<FundBasicType> =>
  get("fund_detail/fund_basic", { ...body });

// 获取基金总结
export const getFundSummaryResult = (data: { fundId: string }) =>
  socketHttp("START_FUND_SUMMARY", {
    meta: data,
  });

// 获取基金收益
export const getFundDetailReturn = (body: FundBasicBody) =>
  get("fund/detail/return", { ...body });

// 获取基金净值走势
export const getFundDailyNetValue = (fundId: string) =>
  get(`/fund/compare/daily_net_value/${fundId}`);

// 基金前十重仓持仓走势
export const getFundDetailStockPositionTrend = (
  params: FundStockPositionBody
): PromiseType<FundDetailStockPositionTrend[]> =>
  get("fund/detail/stock_position_trend", {
    industryCategoryId: params.industryId,
    fundId: params.fundId,
  });

// 基金行业占比走势
export const getFundDetailIndustryTrend = (
  body: FundDetailIndustryBody
): PromiseType<FundDetailIndustryTrend[]> =>
  get("fund/detail/industry_trend", { ...body });

// 基金行业占比走势饼图
export const getFundDetailETFIndustryTrend = (
  body: FundDetailIndustryBody & { date: string }
): PromiseType<FundDetailIndustryTrend[]> =>
  get("fund/detail/industry_distribute", { ...body });

//基金行业占比截止时间
export const getFundDetailIndustryTrendDate = (
  fundId: string
): PromiseType<FundDetailIndustryTrendDate> =>
  get(`fund/detail/industry_distribute/dates/${fundId}`);

// 获得基金分类走势
export const getFundDetailSectorTrend = (
  fundId: string
): PromiseType<FundDetailSectorTrend[]> =>
  get("fund/detail/sector_trend", { fundId });

// 收益归因
export const getFundAttributionBrinsonResult = (
  FundAttributionBrinsonBody: FundAttributionBrinsonBody
) =>
  socketHttp("START_SINGLE_FUND_BRINSON_ATTRIBUTION", {
    meta: FundAttributionBrinsonBody,
  });

// ETF收益归因
export const getETFFundAttributionBrinsonResult = (
  FundAttributionBrinsonBody: ETFFundAttributionBrinsonBody
) =>
  socketHttp("START_ATTRIBUTION_BRINSON", {
    meta: FundAttributionBrinsonBody,
  });

//现任基金经理基础信息和历届基金经理
export const getCurrentAndHistoryFundManagers = (
  fundId: string
): PromiseType<FundDetailFundManagers> =>
  get("fund_detail/fund_manager", { fundId: fundId });

//公告类型
export const getAnnouncementType = (): PromiseType<string[]> =>
  get("fund/announcement_and_news/labels");

//新闻
export const getNewsInfo = (body: NewsBody): PromiseType<NewsInfoBody> =>
  get(`asset/news/${body.fundCode}/${body.pageNum}`, {
    pageSize: body.pageSize,
    from: body.from,
    to: body.to,
    keyWord: body.keyWord,
  });
//

//公告
export const getAnnouncementInfo = (
  body: AnnouncementBody
): PromiseType<AnnouncementInfoBody> =>
  get(`asset/announcement/${body.fundCode}/${body.label}/${body.pageNum}`, {
    pageSize: body.pageSize,
    from: body.from,
    to: body.to,
    keyWord: body.keyWord,
  });

// 净值归因
export const getNetValueAttribution = (param: netValueAttributionParam) =>
  get(
    `asset/fund/attribution/net_value_attribution/${param.id}/${param.section}`
  );

export const getFundAttributionNetValueResult = (
  FundAttributionNetValueBody: FundNetValueAttributionParams
) =>
  socketHttp("START_FUND_NET_ASSET_VALUE_ATTRIBUTION", {
    meta: FundAttributionNetValueBody,
  });

export const getNetValueAttributionModel = (): PromiseType<
  netValueAttributionModalBody[]
> => get(`/factor-model/net-value/attribution`);

export const getEvaluationReportInfo = (
  fundId: string
): PromiseType<EvaluationReportInfoBody> =>
  get(`/open-fund/evaluation-report/mobile/info/${fundId}`);

export const getEvaluationReportAbilitySummary = (
  fundId: string
): PromiseType<EvaluationReportAbilitySummaryBody> =>
  get(`/open-fund/evaluation-report/mobile/ability-summary/${fundId}`);

export const getEvaluationReportRiskSummary = (
  fundId: string
): PromiseType<EvaluationReportRiskSummaryBody> =>
  get(`/open-fund/evaluation-report/mobile/risk-summary/${fundId}`);

//评价报告-综合业绩
export const getComprehensivePerformance = (
  fundCode: string
): PromiseType<ComprehensivePerformanceParam> =>
  get(
    `open-fund/evaluation-report/mobile/comprehensive-performance/${fundCode}`
  );

//评价报告-行业配置能力
export const getIndustryAbilityEvaluation = (
  fundCode: string
): PromiseType<IndustryAbilityEvaluationParam> =>
  get(
    `/open-fund/evaluation-report/mobile/ability-evaluation-detail/${fundCode}`
  );

//评价报告-个股选择能力
export const getStockSelectionAbility = (
  fundId: string
): PromiseType<StockSelectionAbilityBody> =>
  get(`/open-fund/evaluation-report/mobile/ability/stock-selection/${fundId}`);

//评价报告-调仓改善能力
export const getTurnoverImprovementAbility = (
  fundId: string
): PromiseType<TurnoverImprovementAbilityBody> =>
  get(
    `/open-fund/evaluation-report/mobile/turnover-improvement-ability/${fundId}`
  );

// 评价报告-行情捕获能力
export const getMarketCapabilityDetail = (
  fundCode: string
): PromiseType<MarketCapabilityDetailParam> =>
  get(
    `/open-fund/evaluation-report/mobile/market-capability-detail/${fundCode}`
  );

// 评价报告-风险探针详情
export const getRiskDetail = (fundCode: string): PromiseType<RiskDetailParam> =>
  get(`/open-fund/evaluation-report/mobile/risk-detail/${fundCode}`);
//评价报告-基金经理评价
export const getManagerEvaluation = (
  fundId: string
): PromiseType<ManagerEvaluationBody> =>
  get(`/open-fund/evaluation-report/mobile/manager-evaluation/${fundId}`);
