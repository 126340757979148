import { useFormatMessage } from "@/util/formatMessage";
import { Card, Checkbox, Form, Radio, RadioChangeEvent } from "antd";
import React, { useEffect } from "react";
import classnames from "classnames";
import { categorySourceType, categoryType } from "../constant";
import style from "./index.module.less";
import MeanVarianceModel from "./components/meanVarianceModel";
import BlackLittleManModel from "./components/blackLittleManModel";
import { configurationModelOptions } from "./constant";
import InfoIcon from "@/assets/infoIcon.svg";
import { useCategoryModelError, useUpdateModelAllocation } from "./hooks";
import { validateErrorField } from "../../fundConfiguration/constant";
import MarketIndex from "./components/marketIndex";
import { useAppDispatch } from "@/hooks/redux";
import { resetModelConfiguration } from "@/store/createPortfolio";
import { fetchCategoryToBenchmark } from "@/store/entities";

const FormItem = Form.Item;
export default React.memo<{
  onError: React.Dispatch<React.SetStateAction<boolean>>;
  buttonDisabled: boolean;
}>(({ onError, buttonDisabled }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const {
    modelAllocationData,
    emptyCategoryTypes,
    onUpdateModelAllocation,
    onConfigurationModel,
    onUpdateCategoryTypes,
  } = useUpdateModelAllocation();
  const { categoryTypes, categorySource, configurationModel } =
    modelAllocationData;
  const errorField = useCategoryModelError();
  useEffect(() => {
    onError(validateErrorField(errorField as any));
  }, [errorField, onError]);
  useEffect(() => onError(false), [onError]);
  useEffect(()=>{
    dispatch(fetchCategoryToBenchmark())
  },[dispatch])
  return (
    <div className={style.AllocationModel}>
      <Card
        className={style.AllocationModelCard}
        title={formatMessage("allocationModel")}
        bordered={false}
      >
        <FormItem
          labelCol={{ span: 3 }}
          labelAlign="left"
          label={formatMessage("collocationMethod")}
        >
          <Radio.Group
            className={style.RadioGroupItem}
            value={categorySource}
            onChange={(e: RadioChangeEvent) => {
              dispatch(resetModelConfiguration(null));
              onUpdateModelAllocation("categorySource")(e.target.value);
            }}
            options={categorySourceType}
          />
        </FormItem>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          label={formatMessage(
            categorySource === "ASSET_CATEGORY"
              ? "configurationCategory"
              : "configurationIndex"
          )}
        >
          <div className={style.ConfigurationCategory}>
            {categorySource === "ASSET_CATEGORY" && (
              <Checkbox.Group
                className={style.ConfigurationRadioGroupItem}
                options={categoryType}
                value={categoryTypes}
                onChange={onUpdateCategoryTypes}
              />
            )}
            {categorySource === "MARKET_INDEX" && (
              <MarketIndex onChange={onUpdateCategoryTypes} />
            )}
          </div>
          {categorySource === "ASSET_CATEGORY" && emptyCategoryTypes && (
            <div
              className={classnames(style.DescriptionTip, style.CategoryTip)}
            >
              <img src={InfoIcon} className={style.AlertIcon} />
              <p>{formatMessage("pleaseSelectCategoryType")}</p>
            </div>
          )}
        </FormItem>
        {!emptyCategoryTypes && (
          <>
            <FormItem
              labelCol={{ span: 3 }}
              labelAlign="left"
              label={formatMessage("configurationModel")}
            >
              <Radio.Group
                className={style.ConfigurationModel}
                optionType="button"
                options={configurationModelOptions}
                value={configurationModel}
                onChange={onConfigurationModel}
              />
            </FormItem>
            <div className={style.Models}>
              {configurationModel === "meanVarianceModel" && (
                <MeanVarianceModel
                  modelAllocationData={modelAllocationData}
                  updateModelAllocation={onUpdateModelAllocation}
                  categoryModelErrorField={errorField}
                  buttonDisabled={buttonDisabled}
                />
              )}
              {configurationModel === "blackLittleManModel" && (
                <BlackLittleManModel
                  modelAllocationData={modelAllocationData}
                  updateModelAllocation={onUpdateModelAllocation}
                  categoryModelErrorField={errorField}
                  buttonDisabled={buttonDisabled}
                />
              )}
            </div>
          </>
        )}
      </Card>
    </div>
  );
});
