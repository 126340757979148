import React, { useCallback, useEffect, useState } from "react";
import { first, flow, includes, isEmpty, map } from "lodash/fp";
import { leftMenu1 as leftMenu, RouterMenu } from "@/router/menu";
import { useFormatMessage } from "@/util/formatMessage";
import { flattenAllLeaf, mapTree } from "@/util/opt/tree";
import { normalize, fastProp } from "@/util/opt";
import { Menu } from "antd";
import { Link, useMatch, useLocation } from "react-router-dom";
import icons from "@/assets/homeIcons";
import Icon from "@/components/icons";
import style from "./index.module.less";
import { values } from "lodash/fp";
import cn from "classnames";

const { SubMenu } = Menu;
const pathToId = flow(
  flattenAllLeaf,
  normalize("path", fastProp("id"))
)(leftMenu);
const pathToParent: Record<string, string> = flow(
  mapTree<RouterMenu>("children", (node, parent) => ({
    ...node,
    parentId: fastProp("id")(parent),
  })),
  flattenAllLeaf,
  normalize("path", fastProp("parentId"))
)(leftMenu);
const defaultOpenKeys = values(pathToParent);

interface LeftMenuProps {
  selectTabs: (menuName: string) => void;
  collapsed: boolean;
}

// const getIconPath = (active: boolean, id: string) =>
//   active ? icons[`${id}Active`] : icons[id];
// 使用一套svg 改变颜色实现不同状态， 目前svg内容有问题(修改颜色无效)，后续需要设计改善
const CustomIcon = ({
  active,
  id,
  collapsed,
}: {
  active: boolean;
  id: string;
  collapsed?: boolean;
}) => {
  if (active)
    return (
      <Icon
        svg={icons[`${id}Active`]}
        style={{ fontSize: collapsed ? 20 : 16, color: "#fff" }}
      />
    );
  return <Icon svg={icons[id]} style={{ fontSize: collapsed ? 20 : 16 }} />;
};

export default React.memo<LeftMenuProps>(({ selectTabs, collapsed }) => {
  const [selectKeys, setSelectedKeys] = useState<string[]>([]);
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const router = useMatch(location.pathname);
  useEffect(() => {
    if (router) {
      const activeId = pathToId[router.pathname];
      setSelectedKeys([activeId]);
      selectTabs(activeId);
    }
  }, [router, selectTabs]);
  const onSelect = useCallback(
    ({ selectedKeys }) => {
      // setSelectedKeys(selectedKeys);
      selectTabs(selectedKeys[0]);
    },
    [selectTabs]
  );

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={selectKeys}
      onSelect={onSelect}
      className={collapsed ? style.CollapsedMenu : undefined}
      defaultOpenKeys={defaultOpenKeys}
    >
      {leftMenu.map((subMenu) => {
        const { id, children, path } = subMenu || {};
        if (isEmpty(children))
          return (
            <Menu.Item
              key={id}
              style={{ fontSize: 16 }}
              className={cn(collapsed && style.FirstClassName)}
              icon={
                <CustomIcon
                  active={includes(first(selectKeys))(map("id")(children))}
                  id={id}
                  collapsed={collapsed}
                />
              }
            >
              <Link to={path as string}>{formatMessage(id)}</Link>
            </Menu.Item>
          );
        return (
          <SubMenu
            key={id}
            title={formatMessage(id)}
            popupClassName={style.PopupClassName}
            icon={
              <CustomIcon
                active={includes(first(selectKeys))(map("id")(children))}
                id={id}
                collapsed={collapsed}
              />
            }
          >
            {children?.map(({ id, children, path }) => {
              if (isEmpty(children))
                return (
                  <Menu.Item key={id} style={{ fontSize: 16 }}>
                    <Link to={path as string}>{formatMessage(id)}</Link>
                  </Menu.Item>
                );
              return (
                <SubMenu
                  key={id}
                  title={formatMessage(id)}
                  // disabled={!collapsed}
                  className={style.SecondClassName}
                >
                  {children?.map(({ id, path }) => {
                    if (!path) return "";
                    return (
                      <Menu.Item key={id}>
                        <Link
                          to={path}
                          className={cn(
                            first(selectKeys) === id
                              ? style.activeMenuLink
                              : style.MenuLink
                          )}
                        >
                          <span className={style.LastClassName}>
                            {formatMessage(id)}
                          </span>
                        </Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            })}
          </SubMenu>
        );
      })}
    </Menu>
  );
});
