import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { fastNth, mapIndexed } from "@/util/opt";
import cn from "classnames";
import React from "react";
import style from "./quarterbackRank.module.less";

export interface QuarterBackRankProps {
  rank: number;
  size: number;
  className?: string;
}

const colors = ["#D5E8FF", "#BDDCFF", "#9ACAFF", "#76B7FF"];

const calculateRankNum = (
  rank: QuarterBackRankProps["rank"],
  size: QuarterBackRankProps["size"]
) => {
  const sliceSize = Math.floor(size / 4);
  if (!rank || !size || rank > size) return 0;
  let rankNum = 0;
  const rankHeight = size - rank; // rank值越大，UI图中展示效果的排名越 “低”,反之同理
  while (sliceSize * rankNum < rankHeight) {
    rankNum++;
  }
  return rankNum;
};

const getRankMessage = (rankNum: number, formatMessage: FormatMessageFunc) => {
  switch (rankNum) {
    case 1:
      return formatMessage("low");
    case 2:
      return formatMessage("lower");
    case 3:
      return formatMessage("higher");
    case 4:
      return formatMessage("high");
    default:
      return "";
  }
};

export default React.memo<QuarterBackRankProps>(({ rank, size, className }) => {
  const rankNum = calculateRankNum(rank, size);
  const formatMessage = useFormatMessage();
  return (
    <div className={cn(style.QuarterBackRank, className)}>
      <span className={style.DescribeText}>
        {getRankMessage(rankNum, formatMessage)}
      </span>
      {mapIndexed((_: number, index: number) => (
        <div
          style={{
            backgroundColor:
              rankNum >= index + 1 ? fastNth(index)(colors) : null,
          }}
          className={style.Item}
          key={index}
        ></div>
      ))(new Array(4))}
    </div>
  );
});
