import React, { useMemo } from "react";
import goldMedal from "@/assets/goldMedal.svg";
import silverMedal from "@/assets/silverMedal.svg";
import bronzeMedal from "@/assets/bronzeMedal.svg";
import style from "../index.module.less";

const CompetitionRankShow = ({ rankNum }: { rankNum: number }): JSX.Element => {
  const rankIcon = useMemo(() => {
    switch (rankNum) {
      case 1:
        return <img src={goldMedal} />;
        break;
      case 2:
        return <img src={silverMedal} />;
        break;
      case 3:
        return <img src={bronzeMedal} />;
        break;
      default:
        return <span className={style.Medal}>{rankNum || "--"}</span>;
        break;
    }
  }, [rankNum]);
  return <>{rankIcon}</>;
};

export default React.memo(CompetitionRankShow);
