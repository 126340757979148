import { Cascader } from "antd";
import cn from "classnames";
import { prop } from "lodash/fp";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp, normalize } from "@/util/opt";
import { useAppSelector } from "@/hooks/redux";
import style from "./index.module.less";
import { useMemoizedFn } from "ahooks";
import { defaultSearchId } from "@/components/headerSearch/constant";
import { headerSearchOptionObj } from "../../constant";
import { RowVirtualizerFixed } from "@/components/fundReplaceRule/components/fundCascader";

type HeaderSearchProps = {
  value: string;
  onChange: (value: string) => void;
  selectorType?: "funds" | "fundManagers" | "fundCompanies" | "stocks";
  className?: string;
};
const FundStockSelect = ({
  selectorType,
  className,
  value,
  onChange,
}: HeaderSearchProps): JSX.Element => {
  const defaultSearchType = useMemo(
    () => selectorType || defaultSearchId,
    [selectorType]
  );

  const { selector } = useMemo(
    () => fastProp(defaultSearchType)(headerSearchOptionObj) || {},
    [defaultSearchType]
  );
  const data = useAppSelector(prop(`entities.${selector}`));
  const formatMessage = useFormatMessage();
  const selectRef = useRef<any>(null);
  const closeSelect = useMemoizedFn(() => {
    if (selectRef.current) selectRef.current?.blur();
  });

  const [inputValue, setInputValue] = useState("");

  const changeValue = useCallback(
    (v: string[] | any) => {
      onChange(v);
      closeSelect();
      setInputValue("");
    },
    [onChange, closeSelect]
  );

  const displayRender = useMemoizedFn(() => {
    const childInfoMap = normalize("code")(data);
    return prop(`${value}.name`)(childInfoMap);
  });

  return (
    <>
      <Cascader
        className={cn(className, style.FundCascader)}
        ref={selectRef}
        onChange={changeValue}
        placeholder={formatMessage("selectFundTip")}
        value={[value]}
        dropdownRender={() => (
          <div className={style.childPart}>
            <RowVirtualizerFixed
              childInfo={data as Record<string, any>[]}
              changeValue={changeValue}
              selectedValue={value}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </div>
        )}
        displayRender={displayRender}
      />
    </>
  );
};

export default FundStockSelect;
