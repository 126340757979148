export interface SearchOptionInterface {
  id: string;
  message: string;
  placeholderMessage: string;
  selector: string;
}

export const headerSearchOptionObj: Record<string, SearchOptionInterface> = {
  funds: {
    id: "funds",
    message: "funds",
    placeholderMessage: "fundsPlaceholderMessage",
    selector: "funds",
  },
  fundManagers: {
    id: "fundManagers",
    message: "fundManagers",
    placeholderMessage: "fundManagersPlaceholderMessage",
    selector: "managerName",
  },
  fundCompanies: {
    id: "fundCompanies",
    message: "fundCompanies",
    placeholderMessage: "fundCompaniesPlaceholderMessage",
    selector: "companyName",
  },
};
export const defaultSearchId = "funds";
