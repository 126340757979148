import { UNLIMITED } from "@/constant/fundFilter";
import { CheckBoxItem } from "@/model/checkBoxGroup";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { map } from "lodash/fp";
import { useCallback } from "react";
import style from "./checkBoxGroup.module.less";

type RadioBoxGroupProps = {
  list?: CheckBoxItem[];
  value: string;
  onChange: (v: string) => void;
  buttonSize?: "large" | "middle" | "small";
  spacingSize?: number | [number, number];
};
const RadioBoxGroup = ({
  list,
  value,
  onChange,
  buttonSize,
  spacingSize,
}: RadioBoxGroupProps): JSX.Element => {
  const checkItem = useCallback(
    (v: string) => {
      if (v === value) {
        onChange(UNLIMITED);
      } else {
        onChange(v);
      }
    },
    [onChange, value]
  );
  return (
    <Space className={style.Button} size={spacingSize || [8, 4]} wrap>
      {map((item: CheckBoxItem) =>
        !item.render ? (
          <Button
            size={buttonSize || "small"}
            key={item.id}
            type={item.id === value ? "primary" : undefined}
            onClick={() => checkItem(item.id)}
          >
            {item.name}
            {item.id === value && <CheckCircleOutlined />}
          </Button>
        ) : (
          <div key={item.id}>{item.render()}</div>
        )
      )(list)}
    </Space>
  );
};

export default RadioBoxGroup;
