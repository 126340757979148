import { dateFormat } from "@/util/dateFormat";
import { dateProcess } from "@/util/processedDates";
import dayjs from "dayjs";
export interface RangeInterface {
  id: string;
  message: string;
  period?: number;
  count?: string;
  checkIsValid?: (
    start: string,
    end: string,
    processedTradingDates: Record<string, any>,
    tradingDateSize?: number
  ) => boolean;
}

export const getThisYear = (date?: string) =>
  dateFormat(dayjs(date).startOf("year"));

export const fromThisYearValidator = ({
  startDate,
  endDate,
  processedTradingDate,
  tradingDateSize,
  thisYearDate,
}: {
  startDate: string;
  endDate: string;
  processedTradingDate: Record<string, any>;
  tradingDateSize?: number;
  // 自定义的今年日期，如果是组合或者是回测，今年开始是以回测结束日期或组合结束日期为今年
  thisYearDate?: string;
}) => {
  if (!tradingDateSize) return false;
  let tradingDateCount = 0;
  let endDay = dayjs(endDate);
  while (
    endDay.isSameOrAfter(getThisYear(thisYearDate)) &&
    endDay.isSameOrAfter(startDate) &&
    tradingDateCount < tradingDateSize
  ) {
    if (dateProcess(endDay.format("YYYY-MM-DD"), processedTradingDate))
      tradingDateCount += 1;
    endDay = endDay.subtract(1, "day");
  }
  return tradingDateCount >= tradingDateSize;
};

export const RECENT_TRADING_DAY = "RECENT_TRADING_DAY";
export const RECENT_WEEK = "RECENT_WEEK";
export const RECENT_MONTH = "RECENT_MONTH";
export const RECENT_THREE_MONTH = "RECENT_THREE_MONTH";
export const RECENT_HALF_YEAR = "RECENT_HALF_YEAR";
export const FROM_THIS_YEAR = "FROM_THIS_YEAR";
export const RECENT_YEAR = "RECENT_YEAR";
export const RECENT_TWO_YEAR = "RECENT_TWO_YEAR";
export const RECENT_THREE_YEAR = "RECENT_THREE_YEAR";
export const RECENT_FIVE_YEAR = "RECENT_FIVE_YEAR";
export const FROM_CREATION = "FROM_CREATION";
export const FROM_APPOINTMENT = "FROM_APPOINTMENT";

export const statisticRange: Record<string, RangeInterface> = {
  [RECENT_TRADING_DAY]: {
    id: RECENT_TRADING_DAY,
    message: "FROM_LATELY_TRADING_DATE",
    checkIsValid: () => true,
  },
  [RECENT_WEEK]: {
    id: RECENT_WEEK,
    message: "RECENT_WEEK",
    period: 1,
    count: "week",
  },
  [RECENT_MONTH]: {
    id: RECENT_MONTH,
    message: "RECENT_MONTH",
    period: 1,
    count: "month",
  },
  [RECENT_THREE_MONTH]: {
    id: RECENT_THREE_MONTH,
    message: "RECENT_THREE_MONTH",
    period: 3,
    count: "month",
  },
  [RECENT_HALF_YEAR]: {
    id: RECENT_HALF_YEAR,
    message: "RECENT_HALF_YEAR",
    period: 6,
    count: "month",
  },
  [FROM_THIS_YEAR]: {
    id: FROM_THIS_YEAR,
    message: "FROM_THIS_YEAR",
    checkIsValid: (startDate, endDate, processedTradingDate, tradingDateSize) =>
      fromThisYearValidator({
        startDate,
        endDate,
        processedTradingDate,
        tradingDateSize,
      }),
  },
  [RECENT_YEAR]: {
    id: RECENT_YEAR,
    message: "RECENT_YEAR",
    period: 1,
    count: "year",
  },
  [RECENT_TWO_YEAR]: {
    id: RECENT_TWO_YEAR,
    message: "RECENT_TWO_YEARS",
    period: 2,
    count: "year",
  },
  [RECENT_THREE_YEAR]: {
    id: RECENT_THREE_YEAR,
    message: "RECENT_THREE_YEAR",
    period: 3,
    count: "year",
  },
  [RECENT_FIVE_YEAR]: {
    id: RECENT_FIVE_YEAR,
    message: "RECENT_FIVE_YEAR",
    period: 5,
    count: "year",
  },
  [FROM_CREATION]: {
    id: FROM_CREATION,
    message: "FROM_CREATION",
    checkIsValid: () => true,
  },
};

export const COMMON_TIME = "COMMON_TIME";

export const yieldStatisticRange = {
  [COMMON_TIME]: {
    id: COMMON_TIME,
    message: "commonTime",
  },
  ...statisticRange,
};
