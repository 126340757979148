import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, Input } from "antd";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { first, flow, orderBy, prop } from "lodash/fp";
import CaptchaImg from "@/components/captchaImg";
import { setLocalStorage } from "@/util/localStorage";
import { AUTHORIZATION } from "@/const";
import { fastProp } from "@/util/opt";
import errorParser from "@/util/errorParser";
import {
  LoginEntity,
  createEmptyLogin,
  createLoginFormStatus,
  LoginFormStatusInterface,
} from "@/model/login";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchUserById, getDataSource } from "@/store/users";
import { PropsForm } from "./constant";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";
import { useFormStatus } from "@/hooks/formStatus";
import { passwordReg } from "@/util/formRules";
import ErrMessage from "@/components/errMessage";
import CustomIcon from "@/components/customIcon";
import { passwordIconRender } from "@/components/customIcon/hook";
import cn from "classnames";
import { NameIcon } from "@/components/icons";

const IconStyle = {
  width: 148,
  height: 50,
  display: "inline-block",
  color: "",
};

const captchaPrefix = "/api/sessions/captcha";
function useRefreshCaptcha(): [string, string, () => void] {
  const [uuid] = useState(v4());
  const [url, changeUrl] = useState(
    `${captchaPrefix}/${uuid}?_t=${new Date().getTime()}`
  );
  return [
    uuid,
    url,
    useCallback<() => void>(() => {
      changeUrl(`${captchaPrefix}/${uuid}?_t=${new Date().getTime()}`);
    }, [changeUrl, uuid]),
  ];
}

function useLoginFormSetting(
  uuid: string,
  changeCaptcha: () => void
): [
  LoginEntity,
  (arg0: string) => React.ChangeEventHandler<HTMLInputElement>,
  React.FormEventHandler,
  string | undefined,
  string | undefined,
  boolean
] {
  const [loginInfo, setLoginInfo] = useState<LoginEntity>(
    createEmptyLogin(uuid, "")
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDataSource())
      .unwrap()
      .then((datasource) => {
        const datasourceId = flow(
          orderBy("sort", "asc"),
          first,
          fastProp("id")
        )(datasource);
        setLoginInfo((data) => ({
          ...data,
          datasourceId,
        }));
      });
  }, [dispatch]);
  const [errMessage, setErrMessage] = useState<string>();
  const [captchaErrMessage, setCaptchaErrMessage] = useState<string>();
  const changeLoginInfo = useCallback(
    (fieldName: string) => (e: { target: { value: string } }) => {
      setErrMessage(undefined);
      setLoginInfo({
        ...loginInfo,
        [fieldName]: e.target.value,
      });
    },
    [setLoginInfo, loginInfo]
  );
  const navigate = useNavigate();
  const onLogin = useCallback(async () => {
    try {
      const response = await dispatch(
        fetchUserById(loginInfo as LoginEntity)
      ).unwrap();
      setLocalStorage(AUTHORIZATION, response?.authorization);
      navigate("/manage/dataBoard");
    } catch (error: any) {
      if (error?.message?.captcha) {
        setCaptchaErrMessage(errorParser(error.message, error.status));
      } else {
        setErrMessage(errorParser(error.message, error.status));
      }
      changeCaptcha();
    }
  }, [loginInfo, navigate, dispatch, changeCaptcha]);
  const loginLoading = useAppSelector<boolean>(
    prop(`global.loadings.${fetchUserById.typePrefix}`)
  );
  return [
    loginInfo,
    changeLoginInfo,
    onLogin,
    errMessage,
    captchaErrMessage,
    loginLoading,
  ];
}

const LoginForm: React.FC<PropsForm> = () => {
  const [uid, captcha, changeCaptcha] = useRefreshCaptcha();
  const [loginInfo, changeLoginInfo, onLogin, errMessage, captchaErrMessage] =
    useLoginFormSetting(uid, changeCaptcha);
  const [, setFormStatus] = useFormStatus<LoginFormStatusInterface>(
    createLoginFormStatus
  );
  const passwordInput = useRef<any>(null);
  const captchaInput = useRef<any>(null);
  useEffect(() => {
    if (errMessage) {
      passwordInput.current?.focus();
    }
  }, [errMessage]);
  const formatMessage = useFormatMessage();
  const errTip = errMessage || captchaErrMessage;
  return (
    <div className={style.LoginFrom}>
      <Form className={style.From} initialValues={loginInfo} onFinish={onLogin}>
        {errTip && (
          <ErrMessage className={style.ErrMessage} errMessage={errTip} />
        )}
        <p className={style.PlatformName}>
          {/* {formatMessage("advisoryPlatform")} */}
          <NameIcon style={IconStyle} />
        </p>

        {/* <p className={style.PlatformLogin}>{formatMessage("systemLogin")}</p> */}
        <Form.Item
          name="username"
          validateTrigger={"onBlur"}
          rules={[
            {
              pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
              message: formatMessage("eMailError"),
            },
            {
              required: true,
              message: formatMessage("userNameEmpty"),
            },
          ]}
        >
          <Input
            placeholder={formatMessage("userName")}
            value={loginInfo.username}
            prefix={<CustomIcon type="userIcon" />}
            onFocus={setFormStatus("username", true)}
            onBlur={setFormStatus("username", false)}
            onChange={changeLoginInfo("username")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          validateTrigger={"onBlur"}
          rules={[
            {
              pattern: passwordReg,
              message: formatMessage("passwordValidateMessage"),
            },
            {
              required: true,
              message: formatMessage("passwordEmpty"),
            },
          ]}
        >
          <Input.Password
            ref={passwordInput}
            type="password"
            autoComplete="off"
            placeholder={formatMessage("password")}
            prefix={<CustomIcon type="passwordIcon" />}
            value={loginInfo.password}
            onFocus={setFormStatus("password", true)}
            onBlur={setFormStatus("password", false)}
            onChange={changeLoginInfo("password")}
            className={cn(
              style.InputPassword,
              errMessage && style.InputPasswordFocusErr
            )}
            iconRender={passwordIconRender}
          />
        </Form.Item>
        <Form.Item>
          <Form.Item
            name="captcha"
            noStyle
            rules={[
              {
                required: true,
                message: formatMessage("captchaEmpty"),
              },
            ]}
          >
            <div className={style.CaptchaImgBox}>
              <Input
                ref={captchaInput}
                autoComplete="off"
                placeholder={formatMessage("captcha")}
                value={loginInfo.captcha}
                prefix={<CustomIcon type="yanzmIcon" />}
                onChange={changeLoginInfo("captcha")}
                className={cn(
                  style.InputCaptcha,
                  captchaErrMessage && style.InputCaptchaFocusErr
                )}
              />
              <CaptchaImg
                className={style.CaptchaImg}
                src={captcha}
                onClick={changeCaptcha}
              />
            </div>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            {formatMessage("login")}
          </Button>
        </Form.Item>
        <span>{formatMessage("forgetPasswordContactAdministrator")}</span>
      </Form>
    </div>
  );
};

export default React.memo(LoginForm);
