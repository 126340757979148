import { Card } from "antd";
import React from "react";
import YieldStatisticRangePicker from "@/components/yieldStatisticRangePicker";
import {
  RECENT_FIVE_YEAR,
  RECENT_YEAR,
  RECENT_THREE_YEAR,
  FROM_CREATION,
  yieldStatisticRange,
} from "@/constant/statisticRange";
import { flow, isEmpty, pick, toArray, set } from "lodash/fp";
import style from "./index.module.less";
import { useGetScaleChartOptions, useManagerStoreItem } from "../hooks";
import { BaseFundManagerDetailInfoProps } from "../../interface";
import { useFormatMessage } from "@/util/formatMessage";
import LoadingComponent from "@/components/LoadingComponent";
import { getFundManagerScaleTrend } from "@/store/fundManagerDetailSlice";
import EmptyContent from "@/components/emptyContent";
import EchartsWrapper from "@/echarts/echartsWrapper";
import getMessage from "@/util/getMessage";

const defaultOptions = flow(
  pick([RECENT_YEAR, RECENT_THREE_YEAR, RECENT_FIVE_YEAR, FROM_CREATION]),
  set("FROM_CREATION.message", getMessage("sinceTheIndustry")),
  toArray
)(yieldStatisticRange);

export default React.memo<BaseFundManagerDetailInfoProps>(({ id }) => {
  const formatMessage = useFormatMessage();
  const [scaleTrendRange, updateScaleTrendRange] = useManagerStoreItem(
    id,
    "manageFund.scaleRange",
    FROM_CREATION
  );
  const { options, scaleData } = useGetScaleChartOptions(id, scaleTrendRange);
  return (
    <LoadingComponent actions={getFundManagerScaleTrend}>
      <Card className={style.Card}>
        <div className={style.Header}>
          <h3>{formatMessage("manageFundScaleChange")}</h3>
          <YieldStatisticRangePicker
            value={scaleTrendRange}
            onChange={updateScaleTrendRange}
            options={defaultOptions}
          />
        </div>
        {isEmpty(scaleData) ? (
          <EmptyContent
            className={style.EmptyContent}
            message={formatMessage("noData")}
          />
        ) : (
          <div className={style.BarChart}>
            <span className={style.BarChartUnitTip}>{`（${formatMessage(
              "million"
            )}）`}</span>

            <EchartsWrapper options={options} />
          </div>
        )}
      </Card>
    </LoadingComponent>
  );
});
