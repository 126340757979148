import { PORTFOLIO_COLOR, RedColor, YellowColor } from "@/util/colors";
import {
  formatArrayNilToZero,
  formatNilToZero,
  formatPercentage,
} from "@/util/numberFormatter";
import { fastHas, fastNth, fastProp, mapIndexed } from "@/util/opt";
import { cumulativeReturn } from "@/util/transformer";
import { LineSeriesOption } from "echarts";
import { getLineChartOptions } from "@/util/chart";
import { compact, filter, flow, includes, join, map } from "lodash/fp";
import { OptionsType, DailyReturnsWithName } from "./interface";

export const turnoverOptionsConfig = {
  allRange: "allRange",
  warehouseTime: "warehouseTime",
  getTurnoverOptions() {
    return [
      {
        id: this.allRange,
        messageKey: this.allRange,
        value: this.allRange,
      },
      {
        id: this.warehouseTime,
        messageKey: this.warehouseTime,
        value: this.warehouseTime,
      },
    ];
  },
};

const getCumulativeDataSource = (dates: string[], dailyReturns: number[]) => {
  const [calculatedCumulativeReturns, processedDates] = cumulativeReturn(
    dailyReturns,
    dates
  );
  return mapIndexed((date: string, index: number) => [
    date,
    fastNth(index)(calculatedCumulativeReturns),
  ])(processedDates);
};

export const getCumulativeSeries = (dates: string[], options: OptionsType[]) =>
  map<OptionsType, LineSeriesOption>(({ dailyReturns, ...rest }) => {
    const cumulativeDataSource = getCumulativeDataSource(dates, dailyReturns);
    return {
      ...rest,
      type: "line",
      showSymbol: false,
      smooth: false,
      lineStyle: {
        width: 1.5,
      },
      data: cumulativeDataSource,
    };
  })(options);

export const getTurnoverSeries = (data: number[]): LineSeriesOption => ({
  type: "line",
  showSymbol: true,
  symbol: "circle",
  showAllSymbol: true,
  color: RedColor,
  symbolSize: 15,
  zlevel: 5,
  lineStyle: {
    color: "transparent",
    width: 1.5,
  },
  data: data,
});

export const turnoverPositionTooltipFormatter =
  (turnoverDates: Record<string, any>, portfolioAndBenchmarkNames: string[]) =>
  (tooltipInfo: any[]): string => {
    const name = fastProp("name")(fastNth(0)(tooltipInfo));
    const isTurnoverDate = fastHas(name)(turnoverDates);
    const tooltip = filter(({ seriesName }) =>
      includes(seriesName)(portfolioAndBenchmarkNames)
    )(tooltipInfo);
    const content = flow(
      map(({ seriesName, value, marker }: any) =>
        !value
          ? ""
          : `<p style="margin: 8px 0;">${marker}${seriesName}:  ${formatPercentage(
              fastNth(1)(value)
            )}</p>`
      ),
      join("")
    )(tooltip);
    return `<div>
<p>${name}</p>
${isTurnoverDate ? "<p>是否为调仓日：是</p>" : ""}
${content}</div>`;
  };

export const getAllRangeChartConfigs = ({
  turnoverDateMap,
  onChangeButtonGroupValue,
  onChangeTurnoverDate,
  portfolioInfo,
  benchmarkInfo,
  tradingDate,
}: {
  portfolioInfo: DailyReturnsWithName;
  benchmarkInfo: DailyReturnsWithName;
  tradingDate: string[];
  turnoverDateMap: Record<string, any>;
  onChangeButtonGroupValue: React.Dispatch<React.SetStateAction<string>>;
  onChangeTurnoverDate: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) => {
  const [portfolioSeries, benchmarkSeries] = getCumulativeSeries(tradingDate, [
    {
      name: portfolioInfo.name,
      color: PORTFOLIO_COLOR,
      dailyReturns: formatArrayNilToZero(portfolioInfo.dailyReturns),
    },
    {
      name: benchmarkInfo.name,
      color: YellowColor,
      dailyReturns: benchmarkInfo.dailyReturns,
    },
  ]);

  // 设置调仓日数据
  const turnoverData = mapIndexed((date: string, index: number) =>
    fastHas(date)(turnoverDateMap)
      ? fastNth(index)(portfolioSeries.data as number[])
      : null
  )(tradingDate);

  const series = [
    portfolioSeries,
    benchmarkSeries,
    getTurnoverSeries(turnoverData),
  ];

  const options = getLineChartOptions(
    tradingDate,
    compact(map("name")(series)),
    {
      tooltip: {
        formatter: turnoverPositionTooltipFormatter(turnoverDateMap, [
          portfolioSeries.name as string,
          benchmarkSeries.name as string,
        ]) as any,
      },
    }
  );

  const events = {
    click: (info: any) => {
      const date = fastProp("name")(info);
      if (fastHas(date)(turnoverDateMap)) {
        onChangeTurnoverDate(date);
        onChangeButtonGroupValue(turnoverOptionsConfig.warehouseTime);
      }
    },
  };

  return {
    series,
    options,
    events,
  };
};

export const defaultSeriesConfig = {
  series: [],
  options: {},
  events: undefined,
};
export const getTurnoverDateChartConfigs = ({
  selectedTurnoverDate,
  portfolioInfo,
  benchmarkInfo,
  unTurnoverInfo,
  turnoverTradingDates,
}: {
  selectedTurnoverDate: string | undefined;
  portfolioInfo: DailyReturnsWithName;
  benchmarkInfo: DailyReturnsWithName;
  unTurnoverInfo: DailyReturnsWithName;
  turnoverTradingDates: string[];
}) => {
  if (!selectedTurnoverDate) {
    return defaultSeriesConfig;
  }
  const portfolioDailyReturns = map<string, number>((date) =>
    formatNilToZero(fastProp(date)(portfolioInfo.dailyReturnsMap))
  )(turnoverTradingDates);
  const benchmarkDailyReturns = map<string, number>((date) =>
    formatNilToZero(fastProp(date)(benchmarkInfo.dailyReturnsMap))
  )(turnoverTradingDates);
  const unTurnoverDailyReturns = map<string, number>((date) =>
    formatNilToZero(fastProp(date)(unTurnoverInfo.dailyReturnsMap))
  )(turnoverTradingDates);
  const series = getCumulativeSeries(turnoverTradingDates, [
    {
      name: portfolioInfo.name,
      color: PORTFOLIO_COLOR,
      dailyReturns: portfolioDailyReturns,
    },
    {
      name: unTurnoverInfo.name,
      color: RedColor,
      dailyReturns: unTurnoverDailyReturns,
    },
    {
      name: benchmarkInfo.name,
      color: YellowColor,
      dailyReturns: benchmarkDailyReturns,
    },
  ]);
  const options = getLineChartOptions(
    turnoverTradingDates,
    compact(map("name")(series))
  );
  return {
    series,
    options,
    events: undefined,
  };
};
