import { PromiseType } from "@/model/promise";
import { getWithCache } from "@/util/axios";

export type DailyReturns = {
  dates: string[];
  dailyReturns: number[];
};

export type FundSameTypeBody = {
  fundType: string;
  from?: string;
  to?: string;
};

export const getDailyReturns = (fundId: string): PromiseType<DailyReturns> =>
  getWithCache(`/fund/compare/daily_return/${fundId}`);

export const getFundSameTypeReturns = (
  queryData: FundSameTypeBody
): PromiseType<DailyReturns> =>
  getWithCache(`/openfunds/same_type_return`, queryData);
