import { useFormatMessage } from "@/util/formatMessage";
import { mapIndexed } from "@/util/opt";
import { ManualCreatePortfolio } from "@/views/portfolioManage/manualCreatePortfolio/constant";
import { filter, isEmpty, join, map, size } from "lodash/fp";
import React, { useMemo } from "react";
import style from "./index.module.less";

export default React.memo<{
  fundAsset: ManualCreatePortfolio[];
}>(({ fundAsset }) => {
  const formatMessage = useFormatMessage();
  const fundErrorAsset = useMemo(
    () => filter("errorField")(fundAsset),
    [fundAsset]
  );
  const turnoverDateText = useMemo(
    () => join("、")(map("turnoverDate")(fundErrorAsset)),
    [fundErrorAsset]
  );
  return isEmpty(fundErrorAsset) ? null : (
    <div className={style.ErrorFiledContainer}>
      <p>
        {formatMessage("about")}{" "}
        <span className={style.ErrorColor}>{turnoverDateText}</span>
      </p>
      {formatMessage("FundErrorAssetDescription", {
        size: size(fundErrorAsset),
      })}
      <br />
      {formatMessage("FailureReason")}：
      {mapIndexed(
        (
          { turnoverDate, errorField }: ManualCreatePortfolio,
          index: number
        ) => (
          <p key={turnoverDate}>
            {index + 1}. {turnoverDate} : {errorField}
          </p>
        )
      )(fundErrorAsset)}
    </div>
  );
});
