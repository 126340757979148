import { NetValuesType } from "@/model/fundDetail";
import { RootState } from "@/store";
import { fastNth, fastProp, getProp, normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { last } from "lodash";
import {
  first,
  flow,
  isEmpty,
  map,
  omit,
  orderBy,
  prop,
  split,
} from "lodash/fp";
import { MOCK_EARNINGS_FACTORS } from "../constant";

export const fundBasicInfoSelector = createSelector(
  (state: RootState) => state.fundDetail,
  (_: RootState, fundId: string) => fundId,
  (fundDetail, fundId) => fastProp(fundId)(fundDetail)
);

export const fundBasicSelector = createSelector(
  fundBasicInfoSelector,
  (fundBasic) => {
    const fundManagerRatings = fastProp("fundManagerRatings")(fundBasic);
    const basicFundManager = flow(
      prop("basic.fundManager"),
      split("、"),
      map((v: string) => ({
        fundManagerName: v,
        rating: 0,
        managerId: "",
      }))
    )(fundBasic);
    const fundManager = isEmpty(fundManagerRatings)
      ? basicFundManager
      : fundManagerRatings;
    const fundCompany = fastProp("fundCompany")(fundBasic);
    return {
      ...fundBasic,
      basic: {
        ...fundBasic?.basic,
        fundManager,
        fundCompany,
      },
    };
  }
);

export const fundBasicTitleSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => ({
    code: getProp("basic.code")(fundBasic),
    name: getProp("basic.name")(fundBasic),
    fundStyle: fastProp("fundStyle")(fundBasic),
    fundTheme: fastProp("fundTheme")(fundBasic),
    fundRating: flow(
      fastProp("fundRatingTrend"),
      orderBy("reportDate", "desc"),
      first,
      fastProp("bfRating")
    )(fundBasic),
  })
);

export const latestNetValueSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => {
    const netValue: NetValuesType[] = flow(
      fastProp("netValues"),
      orderBy("tradingDate", "desc")
    )(fundBasic);
    const isCurrencyFund = fastProp("isCurrencyFund")(fundBasic);
    const latestNetValue = first(netValue);
    if (isCurrencyFund) {
      const currencyReturn = fastProp("currencyReturn")(fundBasic);
      return {
        netValueIncrease: "",
        navUnit: fastProp("tenThousandPerReturn")(currencyReturn),
        navCum: fastProp("sevenDaysAnnualReturn")(currencyReturn),
        tradingDate: fastProp("tradingDay")(currencyReturn),
        isCurrencyFund,
      };
    }
    const netValueIncrease =
      (fastProp("navUnit")(latestNetValue) -
        fastProp("navUnit")(fastNth(1)(netValue))) /
      fastProp("navUnit")(fastNth(1)(netValue));

    return {
      ...latestNetValue,
      netValueIncrease,
      isCurrencyFund,
    };
  }
);

export const beforeNetValueSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => {
    const netValue: NetValuesType[] = flow(
      fastProp("netValues"),
      orderBy("tradingDate", "desc")
    )(fundBasic);
    const beforeNetValue = last(netValue);
    const netValueIncrease =
      fastProp("navUnit")(beforeNetValue) -
      (fastProp("navUnit")(netValue?.[1]) || 0);
    return {
      ...beforeNetValue,
      netValueIncrease,
    };
  }
);
const basicOmit = ["fundId", "name", "benchmark"];
export const compareTableDataSourceSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => {
    const dataSource = {
      stabilityOfStyle: fastProp("stabilityOfStyle")(fundBasic),
      fundStyle: fastProp("fundStyle")(fundBasic),
      fundScale: flow(
        fastProp("fundScaleTrend"),
        orderBy("reportDate", "desc"),
        first,
        fastProp("scale")
      )(fundBasic),
      ...flow(fastProp("basic"), omit(basicOmit))(fundBasic),
    };
    return dataSource;
  }
);

export const rankValueSelector = createSelector(
  fundBasicSelector,
  (fundBasic) =>
    flow(fastProp("factorRankValue"), normalize("section"), (factorRankValue) =>
      map((v: { section: string; message: string }) => ({
        ...v,
        value: prop(`${v.section}.value`)(factorRankValue),
      }))(MOCK_EARNINGS_FACTORS)
    )(fundBasic)
);
export const selectedFundIdsSelector = createSelector(
  (state: RootState) => state.fundDetail,
  (fundDetail) => fastProp("selectedFundIds")(fundDetail)
);
export const comparedFundsSelector = createSelector(
  (state: RootState) => state.fundDetail,
  (fundDetail) => fastProp("comparedFunds")(fundDetail)
);
export default createSelector(
  fundBasicSelector,
  fundBasicTitleSelector,
  latestNetValueSelector,
  compareTableDataSourceSelector,
  rankValueSelector,
  selectedFundIdsSelector,
  (
    fundBasic,
    fundBasicTitle,
    latestNetValue,
    compareTableDataSource,
    rankValue,
    selectedFundIds
  ) => ({
    fundBasic,
    fundBasicTitle,
    latestNetValue,
    compareTableDataSource,
    rankValue,
    selectedFundIds,
  })
);
