import React, { memo, useEffect, useMemo, useState } from "react";
import { Button, Cascader, Input } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  flow,
  last,
  prop,
  slice,
  size,
  groupBy,
  map,
  concat,
  reverse,
  identity,
  first,
  findLast,
  find,
} from "lodash/fp";
import dayjs from "dayjs";
import { useMemoizedFn } from "ahooks";
import { mapIndexed } from "@/util/opt";
import { treeToMap } from "@/util/opt/tree";
import { useFormatMessage } from "@/util/formatMessage";

import style from "./index.module.less";

export interface DisclosureDateCascaderProps {
  dates?: string[];
  value?: string;
  onChange?: (date: string) => void;
}

const date2Quarter = {
  "12-31": "Q4",
  "09-30": "Q3",
  "06-30": "Q2",
  "03-31": "Q1",
};

export default memo<DisclosureDateCascaderProps>(
  ({ dates = [], value, onChange = identity }) => {
    const formatMessage = useFormatMessage();
    const [date, setDate] = useState<string>("");

    useEffect(() => {
      value && setDate(value);
    }, [value, setDate]);

    const { sortedDates, latestDate, firstDate } = useMemo(() => {
      const sortedDates = dates?.sort();
      return {
        sortedDates,
        latestDate: last<string>(sortedDates),
        firstDate: first<string>(sortedDates),
      };
    }, [dates]);

    const options = useMemo(() => {
      if (!sortedDates) return [];
      const latestDateMD = dayjs(latestDate).format("MM-DD");
      const estimateDate = prop(latestDateMD)(date2Quarter) ? "" : latestDate;
      const disculsoureDates = estimateDate
        ? slice(0, size(sortedDates) - 1)(sortedDates)
        : sortedDates;
      const latestDateLabel = `${dayjs(latestDate).format(
        "MM-DD"
      )}(${formatMessage("dateEstimate")})`;
      const disculsoureDateOptions: [] = flow(
        groupBy((date: string) => dayjs(date).format("YYYY")),
        mapIndexed((dates: string[], year: string) => ({
          label: year,
          value: year,
          children: map((date: string) => {
            const quarter = prop(dayjs(date).format("MM-DD"))(date2Quarter);
            return {
              label: quarter,
              value: date,
              displayValue: `${year} ${quarter}`,
            };
          })(dates?.sort()),
        })),
        reverse
      )(disculsoureDates);

      if (estimateDate) {
        return concat({
          label: formatMessage("latestDate"),
          value: "latestDate",
          children: [
            {
              label: latestDateLabel,
              value: latestDate,
              displayValue: latestDateLabel,
            },
          ],
        })(disculsoureDateOptions);
      }
      return disculsoureDateOptions;
    }, [sortedDates, latestDate, formatMessage]);

    const optionsMap = useMemo(
      () => treeToMap({ idKey: "value", needPath: true })(options),
      [options]
    );

    const previousButtonDisable = useMemo(() => {
      if (firstDate && date <= firstDate) {
        return true;
      }
      return false;
    }, [date, firstDate]);

    const nextButtonDisable = useMemo(() => {
      if (latestDate && date >= latestDate) {
        return true;
      }
      return false;
    }, [date, latestDate]);

    const changeDate = useMemoizedFn((v?: string) => {
      if (v) {
        setDate(v);
        onChange(v);
      }
    });

    const findPreviousDate = useMemoizedFn(() => {
      if (!date || !firstDate) return;
      if (date > firstDate) {
        const previousDate = findLast((v: string) => v < date)(sortedDates);
        previousDate && changeDate(previousDate);
      }
    });

    const findNextDate = useMemoizedFn(() => {
      if (!date || !latestDate) return;
      if (date < latestDate) {
        const nextDate = find((v: string) => v > date)(sortedDates);
        nextDate && changeDate(nextDate);
      }
    });

    return (
      <Input.Group compact className={style.Container}>
        <Button
          icon={<LeftOutlined />}
          onClick={findPreviousDate}
          disabled={previousButtonDisable}
        />
        <Cascader
          value={optionsMap?.[date]?.path || []}
          options={options}
          className={style.Cascader}
          placement="bottomRight"
          onChange={(v: any) => {
            changeDate(last(v));
          }}
          expandTrigger="hover"
          allowClear={false}
          displayRender={(label, selectedOptions) => {
            return flow(last, prop("displayValue"))(selectedOptions);
          }}
        />
        <Button
          icon={<RightOutlined />}
          onClick={findNextDate}
          disabled={nextButtonDisable}
        />
      </Input.Group>
    );
  }
);
