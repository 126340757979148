import PortfolioBasicInfo, {
  validateFormError,
} from "@/components/portfolioCompoents/portfolioBasicInfo";
import FundReplaceRule, {
  validateFundReplaceError,
} from "@/components/fundReplaceRule";
import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { useMemoizedFn, useCreation } from "ahooks";
import { fastProp } from "@/util/opt";
import { editPortfolio } from "@/store/portfolioList";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import type { InitSimpleDataType } from "@/components/portfolioCompoents/hook";
import { formatPortfolioValue } from "@/components/portfolioCompoents/constant";
import { useForm } from "antd/lib/form/Form";
import { useGetCurrentPortfolio, useGetReplaceFundIds } from "./hooks";
import style from "./index.module.less";
import { Button, message, Space } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { useAppDispatch } from "@/hooks/redux";
import { ReplaceRuleType } from "@/components/fundReplaceRule/hooks";
import { compact, mapValues } from "lodash/fp";
import { defalueCashHold } from "@/components/fundReplaceRule/constant";

export default React.memo<{
  portfolioId: string;
}>(({ portfolioId }) => {
  const { stackBack } = useContext(PlatformNavigationContext);
  const currentPortfolio = useGetCurrentPortfolio(portfolioId);
  const initialValues = useCreation<InitSimpleDataType>(
    () => ({
      name: fastProp("name")(currentPortfolio),
      date: fastProp("foundDate")(currentPortfolio),
      initScale: fastProp("initScale")(currentPortfolio),
      shareRange: fastProp("shareRange")(currentPortfolio),
      strategy: fastProp("strategy")(currentPortfolio),
      benchmark: {
        benchmarkId: fastProp("benchmark")(currentPortfolio),
        benchmarkType: fastProp("benchmarkType")(currentPortfolio),
      },
      description: fastProp("description")(currentPortfolio),
    }),
    [currentPortfolio]
  );
  const formatMessage = useFormatMessage();
  const [form] = useForm();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [replaceRule, setReplaceRule] = useState<ReplaceRuleType>(
    fastProp("fundReplaceRule")(currentPortfolio)
      ? fastProp("fundReplaceRule")(currentPortfolio)
      : defalueCashHold
  );

  const onValuesChange = useMemoizedFn(
    (changeValue, allValue: InitSimpleDataType) => {
      setButtonDisabled(validateFormError(allValue));
    }
  );
  useEffect(() => {
    setButtonDisabled(validateFundReplaceError(replaceRule));
  }, [replaceRule]);

  const dispatch = useAppDispatch();
  const onSubmit = useMemoizedFn(() => {
    form.validateFields().then((data: InitSimpleDataType) => {
      dispatch(
        editPortfolio({
          portfolioId: fastProp("id")(currentPortfolio),
          ...formatPortfolioValue(data),
          fundReplaceRule: {
            ...replaceRule,
            replaceFund: mapValues((ids: string[]) => compact(ids))(
              fastProp("replaceFund")(replaceRule)
            ),
          },
        })
      )
        .unwrap()
        .then(() => {
          message.success(formatMessage("updatePortfolioSuccess"));
          stackBack();
        });
    });
  });

  const fundList = useGetReplaceFundIds(currentPortfolio);

  return (
    <div className={style.ChangingPositionForm}>
      <div className={style.ScrollArea}>
        <PortfolioBasicInfo
          initialValues={initialValues}
          form={form}
          disabledInitScale
          onValuesChange={onValuesChange}
        />
        <FundReplaceRule
          className={style.MarginTop12}
          fundList={fundList}
          value={replaceRule}
          onChange={setReplaceRule}
        />
      </div>
      <footer
        className={cn(style.FormCard, style.ButtonOperate, style.LayoutFooter)}
      >
        <Space>
          <Button className={style.OperateButton} onClick={stackBack}>
            {formatMessage("cancel")}
          </Button>
          <Button
            className={style.OperateButton}
            type="primary"
            disabled={buttonDisabled}
            onClick={onSubmit}
          >
            {formatMessage("ok")}
          </Button>
        </Space>
      </footer>
    </div>
  );
});
