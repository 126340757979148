import React, { useState, useEffect } from "react";
import cn from "classnames";
import { first, isEmpty, last } from "lodash/fp";
import { Space } from "antd";
import LoadingComponent from "@/components/LoadingComponent";
import { useFormatMessage } from "@/util/formatMessage";
import { CommonProps } from "@/views/aum/type";
import { useGetAumPortfolioIndexInfo } from "@/hooks/aum";
import { fastProp } from "@/util/opt";
import BrinsonTreeChart from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/brinsonAttribution/components/brinsonTreeChart";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchBrinsonAttribution } from "@/store/aum";
import BrinsonTable from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/brinsonAttribution/components/brinsonTable";
import { frequencyData } from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/constant";
import SummaryCard from "@/views/portfolioManage/portfolioAnalysis/components/summaryCard";
import CustomStaticRange from "@/views/portfolioManage/portfolioAnalysis/components/customStaticRange";
import ColorNumber from "@/components/colorNumber";
import EmptyContent from "@/components/emptyContent";
import BrinsonLineChart from "./components/brinsonLineChart";
import { useGetBrinsonTableData, useGetSummaryData } from "./hook";
import { brinsonTreeChartDataSelector } from "./mapper";
import style from "./index.module.less";

export default React.memo<Omit<CommonProps, "id">>(({ assetPortfolioId }) => {
  const formatMessage = useFormatMessage();

  const [statisticRange, setStatisticRange] = useState<string>("FROM_CREATION");

  const indexInfo = useGetAumPortfolioIndexInfo(assetPortfolioId);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (assetPortfolioId) {
      dispatch(
        fetchBrinsonAttribution({
          id: assetPortfolioId,
          section: statisticRange,
        })
      );
    }
  }, [assetPortfolioId, dispatch, statisticRange]);

  const brinsonTreeChartData = useAppSelector(brinsonTreeChartDataSelector);

  const { dates, value, name } = useGetSummaryData();

  const [frequency, setFrequency] = useState(first(frequencyData) || "MONTHLY");

  const brinsonTableData = useGetBrinsonTableData(frequency);

  return (
    <LoadingComponent actions={fetchBrinsonAttribution}>
      <Space>
        <span> {formatMessage("attributionTime")}</span>
        <CustomStaticRange
          value={statisticRange}
          onChange={setStatisticRange}
          startDate={fastProp("historyStart")(indexInfo)}
          endDate={fastProp("historyEnd")(indexInfo)}
        />
        {isEmpty(dates) ? null : (
          <span>
            ({first(dates)}~{last(dates)})
          </span>
        )}
      </Space>
      {/* <ErrorBoundary errKey={errorKey}> */}
      {!isEmpty(dates) ? (
        <>
          <BrinsonLineChart />
          <div className={style.Flex}>
            <div className={cn(style.Chart, style.MarginTop85)}>
              <BrinsonTreeChart brinsonTreeChartData={brinsonTreeChartData} />
            </div>
            <div className={style.detailTable}>
              <BrinsonTable
                selectFrequency={setFrequency}
                brinsonTableData={brinsonTableData}
                frequency={frequency}
              />
            </div>
          </div>
          <SummaryCard
            summaryText={formatMessage("aumBrinsonAttributionSummary", {
              timeRange: (
                <span>
                  {first(dates)} ~ {last(dates)}
                </span>
              ),
              name: formatMessage(name),
              value: <ColorNumber value={value} formatter="percentage" />,
            })}
          />
        </>
      ) : (
        <EmptyContent
          message={formatMessage("noData")}
          className={style.EmptyContent}
        />
      )}
      {/* </ErrorBoundary> */}
    </LoadingComponent>
  );
});
