import { useFormatMessage } from "@/util/formatMessage";
import React from "react";
import { Table } from "antd";
import { formatPercentage } from "@/util/numberFormatter";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import Style from "../riskAnalysis/index.module.less";

export default React.memo((summaryData: Record<string, any>) => {
  const formatMessage = useFormatMessage();
  const { weight, currentRisk, stressScenariosRisk } = summaryData.summaryData;

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>
          {formatMessage("total")}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="center">
          {formatPercentage(weight)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="center">
          {formatPercentage(currentRisk)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3} align="center">
          {formatPercentage(stressScenariosRisk)}
          {stressScenariosRisk > currentRisk ? (
            <ArrowUpOutlined className={Style.upIcon} />
          ) : (
            <ArrowDownOutlined className={Style.downIcon} />
          )}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
});
