import getMaxDrawdown from "./maxDrawdown";
import { div, times, minus, toAverage } from "../math";
import { equalZero, formatQuantArrayNilToZero } from "../numberFormatter";
import { flow } from "lodash/fp";
import tradingDateCount from "@/constant/tradingDateCount";

/*
    卡玛比率
    (年化收益-无风险利率)/最大回撤
*/
export default (dailyReturns: number[], riskFreeRate: number[]) => {
  const [formatDailyReturns, formatRiskFreeRate] = formatQuantArrayNilToZero(
    dailyReturns,
    riskFreeRate
  );
  const dailyReturnAvg = toAverage(formatDailyReturns);
  const riskFreeRateAvg = toAverage(formatRiskFreeRate);
  const maxDrawdown = getMaxDrawdown(formatDailyReturns);
  if (!maxDrawdown || equalZero(maxDrawdown)) return NaN;
  return flow(
    minus(riskFreeRateAvg),
    div(Math.abs(maxDrawdown)),
    times(tradingDateCount)
  )(dailyReturnAvg);
};
