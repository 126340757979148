import { Button, Form, Input, Space, Tooltip, message, DatePicker } from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import cn from "classnames";
import {
  map,
  isEmpty,
  omit,
  concat,
  flow,
  includes,
  forEach,
  size,
} from "lodash/fp";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useCreation, useMemoizedFn } from "ahooks";
// import moment, { Moment } from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import dayjs from "dayjs";
import { DATEFORMAT } from "@/util/dateFormat";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useFormatMessage } from "@/util/formatMessage";
import SelectFundDialog from "@/components/portfolioCompoents/selectFundDialog";
import { FundList } from "@/components/portfolioCompoents/fundListTable";
import {
  getTurnoverRecordByDate,
  getPortfolioPositionRecord,
  changeTurnoverRecord,
} from "@/api/portfolioList";
import { fastProp, normalize } from "@/util/opt";
import { useFundPosition as useFundListTable } from "../createSimplePortfolio/hook";
import style from "./index.module.less";
import { useGetCurrentPortfolio, useGetEffectiveDate } from "./hooks";
import { checkIsAfterThreeClock } from "./constant";
import { fixedNumberWithCommas } from "@/util/numberFormatter";
import { equalWeight } from "@/util/formatter";
import {
  assetPortfolios,
  assetsType,
  TurnoverRecord,
} from "@/model/portfolioList";
import ChangingPositionTable from "./components/changingPositionTable";
import { weightRoundTo } from "@/util/formatter";
import { checkDisabledRangeDate } from "../manualCreatePortfolio/constant";

const formLabelCol = {
  span: 2,
};

const useManageFormValues = (
  portfolio: assetPortfolios,
  changeWarehouseDate: (value: string) => void
) => {
  const [formValues, setFormValues] = useState<FundList[]>([]);
  const [scale, setScale] = useState(0);
  const [errorField, setErrorField] = useState("");
  const { id, historyEnd, assets, initScale } = useMemo(
    () => ({
      id: fastProp("id")(portfolio),
      initScale: fastProp("initScale")(portfolio),
      historyEnd: fastProp("historyEnd")(portfolio),
      assets: fastProp("assets")(portfolio) as FundList[],
    }),
    [portfolio]
  );
  const formatMessage = useFormatMessage();
  useEffect(() => {
    if (!historyEnd) {
      setFormValues(
        map<FundList, FundList>((item) => ({
          ...item,
          positionScale: 0,
          currentWeight: 0,
          removable: false,
          editable: false,
        }))(assets)
      );
      setScale(initScale);
      setErrorField(formatMessage("portfolioPositionTip"));
    } else {
      if (id) {
        Promise.all([
          getTurnoverRecordByDate(id),
          getPortfolioPositionRecord(id, historyEnd),
        ]).then(([turnoverRecord, positionDetail]) => {
          const turnoverRecordAssets = fastProp("assets")(turnoverRecord);
          const turnoverTime = fastProp("createTime")(turnoverRecord);
          const positionAssets = fastProp("fundWeights")(positionDetail);
          const normalizePositionAssets =
            normalize<any>("fundId")(positionAssets);
          changeWarehouseDate(fastProp("turnoverDate")(turnoverRecord));
          setScale(fastProp("scale")(positionDetail));
          setFormValues(
            flow(
              map((turnoverRecordAsset: TurnoverRecord["assets"]) => {
                const positionAsset = fastProp(
                  fastProp("fundId")(turnoverRecordAsset)
                )(normalizePositionAssets);
                if (!positionAsset)
                  return {
                    ...turnoverRecordAsset,
                    editable: true,
                    currentWeight: 0,
                  };
                return {
                  ...positionAsset,
                  ...turnoverRecordAsset,
                  currentWeight: fastProp("weight")(positionAsset),
                  editable: true,
                  removable: false,
                };
              }),
              weightRoundTo(undefined, "weight")
            )(
              isEmpty(turnoverRecordAssets)
                ? positionAssets
                : turnoverRecordAssets
            )
          );
          // 如果调过仓并且是在三点之前调的仓，则不可再次调仓
          if (
            checkIsAfterThreeClock() &&
            !checkIsAfterThreeClock(turnoverTime)
          ) {
            setErrorField(formatMessage("portfolioPositionCalcTip"));
            setFormValues(
              map((item) => ({
                ...item,
                editable: false,
                removable: false,
              }))
            );
          }
        });
      }
    }
  }, [assets, formatMessage, historyEnd, id, initScale, changeWarehouseDate]);
  return {
    scale,
    formValues,
    errorField,
  };
};

const useManageChangingPositionValue = (initFormValues: FundList[]) => {
  const [originFundIds, originFundList, onChangeOriginFundList] =
    useFundListTable(initFormValues);
  const [
    appendFundIds,
    appendFundList,
    onChangeAppendFundList,
    onChangeFundIds,
  ] = useFundListTable();
  const mergeFundList = useCreation(
    () => concat(originFundList)(appendFundList),
    [originFundList, appendFundList]
  );
  const positionFundIds = useCreation(
    () => map("fundId")(originFundList),
    [originFundList]
  );
  const handleOnChange = useMemoizedFn(
    (fundId: string, key?: string | undefined) => (v: string | number | null) => {
      if (includes(fundId)(originFundIds))
        return onChangeOriginFundList(fundId, key)(v);
      return onChangeAppendFundList(fundId, key)(v);
    }
  );
  const onSetEqualWeight = useMemoizedFn(() => {
    const equaledWeight = equalWeight<FundList>([...mergeFundList]);
    forEach((item: FundList) =>
      handleOnChange(item.fundId, "weight")(item.weight)
    )(equaledWeight);
  });
  return {
    fundIds: appendFundIds,
    positionFundIds,
    fundList: mergeFundList,
    onChange: handleOnChange,
    onChangeFundIds,
    onSetEqualWeight,
  };
};

export default React.memo<{
  portfolioId: string;
}>(({ portfolioId }) => {
  const { stackBack } = useContext(PlatformNavigationContext);
  const formatMessage = useFormatMessage();
  const effectiveDate = useGetEffectiveDate();
  const [warehouseDate, setWarehouseDate] = useState<string>("");

  const currentPortfolio = useGetCurrentPortfolio(portfolioId);

  const { scale, formValues, errorField } = useManageFormValues(
    currentPortfolio,
    setWarehouseDate
  );
  const {
    fundIds,
    fundList,
    onChange,
    onChangeFundIds,
    positionFundIds,
    onSetEqualWeight,
  } = useManageChangingPositionValue(formValues);
  // const isShowDateTip = useMemo(
  //   () =>
  //     warehouseDate === dayjs().format("YYYY-MM-DD") &&
  //     dayjs().get("hour") >= 15,
  //   [warehouseDate]
  // );
  const onUpdateChangingPosition = useMemoizedFn(() => {
    if (isEmpty(fundList)) {
      message.error(formatMessage("selectFundTip"));
      return;
    }
    // if (isShowDateTip) {
    //   message.error(formatMessage("warehouseDateTip"));
    //   return;
    // }
    changeTurnoverRecord({
      portfolioId,
      assets: map(omit(["editable"]))(fundList) as assetsType[],
      turnoverDate: warehouseDate || effectiveDate,
    }).then(() => {
      message.success(formatMessage("warehouseSuccess"));
      stackBack();
    });
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const disabled = useCreation(
    () => buttonDisabled || !!errorField,
    [buttonDisabled, errorField]
  );
  const maxSelectionSize = useCreation(
    () => 100 - size(positionFundIds),
    [positionFundIds]
  );

  const disabledDate: RangePickerProps["disabledDate"] = useMemoizedFn(
    (date: dayjs.Dayjs) => {
      return (
        date &&
        checkDisabledRangeDate(
          dayjs().subtract(1, "day").format(DATEFORMAT),
          dayjs().add(1, "month").format(DATEFORMAT)
        )(date.format(DATEFORMAT))
      );
    }
  );

  return (
    <Form
      labelCol={formLabelCol}
      labelAlign="left"
      className={style.ChangingPositionForm}
    >
      <div className={style.LayoutScrollYContent}>
        <div className={cn(style.FormCard, style.MarginBottom20)}>
          <h3 className={style.CardTitle}>
            {formatMessage("portfolioBaseInfo")}
          </h3>
          <Form.Item
            label={formatMessage("FofPortfolioName")}
            className={style.FormItem}
          >
            <Input
              className={style.Input}
              value={fastProp("name")(currentPortfolio)}
              disabled
            />
          </Form.Item>
          <Form.Item
            label={formatMessage("portfolioLatestScale")}
            className={style.FormItem}
          >
            <Input
              className={cn(style.NumberInput, style.Input)}
              value={fixedNumberWithCommas(scale / 10000)}
              disabled
              addonAfter={<span>{formatMessage("thousandYuan")}</span>}
            />
          </Form.Item>
          <Form.Item
            className={cn(style.ChangingPositionDate, style.FormItem)}
            label={
              <div>
                <span>{formatMessage("warehouseDate")}</span>
                <Tooltip title={formatMessage("changingPositionDateTip")}>
                  <InfoCircleOutlined
                    className={style.ChangingPositionDateIcon}
                  />
                </Tooltip>
              </div>
            }
          >
            <DatePicker
              format="YYYY-MM-DD"
              value={dayjs(warehouseDate || effectiveDate)}
              onChange={(date, dateString) => {
                setWarehouseDate(dateString);
              }}
              disabledDate={disabledDate}
              allowClear={false}
            />
          </Form.Item>
          {/* {isShowDateTip && (
            <p className={style.WarehouseDateTip}>
              {formatMessage("warehouseDateTip")}
            </p>
          )} */}
        </div>
        <div className={style.FormCard}>
          <div className={cn(style.CardTitleFlex, style.CardTitle)}>
            <Space size={20}>
              <h3>{formatMessage("openFund")}</h3>
              <span>*&nbsp;&nbsp;&nbsp;({formatMessage("warehouseTip")})</span>
            </Space>
            <SelectFundDialog
              disabled={disabled}
              fundIds={fundIds}
              alreadyCheckedIds={positionFundIds}
              onChange={onChangeFundIds}
              maxSelectionSize={maxSelectionSize}
            >
              {(onChangeVisible) => (
                <Button
                  onClick={onChangeVisible}
                  disabled={disabled}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  {formatMessage("addFund")}
                </Button>
              )}
            </SelectFundDialog>
          </div>
          <ChangingPositionTable
            fundList={fundList}
            onChange={onChange}
            scale={scale}
            setEqualWeight={onSetEqualWeight}
            onError={setButtonDisabled}
          />
          {errorField && (
            <p className={style.ChangingPositionErrorField}>* {errorField}</p>
          )}
        </div>
      </div>
      <div className={style.LayoutFooter}>
        <Space>
          <Button onClick={stackBack}>{formatMessage("cancel")}</Button>
          <Button
            type="primary"
            disabled={disabled}
            onClick={onUpdateChangingPosition}
          >
            {formatMessage("ok")}
          </Button>
        </Space>
      </div>
    </Form>
  );
});
