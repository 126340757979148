import { isNumber } from "lodash/fp";

export const DEFAULT_WIDTH = "100%";
export const DEFAULT_HEIGH = 400;

export const getStyle = (
  width: string | number = DEFAULT_WIDTH,
  height: string | number = DEFAULT_HEIGH
) => ({
  width: isNumber(width) ? `${width}px` : width,
  height: isNumber(height) ? `${height}px` : height,
});
