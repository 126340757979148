import { useFormatMessage } from "@/util/formatMessage";
import { Button, Card, Checkbox, Input, Popover, Space, Tooltip } from "antd";
import { AutoResizer, BaseTableProps } from "react-base-table";
import React, { Key, useCallback, useMemo } from "react";
import style from "../index.module.less";
import { FundsInterface } from "@/model/entities";
import VirtualTableComponent from "../../virtualTable";
import { isEmpty, prop, pullAt, size, join } from "lodash/fp";
import { fastProp } from "@/util/opt";
import { useCreation } from "ahooks";
import { getCategoryTypeId, getFundColumns } from "../constant";
import FundTree from "./fundTree";
import { OnSelectFund, useBatchAddBox } from "../hook";
import { useAppSelector } from "@/hooks/redux";
import { categoryTreeMapSelector } from "@/selectors/category";
import { fundIdMapSelector } from "@/selectors/fund";
import { PlusOutlined } from "@ant-design/icons";

const VirtualTable = VirtualTableComponent<any>();

type LeftTableProps = {
  selectFundIds: string[];
  onSelectFund: OnSelectFund;
  fundData: FundsInterface[];
  closeModal: () => any;
  showCategoryType: boolean;
  maxSelectionSize: number;
  onSelectedType: (value: Key[]) => void;
  selectedType: Key[];
};

export type TableProps = BaseTableProps<FundsInterface>;

const SelectFundDialog = ({
  selectFundIds,
  onSelectFund,
  fundData,
  closeModal,
  showCategoryType,
  maxSelectionSize,
  onSelectedType,
  selectedType,
}: LeftTableProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const indeterminate = useCreation(
    () => !isEmpty(selectFundIds) && size(selectFundIds) < maxSelectionSize,
    [selectFundIds]
  );
  const fundIdMap = useAppSelector(fundIdMapSelector);
  const categoryTreeMap = useAppSelector(categoryTreeMapSelector);
  const selectFundTableColumns = useMemo<TableProps["columns"]>(
    () => [
      {
        align: "left",
        key: "checked",
        width: 40,
        cellRenderer: ({ rowData }) => (
          <Checkbox
            checked={fastProp("checked")(rowData)}
            disabled={fastProp("disabled")(rowData)}
            onChange={() => onSelectFund("select")(fastProp("fundId")(rowData))}
          />
        ),
      },
      ...(getFundColumns({
        formatMessage,
        nameWidth: 200,
        codeWidth: 150,
        closeModal,
      }) || []),
      (showCategoryType && {
        align: "left",
        dataKey: "category",
        key: "category",
        title: formatMessage("category"),
        width: 200,
        resizable: true,
        cellRenderer: ({ rowData }: any) => (
          <div className={style.Overflow}>
            {prop([
              getCategoryTypeId({
                categoryTreeMap,
                categoryId: rowData.categoryId
                  ? rowData.categoryId
                  : fastProp("categoryId")(fastProp(rowData.fundId)(fundIdMap)),
              }),
              "name",
            ])(categoryTreeMap)}
          </div>
        ),
      }) as any,
      {
        align: "left",
        dataKey: "investType",
        key: "assetType",
        title: formatMessage("fundType"),
        width: 200,
        cellRenderer: ({ rowData }) => (
          <Tooltip
            title={
              rowData.investType || rowData.fundType || rowData.secondInvestType
            }
          >
            <div className={style.Overflow}>
              {rowData.investType ||
                rowData.fundType ||
                rowData.secondInvestType}
            </div>
          </Tooltip>
        ),
      },
    ],
    [
      closeModal,
      formatMessage,
      onSelectFund,
      showCategoryType,
      categoryTreeMap,
      fundIdMap,
    ]
  );
  const headerRenderer = useCallback(
    ({ cells }) => {
      const newCells = pullAt(0)(cells);
      return (
        <>
          <div className={style.HeaderCheckBoxRenderer}>
            <Checkbox
              checked={!isEmpty(selectFundIds) && !indeterminate}
              onChange={() => onSelectFund("all")("")}
              indeterminate={indeterminate}
            />
          </div>
          {newCells}
        </>
      );
    },
    [indeterminate, onSelectFund, selectFundIds]
  );
  const rowSelection = useMemo<TableProps["rowSelection"]>(
    () => ({
      type: "checkbox",
    }),
    []
  );
  const {
    textArea,
    onChangeTextArea,
    batchState,
    setBatchState,
    onBatchAdd,
    errorCodes,
    onReset,
    onClearInvalidCode,
  } = useBatchAddBox({
    selectFundIds,
    onSelectFund,
  });
  return (
    <section className={style.Left}>
      <FundTree
        className={style.Tree}
        onSelectedType={onSelectedType}
        selectedType={selectedType}
      />
      <div className={style.SelectFundTable}>
        <div className={style.BatchAdd}>
          <Popover
            visible={batchState}
            trigger="click"
            placement="bottomLeft"
            zIndex={99}
            getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            content={
              <Card
                size="small"
                title={formatMessage("pleaseInputBatchAdd")}
                bordered={false}
                actions={[
                  <Space>
                    <Button
                      size="small"
                      onClick={() => {
                        onReset();
                      }}
                    >
                      {formatMessage("cancel")}
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      onClick={onClearInvalidCode}
                      disabled={!size(errorCodes)}
                    >
                      {formatMessage("clearInvalidCode")}
                    </Button>
                    <Button size="small" type="primary" onClick={onBatchAdd}>
                      {formatMessage("ok")}
                    </Button>
                  </Space>,
                ]}
                className={style.BatchAddBox}
              >
                <Input.TextArea
                  className={style.BatchAddText}
                  value={textArea}
                  onChange={onChangeTextArea}
                />
                <div className={style.BatchAddError}>
                  {size(errorCodes) ? (
                    <>
                      <div className={style.BatchAddErrorTip}>
                        {formatMessage("notExistFundCodeTip")}
                      </div>
                      <div className={style.BatchAddErrorContent}>
                        {join(" ")(errorCodes)}
                      </div>
                    </>
                  ) : null}
                </div>
              </Card>
            }
          >
            <Button
              size="small"
              icon={<PlusOutlined />}
              className={style.BatchAddButton}
              onClick={() => setBatchState(true)}
            >
              {formatMessage("BatchAdd")}
            </Button>
          </Popover>
        </div>
        <div className={style.VirtualTable}>
          <AutoResizer>
            {({ width, height }) => (
              <VirtualTable
                rowSelection={rowSelection}
                columns={selectFundTableColumns as any[]}
                data={fundData}
                className={style.BaseTable}
                height={height}
                headerRenderer={headerRenderer}
                headerHeight={36}
                width={width}
                rowKey="fundId"
              />
            )}
          </AutoResizer>
        </div>
      </div>
    </section>
  );
};

export default React.memo(SelectFundDialog);
