import { Card, Col, Row } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import React, { useMemo, useState } from "react";
import style from "../index.module.less";
import { useAppSelector } from "@/hooks/redux";
import { fastNth, fastProp } from "@/util/opt";
import { map, prop } from "lodash/fp";
import { SCALE_COLORS } from "@/util/colors";
import mapper from "../mapper";
// import { FROM_CREATION } from "@/constant/statisticRange";
// import ScaleRangeSelector from "../../assetPosition/components/scaleRangeSelector";
import {
  DatePeriodPicker,
  Option,
  DatePeriodValue,
} from "@/base-components/datePeriodSelect";
import { getSlicedDateDatas } from "@/util/business-core/dailyReturn";
import { DatePeriodName } from "@/util/business-core/datePeriod";
import { ScaleChangeChart } from "@/base-components/charts";

const datePeriodOptions: Option[] = [
  "RECENT_TWO_YEAR",
  "RECENT_THREE_YEAR",
  "RECENT_FIVE_YEAR",
  "FROM_CREATION",
];

const useGetBarChartDataSource = (scaleRange: string, fundId: string) => {
  const { fundScale, name: fundName } = useAppSelector((state) =>
    mapper(state, fundId)
  );
  return { fundScale, fundName };
};
export default React.memo(({ fundId }: { fundId: string }): JSX.Element => {
  const formatMessage = useFormatMessage();
  const [scaleRange, setScaleRange] =
    useState<DatePeriodValue>("FROM_CREATION");
  const { fundScale, fundName } = useGetBarChartDataSource(scaleRange, fundId);
  const { dates, dateDatas } = useMemo(() => {
    return getSlicedDateDatas({
      name: scaleRange as DatePeriodName,
      targetDateDatas: [
        {
          name: fundName,
          color: fastNth(0)(SCALE_COLORS),
          data: map((item: Record<string, any>) => ({
            ...item,
            date: prop("reportDate")(item),
          }))(fundScale),
        },
      ],
      dataTransform: (data) => [
        fastProp("date")(data),
        fastProp("scale")(data),
      ],
    });
  }, [fundName, fundScale, scaleRange]);

  return (
    <Card bordered={false} size="small">
      <Row justify="space-between" align="top">
        <Col className={style.Title}>{formatMessage("fundScaleTrend")}</Col>
        <Col>
          {/* <ScaleRangeSelector value={scaleRange} onChange={onChange} />
           */}
          <DatePeriodPicker
            options={datePeriodOptions}
            value={scaleRange}
            onChange={setScaleRange}
          />
        </Col>
      </Row>
      <div className={style.BarChart}>
        <ScaleChangeChart
          title={formatMessage("million")}
          series={dateDatas as any}
          height={380}
          dates={dates}
          showLegend={false}
          showDataZoom={false}
        />
        {/* <BarChart options={dataSource} /> */}
      </div>
    </Card>
  );
});
