/**
 * 年化标准差
 */
import { flow } from "lodash/fp";
import { fastSize } from "../opt";
import tradingDateCount from "@/constant/tradingDateCount";
import { times, toStandardDeviation } from "../math";
import { formatArrayNilToZero } from "../numberFormatter";

export default (dailyTotalReturns: number[]) => {
  if (!dailyTotalReturns || fastSize(dailyTotalReturns) === 0) {
    return 0;
  }
  return flow(
    formatArrayNilToZero,
    toStandardDeviation,
    times(Math.sqrt(tradingDateCount))
  )(dailyTotalReturns);
};
