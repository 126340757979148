import React from "react";
import { Rate } from "antd";
import cn from "classnames";

import style from "./index.module.less";

export default React.memo(
  ({ value, className }: { value: number; className?: string }) => {
    return (
      <Rate
        disabled
        className={cn(style.Rate, className)}
        value={value}
        allowHalf
      />
    );
  }
);
