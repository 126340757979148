import { useFormatMessage } from "@/util/formatMessage";
import { Col, DatePicker, Form, Input, Row, Select, Space } from "antd";
import React from "react";
import NumberInput from "@/components/numberInput";
import basicsIcon from "@/assets/customerAnalysisIcons/basicsIcon.png";
import style from "./index.module.less";
import { size } from "lodash/fp";
import getMessage from "@/util/getMessage";
import { forEachIndexed } from "@/util/opt";
import { RuleRender } from "antd/lib/form";

export const idNumber18Reg =
  /^[1-9]\d{5}(19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i;
const idNumber15Reg =
  /(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;

export const idNumberValidator: RuleRender = () => ({
  validator(_, value) {
    if (!value) {
      return Promise.resolve();
    }
    if (size(value) === 15) {
      if (idNumber15Reg.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(getMessage("pleaseEnterCorrectIDNumber"))
      );
    }
    if (size(value) === 18) {
      if (!value.match(idNumber18Reg)) {
        return Promise.reject(
          new Error(getMessage("pleaseEnterCorrectIDNumber"))
        );
      }
      const code = value.split("");
      // 加权因子
      const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      //校验位
      const parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
      let sum = 0;
      let ai = 0;
      let wi = 0;
      forEachIndexed((_: undefined, index: number) => {
        ai = parseInt(code[index]);
        wi = factor[index];
        sum += ai * wi;
      })(new Array(17));
      const last = parity[sum % 11];
      if (last.toString() !== code[17]) {
        return Promise.reject(
          new Error(getMessage("pleaseEnterCorrectIDNumber"))
        );
      }
      return Promise.resolve();
    }
    return Promise.reject(new Error(getMessage("pleaseEnterCorrectIDNumber")));
  },
  validateTrigger: "validateFields",
});

export default React.memo(({ disabled }: { disabled: boolean }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={style.FullWidth}>
      <div className={style.TitleContent}>
        <Space align="start" size={0}>
          <img src={basicsIcon} />
          <span className={style.SubTitle}>{formatMessage("BasicInfo")}</span>
        </Space>
      </div>
      <Space direction="vertical" size={16} className={style.FullWidth}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item
              label={formatMessage("fullName")}
              name="name"
              rules={[
                {
                  required: true,
                  message: formatMessage("pleaseInputFullName"),
                },
                { max: 20, message: formatMessage("noMoreThan20Words") },
              ]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={formatMessage("gender")} name="gender">
              <Select
                disabled={disabled}
                options={[
                  {
                    value: "MALE",
                    label: formatMessage("male"),
                  },
                  {
                    value: "FEMALE",
                    label: formatMessage("female"),
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("IDNumber")}
              name="idNumber"
              rules={[
                {
                  max: 18,
                  message: formatMessage("noMoreThanWords", { number: 18 }),
                },
                idNumberValidator,
              ]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={formatMessage("birthday")} name="birthday">
              <DatePicker disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("cellPhoneNumber")}
              name="phoneNumber"
              rules={[
                {
                  max: 13,
                  message: formatMessage("noMoreThanWords", { number: 13 }),
                },
              ]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("nativePlace")}
              name="birthplace"
              rules={[{ max: 20, message: formatMessage("noMoreThan20Words") }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item
              label={formatMessage("company")}
              name="company"
              rules={[{ max: 20, message: formatMessage("noMoreThan20Words") }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("industry")}
              name="industry"
              rules={[{ max: 20, message: formatMessage("noMoreThan20Words") }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("position")}
              name="job"
              rules={[{ max: 20, message: formatMessage("noMoreThan20Words") }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("companyAddress")}
              name="companyAddress"
              rules={[{ max: 50, message: formatMessage("noMoreThan20Words") }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("customerSource")}
              name="customerSource"
              rules={[{ max: 20, message: formatMessage("noMoreThan20Words") }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={formatMessage("assetSize")}
              name="assetScale"
              // rules={[{ max: 20, message: formatMessage("noMoreThan20Words") }]}
            >
              <NumberInput
                disabled={disabled}
                type="THOUSANDTH"
                suffix={formatMessage("thousandYuan")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </div>
  );
});
