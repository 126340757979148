import { useFormatMessage } from "@/util/formatMessage";
import { useMemoizedFn } from "ahooks";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  difference,
  filter,
  flow,
  identity,
  isEmpty,
  join,
  map,
  prop,
} from "lodash/fp";
import { useAppSelector } from "./redux";
import { fundIdMapSelector } from "@/selectors/fund";
import { assetsType } from "@/model/portfolioList";
import { fastProp } from "@/util/opt";
import { getNatureDateAndTradingDate } from "@/util/processedDates";
import { dataSourceTimeSelector } from "@/selectors/dataSource";

export const useGetWarning = () => {
  const formatMessage = useFormatMessage();
  return useMemoizedFn(
    ({
      title,
      content,
      onOk,
    }: {
      title?: string;
      content?: string;
      onOk?: () => any;
    }) => {
      Modal.warning({
        title,
        icon: <ExclamationCircleOutlined />,
        content,
        okText: formatMessage("returnSet"),
        okType: "default",
        onOk,
      });
    }
  );
};

export const useGetConfirm = () => {
  const formatMessage = useFormatMessage();
  return useMemoizedFn(
    ({
      title,
      content,
      onOk = identity,
      onCancel,
      okText = formatMessage("ok"),
      cancelText = formatMessage("cancel"),
    }: {
      title?: string;
      content?: string;
      onOk?: () => any;
      onCancel?: () => any;
      okText?: string;
      cancelText?: string;
    }) => {
      Modal.confirm({
        title,
        icon: <ExclamationCircleOutlined />,
        content,
        okText,
        onOk,
        onCancel,
        cancelText,
      });
    }
  );
};

export const useValidateDeListFunds = (
  positionDate: string,
  asset: assetsType[]
) => {
  const fundIdMap = useAppSelector(fundIdMapSelector);
  const { processedTradingDates } = useAppSelector(
    (store) => store.tradingDates
  );
  const dateSource = useAppSelector(dataSourceTimeSelector);
  const formatMessage = useFormatMessage();
  const confirm = useGetConfirm();
  return useMemoizedFn(() => {
    const delistFunds = filter((v: assetsType) => {
      const { maturityDate, netValueEndDate } = fastProp(v.fundId)(fundIdMap);
      const [, tradingDate] = getNatureDateAndTradingDate(
        netValueEndDate,
        positionDate
      )(processedTradingDates);
      // 如果当前最新净值日期和建仓日期超过10个交易日，则和退市基金一样处理
      if (positionDate && tradingDate > 10) return true;
      // 还没有退市日期表示还没退市
      if (!maturityDate) return false;
      // 退市日期小于等于dataSource表示已经退市了
      if (maturityDate <= dateSource) return true;
      return false;
    })(asset);
    if (!isEmpty(delistFunds)) {
      const delistingFundName = flow(
        map((v: assetsType) => prop([v.fundId, "name"])(fundIdMap)),
        join("，")
      )(delistFunds);
      const notDelistingFund = difference(asset, delistFunds);
      const isAllDelistingFund = !isEmpty(notDelistingFund);
      return new Promise<assetsType[]>((resolve, reject) => {
        confirm({
          content: isAllDelistingFund
            ? `${formatMessage(
                "DelistingFundOrLongTermFundTip"
              )}：${delistingFundName}`
            : formatMessage("manualCreatePortfolioHasMaturityDateTip"),
          cancelText: formatMessage("returnSet"),
          okText: formatMessage("ProcessingAsCash"),
          onOk: () => resolve(notDelistingFund),
          onCancel: () => reject(),
        });
      });
    }
    return Promise.resolve(asset);
  });
};
