import { useFormatMessage } from "@/util/formatMessage";
import { Button, Modal, Space, Input, Popconfirm, Alert } from "antd";
import { AutoResizer, BaseTableProps } from "react-base-table";
import React, { Key, ReactNode, useCallback, useMemo, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import style from "./index.module.less";
import { FundsInterface } from "@/model/entities";
import LoadingComponent from "../LoadingComponent";
import VirtualTableComponent from "../virtualTable";
import { identity, size } from "lodash/fp";
import { useMemoizedFn } from "ahooks";
import { AllMarket, getFundColumns, MaxSelectIds } from "./constant";
import { useSelectFundDialogData } from "./hook";
import LeftTable from "./components/leftTable";
import { fetchAllFund } from "@/store/entities";

const VirtualTable = VirtualTableComponent<any>();
type SelectFundDialogProps = {
  children?: (onChangeVisible: () => void) => ReactNode;
  fundIds?: string[];
  disabled?: boolean;
  onChange?: (v: string[]) => void;
  alreadyCheckedIds?: string[];
  disabledFundIds?: string[];
  maxSelectionSize?: number;
  enableDelistingFund?: boolean;
  showCategoryType?: boolean;
  categoryIds?: string[];
  alertMessage?: React.ReactNode;
};
export type TableProps = BaseTableProps<FundsInterface>;

const SelectFundDialog = ({
  children,
  fundIds = [],
  onChange = identity,
  disabled,
  alreadyCheckedIds,
  disabledFundIds,
  maxSelectionSize = MaxSelectIds,
  enableDelistingFund = false,
  showCategoryType = false,
  categoryIds = [],
  alertMessage,
}: SelectFundDialogProps): JSX.Element => {
  const formatMessage = useFormatMessage();

  const [visible, setVisible] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<Key[]>([AllMarket]);

  const {
    fundData,
    allFundSize,
    onSearchFund,
    onSelectFund,
    selectFundIds,
    selectedFunds,
  } = useSelectFundDialogData({
    fundIds,
    maxSelectionSize,
    disabledFundIds,
    alreadyCheckedIds,
    enableDelistingFund,
    showCategoryType,
    categoryIds,
    selectedType,
  });
  const onChangeVisible = useMemoizedFn(() => {
    setVisible(!visible);
    onSelectFund("reset")("");
  });
  const onCancel = useMemoizedFn(() => setVisible(false));

  const selectedTableColumns = useMemo<TableProps["columns"]>(
    () => [
      ...(getFundColumns({
        formatMessage,
        nameWidth: 200,
        codeWidth: 150,
        closeModal: onCancel,
      }) || []),
      {
        align: "left",
        dataKey: "operator",
        key: "operator",
        title: formatMessage("operator"),
        width: 80,
        cellRenderer: ({ rowData }) => (
          <span
            onClick={() => onSelectFund("delete")(rowData.fundId)}
            className={style.Operator}
          >
            {formatMessage("delete")}
          </span>
        ),
      },
    ],
    [formatMessage, onCancel, onSelectFund]
  );
  const onOk = useCallback(() => {
    onChange(selectFundIds);
    onCancel();
  }, [onCancel, onChange, selectFundIds]);
  const selectSize = useMemo(() => size(selectedFunds), [selectedFunds]);
  return (
    <>
      {children ? (
        children(onChangeVisible)
      ) : (
        <Button
          onClick={onChangeVisible}
          type="primary"
          disabled={disabled}
          icon={<PlusOutlined />}
        >
          {formatMessage("addFund")}
        </Button>
      )}
      <Modal
        visible={visible}
        title={formatMessage("addFund")}
        width="50%"
        className={style.SelectFundDialog}
        onCancel={onCancel}
        onOk={onOk}
      >
        <LoadingComponent actions={fetchAllFund}>
          {alertMessage && (
            <Alert
              showIcon
              type="warning"
              className={style.SelectFundDialogTip}
              message={alertMessage}
            />
          )}
          <Input.Search
            className={style.Search}
            onSearch={onSearchFund}
            placeholder={formatMessage("fundsPlaceholderMessage")}
          />
          <section className={style.Section}>
            <LeftTable
              fundData={fundData}
              selectFundIds={selectFundIds}
              onSelectFund={onSelectFund}
              closeModal={onCancel}
              showCategoryType={showCategoryType}
              maxSelectionSize={maxSelectionSize}
              onSelectedType={setSelectedType}
              selectedType={selectedType}
            />
            <div className={style.Right}>
              <div className={style.RightHeader}>
                <Space>
                  <h4>{formatMessage("selectedFundIds")}</h4>
                  <p>
                    (<span className={style.SelectSize}>{selectSize}</span>/
                    <span>{allFundSize}</span>)
                  </p>
                </Space>
                <Popconfirm
                  title={formatMessage("SelectFundDialogClearTitile")}
                  onConfirm={() => onSelectFund("clear")("")}
                  okText={formatMessage("ok")}
                  cancelText={formatMessage("cancel")}
                  placement="bottom"
                >
                  <Button size="small" type="default">
                    {formatMessage("clear")}
                  </Button>
                </Popconfirm>
              </div>
              <div className={style.RightTable}>
                <AutoResizer>
                  {({ width, height }) => (
                    <VirtualTable
                      data={selectedFunds}
                      pagination={false}
                      headerHeight={36}
                      width={width}
                      className={style.BaseTable}
                      height={height}
                      rowKey="fundId"
                      columns={selectedTableColumns as any[]}
                    />
                  )}
                </AutoResizer>
              </div>
            </div>
          </section>
        </LoadingComponent>
      </Modal>
    </>
  );
};

export default React.memo(SelectFundDialog);
