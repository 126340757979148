import React, { useMemo } from "react";
import { merge, map, endsWith, join, flow, isUndefined } from "lodash/fp";
import { SeriesOption } from "@/base-components/charts";
import { ChartProps } from "@/base-components/charts/yieldTrendChart/type";
import { getChartOption } from "@/base-components/charts/yieldTrendChart/helper";
import BaseChart from "@/base-components/charts/baseChart";
import { formatPercentage } from "@/util/numberFormatter";

const getSerie = (serie: SeriesOption) => ({
  ...serie,
  type: "line",
});

export default React.memo<
  ChartProps & {
    left: number | null;
  }
>(
  ({
    title,
    dates = [],
    series,
    height = 300,
    showDataZoom = true,
    showLegend = true,
    saveImgEnable = false,
    splitNumber,
    left,
  }) => {
    const finalSeries = useMemo(() => map(getSerie)(series), [series]);
    const chartOption = useMemo(() => {
      const result = merge(
        getChartOption({
          title,
          showLegend,
          showDataZoom,
          saveImgEnable,
          dates,
          series: finalSeries,
          splitNumber,
        })
      )({
        grid: {
          containLabel: true,
          right: 10,
          left: left,
        },
        tooltip: {
          formatter: (params: any) => {
            const name = params?.[0].name;
            const content = flow(
              map(({ seriesName, value, marker }: any) =>
                isUndefined(value)
                  ? ""
                  : `<p style="margin: 8px 0;">${marker}${seriesName}:  ${formatPercentage(
                      value
                    )}</p>`
              ),
              join("")
            )(params);
            return `<div>
              <p>${name}</p>
              ${content}</div>`;
          },
        },
        legend: {
          formatter: (name: string) => {
            if (endsWith("权重")(name)) return `${name} {subText|(右侧坐标)} `;
            return `${name} {subText|(左侧坐标)} `;
          },
          textStyle: {
            rich: {
              subText: {
                color: "#ACB6C3",
                padding: [0, 20, 0, 0],
              },
            },
          },
        },
        yAxis: [
          {
            type: "value",
            min: "dataMin",
            max: "dataMax",
            axisLabel: {
              formatter: formatPercentage,
            },
            axisLine: { onZero: false },
          },
          {
            type: "value",
            min: "dataMin",
            max: "dataMax",
            axisLabel: {
              formatter: formatPercentage,
            },
            axisLine: { onZero: false },
          },
        ],
        xAxis: {
          type: "category",
          data: dates,
          boundaryGap: true,
          axisTick: {
            show: false,
          },
        },
      });
      return result;
    }, [
      showLegend,
      showDataZoom,
      saveImgEnable,
      dates,
      title,
      splitNumber,
      finalSeries,
      left,
    ]);

    return <BaseChart option={chartOption} height={height} />;
  }
);
