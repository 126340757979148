import { getAllStocks } from "@/api/stocks";
import { flow, map, replace, update } from "lodash/fp";
import { Stock } from "@/model/stockBackCheck";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import {
  getRiskFreeRates,
  getBenchmarks,
  getCategory,
  getSectorCategories,
  getCategoryToBenchmark,
} from "@/api/entities";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { transformData } from "@/util/benchmarks";
import pyFormat, { STYLE_FIRST_LETTER } from "@/util/pinyin";
import {
  getAllFund,
  getAllSimpleFund,
  getAllFundCompanyName,
  getAllFundManagerName,
} from "@/api/home";
import {
  BenchmarkInterface,
  FundsInterface,
  ManagerNameInterface,
  CompanyNameInterface,
  CategoryResponse,
} from "@/model/entities";
import { fastProp, mapIndexed } from "@/util/opt";
import { getIndustryThemeList } from "@/api/targetFund";
import { IndustryListItem } from "@/model/targetFund";
import { category, sectorCategories } from "@/model/sectorCategory";
import { mapTree } from "@/util/opt/tree";
import getMessage from "@/util/getMessage";

export const fetchRiskFreeRate = createAsyncThunk(
  "entities/riskFreeRate",
  async () => {
    const response = await getRiskFreeRates();
    return response;
  }
);

export const fetchBenchmarks = createAsyncThunk(
  "entities/benchmarks",
  async () => {
    const response = await getBenchmarks();
    return response;
  }
);

export const fetchAllStocks = avoidMultiRequestActionThunk<void>(
  "entities.allStocks",
  createAsyncThunk("entities/allStocks", async () => {
    const response = await getAllStocks();
    return response;
  })
);

export const fetchAllFund = avoidMultiRequestActionThunk<void>(
  "entities.funds",
  createAsyncThunk("entities/funds", async () => {
    const response = await getAllFund();
    return response;
  })
);
export const fetchAllSimpleFund = avoidMultiRequestActionThunk<void>(
  "entities.simpleFunds",
  createAsyncThunk("entities/simpleFunds", async () => {
    const response = await getAllSimpleFund();
    return response;
  })
);
export const fetchManagerName = avoidMultiRequestActionThunk<void>(
  "entities.managerName",
  createAsyncThunk("entities/managerName", async () => {
    const response = await getAllFundManagerName();
    return response;
  })
);
export const fetchCompanyName = avoidMultiRequestActionThunk<void>(
  "entities.companyName",
  createAsyncThunk("entities/companyName", async () => {
    const response = await getAllFundCompanyName();
    return response;
  })
);
export const fetchIndustryThemeList = avoidMultiRequestActionThunk<void>(
  "entities.industryThemeList",
  createAsyncThunk("entities/industryThemeList", async () => {
    const response = await getIndustryThemeList();
    return response;
  })
);
export const fetchCategory = avoidMultiRequestActionThunk<void>(
  "entities.category",
  createAsyncThunk("entities/category", async () => {
    const response = await getCategory();
    return response;
  })
);

export const fetchSectorCategories = avoidMultiRequestActionThunk<void>(
  "entities.sectorCategory",
  createAsyncThunk("entities/sectorCategories", async () => {
    const response = await getSectorCategories();
    return response;
  })
);

export const fetchCategoryToBenchmark = avoidMultiRequestActionThunk<void>(
  "entities.categoryToBenchmark",
  createAsyncThunk("entities/categoryToBenchmark", async () => {
    const response = await getCategoryToBenchmark();
    return response;
  })
);
type InitStateType = {
  riskFreeRate: Record<string, number>;
  allStocks: Stock[];
  benchmark: BenchmarkInterface[];
  funds: FundsInterface[];
  simpleFunds: FundsInterface[];
  managerName: ManagerNameInterface[];
  companyName: CompanyNameInterface[];
  industryThemeList: IndustryListItem[];
  category: CategoryResponse[];
  sectorCategories: sectorCategories;
  categoryToBenchmark: Record<string, string>;
};
const initialState: InitStateType = {
  riskFreeRate: {},
  allStocks: [],
  benchmark: [],
  funds: [],
  simpleFunds: [],
  managerName: [],
  companyName: [],
  industryThemeList: [],
  category: [],
  sectorCategories: [],
  categoryToBenchmark: {},
};
const replaceThemeName = (value: string): string => {
  const reg = /^Wind(.+)概念主题基金$/;
  const matchResult = replace(reg, "$1")(value);
  return matchResult || value;
};
const entitiesSlice = createSlice({
  name: "entities",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRiskFreeRate.fulfilled.type]: (state, action) => {
      state.riskFreeRate = action.payload;
    },
    [fetchAllStocks.fulfilled.type]: (state, action) => {
      state.allStocks = action.payload;
    },
    [fetchBenchmarks.fulfilled.type]: (state, action) => {
      state.benchmark = transformData(action.payload);
    },
    [fetchAllFund.fulfilled.type]: (state, action) => {
      state.funds = action.payload;
    },
    [fetchAllSimpleFund.fulfilled.type]: (state, action) => {
      state.simpleFunds = action.payload;
    },
    [fetchManagerName.fulfilled.type]: (state, action) => {
      const data = map((v: ManagerNameInterface) => ({
        ...v,
        py: pyFormat(fastProp("name")(v), STYLE_FIRST_LETTER),
      }))(action.payload);
      state.managerName = data;
    },
    [fetchCompanyName.fulfilled.type]: (state, action) => {
      state.companyName = map((v: ManagerNameInterface) => ({
        ...v,
        py: pyFormat(fastProp("name")(v), STYLE_FIRST_LETTER),
      }))(action.payload);
    },
    [fetchIndustryThemeList.fulfilled.type]: (state, action) => {
      state.industryThemeList = flow(
        mapIndexed((name: string, id: string) => ({ id, name })),
        map((value: IndustryListItem) =>
          update("name", (name) => replaceThemeName(name))(value)
        )
      )(action.payload);
    },
    [fetchCategory.fulfilled.type]: (state, action) => {
      state.category = action.payload;
    },
    [fetchSectorCategories.fulfilled.type]: (state, action) => {
      state.sectorCategories = mapTree<category>("sectors", (item) => ({
        ...item,
        name: getMessage(fastProp("name")(item)),
        nameKey: fastProp("name")(item),
      }))(action.payload);
    },
    [fetchCategoryToBenchmark.fulfilled.type]: (state, action) => {
      state.categoryToBenchmark = action.payload;
    },
  },
});

export default entitiesSlice.reducer;
