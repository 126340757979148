import { useFormatMessage } from "@/util/formatMessage";
import { first, flow, last, prop } from "lodash/fp";
import React, { useEffect } from "react";
import SecondLevelName from "../../secondLevelName";
import SimilarRanking from "../../similarRanking";
import style from "./index.module.less";
import { Table } from "antd";
import {
  getColumns,
  RatePerformanceMap,
  riskLevelMap,
  riskLevelMap1,
} from "./contants";
import { useAppDispatch } from "@/hooks/redux";
import { fetchComprehensivePerformance } from "@/store/fundDetailSlice";
import { fundInfoExportProp } from ".";
import {
  useComprehenPerformance,
  useGetAbilitySummary,
  useGetLineChartData,
} from "./hook";
import { fastProp } from "@/util/opt";
import { formatPercentage } from "@/util/numberFormatter";
import PerformanceTrendChart from "../../performanceTrendChart";

export default React.memo<fundInfoExportProp>(({ fundCode }) => {
  const formatMessage = useFormatMessage();
  const columns = getColumns(formatMessage);
  const dispatch = useAppDispatch();
  const { dates, fundName } = useGetLineChartData(fundCode);
  useEffect(() => {
    dispatch(fetchComprehensivePerformance(fundCode));
  }, [dispatch, fundCode]);
  const { dataSource, summary } = useComprehenPerformance(fundCode);
  const { factorRatingsMap } = useGetAbilitySummary(fundCode);

  return (
    <div className={style.ComprehensivePerformance}>
      <SecondLevelName
        name={formatMessage("comprehensivePerformance")}
        rateNum={prop("fund_rank_performance.fundFactorRating")(
          factorRatingsMap
        )}
      />
      <SimilarRanking
        rank={summary?.comprehensivePerformanceRank}
        count={dataSource[0]?.sameTypeFactorRank?.count}
      />
      <PerformanceTrendChart fundId={fundCode} />
      <Table
        columns={columns as any}
        dataSource={dataSource}
        rowKey="name"
        pagination={false}
        bordered
        className={style.PerformanceTable}
      />
      <div className={style.TimeRangeContainer}>
        <div>
          ({first(dates)} ~ {last(dates)})
        </div>
      </div>
      <div className={style.Summary}>
        {formatMessage("ComprehensivePerformanceSummary", {
          fundName: fundName,
          performanceRating: flow(
            fastProp(summary?.performanceRating),
            formatMessage
          )(RatePerformanceMap),
          riskLevel: flow(
            fastProp(summary?.riskLevel),
            formatMessage
          )(riskLevelMap),
          riskLevelPerformance: flow(
            fastProp(summary?.riskLevel),
            formatMessage
          )(riskLevelMap1),
          avgReturn: formatPercentage(summary?.avgReturn),
          winRate: formatPercentage(summary?.winRate),
          holdingExperience: flow(
            fastProp(summary?.holdingExperience),
            formatMessage
          )(riskLevelMap1),
        })}
      </div>
    </div>
  );
});
