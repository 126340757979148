import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEmpty, find, map } from "lodash/fp";
import {
  Competition,
  CompetitionDetail,
  CompetitionFactors,
  CompetitionFactorsParam,
  RankBasicParam,
  WantCompetitionParam,
} from "@/model/competition";
import {
  getCompetitions,
  createOrUpdateCompetition as createOrUpdateCompetitionApi,
  getAllCompetitions,
  setCompetitionValidate as setCompetitionValidateApi,
  getCompetitors as getCompetitorsApi,
  getCompetitionDetail,
  getCompetitionFactorsApi,
  getCompetitionPortFactorsApi,
  getCompetitionRankBasicInfo,
  getWantCompetition,
} from "@/api/competition";
import type {
  UpdateCompetitionParam,
  SetCompetitionValidateParam,
  Competitor,
} from "@/api/competition";
import { FactorCategory } from "@/model/factors";
import { RootState } from "./index";

export const fetchCompetitions = createAsyncThunk(
  "competition/list",
  async () => {
    const response = await getCompetitions();
    return response;
  }
);
export const fetchCompetitionPortFactors = createAsyncThunk(
  "competition/competitionPortFactors",
  async () => {
    const response = await getCompetitionPortFactorsApi();
    return response;
  }
);
export const fetchCompetitionDetailInfo = createAsyncThunk(
  "competition/competitionDetail",
  async (competitionId: string) => {
    const response = await getCompetitionDetail(competitionId);
    return response;
  }
);
export const fetchCompetitionRankBasicInfo = createAsyncThunk(
  "competition/competitionRankBasicInfo",
  async (body: RankBasicParam) => {
    const response = await getCompetitionRankBasicInfo(body);
    return response;
  }
);
export const fetchCompetitionFactorsData = createAsyncThunk(
  "competition/competitionFactorsData",
  async (body: CompetitionFactorsParam) => {
    const response = await getCompetitionFactorsApi(body);
    return response;
  }
);

export const fetchWantCompetition = createAsyncThunk(
  "competition/competitionRankBasicInfo",
  async (body: WantCompetitionParam, { dispatch }) => {
    const response = await getWantCompetition(body);
    dispatch(fetchCompetitions());
    dispatch(fetchCompetitionDetailInfo(body?.competitionId));
    return response;
  }
);

// export const createCompetition = createAsyncThunk(
//   "competition/create",
//   async (params: CreateCompetitionParam, { dispatch }) => {
//     const response = await createCompetitionApi(params);
//     dispatch(fetchCompetitions());
//     dispatch(fetchAllCompetitions());
//     return response;
//   }
// );

export const createOrUpdateCompetition = createAsyncThunk(
  "competition/update",
  async (params: UpdateCompetitionParam, { dispatch }) => {
    const response = await createOrUpdateCompetitionApi(params);
    dispatch(fetchCompetitions());
    dispatch(fetchAllCompetitions());
    return response;
  }
);

export const fetchAllCompetitions = createAsyncThunk(
  "competition/all/list",
  async () => {
    const response = await getAllCompetitions();
    return response;
  }
);

export const setCompetitionValidate = createAsyncThunk(
  "competition/set/validate",
  async (params: SetCompetitionValidateParam, { dispatch }) => {
    const response = await setCompetitionValidateApi(params);
    dispatch(fetchAllCompetitions());
    return response;
  }
);

export const getCompetitors = createAsyncThunk(
  "competition/competitors",
  async (competitionId: string) => {
    const response = await getCompetitorsApi(competitionId);
    return response;
  }
);

export const fetchCompetitionDetail = createAsyncThunk(
  "competition/detail",
  async (competitionId: string, { getState }) => {
    let competitions = (getState() as RootState)?.competition?.allCompetitions;
    if (isEmpty(competitions)) {
      competitions = await getAllCompetitions();
    }
    const item = find(
      (competition: Competition) => competitionId === competition?.competitionId
    )(competitions);
    if (item) {
      const competitors = await getCompetitorsApi(competitionId);
      return {
        ...item,
        competeUsers: map((competitor: Competitor) => competitor?.competitorId)(
          competitors
        ),
      };
    }
    return competitions;
  }
);

type CompetitionState = {
  competitions: Competition[];
  allCompetitions: Competition[];
  competitionPortFactors: FactorCategory[];
  competitionDetail: CompetitionDetail;
  competitionRankListBasicInfo: Record<string, any>;
  competitionFactorsData: Record<string, CompetitionFactors[]>;
};

const initialState: CompetitionState = {
  competitions: [],
  allCompetitions: [],
  competitionPortFactors: [],
  competitionDetail: {
    competitionId: "",
    name: "",
    summary: "",
    regulation: "string",
    startDate: "",
    endDate: "",
    presentRange: "PUBLIC", // 比赛展示范围
    enterEndDate: "", // 报名截止时间
    bannerImage: "",
    bannerImageName: "",
    entranceImage: "",
    entranceImageName: "",
    createUserId: "",
    validate: false, //  1 启动 0 不启用
    status: 2,
    competeUsers: [],
    inCompetition: false,
    hasPermission: true,
    portfolioTotalNum: "",
    updateDate: "",
  },
  competitionRankListBasicInfo: {},
  competitionFactorsData: {},
};

const competitionSlice = createSlice({
  name: "competition",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCompetitions.fulfilled.type]: (state, action) => {
      state.competitions = action.payload;
    },
    [fetchAllCompetitions.fulfilled.type]: (state, action) => {
      state.allCompetitions = action.payload;
    },
    [fetchCompetitionPortFactors.fulfilled.type]: (state, action) => {
      state.competitionPortFactors = action.payload;
    },
    [fetchCompetitionDetailInfo.fulfilled.type]: (state, action) => {
      state.competitionDetail = action.payload;
    },
    [fetchCompetitionRankBasicInfo.fulfilled.type]: (state, action) => {
      const { type } = action?.meta?.arg;
      state.competitionRankListBasicInfo[type] = action.payload;
    },
    [fetchCompetitionFactorsData.fulfilled.type]: (state, action) => {
      const { type } = action?.meta?.arg;
      state.competitionFactorsData[type] = action.payload;
    },
  },
});

export default competitionSlice.reducer;
