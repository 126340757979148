import { useFormatMessage } from "@/util/formatMessage";
import { Button, Space } from "antd";
import { first, isEmpty, last } from "lodash/fp";
import React from "react";
import CustomStaticRange from "../../components/customStaticRange";

type HeaderProps = {
  value: string;
  title: string;
  onChange: (v: string) => void;
  onStartCalculate?: () => void;
  startDate: string;
  endDate: string;
  dates: string[];
  className?: string;
  tradingDateSize?: number;
  disabled?: boolean;
};
const CustomHeader = ({
  value,
  title,
  onChange,
  onStartCalculate,
  startDate,
  endDate,
  dates,
  tradingDateSize,
  disabled,
}: HeaderProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  return (
    <Space>
      <span>{formatMessage(title)}</span>
      <CustomStaticRange
        value={value}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        tradingDateSize={tradingDateSize}
      />
      {isEmpty(dates) ? null : (
        <span>
          ({first(dates)}~{last(dates)})
        </span>
      )}
      <Button type="primary" disabled={disabled} onClick={onStartCalculate}>
        {formatMessage("startCalculate")}
      </Button>
    </Space>
  );
};

export default React.memo(CustomHeader);
