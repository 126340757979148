import { useContext } from "react";
import CompareManage from "@/views/compareManage";
import FundManagerCompare from "@/views/compareManage/fundManagerCompare";
import FundCompanyCompare from "@/views/compareManage/fundCompanyCompare";
import FundDetail from "@/views/fundDetail/fundDetailInfo";
import FundManagerDetail from "@/views/fundManagerDetail/fundManagerDetailInfo";
import FundCompanyDetail from "@/views/fundCompanyDetail/fundCompanyDetailInfo";
import CreateSimplePortfolio from "@/views/portfolioManage/createSimplePortfolio";
import ManualCreatePortfolio from "@/views/portfolioManage/manualCreatePortfolio";
import FundConfiguration from "@/views/portfolioManage/fundConfiguration";
import FromAboveToBelow from "@/views/portfolioManage/fromAboveToBelow";
import PortfolioEdit from "@/views/portfolioManage/portfolioList/editPortfolio";
import PortfolioChangingPosition from "@/views/portfolioManage/portfolioList/changingPosition";
import PortfolioChangingPositionHistory from "@/views/portfolioManage/portfolioList/changingPositionHistory";
import BackTestingDetailPage from "@/views/portfolioManage/portfolioList/backTestingScheme/backTestingDetailPage";
import PortfolioAnalysis from "@/views/portfolioManage/portfolioList/portfolioAnalysis";
import CompetitionManage from "@/views/competition/manage";
import CreateCompetition from "@/views/competition/createCompetition";
import CompetitionDetail from "@/views/competition/competitionDetail";
import WantCompete from "@/views/competition/wantCompete";
import CustomerAccountAnalysis from "@/views/customerManage/customerAccountAnalysis/index";
import AddCustomer from "@/views/customerManage/addCustomer/index";
import CustomerAumAnalysis from "@/views/customerManage/customerAumAnalysis";
import { NavigationContext } from "./interface";
import type { NavigationStacks } from "./interface";
import { fastProp } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import platformNavigationContext from "./context";

export const NAVIGATION_KEYS = {
  compareManage: "compareManage",
  fundManagerCompare: "fundManagerCompare",
  fundCompanyCompare: "fundCompanyCompare",
  fundDetail: "fundDetail",
  fundManagerDetail: "fundManagerDetail",
  simpleCreatePortfolio: "simpleCreate",
  portfolioEdit: "edit",
  portfolioChangingPosition: "theWarehouse",
  portfolioChangingPositionHistory: "warehouseRecord",
  manualCreatePortfolio: "manualCreate",
  backTestingDetail: "backTestingDetail",
  portfolioAnalysis: "portfolioAnalysis",
  fundConfiguration: "fundConfiguration",
  fundCompanyDetail: "fundCompanyDetail",
  fromAboveToBelow: "fromAboveToBelow",
  competitionManage: "competitionManage",
  createCompetition: "createCompetition",
  competitionDetail: "competitionDetail",
  competitionEdit: "competitionEdit",
  wantCompete: "wantCompete",
  customerAccountAnalysis: "customerDetail",
  customerAumAnalysis: "customerAumAnalysis",
  createCustomer: "addCustomer",
  customerHeldAssetManage: "customerHeldAssetManage",
};

export const NAVIGATION_ELEMENTS = {
  [NAVIGATION_KEYS.compareManage]: CompareManage,
  [NAVIGATION_KEYS.fundManagerCompare]: FundManagerCompare,
  [NAVIGATION_KEYS.fundCompanyCompare]: FundCompanyCompare,
  [NAVIGATION_KEYS.fundDetail]: FundDetail,
  [NAVIGATION_KEYS.fundManagerDetail]: FundManagerDetail,
  [NAVIGATION_KEYS.fundCompanyDetail]: FundCompanyDetail,
  [NAVIGATION_KEYS.simpleCreatePortfolio]: CreateSimplePortfolio,
  [NAVIGATION_KEYS.portfolioEdit]: PortfolioEdit,
  [NAVIGATION_KEYS.portfolioChangingPosition]: PortfolioChangingPosition,
  [NAVIGATION_KEYS.portfolioChangingPositionHistory]:
    PortfolioChangingPositionHistory,
  [NAVIGATION_KEYS.manualCreatePortfolio]: ManualCreatePortfolio,
  [NAVIGATION_KEYS.backTestingDetail]: BackTestingDetailPage,
  [NAVIGATION_KEYS.portfolioAnalysis]: PortfolioAnalysis,
  [NAVIGATION_KEYS.fundConfiguration]: FundConfiguration,
  [NAVIGATION_KEYS.fromAboveToBelow]: FromAboveToBelow,
  [NAVIGATION_KEYS.competitionManage]: CompetitionManage,
  [NAVIGATION_KEYS.createCompetition]: CreateCompetition,
  [NAVIGATION_KEYS.competitionDetail]: CompetitionDetail,
  [NAVIGATION_KEYS.competitionEdit]: CreateCompetition,
  [NAVIGATION_KEYS.wantCompete]: WantCompete,
  [NAVIGATION_KEYS.customerAccountAnalysis]: CustomerAccountAnalysis,
  [NAVIGATION_KEYS.customerAumAnalysis]: CustomerAumAnalysis,
  [NAVIGATION_KEYS.createCustomer]: AddCustomer,
};

const generateStacks = (id: string, params: any): NavigationStacks => ({
  id,
  Component: fastProp(id)(NAVIGATION_ELEMENTS),
  params,
});

export const useGenerateProviderValues = (
  setNavigationStacks: (value: NavigationStacks) => void
) => {
  const goToFundComparePage = useMemoizedFn<
    NavigationContext["goToFundComparePage"]
  >((params) =>
    setNavigationStacks(generateStacks(NAVIGATION_KEYS.compareManage, params))
  );
  const goToFundManagerComparePage = useMemoizedFn<
    NavigationContext["goToFundManagerComparePage"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.fundManagerCompare, params)
    )
  );
  const goToFundCompanyComparePage = useMemoizedFn<
    NavigationContext["goToFundCompanyComparePage"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.fundCompanyCompare, params)
    )
  );
  const goToFundDetailPage = useMemoizedFn<
    NavigationContext["goToFundDetailPage"]
  >((params) =>
    setNavigationStacks(generateStacks(NAVIGATION_KEYS.fundDetail, params))
  );
  const goToFundManagerDetailPage = useMemoizedFn<
    NavigationContext["goToFundManagerDetailPage"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.fundManagerDetail, params)
    )
  );
  const goToFundCompanyDetailPage = useMemoizedFn<
    NavigationContext["goToFundCompanyDetailPage"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.fundCompanyDetail, params)
    )
  );
  const goToSimpleCreatePortfolio = useMemoizedFn<
    NavigationContext["goToSimpleCreatePortfolio"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.simpleCreatePortfolio, params)
    )
  );
  const goToPortfolioEdit = useMemoizedFn<
    NavigationContext["goToPortfolioEdit"]
  >((params) =>
    setNavigationStacks(generateStacks(NAVIGATION_KEYS.portfolioEdit, params))
  );
  const goToPortfolioChangingPosition = useMemoizedFn<
    NavigationContext["goToPortfolioChangingPosition"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.portfolioChangingPosition, params)
    )
  );
  const goToPortfolioChangingPositionHistory = useMemoizedFn<
    NavigationContext["goToPortfolioChangingPositionHistory"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.portfolioChangingPositionHistory, params)
    )
  );

  const goToManualCreatePortfolio = useMemoizedFn<
    NavigationContext["goToSimpleCreatePortfolio"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.manualCreatePortfolio, params)
    )
  );
  const goToPortfolioAnalysis = useMemoizedFn((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.portfolioAnalysis, params)
    )
  );

  const goToBackTestingDetail = useMemoizedFn<
    NavigationContext["goToBackTestingDetail"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.backTestingDetail, params)
    )
  );
  const goToFundConfiguration = useMemoizedFn<
    NavigationContext["goToFundConfiguration"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.fundConfiguration, params)
    )
  );
  const goToFromAboveToBelow = useMemoizedFn<
    NavigationContext["goToFromAboveToBelow"]
  >(() =>
    setNavigationStacks(generateStacks(NAVIGATION_KEYS.fromAboveToBelow, null))
  );
  const goToCompetitionManage = useMemoizedFn<
    NavigationContext["goToCompetitionManage"]
  >(() =>
    setNavigationStacks(generateStacks(NAVIGATION_KEYS.competitionManage, null))
  );
  const goToCreateCompetitionPage = useMemoizedFn<
    NavigationContext["goToCreateCompetitionPage"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.createCompetition, params)
    )
  );
  const goToCompetitionDetail = useMemoizedFn<
    NavigationContext["goToCompetitionDetail"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.competitionDetail, params)
    )
  );
  const goToCompetitionEdit = useMemoizedFn<
    NavigationContext["goToCompetitionEdit"]
  >((params) =>
    setNavigationStacks(generateStacks(NAVIGATION_KEYS.competitionEdit, params))
  );
  const goToWantCompetePage = useMemoizedFn<
    NavigationContext["goToWantCompetePage"]
  >((params) =>
    setNavigationStacks(generateStacks(NAVIGATION_KEYS.wantCompete, params))
  );
  const goToCustomerAccountAnalysis = useMemoizedFn<
    NavigationContext["goToCustomerAccountAnalysis"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.customerAccountAnalysis, params)
    )
  );
  const goToCustomerAumAnalysis = useMemoizedFn<
    NavigationContext["goToCustomerAumAnalysis"]
  >((params) =>
    setNavigationStacks(
      generateStacks(NAVIGATION_KEYS.customerAumAnalysis, params)
    )
  );
  const goToCreateCustomerPage = useMemoizedFn<
    NavigationContext["goToCreateCustomerPage"]
  >((params) =>
    setNavigationStacks(generateStacks(NAVIGATION_KEYS.createCustomer, params))
  );
  return {
    goToFundComparePage,
    goToFundManagerComparePage,
    goToFundCompanyComparePage,
    goToFundDetailPage,
    goToFundManagerDetailPage,
    goToManualCreatePortfolio,
    goToFundCompanyDetailPage,
    goToPortfolioEdit,
    goToPortfolioChangingPosition,
    goToPortfolioChangingPositionHistory,
    goToSimpleCreatePortfolio,
    goToBackTestingDetail,
    goToPortfolioAnalysis,
    goToFundConfiguration,
    goToFromAboveToBelow,
    goToCompetitionManage,
    goToCreateCompetitionPage,
    goToCompetitionDetail,
    goToCompetitionEdit,
    goToWantCompetePage,
    goToCustomerAccountAnalysis,
    goToCustomerAumAnalysis,
    goToCreateCustomerPage,
  };
};

export const generateIdentify = (id: string, index: number) => `${id}_${index}`;

export const usePlatformContentSize = (): { contentHeight: number } => {
  const { contentHeight } = useContext(platformNavigationContext);
  return { contentHeight };
};
