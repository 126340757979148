/**
 * beta算法
 */

import { zipWithMinus } from "../opt";
import { getCovariance, getSampleVariance } from "../math";
import { formatQuantArrayNilToZero } from "../numberFormatter";

export default (
  dailyReturns: number[],
  benchmarkReturns: number[],
  riskFreeRate: number[]
) => {
  const [formatDailyReturns, formatBenchmarkReturns, formatRiskFreeRate] =
    formatQuantArrayNilToZero(dailyReturns, benchmarkReturns, riskFreeRate);
  const fundSubFreeRate = zipWithMinus(formatDailyReturns, formatRiskFreeRate);
  const benchmarkSubRate = zipWithMinus(
    formatBenchmarkReturns,
    formatRiskFreeRate
  );
  return (
    getCovariance(fundSubFreeRate, benchmarkSubRate) /
    getSampleVariance(benchmarkSubRate)
  );
};
