import React, { useEffect } from "react";
import { useMemoizedFn } from "ahooks";
import style from "./index.module.less";
// import ImportOperate from "../components/importOperate";
import PositionCard from "./positionCard";
import ChangingPositionCard from "./changingPositionCard";
import { useManagePositionData } from "../hooks";
import { concat, isNumber, some } from "lodash/fp";
import {
  ManualCreatePortfolio,
  validateManualCreatePortfolioDataError,
} from "../constant";
import { fastNth, fastProp } from "@/util/opt";

export type FundAllocationRef = {
  getData: () => ManualCreatePortfolio[];
};
export default React.memo<{
  onError: React.Dispatch<React.SetStateAction<boolean>>;
  defaultIds?: string[];
}>(({ onError, defaultIds = [] }) => {
  const {
    positionData,
    changingPositionData,
    onUpdatePositionData,
    onUpdateChangingPositionData,
    onAddChangingPositionData,
    onRemovePositionData,
    onClearPositionData,
  } = useManagePositionData();
  useEffect(() => {
    onError(
      some<ManualCreatePortfolio>(validateManualCreatePortfolioDataError)(
        concat(positionData)(changingPositionData)
      )
    );
  }, [positionData, changingPositionData, onError]);
  const handleOnAddChangingPositionData = useMemoizedFn(
    (index?: number | undefined) => {
      const prevAddField = isNumber(index)
        ? changingPositionData[index]
        : positionData;
      if (!prevAddField || validateManualCreatePortfolioDataError(prevAddField))
        return;
      onAddChangingPositionData(index);
    }
  );
  return (
    <>
      {/* <ImportOperate className={style.Card} /> */}
      <PositionCard
        className={style.Card}
        positionData={positionData}
        firstChangingPositionDate={fastProp("turnoverDate")(
          fastNth(0)(changingPositionData)
        )}
        onUpdate={onUpdatePositionData}
        defaultIds={defaultIds}
      />
      <ChangingPositionCard
        className={style.Card}
        changingPositionData={changingPositionData}
        onUpdate={onUpdateChangingPositionData}
        onAdd={handleOnAddChangingPositionData}
        onRemove={onRemovePositionData}
        onClear={onClearPositionData}
        positionDate={positionData.turnoverDate}
      />
    </>
  );
});
