import { useFormatMessage } from "@/util/formatMessage";
import React, { LegacyRef } from "react";
import cn from "classnames";
import { Rate } from "antd";
import LoadingComponent from "@/components/LoadingComponent";
import {
  fetchComprehensivePerformance,
  fetchIndustryAbilityEvaluation,
} from "@/store/fundDetailSlice";
import OneLevelName from "../../oneLevelName";
import ComprehensivePerformance from "./comprehensivePerformance";
import IndustryAbilityEvaluation from "./industryAbilityEvaluation";
import PageHeader from "../../pageHeader";
import style from "./index.module.less";
import { useGetAbilitySummary } from "./hook";

export interface fundInfoExportProp {
  fundCode: string;
}

export default React.forwardRef(
  ({ fundCode }: fundInfoExportProp, ref: LegacyRef<HTMLDivElement>) => {
    const formatMessage = useFormatMessage();
    const { totalRating } = useGetAbilitySummary(fundCode);

    return (
      <div className={style.PageWrap} ref={ref}>
        <LoadingComponent
          actions={[
            fetchComprehensivePerformance,
            fetchIndustryAbilityEvaluation,
          ]}
        >
          <PageHeader pageNumber={2} />
          <div
            className={cn(
              style.PageContentWrap,
              style.EvaluationReportInfoWrap
            )}
          >
            <OneLevelName name={formatMessage("capabilityEvaluation")}>
              <span>
                <Rate
                  disabled
                  value={totalRating}
                  allowHalf
                  className={style.FundTate}
                />
              </span>
            </OneLevelName>
            <ComprehensivePerformance fundCode={fundCode} />
            <IndustryAbilityEvaluation fundCode={fundCode} />
          </div>
        </LoadingComponent>
      </div>
    );
  }
);
