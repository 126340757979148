import { Button, Col, Form, InputNumber, Modal, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { flow, merge, prop } from "lodash/fp";
import { useMemoizedFn } from "ahooks";

import { AssetItemType, PositionAssetTransactionParam } from "@/model/customer";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { RootState } from "@/store";
import { customerAnalysisSelector } from "@/views/customerManage/customerAccountAnalysis/selectors";
import { fastProp, normalize } from "@/util/opt";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  fetchAssetPositionDetail,
  positionAssetTransaction,
} from "@/store/customerAccountAnalysis";
import TradingDatePicker from "@/components/tradingDatePicker";

import style from "./index.module.less";
import {
  useGetIsActionLoading,
  useLargeThanZeroValidator,
  useNotNullValidator,
} from "../hooks";
import { parseToThousandth, toFixedNumber } from "@/util/numberFormatter";
import LoadingComponent from "@/components/LoadingComponent";
import { getPreviousTradingDate } from "@/util/processedDates";

const { Item } = Form;

const QuickInputBtns = React.memo(
  ({
    formatMessage,
    handleQuickInput,
  }: {
    formatMessage: FormatMessageFunc;
    handleQuickInput: (ratio: number) => void;
  }) => (
    <div className={style.QuickBtnsWrap}>
      <Button
        onClick={() => handleQuickInput(1 / 4)}
        className={style.QuickBtn}
      >
        1/4
      </Button>
      <Button
        onClick={() => handleQuickInput(1 / 3)}
        className={style.QuickBtn}
      >
        1/3
      </Button>
      <Button
        onClick={() => handleQuickInput(1 / 2)}
        className={style.QuickBtn}
      >
        1/2
      </Button>
      <Button onClick={() => handleQuickInput(1)} className={style.QuickBtn}>
        {formatMessage("all")}
      </Button>
    </div>
  )
);

export default React.memo(
  ({
    record,
    accountId,
    setPurchaseAndRedeemList,
  }: {
    record: AssetItemType;
    accountId: string;
    setPurchaseAndRedeemList: React.Dispatch<
      React.SetStateAction<PositionAssetTransactionParam["assets"]>
    >;
  }) => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();
    const [isShowModal, setIsShowModal] = useState(false);
    const { assetType, assetCode } = record;

    const handleQuickInput = useMemoizedFn((ratio) => {
      if (!chosenDate) {
        form.validateFields(["date"]);
        return;
      }
      let value = share * ratio;
      if (ratio === 1 || share <= 100) {
        form.setFieldValue("share", share);
        return;
      }
      if (assetType === "STOCK") {
        if (value < 100) value = 100;
        else {
          value = Math.floor(value / 100) * 100;
        }
      }
      form.setFieldValue("share", Number(toFixedNumber(2)(value)));
    });

    const [form] = Form.useForm();

    const handleOk = useMemoizedFn(() => {
      form.validateFields().then((values) => {
        setPurchaseAndRedeemList((preState) => [
          ...preState,
          merge({
            assetType,
            assetCode,
            transactionType: "REDEMPTION",
          })(values),
        ]);
        setIsShowModal(false);
      });
    });

    const confirmLoading = useGetIsActionLoading(positionAssetTransaction);
    const notNullValidator = useNotNullValidator();
    const largeThanZeroValidator = useLargeThanZeroValidator();

    const accountAnalysisData = useAppSelector((state: RootState) =>
      customerAnalysisSelector(state, accountId)
    );
    const { from, to } = useMemo(
      () => fastProp("accountInfo")(accountAnalysisData) || {},
      [accountAnalysisData]
    );
    const disabledDate = useMemoizedFn(
      (date: string) => dayjs(date).isBefore(from) || dayjs(date).isAfter(to)
    );

    const handleShareInputOnBlur = useMemoizedFn(({ target: { value } }) => {
      const calculatedVal = Math.floor(Number(value) / 100) * 100;
      if (Number(value) !== share) {
        if (isNaN(Number(value)))
          setTimeout(() => {
            form.setFieldValue(["assets", 0, "share"], share);
          }, 0);
        else
          setTimeout(() => {
            form.setFieldValue(["assets", 0, "share"], calculatedVal);
          }, 0);
      }
    });

    const [chosenDate, setChosenDate] = useState("");
    useEffect(() => {
      if (chosenDate)
        dispatch(fetchAssetPositionDetail({ accountId, date: chosenDate }));
    }, [accountId, chosenDate, dispatch]);
    const assets = useAppSelector(
      prop(
        `customerAnalysis.${accountId}.assetPositionDetail.${chosenDate}.assets`
      )
    );
    const share = flow(
      normalize("assetCode"),
      prop(`${assetCode}.share`)
    )(assets) as number;

    const { processedTradingDates, tradingDateList } =
      useAppSelector(prop("tradingDates")) || {};

    const handleValuesChange = useMemoizedFn((changedValues) => {
      const formChosenDate = prop("date")(changedValues);

      if (formChosenDate && tradingDateList && processedTradingDates) {
        const previousTradingDate = getPreviousTradingDate(
          tradingDateList,
          processedTradingDates,
          formChosenDate
        );
        setChosenDate(previousTradingDate);
      }
    });
    useEffect(() => {
      if (!isShowModal) setChosenDate("");
    }, [isShowModal]);

    return (
      <div>
        <a onClick={() => setIsShowModal(true)}>{formatMessage("redeem")}</a>
        {isShowModal && (
          <Modal
            title={formatMessage("redeem")}
            open={isShowModal}
            okText={`${formatMessage("confirm")}${formatMessage("redeem")}`}
            onOk={handleOk}
            onCancel={() => setIsShowModal(false)}
            confirmLoading={confirmLoading}
          >
            <Form
              labelCol={{ span: 6 }}
              labelAlign="left"
              preserve={false}
              className={style.Form}
              form={form}
              onValuesChange={handleValuesChange}
            >
              <div className={style.AccountSelectWrap}>
                <Item
                  label={formatMessage("sellDate")}
                  name={"date"}
                  rules={[
                    {
                      validator: notNullValidator(formatMessage("sellDate")),
                    },
                  ]}
                >
                  <TradingDatePicker
                    className={style.FullWidth}
                    disabledDate={disabledDate}
                  />
                </Item>
                {chosenDate && (
                  <Row className={style.CurrentCashScaleWrap}>
                    <Col span={6} />
                    <LoadingComponent actions={fetchAssetPositionDetail}>
                      <Col className={style.CurrentCashScale}>
                        {`${formatMessage(
                          "chosenDateScale"
                        )}：${parseToThousandth(share)}`}
                      </Col>
                    </LoadingComponent>
                  </Row>
                )}
              </div>
              {assetType === "OPEN_FUND" ? (
                <>
                  <Item
                    label={formatMessage("sellPortion")}
                    name={"share"}
                    rules={[
                      {
                        validator: largeThanZeroValidator(
                          formatMessage("sellPortion")
                        ),
                      },
                    ]}
                    className={style.SellItem}
                  >
                    <InputNumber
                      min={0}
                      max={share}
                      placeholder={formatMessage("pleaseEnter")}
                      className={style.FullWidth}
                      precision={2}
                    />
                  </Item>
                  <Row className={style.QuickBtnsRow}>
                    <Col span={6} />
                    <Col>
                      <QuickInputBtns
                        handleQuickInput={handleQuickInput}
                        formatMessage={formatMessage}
                      />
                    </Col>
                  </Row>
                  <Item
                    label={formatMessage("serviceCharge")}
                    name="handlingFee"
                    initialValue={0}
                    rules={[
                      {
                        validator: largeThanZeroValidator(
                          formatMessage("transactionCost"),
                          true
                        ),
                      },
                    ]}
                  >
                    {/* {purchaseFeeType === "feeRatio" ? (
                      <InputNumber<number>
                        formatter={(value) =>
                          `${multiplyFloadNum(2)(value || 0)(100)}%`
                        }
                        parser={(value) =>
                          Number(value?.replace("%", "")) / 100
                        }
                        step={0.0001}
                        className={style.FullWidth}
                        max={0.05}
                        min={0}
                        precision={4}
                      />
                    ) : ( */}
                    <InputNumber<number>
                      className={style.FullWidth}
                      min={0}
                      precision={2}
                    />
                    {/* )} */}
                  </Item>
                </>
              ) : (
                <>
                  <Item
                    label={formatMessage("numberOfSellShares")}
                    name={"share"}
                    className={style.SellItem}
                    rules={[
                      {
                        validator: largeThanZeroValidator(
                          formatMessage("numberOfSellShares")
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      min={100}
                      max={share}
                      placeholder={formatMessage("pleaseEnter")}
                      className={style.FullWidth}
                      precision={2}
                      step={100}
                      onBlur={handleShareInputOnBlur}
                    />
                  </Item>
                  <Row className={style.QuickBtnsRow}>
                    <Col span={6} />
                    <Col>
                      <QuickInputBtns
                        handleQuickInput={handleQuickInput}
                        formatMessage={formatMessage}
                      />
                    </Col>
                  </Row>
                  <Item
                    label={formatMessage("sellPrice")}
                    name={"price"}
                    rules={[
                      {
                        validator: largeThanZeroValidator(
                          formatMessage("sellPrice")
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={formatMessage("pleaseEnter")}
                      min={0}
                      className={style.FullWidth}
                      precision={2}
                    />
                  </Item>
                  <Item
                    label={formatMessage("serviceCharge")}
                    name={"handlingFee"}
                    rules={[
                      {
                        validator: largeThanZeroValidator(
                          formatMessage("serviceCharge"),
                          true
                        ),
                      },
                    ]}
                    initialValue={0}
                  >
                    <InputNumber<number>
                      className={style.FullWidth}
                      min={0}
                      precision={2}
                    />
                  </Item>
                </>
              )}
            </Form>
          </Modal>
        )}
      </div>
    );
  }
);
