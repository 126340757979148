import {
  configureStore,
  MiddlewareAPI,
  Dispatch,
  AnyAction,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import logger from "redux-logger";
import users from "./users";
import global, { addLoading, subLoading } from "./global";
import dataBoard from "./dataBoardSlice";
import factors from "./factors";
import news from "./news";
import targetFund from "./targetFundSlice";
import stocks from "./stocks";
import factorFund from "./factorFundSlice";
import tradingDates from "./tradingDates";
import compareManage from "./compareManage";
import dailyReturns from "./dailyReturns";
import entities from "./entities";
import fundDetail from "./fundDetailSlice";
import portfolioList from "./portfolioList";
import portfolioAnalysis from "./portfolioAnalysis";
import fundManagers from "./fundManagersSlice";
import createPortfolio from "./createPortfolio";
import scenarioList from "./scenarioList";
import fundManagerDetail from "./fundManagerDetailSlice";
import fundCompanyDetail from "./fundCompanyDetail";
import replaceFund from "./replaceFund";
import workbench from "./workbench";
import customBenchmark from "./customBenchmark";
import preferredFund from "./preferredFund";
import competition from "./competition";
import aum from "./aum";
import customerList from "./customerList";
import customerAnalysis from "./customerAccountAnalysis";
import createCustomer from "./createCustomer";
import etfFundFilter from "./etfFilter";
import { reducer as daterPeriodReturn } from "./base/datePeriodReturn";

const showLoadingStatus = new Map<string, boolean>();

type LoadingMiddleWareAction = AnyAction & { meta: { ignoreLoading: boolean } };

const loadingMiddleWare =
  (api: MiddlewareAPI) =>
  (next: Dispatch<AnyAction>) =>
  (action: LoadingMiddleWareAction) => {
    if (isPending(action)) {
      api.dispatch(
        addLoading({
          type: action.type.replace("/pending", ""),
          ignoreLoading: action?.meta?.ignoreLoading,
        })
      );
      if (action?.meta?.ignoreLoading) {
        showLoadingStatus.set(action.meta.requestId, true);
      }
    } else if (isFulfilled(action)) {
      api.dispatch(
        subLoading({
          type: action.type.replace("/fulfilled", ""),
          ignoreLoading: showLoadingStatus.get(action.meta.requestId),
        })
      );
      showLoadingStatus.delete(action.meta.requestId);
      return next(action);
    } else if (isRejected(action)) {
      api.dispatch(
        subLoading({
          type: action.type.replace("/rejected", ""),
          ignoreLoading: showLoadingStatus.get(action.meta.requestId),
        })
      );
      showLoadingStatus.delete(action.meta.requestId);
      return next(action);
    }
    return next(action);
  };

const store = configureStore({
  reducer: {
    users,
    global,
    dataBoard,
    news,
    factors,
    targetFund,
    stocks,
    factorFund,
    tradingDates,
    compareManage,
    dailyReturns,
    entities,
    fundDetail,
    portfolioList,
    portfolioAnalysis,
    fundManagers,
    createPortfolio,
    scenarioList,
    fundManagerDetail,
    fundCompanyDetail,
    replaceFund,
    workbench,
    customBenchmark,
    preferredFund,
    daterPeriodReturn,
    competition,
    aum,
    customerList,
    customerAnalysis,
    createCustomer,
    etfFundFilter,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(loadingMiddleWare).concat(logger),
});

if (process.env.NODE_ENV === "development") {
  window.store = store;
}
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppGetState = typeof store.getState;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
