import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { Card, Space, Table } from "antd";
import { useAppSelector } from "@/hooks/redux";
import { DataRange } from "./fundAllocation";
import {
  indexOf,
  prop,
  isNil,
  size,
  map,
  flow,
  forEach,
  set,
  sumBy,
  orderBy,
} from "lodash/fp";
import {
  FundDetailStockPositionTrend,
  StockWeightListItem,
} from "@/model/fundDetail";
import { fastProp, mapIndexed, normalize } from "@/util/opt";
import { FundAntdTableProps } from "@/components/fundResultTable";
import { allStocksMapSelector } from "@/selectors/stocks";
import ColorNumber from "@/components/colorNumber";
import { formatPercentage } from "@/util/numberFormatter";
import { scaleLinear } from "d3-scale";
import style from "../index.module.less";
import { sectorCategories } from "@/model/sectorCategory";
import { isOther } from "@/views/compareManage/fundCompare/constant";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { formatter } from "@/constant/factorFormatter";
import { AutoResizer, BaseTableProps } from "react-base-table";
import VirtualTableComponent from "@/components/virtualTable";
import { useMemoizedFn } from "ahooks";
import { addZeroIndex } from "@/util/numberFormatter";
import SectorCategoriesSelect from "@/components/sectorCategoriesSelect";
import mapper from "../mapper";

const VirtualTable = VirtualTableComponent<any>();
const dateCellWidth = 150;
const { Summary } = Table;
const color: any = scaleLinear()
  .domain([
    0.0199, 0.0299, 0.0349, 0.035, 0.045, 0.055, 0.065, 0.07, 0.08, 0.085,
    0.095,
  ])
  .range([
    "#B8DAFF",
    "#A0CDFF",
    "#89C1FF",
    "#FCE6E5",
    "#FACECC",
    "#F7AAA7",
    "#F69D9A",
    "#F16D67",
    "#EE4842",
    "#EB3028",
    "#E80B02",
  ] as any);
const renderIndustry = (
  stocksIndustryId: string,
  sectorCategoryMap: Record<string, sectorCategories>,
  formatMessage: (arg0: string) => string
) => {
  return isOther(stocksIndustryId)
    ? formatMessage(stocksIndustryId)
    : prop(`${stocksIndustryId}.name`)(sectorCategoryMap);
};
const useGetColumns = () => {
  const formatMessage = useFormatMessage();
  const sectorCategoryMap = useAppSelector(sectorCategoriesMapSelector);
  return useMemo<FundAntdTableProps["columns"]>(
    () => [
      {
        title: formatMessage("serialNumber"),
        dataIndex: "index",
        key: "index",
        align: "center",
      },
      {
        title: formatMessage("stockCode"),
        dataIndex: "code",
        key: "code",
        align: "center",
      },
      {
        title: formatMessage("stockAbbreviation"),
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        title: formatMessage("totalPercent"),
        dataIndex: "stockWeight",
        key: "stockWeight",
        align: "center",
        render: (v: number) => formatPercentage(v),
      },
      {
        title: formatMessage("ChangeOverPeriod"),
        dataIndex: "lastPeriodStockWeight",
        key: "lastPeriodStockWeight",
        align: "center",
        render: (v: number) => <ColorNumber value={v} formatter="percentage" />,
      },
      {
        title: formatMessage("industry"),
        dataIndex: "industry",
        key: "industry",
        align: "center",
        render: (v: string) =>
          renderIndustry(v, sectorCategoryMap, formatMessage),
      },
    ],
    [formatMessage, sectorCategoryMap]
  );
};
type TableProps = BaseTableProps<any>;
const useGetTableColumns = (date: string[]) => {
  const formatMessage = useFormatMessage();
  return useMemo<TableProps["columns"]>(
    () => [
      {
        title: formatMessage("serialNumber"),
        dataKey: "index",
        key: "index",
        align: "center",
        frozen: "left",
        width: 100,
      },
      {
        title: formatMessage("stockCode"),
        dataKey: "code",
        key: "code",
        align: "center",
        frozen: "left",
        width: 100,
      },
      {
        title: formatMessage("stockAbbreviation"),
        dataKey: "name",
        key: "name",
        align: "center",
        frozen: "left",
        width: 100,
      },
      ...(date.map((v) => ({
        title: v,
        dataKey: v,
        key: v,
        align: "center",
        width: dateCellWidth,
        cellRenderer: ({ rowData }: any) => (
          <div
            className={style.VirtualTableCell}
            style={{
              backgroundColor: color(fastProp(v)(rowData)),
            }}
          >
            {formatter.percent2(fastProp(v)(rowData))}
          </div>
        ),
      })) as any),
      {
        title: formatMessage("HoldingQuarter"),
        dataKey: "holdingQuarter",
        key: "holdingQuarter",
        align: "center",
        frozen: "right",
        width: 100,
      },
    ],
    [date, formatMessage]
  );
};
const useIncreaseMessage = (ratio: number, lastRatio: number | undefined) => {
  const formatMessage = useFormatMessage();
  if (isNil(ratio) || isNil(lastRatio)) return "";
  if (ratio > lastRatio) return formatMessage("UpPeriod");
  return formatMessage("LowerPeriod");
};

const SummaryNode = ({
  ratio,
  lastRatio,
  concentrationRatio,
  lastConcentrationRatio,
}: {
  ratio: number;
  lastRatio: number | undefined;
  concentrationRatio: number;
  lastConcentrationRatio: number | undefined;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Summary.Row>
        <Summary.Cell align="center" index={1}>
          {formatMessage("total")}
        </Summary.Cell>
        <Summary.Cell align="center" index={2} colSpan={5}>
          <ColorNumber value={ratio} formatter="percentage" />
          &nbsp;&nbsp;{useIncreaseMessage(ratio, lastRatio)}
        </Summary.Cell>
      </Summary.Row>
      <Summary.Row>
        <Summary.Cell align="center" index={1}>
          {formatMessage("concentration")}
        </Summary.Cell>
        <Summary.Cell align="center" index={2} colSpan={5}>
          <ColorNumber value={concentrationRatio} formatter="percentage" />
          &nbsp;&nbsp;
          {useIncreaseMessage(concentrationRatio, lastConcentrationRatio)}
        </Summary.Cell>
      </Summary.Row>
    </>
  );
};
const useGetTableDataSource = (
  range: string,
  fundDetailStockPositionTrendMap: Record<string, FundDetailStockPositionTrend>,
  lastPeriodDateRange: string
) => {
  const stocksMap = useAppSelector(allStocksMapSelector);
  const dataSource = prop(`${range}.stockWeightList`)(
    fundDetailStockPositionTrendMap
  );
  const lastPeriodDataSource = prop(`${lastPeriodDateRange}.stockWeightList`)(
    fundDetailStockPositionTrendMap
  );
  const lastPeriodDataSourceMap = normalize("stockCode")(lastPeriodDataSource);
  const tableDataSource = useMemo(
    () =>
      flow(
        orderBy("weight", "desc"),
        mapIndexed(
          (
            { stockCode, weight, industryId }: StockWeightListItem,
            index: number
          ) => ({
            index: addZeroIndex(index + 1),
            code: stockCode,
            name: prop(`${stockCode}.name`)(stocksMap),
            stockWeight: weight,
            lastPeriodStockWeight:
              weight - prop(`${stockCode}.weight`)(lastPeriodDataSourceMap),
            industry: industryId,
          })
        )
      )(dataSource) as FundAntdTableProps["dataSource"],
    [dataSource, lastPeriodDataSourceMap, stocksMap]
  );
  const sumRatio = useMemo(
    () =>
      flow(
        prop(`${range}.stockWeightList`),
        sumBy("weight")
      )(fundDetailStockPositionTrendMap),
    [fundDetailStockPositionTrendMap, range]
  );
  const lastSumRatio = useMemo(
    () =>
      flow(prop(`${lastPeriodDateRange}.stockWeightList`), (data) =>
        !data ? undefined : sumBy("weight")(data)
      )(fundDetailStockPositionTrendMap),
    [fundDetailStockPositionTrendMap, lastPeriodDateRange]
  );
  return useMemo(
    () => ({
      tableDataSource,
      concentrationRatio: prop(`${range}.concentrationRatio`)(
        fundDetailStockPositionTrendMap
      ),
      lastConcentrationRatio: prop(`${lastPeriodDateRange}.concentrationRatio`)(
        fundDetailStockPositionTrendMap
      ),
      sumRatio,
      lastSumRatio,
    }),
    [
      fundDetailStockPositionTrendMap,
      lastPeriodDateRange,
      lastSumRatio,
      range,
      sumRatio,
      tableDataSource,
    ]
  );
};
const useDateDataSource = (
  date: string[],
  stocksPositionMap: any,
  stockCodes: string[]
) => {
  const stocksMap = useAppSelector(allStocksMapSelector);
  return useMemo(
    () =>
      mapIndexed((code: string, index: number) => {
        let stock = {
          index: addZeroIndex(index + 1),
          code,
          name: prop(`${code}.name`)(stocksMap),
          holdingQuarter: size(fastProp(code)(stocksPositionMap)),
        };
        forEach(
          (v: string) =>
            (stock = set(
              v,
              prop(`${code}.${v}.weight`)(stocksPositionMap)
            )(stock))
        )(date);
        return stock;
      })(stockCodes),
    [date, stockCodes, stocksMap, stocksPositionMap]
  );
};
const FundTopPositions = ({
  fundId,
  updateHandler,
}: {
  fundId: string;
  updateHandler: (key: string) => (value: any) => any;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  const {
    industryId,
    stockPositionDates,
    fundDetailStockPositionTrendMap,
    stockPositionRange,
    stocksPositionMap,
  } = useAppSelector((state) => mapper(state, fundId));
  const [dateRange, changeDateRange] = useState("");
  useEffect(
    () => changeDateRange(stockPositionRange as string),
    [stockPositionRange]
  );
  const lastPeriodDateRange = useMemo(() => {
    const index = indexOf(dateRange)(stockPositionDates);
    return stockPositionDates?.[index + 1];
  }, [dateRange, stockPositionDates]);
  const {
    tableDataSource,
    concentrationRatio,
    sumRatio,
    lastSumRatio,
    lastConcentrationRatio,
  } = useGetTableDataSource(
    dateRange,
    fundDetailStockPositionTrendMap as Record<
      string,
      FundDetailStockPositionTrend
    >,
    lastPeriodDateRange
  );
  const columns = useGetColumns();
  const virtualTableColumns = useGetTableColumns(stockPositionDates);
  const stockCodes = useMemo(
    () =>
      flow(
        prop(`${dateRange}.stockWeightList`),
        map(fastProp("stockCode"))
      )(fundDetailStockPositionTrendMap),
    [dateRange, fundDetailStockPositionTrendMap]
  );
  const dateDataSource = useDateDataSource(
    stockPositionDates,
    stocksPositionMap,
    stockCodes
  );
  const virtualTableRef = useRef<any>(null);
  const onChangeDateRange = useMemoizedFn((v: string) => {
    changeDateRange(v);
    const index = indexOf(v)(stockPositionDates);
    virtualTableRef.current?.scrollToLeft(index * dateCellWidth);
  });

  const changeSectorId = useCallback(
    (v: string) => {
      updateHandler("industryId")(v);
    },
    [updateHandler]
  );

  return (
    <Card
      title={formatMessage("DetailsOfTopTenPositions")}
      bordered={false}
      size="small"
      extra={
        <Space>
          <span>{formatMessage("industryDistribution")}</span>
          <SectorCategoriesSelect
            value={industryId}
            onChange={changeSectorId}
            size="small"
          />
          <DataRange
            date={stockPositionDates}
            value={dateRange}
            onChange={onChangeDateRange}
          />
        </Space>
      }
    >
      <Space
        direction="vertical"
        size="large"
        className={style.FundTopPositionsCardContent}
      >
        <div>
          <h5 className={style.TableTitle}>
            {formatMessage("topTenHeavyPositionTip")}
          </h5>
          <Table
            dataSource={tableDataSource}
            pagination={false}
            bordered
            columns={columns}
            className={style.Table}
            summary={() => (
              <SummaryNode
                ratio={sumRatio}
                lastRatio={lastSumRatio}
                concentrationRatio={concentrationRatio}
                lastConcentrationRatio={lastConcentrationRatio}
              />
            )}
          />
        </div>
        <div className={style.TopTenHeavyPosition}>
          <h5 className={style.TableTitle}>
            {formatMessage("topTenHeavyPositionTipChange")}
          </h5>
          <div className={style.AutoResizer}>
            <AutoResizer>
              {({ width, height }) => (
                <VirtualTable
                  className={style.VirtualTable}
                  fixed
                  ref={virtualTableRef}
                  data={dateDataSource}
                  pagination={false}
                  headerHeight={36}
                  rowHeight={36}
                  width={width}
                  height={height}
                  rowKey="fundId"
                  columns={virtualTableColumns}
                />
              )}
            </AutoResizer>
          </div>
        </div>
      </Space>
    </Card>
  );
};

export default React.memo(FundTopPositions);
