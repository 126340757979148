import { conditionsParam } from "@/model/entities";
import { useFormatMessage } from "@/util/formatMessage";
import { Button, Card, Form, Space } from "antd";
import { flow, prop, size, sumBy, uniqBy } from "lodash/fp";
import BenchmarkSelect from "../benchmarkSelect";
import NumberInput from "../numberInput";
import style from "./index.module.less";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useGetBenchmarkChangeValue } from "./hook";
import { useAppSelector } from "@/hooks/redux";
import { marketIndexListSelector } from "@/selectors/benchmarks";
import getMessage from "@/util/getMessage";

type CategoryTypeProp = {
  conditions: conditionsParam[];
  categoryOptionMap: Record<string, any>;
  onChangeValue: (key: string) => (value: any) => void;
  disabled: boolean;
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 21 },
  },
};

const CategoryType = ({
  conditions,
  categoryOptionMap,
  onChangeValue,
  disabled,
}: CategoryTypeProp) => {
  const formatMessage = useFormatMessage();
  const {
    onChangeConditions,
    onChangeParentWeight,
    addComponentBenchmark,
    delComponentBenchmark,
  } = useGetBenchmarkChangeValue(conditions, onChangeValue);

  const customOptions = useAppSelector(marketIndexListSelector);

  return (
    <div className={style.ConditionPart}>
      <Form.List
        name="conditions"
        rules={[
          {
            validator: async (_, conditions) => {
              if (
                parseFloat(sumBy("weight")(conditions).toPrecision(10)) !== 1
              ) {
                return Promise.reject(
                  new Error(getMessage("customBenchmarkEditTip"))
                );
              }
            },
          },
        ]}
      >
        {(fields, _, { errors }) => (
          <Space direction="vertical" className={style.FullWidth}>
            {fields.map((field: Record<string, any>, index: number) => (
              <Card
                key={index}
                size="small"
                title={
                  <div className={style.CategoryName}>
                    <Form.Item
                      label={prop(`${conditions[field?.key]?.categoryId}.name`)(
                        categoryOptionMap
                      )}
                      {...field}
                      name={[field.key, "weight"]}
                      {...formItemLayout}
                    >
                      <NumberInput
                        type="PERCENTAGE"
                        min={0}
                        max={1}
                        precision={2}
                        size="small"
                        placeholder=""
                        addonAfter="%"
                        className={style.WeightInput}
                        // value={conditions[field.key]?.weight}
                        onChange={onChangeParentWeight(index)("weight")}
                        disabled={disabled}
                      />
                    </Form.Item>
                    <Form.ErrorList errors={errors} />
                  </div>
                }
              >
                <div className={style.BenchmarkWeights}>
                  <div className={style.ConditionHeader}>
                    <div className={style.Benchmark}>
                      {formatMessage("componentBenchmark")}
                    </div>
                    <div className={style.Weight}>
                      {formatMessage("Weight")}
                    </div>
                    <div className={style.Opeater}></div>
                  </div>
                  <div className={style.ConditionBody}>
                    <Form.List
                      name={[field.key, "benchmarkWeights"]}
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (
                              parseFloat(
                                sumBy("weight")(value).toPrecision(10)
                              ) !== 1
                            ) {
                              return Promise.reject(
                                new Error(
                                  getMessage("customBenchmarkConditionTip")
                                )
                              );
                            }
                            if (
                              flow(uniqBy("benchmarkId"), size)(value) !==
                              size(value)
                            ) {
                              return Promise.reject(
                                new Error(
                                  getMessage("customBenchmarkiNoSameTip")
                                )
                              );
                            }
                          },
                        },
                      ]}
                    >
                      {(childrenFields, _, { errors: childErrors }) => (
                        <>
                          {childrenFields.map(
                            (childField: Record<string, any>, childIndex) => (
                              <div
                                className={style.ConditionContent}
                                key={childIndex}
                              >
                                <div className={style.Benchmark}>
                                  <Form.Item
                                    {...childField}
                                    name={[childField?.key, "benchmarkId"]}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: formatMessage(
                                          "performanceBenchmarkIsNull"
                                        ),
                                      },
                                    ]}
                                  >
                                    <BenchmarkSelect
                                      className={style.BenchmarkSelect}
                                      value={childField.benchmarkId}
                                      onChange={onChangeConditions(
                                        conditions[field.key]?.benchmarkWeights
                                      )(index)("benchmarkWeights")(childIndex)(
                                        "benchmarkId"
                                      )}
                                      customOptions={customOptions}
                                      disabled={disabled}
                                    />
                                  </Form.Item>
                                </div>
                                <div className={style.Weight}>
                                  <Form.Item
                                    {...childField}
                                    name={[childField.key, "weight"]}
                                  >
                                    <NumberInput
                                      type="PERCENTAGE"
                                      min={0}
                                      max={1}
                                      precision={2}
                                      size="middle"
                                      placeholder=""
                                      addonAfter="%"
                                      className={style.WeightInput}
                                      value={childField.weight}
                                      onChange={onChangeConditions(
                                        conditions[field.key]?.benchmarkWeights
                                      )(index)("benchmarkWeights")(childIndex)(
                                        "weight"
                                      )}
                                      disabled={disabled}
                                    />
                                  </Form.Item>
                                </div>
                                <div className={style.Opeater}>
                                  {size(
                                    conditions[field.key]?.benchmarkWeights
                                  ) ===
                                    childIndex + 1 && (
                                    <Button
                                      type="link"
                                      icon={<PlusOutlined />}
                                      onClick={() =>
                                        addComponentBenchmark(
                                          conditions[field.key]
                                            ?.benchmarkWeights
                                        )(index)("benchmarkWeights")
                                      }
                                      disabled={disabled}
                                    >
                                      {formatMessage("add")}
                                    </Button>
                                  )}
                                  {size(
                                    conditions[field.key]?.benchmarkWeights
                                  ) > 1 && (
                                    <Button
                                      type="link"
                                      icon={<DeleteOutlined />}
                                      onClick={() =>
                                        delComponentBenchmark(
                                          conditions[field.key]
                                            ?.benchmarkWeights
                                        )(index)("benchmarkWeights")(childIndex)
                                      }
                                      disabled={disabled}
                                    />
                                  )}
                                </div>
                              </div>
                            )
                          )}
                          <Form.ErrorList errors={childErrors} />
                        </>
                      )}
                    </Form.List>
                  </div>
                </div>
              </Card>
            ))}
          </Space>
        )}
      </Form.List>
    </div>
  );
};

export default CategoryType;
