import { Descriptions } from "antd";
import React from "react";
import cn from "classnames";
import style from "./index.module.less";
import { factorsFormatter } from "@/constant/factorFormatter";
import { useFormatMessage } from "@/util/formatMessage";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import ToFundCompanyDetailPage from "@/components/navigateToPage/toFundCompanyDetailPage";
import { fixedNumber } from "@/util/numberFormatter";
import ColorNumber from "@/components/colorNumber";
import { floatEqual } from "@/util/opt";

export default React.memo<{
  className?: string;
  company: string;
  majorFund: string;
  majorFundName: string;
  manageScale: number;
  annualizedReturn: number;
  historyManageScale: number;
  workAge: number;
  manageAmount: number;
  historyManageAmount: number;
  qualification: string;
  companyId: string;
}>(
  ({
    className,
    company,
    majorFund,
    manageScale,
    annualizedReturn,
    historyManageScale,
    workAge,
    manageAmount,
    historyManageAmount,
    qualification,
    majorFundName,
    companyId,
  }) => {
    const formatMessage = useFormatMessage();
    return (
      <Descriptions
        bordered
        column={2}
        className={cn(style.ManagerDescription, className)}
      >
        <Descriptions.Item label={formatMessage("nowCompany")}>
          <ToFundCompanyDetailPage
            className={style.Content}
            id={companyId}
            name={company}
          />
        </Descriptions.Item>
        <Descriptions.Item label={formatMessage("manageSize")}>
          {!floatEqual(0)(manageScale) && (
            <ColorNumber value={manageScale} formatValue={fixedNumber} />
          )}
        </Descriptions.Item>
        <Descriptions.Item label={formatMessage("representativeWorks")}>
          <ToFundDetailPage
            className={style.Content}
            id={majorFund}
            name={majorFundName}
          />
        </Descriptions.Item>
        <Descriptions.Item label={formatMessage("annualYield")}>
          <ColorNumber value={annualizedReturn} formatter="percentage" />
        </Descriptions.Item>
        <Descriptions.Item label={formatMessage("manageTotalScale")}>
          {factorsFormatter.scaleWithHundredMillion(historyManageScale)}
          {formatMessage("Millions")}
        </Descriptions.Item>
        <Descriptions.Item label={formatMessage("workAge")}>
          {workAge}
          {formatMessage("year")}
        </Descriptions.Item>
        <Descriptions.Item label={formatMessage("tubeFund")}>
          {manageAmount}
          {formatMessage("zhi")}
        </Descriptions.Item>
        <Descriptions.Item label={formatMessage("education")}>
          {qualification}
        </Descriptions.Item>
        <Descriptions.Item label={formatMessage("cumulativeManagementFund")}>
          {historyManageAmount}
          {formatMessage("zhi")}
        </Descriptions.Item>
      </Descriptions>
    );
  }
);
