import React, { useMemo } from "react";
import { useFormatMessage, FormatMessageFunc } from "@/util/formatMessage";
import { treeDataType } from "@/model/fundDetail";
import { getTreeChartOptions } from "@/util/chart";
import { attributionTooltipFormatter } from "../../constant";
import EchartsWrapper from "@/echarts/echartsWrapper";

const getTreeData = (
  brinsonTreeChartData: Record<string, any>,
  formatMessage: FormatMessageFunc
) => {
  const {
    portfolioAttribution,
    benchmarkAttribution,
    activeAttribution,

    allocationAttribution,
    selectionAttribution,
    timingOrInteractionAttribution,
    otherAttribution,
  } = brinsonTreeChartData;
  return {
    name: formatMessage("portfolioAttribution"),
    value: portfolioAttribution,
    children: [
      {
        name: formatMessage("benchmarkAttribution"),
        value: benchmarkAttribution,
      },
      {
        name: formatMessage("activeAttribution"),
        value: activeAttribution,
        children: [
          {
            name: formatMessage("allocationAttribution"),
            value: allocationAttribution,
          },
          {
            name: formatMessage("selectionFundAttribution"),
            value: selectionAttribution,
          },
          {
            name: formatMessage("timingAttribution"),
            value: timingOrInteractionAttribution,
          },
          {
            name: formatMessage("otherAttributions"),
            value: otherAttribution,
          },
        ],
      },
    ],
  };
};

const getHandleOptions = (
  formatMessage: FormatMessageFunc,
  treeData: treeDataType
) =>
  getTreeChartOptions(treeData, {
    tooltip: {
      formatter: attributionTooltipFormatter(formatMessage),
    },
  });

export default React.memo<{
  brinsonTreeChartData: Record<string, any>;
}>(({ brinsonTreeChartData }) => {
  const formatMessage = useFormatMessage();

  const treeData: treeDataType = useMemo(
    () => getTreeData(brinsonTreeChartData, formatMessage),
    [brinsonTreeChartData, formatMessage]
  );

  const handleOptions = useMemo(
    () => getHandleOptions(formatMessage, treeData),
    [formatMessage, treeData]
  );

  return <EchartsWrapper options={handleOptions} />;
});
