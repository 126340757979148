import { useFormatMessage } from "@/util/formatMessage";
import { Button, Card, Space } from "antd";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import style from "./index.module.less";
import { CSI300_ID } from "../../constant";
import { incomeRangeOptions } from "../constant";
import CumulativeChart from "@/components/cumulativeChart";
import {
  fundTypeItem,
  fundTypeOptions,
  STOCK_FUND,
} from "@/views/fundCompanyDetail/constant";
import { isEmpty, map } from "lodash/fp";
import { useAppDispatch } from "@/hooks/redux";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import { fetchGetCompanyDailyReturn } from "@/store/fundCompanyDetail";
import { useIncomeTrendChart } from "../hook";
import LoadingComponent from "@/components/LoadingComponent";
import BenchmarkSelect from "@/components/benchmarkSelect";
import EmptyContent from "@/components/emptyContent";
import StatisticRange from "@/components/statisticRange";
import { COMMON_TIME } from "@/constant/statisticRange";
import ChartRangePicker from "../../components/chartRangePicker";

type incomeTrendProps = {
  companyIds: string[];
  className?: string;
};

export default React.memo<incomeTrendProps>(({ className, companyIds }) => {
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();
  const [benchmarkId, setBenchmarkId] = useState(CSI300_ID);
  const [activeRange, setActiveRange] = useState(COMMON_TIME);
  const [fundType, setFundType] = useState(STOCK_FUND);
  useEffect(() => {
    dispatch(fetchFundAndBenchmarkDailyReturns(benchmarkId));
    companyIds.map((v: string) =>
      dispatch(fetchGetCompanyDailyReturn({ id: v, fundType }))
    );
  }, [benchmarkId, companyIds, dispatch, fundType]);
  const { chartData, dates } = useIncomeTrendChart(
    companyIds,
    fundType,
    benchmarkId,
    activeRange
  );
  return (
    <LoadingComponent actions={fetchFundAndBenchmarkDailyReturns}>
      <Card
        bordered={false}
        title={formatMessage("incomeTrend")}
        className={cn(className, style.CardHeadBorder)}
      >
        <div className={style.IncomeRange}>
          <Space>
            <span>{formatMessage("benchmark")}</span>
            <BenchmarkSelect
              value={benchmarkId}
              onChange={setBenchmarkId}
              className={style.BenchmarkSelect}
            />
            <span>{formatMessage("periodOfTime")}</span>
            <StatisticRange
              className={style.rangeSelect}
              value={activeRange}
              statisticRangeList={incomeRangeOptions as any}
              onChange={(e: any) => setActiveRange(e)}
            />
            <ChartRangePicker dateList={dates} className={style.RangePicker} />
          </Space>
          {/* <YieldStatisticRangePicker
            value={activeRange}
            options={incomeRangeOptions}
            onChange={(e: any) => setActiveRange(e)}
          /> */}
        </div>
        <Space className={style.FundTypeGroup}>
          {map(({ id, messageKey }: fundTypeItem) => (
            <Button
              key={id}
              type={id === fundType ? "primary" : "default"}
              onClick={() => setFundType(id)}
              className={id === fundType ? "" : style.FundTypeButton}
            >
              {formatMessage(messageKey)}
            </Button>
          ))(fundTypeOptions)}
        </Space>
        {isEmpty(dates) ? (
          <EmptyContent
            message={formatMessage("noData")}
            className={style.EmptyContent}
          />
        ) : (
          <CumulativeChart
            dates={dates}
            dataSource={chartData}
            type="cumulative"
          />
        )}
      </Card>
    </LoadingComponent>
  );
});
