import { useAppSelector } from "@/hooks/redux";
import {
  getNatureDateAndTradingDate,
  getNextTradingDate,
} from "@/util/processedDates";
import InfoIcon from "@/assets/infoIcon.svg";
import { useCreation } from "ahooks";
import cn from "classnames";
import { Form, Radio } from "antd";
import { map } from "lodash/fp";
import React from "react";
import {
  balanceRuleConfig,
  periodFrequencyConfig,
  rebalanceConfig,
} from "../../constant";
import {
  FundConfigurationForm,
  FundConfigurationFormUpdater,
} from "../../interface";
import AddSpecifiedDate from "./addSpecifiedDate";
import style from "./index.module.less";
import ButtonRadioGroup from "../../components/buttonRadioGroup";

const useGetRebalanceGroup = (foundDate: string, endDate: string) => {
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  if (!foundDate || !endDate) return null;
  const positionNextDate = getNextTradingDate(
    tradingDateList,
    processedTradingDates,
    foundDate
  );
  const [natureDateCount] = getNatureDateAndTradingDate(
    positionNextDate,
    endDate
  )(processedTradingDates);
  return natureDateCount;
};

export default React.memo<{
  formData: FundConfigurationForm;
  endDate: string;
  updateFormData: FundConfigurationFormUpdater;
}>(({ formData, endDate, updateFormData }) => {
  const natureDateCount = useGetRebalanceGroup(formData.foundDate, endDate);
  const rebalanceGroups = useCreation(
    () => rebalanceConfig.getRebalanceGroupByNatureDateCount(natureDateCount),
    [natureDateCount]
  );
  const frequencyGroups = useCreation(
    () =>
      periodFrequencyConfig.getFrequencyGroupByNatureDateCount(natureDateCount),
    [natureDateCount]
  );
  const balanceRuleGroups = useCreation(
    () => balanceRuleConfig.getBalanceRuleByModelType(formData.modelType),
    [formData.modelType]
  );
  return (
    <div>
      <Form.Item
        label="再平衡方式"
        className={cn(style.FormItem, style.FormLabelWidth110)}
      >
        <ButtonRadioGroup
          options={rebalanceGroups as any}
          value={formData.rebalanceType}
          onChange={updateFormData("rebalanceType")}
        />
      </Form.Item>
      {formData.rebalanceType ? (
        <div className={style.GreyLayout}>
          {formData.rebalanceType === rebalanceConfig.onlyOnce ? (
            <div className={style.AlertFlex}>
              <img src={InfoIcon} className={style.AlertIcon} />
              <span>建仓后不再调仓</span>
            </div>
          ) : (
            <>
              {formData.rebalanceType === rebalanceConfig.fixInterval && (
                <Form.Item
                  label="* 定期频率"
                  className={cn(style.FormItem, style.FormLabelWidth110)}
                >
                  <Radio.Group
                    value={formData.periodicFrequency}
                    onChange={(e) =>
                      updateFormData("periodicFrequency")(e.target.value)
                    }
                    className={style.FrequencyRadio}
                  >
                    {map(({ id, message }) => (
                      <Radio value={id} key={id}>
                        {message}
                      </Radio>
                    ))(frequencyGroups)}
                  </Radio.Group>
                </Form.Item>
              )}
              {formData.rebalanceType === rebalanceConfig.fixDates && (
                <Form.Item
                  label="* 指定日期"
                  className={cn(style.FormItem, style.FormLabelWidth110)}
                >
                  <AddSpecifiedDate
                    startDate={formData.foundDate}
                    endDate={endDate}
                    dates={formData.specifiedDates}
                    onUpdate={updateFormData("specifiedDates")}
                  />
                </Form.Item>
              )}
              <Form.Item
                label="* 平衡规则"
                className={cn(style.FormItem, style.FormLabelWidth110)}
              >
                <Radio.Group
                  value={formData.turnoverRule}
                  onChange={(e) =>
                    updateFormData("turnoverRule")(e.target.value)
                  }
                >
                  {map(({ id, message }) => (
                    <Radio key={id} value={id}>
                      {message}
                    </Radio>
                  ))(balanceRuleGroups)}
                </Radio.Group>
              </Form.Item>
            </>
          )}
        </div>
      ) : (
        <div className={style.EmptyContent} />
      )}
    </div>
  );
});
