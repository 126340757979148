import { FundsInterface } from "@/model/entities";
import { useCreation } from "ahooks";
import { Table, TableProps } from "antd";
import { size } from "lodash/fp";
import React from "react";
import {
  FundConfigurationAsset,
  FundConfigurationAssetsUpdater,
} from "../../fundConfiguration/interface";
import { Asset } from "../../manualCreatePortfolio/constant";
import type { ErrorField } from "../../fundConfiguration/interface";
import { useTransferAssetToFundAsset } from "../../manualCreatePortfolio/hooks";
import { mergeRowsFunc, useGetFundWeightTableColumns } from "./hooks";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";

export type FundWeightTableProps = TableProps<FundsInterface & Asset>;
export type FundWeightProps = {
  dataSource: FundConfigurationAsset[];
  onChange?: (updater: FundConfigurationAssetsUpdater) => any;
  editable?: boolean;
  className?: string;
  assetIntersectionStartDate?: string;
  assetIntersectionEndDate?: string;
  errorField?: ErrorField;
};
export default React.memo<FundWeightProps>(
  ({
    dataSource,
    onChange,
    errorField,
    assetIntersectionStartDate,
    assetIntersectionEndDate,
  }) => {
    const formatMessage = useFormatMessage();
    const columns = useGetFundWeightTableColumns({
      onChange,
      errorField,
      assetIntersectionStartDate,
      assetIntersectionEndDate,
    });
    const transfer = useTransferAssetToFundAsset();
    const fundAndAssetDataSource = useCreation(
      () => mergeRowsFunc(transfer(dataSource) as any, "category"),
      [transfer, dataSource]
    );
    const scroll = useCreation(
      () => (size(dataSource) > 10 ? { y: 407 } : undefined),
      [dataSource]
    );
    return (
      <Table
        bordered
        className={style.FundConfigurationTable}
        rowKey="fundId"
        columns={columns as any}
        dataSource={fundAndAssetDataSource}
        pagination={false}
        scroll={scroll}
        summary={(pageData) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                {formatMessage("fundTotaSize", { size: size(pageData) })}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    );
  }
);
