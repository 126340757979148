import React from "react";
import MultiLoadableComponentLayout from "@/components/multiLoadableComponentLayout";
import { portfolioAnalysisMenus } from "./constant";
import { useCreation } from "ahooks";
import { PortfolioAnalysisProvider } from "@/providers/portfolioAnalysisProvider";
import { AnalysisType } from "@/providers/portfolioAnalysisProvider/context";

export type PortfolioAnalysisProps = {
  id: string;
  startDate?: string;
  endDate?: string;
  analysisType?: AnalysisType;
};
export default React.memo<PortfolioAnalysisProps>(
  ({ id, startDate, endDate, analysisType }) => {
    const componentProps = useCreation(() => ({ id }), [id]);
    return (
      <PortfolioAnalysisProvider
        id={id}
        start={startDate}
        end={endDate}
        analysisType={analysisType}
      >
        <MultiLoadableComponentLayout
          componentProps={componentProps}
          menus={portfolioAnalysisMenus}
        />
      </PortfolioAnalysisProvider>
    );
  }
);
