import { escapeRegExpFn } from "@/util/opt";
import { replace, size } from "lodash/fp";
import { Tooltip } from "antd";

const HighLight = (props: {
  value: string;
  keyValue: string;
  color?: string;
}): JSX.Element => {
  const { value, keyValue, color = "#FF0A00" } = props || {};
  if (value && size(keyValue) > 0) {
    const lowerTemp = replace(
      new RegExp(escapeRegExpFn(keyValue), "ig"),
      (match) => `<span style="color:${color};">${match}</span>`
    )(value);
    return <span dangerouslySetInnerHTML={{ __html: lowerTemp }}></span>;
  }
  return <Tooltip title={value}>{value}</Tooltip>;
};

export default HighLight;
