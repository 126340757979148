import performancePreview from "../positionStructure/performancePreview";
import portfolioOverview from "./portfolioOverview";
import predictMaxLoss from "@/views/aum/deepPerformance/predictMaxLoss";
import performanceAttribution from "@/views/aum/deepPerformance/performanceAttribution";
export const menus = [
  {
    id: "portfolioOverview",
    messageKey: "portfolioOverview",
    Component: portfolioOverview,
  },
  {
    id: "performancePreview",
    messageKey: "performancePreview",
    Component: performancePreview,
  },
  {
    id: "predictMaxLoss",
    messageKey: "predictMaxLoss",
    Component: predictMaxLoss,
  },
  {
    id: "performanceAttribution",
    messageKey: "performanceAttribution",
    Component: performanceAttribution,
  },
];
