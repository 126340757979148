import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { fastProp } from "@/util/opt";
import { Select, SelectProps } from "antd";
import { map } from "lodash/fp";
import React, { useMemo } from "react";
import { baseManagerSelector } from "../../selectors";
import style from "../index.module.less";

export default React.memo<
  SelectProps<string> & {
    id: string;
    type: string;
  }
>(({ id, type, ...restProps }) => {
  const baseManager = useAppSelector((state: RootState) =>
    baseManagerSelector(state, id)
  );
  const reportDate = useMemo(
    () =>
      type === "positionReportDate"
        ? fastProp("reportDate")(baseManager)
        : fastProp("reportYearDate")(baseManager),
    [baseManager, type]
  );
  const options = useMemo(
    () =>
      map((date: string) => ({
        label: date,
        value: date,
      }))(reportDate),
    [reportDate]
  );
  return <Select className={style.Select} options={options} {...restProps} />;
});
