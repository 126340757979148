import React, { useEffect, useMemo, useRef } from "react";
import type { LineSeriesOption, EChartsOption } from "echarts";
import { defaultColors } from "@/util/colors";
import EchartsWrapper, { WrapperProps } from "./echartsWrapper";
import { identity } from "lodash/fp";

export interface LineChartOpts extends WrapperProps {
  options: EChartsOption;
  series?: LineSeriesOption[];
  color?: string[];
  onChartClick?: (v: string) => void;
}

export default React.memo<LineChartOpts>(
  ({
    options,
    series,
    events = {},
    width,
    height,
    showDataZoom = true,
    color = defaultColors,
    onChartClick = identity,
  }) => {
    const handleOptions = useMemo<LineChartOpts["options"]>(
      () => ({
        ...options,
        color,
      }),
      [color, options]
    );
    const chartRef = useRef<any>(null);
    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.getEchartsInstance().setOption(
          {
            series: series,
          },
          { replaceMerge: "series" }
        );
      }
    }, [series]);
    useEffect(() => {
      if (chartRef.current) {
        const chart = chartRef.current.getEchartsInstance();
        chart?.getZr().on("click", (e: any) => {
          if (e?.target) {
            const pointInPixel = [e?.offsetX, e?.offsetY];
            const chart = chartRef.current.getEchartsInstance();
            if (chart?.containPixel("grid", pointInPixel)) {
              const pointInGrid = chart?.convertFromPixel(
                {
                  seriesIndex: 0,
                },
                pointInPixel
              );
              const xIndex = pointInGrid[0];
              const handleIndex = Number(xIndex);
              const op = chart?.getOption();
              const month = op.xAxis[0].data[handleIndex];
              onChartClick(month);
            }
          }
        });
      }
    }, [onChartClick]);
    return (
      <EchartsWrapper
        ref={chartRef}
        width={width}
        height={height}
        options={handleOptions}
        showDataZoom={showDataZoom}
        events={events}
      />
    );
  }
);
