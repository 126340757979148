import { Select, Space } from "antd";
import React from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { flow, identity, map, pick, toArray } from "lodash/fp";
import {
  RECENT_WEEK,
  RECENT_FIVE_YEAR,
  RECENT_TWO_YEAR,
  RECENT_HALF_YEAR,
  RECENT_MONTH,
  RECENT_THREE_MONTH,
  RECENT_THREE_YEAR,
  RECENT_YEAR,
  statisticRange,
} from "@/constant/statisticRange";
import style from "./index.module.less";
import BenchmarkSelect from "@/components/benchmarkSelect";

const options = flow(
  pick([
    RECENT_WEEK,
    RECENT_MONTH,
    RECENT_THREE_MONTH,
    RECENT_HALF_YEAR,
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    RECENT_THREE_YEAR,
    RECENT_FIVE_YEAR,
  ]),
  toArray
)(statisticRange);

type IncomeTrendExtraProps = {
  range: string;
  benchmarkId: string;
  onChangeRange: (v: string) => void;
  onChangeBenchmarkId: (v: string) => void;
};

const { Option } = Select;

export default React.memo<IncomeTrendExtraProps>(
  ({
    range,
    benchmarkId,
    onChangeRange = identity,
    onChangeBenchmarkId = identity,
  }) => {
    const formatMessage = useFormatMessage();
    return (
      <Space>
        <Space>
          <span>{formatMessage("benchmark")}</span>
          <BenchmarkSelect value={benchmarkId} onChange={onChangeBenchmarkId} />
        </Space>
        <div>
          <span>{formatMessage("timeFrame")} </span>
          <Select
            className={style.IncomeRange}
            value={range}
            onChange={onChangeRange}
          >
            {map(({ id, message }) => (
              <Option key={id} value={id}>
                {formatMessage(message)}
              </Option>
            ))(options)}
          </Select>
        </div>
      </Space>
    );
  }
);
