import React, { ReactNode, useEffect, useState } from "react";
import { Tabs, Card } from "antd";
import { map } from "lodash/fp";
import cn from "classnames";
import { useFormatMessage } from "@/util/formatMessage";
import { fetchCompareInfo } from "@/store/compareManage";
import FundCompare from "./fundCompare";
import FundCompanyCompare from "./fundCompanyCompare";
import FundManagerCompare from "./fundManagerCompare";
import style from "./index.module.less";
import { useAppDispatch } from "@/hooks/redux";

const { TabPane } = Tabs;

const compareManageTabs = [
  {
    id: "fundCompare",
    Component: FundCompare,
  },
  {
    id: "fundManagerCompare",
    Component: FundManagerCompare,
  },
  {
    id: "fundCompanyCompare",
    Component: FundCompanyCompare,
  },
];

const useGetReceiveInfo = (fundIds: string[] | undefined) => {
  const dispatch = useAppDispatch();
  const [managerIds, setManagerIds] = useState<string[][]>();
  const [companyIds, setCompanyIds] = useState<string[]>();
  useEffect(() => {
    if (fundIds) {
      dispatch(fetchCompareInfo(fundIds))
        .unwrap()
        .then((compareInfo: any) => {
          setManagerIds(map("managerIds")(compareInfo));
          setCompanyIds(map("fundCompany")(compareInfo));
        });
    }
  }, [dispatch, fundIds]);
  return {
    managerIds,
    companyIds,
  };
};
export default React.memo<{
  fundIds?: string[] | undefined;
  children?: ReactNode;
}>(({ fundIds }) => {
  const formatMessage = useFormatMessage();
  const { companyIds, managerIds } = useGetReceiveInfo(fundIds);
  return (
    <Card className={style.RootContainer}>
      <Tabs className={cn(style.Tabs)}>
        {map(({ id, Component }) => (
          <TabPane key={id} tab={formatMessage(id)}>
            <Component
              fundIds={fundIds}
              companyIds={companyIds}
              managerIds={managerIds}
            />
          </TabPane>
        ))(compareManageTabs)}
      </Tabs>
    </Card>
  );
});
