import { useFormatMessage } from "@/util/formatMessage";
import React, { useEffect, useMemo } from "react";
import { Table } from "antd";
import style from "../index.module.less";
import { getPositionTableColumns } from "../constant";
import ReportDate from "./reportDate";
import { BaseFundManagerDetailInfoProps } from "../../interface";
import {
  useGetIndustryMarketValueTableData,
  useManageReportDate,
} from "../hooks";
import { useAppDispatch } from "@/hooks/redux";
import { getFundManagerMarketValueInfo } from "@/store/fundManagerDetailSlice";
import { isEmpty } from "lodash/fp";
import EmptyContent from "@/components/emptyContent";
import LoadingComponent from "@/components/LoadingComponent";

export default React.memo<BaseFundManagerDetailInfoProps>(({ id }) => {
  const formatMessage = useFormatMessage();
  const [reportDate, changeReportDate] = useManageReportDate(
    id,
    "positionInfo.positionReportDate"
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id && reportDate) {
      dispatch(getFundManagerMarketValueInfo({ id, reportDate }));
    }
  }, [dispatch, id, reportDate]);
  const columns = useMemo(
    () => getPositionTableColumns(reportDate),
    [reportDate]
  );
  const dataSource = useGetIndustryMarketValueTableData(id, reportDate);
  return (
    <LoadingComponent actions={getFundManagerMarketValueInfo}>
      <div className={style.PositionCard}>
        <div className={style.Header}>
          <h3>{formatMessage("top10PositionMarketValue")}</h3>
          <ReportDate
            value={reportDate}
            type="positionReportDate"
            onChange={changeReportDate}
            id={id}
          />
        </div>
        {isEmpty(dataSource) ? (
          <EmptyContent
            message={formatMessage("noData")}
            className={style.EmptyContent}
          />
        ) : (
          <Table
            className={style.PositionTable}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            key="index"
          />
        )}
      </div>
    </LoadingComponent>
  );
});
