import React, { useMemo } from "react";

import { useFormatMessage } from "@/util/formatMessage";

import style from "../index.module.less";
import BaseEcharts from "../../../echarts/baseEcharts";
import { EChartsOption } from "echarts";
import {
  first,
  identity,
  join,
  keys,
  map,
  nth,
  prop,
  zip,
  values,
  flow,
} from "lodash/fp";
import { toFixedNumber } from "@/util/numberFormatter";
import { useAppSelector } from "@/hooks/redux";
import {
  EvaluationReportRiskSummaryBody,
  FactorValuesParam,
} from "@/model/fundDetail";
import { normalize } from "@/util/opt";
import { getProsperity } from "../constants";
import SecondLevelName from "../../../secondLevelName";

export default React.memo(({ fundId }: { fundId: string }) => {
  const formatMessage = useFormatMessage();

  const evaluationReportRiskSummary: EvaluationReportRiskSummaryBody =
    useAppSelector(prop(`fundDetail.${fundId}.evaluationReportRiskSummary`));

  const { mainFactorRisk } = evaluationReportRiskSummary || {};

  const { factorValues, top2FactorCongestion, comprehensiveFactorCongestion } =
    mainFactorRisk || {};

  const factorValuesMap = useMemo(
    () => normalize("factorCode")(factorValues),
    [factorValues]
  );

  const top2FactorNames = useMemo(
    () =>
      flow(
        keys,
        map(
          (key: string) =>
            prop(`${key}.factorName`)(factorValuesMap) + formatMessage("factor")
        ),
        join("和")
      )(top2FactorCongestion),
    [factorValuesMap, top2FactorCongestion, formatMessage]
  );

  const { factorNames, factorVals } = useMemo(() => {
    const factorNames = map((item: FactorValuesParam) => item?.factorName)(
      factorValues
    );
    const factorVals = map((item: FactorValuesParam) => item?.factorValue)(
      factorValues
    );
    return { factorNames, factorVals };
  }, [factorValues]);

  const options = useMemo<EChartsOption>(
    () => ({
      grid: { top: 30, left: 28 },
      legend: { show: false },
      yAxis: {
        axisTick: { show: false },
        axisLine: { show: false },
        min: 0,
        max: 100,
        axisLabel: {
          formatter: identity,
        },
      },
      series: {
        type: "bar",
        barWidth: 36,
        label: {
          show: false,
          formatter: (params) => {
            const value = prop("value.0")(params);
            return toFixedNumber(2)(value);
          },
        },
        data: zip(factorNames)(factorVals) as [string, number][],
      },
    }),
    [factorNames, factorVals]
  );
  return (
    <div className={style.RecentStatusOfMainFactorsyWrap}>
      <SecondLevelName name={formatMessage("recentStatusOfMainFactors")}>
        {mainFactorRisk?.riskLevel && (
          <span className={style.RiskStatus1}>
            {formatMessage("risk")}
            {getProsperity(mainFactorRisk?.riskLevel)}
          </span>
        )}
      </SecondLevelName>
      <div className={style.Text}>
        {formatMessage("mainFactorsSummary", {
          top2Factors: top2FactorNames,
          firstFactor: flow(keys, first, (key) =>
            prop(`${key}.factorName`)(factorValuesMap)
          )(top2FactorCongestion),
          prosperity: getProsperity(
            flow(values, first)(top2FactorCongestion) as string
          ),
          secondFactor: flow(keys, nth(1), (key) =>
            prop(`${key}.factorName`)(factorValuesMap)
          )(top2FactorCongestion),
          crowdDegree: getProsperity(
            flow(values, nth(1))(top2FactorCongestion) as string
          ),
          fundCrowdDegree: getProsperity(comprehensiveFactorCongestion),
        })}
      </div>
      <div className={style.ChartWrap}>
        <BaseEcharts
          options={options}
          height={272}
          opts={{ devicePixelRatio: 4 }}
        />
      </div>
    </div>
  );
});
