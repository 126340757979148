import React from "react";
import { fastProp } from "@/util/opt";
import { default as check } from "@/assets/login/check.svg";
import { default as checked } from "@/assets/login/checked.svg";
import { default as closeEyeIcon } from "@/assets/login/closeEyeIcon.svg";
import { default as errorIcon } from "@/assets/login/errorIcon.svg";
import { default as openEyeIcon } from "@/assets/login/openEyeIcon.svg";
import { default as passwordIcon } from "@/assets/login/passwordIcon.svg";
import { default as userIcon } from "@/assets/login/userIcon.svg";
import { default as yanzmIcon } from "@/assets/login/yanzmIcon.svg";

const icons: Record<string, string> = {
  check,
  checked,
  closeEyeIcon,
  errorIcon,
  openEyeIcon,
  passwordIcon,
  userIcon,
  yanzmIcon,
};

type CustomIconProps = {
  type: string;
  className?: string;
};
export default React.memo<CustomIconProps>((props) => {
  return <img className={props.className} src={fastProp(props.type)(icons)} />;
});
