import { getPreferredDate, getPreferredFundList } from "@/api/preferredFund";
import {
  PreferredFilterList,
  PreferredFundParam,
  PreferredResultData,
} from "@/model/preferredFund";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchPreferredDate = avoidMultiRequestActionThunk<void>(
  "preferredFund/preferredDate",
  createAsyncThunk("preferredFund/preferredDate", async () => {
    const response = await getPreferredDate();
    return response;
  })
);

export const fetchPreferredFundList = createAsyncThunk(
  "preferredFund/preferredFundList",
  async (body: PreferredFundParam) => {
    const response = await getPreferredFundList(body);
    return response;
  }
);

// 每一期完整的优选基金
export const fetchPreferredFunds = avoidMultiRequestActionThunk<string>(
  (date: string) => `preferredFund.preferredFunds.${date}`,
  createAsyncThunk("preferredFund/preferredFunds", async (date) => {
    const response = await getPreferredFundList({ date });
    return response;
  })
);

type preferredFundListParam = {
  factorAvgValue: Record<string, any>;
  preferredFunds: Record<string, any>[];
};

export const creatPreferredFilterListState: PreferredFilterList = {
  date: "",
  factorIds: "",
  firstType: "",
  secondType: "",
  limitNumber: {
    from: undefined,
    to: undefined,
  },
  exceedRatio: 0,
};

export const createPreferredResultData: PreferredResultData = {
  sortCloumn: "",
  resultFirstType: "",
  resultSecondType: "",
};

interface InitialState {
  preferredFundList: preferredFundListParam;
  preferredDate: string[];
  preferredFilter: PreferredFilterList;
  preferredResultData: PreferredResultData;
  preferredFunds: Record<string, any[]>;
}

const initialState: InitialState = {
  preferredFundList: {
    factorAvgValue: {},
    preferredFunds: [],
  },
  preferredDate: [],
  preferredFilter: creatPreferredFilterListState,
  preferredResultData: createPreferredResultData,
  preferredFunds: {},
};

const preferredFundSlice = createSlice({
  name: "preferredFund",
  initialState,
  reducers: {
    setPreferredFilter: (state: InitialState, action) => {
      state.preferredFilter = action.payload;
    },
    setPreferredResult: (state: InitialState, action) => {
      state.preferredResultData = action.payload;
    },
  },
  extraReducers: {
    [fetchPreferredDate.fulfilled.type]: (state, action) => {
      state.preferredDate = action.payload;
    },
    [fetchPreferredFundList.fulfilled.type]: (state, action) => {
      state.preferredFundList = action.payload;
    },
    [fetchPreferredFunds.fulfilled.type]: (state, action) => {
      state.preferredFunds[action.meta.arg] = action.payload?.preferredFunds;
    },
  },
});

export const { setPreferredFilter, setPreferredResult } =
  preferredFundSlice.actions;

export default preferredFundSlice.reducer;
