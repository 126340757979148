import { FROM_CREATION } from "@/constant/statisticRange";
import { benchmarksIdMapSelector } from "@/selectors/benchmarks";
import { fundIdMapSelector } from "@/selectors/fund";
import { RootState } from "@/store";
import { BENCHMARK_COLOR, colors } from "@/util/colors";
import getMessage from "@/util/getMessage";
import { fastNth, fastProp } from "@/util/opt";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { CSI300_ID } from "@/views/compareManage/constant";
import { createSelector } from "@reduxjs/toolkit";
import { first, prop } from "lodash/fp";
import { fundBasicInfoSelector } from "../../../mapper";

export const benchmarkIdSelector = createSelector(
  fundBasicInfoSelector,
  (fundBasicInfo) =>
    fastProp("assetRiskBenchmarkId")(fundBasicInfo) || CSI300_ID
);

export const statisticRangeSelector = createSelector(
  fundBasicInfoSelector,
  (fundBasicInfo) =>
    fastProp("assetRiskStatisticRange")(fundBasicInfo) || FROM_CREATION
);

export const dataSourceSelector = createSelector(
  fundBasicInfoSelector,
  (state: RootState) => state.dailyReturns,
  benchmarkIdSelector,
  (state: RootState, fundId: string) => fundId,
  fundIdMapSelector,
  // (state: RootState) => state.compareManage.compareBenchmark,
  benchmarksIdMapSelector,
  (state: RootState) => state.factors.benchmarkYieldFactors,
  (
    fundBasicInfo,
    dailyReturns,
    benchmarkId,
    fundId,
    fundsMap,
    // compareBenchmark,
    benchmarksIdMap,
    benchmarkYieldFactors
  ) => {
    const fundDetailReturn = fastProp("fundDetailReturn")(fundBasicInfo);
    const fundDailyReturn = fastProp("fundDailyReturn")(fundDetailReturn);
    const sameTypeDailyReturn = fastProp("sameTypeDailyReturn")(
      fundDetailReturn
    );
    const tradingDates = fastProp("tradingDates")(fundDetailReturn);
    const { dates: benchmarkDates, dailyReturns: benchmarkDailyReturns } =
      fastProp(benchmarkId)(dailyReturns) || { dates: [], dailyReturns: [] };
    const firstInvestType = prop("basic.firstInvestType")(fundBasicInfo);
    return {
      factorRankValue: fastProp("factorRankValue")(fundBasicInfo),
      sameTypeFactors: fastProp("sameTypeSectionValue")(
        fundDetailReturn
      ) as Record<string, number>,
      benchmarkFactors: fastProp(benchmarkId)(benchmarkYieldFactors) as Record<
        string,
        number
      >,
      dates: tradingDates,
      benchmarkDates,
      benchmarkInfo: {
        id: benchmarkId,
        color: BENCHMARK_COLOR,
        name: prop(`${benchmarkId}.name`)(benchmarksIdMap),
        dates: benchmarkDates,
        dailyReturns: benchmarkDailyReturns,
        dailyReturnsMap: normalizeDailyReturnsMap(
          benchmarkDates,
          benchmarkDailyReturns
        ),
      },
      fundInfo: {
        name: prop(`${fundId}.name`)(fundsMap),
        id: fundId,
        color: first(colors) || "",
        dates: tradingDates,
        dailyReturns: fundDailyReturn,
        dailyReturnsMap: normalizeDailyReturnsMap(
          tradingDates,
          fundDailyReturn
        ),
      },
      sameTypeInfo: {
        name: `${getMessage("sameAverage")}(${firstInvestType})`,
        id: "sameType",
        color: fastNth(2)(colors) || "",
        dates: tradingDates,
        dailyReturns: sameTypeDailyReturn,
        dailyReturnsMap: normalizeDailyReturnsMap(
          tradingDates,
          sameTypeDailyReturn
        ),
      },
    };
  }
);

export default createSelector(
  benchmarkIdSelector,
  statisticRangeSelector,
  dataSourceSelector,
  (benchmarkId, statisticRange, dataSource) => ({
    benchmarkId,
    statisticRange,
    ...dataSource,
  })
);
