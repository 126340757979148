import {
  factorAttributionsBody,
  factorsItem,
  FundNetValueAttributionBody,
  treeDataType,
} from "@/model/fundDetail";
import { FormatMessageFunc } from "@/util/formatMessage";
import { fastProp, forEachIndexed, mapIndexed, normalize } from "@/util/opt";
import { getPreviousTradingDate } from "@/util/processedDates";
import { groupDataByIndexArray } from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/netValueAttribution/constant";
import {
  concat,
  first,
  flatten,
  flow,
  forEach,
  fromPairs,
  isEmpty,
  keys,
  last,
  map,
  maxBy,
  pick,
  prop,
  size,
  sum,
  toArray,
  values,
} from "lodash/fp";
import { factorsFormatter } from "@/constant/factorFormatter";
import { minus } from "@/util/math";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { InfoCircleOutlined } from "@ant-design/icons";
import style from "./index.module.less";
import { formatPercentage } from "@/util/numberFormatter";

export const getFundChartData = (
  result: FundNetValueAttributionBody,
  tradingDates: string[],
  processedTradingDates: Record<string, any>,
  factorsMap: Record<string, any>
) => {
  const {
    dates,
    portfolioCumulativeReturns,
    factorAttributions,
    monthTradingDateNum,
  } = result || {};
  if (isEmpty(dates) || isEmpty(factorAttributions)) return {};

  const factorIds = map((item: factorAttributionsBody) =>
    fastProp("factorId")(item)
  )(factorAttributions);

  const attributionsData = map((item: factorAttributionsBody) =>
    fastProp("attributions")(item)
  )(factorAttributions);

  // 将日收益数据按照每个月交易日个数进行划分
  const groupedData = groupDataByIndexArray(
    dates,
    portfolioCumulativeReturns,
    ...attributionsData
  )(monthTradingDateNum);

  // 展平后得到日收益
  const [nDates, nAssetValues, ...otherData] = map(flatten)(groupedData);

  const firstDate = getPreviousTradingDate(
    tradingDates,
    processedTradingDates,
    first(nDates) as string
  );
  const factorData = flow(
    mapIndexed((factorId: string, index: number) => [
      // factorId,
      prop(`${factorId}.factorName`)(factorsMap),
      isEmpty(otherData[index])
        ? otherData[index]
        : concat(0)(otherData[index] as number[]),
    ]),
    fromPairs
  )(factorIds);

  return {
    dates: concat(firstDate)(nDates as string[]),
    dataSource: {
      portfolioAttribution: isEmpty(nAssetValues)
        ? nAssetValues
        : concat(0)(nAssetValues as number[]),
      ...factorData,
    },
  };
};

export const getFundFactorTableColumns = (
  formatMessage: FormatMessageFunc,
  factors: factorsItem[]
) => {
  const basicColumns = [
    {
      title: formatMessage("maxDrawdown"),
      dataIndex: "maxDrawdown",
      align: "center",
      render: factorsFormatter?.maxDrawdown,
    },
    {
      title: formatMessage("sharpeRatio"),
      dataIndex: "sharpeRatio",
      align: "center",
      render: factorsFormatter?.sharpeRatio,
    },
    {
      title: formatMessage("portfolioAttribution"),
      dataIndex: "assetCumulativeReturn",
      align: "center",
      render: factorsFormatter?.yield,
    },
  ];
  const factorColumns = map((item: factorsItem) => ({
    title: item?.factorName,
    dataIndex: item?.factorId,
    align: "center",
    render: factorsFormatter?.yield,
  }))(factors);

  return [...basicColumns, ...factorColumns];
};

export const getFundFormatStatisticsTableData = (
  result: FundNetValueAttributionBody,
  factors: factorsItem[]
) => {
  const { months: dates, monthlyAttributions } = result || {};
  if (isEmpty(dates) || isEmpty(monthlyAttributions)) return [];
  return mapIndexed((date: string, index: number) => {
    const { dataMissing, portfolioCumulativeReturn, factorsCumulativeReturn } =
      monthlyAttributions[index];
    const factorsCumulativeReturnMap = normalize("factorId")(
      factorsCumulativeReturn
    );
    const factorsCumulativeReturnData = flow(
      map((item: factorsItem) => [
        item?.factorId,
        prop(`${item?.factorId}.cumulativeAttribution`)(
          factorsCumulativeReturnMap
        ),
      ]),
      fromPairs
    )(factors);
    const factorAttribution = flow(values, sum)(factorsCumulativeReturnData);
    const otherAttribution = minus(factorAttribution)(
      portfolioCumulativeReturn
    );
    return {
      id: date,
      date,
      portfolioAttribution: portfolioCumulativeReturn,
      ...factorsCumulativeReturnData,
      dataMissing,
      factorAttribution: factorAttribution,
      otherAttribution: otherAttribution,
    };
  })(dates);
};

export const getFundStatisticsTableColumnsAndDataSource = (
  data: any[],
  formatMessage: FormatMessageFunc,
  factors: factorsItem[]
) => {
  const columns: ColumnsType<any> = [
    {
      dataIndex: "name",
      title: "名称",
      width: 150,
      align: "left",
      fixed: "left",
    },
  ];
  const factorAttributions: Record<string, any> = {};
  forEach((item: factorsItem) => {
    factorAttributions[item?.factorId] = {
      id: item?.factorId,
      name: item?.factorName,
    };
  })(factors);
  const indicatorsData = {
    portfolioAttribution: {
      id: "portfolioAttribution",
      name: formatMessage("portfolioAttribution"),
    },
    ...factorAttributions,
    otherAttribution: {
      id: "otherAttribution",
      name: formatMessage("otherAttributions"),
    },
    factorAttribution: {
      id: "factorAttribution",
      name: formatMessage("factorAttribution"),
    },
  };
  forEach((statistic: any) => {
    const id = fastProp("id")(statistic);
    const dataMissing = fastProp("dataMissing")(statistic);
    columns.push({
      dataIndex: id,
      width: 120,
      title: dataMissing ? (
        <div>
          <span>{id}</span>
          <Tooltip title={formatMessage("netValueLess15DateWarning")}>
            <InfoCircleOutlined className={style.WarningIcon} />
          </Tooltip>
        </div>
      ) : (
        id
      ),
    });
    forEachIndexed((value: Record<string, any>, key: string) => {
      value[id] = factorsFormatter.yield(fastProp(key)(statistic));
    })(indicatorsData);
  })(data);
  const factorIds = map((item: factorsItem) => item?.factorId)(factors);
  return {
    columns,
    dataSource: [
      {
        ...fastProp("factorAttribution")(indicatorsData),
        children: flow(pick(factorIds), toArray)(indicatorsData),
      },
      fastProp("otherAttribution")(indicatorsData),
      fastProp("portfolioAttribution")(indicatorsData),
    ],
  };
};

export const getFundNetValueTreeData = (
  netValueAttribution: FundNetValueAttributionBody,
  formatMessage: FormatMessageFunc,
  factorsMap: Record<string, any>
): treeDataType => {
  const { portfolioCumulativeReturns, factorAttributions } =
    netValueAttribution || {};

  const factorsCumulativeReturnData = flow(
    map((item: factorAttributionsBody) => [
      fastProp("factorId")(item),
      flow(fastProp("attributions"), last)(item),
    ]),
    fromPairs
  )(factorAttributions);
  const factorValue = flow(values, sum)(factorsCumulativeReturnData);
  const children = map((factorId: string) => ({
    name: prop(`${factorId}.factorName`)(factorsMap),
    value: fastProp(factorId)(factorsCumulativeReturnData),
  }))(keys(factorsMap));
  const otherAttributions = minus(factorValue)(
    (last(portfolioCumulativeReturns) as number) || 0
  );
  return {
    name: formatMessage("portfolioAttribution"),
    value: last(portfolioCumulativeReturns) as number,
    children: [
      {
        name: formatMessage("factorAttribution"),
        value: factorValue,
        children: children,
      },
      {
        name: formatMessage("otherAttributions"),
        value: otherAttributions,
      },
    ],
  };
};

export const getFundDateRangeText = (
  netValueAttribution: FundNetValueAttributionBody,
  formatMessage: FormatMessageFunc
) => {
  if (!netValueAttribution?.dates || !netValueAttribution?.monthTradingDateNum)
    return null;
  const dates = netValueAttribution?.dates;
  const start = first(dates);
  const end = last(dates);
  if (!start || !end) return null;
  const monthTradingDateNum = netValueAttribution?.monthTradingDateNum;
  const dateMonthSize = flow(size)(monthTradingDateNum);
  if (!dateMonthSize) return `${start || ""} - ${end || ""}`;
  return `${start || ""} - ${end || ""} ${formatMessage("sumMonthSize", {
    size: dateMonthSize,
  })}`;
};

export const getFundStatisticsText = (
  netValueAttribution: FundNetValueAttributionBody,
  formatMessage: FormatMessageFunc,
  cumnlateFactor: Record<string, number>
) => {
  if (isEmpty(netValueAttribution?.portfolioCumulativeReturns)) return null;
  const dates = netValueAttribution?.dates;
  const startDate = first(dates);
  const endDate = last(dates);
  // const maxValue = maxBy((v: number) => Math.abs(v))(values(cumnlateFactor));

  const cumnlateFactorArray = flow(
    map((id: string) => ({ id: id, value: fastProp(id)(cumnlateFactor) }))
  )(keys(cumnlateFactor));
  const maxValue = maxBy((v: Record<string, any>) => Math.abs(v?.value))(
    cumnlateFactorArray
  );
  const maxPercentValue = formatPercentage(maxValue?.value);
  if (maxValue?.name === "marketRiskValue") {
    return formatMessage("marketRiskValueTip", {
      from: startDate,
      to: endDate,
      value: maxPercentValue,
    });
  }
  if (maxValue?.name === "scaleValue") {
    return formatMessage("scaleValueTip", {
      from: startDate,
      to: endDate,
      value: maxPercentValue,
    });
  }
  if (maxValue?.name === "worthValue") {
    return formatMessage("worthValueTip", {
      from: startDate,
      to: endDate,
      value: maxPercentValue,
    });
  }
};

export const defaultModelId = "e9fe1aa6-8057-11e9-a699-c4b301c5b6bb";
export const campisiModelId = "22c09d27-6408-22a4-eb58-1c719f339162";
