import type { RcFile } from "antd/es/upload";

export async function getError({
  file,
  limitSize,
  width,
  height,
}: {
  file: RcFile;
  limitSize: number;
  width: number;
  height: number;
}): Promise<string> {
  const type = file?.type;
  if (type !== "image/png" && type !== "image/jpeg") {
    return "支持png,jpg格式图片";
  }
  if (file?.size > limitSize) {
    return `最大支持${getSizeLabel(limitSize)}`;
  }
  const { width: _width, height: _height } = await getImageWH(file);
  if (width != _width || height != _height) {
    return `图片尺寸大小为${width}px * ${height}px`;
  }
  return "";
}

export async function getImageWH(file: RcFile) {
  return new Promise<{
    width: number;
    height: number;
  }>(function (resolve, reject) {
    const filereader = new FileReader();
    filereader.onload = (e: ProgressEvent<FileReader>) => {
      const src = e.target?.result as string;
      const image = new Image();
      image.onload = function () {
        resolve({
          width: image.width,
          height: image.height,
        });
      };
      image.onerror = reject;
      image.src = src;
    };
    filereader.readAsDataURL(file);
  });
}

export function getSizeLabel(size: number): string {
  if ((size = size / 1024) < 1024) {
    return `${size}KB`;
  }
  return `${size / 1024}M`;
}

export function getBase64(file: RcFile): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}
