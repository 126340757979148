import { fastProp } from "@/util/opt";
import { TaskData } from "@/util/socket";

export const SUCCESS = "SUCCESS";
export const FAILED = "FAILED";
export const ERROR = "ERROR";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const RESPONSE = "response";
export const SYNC_TASK = "SYNC_TASK";
export const TASK_PROCESS = "PROGRESS_TASK";

export const REQUEST_RESOLVED = 1;
export const REQUEST_PENDING = -2;
export const REQUEST_FAILED = -1;

export const STATUS_SUCCESS = 200;
export const STATUS_PARAM_ILLEGAL = 400;
export const STATUS_TOKEN_EXPIRED = 401;
export const STATUS_SERVER_ERROR = 500;

// 用户鉴权
export const SOCKET_AUTHORIZATION = "AUTHORIZATION";

export const isInProgress = (task: TaskData | undefined): boolean => {
  if (!task) return false;
  const progress = fastProp("progress")(task);
  return (
    progress !== undefined &&
    progress !== REQUEST_RESOLVED &&
    progress !== REQUEST_FAILED
  );
};
