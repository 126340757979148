import { flow, includes, reduce } from "lodash/fp";
import { fastProp } from "./opt";
import getMessage from "./getMessage";

export default (errorField: Record<string, string>, status: number): string => {
  let errorMsg = null;
  if (status === 500) {
    errorMsg = fastProp("default")(errorField) || getMessage("systemError");
  } else if (!includes(status)([401, 404, 502])) {
    errorMsg = flow(reduce((result, error) => result + error, ""))(errorField);
  } else {
    errorMsg = getMessage("systemError");
  }
  return errorMsg;
};
