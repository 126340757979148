export const colors = [
  "#0052BF",
  "#EB5B5B",
  "#FFB15A",
  "#00AE39",
  "#CF5E3F",
  "#0099DB",
  "#9051BE",
  "#DA4E8D",
  "#00A9BF",
  "#4E6994",

  // ====
  "#004BAE",
  "#0080BF",
  "#00B9BF",
  "#E26D51",
  "#B11E05",
  "#EC0B0B",
  "#E28D24",
  "#69C447",
  "#1E8F64",
  "#0011B8",
  "#002166",
  "#5E749A",
  "#A73C66",
  "#5D3C80",
  "#3E77C2",
  "#3E9FCF",
  "#3ECBCF",
  "#EA917C",
  "#C45542",
  "#F26060",
  "#EAA95A",
  "#8ED374",
  "#55AB8A",
  "#3D49BB",
  "#3E578C",
  "#8696B3",
  "#A05C76",
  "#7B5D9A",
];
