import { useFormatMessage } from "@/util/formatMessage";
import { Cascader } from "antd";
import { compact, identity, toArray } from "lodash/fp";
import React, { useCallback, useMemo } from "react";
import { PRIVATE, PUBLIC } from "./constant";

export type ShareRange = {
  shareType: string;
  groupId: string;
};

type ShareRangeSelectProps = {
  value?: ShareRange | string;
  onChange?: (v: ShareRange) => void;
};
const ShareRangeSelect = ({
  value,
  onChange = identity,
}: ShareRangeSelectProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const defaultValue = useMemo(() => compact(toArray(value)), [value]);
  const options = useMemo(
    () => [
      {
        value: PUBLIC,
        label: formatMessage("PUBLIC"),
      },
      {
        value: PRIVATE,
        label: formatMessage("PRIVATE"),
      },
    ],
    [formatMessage]
  );
  const changeValue = useCallback(
    (v: any) => {
      onChange({
        shareType: v?.[0],
        groupId: v?.[1],
      });
    },
    [onChange]
  );
  return (
    <Cascader
      value={defaultValue}
      placeholder={formatMessage("PleaseSelect")}
      allowClear={false}
      onChange={changeValue}
      options={options}
    />
  );
};

export default React.memo(ShareRangeSelect);
