import { useContext, useState, useEffect } from "react";
import { LineSeriesOption } from "echarts";
import { prop, isEmpty, flow, map, keys } from "lodash/fp";
import { useMemoizedFn, useCreation } from "ahooks";

import { useAppSelector } from "@/hooks/redux";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import { netValueAttribution } from "@/model/portfolioAnalysis";
import { formatNilToZero, formatPercentage } from "@/util/numberFormatter";
import { checkNeedTime } from "@/providers/portfolioAnalysisProvider/provider";
import { fetchNetValueAttribution } from "@/store/portfolioAnalysis";
import { getTreeChartOptions } from "@/util/chart";
import { useFormatMessage } from "@/util/formatMessage";
import { mapIndexed, fastNth, fastProp } from "@/util/opt";

import { getBrinsonAndNetValueLineChartOptions } from "../constant";
import {
  getChartData,
  getFormatStatisticsTableData,
  getStatisticsTableColumnsAndDataSource,
  getNetValueTreeData,
} from "./constant";

const needTime = 15;

export const useManageHeaderState = (id: string) => {
  const { dispatch, runningTime } = useContext(PortfolioAnalysisContext);
  const [range, setRange] = useState("FROM_CREATION");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    setButtonDisabled(false);
  }, [range]);
  const onStartCalculate = useMemoizedFn(() => {
    if (id && range) {
      dispatch(needTime, fetchNetValueAttribution({ id, section: range }));
      setButtonDisabled(true);
    }
  });
  return {
    range,
    runningTime,
    onChangeRange: setRange,
    buttonDisabled,
    onStartCalculate,
  };
};

export function useGetNetValueAttributionData(
  id: string,
  range: string,
  runningTime: number,
  portfolioAnalysisSelector: any
) {
  const [errorBoundary, setErrorBoundary] = useState<string>();
  useEffect(() => {
    if (checkNeedTime(needTime, runningTime)) {
      setErrorBoundary("less15TradingDates");
    }
  }, [runningTime]);
  const portfolioInfo = useAppSelector((state) =>
    portfolioAnalysisSelector(state, id)
  );
  const netValueAttribution = useCreation(
    () =>
      (prop(`netValueAttribution.${range}`)(portfolioInfo) ||
        {}) as netValueAttribution,
    [portfolioInfo, range]
  );
  useEffect(() => {
    if (
      !isEmpty(netValueAttribution) &&
      flow(
        prop("monthPerformance.monthTradingDateNum"),
        isEmpty
      )(netValueAttribution)
    ) {
      setErrorBoundary("allLess15TradingDates");
    }
  }, [netValueAttribution]);
  const { startDate, endDate } = useCreation(
    () => ({
      startDate: portfolioInfo?.portfolioSummary?.startDate,
      endDate: portfolioInfo?.portfolioSummary?.endDate,
    }),
    [portfolioInfo]
  );
  return {
    errorBoundary,
    startDate,
    endDate,
    netValueAttribution,
  };
}

export function useGetLineChartDataAndOptions(
  netValueAttribution: netValueAttribution
) {
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  const { dates, dataSource } = getChartData(
    netValueAttribution,
    tradingDateList,
    processedTradingDates
  );
  const formatMessage = useFormatMessage();
  const options = useCreation(
    () => getBrinsonAndNetValueLineChartOptions(dates || []),
    [dates]
  );
  const series = useCreation(
    () =>
      map<string, LineSeriesOption>((v) => ({
        type: "line",
        name: formatMessage(v),
        showSymbol: false,
        lineStyle: {
          width: 1.5,
        },
        smooth: false,
        data: mapIndexed((_: string, index: number) =>
          formatNilToZero(fastNth(index)(fastProp(v)(dataSource)))
        )(dates),
      }))(keys(dataSource)),
    [formatMessage, dataSource, dates]
  );
  return { options, series };
}

export function useGetStatisticsTableColumnsAndData(
  netValueAttribution: netValueAttribution
) {
  const formatData = useCreation(
    () => getFormatStatisticsTableData(netValueAttribution),
    [netValueAttribution]
  );
  const formatMessage = useFormatMessage();
  return useCreation(
    () => getStatisticsTableColumnsAndDataSource(formatData, formatMessage),
    [formatData, formatMessage]
  );
}

export function useGetTreeChartOptions(
  netValueAttribution: netValueAttribution
) {
  const formatMessage = useFormatMessage();
  const treeData = useCreation(
    () => getNetValueTreeData(netValueAttribution, formatMessage),
    [netValueAttribution, formatMessage]
  );
  return useCreation(
    () =>
      getTreeChartOptions(treeData, {
        tooltip: {
          formatter: (params: any) => {
            return [formatPercentage(params.value)] + `<br/>` + [params.name];
          },
        },
      }),
    [formatMessage, treeData]
  );
}
