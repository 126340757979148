import {
  filter,
  minBy,
  map,
  last,
  concat,
  flow,
  first,
  max,
  isEmpty,
  find,
  size,
} from "lodash/fp";
import { getTime } from "@/util/dateFormat";
import { useMemo } from "react";
import {
  COMMON_TIME,
  FROM_CREATION,
  FROM_THIS_YEAR,
  getThisYear,
} from "../statisticRange";
import {
  addStartDate,
  getIntersectionDate,
  getRangeDate,
  getStatisticsRangeStartDate,
} from "./helper";

export const getCalculateScaleRangeDate = (
  dates: string[],
  range: string,
  thisYearDate?: string
) => {
  if (!range || isEmpty(dates)) return dates;
  if (range === FROM_CREATION) return dates;
  if (range === FROM_THIS_YEAR) {
    const startThisYear = getThisYear(thisYearDate);
    return getRangeDate(startThisYear)(dates);
  }
  const statisticStartDate = getStatisticsRangeStartDate(
    range,
    last(dates) as string
  );
  if (statisticStartDate) {
    return getRangeDate(statisticStartDate)(dates);
  }
  return dates;
};

export const useCalculateScaleRangeDate = (
  fundDates: string[][],
  benchmarkDates: string[] | undefined,
  range: string
): string[][] => {
  const dataSourceTime = flow(map(last), max)(fundDates) as string;

  return useMemo(() => {
    const concatDates = benchmarkDates
      ? concat(fundDates)([benchmarkDates])
      : fundDates;
    if (range === COMMON_TIME) {
      if (find((date: string[]) => !size(date))(concatDates)) return [];
      const interSectionDate = getIntersectionDate(
        map<string[], string>((date) => first(date) as string)(fundDates),
        map<string[], string>((date) => last(date) as string)(fundDates)
      );
      if (!interSectionDate) return [];
      const [maxStartDate, minEndDate] = interSectionDate;
      return map<string[], string[]>(
        flow(
          filter<string>((item) => item >= maxStartDate && item <= minEndDate),
          addStartDate(maxStartDate)
        )
      )(concatDates);
    }
    if (range === FROM_CREATION) {
      const minStartDate =
        flow(
          map<string[], string | undefined>(first),
          minBy(getTime)
        )(fundDates) || "";

      return map<string[], string[]>((date) =>
        filter<string>(
          (item) => item >= minStartDate && item <= dataSourceTime
        )(date)
      )(concatDates);
    }
    return map<string[], string[]>((dates) =>
      getCalculateScaleRangeDate(dates, range)
    )(concatDates);
  }, [benchmarkDates, dataSourceTime, fundDates, range]);
};
