import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSearch } from "@/api/home";

interface tabsInterface {
  keyWord: string;
  section: string;
  // [key: string]: void;
}

interface newsListInterface {
  title: string;
  source: string;
  createDate: string;
}
interface initFace {
  newsList: newsListInterface[];
}

const initialState: initFace = {
  newsList: [],
};

export const newsList = createAsyncThunk(
  "newsSearch/news",
  async (data: tabsInterface) => {
    const response = await getSearch(data);
    return response;
  }
);

const newsSearchSlice = createSlice({
  name: "newsSearch",
  initialState,
  reducers: {},
  extraReducers: {
    [newsList.fulfilled.type]: (state, action) => {
      state.newsList = action.payload;
    },
  },
});
export default newsSearchSlice.reducer;
