import { useAppSelector } from "@/hooks/redux";
import { Rate, Space } from "antd";
import style from "../../../index.module.less";
import compareIconActive from "@/assets/compareIconActive.png";
import compareIcon from "@/assets/compareIcon.png";
// import collectIcon from "@/assets/collectIconActive.png";
import mapper from "../../mapper";
import { useMemo } from "react";
import { compact, flow, map, prop, values } from "lodash/fp";
import { industryThemeListMapSelector } from "@/selectors/fundTheme";
import { formatNil } from "@/util/numberFormatter";
import { isEmpty, isNil } from "lodash";
import { useFormatMessage } from "@/util/formatMessage";
import AddOptional from "@/components/addOptional";
import { useMemoizedFn } from "ahooks";

const FundBasicInfoTitle = ({
  fundId,
  onOpenAnchorModal,
  addSelectIds,
  validateIsChecked,
}: {
  fundId: string;
  onOpenAnchorModal?: () => any;
  addSelectIds: (id: string) => void;
  validateIsChecked: (id: string) => boolean;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  const industryThemeListMap = useAppSelector(industryThemeListMapSelector);
  const { fundBasicTitle } = useAppSelector((state) => mapper(state, fundId));
  const { name, code, fundTheme, fundStyle, fundRating } = fundBasicTitle || {};
  const compareSelectedFund = useMemoizedFn(() => {
    if (!validateIsChecked(fundId)) {
      addSelectIds(fundId);
      onOpenAnchorModal && onOpenAnchorModal();
    }
  });
  const hiddenFundBasicTitle = useMemo(
    () => flow(values, compact, isEmpty)(fundBasicTitle),
    [fundBasicTitle]
  );
  return (
    <Space className={style.FundBasicInfoTitle}>
      <Space align="baseline">
        {hiddenFundBasicTitle ? (
          <div />
        ) : (
          <>
            <Space>
              <span>{formatNil(name)}</span>
              <span>{`(${formatNil(code)})`}</span>
            </Space>
            {map((v: string) => (
              <span key={v} className={style.FundBasicInfoTitleTag}>
                {formatNil(prop(`${v}.name`)(industryThemeListMap))}
              </span>
            ))(fundTheme)}

            {!isEmpty(fundStyle) && (
              <span className={style.FundBasicInfoTitleTag}>
                {formatNil(fundStyle)}
              </span>
            )}

            {!isNil(fundRating) && fundRating !== 0 ? (
              <Rate disabled defaultValue={formatNil(fundRating)} />
            ) : (
              <span className={style.NoFundRating}>
                {formatMessage("noFundRating")}
              </span>
            )}
          </>
        )}
      </Space>
      <div className={style.BtnsWrap}>
        <AddOptional id={fundId} shape="icon" size="middle" />
        <img
          className={style.Icons}
          src={validateIsChecked(fundId) ? compareIcon : compareIconActive}
          onClick={compareSelectedFund}
        />
        {/* <img className={style.Icons} src={collectIcon} /> */}
      </div>
    </Space>
  );
};

export default FundBasicInfoTitle;
