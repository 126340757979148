import { defaultSectorId } from "@/constant/sectors";
import {
  FundDetailStockPositionTrend,
  StockWeightListItem,
} from "@/model/fundDetail";
import { fastProp, normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import {
  map,
  flow,
  orderBy,
  first,
  prop,
  flatten,
  groupBy,
  update,
  mapValues,
} from "lodash/fp";
import { fundBasicSelector } from "../../../mapper";

export const FundDetailSectorTrendSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => {
    const { fundSectorWeightTrend, sameTypeFundSectionWeightTrend } =
      fastProp("fundDetailSectorTrend")(fundBasic) || {};

    const sameTypeFundSectionWeightMap = normalize("reportDate")(
      sameTypeFundSectionWeightTrend
    );
    return {
      fundSectorWeightTrend,
      sameTypeFundSectionWeightMap,
    };
  }
);
export const sectorIdSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => fastProp(`selectedSectorId`)(fundBasic) || defaultSectorId
);
export const industryIdSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => fastProp(`industryId`)(fundBasic) || defaultSectorId
);
export const FundDetailIndustryTrendSelector = createSelector(
  fundBasicSelector,
  sectorIdSelector,
  (fundBasic, sectorId) => {
    const fundDetailIndustryTrend = prop(`fundDetailIndustryTrend.${sectorId}`)(
      fundBasic
    );
    return {
      fundDetailIndustryTrend,
    };
  }
);
export const FundDetailETFIndustryTrendSelector = createSelector(
  fundBasicSelector,
  sectorIdSelector,
  (fundBasic, sectorId) => {
    const fundDetailETFIndustryTrend = prop(`fundDetailETFIndustryTrend.${sectorId}`)(
      fundBasic
    );
    return {
      fundDetailETFIndustryTrend,
    };
  }
);
export const IndustryTrendDateSelector = createSelector(
  fundBasicSelector,
  (fundBasic) => fastProp(`fundDetailIndustryTrendDate`)(fundBasic) || []
);
export const FundDetailStockPositionTrendSelector = createSelector(
  fundBasicSelector,
  industryIdSelector,
  (fundBasic, industryId) => {
    const fundDetailStockPositionTrend = prop(
      `fundDetailStockPositionTrend.${industryId}`
    )(fundBasic);
    const stocksPositionMap = flow(
      map((v: FundDetailStockPositionTrend) =>
        update("stockWeightList", (stockWeightList: StockWeightListItem[]) =>
          map((stockWeight: StockWeightListItem) => ({
            ...stockWeight,
            reportDate: v.reportDate,
          }))(stockWeightList)
        )(v)
      ),
      map(fastProp("stockWeightList")),
      flatten,
      groupBy("stockCode"),
      mapValues(normalize("reportDate"))
    )(fundDetailStockPositionTrend);
    const stockPositionDates = flow(
      orderBy("reportDate", "desc"),
      map(fastProp("reportDate"))
    )(fundDetailStockPositionTrend);
    const fundDetailStockPositionTrendMap = normalize("reportDate")(
      fundDetailStockPositionTrend
    );
    return {
      stockPositionDates,
      fundDetailStockPositionTrendMap,
      stockPositionRange: first(stockPositionDates),
      stocksPositionMap,
    };
  }
);
export default createSelector(
  FundDetailSectorTrendSelector,
  sectorIdSelector,
  industryIdSelector,
  FundDetailIndustryTrendSelector,
  FundDetailETFIndustryTrendSelector,
  FundDetailStockPositionTrendSelector,
  IndustryTrendDateSelector,
  (
    fundDetailSectorTrend,
    sectorId,
    industryId,
    fundDetailIndustryTrend,
    fundDetailETFIndustryTrend,
    fundDetailStockPositionTrend,
    industryTrendDate,
  ) => ({
    ...fundDetailSectorTrend,
    sectorId,
    industryId,
    ...fundDetailIndustryTrend,
    ...fundDetailETFIndustryTrend,
    ...fundDetailStockPositionTrend,
    industryTrendDate
  })
);
