import { flow, reduce, size } from "lodash/fp";
import tradingDateCount from "@/constant/tradingDateCount";
import { formatArrayNilToZero } from "../numberFormatter";

/**
 * 年收益率
 * link: [http://ci.betalpha.com:61009/pages/viewpage.action?pageId=11601078]
 */
export default (datas: number[]) =>
  flow(
    formatArrayNilToZero,
    reduce((multiplyValue, n: number) => multiplyValue * (n + 1), 1),
    (value) => Math.pow(Math.pow(value, 1 / size(datas)), tradingDateCount) - 1
  )(datas);
