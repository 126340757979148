import {
  fastProp,
  getProp,
  mapIndexed,
  zipWithMinus,
} from "@/util/opt";
import { size } from "lodash/fp";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import getCumulativeAttributionsByParams from "@/util/quant/cumulativeAttributions";
import getInformationRatio from "@/util/quant/informationRatio";
import getTrackDeviation from "@/util/quant/trackDeviation";
import { subAttributionsType } from "@/model/fundDetail";
import { getAShareAllocationAndSelectionAttributions } from ".";
import { formatArrayNilToZero } from "@/util/numberFormatter";

export default function (result:Record<string,any>, sectors:Record<string,any>) {
    // 行业业绩
    const subAttributions = fastProp("subAttributions")(result) || [];
    // 日期
    const dates = fastProp("dates")(result) || [];

    // 基金净值业绩
    const dailyPortfolioReturns =
    formatArrayNilToZero(fastProp("dailyPortfolioActualReturns")(result) || []);
    // 基金净值累计业绩
    const cumulativePortfolioAttributions = getCumulativeReturns(
      dailyPortfolioReturns
    );
    // 基金持仓业绩
    const dailyPortfolioPositionReturns =
    formatArrayNilToZero(fastProp("dailyPortfolioReturns")(result) || []);

    // 基准A股部分的持仓业绩
    const dailyAShareBenchmarkPositionReturns =
    formatArrayNilToZero(fastProp("dailyBenchmarkPositionReturns")(result) || []);

    // A股+非A股+权益类的基准净值业绩
    const dailyBenchmarkReturns =
      fastProp("dailyBenchmarkReturns")(result) || [];
    // A股+非A股+权益类的基准净值累计业绩
    const cumulativeBenchmarkAttributions = getCumulativeReturns(
      dailyBenchmarkReturns
    );

    // 净值 连接算法
    const getActualCumulativeAttributions = getCumulativeAttributionsByParams(
      // 基金净值
      dailyPortfolioReturns,
      // 基准净值
      dailyBenchmarkReturns,
      cumulativePortfolioAttributions,
      cumulativeBenchmarkAttributions
    );

    // 日超额业绩
    const dailyActiveReturns = zipWithMinus(
      // 基金净值
      dailyPortfolioReturns,
      // 基准业绩(A股+非A股+权益类)
      dailyBenchmarkReturns
    );
    // 超额业绩 = 基金净值业绩 - 基准净值业绩
    const cumulativeActiveAttributions =
      getActualCumulativeAttributions(dailyActiveReturns);

    // 资产配置净值业绩
    const dailyAllocationAttributions =
      fastProp("dailyAllocationAttributions")(result) || [];
    // 资产配置累计净值业绩
    const cumulativeAllocationAttributions = getActualCumulativeAttributions(
      dailyAllocationAttributions
    );

    // 选非A股净值业绩
    const dailyNonAShareAttributions =
      fastProp("dailyNonAShareAttributions")(result) || [];
    // 选非A股累计净值业绩
    const cumulativeNonAShareAttributions = getActualCumulativeAttributions(
      dailyNonAShareAttributions
    );

    // 选非权益类净值业绩
    const dailyNonEquityAttributions =
      fastProp("dailyNonEquityAttributions")(result) || [];
    // 选非权益类累计净值业绩
    const cumulativeNonEquityAttributions = getActualCumulativeAttributions(
      dailyNonEquityAttributions
    );

    // 选A股持仓-净值业绩
    const dailyAShareAttributions =
      fastProp("dailyAShareAttributions")(result) || [];
    // 选A股持仓-净值累计业绩
    const cumulativeAShareAttributions = getActualCumulativeAttributions(
      dailyAShareAttributions
    );

    const {
      // 选A股配置持仓业绩
      dailyAShareAllocationAttributions,
      // 选A股选股持仓业绩
      dailyAShareSelectionAttributions,
      // 选A股择时持仓业绩
      dailyAShareTimingAttributions,
    } = getAShareAllocationAndSelectionAttributions(
      subAttributions,
      size(dates)
    );

    // 选A股配置持仓累计业绩
    const cumulativeAShareAllocationAttributions =
      getActualCumulativeAttributions(dailyAShareAllocationAttributions);

    // 选A股选股持仓累计业绩
    const cumulativeAShareSelectionAttributions =
      getActualCumulativeAttributions(dailyAShareSelectionAttributions);
    // 选A股择时持仓累计业绩
    const cumulativeAShareTimingAttributions = getActualCumulativeAttributions(
      dailyAShareTimingAttributions
    );

    // 第三层的其他业绩 = 基金净值 - 基金持仓
    const dailyThirdFloorOtherAttributionReturns = zipWithMinus(
      // 基金净值
      dailyPortfolioReturns,
      // 基金持仓
      dailyPortfolioPositionReturns
    );
    // 第三层其他业绩累计
    const cumulativeThirdFloorOtherAttributionReturns =
      getActualCumulativeAttributions(dailyThirdFloorOtherAttributionReturns);

    const calculatedSubAttributions = mapIndexed(
      (subAttribution: Array<subAttributionsType>) => {
        const id = fastProp("id")(subAttribution);
        const subDailyAShareAllocationAttributions =
          fastProp("dailyAllocationAttributions")(subAttribution) || [];
        const subDailyAShareSelectionAttributions =
          fastProp("dailySelectionAttributions")(subAttribution) || [];
        const subDailyTimingAttributions =
          fastProp("dailyTimingAttributions")(subAttribution) || [];
        const subCumulativeAShareAllocationAttributions =
          getActualCumulativeAttributions(
            subDailyAShareAllocationAttributions
          ) || [];
        const subCumulativeAShareSelectionAttributions =
          getActualCumulativeAttributions(
            subDailyAShareSelectionAttributions
          ) || [];
        const subCumulativeTimingAttributions = getActualCumulativeAttributions(
          subDailyTimingAttributions
        );
        return {
          id,
          subDailyAShareAllocationAttributions,
          subDailyAShareSelectionAttributions,
          subDailyTimingAttributions,
          subCumulativeAShareAllocationAttributions,
          subCumulativeAShareSelectionAttributions,
          subCumulativeTimingAttributions,
          aShareAllocationInformationRatio: getInformationRatio(
            subDailyAShareAllocationAttributions
          ),
          aShareSelectionInformationRatio: getInformationRatio(
            subDailyAShareSelectionAttributions
          ),
          aShareTimingInformationRatio: getInformationRatio(
            subDailyTimingAttributions
          ),

          name: getProp(`${id}.name`)(sectors),
        };
      }
    )(subAttributions);

    // 行业外返回的第四层其他业绩
    const dailyAShareOtherReturns = fastProp("dailyOtherReturns")(result) || [];

    // 第四层其他累计业绩,因为要乘权重，所以不能用净值-持仓
    const cumulativeForthFloorOtherAttributions =
      getActualCumulativeAttributions(dailyAShareOtherReturns);
    return {
      dates,
      dailyPortfolioReturns,
      dailyBenchmarkReturns,
      dailyActiveReturns,
      dailyAShareBenchmarkPositionReturns,

      dailyAllocationAttributions,
      dailyAShareAttributions,
      dailyNonAShareAttributions,
      dailyNonEquityAttributions,
      dailyThirdFloorOtherAttributionReturns,

      dailyAShareAllocationAttributions,
      dailyAShareSelectionAttributions,
      dailyAShareTimingAttributions,
      dailyAShareOtherReturns,

      cumulativePortfolioAttributions,
      cumulativeBenchmarkAttributions,
      cumulativeActiveAttributions,

      cumulativeAllocationAttributions,
      cumulativeNonAShareAttributions,
      cumulativeNonEquityAttributions,
      cumulativeThirdFloorOtherAttributionReturns,
      cumulativeAShareAttributions,

      cumulativeAShareAllocationAttributions,
      cumulativeAShareSelectionAttributions,
      cumulativeAShareTimingAttributions,
      cumulativeForthFloorOtherAttributions,

      informationRatio: getInformationRatio(dailyActiveReturns),
      trackDeviation: getTrackDeviation(
        dailyPortfolioReturns,
        dailyBenchmarkReturns
      ),
      subAttributions: calculatedSubAttributions,
    };
  }
