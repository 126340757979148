import { Col, Form, InputNumber, Modal, Row, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useMemoizedFn } from "ahooks";
import { map, merge, prop } from "lodash/fp";
import dayjs from "dayjs";

import { useFormatMessage } from "@/util/formatMessage";
import { totalAccountProp } from "@/views/customerManage/customerAccountAnalysis/customerSummary";
import { useGetAccountList } from "@/views/customerManage/customerAccountAnalysis/customerSummary/hook";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  fetchAssetPositionDetail,
  fetchPositionAssetsList,
  positionAssetTransaction,
} from "@/store/customerAccountAnalysis";
import { PositionAssetTransactionParam } from "@/model/customer";
import { dataSourceTimeSelector } from "@/selectors/dataSource";
import { customerAnalysisSelector } from "@/views/customerManage/customerAccountAnalysis/selectors";
import { RootState } from "@/store";
import { fastProp } from "@/util/opt";
import LoadingComponent from "@/components/LoadingComponent";
import { parseToThousandth } from "@/util/numberFormatter";

import style from "./index.module.less";
import {
  useGetIsActionLoading,
  useLargeThanZeroValidator,
  useNotNullValidator,
} from "../hooks";
import TradingDatePicker from "@/components/tradingDatePicker";

const { Item } = Form;

export enum TRANSFER_TYPE {
  IN = "transferIn",
  OUT = "transferOut",
}

export default React.memo(
  ({
    transferType,
    accountId,
    customerName,
    totalAccountId,
  }: {
    transferType: TRANSFER_TYPE;
    accountId: string;
    customerName: string;
    totalAccountId: string;
  }) => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();
    const [isShowModal, setIsShowModal] = useState(false);

    const accountList = useGetAccountList({
      totalAccountId: totalAccountId,
      customerName,
    });

    const [form] = Form.useForm();

    const handleOk = useMemoizedFn(() => {
      form.validateFields().then((values) => {
        dispatch(
          positionAssetTransaction(
            merge({
              assets: [
                {
                  assetType: "CASH",
                  transactionType:
                    transferType === TRANSFER_TYPE.IN
                      ? "APPEND_INVEST"
                      : "REDUCE_INVEST",
                },
              ],
            } as Partial<PositionAssetTransactionParam>)(values)
          )
        ).then(() => {
          dispatch(
            fetchPositionAssetsList({
              accountId,
              forceToRefetch: true,
            })
          );
          setIsShowModal(false);
        });
      });
    });

    const confirmLoading = useGetIsActionLoading(positionAssetTransaction);

    const notNullValidator = useNotNullValidator();
    const largeThanZeroValidator = useLargeThanZeroValidator();

    useEffect(() => {
      if (isShowModal && accountId) form.setFieldsValue({ accountId });
    }, [accountId, form, isShowModal]);
    const dataSourceTime = useAppSelector(dataSourceTimeSelector);

    const accountAnalysisData = useAppSelector((state: RootState) =>
      customerAnalysisSelector(state, accountId)
    );
    const { from, to } = useMemo(
      () => fastProp("accountInfo")(accountAnalysisData) || {},
      [accountAnalysisData]
    );
    const disabledDate = useMemoizedFn(
      (date: string) =>
        dayjs(date).isAfter(dataSourceTime) ||
        dayjs(date).isBefore(from) ||
        dayjs(date).isAfter(to)
    );

    const [chosenDate, setChosenDate] = useState("");
    useEffect(() => {
      if (chosenDate && transferType === TRANSFER_TYPE.OUT)
        dispatch(fetchAssetPositionDetail({ accountId, date: chosenDate }));
    }, [accountId, chosenDate, dispatch, transferType]);

    useEffect(() => {
      if (!isShowModal) setChosenDate("");
    }, [isShowModal]);

    const cashScale: number = useAppSelector(
      prop(
        `customerAnalysis.${accountId}.assetPositionDetail.${chosenDate}.cash`
      )
    );
    const handleValuesChange = useMemoizedFn((changedValues) => {
      const formChosenDate = prop(["assets", 0, "date"])(changedValues);
      if (formChosenDate) setChosenDate(formChosenDate);
    });
    return (
      <div>
        <a onClick={() => setIsShowModal(true)}>
          {formatMessage(transferType)}
        </a>
        {isShowModal && (
          <Modal
            title={formatMessage(transferType)}
            open={isShowModal}
            okText={`${formatMessage("confirm")}${formatMessage(transferType)}`}
            onOk={handleOk}
            onCancel={() => setIsShowModal(false)}
            destroyOnClose
            confirmLoading={confirmLoading}
          >
            <Form
              labelCol={{ span: 6 }}
              labelAlign="left"
              preserve={false}
              form={form}
              className={style.Form}
              onValuesChange={handleValuesChange}
            >
              <Item
                label={`${formatMessage(transferType)}${formatMessage(
                  "account"
                )}`}
                name="accountId"
                rules={[
                  {
                    validator: notNullValidator(formatMessage("account")),
                  },
                ]}
              >
                <Select dropdownMatchSelectWidth={false}>
                  {map((item: totalAccountProp) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.message}
                    </Select.Option>
                  ))(accountList)}
                </Select>
              </Item>
              <div className={style.AccountSelectWrap}>
                <Item
                  label={`${formatMessage(transferType)}${formatMessage(
                    "Date"
                  )}`}
                  name={["assets", 0, "date"]}
                  rules={[
                    {
                      validator: notNullValidator(formatMessage("Date")),
                    },
                  ]}
                >
                  <TradingDatePicker
                    className={style.FullWidth}
                    disabledDate={disabledDate}
                  />
                </Item>
                {chosenDate && transferType === TRANSFER_TYPE.OUT && (
                  <Row className={style.CurrentCashScaleWrap}>
                    <Col span={6} />
                    <LoadingComponent actions={fetchAssetPositionDetail}>
                      <Col className={style.CurrentCashScale}>
                        {`${formatMessage(
                          "chosenDateAmount"
                        )}：${parseToThousandth(cashScale)}`}
                      </Col>
                    </LoadingComponent>
                  </Row>
                )}
              </div>
              <Item
                label={`${formatMessage(transferType)}${formatMessage(
                  "amountOfMoney"
                )}`}
                name={["assets", 0, "amount"]}
                rules={[
                  {
                    validator: largeThanZeroValidator(
                      `${formatMessage(transferType)}${formatMessage(
                        "amountOfMoney"
                      )}`
                    ),
                  },
                  ...(transferType === TRANSFER_TYPE.OUT
                    ? [
                        {
                          validator: (rule: any, value: number) => {
                            if (value > cashScale)
                              return Promise.reject(
                                new Error(
                                  formatMessage("transferOutAmountError")
                                )
                              );
                            return Promise.resolve();
                          },
                        },
                      ]
                    : []),
                ]}
              >
                <InputNumber<number>
                  step={0.01}
                  className={style.FullWidth}
                  {...(transferType === TRANSFER_TYPE.OUT
                    ? { max: cashScale }
                    : {})}
                  min={0}
                  precision={2}
                />
              </Item>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
);
