import React, { useMemo } from "react";
import { fastProp, forEachIndexed, normalize } from "@/util/opt";
import { Table } from "antd";
import { useMemoizedFn } from "ahooks";
import PeriodRangePicker from "@/components/periodRangePicker";
import {
  brinsonIndicatorsInfo,
  fixedNameTableColumn,
  frequencyData,
} from "../../constant";
import { map, compact, forEach } from "lodash/fp";
import style from "../index.module.less";
import ColorNumber from "@/components/colorNumber";

export const getBrinsonIndicator = (key: string) =>
  fastProp(key)(brinsonIndicatorsInfo);

export default React.memo<{
  frequency: string;
  brinsonTableData: any;
  selectFrequency: (frequency: string) => void;
}>(({ brinsonTableData, frequency, selectFrequency }) => {
  const statisticsTableIndicators = useMemo(
    () =>
      map(getBrinsonIndicator)([
        "activeAttribution",
        "allocationAttribution",
        "selectionAttribution",
        "timingOrInteractionAttribution",
        "otherAttribution",
        "portfolioActualAttribution",
        "benchmarkActualAttribution",
      ]),
    []
  );

  const getStatisticsTableData = useMemoizedFn((data: any, indicators: any) => {
    const columns = [fixedNameTableColumn];
    const indicatorsData = normalize("id")(indicators);
    forEach((statistic: any) => {
      const id = fastProp("id")(statistic);
      columns.push({
        dataIndex: id,
        title: id,
        width: 110,
        align: "center",
        fixed: false as any,
        render: (text: number) => {
          return <ColorNumber value={text} formatter="percentage" />;
        },
      } as any);

      forEachIndexed((value: Record<string, string>, key: string) => {
        value[id] = fastProp(key)(statistic);
      })(indicatorsData);
    })(data);
    return {
      columns,
      tableDataSource: indicatorsData,
    };
  });

  const { columns, tableDataSource } = getStatisticsTableData(
    brinsonTableData,
    statisticsTableIndicators
  );
  const dataSource = useMemo(
    () => [
      {
        // 超额业绩
        ...fastProp("activeAttribution")(tableDataSource),
        // 超额业绩 = 配置业绩+选基金业绩+交叉项业绩
        children: compact(
          map((key: string) => fastProp(key)(tableDataSource))([
            "allocationAttribution",
            "selectionAttribution",
            "timingOrInteractionAttribution",
            "otherAttribution",
          ])
        ),
      },
      // 组合业绩和基准业绩
      ...compact(
        map((key: string) => fastProp(key)(tableDataSource))([
          "benchmarkActualAttribution",
          "portfolioActualAttribution",
        ])
      ),
    ],
    [tableDataSource]
  );
  return (
    <div>
      <PeriodRangePicker
        period={frequency}
        selectablePeriods={frequencyData}
        onChange={selectFrequency}
      />
      <Table
        defaultExpandAllRows
        dataSource={dataSource}
        columns={columns as any}
        bordered
        pagination={false}
        scroll={{ x: 600 }}
        className={style.BrinsonTable}
      />
    </div>
  );
});
