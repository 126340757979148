import { useCreation, useMemoizedFn } from "ahooks";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { ManualCreatePortfolio, Asset, getDefaultErrorField } from "./constant";
import { fastProp } from "@/util/opt";
import {
  resetFundConfiguration,
  resetManualCreatePortfolio,
  resetModelConfiguration,
} from "@/store/createPortfolio";
import { useGetConfirm } from "@/hooks/modal";
import { getNextTradingDate } from "@/util/processedDates";
import {
  compact,
  concat,
  map,
  pullAt,
  set,
  update,
  flow,
  isEmpty,
  prop,
  isNumber,
  sumBy,
  first,
  drop,
  pick,
  last,
  some,
} from "lodash/fp";
import { fundIdMapSelector } from "@/selectors/fund";
import { FundsInterface } from "@/model/entities";
import { dataSourceTimeSelector } from "@/selectors/dataSource";
import { validateWeightMaxError } from "@/components/portfolioCompoents/fundListTable";
import { roundTo } from "@/util/numberFormatter";
import { updateManualCreatePortfolio } from "@/store/createPortfolio";
import { useHandleEffectiveDate } from "../portfolioList/hooks";
import { useContext, useEffect, useMemo } from "react";
import { InitSimpleDataType } from "@/components/portfolioCompoents/hook";
import { BackTestingFormProps } from "./interface";
import { fetchAssetPositionDetail } from "@/store/portfolioAnalysis";
import mapper from "@/views/portfolioManage/portfolioAnalysis/positionThrough/mapper";
import { assetPortfoliosPositionDetailAsset } from "@/model/portfolioAnalysis";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useFormatMessage } from "@/util/formatMessage";
import { isNil } from "lodash";

export type Updater = string | boolean | ((asset: Asset[]) => Asset[]);
const dataUpdater =
  (key: string, updater: Updater) => (data: ManualCreatePortfolio) => {
    if (typeof updater === "function") {
      const updatedData = update(key, updater)(data) as ManualCreatePortfolio;
      const sumWeight = roundTo(4)(sumBy("weight")(updatedData.weights));
      return {
        ...updatedData,
        sumWeight,
      };
    }
    return set(key, updater)(data);
  };
export const validateDataError = (
  data: ManualCreatePortfolio,
  transfer: any
) => {
  const dateEmptyError = !data.turnoverDate;
  const fundEmptyError = isEmpty(data.weights);
  const fundWeightMaxError = validateWeightMaxError(data.sumWeight);
  const fundWeightZeroError = data.sumWeight <= 0;

  const transferData = transfer?.(data?.weights);
  let hasNetValueStartDateError = false;
  let netValueStartDateZeroError = false;
  let latestNetUnitValueZeroError = false;
  let hasMaturityDateError = false;
  let weights = data.weights;
  if (some((item: any) => isNil(fastProp("netValue")(item)))(transferData))
    latestNetUnitValueZeroError = true;
  else latestNetUnitValueZeroError = false;
  if (
    some((item: any) => isNil(fastProp("netValueStartDate")(item)))(
      transferData
    )
  )
    netValueStartDateZeroError = true;
  else netValueStartDateZeroError = false;
  if (!fundEmptyError && !dateEmptyError) {
    const turnoverDateDay = dayjs(data.turnoverDate);
    weights = data.weights.map((item) => {
      const { netValueStartDate, tradingDay } = item || {};
      const netValueStartDateError =
        turnoverDateDay.isBefore(netValueStartDate);
      const maturityDateError = turnoverDateDay.isAfter(tradingDay);
      if (netValueStartDateError) hasNetValueStartDateError = true;
      if (maturityDateError) hasMaturityDateError = true;
      return {
        ...item,
        netValueStartDateError,
        maturityDateError,
      };
    });
  }
  return {
    ...data,
    weights,
    errorField: {
      ...data.errorField,
      dateEmptyError,
      fundEmptyError,
      fundWeightMaxError,
      fundWeightZeroError,
      netValueStartDateError: hasNetValueStartDateError,
      maturityDateError: hasMaturityDateError,
      netValueStartDateZeroError,
      latestNetUnitValueZeroError,
    },
  };
};
export const useManagePositionData = () => {
  const { allocationData } = useAppSelector(
    (state) => state.createPortfolio.manualCreatePortfolio
  );
  const positionData = useCreation(
    () => first(allocationData) as ManualCreatePortfolio,
    [allocationData]
  );
  const changingPositionData = useCreation(
    () => drop(1)(allocationData),
    [allocationData]
  );
  const transfer = useTransferAssetToFundAsset();
  const dispatch = useAppDispatch();
  const setPositionData = useMemoizedFn(
    (updater: (data: ManualCreatePortfolio) => ManualCreatePortfolio) =>
      dispatch(
        updateManualCreatePortfolio("allocationData", () => [
          updater(positionData),
          ...changingPositionData,
        ])
      )
  );
  const setChangingPositionData = useMemoizedFn(
    (updater: (data: ManualCreatePortfolio[]) => ManualCreatePortfolio[]) =>
      dispatch(
        updateManualCreatePortfolio("allocationData", () => [
          positionData,
          ...updater(changingPositionData),
        ])
      )
  );
  const onUpdate = useMemoizedFn(
    (index: number, key: string, updater: Updater) =>
      setChangingPositionData(
        update(
          index,
          flow(dataUpdater(key, updater), (arg) =>
            validateDataError(arg, transfer)
          )
        )
      )
  );
  const onUpdatePositionData = useMemoizedFn(
    (key: string) => (updater: Updater) =>
      setPositionData(
        flow(dataUpdater(key, updater), (arg) =>
          validateDataError(arg, transfer)
        )
      )
  );
  const onUpdateChangingPositionData = useMemoizedFn(
    (index: number) => (key: string) => (updater: Updater) =>
      onUpdate(index, key, updater)
  );
  const getNextTurnoverDate = useGetNextTurnoverDate();
  const onAddChangingPositionData = useMemoizedFn(
    (preIndex: number | null | undefined) => {
      if (isNumber(preIndex)) {
        const prePosition = changingPositionData[preIndex];
        if (prePosition) {
          setChangingPositionData((originData) =>
            concat(originData)(
              validateDataError(
                {
                  ...prePosition,
                  turnoverDate: getNextTurnoverDate(prePosition.turnoverDate),
                  weights: [...prePosition.weights],
                },
                transfer
              )
            )
          );
        }
      } else {
        setChangingPositionData((originData) =>
          concat(originData)(
            validateDataError(
              {
                ...positionData,
                turnoverDate: getNextTurnoverDate(positionData.turnoverDate),
                weights: [...positionData.weights],
              },
              transfer
            )
          )
        );
      }
    }
  );
  const onRemovePositionData = useMemoizedFn((index: number) =>
    setChangingPositionData(pullAt(index))
  );
  const onClearPositionData = useMemoizedFn(() =>
    setChangingPositionData(() => [])
  );
  return {
    positionData,
    changingPositionData,
    onUpdatePositionData,
    onUpdateChangingPositionData,
    onAddChangingPositionData,
    onRemovePositionData,
    onClearPositionData,
  };
};

export const useTransferAssetToFundAsset = () => {
  const fundsMap = useAppSelector(fundIdMapSelector);
  return useMemoizedFn((weights: Asset[]) =>
    flow(
      map<Asset, FundsInterface & Asset>((weight) => ({
        ...weight,
        ...fastProp(weight?.fundId)(fundsMap),
      })),
      compact
    )(weights)
  );
};

export const useGetNextTurnoverDate = () => {
  const processedTradingDates = useAppSelector(
    prop("tradingDates.processedTradingDates")
  );
  const tradingDateList = useAppSelector(prop("tradingDates.tradingDateList"));
  const dataSourceTime = useAppSelector(dataSourceTimeSelector);
  return useMemoizedFn((date: string | undefined | null) => {
    if (!date) return "";
    const nextTradingDate = getNextTradingDate(
      tradingDateList,
      processedTradingDates,
      date
    );
    if (nextTradingDate > dataSourceTime) return "";
    return nextTradingDate;
  });
};

export const useGenerateAsset = () => {
  const fundsMap = useAppSelector(fundIdMapSelector);
  return useMemoizedFn((fundIds: string[]) =>
    map<string, Asset>((id) => {
      const currentFund = fastProp(id)(fundsMap);
      return {
        fundId: id,
        weight: 0,
        maturityDateError: false,
        netValueStartDateError: false,
        maturityDate: fastProp("maturityDate")(currentFund),
        tradingDay: fastProp("tradingDay")(currentFund),
        netValueStartDate: fastProp("netValueStartDate")(currentFund),
      };
    })(fundIds)
  );
};

export const useGetPositionDate = (initSimpleData: InitSimpleDataType) => {
  const { effectiveDate, onGetEffectiveDate } = useHandleEffectiveDate();
  useEffect(() => {
    if (initSimpleData && !isEmpty(initSimpleData) && !initSimpleData.name) {
      onGetEffectiveDate();
    }
  }, [initSimpleData, onGetEffectiveDate]);
  return useCreation(
    () => (initSimpleData && initSimpleData.date) || effectiveDate,
    [effectiveDate, initSimpleData]
  );
};

// 用来判断当前页面是通过修改配置进入的还是新创建进入的
export const useCheckIsRecreating = (type: BackTestingFormProps["type"]) => {
  const { backTestingFormData } = useAppSelector(
    (state) => state.createPortfolio[type]
  );
  return useMemo(() => !!backTestingFormData?.id, [backTestingFormData.id]);
};

// 回测之后再保存组合增加一次调仓
const pickFund = [
  "fundId",
  "netValueStartDate",
  "netValueStartDate",
  "tradingDay",
  "maturityDate",
  "netValueStartDateError",
  "maturityDateError",
];
const useCheckIsBackTested = (data: ManualCreatePortfolio[]) => {
  const dispatch = useAppDispatch();
  const backTestingTask = useAppSelector(
    prop("portfolioAnalysis.backTestingTask")
  );
  const backTestingId = fastProp("backTestingId")(backTestingTask);
  const date = prop(["arg", "endDate"])(backTestingTask);
  const lastTurnoverDate = useMemo(
    () => flow(last, fastProp("turnoverDate"))(data),
    [data]
  );
  useEffect(() => {
    if (backTestingId && date && lastTurnoverDate !== date) {
      dispatch(
        fetchAssetPositionDetail({
          id: backTestingId,
          date,
        })
      );
    }
  }, [backTestingId, backTestingTask, date, dispatch, lastTurnoverDate]);
  return useMemo(
    () => ({
      backTestingId,
      isBackTested: !!backTestingId && lastTurnoverDate !== date,
      date,
    }),
    [backTestingId, date, lastTurnoverDate]
  );
};
export const useGetSaveTableData = (data: ManualCreatePortfolio[]) => {
  const transfer = useTransferAssetToFundAsset();
  const { backTestingId, isBackTested, date } = useCheckIsBackTested(data);
  const {
    assetPosition: { assetPositionDetail },
  } = useAppSelector((state) => mapper(state, backTestingId));
  const fundIdMap = useAppSelector(fundIdMapSelector);
  const createPortfolioData = useMemo<ManualCreatePortfolio>(() => {
    const weights = flow(
      prop([date, "assets"]),
      map(({ fundId, weight }: assetPortfoliosPositionDetailAsset) => {
        const fund = fastProp(fundId)(fundIdMap);
        return {
          ...pick(pickFund)(fund),
          weight,
        };
      })
    )(assetPositionDetail);
    return validateDataError(
      {
        turnoverDate: date,
        weights,
        sumWeight: sumBy("weight")(weights),
        errorField: getDefaultErrorField(),
      },
      transfer
    );
  }, [assetPositionDetail, date, fundIdMap, transfer]);
  return useMemo(
    () => (isBackTested ? data.concat(createPortfolioData) : data),
    [isBackTested, data, createPortfolioData]
  );
};
export const useCloseModal = (
  type: "manualCreatePortfolio" | "fundConfiguration" | "modelAllocation"
) => {
  const confirm = useGetConfirm();
  const { stackBack } = useContext(PlatformNavigationContext);
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const resetHandler = useMemoizedFn(() => {
    switch (type) {
      case "manualCreatePortfolio":
        return dispatch(resetManualCreatePortfolio(null));
      case "fundConfiguration":
        return dispatch(resetFundConfiguration(null));
      case "modelAllocation":
        return dispatch(resetModelConfiguration(null));
    }
  });
  const isRecreating = useCheckIsRecreating(type);
  useEffect(() => resetHandler, [resetHandler]);
  return useMemoizedFn(() =>
    confirm({
      onOk: () => {
        resetHandler();
        stackBack();
      },
      content: isRecreating
        ? formatMessage("cancelModifyTip")
        : formatMessage("cancelTip"),
    })
  );
};
