import { PromiseType } from "@/model/promise";
import { del, get, post, put } from "@/util/axios";

export type editTagParam = {
  id: string;
  name: string;
  frequency: number;
};

//添加客户
export const addCustomerApi = (params: Record<string, any>) =>
  post("/customer/manage/add/customer", params);

//添加标签
export const addTagApi = (name: string) => post("/customer/tag", { name });

//所有标签
export const getAllCustomerTagApi = () => get("/customer/tag");

//删除标签
export const deleteTagApi = (id: string): PromiseType<void> =>
  del(`customer/tag/delete/${id}`, null);

//编辑标签
export const editTagApi = (params: editTagParam) =>
  put(`customer/tag`, params, {
    config: {
      showLoading: true,
    },
  });
