import React, { useEffect, useMemo, useState } from "react";
import { map, last, forEach, prop, isEmpty } from "lodash/fp";
import { Spin } from "antd";
import { useMemoizedFn } from "ahooks";
import { useFormatMessage } from "@/util/formatMessage";
// import { colors } from "@/util/colors";
import { mapIndexed, normalize } from "@/util/opt";
import { getAumHoldStockStyle } from "@/api/aum";
import { CodeWeight, HoldStockStyle } from "@/model/aum";
import { useRequest } from "@/hooks/request";
import { holdStockStyleMsg } from "@/constant/holdStockStyle";
import DisclosureDateCascader from "@/base-components/disclosureDateCascader";
import {
  DistributionChangeChart,
  HoldStockStyleChart,
} from "@/base-components/charts";
import EmptyContent from "@/components/emptyContent";
import SubTitle from "../../components/subTitle";
import { CommonProps } from "../../type";

import style from "../../index.module.less";

export default React.memo<CommonProps>(({ assetPortfolioId }) => {
  const formatMessage = useFormatMessage();
  const [date, setDate] = useState<string>("");
  const { loading, data, request } = useRequest<string, HoldStockStyle[]>(
    getAumHoldStockStyle
  );

  const { dates, styleByDateMap } = useMemo(() => {
    if (!data) return {};
    return {
      styleByDateMap: normalize<HoldStockStyle, CodeWeight[]>(
        "tradingDate",
        (item) => item?.dailyStyleInfoList
      )(data),
      dates: map((item: HoldStockStyle) => item?.tradingDate)(data),
    };
  }, [data]);

  const series = useMemo(() => {
    const result: Record<string, [string, number][]> = {
      LARGE_VALUE: [],
      LARGE_BALANCE: [],
      LARGE_GROWTH: [],
      MID_VALUE: [],
      MID_BALANCE: [],
      MID_GROWTH: [],
      SMALL_VALUE: [],
      SMALL_BALANCE: [],
      SMALL_GROWTH: [],
      OTHER: [],
    };
    forEach((item: HoldStockStyle) => {
      forEach((codeWeight: CodeWeight) => {
        result[codeWeight?.code].push([item?.tradingDate, codeWeight?.weight]);
      })(item?.dailyStyleInfoList);
    })(data);
    return mapIndexed((values: [string, number][], code: string) => ({
      name: formatMessage(prop(code)(holdStockStyleMsg)),
      data: values,
    }))(result);
  }, [data, formatMessage]);

  const styleChartData = useMemo(() => {
    return normalize<CodeWeight, number>(
      "code",
      (item) => item?.weight
    )(styleByDateMap?.[date] || []);
  }, [styleByDateMap, date]);

  const changeChartDateHandler = useMemoizedFn((event: any) => {
    if (event?.name) setDate(event?.name);
  });

  useEffect(() => {
    if (assetPortfolioId) {
      request(assetPortfolioId);
    }
  }, [assetPortfolioId, request]);

  useEffect(() => {
    if (dates) {
      setDate(last(dates) || "");
    }
  }, [dates]);

  return (
    <div>
      <SubTitle title={formatMessage("holdStockStyle")} />
      <Spin spinning={loading}>
        <div className={style.ChartContainer}>
          <div className={style.Chart}>
            {isEmpty(data) ? (
              <EmptyContent>
                <strong>{formatMessage("noData")}</strong>
              </EmptyContent>
            ) : (
              <DistributionChangeChart
                series={series}
                dates={dates || []}
                height={400}
                showDataZoom={false}
                selectedDate={date}
                boundaryGap={true}
                dateMode="twoLine"
                onEvents={{ click: changeChartDateHandler }}
              />
            )}
          </div>
          <div className={style.Content}>
            <div className={style.DisclosureDateWrap}>
              <DisclosureDateCascader
                dates={dates || []}
                value={date}
                onChange={setDate}
              />
            </div>
            <div className={style.HoldStockStyleChartWrap}>
              <HoldStockStyleChart data={styleChartData} height={350} />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
});
