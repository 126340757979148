import { Card, Space, Table } from "antd";
import React, { useEffect, useMemo } from "react";
import { FROM_CREATION } from "@/constant/statisticRange";
import style from "./index.module.less";
import { BaseFundManagerDetailInfoProps } from "../../interface";
import { useFormatMessage } from "@/util/formatMessage";
import LoadingComponent from "@/components/LoadingComponent";
import StatisticRange from "@/components/statisticRange";
import {
  useGetPerformanceRankingTableData,
  useManagerStoreItem,
} from "../hooks";
import { getFundManagerManageFundPerformanceInfo } from "@/store/fundManagerDetailSlice";
import { useAppDispatch } from "@/hooks/redux";
import {
  getPerformanceRankTableColumns,
  performanceAndYieldOptions,
} from "../constant";

export default React.memo<BaseFundManagerDetailInfoProps>(({ id }) => {
  const formatMessage = useFormatMessage();
  const [rankRange, setRankRange] = useManagerStoreItem(
    id,
    "manageFund.performanceRankRange",
    FROM_CREATION
  );
  const tableColumns = useMemo(
    () => getPerformanceRankTableColumns(rankRange),
    [rankRange]
  );
  const dataSource = useGetPerformanceRankingTableData(id, rankRange);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id && rankRange) {
      dispatch(
        getFundManagerManageFundPerformanceInfo({ id, section: rankRange })
      );
    }
  }, [dispatch, id, rankRange]);
  return (
    <LoadingComponent actions={getFundManagerManageFundPerformanceInfo}>
      <Card className={style.Card} bordered={false}>
        <div className={style.Header}>
          <h3>{formatMessage("performanceRanking")}</h3>
          <Space>
            <p>{formatMessage("periodOfTime")}: </p>
            <StatisticRange
              value={rankRange}
              onChange={setRankRange}
              statisticRangeList={performanceAndYieldOptions}
            />
          </Space>
        </div>
      </Card>
      <Table
        columns={tableColumns}
        dataSource={dataSource}
        className={style.RankingTable}
        pagination={false}
        scroll={{
          x: 400,
          y: 900,
        }}
      />
    </LoadingComponent>
  );
});
