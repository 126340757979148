/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import EventEmitter from "events";
import { size, last, isFunction } from "lodash/fp";

const getErrorKey = (uuid: string) => `${uuid}-rejected`;
const getSuccessKey = (uuid: string) => `${uuid}-resolve`;

export default class RequestListener {
  requestQueue: Array<[string, Promise<any>]>;
  batchedSize: number;
  curExecuteSize: number;
  eventListener: EventEmitter;
  constructor(batchedSize: number) {
    this.requestQueue = [];
    this.batchedSize = batchedSize;
    this.curExecuteSize = 0;
    this.eventListener = new EventEmitter();
  }

  isEmptyQueue(): boolean {
    return size(this.requestQueue) === 0;
  }

  inQueue(uuid: string, promise: any): Promise<unknown> {
    this.requestQueue.push([uuid, promise]);
    let resolve: any = null;
    let reject: any = null;
    const successKey = getSuccessKey(uuid);
    const failedKey = getErrorKey(uuid);
    const promiseHandler = new Promise((res, rej) => {
      this.eventListener.on(successKey, res);
      this.eventListener.on(failedKey, rej);
      resolve = res;
      reject = rej;
    }).finally(() => {
      this.eventListener.removeListener(successKey, resolve);
      this.eventListener.removeListener(failedKey, reject);
    });
    this.request();
    return promiseHandler;
  }

  request() {
    if (this.isEmptyQueue()) return;
    if (this.curExecuteSize < this.batchedSize) {
      this.curExecuteSize += 1;
      const batchedRequest = this.requestQueue.shift();
      if (!batchedRequest) return;
      const uuId = batchedRequest[0];
      let promiseHandler = last(batchedRequest);
      if (isFunction(promiseHandler)) promiseHandler = promiseHandler();
      Promise.resolve(promiseHandler)
        .then((result) => {
          this.eventListener.emit(getSuccessKey(uuId), result);
        })
        .catch((err) => {
          this.eventListener.emit(getErrorKey(uuId), err);
        })
        .finally(() => {
          this.curExecuteSize -= 1;
          this.request();
        });
    }
  }
}
