import React, { useEffect, useMemo, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { Card, Row, Col, Space, Rate, Select } from "antd";
import style from "../index.module.less";
import FundManagerSelect from "./fundManagerSelect";
import { CSI300_ID } from "@/views/compareManage/constant";
import CumulativeChart from "@/components/cumulativeChart";
import { currentFundManagerType } from "@/model/fundDetail";
import { first, flow, map, filter, last, prop, concat, omit } from "lodash/fp";
import { fastProp, normalize } from "@/util/opt";
import mapper from "../mapper";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import { formatNilToZero, formatPercentage } from "@/util/numberFormatter";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { currentFundManagerReturnSelector } from "../selectors";
import {
  fetchGetCurrentFundManagerReturn,
  fetchGetFundManagerImg,
} from "@/store/fundDetailSlice";
import fundManagerIcon from "@/assets/fundManagerIcon.png";
import type { AppDispatch } from "@/store/index";
import { useGetBenchmarkDailyReturn } from "@/hooks/benchmark";
import basicMapper from "../../../../mapper";
import { FROM_APPOINTMENT } from "@/constant/statisticRange";
import { useGetCumulativeChartCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import dayjs from "dayjs";
import { dateFormat } from "@/util/dateFormat";
import { statisticRange } from "@/constant/statisticRange";

const { Option } = Select;

const statisticOptions = {
  ...flow(omit(["RECENT_TRADING_DAY", "FROM_THIS_YEAR"]))(statisticRange),
  FROM_APPOINTMENT: {
    id: FROM_APPOINTMENT,
    message: "SinceTakingOffice",
    checkIsValid: () => true,
  },
};

export const calculateReturn = (
  dailyReturnsMap: Record<string, string>,
  jobDates: string[]
) => {
  return flow(
    map((date: string) => fastProp(date)(dailyReturnsMap)),
    (res) => getCumulativeReturns(res),
    last,
    formatPercentage
  )(jobDates);
};

const useGetChartData = (
  fundId: string,
  benchmarkId: string,
  activeRange: string,
  name: string,
  managerId: string | undefined,
  jobStartDate: string
) => {
  const currentFundManagerReturn = useAppSelector((state) =>
    currentFundManagerReturnSelector(state, fundId, managerId)
  );

  const managerIncomeInfo = useMemo(
    () => ({
      id: fundId,
      name: name,
      color: "#FFA62A",
      ...currentFundManagerReturn,
      dailyReturnsMap: normalizeDailyReturnsMap(
        fastProp("dates")(currentFundManagerReturn),
        fastProp("returns")(currentFundManagerReturn)
      ),
    }),
    [currentFundManagerReturn, fundId, name]
  );
  const benchmarkInfo = useGetBenchmarkDailyReturn(benchmarkId);

  const getCumulativeChartCalculateRangeDate =
    useGetCumulativeChartCalculateRangeDate();

  const calculatedDates = useMemo(() => {
    const rangeDate = fastProp("dates")(currentFundManagerReturn) as string[];
    return getCumulativeChartCalculateRangeDate(
      rangeDate,
      activeRange,
      jobStartDate
    );
  }, [
    currentFundManagerReturn,
    activeRange,
    getCumulativeChartCalculateRangeDate,
    jobStartDate,
  ]);

  const managerAndBenchmarkInfo = useMemo(
    () => concat(managerIncomeInfo)(benchmarkInfo),
    [benchmarkInfo, managerIncomeInfo]
  );

  const chartData = useMemo(
    () =>
      map(({ name, dailyReturnsMap, color }) => ({
        name,
        color,
        dailyReturns: map<string, [string, number]>((date) => [
          date,
          formatNilToZero(fastProp(date)(dailyReturnsMap)),
        ])(calculatedDates),
      }))(managerAndBenchmarkInfo),
    [managerAndBenchmarkInfo, calculatedDates]
  );

  return { dates: calculatedDates, chartData };
};

const useGetManagerBasicInfo = (
  fundId: string,
  currentFundManagers: currentFundManagerType[],
  dispatch: AppDispatch,
  managerId?: string
) => {
  const { dates, dailyReturnsMap } = useAppSelector((state) =>
    mapper(state, fundId)
  );

  const currentManagerInfo = useMemo(
    () =>
      flow(normalize("id"), fastProp(managerId as string))(currentFundManagers),
    [managerId, currentFundManagers]
  );
  const jobStartDate = useMemo(
    () => fastProp("startDate")(currentManagerInfo),
    [currentManagerInfo]
  );
  const jobReturn = useMemo(() => {
    const jobDates = filter((date: string) => date >= jobStartDate)(dates);
    return calculateReturn(dailyReturnsMap, jobDates);
  }, [dates, dailyReturnsMap, jobStartDate]);

  const url = useMemo(
    () => fastProp("url")(currentManagerInfo),
    [currentManagerInfo]
  );

  useEffect(() => {
    if (url && fundId && managerId) {
      dispatch(
        fetchGetFundManagerImg({
          fundId,
          managerId,
          url,
        })
      );
    }
  }, [url, dispatch, fundId, managerId]);

  return {
    rate: fastProp("rating")(currentManagerInfo),
    jobReturn,
    jobStartDate,
    introduce: fastProp("introduction")(currentManagerInfo),
    name: fastProp("name")(currentManagerInfo),
  };
};

export default React.memo(({ fundId }: { fundId: string }): JSX.Element => {
  const formatMessage = useFormatMessage();
  const [activeRange, setActiveRange] = useState(FROM_APPOINTMENT);
  const [benchmarkId, setBenchmarkId] = useState(CSI300_ID);
  const [managerId, setManagerId] = useState("");
  const dispatch = useAppDispatch();

  const { fundDetailFundManagers } = useAppSelector((state) =>
    mapper(state, fundId)
  );

  const { fundBasic } = useAppSelector((state) => basicMapper(state, fundId));

  const imgSrc = useMemo(
    () => prop(`fundManagersImg.${managerId}`)(fundBasic),
    [fundBasic, managerId]
  );

  const currentFundManagers = useMemo(
    () => fastProp("currentFundManagers")(fundDetailFundManagers),
    [fundDetailFundManagers]
  );

  useEffect(() => {
    setManagerId(flow(first, fastProp("id"))(currentFundManagers));
  }, [currentFundManagers]);

  useEffect(() => {
    if (managerId) {
      dispatch(fetchGetCurrentFundManagerReturn({ fundId, id: managerId }));
    }
  }, [fundId, dispatch, managerId, benchmarkId]);

  const { rate, jobReturn, jobStartDate, introduce, name } =
    useGetManagerBasicInfo(fundId, currentFundManagers, dispatch, managerId);

  const { dates, chartData } = useGetChartData(
    fundId,
    benchmarkId,
    activeRange,
    name,
    managerId,
    jobStartDate
  );

  return (
    <>
      <Card className={style.Card} title={formatMessage("currentFundManager")}>
        <Row gutter={16}>
          <Col span={10}>
            <div className={style.fundMangerCard}>
              <div className={style.fundMangerCardHead}>
                <Space size={20} className={style.fullWidth}>
                  <img src={imgSrc ? imgSrc : fundManagerIcon} />
                  <Space direction="vertical" size={5}>
                    <Space>
                      <Select
                        className={style.managerSelect}
                        value={managerId}
                        bordered={false}
                        onChange={setManagerId}
                      >
                        {map((item: currentFundManagerType) => (
                          <Option
                            key={fastProp("id")(item)}
                            value={fastProp("id")(item)}
                          >
                            {fastProp("name")(item)}
                          </Option>
                        ))(currentFundManagers)}
                      </Select>
                      <Rate
                        className={style.rateIcon}
                        allowHalf
                        disabled
                        value={rate}
                      />
                    </Space>
                    <div className={style.officeInfo} key="1">
                      <Space>
                        <span>{formatMessage("termReturn")}：</span>
                        <span className={style.officeNumber}>{jobReturn}</span>
                      </Space>
                    </div>
                    <div className={style.officeInfo} key="2">
                      <Space>
                        <span>{formatMessage("tenure")}：</span>
                        <span className={style.officeNumber}>
                          {jobStartDate}～{formatMessage("soFar")}
                        </span>
                      </Space>
                    </div>
                  </Space>
                </Space>
              </div>
              <div className={style.fundMangerCardContent}>
                <h3>{formatMessage("personalExperience")}</h3>
                <div>{introduce}</div>
              </div>
            </div>
          </Col>
          <Col span={14}>
            <h3>{formatMessage("profitability")}</h3>
            <div className={style.FundManagerSelect}>
              <FundManagerSelect
                activeRange={activeRange}
                benchmarkId={benchmarkId}
                onRangeChange={(v) => setActiveRange(v)}
                onBenchmarkChange={(v) => setBenchmarkId(v)}
                startDate={jobStartDate}
                endDate={dateFormat(dayjs())}
                staticRange={statisticOptions}
              />
            </div>
            <CumulativeChart
              dates={dates}
              dataSource={chartData}
              type="cumulative"
            />
          </Col>
        </Row>
      </Card>
    </>
  );
});
