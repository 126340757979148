import { LineChartOpts } from "@/echarts/lineChart";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { baseManagerSelector, currentManagerInfoSelector } from "../selectors";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { getOptions, getDuringReturns } from "./constant";
import { useFormatMessage } from "@/util/formatMessage";
import {
  forEach,
  fromPairs,
  map,
  flow,
  prop,
  assign,
  orderBy,
  last,
  values,
  take,
} from "lodash/fp";
import {
  FundManagerManageFundInfo,
  FundManagerManagePerformanceInfo,
} from "@/model/fundManagerDetail";
import { SeriesOption } from "echarts";
import { useMemoizedFn } from "ahooks";
import { updateManagerDetailInfo } from "@/store/fundManagerDetailSlice";
import { useGetFundDailyReturn } from "@/hooks/fund";
import { useGetBenchmarkDailyReturn } from "@/hooks/benchmark";
import { formatNilToZero } from "@/util/numberFormatter";
import { BENCHMARK_COLOR, PORTFOLIO_COLOR } from "@/util/colors";
import getCumulativeReturns from "@/util/quant/cumulativeReturns";
import { equalNaN, equalNil } from "@/util/opt";
import { useGetCumulativeChartCalculateRangeDate } from "@/constant/statisticRangeCalculator/cumulativeCalculator";
import { getCalculateScaleRangeDate } from "@/constant/statisticRangeCalculator/rangeCalculator";

export const useGetScaleChartOptions = (id: string, scaleRange: string) => {
  const formatMessage = useFormatMessage();
  const baseInfo = useAppSelector((state: RootState) =>
    baseManagerSelector(state, id)
  );
  const scaleTrend = useMemo(
    () => fastProp("scaleTrend")(baseInfo),
    [baseInfo]
  );
  const { dates, scales } = useMemo(
    () => ({
      dates: fastProp("dates")(scaleTrend) || [],
      scales: fastProp("scales")(scaleTrend) || [],
      startManageFunds: fastProp("startManageFunds")(scaleTrend) || [],
      endManageFunds: fastProp("endManageFunds")(scaleTrend) || [],
    }),
    [scaleTrend]
  );
  const scalesAndDateMap = useMemo(
    () =>
      flow(
        mapIndexed((date: string, index: number) => [
          date,
          fastNth(index)(scales),
        ]),
        fromPairs
      )(dates),
    [dates, scales]
  );

  // const startManageDates = useMemo(() => {
  //   return flow(
  //     orderBy<string>("date", "asc"),
  //     map((item: Record<string, any>) => fastProp("date")(item))
  //   )(startManageFunds);
  // }, [startManageFunds]);
  // const endManageDates = useMemo(() => {
  //   return flow(
  //     orderBy<string>("date", "asc"),
  //     map((item: Record<string, any>) => fastProp("date")(item))
  //   )(endManageFunds);
  // }, [endManageFunds]);
  const rangeDates = useMemo(
    () => getCalculateScaleRangeDate(dates, scaleRange),
    [dates, scaleRange]
  );

  // const startRangeDates = useMemo(
  //   () =>
  //     getCalculateRangeDate({
  //       dates: startManageDates,
  //       range: scaleRange,
  //       tradingDateList,
  //       processedTradingDates,
  //     }),
  //   [startManageDates, scaleRange, tradingDateList, processedTradingDates]
  // );

  // const endRangeDates = useMemo(
  //   () =>
  //     getCalculateRangeDate({
  //       dates: endManageDates,
  //       range: scaleRange,
  //       tradingDateList,
  //       processedTradingDates,
  //     }),
  //   [endManageDates, scaleRange, tradingDateList, processedTradingDates]
  // );

  // const startRangeScales = useMemo(
  //   () => getRangeScales(startManageFunds, startRangeDates),
  //   [startManageFunds, startRangeDates]
  // );

  // const endRangeScales = useMemo(
  //   () => getRangeScales(endManageFunds, endManageDates),
  //   [endManageFunds, endManageDates]
  // );

  const rangeScales = useMemo(
    () =>
      map<string, number>((date) => fastProp(date)(scalesAndDateMap))(
        rangeDates
      ),
    [rangeDates, scalesAndDateMap]
  );
  const series = useMemo<SeriesOption[]>(
    () => [
      {
        type: "bar" as any,
        name: formatMessage("manageScale"),
        color: "#86B0CF",
        data: rangeScales,
        barMaxWidth: 40,
      },
      // {
      //   type: "line",
      //   color: "#FFA62A",
      //   lineStyle: {
      //     color: "transparent",
      //     width: 1.5,
      //   },
      //   symbolSize: 10,
      //   symbol: "circle",
      //   name: formatMessage("takeOverOrOpen"),
      //   data: new Array(size(startRangeScales)).fill(0),
      //   xAxisIndex: 1,
      //   yAxisIndex: 1,
      // },
      // {
      //   type: "line",
      //   color: "#FF0A00",
      //   lineStyle: {
      //     color: "transparent",
      //     width: 1.5,
      //   },
      //   symbolSize: 8,
      //   name: formatMessage("leaveOfficeOrWindUp"),
      //   data: new Array(size(endRangeScales)).fill(0),
      //   xAxisIndex: 2,
      //   yAxisIndex: 2,
      // },
    ],
    [formatMessage, rangeScales]
  );
  const options = useMemo<LineChartOpts["options"]>(
    () => getOptions(rangeDates, series),
    [rangeDates, series]
  );
  return { options, scaleData: rangeScales };
};

export const useGetFundTableData = (id: string) => {
  const baseInfo = useAppSelector((state: RootState) =>
    baseManagerSelector(state, id)
  );
  const fundManageInfo = useMemo(
    () => fastProp("fundManageInfo")(baseInfo),
    [baseInfo]
  );
  const { processedTradingDates, tradingDateList } = useAppSelector(
    (store) => store.tradingDates
  );
  return useMemo(() => {
    const tubeFund: FundManagerManageFundInfo[] = [];
    const outgoingFunds: FundManagerManageFundInfo[] = [];
    forEach((fund: FundManagerManageFundInfo) => {
      const fundDailyReturn = fastProp("fundDailyReturn")(fund);
      const fundDailyReturnValues: number[] = values(fundDailyReturn);
      const fundCumulativeReturn: number = last(
        getCumulativeReturns(fundDailyReturnValues)
      ) as number;
      const { startDate } = fund;
      const endDate = fastProp("endDate")(fund);
      const fundDuringReturn = getDuringReturns(
        processedTradingDates,
        tradingDateList,
        startDate,
        fundDailyReturn,
        endDate
      );
      if (fund.endDate) {
        outgoingFunds.push({
          ...fund,
          fundDailyReturn: fundCumulativeReturn,
          fundDuringReturn,
        });
      } else {
        tubeFund.push({
          ...fund,
          fundDailyReturn: fundCumulativeReturn,
          fundDuringReturn,
        });
      }
    })(fundManageInfo);
    return { tubeFund, outgoingFunds };
  }, [fundManageInfo, processedTradingDates, tradingDateList]);
};

export const useManagerStoreItem = (
  id: string,
  path: string,
  defaultValue?: string
): [string, (value: string) => any] => {
  const baseInfo = useAppSelector((state: RootState) =>
    baseManagerSelector(state, id)
  );
  const value = prop(path)(baseInfo) || defaultValue;
  const dispatch = useAppDispatch();
  const updateValue = useMemoizedFn((value: string) =>
    dispatch(
      updateManagerDetailInfo({
        id,
        key: path,
        value,
      })
    )
  );
  return [value, updateValue];
};

export const useGetPerformanceRankingTableData = (
  id: string,
  section: string
) => {
  const baseInfo = useAppSelector((state: RootState) =>
    baseManagerSelector(state, id)
  );
  const managerPerformanceInfo = useMemo(
    () => prop(`fundManagerPerformanceInfo.${section}`)(baseInfo),
    [baseInfo, section]
  );
  return useMemo(() => {
    const dataSource = flow(
      map<
        FundManagerManagePerformanceInfo,
        Omit<FundManagerManagePerformanceInfo, "factorRankView"> &
          FundManagerManagePerformanceInfo["factorRankView"]
      >((item) => ({
        name: item?.name,
        code: item?.code,
        fundId: item?.fundId,
        fundType: item?.fundType,
        ...item?.factorRankView,
      }))
    )(managerPerformanceInfo);

    return flow(
      orderBy(
        (item: FundManagerManagePerformanceInfo) =>
          equalNaN(item.value) || equalNil(item.value)
            ? Number.MIN_SAFE_INTEGER
            : item.value,
        "desc"
      ),
      take(10)
    )(dataSource);
  }, [managerPerformanceInfo]);
};

export const useGetRepresentFundYieldData = (
  id: string,
  benchmarkId: string,
  range: string
) => {
  const managerInfo = useAppSelector((state: RootState) =>
    currentManagerInfoSelector(state, id)
  );
  const majorFundId = fastProp("majorFund")(managerInfo);
  const majorFundDailyReturnsInfo = useGetFundDailyReturn(majorFundId);
  const benchmarkDailyReturnsInfo = useGetBenchmarkDailyReturn(benchmarkId);
  const dateGetter = useGetCumulativeChartCalculateRangeDate();
  const calcDates = useMemo(
    () => dateGetter(majorFundDailyReturnsInfo.dates, range),
    [dateGetter, majorFundDailyReturnsInfo.dates, range]
  );
  const chartData = useMemo(
    () =>
      map(({ name, dailyReturnsMap, color }) => ({
        name,
        color,
        dailyReturns: map<string, [string, number]>((date) => [
          date,
          formatNilToZero(fastProp(date)(dailyReturnsMap)),
        ])(calcDates),
      }))([
        assign({ color: PORTFOLIO_COLOR })(majorFundDailyReturnsInfo),
        assign({ color: BENCHMARK_COLOR })(benchmarkDailyReturnsInfo),
      ]),
    [benchmarkDailyReturnsInfo, calcDates, majorFundDailyReturnsInfo]
  );
  return {
    dates: calcDates,
    initDates: majorFundDailyReturnsInfo.dates,
    chartData,
  };
};
