import { useMemoizedFn } from "ahooks";
import { flow, identity, isEmpty, set, some, sumBy, update } from "lodash/fp";
import { useEffect, useMemo, useState } from "react";
import { Updater } from "./interface";
import { transferAssetAllocateToAsset } from "./constant";
import { AssetAllocate } from "@/model/portfolioAnalysis";
import { useTransferAssetToFundAsset } from "../../manualCreatePortfolio/hooks";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { validateWeightMaxError } from "@/components/portfolioCompoents/fundListTable";
import { map } from "lodash/fp";
import { ManualCreatePortfolio } from "../../manualCreatePortfolio/constant";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { updateFundConfiguration } from "@/store/createPortfolio";

export const useTransferAssetAllocateToManualPortfolioType = () => {
  const transfer = useTransferAssetToFundAsset();
  return useMemoizedFn((assetAllocate: AssetAllocate[]) =>
    map<AssetAllocate, ManualCreatePortfolio>((item) => {
      const assets = transfer(transferAssetAllocateToAsset(item.assets) as any);
      return {
        turnoverDate: fastProp("date")(item),
        weights: assets,
        sumWeight: sumBy("weight")(assets),
        errorField: item.error as any,
      };
    })(assetAllocate)
  );
};

export const useManageAllocateData = () => {
  const { assetAllocateData, manualPortfolioAllocateData } = useAppSelector(
    (state) => state.createPortfolio.fundConfiguration
  );
  const dispatch = useAppDispatch();
  const updateManualPortfolioAllocateData = useMemoizedFn(
    (updater: (data: ManualCreatePortfolio[]) => ManualCreatePortfolio[]) =>
      dispatch(updateFundConfiguration("manualPortfolioAllocateData", updater))
  );
  const transfer = useTransferAssetAllocateToManualPortfolioType();
  const originFundAsset = useMemo<ManualCreatePortfolio[]>(
    () => transfer(assetAllocateData),
    [assetAllocateData, transfer]
  );
  useEffect(() => {
    if (!isEmpty(originFundAsset) && isEmpty(manualPortfolioAllocateData))
      updateManualPortfolioAllocateData(() => originFundAsset);
  }, [
    manualPortfolioAllocateData,
    originFundAsset,
    updateManualPortfolioAllocateData,
  ]);
  const onUpdateAssets = useMemoizedFn(
    (index: number) => (updater: Updater) =>
      updateManualPortfolioAllocateData(
        update(index, update("weights", updater))
      )
  );
  return {
    onUpdateAssets,
    originFundAsset,
    manualPortfolioAllocateData,
  };
};

export const useManageError = (
  originFundAsset: ManualCreatePortfolio[],
  manualPortfolioAllocateData: ManualCreatePortfolio[]
) => {
  const [errorField, setErrorField] = useState({});
  useEffect(() => {
    mapIndexed(
      ({ weights, errorField }: ManualCreatePortfolio, index: number) => {
        const positionWeights = sumBy("weight")(weights);
        const positionOriginWeights = flow(
          fastNth(index),
          fastProp("weights"),
          sumBy("weight")
        )(originFundAsset);
        if (errorField) {
          setErrorField(
            set(
              index,
              validateWeightMaxError(positionWeights) ||
                positionWeights === positionOriginWeights
            )
          );
        } else {
          setErrorField(set(index, validateWeightMaxError(positionWeights)));
        }
      }
    )(manualPortfolioAllocateData);
  }, [manualPortfolioAllocateData, originFundAsset]);
  return useMemo(
    () => ({
      errorField,
      error: some(identity)(errorField),
    }),
    [errorField]
  );
};
