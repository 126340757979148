import React, { useState } from "react";
import { Layout, Row, Col } from "antd";
import { Outlet, useMatch, Navigate } from "react-router-dom";
import BetalphaIcon from "@/assets/betalphaIcon.svg";
import { useAppSelector } from "@/hooks/redux";
import { CommonApiProvider } from "@/providers/commonApiProvider";
import { PlatformNavigationProvider } from "@/providers/platformNavigationProvider";
import { userSelector } from "@/selectors/user";
import style from "./index.module.less";
import SiderMenu from "./menu1";
import ToggleFold from "./components/ToggleFold";
// import { useFormatMessage } from "@/util/formatMessage";
import OperateArea from "./operateArea";
import { NameIcon } from "@/components/icons";

const IconStyle = {
  color: "rgb(255,248,255)",
  width: 108,
  height: 23,
  display: "inline-block",
};

const { Header, Sider } = Layout;

const Manage = (): JSX.Element => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [breadcrumb, setBreadcrumb] = useState<string>("dataBoard");
  // const formatMessage = useFormatMessage();
  const user = useAppSelector(userSelector);
  const isMath = useMatch("/manage");
  if (isMath) return <Navigate to="/manage/databoard" />;
  return (
    <CommonApiProvider>
      <Layout className={style.RootLayout}>
        <Sider
          width={210}
          className={style.Slider}
          collapsible
          trigger={null}
          collapsed={collapsed}
        >
          {collapsed ? (
            <div className={style.Logo}>
              <img src={BetalphaIcon} />
            </div>
          ) : (
            <div className={style.noCollapsedLogo}>
              <div className={style.noCollapsedLogoText}>
                <div>
                  <img src={BetalphaIcon} />
                </div>
                <div className={style.TextStyle}>
                  {/* <div className={style.Zh}>
                    {formatMessage("advisoryPlatform")}
                  </div> */}
                  <p className={style.NameIcon}>
                    <NameIcon style={IconStyle} />
                  </p>
                </div>
              </div>
              <div className={style.Line} />
            </div>
          )}
          <SiderMenu selectTabs={setBreadcrumb} collapsed={collapsed} />
        </Sider>
        <Layout className={style.RightLayout}>
          <div className={style.RightLayoutScrollWidth}>
            <Header className={style.LayoutHeader}>
              <Row>
                <Col>
                  <ToggleFold
                    className={style.FoldOutlined}
                    setCollapsed={setCollapsed}
                    collapsed={collapsed}
                  />
                </Col>
                <Col className={style.PullRight}>
                  <div className={style.PullRightWrap}>
                    <span className={style.SystemTip}>
                      本系统涉及的全部数据仅供内部使用，请勿外传。
                    </span>
                    <span className={style.Username}>{user?.username}</span>
                    <OperateArea />
                  </div>
                </Col>
              </Row>
            </Header>
            <PlatformNavigationProvider rootKey={breadcrumb}>
              <Outlet />
            </PlatformNavigationProvider>
          </div>
        </Layout>
      </Layout>
    </CommonApiProvider>
  );
};

export default React.memo(Manage);
