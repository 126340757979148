import { PreferredFundParam } from "@/model/preferredFund";
import { PromiseType } from "@/model/promise";
import { get, post } from "@/util/axios";

//优选基金日期
export const getPreferredDate = (): PromiseType<string[]> =>
  get("/preferred/fund/date");

//优选列表
export const getPreferredFundList = (body: PreferredFundParam) =>
  post("/preferred/fund/filter", body);

//历史优选跟踪
export const getHistoryTrack = (fundType: string) =>
  get(`/preferred/fund/return/${fundType}`);
