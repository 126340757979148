import { weightRoundTo } from "@/util/formatter";
import getMessage from "@/util/getMessage";
import { floorTo } from "@/util/numberFormatter";
import {
  concat,
  difference,
  filter,
  flow,
  identity,
  isEmpty,
  last,
  map,
  minBy,
  omit,
  size,
  some,
  toArray,
} from "lodash/fp";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { fastProp, normalize } from "@/util/opt";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const manualCreatePortfolioSteps = {
  fundAllocation: 0,
  backTestingAllocation: 1,
  savePortfolio: 2,
  steps: [
    {
      message: getMessage("allocationFund"),
    },
    {
      message: getMessage("allocationbackTest"),
    },
    {
      message: getMessage("savePortfolio"),
    },
  ],
};

export type Asset = {
  fundId: string;
  weight: number;
  netValueStartDate: number;
  tradingDay: string;
  maturityDate: string;
  netValueStartDateError: boolean;
  maturityDateError: boolean;
};

export type ErrorField = {
  dateEmptyError: boolean;
  fundEmptyError: boolean;
  fundWeightZeroError: boolean;
  latestNetUnitValueZeroError: boolean;
  fundWeightMaxError: boolean;
  netValueStartDateZeroError: boolean;
  netValueStartDateError: boolean;
  maturityDateError: boolean;
};
export const getDefaultErrorField = (): ErrorField => ({
  dateEmptyError: true,
  fundEmptyError: true,
  fundWeightZeroError: true,
  latestNetUnitValueZeroError: false,
  fundWeightMaxError: false,
  netValueStartDateZeroError: false,
  netValueStartDateError: false,
  maturityDateError: false,
});
export type ManualCreatePortfolio = {
  turnoverDate: string | null;
  weights: Asset[];
  sumWeight: number;
  errorField: ErrorField;
};
export const generateManualCreatePortfolioData = (turnoverDate?: string) => ({
  turnoverDate: turnoverDate || null,
  weights: [],
  sumWeight: 0,
  errorField: getDefaultErrorField(),
});

export const validateManualCreatePortfolioDataError = (
  data: ManualCreatePortfolio
) => some(identity)(data.errorField);

export const setEqualWeight = (data: Asset[]) => {
  const dataSize = size(data);
  if (dataSize) {
    const avgWeight = floorTo(4)(1 / dataSize);
    return flow(
      map<Asset, Asset>((v) => ({
        ...v,
        weight: avgWeight,
      })),
      weightRoundTo(1, "weight")
    )(data);
  }
};

export const checkDisabledRangeDate =
  (startDate: string | null, endDate: string | null) => (today: string) => {
    const valueDay = dayjs(today);
    if (!startDate && !endDate) return false;
    let startDateDisabled = false;
    if (startDate) startDateDisabled = valueDay.isSameOrBefore(startDate);
    let endDateDisabled = false;
    if (endDate) endDateDisabled = valueDay.isSameOrAfter(endDate);
    return startDateDisabled || endDateDisabled;
  };

export const addFundWeights =
  (generateAsset: (ids: string[]) => Asset[], changedFundIds: string[]) =>
  (originalWeights: Asset[]) => {
    if (isEmpty(originalWeights)) return generateAsset(changedFundIds);
    const originalWeightsMap = normalize<Asset>("fundId")(originalWeights);
    const originalFundIds = map("fundId")(originalWeights);
    const addIds = difference(changedFundIds)(originalFundIds);
    const removeIds = difference(originalFundIds)(changedFundIds);
    return flow(omit(removeIds), toArray, (data) =>
      concat(data)(generateAsset(addIds))
    )(originalWeightsMap);
  };

export const getLastMaxNavDate = (data: ManualCreatePortfolio[]) =>
  flow(
    last,
    fastProp("weights"),
    filter((v: Asset) => !fastProp("maturityDate")(v)),
    minBy("tradingDay"),
    fastProp("tradingDay")
  )(data);

export const getValidFunds = (
  turnoverDate: string | null,
  weights: Asset[]
): boolean => {
  const validFunds = filter((v: Asset) => {
    if (!v.maturityDate) return false;
    if (turnoverDate && v.maturityDate && v.maturityDate >= turnoverDate)
      return true;
    return false;
  })(weights);

  return !isEmpty(validFunds);
};
