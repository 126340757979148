import React, { useEffect } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import TaskResult from "@/components/taskResult";
import style from "./index.module.less";
import BasicInfo from "./components/basicInfo";
import RangeOfBenefits from "./components/rangeOfBenefits";
import YieldAndRiskChart from "./components/yieldAndRiskChart";
import FundScale from "./components/fundScale";
import CategoryAssetsDistribution from "./components/categoryAssetsDistribution";
import StockIndustryDistribution from "./components/stockIndustryDistribution";
import Top10Stocks from "./components/top10Stocks";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import Brinson from "./components/brinsonAttribution";
import { map, prop } from "lodash/fp";
import { clearFundCompareResult } from "@/store/compareManage";
import { fetchFundDetailIndustryTrendDate } from "@/store/fundDetailSlice";

export default React.memo<{
  fundIds: string[];
}>(({ fundIds }) => {
  const task = useAppSelector(
    prop("compareManage.fundCompare.compareFundProgress")
  );
  const dispatch = useAppDispatch();
  useEffect(
    () => () => {
      dispatch(clearFundCompareResult());
    },
    [dispatch]
  );

  useEffect(() => {
    map((fundId: string) => {
      dispatch(fetchFundDetailIndustryTrendDate(fundId));
    })(fundIds);
  }, [dispatch, fundIds]);

  const formatMessage = useFormatMessage();
  return task === undefined ? null : (
    <TaskResult className={style.TaskResult} task={task}>
      <div className={style.Result}>
        <h2 className={style.ResultTitle}>{formatMessage("compareResult")}</h2>
        <BasicInfo className={style.Card} />
        <RangeOfBenefits className={style.Card} />
        <YieldAndRiskChart className={style.Card} />
        <FundScale className={style.Card} />
        <CategoryAssetsDistribution className={style.Card} fundIds={fundIds} />
        <StockIndustryDistribution className={style.Card} fundIds={fundIds} />
        <Top10Stocks fundIds={fundIds} className={style.Card} />
        <Brinson className={style.Card} fundIds={fundIds} />
      </div>
    </TaskResult>
  );
});
