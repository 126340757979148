import {
  FROM_CREATION,
  RECENT_FIVE_YEAR,
  RECENT_THREE_YEAR,
  RECENT_TWO_YEAR,
  RECENT_YEAR,
  statisticRange,
} from "@/constant/statisticRange";
import { useAppDispatch } from "@/hooks/redux";
import {
  fetchGetCompanyScaleRank,
  fetchGetCompanyScaleTrend,
} from "@/store/fundCompanyDetail";
import { useFormatMessage } from "@/util/formatMessage";
import { useMemoizedFn } from "ahooks";
import { Card, Col, Radio, RadioChangeEvent, Row, Table } from "antd";
import { flow, map, pick, toArray } from "lodash/fp";
import React, { useEffect, useMemo, useState } from "react";
import style from "./index.module.less";
import BarChart from "@/echarts/barChart";
import { useScaleTrendChart, useScaleTrendTableData } from "../hook";
import { getScaleTrendColumns } from "../constant";
import cn from "classnames";
import LoadingComponent from "@/components/LoadingComponent";

type scaleTrendProps = {
  id: string;
};

const options = flow(
  pick([
    RECENT_YEAR,
    RECENT_TWO_YEAR,
    RECENT_THREE_YEAR,
    RECENT_FIVE_YEAR,
    FROM_CREATION,
  ]),
  toArray
)(statisticRange);

export default React.memo<scaleTrendProps>(({ id }) => {
  const formatMessage = useFormatMessage();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchGetCompanyScaleTrend(id));
    dispatch(fetchGetCompanyScaleRank(id));
  }, [dispatch, id]);

  const [dataRange, setDataRange] = useState(FROM_CREATION);
  const onChangeDataRange = useMemoizedFn((e: RadioChangeEvent) => {
    setDataRange(e.target.value);
  });
  const chartDataSource = useScaleTrendChart(id, dataRange);

  const columns = useMemo(
    () => getScaleTrendColumns(formatMessage),
    [formatMessage]
  );
  const { tableData, scroll } = useScaleTrendTableData(id);
  return (
    <Card
      size="small"
      bordered={false}
      title={formatMessage("funCompanyTotalTrendChange")}
      className={style.CompanyCommonCard}
    >
      <Row gutter={32}>
        <Col span={12}>
          <LoadingComponent actions={fetchGetCompanyScaleTrend}>
            <div className={style.TrendDataRange}>
              <Radio.Group
                size="small"
                value={dataRange}
                onChange={onChangeDataRange}
              >
                {map(({ id, message }) => (
                  <Radio.Button key={id} value={id}>
                    {formatMessage(message)}
                  </Radio.Button>
                ))(options)}
              </Radio.Group>
            </div>
            <div className={style.BarChart}>
              <span className={style.BarChartUnitTip}>{`（${formatMessage(
                "million"
              )}）`}</span>
              <BarChart showDataZoom height={360} options={chartDataSource} />
            </div>
          </LoadingComponent>
        </Col>
        <Col span={12}>
          <LoadingComponent actions={fetchGetCompanyScaleRank}>
            <Table
              className={cn(style.TableStyle, style.TopNone)}
              columns={columns}
              pagination={false}
              dataSource={tableData}
              scroll={scroll}
            />
          </LoadingComponent>
        </Col>
      </Row>
    </Card>
  );
});
