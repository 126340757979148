import { Button, Form, message, Popconfirm, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMemoizedFn } from "ahooks";
import dayjs from "dayjs";
import { v4 } from "uuid";
import { debounce, isEmpty, map } from "lodash/fp";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useFormatMessage } from "@/util/formatMessage";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  deleteCustomerAccount,
  fetchCustomerInfo,
  fetchCustomerList,
  saveCustomerInfo,
  saveCustomerBasicInfo,
} from "@/store/customerList";
import { subAccountNameProp } from "@/model/customer";

import LoadingComponent from "@/components/LoadingComponent";
import { customerInfoSelector } from "./selector";
import BasicsInfo from "./basicsInfo";
import DemandInfo from "./demandInfo";
import AssetInfo from "./assetInfo";
import CustomerTag from "./components/customerTag";
import { checkAssetInfo, formatData, validateAssetInfo } from "./constant";
import style from "./index.module.less";

type AddCustomerProp = {
  customerId: number;
  accountId: string;
};

export default React.memo<AddCustomerProp>(({ customerId, accountId }) => {
  const formatMessage = useFormatMessage();
  const [showTag, setShowTag] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string[]>([]);
  const [assetInfo, setAssetInfo] = useState<subAccountNameProp[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [method, setMethod] = useState(true);
  const { stackBack } = useContext(PlatformNavigationContext);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const customerInfo = useAppSelector((state) =>
    customerInfoSelector(state, customerId)
  );

  const onDelete = useMemoizedFn((id: number) => {
    dispatch(deleteCustomerAccount(id))
      .unwrap()
      .then(() => {
        message.success(formatMessage("deleteSuccess"));
        stackBack();
        dispatch(fetchCustomerList());
      });
  });
  useEffect(() => {
    if (customerId) {
      setDisabled(true);
      dispatch(fetchCustomerInfo(customerId));
    }
  }, [customerId, dispatch]);

  useEffect(() => {
    if (customerInfo) {
      form.setFieldsValue({
        ...customerInfo,
        birthday: customerInfo?.birthday ? dayjs(customerInfo?.birthday) : null,
      });

      const uploadAssets = map((item: subAccountNameProp) => {
        const uuid = v4();
        return { ...item, id: uuid };
      })(customerInfo?.uploadAssets || []);
      setAssetInfo(uploadAssets);
      setSelectedTag(customerInfo?.allTags || []);
      setShowTag(customerInfo?.displayTags || []);
      setMethod(customerInfo?.enterAccount || true);
    }
  }, [customerInfo, form]);

  const saveCustomer = useMemoizedFn((isUpdate: boolean) => {
    form.validateFields().then((value) => {
      if (method) {
        if (!validateAssetInfo(assetInfo)) {
          message.warning(formatMessage("pleaseEnterCorrectData"));
          return false;
        }
      }
      setDisabled(true);

      dispatch(
        saveCustomerBasicInfo({
          ...value,
          birthday: value?.birthday
            ? dayjs(value?.birthday).format("YYYY-MM-DD")
            : null,
          displayTags: showTag,
          allTags: selectedTag,
          customerId: customerId || null,
        })
      )
        .unwrap()
        .then((res: any) => {
          setDisabled(false);
          if (isUpdate) {
            dispatch(
              saveCustomerInfo({
                accountId: accountId || null,
                overwrite: true,
                customerId: res || null,
                enterAccount: method,
                uploadAssets: formatData(assetInfo),
              })
            ).unwrap();
          } else {
            message.success(formatMessage("saveSucceeded"));
            dispatch(fetchCustomerList());
          }
          stackBack();
        });
    });
  });

  const onSave = useMemoizedFn(() => {
    saveCustomer(accountId ? false : isEmpty(assetInfo) ? false : true);
  });
  return (
    <div className={style.AddCustomerContainer}>
      <LoadingComponent actions={fetchCustomerInfo}>
        {customerId && (
          <div className={style.Header}>
            <Space>
              <Button
                icon={<EditOutlined />}
                onClick={() => setDisabled(false)}
              >
                {formatMessage("edit")}
              </Button>
              <Popconfirm
                title={formatMessage("delectCustomerTip")}
                onConfirm={() => onDelete(customerId)}
              >
                <Button danger icon={<DeleteOutlined />}>
                  {formatMessage("deleteCustomer")}
                </Button>
              </Popconfirm>
            </Space>
          </div>
        )}

        <div className={style.Content}>
          <Form
            layout="inline"
            labelCol={{ flex: "70px" }}
            colon={false}
            labelAlign="left"
            form={form}
          >
            <BasicsInfo disabled={disabled} />
            <DemandInfo disabled={disabled} />
            {!accountId && (
              <AssetInfo
                tableData={assetInfo}
                setTableData={setAssetInfo as any}
                disabled={disabled}
                method={method}
                setMethod={(val: boolean) => {
                  setMethod(val);
                  setAssetInfo([]);
                }}
              />
            )}
            <CustomerTag
              showTag={showTag}
              setShowTag={setShowTag}
              disabled={disabled}
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag as any}
            />
          </Form>
        </div>
      </LoadingComponent>
      <div className={style.LayoutFooter}>
        <Space>
          <Button className={style.OperateButton} onClick={stackBack}>
            {formatMessage("cancel")}
          </Button>
          <Button
            className={style.OperateButton}
            type="primary"
            disabled={disabled || !checkAssetInfo(method, assetInfo)}
            onClick={debounce(500)(onSave)}
          >
            {formatMessage("saveCustomer")}
          </Button>
        </Space>
      </div>
    </div>
  );
});
