import { useEffect } from "react";
import { size, flow, keys, difference } from "lodash/fp";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { DatePeriodName } from "@/util/business-core/datePeriod";
import { fetchDatePeriodReturnAction } from "./reducer";
import { fundDatePeriodReturnSelector } from "./selector";

export const useGetFundDatePeriodReturn = (
  ids: string[],
  datePeriod: DatePeriodName
) => {
  const dispatch = useAppDispatch();
  const fundDatePeriodReturns = useAppSelector(fundDatePeriodReturnSelector);
  const currentPeriodReturns = fundDatePeriodReturns?.[datePeriod];

  useEffect(() => {
    const differenceIds = flow(keys, difference(ids))(currentPeriodReturns);
    if (size(differenceIds) && datePeriod) {
      dispatch(
        fetchDatePeriodReturnAction({
          fundIds: differenceIds,
          section: datePeriod,
        })
      );
    }
  }, [ids, currentPeriodReturns, datePeriod, dispatch]);

  return currentPeriodReturns;
};
