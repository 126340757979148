import { RootState } from "@/store";
import { fastHas, fastProp } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { pick, prop } from "lodash/fp";
import { assetPortfolioMapSelector } from "@/selectors/portfolioList";
import getMessage from "@/util/getMessage";

export const portfolioAnalysisSelector = createSelector(
  (state: RootState) => state.portfolioAnalysis,
  (_: RootState, id: string) => id,
  (portfolioAnalysis, id) => fastProp(id)(portfolioAnalysis) || {}
);

export const analysisBasicInfoSelector = createSelector(
  portfolioAnalysisSelector,
  assetPortfolioMapSelector,
  (portfolioAnalysis, assetPortfolioMap) => {
    const id = prop(["portfolioSummary", "id"])(portfolioAnalysis);
    const portfolioName =
      (fastHas(id)(assetPortfolioMap) &&
        prop([id, "name"])(assetPortfolioMap)) ||
      getMessage("backtestingPortfolio");

    return {
      ...fastProp("portfolioSummary")(portfolioAnalysis),
      portfolioName,
    };
  }
);

export const factorRatioSelector = createSelector(
  portfolioAnalysisSelector,
  (
    _: RootState,
    id: string,
    rangeAndFactors: { range: string; factors: string[] }
  ) => rangeAndFactors,
  (portfolioAnalysis, rangeAndFactors) => {
    const factorRatio = fastProp("factorRatio")(portfolioAnalysis);
    return pick(rangeAndFactors.factors)(
      fastProp(rangeAndFactors.range)(factorRatio)
    );
  }
);
