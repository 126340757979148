import { TaskData } from "@/util/socket";

export interface FundBasicBody {
  fundId: string;
  userId: string;
}
export interface FundDetailIndustryBody {
  fundId: string;
  sectorId: string;
}

export interface FundDetailETFIndustryBody {
  fundId: string;
  sectorId: string;
  date: string;
}

export interface FundStockPositionBody {
  fundId: string;
  industryId: string;
}

export interface FundDetailManagersBody {
  fundId: string;
  id: string;
}

export interface FundBasicInfoType {
  fundId: string;
  code: string;
  name: string;
  foundDate: string;
  firstInvestType: string;
  fundType: string;
  purchaseStatus: string;
  redemptionStatus: string;
  benchmark: string;
  [key: string]: unknown;
}

export interface FundRatingTrendType {
  reportDate: string;
  bfRating: string;
}

export interface FundScaleTrendType {
  reportDate: string;
  scale: string;
}

export interface NetValuesType {
  tradingDate: string;
  navUnit: string;
  navCum: string;
}
export interface FactorRankValueType {
  factorId: string;
  value: string;
  rank: string;
  size: string;
}
export interface FundManagerRatings {
  managerId: string;
  fundManagerName: string;
  rating: number;
}
export interface FundSummaryResult {
  fundSummaryProgress?: TaskData | undefined;
  fundSummaryTask: Record<string, any>;
}
export interface FundDetailReturn {
  tradingDates: string[];
  fundDailyReturn: number[];
  sameTypeDailyReturn: number[];
}
export interface StockWeightListItem {
  stockCode: string;
  weight: number;
  industryId: string;
}
export interface FundDetailStockPositionTrend {
  fundId: string;
  reportDate: string;
  stockWeightList: StockWeightListItem[];
  concentrationRatio: string;
}
export interface FundDetailIndustryWeight {
  industryId: string;
  weight: string;
}
export interface FundDetailIndustryTrend {
  [key: string]: {
    [key: string]: number;
  };
}
export interface FundDetailIndustryTrendDate {
  isETF: boolean;
  dates: string[];
}
export interface FundDetailSectorTrend {
  fundId: string;
  reportDate: string;
  stocktonav: number;
  bondtonav: number;
  cashtonav: number;
  fundtonav: number;
  othertonav: number;
}
export interface FundBasicType {
  basic: FundBasicInfoType;
  stabilityOfStyle: string;
  fundTheme: string[];
  fundStyle: string;
  fundRatingTrend: FundRatingTrendType[];
  fundScaleTrend: FundScaleTrendType[];
  netValues: NetValuesType[];
  factorRankValue: FactorRankValueType[];
  fundCompany: {
    [key: string]: string;
  };
  fundManagerRatings: FundManagerRatings[];
  fundSummaryProgress?: TaskData | undefined;
  fundSummaryTask: Record<string, any>;
  fundDetailReturn: FundDetailReturn;
  fundDetailStockPositionTrend: FundDetailStockPositionTrend[];
  fundDetailIndustryTrend: FundDetailIndustryTrend[];
  fundDetailSectorTrend: FundDetailSectorTrend[];
  selectedSectorId: string;
  fundAttributionBrinsonProgress?: TaskData | undefined;
  fundAttributionBrinsonResult: Record<string, any>;
  netValueAttributionProgress?: TaskData | undefined;
  netValueAttribution: Record<string, any>;
  comprehensivePerformance: ComprehensivePerformanceParam;
  industryAbilityEvaluation: IndustryAbilityEvaluationParam;
  enableFundEvaluate: boolean;
  marketCapabilityDetail: MarketCapabilityDetailParam;
  riskDetail: RiskDetailParam;
}

export interface benchmarkType {
  benchmarkId: string;
  type: string;
  weight: number;
}

export interface FundAttributionBrinsonBody {
  benchmarks: benchmarkType[];
  assetId: string;
  from: string;
  to: string;
  sectorCategoryId: string;
}

export interface ETFFundAttributionBrinsonBody {
  benchmarkId: string;
  portfolioId: string;
  from: string;
  to: string;
  sectorCategoryId: string;
  attributionMethod: string;
  usingFixedBenchmark: boolean;
  attributionMeasurement: string;
}

export interface FundAttributionBrinsonFundSummaryResult {
  fundAttributionBrinsonProgress?: TaskData | undefined;
  FundAttributionBrinsonTask: Record<string, any>;
}

export interface subAttributionsType {
  dailyAllocationAttributions: number[];
  dailySelectionAttributions: number[];
  dailyTimingAttributions: number[];
  id: string;
}

export interface benchmarksTypes {
  benchmarkId: string;
  weight: number;
}

export interface defaultBenchmarksTypes {
  type: string;
  defaultBenchmarkCode: string;
  weight: number;
}

export interface periodType {
  date: string;
  periodStartIndex: number;
  periodEndIndex: number;
}

export interface treeDataType {
  name: string;
  value: number;
  children?: treeDataType[];
}

export interface dateType {
  date: string;
  periodStartIndex: number;
}

export interface currentFundManagerType {
  id: string;
  name: string;
  rating: number;
  startDate: string;
  introduction: string;
  url: string;
}

export interface historyFundManagerType {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
}

export interface FundDetailFundManagers {
  currentFundManagers: currentFundManagerType[];
  historyFundManagers: historyFundManagerType[];
}

export interface FundDetailManagerReturn {
  date: string[];
  dailyReturn: number[];
}

export interface NewsBody {
  fundCode: string;
  pageNum: number;
  fundId: string;
  pageSize: number;
  from: number | undefined;
  to: number | undefined;
  keyWord: string;
}
export interface AnnouncementBody {
  fundCode: string;
  pageNum: number;
  label: string;
  fundId: string;
  pageSize: number;
  from: number | undefined;
  to: number | undefined;
  keyWord: string;
}
export interface newsItem {
  title: string;
  contentUrl: string;
  createTime: string;
}

export interface NewsInfoBody {
  result: newsItem[];
  totalSize: number;
}

export interface AnnouncementItem {
  id: string;
  title: string;
  contentUrl: string;
  label: string;
  createTime: string;
}

export interface AnnouncementInfoBody {
  result: AnnouncementItem[];
  totalSize: number;
}

export interface FundManagerImgBody {
  fundId: string;
  managerId: string;
  url: string;
}

export interface FundNetValueAttributionParams {
  fundId: string;
  section: string;
  factorModelId: string;
}

export interface factorAttributionsBody {
  factorId: string;
  attributions: number[];
}
export interface factorsCumulativeReturnBody {
  factorId: string;
  cumulativeAttribution: number[];
}

export interface monthlyAttributionsBody {
  dataMissing: string;
  portfolioCumulativeReturn: number;
  factorsCumulativeReturn: factorsCumulativeReturnBody[];
}

export interface factorsCumulativeReturnParam {
  factorId: string;
  cumulativeReturn: number;
}
export interface FundNetValueAttributionBody {
  maxDrawdown: number;
  sharpeRatio: number;
  portfolioCumulativeReturns: number[];
  factorAttributions: factorAttributionsBody[];
  dates: string[];
  months: string[];
  monthlyAttributions: monthlyAttributionsBody[];
  factorModelId: string;
  monthTradingDateNum: number[];
}

export interface factorsItem {
  factorId: string;
  factorName: string;
}
export interface netValueAttributionModalBody {
  factorModelId: string;
  factorModelName: string;
  factors: factorsItem[];
}

export interface EvaluationReportInfoBody {
  fundCode: string;
  fundName: string;
  fundEvaluation: string;
  fundManage: string;
  fundType: string;
  foundDate: string;
  reportDate: string;
  fundCompany: string;
  fundScale: number;
  fundTypeName: string;
  returns: {
    fundReturns: number[];
    benchmarkReturns: number[];
    dates: string[];
  };
}
export interface EvaluationReportAbilitySummaryBody {
  totalRating: number;
  factorRatings: {
    factorCode: string;
    factorName: string;
    fundFactorRating: number;
    sameTypeFundFactorRating: number;
  }[];
}

export enum LEVEL_ENUM {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}
export interface EvaluationReportRiskSummaryBody {
  totalRisk: LEVEL_ENUM;
  styleRisk: {
    riskScore: number;
    riskLevel: LEVEL_ENUM;
    fundStyle: string;
  };
  industryRisk: {
    riskScore: number;
    riskLevel: LEVEL_ENUM;
    top3Industries: string[];
    industryCongestion: LEVEL_ENUM;
    industryProsperity: LEVEL_ENUM;
  };
  mainFactorRisk: {
    riskScore: number;
    riskLevel: LEVEL_ENUM;
    factorCrowding: Record<string, LEVEL_ENUM>;
    factorValues: FactorValuesParam[];
    comprehensiveFactorCongestion: string;
    top2FactorCongestion: Record<string, string>;
  };
}

export interface SameTypeRankParam {
  rank: string;
  count: string;
}

export interface FactorPerformanceParam {
  factorCode: string;
  fundFactorValue: number;
  benchmarkFactorValue: number;
  sameTypeFactorRank: SameTypeRankParam;
}

export interface ComprehensivePerformanceSummary {
  performanceRating: string;
  riskLevel: string;
  avgReturn: string;
  winRate: string;
  holdingExperience: string;
  comprehensivePerformanceRank: number;
}

export interface ComprehensivePerformanceParam {
  factorPerformance: FactorPerformanceParam[];
  summary: ComprehensivePerformanceSummary;
}

export interface AllocationContributeParam {
  fundContribute: string;
  sameTypeContribute: string;
}

export interface IndustryAbilityEvaluationParam {
  industryAllocationRating: string;
  sameTypeRank: SameTypeRankParam;
  top3IndustryInEquity: number;
  top3IndustryWeightRating: string;
  preferenceAllocation: string;
  industryAllocationCompareWithSameType: string;
  brinsonAllocationContribute: AllocationContributeParam;
  factorModelAllocationContribute: AllocationContributeParam;
}

export interface FactorSelectionType {
  fundValue: number;
  sameTypeAvg: number;
}
export interface StockSelectionAbilityBody {
  abilityRating: number;
  rank: number;
  sameTypeCount: number;
  top10StockWeightAvg: number;
  positionStockDisperseGrade: LEVEL_ENUM;
  topStockRetainRatio: number;
  topStockAdjustGrade: LEVEL_ENUM;
  selectionAbilitySummary: LEVEL_ENUM;
  brinsonSelection: FactorSelectionType;
  multiFactorSelection: FactorSelectionType;
}

export interface TradeAbility {
  turnoverAbility: number;
  dates: string[];
  extraReturn: number[];
  extraReturnSameAvg: number[];
}

export interface ValueAndRank {
  value: number;
  sameTypeRank: LEVEL_ENUM;
}

export interface NewStockTrendParam {
  dates: string[];
  newStockReturn: number[];
  newStockReturnAvg: number[];
  newStockRatio: number[];
  newStockRatioAvg: number[];
}

export interface ExtendNewStocksAbility {
  desireGrade: LEVEL_ENUM;
  newStockQuality: LEVEL_ENUM;
  newStockRatio: ValueAndRank;
  newStockReturnOfMarket: ValueAndRank;
  newStockTrend: NewStockTrendParam;
}
export interface TurnoverImprovementAbilityBody {
  abilityRating: number;
  rank: number;
  sameTypeCount: number;
  tradeAbility: TradeAbility; //基金经理隐形交易能力
  extendNewStocksAbility: ExtendNewStocksAbility; //扩展意愿和能力
}

export enum AbilityLevel {
  GOOD = "GOOD",
  ORDINARY = "ORDINARY",
  POOR = "POOR",
}

export interface MarketRankDetailsParam {
  sectionReturn: number;
  startDate: string;
  endDate: string;
  excessReturn: number;
  sameTypeRank: number;
}

export interface MarketCapabilityDetailParam {
  marketCapabilitySameTypeRank: number;
  sameTypeFundCount: number;
  marketCapabilityLevel: string;
  defensiveAbilityLevel: string;
  offensiveAbilityLevel: string;
  risingMarketCaptureRank: number;
  fallingMarketCaptureRank: number;
  marketRisingRankingDetails: MarketRankDetailsParam[];
  marketFallRankingDetails: MarketRankDetailsParam[];
}

export interface StyleRiskDetailParam {
  curFundStyleValue: number;
  historyFundStyleValue: number;
}

export interface IndustryRiskDetailParam {
  industryCode: string;
  industryName: string;
  industryCongestion: number;
  industryProsperity: number;
}

export interface FactorValuesParam {
  factorCode: string;
  factorValue: number;
  factorName: string;
}

export interface RiskDetailParam {
  styleRiskDetail: StyleRiskDetailParam;
  industryRiskDetail: IndustryRiskDetailParam[];
}

export interface ManagerEvaluationBody {
  fundManagerInfos: {
    name: string;
    duration: string; // 任职时长
    representativeFund: string; // 代表基金
    tenureReturn: number; // 任期回报
    expertiseArea: string; // 擅长领域
    manageScale: number; // 管理规模
    managerFactorRatings: {
      factorCode: string;
      factorName: string;
      rating: number;
    }[];
  }[];
  fundScoreTrend: // 基金历史基金经理变动以及评分变化
  {
    date: string;
    score: number;
  }[];
  fundManagerRecords: Record<string, string[]>;
}
