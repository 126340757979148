import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { fastNth, fastProp, mapIndexed, mapValuesIndexed } from "@/util/opt";
import {
  flow,
  isEmpty,
  first,
  prop,
  sum,
  toArray,
  values,
  orderBy,
} from "lodash/fp";
import { useEffect, useMemo } from "react";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { useManagerStoreItem } from "../manageFundInfo/hooks";
import { baseManagerSelector } from "../selectors";
import { colors } from "@/util/colors";
import { PieSeriesOption } from "echarts";
import { allStocksMapSelector } from "@/selectors/stocks";
import { addZeroIndex } from "@/util/numberFormatter";
import { useFormatMessage } from "@/util/formatMessage";
import { stockPositionInfo } from "../interface";
import getMessage from "@/util/getMessage";
import { defaultSectorId } from "@/constant/sectors";

export const useManageIndustrySectorId = (id: string) => {
  return useManagerStoreItem(
    id,
    "positionInfo.industrySectorId",
    defaultSectorId
  );
};

export const useManageReportDate = (
  id: string,
  path: string
): [string, (value: string) => any] => {
  const baseManager = useAppSelector((state: RootState) =>
    baseManagerSelector(state, id)
  );
  const defaultReportDate = useMemo<string | undefined>(
    () =>
      path === "positionInfo.positionReportDate"
        ? first(fastProp("reportDate")(baseManager))
        : first(fastProp("reportYearDate")(baseManager)),
    [baseManager, path]
  );
  const [reportDate, setReportDate] = useManagerStoreItem(id, path);
  useEffect(() => {
    if (defaultReportDate) {
      setReportDate(defaultReportDate);
    }
  }, [defaultReportDate, setReportDate]);
  return [reportDate, setReportDate];
};

export const useGetIndustryPieChartData = (
  id: string,
  reportDate: string,
  sectorId: string
) => {
  const baseManager = useAppSelector((state: RootState) =>
    baseManagerSelector(state, id)
  );
  const industryInfo = useMemo(
    () => prop(`industryInfo.${sectorId}.${reportDate}`)(baseManager),
    [baseManager, reportDate, sectorId]
  );
  const sectorCategoryMap = useAppSelector(sectorCategoriesMapSelector);
  return useMemo<PieSeriesOption["data"]>(() => {
    let index = 0;
    return flow(
      mapValuesIndexed((value: number, key: string) => {
        const data = {
          name: prop(`${key}.name`)(sectorCategoryMap) || getMessage(key),
          value,
          color: fastNth(index)(colors),
        };
        index += 1;
        return data;
      }),
      toArray,
      orderBy("value", "desc")
    )(industryInfo);
  }, [industryInfo, sectorCategoryMap]);
};

export const useGetIndustryMarketValueTableData = (
  id: string,
  reportDate: string
) => {
  const formatMessage = useFormatMessage();
  const baseManager = useAppSelector((state: RootState) =>
    baseManagerSelector(state, id)
  );
  const marketValueInfo = useMemo(
    () => prop(`marketValueInfo.${reportDate}`)(baseManager),
    [baseManager, reportDate]
  );
  const allStocksMap = useAppSelector(allStocksMapSelector);
  const marketValueData = useMemo(
    () =>
      flow(
        mapValuesIndexed((value: number, stockId: string) => {
          return {
            value: {
              name: prop(`${stockId}.name`)(allStocksMap),
              value,
            },
          };
        }),
        toArray,
        orderBy("value.value", "desc"),
        mapIndexed((item: any, index: number) => {
          item.index = addZeroIndex(index + 1);
          return item;
        })
      )(marketValueInfo),
    [allStocksMap, marketValueInfo]
  );
  const name = prop("basicInfo.info.name")(baseManager);
  const sumValues = sum(values(marketValueInfo));
  return useMemo(
    () =>
      isEmpty(marketValueData)
        ? []
        : [
            {
              index: formatMessage("serialNumber"),
              value: name,
            },
            ...(marketValueData as stockPositionInfo[]),
            {
              index: formatMessage("total"),
              value: sumValues,
            },
          ],
    [formatMessage, marketValueData, name, sumValues]
  );
};
