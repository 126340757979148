import React from "react";
import cn from "classnames";
import { includes } from "lodash/fp";
import {
  CompetitionStatus,
  competitionStatusText,
  getCompetitionStatusText,
} from "@/model/competition";
import { useFormatMessage } from "@/util/formatMessage";

import style from "./index.module.less";

type Props = {
  status: CompetitionStatus;
  className?: string;
};

export default function StatusTag({ status, className }: Props) {
  const formatMessage = useFormatMessage();
  if (!competitionStatusText?.[status]) {
    return null;
  }
  // const _status = getCompetitionStatusText(status);
  // const text = formatMessage(_status);
  // if (status === CompetitionStatus.ENROLL_DEADLINE) {
  //   return <span className={cn(style.EnrollDeadlineStatus, className)}>{text}</span>;
  // }
  return (
    <span className={cn(style.Status, className)}>
      {formatMessage(getCompetitionStatusText(status))}
      {includes(status)([
        CompetitionStatus.ENROLL_DEADLINE,
        CompetitionStatus.ENROLLING,
      ])
        ? `(${formatMessage(competitionStatusText?.[status])})`
        : ""}
    </span>
  );
}
