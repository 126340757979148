import { getIndustryList, getMainFilterList } from "@/api/targetFund";
import type { Fund } from "@/components/fundResultTable/index";
import { FundFilter, IndustryListItem } from "@/model/targetFund";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createSocketThunk from "@/util/createSocketThunk";
import { mapIndexed } from "@/util/opt";
import { orderBy } from "lodash/fp";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { UNLIMITED } from "@/constant/fundFilter";
import { TaskData } from "@/util/socket";

export const fetchIndustryList = avoidMultiRequestActionThunk<void>(
  "fundSlice.industryList",
  createAsyncThunk("fundSlice/industryList", async () => {
    const response = await getIndustryList();
    return response;
  })
);

export const fetchMainFilterList = createSocketThunk(
  "fundSlice/mainFilterList",
  "FINISH_FUND_TARGET_FILTER_CONDITION",
  (v: FundFilter) => {
    const response = getMainFilterList(v);
    return response;
  }
);

// export const fetchFundsTags = avoidMultiRequestActionThunk<void>(
//   "fundSlice.fundsTags",
//   createAsyncThunk("fundSlice/fundsTags", async () => {
//     const response = await getFundsTags();
//     return response;
//   })
// );
interface TargetFundSliceState {
  industryList: IndustryListItem[];
  mainFilterList: Fund[];
  filterTaskProgress?: TaskData;
  targetFundFilter: FundFilter;
  // fundsTags: string[];
}
export const creatMainFilterListState: FundFilter = {
  fundRiskLevelList: [UNLIMITED],
  fundStabilityList: [UNLIMITED],
  fundIndustryList: [UNLIMITED],
  fundThemeList: [UNLIMITED],
};
const initialState: TargetFundSliceState = {
  industryList: [],
  mainFilterList: [],
  targetFundFilter: creatMainFilterListState,
  // fundsTags: [],
};

const fetchMainFilterListStart: string = fetchMainFilterList.start.type;
const fetchMainFilterListProgress: string = fetchMainFilterList.progress.type;
const fetchMainFilterListFinish: string = fetchMainFilterList.finish.type;
const fetchMainFilterListError: string = fetchMainFilterList.error.type;

const targetFundSlice = createSlice({
  name: "fundSlice",
  initialState,
  reducers: {
    setTargetFundFilter: (state: TargetFundSliceState, action) => {
      state.targetFundFilter = action.payload;
    },
  },
  extraReducers: {
    [fetchIndustryList.fulfilled.type]: (state, action) => {
      state.industryList = mapIndexed((name: string, id: string) => ({
        id,
        name,
      }))(action.payload);
    },
    // [fetchFundsTags.fulfilled.type]: (state, action) => {
    //   state.industryList = mapIndexed((name: string, id: string) => ({
    //     id,
    //     name,
    //   }))(action.payload);
    // },
    [fetchMainFilterListStart]: (state, action) => {
      state.filterTaskProgress = action.meta;
    },
    [fetchMainFilterListProgress]: (state, action) => {
      state.filterTaskProgress = action.payload;
    },
    [fetchMainFilterListFinish]: (state, action) => {
      state.mainFilterList = orderBy<Fund>(
        "code",
        "asc"
      )(action?.payload?.view);
      state.filterTaskProgress = action?.meta;
    },
    [fetchMainFilterListError]: (state, action) => {
      state.filterTaskProgress = action.error;
    },
  },
});

export const { setTargetFundFilter } = targetFundSlice.actions;
export default targetFundSlice.reducer;
