import React from "react";
import { Modal } from "antd";
import { useMemoizedFn } from "ahooks";
import { useFormatMessage } from "@/util/formatMessage";

import style from "./index.module.less";

type BrowseImgProps = {
  name: string;
  url: string;
};

const BrowseImg = ({ name, url }: BrowseImgProps) => {
  const formatMessage = useFormatMessage();
  const openImgModal = useMemoizedFn(() => {
    if (!url) return;
    Modal.info({
      content: <img className={style.Img} src={url} />,
      icon: null,
      okText: formatMessage("ok"),
    });
  });

  return <a onClick={openImgModal}>{name}</a>;
};

export default React.memo(BrowseImg);
