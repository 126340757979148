import { RadarChartOpts } from "@/echarts/radarChart";
import getMessage from "@/util/getMessage";
import { formatNil, roundTo } from "@/util/numberFormatter";
import { fastNth, fastProp, getProp, mapIndexed, normalize } from "@/util/opt";
import { flow, join } from "lodash/fp";

interface dataSourceface {
  name: string;
  value: string | number;
  max: number;
}

type valueType = undefined | string | number;

export const getRadarOptions = (
  dataMaps: dataSourceface[],
  radarKeys: string[],
  radarValues: string[]
): RadarChartOpts["options"] => ({
  radar: {
    center: ["50%", "50%"],
    splitArea: {
      show: true,
      areaStyle: {
        color: ["white"],
        shadowColor: "rgba(0, 0, 0, 0.2)",
        shadowBlur: 15,
      },
    },
    axisName: {
      formatter(value: any) {
        const radarDataMap = normalize("name")(dataMaps);
        const data = getProp(`${value}.value`)(radarDataMap) as valueType;
        return `{normal|${getMessage(value)}}: {bold|${formatNil(
          roundTo(2)(Number(data))
        )}分}`;
      },
      rich: {
        normal: {
          color: "#647286",
        },
        bold: {
          color: "#0E1D32",
          fontWeight: 500,
        },
      },
    },
  },
  series: {
    itemStyle: {
      color: "#FFA201",
    },
    areaStyle: {
      color: "rgba(255, 168, 25, 0.3)",
    },
  },
  tooltip: {
    trigger: "axis",
    position: ["60%", "60%"],
    formatter(value: any) {
      const content = flow(
        mapIndexed(
          (name: string, index: number) =>
            `<p style="margin: 8px 0;">${fastProp("marker")(value)}${getMessage(
              name
            )}:  ${formatNil(roundTo(2)(fastNth(index)(radarValues)))}分</p>`
        ),
        join("")
      )(radarKeys);
      return `<div>${content}</div>`;
    },
  },
});
