import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import React, { useContext, useEffect, useState } from "react";
import scenarioAnalysisIcon from "@/assets/portfolioAnalysisIcons/scenarioAnalysis.png";
import { useFormatMessage } from "@/util/formatMessage";
import PortfolioAnalysisSubTitle from "../components/portfolioAnalysisSubtitle";
import { Space, Table } from "antd";
import style from "./index.module.less";
import LineChart from "@/echarts/lineChart";
import { useGetPortfolioAndBenchmarkInfo } from "../warehouseAnalysis/hooks";
import {
  useGetScenarioDailyReturnsInfo,
  useGetScenarioLineChartConfigs,
  useGetScenarioTableInfo,
} from "./hooks";
import ScenarioManage from "@/components/scenarioManage";
import { analysisBasicInfoSelector } from "../selectors";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import ErrorBoundary from "@/components/errorBoundary";
import { errorValidator } from "@/components/errorBoundary/constant";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import { fetchPortfolioScenarioList } from "@/store/scenarioList";
import LoadingComponent from "@/components/LoadingComponent";
import { fetchPortfolioSummary } from "@/store/portfolioAnalysis";
import { isEmpty } from "lodash/fp";

const needTime = 2;

const actions = [fetchPortfolioScenarioList, fetchPortfolioSummary];
export default React.memo<{
  id: string;
}>(({ id }) => {
  const { runningTime } = useContext(PortfolioAnalysisContext);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchPortfolioScenarioList());
  }, [dispatch]);
  const formatMessage = useFormatMessage();
  const [selectedScenarios, setSelectedScenarios] = useState<string[]>([]);
  const { tradingDate, portfolioInfo, benchmarkInfo } =
    useGetPortfolioAndBenchmarkInfo(id);
  const scenarioReturnInfo = useGetScenarioDailyReturnsInfo({
    selectedScenarios,
    tradingDate,
    portfolioInfo,
    benchmarkInfo,
  });
  const { series, options } = useGetScenarioLineChartConfigs({
    tradingDate,
    portfolioInfo,
    benchmarkInfo,
    scenarioReturnInfo,
  });
  const { startDate, endDate } = useAppSelector((state) =>
    analysisBasicInfoSelector(state, id)
  );
  const { dataSource, columns } = useGetScenarioTableInfo(scenarioReturnInfo);

  const tip: CardLayoutTipType = [
    [formatMessage("scenarioAnalysis"), formatMessage("scenarioAnalysisTip")],
  ];
  return (
    <CardLayout
      icon={scenarioAnalysisIcon}
      title={formatMessage("scenarioAnalysis")}
      tip={tip}
    >
      <ErrorBoundary
        errKey={errorValidator.lessTwoTradingDate(needTime, runningTime)}
      >
        <LoadingComponent actions={actions}>
          <Space className={style.FullWidth} direction="vertical">
            <div className={style.Flex}>
              <PortfolioAnalysisSubTitle
                name={formatMessage("scenarioAnalysis")}
              />
              <ScenarioManage
                startDate={startDate}
                endDate={endDate}
                value={selectedScenarios}
                onChange={setSelectedScenarios}
              />
            </div>
            <LineChart series={series} options={options} />
            {!isEmpty(dataSource) && (
              <Table
                className={style.StripTable}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            )}
          </Space>
        </LoadingComponent>
      </ErrorBoundary>
    </CardLayout>
  );
});
