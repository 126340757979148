import { getFileApi } from "@/api/fileInfo";

export const download = (url: string, fileName: string) => {
  getFileApi(url).then((res) => {
    const href = URL.createObjectURL(
      new Blob([res], { type: "application/pdf" })
    );
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = href;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(a.href);
  });
};
