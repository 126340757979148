import {
  filter,
  minBy,
  map,
  last,
  concat,
  flow,
  first,
  max,
  isEmpty,
  size,
} from "lodash/fp";
import { getTime } from "@/util/dateFormat";
import { useAppSelector } from "@/hooks/redux";
import { useMemo } from "react";
import {
  dateProcess,
  getNextTradingDate,
  getPreviousTradingDate,
} from "@/util/processedDates";
import {
  COMMON_TIME,
  FROM_APPOINTMENT,
  FROM_CREATION,
  FROM_THIS_YEAR,
  getThisYear,
} from "../statisticRange";
import { useMemoizedFn } from "ahooks";
import {
  addStartDate,
  getIntersectionDate,
  getRangeDate,
  getStatisticsRangeStartDate,
} from "./helper";

export const getStartDate =
  (tradingDateList: string[], processedTradingDates: Record<string, any>) =>
  (date: string) => {
    if (dateProcess(date, processedTradingDates)) return date;
    const nextTradingDate = getNextTradingDate(
      tradingDateList,
      processedTradingDates,
      date
    );
    return nextTradingDate;
  };

/**
 * 截取组合或者基金时间段函数，这个函数截取后没有往前一天推0，因为计算指标不需要往前面一天推0计算
 */
export const getCumulativeCalculateRangeDate = ({
  dates,
  range,
  tradingDateList,
  processedTradingDates,
  thisYearDate,
}: {
  dates: string[];
  range: string;
  tradingDateList: string[];
  processedTradingDates: Record<string, any>;
  thisYearDate?: string;
}) => {
  if (!range || isEmpty(dates)) return dates;
  if (range === FROM_CREATION) return dates;
  if (range === FROM_THIS_YEAR) {
    const startThisYear = getStartDate(
      tradingDateList,
      processedTradingDates
    )(getThisYear(thisYearDate));
    return getRangeDate(startThisYear)(dates);
  }
  const statisticStartDate = getStatisticsRangeStartDate(
    range,
    last(dates) as string
  );
  if (statisticStartDate) {
    const startDate = getStartDate(
      tradingDateList,
      processedTradingDates
    )(statisticStartDate);
    return getRangeDate(startDate)(dates);
  }
  return dates;
};

export const useGetFactorsRangeDate = () => {
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  return useMemoizedFn((dates: string[], range: string) =>
    getCumulativeCalculateRangeDate({
      dates,
      range,
      tradingDateList,
      processedTradingDates,
    })
  );
};
/**
 * 获取单个基金或者组合的累计收益图的区间数据
 */
export const useGetCumulativeChartCalculateRangeDate = () => {
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  return useMemoizedFn(
    (dates: string[], range: string, officeStartDate?: string) => {
      if (isEmpty(dates)) return [];
      if (range === FROM_CREATION) return dates;
      if (range === FROM_APPOINTMENT)
        return getRangeDate(officeStartDate || "")(dates);
      const calculatedDates = getCumulativeCalculateRangeDate({
        dates,
        range,
        tradingDateList,
        processedTradingDates,
      });
      const firstCalculatedDates = first(calculatedDates);
      if (firstCalculatedDates) {
        const firstDay = getPreviousTradingDate(
          tradingDateList,
          processedTradingDates,
          first(calculatedDates) as string
        );
        return concat(firstDay)(calculatedDates);
      }
      return calculatedDates;
    }
  );
};
/**
 * 获取一个基金list或者组合list和基准的累计收益图的区间范围
 */
export const useCalculateRangeDate = (
  fundDates: string[][],
  benchmarkDates: string[] | undefined,
  range: string
): string[][] => {
  const dataSourceTime = flow(
    map(last),
    max
  )(size(fundDates) > 0 ? fundDates : [benchmarkDates]) as string;
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  const getStartDateHandler = useMemo(
    () => getStartDate(tradingDateList, processedTradingDates),
    [processedTradingDates, tradingDateList]
  );
  const getCumulativeRangeDate = useGetCumulativeChartCalculateRangeDate();
  return useMemo(() => {
    const concatDates = benchmarkDates
      ? concat(fundDates)([benchmarkDates])
      : fundDates;
    if (range === COMMON_TIME) {
      const interSectionDate = getIntersectionDate(
        map<string[], string>((date) => first(date) as string)(fundDates),
        map<string[], string>((date) => last(date) as string)(fundDates)
      );
      if (!interSectionDate) return [];
      const [maxStartDate, minEndDate] = interSectionDate;
      const startDate = getStartDateHandler(maxStartDate);
      return map<string[], string[]>(
        flow(
          filter<string>((item) => item >= startDate && item <= minEndDate),
          addStartDate(startDate)
        )
      )(concatDates);
    }
    if (range === FROM_CREATION) {
      const minStartDate =
        flow(
          map<string[], string | undefined>(first),
          minBy(getTime)
        )(fundDates) || "";

      return map<string[], string[]>((date) =>
        filter<string>(
          (item) => item >= minStartDate && item <= dataSourceTime
        )(date)
      )(concatDates);
    }
    return map<string[], string[]>((dates) =>
      getCumulativeRangeDate(dates, range)
    )(concatDates);
  }, [
    benchmarkDates,
    dataSourceTime,
    fundDates,
    getCumulativeRangeDate,
    getStartDateHandler,
    range,
  ]);
};
