import React, { useContext, useEffect, useMemo } from "react";
import { Space } from "antd";
import PortfolioAnalysisSubtitle from "../components/portfolioAnalysisSubtitle";
import { useFormatMessage } from "@/util/formatMessage";
import {
  map,
  prop,
  last,
  flow,
  size,
  orderBy,
  first,
  filter,
  uniq,
  keys,
} from "lodash/fp";
import { useCreation } from "ahooks";
import style from "./index.module.less";
import { fetchPortfolioSummary } from "@/store/portfolioAnalysis";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import { useAppSelector } from "@/hooks/redux";
import { formatPercentage } from "@/util/numberFormatter";
import { fastProp } from "@/util/opt";
import { getNatureDateAndTradingDate } from "@/util/processedDates";
import { fundIdMapSelector } from "@/selectors/fund";
import { PortfolioAnalysisProps } from "..";
import { useGetFactors } from "../hooks";
import { analysisBasicInfoSelector } from "../selectors";
import CardLayout from "@/components/cardLayout";
import portfolioIntroductionIcon from "@/assets/portfolioAnalysisIcons/portfolioIntroduction.png";
import { factorsFormatter, formatter } from "@/constant/factorFormatter";
import LoadingComponent from "@/components/LoadingComponent";
import ColorNumber from "@/components/colorNumber";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import KeyIndicator, { IndexDataItem } from "./keyIndicator";

const loadingActions = [
  fetchPortfolioSummary,
  fetchFundAndBenchmarkDailyReturns,
];

const percentage = (value: number) => formatPercentage(value);

export const getValueColor = (item: number) => {
  return (item as number) > 0 ? style.colorRed : style.colorGreen;
};

const factors = [
  "maxDrawdown",
  "annualVolatility",
  "portfolioReturn",
  "sharpe",
  "annualReturn",
];

const useGetPortfolioIntroductionData = (
  id: string,
  portfolioSummary: Record<string, any>
) => {
  const processedTradingDates = useAppSelector(
    prop("tradingDates.processedTradingDates")
  );

  const {
    dailyReturn,
    latestScale,
    startDate,
    endDate,
    riskContributions,
    latestPosition,
    benchmarkName,
  } = useCreation(() => portfolioSummary || {}, [portfolioSummary]);
  const factorsData = useGetFactors(id, "FROM_CREATION", factors);
  const fundsMap = useAppSelector(fundIdMapSelector);
  //交易日
  const natureDateAndTradingDate = useMemo(
    () =>
      getNatureDateAndTradingDate(startDate, endDate)(processedTradingDates),
    [startDate, endDate, processedTradingDates]
  );

  const keyIndicatorData = useCreation<IndexDataItem[]>(
    () => [
      {
        title: "dailyYieldReturn",
        type: "yield",
        value: last(dailyReturn),
        transformer: percentage,
      },
      {
        title: "cumulativeIncome",
        type: "yield",
        value: factorsData?.portfolioReturn,
        transformer: factorsFormatter.yield,
      },
      {
        title: "maxDrawdown",
        value: factorsData?.maxDrawdown,
        transformer: factorsFormatter.maxDrawdown,
      },
      {
        title: "annualizedVolatility",
        value: factorsData?.annualVolatility,
        transformer: factorsFormatter.volatility,
      },
      {
        title: "sharpeRatio",
        value: factorsData?.sharpe,
        transformer: factorsFormatter.sharpeRatio,
      },
      {
        title: "latestScale",
        value: latestScale,
        transformer: formatter.scaleWithTenThousand,
      },
    ],
    [dailyReturn, factorsData, latestScale]
  );

  //持仓分析
  const positionAnalysis = useMemo(() => {
    const noCashData = filter((item: any) => item.fundId !== "cash_fund")(
      latestPosition
    );
    const subFundNum = size(noCashData);
    const strategyNum = flow(
      map((item: any) => prop(`${item.fundId}.investType`)(fundsMap)),
      uniq,
      size
    )(noCashData);
    const maxSingleStrateg = flow(orderBy("weight", "desc"), first)(noCashData);
    const maxSingleStrategName = prop(
      `${prop("fundId")(maxSingleStrateg)}.name`
    )(fundsMap);
    const maxSingleStrategvalue = flow(
      fastProp("weight"),
      percentage
    )(maxSingleStrateg);
    const maxSingleStrategType = prop(
      `${prop("fundId")(maxSingleStrateg)}.investType`
    )(fundsMap);
    return {
      subFundNum,
      strategyNum,
      maxSingleStrategName,
      maxSingleStrategvalue,
      maxSingleStrategType,
    };
  }, [latestPosition, fundsMap]);

  //风险归因
  const riskAttribution = useMemo(() => {
    const noCashDatas = flow(
      keys,
      map((item) => ({ fundId: item, weight: riskContributions[item] })),
      filter((item) => item.fundId !== "cash_fund"),
      orderBy("weight", "desc")
    )(riskContributions);
    const maxRisFundkValue: number = flow(
      first,
      fastProp("weight"),
      percentage
    )(noCashDatas);
    const maxRiskFundName = prop(
      `${flow(first, fastProp("fundId"))(noCashDatas)}.name`
    )(fundsMap);
    const minRiskFundValue: number = flow(
      last,
      fastProp("weight"),
      percentage
    )(noCashDatas);
    const minRiskFundName = prop(
      `${flow(last, prop("fundId"))(noCashDatas)}.name`
    )(fundsMap);
    return {
      maxRiskFundName,
      maxRisFundkValue,
      minRiskFundValue,
      minRiskFundName,
    };
  }, [riskContributions, fundsMap]);

  return {
    keyIndicatorData,
    startDate,
    endDate,
    latestScale,
    natureDateAndTradingDate,
    positionAnalysis,
    riskAttribution,
    benchmarkName,
    factorsData,
  };
};

const useGetPortfolioSummary = (id: string) => {
  const { dispatch } = useContext(PortfolioAnalysisContext);
  const portfolioSummary = useAppSelector((state) =>
    analysisBasicInfoSelector(state, id)
  );
  const benchmarkId = portfolioSummary?.benchmark;

  useEffect(() => {
    if (id) dispatch(0, fetchPortfolioSummary(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (benchmarkId)
      dispatch(0, fetchFundAndBenchmarkDailyReturns(benchmarkId));
  }, [dispatch, benchmarkId]);

  return portfolioSummary;
};

export default React.memo<PortfolioAnalysisProps>((props) => {
  const formatMessage = useFormatMessage();
  const portfolioSummary = useGetPortfolioSummary(props.id);
  const {
    keyIndicatorData,
    startDate,
    endDate,
    benchmarkName,
    latestScale,
    natureDateAndTradingDate,
    positionAnalysis,
    riskAttribution,
    factorsData,
  } = useGetPortfolioIntroductionData(props.id, portfolioSummary);
  const [natureDateCount, tradingDateCount] = natureDateAndTradingDate;

  return (
    <>
      {/* 关键指标 */}
      <LoadingComponent actions={loadingActions}>
        <CardLayout
          title={formatMessage("portfolioIntroduction")}
          icon={portfolioIntroductionIcon}
        >
          <PortfolioAnalysisSubtitle name={formatMessage("keyIndicator")} />
          <KeyIndicator data={keyIndicatorData}>
            <>
              <span className={style.keyIndicatorEndItem}>
                {formatMessage("startDate")}:{startDate}
              </span>
              <span className={style.keyIndicatorEndItem}>
                {formatMessage("endDate")}:{endDate}
              </span>
              <span className={style.keyIndicatorEndItem}>
                {endDate
                  ? formatMessage("natureDateAndTradingDate", {
                      natureDateCount: natureDateCount,
                      tradingDateCount: tradingDateCount,
                    })
                  : `${formatMessage("runningDate")}: ${formatMessage(
                      "inCreating"
                    )}`}
              </span>
              <span className={style.keyIndicatorEndItem}>
                {formatMessage("performanceBenchmark")}:{benchmarkName}
              </span>
            </>
          </KeyIndicator>

          {/* 总体评价 */}
          <PortfolioAnalysisSubtitle
            name={formatMessage("overallEvaluation")}
          />
          <Space
            direction="vertical"
            size={5}
            className={style.overallEvaluation}
          >
            <Space size="middle" wrap>
              <div className={style.overallEvaluationItem}>
                {formatMessage("performance")}
              </div>
              <span>
                {formatMessage("portfolioSummaryPerformanceDescription", {
                  annualReturn: (
                    <ColorNumber
                      formatValue={factorsFormatter.yield}
                      value={factorsData?.annualReturn}
                    />
                  ),
                  annualVolatility: factorsFormatter.volatility(
                    factorsData?.annualVolatility
                  ),
                  sharpeRatio: (
                    <span className={style.colorBlock}>
                      {factorsFormatter.sharpeRatio(factorsData?.sharpe)}
                    </span>
                  ),
                  maxDrawdown: (
                    <span>
                      {factorsFormatter.maxDrawdown(factorsData?.maxDrawdown)}
                    </span>
                  ),
                })}
              </span>
            </Space>
            <Space size="middle">
              <div className={style.overallEvaluationItem}>
                {formatMessage("positionAnalysis")}
              </div>
              <span>
                {formatMessage("portfolioSummaryPositionAnalysisDescription", {
                  latestScale: formatter.scaleWithTenThousand(latestScale),
                  subFundNum: positionAnalysis?.subFundNum,
                  strategyNum: positionAnalysis?.strategyNum,
                  maxSingleFundName: positionAnalysis?.maxSingleStrategName,
                  maxSingleFundValue: positionAnalysis?.maxSingleStrategvalue,
                  maxSinglePolicyType: positionAnalysis?.maxSingleStrategType,
                })}
              </span>
            </Space>
            <Space size="middle">
              <div className={style.overallEvaluationItem}>
                {formatMessage("riskAttribution")}
              </div>
              <span>
                {formatMessage("portfolioSummaryRiskAttributionDescription", {
                  currentMaxRiskAttributionFund:
                    riskAttribution?.maxRiskFundName,
                  maxContributionRisk: riskAttribution?.maxRisFundkValue,
                  currentMinRiskAttributionFund:
                    riskAttribution?.minRiskFundName,
                  minContributionRisk: riskAttribution?.minRiskFundValue,
                })}
              </span>
            </Space>
          </Space>
        </CardLayout>
      </LoadingComponent>
    </>
  );
});
