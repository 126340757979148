import { Card, Space, Table, TableColumnProps } from "antd";
import React, { useMemo } from "react";
import {
  map,
  max,
  fromPairs,
  flow,
  prop,
  concat,
  isNumber,
  isNil,
} from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import ColorNumber from "@/components/colorNumber";
import { YIELD_FACTORS_SECTION } from "@/constant/yieldFactors";
import style from "../../index.module.less";
import innerStyle from "./index.module.less";
import { useAppSelector } from "@/hooks/redux";
import { fastProp } from "@/util/opt";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";

const useGetColumns = (maxData: Record<string, number>) => {
  const formatMessage = useFormatMessage();
  // const returnFactors = useAppSelector(prop("compareManage.returnFactors"));
  return useMemo(
    () =>
      flow(
        map<string, TableColumnProps<any>>((id) => ({
          title: <div>{formatMessage(id)}</div>,
          dataIndex: id,
          align: "right",
          className: "factorDataColumn",
          render: (dataSource: Record<string, any>) => {
            const currentFactorMaxData = fastProp(id)(maxData);
            const { value, size, rank } = dataSource || {};
            return (
              <div
                className={
                  isNumber(value) &&
                  isNumber(currentFactorMaxData) &&
                  currentFactorMaxData === value
                    ? innerStyle.FullColor
                    : undefined
                }
              >
                <ColorNumber value={value} formatter="percentage" />
                <div className={style.RankWrap}>
                  {isNaN(rank) || isNil(rank) ? (
                    "--"
                  ) : (
                    <span className={style.Rank}>{rank}</span>
                  )}
                  <span className={style.Size}>/{size}</span>
                </div>
              </div>
            );
          },
        })),
        concat({
          title: formatMessage("fundName"),
          dataIndex: "fundName",
          render: (val, record) => {
            return (
              <ToFundDetailPage name={val} id={fastProp("fundId")(record)} />
            );
          },
        })
      )(YIELD_FACTORS_SECTION),
    [formatMessage, maxData]
  );
};

const useGetDataSource = () => {
  const compareFundTask = useAppSelector(
    prop("compareManage.fundCompare.compareFundTask")
  );
  return useMemo(
    () =>
      map(({ fundName, factorRankViews, fundId }) => {
        const factorValues = flow(
          map(({ section, ...res }) => [section, res]),
          fromPairs
        )(factorRankViews);
        return {
          fundName,
          fundId,
          ...factorValues,
        };
      })(compareFundTask),
    [compareFundTask]
  );
};

export default React.memo<{
  className?: string;
}>(({ className }) => {
  const formatMessage = useFormatMessage();
  const dataSource = useGetDataSource();
  const maxData = useMemo(
    () =>
      flow(
        map<string, any[]>((key) => [
          key,
          max(map((item) => prop(`${key}.value`)(item))(dataSource)),
        ]),
        fromPairs
      )(YIELD_FACTORS_SECTION),
    [dataSource]
  );

  const columns = useGetColumns(maxData);
  return (
    <Card bordered={false} className={className}>
      <Space direction="vertical" className={style.fullWidth}>
        <h3>{formatMessage("rangeOfBenefits")}</h3>
        <Table
          className={innerStyle.RangeBenefitsTable}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </Space>
    </Card>
  );
});
