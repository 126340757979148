import { useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { Col, Row, Tabs } from "antd";
import React, { useMemo, useState } from "react";
import style from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/performanceDismantling/index.module.less";
import mapper from "../mapper";
import {
  FundCompanyContribution,
  AssetContribution,
  ReturnContribution,
} from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/performanceDismantling/compoents/dismantlingBarChart";
import { fastSize } from "@/util/opt";

const { TabPane } = Tabs;

export default React.memo(({ id }: { id: string }) => {
  const [leftMenuSelectedKeys, setLeftMenuSelectedKeys] =
    useState("assetContribution");
  const [rightMenuSelectedKeys, setRightMenuSelectedKeys] = useState(
    "top10ContributionFund"
  );
  const formatMessage = useFormatMessage();

  const {
    assetContributionSelector: {
      contributionNames: assetContributionNames,
      contributionMap: assetContributionMap,
    },
    industryContributionSelector: {
      contributionNames: industryContributionNames,
      contributionMap: industryContributionMap,
    },
    fundCompanyContributionSelector: fundCompanyContribution,
    topFundContributionsSelector: {
      contributionsNames: topFundContributionsNames,
      contributionsMap: topFundContributionsMap,
    },
    lowestFundContributionsSelector: {
      contributionsNames: lowestFundContributionsNames,
      contributionsMap: lowestFundContributionsMap,
    },
    topStockContributionsSelector: {
      contributionsNames: topStockContributionsNames,
      contributionsMap: topStockContributionsMap,
    },
    lowestStockContributionsSelector: {
      contributionsNames: lowestStockContributionsNames,
      contributionsMap: lowestStockContributionsMap,
    },
  } = useAppSelector((state) => mapper(state, id));

  const maxFundSize = useMemo(() => {
    return fastSize(topFundContributionsNames) >
      fastSize(lowestFundContributionsNames)
      ? fastSize(topFundContributionsNames) || 0
      : fastSize(lowestFundContributionsNames) || 0;
  }, [topFundContributionsNames, lowestFundContributionsNames]);

  const maxStockSize = useMemo(() => {
    return fastSize(topFundContributionsNames) >
      fastSize(lowestFundContributionsNames)
      ? fastSize(topFundContributionsNames) || 0
      : fastSize(lowestFundContributionsNames) || 0;
  }, [topFundContributionsNames, lowestFundContributionsNames]);

  return (
    <Row gutter={14}>
      <Col span={12}>
        <div className={style.BarChartBox}>
          <Tabs
            onChange={setLeftMenuSelectedKeys}
            activeKey={leftMenuSelectedKeys}
          >
            <TabPane
              forceRender
              tab={formatMessage("assetContribution")}
              key="assetContribution"
            >
              <AssetContribution
                contributionMap={assetContributionMap}
                contributionNames={assetContributionNames}
              />
            </TabPane>
            <TabPane
              forceRender
              tab={formatMessage("industryContribution")}
              key="industryContribution"
            >
              <AssetContribution
                contributionMap={industryContributionMap}
                contributionNames={industryContributionNames}
              />
            </TabPane>
            <TabPane
              forceRender
              tab={formatMessage("fundComponyContribution")}
              key="fundComponyContribution"
            >
              <FundCompanyContribution
                portfolioType="aum"
                fundCompanyContribution={fundCompanyContribution}
              />
            </TabPane>
          </Tabs>
        </div>
      </Col>
      <Col span={12}>
        <div className={style.BarChartBox}>
          <Tabs
            activeKey={rightMenuSelectedKeys}
            onChange={setRightMenuSelectedKeys}
          >
            <TabPane
              forceRender
              tab={formatMessage("top10ContributionFund")}
              key="top10ContributionFund"
            >
              <Row align="middle">
                <Col span={12}>
                  <ReturnContribution
                    type="lowest"
                    contributionsNames={lowestFundContributionsNames}
                    contributionsMap={lowestFundContributionsMap}
                    maxSize={maxFundSize}
                  />
                </Col>
                <Col span={12}>
                  <ReturnContribution
                    type="top"
                    contributionsNames={topFundContributionsNames}
                    contributionsMap={topFundContributionsMap}
                    maxSize={maxFundSize}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane
              forceRender
              tab={formatMessage("top10ContributionStock")}
              key="top10ContributionStock"
            >
              <Row align="middle">
                <Col span={12}>
                  <ReturnContribution
                    type="lowest"
                    contributionsNames={lowestStockContributionsNames}
                    contributionsMap={lowestStockContributionsMap}
                    maxSize={maxStockSize}
                  />
                </Col>
                <Col span={12}>
                  <ReturnContribution
                    type="top"
                    contributionsNames={topStockContributionsNames}
                    contributionsMap={topStockContributionsMap}
                    maxSize={maxStockSize}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </Col>
    </Row>
  );
});
