import React from "react";
import { Select } from "antd";
import type { SelectProps } from "antd/es/select";
import { map } from "lodash/fp";
import { ScenarioListResponse } from "@/model/portfolioAnalysis";
export interface StatisticRangeProps extends SelectProps<any> {
  onChange: (value: number) => void;
  className?: string;
  value?: any;
  optionList: ScenarioListResponse[];
}
export default React.memo<StatisticRangeProps>(
  ({ value, className, optionList, onChange, ...props }) => {
    const { Option } = Select;

    return (
      <Select
        value={value ? value : "--"}
        className={className}
        {...props}
        onChange={onChange}
      >
        {map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))(optionList)}
      </Select>
    );
  }
);
