import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { FormatMessageFunc } from "@/util/formatMessage";
import { Button, Space, Tag } from "antd";
import { TableProps as AntdTableProps } from "antd";
import { MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { SortableHandle } from "react-sortable-hoc";
import { prop, size, map } from "lodash/fp";
import SelectFundDialog from "../portfolioCompoents/selectFundDialog";
import { PlusOutlined } from "@ant-design/icons";
import style from "./index.module.less";
import NumberInput, { percentageParser } from "../numberInput";
import { fastProp } from "@/util/opt";

type BasicTableProps = AntdTableProps<any>;

export const getDesignatedBasicColumns = (
  formatMessage: FormatMessageFunc,
  fundIdMap: Record<string, any>,
  changeReplaceFund: (key: string) => (value: string[]) => void,
  delReplaceFund: (key: string) => (value: string) => void
): BasicTableProps["columns"] => [
  {
    title: formatMessage("fundName"),
    dataIndex: "name",
    key: "name",
    width: 150,
    render: (text, record) => {
      return (
        <ToFundDetailPage
          name={prop(`${record.fundId}.name`)(fundIdMap)}
          id={record.fundId}
        />
      );
    },
  },
  {
    title: formatMessage("fundCode"),
    dataIndex: "code",
    key: "code",
    width: 150,
    render: (text, record) => (
      <ToFundDetailPage
        name={prop(`${record.fundId}.code`)(fundIdMap)}
        id={record.fundId}
      />
    ),
  },
  {
    title: formatMessage("policyType"),
    dataIndex: "investType",
    key: "investType",
    width: 150,
    render: (text, record) => prop(`${record.fundId}.investType`)(fundIdMap),
  },
  {
    title: formatMessage("designatedAlternativeFund"),
    dataIndex: "replaceFund",
    key: "replaceFund",
    render: (text, record) => {
      return (
        <Space>
          {size(text) < 3 && (
            <SelectFundDialog
              fundIds={(text as string[]) || []}
              onChange={changeReplaceFund(record?.fundId)}
              disabledFundIds={[record?.fundId]}
              maxSelectionSize={3}
            >
              {(onChangeVisible) => (
                <Button
                  onClick={onChangeVisible}
                  // className={style.ShadowButton}
                  type="primary"
                  icon={<PlusOutlined />}
                  ghost
                >
                  {formatMessage("add")}
                </Button>
              )}
            </SelectFundDialog>
          )}
          {map((id: string) => {
            return (
              <Tag
                className={style.FundTag}
                key={id}
                closable
                onClose={() => delReplaceFund(record?.fundId)(id)}
              >
                <span>{prop(`${id}.name`)(fundIdMap)}</span>
              </Tag>
            );
          })(text)}
        </Space>
      );
    },
  },
];

const DragHandle = SortableHandle(() => <MenuOutlined />);

const dealData = (value: string) => {
  const parsedValue = percentageParser(value);
  if (parsedValue > 0.05) {
    return 0.05;
  }
  if (parsedValue <= 0) {
    return 0;
  }
  return parsedValue;
};

export const getDesignatedColumns = (
  formatMessage: FormatMessageFunc,
  delFund: (value: string) => any,
  changeNoDesignated: (
    index: number
  ) => (key: string) => (value: number | null) => any,
  fundListMap: Record<string, any>
) => [
  {
    title: formatMessage("alternativePriority"),
    dataIndex: "series",
    key: "series",
    width: 150,
    render: (_: any, __: any, index: number) => {
      return index + 1;
    },
  },
  {
    title: formatMessage("fundName"),
    dataIndex: "fundName",
    key: "fundName",
    width: 200,
    render: (text: string, record: Record<string, any>) => {
      return (
        <Space>
          <DragHandle />
          <ToFundDetailPage name={text} id={record.fundId} />
        </Space>
      );
    },
  },
  {
    title: formatMessage("fundCode"),
    dataIndex: "fundCode",
    key: "fundCode",
    width: 200,
    render: (text: string, record: Record<string, any>) => (
      <ToFundDetailPage name={text} id={record.fundId} />
    ),
  },
  {
    title: formatMessage("policyType"),
    dataIndex: "policyType",
    key: "policyType",
    width: 200,
  },
  {
    title: formatMessage("purchFeeRatio"),
    dataIndex: "purchFeeRatio",
    key: "purchFeeRatio",
    width: 150,
    render: (text: number, record: any, index: number) => (
      <NumberInput
        type="PERCENTAGE"
        min={0}
        max={0.05}
        precision={2}
        placeholder=""
        addonAfter="%"
        className={style.WeightInput}
        value={text}
        onBlur={(e) => {
          const value = dealData(e.target.value);
          changeNoDesignated(index)("purchFeeRatio")(value);
        }}
        disabled={!!fastProp(record?.fundId)(fundListMap)}
      />
    ),
  },
  {
    title: formatMessage("redeemFeeRatio"),
    dataIndex: "redeemFeeRatio",
    key: "redeemFeeRatio",
    width: 150,
    render: (text: number, record: any, index: number) => (
      <NumberInput
        type="PERCENTAGE"
        min={0}
        max={0.05}
        precision={2}
        placeholder=""
        addonAfter="%"
        className={style.WeightInput}
        value={text}
        onBlur={(e) => {
          const value = dealData(e.target.value);
          changeNoDesignated(index)("redeemFeeRatio")(value);
        }}
        disabled={!!fastProp(record?.fundId)(fundListMap)}
      />
    ),
  },
  {
    title: formatMessage("operator"),
    dataIndex: "operator",
    key: "operator",
    width: 80,
    render: (_: string, record: Record<string, any>) => (
      <Button
        type="link"
        onClick={() => delFund(record.fundId)}
        icon={<DeleteOutlined />}
      ></Button>
    ),
  },
];

export const replaceRuleConfig = {
  cash: "cash",
  intraGroupAllcation: "allocateWithinGroup",
  replaceFund: "replaceFund",
  alternateFund: "alternativeFund",
};

export const defalueCashHold = {
  rule: replaceRuleConfig.cash,
};

export const defaultReplaceFund = {
  rule: replaceRuleConfig.replaceFund,
  similarPosition: true, //持仓相似
  similarNetValue: true, //净值相关
  blockTrade: true, //大额
  purchFeeRatio: 0, //申购费率
  redeemFeeRatio: 0, //赎回费率
};
export const defalutIntraGroupAllcation = {
  rule: replaceRuleConfig.intraGroupAllcation,
  allocateType: "EQUAL",
};
export const defaultalternativeFund = {
  rule: replaceRuleConfig.alternateFund,
  isAppoint: true, // 是否指定基金
  //指定基金的替换基金
  replaceFund: {},
  replaceFundTradeFeeRatio: [],
  //不指定基金替换基金池
  replaceFundPool: [],
};

export const intraGroupAllcationInfo = {
  EQUAL: "equalDistributionInfo",
  SCALE: "marketValueAllocationInfo",
};
