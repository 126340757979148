import { useCallback, useState } from "react";

export function useFormStatus<T>(
  create: T
): [
  T,
  (arg0: string, arg1: boolean) => React.ChangeEventHandler<HTMLInputElement>
] {
  const [formStatus, setFormStatus] = useState<T>(create);
  const changFormStatus = useCallback(
    (fieldName: string, status: boolean) => () => {
      setFormStatus({
        ...formStatus,
        [fieldName]: status,
      });
    },
    [setFormStatus, formStatus]
  );
  return [formStatus, changFormStatus];
}
