import React, { useEffect } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import style from "../index.module.less";
import BasicInfo from "./components/basicInfo";
import IncomeTrend from "./components/incomeTrend";
import ManageFUndScale from "./components/manageFundScale";
import IncomeDistribution from "./components/incomeDistribution";
import { useAppDispatch } from "@/hooks/redux";
import { clearFundCompanyResult } from "@/store/compareManage";
import RiskDistribution from "./components/riskDistribution";

export default React.memo<{ companyIds: string[] }>(({ companyIds }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  useEffect(
    () => () => {
      dispatch(clearFundCompanyResult());
    },
    [dispatch]
  );
  return (
    <div className={style.Result}>
      <h2 className={style.margin20}>{formatMessage("compareResult")}</h2>
      <BasicInfo className={style.marginBottom12} />
      <IncomeTrend companyIds={companyIds} className={style.marginBottom12} />
      <ManageFUndScale
        companyIds={companyIds}
        className={style.marginBottom12}
      />
      <IncomeDistribution
        companyIds={companyIds}
        className={style.marginBottom12}
      />
      <RiskDistribution companyIds={companyIds} />
    </div>
  );
});
