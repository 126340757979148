import React from "react";
import cn from "classnames";
import style from "./index.module.less";

export default ({
  message,
  className,
}: {
  message: string;
  className?: string;
}) => <p className={cn(style.ErrorField, className)}>* {message}</p>;
