import { useAppDispatch } from "@/hooks/redux";
import { fetchDeleteOptionalFund } from "@/store/workbench";
import { AddGroupFundListParm } from "@/api/workbench";
import { useFormatMessage } from "@/util/formatMessage";
import { useMemoizedFn } from "ahooks";
import { Popconfirm, Button } from "antd";
import React from "react";
import { isEmpty } from "lodash/fp";
import { FUND } from "../constant";
import style from "./index.module.less";

type deletePopconfirmProps = {
  className?: string;
  ids: string[];
  selected: string;
  btnType?: string;
  type: string;
};
export default React.memo<deletePopconfirmProps>(
  ({ ids, selected, type, btnType = "text" }) => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();
    const onDeleteOptionalFund = useMemoizedFn(() => {
      let params = {};
      if (type === FUND) {
        params = {
          groupName: selected,
          fundIds: ids,
          type,
        };
      } else {
        params = {
          groupId: selected,
          fundManagerIds: ids,
          type,
        };
      }
      dispatch(fetchDeleteOptionalFund(params as AddGroupFundListParm));
    });
    return (
      <Popconfirm
        title={type === FUND ? formatMessage("deleteOptionalFund") : formatMessage("deleteOptionalFundManager")}
        onConfirm={onDeleteOptionalFund}
        okText={formatMessage("ok")}
        cancelText={formatMessage("cancel")}
      >
        <Button
          className={style.DeleteButton}
          type={btnType === "default" ? "default" : "text"}
          disabled={btnType === "default" && isEmpty(ids)}
        >
          {formatMessage("delete")}
        </Button>
      </Popconfirm>
    );
  }
);
