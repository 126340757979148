import { useAppSelector } from "@/hooks/redux";
import { fastProp, getProp } from "@/util/opt";
import { Button, Form, Input, Space } from "antd";
import { useCreation } from "ahooks";
import { Card } from "antd";
import { map, sumBy } from "lodash/fp";
import React from "react";
import NumberInput from "@/components/numberInput";
import TradingDatePicker from "@/components/tradingDatePicker";
import DisplayFundWeightTable from "../../manualCreatePortfolio/components/displayFundWeightTable";
import PortfolioAnalysis from "../../portfolioAnalysis";
import style from "./index.module.less";
import { ManualCreatePortfolio } from "../../manualCreatePortfolio/constant";
import { roundTo } from "@/util/numberFormatter";
import { useFormatMessage } from "@/util/formatMessage";
import { backTestingSchemeMapSelector } from "@/selectors/portfolioList";
import { portfolioAnalysisConfig } from "@/providers/portfolioAnalysisProvider/context";
import {
  useCloseModal,
  useGetCurrentScheme,
  useModifyConfiguration,
} from "./hooks";
import { getUserId } from "@/util/userInfo";
import BenchmarkSelect from "@/components/benchmarkSelect";

const { Item } = Form;

const generateManualCreatePortfolioData = (
  data: any[]
): ManualCreatePortfolio[] =>
  map((item: any) => ({
    turnoverDate: fastProp("turnoverDate")(item),
    weights: fastProp("weights")(item),
    sumWeight: roundTo(4)(sumBy("weight")(fastProp("weights")(item))),
    errorField: {
      dateEmptyError: false,
      fundEmptyError: false,
      fundWeightZeroError: false,
      latestNetUnitValueZeroError: false,
      fundWeightMaxError: false,
      netValueStartDateZeroError: false,
      netValueStartDateError: false,
      maturityDateError: false,
    },
  }))(data);
export default React.memo<{
  id: string;
}>(({ id }) => {
  const formatMessage = useFormatMessage();
  const currentScheme = useGetCurrentScheme(id);
  const dataSource = useCreation(
    () =>
      generateManualCreatePortfolioData(
        fastProp("turnoverPositions")(currentScheme)
      ),
    [currentScheme]
  );
  const backTestingSchemeMap = useAppSelector(backTestingSchemeMapSelector);
  const backTestingSchemeName = useCreation(
    () => getProp(`${id}.name`)(backTestingSchemeMap),
    [backTestingSchemeMap, id]
  );
  const backTestingSchemeOwnerId = useCreation(
    () => getProp(`${id}.userId`)(backTestingSchemeMap),
    [backTestingSchemeMap, id]
  );
  const onModifyConfiguration = useModifyConfiguration(currentScheme);
  const userId = getUserId();
  const onCancel = useCloseModal();
  return (
    <div className={style.LayoutContainer}>
      <section
        className={
          backTestingSchemeOwnerId === userId
            ? style.LayoutScrollYContent
            : style.FullHeight
        }
      >
        <Card title={backTestingSchemeName} className={style.Card}>
          <Form layout="inline" className={style.Form}>
            <Item label={formatMessage("startDate")}>
              <TradingDatePicker
                disabled
                value={fastProp("startDate")(currentScheme)}
              />
            </Item>

            <Item label={formatMessage("endDate")}>
              <TradingDatePicker
                disabled
                value={fastProp("endDate")(currentScheme)}
              />
            </Item>

            <Item label={formatMessage("performanceBenchmark")}>
              <BenchmarkSelect
                disabled
                value={fastProp("benchmark")(currentScheme)}
              />
            </Item>

            <Item label={formatMessage("initScale")}>
              <NumberInput
                className={style.InitScale}
                disabled
                type="THOUSANDTH"
                value={fastProp("initScale")(currentScheme)}
                addonAfter={formatMessage("YUAN")}
              />
            </Item>

            <Item label={formatMessage("creater")}>
              <Input disabled value={fastProp("userName")(currentScheme)} />
            </Item>
          </Form>
          <p className={style.Comment}>
            {formatMessage("schemeDescription")}：
            <span>{fastProp("description")(currentScheme)}</span>
          </p>
        </Card>
        <Card className={style.Card}>
          <DisplayFundWeightTable data={dataSource} />
        </Card>
        <PortfolioAnalysis
          id={id}
          analysisType={portfolioAnalysisConfig.BACK_TEST}
        />
      </section>
      {backTestingSchemeOwnerId === userId && (
        <Space className={style.LayoutFooter}>
          <Button onClick={onCancel}>{formatMessage("cancel")}</Button>
          <Button type="primary" onClick={onModifyConfiguration}>
            {formatMessage("modifyConfiguration")}
          </Button>
        </Space>
      )}
    </div>
  );
});
