// import { RequiredSome } from "@/types";
import { treeToMap } from "@/util/opt/tree";
// import { RouteObject } from "react-router";

// export type RouterMenu = Omit<RequiredSome<RouteObject, "id">, "children"> & {
//   children?: RequiredSome<RouteObject, "id">[];
// };

export type RouterMenu = {
  id: string;
  path?: string;
  children?: RouterMenu[];
};

export const leftMenu: RouterMenu[] = [
  {
    id: "home",
    children: [
      {
        id: "dataBoard",
        path: "/manage/dataBoard",
      },
      {
        id: "newsSearch",
        path: "/manage/newsSearch",
      },
    ],
  },
  {
    id: "customerManage",
    path: "/manage/customerList",
  },
  {
    id: "aum",
    path: "/manage/aum",
  },
  {
    id: "competition",
    path: "/manage/competition",
  },
  {
    id: "searchFund",
    children: [
      {
        id: "targetFund",
        path: "/manage/targetFund",
      },
      {
        id: "factorFund",
        path: "/manage/factorFund",
      },
      {
        id: "stockBackCheck",
        path: "/manage/stockBackCheck",
      },
      {
        id: "replaceFund",
        path: "/manage/replaceFund",
      },
      {
        id: "compareManage",
        path: "/manage/compareManage",
      },
    ],
  },
  {
    id: "readFund",
    children: [
      {
        id: "fundDetails",
        path: "/manage/fundDetail",
      },
      {
        id: "fundManagerDetails",
        path: "/manage/fundManagerDetail",
      },
      {
        id: "fundCompanyDetails",
        path: "/manage/fundCompanyDetail",
      },
    ],
  },
  {
    id: "fundConfiguration",
    children: [
      {
        id: "mockPortfolio",
        path: "/manage/portfolioList",
      },
      // {
      //   id: "competition",
      //   path: "/manage/competition",
      // },
      // {
      //   id: "aum",
      //   path: "/manage/aum",
      // },
    ],
  },
  {
    id: "recommendedFund",
    children: [
      {
        id: "preferredFund",
        path: "/manage/preferredFund",
      },
    ],
  },
  {
    id: "workbench",
    children: [
      {
        id: "myOptional",
        path: "/manage/myOptional",
      },
      // {
      //   id: "customerManage",
      //   path: "/manage/customerList",
      // },
    ],
  },
];

export const leftMenu1: RouterMenu[] = [
  {
    id: "home",
    children: [
      {
        id: "dataBoard",
        path: "/manage/dataBoard",
      },
      {
        id: "newsSearch",
        path: "/manage/newsSearch",
      },
    ],
  },
  {
    id: "customerManage",
    path: "/manage/customerList",
  },
  {
    id: "configurationManage",
    children: [
      {
        id: "mockPortfolio",
        path: "/manage/portfolioList",
      },
    ],
  },
  {
    id: "assetInformation",
    children: [
      {
        id: "publicFund",
        children: [
          {
            id: "fundFilter",
            path: "/manage/factorFund",
          },
          {
            id: "stockBackCheck",
            path: "/manage/stockBackCheck",
          },
          {
            id: "replaceFund",
            path: "/manage/replaceFund",
          },
          {
            id: "optimizingFund",
            path: "/manage/preferredFund",
          },
        ],
      },
      {
        id: "ETF",
        children: [
          {
            id: "ETFFilter",
            path: "/manage/etfFilter",
          },
        ],
      },
      {
        id: "myAssetPool",
        path: "/manage/myOptional",
      },
    ],
  },
  {
    id: "assetComparison",
    path: "/manage/compareManage",
  },
  {
    id: "aum",
    path: "/manage/aum",
  },
  {
    id: "competition",
    path: "/manage/competition",
  },
];

export const leftMenuMaps = treeToMap({ idKey: "path", needPath: true })(
  leftMenu
);
