import React from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import { Card, Rate, Table } from "antd";
import { useAppSelector } from "@/hooks/redux";
import { fundInfoDataIndicators } from "@/views/fundDetail/constant";
import { map } from "lodash/fp";
import { fixedNumber } from "@/util/numberFormatter";
import { FundManagerRatings } from "@/model/fundDetail";
import { useTableDataSource } from "@/views/fundDetail/fundDetailInfo/hook";
import mapper from "../mapper";
import style from "../index.module.less";

function dataSourceRender(v: any) {
  switch (fastProp("id")(v)) {
    case "fundCompany":
      return v?.value?.name;
    case "fundManager":
      return map((manager: FundManagerRatings) => (
        <div key={manager.managerId}>
          {manager.fundManagerName}
          &nbsp;&nbsp;
          <Rate disabled defaultValue={manager.rating} />
        </div>
      ))(v?.value);
    case "fundScale":
      return fixedNumber(v?.value);
    case "fundRating":
      return v.value && <Rate disabled value={v.value} />;
    default:
      return v?.value;
  }
}
const columns = [
  { key: "0", dataIndex: "0", render: dataSourceRender },
  {
    key: "1",
    dataIndex: "1",
    render: dataSourceRender,
    className: style.tableOtherColorNumber,
  },
  { key: "2", dataIndex: "2", render: dataSourceRender },
  {
    key: "3",
    dataIndex: "3",
    render: dataSourceRender,
    className: style.tableOtherColorNumber,
  },
];

export default React.memo(({ fundId }: { fundId: string }): JSX.Element => {
  const formatMessage = useFormatMessage();
  const { fundInfo } = useAppSelector((state) => mapper(state, fundId));
  const dataSource = useTableDataSource(fundInfo, fundInfoDataIndicators);
  return (
    <>
      <Card bordered={false} size="small">
        <div className={style.Title}>{formatMessage("fundInfo")}</div>
        <Table
          dataSource={dataSource}
          showHeader={false}
          pagination={false}
          bordered
          columns={columns}
          className={style.Table}
        />
      </Card>
    </>
  );
});
