import React, { LegacyRef } from "react";
import cn from "classnames";
import { prop } from "lodash/fp";

import { useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";

import PageHeader from "../../pageHeader";
import style from "./index.module.less";
import StyleDynamicAlert from "./styleDynamicAlert";
import RecentSituationOfHeavyWarehouseIndustry from "./recentSituationOfHeavyWarehouseIndustry";
import RecentStatusOfMainFactors from "./recentStatusOfMainFactors";
import { EvaluationReportRiskSummaryBody } from "@/model/fundDetail";
import { getProsperity } from "./constants";

export default React.forwardRef(
  ({ fundId }: { fundId: string }, ref: LegacyRef<HTMLDivElement>) => {
    const formatMessage = useFormatMessage();

    const evaluationReportRiskSummary: EvaluationReportRiskSummaryBody =
      useAppSelector(prop(`fundDetail.${fundId}.evaluationReportRiskSummary`));

    const { totalRisk } = evaluationReportRiskSummary || {};

    return (
      <div className={style.PageWrap} ref={ref}>
        <PageHeader pageNumber={5} />
        <div className={cn(style.PageContentWrap, style.FourthPageWrap)}>
          <h1>
            二、{formatMessage("riskProbe")}
            <span className={style.RiskStatus1}>
              {totalRisk && (
                <>
                  {formatMessage("risk")}
                  {getProsperity(totalRisk)}
                </>
              )}
            </span>
          </h1>
          <StyleDynamicAlert fundId={fundId} />
          <RecentSituationOfHeavyWarehouseIndustry fundId={fundId} />
          <RecentStatusOfMainFactors fundId={fundId} />
        </div>
      </div>
    );
  }
);
