import React, { useMemo, useState } from "react";
import { prop, map, remove, isEmpty } from "lodash/fp";
import { Select, SelectProps } from "antd";
import type { LabeledValue } from "antd/es/select";
import { MinusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useAppSelector } from "@/hooks/redux";
import cn from "classnames";
import HighLight from "@/components/highLight";
import { includeStr, arrayToMap, fastHas, fastProp } from "@/util/opt";
import style from "./index.module.less";

import type { optionsType } from "../interface";
import { fundIdMapSelector, notDelistingFundsSelector } from "@/selectors/fund";
import { managerNameMapSelector } from "@/selectors/manager";
import { companyNameMapSelector } from "@/selectors/company";

export interface CompareInfo extends SelectProps<string> {
  type: "funds" | "managerName" | "companyName";
  color: string;
  removeIds?: string[];
  hideRemoveIcon?: boolean;
  onRemove?: () => any;
  customData: optionsType[] | undefined;
}

const getIdKey = (type: CompareInfo["type"]): string => {
  switch (type) {
    case "funds":
      return "fundId";
    case "companyName":
      return "id";
    case "managerName":
      return "id";
  }
};

export const useGetFilterOption = (type: CompareInfo["type"]) => {
  const fundsMap = useAppSelector(fundIdMapSelector);
  const managerNameMap = useAppSelector(managerNameMapSelector);
  const companyNameMap = useAppSelector(companyNameMapSelector);
  return useMemo(() => {
    switch (type) {
      case "funds":
        return (input: string, { value }: any = {}) => {
          const { name, code } = fastProp(value)(fundsMap) || {};
          return includeStr(input)(name) || includeStr(input)(code);
        };
      case "managerName":
        return (input: string, { value }: any = {}) => {
          const { name, py } = fastProp(value)(managerNameMap) || {};
          return includeStr(input)(name) || includeStr(input)(py);
        };
      case "companyName":
        return (input: string, { value }: any = {}) => {
          const { name, py } = fastProp(value)(companyNameMap) || {};
          return includeStr(input)(name) || includeStr(input)(py);
        };
    }
  }, [companyNameMap, fundsMap, managerNameMap, type]);
};

const useGetBaseData = (type: CompareInfo["type"]) => {
  const notDelistingFunds = useAppSelector(notDelistingFundsSelector);
  const baseData = useAppSelector<optionsType[]>(prop(`entities.${type}`));
  return type === "funds" ? notDelistingFunds : baseData;
};

const formatLabel = (data: Record<string, any>): string => {
  const name = fastProp("name")(data);
  const code = fastProp("code")(data);
  if (!code) return name;
  return `${name} (${code})`;
};
export default React.memo<CompareInfo>(
  ({
    type,
    color,
    showSearch = true,
    className,
    removeIds = [],
    onRemove,
    value,
    hideRemoveIcon = true,
    customData,
    ...rest
  }) => {
    const baseData = useGetBaseData(type);

    const idKey = getIdKey(type);
    const data = useMemo(() => {
      if (isEmpty(removeIds)) return baseData;
      const removeIdMap = arrayToMap(removeIds);
      return remove((item: optionsType) =>
        fastHas(fastProp(idKey)(item))(removeIdMap)
      )(customData || baseData);
    }, [baseData, customData, idKey, removeIds]);

    const selectData = useMemo(
      () =>
        map<optionsType, LabeledValue>((item) => ({
          label: formatLabel(item),
          value: fastProp(idKey)(item),
        }))(data),
      [data, idKey]
    );

    const selectBorderStyle = useMemo(
      () => ({
        borderLeft: `4px solid ${color}`,
      }),
      [color]
    );
    const [searchValue, changeSearchValue] = useState<string>("");

    const filterOption = useGetFilterOption(type);
    return (
      <div className={style.SelectCompare}>
        <Select
          {...rest}
          filterOption={filterOption}
          value={value}
          onSearch={changeSearchValue}
          showSearch={showSearch}
          className={cn(style.Selector, className)}
          style={selectBorderStyle}
          suffixIcon={<SearchOutlined />}
        >
          {map<LabeledValue, JSX.Element>((item) => (
            <Select.Option key={item.value} value={item.value}>
              <HighLight
                value={fastProp("label")(item)}
                keyValue={searchValue}
              />
            </Select.Option>
          ))(selectData)}
        </Select>
        {hideRemoveIcon ? null : (
          <MinusCircleOutlined
            className={style.RemoveIcon}
            onClick={() => onRemove && onRemove()}
          />
        )}
      </div>
    );
  }
);
