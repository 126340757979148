import TradingDatePicker from "@/components/tradingDatePicker";
import { useMemoizedFn, useSafeState } from "ahooks";
import { Button, Form, Input, message, Popover, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  CreateScenarioParams,
  validateScenarioName,
} from "@/api/portfolioAnalysis";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { identity, includes, isEmpty, set, size, some } from "lodash/fp";
import React, { useCallback, useEffect, useRef } from "react";
import { portfolioNameReg } from "@/util/formRules";
import style from "./index.module.less";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { useAppDispatch } from "@/hooks/redux";
import { createScenario } from "@/store/scenarioList";
import { fastProp } from "@/util/opt";

dayjs.extend(isSameOrBefore);

const nameValidator = (name: string, formatMessage: FormatMessageFunc) => {
  if (isEmpty(name)) return formatMessage("scenarioEmptyError");
  if (size(name) > 20) return formatMessage("scenarioLengthError");
  if (includes(" ")(name)) return formatMessage("scenarioSpaceError");
  if (!portfolioNameReg.test(name)) return formatMessage("scenarioNameError");
  return "";
};

const getDefaultFormData = () => ({
  name: "",
  startDate: "",
  endDate: "",
});
const useManageFormData = () => {
  const formatMessage = useFormatMessage();
  const [formData, setFormData] = useSafeState<CreateScenarioParams>(
    getDefaultFormData()
  );
  const updateFormData = useMemoizedFn(
    (key: string) => (value: string) => setFormData(set(key, value))
  );
  const [errorField, setErrorField] = useSafeState({
    nameError: "",
    startDateError: "",
    endDateError: "",
  });
  const validateName = useMemoizedFn(() =>
    setErrorField((errors) => ({
      ...errors,
      nameError: nameValidator(formData.name, formatMessage),
    }))
  );
  const validateStartDateError = useMemoizedFn(() =>
    setErrorField((errors) => ({
      ...errors,
      startDateError: isEmpty(formData.startDate)
        ? formatMessage("startDateEmptyError")
        : "",
    }))
  );
  const validateEndDateError = useMemoizedFn(() =>
    setErrorField((errors) => ({
      ...errors,
      endDateError: isEmpty(formData.endDate)
        ? formatMessage("endDateEmptyError")
        : "",
    }))
  );
  return {
    formData,
    setFormData,
    updateFormData,
    errorField,
    validateName,
    setErrorField,
    validateEndDateError,
    validateStartDateError,
  };
};
const useManagePopover = (setFormData: (formData: any) => any) => {
  const popoverRef = useRef<any>(null);
  const closePopover = useMemoizedFn(() => {
    setFormData(getDefaultFormData());
    popoverRef.current && popoverRef.current?.close();
  });
  return { popoverRef, closePopover };
};

export default React.memo(() => {
  const formatMessage = useFormatMessage();
  const {
    formData,
    setFormData,
    updateFormData,
    validateName,
    setErrorField,
    errorField,
    validateStartDateError,
    validateEndDateError,
  } = useManageFormData();
  const disabledEndDate = useCallback(
    (date: string) => dayjs(date).isSameOrBefore(formData.startDate),
    [formData.startDate]
  );
  const disabledStartDate = useCallback(
    (date: string) => dayjs(date).isSameOrAfter(formData.endDate),
    [formData.endDate]
  );
  const dispatch = useAppDispatch();
  const [buttonDisabled, setButtonDisabled] = useSafeState(true);
  useEffect(() => {
    setButtonDisabled(some(identity)(errorField));
  }, [errorField, setButtonDisabled]);
  const onCreateScenario = useMemoizedFn(() => {
    validateName();
    validateStartDateError();
    validateEndDateError();
    if (some(isEmpty)(formData)) return;
    setButtonDisabled(true);
    validateScenarioName(formData.name)
      .then(() => {
        dispatch(createScenario(formData))
          .unwrap()
          .then(() => {
            message.success(formatMessage("createSuccess"));
            closePopover();
          })
          .finally(() => {
            setButtonDisabled(false);
          });
      })
      .catch((error) => {
        const errorMessage = fastProp("scenario")(error?.message);
        if (errorMessage) {
          setErrorField((error) => ({
            ...error,
            nameError: errorMessage,
          }));
        }
      });
  });
  const { popoverRef, closePopover } = useManagePopover(setFormData);
  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      ref={popoverRef}
      content={
        <Form className={style.CreateScenarioForm}>
          <Form.Item
            label={`* ${formatMessage("scenarioName")}`}
            validateStatus={errorField.nameError ? "error" : ""}
            help={errorField.nameError}
          >
            <Input
              value={formData.name}
              onBlur={validateName}
              className={style.InputWidth}
              onChange={(e) => updateFormData("name")(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={`* ${formatMessage("startDate")}`}
            validateStatus={errorField.startDateError ? "error" : undefined}
            help={errorField.startDateError}
          >
            <TradingDatePicker
              value={formData.startDate}
              className={style.InputWidth}
              onBlur={validateStartDateError}
              disabledDate={disabledStartDate}
              onChange={updateFormData("startDate")}
            />
          </Form.Item>
          <Form.Item
            label={`* ${formatMessage("endDate")}`}
            validateStatus={errorField.endDateError ? "error" : undefined}
            help={errorField.endDateError}
          >
            <TradingDatePicker
              value={formData.endDate}
              className={style.InputWidth}
              onBlur={validateEndDateError}
              onChange={updateFormData("endDate")}
              disabledDate={disabledEndDate}
            />
          </Form.Item>
          <Space className={style.OperateArea}>
            <Button onClick={closePopover}>{formatMessage("cancel")}</Button>
            <Button
              onClick={onCreateScenario}
              disabled={buttonDisabled}
              type="primary"
            >
              {formatMessage("ok")}
            </Button>
          </Space>
        </Form>
      }
    >
      <Button className={style.CreateScenario} icon={<PlusOutlined />}>
        {formatMessage("createScenario")}
      </Button>
    </Popover>
  );
});
