import { Button, Progress } from "antd";
import React from "react";
import { ReloadOutlined, CloseCircleFilled } from "@ant-design/icons";
import style from "./index.module.less";
import { useFormatMessage } from "@/util/formatMessage";

type ProgressProp = {
  name: string;
  status: "active" | "success" | "normal" | "exception";
  percent: number;
  reUpload: () => void;
  deleteFile: () => void;
};

export default React.memo(
  ({ name, status, percent, reUpload, deleteFile }: ProgressProp) => {
    const formatMessage = useFormatMessage();
    return (
      <div className={style.Progress}>
        <div className={style.StatusHead}>
          <div>{name}</div>
          {percent === 100 && (
            <div className={style.uploadSucceeded}>
              {formatMessage("uploadSucceeded")}
            </div>
          )}
          {percent < 100 && (
            <div className={style.DelectIcon}>
              <Button type="link" size="small" className={style.ButtonColor}>
                <CloseCircleFilled />
              </Button>
            </div>
          )}
          {status === "exception" && (
            <div>
              <Button type="link" size="small" onClick={reUpload}>
                <ReloadOutlined />
              </Button>
              <Button type="link" size="small" onClick={deleteFile}>
                <CloseCircleFilled />
              </Button>
            </div>
          )}
        </div>
        <Progress
          percent={percent}
          showInfo={false}
          trailColor="#DEE5EF"
          status={status}
          className={style.UploadProgress}
        />
      </div>
    );
  }
);
