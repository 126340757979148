import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { useAppSelector } from "@/hooks/redux";
import cn from "classnames";
import {
  brinsonLineChartSelector,
  brinsonTreeChartDataSelector,
  brinsonTableDataSelector,
  brinsonAttributionPeriodSelector,
} from "./mapper";
import { brinsonAttributionSelector } from "./commonSelector";
import BrinsonLineChart from "./components/brinsonLineChart";
import BrinsonTreeChart from "./components/brinsonTreeChart";
import BrinsonTable from "./components/brinsonTable";
import {
  fetchBrinsonAttributionReturn,
  updatePortfolioPerformanceAttribution,
} from "@/store/portfolioAnalysis";
import CustomHeader from "../components/customHeader";
import { fastProp } from "@/util/opt";
import SummaryCard from "../../components/summaryCard";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import { first, last, isEmpty } from "lodash/fp";
import { formatPercentage } from "@/util/numberFormatter";
import style from "./index.module.less";
import { PortfolioAnalysisProps } from "../..";
import { analysisBasicInfoSelector } from "../../selectors";
import { useCreation, useMemoizedFn } from "ahooks";
import LoadingComponent from "@/components/LoadingComponent";
import { PortfolioAnalysisContext } from "@/providers/portfolioAnalysisProvider";
import ErrorBoundary from "@/components/errorBoundary";
import { Space } from "antd";
import { errorValidator } from "@/components/errorBoundary/constant";

const needTime = 2;
const getStatisticText = (
  brinsonTreeChartData: Record<string, any>,
  dates: string[],
  formatMessage: FormatMessageFunc
) => {
  const allocationAttribution = fastProp("allocationAttribution")(
    brinsonTreeChartData
  );
  const selectionAttribution = fastProp("selectionAttribution")(
    brinsonTreeChartData
  );
  let summaryText = "";
  if (Math.abs(allocationAttribution) > Math.abs(selectionAttribution)) {
    if (allocationAttribution > 0) {
      summaryText = formatMessage(
        "brinsonAttributionAllocationAttributionPositiveTip",
        {
          from: first(dates),
          to: last(dates),
          value: formatPercentage(allocationAttribution),
        }
      );
    } else {
      summaryText = formatMessage(
        "brinsonAttributionAllocationAttributionNegativeTips",
        {
          from: first(dates),
          to: last(dates),
          value: formatPercentage(allocationAttribution),
        }
      );
    }
  } else {
    if (selectionAttribution > 0) {
      summaryText = formatMessage(
        "brinsonAttributionSelectionAttributionPositiveTip",
        {
          from: first(dates),
          to: last(dates),
          value: formatPercentage(selectionAttribution),
        }
      );
    } else {
      summaryText = formatMessage("brinsonAttributionSelectionNegativeTip", {
        from: first(dates),
        to: last(dates),
        value: formatPercentage(selectionAttribution),
      });
    }
  }
  return summaryText;
};

export default React.memo((props: PortfolioAnalysisProps) => {
  const { id } = props;

  const brinsonLineChart = useAppSelector((state) =>
    brinsonLineChartSelector(state, id)
  );
  const brinsonTreeChartData = useAppSelector((state) =>
    brinsonTreeChartDataSelector(state, id)
  );
  const brinsonTableData = useAppSelector((state) =>
    brinsonTableDataSelector(state, id)
  );
  const brinsonAttributionReturn = useAppSelector((state) =>
    brinsonAttributionSelector(state, id)
  );
  const frequency = useAppSelector((state) =>
    brinsonAttributionPeriodSelector(state, id)
  );
  const { runningTime, dispatch } = useContext(PortfolioAnalysisContext);
  const formatMessage = useFormatMessage();

  const { endDate, startDate } = useAppSelector((state) =>
    analysisBasicInfoSelector(state, id)
  );

  const dates = useMemo(
    () => fastProp("dates")(brinsonAttributionReturn),
    [brinsonAttributionReturn]
  );

  const updateHandler = useCallback(
    (key: string) => (value: any) =>
      dispatch(
        needTime,
        updatePortfolioPerformanceAttribution({
          id,
          key,
          value,
        })
      ),
    [dispatch, id]
  );
  const [statisticRange, setStatisticRange] = useState("FROM_CREATION");
  useEffect(() => {
    updateHandler("section")(statisticRange);
  }, [statisticRange, updateHandler]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const startCalculate = useMemoizedFn(() => {
    setBtnDisabled(true);
    if (isEmpty(dates)) {
      dispatch(
        needTime,
        fetchBrinsonAttributionReturn({
          id,
          section: statisticRange,
        })
      );
    }
  });

  const statisticText = getStatisticText(
    brinsonTreeChartData,
    dates,
    formatMessage
  );
  const errorKey = useCreation(
    () => errorValidator.lessTwoTradingDate(needTime, runningTime),
    [runningTime]
  );

  return (
    <LoadingComponent actions={fetchBrinsonAttributionReturn}>
      <Space direction="vertical" className={style.FullWidth}>
        <CustomHeader
          value={statisticRange}
          title="attributionTime"
          onChange={(value) => {
            setStatisticRange(value);
            setBtnDisabled(false);
            updateHandler("section")(value);
          }}
          startDate={startDate}
          endDate={endDate}
          dates={dates}
          onStartCalculate={startCalculate}
          disabled={btnDisabled || !!errorKey}
        />
        <ErrorBoundary errKey={errorKey}>
          {!isEmpty(dates) ? (
            <>
              <BrinsonLineChart
                brinsonLineChart={brinsonLineChart}
                id={id}
                section={statisticRange}
              />
              <div className={style.Flex}>
                <div className={cn(style.Chart, style.MarginTop85)}>
                  <BrinsonTreeChart
                    brinsonTreeChartData={brinsonTreeChartData}
                  />
                </div>
                <div className={style.detailTable}>
                  <BrinsonTable
                    selectFrequency={updateHandler("frequency")}
                    brinsonTableData={brinsonTableData}
                    frequency={frequency}
                  />
                </div>
              </div>
              {statisticText ? (
                <SummaryCard summaryText={statisticText} />
              ) : null}
            </>
          ) : (
            <div className={style.Empty}></div>
          )}
        </ErrorBoundary>
      </Space>
    </LoadingComponent>
  );
});
