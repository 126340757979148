import LoadingComponent from "@/components/LoadingComponent";
import { getFundManagerManageFundInfo } from "@/store/fundManagerDetailSlice";
import { useFormatMessage } from "@/util/formatMessage";
import { Table, Tabs } from "antd";
import cn from "classnames";
import { size } from "lodash/fp";
import React, { useMemo } from "react";
import { BaseFundManagerDetailInfoProps } from "../../interface";
import { getTableColumns } from "../constant";
import { useGetFundTableData } from "../hooks";
import style from "./index.module.less";

const { TabPane } = Tabs;
export default React.memo<
  BaseFundManagerDetailInfoProps & {
    className?: string;
  }
>(({ className, id }) => {
  const formatMessage = useFormatMessage();
  const columns = useMemo(() => getTableColumns(), []);
  const { tubeFund, outgoingFunds } = useGetFundTableData(id);
  return (
    <LoadingComponent actions={getFundManagerManageFundInfo}>
      <Tabs
        defaultActiveKey="tubeFund"
        className={cn(style.FundTabs, className)}
      >
        <TabPane
          tab={`${formatMessage("tubeFund")}(${size(tubeFund)})`}
          key="tubeFund"
        >
          <Table
            className={style.ManageFundTable}
            pagination={false}
            columns={columns}
            dataSource={tubeFund}
            scroll={{
              y: 410,
            }}
          />
        </TabPane>
        <TabPane
          tab={`${formatMessage("outgoingFunds")}(${size(outgoingFunds)})`}
          key="outgoingFunds"
        >
          <Table
            pagination={false}
            columns={columns}
            dataSource={outgoingFunds}
            className={style.ManageFundTable}
            scroll={{
              y: 410,
              x: 600,
            }}
          />
        </TabPane>
      </Tabs>
    </LoadingComponent>
  );
});
