import React from "react";
import { useFormatMessage } from "@/util/formatMessage";
import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import portfolioOverviewIcon from "@/assets/portfolioAnalysisIcons/portfolioOverview.png";
import { CommonProps } from "../../type";
import style from "../../index.module.less";
import KeyIndex from "../../positionStructure/keyIndex";

export default React.memo<CommonProps>(
  ({ id, assetPortfolioId, managerName }) => {
    const formatMessage = useFormatMessage();
    const tip: CardLayoutTipType = [
      [formatMessage("keyIndicator"), formatMessage("keyIndicatorTip")],
    ];
    return (
      <CardLayout
        title={formatMessage("portfolioOverview")}
        icon={portfolioOverviewIcon}
        tip={tip}
      >
        <div className={style.CardContent}>
          <KeyIndex
            id={id}
            assetPortfolioId={assetPortfolioId}
            managerName={managerName}
          />
        </div>
      </CardLayout>
    );
  }
);
