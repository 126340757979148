import { fundBasicInfoSelector } from "../../../../../mapper";
import { createSelector } from "@reduxjs/toolkit";
import { fastProp } from "@/util/opt";
import { prop} from "lodash/fp";
export const fundAttributionBrinsonResultSelector = createSelector(
  fundBasicInfoSelector,
  (fundBasicInfo) => fastProp("fundAttributionBrinsonResult")(fundBasicInfo)
);
export const fundAttributionBrinsonProgressSelector = createSelector(
  fundBasicInfoSelector,
  (fundBasicInfo) => fastProp("fundAttributionBrinsonProgress")(fundBasicInfo)
);
export const fundAttributionBrinsonProgressArgSelector = createSelector(
  fundAttributionBrinsonProgressSelector,
  (fundAttributionBrinsonProgress) =>
    fastProp("arg")(fundAttributionBrinsonProgress)
);
export const fundDetailIsETFSelector = createSelector(
  fundBasicInfoSelector,
  (fundBasicInfo) => {
    const ETFTimeRange = prop(`fundDetailIndustryTrendDate`)(fundBasicInfo)
    return fastProp("isETF")(ETFTimeRange)
  }
);
