import { useMemoizedFn } from "ahooks";
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Input, Modal, Select, Space, Table } from "antd";
import {
  concat,
  debounce,
  find,
  flow,
  map,
  prop,
  reduce,
  remove,
  size,
} from "lodash/fp";
import cn from "classnames";

import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { parseToThousandth } from "@/util/numberFormatter";
import { CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import {
  clearCustomerAccountAnalysisData,
  fetchAccountInfo,
  fetchPositionAssetsList,
  positionAssetTransaction,
  updateCustomerAccountId,
} from "@/store/customerAccountAnalysis";
import {
  AssetItemType,
  PositionAssetsListBody,
  PositionAssetsListItem,
  PositionAssetTransactionParam,
} from "@/model/customer";
import { fastProp, normalize } from "@/util/opt";

import { useGetAccountList } from "../../customerAccountAnalysis/customerSummary/hook";
import { totalAccountProp } from "../../customerAccountAnalysis/customerSummary";
import {
  useGetColumns,
  useGetDataSource,
  useGetIsActionLoading,
  useGetSubmitTableColumns,
} from "./hooks";
import style from "./index.module.less";
import LoadingComponent from "@/components/LoadingComponent";
import AddAssetInfoDialog from "../addAssetInfoDialog";
import { useWindowSizeContext } from "@/providers/windowSizeProvider";
import { customerAnalysisSelector } from "../../customerAccountAnalysis/selectors";
import { RootState } from "@/store";
import { isEmpty } from "lodash";

const { Option } = Select;

export default React.memo(
  ({
    customerId,
    totalAccountId,
    customerName,
  }: {
    customerId: string;
    totalAccountId: string;
    customerName: string;
  }) => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();
    const accountList = useGetAccountList({ totalAccountId, customerName });

    const [accountId, setAccountId] = useState(totalAccountId);
    const [searchStr, setSearchStr] = useState("");

    const accountAnalysisData = useAppSelector((state: RootState) =>
      customerAnalysisSelector(state, accountId)
    );
    const accountInfo = useMemo(
      () => fastProp("accountInfo")(accountAnalysisData),
      [accountAnalysisData]
    );
    useEffect(() => {
      if (accountId) {
        dispatch(
          fetchPositionAssetsList({
            accountId,
            forceToRefetch: accountId === totalAccountId,
          })
        );
        if (isEmpty(accountInfo)) dispatch(fetchAccountInfo(accountId));
      }
    }, [accountId, accountInfo, dispatch, totalAccountId]);

    const [purchaseAndRedeemList, setPurchaseAndRedeemList] = useState<
      PositionAssetTransactionParam["assets"]
    >([]);

    const positionAssetsList: PositionAssetsListBody =
      useAppSelector(
        prop(`customerAnalysis.${accountId}.positionAssetsList`)
      ) || {};
    const columns = useGetColumns({
      accountId,
      isTotalAccount: accountId === totalAccountId,
      accountList,
      setPurchaseAndRedeemList,
    });
    const dataSource = useGetDataSource({
      positionAssetsList,
      searchStr,
      customerName: flow(
        find(["id", accountId]),
        fastProp("message")
      )(accountList),
      accountId,
      totalAccountId,
    });
    const totalScale = reduce(
      (prev: number, curr: PositionAssetsListItem) => prev + curr.totalScale
    )(0)(positionAssetsList);

    const handleSearch = useMemoizedFn(
      debounce(300)(({ target: { value } }) => {
        setSearchStr(value);
      })
    );

    const [expandedRowKeys, setExpandedRowKeys] = useState([
      "OPEN_FUND",
      "BOND",
      "STOCK",
    ] as AssetItemType["assetType"][]);

    const handleExpandRow = useMemoizedFn((expanded, record) => {
      if (expanded)
        setExpandedRowKeys(concat(expandedRowKeys)(fastProp("key")(record)));
      else
        setExpandedRowKeys(
          remove(
            (v: AssetItemType["assetType"]) => fastProp("key")(record) === v
          )(expandedRowKeys)
        );
    });
    const { containerHeight } = useWindowSizeContext();

    const handleDeleteSubmitItem = useMemoizedFn((assetCode: string) => {
      setPurchaseAndRedeemList((preState) =>
        remove(
          (item: PositionAssetTransactionParam["assets"][number]) =>
            fastProp("assetCode")(item) === assetCode
        )(preState)
      );
    });

    const submitTableColumns = useGetSubmitTableColumns(
      positionAssetsList,
      handleDeleteSubmitItem
    );
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const handleCancel = useMemoizedFn(() => {
      setPurchaseAndRedeemList([]);
    });
    const handleOpenSubmitModal = useMemoizedFn(() => {
      setSubmitModalOpen(true);
    });
    const handleSubmit = useMemoizedFn(() => {
      if (size(purchaseAndRedeemList) > 0)
        dispatch(
          positionAssetTransaction({
            accountId,
            assets: purchaseAndRedeemList,
          })
        ).then(() => {
          dispatch(
            fetchPositionAssetsList({
              accountId,
              forceToRefetch: true,
            })
          );
          dispatch(clearCustomerAccountAnalysisData());
          dispatch(updateCustomerAccountId(customerId));
          dispatch(fetchAccountInfo(customerId));
          setPurchaseAndRedeemList([]);
          setSubmitModalOpen(false);
        });
    });
    const isTransaction = useGetIsActionLoading(positionAssetTransaction);

    const isShowSubmit = useMemo(
      () => size(purchaseAndRedeemList) > 0,
      [purchaseAndRedeemList]
    );

    const accountIdMap = useMemo(
      () => normalize("id")(accountList),
      [accountList]
    );

    const handleChangeAccount = useMemoizedFn((value: string) => {
      if (isShowSubmit) {
        Modal.error({
          title: formatMessage("notice"),
          content: formatMessage("submitPositionError"),
        });
        return;
      }
      setAccountId(value);
    });

    return (
      <div className={style.AssetInformationWrap}>
        <div className={style.TableHeader}>
          <Space size={30}>
            <Select
              value={accountId}
              onChange={handleChangeAccount}
              dropdownMatchSelectWidth={false}
            >
              {map((item: totalAccountProp) => (
                <Option key={item.id} value={item.id}>
                  {item.message}
                </Option>
              ))(accountList)}
            </Select>
            <div>
              {formatMessage("totalAssets")}：
              <span>{parseToThousandth(totalScale)}</span>
              {formatMessage("YUAN")}
            </div>
          </Space>
          <Space size={"middle"}>
            <div className={style.SearchWrapper}>
              <Input
                placeholder={formatMessage("searchAssetsPlaceholder")}
                onChange={handleSearch}
              />
            </div>
            <Button icon={<CloudUploadOutlined />} type="primary" disabled>
              {formatMessage("uploadPosition")}
            </Button>
            <AddAssetInfoDialog
              accountId={customerId}
              totalAccountId={totalAccountId}
              customerName={customerName}
            >
              {(onChangeVisible) => (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={onChangeVisible}
                >
                  {formatMessage("assetAdjustment")}
                </Button>
              )}
            </AddAssetInfoDialog>
          </Space>
        </div>
        <div>
          <LoadingComponent actions={fetchPositionAssetsList}>
            <Table
              columns={columns}
              dataSource={dataSource}
              className={style.Table}
              expandable={{ expandedRowKeys, onExpand: handleExpandRow }}
              pagination={false}
              scroll={{ y: containerHeight - 380 }}
              rowClassName={(record: Record<string, any>, index: number) => {
                if (record.key) {
                  if (record.key === "cashScale")
                    return cn(
                      style[`cashScale`],
                      index % 2 !== 0 && style.OddRow
                    );
                  return cn(
                    style[`stickyRow${record.stickyRowIndex}`],
                    index % 2 !== 0 && style.OddRow
                  );
                }
                return "";
              }}
            />
          </LoadingComponent>
        </div>
        {isShowSubmit && (
          <div className={style.LayoutFooter}>
            <Space>
              <Button onClick={handleCancel}>{formatMessage("cancel")}</Button>
              <Button
                className={style.OperateButton}
                type="primary"
                onClick={handleOpenSubmitModal}
              >
                {formatMessage("submitPosition")}
              </Button>
            </Space>
            <Modal
              open={submitModalOpen}
              onOk={handleSubmit}
              onCancel={() => setSubmitModalOpen(false)}
              width={1500}
              title={formatMessage("confirmPosition")}
              confirmLoading={isTransaction}
            >
              <Alert
                message={formatMessage("confirmPositionTip")}
                type="info"
                showIcon
                className={style.ConfirmPositionTip}
              />
              <div className={style.AccountLabel}>
                {prop(`${accountId}.message`)(accountIdMap)}
              </div>
              <Table
                columns={submitTableColumns}
                dataSource={purchaseAndRedeemList}
                pagination={false}
                scroll={{ y: 600 }}
                className={style.SubmitTable}
              />
            </Modal>
          </div>
        )}
      </div>
    );
  }
);
