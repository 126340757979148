import { useFormatMessage } from "@/util/formatMessage";
import { Card, Radio, Row, Col } from "antd";
import {
  filter,
  flow,
  fromPairs,
  includes,
  map,
  maxBy,
  pick,
  prop,
  set,
  size,
  some,
  toArray,
} from "lodash/fp";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import style from "../index.module.less";
import {
  COMMON_TIME,
  FROM_CREATION,
  RECENT_FIVE_YEAR,
  RECENT_THREE_YEAR,
  RECENT_YEAR,
  yieldStatisticRange,
} from "@/constant/statisticRange";
import BarChart, { BarChartOpts } from "@/echarts/barChart";
import inStyle from "../../fundCompare/components/index.module.less";
import { fastNth, fastProp, mapIndexed } from "@/util/opt";
import { fixedHundredMillion } from "@/util/numberFormatter";
import { getLastDateOfYear, getRectangleLegendConfig } from "@/util/chart";
import { SCALE_COLORS } from "@/util/colors";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { fetchFundManagerCompareScaleChange } from "@/store/compareManage";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import { useSelector } from "react-redux";
import { fundManagerMapSelector } from "../selectors";
import { useCreation } from "ahooks";
import LoadingComponent from "@/components/LoadingComponent";
import { useCalculateScaleRangeDate } from "@/constant/statisticRangeCalculator/rangeCalculator";
import EmptyContent from "@/components/emptyContent";

const options = flow(
  pick([
    COMMON_TIME,
    RECENT_YEAR,
    RECENT_THREE_YEAR,
    RECENT_FIVE_YEAR,
    FROM_CREATION,
  ]),
  set(`${RECENT_YEAR}.message`, "oneYear"),
  set(`${RECENT_THREE_YEAR}.message`, "threeYears"),
  set(`${RECENT_FIVE_YEAR}.message`, "fiveYears"),
  set(`${FROM_CREATION}.message`, "sinceTheIndustry"),
  toArray
)(yieldStatisticRange);

const useGetChartData = (managerIds: string[], scaleRange: string) => {
  const scaleChangeInfo = useAppSelector(
    prop("compareManage.fundManager.ScaleChangeInfo")
  );
  const fundManagerMap = useSelector(fundManagerMapSelector);

  const fundManagerInfo = useMemo(
    () =>
      map((item: Record<string, any>) => {
        return {
          id: item.id,
          name: prop(`${item.id}.name`)(fundManagerMap),
          dates: item.dates,
          scales: item.scales,
          dailyScalesMap: normalizeDailyReturnsMap(item.dates, item.scales),
        };
      })(scaleChangeInfo),
    [scaleChangeInfo, fundManagerMap]
  );
  const calculatedDates = useCalculateScaleRangeDate(
    map("dates")(fundManagerInfo),
    undefined,
    scaleRange
  );
  const categories = useCreation(
    () =>
      flow(
        maxBy(size),
        filter((date) =>
          some(({ dates }) => includes(date)(dates))(scaleChangeInfo)
        )
      )(calculatedDates),
    [calculatedDates, scaleChangeInfo]
  );

  const formatMessage = useFormatMessage();

  const dataSource = useMemo<BarChartOpts["options"]>(() => {
    const yearOfDates = getLastDateOfYear(categories);
    return {
      categories: categories,
      grid: {
        top: 60,
        bottom: 10,
      },
      yAxis: {
        axisLabel: {
          formatter(val: number) {
            return fixedHundredMillion(val);
          },
        },
      },
      legend: getRectangleLegendConfig(),
      tooltip: {
        valueFormatter: (value) => {
          return fixedHundredMillion(value as number);
        },
      },
      xAxis: {
        nameGap: 40,
        axisLabel: {
          interval: (index: number, value: string) => {
            const year = fastProp(value)(yearOfDates);
            return year ? true : false;
          },
          formatter(value: string) {
            return `${fastProp(value)(yearOfDates)}${formatMessage("year")}`;
          },
          align: "right",
        },
      },
      data: flow(
        map(({ name, dailyScalesMap }) => [
          name,
          map((date: string) => fastProp(date)(dailyScalesMap))(categories),
        ]),
        fromPairs
      )(fundManagerInfo),
      series: mapIndexed((_: any, index: number) => ({
        color: fastNth(index)(SCALE_COLORS),
        barMaxWidth: 40,
      }))(fundManagerInfo),
    };
  }, [categories, formatMessage, fundManagerInfo]);

  return { calculatedDates, dataSource };
};

export default React.memo<{
  managerIds: string[];
}>(({ managerIds }): JSX.Element => {
  const [scaleRange, setScaleRange] = useState(COMMON_TIME);
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const { calculatedDates, dataSource } = useGetChartData(
    managerIds,
    scaleRange
  );
  const onChange = useCallback((e) => setScaleRange(e.target.value), []);
  useEffect(() => {
    dispatch(fetchFundManagerCompareScaleChange(managerIds));
  }, [managerIds, dispatch]);
  return (
    <LoadingComponent actions={fetchFundManagerCompareScaleChange}>
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <h3 className={style.margin16}>
              {formatMessage("manageFundScaleChange")}
            </h3>
          </Col>
          <Col>
            <div className={style.FundManagerSelect}>
              <Radio.Group value={scaleRange} onChange={onChange}>
                {map(({ id, message }) => (
                  <Radio.Button value={id}>
                    {formatMessage(message)}
                  </Radio.Button>
                ))(options)}
              </Radio.Group>
            </div>
          </Col>
        </Row>
        {size(calculatedDates) ? (
          <div className={inStyle.BarChart}>
            <span className={inStyle.BarChartUnitTip}>{`（${formatMessage(
              "million"
            )}）`}</span>
            <BarChart options={dataSource} />
          </div>
        ) : (
          <EmptyContent
            className={style.EmptyContent}
            message={formatMessage("noData")}
          />
        )}
      </Card>
    </LoadingComponent>
  );
});
