import { PromiseType } from "@/model/promise";
import { post } from "@/util/axios";
import { DatePeriodName } from "@/util/business-core/datePeriod";

export type DatePeriodReturnParam = {
  fundIds: string[];
  section: DatePeriodName;
};

export const getDatePeriodReturn = (
  params: DatePeriodReturnParam
): PromiseType<Record<string, number>> =>
  post("/openfunds/section_return", params);
