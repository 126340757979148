import { Button, Form, Input, Row, Col, message } from "antd";
import { useNavigate } from "react-router";
import { useCallback, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import {
  RegisterEntity,
  createEmptyRegister,
  createRegisterFormStatus,
  RegisterFormStatusInterface,
} from "@/model/login";
import { register } from "@/api/userConfig";
import { useFormStatus } from "@/hooks/formStatus";
import style from "./index.module.less";
import CircleToolTip from "./circleToolTip";
import { passwordReg } from "@/util/formRules";

function useRegisterFormSetting(): [
  RegisterEntity,
  (arg0: string) => React.ChangeEventHandler<HTMLInputElement>,
  () => Promise<void>
] {
  const [registerInfo, setRegisterInfo] = useState<RegisterEntity>(
    createEmptyRegister()
  );
  const changeRegisterInfo = useCallback(
    (fieldName: string) => (e: { target: { value: string } }) => {
      setRegisterInfo({
        ...registerInfo,
        [fieldName]: e.target.value,
      });
    },
    [setRegisterInfo, registerInfo]
  );
  const navigate = useNavigate();
  const onRegister = useCallback(async () => {
    try {
      await register(registerInfo);
      message.success("注册成功");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [registerInfo, navigate]);

  return [registerInfo, changeRegisterInfo, onRegister];
}

const RegisterForm = (): JSX.Element => {
  const [registerInfo, changeRegisterInfo, onRegister] =
    useRegisterFormSetting();
  const navigate = useNavigate();
  const [formStatus, setFormStatus] =
    useFormStatus<RegisterFormStatusInterface>(createRegisterFormStatus);
  const onFinish = useCallback(() => {
    onRegister();
  }, [onRegister]);
  const formatMessage = useFormatMessage();
  return (
    <div className={style.LoginFrom}>
      <Form className={style.From} labelCol={{ span: 4 }} onFinish={onFinish}>
        <div>
          欢迎注册，已有账号请
          <span
            className={style.toLogin}
            onClick={() => {
              navigate("/login");
            }}
          >
            登陆
          </span>
        </div>
        <Form.Item
          label={formatMessage("userName")}
          name="username"
          validateTrigger={"onBlur"}
          rules={[
            {
              pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
              message: formatMessage("eMailError"),
            },
            {
              required: true,
              message: formatMessage("userNameEmpty"),
            },
          ]}
        >
          <Row gutter={10} align="middle">
            <Col span={22}>
              <Input
                placeholder="请输入用户名"
                onChange={changeRegisterInfo("username")}
                value={registerInfo.username}
                onFocus={setFormStatus("username", true)}
                onBlur={setFormStatus("username", false)}
              />
            </Col>
            <Col span={1}>
              <CircleToolTip
                visible={formStatus.username}
                title={formatMessage("eMailAsUsername")}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label={formatMessage("password")}
          name="password"
          validateTrigger={"onBlur"}
          rules={[
            {
              pattern: passwordReg,
              message: formatMessage("passwordValidateMessage"),
            },
            {
              required: true,
              message: formatMessage("passwordEmpty"),
            },
          ]}
        >
          <Row gutter={10} align="middle">
            <Col span={22}>
              <Input.Password
                type="password"
                autoComplete="off"
                placeholder="请输入密码"
                onChange={changeRegisterInfo("password")}
                value={registerInfo.password}
                onFocus={setFormStatus("password", true)}
                onBlur={setFormStatus("password", false)}
              />
            </Col>
            <Col span={1}>
              <CircleToolTip
                visible={formStatus.password}
                title={formatMessage("passwordLength")}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label={formatMessage("password")}
          name="confirmedPassword"
          validateTrigger={"onBlur"}
          dependencies={["password"]}
          rules={[
            {
              pattern: passwordReg,
              message: formatMessage("passwordValidateMessage"),
            },
            {
              required: true,
              message: formatMessage("passwordEmpty"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("密码的两次输入必须相同"));
              },
            }),
          ]}
        >
          <Row gutter={10} align="middle">
            <Col span={22}>
              <Input.Password
                type="password"
                autoComplete="off"
                placeholder="请在次输入密码"
                onChange={changeRegisterInfo("confirmedPassword")}
                value={registerInfo.confirmedPassword}
                onFocus={setFormStatus("confirmedPassword", true)}
                onBlur={setFormStatus("confirmedPassword", false)}
              />
            </Col>
            <Col span={1}>
              <CircleToolTip
                visible={formStatus.confirmedPassword}
                title={formatMessage("passwordLength")}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col span={12} offset={6}>
              <Button
                htmlType="submit"
                style={{ width: "calc( 100% - 5px )" }}
                type="primary"
              >
                立即注册
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterForm;
