import { PromiseType } from "@/model/promise";
import { get, getWithCache, post } from "@/util/axios";
import { socketHttp } from "@/util/socket";
import {
  AumPortfolio,
  OrganizationType,
  IndustryDistribution,
  SectorDistribution,
  HoldStockStyle,
  ConcentrationDetectionBody,
  ConcentrationDetectionResponse,
  ChangeMonitorBody,
  ChangeMonitorResponse,
  AumPerformanceDisassembleParams,
  AssetTypeTrend,
  AssetAllocationDistributionResponse,
  HeavyPositionBondListResponse,
  BrinsonAttributionBody,
  BrinsonAttributionResponse,
  RankResponse,
  RankBody,
  factorValueResponse,
  factorValueBody,
} from "@/model/aum";
import { DatePeriodValue } from "@/base-components/datePeriodSelect";
import { PeriodTypeValue } from "@/views/aum/positionStructure/performancePreview/periodTypePicker";
import {
  SectionReturnType,
  TrendDataType,
} from "@/views/aum/positionStructure/performancePreview";
import { FactorCategory } from "@/model/factors";
import { omit } from "lodash/fp";

export const getAumOrganizations = (organizationType: OrganizationType) =>
  get("/aum/portfolio/permission", { organizationType }, { cache: true });

export const getAumPortfolios = (
  organizationId: string
): PromiseType<AumPortfolio[]> =>
  get(`/aum/portfolio-permission/${organizationId}/ids`);

export const getAumPortfolioDates = (id: string): PromiseType<string[]> =>
  get("/portfolio/aum/asset-portfolios/date/list", { portfolioId: id });

export interface AumIndustryDistributionsParam {
  id: string;
  sectorCategoryId: string;
  tradingDate: string;
}

export const getAumIndustryDistributions = (
  param: AumIndustryDistributionsParam
): PromiseType<IndustryDistribution> =>
  get(
    `/portfolio/aum/asset-portfolios/${param?.id}/analysis/aum-industry-trend`,
    {
      sectorCategoryId: param?.sectorCategoryId,
      tradingDate: param?.tradingDate,
    }
  );

export interface AumSectorDistributionsParam {
  id: string;
  sectorCategoryId: string;
}

export const getAumSectorDistributions = (
  param: AumSectorDistributionsParam
): PromiseType<SectorDistribution[]> =>
  get(
    `/portfolio/aum/asset-portfolios/${param?.id}/analysis/aum-sector-trend`,
    {
      sectorCategoryId: param?.sectorCategoryId,
    }
  );

export const getAumHoldStockStyle = (
  id: string
): PromiseType<HoldStockStyle[]> =>
  get(`/portfolio/aum/asset-portfolios/${id}/analysis/aum-style-trend`);

//集中度检测
export const getConcentrationDetection = (
  body: ConcentrationDetectionBody
): PromiseType<ConcentrationDetectionResponse[]> =>
  get(`/aum/asset-portfolios/concern-risk/${body.grpId}`, { type: body.type });

//异动风险分析
export const getChangeMonitor = (
  body: ChangeMonitorBody
): PromiseType<ChangeMonitorResponse> =>
  get(`aum/asset-portfolios/${body.aumGrpId}`, {
    type: body.type,
    section: body.section,
  });

export const getPortfolioSectionReturns = (params: {
  portfolioId: string;
  periodType: PeriodTypeValue;
}): PromiseType<SectionReturnType> =>
  get(`/portfolio/aum/asset/portfolio/factor/section/returns`, params);

export const getPortfolioPerformance = (params: {
  portfolioId: string;
  section: DatePeriodValue;
}): PromiseType<Record<string, Record<string, number | null>>> =>
  get(`/portfolio/aum/asset/portfolio/factor/performance`, params);

export const getPortfolioPerformanceTrend = (
  portfolioId: string,
  section: DatePeriodValue
): PromiseType<TrendDataType> =>
  get(`/portfolio/aum/asset/portfolio/factor/performance/trend`, {
    portfolioId,
    section,
  });

export const getAumPortfolioFactorOverview = (
  id: string
): PromiseType<Record<string, number>> => get(`/aum/factor/overview/${id}`);

export const getAumPortfolioSummary = (
  id: string
): PromiseType<Record<string, number | Record<string, string | number>>> =>
  get(`/aum/asset-portfolios/summary/${id}`);

export const getAumPortfolioIndexInfo = (
  id: string
): PromiseType<Record<string, any>> =>
  get(`/aum/asset-portfolios/portfolio/${id}`);

// 业绩拆解
export const getAumPerformanceDisassemble = (
  params: AumPerformanceDisassembleParams
): PromiseType<any> =>
  socketHttp("START_AUM_PERFORMANCE_DISSECT", {
    meta: params,
  });

// 基金类别分布
export const getAssetTypeTrend = (id: string): PromiseType<AssetTypeTrend> =>
  get(`/portfolio/aum/asset-portfolios/${id}/analysis/asset-type-trend`);

// 资产配置分布
export const getPortfolioFundDissectList = (
  id: string
): PromiseType<AssetAllocationDistributionResponse> =>
  get(`/portfolio/aum/asset-portfolios/${id}/portfolio-fund-dissect-list`);

// 重仓债券分布
export const getHeavyPositionBondList = (
  id: string
): PromiseType<HeavyPositionBondListResponse> =>
  get(
    `/portfolio/aum/asset-portfolios/${id}/heavy-position-bond-list`,
    {},
    { mocker: true }
  );

//brinson归因
export const getBrinsonAttribution = (
  payload: BrinsonAttributionBody
): PromiseType<BrinsonAttributionResponse> =>
  get(
    `/asset/portfolio/attribution/brinson/${payload?.id}/${payload?.section}`
  );

type ApiFactorCategory = {
  openFundFactorTypeView: {
    id: string;
    name: string;
  };
  openFundFactorViews: {
    id: string;
    name: string;
  }[];
};
export const getAumFactorInfo = (): PromiseType<FactorCategory[]> =>
  getWithCache<ApiFactorCategory[]>(
    "/portfolio/aum/asset-portfolios/factors"
  ).then((v) => {
    return v.map((item) => ({
      id: item.openFundFactorTypeView.id,
      name: item.openFundFactorTypeView.name,
      factorViews: item.openFundFactorViews.map((factor) => ({
        id: factor.id,
        categoryId: item.openFundFactorTypeView.id,
        name: factor.name,
        disabled: false,
        sorter: true,
      })),
    }));
  });

//排行榜查询因子值
export const getAumFactorValue = (
  payload: factorValueBody
): PromiseType<factorValueResponse> =>
  post(`/portfolio/aum/asset-portfolios/factor-values`, {
    ...omit(["key"])(payload),
  });

//排行榜
export const getRankList = (payload: RankBody): PromiseType<RankResponse> =>
  post(`/portfolio/aum/asset-portfolios/rankList`, { ...payload });
