import React, { useMemo } from "react";
import riskAnalysisIcon from "@/assets/portfolioAnalysisIcons/riskAnalysis.png";
import CardLayout, { CardLayoutTipType } from "@/components/cardLayout";
import { useFormatMessage } from "@/util/formatMessage";
import ConcentrationMonitor from "../concentrationMonitor";
import ChangeMonitor from "../changeMonitor";
import { CommonProps } from "../../type";
import { useGetAumOrganization } from "@/hooks/aum";
import { treeToMap } from "@/util/opt/tree";
import { Organization } from "@/model/aum";
import { prop } from "lodash/fp";

export default React.memo<CommonProps>(({ id }) => {
  const formatMessage = useFormatMessage();
  const organizations = useGetAumOrganization("SELECTOR");
  const organizationMap = useMemo(
    () => treeToMap<Organization>({ idKey: "organizationId" })(organizations),
    [organizations]
  );
  const isShowSubOrganizations = useMemo(
    () => (prop(`${id}.children`)(organizationMap) ? true : false),
    [organizationMap, id]
  );

  const tip: CardLayoutTipType = [
    [
      formatMessage("concentrationMonitor"),
      formatMessage("concentrationMonitorTip"),
    ],
    [formatMessage("changeDetection"), formatMessage("changeDetectionTip")],
  ];
  return (
    <div>
      <CardLayout
        icon={riskAnalysisIcon}
        title={formatMessage("riskAnalysis")}
        tip={tip}
      >
        <ConcentrationMonitor
          assetPortfolioId={id}
          isShowSubOrganizations={isShowSubOrganizations}
        />
      </CardLayout>
      <ChangeMonitor
        assetPortfolioId={id}
        isShowSubOrganizations={isShowSubOrganizations}
      />
    </div>
  );
});
