import { useFormatMessage } from "@/util/formatMessage";
import { Radio, Space, Table } from "antd";
import { flow, map, prop } from "lodash/fp";
import React, { useEffect, useState } from "react";
import AumSubTitle from "./aumSubTitle";
import {
  useGetColumns,
  useGetDataSource,
  useGetMoldColumns,
  useGetSummaryData,
} from "./hook";
import style from "./index.module.less";
import { formatPercentage } from "@/util/numberFormatter";
import { useDispatch } from "react-redux";
import { fetchConcentrationFund, fetchConcentrationType } from "@/store/aum";
import {
  dealScaleData,
  fundProductsOptions,
  fundTypeOptions,
  moldOptions,
} from "./constant";
import { fastProp, normalize } from "@/util/opt";
import LoadingComponent from "@/components/LoadingComponent";

export type itemType = {
  label: string;
  value: string;
};

export default React.memo(
  ({
    assetPortfolioId,
    isShowSubOrganizations,
  }: {
    assetPortfolioId: string;
    isShowSubOrganizations: boolean;
  }) => {
    const formatMessage = useFormatMessage();
    const [type, setType] = useState("table");
    const [classification, setClassification] = useState("FUND");
    const [fundType, setFundType] = useState("EQUITY");
    const [mold, setMold] = useState("STOCK_STYLE");

    const dataSource = useGetDataSource(
      assetPortfolioId,
      classification,
      "concentrationFund",
      fundType
    );
    const moldDataSource = useGetDataSource(
      assetPortfolioId,
      mold,
      "typeRelatedData"
    );
    const columns = useGetColumns(
      classification,
      type,
      dataSource,
      isShowSubOrganizations
    );
    const moldColumns = useGetMoldColumns(
      mold,
      type,
      moldDataSource,
      isShowSubOrganizations
    );
    const dispatch = useDispatch();
    useEffect(() => {
      if (assetPortfolioId) {
        dispatch(
          fetchConcentrationFund({
            grpId: assetPortfolioId,
            type: classification === "FUND" ? fundType : classification,
          })
        );
      }
    }, [classification, dispatch, fundType, assetPortfolioId]);
    useEffect(() => {
      if (assetPortfolioId) {
        dispatch(
          fetchConcentrationType({ grpId: assetPortfolioId, type: mold })
        );
      }
    }, [dispatch, mold, assetPortfolioId]);

    const { totalScale, maxWeightObject, totalRatio } =
      useGetSummaryData(dataSource);
    const { totalScale: moldTotalScale, totalRatio: moldTotalRatio } =
      useGetSummaryData(moldDataSource);

    const fundTypeMap = normalize("value")(fundProductsOptions);
    const classificationMap = normalize("value")(fundTypeOptions);
    return (
      <div className={style.ConcentrationMonitor}>
        <Space direction="vertical" className={style.FullWidth}>
          <AumSubTitle
            title={formatMessage("concentrationMonitor")}
            type={type}
            changeType={setType}
          />
          <div>
            <Radio.Group
              value={classification}
              onChange={(e) => setClassification(e.target.value)}
              buttonStyle="solid"
            >
              {map((item: Record<string, string>) => {
                return (
                  <Radio.Button value={item.value}>
                    {formatMessage(item.label)}
                  </Radio.Button>
                );
              })(fundTypeOptions)}
            </Radio.Group>
          </div>
          {classification === "FUND" && (
            <div>
              <Radio.Group
                onChange={(e) => setFundType(e.target.value)}
                value={fundType}
              >
                {map((item: Record<string, string>) => {
                  return (
                    <Radio value={item.value}>
                      {formatMessage(item.label)}
                    </Radio>
                  );
                })(fundProductsOptions)}
              </Radio.Group>
            </div>
          )}
          <LoadingComponent actions={fetchConcentrationFund}>
            <Table
              className={type === "chart" ? style.FundChart : style.FundTable}
              columns={columns}
              bordered={false}
              dataSource={dataSource}
              pagination={false}
              scroll={{ x: "max-content" }}
              rowKey="code"
              summary={() =>
                type === "table" ? (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>
                        {formatMessage("top10Total")}
                      </Table.Summary.Cell>
                      {classification === "FUND" && (
                        <>
                          <Table.Summary.Cell index={1}></Table.Summary.Cell>
                          <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        </>
                      )}
                      <Table.Summary.Cell index={1}>
                        {dealScaleData(totalScale)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        {formatPercentage(totalRatio)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                ) : null
              }
            />
          </LoadingComponent>
          <div className={style.ConcentrationSummary}>
            {formatMessage("concentrationSummary", {
              fundType:
                classification === "FUND" ? (
                  <span>
                    {formatMessage(prop(`${fundType}.label`)(fundTypeMap))}
                    {formatMessage("fundProducts")}
                  </span>
                ) : (
                  formatMessage(
                    prop(`${classification}.label`)(classificationMap)
                  )
                ),
              weight: formatPercentage(totalRatio),
              fundName: fastProp("name")(maxWeightObject),
              maxRatio: flow(
                fastProp("weight"),
                formatPercentage
              )(maxWeightObject),
            })}
          </div>
          <Radio.Group
            buttonStyle="solid"
            onChange={(e) => setMold(e.target.value)}
            value={mold}
          >
            {map((item: itemType) => {
              return (
                <Radio.Button value={item.value}>
                  {formatMessage(item.label)}
                </Radio.Button>
              );
            })(moldOptions)}
          </Radio.Group>
          <LoadingComponent actions={fetchConcentrationType}>
            <Table
              className={type === "chart" ? style.FundChart : style.FundTable}
              columns={moldColumns}
              bordered={false}
              dataSource={moldDataSource}
              pagination={false}
              // scroll={scroll}
              scroll={{ x: "max-content" }}
              rowKey="code"
              summary={() =>
                type === "table" ? (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>
                        {formatMessage("sum")}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align="left">
                        {dealScaleData(moldTotalScale)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} align="left">
                        {formatPercentage(moldTotalRatio)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                ) : null
              }
            />
          </LoadingComponent>
        </Space>
      </div>
    );
  }
);
