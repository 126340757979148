import { Stock } from "@/model/stockBackCheck";
import { RootState } from "@/store";
import { normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { filter, flow, isNull } from "lodash/fp";

export const allStocksMapSelector = createSelector(
  (state: RootState) => state.entities.allStocks,
  (allStocks) => normalize<Stock>("code")(allStocks)
);

export const delistedStocksMapSelector = createSelector(
  (state: RootState) => state.entities.allStocks,
  (allStocks) =>
    flow(
      filter((v: Stock) => isNull(v.delistedDate)),
      normalize<Stock>("code")
    )(allStocks)
);
