import { fastProp } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { fundBasicInfoSelector } from "../../../mapper";
import { normalizeDailyReturnsMap } from "@/util/transformer";

export const FundDetailFundManagersSelector = createSelector(
  fundBasicInfoSelector,
  (fundBasic) => fastProp("fundDetailFundManagers")(fundBasic)
);

export const fundDailyReturnSelector = createSelector(
  fundBasicInfoSelector,
  (fundBasic) => {
    const fundDetailReturn = fastProp("fundDetailReturn")(fundBasic);
    const tradingDates = fastProp("tradingDates")(fundDetailReturn);
    const fundDailyReturn = fastProp("fundDailyReturn")(fundDetailReturn);

    return {
      dates: tradingDates,
      dailyReturnsMap: normalizeDailyReturnsMap(tradingDates, fundDailyReturn),
    };
  }
);

export default createSelector(
  FundDetailFundManagersSelector,
  fundDailyReturnSelector,
  (fundDetailFundManagers, fundReturnsData) => ({
    fundDetailFundManagers,
    ...fundReturnsData,
  })
);
