import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp, normalize } from "@/util/opt";
import { useMemoizedFn } from "ahooks";
import {
  filter,
  flatten,
  flow,
  fromPairs,
  isEmpty,
  map,
  prop,
  pullAt,
  union,
  uniq,
  update,
  values,
} from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getDesignatedBasicColumns } from "./constant";
import type { SortEnd } from "react-sortable-hoc";

export type fundInfo = {
  fundId: string;
  purchFeeRatio: number;
  redeemFeeRatio: number;
};

export interface ReplaceRuleType {
  [key: string]:
    | string
    | Record<string, string[]>
    | string[]
    | boolean
    | fundInfo[];
}

export const useGetDesignatedColumnsAndData = (
  ruleInfo: ReplaceRuleType,
  fundIds: string[],
  changeReplaceRule: (key: string) => (value: any) => any,
  fundListMap:Record<string,any>
) => {
  const formatMessage = useFormatMessage();
  const fundIdMap = useAppSelector(fundIdMapSelector);
  const replaceFundTradeFeeRatio = useMemo(()=>fastProp("replaceFundTradeFeeRatio")(
    ruleInfo
  ),[ruleInfo]);
  

  const replaceFund = useMemo(() => {
    const replaceFund = !isEmpty(fastProp("replaceFund")(ruleInfo))
      ? fastProp("replaceFund")(ruleInfo)
      : flow(
          map((fundId: string) => [fundId, []]),
          fromPairs
        )(fundIds);
    return replaceFund;
  }, [ruleInfo, fundIds]);

  const allReplaceFund = useMemo(
    () => flow(values, flatten, uniq)(replaceFund),
    [replaceFund]
  );
  const replaceFundTradeFeeRatioMap = useMemo(
    () => normalize("fundId")(replaceFundTradeFeeRatio),
    [replaceFundTradeFeeRatio]
  );
  

  const changeReplaceFund = useMemoizedFn(
    (key: string) => (value: string[]) => {
      const data = union(allReplaceFund, value);
      const ratioData = map((fundId: string) => {
        if(fastProp(fundId)(fundListMap)){
          return { fundId: fundId, purchFeeRatio: prop(`${fundId}.purchFeeRatio`)(fundListMap), redeemFeeRatio: prop(`${fundId}.redeemFeeRatio`)(fundListMap) };
        }else{
          if (fastProp(fundId)(replaceFundTradeFeeRatioMap)) {
            return fastProp(fundId)(replaceFundTradeFeeRatioMap);
          } else {
            return { fundId: fundId, purchFeeRatio: 0, redeemFeeRatio: 0 };
          }
        }
        
      })(data);
      changeReplaceRule("replaceFund")(update(key, () => value)(replaceFund));
      changeReplaceRule("replaceFundTradeFeeRatio")(ratioData);
    }
  );

  const delReplaceFund = useMemoizedFn((key: string) => (value: string) => {
    changeReplaceRule("replaceFund")(
      update(key, (res) => filter((id: string) => id !== value)(res))(
        replaceFund
      )
    );
    const data = filter((item: fundInfo) => item?.fundId !== value)(
      replaceFundTradeFeeRatio
    );
    changeReplaceRule("replaceFundTradeFeeRatio")(data);
  });

  const designatedColumns = useMemo(()=>getDesignatedBasicColumns(
    formatMessage,
    fundIdMap,
    changeReplaceFund,
    delReplaceFund
  ),[changeReplaceFund,delReplaceFund,formatMessage,fundIdMap]) 

  const dataSource = useMemo(() => {
    return map((fundId: string) => ({
      fundId,
      replaceFund: fastProp(fundId)(replaceFund) || [],
    }))(fundIds);
  }, [fundIds, replaceFund]);

  return { designatedColumns, designatedData: dataSource };
};

export interface NoDesignatedType {
  fundName: string;
  fundId: string;
  fundCode: string;
  policyType: string;
  purchFeeRatio: number;
  redeemFeeRatio: number;
}

export const useGetNoDesignated = (
  fundIds: fundInfo[],
  changeReplaceRule: (key: string) => (value: any) => any,
  fundListMap:Record<string,any>
) => {
  const [dataSource, setDataSource] = useState<NoDesignatedType[]>([]);
  const fundIdMap = useAppSelector(fundIdMapSelector);
  useEffect(() => {
    const data = map((item: fundInfo) => {
      if(fastProp(item?.fundId)(fundListMap)){
        return {
          fundName: prop(`${item?.fundId}.name`)(fundIdMap),
          fundCode: prop(`${item?.fundId}.code`)(fundIdMap),
          policyType: prop(`${item?.fundId}.investType`)(fundIdMap),
          ...fastProp(item?.fundId)(fundListMap),
        }
      }else{
        return {
          ...item,
          fundName: prop(`${item?.fundId}.name`)(fundIdMap),
          fundCode: prop(`${item?.fundId}.code`)(fundIdMap),
          policyType: prop(`${item?.fundId}.investType`)(fundIdMap),
        }
      }
      
  })(fundIds);
    setDataSource(data);
  }, [fundIds, fundIdMap,fundListMap]);
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const moveData = dataSource[oldIndex];
      const delMoveData = pullAt(oldIndex)(dataSource);
      delMoveData.splice(newIndex, 0, moveData);
      setDataSource(delMoveData);
    }
  };
  const delFund = useCallback(
    (fundId: string) => {
      const fundData = filter(
        (item: NoDesignatedType) => item?.fundId !== fundId
      )(dataSource);
      changeReplaceRule("replaceFundPool")(
        map((item: NoDesignatedType) => ({
          fundId: item?.fundId,
          purchFeeRatio: item?.purchFeeRatio,
          redeemFeeRatio: item?.redeemFeeRatio,
        }))(fundData)
      );
    },
    [changeReplaceRule, dataSource]
  );
  return { noDesignatedData: dataSource, onSortEnd, delFund };
};

export const useGetFundReplace = (
  onChange: (data: Record<string, any>) => void
) => {
  const changeReplace = useMemoizedFn((key: string) => (value: any) => {
    onChange((originValue: any) => ({
      ...originValue,
      [key]: value,
    }));
  });

  return { changeReplace };
};

export const delElementInArray = (n: number, arr: any[]) => {
  if (n < 0) return arr;
  return pullAt(n - 1)(arr);
};
