import { get, post } from "@/util/axios";

// 获取基金经理基本信息
export const getFundManagerDetailBasicInfoApi = (id: string) =>
  get("/fund_manager/detail/basic", { id });

// 获取基金经理日收益
export const getFundManagerDetailDailyReturnApi = (ids: string[]) =>
  get("/fund_manager/detail/daily_return", { ids });

// 获取基金经理规模变化
export const getFundManagerScaleTrendApi = (ids: string[]) =>
  get("/fund_manager/compare/scale_trend", { ids });

// 获取基金经理管理的基金信息
export const getFundManagerManageFundInfoApi = (id: string) =>
  get("/fund_manager/detail/manage_fund", { id });

export type GetFundManagerManageFundPerformanceInfoParam = {
  id: string;
  section: string;
};
// 获取基金经理的基金业绩
export const getFundManagerManageFundPerformanceInfoApi = ({
  id,
  section,
}: GetFundManagerManageFundPerformanceInfoParam) =>
  get("/fund_manager/manage_fund_return", { id, section });

// 获取基金经理的年报半年报日期
export const getFundManagerYearReportDateApi = (managerIds: string[]) =>
  post("/fund_manager/compare/annual_report_date", managerIds);

// 获取基金经理的财报日期
export const getFundManagerReportDateApi = (managerIds: string[]) =>
  post("/fund_manager/compare/report_date", managerIds);

export type GetFundManagerIndustryInfoParam = {
  id: string;
  sectorId: string;
  reportDate: string;
};
export type FundManagerIndustryInfoParams = {
  ids: string[];
  sectorId: string;
  reportDate: string;
};
// 获取基金经理行业top5
export const getFundManagerIndustryInfoApi = ({
  ids,
  sectorId,
  reportDate,
}: FundManagerIndustryInfoParams) =>
  get(`/fund_manager/compare/industry/${reportDate}`, {
    ids,
    sectorId,
  });

// 获取基金经理市值top10
export const getFundManagerMarketValueInfoApi = ({
  ids,
  reportDate,
}: {
  ids: string[];
  reportDate: string;
}) => get(`/fund_manager/compare/market_value/${reportDate}`, { ids });
