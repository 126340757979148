import { subOrgs } from "@/model/aum";
import { FormatMessageFunc } from "@/util/formatMessage";
import {
  fixedNumber,
  formatPercentage,
  parseToThousandth,
} from "@/util/numberFormatter";
import { fastNth, fastProp, mapIndexed, normalize } from "@/util/opt";
import { Tooltip } from "antd";
import cn from "classnames";
import { flatten, flow, prop } from "lodash/fp";
import { TooltipContent } from "./hook";
import RankTitle from "./rankTitle";
import style from "./index.module.less";

export const chartColors = [
  "#0052BF",
  "#EB5B5B",
  "#FFB15A",
  "#00AE39",
  "#CF5E3F",
  "#0099DB",
  "#9051BE",
  "#DA4E8D",
  "#00A9BF",
  "#4F6B99",
];
export const chartOpacity = [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.15];

export const fundTypeOptions = [
  {
    label: "fundProducts",
    value: "FUND",
  },
  {
    label: "fundManagers",
    value: "FUND_MANAGER",
  },
  {
    label: "fundCompanys",
    value: "FUND_COMPANY",
  },
];
export const fundProductsOptions = [
  {
    label: "EQUITY",
    value: "EQUITY",
  },
  {
    label: "FIXED",
    value: "FIXED",
  },
];
export const moldOptions = [
  {
    label: "style",
    value: "STOCK_STYLE",
  },
  {
    label: "industry",
    value: "INDUSTRY",
  },
  {
    label: "stock",
    value: "STOCK",
  },
];

export const getMoldSubColumns = (
  subNum: number,
  subKey: string,
  nameKey: string,
  scaleKey: string,
  formatMessage: FormatMessageFunc
) => {
  return flow(
    mapIndexed((_: any, index: number) => [
      {
        dataIndex: "orgName",
        title: <RankTitle number={index + 1} />,
        className: style.TableLeftBorder,
        width: 100,
        render: (_: any, record: Record<string, any>) => {
          const value =
            flow(fastProp(subKey), fastNth(index), fastProp(nameKey))(record) ||
            "--";
          return (
            <Tooltip title={value}>
              <p className={style.OrgName}>{value}</p>
            </Tooltip>
          );
        },
      },
      {
        dataIndex: `orgScale`,
        width: 120,
        title: (
          <span>
            {formatMessage("Scale")}
            {formatMessage("estimate")}
          </span>
        ),
        render: (_: any, record: Record<string, any>) => {
          return flow(
            fastProp(subKey),
            fastNth(index),
            fastProp(scaleKey),
            dealScaleData
          )(record);
        },
      },
    ]),
    flatten
  )(new Array(subNum));
};

export const getScaleColumns = (formatMessage: FormatMessageFunc) => {
  return [
    {
      dataIndex: "scale",
      width: 120,
      title: formatMessage("retentionScale"),
      align: "left",
      render: (text: number) => {
        return <span>{dealScaleData(text)}</span>;
      },
    },
    {
      dataIndex: "weight",
      width: 120,
      title: formatMessage("scaleRatio"),
      align: "left",
      render: (text: number) => {
        return formatPercentage(text);
      },
    },
    {
      dataIndex: "customers",
      width: 120,
      title: formatMessage("positionCustomersNumber"),
      render: (text: number) => {
        return text || "--";
      },
    },
  ];
};

export const getChartColumns = (
  totalScale: number,
  maxWeight: number,
  maxsubTotleScale: number,
  formatMessage: FormatMessageFunc,
  isShowSubOrganizations: boolean,
  mold?: string
) => {
  return [
    {
      dataIndex: "scale",
      width: 300,
      title: getChartTitleName(totalScale, formatMessage, mold),
      render: (_: any, row: Record<string, any>, index: number) => {
        return (
          <>
            <Tooltip
              title={
                <TooltipContent
                  name={row.name}
                  scale={row.scale}
                  customers={row.customers}
                />
              }
              color="#ffff"
            >
              <div
                className={style.ProgressDiv}
                style={getScaleChartStyle(
                  fastProp("weight")(row),
                  index,
                  maxWeight
                )}
              />
            </Tooltip>
            <span className={style.Weight}>{formatPercentage(row.weight)}</span>
          </>
        );
      },
    },
    {
      dataIndex: "subOrgs",
      title: formatMessage("subTop10PositionDistribution"),
      className: !isShowSubOrganizations && style.NoShow,
      render: (data: subOrgs[], _: any, index: number) => {
        return (
          <div className={style.SubOrgs}>
            {mapIndexed((item: subOrgs, i: number) => {
              return (
                <Tooltip
                  title={
                    <TooltipContent
                      name={item?.orgName}
                      scale={item?.orgScale}
                      customers={item?.customers}
                    />
                  }
                  color="#ffff"
                  key={i}
                >
                  <div
                    className={cn(
                      style.SubProgress,
                      i !== 0 && style.BorderLeft
                    )}
                    style={getSubScaleChartStyle(
                      fastProp("orgScale")(item),
                      index,
                      maxsubTotleScale,
                      i
                    )}
                  />
                </Tooltip>
              );
            })(data)}
          </div>
        );
      },
    },
  ];
};

export const getChartTitleName = (
  totalScale: number,
  formatMessage: FormatMessageFunc,
  mold?: string
) => {
  const moldMap = normalize("value")(moldOptions);
  if (mold && mold === "STOCK_STYLE") {
    return formatMessage("styleScaleTotalRatio", {
      ratio: formatPercentage(totalScale),
    });
  } else if (mold && (mold === "INDUSTRY" || mold === "STOCK")) {
    return formatMessage("byTypeScaleTotalRatio", {
      type: formatMessage(prop(`${mold}.label`)(moldMap)),
      ratio: formatPercentage(totalScale),
    });
  } else {
    return formatMessage("top10ScaleRatio", {
      totalRatio: formatPercentage(totalScale),
    });
  }
};

export const getScaleChartStyle = (
  weight: number,
  index: number,
  maxFirWidth: number,
  firstColor?: string
) => {
  return {
    width: `calc(${(100 * Math.abs(weight)) / (1.4 * maxFirWidth)}% + 8px)`,
    background: firstColor || chartColors[index],
  };
};

export const getSubScaleChartStyle = (
  weight: number,
  index: number,
  maxFirWidth: number,
  i: number
) => {
  return {
    width: `calc(${(100 * Math.abs(weight)) / (1.2 * maxFirWidth)}% + 8px)`,
    background: chartColors[index],
    opacity: chartOpacity[i],
  };
};

export const dealScaleData = (num: number) => {
  return num >= 10000 ? fixedNumber(num) : parseToThousandth(num);
};
