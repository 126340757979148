import React from "react";
import compareIconActive from "@/assets/compareIconActive.png";
import compareIcon from "@/assets/compareIcon.png";
import style from "./index.module.less";

export default React.memo<{
  checked: boolean;
  onClick: () => any;
}>(({ checked, onClick }) => (
  <img
    className={style.Icon}
    src={checked ? compareIcon : compareIconActive}
    onClick={onClick}
  />
));
