import { useFormatMessage } from "@/util/formatMessage";
import { Button, Card, Col, Radio, Row, Space, Table } from "antd";
import React, { useMemo } from "react";
import { PlusOutlined } from "@ant-design/icons";
import style from "../index.module.less";
import { size } from "lodash";
import type { SortableContainerProps } from "react-sortable-hoc";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import SelectFundDialog from "@/components/portfolioCompoents/selectFundDialog";
import { useCreation, useMemoizedFn, useUpdateEffect } from "ahooks";
import { fastProp, mapIndexed, normalize } from "@/util/opt";
import {
  fundInfo,
  NoDesignatedType,
  ReplaceRuleType,
  useGetDesignatedColumnsAndData,
  useGetNoDesignated,
} from "../hooks";
import { getDesignatedColumns, replaceRuleConfig } from "../constant";
import { map, prop, set } from "lodash/fp";
import NumberInput from "@/components/numberInput";
import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";

export const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props}></tr>
);
export const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

export default React.memo(
  ({
    className,
    ruleInfo,
    changeReplaceRule,
    fundIds,
    fundList,
  }: {
    className?: string;
    ruleInfo: ReplaceRuleType;
    changeReplaceRule: (key: string) => (value: any) => any;
    fundIds: string[];
    fundList: Record<string, any>[];
  }) => {
    const formatMessage = useFormatMessage();
    const fundListMap = useMemo(
      () => normalize("fundId")(fundList),
      [fundList]
    );
    const { designatedColumns, designatedData } =
      useGetDesignatedColumnsAndData(
        ruleInfo,
        fundIds,
        changeReplaceRule,
        fundListMap
      );

    const replaceFundPool = useCreation(
      () => fastProp("replaceFundPool")(ruleInfo) || [],
      [ruleInfo]
    );

    const { noDesignatedData, onSortEnd, delFund } = useGetNoDesignated(
      replaceFundPool,
      changeReplaceRule,
      fundListMap
    );

    const changeNoDesignated = useMemoizedFn(
      (index: number) => (key: string) => (value: number | null) => {
        changeReplaceRule("replaceFundPool")(
          set(`${index}.${key}`, value)(fastProp("replaceFundPool")(ruleInfo))
        );
      }
    );

    const noDesignatedColumns = useMemo(
      () =>
        getDesignatedColumns(
          formatMessage,
          delFund,
          changeNoDesignated,
          fundListMap
        ),
      [changeNoDesignated, delFund, formatMessage, fundListMap]
    );
    const DraggableContainer = (props: SortableContainerProps) => (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass={style.rowDragging}
        onSortEnd={onSortEnd}
        {...props}
      />
    );

    const DraggableBodyRow: React.FC<any> = ({
      // className,
      // style,
      ...restProps
    }) => {
      const index = noDesignatedData.findIndex(
        (x: NoDesignatedType) => x.fundId === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
    };

    const onChangeFundIds = useMemoizedFn((ids: string[]) => {
      const oldIds = fastProp("replaceFundPool")(ruleInfo);
      const oldIdsMap = normalize("fundId")(oldIds);
      const fundInfo = map((fundId: string) => {
        if (fastProp(fundId)(oldIdsMap)) {
          return fastProp(fundId)(oldIdsMap);
        } else {
          return { fundId: fundId, purchFeeRatio: 0, redeemFeeRatio: 0 };
        }
      })(ids);
      changeReplaceRule("replaceFundPool")(fundInfo);
    });

    const onChangeRadio = useMemoizedFn((value: boolean) => {
      changeReplaceRule("isAppoint")(value);
      if (value) {
        changeReplaceRule("replaceFundPool")([]);
      } else {
        changeReplaceRule("replaceFund")({});
        changeReplaceRule("replaceFundTradeFeeRatio")([]);
      }
    });

    const selectedId = useMemo(
      () =>
        map((item: fundInfo) => item.fundId)(
          fastProp("replaceFundPool")(ruleInfo)
        ),
      [ruleInfo]
    );

    const changeFundRatio = useMemoizedFn(
      (index: number) => (key: string) => (value: number | null) => {
        changeReplaceRule("replaceFundTradeFeeRatio")(
          set(
            `${index}.${key}`,
            value
          )(fastProp("replaceFundTradeFeeRatio")(ruleInfo))
        );
      }
    );

    const fundIdMap = useAppSelector(fundIdMapSelector);

    useUpdateEffect(() => {
      const ratioData = map((item: fundInfo) => {
        if (fastProp(item?.fundId)(fundListMap)) {
          return {
            fundId: item.fundId,
            purchFeeRatio: prop(`${item.fundId}.purchFeeRatio`)(fundListMap),
            redeemFeeRatio: prop(`${item.fundId}.redeemFeeRatio`)(fundListMap),
          };
        } else {
          return item;
        }
      })(fastProp("replaceFundTradeFeeRatio")(ruleInfo));
      changeReplaceRule("replaceFundTradeFeeRatio")(ratioData);
    }, [fundListMap]);

    return (
      <>
        <div className={className}>
          <Space direction="vertical" className={style.FullWidth}>
            <Radio.Group
              onChange={(e) => onChangeRadio(e.target.value)}
              value={fastProp("isAppoint")(ruleInfo)}
            >
              <Radio value={true}>{formatMessage("designatedFund")}</Radio>
              <Radio value={false}>{formatMessage("noDesignatedFund")}</Radio>
            </Radio.Group>
            {fastProp("isAppoint")(ruleInfo) ? (
              <div className={style.DesignatedTable}>
                <Space direction="vertical">
                  <Table
                    columns={designatedColumns}
                    dataSource={designatedData}
                    pagination={false}
                    bordered={true}
                    className={style.Table}
                    rowKey="fundId"
                    scroll={{ y: 360 }}
                    tableLayout="auto"
                  />
                  <Row gutter={16}>
                    {mapIndexed((item: fundInfo, index: number) => {
                      return (
                        <Col span={5} key={index}>
                          <Card
                            title={prop(`${item?.fundId}.name`)(fundIdMap)}
                            key={index}
                            size="small"
                            className={style.Card}
                          >
                            <Space direction="vertical">
                              <p className={style.RatioNumber}>
                                {formatMessage("purchFeeRatio")}:
                                <NumberInput
                                  type="PERCENTAGE"
                                  min={0}
                                  max={0.05}
                                  precision={2}
                                  placeholder=""
                                  addonAfter="%"
                                  className={style.WeightInput}
                                  value={item?.purchFeeRatio}
                                  onChange={(value) =>
                                    changeFundRatio(index)("purchFeeRatio")(
                                      value
                                    )
                                  }
                                  disabled={
                                    !!fastProp(item?.fundId)(fundListMap)
                                  }
                                />
                              </p>
                              <p>
                                {formatMessage("redeemFeeRatio")}:
                                <NumberInput
                                  type="PERCENTAGE"
                                  min={0}
                                  max={0.05}
                                  precision={2}
                                  placeholder=""
                                  addonAfter="%"
                                  className={style.WeightInput}
                                  value={item?.redeemFeeRatio}
                                  onChange={(value) =>
                                    changeFundRatio(index)("redeemFeeRatio")(
                                      value
                                    )
                                  }
                                  disabled={
                                    !!fastProp(item?.fundId)(fundListMap)
                                  }
                                />
                              </p>
                            </Space>
                          </Card>
                        </Col>
                      );
                    })(fastProp("replaceFundTradeFeeRatio")(ruleInfo))}
                  </Row>
                </Space>
              </div>
            ) : (
              <div className={style.NoDesignatedTable}>
                <Space direction="vertical">
                  <Table
                    columns={noDesignatedColumns}
                    dataSource={noDesignatedData}
                    pagination={false}
                    bordered={true}
                    rowKey="fundId"
                    className={style.noDesignatedTable}
                    components={{
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                      },
                    }}
                  />
                  {size(noDesignatedData) < 5 && (
                    <SelectFundDialog
                      fundIds={(selectedId as string[]) || []}
                      onChange={onChangeFundIds}
                      maxSelectionSize={5}
                    >
                      {(onChangeVisible) => (
                        <Button
                          onClick={onChangeVisible}
                          className={style.ShadowButton}
                          type="primary"
                          icon={<PlusOutlined />}
                          block
                        >
                          {formatMessage("addFund")}
                        </Button>
                      )}
                    </SelectFundDialog>
                  )}
                </Space>
              </div>
            )}
            {size(ruleInfo?.replaceFundPool as string[]) === 1 && (
              <div className={style.ReplaceTip}>
                {formatMessage("noDesignatedFundTip")}
              </div>
            )}
            {ruleInfo?.rule !== replaceRuleConfig.cash && (
              <div className={style.ReplaceTip}>
                {formatMessage("fundReplaceRuleTip")}
              </div>
            )}
          </Space>
        </div>
      </>
    );
  }
);
