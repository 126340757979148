import React from "react";
import { useLocation, useNavigate } from "react-router";
import LoginForm from "./loginForm";
import RegisterForm from "./registerForm";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      {location.pathname === "/login" ? (
        <LoginForm toRegister={() => navigate("/register")} />
      ) : (
        <RegisterForm />
      )}
    </>
  );
};

export default Login;
