import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";
import { useFormatMessage } from "@/util/formatMessage";
import { formatPercentage, toFixedNumber } from "@/util/numberFormatter";
import { approachEqual, fastProp, size } from "@/util/opt";
import { Col, Row, Slider, Table, TableProps } from "antd";
import cn from "classnames";
import { identity, isEmpty, map, sumBy } from "lodash/fp";
import React, { useCallback, useEffect, useMemo } from "react";
import NumberInput from "../numberInput";
import ToFundDetailPage from "../navigateToPage/toFundDetailPage";
import style from "./index.module.less";
import { getTableSummaryNode } from "./constant";

export type FundList = {
  fundId: string;
  weight: number;
  purchFeeRatio: number;
  redeemFeeRatio: number;
  removable?: boolean;
  editable?: boolean;
  [key: string]: unknown;
};
type TablePropsType = TableProps<FundList>;
export type SliderAndInputProps = {
  value: number;
  disabled?: boolean;
  onChange?: (v: number | null) => void;
};
export const SliderAndInput = ({
  value,
  disabled,
  onChange,
}: SliderAndInputProps): JSX.Element => {
  return (
    <Row className={style.SliderAndInput} align="middle">
      <Col span={16} className={style.SliderCol}>
        <div className={style.Slider}>
          <Slider
            disabled={disabled}
            step={0.01}
            min={0}
            className={style.Slider}
            value={value}
            onChange={onChange}
            tipFormatter={(value) => formatPercentage(value || 0)}
            max={1}
          />
        </div>
      </Col>
      <Col span={8}>
        <NumberInput
          className={style.RatioNumberInput}
          size="small"
          value={value}
          min={0}
          precision={2}
          max={1}
          disabled={disabled}
          step={0.01}
          onChange={onChange}
          type={"PERCENTAGE"}
        />
      </Col>
    </Row>
  );
};
export const useFundListTable = (fundList: FundList[]): FundList[] => {
  const allFundIdMap = useAppSelector(fundIdMapSelector);
  return useMemo(
    () =>
      map((v: FundList) => ({
        ...v,
        ...allFundIdMap?.[v.fundId],
      }))(fundList),
    [allFundIdMap, fundList]
  );
};
export type FundListTableProps = {
  fundList: FundList[];
  onChange?: (
    fundId: string,
    key?: string
  ) => (v: string | number | null) => void;
  onError?: (v: boolean) => void;
};
export type SummaryNodeProps = {
  fundListSize: number;
  positionWeight: number;
};

export const validateWeightMaxError = (weight: number) =>
  weight > 1 && !approachEqual(weight, 1, 5);

export const SummaryNode = ({
  fundListSize,
  positionWeight,
}: SummaryNodeProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  return useMemo(
    () =>
      getTableSummaryNode([
        {
          index: 1,
          colSpan: 5,
          align: "left",
          render: formatMessage("fundTotalNum", { num: fundListSize }),
        },
        {
          align: "left",
          index: 2,
          colSpan: 2,
          render: (
            <div
              className={cn(
                style.SummaryPosition,
                positionWeight < 0 || validateWeightMaxError(positionWeight)
                  ? style.PositionError
                  : ""
              )}
            >
              <p>{formatMessage("RemainingAvailablePositions")}</p>
              <p className={style.Position}>
                {formatPercentage(1 - positionWeight)}
              </p>
            </div>
          ),
        },
      ]),
    [formatMessage, fundListSize, positionWeight]
  );
};
const FundListTable = ({
  fundList,
  onChange = identity,
  onError = identity,
}: FundListTableProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  const dataSource = useFundListTable(fundList);
  const positionWeight = useMemo(() => sumBy("weight")(fundList), [fundList]);
  const handleOnChange = useCallback<Required<FundListTableProps>["onChange"]>(
    (fundId, key) => (value) => {
      return onChange(fundId, key)(value);
    },
    [onChange]
  );
  const deleteFund = useCallback(
    (v: string) => {
      handleOnChange(v)(null);
    },
    [handleOnChange]
  );
  useEffect(() => {
    onError(validateWeightMaxError(positionWeight) || positionWeight === 0);
  }, [positionWeight, onError]);
  const columns = useMemo<TablePropsType["columns"]>(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: formatMessage("fundName"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
      },
      {
        key: "code",
        dataIndex: "code",
        title: formatMessage("fundCode"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
      },
      {
        key: "investType",
        dataIndex: "investType",
        title: formatMessage("policyType"),
      },
      {
        key: "netValue",
        dataIndex: "netValue",
        title: formatMessage("latestNetUnitValue"),
        render: toFixedNumber(4),
      },
      {
        key: "netValueStartDate",
        dataIndex: "netValueStartDate",
        title: formatMessage("netValueDate"),
      },
      {
        key: "weight",
        dataIndex: "weight",
        title: formatMessage("positionWeight"),
        render: (_, record) => (
          <SliderAndInput
            disabled={!fastProp("editable")(record)}
            value={record.weight}
            onChange={handleOnChange(record.fundId, "weight")}
          />
        ),
      },
      {
        key: "purchFeeRatio",
        dataIndex: "purchFeeRatio",
        title: formatMessage("purchFeeRatio"),
        render: (_, record) => (
          <NumberInput
            onChange={handleOnChange(record.fundId, "purchFeeRatio")}
            min={0}
            max={0.05}
            precision={2}
            className={style.RatioNumberInput}
            size="small"
            disabled={!fastProp("editable")(record)}
            value={record.purchFeeRatio}
            type={"PERCENTAGE"}
          />
        ),
      },
      {
        key: "redeemFeeRatio",
        dataIndex: "redeemFeeRatio",
        title: formatMessage("redeemFeeRatio"),
        render: (_, record) => (
          <NumberInput
            className={style.RatioNumberInput}
            precision={2}
            size="small"
            min={0}
            max={0.05}
            disabled={!fastProp("editable")(record)}
            onChange={handleOnChange(record.fundId, "redeemFeeRatio")}
            value={record.redeemFeeRatio}
            type={"PERCENTAGE"}
          />
        ),
      },
      {
        key: "operator",
        dataIndex: "operator",
        title: formatMessage("operator"),
        render: (_, record) =>
          fastProp("removable")(record) && (
            <span
              onClick={() => deleteFund(record.fundId)}
              className={style.Operator}
            >
              {formatMessage("delete")}
            </span>
          ),
      },
    ],
    [deleteFund, formatMessage, handleOnChange]
  );
  const scroll = useMemo(
    () => (size(dataSource) > 8 ? { y: 360, x: "max-content" } : undefined),
    [dataSource]
  );
  return (
    <Table
      dataSource={dataSource}
      pagination={false}
      columns={columns as any[]}
      scroll={scroll}
      className={style.FundListTable}
      summary={() =>
        !isEmpty(dataSource) && (
          <SummaryNode
            positionWeight={positionWeight}
            fundListSize={size(dataSource)}
          />
        )
      }
    />
  );
};

export default React.memo(FundListTable);
