import React, { ReactElement } from "react";
import { Spin, Alert } from "antd";
import cn from "classnames";
import {
  REQUEST_RESOLVED,
  REQUEST_PENDING,
  REQUEST_FAILED,
} from "@/constant/socket";
import { useFormatMessage } from "@/util/formatMessage";
import ReProgress from "./progress";
import {
  SocketErrorData,
  SocketFinishData,
  SocketProgressData,
} from "@/util/socket";
import { prop } from "lodash/fp";
import style from "./taskResult.module.less";

function TaskResult(props: TaskResultProps): ReactElement {
  const formatMessage = useFormatMessage();
  const progress = prop("progress")(props.task);
  if (progress === REQUEST_PENDING) {
    return (
      <div
        className={cn(style.Container, props.className)}
        style={props?.style}
      >
        <Spin className={style.SpinDiv} tip={formatMessage("taskInQueue")} />
      </div>
    );
  }
  if (progress === REQUEST_RESOLVED) {
    return props.children;
  }
  if (progress === REQUEST_FAILED) {
    return (
      <div
        className={cn(style.Container, props.className)}
        style={props?.style}
      >
        <Alert
          message={formatMessage("taskFailed")}
          type="error"
          description={prop("fieldError.message")(props.task)}
        />
      </div>
    );
  }
  return (
    <div
      // className={!isEmpty(props.className) ? props.className : style.Progress}
      className={cn(style.Container, style.Progress, props.className)}
      style={props?.style}
      // style={containerStyle}
    >
      <ReProgress percent={progress} />
    </div>
  );
}

export default React.memo(TaskResult);

interface TaskResultProps {
  task: SocketErrorData | SocketProgressData | SocketFinishData;
  children: ReactElement;
  className?: string;
  style?: Record<string, any>;
}
