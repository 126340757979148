import { map, orderBy, prop, flow } from "lodash/fp";
import React, { useMemo } from "react";
import { Select, SelectProps } from "antd";
import { LabeledValue } from "antd/lib/select";
import { useAppSelector } from "@/hooks/redux";
import style from "../../index.module.less";

export default React.memo<SelectProps<string>>((props) => {
  const reportDate = useAppSelector<string[]>(
    prop("compareManage.fundCompare.financialReportDate")
  );
  const options = useMemo(
    () =>
      flow(
        map<string, LabeledValue>((date) => ({
          label: date,
          value: date,
        })),
        orderBy("value", "desc")
      )(reportDate),
    [reportDate]
  );
  return (
    <Select className={style.width150} options={options as any} {...props} />
  );
});
