import { useMemo, useState } from "react";
import type { TableProps } from "antd";
import { useUpdateEffect } from "ahooks";
import dayjs from "dayjs";
import { fromPairs } from "lodash";
import { filter, first, flow, map, prop } from "lodash/fp";
import ColorNumber from "@/components/colorNumber";
import { useGetFactorColumns } from "@/hooks/factors";
import { useAppSelector } from "@/hooks/redux";
import {
  CompetitionInterface,
  CompetitionStatus,
  PortfolioRank,
} from "@/model/competition";
import { RootState } from "@/store";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp, normalize } from "@/util/opt";
import { PortfolioBasicInfo } from "./components/competitionRank";
import CompetitionRankShow from "./components/competitionRankShow";
import detailsStyle from "./index.module.less";
import { getFactorsFormatter } from "@/constant/factorFormatter";
import CompetitionRank from "./components/competitionRank";
import {
  competitionDetailSelector,
  competitionFactorsDataSelector,
  competitionListBasicInfoSelector,
} from "./selectors";
import { defaultFactors } from "./constant";

export type CompetitionProps = TableProps<CompetitionInterface>;

export const useGetColumns = (
  selectedTag: string,
  selectedFactorCode: string[],
  factorMap: Record<string, any>,
  type: string
) => {
  const formatMessage = useFormatMessage();
  const factorColumns = useGetFactorColumns("comPortfolio", selectedFactorCode);
  const filterFactorColumns = useMemo(
    () =>
      flow(
        filter((item: Record<string, any>) => item.dataIndex !== selectedTag),
        map((item: Record<string, any>) => {
          if (type === "Day_Yield") return [];
          if (selectedFactorCode.includes(item.dataIndex)) {
            return {
              ...item,
              dataIndex: `${type}_${item.dataIndex}`,
            };
          }
          return item;
        })
      )(factorColumns),
    [selectedTag, factorColumns, selectedFactorCode, type]
  );
  return useMemo<CompetitionProps["columns"]>(
    () => [
      {
        dataIndex: "rankNum",
        title: formatMessage("rank"),
        width: 100,
        align: "center",
        fixed: "left",
        render: (text: number) => {
          return <CompetitionRankShow rankNum={text} />;
        },
      },
      {
        dataIndex: "value",
        title:
          type === "Day_Yield"
            ? formatMessage("dailyYieldReturn")
            : prop(`${selectedTag}.name`)(factorMap),
        width: 100,
        align: "center",
        fixed: "left",
        render: (text: number) => {
          return (
            <ColorNumber
              value={text}
              className={detailsStyle.SelectedIndexValue}
              formatValue={getFactorsFormatter(selectedTag)}
            />
          );
        },
      },
      {
        dataIndex: "portfolioInfo",
        fixed: "left",
        width: 220,
        title: formatMessage("portfolioBaseInfo"),
        render: (_, record) => {
          return (
            <PortfolioBasicInfo
              formatMessage={formatMessage}
              portfolioRank={record as PortfolioRank}
            />
          );
        },
      },
      ...(filterFactorColumns as Record<string, any>[]),
    ],
    [selectedTag, formatMessage, filterFactorColumns, factorMap, type]
  );
};

export const useGetPortfolioBasicData = (
  competitionId: string,
  type: string
) => {
  const competitionRankListBasicInfo = useAppSelector((state: RootState) =>
    competitionListBasicInfoSelector(state, type)
  );

  const competitionDetail = useAppSelector(competitionDetailSelector);

  const portfolioRanks = useMemo(() => {
    return fastProp("portfolioRanks")(competitionRankListBasicInfo);
  }, [competitionRankListBasicInfo]);

  const competitionFactorsData = useAppSelector((state: RootState) =>
    competitionFactorsDataSelector(state, type)
  );

  const competitionFactorsDataMap = useMemo(
    () => normalize("portfolioId")(competitionFactorsData),
    [competitionFactorsData]
  );

  const myPortfolioRank = useMemo(() => {
    const myPortfolioRankData = fastProp("myPortfolioRank")(
      competitionRankListBasicInfo
    );
    return {
      ...myPortfolioRankData,
      ...prop(`${fastProp("portfolioId")(myPortfolioRankData)}.factors`)(
        competitionFactorsDataMap
      ),
    };
  }, [competitionRankListBasicInfo, competitionFactorsDataMap]);

  return {
    rankBasicInfo: competitionDetail,
    portfolioRanks,
    myPortfolioRank,
    competitionFactorsDataMap,
    competitionDetail,
  };
};

export const useGetPortfolioFactorData = (
  type: string,
  selectedFactorCode: string[]
) => {
  const competitionRankListBasicInfo = useAppSelector((state: RootState) =>
    competitionListBasicInfoSelector(state, type)
  );
  const competitionFactorsData = useAppSelector((state: RootState) =>
    competitionFactorsDataSelector(state, type)
  );
  const portfolioRanks = useMemo(() => {
    return fastProp("portfolioRanks")(competitionRankListBasicInfo);
  }, [competitionRankListBasicInfo]);

  const competitionFactorsDataMap = useMemo(
    () => normalize("portfolioId")(competitionFactorsData),
    [competitionFactorsData]
  );
  const dataSource = useMemo(() => {
    return map((item: PortfolioRank) => {
      const factors = prop(`${item.portfolioId}.factors`)(
        competitionFactorsDataMap
      );
      return {
        ...item,
        ...flow(
          map((key: string) => `${type}_${key}`),
          map((key: string) => [
            key,
            !fastProp(key)(factors) && fastProp(key)(factors) !== 0
              ? null
              : fastProp(key)(factors),
          ]),
          fromPairs
        )(selectedFactorCode),
      };
    })(portfolioRanks);
  }, [competitionFactorsDataMap, portfolioRanks, selectedFactorCode, type]);
  return { dataSource };
};

export const useGetCompetitionRangeTabs = () => {
  const competitionDetail = useAppSelector(competitionDetailSelector);
  const competitionRangeTabs = useMemo(() => {
    const startDate = fastProp("startDate")(competitionDetail);
    if (fastProp("status")(competitionDetail) === CompetitionStatus.HAS_END)
      return [
        {
          id: "from_creation",
          name: "startToNowRank",
          Component: CompetitionRank,
          isArriveDate: true,
        },
      ];
    return [
      {
        id: "from_creation",
        name: "startToNowRank",
        Component: CompetitionRank,
        isArriveDate: true,
      },
      {
        id: "Day_Yield",
        name: "dailyRiseAndFallRank",
        Component: CompetitionRank,
        isArriveDate: true,
      },
      {
        id: "recent_week",
        name: "recentWeekRank",
        Component: CompetitionRank,
        isArriveDate:
          startDate <= dayjs().subtract(7, "day").format("YYYY-MM-DD"),
      },
      {
        id: "recent_month",
        name: "recentMonthRank",
        Component: CompetitionRank,
        isArriveDate:
          startDate <= dayjs().subtract(1, "month").format("YYYY-MM-DD"),
      },
      {
        id: "recent_three_month",
        name: "recentThreeMonthRank",
        Component: CompetitionRank,
        isArriveDate:
          startDate <= dayjs().subtract(3, "month").format("YYYY-MM-DD"),
      },
    ];
  }, [competitionDetail]);
  return competitionRangeTabs;
};

export const useGetFactorData = () => {
  const [selectedFactorCode, changeFactorCode] = useState(defaultFactors);
  const [selectedTag, setSelectedTag] = useState<string>(
    first(selectedFactorCode) as string
  );
  useUpdateEffect(() => {
    setSelectedTag(first(selectedFactorCode) as string);
  }, [selectedFactorCode]);
  return { selectedFactorCode, changeFactorCode, selectedTag, setSelectedTag };
};
