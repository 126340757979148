import { createContext, useContext } from "react";
import { FundsInterface } from "@/model/entities";

interface FundInfoExportContext {
  fundMap: Record<string, FundsInterface>;
}

const Context = createContext<FundInfoExportContext>({
  fundMap: {},
});

export const useFundReportContext = () => {
  const data = useContext(Context);
  return data;
};

export default Context;
