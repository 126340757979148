import SelectFundDialog from "@/components/portfolioCompoents/selectFundDialog";
import { useFormatMessage } from "@/util/formatMessage";
import { Button, Card, Space } from "antd";
import React, { useEffect, useState } from "react";
import style from "./index.module.less";
import { PlusOutlined } from "@ant-design/icons";
import EmptyContent from "@/components/emptyContent";
import {
  useCategoryTypes,
  useGenerateFundConfigurationAsset,
  useManageConfigurationAssets,
  useManageErrorField,
  useManageFundConfigurationForm,
} from "./hooks";
import { useCreation, useMemoizedFn } from "ahooks";
import { first, isEmpty, map, omit, prop, size } from "lodash/fp";
import { addFundWeights } from "../../manualCreatePortfolio/constant";
import FundConfigurationForm from "@/views/portfolioManage/fundConfiguration/fundAllocation/fundConfigurationForm";
import FundConfigurationTable from "./fundConfigurationTable";
import {
  modelsConfig,
  validateErrorField,
} from "../../fundConfiguration/constant";
import { FundConfigurationAssetsUpdater } from "../../fundConfiguration/interface";
import { fastProp } from "@/util/opt";
import ErrorField from "../../manualCreatePortfolio/components/errorField";
import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";
import { useGetIndexInfo } from "../modelAllocation/components/hooks";
import cn from "classnames";

export default React.memo<{
  onError: React.Dispatch<React.SetStateAction<boolean>>;
}>(({ onError }) => {
  const formatMessage = useFormatMessage();
  const { configurationAssets, updateAssets, categorySource, categoryTypes } =
    useManageConfigurationAssets();
  const [marketIndex, setMarketIndex] = useState<string>("");
  useEffect(() => {
    if (categorySource === "MARKET_INDEX") {
      setMarketIndex(first(categoryTypes) as string);
    }
  }, [categorySource, categoryTypes]);
  const {
    formData,
    onUpdateFormData,
    startDate,
    endDate,
    netValueStart,
    availableDateRange,
  } = useManageFundConfigurationForm(configurationAssets);
  const errorField = useManageErrorField({
    assets: configurationAssets,
    startDate,
    endDate,
    netValueStart,
    formData,
  });
  useEffect(() => {
    const newErrorField = omit(["fundLess3MothError"])(errorField) as any;
    onError(validateErrorField(newErrorField));
  }, [errorField, onError]);
  const fundIds = useCreation(
    () => map("fundId")(configurationAssets),
    [configurationAssets]
  );
  const generateAsset = useGenerateFundConfigurationAsset(marketIndex);
  const onChangeFundIds = useMemoizedFn((ids: string[]) => {
    updateAssets(addFundWeights(generateAsset, ids));
    onUpdateFormData("modelType")(modelsConfig.equalWeight);
  });
  const { categoryIds, alertMessage, categoryIdErrorMessage } =
    useCategoryTypes({
      assets: configurationAssets,
      categoryTypeError: fastProp("categoryTypeError")(errorField),
    });
  const onUpdateAssets = useMemoizedFn(
    (updater: FundConfigurationAssetsUpdater) =>
      updateAssets((data) => {
        const result = updater(data);
        const isRemove = size(data) !== size(result);
        if (isRemove) onUpdateFormData("modelType")(modelsConfig.equalWeight);
        return result;
      })
  );
  const fundIdMap = useAppSelector(fundIdMapSelector);
  const someFundLess3MothError = useCreation(() => {
    const fundId = fastProp("fundLess3MothError")(errorField);
    if (fundId)
      return `${prop([fundId, "name"])(fundIdMap)} ${formatMessage(
        "someFundLess3MothErrorMessage"
      )}`;
    return "";
  }, [errorField, formatMessage, fundIdMap]);
  const { factorMap } = useGetIndexInfo();
  return (
    <SelectFundDialog
      categoryIds={categoryIds}
      alertMessage={alertMessage}
      showCategoryType
      fundIds={fundIds}
      onChange={onChangeFundIds}
    >
      {(onChangeVisible) => (
        <>
          <Card className={style.Card}>
            <div className={style.Header}>
              <h3>{formatMessage("OptionalFund")}</h3>
              {!isEmpty(configurationAssets) &&
                categorySource === "ASSET_CATEGORY" && (
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={onChangeVisible}
                  >
                    {formatMessage("addFund")}
                  </Button>
                )}
            </div>
            {isEmpty(configurationAssets) &&
              categorySource === "ASSET_CATEGORY" && (
                <EmptyContent
                  message={formatMessage("isEmptyConfigurationAssets")}
                  className={style.EmptyContent}
                >
                  <Button
                    icon={<PlusOutlined />}
                    className={style.OptionsButton}
                    onClick={onChangeVisible}
                  >
                    {formatMessage("addFund")}
                  </Button>
                </EmptyContent>
              )}
            {categorySource === "MARKET_INDEX" && (
              <Space className={style.MarketIndexButtons}>
                {map((v: string) => (
                  <Button
                    icon={<PlusOutlined />}
                    type="default"
                    className={style.MarketIndexButton}
                    onClick={() => {
                      setMarketIndex(v);
                      onChangeVisible();
                    }}
                  >
                    {prop([v, "name"])(factorMap)}
                  </Button>
                ))(categoryTypes)}
              </Space>
            )}
            {!isEmpty(configurationAssets) && (
              <>
                <FundConfigurationTable
                  editable
                  dataSource={configurationAssets}
                  assetIntersectionStartDate={startDate}
                  assetIntersectionEndDate={endDate}
                  onChange={onUpdateAssets}
                  errorField={errorField}
                />
                {errorField.fundLess3MothError ? (
                  <ErrorField
                    className={style.MarginTop8}
                    message={someFundLess3MothError}
                  />
                ) : errorField.fundMinWeightError ? (
                  <ErrorField
                    className={style.MarginTop8}
                    message={formatMessage("ConfigurationAssetsMinWeightError")}
                  />
                ) : (
                  errorField.fundMaxWeightError && (
                    <ErrorField
                      className={style.MarginTop8}
                      message={formatMessage(
                        "ConfigurationAssetsMaxWeightError"
                      )}
                    />
                  )
                )}
              </>
            )}
            <div className={cn(style.MarginTop8, style.ErrorField)}>
              {categoryIdErrorMessage && (
                <ErrorField
                  className={style.MarginTop8}
                  message={categoryIdErrorMessage}
                />
              )}
            </div>
          </Card>
          <FundConfigurationForm
            startDate={startDate}
            endDate={endDate}
            availableDateRange={availableDateRange}
            formData={formData}
            updateFormData={onUpdateFormData}
            errorField={errorField}
            foundDatePickerErrorMessage={
              !isEmpty(configurationAssets) &&
              categorySource === "MARKET_INDEX" &&
              errorField.foundDateEmptyError
                ? formatMessage("foundDatePickerErrorMessage")
                : undefined
            }
          />
        </>
      )}
    </SelectFundDialog>
  );
});
