import { flow, isEmpty, map, split, some, isObject } from "lodash/fp";
import localeConfigs from "@/locales";
import { Fragment, createElement } from "react";
import { fastProp, fastHas } from "./opt";
import getLang from "./getLang";

export const isEn = () => getLang() === "en";
export default (
  key: string,
  lang?: string,
  defaultMessage?: string,
  placeholder: Record<string, string> = {}
) => {
  const messages = flow(
    fastProp(lang || getLang()),
    fastProp("messages")
  )(localeConfigs);
  const message = messages && messages[key];
  if (!fastHas(key)(messages) || !message) return key;
  if (isEmpty(placeholder)) {
    return message;
  }

  const res = flow(
    split(/{([\s\S]+?)}/g),
    map((value) => {
      if (fastHas(value)(placeholder)) {
        return fastProp(value)(placeholder);
      }
      return value;
    })
  )(message);

  if (some(isObject)(placeholder)) {
    return createElement(Fragment, null, res);
  }
  return res.join("");
};
