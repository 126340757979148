import { FundsInterface } from "@/model/entities";
import { FormatMessageFunc, useFormatMessage } from "@/util/formatMessage";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { Table, TableProps } from "antd";
import { ColumnType } from "antd/lib/table";
import React, { ReactNode, useMemo } from "react";
import { fastProp } from "@/util/opt";
import {
  ChangingPositionHistoryAssetType,
  transactionTypeOptionsMap,
} from "../constant";
import { identity, map, prop, sumBy } from "lodash/fp";
import ColorNumber from "@/components/colorNumber";
import {
  formatPercentage,
  fixedNumberWithCommas,
} from "@/util/numberFormatter";
import style from "./index.module.less";
import ToFundManagerDetailPage from "@/components/navigateToPage/toFundManagerDetailPage";

const getColumns = (formatMessage: FormatMessageFunc): ColumnType<any>[] => [
  {
    title: formatMessage("fundName"),
    dataIndex: "name",
    render: (name: string, record: FundsInterface) => (
      <ToFundDetailPage name={name} id={fastProp("fundId")(record)} />
    ),
    fixed: "left",
    width: 150,
    ellipsis: true,
  },
  {
    title: formatMessage("fundCode"),
    dataIndex: "code",
    render: (code: string, record: FundsInterface) => (
      <ToFundDetailPage name={code} id={fastProp("fundId")(record)} />
    ),
    fixed: "left",
    width: 100,
  },
  {
    title: formatMessage("fundManagers"),
    dataIndex: "fundManagers",
    ellipsis: true,
    render: (managers) => {
      const managersNode = map<{ id: string; name: string }, ReactNode>(
        ({ id, name }) => (
          <ToFundManagerDetailPage
            className={style.MarginRight4}
            key={id}
            name={name}
            id={id}
          />
        )
      )(managers);
      return managersNode;
    },
    width: 200,
  },
  {
    title: formatMessage("policyType"),
    dataIndex: "investType",
    width: 150,
    ellipsis: true,
  },
  {
    title: formatMessage("transactionType"),
    dataIndex: "tradeType",
    width: 100,
    render: (tradeType: string) =>
      prop(`${tradeType}.label`)(transactionTypeOptionsMap),
  },
  {
    title: `${formatMessage("turnover")}(${formatMessage("YUAN")})`,
    dataIndex: "tradeCash",
    width: 150,
    align: "right",
  },
  {
    title: `${formatMessage("transactionCosts")}(${formatMessage("YUAN")})`,
    dataIndex: "tradeFee",
    width: 150,
    align: "right",
  },
  {
    title: `${formatMessage("preTransactionSize")}(${formatMessage("YUAN")})`,
    dataIndex: "beforeScale",
    width: 150,
    align: "right",
    render: (beforeScale: number) => fixedNumberWithCommas(beforeScale),
  },
  {
    title: `${formatMessage("afterTransactionSize")}(${formatMessage("YUAN")})`,
    width: 150,
    align: "right",
    dataIndex: "afterScale",
    render: (afterScale: number) => fixedNumberWithCommas(afterScale),
  },
  {
    title: formatMessage("preTransactionWeight"),
    dataIndex: "beforeWeight",
    width: 100,
    align: "right",
    render: (beforeWeight: number) => formatPercentage(beforeWeight),
  },
  {
    title: formatMessage("afterTransactionWeight"),
    dataIndex: "afterWeight",
    width: 100,
    align: "right",
    render: (afterWeight: number) => formatPercentage(afterWeight),
  },
  {
    title: formatMessage("weightChange"),
    dataIndex: "weightChange",
    width: 100,
    align: "right",
    render: (value, record) => {
      const afterWeight = fastProp("afterWeight")(record);
      const beforeWeight = fastProp("beforeWeight")(record);
      return (
        <ColorNumber
          value={afterWeight - beforeWeight}
          formatValue={(value) =>
            value > 0 ? `+${formatPercentage(value)}` : formatPercentage(value)
          }
        />
      );
    },
  },
];

export interface PositionHistoryTableProps
  extends TableProps<ChangingPositionHistoryAssetType> {
  updateColumns?: (columns: ColumnType<any>[]) => ColumnType<any>[];
}
export default React.memo<PositionHistoryTableProps>(
  ({ dataSource, updateColumns = identity, scroll, ...restProps }) => {
    const formatMessage = useFormatMessage();
    const columns = useMemo(
      () => updateColumns(getColumns(formatMessage)),
      [formatMessage, updateColumns]
    );
    const handleScroll = useMemo(
      () => ({
        ...scroll,
        x: sumBy("width")(columns),
      }),
      [columns, scroll]
    );
    return (
      <Table
        className={style.PositionHistoryTable}
        scroll={handleScroll}
        rowKey="code"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        {...restProps}
      ></Table>
    );
  }
);
