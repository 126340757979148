import React, { memo, ReactNode } from "react";

import style from "./index.module.less";

export default memo<{
  title: string;
  extraContent?: ReactNode;
}>(({ title, extraContent }) => (
  <div className={style.SubTitle}>
    <span className={style.Title}>{title}</span>
    <div>{extraContent}</div>
  </div>
));
