import React, { useMemo } from "react";
import { Table } from "antd";
import cn from "classnames";

import { useFormatMessage } from "@/util/formatMessage";
import { fastProp, mapIndexed, normalize } from "@/util/opt";
import userPlaceHolder from "@/assets/fundEvaluationReport/userPlaceHolder.svg";

import style from "../index.module.less";
import { ColumnsType } from "antd/es/table";
import {
  fixedNumber,
  formatNil,
  formatPercentage,
} from "@/util/numberFormatter";
import { useGetDefaultRadarOptions } from "../../firstPage/hooks";
import {
  compact,
  flow,
  isEmpty,
  isNumber,
  map,
  pick,
  prop,
  round,
} from "lodash/fp";
import {
  EvaluationReportAbilitySummaryBody,
  ManagerEvaluationBody,
} from "@/model/fundDetail";
import { useAppSelector } from "@/hooks/redux";
import {
  factorRatingsOrder,
  managerFactorRatingsOrder,
} from "@/components/fundInfoExport/constants";
import BaseEcharts from "../../../echarts/baseEcharts";
import { useFundReportContext } from "../../../../context";

export default React.memo(
  ({
    fundManagerInfo,
    fundId,
  }: {
    fundManagerInfo: ManagerEvaluationBody["fundManagerInfos"][number];
    fundId: string;
  }) => {
    const formatMessage = useFormatMessage();
    const labelData = useMemo(
      () => [
        formatMessage("commencementDateToNow"),
        formatMessage("representativeFund"),
        formatMessage("tenureReturn"),
        formatMessage("expertiseArea"),
        formatMessage("manageScale"),
      ],
      [formatMessage]
    );

    const contextValue = useFundReportContext();
    const fundMap = contextValue?.fundMap;
    const data = useMemo(
      () => [
        fastProp("duration")(fundManagerInfo),
        prop(`${fastProp("representativeFund")(fundManagerInfo)}.name`)(
          fundMap
        ),
        formatPercentage(fastProp("tenureReturn")(fundManagerInfo)),
        fastProp("expertiseArea")(fundManagerInfo),
        fixedNumber(fastProp("manageScale")(fundManagerInfo)),
      ],
      [fundManagerInfo, fundMap]
    );

    const evaluationReportAbilitySummary: EvaluationReportAbilitySummaryBody =
      useAppSelector(
        prop(`fundDetail.${fundId}.evaluationReportAbilitySummary`)
      );
    const sortedFactorRatings = useMemo(() => {
      const managerFactorRatings = fastProp("managerFactorRatings")(
        fundManagerInfo
      );
      const factorRatingsCodeMap = flow(
        fastProp("factorRatings"),
        normalize("factorCode")
      )(evaluationReportAbilitySummary);

      return flow(normalize("factorCode"), (mapper) =>
        mapIndexed((factorCode: string, idx: number) => ({
          ...pick(["factorCode", "factorName", "sameTypeFundFactorRating"])(
            prop(`${factorCode}`)(factorRatingsCodeMap)
          ),
          ...(fastProp(factorCode)(mapper) ||
            fastProp(managerFactorRatingsOrder[idx])(mapper) ||
            {}),
        }))(factorRatingsOrder)
      )(managerFactorRatings);
    }, [evaluationReportAbilitySummary, fundManagerInfo]);

    const indicatorsNameMap = useMemo(
      () => normalize("factorName")(sortedFactorRatings),
      [sortedFactorRatings]
    );
    const radarChartData = useMemo(() => {
      return [
        {
          name: fundManagerInfo?.name,
          value: map((item: Record<string, any>) => item?.rating)(
            sortedFactorRatings
          ),
        },
        {
          name: formatMessage("averageOfTheSameType"),
          value: map(
            (item: Record<string, any>) => item?.sameTypeFundFactorRating
          )(sortedFactorRatings),
        },
      ];
    }, [formatMessage, fundManagerInfo, sortedFactorRatings]);

    const isEmptyRatingValues = flow(
      map((item: Record<string, any>) => {
        const rating = item?.rating;
        if (isNumber(rating)) return rating;
        return null;
      }),
      compact,
      isEmpty
    )(sortedFactorRatings);

    const options = useGetDefaultRadarOptions({
      series: [
        {
          type: "radar",
          areaStyle: {
            opacity: 0.85,
          },
          symbol: "none",
          z: 2,
          data: [radarChartData[0]],
        },
        {
          type: "radar",
          areaStyle: {
            opacity: 0.85,
          },
          symbol: "none",
          z: 1,
          data: [radarChartData[1]],
        },
      ],
      graphic: [
        ...(isEmptyRatingValues
          ? [
              {
                type: "text",
                left: "center",
                top: "center",
                zlevel: 9,
                style: {
                  text: "数据不足",
                  fill: "#222222",
                  fontSize: 14,
                },
              },
            ]
          : []),
      ],
      radar: {
        indicator: map((item: any) => ({ name: item.factorName, max: 5 }))(
          sortedFactorRatings
        ),
        radius: "40%",
        axisName: {
          formatter: (name) => {
            const ratingNum =
              flow(prop(`${name}.rating`), round)(indicatorsNameMap) || 0;
            let res = `${name}\n`;
            const starStr = `{star|}`;
            for (let index = 0; index < ratingNum; index++) {
              res += starStr;
              if (index < ratingNum - 1) res += " ";
            }
            return res;
          },
        },
      },
    });

    const dataSource = useMemo(
      () =>
        mapIndexed((item: string, idx: number) => ({
          label: item,
          value: data[idx],
        }))(labelData),
      [data, labelData]
    );
    const columns = useMemo<
      ColumnsType<{ label: string; value: string | number }>
    >(
      () => [
        {
          key: "label",
          dataIndex: "label",
          className: style.Label,
          width: 140,
        },
        {
          key: "value",
          dataIndex: "value",
          render: (val: any) => formatNil(val),
        },
      ],
      []
    );
    return (
      <div className={style.ManagerInfoWrap}>
        <div className={style.ManagerInfo}>
          <div className={style.ImgWrap}>
            <img src={userPlaceHolder} />
            <div className={style.ManagerName}>
              {fastProp("name")(fundManagerInfo)}
            </div>
          </div>
          <div className={style.TableWrap}>
            <Table
              dataSource={dataSource}
              showHeader={false}
              pagination={false}
              columns={columns}
              bordered
              className={style.Table}
              rowClassName={(_, index: number) =>
                cn(index % 2 === 0 && style.ColoredRow, style.Row)
              }
            />
          </div>
        </div>
        <div className={style.ChartWrap}>
          <BaseEcharts
            option={options}
            height={230}
            opts={{ devicePixelRatio: 4 }}
          />
        </div>
      </div>
    );
  }
);
