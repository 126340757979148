import { useFormatMessage } from "@/util/formatMessage";
import { Button, Form, Input, Modal, message } from "antd";
import React, { ReactNode, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useMemoizedFn, useUpdateEffect } from "ahooks";
import style from "../index.module.less";
import { addOptionalGroupParm } from "@/api/workbench";
import { update, size } from "lodash/fp";
import { useForm } from "antd/lib/form/Form";
import { portfolioNameReg } from "@/util/formRules";
import { useAppDispatch } from "@/hooks/redux";
import {
  fetchAddOptionalGroup,
  fetchUpdateOptionalGroup,
  fetchGroupNameRepeat,
} from "@/store/workbench";
import { FUND } from "../constant";

const formLabelCol = {
  span: 4,
};

type NewGroupProps = {
  children?: ReactNode;
  defaultFormData?: addOptionalGroupParm;
  setPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>;
  modelType?: string;
  value?: addOptionalGroupParm;
  type: string;
};
const getDefaultFormData = (type: string): addOptionalGroupParm => ({
  groupId: "",
  newGroupName: "",
  oldGroupName: "",
  groupName: "",
  groupDetail: "",
  type,
});
export default React.memo<NewGroupProps>(
  ({
    children,
    defaultFormData,
    setPopoverVisible,
    value,
    modelType = "addNewGroup",
    type,
  }) => {
    const dispatch = useAppDispatch();
    const formatMessage = useFormatMessage();
    const [visible, setVisible] = useState<boolean>();
    const [formData, setFormData] = useState<addOptionalGroupParm>(
      getDefaultFormData(type)
    );
    const [form] = useForm();
    useUpdateEffect(() => {
      form.setFieldsValue(formData);
    }, [formData]);
    const onChangeVisible = useMemoizedFn(() => {
      setPopoverVisible(false);
      setVisible(!visible);
      if (defaultFormData) {
        form.setFieldsValue(defaultFormData);
        setFormData(defaultFormData);
      }
    });
    const onCancel = useMemoizedFn(() => {
      setVisible(false);
      setFormData(getDefaultFormData(type));
    });
    const [disabled, setDisabled] = useState<boolean>(false);
    const onOk = useMemoizedFn(() => {
      form
        .validateFields()
        .then(() => {
          if (modelType === "editGroup" && value) {
            let params = {};
            if (type === FUND) {
              params = {
                oldGroupName: value.groupName,
                newGroupName: formData.groupName,
                groupDetail: formData.groupDetail,
                type,
              };
            } else {
              params = {
                groupId: value.groupId,
                groupName: formData.groupName,
                groupDetail: formData.groupDetail,
                type,
              };
            }
            dispatch(fetchUpdateOptionalGroup(params as addOptionalGroupParm))
              .unwrap()
              .then(() => {
                setVisible(false);
                message.success(formatMessage("editGroupSuccess"));
                setFormData(getDefaultFormData(type));
              })
              .catch(() => {
                setVisible(true);
                return Promise.reject();
              });
          } else {
            let params = {};
            if (type === FUND) {
              params = {
                newGroupName: formData.groupName,
                groupDetail: formData.groupDetail,
                type,
              };
            } else {
              params = {
                groupName: formData.groupName,
                groupDetail: formData.groupDetail,
                type,
              };
            }
            dispatch(fetchAddOptionalGroup(params as addOptionalGroupParm))
              .unwrap()
              .then(() => {
                setVisible(false);
                message.success(formatMessage("groupCreateSuccess"));
                setFormData(getDefaultFormData(type));
              })
              .catch(() => {
                setVisible(true);
                return Promise.reject();
              });
          }
        })
        .catch(() => {
          setVisible(true);
          setDisabled(true);
          return Promise.reject();
        });
    });
    const onChangeValue = useMemoizedFn(
      (key: string) => (e: { target: { value: string } }) => {
        setDisabled(false);
        setFormData(update(key, () => e.target.value));
        if (
          key === "groupName" &&
          (e.target.value === "" ||
            !portfolioNameReg.test(e.target.value) ||
            e.target.value === formatMessage("unnamedGroup") ||
            size(e.target.value) > 30)
        ) {
          setDisabled(true);
        }
      }
    );
    const handleBlur = useMemoizedFn(() => {
      dispatch(fetchGroupNameRepeat({ name: formData.groupName, type }))
        .unwrap()
        .catch(({ errors }) => {
          form.setFields([{ name: "groupName", errors: [errors[0]?.message] }]);
          setDisabled(true);
        });
    });

    const validateRepeatName = useMemoizedFn((rule, value, callback) => {
      if (value === formatMessage("unnamedGroup")) {
        return callback(new Error(formatMessage("groupHasExist")));
      }
      return callback();
    });

    return (
      <>
        {children ? (
          <span onClick={onChangeVisible}>{children}</span>
        ) : (
          <Button icon={<PlusOutlined />} onClick={onChangeVisible}>
            {formatMessage("addNewGroup")}
          </Button>
        )}
        <Modal
          visible={visible}
          title={formatMessage(modelType)}
          footer={
            <>
              <Button onClick={onCancel}>{formatMessage("cancel")}</Button>
              <Button onClick={onOk} type="primary" disabled={disabled}>
                {formatMessage("preservation")}
              </Button>
            </>
          }
          onCancel={onCancel}
          width={608}
          destroyOnClose
        >
          <Form form={form} labelAlign="right" labelCol={formLabelCol}>
            <Form.Item
              name="groupName"
              label={formatMessage("groupName")}
              rules={[
                {
                  required: true,
                  message: formatMessage("groupNameIsNull"),
                },
                {
                  pattern: portfolioNameReg,
                  message: formatMessage("groupNameRule"),
                },
                { max: 30, message: formatMessage("groupNameMax") },
                { validator: validateRepeatName },
              ]}
            >
              <Input
                value={formData.groupName}
                onChange={onChangeValue("groupName")}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item label={formatMessage("groupDescribe")}>
              <Input.TextArea
                className={style.TextArea}
                showCount
                maxLength={100}
                value={formData.groupDetail}
                onChange={onChangeValue("groupDetail")}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
);
