import { LineChartOpts } from "@/echarts/lineChart";
import { treeDataType } from "@/model/fundDetail";
import dayjs from "dayjs";
import { EChartsOption } from "echarts";
import { flow, forEach, invert, join, map, merge, orderBy } from "lodash/fp";
import { FormatMessageFunc } from "./formatMessage";
import getMessage from "./getMessage";
import { formatPercentage } from "./numberFormatter";
import { fastProp } from "./opt";

export const getLastDateOfYear = (processedDates: string[]) => {
  const dates: Record<string, string> = {};
  forEach((date: string) => {
    const dateYear = dayjs(date).get("year").toString();
    const prevDate = fastProp(dateYear.toString())(dates);
    if (!prevDate || date > prevDate) {
      dates[dateYear] = date;
    }
  })(processedDates);
  return invert(dates);
};

export const getChartDataZoomConfig = () => ({
  start: 0,
  end: 100,
  moveHandleSize: 0,
  realtime: false,
  brushSelect: false,
  showDetail: false,
  fillerColor: "rgba(212, 230, 255, 0.6)",
  handleIcon:
    "path://M8,0.5C8.414213180541992,0.5,8.789213180541992,0.6678932309150696,9.060660362243652,0.939339816570282C9.332106590270996,1.2107864618301392,9.5,1.5857864618301392,9.5,2L9.5,2L9.5,22C9.5,22.414213180541992,9.332106590270996,22.789213180541992,9.060660362243652,23.060659408569336C8.789213180541992,23.332107543945312,8.414213180541992,23.5,8,23.5L8,23.5L2,23.5C1.5857864618301392,23.5,1.2107864618301392,23.332107543945312,0.939339816570282,23.060659408569336C0.6678932309150696,22.789213180541992,0.5,22.414213180541992,0.5,22L0.5,22L0.5,2C0.5,1.5857864618301392,0.6678932309150696,1.2107864618301392,0.939339816570282,0.939339816570282C1.2107864618301392,0.6678932309150696,1.5857864618301392,0.5,2,0.5L2,0.5Z",
  handleStyle: {
    shadowColor: "rgba(194, 15, 15, 1)",
  },
  dataBackground: {
    areaStyle: {
      opacity: 0,
    },
  },
  selectedDataBackground: {
    lineStyle: {
      color: "#90C1FF",
    },
    areaStyle: {
      opacity: 0,
    },
  },
  moveHandleStyle: {
    borderCap: "square",
  },
});

export const tooltipFormatter =
  (format: (...args: any) => string, formatMessage: FormatMessageFunc) =>
  (...args: any[]): string => {
    const data = args?.[0];
    const name = args?.[0]?.[0].name;
    const content = flow(
      orderBy("value", "desc"),
      map(({ seriesName, value, marker }: any) =>
        !value
          ? ""
          : `<p style="margin: 8px 0;">${marker}${seriesName}${formatMessage(
              "IndustylineChartTip"
            )}:  ${format(value)}</p>`
      ),
      join("")
    )(data);
    return `<div>
  <p>${name}</p>
  ${content}</div>`;
  };

export const getChartBaseOptions = (
  options?: EChartsOption,
  showDataZoom?: boolean
) =>
  merge({
    grid: {
      left: 0,
      right: 20,
      top: 50,
      bottom: 50,
      containLabel: true,
    },
    legend: {
      align: "left",
      show: true,
    },
    tooltip: {
      trigger: "axis",
      valueFormatter: (value: number) => formatPercentage(value),
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        hideOverlap: true,
      },
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, "100%"],
      axisLabel: {
        formatter: (value: number) => formatPercentage(value),
      },
    },
    ...(showDataZoom
      ? {
          dataZoom: [getChartDataZoomConfig()],
        }
      : {}),
  })(options);

export const getTreeChartOptions = (
  treeData: treeDataType,
  extraOptions: any
): EChartsOption =>
  merge({
    tooltip: {
      show: "true",
      trigger: "item",
      triggerOn: "mousemove",
      position: "right",
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      show: false,
    },
    series: [
      {
        type: "tree",
        data: [treeData],
        left: "10%",
        right: "15%",
        top: "0%",
        bottom: "4%",

        symbol: "none",
        orient: "horizontal",

        expandAndCollapse: true, //默认展开

        initialTreeDepth: 3, //默认：2

        label: {
          normal: {
            rotate: 0,
            verticalAlign: "middle",
            align: "center",
            fontSize: 12,
            formatter: (params: treeDataType) => {
              const { value } = params;
              if (value > 0) {
                return [
                  `{red|${formatPercentage(params.value)}}\n${params.name}`,
                ];
              } else if (value < 0) {
                return [
                  `{green|${formatPercentage(params.value)}}\n${params.name}`,
                ];
              }
              return [
                `{normal|${formatPercentage(params.value)}}\n${params.name}`,
              ];
            },
            rich: {
              red: {
                color: "#E86386",
                fontSize: 18,
                fontWeight: "bold",
                padding: [0, 6, 4, 6],
              },
              normal: {
                color: "#101010",
                fontSize: 18,
                fontWeight: "bold",
                padding: [0, 6, 4, 6],
              },
              green: {
                color: "#50BF14",
                fontSize: 18,
                fontWeight: "bold",
                padding: [0, 0, 4, 0],
              },
              grey: {
                color: "#A0A0A0",
                fontSize: 18,
                fontWeight: "bold",
                padding: [0, 0, 4, 0],
              },
            },
            backgroundColor: "#f3f5fa",
            padding: [7, 15],
            borderRadius: 4,
          },
        },
        edgeShape: "polyline", //线条形状折线
        leaves: {
          label: {
            normal: {
              position: "inside",
              rotate: 0,
              verticalAlign: "middle",
              align: "center",
              fontSize: 12,
              distance: 20,
            },
          },
        },

        animationDurationUpdate: 750,
      },
    ],
  })(extraOptions);

// 长方形图例的配置
export const getRectangleLegendConfig = () => ({
  icon: "path://M46.54499816894531,363.05499267578125l930.9100189208984,0l0,279.27203369140625L46.54499816894531,642.3270263671875Z",
  itemWidth: 20,
});
// 正方形图例的配置
export const getSquareLegendConfig = () => ({
  icon: "path://M85.33333587646484,85.33333587646484m53.333335876464844,0l746.6666412353516,0q53.3333740234375,0,53.3333740234375,53.333335876464844l0,746.6666412353516q0,53.3333740234375,-53.3333740234375,53.3333740234375l-746.6666412353516,0q-53.333335876464844,0,-53.333335876464844,-53.3333740234375l0,-746.6666412353516q0,-53.333335876464844,53.333335876464844,-53.333335876464844Z",
  itemWidth: 15,
});

export const getScatterTooltipItem = (
  color: string,
  name: string,
  seriesName: string,
  value: number | string,
  accountName?: string
) => `<div style="display:flex;align-items:center;height:20px;margin-top: 3px">
<span style="display:inline-block;margin-right:5px;border-radius: 50%;width:10px;height:10px;background-color:${[
  color,
]};"></span>
${seriesName}:${name}:
${value}${accountName ? "(" + accountName + ")" : ""}
</div>`;

export const getTooltipItem = (
  color: string,
  name: string,
  value: number | string,
  width: number,
  height: number
) => `<div style="display:flex;align-items:center;height:20px;margin-top: 3px">
<span style="display:inline-block;margin-right:5px;width:${width}px;height:${height}px;background-color:${[
  color,
]};"></span>
${name}:
${value}
</div>`;

export const getLineChartOptions = (
  dates: string[],
  legendData: string[],
  options?: LineChartOpts["options"]
): LineChartOpts["options"] => {
  const yearOfDates = getLastDateOfYear(dates);
  return merge({
    xAxis: {
      data: dates,
      nameGap: 40,
      axisLabel: {
        interval: (index: number, value: string) => {
          const year = fastProp(value)(yearOfDates);
          return year ? true : false;
        },
        formatter(value: string) {
          return `${fastProp(value)(yearOfDates)}${getMessage("year")}`;
        },
      },
    },
    yAxis: {
      min: "dataMin",
      max: "dataMax",
      axisLabel: {
        formatter(value: number) {
          return formatPercentage(value);
        },
      },
    },
    legend: {
      left: 0,
      top: 0,
      ...getRectangleLegendConfig(),
      data: legendData,
    },
    tooltip: {
      valueFormatter: (value: any) => {
        return formatPercentage(value);
      },
    },
  })(options);
};
