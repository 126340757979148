import { useState } from "react";
import { useMemoizedFn } from "ahooks";
import { PromiseType } from "@/model/promise";

type UseRequestReturn<T, R> = {
  loading: boolean;
  data: R | undefined;
  request: (param: T) => Promise<any>;
};

export const useRequest = <T, R>(
  apiRequest: (param: T) => PromiseType<R>
): UseRequestReturn<T, R> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<R | undefined>();

  return {
    loading,
    data,
    request: useMemoizedFn((param: T) => {
      setLoading(true);
      return apiRequest(param)
        .then((result: R) => {
          setLoading(false);
          setData(result);
          return result;
        })
        .catch(() => {
          setLoading(false);
        });
    }),
  };
};
