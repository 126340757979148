import React, { useMemo } from "react";
import { merge, map, flow, prop, flatten, max, isEmpty } from "lodash/fp";
import { fixedHundredMillion } from "@/util/numberFormatter";
import { SeriesOption, ChartProps } from "../distributionChangeChart/type";
import { getChartOption } from "../yieldTrendChart/helper";
import BaseChart from "../baseChart";

export const getYxiasFixedNumberSplitLineConfit = (
  series: SeriesOption[],
  splitLineNumber: number
) => {
  if (isEmpty(series)) {
    return {};
  }
  const values: number[] = flow(
    map((item: SeriesOption) =>
      map((v: number[]) => v?.[1])(prop("data")(item))
    ),
    flatten
  )(series);
  const maxValue = max(values) || 0;
  const minValue = 0;
  const distance =
    Math.round((((maxValue - minValue) * 1.1) / splitLineNumber) * 10000) /
    10000;
  return {
    max: splitLineNumber * distance,
    min: 0,
    interval: distance,
  };
};

const getSerie = (serie: SeriesOption) => ({
  ...serie,
  type: "bar",
  barMaxWidth: 30,
});

export default React.memo<ChartProps>(
  ({
    title,
    dates = [],
    series,
    height = 300,
    showDataZoom = true,
    showLegend = true,
    saveImgEnable = false,
    splitNumber,
  }) => {
    const finalSeries = useMemo(() => map(getSerie)(series), [series]);
    const chartOption = useMemo(() => {
      const result = merge(
        getChartOption({
          title,
          showLegend,
          showDataZoom,
          saveImgEnable,
          dates,
          series: finalSeries,
          splitNumber,
        })
      )({
        yAxis: {
          // ...(showDataZoom ? {}:
          //   getYxiasFixedNumberSplitLineConfit(
          //     finalSeries,
          //     getSplitNumber({
          //       splitNumber,
          //       showDataZoom,
          //       showLegend,
          //       height,
          //     })
          //   )),
          axisLabel: {
            formatter: (v: number) => fixedHundredMillion(v),
          },
        },
        legend: {
          top: 0,
        },
        title: {
          top: 15,
          textStyle: {
            color: "#647286",
            fontSize: 12,
          },
        },
        xAxis: {
          boundaryGap: true,
          axisTick: {
            show: false,
          },
        },
        tooltip: {
          valueFormatter: (v: number) => fixedHundredMillion(v),
        },
      });
      return result;
    }, [
      showLegend,
      showDataZoom,
      saveImgEnable,
      dates,
      title,
      splitNumber,
      finalSeries,
    ]);

    return <BaseChart option={chartOption} height={height} />;
  }
);
