import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createCustomBenchmarkApi,
  deleteCustomBenchmarkApi,
  getCustomBenchmarkListApi,
} from "@/api/customBenchmark";
import { CustomBenchmarkInterface } from "@/model/entities";
import createSocketThunk from "@/util/createSocketThunk";
import { TaskData } from "@/util/socket";
import { dealWithBenchmarkData } from "@/components/customBenchmarkDialog/hook";
import { first, flow, prop } from "lodash/fp";
import { fastProp } from "@/util/opt";

export const fetchCustomBenchmarkList = avoidMultiRequestActionThunk<void>(
  "customBenchmark.customBenchmarkList",
  createAsyncThunk("customBenchmark/customBenchmarkList", async () => {
    const response = await getCustomBenchmarkListApi();
    return dealWithBenchmarkData(response);
  })
);

export const createCustomBenchmark = createSocketThunk(
  "customBenchmark/createCustomBenchmark",
  "FINISH_CREATE_ASSET_BENCHMARK_MANAGE_PORTFOLIO",
  async (params: CustomBenchmarkInterface) => {
    const response = await createCustomBenchmarkApi(params);
    return {
      ...response,
      conditions: flow(
        fastProp("conditions"),
        first,
        fastProp("config")
      )(response),
    };
  }
);

export const deleteCustomBenchmark = createAsyncThunk(
  "customBenchmark/deleteCustomBenchmark",
  async (id: string) => {
    const response = await deleteCustomBenchmarkApi(id);
    return response;
  }
);
interface initCustomBenchmark {
  customBenchmarkList: CustomBenchmarkInterface[];
  createCustomBenchmarkProgress?: TaskData | undefined;
}

const initialState: initCustomBenchmark = {
  customBenchmarkList: [],
};

const createCustomBenchmarkFinish: string = createCustomBenchmark.finish.type;
const createCustomBenchmarkError: string = createCustomBenchmark.error.type;
const createCustomBenchmarkProgress: string =
  createCustomBenchmark.progress.type;

const customBenchmarkSlice = createSlice({
  name: "customBenchmark",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCustomBenchmarkList.fulfilled.type]: (state, action) => {
      state.customBenchmarkList = action.payload;
    },
    [createCustomBenchmarkFinish]: (state, action) => {
      if (
        state.customBenchmarkList.findIndex(
          (item) => item.id === action.meta?.arg?.id
        ) === -1
      ) {
        state.customBenchmarkList.unshift({
          ...fastProp("result")(action?.payload),
          conditions:
            flow(
              prop("result.conditions"),
              first,
              fastProp("config")
            )(action?.payload) || {},
        });
      } else {
        state.customBenchmarkList = state.customBenchmarkList.map(
          (customBenchmarkItem) => {
            if (customBenchmarkItem.id === action.meta?.arg?.id) {
              return {
                ...customBenchmarkItem,
                ...fastProp("result")(action?.payload),
                conditions:
                  flow(
                    prop("result.conditions"),
                    first,
                    fastProp("config")
                  )(action?.payload) || {},
              };
            }
            return customBenchmarkItem;
          }
        );
      }
      state.createCustomBenchmarkProgress = action.meta;
    },
    [createCustomBenchmarkError]: (state, action) => {
      state.createCustomBenchmarkProgress = action.error;
    },
    [createCustomBenchmarkProgress]: (state, action) => {
      state.createCustomBenchmarkProgress = action.payload;
    },
    [deleteCustomBenchmark.fulfilled.type]: (state, action) => {
      const removeId = action?.meta?.arg;
      if (removeId) {
        state.customBenchmarkList = state.customBenchmarkList.filter(
          ({ id }) => id !== removeId
        );
      }
    },
  },
});
export default customBenchmarkSlice.reducer;
