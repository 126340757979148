import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMemoizedFn, useScroll, useThrottle } from "ahooks";
import { compact, forEach, prop, size } from "lodash/fp";
import { Button, InputNumber, Pagination, message } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import { useWindowSizeContext } from "@/providers/windowSizeProvider";

import { useAppSelector } from "@/hooks/redux";
import { fundIdMapSelector } from "@/selectors/fund";

import FirstPage from "./components/pages/firstPage";
import style from "./index.module.less";
import SecondPage from "./components/pages/secondPage";
import ThirdPage from "./components/pages/thirdPage";
import FourthPage from "./components/pages/fourthPage";
import FifthPage from "./components/pages/fifthPage";
import SixthPage from "./components/pages/sixthPage";
import Context from "./context";
import { getPDFDownloadLink } from "./constants";
import { openUrl } from "@/util/browser";
import { EvaluationReportInfoBody } from "@/model/fundDetail";

const forEachIndexed = forEach.convert({ cap: false });

export default React.memo(({ fundId }: { fundId: string }) => {
  const formatMessage = useFormatMessage();
  const [isExporting, setIsExporting] = useState(false);
  const fundMap = useAppSelector(fundIdMapSelector);

  const fundEvaluationReportInfo: EvaluationReportInfoBody = useAppSelector(
    prop(`fundDetail.${fundId}.evaluationReportInfo`)
  );

  const reportDate = useMemo(
    () => prop(`reportDate`)(fundEvaluationReportInfo),
    [fundEvaluationReportInfo]
  );

  const handleOk = useMemoizedFn(async () => {
    setIsExporting(true);
    const link = getPDFDownloadLink(
      fundId ?? "",
      process.env.NODE_ENV === "development"
        ? PROXY_ENV
        : window.location.origin, // refer 决定从哪个环境导出pdf
      reportDate
    );
    const { default: jsonp } = await import("jsonp");
    jsonp(link, (err, downloadLink) => {
      setIsExporting(false);
      if (err) message.error(formatMessage("systemError"));
      else if (downloadLink) {
        openUrl(downloadLink);
      }
    });
  });

  const fundEvaluationReportWrapRef = useRef<HTMLDivElement>(null);
  const firstPageRef = useRef<HTMLDivElement>(null);
  const secondPageRef = useRef<HTMLDivElement>(null);
  const thirdPageRef = useRef<HTMLDivElement>(null);
  const fourthPageRef = useRef<HTMLDivElement>(null);
  const fifthPageRef = useRef<HTMLDivElement>(null);
  const [sixPageDoms, setSixPageDoms] = useState<HTMLDivElement[]>([]);

  const [zoomVal, setZoomVal] = useState(100);

  const pageDoms = useMemo(
    () =>
      compact([
        firstPageRef.current,
        secondPageRef.current,
        thirdPageRef.current,
        fourthPageRef.current,
        fifthPageRef.current,
        ...sixPageDoms,
      ]),
    [sixPageDoms]
  );
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const handlePageChange = useMemoizedFn((val) => {
    setCurrentPageNum(val);
    setNotUpdateTop(true);

    fundEvaluationReportWrapRef.current?.scrollTo({
      top:
        ((pageDoms[val - 1]?.offsetTop || 0) -
          (firstPageRef.current?.offsetTop || 0)) *
        (zoomVal / 100),
    });
  });

  const { containerHeight } = useWindowSizeContext();

  const [notUpdateTop, setNotUpdateTop] = useState(false);
  const { top } =
    useScroll(fundEvaluationReportWrapRef, () => {
      if (notUpdateTop) {
        setNotUpdateTop(false);
        return false;
      }
      return true;
    }) || {};

  const throttledTop = useThrottle(top, { wait: 300 });
  const scrollHandler = useMemoizedFn(() => {
    throttledTop &&
      forEachIndexed((pageDom: HTMLDivElement, index: number) => {
        const getDistance = (dom: HTMLDivElement) =>
          throttledTop -
          ((dom?.offsetTop ?? 0) - (firstPageRef.current?.offsetTop ?? 0)) *
            (zoomVal / 100);
        const distance = getDistance(pageDom);

        const pageHeight = pageDom.offsetHeight * (zoomVal / 100);
        if (distance > 0 && distance < pageHeight) {
          const currentPageDomDistance = getDistance(
            pageDoms[currentPageNum - 1]
          );
          if (currentPageDomDistance > 0 && currentPageDomDistance < pageHeight)
            return false;
          setCurrentPageNum(index + 1);
          return false;
        }
      })(pageDoms);
  });
  useEffect(() => {
    scrollHandler();
  }, [throttledTop, scrollHandler]);

  return (
    <Context.Provider value={{ fundMap }}>
      <div
        className={style.FundEvaluationReportWrap}
        ref={fundEvaluationReportWrapRef}
        style={{ height: containerHeight - 16 }}
      >
        <div className={style.TopBar}>
          <Pagination
            defaultCurrent={1}
            current={currentPageNum}
            simple
            pageSize={1}
            total={size(pageDoms) || 1}
            onChange={handlePageChange}
          />
          <div className={style.ZoomWrap}>
            <InputNumber
              min={1}
              max={150}
              precision={0}
              step={10}
              formatter={(value) => `${value}%`}
              parser={(value) => Number((value || "").replace("%", ""))}
              value={zoomVal}
              onChange={(value) => setZoomVal(value || zoomVal)}
            />
          </div>
          <Button onClick={handleOk} loading={isExporting}>
            {formatMessage("downloadReport")}
          </Button>
        </div>
        <div
          className={style.PreviewContentWrap}
          style={{ zoom: zoomVal / 100 || 1 }}
        >
          <FirstPage fundId={fundId} ref={firstPageRef} />
          <SecondPage fundCode={fundId} ref={secondPageRef} />
          <ThirdPage fundId={fundId} ref={thirdPageRef} />
          <FourthPage fundId={fundId} ref={fourthPageRef} />
          <FifthPage fundId={fundId} ref={fifthPageRef} />
          <SixthPage fundId={fundId} setSixPageDoms={setSixPageDoms} />
        </div>
      </div>
    </Context.Provider>
  );
});
