import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  useCreation,
  useMemoizedFn,
  useSafeState,
  useUpdateEffect,
} from "ahooks";
import dayjs from "dayjs";
import { updateFundConfiguration } from "@/store/createPortfolio";
import { first, flow, isEmpty, isNil, map, set, some, sumBy } from "lodash/fp";
import { useEffect, useMemo } from "react";
import { Asset } from "../manualCreatePortfolio/constant";
import {
  useGenerateAsset,
  useTransferAssetToFundAsset,
} from "../manualCreatePortfolio/hooks";
import {
  checkSomeFundRunTimeLess3Month,
  modelsConfig,
  getAssetInterSectionDate,
  rebalanceConfig,
  balanceRuleConfig,
  periodFrequencyConfig,
  getNetValueStartDate,
  getAvailableDateRange,
  serializeAllocateDataHelper,
  fundConfigurationSteps,
} from "./constant";
import {
  ErrorField,
  FundConfigurationAsset,
  FundConfigurationAssetsUpdater,
  FundConfigurationForm,
} from "./interface";
import { getScaleFirstDate } from "@/api/portfolioAnalysis";
import { fastProp } from "@/util/opt";
import { getNextTradingDate } from "@/util/processedDates";

export const useManageConfigurationAssets = () => {
  const { configurationAssets } = useAppSelector(
    (state) => state.createPortfolio.fundConfiguration
  );
  const dispatch = useAppDispatch();
  const updateAssets = useMemoizedFn(
    (updater: FundConfigurationAssetsUpdater) =>
      dispatch(updateFundConfiguration("configurationAssets", updater))
  );
  return {
    configurationAssets,
    updateAssets,
  };
};

export const useGenerateFundConfigurationAsset = () => {
  const generateAsset = useGenerateAsset();
  return useMemoizedFn((ids: string[]) =>
    flow(
      generateAsset,
      map<Asset, FundConfigurationAsset>((asset) => ({
        ...asset,
        minWeight: 0,
        maxWeight: 1,
      }))
    )(ids)
  );
};

export const useManageErrorField = ({
  assets,
  startDate,
  netValueStart,
  endDate,
  formData,
}: {
  assets: FundConfigurationAsset[];
  startDate: string;
  netValueStart: string;
  endDate: string;
  formData: FundConfigurationForm;
}) => {
  const { errorField } = useAppSelector(
    (state) => state.createPortfolio.fundConfiguration
  );
  const dispatch = useAppDispatch();
  const setErrorField = useMemoizedFn(
    (updater: (errorField: ErrorField) => ErrorField) =>
      dispatch(updateFundConfiguration("errorField", updater))
  );
  const transfer = useTransferAssetToFundAsset();
  useEffect(() => {
    if (!isEmpty(assets)) {
      const fundMinWeightError = sumBy("minWeight")(assets) > 1;
      const fundMaxWeightError = sumBy("maxWeight")(assets) < 1;
      const fundLimitGreaterLowerErr = some<FundConfigurationAsset>(
        (v) => v.minWeight > v.maxWeight
      )(assets);
      const fundLess3MothError = checkSomeFundRunTimeLess3Month(assets);
      const fundEmptyError = isEmpty(assets);
      let netValueStartDateZeroError = false;
      let latestNetUnitValueZeroError = false;
      const transferData = transfer(assets);
      if (some((item: any) => isNil(fastProp("netValue")(item)))(transferData))
        latestNetUnitValueZeroError = true;
      else latestNetUnitValueZeroError = false;
      if (
        some((item: any) => isNil(fastProp("netValueStartDate")(item)))(
          transferData
        )
      )
        netValueStartDateZeroError = true;
      else netValueStartDateZeroError = false;
      setErrorField((errorField) => ({
        ...errorField,
        fundMinWeightError,
        fundMaxWeightError,
        fundLimitGreaterLowerErr,
        fundLess3MothError,
        fundEmptyError,
        latestNetUnitValueZeroError,
        netValueStartDateZeroError,
      }));
    }
  }, [assets, setErrorField, transfer]);
  useEffect(() => {
    if (startDate && endDate) {
      setErrorField(
        set("fundIntersectionDateError", dayjs(startDate).isAfter(endDate))
      );
    }
  }, [startDate, endDate, setErrorField]);

  useEffect(() => {
    if (netValueStart && endDate) {
      setErrorField(
        set("netValueStartDateBiggerThanEndDate", netValueStart >= endDate)
      );
    }
  }, [netValueStart, endDate, setErrorField]);

  useEffect(() => {
    setErrorField(
      set(
        "riskValueError",
        formData.modelType === modelsConfig.maximizationOfExpected &&
          isNil(formData.riskValue)
      )
    );
  }, [formData.modelType, formData.riskValue, setErrorField]);

  useEffect(() => {
    setErrorField(set("modelTypeError", isEmpty(formData.modelType)));
  }, [formData.modelType, setErrorField]);

  useEffect(() => {
    setErrorField(set("rebalanceConfigError", isEmpty(formData.rebalanceType)));
  }, [formData.rebalanceType, setErrorField]);

  useEffect(() => {
    setErrorField(set("foundDateEmptyError", isEmpty(formData.foundDate)));
  }, [formData.foundDate, setErrorField]);

  return errorField;
};

export const useCalcAssetsDateRange = ({
  assets,
  dataRange,
  modelType,
  onUpdateFormData,
}: {
  assets: FundConfigurationAsset[];
  dataRange: string;
  modelType: any;
  onUpdateFormData: any;
}) => {
  const { fundConfigurationFormData } = useAppSelector(
    (state) => state.createPortfolio.fundConfiguration
  );
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  // 基金列表的净值开始和结束日期的交集
  const [startDate, endDate] = useCreation(
    () => getAssetInterSectionDate(assets),
    [assets]
  );
  const initNetValueStart = useCreation(
    () =>
      getNetValueStartDate({
        startDate,
        dataRange,
        modelType,
        tradingDateList,
        processedTradingDates,
      }),
    [startDate, dataRange, modelType, tradingDateList, processedTradingDates]
  );
  useUpdateEffect(() => {
    if (modelType !== modelsConfig.marketWeight) {
      onUpdateFormData("netValueStart")(initNetValueStart);
    }
  }, [initNetValueStart, modelType, onUpdateFormData]);

  // 获取可选的建仓日期范围
  const availableDateRange = useCreation(
    () =>
      getAvailableDateRange({
        startDate: fundConfigurationFormData.netValueStart,
        endDate,
        tradingDateList,
        processedTradingDates,
      }),
    [
      endDate,
      fundConfigurationFormData.netValueStart,
      processedTradingDates,
      tradingDateList,
    ]
  );

  useUpdateEffect(() => {
    const fundIds = map(fastProp("fundId"))(assets);
    if (modelType === modelsConfig.marketWeight && !isEmpty(fundIds)) {
      getScaleFirstDate({
        fundIds,
      }).then((date: string) => {
        const nextTradingDate = getNextTradingDate(
          tradingDateList,
          processedTradingDates,
          date
        );
        onUpdateFormData("foundDate")(nextTradingDate);
        onUpdateFormData("netValueStart")(nextTradingDate);
      });
    }
  }, [
    assets,
    modelType,
    onUpdateFormData,
    tradingDateList,
    processedTradingDates,
  ]);
  // 模型发生变化的时候，日期范围变化的时候以及选择基金变化的时候初始化建仓日期
  useUpdateEffect(() => {
    if (modelType !== modelsConfig.marketWeight) {
      onUpdateFormData("foundDate")(first(availableDateRange));
    }
  }, [modelType, availableDateRange, onUpdateFormData]);
  return {
    startDate,
    endDate,
    netValueStart: fundConfigurationFormData.netValueStart,
    availableDateRange,
  };
};

export const useManageFundConfigurationForm = (
  assets: FundConfigurationAsset[]
) => {
  const { fundConfigurationFormData } = useAppSelector(
    (state) => state.createPortfolio.fundConfiguration
  );
  const dispatch = useAppDispatch();
  const updateFundConfigurationFormData = useMemoizedFn(
    (updater: (formValue: FundConfigurationForm) => FundConfigurationForm) =>
      dispatch(updateFundConfiguration("fundConfigurationFormData", updater))
  );
  const onUpdateFormData = useMemoizedFn(
    (key: keyof FundConfigurationForm) => (value: any) => {
      const oldValue = fastProp(key)(fundConfigurationFormData);
      // 如果相同的话就不必更新了
      if (oldValue === value) return;
      updateFundConfigurationFormData(set(key, value));
    }
  );
  const { startDate, endDate, netValueStart, availableDateRange } =
    useCalcAssetsDateRange({
      assets,
      dataRange: fundConfigurationFormData.dataRange,
      modelType: fundConfigurationFormData.modelType,
      onUpdateFormData,
    });

  // 建仓日期变化的时候初始化再平衡方式的所有值
  useUpdateEffect(() => {
    if (fundConfigurationFormData.foundDate) {
      updateFundConfigurationFormData((data) => ({
        ...data,
        rebalanceType: rebalanceConfig.onlyOnce,
        specifiedDates: [],
        periodicFrequency: periodFrequencyConfig.MONTH,
        turnoverRule: balanceRuleConfig.INIT_WEIGHT,
      }));
    }
  }, [fundConfigurationFormData.foundDate, updateFundConfigurationFormData]);

  useEffect(() => {
    if (fundConfigurationFormData.modelType === modelsConfig.equalWeight) {
      onUpdateFormData("turnoverRule")(balanceRuleConfig.INIT_WEIGHT);
    }
  }, [fundConfigurationFormData.modelType, onUpdateFormData]);

  return useMemo(
    () => ({
      startDate,
      endDate,
      netValueStart,
      onUpdateFormData,
      availableDateRange,
      formData: fundConfigurationFormData,
    }),
    [
      availableDateRange,
      endDate,
      fundConfigurationFormData,
      netValueStart,
      onUpdateFormData,
      startDate,
    ]
  );
};

export const useGetAssetAllocateData = () => {
  const { configurationAssets, fundConfigurationFormData } = useAppSelector(
    (state) => state.createPortfolio.fundConfiguration
  );
  return useMemo(
    () =>
      serializeAllocateDataHelper.serializeData(
        configurationAssets,
        fundConfigurationFormData
      ),
    [configurationAssets, fundConfigurationFormData]
  );
};

// 获取assetAllocateData以及加上assetAllocate是否更新的状态
export const useWatchAllocateData = (currentStep: number) => {
  const assetAllocateData = useGetAssetAllocateData();
  const [formStateChanged, setFormStateChanged] = useSafeState(false);
  useEffect(() => {
    // 当从第二步返回第一步的时候将stateChanged设置为false，相当于初始化，当在第一步改变了configurationAssets, fundConfigurationFormData
    // 这两个数据后stateChanged又为true，会重新发请求，如果没有改变，那么还是为false，就不会去发请求了
    if (currentStep === fundConfigurationSteps.fundAllocation) {
      setFormStateChanged(false);
    }
  }, [currentStep, setFormStateChanged]);

  useEffect(() => {
    // 如果这两个数据有更新，那么stateChanged为true，将会去重新请求数据
    setFormStateChanged(true);
  }, [assetAllocateData, setFormStateChanged]);
  return { assetAllocateData, formStateChanged };
};
