import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { getCategoryTypeId } from "@/components/portfolioCompoents/constant";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { categoryTreeMapSelector } from "@/selectors/category";
import { fundIdMapSelector } from "@/selectors/fund";
import { updateModelConfiguration } from "@/store/createPortfolio";
import { useFormatMessage } from "@/util/formatMessage";
import { toFixedNumber } from "@/util/numberFormatter";
import { fastProp } from "@/util/opt";
import { useCreation, useMemoizedFn, useUpdateEffect } from "ahooks";
import {
  difference,
  first,
  flatten,
  flow,
  groupBy,
  isEmpty,
  isNil,
  join,
  keys,
  map,
  max,
  prop,
  pullAt,
  set,
  size,
  some,
  sumBy,
  update,
  values,
} from "lodash/fp";
import { ReactNode, useEffect, useMemo } from "react";
import {
  FundConfigurationAsset,
  FundConfigurationAssetsUpdater,
  FundConfigurationForm,
} from "../../fundConfiguration/interface";
import { Asset } from "../../manualCreatePortfolio/constant";
import {
  categoryTypeIds,
  categoryTypeIdsOrder,
  categoryTypeMap,
} from "../constant";
import {
  FundWeightProps,
  FundWeightTableProps,
} from "./fundConfigurationTable";
import style from "./index.module.less";
import NumberInput from "@/components/numberInput";
import cn from "classnames";
import { Popconfirm, Tooltip } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useValidateIntersectionDateError } from "../../fundConfiguration/fundAllocation/fundConfigurationTable";
import {
  balanceRuleConfig,
  checkSomeFundRunTimeLess3Month,
  getAddRangeDate,
  getAssetInterSectionDate,
  getAvailableDateRange,
  getNetValueStartDate,
  modelsConfig,
  periodFrequencyConfig,
  rebalanceConfig,
} from "../../fundConfiguration/constant";
import { getScaleFirstDate } from "@/api/portfolioAnalysis";
import { ErrorField } from "../interface";
import dayjs from "dayjs";
import {
  useGetIndexInfo,
} from "../modelAllocation/components/hooks";
import { useTransferAssetToFundAsset } from "../../manualCreatePortfolio/hooks";
import { benchmarkMapSelector } from "@/selectors/benchmarks";
import { getNextTradingDate } from "@/util/processedDates";

export const useManageConfigurationAssets = () => {
  const {
    configurationAssets,
    modelAllocationData: { categorySource, categoryTypes },
  } = useAppSelector((state) => state.createPortfolio.modelAllocation);
  const dispatch = useAppDispatch();
  const updateAssets = useMemoizedFn(
    (updater: FundConfigurationAssetsUpdater) =>
      dispatch(updateModelConfiguration("configurationAssets", updater))
  );
  return useMemo(
    () => ({
      configurationAssets,
      updateAssets,
      categorySource,
      categoryTypes,
    }),
    [categorySource, categoryTypes, configurationAssets, updateAssets]
  );
};

export const useGenerateAsset = () => {
  const fundsMap = useAppSelector(fundIdMapSelector);
  return useMemoizedFn((fundIds: string[]) =>
    map<string, Asset>((id) => {
      const currentFund = fastProp(id)(fundsMap);
      return {
        fundId: id,
        weight: 0,
        maturityDateError: false,
        netValueStartDateError: false,
        maturityDate: fastProp("maturityDate")(currentFund),
        tradingDay: fastProp("tradingDay")(currentFund),
        netValueStartDate: fastProp("netValueStartDate")(currentFund),
        categoryId: fastProp("categoryId")(currentFund),
      };
    })(fundIds)
  );
};

export const useGenerateFundConfigurationAsset = (marketIndex: string) => {
  const generateAsset = useGenerateAsset();
  const categoryTreeMap = useAppSelector(categoryTreeMapSelector);
  return useMemoizedFn(
    (ids: string[]) =>
      flow(
        generateAsset,
        map<Asset, FundConfigurationAsset>((asset) => {
          const category =
            marketIndex ||
            getCategoryTypeId({
              categoryTreeMap,
              categoryId: fastProp("categoryId")(asset),
            });
          return {
            ...asset,
            minWeight: 0,
            maxWeight: 1,
            category,
            categoryOrder: fastProp(category)(categoryTypeIdsOrder),
          };
        })
      )(ids) as Asset[]
  );
};

export const useCategoryTypes = ({
  assets,
  categoryTypeError,
}: {
  assets: FundConfigurationAsset[];
  categoryTypeError: boolean;
}) => {
  const formatMessage = useFormatMessage();
  const { categorySource, categoryTypes } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation.modelAllocationData
  );
  const { factorMap } = useGetIndexInfo();

  const alertMessage = useCreation(
    () =>
      categorySource === "ASSET_CATEGORY"
        ? formatMessage("SelectFundDialogTip", {
            types: flow(
              map((v: string) => prop([v, "label"])(categoryTypeMap)),
              join("、")
            )(categoryTypes),
          })
        : "",
    [categoryTypes]
  );

  const categoryIds = useCreation(
    () =>
      categorySource === "ASSET_CATEGORY"
        ? map((v: string) => fastProp(v)(categoryTypeIds))(categoryTypes)
        : categoryTypes,
    [categorySource, categoryTypes]
  );

  const categoryTreeMap = useAppSelector(categoryTreeMapSelector);
  const categoryIdErrorMessage = useMemo(() => {
    if (!categoryTypeError && categorySource !== "MARKET_INDEX") return "";
    const errorIds = flow(groupBy("category"), keys, (ids: string[]) =>
      difference(categoryIds, ids)
    )(assets);
    if (isEmpty(errorIds)) return "";
    return formatMessage("categoryIdErrorMessage", {
      key: formatMessage(
        categorySource === "ASSET_CATEGORY" ? "category" : "index"
      ),
      types: flow(
        map((v: string) =>
          prop([v, "name"])(
            categorySource === "ASSET_CATEGORY" ? categoryTreeMap : factorMap
          )
        ),
        join("、")
      )(errorIds),
    });
  }, [
    assets,
    categoryIds,
    categorySource,
    categoryTreeMap,
    categoryTypeError,
    factorMap,
    formatMessage,
  ]);
  return useMemo(
    () => ({
      alertMessage,
      categoryIds:
        categorySource === "ASSET_CATEGORY" ? categoryIds : undefined,
      categoryIdErrorMessage,
    }),
    [alertMessage, categoryIdErrorMessage, categoryIds, categorySource]
  );
};

export const cellRender = (node: ReactNode, record: any) => ({
  children: node,
  props: {
    rowSpan: record.rowSpan,
    colSpan: record.colSpan,
  },
});

export const mergeRowsFunc = (rows: FundConfigurationAsset[], key: string) => {
  return flow(
    groupBy(key),
    values,
    map((value: FundConfigurationAsset[]) => {
      const asset = first(value);
      const rowSpan = size(value);
      return map((v: FundConfigurationAsset) => {
        const isFirst = fastProp("fundId")(asset) === v.fundId;
        return {
          ...v,
          categoryAssetWeight: sumBy("weight")(value),
          rowSpan: isFirst ? rowSpan : 0,
        };
      })(value);
    }),
    flatten
  )(rows);
};

export const useGetFundWeightTableColumns = ({
  onChange,
  errorField,
  assetIntersectionStartDate,
  assetIntersectionEndDate,
}: Pick<
  FundWeightProps,
  | "onChange"
  | "errorField"
  | "assetIntersectionStartDate"
  | "assetIntersectionEndDate"
>) => {
  const formatMessage = useFormatMessage();
  const { categorySource } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation.modelAllocationData
  );
  const { factorMap } = useGetIndexInfo();
  const categoryTreeMap = useAppSelector(categoryTreeMapSelector);
  const onRemoveAsset = useMemoizedFn(
    (index: number) => onChange && onChange(pullAt(index))
  );
  const onChangeMinWeight = useMemoizedFn(
    (index: number) => (value: number | null) =>
      onChange && onChange(update(index, set("minWeight", value)))
  );
  const onChangeMaxWeight = useMemoizedFn(
    (index: number) => (value: number | null) =>
      onChange && onChange(update(index, set("maxWeight", value)))
  );
  const { validateStartDateError, validateEndDateError } =
    useValidateIntersectionDateError(
      assetIntersectionStartDate,
      assetIntersectionEndDate
    );
  return useMemo<FundWeightTableProps["columns"]>(
    () => [
      {
        key: "category",
        dataIndex: "category",
        width: categorySource === "ASSET_CATEGORY" ? 60 : 120,
        title: formatMessage(
          categorySource === "ASSET_CATEGORY" ? "category" : "index"
        ),
        render: (text, record) =>
          cellRender(
            prop([text, "name"])(
              categorySource === "ASSET_CATEGORY" ? categoryTreeMap : factorMap
            ),
            record
          ),
        align: "center",
        fixed: "left",
        ellipsis: true,
      },
      {
        key: "name",
        dataIndex: "name",
        title: formatMessage("fundName"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
        fixed: "left",
      },
      {
        key: "code",
        dataIndex: "code",
        title: formatMessage("fundCode"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
        fixed: "left",
      },
      {
        key: "investType",
        dataIndex: "investType",
        title: formatMessage("policyType"),
      },
      {
        key: "netValue",
        dataIndex: "netValue",
        width: 200,
        align: "right",
        title: (
          <div>
            {formatMessage("latestNetUnitValue")}
            {errorField && errorField.latestNetUnitValueZeroError && (
              <Tooltip title={formatMessage("lackLatestNetUnitValue")}>
                <ExclamationCircleFilled className={style.ErrorAlert} />
              </Tooltip>
            )}
          </div>
        ),
        render: (value: number, record: any) => {
          const isLackNetValueError = isNil(value);
          return (
            <div>
              {toFixedNumber(4)(value)}
              <span className={style.GreyColor}>
                （{fastProp("tradingDay")(record)}）
              </span>
              {isLackNetValueError && (
                <Tooltip title={formatMessage("lackLatestNetUnitValue")}>
                  <ExclamationCircleFilled className={style.ErrorAlert} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        key: "tradingDay",
        dataIndex: "tradingDay",
        title: (
          <div>
            {formatMessage("netValueStartAndEndDate")}
            {errorField &&
              (errorField.fundIntersectionDateError ||
                errorField.netValueStartDateZeroError) && (
                <ExclamationCircleFilled className={style.ErrorAlert} />
              )}
          </div>
        ),
        render: (_, record) => {
          const netValueStartDate = fastProp("netValueStartDate")(record);
          const startDateError = validateStartDateError(netValueStartDate);
          const endDateError = validateEndDateError(_);
          const startDateZeroError = isNil(netValueStartDate);
          return (
            <div>
              <span className={startDateError ? style.ErrorField : undefined}>
                {fastProp("netValueStartDate")(record) || "--"}
              </span>{" "}
              {formatMessage("to")}{" "}
              <span className={endDateError ? style.ErrorField : undefined}>
                {_ || "--"}
              </span>
              {(startDateError || endDateError) && (
                <Tooltip
                  title={
                    startDateError
                      ? formatMessage("LatestNetWorthStartDateError")
                      : formatMessage("LatestUnitNetWorthDateError")
                  }
                >
                  <ExclamationCircleFilled className={style.ErrorAlert} />
                </Tooltip>
              )}
              {startDateZeroError && (
                <Tooltip title={formatMessage("lackNetValueStartDate")}>
                  <ExclamationCircleFilled className={style.ErrorAlert} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        key: "minWeight",
        dataIndex: "minWeight",
        width: 150,
        title: formatMessage("AssetsMinWeight"),
        render: (minWeight: number, record: any, index: number) => {
          const weightError = minWeight > fastProp("maxWeight")(record);
          return (
            <div className={style.Flex}>
              <NumberInput
                type="PERCENTAGE"
                min={0}
                max={1}
                className={cn({
                  [style.WeightInput]: true,
                  [style.InputError]: weightError,
                })}
                value={minWeight}
                onChange={onChangeMinWeight(index)}
              />
              {weightError && (
                <Tooltip title={formatMessage("AssetsMinWeightGreaterThanTip")}>
                  <ExclamationCircleFilled className={style.ErrorAlert} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        key: "maxWeight",
        dataIndex: "maxWeight",
        width: 100,
        title: formatMessage("AssetsMaxWeight"),
        render: (maxWeight: number, record: any, index: number) => (
          <NumberInput
            type="PERCENTAGE"
            min={0}
            max={1}
            className={style.WeightInput}
            value={maxWeight}
            onChange={onChangeMaxWeight(index)}
          />
        ),
      },
      {
        key: "operator",
        dataIndex: "operator",
        fixed: "right",
        width: 60,
        align: "center",
        title: formatMessage("operator"),
        render: (_: any, record: any, index: number) => (
          <Popconfirm
            title={formatMessage("deleteFundConfirm")}
            onConfirm={() => onRemoveAsset(index)}
            okText={formatMessage("ok")}
            cancelText={formatMessage("cancel")}
          >
            <span className={style.Operator}>{formatMessage("delete")}</span>
          </Popconfirm>
        ),
      },
    ],
    [
      categorySource,
      categoryTreeMap,
      errorField,
      factorMap,
      formatMessage,
      onChangeMaxWeight,
      onChangeMinWeight,
      onRemoveAsset,
      validateEndDateError,
      validateStartDateError,
    ]
  );
};

export const useCalcAssetsDateRange = ({
  assets,
  dataRange,
  modelType,
  onUpdateFormData,
}: {
  assets: FundConfigurationAsset[];
  dataRange: string;
  modelType: any;
  onUpdateFormData: any;
}) => {
  const { fundConfigurationFormData,modelAllocationData } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation
  );
  const { allocateConfig } = modelAllocationData
  const categoryToBenchmark = useAppSelector(
    (state) => state.entities.categoryToBenchmark
  );
  const benchmarkMap = useAppSelector(benchmarkMapSelector)
  const categoryTypes = fastProp("categoryTypes")(modelAllocationData)
  const { tradingDateList, processedTradingDates } = useAppSelector(
    (state) => state.tradingDates
  );
  //大类对应基准的最大开始时间,加上类型配置的模型时长
  const maxBenchmarkDate = useMemo(()=>{
    let benchmarkIds = categoryTypes
    if(modelAllocationData?.categorySource === "ASSET_CATEGORY"){
      benchmarkIds = map((type:string)=>fastProp(type)(categoryToBenchmark))(categoryTypes)
    }
    return flow(
      map((id:string)=>prop(`${id}.historyStart`)(benchmarkMap)),
      max,
      (res)=>getAddRangeDate(tradingDateList, processedTradingDates)(
        allocateConfig.dataRange,
        res
      )
      )(benchmarkIds)
  },[benchmarkMap,categoryToBenchmark,categoryTypes,modelAllocationData.categorySource,processedTradingDates,tradingDateList,allocateConfig.dataRange])

  // 指数与模型的时间交集
  // const { indexStartDate, indexEndDate } = useGetModelDurationOptions();
  // 基金列表的净值开始和结束日期的交集
  const [startDate, endDate] = useCreation(() => {
    const [assetsStart, assetsEnd] = getAssetInterSectionDate(assets);
    return [assetsStart,assetsEnd];
  }, [assets]);
  const initNetValueStart = useCreation(
    () => {
      const assetNetValue = getNetValueStartDate({
        startDate: startDate,
        dataRange,
        modelType,
        tradingDateList,
        processedTradingDates,
      })
      const maxDate = max([assetNetValue,maxBenchmarkDate])
      return getNextTradingDate(
        tradingDateList,
        processedTradingDates,
        maxDate,
      );
    },
    [startDate, dataRange, modelType, tradingDateList, processedTradingDates,maxBenchmarkDate]
  );
  useEffect(() => {
    if (modelType !== modelsConfig.marketWeight) {
      onUpdateFormData("netValueStart")(initNetValueStart);
    }
  }, [initNetValueStart, modelType, onUpdateFormData]);

  // 获取可选的建仓日期范围
  const availableDateRange = useCreation(
    () =>
      getAvailableDateRange({
        startDate: fundConfigurationFormData.netValueStart,
        endDate,
        tradingDateList,
        processedTradingDates,
      }),
    [
      endDate,
      fundConfigurationFormData.netValueStart,
      processedTradingDates,
      tradingDateList,
    ]
  );

  useUpdateEffect(() => {
    const fundIds = map(fastProp("fundId"))(assets);
    if (modelType === modelsConfig.marketWeight && !isEmpty(fundIds)) {
      getScaleFirstDate({
        fundIds,
      }).then((date: string) => {
        const maxDate = max([date,maxBenchmarkDate]) as string;
        const nextTradingDate = getNextTradingDate(
          tradingDateList,
          processedTradingDates,
          maxDate,
        );
        
        onUpdateFormData("foundDate")(nextTradingDate);
        onUpdateFormData("netValueStart")(nextTradingDate);
      });
    }
  }, [
    assets,
    modelType,
    onUpdateFormData,
    tradingDateList,
    processedTradingDates,
    maxBenchmarkDate,
  ]);
  // 模型发生变化的时候，日期范围变化的时候以及选择基金变化的时候初始化建仓日期
  useEffect(() => {
    if (modelType !== modelsConfig.marketWeight) {
      onUpdateFormData("foundDate")(first(availableDateRange));
    }
  }, [modelType, availableDateRange, onUpdateFormData]);
  return {
    startDate,
    endDate,
    netValueStart: fundConfigurationFormData.netValueStart,
    availableDateRange,
  };
};

export const useManageFundConfigurationForm = (
  assets: FundConfigurationAsset[]
) => {
  const { fundConfigurationFormData } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation
  );
  const dispatch = useAppDispatch();
  const updateFundConfigurationFormData = useMemoizedFn(
    (updater: (formValue: FundConfigurationForm) => FundConfigurationForm) => {
      dispatch(updateModelConfiguration("fundConfigurationFormData", updater));
    }
  );
  const onUpdateFormData = useMemoizedFn(
    (key: keyof FundConfigurationForm) => (value: any) => {
      const oldValue = fastProp(key)(fundConfigurationFormData);
      // 如果相同的话就不必更新了
      if (oldValue === value) return;
      updateFundConfigurationFormData(set(key, value));
    }
  );
  const { startDate, endDate, netValueStart, availableDateRange } =
    useCalcAssetsDateRange({
      assets,
      dataRange: fundConfigurationFormData.dataRange,
      modelType: fundConfigurationFormData.modelType,
      onUpdateFormData,
    });

  // 建仓日期变化的时候初始化再平衡方式的所有值
  useUpdateEffect(() => {
    if (fundConfigurationFormData.foundDate) {
      updateFundConfigurationFormData((data) => ({
        ...data,
        rebalanceType: rebalanceConfig.onlyOnce,
        specifiedDates: [],
        periodicFrequency: periodFrequencyConfig.MONTH,
        turnoverRule: balanceRuleConfig.INIT_WEIGHT,
      }));
    }
  }, [fundConfigurationFormData.foundDate, updateFundConfigurationFormData]);

  useEffect(() => {
    if (fundConfigurationFormData.modelType === modelsConfig.equalWeight) {
      onUpdateFormData("turnoverRule")(balanceRuleConfig.INIT_WEIGHT);
    }
  }, [fundConfigurationFormData.modelType, onUpdateFormData]);

  return useMemo(
    () => ({
      startDate,
      endDate,
      netValueStart,
      onUpdateFormData,
      availableDateRange,
      formData: fundConfigurationFormData,
    }),
    [
      availableDateRange,
      endDate,
      fundConfigurationFormData,
      netValueStart,
      onUpdateFormData,
      startDate,
    ]
  );
};

const useGetCategoryTypeError = () => {
  const { categoryTypes } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation.modelAllocationData
  );
  return useMemoizedFn((assets: FundConfigurationAsset[]) => {
    return size(categoryTypes) !== flow(groupBy("category"), size)(assets);
  });
};

export const useManageErrorField = ({
  assets,
  startDate,
  netValueStart,
  endDate,
  formData,
}: {
  assets: FundConfigurationAsset[];
  startDate: string;
  netValueStart: string;
  endDate: string;
  formData: FundConfigurationForm;
}) => {
  const { errorField } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation
  );
  const dispatch = useAppDispatch();
  const setErrorField = useMemoizedFn(
    (updater: (errorField: ErrorField) => ErrorField) =>
      dispatch(updateModelConfiguration("errorField", updater))
  );
  const getCategoryTypeError = useGetCategoryTypeError();
  const transfer = useTransferAssetToFundAsset();
  useEffect(() => {
    if (!isEmpty(assets)) {
      const fundMinWeightError = sumBy("minWeight")(assets) > 1;
      const fundMaxWeightError = sumBy("maxWeight")(assets) < 1;
      const fundLimitGreaterLowerErr = some<FundConfigurationAsset>(
        (v) => v.minWeight > v.maxWeight
      )(assets);
      const fundLess3MothError = checkSomeFundRunTimeLess3Month(assets);
      const fundEmptyError = isEmpty(assets);
      const categoryTypeError = getCategoryTypeError(assets);
      let netValueStartDateZeroError = false;
      let latestNetUnitValueZeroError = false;
      const transferData = transfer(assets);
      if (some((item: any) => isNil(fastProp("netValue")(item)))(transferData))
        latestNetUnitValueZeroError = true;
      else latestNetUnitValueZeroError = false;
      if (
        some((item: any) => isNil(fastProp("netValueStartDate")(item)))(
          transferData
        )
      )
        netValueStartDateZeroError = true;
      else netValueStartDateZeroError = false;
      setErrorField((errorField) => ({
        ...errorField,
        fundMinWeightError,
        fundMaxWeightError,
        fundLimitGreaterLowerErr,
        fundLess3MothError,
        fundEmptyError,
        categoryTypeError,
        latestNetUnitValueZeroError,
        netValueStartDateZeroError,
      }));
    }
  }, [assets, getCategoryTypeError, setErrorField, transfer]);
  useEffect(() => {
    if (startDate && endDate) {
      setErrorField(
        set("fundIntersectionDateError", dayjs(startDate).isAfter(endDate))
      );
    }
  }, [startDate, endDate, setErrorField]);

  useEffect(() => {
    if (netValueStart && endDate) {
      setErrorField(
        set("netValueStartDateBiggerThanEndDate", netValueStart >= endDate)
      );
    }
  }, [netValueStart, endDate, setErrorField]);

  useEffect(() => {
    setErrorField(
      set(
        "riskValueError",
        formData.modelType === modelsConfig.maximizationOfExpected &&
          isNil(formData.riskValue)
      )
    );
  }, [formData.modelType, formData.riskValue, setErrorField]);

  useEffect(() => {
    setErrorField(set("modelTypeError", isEmpty(formData.modelType)));
  }, [formData.modelType, setErrorField]);

  useEffect(() => {
    setErrorField(set("rebalanceConfigError", isEmpty(formData.rebalanceType)));
  }, [formData.rebalanceType, setErrorField]);

  useEffect(() => {
    setErrorField(set("foundDateEmptyError", isEmpty(formData.foundDate)));
  }, [formData.foundDate, setErrorField]);

  return errorField;
};
