import { useAppSelector } from "@/hooks/redux";
import { fastProp } from "@/util/opt";
import { fundBasicIndicators } from "@/views/fundDetail/constant";
import { Table } from "antd";
import { map } from "lodash/fp";
import { fixedNumber } from "@/util/numberFormatter";
import ColorNumber from "@/components/colorNumber";
import { FundManagerRatings } from "@/model/fundDetail";
import { useTableDataSource } from "../../hook";
import mapper from "../../mapper";
import style from "../../index.module.less";
import ToFundCompanyDetailPage from "@/components/navigateToPage/toFundCompanyDetailPage";
import ToFundManagerDetailPage from "@/components/navigateToPage/toFundManagerDetailPage";

const { Column } = Table;
function dataSourceRender(v: any) {
  switch (fastProp("id")(v)) {
    case "fundCompany":
      return <ToFundCompanyDetailPage id={v.value?.id} name={v.value?.name} />;
    case "fundManager":
      return map((manager: FundManagerRatings) => (
        <>
          <ToFundManagerDetailPage
            key={manager.managerId}
            name={manager.fundManagerName}
            id={manager.managerId}
          />
          &nbsp;
        </>
      ))(v.value);
    case "fundScale":
      return <ColorNumber value={v.value} formatValue={fixedNumber} />;
    default:
      return v.value ?? "--";
  }
}

const CompareTable = ({ fundId }: { fundId: string }): JSX.Element => {
  const { compareTableDataSource } = useAppSelector((state) =>
    mapper(state, fundId)
  );
  const dataSource = useTableDataSource(
    compareTableDataSource,
    fundBasicIndicators
  );
  return (
    <Table
      dataSource={dataSource}
      showHeader={false}
      pagination={false}
      bordered
      scroll={{ x: 600 }}
      className={style.CompareTable}
      rowClassName={(_, index: number) =>
        index % 2 === 0 ? style.CompareTableRow : ""
      }
    >
      <Column key="0" dataIndex="0" render={dataSourceRender} width={63} />
      <Column
        key="1"
        dataIndex="1"
        render={dataSourceRender}
        width={80}
        ellipsis
        className={style.tableOtherColorNumber}
      />
      <Column key="2" dataIndex="2" render={dataSourceRender} width={60} />
      <Column
        key="3"
        dataIndex="3"
        render={dataSourceRender}
        width={160}
        ellipsis
        className={style.tableOtherColorNumber}
      />
    </Table>
  );
};

export default CompareTable;
