import { DatePicker } from "antd";
import React, { useMemo } from "react";
// import moment from "moment";
import dayjs from "dayjs";
import { last } from "lodash/fp";
import { fastNth } from "@/util/opt";

const { RangePicker } = DatePicker;

export default React.memo<{
  dateList: string[];
  className?: string;
}>(({ dateList, className }) => {
  const startDate = useMemo(
    () => (fastNth(1)(dateList) ? dayjs(fastNth(1)(dateList)) : null),
    [dateList]
  );
  const endDate = useMemo(
    () => (last(dateList) ? dayjs(last(dateList)) : null),
    [dateList]
  );
  return (
    <RangePicker className={className} value={[startDate, endDate]} disabled />
  );
});
