import { Space, TableColumnType, TablePaginationConfig } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useMemoizedFn } from "ahooks";
import { find, flow, isNumber, map } from "lodash/fp";
import { Fund, FundAntdTableProps } from "@/components/fundResultTable";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useGetFactorColumns } from "@/hooks/factors";
import { useFormatMessage } from "@/util/formatMessage";
import {
  fundManagerItem,
  fundViewList,
  optionalFundGroupList,
} from "./interface";
import {
  // fetchOptionalFundGroupList,
  fetchDeleteOptionalFund,
  fetchAddGroupFundList,
} from "@/store/workbench";
import { addZeroIndex } from "@/util/numberFormatter";
import { toFixedNumber, formatPercentage } from "@/util/numberFormatter";
import MovePopover from "./components/movePopover";
import DeletePopover from "../components/deletePopover";
import { optionalFundGroupListSelector } from "./selector";
import { fastProp, normalize } from "@/util/opt";
import style from "./index.module.less";
import { FUND } from "../constant";

const basicColumns = [
  // {
  //   title: "serialNumber",
  //   dataIndex: "serialNumber",
  //   align: "left",
  // },
  {
    title: "fundName",
    dataIndex: "name",
    align: "left",
  },
  {
    title: "fundCode",
    dataIndex: "code",
    align: "center",
  },
];

export const useGetColumns = (
  selectedFactorIds: string[],
  groupName: string
) => {
  const formatMessage = useFormatMessage();
  const factorColumns = useGetFactorColumns("fund", selectedFactorIds);
  return useMemo<FundAntdTableProps["columns"]>(
    () =>
      [
        ...basicColumns.map((v) => ({
          ...v,
          title: formatMessage(v.title),
          fixed: "left",
          width: v.dataIndex === "serialNumber" ? 60 : 160,
          render: (text: string, record: Fund, index: number) => {
            if (v.dataIndex === "serialNumber") {
              return addZeroIndex(index + 1);
            }
            return (
              <ToFundDetailPage
                name={text}
                id={record.fundId}
                className={style.FundNameLink}
              />
            );
          },
        })),
        {
          title: formatMessage("LatestNetValue"),
          dataIndex: "netValue",
          width: 160,
          align: "left",
          sorter: true,
          render: toFixedNumber(2),
        },
        {
          title: formatMessage("DailyYield"),
          dataIndex: "dailyReturn",
          width: 160,
          align: "left",
          sorter: true,
          render: (value) => formatPercentage(value, { digit: 2 }),
        },
        {
          title: formatMessage("purchaseStatus"),
          dataIndex: "purchStatus",
          width: 160,
          align: "left",
        },
        {
          title: formatMessage("redemption"),
          dataIndex: "redemStatus",
          width: 160,
          align: "left",
        },
        {
          title: formatMessage("yieldAfterFavorite"),
          dataIndex: "createDateCumulativeReturn",
          width: 160,
          align: "left",
          sorter: true,
          render: (value, record: Fund) =>
            `${formatPercentage(value)} (${
              isNumber(fastProp("createDay")(record))
                ? fastProp("createDay")(record)
                : ""
            }天)`,
        },
        ...(factorColumns || []),
        {
          title: formatMessage("operator"),
          dataIndex: "fundId",
          fixed: "right",
          render: (id) => (
            <Space className={style.Operator}>
              <MovePopover fundIds={[id]} groupName={groupName} type={FUND} />
              <DeletePopover ids={[id]} selected={groupName} type={FUND} />
            </Space>
          ),
          width: 100,
        },
      ] as TableColumnType<Fund>[],
    [factorColumns, formatMessage, groupName]
  );
};

export function useGetRowSelection(
  fundsMap: Record<string, Fund>
): [string[], Fund[], FundAntdTableProps["rowSelection"]] {
  const [selectedFundCodes, changeSelectedFundCodes] = useState<string[]>([]);
  useEffect(() => {
    changeSelectedFundCodes([]);
  }, [fundsMap]);
  return [
    selectedFundCodes,
    useMemo(
      () => selectedFundCodes?.map((code) => fundsMap[code]),
      [selectedFundCodes, fundsMap]
    ),
    useMemo(
      () => ({
        fixed: true,
        // hideSelectAll: true,
        hideOnSinglePage: false,
        onChange(selectedRowKeys: React.Key[]) {
          changeSelectedFundCodes(selectedRowKeys as string[]);
        },
      }),
      [changeSelectedFundCodes]
    ),
  ];
}

export function useGetPagination(): TablePaginationConfig {
  return useMemo(
    () => ({
      showQuickJumper: true,
      size: "small",
      pageSize: 20,
    }),
    []
  );
}

export function useFundOptionalService() {
  // todo update 获取自选列表数据最好再这里，但要解决同时调用此处导致的重复请求问题.
  const formatMessage = useFormatMessage();
  const optionalGruopName = "";
  const dispatch = useAppDispatch();
  const _groups = useAppSelector(optionalFundGroupListSelector);
  const groups = useMemo(
    () =>
      map((group: optionalFundGroupList) => ({
        ...group,
        _groupName:
          group.groupName === null || group.groupName === ""
            ? formatMessage("unnamedGroup")
            : group.groupName,
        fundViews: map((item: fundViewList) => ({
          ...item,
          fundCompany: {
            fundCompanyId: item?.fundCompanyId,
            fundCompanyName: item?.fundCompany,
          },
          fundManager: map((v: fundManagerItem) => ({
            fundManagerId: v?.id,
            fundManagerName: v?.name,
          }))(item?.fundManagerIds || []),
        }))(group?.fundViews || []),
      }))(_groups),
    [_groups, formatMessage]
  );
  // useEffect(() => {
  //   if (isEmpty(groups)) {
  //     dispatch(fetchOptionalFundGroupList());
  //   }
  // }, [groups, dispatch]);

  const groupMap = useMemo(
    () => normalize<optionalFundGroupList>("groupName")(groups as any),
    [groups]
  );
  const optionalMap = useMemo(
    () =>
      flow(
        find(
          (item: optionalFundGroupList) => item?.groupName === optionalGruopName
        ),
        (v: optionalFundGroupList) => v?.fundViews,
        normalize("fundId")
      )(groups),
    [groups]
  );

  const isOptionaled = useMemoizedFn((id: string) => !!optionalMap?.[id]);

  const addOptional = useMemoizedFn((id: string) => {
    return dispatch(
      fetchAddGroupFundList({
        groupName: optionalGruopName,
        fundIds: [id],
        type: FUND,
      })
    );
  });

  const cancelOptional = useMemoizedFn((id: string) => {
    return dispatch(
      fetchDeleteOptionalFund({
        groupName: optionalGruopName,
        fundIds: [id],
        type: FUND,
      })
    );
  });

  const addToGroup = useMemoizedFn((ids: string[], groupName: string) => {
    return dispatch(
      fetchAddGroupFundList({
        groupName,
        fundIds: ids,
        type: FUND,
      })
    );
  });

  return {
    optionalMap,
    isOptionaled,
    groups,
    groupMap,
    add: addOptional,
    cancel: cancelOptional,
    addToGroup,
  };
}
