import React from "react";

import { useFormatMessage } from "@/util/formatMessage";

import style from "../index.module.less";
import PerformanceTrendChart from "../../../performanceTrendChart";
import SecondLevelName from "../../../secondLevelName";

export default React.memo(({ fundId }: { fundId: string }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={style.PerformanceTrendWrap}>
      <SecondLevelName name={formatMessage("performanceTrend")} hideColon />
      <PerformanceTrendChart fundId={fundId} showDate />
    </div>
  );
});
