import { useEffect, useMemo } from "react";
import { prop, flatMap, map, flow, concat, reject } from "lodash/fp";
import { useAppSelector } from "@/hooks/redux";
import { useAppDispatch } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { getFactors } from "@/store/factors";
import type { Factor, FactorCategory } from "@/model/factors";
import { fastProp, normalize } from "@/util/opt";
import { allStocksMapSelector } from "@/selectors/stocks";
import type { SelectIndexDialogProps } from "./selectIndexDialog";

const filterFundTree =
  (type: SelectIndexDialogProps["type"]) => (factorTree: FactorCategory[]) => {
    if (type === "comPortfolio") return factorTree;
    if (type === "preferredFund") {
      return reject<FactorCategory>(
        (item) =>
          fastProp("id")(item) === "portfolioBasic" ||
          fastProp("id")(item) === "basic"
      )(factorTree);
    }
    const omitKey = type === "fund" ? "portfolioBasic" : "basic";
    return reject<FactorCategory>((item) => fastProp("id")(item) === omitKey)(
      factorTree
    );
  };
export function useGetIndexInfo(type: SelectIndexDialogProps["type"]): {
  categories: FactorCategory[];
  categoryMap: Record<string, Factor[]>;
  factorMap: Record<string, Factor>;
} {
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();
  useEffect(() => {
    if (type !== "comPortfolio") {
      dispatch(getFactors());
    }
  }, [dispatch, type]);
  const factorTree = useAppSelector<FactorCategory[]>(
    type === "comPortfolio"
      ? prop("competition.competitionPortFactors")
      : prop("factors.factorTree")
  );
  const categories: FactorCategory[] = useMemo(
    () =>
      flow(
        filterFundTree(type),
        map((category: FactorCategory) => ({
          ...category,
          name: category.nameKey
            ? formatMessage(category.nameKey)
            : category.name,
          factorViews: map((factor: Factor) => ({
            ...factor,
            name: factor.nameKey ? formatMessage(factor.nameKey) : factor.name,
          }))(category.factorViews),
        })),
        (data) =>
          concat({
            id: "ALL",
            name: formatMessage("all"),
            factorViews: flatMap<FactorCategory, Factor>(
              fastProp("factorViews")
            )(data),
          })(data as any)
      )(factorTree),
    [factorTree, formatMessage, type]
  );
  const factors = useMemo(
    () => flatMap<FactorCategory, Factor>(fastProp("factorViews"))(categories),
    [categories]
  );

  return {
    categories,
    categoryMap: useMemo(
      () =>
        normalize<FactorCategory, Factor[]>(
          "id",
          fastProp("factorViews")
        )(categories),
      [categories]
    ),
    factorMap: useMemo(() => normalize<Factor>("id")(factors), [factors]),
  };
}

export const useStocksPositionColumns = () => {
  const formatMessage = useFormatMessage();
  const stockPosition = useAppSelector(prop("stocks.stocksPositions"));
  const stocksMap = useAppSelector(allStocksMapSelector);
  return flow(
    map(fastProp("stockCode")),
    map((v: string) => ({
      title: formatMessage("stocksPositions", {
        stockName: prop(`${v}.name`)(stocksMap),
      }),
      dataIndex: v,
      align: "right",
      width: 160,
      ellipsis: true,
    }))
  )(stockPosition);
};
