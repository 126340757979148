import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import dayjs, { ManipulateType } from "dayjs";
import { filter, flow, pick, values } from "lodash/fp";
import { RangeInterface, statisticRange } from "@/constant/statisticRange";
import { useMemoizedFn } from "ahooks";
import { StatisticRangeProps } from "@/components/statisticRange";

export const useGetStatisticRange = (tradingDateSize = 2) => {
  const processedTradingDate = useAppSelector(
    (state: RootState) => state.tradingDates.processedTradingDates
  );
  return useMemoizedFn(
    (
      startDate: string,
      endDate: string,
      staticRange: StatisticRangeProps["statisticRangeList"]
    ) => {
      if (!startDate || !endDate)
        return flow(pick(["FROM_CREATION"]), values)(statisticRange);
      const endDay = dayjs(endDate);
      return filter(({ checkIsValid, period, count }: RangeInterface) => {
        if (checkIsValid) {
          return checkIsValid(
            startDate,
            endDate,
            processedTradingDate,
            tradingDateSize
          );
        }
        return !endDay
          .subtract(period as number, count as ManipulateType)
          .isBefore(startDate);
      })(staticRange);
    }
  );
};
