import { PromiseType } from "@/model/promise";
import { get } from "@/util/axios";
import { socketHttp } from "@/util/socket";
import { Stock, StockFilter } from "@/model/stockBackCheck";

// 查询所有股票
export const getAllStocks = (): PromiseType<Stock[]> => get("/stocks/allStock");

// 股票倒查
export const getStocksFilterList = (stockFilter: StockFilter): PromiseType =>
  socketHttp("START_FUND_QUERY_BY_STOCK", {
    meta: stockFilter,
  });
