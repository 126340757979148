import {
  benchmarkIds,
  customIndexId,
  marketIndexIds,
  POSITION_TYPE,
} from "@/constant/benchmarks";
import { BenchmarkInterface } from "@/model/entities";
import { RootState } from "@/store";
import { fastHas, fastProp } from "@/util/opt";
import { filterAndMapTree, treeToMap } from "@/util/opt/tree";
import { createSelector } from "@reduxjs/toolkit";
import { concat, filter, flow, size } from "lodash/fp";

export const benchmarkMapSelector = createSelector(
  (state: RootState) => state.entities.benchmark,
  (benchmark) => treeToMap({ needPath: true })(benchmark)
);

export const benchmarksCodeMapSelector = createSelector(
  (state: RootState) => state.entities.benchmark,
  (benchmark) => treeToMap({ idKey: "code", needPath: true })(benchmark)
);

export const basicBenchmarkListSelector = createSelector(
  (state: RootState) => state.entities.benchmark,
  (benchmark) =>
    filter((item: Record<string, any>) => benchmarkIds.includes(item.id))(
      benchmark
    )
);

export const marketIndexListSelector = createSelector(
  (state: RootState) => state.entities.benchmark,
  (benchmark) =>
    filter((item: Record<string, any>) => marketIndexIds.includes(item.id))(
      benchmark
    )
);

export const benchmarkListSelector = createSelector(
  marketIndexListSelector,
  (state: RootState) => state.customBenchmark.customBenchmarkList,
  (basicBenchmarkList, customBenchmark) =>
    size(customBenchmark)
      ? basicBenchmarkList.concat([
          {
            id: "BENCHMARK_MANAGE",
            name: "基准方案",
            nameKey: "",
            sort: 0,
            source: "",
            parentId: "",
            children: customBenchmark,
          },
        ])
      : basicBenchmarkList
);

export const benchmarksIdMapSelector = createSelector(
  // (state: RootState) => state.entities.benchmark,
  benchmarkListSelector,
  (benchmark) => treeToMap({ idKey: "id", needPath: true })(benchmark)
);

//持仓指数数据需要过滤叶子节点不为POSITION_TYPE的
export const positionIndexBenchmarkListSelector = createSelector(
  (state: RootState) => state.entities.benchmark,
  (benchmark) =>
    flow(
      filterAndMapTree(
        (node: BenchmarkInterface) =>
          !(
            !fastHas("children")(node) &&
            fastProp("scenarioType")(node) !== POSITION_TYPE
          )
      ),
      filter(
        (item: Record<string, any>) =>
          fastProp("children")(item) && size(fastProp("children")(item))
      ),
      filter((item: Record<string, any>) => item.id !== customIndexId) //过滤自定义指数
    )(benchmark)
);

export const allBenchmarkMapSelector = createSelector(
  (state: RootState) => state.entities.benchmark,
  (state: RootState) => state.customBenchmark.customBenchmarkList,
  (benchmark, customBenchmark) => {
    const allBenchmark = concat([
      {
        id: "BENCHMARK_MANAGE",
        name: "基准方案",
        nameKey: "",
        sort: 0,
        source: "",
        parentId: "",
        children: customBenchmark,
      },
    ])(benchmark as any[]);
    return treeToMap({ needPath: true })(allBenchmark);
  }
);
