import { useAppSelector } from "@/hooks/redux";
import { Col, Row } from "antd";
import { map } from "lodash/fp";
import ColorNumber from "@/components/colorNumber";
import style from "../../../index.module.less";
import mapper from "../../mapper";

export type ValueTagProps = {
  id: string;
  value: number;
  message: string;
  section: string;
};
const ValueTag = ({
  message,
  value,
}: {
  value: number;
  message: string;
}): JSX.Element => {
  return (
    <Row gutter={[4, 16]} className={style.ValueTag}>
      <Col span={12}>
        <span>{message}:</span>
      </Col>
      <Col className={style.ColorNumber} span={12}>
        <ColorNumber
          value={value}
          formatter="percentage"
          className={style.ColorNumberItem}
        />
      </Col>
    </Row>
  );
};

const RankValueSection = ({ fundId }: { fundId: string }): JSX.Element => {
  const { rankValue } = useAppSelector((state) => mapper(state, fundId));
  return (
    <>
      <Row gutter={[8, 16]}>
        {map((v: ValueTagProps) => (
          <Col span={8} key={v.id}>
            <ValueTag message={v.message} value={v.value} />
          </Col>
        ))(rankValue)}
      </Row>
    </>
  );
};

export default RankValueSection;
