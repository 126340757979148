/**
 * 索提诺比率
 */
import { flow, isEmpty } from "lodash/fp";
import { div, times, toAverage } from "../math";
import tradingDateCount from "@/constant/tradingDateCount";
import { getDownwardVolatiltyWithoutAnnual } from "./downwardVolatilty";
import { zipWithMinus } from "../opt";
import { equalZero, formatQuantArrayNilToZero } from "../numberFormatter";

export default (dailyReturns: number[], riskFreeRates: number[]) => {
  if (isEmpty(dailyReturns) || isEmpty(riskFreeRates)) return 0;
  const [formatDailyReturns, formatRiskFreeRates] = formatQuantArrayNilToZero(
    dailyReturns,
    riskFreeRates
  );
  const downwardVolatilty =
    getDownwardVolatiltyWithoutAnnual(formatDailyReturns);
  if (equalZero(downwardVolatilty)) return NaN;
  const fundSubFreeRate = zipWithMinus(formatDailyReturns, formatRiskFreeRates);
  return flow(
    toAverage,
    div(downwardVolatilty),
    times(Math.sqrt(tradingDateCount))
  )(fundSubFreeRate);
};
