import { flow, toArray, pick, filter } from "lodash/fp";
import { NamedExoticComponent } from "react";
import AssetIncome from "./containers/assetIncome";
import AssetInfo from "./containers/assetInfo";
import AssetPosition from "./containers/assetPosition";
import AssetRisk from "./containers/assetRisk";
import assetAttribution from "./containers/assetAttribution";
import assetManager from "./containers/assetManager";
import assetNews from "./containers/assetNews";
import { FormatMessageFunc } from "@/util/formatMessage";
import dayjs from "dayjs";
import { calculateReturn } from "./containers/assetManager/components/currentFundManger";
import { yieldStatisticRange } from "@/constant/statisticRange";
import ToFundManagerDetailPage from "@/components/navigateToPage/toFundManagerDetailPage";
import FundInfoExport from "@/components/fundInfoExport";

export interface AssetDetailComponent {
  updateHandler: (key: string) => (value: any) => any;
  fundId: string;
}

export interface AssetDetailMenuItem {
  id: string;
  message: string;
  Component: NamedExoticComponent<AssetDetailComponent>;
}

export const assetDetailMenu: AssetDetailMenuItem[] = [
  {
    id: "assetInfo",
    message: "assetInfo",
    Component: AssetInfo,
  },
  {
    id: "fundEvaluation",
    message: "fundEvaluation",
    Component: FundInfoExport,
  },
  {
    id: "assetIncome",
    message: "assetIncome",
    Component: AssetIncome,
  },
  {
    id: "assetRisk",
    message: "assetRisk",
    Component: AssetRisk,
  },
  {
    id: "assetPosition",
    message: "assetPosition",
    Component: AssetPosition,
  },
  {
    id: "assetAttribution",
    message: "assetAttribution",
    Component: assetAttribution,
  },
  {
    id: "assetManager",
    message: "fundManagers",
    Component: assetManager,
  },
  {
    id: "assetNews",
    message: "assetNews",
    Component: assetNews,
  },
];

export const statisticOptions = flow(
  pick([
    "MONTH",
    "RECENT_THREE_MONTH",
    "RECENT_SIX_MONTH",
    "FROM_THIS_YEAR",
    "RECENT_YEAR",
    "RECENT_THREE_YEAR",
    "FROM_CREATION",
  ]),
  toArray
)(yieldStatisticRange);

export const getDefaultBenchmarkInfo = () => [
  {
    type: "A_SHARE",
    defaultBenchmarkCode: "idx_000300sh",
    weight: 0.6,
  },
  {
    type: "NON_A_SHARE",
    defaultBenchmarkCode: "idx_hsihi",
    weight: 0,
  },
  {
    type: "NON_EQUITY",
    defaultBenchmarkCode: "idx_cba00301_cs",
    weight: 0.4,
  },
];

export const attriibutionFieldNames = {
  // 选股业绩
  selectionAttribution: "selectionAttribution",
  // 基金业绩
  fundAttribution: "fundAttribution",
  fundTotal: "fundTotal",
};
export const OTHER_ID = "49f6a36c-6f5c-441e-a560-9ab9a2c116e9";

export const getSuccessiveFundManagersColumns = (
  formatMessage: FormatMessageFunc,
  dates: string[],
  dailyReturnsMap: Record<string, any>
) => [
  {
    title: formatMessage("fullName"),
    dataIndex: "name",
    align: "center",
    render: (text: string, Record: Record<string, any>) => {
      return (
        <ToFundManagerDetailPage key={Record.id} name={text} id={Record.id} />
      );
    },
  },
  {
    title: formatMessage("termOfOfficeDate"),
    dataIndex: "startDate",
    align: "center",
  },
  {
    title: formatMessage("resignationDate"),
    dataIndex: "endDate",
    align: "center",
    render: (text: string) => {
      return text ? text : formatMessage("soFar");
    },
  },
  {
    title: formatMessage("durationOfTermOfOffice"),
    dataIndex: "officeDuration",
    align: "center",
    render: (_: any, row: Record<string, any>) => {
      if (!row.endDate)
        return formatMessage("days", {
          number: dayjs().diff(dayjs(row.startDate), "days"),
        });
      return formatMessage("days", {
        number: dayjs(row.endDate).diff(dayjs(row.startDate), "days"),
      });
    },
  },
  {
    title: formatMessage("termReturn"),
    dataIndex: "termReturn",
    align: "center",
    render: (_: any, row: Record<string, any>) => {
      const jobDates = filter((date: string) =>
        row.endDate
          ? row.startDate <= date && date <= row.endDate
          : row.startDate <= date
      )(dates);
      return calculateReturn(dailyReturnsMap, jobDates);
    },
  },
];

export const newType = {
  ASSET_ANNOUNCEMENT: "assetAnnouncement",
  NEW_INFORMATION: "newInformation",
  isNews: (type: string) => {
    return type === "newInformation" ? true : false;
  },
};
// 资产公告类型
export const ANNOUNCEMENT_TYPE = {
  ALL_PUBLISH: "ALL_PUBLISH", // 全部公告
  DISTRIBUTION_OPERATION: "DISTRIBUTION_OPERATION", // 发行运作
  DIVIDEND_DISTRIBUTION: "DIVIDEND_DISTRIBUTION", // 分红配送
  REGULAR_REPORTS: "REGULAR_REPORTS", // 定期报告
  PERSONNEL_ADJUSTMENT: "PERSONNEL_ADJUSTMENT", // 人事调整
  FUND_SALES: "FUND_SALES", // 基金销售
  OTHER_PUBLISH: "OTHER_PUBLISH", // 其他公告
};

export const ALL_PUBLISH = "ALL_PUBLISH";
