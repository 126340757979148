import React from "react";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { Tabs } from "antd";
import { map, prop, remove } from "lodash/fp";
import { updateFundDetailInfo } from "@/store/fundDetailSlice";
import { useCallback } from "react";
import { assetDetailMenu, AssetDetailMenuItem } from "./constant";
import style from "./index.module.less";

const { TabPane } = Tabs;

export default React.memo<{
  fundId: string;
}>(({ fundId }) => {
  const formatMessage = useFormatMessage();
  const dispatch = useAppDispatch();
  const fundInfoUpdater = useCallback(
    (key: string) => (value: any) =>
      dispatch(
        updateFundDetailInfo({
          fundId,
          key,
          value,
        })
      ),
    [dispatch, fundId]
  );
  const enableFundEvaluate = useAppSelector(
    prop(`fundDetail.${fundId}.enableFundEvaluate`)
  );

  return (
    <Tabs className={style.Tabs}>
      {map(({ id, message, Component }: AssetDetailMenuItem) => (
        <TabPane tab={formatMessage(message)} key={id}>
          <Component updateHandler={fundInfoUpdater} fundId={fundId} />
        </TabPane>
      ))(
        remove((item: AssetDetailMenuItem) => {
          return item.id === "fundEvaluation" && !enableFundEvaluate;
        })(assetDetailMenu)
      )}
    </Tabs>
  );
});
