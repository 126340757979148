import { useFormatMessage } from "@/util/formatMessage";
import { Card, Space } from "antd";
import React from "react";
import style from "./index.module.less";
import SelectFundDialog from "@/components/portfolioCompoents/selectFundDialog";
import FundListTable, {
  FundList,
} from "@/components/portfolioCompoents/fundListTable";

type FundPositionProps = {
  fundIds: string[];
  fundList: FundList[];
  onChange: (
    fundId: string,
    key?: string
  ) => (v: string | number | null) => void;
  onChangeFundIds: (v: string[]) => void;
  onError: (v: boolean) => void;
};
const FundPosition = ({
  fundIds,
  fundList,
  onChange,
  onChangeFundIds,
  onError,
}: FundPositionProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  return (
    <Card size="small" className={style.FundPosition} bordered={false}>
      <header className={style.FundPositionHeader}>
        <Space size="large">
          <h4>{formatMessage("openFund")}</h4>
          <span>{`*(${formatMessage("fundPositionPromPrompt")})`}</span>
        </Space>
        <SelectFundDialog
          fundIds={fundIds}
          enableDelistingFund
          onChange={onChangeFundIds}
        />
      </header>
      <FundListTable
        onError={onError}
        fundList={fundList}
        onChange={onChange}
      />
    </Card>
  );
};

export default React.memo(FundPosition);
