import { RECENT_THREE_MONTH } from "@/constant/statisticRange";
import { useAppDispatch } from "@/hooks/redux";
import {
  fetchGetCompanyDailyReturn,
  fetchGetCompanyManageFund,
} from "@/store/fundCompanyDetail";
import { useFormatMessage } from "@/util/formatMessage";
import { Button, Card, Col, Row, Space, Table } from "antd";
import { isEmpty, map } from "lodash/fp";
import React, { useEffect, useState } from "react";
import {
  fundTypeItem,
  fundTypeOptions,
  getManageFundColumns,
  STOCK_FUND,
} from "../constant";
import { useIncomeTrendChart, useIncomeTrendTable } from "../hook";
import IncomeTrendExtra from "./incomeTrendExtra";
import style from "./index.module.less";
import CumulativeChart from "@/components/cumulativeChart";
import { CSI300_ID } from "@/views/compareManage/constant";
import LoadingComponent from "@/components/LoadingComponent";
import EmptyContent from "@/components/emptyContent";

type incomeTrendProps = {
  id: string;
};

export default React.memo<incomeTrendProps>(({ id }) => {
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();
  const [section, setSection] = useState(RECENT_THREE_MONTH);
  const [benchmarkId, setBenchmarkId] = useState(CSI300_ID);
  const [fundType, setFundType] = useState(STOCK_FUND);
  useEffect(() => {
    dispatch(fetchGetCompanyDailyReturn({ id, fundType }));
  }, [dispatch, fundType, id]);
  useEffect(() => {
    dispatch(fetchGetCompanyManageFund({ id, fundType, section }));
  }, [dispatch, fundType, id, section]);

  const columns = getManageFundColumns(formatMessage, section);
  const { tableData, scroll } = useIncomeTrendTable(id, fundType, section);
  const { chartData, dates } = useIncomeTrendChart(
    id,
    fundType,
    section,
    benchmarkId
  );
  return (
    <Card
      bordered={false}
      title={formatMessage("incomeTrend")}
      size="small"
      className={style.IncomeTrendCard}
      extra={
        <IncomeTrendExtra
          range={section}
          benchmarkId={benchmarkId}
          onChangeRange={setSection}
          onChangeBenchmarkId={setBenchmarkId}
        />
      }
    >
      <Space className={style.FundTypeGroup}>
        {map(({ id, messageKey }: fundTypeItem) => (
          <Button
            key={id}
            type={id === fundType ? "primary" : "default"}
            onClick={() => setFundType(id)}
            className={id === fundType ? "" : style.FundTypeButton}
          >
            {formatMessage(messageKey)}
          </Button>
        ))(fundTypeOptions)}
      </Space>
      <Row gutter={32}>
        <Col span={12}>
          <LoadingComponent actions={fetchGetCompanyDailyReturn}>
            {isEmpty(dates) ? (
              <EmptyContent
                message={formatMessage("noData")}
                className={style.EmptyContent}
              />
            ) : (
              <CumulativeChart
                dates={dates}
                dataSource={chartData}
                type="cumulative"
              />
            )}
          </LoadingComponent>
        </Col>
        <Col span={12}>
          <LoadingComponent actions={fetchGetCompanyManageFund}>
            <Table
              className={style.IncomeTrendTable}
              columns={columns}
              dataSource={tableData}
              scroll={scroll}
              pagination={false}
            />
          </LoadingComponent>
        </Col>
      </Row>
    </Card>
  );
});
