import React, { memo, useMemo } from "react";
import { Table, TableColumnType } from "antd";
import cn from "classnames";
import { useFormatMessage } from "@/util/formatMessage";
import { formatPercentage } from "@/util/numberFormatter";

import style from "../../index.module.less";

export interface DataItem {
  name: string;
  weight: number;
}

interface TableProps {
  height: number;
  dataSource: DataItem[];
  className?: string;
}

const useColumns = () => {
  const formatMessage = useFormatMessage();
  return useMemo<TableColumnType<DataItem>[]>(
    () => [
      {
        title: formatMessage("industry"),
        dataIndex: "name",
        align: "left",
      },
      {
        title: formatMessage("totalPercent"),
        dataIndex: "weight",
        align: "right",
        render: (v: number) => formatPercentage(v),
      },
    ],
    [formatMessage]
  );
};

export default memo<TableProps>(({ height, dataSource, className }) => {
  const columns = useColumns();

  return (
    <Table
      columns={columns}
      dataSource={dataSource || []}
      pagination={false}
      className={cn(style.CommonTable, className)}
      scroll={{ y: height - 43, x: "100%" }}
      rowKey="code"
      bordered
    />
  );
});
