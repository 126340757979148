import { useAppSelector } from "@/hooks/redux";
import { RootState } from "@/store";
import { fastProp } from "@/util/opt";
import { useCreation } from "ahooks";
import { first, flow, fromPairs, keys, map, values } from "lodash/fp";
import { useMemo } from "react";
import { customerAnalysisSelector } from "../selectors";

export const useGetTradeValueSection = (
  customerAnalysis: Record<string, any>
) => {
  const rangeIncomeMap = useCreation(() => {
    const rangeIncome = fastProp("rangeIncome")(customerAnalysis);
    return flow(
      map((item: Record<string, number>) => [
        first(keys(item) || []),
        first(values(item) || []),
      ]),
      fromPairs
    )(rangeIncome || []);
  }, [customerAnalysis]);
  return rangeIncomeMap;
};

export const useGetAccountList = ({
  totalAccountId,
  customerName,
}: {
  totalAccountId: string;
  customerName: string;
}) => {
  const totalAccountInfo = useAppSelector((state: RootState) =>
    customerAnalysisSelector(state, totalAccountId)
  );
  const accountInfo = fastProp("accountInfo")(totalAccountInfo);
  const totalSubCustomerDetails = fastProp("subCustomerDetails")(accountInfo);
  const accountList = useMemo(() => {
    const subAccountList = map((item: Record<string, any>) => ({
      id: item?.accountId,
      message: item?.accountName,
    }))(totalSubCustomerDetails);
    return [
      {
        id: totalAccountId,
        message: customerName,
      },
      ...subAccountList,
    ];
  }, [totalSubCustomerDetails, totalAccountId, customerName]);
  return accountList;
};
