import { FormatMessageFunc } from "./formatMessage";

export const getRiskLevel = (
  formatMessage: FormatMessageFunc,
  riskLevel: string
) => {
  if (riskLevel === "R1") return `${formatMessage("lowRisk")}${riskLevel}`;
  if (riskLevel === "R2")
    return `${formatMessage("lowAndMediumRisk")}${riskLevel}`;
  if (riskLevel === "R3") return `${formatMessage("middleRisk")}${riskLevel}`;
  if (riskLevel === "R4")
    return `${formatMessage("mediumAndHighRisk")}${riskLevel}`;
  if (riskLevel === "R5") return `${formatMessage("highRisk")}${riskLevel}`;
};
