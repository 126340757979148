import React, { useContext, useRef, useState } from "react";
import { Steps, Card, Button, message, Space } from "antd";
import { map } from "lodash/fp";
import { useMemoizedFn } from "ahooks";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import { useFormatMessage } from "@/util/formatMessage";
import FundAllocation from "./fundAllocation";
import style from "./index.module.less";
import { fundConfigurationSteps } from "./constant";
import BackTestingAllocation from "./backTestingAllocation";
import SavePortfolio, {
  SavePortfolioRef,
} from "../manualCreatePortfolio/savePortfolio";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  assetFundConfigurationAllocate,
  updateFundConfiguration,
} from "@/store/createPortfolio";
import { useWatchAllocateData } from "./hooks";
import { useCloseModal } from "../manualCreatePortfolio/hooks";

const useManageFundAllocation = (currentStep: number) => {
  const dispatch = useAppDispatch();
  const { assetAllocateData, formStateChanged } =
    useWatchAllocateData(currentStep);
  return useMemoizedFn((goNext: () => any) => {
    // 只有assetAllocateData改变之后才会去重新请求
    if (formStateChanged) {
      return dispatch(assetFundConfigurationAllocate(assetAllocateData))
        .unwrap()
        .then(() => {
          // 请求到数据之后应该把之前的数据给初始化了
          dispatch(
            updateFundConfiguration("manualPortfolioAllocateData", () => [])
          );
          goNext();
        });
    }
    goNext();
    return Promise.resolve();
  });
};

const useManageSavePortfolio = () => {
  const savePortfolioRef = useRef<SavePortfolioRef>(null);
  const formatMessage = useFormatMessage();
  const onStartSavePortfolio = useMemoizedFn((stackBack: () => any) => {
    savePortfolioRef.current?.onSavePortfolio().then(() => {
      message.success(formatMessage("createPortfolioSuccess"));
      stackBack();
    });
  });
  return { savePortfolioRef, onStartSavePortfolio };
};

const useManageStepOperator = () => {
  const { stackBack } = useContext(PlatformNavigationContext);
  const [steps, setSteps] = useState(fundConfigurationSteps.fundAllocation);
  const onStartAllocation = useManageFundAllocation(steps);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { savePortfolioRef, onStartSavePortfolio } = useManageSavePortfolio();
  const goBack = useMemoizedFn(() => {
    setSteps((step) => step - 1);
    setButtonDisabled(false);
  });
  const goNext = useMemoizedFn(() => setSteps((step) => step + 1));
  const onNext = useMemoizedFn(() => {
    if (steps === fundConfigurationSteps.fundAllocation) {
      return onStartAllocation(goNext);
    } else if (steps === fundConfigurationSteps.savePortfolio) {
      return onStartSavePortfolio(stackBack);
    } else {
      return goNext();
    }
  });
  return {
    steps,
    onNext,
    goBack,
    buttonDisabled,
    setButtonDisabled,
    savePortfolioRef,
  };
};

const { Step } = Steps;
export default React.memo((props?: { fundIds: string[] }) => {
  const formatMessage = useFormatMessage();
  const {
    steps,
    onNext,
    goBack,
    savePortfolioRef,
    buttonDisabled,
    setButtonDisabled,
  } = useManageStepOperator();
  const { backTestingFormData, manualPortfolioAllocateData } = useAppSelector(
    (state) => state.createPortfolio.fundConfiguration
  );
  const cancel = useCloseModal("fundConfiguration");
  return (
    <div className={style.LayoutContainer}>
      <div className={style.LayoutScrollYContent}>
        <Card className={style.MarginBottom16}>
          <Steps current={steps} className={style.StepContainer}>
            {map(({ message }) => <Step key={message} title={message} />)(
              fundConfigurationSteps.steps
            )}
          </Steps>
        </Card>
        {steps === fundConfigurationSteps.fundAllocation && (
          <FundAllocation
            defaultIds={props?.fundIds}
            onError={setButtonDisabled}
          />
        )}
        {steps === fundConfigurationSteps.backTestingAllocation && (
          <BackTestingAllocation onError={setButtonDisabled} />
        )}
        {steps === fundConfigurationSteps.savePortfolio && (
          <SavePortfolio
            ref={savePortfolioRef}
            formData={backTestingFormData}
            data={manualPortfolioAllocateData}
            onError={setButtonDisabled}
          />
        )}
      </div>
      <div className={style.LayoutFooter}>
        <Space>
          <Button className={style.OperateButton} onClick={cancel}>
            {formatMessage("cancel")}
          </Button>
          {steps !== fundConfigurationSteps.fundAllocation && (
            <Button
              className={style.OperateButton}
              type="primary"
              onClick={goBack}
            >
              {formatMessage("thePreviousStep")}
            </Button>
          )}
          <Button
            className={style.OperateButton}
            type="primary"
            disabled={buttonDisabled}
            onClick={onNext}
          >
            {steps === fundConfigurationSteps.savePortfolio
              ? formatMessage("savePortfolio")
              : formatMessage("theNextStep")}
          </Button>
        </Space>
      </div>
    </div>
  );
});
