import { formatPercentage } from "@/util/numberFormatter";
import { fastProp, normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import {
  compact,
  first,
  flatten,
  flow,
  last,
  map,
  maxBy,
  prop,
  update,
  concat,
  isEmpty,
  orderBy,
} from "lodash/fp";
import { RootState } from "@/store";
import { fundIdMapSelector } from "@/selectors/fund";
import { getPreviousTradingDate } from "@/util/processedDates";
import { getChartFactorInfo } from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/performanceDismantling/constant";
import { getContributionInfo } from "./constant";
import { allStocksMapSelector } from "@/selectors/stocks";

export const portfolioAnalysisSelector = createSelector(
  (state: RootState) => state.aum,
  (_: RootState, id: string) => id,
  (portfolioAnalysis, id) => fastProp(id)(portfolioAnalysis) || {}
);

export const performanceDismantlingSelector = createSelector(
  portfolioAnalysisSelector,
  (portfolioAnalysis) => portfolioAnalysis?.performanceDisassembleResult
);

export const performanceDismantlingProgressSelector = createSelector(
  portfolioAnalysisSelector,
  (portfolioAnalysis) => portfolioAnalysis?.performanceDisassembleProgress
);

export const assetContributionSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => {
    return getContributionInfo(performanceDismantling?.assetContribution);
  }
);

export const industryContributionSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => {
    return getContributionInfo(performanceDismantling?.industryContribution);
  }
);

export const categoryMaxAndMinSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => ({
    maxAssetContribution: fastProp("maxCategoryInfo")(performanceDismantling),
    minAssetContribution: fastProp("minCategoryInfo")(performanceDismantling),
    portfolioReturn: fastProp("portfolioInfo")(performanceDismantling),
    benchmarkAttribution: prop("benchmarkInfo")(performanceDismantling),
  })
);

export const fundCompanyContributionSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => performanceDismantling?.fundCompanyContribution
);

export const topFundContributionsSelector = createSelector(
  performanceDismantlingSelector,
  fundIdMapSelector,
  (performanceDismantling, fundIdMap) => {
    const newContributions = flow(
      orderBy("contribution", "asc"),
      map(
        update(
          "name",
          (name) => prop("name")(prop(`${name}`)(fundIdMap)) || name
        )
      )
    )(performanceDismantling?.topFundContributions);
    return {
      contributionsNames: map(fastProp("name"))(newContributions),
      contributionsMap: normalize("name")(newContributions),
    };
  }
);

export const lowestFundContributionsSelector = createSelector(
  performanceDismantlingSelector,
  fundIdMapSelector,
  (performanceDismantling, fundIdMap) => {
    const newContributions = flow(
      orderBy("contribution", "desc"),
      map(
        update(
          "name",
          (name) => prop("name")(prop(`${name}`)(fundIdMap)) || name
        )
      )
    )(performanceDismantling?.lowestFundContributions);
    return {
      contributionsNames: map(fastProp("name"))(newContributions),
      contributionsMap: normalize("name")(newContributions),
    };
  }
);

export const topStockContributionsSelector = createSelector(
  performanceDismantlingSelector,
  allStocksMapSelector,
  (performanceDismantling, allStocksMap) => {
    const newContributions = flow(
      orderBy("contribution", "asc"),
      map(
        update(
          "name",
          (name) => prop("name")(prop(`${name}`)(allStocksMap)) || name
        )
      )
    )(performanceDismantling?.topStockContributions);
    return {
      contributionsNames: map(fastProp("name"))(newContributions),
      contributionsMap: normalize("name")(newContributions),
    };
  }
);

export const lowestStockContributionsSelector = createSelector(
  performanceDismantlingSelector,
  allStocksMapSelector,
  (performanceDismantling, allStocksMap) => {
    const newContributions = flow(
      orderBy("contribution", "desc"),
      map(
        update(
          "name",
          (name) => prop("name")(prop(`${name}`)(allStocksMap)) || name
        )
      )
    )(performanceDismantling?.lowestStockContributions);
    return {
      contributionsNames: map(fastProp("name"))(newContributions),
      contributionsMap: normalize("name")(newContributions),
    };
  }
);

export const fundDisassembleTableSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => performanceDismantling?.fundDisassembleTable
);

export const dismantlingLineChartSelector = createSelector(
  performanceDismantlingSelector,
  (state: RootState) => state.tradingDates.tradingDateList,
  (state: RootState) => state.tradingDates.processedTradingDates,
  (performanceDismantling, tradingDates, processedTradingDates) => {
    const {
      dates,
      portfolioInfo,
      benchmarkInfo,
      maxCategoryInfo,
      minCategoryInfo,
    } = performanceDismantling || {};
    const firstDate = getPreviousTradingDate(
      tradingDates,
      processedTradingDates,
      first(dates) as string
    );
    const chartDates = concat(firstDate)(dates);
    const chartPortfolioInfo = getChartFactorInfo(portfolioInfo);
    const chartBenchmarkInfo = getChartFactorInfo(benchmarkInfo);
    const chartMaxCategoryInfo = getChartFactorInfo(maxCategoryInfo);
    const chartMinCategoryInfo = getChartFactorInfo(minCategoryInfo);
    return {
      dates: chartDates,
      portfolioInfo: chartPortfolioInfo,
      benchmarkInfo: chartBenchmarkInfo,
      maxCategoryInfo: chartMaxCategoryInfo,
      minCategoryInfo: chartMinCategoryInfo,
    };
  }
);

export const dismantlingDatesSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => performanceDismantling?.dates
);

export const dismantlingTextSelector = createSelector(
  performanceDismantlingSelector,
  dismantlingDatesSelector,
  fundIdMapSelector,
  (performanceDismantling, dismantlingDates, fundIdMap) => {
    if (isEmpty(dismantlingDates)) return {};
    const { fundDisassembleTable } = performanceDismantling || {};
    const fundDisassembles = flow(
      fastProp("children"),
      map(fastProp("children")),
      compact,
      flatten,
      map(update("attribution", (value: number) => Math.abs(value))),
      maxBy("attribution")
    )(fundDisassembleTable);
    return {
      from: first(dismantlingDates) as string,
      to: last(dismantlingDates) as string,
      fundName:
        prop(`${fastProp("name")(fundDisassembles)}.name`)(fundIdMap) || "--",
      attributionRatio: formatPercentage(
        fastProp("attributionRatio")(fundDisassembles)
      ),
    };
  }
);

export const factorRatioSelector = createSelector(
  performanceDismantlingSelector,
  (performanceDismantling) => ({
    sharpe: fastProp("annualSharpeRatio")(performanceDismantling),
    infoRatio: fastProp("informationRatio")(performanceDismantling),
    maxDrawdown: fastProp("maxDrawdown")(performanceDismantling),
    trackDeviation: prop("trackingError")(performanceDismantling),
  })
);

export default createSelector(
  performanceDismantlingSelector,
  performanceDismantlingProgressSelector,
  assetContributionSelector,
  industryContributionSelector,
  categoryMaxAndMinSelector,
  factorRatioSelector,
  fundCompanyContributionSelector,
  topFundContributionsSelector,
  fundDisassembleTableSelector,
  dismantlingLineChartSelector,
  dismantlingDatesSelector,
  dismantlingTextSelector,
  lowestStockContributionsSelector,
  topStockContributionsSelector,
  lowestFundContributionsSelector,
  (
    performanceDismantlingSelector,
    performanceDismantlingProgressSelector,
    assetContributionSelector,
    industryContributionSelector,
    categoryMaxAndMinSelector,
    factorRatioSelector,
    fundCompanyContributionSelector,
    topFundContributionsSelector,
    fundDisassembleTableSelector,
    dismantlingLineChartSelector,
    dismantlingDatesSelector,
    dismantlingTextSelector,
    lowestStockContributionsSelector,
    topStockContributionsSelector,
    lowestFundContributionsSelector
  ) => ({
    performanceDismantlingSelector,
    performanceDismantlingProgressSelector,
    assetContributionSelector,
    industryContributionSelector,
    categoryMaxAndMinSelector,
    factorRatioSelector,
    fundCompanyContributionSelector,
    topFundContributionsSelector,
    fundDisassembleTableSelector,
    dismantlingLineChartSelector,
    dismantlingDatesSelector,
    dismantlingTextSelector,
    lowestStockContributionsSelector,
    topStockContributionsSelector,
    lowestFundContributionsSelector,
  })
);
