import { Cascader, CascaderProps } from "antd";
import { useAppSelector } from "@/hooks/redux";
import {
  allBenchmarkMapSelector,
  benchmarkListSelector,
} from "@/selectors/benchmarks";
import { useCreation } from "ahooks";
import { first, identity, isString, last, prop } from "lodash/fp";
import { useCallback, useMemo } from "react";
import { size } from "lodash";
import { fastProp } from "@/util/opt";
import {
  BENCHMARK,
  BENCHMARK_MANAGE,
  PerformanceBenchmarkSelectValue,
} from "./portfolioCompoents/performanceBenchmarkSelect";

type benchmarkSelectProps = Omit<
  CascaderProps<string>,
  "onChange" | "value"
> & {
  onChange?: (...args: any[]) => void;
  value?: string | PerformanceBenchmarkSelectValue;
  customOptions?: Record<string, any>[];
  returnDataType?: string;
  customPathIds?: string[];
};
const BenchmarkSelect = ({
  onChange = identity,
  value,
  className,
  customOptions,
  returnDataType = "string",
  customPathIds = [],
  ...res
}: benchmarkSelectProps): JSX.Element => {
  const options = useAppSelector(benchmarkListSelector);
  const optionsMap = useAppSelector(allBenchmarkMapSelector);
  const cascaderValue = useCreation(() => {
    if (!value) return "";
    if (isString(value))
      return prop(`${value}.path`)(optionsMap) || [...customPathIds, value];
    return prop(`${fastProp("benchmarkId")(value)}.path`)(optionsMap);
  }, [value, optionsMap]);
  const onChangeCascader = useCallback(
    (value: any) => {
      if (returnDataType === "object") {
        onChange({
          benchmarkId: last(value) as string,
          benchmarkType:
            first(value) === BENCHMARK_MANAGE ? BENCHMARK_MANAGE : BENCHMARK,
        });
      } else {
        onChange(last(value) as string);
      }
    },
    [onChange, returnDataType]
  );

  const option = useMemo(() => {
    return size(customOptions) ? customOptions : options;
  }, [customOptions, options]);

  return (
    <Cascader
      className={className}
      options={option as any}
      fieldNames={{ label: "name", value: "id", children: "children" }}
      onChange={onChangeCascader}
      value={cascaderValue}
      {...res}
    />
  );
};

export default BenchmarkSelect;
