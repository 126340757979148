import {
  startBackTesting as startBackTestingAction,
  BackTestingParam,
  getAssetPositionDetail,
  getPositionTrend,
  getPositionTrendStrategy,
  netValueAttributionParam,
  getNetValueAttribution,
  BackTestingTurnoverParm,
  fundCorrelationParam,
  getFundCorrelation,
  valueAtRiskParam,
  getValueAtRisk,
  getRiskDismantle,
  riskDismantleParam,
  getAssetPortfolioDissect,
} from "@/api/portfolioAnalysis";
import {
  flow,
  fromPairs,
  isEmpty,
  isNil,
  map,
  prop,
  reject,
  set,
  update,
} from "lodash/fp";
import createSocketThunk from "@/util/createSocketThunk";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getBackTestingAnalysisDissect,
  getTurnoverDates,
  getBackTestingTurnover,
  getPortfolioAnalysisIntroduction,
  getBrinsonReturn,
} from "@/api/portfolioAnalysis";
import {
  AnalysisDissect,
  assetPortfoliosPositionDetail,
  WarehouseTurnoverReturn,
  brinsonReturnType,
  PositionTrendResponse,
  PositionTrendStrategyResponse,
  netValueAttribution,
  fundCorrelationResponse,
  valueAtRiskResponse,
  RiskDismantleResponse,
} from "@/model/portfolioAnalysis";
import { eqNaN, fastHas, fastProp } from "@/util/opt";
import { normalizeDailyReturnsMap } from "@/util/transformer";
import {
  factorRatioFuncs,
  getPortfolioReturns,
} from "@/constant/portfolioAnalysis";
import { RootState } from ".";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { getSection } from "@/views/portfolioManage/portfolioAnalysis/riskAnalysis/constant";

export const startBackTesting = createSocketThunk(
  "compareManage/fundCompare/fundCompareReturnInfo",
  "FINISH_BACK_TESTING_TASK",
  (fundIds: BackTestingParam) => {
    return startBackTestingAction(fundIds);
  }
);

const startBackTestingFinish: string = startBackTesting.finish.type;
const startBackTestingError: string = startBackTesting.error.type;
const startBackTestingProgress: string = startBackTesting.progress.type;

export const fetchPortfolioSummary = avoidMultiRequestActionThunk<string>(
  (id) => `portfolioAnalysis.${id}.portfolioSummary`,
  createAsyncThunk("portfolioSummary/getPortfolioSummary", async (id) => {
    const response = await getPortfolioAnalysisIntroduction(id);
    return response;
  })
);

export const fetchBackTestingTurnover =
  avoidMultiRequestActionThunk<BackTestingTurnoverParm>(
    (params) =>
      `portfolioAnalysis.${params.id}.turnoverAnalysis.backTestingTurnover.${params.date}`,
    createAsyncThunk(
      "portfolioAnalysis/turnoverAnalysis/backTestingTurnover",
      async (params) => {
        const response = await getBackTestingTurnover(params);
        return response;
      }
    )
  );

export const fetchTurnoverDates = avoidMultiRequestActionThunk<string>(
  (id) => `portfolioAnalysis.${id}.turnoverAnalysis.turnoverDates`,
  createAsyncThunk(
    "portfolioAnalysis/turnoverAnalysis/backTestingReturn",
    async (id) => {
      const response = await getTurnoverDates(id);
      return response;
    }
  )
);

export const fetchBrinsonAttributionReturn = createAsyncThunk(
  "portfolioAnalysis/brinsonAttrubution",
  async (data: { id: string; section: string }) => {
    const response = await getBrinsonReturn(data);
    return response;
  }
);

export const fetchPerformanceDismantling = createAsyncThunk(
  "portfolioAnalysis/performanceDismantling",
  async (data: { id: string; section: string }) => {
    const response = await getBackTestingAnalysisDissect(data);
    return response;
  }
);

export const fetchAssetPortfolioDismantling = createAsyncThunk(
  "portfolioAnalysis/performanceDismantling",
  async (data: { id: string; section: string }) => {
    const response = await getAssetPortfolioDissect(data);
    return response;
  }
);

export const fetchAssetPositionDetail = createAsyncThunk(
  "portfolioAnalysis/AssetPositionDetail",
  async (data: { id: string; date: string }) => {
    const res = await getAssetPositionDetail(data);
    return res;
  }
);
export const calculationFactorRatio: any = createAsyncThunk(
  "portfolioAnalysis/calculationFactorRatio",
  (
    { id, range, factor }: { id: string; range: string; factor: string[] },
    { getState }
  ) => {
    const result = getState() as RootState;
    const oldFactorRatio = result.portfolioAnalysis?.[id]?.factorRatio?.[range];
    const needCalcFactors = reject(
      (id: string) =>
        fastHas(id)(oldFactorRatio) &&
        !eqNaN(
          fastProp(id)(oldFactorRatio) && !isNil(fastProp(id)(oldFactorRatio))
        )
    )(factor);
    if (isEmpty(needCalcFactors)) return {};
    const { tradingDate, dailyReturn, benchmark, dailyNetValue } = result
      .portfolioAnalysis?.[id].portfolioSummary as portfolioSummaryType;
    const { dailyReturns, dates } = result.dailyReturns?.[benchmark] || {};
    const dailyReturnMap = normalizeDailyReturnsMap(tradingDate, dailyReturn);
    const benchmarkReturnMap = normalizeDailyReturnsMap(dates, dailyReturns);
    const netValueReturnMap = normalizeDailyReturnsMap(
      tradingDate,
      dailyNetValue
    );
    const riskFreeRates = result.entities.riskFreeRate;
    const { tradingDateList, processedTradingDates } = result.tradingDates;
    const portfolioDaliReturns = getPortfolioReturns({
      range,
      dates: tradingDate,
      portfolioDailyReturnsMap: dailyReturnMap,
      benchmarkDailyReturnsMap: benchmarkReturnMap,
      netValueReturnMap,
      riskFreeRates,
      tradingDateList,
      processedTradingDates,
    });
    const res = flow(
      map((v: string) => {
        const func = fastProp(v)(factorRatioFuncs);
        return [v, func ? func(portfolioDaliReturns) : null];
      }),
      fromPairs
    )(needCalcFactors);
    return {
      type: range,
      data: res,
      id,
    };
  }
);
export const fetchGetPositionTrend = avoidMultiRequestActionThunk<string>(
  (id) => `portfolioAnalysis.${id}.positionTrend`,
  createAsyncThunk("portfolioAnalysis/PositionTrend", async (id: string) => {
    const response = await getPositionTrend(id);
    return response;
  })
);
export const fetchGetPositionTrendStrategy =
  avoidMultiRequestActionThunk<string>(
    (id) => `portfolioAnalysis.${id}.positionTrendStrategy`,
    createAsyncThunk(
      "portfolioAnalysis/PositionTrendStrategy",
      async (id: string) => {
        const response = await getPositionTrendStrategy(id);
        return response;
      }
    )
  );

export const fetchNetValueAttribution = createAsyncThunk(
  "portfolioAnalysis/netValueAttribution",
  async (param: netValueAttributionParam, { getState }) => {
    const data = prop(
      `portfolioAnalysis.${param.id}.netValueAttribution.${param.section}`
    )(getState() as RootState);
    if (!isEmpty(data)) return Promise.resolve();
    const res = await getNetValueAttribution(param);
    return res;
  }
);
const getFundCorrelationSection = (param: fundCorrelationParam) => {
  const { section, from, to } = param;
  return from && to ? `${from}-${to}` : section;
};
export const fetchFundCorrelation = createAsyncThunk(
  "portfolioAnalysis/fundCorrelation",
  async (param: fundCorrelationParam, { getState }) => {
    const sectionId = getFundCorrelationSection(param);
    const data = prop(
      `portfolioAnalysis.${param.portfolioId}.fundCorrelation.${sectionId}`
    )(getState() as RootState);
    if (!isEmpty(data)) return Promise.resolve();
    const res = await getFundCorrelation(param);
    return res;
  }
);
export const fetchValueAtRisk = createAsyncThunk(
  "portfolioAnalysis/valueAtRisk",
  async (param: valueAtRiskParam, { getState }) => {
    const { id, computationType, confidenceType, future } = param;
    const sectionId = getSection(computationType, confidenceType, future);
    const data = prop(`portfolioAnalysis.${id}.valueAtRisk.${sectionId}`)(
      getState() as RootState
    );
    if (!isEmpty(data)) return Promise.resolve();
    const res = await getValueAtRisk(param);
    return res;
  }
);
export const fetchPortfolioRiskDismantle =
  avoidMultiRequestActionThunk<riskDismantleParam>(
    (param) =>
      `portfolioAnalysis.${param.id}.riskDismantle.${param.section}.${param.scenarioId}`,
    createAsyncThunk("portfolioAnalysis/riskDismantle", async (param) => {
      const response = await getRiskDismantle(param);
      return response;
    })
  );

export type latestPositionType = {
  fundId: string;
  purchFeeRatio: number;
  redeemFeeRatio: number;
  weight: number;
};

export type portfolioSummaryType = {
  latestScale: string; //最新规模
  id: string;
  startDate: string;
  endDate: string;
  benchmark: string; //业绩基准
  benchmarkName: string;
  riskContributions: Record<string, any>;
  fundContributions: Record<string, any>;
  strategyContributions: Record<string, any>;
  latestPosition: latestPositionType[];
  tradingDate: string[];
  dailyNetValue: number[];
  dailyReturn: number[];
  trendView: any;
  fundSectionMaxDrawdown: Record<string, any>;
};

type portfolioPreviewType = {
  selectedIncomeType: string;
  activeRange: string;
  benchmarkId: string;
  legendIds: string;
  frequency: string;
};

type portfolioAnalysisState = {
  portfolioSummary?: portfolioSummaryType;
  portfolioPreview?: portfolioPreviewType;
  turnoverAnalysis?: {
    turnoverDates?: string[];
    backTestingTurnover?: Record<string, WarehouseTurnoverReturn>;
  };
  performanceDismantling?: Record<string, AnalysisDissect>;
  factorRatio?: Record<string, any>;
  assetPortfoliosPositionDetail?: assetPortfoliosPositionDetail;
  brinsonAttribution?: Record<string, brinsonReturnType>;
  positionTrend?: PositionTrendResponse;
  positionTrendStrategy?: PositionTrendStrategyResponse;
  netValueAttribution?: Record<string, netValueAttribution>;
  fundCorrelation?: Record<string, fundCorrelationResponse>;
  valueAtRisk?: Record<string, valueAtRiskResponse>;
  riskDismantle: RiskDismantleResponse;
};
type InitialState = {
  [key: string]: portfolioAnalysisState | any;
  backTestingTask?: Record<string, any>;
};
const initialState: InitialState = {};

const portfolioAnalysisSlice = createSlice({
  name: "portfolioAnalysis",
  initialState,
  reducers: {
    updatePortfolioPreviewInfo: (state: InitialState, action) => {
      const { id, key, value } = action.payload;
      if (id && key && value) {
        state[id] = {
          ...fastProp(id)(state),
          portfolioPreview: {
            ...prop(`${id}.portfolioPreview`)(state),
            [key]: value,
          },
        };
      }
    },
    updatePortfolioPerformanceAttribution: (state: InitialState, action) => {
      const { key, value, id } = action.payload;
      if (id && key && value) {
        state[id] = {
          ...fastProp(id)(state),
          brinsonAttribution: {
            ...prop(`${id}.brinsonAttribution`)(state),
            [key]: value,
          },
        };
      }
    },
    resetBackTestingTask: (state: InitialState) => {
      state.backTestingTask = undefined;
    },
  },
  extraReducers: {
    [startBackTestingFinish]: (state, action) => {
      state.backTestingTask = {
        ...action?.meta,
        backTestingId: prop("payload.id")(action),
      };
    },
    [startBackTestingError]: (state, action) => {
      state.backTestingTask = action.error;
    },
    [startBackTestingProgress]: (state, action) => {
      state.backTestingTask = action.payload;
    },
    [fetchPortfolioSummary.fulfilled.type]: (state, action) => {
      state[action?.meta?.arg] = {
        ...state[action?.meta?.arg],
        portfolioSummary: action.payload,
      };
    },
    [fetchBackTestingTurnover.fulfilled.type]: (state, action) => {
      const { id, date } = action?.meta?.arg || {};
      if (id && date) {
        state[id] = set(
          `turnoverAnalysis.backTestingTurnover.${date}`,
          action.payload
        )(state[id]);
      }
    },
    [fetchTurnoverDates.fulfilled.type]: (state, action) => {
      state[action?.meta?.arg] = set(
        "turnoverAnalysis.turnoverDates",
        action.payload
      )(state[action?.meta?.arg]);
    },
    [fetchBrinsonAttributionReturn.fulfilled.type]: (state, action) => {
      state[action.meta?.arg?.id] = {
        ...state[action.meta?.arg?.id],
        brinsonAttribution: {
          ...state[action?.meta?.arg?.id]?.brinsonAttribution,
          [action?.meta?.arg?.section]: {
            brinsonReturn: action.payload,
          },
        },
      };
    },
    [fetchPerformanceDismantling.fulfilled.type]: (state, action) => {
      state[action?.meta?.arg?.id] = {
        ...state[action?.meta?.arg?.id],
        performanceDismantling: {
          ...state[action?.meta?.arg?.id]?.performanceDismantling,
          [action?.meta?.arg?.section]: action.payload,
        },
      };
    },
    [fetchAssetPortfolioDismantling.fulfilled.type]: (state, action) => {
      const { id, section } = action?.meta?.arg;
      state[id] = set(
        `performanceDismantling.${section}`,
        action.payload
      )(state[id]);
    },
    [fetchAssetPositionDetail.fulfilled.type]: (state, action) => {
      state[action?.meta?.arg?.id] = {
        ...state[action?.meta?.arg?.id],
        assetPortfoliosPositionDetail: {
          ...state[action?.meta?.arg?.id]?.assetPortfoliosPositionDetail,
          [action?.meta?.arg?.date]: action.payload,
        } as assetPortfoliosPositionDetail,
      };
    },
    [calculationFactorRatio.fulfilled.type]: (state, action) => {
      const { id, type, data } = action.payload;
      if (id && type && !isEmpty(data)) {
        state[id] = update(`factorRatio.${type}`, (originData) => ({
          ...(originData || {}),
          ...data,
        }))(state[id]);
      }
    },
    [fetchGetPositionTrend.fulfilled.type]: (state, action) => {
      const id = action?.meta?.arg;
      if (id) {
        state[id] = set("positionTrend", action.payload)(state[id]);
      }
    },
    [fetchGetPositionTrendStrategy.fulfilled.type]: (state, action) => {
      const id = action?.meta?.arg;
      if (id) {
        state[id] = set("positionTrendStrategy", action.payload)(state[id]);
      }
    },
    [fetchNetValueAttribution.fulfilled.type]: (state, action) => {
      const { id, section } = action.meta?.arg;
      if (id && section && action.payload) {
        state[id] = set(
          `netValueAttribution.${section}`,
          action.payload
        )(state[id]);
      }
    },
    [fetchFundCorrelation.fulfilled.type]: (state, action) => {
      const { portfolioId: id } = action.meta?.arg;
      const section = getFundCorrelationSection(action.meta?.arg);
      if (id && section && action.payload) {
        state[id] = set(
          `fundCorrelation.${section}`,
          action.payload
        )(state[id]);
      }
    },
    [fetchValueAtRisk.fulfilled.type]: (state, action) => {
      const { id, computationType, confidenceType, future } = action.meta?.arg;
      const section = getSection(computationType, confidenceType, future);
      if (id && section && action.payload) {
        state[id] = set(`valueAtRisk.${section}`, action.payload)(state[id]);
      }
    },
    [fetchPortfolioRiskDismantle.fulfilled.type]: (state, action) => {
      const { id, section, scenarioId } = action?.meta?.arg;
      if (id && section && scenarioId && action.payload) {
        state[id] = set(
          `riskDismantle.${section}.${scenarioId}`,
          action.payload
        )(state[id]);
      }
    },
  },
});

export const {
  updatePortfolioPreviewInfo,
  updatePortfolioPerformanceAttribution,
  resetBackTestingTask,
} = portfolioAnalysisSlice.actions;
export default portfolioAnalysisSlice.reducer;
