import { useContext } from "react";
import { PlatformNavigationContext } from "@/providers/platformNavigationProvider";
import cn from "classnames";
import { RequiredJumpProps } from "./interface";
import { useMemoizedFn } from "ahooks";
import { identity } from "lodash/fp";
import style from "./index.module.less";
import BaseNavigate from "./baseNavigate";

interface ToFundCompanyDetailPageProps extends RequiredJumpProps {
  beforeJump?: () => any;
}
const ToFundCompanyDetailPage = ({
  name,
  id,
  className,
  beforeJump = identity,
}: ToFundCompanyDetailPageProps): JSX.Element => {
  const { goToFundCompanyDetailPage } = useContext(PlatformNavigationContext);
  const navigateTo = useMemoizedFn(() => {
    beforeJump();
    if (id) goToFundCompanyDetailPage({ id });
  });
  return id ? (
    <BaseNavigate name={name} navigateTo={navigateTo} className={className} />
  ) : (
    <p className={cn(className, style.InlineBlockText)}>{name}</p>
  );
};

export default ToFundCompanyDetailPage;
