import dayjs from "dayjs";
import { isEmpty, max, nth, min, size, prop, isString, isNumber } from "lodash/fp";
import { forEachIndexed, fastNth } from "./opt";
import { dateFormat } from "./dateFormat";
import { DATEFORMAT } from "@/util/dateFormat";

export default (dates: string[]) => {
  const res: Record<string, any> = {};
  forEachIndexed((date: string, index: number) => {
    const [year, month, day] = date.split("-");
    res[year] = res[year] || {};
    res[year][month] = res[year][month] || {};
    res[year][month][day] = index;
  })(dates);
  return res;
};
export const getDisabledDate = ({ time, start, end, tradingDate }: any) => {
  const dTime = dayjs(time);
  const sTime = dayjs(start);
  const eTime = dayjs(end);
  return (
    dTime.isAfter(eTime) ||
    dTime.isBefore(sTime) ||
    !dateProcess(dTime.format(DATEFORMAT), tradingDate)
  );
};
export const dateProcess = (
  date: string,
  processedDateObj: Record<string, any>
): number | null => {
  if (!isEmpty(date) && !isEmpty(processedDateObj) && isString(date)) {
    const [year, month, day] = date.split("-");
    return prop(`${year}.${month}.${day}`)(processedDateObj);
  }
  return null;
};

export const getNatureDateAndTradingDate =
  (startDate: string, endDate: string) =>
  (processedDateObj: Record<string, any>) => {
    let startDay = dayjs(startDate);
    const endDay = dayjs(endDate);
    let natureDateCount = 0;
    let tradingDateCount = 0;
    if (!startDay.isValid() || !endDay.isValid() || !startDate || !endDate)
      return [natureDateCount, tradingDateCount];
    while (!endDay.isBefore(startDay)) {
      natureDateCount += 1;
      if (dateProcess(startDay.format("YYYY-MM-DD"), processedDateObj))
        tradingDateCount += 1;
      startDay = startDay.add(1, "day");
    }
    return [natureDateCount, tradingDateCount];
  };

export const getPreviousTradingDate = (
  tradingDates: string[],
  processedTradingDates: Record<string, any>,
  date: string,
  maxLoop = 30
) => {
  const currIndex = dateProcess(date, processedTradingDates);

  // 如果今天是trading date
  if (currIndex) {
    const prevIndex = max([currIndex - 1, 0]);
    return nth(prevIndex as number)(tradingDates) as string;
  }

  let i = 1;
  let prevTradingDate = "";
  while (maxLoop > 0) {
    prevTradingDate = dateFormat(dayjs(date).subtract(i, "day"));
    const prevIndex = dateProcess(prevTradingDate, processedTradingDates);
    if (prevIndex) {
      break;
    } else {
      i += 1;
    }
    // eslint-disable-next-line
    maxLoop -= 1;
  }

  return prevTradingDate;
};

export const getNextTradingDate = (
  tradingDates: string[],
  processedTradingDates: Record<string, any>,
  date: string,
  maxLoop = 30
) => {
  const currIndex = dateProcess(date, processedTradingDates);

  // 如果今天是trading date
  if (isNumber(currIndex)) {
    const prevIndex = min([currIndex + 1, size(tradingDates)]);
    return fastNth(prevIndex as number)(tradingDates);
  }

  // 如果时间在交易日之前，返回交易日第一天
  if (date < fastNth(0)(tradingDates)) {
    return fastNth(0)(tradingDates);
  }

  let i = 1;
  let nextTradingDate = "";
  while (maxLoop > 0) {
    nextTradingDate = dateFormat(dayjs(date).add(i, "day"));
    const nextIndex = dateProcess(nextTradingDate, processedTradingDates);
    if (nextIndex) {
      return nextTradingDate;
    }
    i += 1;

    // eslint-disable-next-line
    maxLoop -= 1;
  }

  return undefined;
};

export const getTradingDate = (
  tradingDates: string[],
  processedTradingDates: Record<string, any>,
  date: string
) => {
  if (tradingDates[0] > date) {
    return tradingDates[0];
  }

  const currIndex = dateProcess(date, processedTradingDates);

  // 如果今天是trading date
  if (isNumber(currIndex)) {
    return fastNth(currIndex)(tradingDates);
  }
  return getNextTradingDate(tradingDates, processedTradingDates, date);
};
