import {
  TASK_PROCESS,
  SYNC_TASK,
  ERROR,
  REQUEST_FAILED,
} from "@/constant/socket";
import { AppDispatch } from "@/store";
import { socketAuthorization } from "@/store/users";
import { getAuthorizationKey } from "../authorizationKey";
import { fastProp } from "../opt";
import socket from "./createBaseSocket";
import { SocketProgressData, SocketErrorData } from "./interface";

export const requestCache = new Map<
  string,
  {
    progress: (data: SocketProgressData) => void;
    error: (err: SocketErrorData) => void;
  }
>();

export default (dispatch: AppDispatch) => {
  const handleListener = (data: SocketProgressData) => {
    const taskId = fastProp("id")(data);
    const processData = Object.assign(data, {
      status: TASK_PROCESS,
    });
    const cache = requestCache.get(taskId);
    if (cache && cache.progress) {
      cache.progress(processData);
    }
  };
  const errorListener = (data: SocketErrorData) => {
    const taskId = fastProp("taskId")(data);
    const cache = requestCache.get(taskId);
    if (cache && cache.error) {
      cache.error({
        ...data,
        status: TASK_PROCESS,
        progress: REQUEST_FAILED,
      });
      requestCache.delete(taskId);
    }
  };
  const auth = getAuthorizationKey();
  const promises = [
    new Promise((resolve, reject) =>
      socket.on("connect", () =>
        dispatch(socketAuthorization(auth)).then(resolve).catch(reject)
      )
    ),
    socket.on(SYNC_TASK, handleListener),
    socket.on(TASK_PROCESS, handleListener),
    socket.on(ERROR, errorListener),
  ];
  return Promise.all(promises);
};
