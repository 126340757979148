import React, { useCallback, useMemo } from "react";
import { map, merge, forEach } from "lodash/fp";
import { LineSeriesOption } from "echarts";
import {
  cumulativeReturn,
  cumulativeDynamicMaxDrawdown,
  CalculatorFunc,
  dayilreturn,
} from "@/util/transformer";
import { getLastDateOfYear, getRectangleLegendConfig } from "@/util/chart";
import LineChart, { LineChartOpts } from "@/echarts/lineChart";
import { useManageDataZoom, DataSource, SeriesDataSource } from "@/hooks/chart";
import { mapIndexed, fastNth, fastProp } from "@/util/opt";
import { useFormatMessage } from "@/util/formatMessage";
import { formatPercentage, toFixedNumber } from "@/util/numberFormatter";
import { WrapperProps } from "@/echarts/echartsWrapper";
import { formatNilToZero } from "@/util/numberFormatter";

interface CumulativeChartOpts extends WrapperProps {
  dates: string[];
  dataSource: DataSource[];
  type: "cumulative" | "maxDrawdown" | "cumulativeNetValue";
  options?: LineChartOpts["options"];
  showDataZoom?: boolean;
}

const getDatesAndDailyReturns = (data: DataSource["dailyReturns"]) => {
  const dates: string[] = [];
  const dailyReturns: number[] = [];
  forEach<[string, number]>(([date, dailyReturn]) => {
    dates.push(date);
    dailyReturns.push(formatNilToZero(dailyReturn));
  })(data);
  return { dates, dailyReturns };
};

export const calculateCumulativeDataSource = (
  slicedDataSource: DataSource[],
  calculator: CalculatorFunc
) =>
  map<DataSource, SeriesDataSource>(
    ({ name, dailyReturns: wrapDailyReturns, ...rest }) => {
      const { dates, dailyReturns } = getDatesAndDailyReturns(wrapDailyReturns);
      const [calculatedCumulativeReturns, processedDates] = calculator(
        dailyReturns,
        dates
      );
      return {
        name,
        ...rest,
        cumulativeReturns: mapIndexed((date: string, index: number) => [
          date,
          fastNth(index)(calculatedCumulativeReturns),
        ])(processedDates),
      };
    }
  )(slicedDataSource);

const getCalculator = (type: CumulativeChartOpts["type"]): CalculatorFunc => {
  if (type === "cumulative") return cumulativeReturn;
  if (type === "maxDrawdown") return cumulativeDynamicMaxDrawdown;
  if (type === "cumulativeNetValue") return dayilreturn;
  return cumulativeReturn;
};
export default React.memo<CumulativeChartOpts>(
  ({ dates, dataSource, type, options, showDataZoom, width, height }) => {
    const [slicedDataSource] = useManageDataZoom(dataSource);
    const calculator = useCallback(
      (dailyReturns: number[], dates: string[]) =>
        getCalculator(type)(dailyReturns, dates),
      [type]
    );

    const cumulativeDataSource = useMemo(
      () => calculateCumulativeDataSource(slicedDataSource, calculator),
      [calculator, slicedDataSource]
    );
    const legendData = useMemo(() => map("name")(dataSource), [dataSource]);

    const formatMessage = useFormatMessage();
    const handleOptions = useMemo<LineChartOpts["options"]>(() => {
      const yearOfDates = getLastDateOfYear(dates);
      return merge({
        xAxis: {
          data: dates,
          nameGap: 40,
          axisLabel: {
            interval: (index: number, value: string) => {
              const year = fastProp(value)(yearOfDates);
              return year ? true : false;
            },
            formatter(value: string) {
              return `${fastProp(value)(yearOfDates)}${formatMessage("year")}`;
            },
          },
        },
        yAxis: {
          min: "dataMin",
          max: "dataMax",
          axisLabel: {
            formatter(value: number) {
              return type === "cumulativeNetValue"
                ? toFixedNumber(4)(value)
                : formatPercentage(value);
            },
          },
        },
        legend: {
          left: 0,
          top: 0,
          ...getRectangleLegendConfig(),
          data: legendData,
        },
        tooltip: {
          valueFormatter: (value: any) => {
            return type === "cumulativeNetValue"
              ? toFixedNumber(4)(value)
              : formatPercentage(value);
          },
        },
      })(options);
    }, [dates, legendData, options, type, formatMessage]);

    const series = useMemo<LineChartOpts["series"]>(
      () =>
        map<SeriesDataSource, LineSeriesOption>(
          ({ name, cumulativeReturns, ...rest }) => ({
            type: "line",
            lineStyle: {
              width: 1.5,
            },
            name,
            showSymbol: false,
            smooth: false,
            ...rest,
            data: cumulativeReturns,
          })
        )(cumulativeDataSource),
      [cumulativeDataSource]
    );

    // 先不监听拖动重新计算累计收益的事件
    // const events = useMemo(
    //   () => ({
    //     datazoom: onDataZoom,
    //   }),
    //   [onDataZoom]
    // );

    return (
      <LineChart
        options={handleOptions}
        series={series}
        // events={events}
        height={height}
        width={width}
        showDataZoom={showDataZoom}
      />
    );
  }
);
