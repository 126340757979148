import { isNil, map } from "lodash/fp";
import { fastProp } from "@/util/opt";
import {
  LineChartNames,
  dismantlingLineChartNames,
} from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/constant";
import { generateOptions } from "@/views/portfolioManage/portfolioAnalysis/performanceAttribution/constant";
import { LineSeriesOption } from "echarts";
import getMessage from "@/util/getMessage";

export const getBaseData = (dismantlingLineChart: Record<string, any>) => {
  const dates: string[] = fastProp("dates")(dismantlingLineChart);
  const chartData = map((v: LineChartNames) => {
    const {
      name,
      cumulativeReturns,
    }: {
      name: string[];
      cumulativeReturns: number[];
    } = fastProp(v.id)(dismantlingLineChart) || {};
    return {
      color: v.color,
      name: isNil(name) ? getMessage(v.message) : name,
      dailyReturns: cumulativeReturns,
    };
  })(dismantlingLineChartNames);
  return {
    dates,
    chartData,
  };
};

export const getSeries = (dismantlingLineChart: Record<string, any>) => {
  const { chartData, dates } = getBaseData(dismantlingLineChart);
  return map<any, LineSeriesOption>(({ name, dailyReturns, color }) => ({
    name,
    color,
    dates,
    returns: dailyReturns,
  }))(chartData);
};

export const getOptions = (
  dismantlingLineChart: Record<string, any>,
  portfolioType?: string
) => {
  const { chartData, dates } = getBaseData(dismantlingLineChart);
  return generateOptions({
    legend: map("name")(chartData),
    dates,
    portfolioType,
  });
};
