import { FundList } from "@/components/portfolioCompoents/fundListTable";
import { FundsInterface } from "@/model/entities";
import { assetsType } from "@/model/portfolioList";
import { fastHas, fastProp, normalize } from "@/util/opt";
import { dateProcess } from "@/util/processedDates";
import {
  flow,
  map,
  omit,
  set,
  keys,
  values,
  isEmpty,
  compact,
} from "lodash/fp";
import { useState, useCallback, useMemo, useEffect } from "react";

export const useFundPosition = (
  defaultFundData?: FundList[]
): [
  string[],
  FundList[],
  (fundId: string, key?: string) => (v: string | number | null) => void,
  (v: string[]) => void
] => {
  const [fundList, setFundList] = useState<Record<string, FundList>>({});
  useEffect(() => {
    if (defaultFundData && !isEmpty(defaultFundData)) {
      setFundList(normalize<FundList>("fundId")(defaultFundData));
    }
  }, [defaultFundData]);
  const onChangeFundIds = useCallback(
    (v: string[]) => {
      const newFundList = flow(
        map((v: string) => ({
          fundId: v,
          weight: 0,
          purchFeeRatio: 0,
          redeemFeeRatio: 0,
          editable: true,
          removable: true,
          ...(fastHas(v)(fundList) ? fundList?.[v] : {}),
        })),
        normalize("fundId")
      )(v);
      setFundList(newFundList as Record<string, FundList>);
    },
    [fundList]
  );

  const onUpdate = useCallback(
    (fundId: string, key?: string) => (v: string | number | null) => {
      if (!key) {
        setFundList(omit(fundId));
      } else {
        setFundList(set(`${fundId}.${key}`, v));
      }
    },
    []
  );
  return useMemo(
    () => [keys(fundList), values(fundList), onUpdate, onChangeFundIds],
    [fundList, onChangeFundIds, onUpdate]
  );
};

export const checkNetValue = (
  assets: assetsType[],
  fundIdMap: Record<string, FundsInterface>,
  dateSource: string,
  processedTradingDates: Record<string, any>
) => {
  return flow(
    map(({ fundId }: assetsType) => {
      const { name, code, netValueEndDate } = fastProp(fundId)(fundIdMap) || {};
      if (!netValueEndDate) return;
      const dateSourceIndex = dateProcess(dateSource, processedTradingDates);
      const netValueEndDateIndex = dateProcess(
        netValueEndDate,
        processedTradingDates
      );
      if (!dateSourceIndex || !netValueEndDateIndex) return;
      if (dateSourceIndex - netValueEndDateIndex < 10) return;
      return `${name}（${code}）`;
    }),
    compact
  )(assets);
};
