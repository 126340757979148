// import { ScenarioListResponse } from "@/model/portfolioAnalysis";
import { categoryTreeMapSelector } from "@/selectors/category";
import { RootState } from "@/store";
import { createSelector } from "@reduxjs/toolkit";

export const scenarioListSelector = createSelector(
  (state: RootState) => state.scenarioList.scenarioList,
  (scenarioList) => scenarioList || []
);

export default createSelector(categoryTreeMapSelector, (categoryTreeMap) => ({
  categoryTreeMap,
}));
