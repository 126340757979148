import { useState, useEffect, useCallback, useMemo } from "react";
import { getUserConfig, updateUserConfig } from "@/api/userConfig";
import { fromPairs, map, flow, join, prop } from "lodash/fp";
import { fastProp, size } from "@/util/opt";
import { Tooltip } from "antd";
import type { TableProps } from "antd";
import { formatFactors } from "@/constant/factorFormatter";
import { useFormatMessage } from "@/util/formatMessage";
import { sortAntdTable } from "@/util/sortTable";
import { getFactorValue, getPortfolioFactorValue } from "@/store/factors";
import { FundsInterface } from "@/model/entities";
import { dataSourceTimeSelector } from "@/selectors/dataSource";
import { useGetIndexInfo } from "@/components/fundResultTable/hooks";
import { Factor } from "@/model/factors";
import type { SelectIndexDialogProps } from "@/components/fundResultTable/selectIndexDialog";
import { SorterResult } from "antd/lib/table/interface";
import { useAppSelector, useAppDispatch } from "./redux";
import ToFundCompanyDetailPage from "@/components/navigateToPage/toFundCompanyDetailPage";
import ToFundManagerDetailPage from "@/components/navigateToPage/toFundManagerDetailPage";
import style from "./index.module.less";

type FundManagerStatus = {
  fundManagerId?: string;
  fundManagerName?: string;
}[];

export function useGetFactorIds(
  configId: string,
  defaultFactors: string[]
): [string[], (arg: string[]) => void] {
  const [selectedFactorCode, changeFactorCode] = useState<string[]>([]);
  useEffect(() => {
    getUserConfig(configId).then((res) => {
      changeFactorCode(fastProp("config")(res) || defaultFactors);
    });
  }, [configId, defaultFactors]);
  const updateSelectedFactorCodes = useCallback(
    (codes: string[]) => {
      changeFactorCode(codes);
      updateUserConfig({ moduleId: configId, config: codes });
    },
    [configId]
  );
  return [selectedFactorCode, updateSelectedFactorCodes];
}

export type FundAntdTableProps = TableProps<FundsInterface>;

export function useGetFactorColumns(
  type: SelectIndexDialogProps["type"],
  selectedFactorIds: string[]
) {
  const { factorMap } = useGetIndexInfo(type);
  const formatMessage = useFormatMessage();
  return useMemo<FundAntdTableProps["columns"]>(
    () =>
      selectedFactorIds?.map((id) => {
        const factorInfo = factorMap?.[id];
        return {
          title: factorInfo?.name,
          dataIndex: id,
          sorter: factorInfo?.sorter,
          width: 150,
          align: factorInfo?.align,
          ellipsis: {
            showTitle: false,
          },
          render: (value: string | number, { fundCompany, fundManager }) => {
            if (value !== undefined || factorInfo?.isBasicInfo) {
              const formatValue = formatFactors(value, factorInfo?.format);
              if (factorInfo?.id === "fundCompany" && fundCompany) {
                return (
                  <ToFundCompanyDetailPage
                    name={fundCompany?.fundCompanyName}
                    id={fundCompany?.fundCompanyId}
                    className={style.MaxWidth}
                  />
                );
              }
              if (factorInfo?.id === "fundManager" && size(fundManager)) {
                return (
                  <div className={style.MaxWidth}>
                    {map<any, JSX.Element>(
                      ({ fundManagerId, fundManagerName } = {}) => (
                        <>
                          <ToFundManagerDetailPage
                            id={fundManagerId}
                            name={fundManagerName as string}
                            className={style.ManagerLink}
                          />
                        </>
                      )
                    )(fundManager as FundManagerStatus)}
                  </div>
                );
              }
              return (
                <Tooltip
                  placement="topLeft"
                  title={formatValue}
                  className={style.MaxWidth}
                >
                  {formatValue}
                </Tooltip>
              );
            }
            return formatMessage("loading");
          },
        };
      }),
    [selectedFactorIds, formatMessage, factorMap]
  );
}

export function useGetFactorsDataSource<T>(
  funds: T[],
  idKey: string,
  factors: string[],
  factorMap: Record<string, Factor>
): [T[], Exclude<TableProps<any>["onChange"], undefined>] {
  const [sorter, changeSorter] = useState<SorterResult<T> | undefined>();
  const factorValues = useAppSelector((store) => store.factors.factorValues);
  const notBasicFactors = useMemo(
    () => factors.filter((factor) => !factorMap[factor]?.isBasicInfo),
    [factors, factorMap]
  );

  const dataSource = useMemo(
    () =>
      funds?.map((fund: T) => ({
        ...fund,
        fundManagerName: flow(
          fastProp("fundManager"),
          map("fundManagerName"),
          join("、")
        )(fund),
        fundCompanyName: prop(`fundCompany.fundCompanyName`)(fund),
        ...flow(
          map((factor: string) => [
            factor,
            factorValues?.[factor]?.[fastProp(idKey)(fund)],
          ]),
          fromPairs
        )(notBasicFactors),
      })),
    [funds, notBasicFactors, factorValues, idKey]
  );
  const sortedDataSource = useMemo(() => {
    if (sorter && sorter.field && sorter.order) {
      const sortFunc = sortAntdTable(sorter.field);
      if (sorter.order === "ascend")
        return [...dataSource].sort((a, b) => sortFunc(a, b, sorter.order));
      return [...dataSource].sort((a, b) => sortFunc(b, a, sorter.order));
    }
    return dataSource;
  }, [dataSource, sorter]);
  const onSort = useCallback<Exclude<TableProps<any>["onChange"], undefined>>(
    (_, __, curSorter) => {
      if (Array.isArray(curSorter)) {
        return;
      }
      if (
        sorter?.order !== curSorter?.order ||
        sorter?.field !== curSorter?.field
      ) {
        changeSorter(curSorter);
      }
    },
    [sorter, changeSorter]
  );
  return [sortedDataSource, onSort];
}

export function useGetFactorValue(
  dataSource: any[],
  idKey: string,
  type: SelectIndexDialogProps["type"],
  factors: string[],
  factorMap: Record<string, Factor>
) {
  const dispatch = useAppDispatch();
  const assetIds = useMemo(
    () => dataSource?.map((v) => fastProp(idKey)(v)),
    [dataSource, idKey]
  );
  const lastUpdateDate = useAppSelector(dataSourceTimeSelector);
  useEffect(() => {
    if (lastUpdateDate && factors?.length && assetIds?.length) {
      for (let index = 0; index < factors.length; index++) {
        const factorInfo = factorMap[factors[index]];
        if (factorInfo && !factorInfo.isBasicInfo) {
          switch (type) {
            case "fund":
              dispatch(
                getFactorValue({
                  assetIds,
                  factorId: factors[index],
                  date: lastUpdateDate,
                })
              );
              break;
            case "portfolio":
              dispatch(
                getPortfolioFactorValue({
                  portfolioIds: assetIds,
                  factorId: factors[index],
                })
              );
              break;
          }
        }
      }
    }
  }, [dispatch, assetIds, factors, factorMap, lastUpdateDate, type]);
}
