import { RootState } from "@/store";
import { fastProp } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { compact, flatten, flow, map, prop } from "lodash/fp";

export const customerAnalysisSelector = createSelector(
  (state: RootState) => state.customerAnalysis,
  (_: RootState, id: string) => id,
  (customerAnalysis, id) => fastProp(id)(customerAnalysis) || {}
);

export const allSubAccountAnalysisSelector = createSelector(
  (state: RootState) => state.customerAnalysis,
  (customerAnalysis) =>
    flow(
      map((item) => prop("accountInfo.subCustomerDetails")(item)),
      compact,
      flatten
    )(customerAnalysis as unknown as unknown[])
);

export const accountPortfolioAssetListSelector = createSelector(
  customerAnalysisSelector,
  (customerAnalysis) => fastProp(`assetList`)(customerAnalysis) || {}
);
