import { createSlice } from "@reduxjs/toolkit";

interface GlobalState {
  loadings: Record<string, number>;
  loadingCount: number;
}

const initialState: GlobalState = { loadings: {}, loadingCount: 0 };

type LoadingPayload = {
  type: string;
  ignoreLoading?: boolean;
};

const globalSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    addGlobalLoading: (state: GlobalState) => {
      state.loadingCount += 1;
    },
    subGlobalLoading: (state: GlobalState) => {
      if (state.loadingCount >= 1) {
        state.loadingCount -= 1;
      }
    },
    addLoading: (
      state: GlobalState,
      { payload }: { payload: LoadingPayload }
    ) => {
      if (!payload.ignoreLoading) {
        const loadingCount = state.loadings[payload.type] || 0;
        state.loadings[payload.type] = loadingCount + 1;
      }
    },
    subLoading: (
      state: GlobalState,
      { payload }: { payload: LoadingPayload }
    ) => {
      if (!payload.ignoreLoading) {
        const loadingCount = state.loadings[payload.type] || 0;
        state.loadings[payload.type] = loadingCount > 1 ? loadingCount - 1 : 0;
      }
    },
  },
});

export const { addLoading, subLoading, addGlobalLoading, subGlobalLoading } =
  globalSlice.actions;
export default globalSlice.reducer;
