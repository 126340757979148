import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { SliderAndInput } from "@/components/portfolioCompoents/fundListTable";
import { factorsFormatter } from "@/constant/factorFormatter";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { updateModelConfiguration } from "@/store/createPortfolio";
import { useFormatMessage } from "@/util/formatMessage";
import { equalNaN, fastNth, fastProp, mapIndexed } from "@/util/opt";
import { useCreation, useMemoizedFn } from "ahooks";
import { Button, TableProps } from "antd";
import classnames from "classnames";
import {
  update,
  set,
  isEmpty,
  map,
  sumBy,
  flow,
  flatten,
  prop,
} from "lodash/fp";
import { useEffect, useMemo, useState } from "react";
import style from "./index.module.less";
import {
  Asset,
  ManualCreatePortfolio,
} from "../../manualCreatePortfolio/constant";
import { Updater } from "@/views/portfolioManage/fundConfiguration/backTestingAllocation/interface";
import { transferAssetAllocateToAsset } from "../../fundConfiguration/backTestingAllocation/constant";
import { useTransferAssetToFundAsset } from "../../manualCreatePortfolio/hooks";
import { AssetAllocate } from "@/model/portfolioAnalysis";
import { transferCategory } from "../interface";
import { FundsInterface } from "@/model/entities";
import { cellRender } from "../fundAllocation/hooks";
import { categoryTreeMapSelector } from "@/selectors/category";
import { categoryTypeIds } from "../constant";
import { weightRoundTo } from "@/util/formatter";
import { formatPercentage } from "@/util/numberFormatter";
import { useGetIndexInfo } from "../modelAllocation/components/hooks";

export const useTransferAssetAllocateToManualPortfolioType = () => {
  const transfer = useTransferAssetToFundAsset();
  const { categorySource } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation.modelAllocationData
  );
  return useMemoizedFn((assetAllocate: AssetAllocate[]) =>
    map<AssetAllocate, ManualCreatePortfolio>((item) => {
      const categories = flow(
        fastProp("categories"),
        weightRoundTo(1, "weight")
      )(item);
      const error = fastProp("error")(item);
      const assets = flow(
        map((category: transferCategory) => {
          const assets = transfer(
            transferAssetAllocateToAsset(category.assets) as any
          );
          const categoryWeight = equalNaN(category.weight)
            ? 0
            : category.weight;
          return flow(
            map((asset: FundsInterface & Asset) => ({
              ...asset,
              weight: categoryWeight * asset.weight,
              category:
                categorySource === "ASSET_CATEGORY"
                  ? fastProp(category.id)(categoryTypeIds)
                  : category.id,
              categoryWeight,
            })),
            (data) =>
              error ? data : weightRoundTo(categoryWeight, "weight")(data)
          )(assets);
        }),
        flatten
      )(categories);
      return {
        turnoverDate: fastProp("date")(item),
        weights: assets,
        sumWeight: sumBy("weight")(assets),
        errorField: item.error as any,
      };
    })(assetAllocate)
  );
};

export const useManageAllocateData = () => {
  const { assetAllocateData, modelPortfolioAllocateData } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation
  );
  const dispatch = useAppDispatch();
  const updateManualPortfolioAllocateData = useMemoizedFn(
    (updater: (data: ManualCreatePortfolio[]) => ManualCreatePortfolio[]) =>
      dispatch(updateModelConfiguration("modelPortfolioAllocateData", updater))
  );
  const transfer = useTransferAssetAllocateToManualPortfolioType();
  const originFundAsset = useMemo<ManualCreatePortfolio[]>(
    () => transfer(assetAllocateData),
    [assetAllocateData, transfer]
  );
  useEffect(() => {
    if (!isEmpty(originFundAsset) && isEmpty(modelPortfolioAllocateData))
      updateManualPortfolioAllocateData(() => originFundAsset);
  }, [
    modelPortfolioAllocateData,
    originFundAsset,
    updateManualPortfolioAllocateData,
  ]);
  const onUpdateAssets = useMemoizedFn(
    (index: number) => (updater: Updater) =>
      updateManualPortfolioAllocateData(
        update(index, update("weights", updater))
      )
  );
  return {
    onUpdateAssets,
    originFundAsset,
    modelPortfolioAllocateData,
  };
};

export const useGetFundWeightTableColumns = (
  onChange: (updater: (assets: Asset[]) => Asset[]) => any,
  originData: Asset[]
) => {
  const formatMessage = useFormatMessage();
  const { categorySource } = useAppSelector(
    (state) => state.createPortfolio.modelAllocation.modelAllocationData
  );
  const [disabled, setDisabled] = useState(true);
  const onChangeAssetWeight = useMemoizedFn(
    (index: number) => (value: number | null) => {
      onChange(update(index, set("weight", value)));
      setDisabled(false);
    }
  );
  const backToNormal = useMemoizedFn(() => {
    onChange(
      mapIndexed((data: Asset, index: number) => ({
        ...data,
        weight: fastProp("weight")(fastNth(index)(originData)),
      }))
    );
    setDisabled(true);
  });
  const categoryTreeMap = useAppSelector(categoryTreeMapSelector);
  const { factorMap } = useGetIndexInfo();
  return useCreation<TableProps<any>["columns"]>(
    () => [
      {
        key: "category",
        dataIndex: "category",
        width: categorySource === "ASSET_CATEGORY" ? 60 : 120,
        title: formatMessage(
          categorySource === "ASSET_CATEGORY" ? "category" : "index"
        ),
        render: (text, record) =>
          cellRender(
            prop([text, "name"])(
              categorySource === "ASSET_CATEGORY" ? categoryTreeMap : factorMap
            ),
            record
          ),
        align: "center",
        fixed: "left",
        ellipsis: true,
      },
      {
        key: "categoryWeight",
        dataIndex: "categoryWeight",
        width: 80,
        title: formatMessage("categoryWeight"),
        render: (text, record) => cellRender(formatPercentage(text), record),
        align: "center",
        fixed: "left",
      },
      {
        key: "categoryAssetWeight",
        dataIndex: "categoryAssetWeight",
        width: 80,
        title: formatMessage("categoryAssetWeight"),
        render: (text, record) => cellRender(formatPercentage(text), record),
        align: "center",
        fixed: "left",
      },
      {
        key: "name",
        dataIndex: "name",
        title: formatMessage("fundName"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
        fixed: "left",
      },
      {
        key: "code",
        dataIndex: "code",
        title: formatMessage("fundCode"),
        render: (text, record) => (
          <ToFundDetailPage name={text} id={record.fundId} />
        ),
        fixed: "left",
      },
      {
        key: "investType",
        dataIndex: "firstInvestType",
        title: formatMessage("policyType"),
      },
      {
        key: "netValue",
        dataIndex: "netValue",
        width: 150,
        title: formatMessage("latestNetUnitValue"),
        render: (netValue: number) => (
          <div>{factorsFormatter.netValue(netValue)}</div>
        ),
      },
      {
        key: "tradingDay",
        dataIndex: "tradingDay",
        title: formatMessage("netValueStartAndEndDate"),
        render: (_, record) => {
          return (
            <div>
              {fastProp("netValueStartDate")(record) || "--"}{" "}
              {formatMessage("to")} {_ || "--"}
            </div>
          );
        },
      },
      {
        key: "weight",
        dataIndex: "weight",
        width: 250,
        title: (
          <div>
            {formatMessage("positionWeight")}

            <Button
              size="small"
              onClick={backToNormal}
              className={classnames(
                style.EqualWeightButton,
                disabled && style.DisabledColor
              )}
              disabled={disabled}
            >
              {formatMessage("resetDefault")}
            </Button>
          </div>
        ),
        render: (_, record, index) => (
          <SliderAndInput
            value={record.weight}
            onChange={onChangeAssetWeight(index)}
          />
        ),
      },
    ],
    [
      backToNormal,
      formatMessage,
      onChangeAssetWeight,
      categoryTreeMap,
      disabled,
    ]
  );
};
