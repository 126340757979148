import { Card, Space } from "antd";
import React from "react";
import style from "./index.module.less";
import { BaseFundManagerDetailInfoProps } from "../../interface";
import { useFormatMessage } from "@/util/formatMessage";
import LoadingComponent from "@/components/LoadingComponent";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import { useGetRepresentFundYieldData, useManagerStoreItem } from "../hooks";
import { performanceAndYieldOptions } from "../constant";
import { CSI300_ID } from "@/views/compareManage/constant";
import CumulativeChart from "@/components/cumulativeChart";
import { FROM_CREATION } from "@/constant/statisticRange";
import { first, isEmpty, last } from "lodash/fp";
import EmptyContent from "@/components/emptyContent";
import BenchmarkSelect from "@/components/benchmarkSelect";
import CustomStaticRange from "@/views/portfolioManage/portfolioAnalysis/components/customStaticRange";

export default React.memo<BaseFundManagerDetailInfoProps>(({ id }) => {
  const formatMessage = useFormatMessage();
  const [benchmarkId, onChangeBenchmarkId] = useManagerStoreItem(
    id,
    "manageFund.yieldBenchmarkId",
    CSI300_ID
  );
  const [range, setRange] = useManagerStoreItem(
    id,
    "manageFund.yieldRange",
    FROM_CREATION
  );
  const { dates, chartData, initDates } = useGetRepresentFundYieldData(
    id,
    benchmarkId,
    range
  );
  return (
    <LoadingComponent actions={fetchFundAndBenchmarkDailyReturns}>
      <Card className={style.Card} bordered={false}>
        <div className={style.Header}>
          <h3>{formatMessage("representFundYield")}</h3>
          <Space>
            <BenchmarkSelect
              value={benchmarkId}
              onChange={onChangeBenchmarkId}
            />
            <Space>
              <p>{formatMessage("periodOfTime")}: </p>
              <CustomStaticRange
                className={style.StatisticRange}
                value={range}
                onChange={setRange}
                startDate={first(initDates) || ""}
                endDate={last(initDates) || ""}
                staticRange={performanceAndYieldOptions as any}
              />
            </Space>
          </Space>
        </div>
        {isEmpty(dates) ? (
          <EmptyContent
            className={style.EmptyContent}
            message={formatMessage("noData")}
          />
        ) : (
          <CumulativeChart
            dataSource={chartData}
            dates={dates}
            height={470}
            type="cumulative"
          />
        )}
      </Card>
    </LoadingComponent>
  );
});
