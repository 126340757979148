import { map, prop } from "lodash/fp";
import React, { useMemo } from "react";
import { Select, SelectProps } from "antd";
import { LabeledValue } from "antd/lib/select";
import { useAppSelector } from "@/hooks/redux";
import style from "../../index.module.less";
import { fastProp } from "@/util/opt";

export interface ReportDateProps extends SelectProps<string> {
  type: string;
  value: string;
  onChange: (value: string) => void;
}

const ReportDate = ({ type, ...props }: ReportDateProps): JSX.Element => {
  const basiceInfo = useAppSelector(prop("compareManage.fundManager"));
  const dates = useMemo(() => {
    return type === "industryReportDate"
      ? fastProp("yearReportDate")(basiceInfo)
      : fastProp("reportDate")(basiceInfo);
  }, [basiceInfo, type]);

  const options = useMemo(
    () =>
      map<string, LabeledValue>((date) => ({
        label: date,
        value: date,
      }))(dates),
    [dates]
  );
  return <Select className={style.width150} options={options} {...props} />;
};

export default ReportDate;
