import { prop } from "lodash/fp";
import { RootState } from "@/store";
import { DISTRIBUTION_TYPE } from "@/api/compareManage";
import { createSelector } from "@reduxjs/toolkit";

export const baseSelector = createSelector(
  (state: RootState) => state.compareManage.fundCompany,
  (fundCompany) => fundCompany
);

export const distributionsSelector = createSelector(
  baseSelector,
  (
    _: RootState,
    param: {
      section: string;
      distributionType: DISTRIBUTION_TYPE;
    }
  ) => param,
  (fundCompany, { section, distributionType }) =>
    prop(["distributions", distributionType, section])(fundCompany) || []
);
