import {
  AddGroupFundListParm,
  addOptionalGroup,
  updateOptionalGroup,
  groupNameRepeat,
  repeatNameParm,
  addOptionalGroupParm,
  updateGroupFundList,
  deleteOptionalFund,
  deleteOptionalGroup,
  delGroupParm,
  getAddGroupFundList,
  getGroupList,
  moveOptionalFund,
  MoveOptionalFundParm,
  getManagerGroupList,
  addFundManagerDefaultGroup,
  getManagerGroupFundList,
} from "@/api/workbench";
import { fastHas, fastProp } from "@/util/opt";
import {
  GroupFundList,
  optionalFundGroupList,
} from "@/views/myOptional/fundOptional/interface";
import {
  optionalFundManagerGroupList,
  FundManagerFund,
} from "@/views/myOptional/fundManagerOptional/interface";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { omit } from "lodash/fp";
import { FUND } from "@/views/myOptional/constant";

export const fetchOptionalFundGroupList = createAsyncThunk(
  "workbench/optionalFundGroupList",
  async () => {
    const response = await getGroupList();
    return response;
  }
);

export const fetchAddOptionalGroup = createAsyncThunk(
  "workbench/addOptionalGroup",
  async (data: addOptionalGroupParm, { rejectWithValue }) => {
    try {
      const response = await addOptionalGroup(data);
      return response;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const fetchUpdateOptionalGroup = createAsyncThunk(
  "workbench/updateOptionalGroup",
  async (data: addOptionalGroupParm) => {
    const response = await updateOptionalGroup(data);
    return response;
  }
);

export const fetchGroupNameRepeat = createAsyncThunk(
  "workbench/groupNameRepeat",
  async (data: repeatNameParm, { rejectWithValue }) => {
    try {
      const response = await groupNameRepeat(data);
      return response;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const fetchDeleteOptionalGroup = createAsyncThunk(
  "workbench/deleteOptionalGroup",
  async (data: delGroupParm) => {
    const response = await deleteOptionalGroup(data);
    return response;
  }
);

export const fetchAddGroupFundList = createAsyncThunk(
  "workbench/AddGroupFundList",
  async (data: AddGroupFundListParm, { dispatch }) => {
    const response = await getAddGroupFundList(data);
    if (data.type === FUND) {
      dispatch(fetchOptionalFundGroupList());
    } else {
      dispatch(fetchOptionalFundManagerGroupList());
    }
    return response;
  }
);

export const fetchUpdateGroupFundList = createAsyncThunk(
  "workbench/UpdateGroupFundList",
  async (data: AddGroupFundListParm) => {
    const response = await updateGroupFundList(data);
    return response;
  }
);

export const fetchDeleteOptionalFund = createAsyncThunk(
  "workbench/deleteOptionalFund",
  async (data: AddGroupFundListParm, { dispatch }) => {
    const response = await deleteOptionalFund(data);
    if (data.type === FUND) {
      dispatch(fetchOptionalFundGroupList());
    } else {
      dispatch(fetchOptionalFundManagerGroupList());
    }
    return response;
  }
);

export const fetchMoveOptionalFund = createAsyncThunk(
  "workbench/moveOptionalFund",
  async (data: MoveOptionalFundParm, { dispatch }) => {
    const response = await moveOptionalFund(data);
    if (data.type === FUND) {
      dispatch(fetchOptionalFundGroupList());
    } else {
      dispatch(fetchOptionalFundManagerGroupList());
    }
    return response;
  }
);

export const fetchOptionalFundManagerGroupList = createAsyncThunk(
  "workbench/optionalFundManagerGroupList",
  async () => {
    const response = await getManagerGroupList();
    return response;
  }
);

export const fetchAddGroupFundManagerList = createAsyncThunk(
  "workbench/AddGroupFundManagerList",
  async (fundManagerId: string, { dispatch }) => {
    const response = await addFundManagerDefaultGroup(fundManagerId);
    dispatch(fetchOptionalFundManagerGroupList());
    return response;
  }
);

export const fetchFundManagerGroupFundList = createAsyncThunk(
  "workbench/fundManagerGroupFundList",
  async (fundManagerId: string) => {
    const response = await getManagerGroupFundList(fundManagerId);
    return response;
  }
);

type workbenchStore = {
  optionalFundGroupList: optionalFundGroupList[];
  groupFundList: Record<string, GroupFundList[]>;
  optionalFundManagerGroupList: optionalFundManagerGroupList[];
  optionalFundManagerFundList: Record<string, FundManagerFund[]>;
};

const initialState: workbenchStore = {
  optionalFundGroupList: [],
  groupFundList: {},
  optionalFundManagerGroupList: [],
  optionalFundManagerFundList: {},
};

const workbenchSlice = createSlice({
  name: "workbench",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOptionalFundGroupList.fulfilled.type]: (state, action) => {
      state.optionalFundGroupList = action.payload;
    },
    [fetchAddOptionalGroup.fulfilled.type]: (state, action) => {
      const formData = action.meta?.arg;
      if (formData.type === FUND) {
        if (!fastHas("oldGroupName")(formData)) {
          state.optionalFundGroupList.push({
            groupName: fastProp("newGroupName")(formData),
            groupDetail: fastProp("groupDetail")(formData),
            count: 0,
            fundViews: [],
          });
        } else {
          const list = state.optionalFundGroupList;
          state.optionalFundGroupList = list.map((optional) => {
            if (optional.groupName === fastProp("oldGroupName")(formData)) {
              return {
                ...optional,
                groupDetail: fastProp("groupDetail")(formData),
                groupName: fastProp("newGroupName")(formData),
              };
            }
            return optional;
          });
        }
      } else {
        state.optionalFundManagerGroupList.push(action.payload);
      }
    },
    [fetchUpdateOptionalGroup.fulfilled.type]: (state, action) => {
      const formData = action.meta?.arg;
      if (formData?.type === FUND) {
        const list = state.optionalFundGroupList;
        state.optionalFundGroupList = list.map((optional) => {
          if (optional.groupName === fastProp("oldGroupName")(formData)) {
            return {
              ...optional,
              groupDetail: fastProp("groupDetail")(formData),
              groupName: fastProp("newGroupName")(formData),
            };
          }
          return optional;
        });
      } else {
        const list = state.optionalFundManagerGroupList;
        state.optionalFundManagerGroupList = list.map((optional) => {
          if (optional.id === fastProp("groupId")(formData)) {
            return {
              ...optional,
              groupDetail: fastProp("groupDetail")(formData),
              groupName: fastProp("groupName")(formData),
            };
          }
          return optional;
        });
      }
    },
    [fetchDeleteOptionalGroup.fulfilled.type]: (state, action) => {
      const type = action.meta?.arg?.type;
      if (action.meta?.arg) {
        if (type === FUND) {
          state.optionalFundGroupList = state.optionalFundGroupList.filter(
            ({ groupName }) => groupName !== action.meta?.arg.groupId
          );
          state.groupFundList = omit([action.meta?.arg.groupId])(
            state.groupFundList
          ) as Record<string, GroupFundList[]>;
        } else {
          state.optionalFundManagerGroupList =
            state.optionalFundManagerGroupList.filter(
              ({ id }) => id !== action.meta?.arg.groupId
            );
        }
      }
    },
    [fetchOptionalFundManagerGroupList.fulfilled.type]: (state, action) => {
      state.optionalFundManagerGroupList = action.payload;
    },
    [fetchFundManagerGroupFundList.fulfilled.type]: (state, action) => {
      const fundManagerId = action.meta?.arg;
      state.optionalFundManagerFundList[fundManagerId] = action.payload;
    },
    // [fetchDeleteOptionalFund.fulfilled.type]: (state, action) => {
    //   const { groupName, fundIds } = action.meta?.arg;
    //   const fundIdsMap = arrayToMap(fundIds);
    //   const oldList = state.groupFundList[groupName];
    //   const rejectFunds = reject((v: GroupFundList) =>
    //     fastProp(v?.fundId)(fundIdsMap)
    //   )(oldList);
    //   state.groupFundList[groupName] = rejectFunds;
    //   state.optionalFundGroupList = state.optionalFundGroupList.map(
    //     (optional) => {
    //       if (optional.groupName === groupName) {
    //         return {
    //           ...optional,
    //           count: size(rejectFunds),
    //         };
    //       }
    //       return optional;
    //     }
    //   );
    // },
    // [fetchMoveOptionalFund.fulfilled.type]: (state, action) => {
    //   const { oldGroupName, newGroupName, fundIds } = action.meta?.arg;
    //   const fundIdsMap = arrayToMap(fundIds);
    //   const oldList = state.groupFundList[oldGroupName];
    //   const newListMap = normalize("fundId")(state.groupFundList[newGroupName]);
    //   const rejectFunds = reject(({ fundId }: GroupFundList) =>
    //     fastProp(fundId)(fundIdsMap)
    //   )(oldList);
    //   state.groupFundList[oldGroupName] = rejectFunds;
    //   const filterFunds = flow(
    //     filter(
    //       ({ fundId }: GroupFundList) =>
    //         fastProp(fundId)(fundIdsMap) && !fastHas(fundId)(newListMap)
    //     ),
    //     (data: GroupFundList[]) =>
    //       state.groupFundList[newGroupName]?.concat(data)
    //   )(oldList);
    //   state.groupFundList[newGroupName] = filterFunds;
    //   state.optionalFundGroupList = state.optionalFundGroupList.map(
    //     (optional) => {
    //       if (optional.groupName === oldGroupName) {
    //         return {
    //           ...optional,
    //           count: size(rejectFunds),
    //         };
    //       }
    //       if (optional.groupName === newGroupName) {
    //         return {
    //           ...optional,
    //           count: size(filterFunds),
    //         };
    //       }
    //       return optional;
    //     }
    //   );
    // },
  },
});

export default workbenchSlice.reducer;
