import { DailyReturns } from "@/api/dailyReturns";
import { fundIdMapSelector } from "@/selectors/fund";
import { fetchFundAndBenchmarkDailyReturns } from "@/store/dailyReturns";
import { fastProp } from "@/util/opt";
import { normalizeDailyReturns } from "@/util/transformer";
import { prop } from "lodash/fp";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "./redux";

export const useGetFundDailyReturn = (fundId: string) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (fundId) {
      dispatch(fetchFundAndBenchmarkDailyReturns(fundId));
    }
  }, [dispatch, fundId]);
  const fundIdsMap = useAppSelector(fundIdMapSelector);
  const dailyReturns = useAppSelector((state) => state.dailyReturns);
  const fundDailyReturns = useMemo<DailyReturns>(
    () => fastProp(fundId)(dailyReturns),
    [dailyReturns, fundId]
  );
  const fundName = prop(`${fundId}.name`)(fundIdsMap);
  return useMemo(
    () => ({
      ...normalizeDailyReturns(
        fundDailyReturns?.dates,
        fundDailyReturns?.dailyReturns
      ),
      name: fundName,
    }),
    [fundName, fundDailyReturns?.dailyReturns, fundDailyReturns?.dates]
  );
};
