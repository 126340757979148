import cn from "classnames";
import formItemLabelStyle from "./FormItemLabelMessage.module.less";

type FormItemLabelProps = {
  message: string;
  className?: string;
  style?: { [key: string]: unknown };
};
const FormItemLabelMessage = ({
  message,
  className,
  style,
}: FormItemLabelProps): JSX.Element => {
  return (
    <span
      className={cn(formItemLabelStyle.FormItemLabel, className)}
      style={style}
    >
      {message}
    </span>
  );
};
export default FormItemLabelMessage;
