import { useFormatMessage } from "@/util/formatMessage";
import { useMemoizedFn, useUpdateEffect } from "ahooks";
import { Modal, Form, Input } from "antd";
import ShareRangeSelect from "@/components/portfolioCompoents/shareRangeSelect";
import TextArea from "antd/lib/input/TextArea";
import LoadingComponent from "@/components/LoadingComponent";
import { editBackTestingScheme } from "@/store/portfolioList";
import { forwardRef, useImperativeHandle, useState } from "react";
import style from "./index.module.less";
import { useForm } from "antd/lib/form/Form";
import { portfolioNameReg, nameUniqValidator } from "@/util/formRules";
import { InitSimpleDataType } from "@/components/portfolioCompoents/hook";
import { isEmpty } from "lodash/fp";
import { schemeConfig } from "./constant";

const formLabelCol = {
  span: 4,
};

const { Item } = Form;

export type SaveSchemeFormType = Pick<
  InitSimpleDataType,
  "name" | "shareRange" | "description" | "id"
>;

export const getDefaultFormData = (): SaveSchemeFormType => {
  return {
    name: "",
    shareRange: {
      shareType: "",
      groupId: "",
    },
    description: "",
  };
};

export type SaveSchemeProps = {
  onOk: (value: SaveSchemeFormType) => Promise<any>;
  title?: string;
  id?: string;
  schemeType?: string;
};
export type ManageSchemeModalRef = {
  openModal: (initFormValues?: SaveSchemeFormType) => any;
};

export default forwardRef<ManageSchemeModalRef, SaveSchemeProps>(
  ({ onOk, title, id, schemeType }, ref) => {
    const [initFormValue, setInitFormValue] = useState<SaveSchemeFormType>(
      getDefaultFormData()
    );
    const [visible, setVisible] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [form] = useForm();
    useUpdateEffect(() => {
      form.setFieldsValue(initFormValue);
    }, [initFormValue]);
    const openModal = useMemoizedFn((initFormValues?: SaveSchemeFormType) => {
      setVisible(true);
      setButtonDisabled(true);
      if (initFormValues) {
        form.setFieldsValue(initFormValues);
        setInitFormValue(initFormValues);
        setButtonDisabled(false);
      }
    });
    const closeModal = useMemoizedFn(() => {
      setVisible(false);
      form.setFieldsValue(getDefaultFormData());
    });
    const formatMessage = useFormatMessage();
    const handleOnOk = useMemoizedFn(() => {
      setButtonDisabled(true);
      form
        .validateFields()
        .then((data: SaveSchemeFormType) => {
          setVisible(false);
          setButtonDisabled(false);
          onOk({
            ...data,
            id: schemeType && schemeConfig.isCover(schemeType) ? id : undefined,
          }).then(() => {
            form.resetFields();
          });
        })
        .catch(() => {
          setButtonDisabled(true);
          return Promise.reject();
        });
    });
    const onValuesChange = useMemoizedFn(
      (value, allValue: SaveSchemeFormType) => {
        setButtonDisabled(
          isEmpty(allValue.name) || isEmpty(allValue.shareRange)
        );
      }
    );
    useImperativeHandle(ref, () => ({
      openModal: openModal,
    }));
    return (
      <Modal
        visible={visible}
        title={title}
        width={600}
        className={style.SaveSchemeModal}
        onOk={handleOnOk}
        onCancel={closeModal}
        okButtonProps={{
          disabled: buttonDisabled,
        }}
      >
        <LoadingComponent actions={[editBackTestingScheme]}>
          <Form
            form={form}
            labelAlign="right"
            labelCol={formLabelCol}
            onValuesChange={onValuesChange}
          >
            <Item
              name="name"
              label={formatMessage("schemeName")}
              rules={[
                {
                  required: true,
                  message: formatMessage("SchemeNameEmpty"),
                },
                {
                  pattern: portfolioNameReg,
                  message: formatMessage("schemeRuleErrorTip"),
                },
                {
                  max: 30,
                  message: formatMessage("schemeNameMaxTip"),
                },
                nameUniqValidator(initFormValue.name),
              ]}
            >
              <Input placeholder={formatMessage("enterName")} />
            </Item>

            <Item
              name="shareRange"
              label={formatMessage("VisibleRange")}
              rules={[
                {
                  required: true,
                  message: formatMessage("VisibleRangeIsNull"),
                },
              ]}
            >
              <ShareRangeSelect />
            </Item>

            <Item name="description" label={formatMessage("schemeDescription")}>
              <TextArea
                placeholder={formatMessage("pleaseEnter")}
                autoSize={{ maxRows: 8, minRows: 4 }}
                showCount
                maxLength={300}
              />
            </Item>
          </Form>
        </LoadingComponent>
      </Modal>
    );
  }
);
