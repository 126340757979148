import type { TableProps } from "./selectFundDialog";
import ToFundDetailPage from "../navigateToPage/toFundDetailPage";
import { FormatMessageFunc } from "@/util/formatMessage";
import { FundsInterface } from "@/model/entities";
import { first, flow, forEach, map, omit, pick, prop, size } from "lodash/fp";
import { fastProp } from "@/util/opt";
import { InitSimpleDataType } from "./hook";
import { Table } from "antd";
import { ReactNode } from "react";

export const PRIVATE = "PRIVATE";
export const GROUP = "GROUP";
export const PUBLIC = "PUBLIC";
export const AllMarket = "AllMarket";
export const PreferredPool = "preferredPool";
export const OptionalPool = "optionalPool";
export const MaxSelectIds = 100;

export const CUSTOM = "custom";
export const ADD_CUSTOM = "ADD_CUSTOM";
export const CREATE_CUSTOM = "CREATE_CUSTOM";
export const COMMON_USE = "COMMON_USE";

export const CASH = "CASH";
export const REPLACEFUND = "replaceFund";
export const ALTERNATEFUND = "alternativeFund";
export const CASHHOLD = "cash";
export const INTRAGROUPALLCATION = "allocateWithinGroup";

export const ReplaceRules = [
  {
    id: CASHHOLD,
    messageKey: "CASH",
  },
  {
    id: INTRAGROUPALLCATION,
    messageKey: "IntraGroupAllcation",
  },
  {
    id: REPLACEFUND,
    messageKey: "replaceFund",
  },
  {
    id: ALTERNATEFUND,
    messageKey: "alternateFund",
  },
];

export const getFundColumns = ({
  nameWidth = 200,
  codeWidth = 150,
  formatMessage,
  closeModal,
}: {
  formatMessage: FormatMessageFunc;
  nameWidth?: number;
  codeWidth?: number;
  onClick?: () => void;
  closeModal: () => any;
}): TableProps["columns"] => [
  {
    align: "left",
    dataKey: "name",
    key: "name",
    title: formatMessage("fundName"),
    width: nameWidth,
    resizable: true,
    cellRenderer: ({ rowData }) => (
      <ToFundDetailPage
        beforeJump={closeModal}
        name={rowData.name}
        id={rowData.fundId}
      />
    ),
  },
  {
    align: "left",
    dataKey: "code",
    key: "code",
    title: formatMessage("fundCode"),
    width: codeWidth,
    resizable: true,
    cellRenderer: ({ rowData }) => (
      <ToFundDetailPage
        beforeJump={closeModal}
        name={rowData.code}
        id={rowData.fundId}
      />
    ),
  },
];

export const getMaxSelectionData =
  (shouldPick: (id: string) => boolean, maxSelectionSize: number) =>
  (allFund: FundsInterface[]) => {
    const pickedFund: FundsInterface[] = [];
    forEach((item: FundsInterface) => {
      const fundId = fastProp("fundId")(item);
      if (size(pickedFund) >= maxSelectionSize) return false;
      if (shouldPick(fundId)) pickedFund.push(item);
      return true;
    })(allFund);
    return pickedFund;
  };

export const formatPortfolioValue = (values: InitSimpleDataType) => ({
  ...fastProp("benchmark")(values),
  ...omit(["benchmark"])(values),
});

export const assembleInitSimpleData = (values: { [key: string]: any }) => {
  const benchmark = pick(["benchmarkId", "benchmarkType"])(values);
  return {
    benchmark,
    ...values,
  };
};

const { Summary } = Table;
export interface SummaryNode {
  align?: "left" | "right" | "center";
  index: number;
  colSpan?: number;
  render: ReactNode | string;
}
export const getTableSummaryNode = (arr: SummaryNode[]) => (
  <Summary fixed>
    <Summary.Row>
      {map<SummaryNode, ReactNode>(({ align, index, colSpan, render }) => (
        <Summary.Cell key={index} align={align} index={index} colSpan={colSpan}>
          {render}
        </Summary.Cell>
      ))(arr)}
    </Summary.Row>
  </Summary>
);

export const getCategoryTypeId = ({
  categoryTreeMap,
  categoryId,
}: {
  categoryId: string;
  categoryTreeMap: Record<string, any>;
}) => {
  return flow(prop([categoryId, "path"]), first)(categoryTreeMap);
};
