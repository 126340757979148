import { useFormatMessage } from "@/util/formatMessage";
import { Button, message, Space, Table, Upload, UploadProps } from "antd";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import uploadIcon from "@/assets/customerAnalysisIcons/uploadIcon.png";
import style from "./index.module.less";
import { useMemoizedFn } from "ahooks";
import { drop, first, isEmpty } from "lodash/fp";
import { CloudDownloadOutlined } from "@ant-design/icons";
import Progress from "./progress";
import { getColumns, transformUploadFile } from "../constant";
import { subAccountNameProp } from "@/model/customer";

const { Dragger } = Upload;

export interface AssetInfoProp {
  tableData: subAccountNameProp[];
  setTableData: (value: Record<string, any>[]) => void;
  disabled?: boolean;
  isAddAsset?: boolean;
  totalAccountId?: string;
  customerName?: string;
}

export default React.memo<AssetInfoProp>(
  ({ tableData, setTableData, disabled }) => {
    const formatMessage = useFormatMessage();
    const [fileName, setFileName] = useState("");
    const [status, setStatus] = useState<
      "active" | "success" | "normal" | "exception"
    >("active");
    const [percent, setPercent] = useState(0);
    const [reUpload, setReUpload] = useState(false);
    const [fileInfo, setFileInfo] = useState({});

    const tableHeader = getColumns(formatMessage);

    const uploadFilesChange = useMemoizedFn((file: any) => {
      if (!/\.(xls|xlsx)$/.test(file?.file?.name.toLowerCase() as string)) {
        message.error(formatMessage("fileFormatErrorTip"));
        return false;
      }
      // 通过FileReader对象读取文件
      const fileReader = new FileReader();
      // 以二进制方式打开文件
      // fileReader.readAsBinaryString(file.file);
      setPercent(50);
      fileReader.onload = (event) => {
        try {
          const { result } = event.target || {};
          // 以二进制流方式读取得到整份excel表格对象
          const workbook = XLSX.read(result, {
            type: "binary",
            cellDates: true,
            dateNF: "YYYY-MM-DD",
            codepage: 65001,
          });
          const wsname = workbook.SheetNames[0];
          const sheets = workbook.Sheets;
          const ws = XLSX.utils.sheet_to_json(sheets[wsname], {
            header: "A",
            raw: false,
          });
          // 最终获取到并且格式化后的 json 数据
          const AllAata = drop(1)(ws);
          const dataSource = drop(1)(AllAata);
          const data = transformUploadFile(dataSource as any);
          setPercent(() => {
            setTableData(data as any);
            return 100;
          });

          setStatus("active");
        } catch (e) {
          message.error(formatMessage("fileFormatErrorTip"));
          setPercent(0);
          setStatus("active");
          setReUpload(false);
        }
      };

      fileReader.readAsBinaryString(file.file);
    });

    const onReUpload = useMemoizedFn(() => {
      setPercent(0);
      uploadFilesChange(fileInfo);
    });

    const deleteFile = useMemoizedFn(() => {
      setPercent(0);
      setFileInfo({});
    });

    // const onExport = useMemoizedFn(() => {
    //   exportXlsx({
    //     title: "客户组合模版",
    //     dataSource: [],
    //     columns: exportDataColumn,
    //   })("xlsx")("客户组合模版");
    // });
    // useExportTable({ fileName: formatMessage("customerPortfolioTemplate") });

    const props: UploadProps = {
      name: "file",
      accept: ".xls,.xlsx",
      maxCount: 1,
      showUploadList: false,
      onChange(info) {
        const { status } = info.file;
        setFileName(info.file.name);
        setStatus(status as any);
        setPercent(0);
        setReUpload(isEmpty(tableData) ? false : true);
        uploadFilesChange(info);
        setFileInfo(info);
      },
      onDrop(e) {
        const fileInfo = first(e.dataTransfer.files || []);
        if (!/\.(xls|xlsx)$/.test(fileInfo?.name.toLowerCase() as string)) {
          message.error(formatMessage("fileFormatErrorTip"));
          return false;
        }
      },
      beforeUpload() {
        return false;
      },
    };

    return (
      <div className={style.AssetContent}>
        {!isEmpty(tableData) ? (
          <div className={style.UploadContent}>
            <div>
              <div className={style.OperatePart}>
                <Space size={30}>
                  <Upload {...props} disabled={disabled}>
                    <Button
                      icon={<CloudDownloadOutlined />}
                      type="primary"
                      ghost
                      disabled={disabled}
                    >
                      {formatMessage("reUploadCom")}
                    </Button>
                  </Upload>
                  <span>{formatMessage("supportFormats")}</span>
                  {percent > 0 && percent < 100 && (
                    <div className={style.ReUploadProgress}>
                      <Progress
                        name={fileName}
                        status={status}
                        percent={percent}
                        reUpload={onReUpload}
                        deleteFile={deleteFile}
                      />
                    </div>
                  )}
                </Space>
              </div>
              <Table
                columns={tableHeader as any}
                dataSource={tableData}
                style={{ marginTop: "20px" }}
                pagination={false}
                scroll={{ y: 240 }}
              />
            </div>
          </div>
        ) : (
          <>
            {!reUpload && percent > 0 && percent < 100 ? (
              <div className={style.UploadContent}>
                <div className={style.UploadProgress}>
                  <Progress
                    name={fileName}
                    status={status}
                    percent={percent}
                    reUpload={onReUpload}
                    deleteFile={deleteFile}
                  />
                </div>
              </div>
            ) : (
              <Dragger {...props}>
                <>
                  <p className="ant-upload-drag-icon">
                    <img src={uploadIcon} />
                  </p>
                  <p className={style.AntUploadText}>
                    {formatMessage("uploadFileInfo")}
                  </p>
                  <p className={style.AntUploadText}>
                    {formatMessage("supportFormats")}
                  </p>
                </>
              </Dragger>
            )}
          </>
        )}
      </div>
    );
  }
);
