// import { useFormatMessage } from "@/util/formatMessage";
import { Space } from "antd";
import React from "react";
import cn from "classnames";
import { useFormatMessage } from "@/util/formatMessage";
import { formatNil } from "@/util/numberFormatter";
import style from "./oneLevelName.module.less";

type SimilarRankProps = {
  rank?: number;
  count?: number;
  className?: string;
};

export default React.memo(({ rank, count, className }: SimilarRankProps) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={cn(style.SimilarRankContainer, className)}>
      <Space size={12}>
        <span className={style.SimilarRankName}>
          {formatMessage("similarRankings")}：
        </span>
        <span className={style.SimilarCount}>
          <span className={style.SimilarRankName}>{formatNil(rank)}/</span>
          {formatNil(count)}
        </span>
      </Space>
    </div>
  );
});
