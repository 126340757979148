import React, { lazy, ReactNode, Suspense } from "react";
import { RouteObject } from "react-router";
import { flow, map, omit } from "lodash/fp";
import { Skeleton } from "antd";
import Manage from "@/views/manage";
import Login from "@/views/login";
import { flattenAllLeaf } from "@/util/opt/tree";
import { leftMenu1 as leftMenu } from "./menu";
import HomeRouteElement from "./homeRouteElement";

const lazyLoad = (
  Component: React.LazyExoticComponent<React.FC>
): ReactNode => (
  <Suspense fallback={<Skeleton />}>
    <Component />
  </Suspense>
);

const pathComponentMap: Record<string, ReactNode> = {
  "/manage/dataBoard": lazyLoad(lazy(() => import("@/views/dataBoard"))),
  "/manage/targetFund": lazyLoad(lazy(() => import("@/views/targetFund"))),
  "/manage/newsSearch": lazyLoad(lazy(() => import("@/views/news"))),
  "/manage/stockBackCheck": lazyLoad(
    lazy(() => import("@/views/stockBackCheck"))
  ),
  "/manage/factorFund": lazyLoad(lazy(() => import("@/views/factorFund"))),
  "/manage/replaceFund": lazyLoad(lazy(() => import("@/views/replaceFund"))),
  "/manage/compareManage": lazyLoad(
    lazy(() => import("@/views/compareManage"))
  ),
  "/manage/fundDetail": lazyLoad(lazy(() => import("@/views/fundDetail"))),
  "/manage/fundManagerDetail": lazyLoad(
    lazy(() => import("@/views/fundManagerDetail"))
  ),
  "/manage/fundCompanyDetail": lazyLoad(
    lazy(() => import("@/views/fundCompanyDetail"))
  ),
  "/manage/portfolioList": lazyLoad(
    lazy(() => import("@/views/portfolioManage/portfolioList"))
  ),
  "/manage/competition": lazyLoad(
    lazy(() => import("@/views/competition/competitionHome"))
  ),
  "/manage/preferredFund": lazyLoad(
    lazy(() => import("@/views/preferredFund"))
  ),
  "/manage/myOptional": lazyLoad(lazy(() => import("@/views/myOptional"))),
  "/manage/customerList": lazyLoad(
    lazy(() => import("@/views/customerManage/index"))
  ),
  "/manage/aum": lazyLoad(lazy(() => import("@/views/aum"))),
  "/manage/etfFilter": lazyLoad(lazy(() => import("@/views/etfFilter"))),
};

const router: RouteObject[] = [
  {
    path: "/",
    element: <HomeRouteElement />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Login />,
  },
  {
    path: "/manage",
    element: <Manage />,
    children: flow(
      flattenAllLeaf,
      map((v: RouteObject) => ({
        ...(v?.index ? omit("path")(v) : v),
        element: pathComponentMap[v?.path as string],
      }))
    )(leftMenu),
  },
  {
    path: "/fund-evaluate-report/:id",
    element: lazyLoad(lazy(() => import("@/views/fundEvaluate"))),
  },
];

export default router;
