import React, { useCallback } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import style from "../../index.module.less";
import { Space } from "antd";
import CustomStaticRange from "../../components/customStaticRange";
import { omit } from "lodash/fp";
import { FROM_THIS_YEAR, RangeInterface } from "@/constant/statisticRange";
import { portfolioAnalysisStatisticRange } from "@/constant/portfolioAnalysis";
import BenchmarkSelect from "@/components/benchmarkSelect";

export const portfolioStaticRange = omit([FROM_THIS_YEAR])(
  portfolioAnalysisStatisticRange
);

export default React.memo<{
  startDate: string;
  endDate: string;
  activeRange: string;
  legendIds: string;
  selectedIncomeType: string;
  className: string;
  onChange: (key: string) => (value: any) => any;
  staticRange?: Record<string, RangeInterface>;
}>(
  ({
    activeRange,
    legendIds,
    selectedIncomeType,
    startDate,
    endDate,
    onChange,
    className,
    staticRange = portfolioStaticRange,
  }) => {
    const formatMessage = useFormatMessage();
    const updateHandler = useCallback(
      (value) => onChange && onChange("legendIds")(value),
      [onChange]
    );
    return (
      <div className={className}>
        <Space direction="vertical" className={style.fullWidth}>
          <div className={style.incomeSelectPart}>
            <Space>
              <span>{formatMessage("statisticalnterval")}</span>
              <CustomStaticRange
                className={style.StatisticRange}
                value={activeRange}
                onChange={onChange("activeRange")}
                startDate={startDate}
                endDate={endDate}
                staticRange={staticRange as Record<string, RangeInterface>}
              />
              {selectedIncomeType && selectedIncomeType === "cumulativeIncome" && (
                <div>
                  <Space>
                    <span>{formatMessage("legend")}</span>
                    <BenchmarkSelect
                      value={legendIds}
                      onChange={updateHandler}
                      placement="bottomRight"
                    />
                  </Space>
                </div>
              )}
            </Space>
          </div>
        </Space>
      </div>
    );
  }
);
