import { Card, Space } from "antd";
import React from "react";
import defaultCompetitipnImg from "@/assets/defaultCompetition.png";
import { CompetitionDetail } from "@/model/competition";
import { fastProp } from "@/util/opt";
import { useFormatMessage } from "@/util/formatMessage";
import style from "../index.module.less";

const CompetitionHead = ({
  competitionDetail,
}: {
  competitionDetail: CompetitionDetail;
}): JSX.Element => {
  const formatMessage = useFormatMessage();
  return (
    <Card size="small">
      <div className={style.BacicHead}>
        <div className={style.ImgPart}>
          <img
            src={
              fastProp("bannerImage")(competitionDetail) ||
              defaultCompetitipnImg
            }
            className={style.CompetitipnImg}
          />
        </div>
        <div className={style.BacicHeadRight}>
          <Space direction="vertical" className={style.FullWidth}>
            <span className={style.CompetitionName}>
              {formatMessage("competitionArea")}：
              {fastProp("name")(competitionDetail)}
            </span>
            <span className={style.EntryRequire}>
              {formatMessage("entryRequirements")}：
              {fastProp("regulation")(competitionDetail)}
            </span>
          </Space>
        </div>
      </div>
    </Card>
  );
};

export default React.memo(CompetitionHead);
