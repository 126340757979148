import {
  companyDailyReturnParm,
  companyManageFundParm,
  getCompanyDailyReturn,
  getCompanyInfo,
  getCompanyManageFund,
  getCompanyScaleRank,
  getCompanyScaleTrend,
  getRepresentativeFund,
} from "@/api/fundCompanyDetail";
import { fundCompanyDetailType } from "@/model/fundCompanyDetail";
import { avoidMultiRequestActionThunk } from "@/util/getReduxState";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { set, sortBy } from "lodash/fp";

export const fetchGetCompanyInfo = avoidMultiRequestActionThunk<string>(
  (id) => `fundCompanyDetail.${id}.companyInfo`,
  createAsyncThunk("fundCompanyDetail/companyInfo", async (id) => {
    const response = await getCompanyInfo(id);
    return response;
  })
);

export const fetchGetCompanyDailyReturn =
  avoidMultiRequestActionThunk<companyDailyReturnParm>(
    (parm) =>
      `fundCompanyDetail.${parm.id}.companyDailyReturn.${parm.fundType}`,
    createAsyncThunk("fundCompanyDetail/companyDailyReturn", async (parm) => {
      const response = await getCompanyDailyReturn(parm);
      return response;
    })
  );

export const fetchGetCompanyScaleTrend = avoidMultiRequestActionThunk<string>(
  (id) => `fundCompanyDetail.${id}.companyScaleTrend`,
  createAsyncThunk(
    "fundCompanyDetail/companyScaleTrend",
    async (id: string) => {
      const response = await getCompanyScaleTrend(id);
      return response;
    }
  )
);

export const fetchGetCompanyScaleRank = avoidMultiRequestActionThunk<string>(
  (id) => `fundCompanyDetail.${id}.companyScaleRank`,
  createAsyncThunk("fundCompanyDetail/companyScaleRank", async (id) => {
    const response = await getCompanyScaleRank(id);
    return response;
  })
);

export const fetchGetCompanyManageFund = avoidMultiRequestActionThunk(
  (parm) =>
    `fundCompanyDetail.${parm.id}.companyManageFund.${parm.fundType}.${parm.section}`,
  createAsyncThunk(
    "fundCompanyDetail/companyManageFund",
    async (parm: companyManageFundParm) => {
      const response = await getCompanyManageFund(parm);
      return response;
    }
  )
);

export const fetchGetRepresentativeFund = avoidMultiRequestActionThunk<string>(
  (id) => `fundCompanyDetail.${id}.representativeFund`,
  createAsyncThunk("fundCompanyDetail/representativeFund", async (id) => {
    const response = await getRepresentativeFund(id);
    return response;
  })
);

type InitStateType = {
  [key: string]: fundCompanyDetailType | any;
};

const initialState: InitStateType = {};

const fundCompanyDetailSlice = createSlice({
  name: "fundCompanyDetail",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGetCompanyInfo.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = set(
        "companyInfo",
        action.payload
      )(state[action.meta?.arg]);
    },
    [fetchGetCompanyDailyReturn.fulfilled.type]: (state, action) => {
      const { id, fundType } = action.meta?.arg;
      state[id] = set(
        ["companyDailyReturn", fundType],
        action.payload
      )(state[id]);
    },
    [fetchGetCompanyScaleTrend.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = set(
        "companyScaleTrend",
        action.payload
      )(state[action.meta?.arg]);
    },
    [fetchGetCompanyScaleRank.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = set(
        "companyScaleRank",
        sortBy("date")(action.payload)
      )(state[action.meta?.arg]);
    },
    [fetchGetCompanyManageFund.fulfilled.type]: (state, action) => {
      const { id, fundType, section } = action.meta?.arg;
      state[id] = set(
        ["companyManageFund", fundType, section],
        action.payload
      )(state[id]);
    },
    [fetchGetRepresentativeFund.fulfilled.type]: (state, action) => {
      state[action.meta?.arg] = set(
        "representativeFund",
        action.payload
      )(state[action.meta?.arg]);
    },
  },
});

export default fundCompanyDetailSlice.reducer;
