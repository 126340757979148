import React, { useCallback } from "react";
import { EChartsOption } from "echarts-for-react";
import { orderBy, reduce } from "lodash/fp";
import { TooltipComponentOption } from "echarts";
import { useCreation } from "ahooks";

import { useFormatMessage } from "@/util/formatMessage";
import EchartsWrapper from "@/echarts/echartsWrapper";
import { mapIndexed } from "@/util/opt";
import { formatPercentage } from "@/util/numberFormatter";

import style from "./index.module.less";
import { colors } from "../helper/colors";
import { AssertTypePieChartDataType } from "./type";

export default React.memo<AssertTypePieChartDataType>(
  ({
    pieChartData,
    title = "",
    selectedDate = "",
    formatter,
    dataType = "weight",
    width = "100%",
    height = 300,
    showLegend = false,
    className,
  }) => {
    const formatMessage = useFormatMessage();
    pieChartData = orderBy(
      "value",
      "desc"
    )(pieChartData) as unknown as AssertTypePieChartDataType["pieChartData"];

    // 如果传入是占比且占比总和不为1，则需要利用总占比乘以比例用来计算文本的显示面积
    const displayArea = useCreation(() => {
      if (dataType === "weight") {
        return reduce(
          (prev: number, curr: { name: string; value: number }) =>
            prev + curr.value
        )(0)(pieChartData);
      } else return 1;
    }, [dataType, pieChartData]);

    const isShowLabelLine = useCallback(
      (value: number) => value < displayArea * 0.05,
      [displayArea]
    ); // 小于5%则使用引导线，否则在饼图上显示百分比

    const getLabelTypeOpt = useCallback(
      (value: number) => {
        if (isShowLabelLine(value))
          return {
            label: {
              show: Boolean(value), // 如果为0则不做展示
              position: "outside",
              formatter: (param: Record<string, any>) => {
                if (dataType === "weight") return formatPercentage(param.value);
                return `${param.percent}%`;
              },
            },
            // labelLine: { show: true },
            avoidLabelOverlap: true,
            labelLine: { show: true, length2: 4 },
          };
        return {
          label: {
            show: true,
            position: "inside",
            color: "#FFF",
            formatter: (param: Record<string, any>) => {
              if (dataType === "weight") return formatPercentage(param.value);
              return `${param.percent}%`;
            },
          },
          labelLine: {
            show: false,
            opacity: 0,
          },
        };
      },
      [dataType, isShowLabelLine]
    );
    const handleOptions: EChartsOption = useCreation(
      () => ({
        grid: {
          left: 0,
          right: 0,
          top: 50,
          bottom: 0,
          containLabel: true,
        },
        tooltip: {
          trigger: "item" as TooltipComponentOption["trigger"],
        },
        legend: {
          top: 10,
          left: 10,
          show: showLegend,
          orient: "vertical",
        },
        labelLine: {
          show: false,
        },
        // series两个饼图叠加； 第一个用于显示内侧标题，第二个正常用于显示数据
        series: [
          {
            type: "pie",
            radius: ["70px", "120px"],
            color: mapIndexed((_: any, index: number) => colors?.[index])(
              pieChartData
            ),
            label: {
              show: true,
              position: "center",
              fontSize: 16,
              formatter: title,
            },
            data: mapIndexed((item: Record<string, any>, index: number) => ({
              ...item,
              itemStyle: {
                color: item.color || colors[index],
              },
            }))(pieChartData),
          },
          {
            type: "pie",
            radius: ["70px", "120px"],
            emphasis: {
              labelLine: {
                show: false,
              },
            },
            data: mapIndexed(
              (
                v: { name: string; value: number; color?: string },
                index: number
              ) => ({
                ...v,
                ...getLabelTypeOpt(v.value),
                itemStyle: {
                  color: v.color || colors[index],
                },
              })
            )(pieChartData),
            tooltip: {
              formatter:
                formatter ||
                ((param: Record<string, any>) => {
                  return `<div>
                    <div style="color:${param?.color}">${param?.name}:</div>
                    <div>${formatMessage("Weight")}:${formatPercentage(
                    param?.value
                  )}</div>
                  </div>`;
                }),
            },
          },
        ],
      }),
      [formatMessage, formatter, getLabelTypeOpt, pieChartData, title]
    );

    return (
      <div className={style.pieChartWrapper}>
        <EchartsWrapper
          width={width}
          height={height}
          options={handleOptions}
          showDataZoom={false}
          className={className}
        />
        {selectedDate && (
          <div className={style.selectedDate}>{selectedDate}</div>
        )}
      </div>
    );
  }
);
