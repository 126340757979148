import React, { useEffect } from "react";
import style from "./index.module.less";
import fundCompanyDefaultIcon from "@/assets/fundCompanyDefaultIcon.svg";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { companyInfoSelector } from "../selector";
import { fetchGetCompanyInfo } from "@/store/fundCompanyDetail";
import { Rate, Space } from "antd";
import { useFormatMessage } from "@/util/formatMessage";
import CompanyBasicRank from "./companyBasicRank";
import { companyRanks, companyRanksItem } from "../constant";
import { identity, map } from "lodash/fp";
import { fastProp } from "@/util/opt";
import { fixedNumber } from "@/util/numberFormatter";
import { useMemoizedFn } from "ahooks";
import compareIconActive from "@/assets/compareIconActive.png";
import compareIcon from "@/assets/compareIcon.png";
import cn from "classnames";
import LoadingComponent from "@/components/LoadingComponent";

type BasicInfoProps = {
  id: string;
  onOpenAnchorModal?: () => void;
  addSelectIds: (id: string) => void;
  validateIsChecked: (id: string) => boolean;
};

export default React.memo<BasicInfoProps>(
  ({ id, onOpenAnchorModal, addSelectIds, validateIsChecked }) => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();
    useEffect(() => {
      dispatch(fetchGetCompanyInfo(id));
    }, [id, dispatch]);
    const companyInfo = useAppSelector((state) =>
      companyInfoSelector(state, id)
    );
    const { name, registeredScale, url, companyCount, companyRating } =
      companyInfo || {};
    const compareSelectedFund = useMemoizedFn(() => {
      if (!validateIsChecked(id)) {
        addSelectIds(id);
        onOpenAnchorModal && onOpenAnchorModal();
      }
    });
    return (
      <LoadingComponent actions={fetchGetCompanyInfo}>
        <div className={style.BasicInfo}>
          <div className={style.CompanyBasicInfo}>
            <img src={fundCompanyDefaultIcon} />
            <Space direction="vertical">
              <div>
                <h2>{name}</h2>
                <Rate disabled value={companyRating} />
              </div>
              <span className={style.RegisteredScale}>
                {formatMessage("registeredScale", {
                  scale: fixedNumber(registeredScale as unknown as number),
                })}
              </span>
              <a
                className={cn(style.RegisteredScale, !url && style.NotAllowed)}
                href={url && "http://" + url}
                target={url && "_blank"}
              >
                {formatMessage("website", { url })}
              </a>
            </Space>
          </div>
          <div className={style.CompanyBasicRank}>
            {map(
              ({
                id,
                messageKey,
                scale,
                rank,
                format = identity,
                unitKey,
              }: companyRanksItem) => {
                const scaleNum = fastProp(scale)(companyInfo);
                const scaleFormat = unitKey
                  ? `${scaleNum ? scaleNum + formatMessage(unitKey) : "--"}`
                  : format(scaleNum);
                return (
                  <CompanyBasicRank
                    key={id}
                    messageKey={messageKey}
                    scale={scaleFormat}
                    rank={fastProp(rank)(companyInfo)}
                    companyCount={companyCount}
                  />
                );
              }
            )(companyRanks)}
          </div>
          <div className={style.CompareSelected}>
            <Space>
              <img
                className={style.Icons}
                src={validateIsChecked(id) ? compareIcon : compareIconActive}
                onClick={compareSelectedFund}
              />
            </Space>
          </div>
        </div>
      </LoadingComponent>
    );
  }
);
