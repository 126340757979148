import { LineChartOpts } from "@/echarts/lineChart";
import { getTooltipItem } from "@/util/chart";
import { BlueColor, YellowColor } from "@/util/colors";
import { FormatMessageFunc } from "@/util/formatMessage";
import { formatPercentage } from "@/util/numberFormatter";
import { fastNth, floatEqual, fastProp, forEachIndexed } from "@/util/opt";
import { LineSeriesOption } from "echarts";
import { last, isNil } from "lodash/fp";
import { dateFormat } from "@/util/dateFormat";

export const PARAMETER = "PARAMETER";
export const HISTORY = "HISTORY";
export const MONTE_CARLO = "MONTE_CARLO";

export const day10 = 10;
export const day30 = 30;
export const day90 = 90;

export const COEFFICIENT85 = "COEFFICIENT85";
export const COEFFICIENT90 = "COEFFICIENT90";
export const COEFFICIENT95 = "COEFFICIENT95";
export const COEFFICIENT99 = "COEFFICIENT99";

export const getSection = (
  computationType: string,
  confidenceType: string,
  future: number
) => `${computationType}-${confidenceType}-${future}`;

export type valueAtRiskRangeItemType = {
  id: string | number;
  message: string | number;
};

export const compareVar = (
  portfolioVar: number | undefined,
  benchmarkVar: number | undefined,
  formatMessage: FormatMessageFunc
) => {
  if (isNil(portfolioVar) || isNil(benchmarkVar)) return "--";
  if (floatEqual(portfolioVar)(benchmarkVar)) return formatMessage("Flat");
  if (portfolioVar > benchmarkVar) return formatMessage("greaterThan");
  return formatMessage("lessThan");
};

export const earningStatisticsButtonGroup = {
  PARAMETER: {
    id: PARAMETER,
    message: "parameter",
  },
  HISTORY: {
    id: HISTORY,
    message: "history",
  },
  MONTE_CARLO: {
    id: "MONTE_CARLO",
    message: "monte_carlo",
  },
};

export const confidenceTypeOption = {
  COEFFICIENT99: {
    id: COEFFICIENT99,
    message: 0.99,
  },
  COEFFICIENT95: {
    id: COEFFICIENT95,
    message: 0.95,
  },
  COEFFICIENT90: {
    id: COEFFICIENT90,
    message: 0.9,
  },
  COEFFICIENT85: {
    id: COEFFICIENT85,
    message: 0.85,
  },
};

export const futureOption = {
  day10: {
    id: day10,
    message: "10",
  },
  day30: {
    id: day30,
    message: "30",
  },
  day90: {
    id: day90,
    message: "90",
  },
};

const percentage = (value: number) => formatPercentage(value);

export const getRiskDismantleBarChartOption = (
  categories: string[],
  formatMessage: FormatMessageFunc,
  chartDatas: Record<string, any>
) => {
  return {
    categories: categories,
    grid: {
      top: 60,
      bottom: 10,
      right: 50,
      containLabel: true,
    },
    yAxis: {
      type: "category",
      data: categories,
      axisLabel: {
        formatter: (val: string) => val,
      },
    },
    tooltip: {
      valueFormatter: (value: number) => {
        return percentage(value);
      },
      formatter: (param: Record<string, any>) => {
        let showHtml = "";
        forEachIndexed((item: Record<string, any>, index: number) => {
          showHtml +=
            index === 0
              ? `<h5>
                      ${item.name}
                      </h5>`
              : "";
          return (showHtml += getTooltipItem(
            item.color,
            item.seriesName,
            percentage(item.value[index + 1] as number),
            10,
            5
          ));
        })(param);
        return showHtml;
      },
    },
    xAxis: {
      type: "value",
      axisLabel: {
        formatter(value: number) {
          return percentage(value);
        },
        align: "right",
      },
    },
    data: chartDatas,
    series: [
      {
        name: formatMessage("currentRisk"),
        type: "bar",
        color: "#1A7FFF",
        barGap: 0,
        label: {
          show: true,
          position: "right",
          formatter: (param: Record<string, any>) => {
            return percentage(fastNth(1)(param.value) as number);
          },
        },
      },
      {
        name: formatMessage("stressScenario"),
        type: "bar",
        color: "#FFA62A",
        barGap: 0,
        label: {
          show: true,
          position: "right",
          formatter: (param: Record<string, any>) => {
            return percentage(last(param.value) as number);
          },
        },
      },
    ],
  };
};

export const getValueAtRiskChartSeries = (
  portfolioVarData: [string, number][],
  benchmarkVarData: [string, number][],
  portfolioDataName: string,
  formatMessage: FormatMessageFunc
): LineSeriesOption[] => [
  {
    type: "line",
    lineStyle: {
      width: 1.5,
    },
    name: portfolioDataName,
    showSymbol: false,
    smooth: false,
    areaStyle: {
      opacity: 0.5,
      color: {
        type: "linear",
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: BlueColor,
          },
          {
            offset: 1,
            color: "#fff",
          },
        ],
        global: false,
      },
    },
    color: BlueColor,
    data: portfolioVarData,
  },
  {
    type: "line",
    lineStyle: {
      width: 1.5,
    },
    name: formatMessage("performanceBenchmark"),
    showSymbol: false,
    smooth: false,
    color: YellowColor,
    data: benchmarkVarData,
  },
];

export const getValueAtRiskChartOptions = (
  dates: string[],
  yearOfDates: Record<string, string>,
  portfolioType: string,
  formatMessage: FormatMessageFunc
): LineChartOpts["options"] => ({
  grid: {
    top: 60,
    right: 40,
  },
  xAxis: {
    data: dates,
    nameGap: 40,
    axisLabel: {
      interval: (index: number, value: string) => {
        const year = fastProp(value)(yearOfDates);
        return year ? true : false;
      },
      formatter(value: string) {
        return portfolioType === "aum"
          ? `${dateFormat(value, "YYYY-MM")}`
          : `${fastProp(value)(yearOfDates)}${formatMessage("year")}`;
      },
    },
  },
  yAxis: {
    min: "dataMin",
    max: "dataMax",
  },
  legend: {
    left: 0,
    top: 15,
    icon: "rect",
    borderRadius: 4,
    itemWidth: 20,
    itemHeight: 4,
  },
});
