import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormatMessage } from "@/util/formatMessage";
import { Card, Col, Row, Space } from "antd";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import SectorCategoriesSelect from "@/components/sectorCategoriesSelect";
import {
  fetchFundDetailIndustryTrendDate,
  fetchGetFundDetailETFIndustryTrend,
  fetchGetFundDetailIndustryTrend,
} from "@/store/fundDetailSlice";
import { fastHas, fastProp, mapIndexed } from "@/util/opt";
import PieCard from "@/views/compareManage/components/pieCard";
import LineChart, { LineChartOpts } from "@/echarts/lineChart";
import style from "../index.module.less";
import { DataRange } from "./fundAllocation";
import { eightPercentColors, paletteColors } from "@/util/colors";
import { FundDetailIndustryTrend } from "@/model/fundDetail";
import {
  keys,
  prop,
  flow,
  isEmpty,
  orderBy,
  map,
  filter,
  identity,
  flatten,
  uniq,
  last,
  first,
} from "lodash/fp";
import {
  getIndustyPieChartOptions,
  isOther,
} from "@/views/compareManage/fundCompare/constant";
import { LineSeriesOption, PieSeriesOption } from "echarts";
import dayjs from "dayjs";
// import { AssetDetailComponent } from "../../../constant";
import mapper from "../mapper";
import { tooltipFormatter } from "@/util/chart";
import { formatPercentage } from "@/util/numberFormatter";
import { sectorCategoriesMapSelector } from "@/selectors/sectorCategories";
import { FROM_CREATION } from "@/constant/statisticRange";
import ScaleRangeSelector from "./scaleRangeSelector";
import LoadingComponent from "@/components/LoadingComponent";
import { getCalculateScaleRangeDate } from "@/constant/statisticRangeCalculator/rangeCalculator";
import { useCreation, useMemoizedFn } from "ahooks";
import TradingDatePicker from "@/components/tradingDatePicker";
import { checkDisabledRangeDate } from "@/views/portfolioManage/manualCreatePortfolio/constant";

const useGetSectorCategoryName = () => {
  const formatMessage = useFormatMessage();
  const sectorCategoryMap = useAppSelector(sectorCategoriesMapSelector);

  return (v: string) =>
    isOther(v) ? formatMessage(v) : prop(`${v}.name`)(sectorCategoryMap);
};
const useGetPieCardData = (
  range: string,
  fundDetailIndustryTrend: FundDetailIndustryTrend
) => {
  const getSectorCategoryName = useGetSectorCategoryName();
  return useMemo(() => {
    const newData = fastProp(range)(fundDetailIndustryTrend);
    return flow(
      keys,
      map((v: string) => ({
        name: getSectorCategoryName(v),
        value: fastProp(v)(newData),
      })),
      orderBy("value", "desc"),
      filter("value"),
      mapIndexed((v: { name: string; value: number }, index: number) => ({
        ...v,
        color: eightPercentColors[index],
      }))
    )(newData);
  }, [fundDetailIndustryTrend, getSectorCategoryName, range]);
};
const useGetLineChartDataSource = (
  allDates: string[],
  fundDetailIndustryTrend: FundDetailIndustryTrend,
  scaleRange: string,
  sectorCategories: string[]
) => {
  const formatMessage = useFormatMessage();
  const sectorCategoryMap = useAppSelector(sectorCategoriesMapSelector);
  const categories = useMemo(
    () =>
      filter((date: string) => fastHas(date)(fundDetailIndustryTrend))(
        getCalculateScaleRangeDate(allDates, scaleRange)
      ),
    [allDates, fundDetailIndustryTrend, scaleRange]
  );
  const options = useMemo<LineChartOpts["options"]>(() => {
    return {
      grid: {
        right: 100,
        top: 20,
      },
      xAxis: {
        data: categories,
        nameGap: 40,
        axisLabel: {
          formatter(value: string) {
            return `${dayjs(value).format("YYYY/MM/DD")}`;
          },
        },
      },
      yAxis: {
        min: "dataMin",
        max: "dataMax",
      },
      legend: {
        right: 0,
        orient: "vertical",
        type: "scroll",
      },
      tooltip: {
        order: "valueDesc",
        confine: true,
        formatter: tooltipFormatter(formatPercentage, formatMessage),
      },
    };
  }, [categories, formatMessage]);
  const series = useMemo<LineChartOpts["series"]>(
    () =>
      map<string, LineSeriesOption>((v) => ({
        type: "line",
        name: isOther(v)
          ? formatMessage(v)
          : prop(`${v}.name`)(sectorCategoryMap),
        showSymbol: false,
        smooth: false,
        areaStyle: {
          opacity: 0.5,
        },
        lineStyle: {
          width: 1.5,
        },
        stack: "Total",
        data: map(
          (date: string) => prop(`${date}.${v}`)(fundDetailIndustryTrend) || 0
        )(categories),
      }))(sectorCategories),
    [
      categories,
      fundDetailIndustryTrend,
      sectorCategoryMap,
      formatMessage,
      sectorCategories,
    ]
  );
  return { options, series };
};
export default React.memo(
  ({
    fundId,
    updateHandler,
  }: {
    fundId: string;
    updateHandler: (key: string) => (value: any) => any;
  }): JSX.Element => {
    const formatMessage = useFormatMessage();
    const dispatch = useAppDispatch();
    const [dateRange, changeDateRange] = useState("");
    const {
      sectorId,
      fundDetailIndustryTrend,
      fundDetailETFIndustryTrend,
      industryTrendDate,
    } = useAppSelector((state) => mapper(state, fundId));

    const endDates = useMemo(
      () =>
        flow(fastProp("dates"), orderBy(identity, "desc"))(industryTrendDate),
      [industryTrendDate]
    );

    const reportDate = useMemo(
      () => flow(orderBy(identity, "asc"))(keys(fundDetailIndustryTrend)),
      [fundDetailIndustryTrend]
    );
    const sectorCategories = useMemo(
      () => flow(map(keys), flatten, uniq)(fundDetailIndustryTrend),
      [fundDetailIndustryTrend]
    );

    const isETF = useMemo(
      () => fastProp("isETF")(industryTrendDate),
      [industryTrendDate]
    );

    const industryTrendRange = useMemo(() => first(endDates), [endDates]);

    useEffect(() => {
      if (sectorId) {
        dispatch(
          fetchGetFundDetailIndustryTrend({
            sectorId,
            fundId,
          })
        );
      }
    }, [dispatch, fundId, sectorId]);
    useEffect(() => {
      dispatch(fetchFundDetailIndustryTrendDate(fundId));
    }, [dispatch, fundId]);
    useEffect(() => {
      if (sectorId && dateRange) {
        dispatch(
          fetchGetFundDetailETFIndustryTrend({
            sectorId,
            fundId,
            date: dateRange,
          })
        );
      }
    }, [dispatch, fundId, sectorId, dateRange]);

    useEffect(
      () => changeDateRange(industryTrendRange as string),
      [industryTrendRange]
    );
    const pieData = useGetPieCardData(dateRange, fundDetailETFIndustryTrend);

    const [scaleRange, setScaleRange] = useState(FROM_CREATION);
    const { options, series } = useGetLineChartDataSource(
      reportDate,
      fundDetailIndustryTrend,
      scaleRange,
      sectorCategories
    );
    const changeScaleRange = useCallback(
      (e) => setScaleRange(e.target.value),
      []
    );
    const changeSectorId = useCallback(
      (v: string) => {
        updateHandler("selectedSectorId")(v);
        return dispatch(
          fetchGetFundDetailIndustryTrend({
            sectorId: v,
            fundId,
          })
        );
      },
      [dispatch, updateHandler, fundId]
    );

    const pieOptions = useCreation(
      () => getIndustyPieChartOptions(formatMessage),
      []
    );
    const disabledDate = useMemoizedFn(
      checkDisabledRangeDate(
        last(endDates) as string,
        first(endDates) as string
      )
    );
    return (
      <Card
        title={formatMessage("IndustryDistributionOfStockAssets")}
        bordered={false}
        size="small"
        extra={
          <Space>
            <span>{formatMessage("industryDistribution")}</span>
            <SectorCategoriesSelect
              value={sectorId}
              onChange={changeSectorId}
            />
          </Space>
        }
      >
        <Row gutter={8} wrap={false}>
          <Col span={12}>
            <LoadingComponent actions={fetchGetFundDetailETFIndustryTrend}>
              <PieCard
                title={formatMessage("IndustryOfStockAssets")}
                dataSource={pieData as PieSeriesOption["data"]}
                pieOptions={pieOptions}
                operation={
                  !isEmpty(endDates) &&
                  (isETF ? (
                    <TradingDatePicker
                      disabledDate={disabledDate}
                      allowClear={false}
                      value={dateRange}
                      className={style.DatePickerWidth}
                      onChange={changeDateRange}
                    />
                  ) : (
                    <DataRange
                      date={endDates as string[]}
                      value={dateRange}
                      onChange={changeDateRange}
                    />
                  ))
                }
              />
            </LoadingComponent>
          </Col>
          <Col span={12}>
            <Card className={style.PieCard}>
              <div className={style.PieCardTitle}>
                <span>{formatMessage("ChangesInStockAssets")}</span>
                <ScaleRangeSelector
                  value={scaleRange}
                  onChange={changeScaleRange}
                />
              </div>
              <LoadingComponent actions={fetchGetFundDetailIndustryTrend}>
                <LineChart
                  options={options}
                  series={series}
                  color={paletteColors}
                  height={300}
                />
              </LoadingComponent>
            </Card>
          </Col>
        </Row>
      </Card>
    );
  }
);
