import { createContext, Dispatch } from "react";

export interface GlobalFace {
  lang: "zh" | "en";
}
export const globalDataInit: GlobalFace = {
  lang: "zh",
};
export interface GlobalReducerProps {
  globalState: GlobalFace;
  setGlobalState: Dispatch<Partial<GlobalFace>>;
}
export const ChoiceLang = createContext<GlobalReducerProps>({
  globalState: globalDataInit,
  setGlobalState: () => {
    throw new Error("GlobalContext 未定义");
  },
});
export const GlobalReducer = (
  prevState: GlobalFace,
  updatedProperty: Partial<GlobalFace>
): GlobalFace => ({
  ...prevState,
  ...updatedProperty,
});
