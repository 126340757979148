import { Select } from "antd";
import { map } from "lodash/fp";
import { useFormatMessage } from "@/util/formatMessage";
import style from "@/components/periodRangePicker.module.less";

const { Option } = Select;
type periodRangeProps = {
  onChange: (value: string) => void;
  period?: string;
  selectablePeriods: string[];
};
const PeriodRange = ({
  onChange,
  period,
  selectablePeriods,
}: periodRangeProps): JSX.Element => {
  const formatMessage = useFormatMessage();
  return (
    <div className={style.PeriodPicker}>
      <Select value={period} className={style.Width} onChange={onChange}>
        {map((selectablePeriod: string) => (
          <Option key={selectablePeriod} value={selectablePeriod}>
            {formatMessage(selectablePeriod)}
          </Option>
        ))(selectablePeriods)}
      </Select>
    </div>
  );
};

export default PeriodRange;
