import { FundsInterface } from "@/model/entities";
import { RootState } from "@/store";
import { fastNth, fastProp, normalize } from "@/util/opt";
import { createSelector } from "@reduxjs/toolkit";
import { flow, map, orderBy, reject, update } from "lodash/fp";

export const orderedFundSelector = createSelector(
  (state: RootState) => state.entities.funds,
  (funds) => orderBy("code", "asc")(funds) as FundsInterface[]
);
export const fundCodeMapSelector = createSelector(
  (state: RootState) => state.entities.funds,
  (funds) => normalize<FundsInterface>("code")(funds)
);

export const fundIdMapSelector = createSelector(
  (state: RootState) => state.entities.funds,
  (funds) => normalize<FundsInterface>("fundId")(funds)
);

export const notDelistingFundsSelector = createSelector(
  (state: RootState) => state.entities.funds,
  (funds) =>
    reject<FundsInterface>((item) => fastProp("maturityDate")(item))(funds)
);

export const notDelistingFundsMapSelector = createSelector(
  notDelistingFundsSelector,
  (funds) => normalize<FundsInterface>("fundId")(funds)
);

export const fundNameMapSelector = createSelector(
  (state: RootState) => state.entities.funds,
  (funds) => normalize<FundsInterface>("name")(funds)
);

export const orderedNotDelistingFundsSelector = createSelector(
  notDelistingFundsSelector,
  (funds) => orderBy("code", "asc")(funds) as FundsInterface[]
);

export const fundCodeSuffixMapSelector = createSelector(
  (state: RootState) => state.entities.funds,
  (funds) =>
    flow(
      map(update("code", (code: string) => fastNth(0)(code?.split(".")))),
      normalize<FundsInterface>("code")
    )(funds)
);
