import { Rate, Space, Tag } from "antd";
import {
  divide,
  filter,
  first,
  flow,
  groupBy,
  map,
  mapValues,
  pick,
  prop,
  size,
  toArray,
} from "lodash/fp";
import { useCreation, useMemoizedFn, useUpdateEffect } from "ahooks";
import { useEffect, useMemo, useState } from "react";
import {
  useGetFactorColumns,
  useGetFactorsDataSource,
  useGetFactorValue,
} from "@/hooks/factors";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useFormatMessage } from "@/util/formatMessage";
import { fastProp } from "@/util/opt";
import {
  fundFiskLevel,
  fundStyleTags,
  hotThemeIds,
  ratingTags,
} from "@/constant/fundFilter";
import { industryThemeListMapSelector } from "@/selectors/fundTheme";
import {
  preferredFilterSelector,
  preferredFundListSelector,
  preferredResultSelector,
  preferredUpdateSelector,
} from "./selector";
import { Fund } from "@/components/fundResultTable";
import { useGetIndexInfo } from "@/components/fundResultTable/hooks";
import {
  fetchPreferredFundList,
  setPreferredResult,
  fetchPreferredFunds,
  fetchPreferredDate,
} from "@/store/preferredFund";
import {
  PreferredFilterList,
  PreferredFundParam,
  PreferredFund,
} from "@/model/preferredFund";
import {
  industryDataSelector,
  sectorCategoriesMapSelector,
} from "@/selectors/sectorCategories";
import { fundIdMapSelector } from "@/selectors/fund";
import ToFundDetailPage from "@/components/navigateToPage/toFundDetailPage";
import { normalize } from "@/util/opt";
import AddOptional from "@/components/addOptional";
import compareIconActive from "@/assets/compareIconActive.png";
import compareIcon from "@/assets/compareIcon.png";
import { bigNumber } from "@/util/math";
import {
  defaultPerferredFactors,
  getFeaturesConditions,
  maxDrawal,
  statisticRangeList,
} from "./constant";
import style from "./index.module.less";
import ShowTagList from "./components/tagList";
import { featureListItem } from "./components/preferredFilter";

const { div, toNumber, convert } = bigNumber;

export const useGetFeaturesConditions = () => {
  const formatMessage = useFormatMessage();
  const industriesChildren = useAppSelector(industryDataSelector);
  const industryThemeListMap = useAppSelector(industryThemeListMapSelector);
  const hotThemeList = useMemo(
    () => flow(pick(hotThemeIds), toArray)(industryThemeListMap),
    [industryThemeListMap]
  );
  const ratingTagList = useMemo(
    () => ratingTags(formatMessage) as featureListItem[],
    [formatMessage]
  );
  const fundStyleTagList = useMemo(
    () => fundStyleTags(formatMessage) as featureListItem[],
    [formatMessage]
  );
  const riskLevelList = useMemo(
    () => fundFiskLevel(formatMessage),
    [formatMessage]
  );
  const featureList = useMemo(
    () =>
      getFeaturesConditions(
        formatMessage,
        industriesChildren,
        hotThemeList,
        ratingTagList,
        fundStyleTagList,
        riskLevelList,
        statisticRangeList
      ),
    [
      formatMessage,
      industriesChildren,
      hotThemeList,
      ratingTagList,
      fundStyleTagList,
      riskLevelList,
    ]
  );

  const featureListMap = useMemo(
    () => normalize("id")(featureList),
    [featureList]
  );

  return { featureList, featureListMap };
};

export const useGetPreferredInfoAndList = (
  updatePreferredFilter: (key: string) => (value: any) => void
) => {
  // const formatMessage = useFormatMessage();
  const preferredFilterList = useAppSelector(preferredFilterSelector);
  const updateDate = useAppSelector(preferredUpdateSelector);
  const { firstType, secondType, factorIds, limitNumber, date } =
    preferredFilterList as PreferredFilterList;
  const { featureList } = useGetFeaturesConditions();
  const preferredFund = useAppSelector(preferredFundListSelector);
  const preferredResultData = useAppSelector(preferredResultSelector);

  const { sortCloumn } = preferredResultData;

  const avgValue = useMemo(
    () => prop(`factorAvgValue.${sortCloumn}`)(preferredFund),
    [preferredFund, sortCloumn]
  );

  const dispatch = useAppDispatch();

  const secondTypeList = useMemo(
    () =>
      flow(
        filter((item: featureListItem) => item.id === firstType),
        first,
        fastProp("children")
      )(featureList),
    [featureList, firstType]
  );

  const onSearch = useMemoizedFn(() => {
    const params: Record<string, any> = { date: date };
    if (firstType && secondType) {
      if (firstType === "sectionReturn") {
        params[firstType] = { section: secondType, ...limitNumber };
      } else {
        params[firstType] = [secondType];
      }
    }
    if (factorIds) {
      params.factorIds = [factorIds];
    }

    dispatch(
      setPreferredResult({
        sortCloumn: factorIds,
        resultFirstType: firstType,
        resultSecondType: secondType,
      })
    );
    dispatch(fetchPreferredFundList(params as PreferredFundParam));
  });

  return {
    updateDate,
    secondTypeList,
    featureList,
    onSearch,
    preferredFilterList,
    updatePreferredFilter,
    avgValue,
  };
};

export const useGetPreferredTableData = ({
  onOpenAnchorModal,
  addSelectIds,
  validateIsChecked,
  updatePreferredFilter,
}: {
  onOpenAnchorModal?: () => any;
  addSelectIds: (id: string) => void;
  validateIsChecked: (id: string) => boolean;
  updatePreferredFilter: (key: string) => (value: any) => void;
}) => {
  const preferredFund = useAppSelector(preferredFundListSelector);
  const preferredResultData = useAppSelector(preferredResultSelector);
  const { sortCloumn } = preferredResultData;
  const preferredFundList = useMemo(
    () => fastProp("preferredFunds")(preferredFund),
    [preferredFund]
  );

  const avgList = useMemo(
    () => fastProp("factorAvgValue")(preferredFund),
    [preferredFund]
  );

  const [selectedFactorCode, changeFactorCode] = useState<string[]>(
    defaultPerferredFactors
  );

  const { factorMap } = useGetIndexInfo("fund");

  const { columns, newFactorCode } = useGetFactorColumn(
    selectedFactorCode,
    sortCloumn,
    addSelectIds,
    validateIsChecked,
    onOpenAnchorModal
  );

  useGetFactorValue(
    preferredFundList || [],
    "fundId",
    "fund",
    newFactorCode,
    factorMap
  );
  const [dataSource, onSort] = useGetFactorsDataSource<Fund>(
    preferredFundList || [],
    "fundId",
    newFactorCode,
    factorMap
  );
  const exceedRatio = useMemo(() => {
    if (sortCloumn) {
      const avg = fastProp(sortCloumn)(avgList);
      return flow(
        sortCloumn === maxDrawal
          ? filter((item: Record<string, any>) => item[sortCloumn] < avg)
          : filter((item: Record<string, any>) => item[sortCloumn] > avg),
        size,
        (res) => divide(res, size(dataSource))
      )(dataSource);
    }
    return;
  }, [dataSource, sortCloumn, avgList]);

  useUpdateEffect(() => {
    updatePreferredFilter("exceedRatio")(exceedRatio);
  }, [exceedRatio]);

  return {
    preferredFundList: dataSource,
    selectedFactorCode,
    columns,
    changeFactorCode,
    onSort,
  };
};

export const useGetFactorColumn = (
  factorCode: string[],
  factorIds: string,
  addSelectIds: (id: string) => void,
  validateIsChecked: (id: string) => boolean,
  onOpenAnchorModal?: () => any
) => {
  const preferredResultData = useAppSelector(preferredResultSelector);
  const categoriesMap = useAppSelector(sectorCategoriesMapSelector);
  const { sortCloumn } = preferredResultData;
  const newFactorCode = useCreation(() => {
    if (sortCloumn) {
      if (factorCode.includes(sortCloumn)) {
        return flow(
          filter((factorId) => factorId !== factorIds),
          (res) => [sortCloumn].concat(res as string[])
        )(factorCode);
      } else {
        return [sortCloumn].concat(factorCode);
      }
    } else {
      return factorCode;
    }
  }, [sortCloumn, factorCode]);
  const factorColumns = useGetFactorColumns("fund", newFactorCode);
  const columns = useGetColumns(
    factorColumns as Record<string, any>[],
    categoriesMap,
    addSelectIds,
    validateIsChecked,
    onOpenAnchorModal
  );
  return { columns, newFactorCode };
};

export const useGetColumns = (
  factorColumns: Record<string, any>[],
  categoriesMap: Record<string, any>,
  addSelectIds: (id: string) => void,
  validateIsChecked: (id: string) => boolean,
  onOpenAnchorModal?: () => any
) => {
  const formatMessage = useFormatMessage();
  const fundRiskMap = normalize("id")(fundFiskLevel(formatMessage));
  const compareSelectedFund = useMemoizedFn((fundId: string) => {
    if (!validateIsChecked(fundId)) {
      addSelectIds(fundId);
      onOpenAnchorModal && onOpenAnchorModal();
    }
  });
  return [
    {
      dataIndex: "name",
      title: formatMessage("fundName"),
      align: "left",
      fixed: "left",
      width: 350,
      ellipsis: true,
      render: (text: string, record: Record<string, any>) => {
        const topIndustry = map((id: string) =>
          prop(`${id}.name`)(categoriesMap)
        )(record.topIndustryIds);
        const tagList = [
          prop(`${record?.fundRisk}.name`)(fundRiskMap),
          ...topIndustry,
        ];
        if (record.fundStyle) {
          tagList.unshift(`${formatMessage(record?.fundStyle)}型`);
        }
        return (
          <div>
            <Space direction="vertical" size={0}>
              <Space>
                <ToFundDetailPage
                  name={text}
                  id={record.fundId}
                  className={style.FundName}
                />
                <span className={style.FundCode}>
                  <ToFundDetailPage
                    name={`(${record?.code})`}
                    id={record.fundId}
                  />
                </span>
              </Space>
              <Space>
                <Rate
                  className={style.rateIcon}
                  allowHalf
                  disabled
                  value={record?.fundRating}
                />
                <div>
                  <ShowTagList tagList={tagList as string[]} />
                </div>
              </Space>
            </Space>
          </div>
        );
      },
    },
    {
      title: formatMessage("fundType"),
      dataIndex: "fundType",
      align: "center",
      width: 120,
      render: (text: string) => {
        return (
          <Tag color="processing" className={style.FundTypeTag}>
            {text}
          </Tag>
        );
      },
    },
    ...(factorColumns || []),
    {
      title: formatMessage("operator"),
      dataIndex: "operator",
      fixed: "right",
      width: 100,
      render: (_: any, record: Record<string, any>) => (
        <Space>
          <div className={style.OperatorWrapper}>
            <AddOptional id={record.fundId} shape="icon" size="middle" />
            <img
              className={style.Icons}
              src={
                validateIsChecked(record.fundId)
                  ? compareIcon
                  : compareIconActive
              }
              onClick={() => compareSelectedFund(record.fundId)}
            />
          </div>
        </Space>
      ),
    },
  ];
};

export const useGetPreferredFundsByDate = (date?: string) => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(preferredUpdateSelector);
  const preferredFunds = useAppSelector(
    (state) => state?.preferredFund?.preferredFunds
  );
  const fundIdMap = useAppSelector(fundIdMapSelector);

  useEffect(() => {
    dispatch(fetchPreferredDate());
  }, [dispatch]);

  useUpdateEffect(() => {
    if (date && !preferredFunds?.[date]) {
      dispatch(fetchPreferredFunds(date));
    }
  }, [date, dispatch]);

  useUpdateEffect(() => {
    if (!date && dates) {
      dispatch(fetchPreferredFunds(first(dates) as string));
    }
  }, [date, dates, dispatch]);

  const funds = useCreation(() => {
    return map((fund: PreferredFund) => ({
      ...fund,
      firstInvestType: fundIdMap?.[fund?.fundId]?.firstInvestType,
    }))(fastProp(date as string)(preferredFunds));
  }, [fundIdMap, preferredFunds?.[date || ""]]);

  const fundCategory2FundIds = useCreation(
    () =>
      flow(
        groupBy("firstInvestType"),
        mapValues(map(fastProp("fundId")))
      )(funds),
    [funds]
  );

  const recentPreferredFund = useMemo(
    () => fastProp(first(dates) as string)(preferredFunds),
    [dates, preferredFunds]
  );

  return {
    dates,
    funds,
    fundCategory2FundIds,
    recentPreferredFund,
    recentDate: first(dates),
  };
};

export const genParser =
  (scale: number) =>
  (value: number | undefined | null): number => {
    let v = null;
    try {
      v = flow(convert, div(scale), toNumber)(Number(value));
    } catch (e) {
      v = NaN;
    }

    return v;
  };
